define('component-library/components/contact-box', ['exports', 'ember', 'component-library/templates/components/contact-box'], function (exports, _ember, _componentLibraryTemplatesComponentsContactBox) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-typography', 'z-contactBox-container'],
		classNameBindings: ['isBoxOpened:is-opened', 'isSubmitted:is-shrinked'],
		template: _componentLibraryTemplatesComponentsContactBox['default'],
		isSubmitted: false,
		isBoxOpened: false,
		message: null,
		errorText: null,
		zenefitsContact: null,
		aggregationToken: null,
		// don't review the name of Zenefits contact, and always show 'account manager' regardless of actual internal type
		label: 'Ask your benefits team anything.',

		actions: {
			sendMessage: function sendMessage(sendButton) {
				var _this = this;

				if (!this.get('message')) {
					this.set('errorText', 'Question cannot be empty.');
					sendButton.stop();
				} else {
					this.set('errorText', null);
					var promise = _ember['default'].ajax({
						url: '/custom_api/email_oe_contact',
						type: 'POST',
						data: {
							'zenefitsContactsId': this.get('zenefitsContact.zenefitsContactsId'),
							'message': this.get('message'),
							'aggregationToken': this.get('aggregationToken'),
							'productURL': window.location.href
						}
					});
					promise.then(function () {
						sendButton.stop();
						_this.set('isSubmitted', true);

						// close contact box 5.5s later after message sent
						return _ember['default'].run.later(function () {
							_this.send('close');
						}, 5500);
					});
				}
			},

			close: function close() {
				this.setProperties({
					isBoxOpened: false,
					errorText: null
				});
			},

			revealContactBox: function revealContactBox() {
				this.setProperties({
					isBoxOpened: true,
					isSubmitted: false,
					message: null
				});
			}
		}
	});
});