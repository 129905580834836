define('component-library/components/simple-wizard/profile-layout/wizard-content', ['exports', 'ember', 'component-library/mixins/fixed-sidebar-resize'], function (exports, _ember, _componentLibraryMixinsFixedSidebarResize) {
	'use strict';

	var DEFAULT_PROFILE_THUMBNAIL = '/static/img/silhouette.png';
	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsFixedSidebarResize['default'], {
		firstName: '',
		lastName: '',
		navAndProfileOffset: 80,
		canEditImage: true,

		photoThumbnailURL: _ember['default'].computed('employee.photoThumbnailUrl', function () {
			return this.get('employee.photoThumbnailUrl') || DEFAULT_PROFILE_THUMBNAIL;
		}),

		// properties for sidebar mixin
		sidebarNavSelector: '.z-simple-wizard-navigation',

		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super();

			// from mixin
			this.addSidebarWidthResizingSupport();

			if (this.get('alwaysSmallProfileInfo')) {
				return;
			}

			// resize profile info sidebar
			var scopedScrollEvent = 'scroll.' + this.get('guid');

			_ember['default'].$('.simple-wizard-profile-layout').on(scopedScrollEvent, function () {
				var scrollTop = _ember['default'].$('.z-productBody-container').scrollTop();
				var cardsTop = _ember['default'].$('.main-content').offset().top - _this.get('navAndProfileOffset') - _ember['default'].$(window).scrollTop();

				_ember['default'].$('.profile-info').toggleClass('small', scrollTop > cardsTop);
			});
		},

		willDestroyElement: function willDestroyElement() {
			this._super();

			this.removeSidebarWidthResizingSupport();

			var scopedScrollEvent = 'scroll.' + this.get('guid');
			_ember['default'].$('.simple-wizard-profile-layout').off(scopedScrollEvent);
		},

		actions: {

			onProfileImageChange: function onProfileImageChange(newPhotoUrl, newThumbnailUrl) {
				var employee = this.get('employee');
				employee.set('photoUrl', newPhotoUrl);
				if (newThumbnailUrl) {
					employee.set('photoThumbnailUrl', newThumbnailUrl);
				}
				employee.save();
			}
		}
	});
});