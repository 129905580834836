define('z-address/mixins/address-mixin', ['exports', 'ember', 'z-address/constants/work-location'], function (exports, _ember, _zAddressConstantsWorkLocation) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		includeSanctionedCountries: false,
		showOnlyCountriesInWorkLocations: false,
		countries: _ember['default'].computed('showOnlyCountriesInWorkLocations', 'includeSanctionedCountries', function () {
			if (this.get('showOnlyCountriesInWorkLocations')) {
				return this.get('includeSanctionedCountries') ? _zAddressConstantsWorkLocation.NEW_SUPPORTED_COUNTRY_INCLUDING_SANCTIONED : _zAddressConstantsWorkLocation.NEW_SUPPORTED_COUNTRY;
			} else {
				return _zAddressConstantsWorkLocation.ISO_COUNTRY;
			}
		}),
		setCountry: _ember['default'].computed('country', {
			get: function get() {
				return this.get('country');
			},
			set: function set(key, value) {
				this.setProperties({
					country: value,
					state: null,
					zipCode: null
				});
				return value;
			}
		}),
		isInternational: _ember['default'].computed('country', function () {
			return this.get('country') && this.get('country') != 'US';
		}),
		supportedStates: _ember['default'].computed('country', 'stateDropdownOptions', 'isInternational', function () {
			if (!_ember['default'].isEmpty(this.get('stateDropdownOptions'))) {
				return this.get('stateDropdownOptions');
			}
			// If we didn't get stateDropdownOptions by default, we use the US_STATES
			var states = _zAddressConstantsWorkLocation.US_STATES;
			if (this.get('isInternational')) {
				states = _zAddressConstantsWorkLocation.SUPPORTED_STATES[this.get('country')] || _ember['default'].A([]);
			}
			return _ember['default'].A(states).mapBy('code');
		}),
		stateLabel: _ember['default'].computed('country', function () {
			return _zAddressConstantsWorkLocation.STATE_LABELS[this.get('country')] || 'State/Province';
		}),
		cityLabel: _ember['default'].computed('country', function () {
			return _zAddressConstantsWorkLocation.CITY_LABELS[this.get('country')] || 'City';
		}),
		postalCodeLabel: _ember['default'].computed('country', function () {
			return _zAddressConstantsWorkLocation.ZIP_LABELS[this.get('country')] || 'Postal Code';
		}),
		isCountrySupported: _ember['default'].computed('country', 'showSanctionedCountries', function () {
			var supportedCountries = this.get('showSanctionedCountries') ? _zAddressConstantsWorkLocation.COUNTRIES_SUPPORTED_INCLUDING_SANCTIONED : _zAddressConstantsWorkLocation.COUNTRIES_SUPPORTED;
			return supportedCountries.indexOf(this.get('country')) !== -1;
		}),
		hasState: _ember['default'].computed('country', function () {
			return !(_zAddressConstantsWorkLocation.COUNTRIES_WITH_NO_STATE.indexOf(this.get('country')) !== -1);
		}),
		hasPostalCode: _ember['default'].computed('country', function () {
			return !(_zAddressConstantsWorkLocation.COUNTRIES_WITH_NO_POSTAL_CODE.indexOf(this.get('country')) !== -1);
		})
	});
});