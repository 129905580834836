(function() {
  function vendorModule(Ember) {
    'use strict';
    Ember['default'].assert('Make sure rangy-*.js is imported and loaded before importing this module.' +
    	'in client-app this library is imported into vendor-lazy.js. self.rangy is not defined',
    	!!self.rangy);
    return { 'default': self['rangy'] };
  }

  define('rangy', ['ember'], vendorModule);
})();
