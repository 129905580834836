define('component-library/components/z-spreadsheet', ['exports', 'ember', 'lodash/lodash'], function (exports, _ember, _lodashLodash) {
	// [WIP]
	// This component is WIP, use at your own risk as the API may change rapidily.  This will be safe to use once it lands in style-guide and is properly documented.
	// ============================================================

	'use strict';

	var service = _ember['default'].inject.service;

	// Error popups need to get appended to td elements that are generated by handsontable dynamically.
	// I was unable to figure out how to  dynamically create, render, and append a component to some non-ember,
	// dynamically-generated piece of DOM, so we'll just have to forego using our existing component.
	var Z_SPREADSHEET_ERROR_POPOVER_TEMPLATE = '\n\t\t<div class=\'js-censusSheet-errorPopover z-popover z-typography zIndex-level--1 z-popover--placementBottomCenter\'>\n\t\t\t<div class="paper paper--zDepth-3 z-popover-panel">\n\t\t\t\t<div class="paper-container">\n\t\t\t\t\t<div class="grid-block vertical">\n\t\t\t\t\t\t<div class="grid-block">\n\t\t\t\t\t\t\t<div class="grid-content u-textCenter">\n\t\t\t\t\t\t\t\t<p class="popover-content"></p>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>';

	exports['default'] = _ember['default'].Component.extend({
		// NOTE: applying flex-box styles breaks some positioning calculations that are critical
		// to proper rendering
		classNames: ['z-spreadsheet-wrapper'],
		lazyLoader: service(),

		guid: _ember['default'].guidFor(undefined),

		isUnitTestMode: false, //Set to true to skip handsontable lib loading

		//////////////////Required user-specified properties///////////////////////////
		sourceData: [], // Input source from the consumer of this component. DO NOT MODIFY.

		schema: [],
		flow: null, //Flow name (e.g. 'HR', 'BOR', etc.) -- used only for logging
		///////////////////////////////////////////////////////////////////////

		//////////////////Optional user-specified properties///////////////////////////
		handsOnTableOptions: {}, // Object with handsontable configuration options

		// Set to false if you want to use custom col headers.
		// It's more likely that we'll want to define Column Headers in our schema
		useSchemaColHeaders: true,

		// If useSchemaColHeaders is false, these have to be defined else HandsOnTable will default to
		colHeaderProp: null,
		rowHeaderProp: null,

		// bannerNotification and button props
		showBanner: true,
		bannerShowIcon: true,
		bannerText: '',
		bannerMode: 'info',
		initialSaveButtonText: null,
		saveButtonText: null,
		submitButtonText: 'Submit',

		// Additional js selectors for triggering save and submit actions
		saveActionSelector: null,
		submitActionSelector: null,

		// Optional function to set cells to read-only. Function should have an api of
		// function(row, col, prop, cellValue, cellSource, allCellsSourceData), where the
		// cellValue is the handsontable-stored value and allCellsSourceData is the source for ALL cells in
		// case you want to compare against values in other cells.
		// Return true from the function if you want a particular cell to be read-only.
		// Example:
		// readOnlyFunction: function(row, col, prop, cellValue, allCellsSourceData) {
		// 	return cellValue === 'readonly';
		// },
		// Note: "prop" is now just the col number since we translate to array-of-arrays before
		// passing the source into handsontable
		readOnlyFunction: null,
		// Optional function to generate a blank record corresponding to new rows that were
		// that were added in the spreadsheet.
		createBlankRecordFunction: function createBlankRecordFunction() {
			return {};
		},
		validateReadOnlyCells: false,
		///////////////////////////////////////////////////////////////////////

		hotInstance: null,
		_spreadsheetStateData: [], //Cloned and formatted inputData, that is passed to handsontable for the spreadsheet metadata.

		// must be configured, we do not use these directly
		// they're manipulated by _schema methods below
		columns: [],
		colHeaders: [],

		tableErrors: [],

		action: null,
		eventHandlers: {},

		// default zenefits configuration
		defaultHandsOnTableOptions: {
			manualColumnResize: true,
			manualRowResize: true,
			stretchH: 'all',
			startRows: 10,
			startCols: 10,
			minSpareRows: 20,
			minRows: 20,
			rowHeaders: true,
			columnSorting: false,
			allowInvalid: true,
			fixedRowsTop: 0,
			renderAllRows: true },

		// Renders ALL rows. May want to turn off for thousands of rows.
		// lazyload state
		isInDom: false,

		hasErrors: _ember['default'].computed.gt('errorCount', 0),
		errorCount: _ember['default'].computed.alias('tableErrors.length'),
		serverErrors: null,

		init: function init() {
			this._super.apply(this, arguments);

			// Take a deep clone of the original input for handsontable to modify.
			// Note: handsontable supports nested props so a deep clone might be necessary.
			this._initSpreadsheetStateData(this.get('sourceData'));

			this._loadHandsOnTableSource();
		},

		_initSpreadsheetStateData: function _initSpreadsheetStateData(sourceData) {
			var spreadsheetData = undefined;
			var schema = this.get('schema');

			spreadsheetData = sourceData.map(function (record, rowIndex) {
				var rowArr = schema.map(function (definition) {
					return definition.toRow(record);
				});
				rowArr._sourceRowIndex = rowIndex;
				return rowArr;
			});

			this._removeAllCellErrorPopovers();

			this.setProperties({
				tableErrors: [],
				_spreadsheetStateData: spreadsheetData
			});
		},

		// Trigger a refresh of the table if the source data changes. Note that we do NOT reinit
		// the table on a `sourceData.[]` (i.e. addition or deletion of an item in the source),
		// because that would be very expensive. The parent controller needs to pass a completely
		// new reference (so a whole new sourceData set) to trigger this.
		// In other words... your sourceData should be pre-loaded in the model hook before
		// you pass it to z-spreadsheet! If you want to refresh the data in the spreadsheet,
		// pass it a whole new object.
		_refreshTableOnSourceDataChange: (function () {
			var hotInstance = this.get('hotInstance');

			this._initSpreadsheetStateData(this.get('sourceData'));

			if (hotInstance) {
				hotInstance.loadData(this.get('_spreadsheetStateData'));
			}
		}).observes('sourceData'),

		// if schema or data changes, update columns
		_getColumns: function _getColumns() {
			var columns = undefined;
			var schema = this.get('schema');

			if (this.get('_spreadsheetStateData') && schema) {
				columns = schema.map(function (settings) {
					return _ember['default'].$.extend({}, settings, {
						colHeader: settings.colHeader,
						validator: function validator(value, callback) {
							callback(true);
						}
					});
				});
			}

			return columns;
		},

		// if schema changes, update Column Headers
		// [wip]
		_getColHeaders: function _getColHeaders() {
			var prop = undefined;
			var headers = undefined;
			var schema = this.get('schema');

			// by default use schema-defiend colHeaders
			if (this.get('useSchemaColHeaders')) {
				prop = 'colHeader';

				// otherwise use user-configured prop for rowHeader
			} else if (!_ember['default'].isEmpty(this.get('colHeaderProp'))) {
					prop = this.get('colHeaderProp');

					// but if nothing is passed in go with default HandsOnTable colHeaders (aka letters in increments of 1)
				} else {
						return;
					}

			headers = schema.map(function (which) {
				return which[prop];
			});

			return headers;
		},

		_serverErrors: _ember['default'].observer('serverErrors', function () {
			var _this = this;

			var hotInstance = this.get('hotInstance');
			if (!hotInstance) {
				return;
			}
			this.get('serverErrors').forEach(function (error) {
				_this._insertError(error.rowIndex, error.columnIndex, error.propName, error.message);
				hotInstance.setCellMeta(error.rowIndex, error.columnIndex, 'valid', false);
			});
			hotInstance.render();
		}),

		_insertError: function _insertError(rowIndex, columnIndex, propName, errorMessage) {
			/*
    * @param {Integer} rowIndex
    * @param {Integer} columnIndex
    */
			var existingError = this._getErrorObjectForCell(rowIndex, columnIndex);

			if (existingError) {
				existingError.set('errorMessage', errorMessage);
				return;
			}

			this.get('tableErrors').pushObject(_ember['default'].Object.create({
				row: rowIndex,
				col: columnIndex,
				prop: propName,
				errorMessage: errorMessage
			}));

			this._addCellErrorPopover(rowIndex, columnIndex, errorMessage);
		},

		_removeError: function _removeError(rowIndex, columnIndex) {
			this._removeCellErrorPopover(rowIndex, columnIndex);

			var newTableErrors = this.get('tableErrors').reject(function (cellErrors) {
				return cellErrors.row === rowIndex && cellErrors.col === columnIndex;
			});

			this.set('tableErrors', newTableErrors);
		},

		_getErrorObjectForCell: function _getErrorObjectForCell(rowIndex, columnIndex) {
			var tableErrors = this.get('tableErrors');

			return tableErrors.find(function (errorCell) {
				return errorCell.row === rowIndex && errorCell.col === columnIndex;
			});
		},

		_addCellErrorPopover: function _addCellErrorPopover(rowIndex, columnIndex, errorMessage) {
			var _this2 = this;

			var hotInstance = this.get('hotInstance');

			// Add error icon to cell with popover
			var cellTdElement = hotInstance && hotInstance.getCell(rowIndex, columnIndex) || null;

			if (!cellTdElement) {
				return;
			}

			var $tdElement = _ember['default'].$(cellTdElement);
			var actualTable = this.$('.js-handsOnTableTarget');
			$tdElement.popover({
				template: Z_SPREADSHEET_ERROR_POPOVER_TEMPLATE,
				content: function content() {
					// Dynamically get error message content from current tableErrors state object.
					// TODO: Optimize by converting the tableErrors object to be an object indexed
					// by row and col instead of an array.
					var errorObj = _this2._getErrorObjectForCell(rowIndex, columnIndex);
					return errorObj && errorObj.get('errorMessage') || '';
				},
				placement: 'top',
				trigger: 'hover',
				container: actualTable,
				viewport: {
					selector: actualTable,
					padding: 0
				}
			});
		},

		_removeAllCellErrorPopovers: function _removeAllCellErrorPopovers() {
			// Forcefully destroy all popovers. These should not be present at this point, but
			// sometimes stray ones remain.
			_ember['default'].$('.js-censusSheet-errorPopover').remove();
		},

		_removeCellErrorPopover: function _removeCellErrorPopover(rowIndex, columnIndex) {
			var hotInstance = this.get('hotInstance');

			var cellTdElement = hotInstance && hotInstance.getCell(rowIndex, columnIndex) || null;

			if (!cellTdElement) {
				return;
			}

			var $tdElement = _ember['default'].$(cellTdElement);
			if ($tdElement && $tdElement.popover) {
				$tdElement.popover('destroy');
			}
		},

		_parseCells: function _parseCells(changes) {
			/* Handsontable build in plug-in event
    *
    * @param 2D{Array} changes - 2D array containing information about each of the edited cells.
    *                            [[row, prop, oldVal, newVal], ...]
    */
			// TODO Test this
			var change = undefined,
			    oldVal = undefined,
			    newVal = undefined,
			    parsedVal = undefined,
			    row = undefined,
			    col = undefined,
			    parser = undefined;
			var hotInstance = this.get('hotInstance');

			for (var i = 0; i < changes.length; i++) {
				change = changes[i];
				row = change[0];
				col = change[1];
				oldVal = change[2];
				newVal = change[3];
				parser = this._getParserForCol(col);

				if (parser && !_ember['default'].isBlank(newVal) && oldVal !== newVal) {
					parsedVal = parser(newVal);

					if (!_ember['default'].isBlank(parsedVal)) {
						change[3] = parsedVal; //Directly set the change object instead of metadata
					}
				}
			}
		},

		_getColumnSchema: function _getColumnSchema(colIndex) {
			return this.get('schema')[colIndex];
		},

		_getParserForCol: function _getParserForCol(colIndex) {
			var colSchema = this._getColumnSchema(colIndex);
			return colSchema && colSchema.parser || null;
		},

		_getSourceRecordByRow: function _getSourceRecordByRow(row) {
			var hotRowSource = this.get('hotInstance').getSourceDataAtRow(row);

			if (hotRowSource && typeof hotRowSource._sourceRowIndex !== 'undefined') {
				return this.get('sourceData')[hotRowSource._sourceRowIndex];
			}

			return null;
		},

		_resetEmptyRows: function _resetEmptyRows(changes) {
			var _this3 = this;

			if (!changes || !changes.length) {
				return;
			}

			var hotInstance = this.get('hotInstance');
			var tableErrors = this.get('tableErrors');
			var colCount = hotInstance.countCols();

			var changedRowIndexes = changes.map(function (change) {
				return change[0];
			}).uniq();

			var emptyRowIndexes = changedRowIndexes.filter(function (rowIndex) {
				if (hotInstance.isEmptyRow(rowIndex)) {

					// If the whole row is empty, set the handsontable metadata to show all
					// cells as valid.
					for (var columnIndex = 0; columnIndex < colCount; columnIndex++) {
						_this3._removeCellErrorPopover(rowIndex, columnIndex);
						hotInstance.setCellMeta(rowIndex, columnIndex, 'valid', true);
					}

					return true;
				}

				return false;
			});

			// Remove all errors associated with this row.
			tableErrors = tableErrors.reject(function (errorCellData) {
				return emptyRowIndexes.contains(errorCellData.row);
			});

			// Remove all server-validation errors as well. Let the server re-validate this
			// again on next save/submit request.
			emptyRowIndexes.forEach(function (row) {
				var record = _this3._getSourceRecordByRow(row) || {};
				record._errors = {};
			});

			this.set('tableErrors', tableErrors);
		},

		_sendAfterChangeAction: function _sendAfterChangeAction(changes, source) {
			var _this4 = this;

			this.sendAction('afterChange', _ember['default'].Object.create({
				hasErrors: this.get('hasErrors'),
				errorCount: this.get('errorCount'),
				changes: changes.map(function (change) {
					var record = _this4._getSourceRecordByRow(change[0]);
					return _ember['default'].Object.create({
						row: change[0],
						prop: change[1],
						oldVal: change[2],
						newVal: change[3],
						record: record
					});
				})
			}), source);
		},

		/*
       	Motivation:
             Unfortunately, handsontable doesn't support an easy way to set dependency between cells on the
             frontend, thus custom code needs to be written to set up cell dependencies.
             Cell dependencies are useful for cases when you want to lock specific cell based on the value in
             a different cell. In this case, I wanted to narrow down the list of options in
             for Compensation time column when particular Employment Type value is selected
         How is it done:
             The function takes on Ember instance, handsOnTable instance and list of changes
             In our frontend schema component-library/addon/constants/z-spreadsheet-schema.js,
             I need to define afterChange key with two key-value pairs:
                 - dependent column name
                 - callback function
              For each change, I check if there is an afterChange defined in the schema on the column I just changed
             Then I check if the dependent column name is also in handsOnTable and if it is,
             I run a callback function defined in afterChange.
  	Example:
  		- When I select Full-Time as employmentType, Compensation option list will narrow to
  		Salary and Hourly compensation options only.
  		- I remove the value from the Compensation Type cell if there is a mismatch with Employment Type
  */
		_triggerCellDependencyAfterChange: function _triggerCellDependencyAfterChange(handsontableInstance, emberInstance, changes) {
			emberInstance.get('schema').forEach(function (columnSchema) {
				if (columnSchema.afterChange) {
					var columnName = columnSchema.colHeader;
					var columnHeaders = handsontableInstance.getColHeader();
					var colIndex = columnHeaders.indexOf(columnName);
					if (columnSchema.afterChange) {
						var dependentColumnName = columnSchema.afterChange.dependentColumn;
						var dependentColumnIndex = columnHeaders.indexOf(dependentColumnName);
						if (dependentColumnIndex === -1) {
							dependentColumnIndex = columnHeaders.indexOf(dependentColumnName + " (optional)");
						}
						if (dependentColumnIndex > 0) {
							columnSchema.afterChange.callback.call(handsontableInstance, changes, colIndex, dependentColumnIndex);
						}
					}
				}
			});
		},

		_eventHandlers: (function () {
			var _this5 = this;

			var emberInstance = this;
			var defaultHandlers = {
				beforeChange: function beforeChange(changes, source) {
					_this5._parseCells(changes);
				},

				afterChange: function afterChange(changes, source) {
					emberInstance.get('schema');
					console.log(changes);
					console.log(source);
					if (!changes) {
						return;
					}

					var hotInstance = emberInstance.get('hotInstance');

					if (source !== 'loadData') {
						emberInstance._resetEmptyRows(changes);
					}

					if (source === 'paste') {
						// If change set came from copy-paste, validations get run before
						// the beforeChange hook fires to parse the value, so we'll need to
						// re-validate on the parsed and reformatted values.
						hotInstance.validateCells(function () {
							hotInstance.render();
							emberInstance._sendAfterChangeAction(changes, source);
						});
					}

					hotInstance.render();
					emberInstance._sendAfterChangeAction(changes, source);

					var handsontableInstance = this;
					emberInstance._triggerCellDependencyAfterChange(handsontableInstance, emberInstance, changes);
					// Reset saveButtonText after data change
					emberInstance.set('saveButtonText', emberInstance.get('initialSaveButtonText'));
				},

				beforeValidate: function beforeValidate(value, row, prop, source) {
					var parser = _this5._getParserForCol(prop);
					if (parser) {
						value = parser(value);
					}
					return value;
				},

				afterValidate: function afterValidate(isValid, value, row, col, source) {
					var hotInstance = _this5.get('hotInstance');
					var colSchema = _this5.get('schema')[col];
					var prop = colSchema.modelProp;
					var validator = colSchema && colSchema['validator'];
					var advanceValidator = colSchema && colSchema['advanceValidator'];
					var validationMessage = null;
					var record = undefined;
					var serverErrors = undefined;
					var cellMeta = undefined;

					if (_ember['default'].isBlank(value) && hotInstance.isEmptyRow(row)) {
						// Completely empty row -- mark as valid
						return true;
					}

					cellMeta = hotInstance.getCellMeta(row, col);
					record = _this5._getSourceRecordByRow(row);

					if (advanceValidator) {
						validator = advanceValidator(record);
					}

					// Don't run validations on readOnly cells
					if (!_this5.get('validateReadOnlyCells') && cellMeta.readOnly) {
						validationMessage = null; //Treat it like no errors (at least for client-side validations)
					} else {
							// Run client-side validations
							if (typeof validator == 'function') {
								validationMessage = validator(value);
							} else if (validator instanceof RegExp) {
								validationMessage = validator.test(value) ? null : colSchema['validationMessage'];
							}
						}

					isValid = validationMessage === null;

					// Client-side validation errors exist. These take precedence over server-side
					// errors.
					if (!isValid) {
						_this5._insertError(row, col, prop, validationMessage);
						return false;
					}

					serverErrors = record && record._errors || null;

					// If a user cleared all client-side validations and then edited a cell,
					// then just assume server-side validation is cleared (and let
					// the server catch it again on submit). Otherwise, on all other actions,
					// we want to see if any server-side validation errors for this cell exists
					// and include it as part of the errors list for this cell.
					// Note: Server-side errors will be shown for read-only cells, by design.
					if (source !== 'edit') {
						if (serverErrors && serverErrors[prop]) {
							validationMessage = serverErrors[prop];
							_this5._insertError(row, col, prop, validationMessage);
							delete serverErrors[prop];
							return false;
						}
					}

					// No client- or server-side errors. Clear this cell.
					_this5._removeError(row, col);
					if (serverErrors && serverErrors[prop]) {
						delete serverErrors[prop];
					}
					return true;
				},

				afterColumnSort: function afterColumnSort(column, order) {
					var hotInstance = _this5.get('hotInstance');
					hotInstance.validateCells();
				},

				afterGetColHeader: function afterGetColHeader(colIndex, th) {
					var schema = _this5.get('schema');
					var $headerIcon = _this5.$('.js-z-label-help--header').eq(colIndex);
					var helperText = schema[colIndex] && schema[colIndex]['helperText'];
					var actualTable = _this5.$('.js-handsOnTableTarget');
					if (th.firstChild && !_this5.$(th).find('.js-z-label-help--header').length && $headerIcon.length && helperText) {
						var $newtip = $headerIcon.clone(false);
						th.firstChild.appendChild($newtip.get(0));
						$newtip.bstooltip({
							title: helperText,
							container: actualTable,
							placement: 'bottom',
							html: true,
							template: '<div class="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
							viewport: {
								selector: actualTable,
								padding: 0
							}
						});
					}
				},

				// Handle re-validating once we refresh all data on the table
				// (but not on initial load).
				afterLoadData: function afterLoadData(isFirstTimeLoad) {
					if (!isFirstTimeLoad) {
						_this5.get('hotInstance').validateCells(function () {
							_this5.sendAction('afterChange', _ember['default'].Object.create({
								hasErrors: _this5.get('hasErrors'),
								errorCount: _this5.get('errorCount'),
								changes: []
							}), 'afterLoadData');
						});
					}
				}
			};

			return _ember['default'].$.extend({}, defaultHandlers, this.get('eventHandlers'));
		}).property(),

		_getSpreadsheetState: function _getSpreadsheetState() {
			var hotInstance = this.get('hotInstance');
			var numRows = hotInstance.countRows();
			var recordsList = [];
			var schema = this.get('schema');
			var numCols = schema.length;
			var createBlankRecordFunction = this.get('createBlankRecordFunction');

			for (var rowIndex = 0; rowIndex < numRows; rowIndex++) {
				var record = this._getSourceRecordByRow(rowIndex);
				var rowArr = hotInstance.getDataAtRow(rowIndex);

				// TODO: test deleting a row
				if (hotInstance.isEmptyRow(rowIndex) && !record) {
					continue;
				}

				record = record && _lodashLodash['default'].cloneDeep(record) || createBlankRecordFunction();
				var rowObj = this._convertArrayRowToObject(rowArr);

				var _iteratorNormalCompletion = true;
				var _didIteratorError = false;
				var _iteratorError = undefined;

				try {
					for (var _iterator = schema[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
						var colSchema = _step.value;

						var newRecord = colSchema.fromRow(rowObj, record);
						if (!newRecord) {
							newRecord = record;
						}
					}
				} catch (err) {
					_didIteratorError = true;
					_iteratorError = err;
				} finally {
					try {
						if (!_iteratorNormalCompletion && _iterator['return']) {
							_iterator['return']();
						}
					} finally {
						if (_didIteratorError) {
							throw _iteratorError;
						}
					}
				}

				recordsList.push(record);
			}

			return _ember['default'].Object.create({
				hasErrors: this.get('hasErrors'),
				errorCount: this.get('errorCount'),
				errors: this.get('tableErrors'),
				data: recordsList
				// Possibly add in the entire errors list into here later if needed.
			});
		},

		_convertArrayRowToObject: function _convertArrayRowToObject(rowSource) {
			var schema = this.get('schema');
			var objectRecord = {};

			schema.forEach(function (colData, i) {
				objectRecord[colData.modelProp] = rowSource[i];
			});

			return objectRecord;
		},

		_getConfig: function _getConfig() {
			var configs = _ember['default'].$.extend(this.get('defaultHandsOnTableOptions'), this._getCellReadOnlyFunction(), this.get('handsOnTableOptions'), {
				data: this.get('_spreadsheetStateData'),
				columns: this._getColumns(),
				colHeaders: this._getColHeaders()
			},

			// hooks for HandsOnTable events
			this.get('_eventHandlers'));
			return configs;
		},

		_customCellRenderer: function _customCellRenderer() {
			var Handsontable = window.Handsontable;
			var schema = this.get('schema');

			var originalBaseRenderer = Handsontable.renderers.BaseRenderer;
			Handsontable.renderers.registerRenderer('base', function (instance, td, row, col, prop, value, cellProperties) {

				originalBaseRenderer.apply(this, arguments);

				return td;
			});
		},

		_getCellReadOnlyFunction: function _getCellReadOnlyFunction() {
			var _this6 = this;

			var readOnlyFunction = this.get('readOnlyFunction');

			if (typeof readOnlyFunction !== 'function') {
				return {};
			}

			return {
				cells: function cells(row, col, prop) {
					var hotInstance = _this6.get('hotInstance');

					var sourceData = _this6.get('sourceData');
					var cellValue = undefined;

					if (hotInstance) {
						cellValue = hotInstance.getSourceDataAtCell(row, col);
					} else {
						cellValue = _this6.get('_spreadsheetStateData')[row];
					}

					return {
						readOnly: readOnlyFunction(row, col, prop, cellValue, sourceData, _this6.get('schema'))
					};
				}
			};
		},

		_attachScrollHandlers: function _attachScrollHandlers() {
			var _this7 = this;

			if (!this.get('showBanner')) {
				return;
			}

			var lastScrollTop = 0;
			var topNavHeight = _ember['default'].$('.js-z-navigation').height();

			var $banner = this.$('.js-z-spreadsheet-banner');
			var bannerTop = $banner.offset().top;

			// wrap the banner in a placeholder that preserves the vertical offset
			// of subsequent elements when banner becomes position fixed
			var $bannerWrapper = this.$('<div class="js-z-spreadsheet-banner-wrapper"/>');
			$bannerWrapper.height($banner.outerHeight());
			$banner.wrap($bannerWrapper);

			_ember['default'].$(window).on('scroll.' + this.get('guid'), function () {
				if (_this7.isDestroyed) {
					return;
				}

				var scrollTop = _ember['default'].$('body').scrollTop();
				var scrollDirection = scrollTop >= lastScrollTop ? 'down' : 'up';
				_this7.set('bannerScrollDirection', scrollDirection);
				lastScrollTop = scrollTop;
			});

			$banner.affix({
				offset: {
					top: function top() {
						return _this7.get('bannerScrollDirection') == 'up' ? bannerTop : bannerTop - topNavHeight;
					}
				}
			});
		},

		_detachScrollHandlers: function _detachScrollHandlers() {
			if (this.get('showBanner')) {
				_ember['default'].$(window).off('scroll.' + this.get('guid'));
			}
		},

		initHandsOnTableInstance: function initHandsOnTableInstance() {
			if (this.get('hotInstance') || !window.Handsontable || !this.get('isInDom')) {
				return; // only need to run this once
			}

			var $spinner = this.$('.js-loading');
			var $hot = this.$('.js-handsOnTableTarget');

			// we're not loading anymore
			$spinner.hide();

			// init the spreadsheet
			$hot.handsontable(this._getConfig());

			// store references
			this.set('hotElement', $hot);
			this.set('hotInstance', $hot.handsontable('getInstance'));

			this._attachScrollHandlers();
			this._customCellRenderer();

			var saveActionSelector = this.get('saveActionSelector');
			var submitActionSelector = this.get('submitActionSelector');

			if (saveActionSelector) {
				this._bindClickAction(saveActionSelector, 'save');
			}

			if (submitActionSelector) {
				this._bindClickAction(submitActionSelector, 'submit');
			}

			// HACK (vberteanu)
			// Without this the sheet lines are misaligned.
			$hot.handsontable('getInstance').render();

			// Make sure table width is correct
			var $tableTarget = this.$('.js-handsOnTableTarget');
			var tableTargetWidth = $tableTarget[0].offsetWidth;
			var actualTableWidth = $tableTarget.find('table')[0].offsetWidth;
			if (actualTableWidth > tableTargetWidth) {
				$tableTarget.width(actualTableWidth);
			}
		},

		// lazy-load HandsOnTable so that it does not need to be loaded
		// for other routes. Don't load if already loaded
		_loadHandsOnTableSource: function _loadHandsOnTableSource() {
			var _this8 = this;

			// TEMP HACK: Disable handsontable loading for unit tests that test the "wrappings"
			// around handsontable
			if (this.get('isUnitTestMode')) {
				return;
			}

			var lazyLoader = this.get('lazyLoader');
			if (lazyLoader) {
				lazyLoader.loadBundle('handsontable').then(function () {
					return _this8.initHandsOnTableInstance();
				});
			}
		},

		_bindClickAction: function _bindClickAction(selector, actionName) {
			_ember['default'].$(selector).on('click', this._scheduleAction.bind(this, actionName));
		},

		_unbindClickAction: function _unbindClickAction(selector, actionName) {
			_ember['default'].$(selector).off('click', this._scheduleAction.bind(this, actionName));
		},

		_scheduleAction: function _scheduleAction(actionName) {
			var _this9 = this;

			_ember['default'].run.scheduleOnce('actions', function () {
				_this9.send(actionName);
			});
		},

		didInsertElement: function didInsertElement() {
			this.set('isInDom', true);
			this.initHandsOnTableInstance();
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			this._detachScrollHandlers();
			this._removeAllCellErrorPopovers();
			this.set('isInDom', false);

			var saveActionSelector = this.get('saveActionSelector');
			var submitActionSelector = this.get('submitActionSelector');

			if (saveActionSelector) {
				this._unbindClickAction(saveActionSelector, 'save');
			}

			if (submitActionSelector) {
				this._unbindClickAction(submitActionSelector, 'submit');
			}
		},

		actions: {
			save: function save() {
				var spreadsheetState = this._getSpreadsheetState();

				if (!spreadsheetState) {
					return;
				}

				this.sendAction('save', spreadsheetState);
			},
			submit: function submit(laddaButton) {
				var _this10 = this;

				var hotInstance = this.get('hotInstance');

				if (!hotInstance) {
					return; //Users may be clicking the submit button before handsontable is loaded
				}

				// Re-validate all cells before submission. Invalid data that was initialized to some
				// invalid value wouldn't have gotten validated if its cell was untouched.
				hotInstance.validateCells(function () {
					// Get properly-formatted set of records to send back to the parent controller.
					var spreadsheetState = _this10._getSpreadsheetState();

					_this10.eventLogger.log('census_spreadsheet_submit', {
						flow: _this10.get('flow'),
						errorCount: _this10.get('errorCount'),
						errorCountsByProperty: _this10.getErrorsLogObject()
					});

					_this10.sendAction('submit', spreadsheetState, laddaButton);
				});
			},
			validateNext: function validateNext(laddaButton) {
				var _this11 = this;

				_ember['default'].run.next(this, function () {
					_this11.get('hotInstance').getActiveEditor().finishEditing();
					laddaButton.stop();
				});
			}
		},

		getErrorsLogObject: function getErrorsLogObject() {
			var errorsObject = {};
			var tableErrors = this.get('tableErrors');

			tableErrors.forEach(function (error) {
				var propName = error.get('prop');

				if (!errorsObject[propName]) {
					errorsObject[propName] = 1;
					return;
				}

				errorsObject[propName]++;
			});

			return errorsObject;
		}
	});
});