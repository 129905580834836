define('ui-infra/services/switches', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var SWITCHES_ENDPOINT = '/api/switches/';

  var getSwitchIdsByCompany = function getSwitchIdsByCompany(company) {
    return ['company:' + company.get('id')];
  };

  var getSwitchIdsByEmployee = function getSwitchIdsByEmployee(employee) {
    return ['company:' + employee.get('_data.company_id'), 'employee:' + employee.get('id')];
  };

  var Switches = _ember['default'].ObjectProxy.extend({
    eventLogger: _ember['default'].inject.service(),

    reset: function reset(switches) {
      this.set('_stack', [switches]);
      this._updateFromStack();
    },
    reload: function reload(ids) {
      var _this = this;

      return this._fetch(ids).then(function (switches) {
        _this._stackReplaceTop(switches);
        return switches;
      });
    },
    push: function push(ids) {
      var _this2 = this;

      return this._fetch(ids).then(function (switches) {
        _this2._stackPush(switches);
        return switches;
      });
    },
    pushEmployee: function pushEmployee(employee, additionalIds) {
      var ids = getSwitchIdsByEmployee(employee);
      if (additionalIds) {
        ids = ids.concat(additionalIds);
      }
      return this.push(ids);
    },
    pushCompany: function pushCompany(company, additionalIds) {
      var ids = getSwitchIdsByCompany(company);
      if (additionalIds) {
        ids = ids.concat(additionalIds);
      }
      return this.push(ids);
    },
    pop: function pop() {
      this._stackPop();
    },

    unknownProperty: function unknownProperty(key) {
      // HACK: this is a bit unconventional, but works great.
      // we would normally define the property as volatile so the accessor is consistently called
      // this will make sure we call it on the next loop
      this.propertyDidChange(key);
      var result = this._super.apply(this, arguments);

      if (typeof result !== 'undefined') {
        this.get('eventLogger').batchLog('check_switch_status', {
          switch_name: key,
          switch_is_active: result
        });
      }
      return result;
    },

    // Private members ----
    _stack: [],
    _stackPush: function _stackPush(switches) {
      this._stack.push(switches);
      this._updateFromStack();
    },
    _stackPop: function _stackPop() {
      // Probably safer not to pop the last element in a stack.
      if (this._stack.length > 1) {
        this._stack.pop();
        this._updateFromStack();
      }
    },
    _stackReplaceTop: function _stackReplaceTop(switches) {
      if (this._stack.length) {
        this._stack[this._stack.length - 1] = switches;
      } else {
        this._stack.push(switches);
      }
      this._updateFromStack();
    },
    _updateFromStack: function _updateFromStack() {
      // Sets the proxy content to whatever is on the top of the stack
      var peek = this._stack.length ? this._stack[this._stack.length - 1] : {};
      this.set('content', _ember['default'].Object.create(peek));
    },
    _fetch: function _fetch(ids) {
      var params = ids ? { 'id': ids } : {};
      return _ember['default'].Model.adapter.ajax(SWITCHES_ENDPOINT, params, 'GET').then(function (data) {
        return data['objects'][0];
      });
    }
  });

  Switches.reopenClass({
    isServiceFactory: true
  });

  exports['default'] = Switches;
});