define('ember-view-state/services/view-state-repository', ['exports', 'ember', 'ember-view-state/utils/local-storage'], function (exports, _ember, _emberViewStateUtilsLocalStorage) {
  'use strict';

  /***
   * The ViewStateRepository manages the data access to persist
   * properties for the ViewState Mixin and Service
   * We don't return promises to avoid async and delaying, UX
   * but we can expect that data to be "eventually consistent".
   */
  exports['default'] = _ember['default'].Service.extend({
    /***
     * Flushes in memory data to localStorage so it's persisted across browser sessions
     * and enqueues a server side save (if implemented)
     */
    flush: function flush() {
      var data = {
        viewState: this.get('_state'),
        lastUpdatedAt: new Date()
      };
      data = JSON.stringify(data);
      _emberViewStateUtilsLocalStorage['default'].setItem(this._getNamespace(), data);
      // We persist in both places, but delay the server side save
      this._enqueueServerSideSave();
    },

    /***
     * Loads data from from localStorage and the server and leaves it in memory
     */
    load: function load() {
      var _this = this;

      if (_ember['default'].isEmpty(this.get('_state'))) {
        var _ret = (function () {
          var localState = _emberViewStateUtilsLocalStorage['default'].getItem(_this._getNamespace());
          localState = localState ? JSON.parse(localState) : {};
          localState.viewState = localState.viewState || {};
          _this.set('_state', localState.viewState);

          return {
            v: _this._loadServerViewState().then(function (serverState) {
              return _this._mergeViewState(localState, serverState);
            })
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
    },

    getViewStateFor: function getViewStateFor(key) {
      this.load();
      var state = this.get('_state');
      var viewStateForKey = state[key] || {};
      state[key] = viewStateForKey;
      return viewStateForKey;
    },

    _getNamespace: function _getNamespace() {
      // NOTE: document that we can allow the user to override this so each user
      // can have a different namespace without overriding each other.
      // return 'ViewState.' + session.get('userGUID');
      return 'ViewState';
    },

    _serverSideSaveDelay: 5 * 60 * 1000, // 5 minutes
    _loadServerViewState: function _loadServerViewState() {
      // TODO: let consumers implement this add an example using firebase
      // return ajax(url);
      return _ember['default'].RSVP.resolve();
    },

    /**
     * Merges the ViewState stored locally with the server side ViewState
     * giving ViewState to the newer ones
     * @param  {Object} localViewState
     * @param  {Object} serverViewState
     */
    _mergeViewState: function _mergeViewState(localViewState, serverViewState) {
      if (!serverViewState) {
        return;
      }
      var newLocalState = !localViewState.lastUpdatedAt;
      if (localViewState.lastUpdatedAt === serverViewState.lastUpdatedAt) {
        // They're the same, nothing to do here.
        return;
      }
      localViewState.viewState = localViewState.viewState || {};
      serverViewState.viewState = serverViewState.viewState || {};
      var local = localViewState.viewState;
      var server = serverViewState.viewState;

      var allKeys = Object.keys(local).concat(Object.keys(server));
      allKeys.forEach(function (key) {
        var localValue = local[key];
        var serverValue = server[key];
        var serverLastUpdatedAt = _ember['default'].getWithDefault(serverValue || {}, 'lastUpdatedAt', serverViewState.lastUpdatedAt);
        var localLastUpdatedAt = _ember['default'].getWithDefault(localValue || {}, 'lastUpdatedAt', localViewState.lastUpdatedAt);

        if (!localValue || serverLastUpdatedAt > localLastUpdatedAt) {
          if (serverValue) {
            _ember['default'].set(local, key, serverValue);
          }
        }
      });

      if (newLocalState || serverViewState.lastUpdatedAt > localViewState.lastUpdatedAt) {
        localViewState.lastUpdatedAt = serverViewState.lastUpdatedAt;
      }
    },

    _enqueueServerSideSave: function _enqueueServerSideSave() {
      var _this2 = this;

      var immediate = false; // trigger on the tail of the wait time interval as opposed as the default of leading
      _ember['default'].run.throttle(this, function () {
        var data = _emberViewStateUtilsLocalStorage['default'].getItem(_this2._getNamespace());
        _this2._persistViewStateServerSide(data);
      }, this._serverSideSaveDelay, immediate);
    },

    _persistViewStateServerSide: function _persistViewStateServerSide() /*data*/{
      // return ajax(url, 'POST', data);
      return _ember['default'].RSVP.resolve();
    }
  });
});