define('component-library/components/contributions-modal/components/contribution-types', ['exports', 'ember', 'component-library/components/contributions-modal/constants', 'component-library/components/contributions-modal/utils'], function (exports, _ember, _componentLibraryComponentsContributionsModalConstants, _componentLibraryComponentsContributionsModalUtils) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		isComponentEnabled: true,
		capMaxValue: null,
		isComponentDisabled: _ember['default'].computed.equal('isComponentEnabled', false),
		selectedType: _ember['default'].computed.alias('contributionSelectionTab.selectedType'),
		data: _ember['default'].computed('contributionSelectionTab', 'selectedType.id', function () {
			var contributionSelection = this.get('contributionSelectionTab.data');
			var selectionType = this.get('selectedType.id');
			if (selectionType in contributionSelection) {
				return contributionSelection[selectionType];
			}
			this.set('contributionSelectionTab.data.' + selectionType, _componentLibraryComponentsContributionsModalUtils['default'].allTypes(this.get('plans'), this.get('coverageSelection.selectedLoc.id'))[selectionType]);
			return this.get('contributionSelectionTab.data.' + selectionType);
		}),
		hasPercentageAttr: _ember['default'].computed('data', function () {
			return _componentLibraryComponentsContributionsModalConstants.RULE_DATA_ATTRS.PERCENTAGE in this.get('data');
		}),
		hasFixedAmountAttr: _ember['default'].computed('data', function () {
			return _componentLibraryComponentsContributionsModalConstants.RULE_DATA_ATTRS.FIXED_AMOUNT in this.get('data');
		}),
		hasCapMaxAttr: _ember['default'].computed('data', function () {
			return _componentLibraryComponentsContributionsModalConstants.RULE_DATA_ATTRS.CAP in this.get('data');
		}),
		planIdObserver: _ember['default'].observer('contributionSelectionTab.selectedType', function () {
			if (this.get('contributionSelectionTab.selectedType.id') == _componentLibraryComponentsContributionsModalConstants.PERCENTAGE_OF_PLAN_RAW) {
				this.set('data.planId', this.get('contributionSelectionTab.selectedType.id'));
				this.set('data.lineOfCoverage', this.get('coverageSelection.selectedLoc.id'));
			}
		}),
		capMaxObserver: _ember['default'].observer('capMaxValue', function () {
			// if we write a number and removed it again the default value changes to empty string, this to ensure it's null
			this.set('data.cap', this.get('capMaxValue') || null);
		})
	});
});