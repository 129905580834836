define('component-library/components/progress-meter', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-progressMeter', 'u-padVertical'],
		classNameBindings: ['isProgressFull:z-progressMeter--full'],
		title: '',
		total: 0,
		progress: 0,
		dueDate: '',
		dueDateFormat: _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT,
		textCompleted: '',
		textLeft: '',

		isProgressFull: _ember['default'].computed('total', 'progress', function () {
			return this.get('progress') === this.get('total');
		}),
		barWidth: _ember['default'].computed('total', 'progress', function () {
			return Math.ceil(parseFloat(this.get('progress') || 0) / parseFloat(this.get('total') || 1) * 100);
		}),
		totalTextWidth: _ember['default'].computed('barWidth', function () {
			return Math.max(15, Math.min(82, this.get('barWidth') - 2));
		}),
		leftTextWidth: _ember['default'].computed('barWidth', function () {
			return Math.max(15, Math.min(82, 100 - this.get('barWidth') - 1));
		}),

		momentDueDate: _ember['default'].computed('dueDate', 'dueDateFormat', function () {
			return (0, _componentLibraryUtilsGlobals.moment)(this.get('dueDate'), this.get('dueDateFormat'));
		}),

		daysLeft: _ember['default'].computed('momentDueDate', function () {
			// Round down to date itself (e.g. Days between 1/1/2017 at 5pm and
			// 1/2/2017 12pm should still be 1 day even though it's not technically a full day apart)
			return this.get('momentDueDate').diff((0, _componentLibraryUtilsGlobals.moment)().format('YYYY-MM-DD'), 'days');
		}),

		expireDateStr: _ember['default'].computed('momentDueDate', function () {
			return this.get('momentDueDate').format('MMMM Do');
		}),

		dueDatePassed: _ember['default'].computed('momentDueDate', function () {
			return this.get('momentDueDate').format('YYYY-MM-DD') < (0, _componentLibraryUtilsGlobals.moment)().format('YYYY-MM-DD');
		})
	});
});