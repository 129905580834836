define('component-library/modals/image-crop/image-crop-modal', ['exports', 'ember', 'component-library/components/promise-modal', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryComponentsPromiseModal, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend((0, _componentLibraryUtilsGlobals.getFilePickerMixin)(), {
		templateName: 'modals/image-crop/image-crop-modal',
		classNames: ['z-image-crop-modal', 'z-typography'],

		// we need to pass in the filepicker object because the `filepicker.convert` API requires a
		// FP object or FP URL.  The URL returned in the success callback does not pass that validation,
		// so we pass the FP object directly.
		fpFile: null,
		photoUrl: _ember['default'].computed('fpFile', function () {
			return this.get('fpFile.url');
		}),
		fileUploaded: false,

		validate: function validate() {
			var isValid = true;
			this.set('profilePicEditError', '');

			if (_ember['default'].isEmpty(this.get('fileUploaded'))) {
				isValid = false;
				this.set('profilePicEditError', 'Haven\'t successfully uploaded pic yet');
			}
			return isValid;
		},

		actions: {
			apply: function apply(laddaButton) {
				var _this = this;

				if (!this.validate()) {
					laddaButton.stop();
					return;
				}

				var fpFile = this.get('fpFile');
				_componentLibraryUtilsGlobals.filepicker.convert(fpFile, {
					crop: [this.get('x'), this.get('y'), this.get('w'), this.get('h')],
					rotate: "exif"
				}, { location: "S3" }, function (FPFile) {
					_componentLibraryUtilsGlobals.filepicker.convert(FPFile, { height: 256, width: 256, fit: 'crop', align: 'faces' }, { location: "S3" }, function (thumbnailFPFile) {
						_this.send('confirm', { photoUrl: FPFile.url, photoThumbnailUrl: thumbnailFPFile.url });
					});
				}, function (FPError) {
					console.log(FPError.toString());
				});
			}
		}
	});
});