define('component-library/components/tabbed-modal/sidebar-item', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: 'z-tabbedModal-sidebarItem',
		classNameBindings: ['isSelected:z-tabbedModal-sidebarItem--selected', 'showCheckMark:z-tabbedModal-sidebarItem--check', 'number:z-tabbedModal-sidebarItem--number', 'showExclamationSign:z-tabbedModal-sidebarItem--exclamation', 'isClickable:z-tabbedModal-sidebarItem--clickable'],

		showCheckMark: false,
		showExclamationSign: false,
		isSelected: false,
		number: '',
		text: '',

		click: function click() {
			this.attrs.onClickAction();
		}
	});
});