define('component-library/components/validation-error-wrapper', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		validationErrorComponentWrapper: undefined,

		relevantValidationErrors: _ember['default'].computed.alias('validationErrorComponentWrapper.relevantValidationErrors'),

		setupValidationErrorsProperty: (function () {
			var validationErrorComponentWrapper = this.nearestWithProperty('isValidationErrorComponentWrapper');
			this.set('validationErrorComponentWrapper', validationErrorComponentWrapper);
		}).on('init')
	});
});