define('component-library/components/benefits/timeline/transaction-detail', ['exports', 'ember', 'component-library/templates/components/benefits/timeline/transaction-detail', 'component-library/utils/globals', 'component-library/utils/benefits/timeline/computed', 'component-library/mixins/benefits/timeline/transaction-fulfillment-data-mixin'], function (exports, _ember, _componentLibraryTemplatesComponentsBenefitsTimelineTransactionDetail, _componentLibraryUtilsGlobals, _componentLibraryUtilsBenefitsTimelineComputed, _componentLibraryMixinsBenefitsTimelineTransactionFulfillmentDataMixin) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsBenefitsTimelineTransactionFulfillmentDataMixin['default'], {
		template: _componentLibraryTemplatesComponentsBenefitsTimelineTransactionDetail['default'],
		classNames: ['u-flexContainer'],

		store: _ember['default'].inject.service(),

		employmentType: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.employment.employmentType'),
		hireDate: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.employment.hireDate'),

		employeeType: _ember['default'].computed('employmentType', function () {
			var type = _ember['default'].get(this, 'employmentType');
			var mapper = {
				'FT': 'Full time employee',
				'PT': 'Part time employee',
				'TE': 'Temporary employee'
			};
			return type ? _ember['default'].get(mapper, type) : null;
		}),

		momentOfHireDate: _ember['default'].computed('hireDate', function () {
			var hireDate = _ember['default'].get(this, 'hireDate');
			return hireDate ? (0, _componentLibraryUtilsGlobals.moment)(hireDate, _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT) : null;
		}),

		prettyHireDate: _ember['default'].computed('momentOfHireDate', function () {
			var date = _ember['default'].get(this, 'momentOfHireDate');
			return date ? date.format('MMM D, YYYY') : null;
		}),

		isInsuranceTransaction: _ember['default'].computed('transaction.benefitsApp', function () {
			var app = this.get('transaction.benefitsApp');
			return app == 'MDV' || app == 'LIFE';
		}),

		// By default, we show summary
		_showSummary: true,
		_showCostBreakdown: false,
		_showEnrollmentInfo: false,
		_showFulfillmentInfoDetails: false,

		hasBenefitsContext: _ember['default'].computed.notEmpty('transaction.benefitsContext'),

		showSummary: _ember['default'].computed('_showSummary', 'hasBenefitsContext', function () {
			return _ember['default'].get(this, '_showSummary') && _ember['default'].get(this, 'hasBenefitsContext');
		}),
		showCostBreakdown: _ember['default'].computed('_showCostBreakdown', 'hasBenefitsContext', function () {
			return _ember['default'].get(this, '_showCostBreakdown') || !_ember['default'].get(this, 'hasBenefitsContext');
		}),
		showEnrollmentInfo: _ember['default'].computed('_showEnrollmentInfo', 'hasBenefitsContext', function () {
			return _ember['default'].get(this, '_showEnrollmentInfo') && _ember['default'].get(this, 'hasBenefitsContext');
		}),
		showFulfillmentInfoDetails: _ember['default'].computed('_showFulfillmentInfoDetails', 'hasFulfillmentInfoDetails', function () {
			return this.get('_showFulfillmentInfoDetails') && this.get('hasFulfillmentInfoDetails');
		}),

		employee: (0, _componentLibraryUtilsBenefitsTimelineComputed.computedForEmployeePromiseObject)('transaction.employeeId'),

		actions: {

			showSummary: function showSummary() {
				this.setProperties({
					_showCostBreakdown: false,
					_showEnrollmentInfo: false,
					_showSummary: true,
					_showFulfillmentInfoDetails: false
				});
			},

			showEnrollmentInfo: function showEnrollmentInfo() {
				this.setProperties({
					_showCostBreakdown: false,
					_showEnrollmentInfo: true,
					_showSummary: false,
					_showFulfillmentInfoDetails: false
				});
			},

			showCostBreakdown: function showCostBreakdown() {
				this.setProperties({
					_showCostBreakdown: true,
					_showEnrollmentInfo: false,
					_showSummary: false,
					_showFulfillmentInfoDetails: false
				});
			},

			showFulfillmentInfoDetails: function showFulfillmentInfoDetails() {
				this.setProperties({
					_showCostBreakdown: false,
					_showEnrollmentInfo: false,
					_showSummary: false,
					_showFulfillmentInfoDetails: true
				});
			}
		}
	});
});