define('component-library/instance-initializers/action-logger', ['exports', 'ember'], function (exports, _ember) {
	// TODO: move to ui-infra
	'use strict';

	// set of actions that should be batch logged
	var BATCH_LOGGED_ACTIONS = {
		showModal: true,
		hideModal: true,
		getHelpCenterLinks: true
	};

	exports['default'] = {

		name: 'action-logger',
		after: 'logger-initializer',
		initialize: function initialize(appInstance) {
			var TrackedActionMixin = _ember['default'].Mixin.create({
				send: function send(actionName) {
					var logger = this.container && this.container.lookup('service:event-logger');
					this._super.apply(this, arguments);
					if (!logger) {
						return;
					}

					if (BATCH_LOGGED_ACTIONS[actionName]) {
						logger.batchLog(actionName, {}, 'ember_action');
					} else {
						logger.log(actionName, {}, 'ember_action');
					}
				}
			});

			_ember['default'].Controller.reopen(TrackedActionMixin);
			_ember['default'].ObjectController.reopen(TrackedActionMixin);
			_ember['default'].ArrayController.reopen(TrackedActionMixin);
			_ember['default'].Route.reopen(TrackedActionMixin);
			_ember['default'].View.reopen(TrackedActionMixin);
		}
	};
});