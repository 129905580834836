define('component-library/modals/payroll/sign-form', ['exports', 'ember', 'component-library/components/promise-modal', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryComponentsPromiseModal, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'modals/payroll/sign-form',

		didInsertElement: function didInsertElement() {
			this._super();
			this.pad = this.$('.sigPad').signaturePad({
				"drawOnly": true,
				"bgColour": "transparent"
			});
		},

		validate: function validate() {
			var isValid = true;
			if (!this.pad.validateSignature()) {
				isValid = false;
				this.set("signatureError", "The signature is too small. Please try again.");
				this.pad.clearCanvas();
			}
			var name = this.get('name');
			if (_ember['default'].isEmpty(name)) {
				isValid = false;
				this.set("signatureNameError", "The required field is missing.");
			}
			return isValid;
		},

		actions: {
			apply: function apply(laddaButton) {
				var _this = this;

				if (!this.validate()) {
					laddaButton.stop();
					return;
				}
				var name = this.get('name');
				var form = this.get('form');
				form.setProperties({
					signatureName: name,
					signature: this.pad.getSignatureImage(),
					signatureDate: (0, _componentLibraryUtilsGlobals.moment)().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT)
				});
				form.save().then(function () {
					_this.send('confirm');
				});
			}
		}

	});
});