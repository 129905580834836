define('component-library/mixins/payroll/pay-schedule-validations-mixin', ['exports', 'ember', 'component-library/constants/cps'], function (exports, _ember, _componentLibraryConstantsCps) {
	// This is a helper functions for Pay schedule PYP validations
	'use strict';

	function missing(compType) {
		return (function () {
			return !this.get('paySchedulesCompType').contains('Both') && !this.get('paySchedulesCompType').contains(compType);
		}).property('paySchedulesCompType', 'paySchedules.@each.compType');
	}

	exports['default'] = _ember['default'].Mixin.create({
		// employee Validations
		_filterEmployees: function _filterEmployees(employee) {
			if (employee.get('Status') == 'Ter' || employee.get('Status') == 'Del' || employee.get('hasMinimalFields') === false || employee.get('isInternational')) {
				return false;
			}
			var isEmployee = employee.get('isEmployee');
			var isStatusValid = employee.get('isActive') || employee.get('isSettingUp');
			return isEmployee && isStatusValid;
		},

		validEmployees: (function () {
			return this.get('employees').filter(this._filterEmployees);
		}).property('employees.@each.isActive'),

		eeCompTypes: (function () {
			return this.get('validEmployees').mapBy('compTypeHuman').uniq();
		}).property('validEmployees'),

		// PYP-CPS/validations
		needsHourlyPaySchedule: missing('Hourly'),
		needsSalariedPaySchedule: missing('Salaried'),

		paySchedulesCompType: _ember['default'].computed.mapBy('paySchedules', 'compTypeHuman'),
		missingPaySchedule: (function () {
			if (this.get('needsHourlyPaySchedule') == this.get('needsSalariedPaySchedule')) {
				return;
			}
			return this.get('needsHourlyPaySchedule') ? 'Hourly' : 'Salaried';
		}).property('needsHourlyPaySchedule', 'needsSalariedPaySchedule'),

		needsAdditionalPaySchedule: _ember['default'].computed.or('needsHourlyPaySchedule', 'needsSalariedPaySchedule')
	});
});