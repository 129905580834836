define('component-library/components/contributions-modal/modal', ['exports', 'ember', 'component-library/components/tabbed-modal/tabbed-modal'], function (exports, _ember, _componentLibraryComponentsTabbedModalTabbedModal) {
	'use strict';

	var _Ember$Object$create, _Ember$Object$create2;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
		} else {
			obj[key] = value;
		}return obj;
	}

	var TABS = _ember['default'].Object.create({
		TAB_1: 'tab_1',
		TAB_2: 'tab_2',
		TAB_3: 'tab_3'
	});

	var TAB_TEXT = _ember['default'].Object.create((_Ember$Object$create = {}, _defineProperty(_Ember$Object$create, TABS.TAB_1, 'Details'), _defineProperty(_Ember$Object$create, TABS.TAB_2, 'Eligibility'), _defineProperty(_Ember$Object$create, TABS.TAB_3, 'Contributions'), _Ember$Object$create));

	var TAB_TITLES = _ember['default'].Object.create((_Ember$Object$create2 = {}, _defineProperty(_Ember$Object$create2, TABS.TAB_1, 'Coverage Details'), _defineProperty(_Ember$Object$create2, TABS.TAB_2, 'Employee Eligibility'), _defineProperty(_Ember$Object$create2, TABS.TAB_3, 'Company Contributions'), _Ember$Object$create2));

	exports['default'] = _componentLibraryComponentsTabbedModalTabbedModal['default'].extend({
		TABS: TABS,
		TAB_TEXT: _ember['default'].computed('isDeductionsEnabled', function () {
			return TAB_TEXT;
		}),
		TAB_TITLES: _ember['default'].computed('isDeductionsEnabled', function () {
			return TAB_TITLES;
		}),
		changeContributionDeductionTabObserver: _ember['default'].on('init', _ember['default'].observer('isDeductionsEnabled', function () {
			if (this.get('isDeductionsEnabled')) {
				TAB_TEXT.set([TABS.TAB_3], 'Deductions');
				TAB_TITLES.set([TABS.TAB_3], 'Employee Deductions');
			} else {
				TAB_TEXT.set([TABS.TAB_3], 'Contributions');
				TAB_TITLES.set([TABS.TAB_3], 'Company Contributions');
			}
		})),
		size: 'large',
		init: function init() {
			this._super.apply(this, arguments);
		},
		onFinish: function onFinish() {
			var _this = this;

			return _ember['default'].RSVP.Promise.resolve().then(function () {
				return _this.attrs.onSaveAction();
			});
		}
	});
});