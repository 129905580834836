define('component-library/controllers/detail', ['exports', 'ember', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryComponentsPromiseModal) {
	'use strict';

	exports['default'] = _ember['default'].Controller.extend({
		queryParams: [{
			isEditing: 'is_editing'
		}],

		editWrapper: _ember['default'].computed(function () {
			return _ember['default'].Object.createWithMixins({
				isEditing: false,
				isNotEditing: _ember['default'].computed.not('isEditing')
			});
		}).property(),

		isEditing: _ember['default'].computed.alias('editWrapper.isEditing'),
		isNotEditing: _ember['default'].computed.alias('editWrapper.isNotEditing'),

		doCancel: function doCancel() {
			var model = this.get('model');

			model.rollback();
			model.reload();
			Object.keys(model._data).forEach(function (key) {
				var oldVal = model._data[key];

				if (_ember['default'].isArray(oldVal) && _ember['default'].isEmpty(oldVal)) {
					model.get(key).clear();
				}
			});

			this.set('isEditing', false);
		},

		doSave: function doSave() {
			var controller = this;

			this.get('model').save().then(function () {
				controller.set('isEditing', false);
			});
		},

		actions: {
			edit: function edit() {
				this.set('isEditing', true);
			},

			cancel: function cancel() {
				this.doCancel();
			},

			'delete': function _delete() {
				var controller = this;

				_componentLibraryComponentsPromiseModal['default'].show({
					templateName: 'confirm-delete'
				}).then(function () {
					var factorSet = controller.get('model');

					factorSet.destroyRecord();
					controller.send('objectDeleted', factorSet);
				});
			},

			save: function save() {
				this.doSave();
			}
		}
	});
});