define('component-library/utils/traditional-query-converter', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	// take a query object from FilterArray.query and convert it to a query object
	// that is compatible with this.store.find

	exports['default'] = function (evilQuery) {
		var traditionalParams = _ember['default'].$.param(evilQuery, true);
		if (!traditionalParams.length) {
			return {};
		}
		var goodNest = traditionalParams.split('&').map(function (p) {
			return p.split('=');
		});
		var goodQuery = {};
		goodNest.forEach(function (p) {
			var key = decodeURIComponent(p[0]);
			var val = decodeURIComponent(p[1]);
			if (key && val) {
				goodQuery[key] = val;
			}
		});
		return goodQuery;
	};
});