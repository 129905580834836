define('component-library/attribute-types/american-date-moment', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = {
		deserialize: function deserialize(serialized) {
			if (_ember['default'].isEmpty(serialized)) {
				return null;
			}
			return (0, _componentLibraryUtilsGlobals.moment)(serialized, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		},
		serialize: function serialize(deserialized) {
			if (_ember['default'].isEmpty(deserialized)) {
				return null;
			}
			return deserialized.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		},
		isEqual: function isEqual(obj1, obj2) {
			return _componentLibraryUtilsGlobals.moment.isMoment(obj1) && _componentLibraryUtilsGlobals.moment.isMoment(obj2) && obj1.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT) === obj2.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		}
	};
});