define('component-library/wizard/settings-setup', ['exports', 'ember', 'component-library/wizard/flow/controller', 'component-library/wizard/flow/route', 'component-library/wizard/flow/index/route', 'component-library/wizard/flow/section/controller', 'component-library/wizard/flow/section/route', 'component-library/wizard/flow/section/index/route', 'component-library/wizard/flow/section/step/controller', 'component-library/wizard/flow/section/step/route'], function (exports, _ember, _componentLibraryWizardFlowController, _componentLibraryWizardFlowRoute, _componentLibraryWizardFlowIndexRoute, _componentLibraryWizardFlowSectionController, _componentLibraryWizardFlowSectionRoute, _componentLibraryWizardFlowSectionIndexRoute, _componentLibraryWizardFlowSectionStepController, _componentLibraryWizardFlowSectionStepRoute) {
	'use strict';

	var SETTINGS_SETUP_MODES = {
		SETTINGS: 'settings',
		SETUP: 'setup'
	};

	exports.SETTINGS_SETUP_MODES = SETTINGS_SETUP_MODES;

	var identifyMode = function identifyMode(mode) {
		if (Object.values(SETTINGS_SETUP_MODES).includes(mode)) {
			return mode;
		}
	};

	var SettingsSetupWizardFlowRoute = _componentLibraryWizardFlowRoute['default'].extend({
		wizardService: _ember['default'].computed.alias('settingsSetupWizardService'),
		settingsSetupWizardService: null,
		serviceEnabled: true,
		routeModeKey: 'setup_or_settings',

		beforeModel: function beforeModel(transition) {
			var settingsSetupWizardService = this.get('settingsSetupWizardService');
			var mode = this.paramsFor(this.routeName)[this.get('routeModeKey')] || SETTINGS_SETUP_MODES.SETUP;

			var settingsSetupMode = identifyMode(mode);
			if (!settingsSetupMode) {
				transition.abort();
			} else {
				settingsSetupWizardService.set('settingsSetupWizardMode', settingsSetupMode);
				return this._super.apply(this, arguments);
			}
		}
	});

	exports.SettingsSetupWizardFlowRoute = SettingsSetupWizardFlowRoute;

	var SettingsSetupWizardFlowController = _componentLibraryWizardFlowController['default'].extend({
		wizardService: _ember['default'].computed.alias('settingsSetupWizardService'),
		settingsSetupWizardService: null,
		serviceEnabled: true,
		wizardFlowState: _ember['default'].computed.alias('settingsSetupWizardService.wizardFlowState'),
		computedWizardFlowState: _ember['default'].computed.alias('settingsSetupWizardService.computedWizardFlowState')
	});

	exports.SettingsSetupWizardFlowController = SettingsSetupWizardFlowController;

	var SettingsSetupWizardFlowIndexRoute = _componentLibraryWizardFlowIndexRoute['default'].extend({
		wizardService: _ember['default'].computed.alias('settingsSetupWizardService'),
		settingsSetupWizardService: null,
		serviceEnabled: true,

		model: function model() {
			// return a no-op promise
			return _ember['default'].RSVP.resolve();
		}
	});

	exports.SettingsSetupWizardFlowIndexRoute = SettingsSetupWizardFlowIndexRoute;

	var SettingsSetupWizardSectionRoute = _componentLibraryWizardFlowSectionRoute['default'].extend({
		wizardService: _ember['default'].computed.alias('settingsSetupWizardService'),
		settingsSetupWizardService: null,
		serviceEnabled: true
	});

	exports.SettingsSetupWizardSectionRoute = SettingsSetupWizardSectionRoute;

	var SettingsSetupWizardSectionController = _componentLibraryWizardFlowSectionController['default'].extend({
		wizardService: _ember['default'].computed.alias('settingsSetupWizardService'),
		settingsSetupWizardService: null,
		serviceEnabled: true
	});

	exports.SettingsSetupWizardSectionController = SettingsSetupWizardSectionController;

	var SettingsSetupWizardSectionIndexRoute = _componentLibraryWizardFlowSectionIndexRoute['default'].extend({
		wizardService: _ember['default'].computed.alias('settingsSetupWizardService'),
		settingsSetupWizardService: null,
		serviceEnabled: true
	});

	exports.SettingsSetupWizardSectionIndexRoute = SettingsSetupWizardSectionIndexRoute;

	var SettingsSetupWizardStepRoute = _componentLibraryWizardFlowSectionStepRoute['default'].extend({
		wizardService: _ember['default'].computed.alias('settingsSetupWizardService'),
		settingsSetupWizardService: null,
		serviceEnabled: true
	});

	exports.SettingsSetupWizardStepRoute = SettingsSetupWizardStepRoute;

	var SettingsSetupWizardStepController = _componentLibraryWizardFlowSectionStepController['default'].extend({
		wizardService: _ember['default'].computed.alias('settingsSetupWizardService'),
		settingsSetupWizardService: null,
		serviceEnabled: true
	});
	exports.SettingsSetupWizardStepController = SettingsSetupWizardStepController;
});