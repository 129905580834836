define('model-library/models/company-payment-method-abstract', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	var attr = _emberData['default'].attr;

	exports['default'] = _emberData['default'].Model.extend({
		zuoraId: attr('string'),
		type: attr('string'),
		holderName: attr('string'),
		maskedNumber: attr('string'),
		isActive: attr('boolean'),

		last4: (function () {
			return this.get('maskedNumber').slice(-4);
		}).property('maskedNumber')
	});
});