define('component-library/components/animated-checkbox', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['animatedCheckbox'],
		classNameBindings: ['checked:is-checked'],

		checkboxId: (function () {
			return this.get('elementId') + '-animatedCheckbox';
		}).property('elementId')
	});
});