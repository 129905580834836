define('component-library/utils/benefits/timeline/computed', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	var computedForFullName = function computedForFullName(firstNameProperty, lastNameProperty) {
		return _ember['default'].computed(firstNameProperty, lastNameProperty, function () {
			var firstName = (this.get(firstNameProperty) || '').capitalize();
			var lastName = (this.get(lastNameProperty) || '').capitalize();
			if (firstName || lastName) {
				return firstName + ' ' + lastName;
			} else if (firstName) {
				return firstName;
			} else if (lastName) {
				return lastName;
			} else {
				return null;
			}
		});
	};

	exports.computedForFullName = computedForFullName;

	var computedForEffectiveDateDisplay = function computedForEffectiveDateDisplay(effectiveDateProperty) {
		return _ember['default'].computed(effectiveDateProperty, {
			get: function get(key) {
				var date = this.get(effectiveDateProperty);
				return _ember['default'].isEmpty(date) ? null : date.format('MM/DD/YYYY');
			},

			set: function set(key, value) {
				this.set(effectiveDateProperty, _ember['default'].isEmpty(value) ? null : (0, _componentLibraryUtilsGlobals.moment)(value, 'MM/DD/YYYY'));
				return value;
			}
		});
	};

	exports.computedForEffectiveDateDisplay = computedForEffectiveDateDisplay;

	var computedForEmployeePromiseObject = function computedForEmployeePromiseObject(employeeIdProperty) {
		return _ember['default'].computed(employeeIdProperty, function () {
			var employeeId = this.get(employeeIdProperty);
			if (_ember['default'].isEmpty(employeeId)) {
				return null;
			} else {
				var employeePromise = this.get('store').modelFor('filterEmployee').find(employeeId);
				return _ember['default'].ObjectProxy.extend(_ember['default'].PromiseProxyMixin).create({
					promise: employeePromise
				});
			}
		});
	};

	exports.computedForEmployeePromiseObject = computedForEmployeePromiseObject;

	var computedArrayForChangeModel = function computedArrayForChangeModel(changeModel, keyPath, currentProperty, addedProperty, removedProperty, modifiedProperty) {
		return _ember['default'].computed(currentProperty, addedProperty, removedProperty, modifiedProperty, function () {
			var current = this.get(currentProperty) || [];
			var added = this.get(addedProperty) || [];
			var removed = this.get(removedProperty) || [];
			var modifications = this.get(modifiedProperty) || {};
			var additions = added.map(function (item) {
				return _ember['default'].get(item, keyPath);
			});
			return [].concat(
			// removed
			removed.map(function (item) {
				return changeModel.create({
					old: item,
					'new': null,
					change: null
				});
			})).concat(current.map(function (item) {
				var id = _ember['default'].get(item, keyPath);
				if (id in modifications) {
					// modifications
					return changeModel.create({
						old: null,
						'new': item,
						change: modifications[id]
					});
				} else if (additions.includes(id)) {
					// new additions
					return changeModel.create({
						old: null,
						'new': item,
						change: null
					});
				} else {
					// copies
					return changeModel.create({
						old: item,
						'new': item,
						change: null
					});
				}
			}));
		});
	};
	exports.computedArrayForChangeModel = computedArrayForChangeModel;
});