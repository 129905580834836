define('component-library/wizard/flow/route', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Route.extend({
		// OVERRIDES - begin
		wizardService: null,
		serviceEnabled: false,

		// model: must return an Ember.RSVP.hash

		// OVERRIDES - end

		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);

			if (this.get('serviceEnabled')) {
				return;
			}

			var wizardFlowState = controller.newWizardFlowState();
			_ember['default'].set(model, 'wizardFlowState', wizardFlowState);
			controller.set('wizardFlowState', wizardFlowState);
		},

		actions: {
			// OVERRIDES - begin

			// Called when entering the route for the wizard flow index
			enteringFlowIndex: function enteringFlowIndex() {},

			// Called when entering a section from anywhere outside of that section
			enteringSection: function enteringSection(sectionKey) {},

			// OVERRIDES - end

			_regenerateFlowState: function _regenerateFlowState() {
				var controller = this.get('controller');
				var wizardFlowState = controller.newWizardFlowState();
				_ember['default'].set(this.modelFor(this.routeName), 'wizardFlowState', wizardFlowState);
				controller.set('wizardFlowState', wizardFlowState);
			},

			_updateStepComplete_sectionAction: function _updateStepComplete_sectionAction(sectionKey, stepKey, isComplete, shouldTransition) {
				var _this = this;

				var transitionHandler = function transitionHandler() {
					if (shouldTransition) {
						_this.get('controller')._transitionToNextStep();
					}
				};

				this.get('controller')._setStepCompletion(sectionKey, stepKey, isComplete).then(transitionHandler, transitionHandler);
			},
			_transitionToNextStep_sectionAction: function _transitionToNextStep_sectionAction(sectionKey) {
				this.get('controller')._transitionToNextStep();
			},
			_transitionToPreviousStep_sectionAction: function _transitionToPreviousStep_sectionAction(sectionKey) {
				this.get('controller')._transitionToPreviousStep();
			},
			_transitionToStepIndex_sectionAction: function _transitionToStepIndex_sectionAction(sectionKey, index) {
				this.get('controller')._transitionToStepIndex(sectionKey, index);
			},
			_transitionToStepKey_sectionAction: function _transitionToStepKey_sectionAction(sectionKey, stepKey) {
				this.get('controller')._transitionToStepKey(sectionKey, stepKey);
			},
			_transitionToFirstIncompleteStep_sectionAction: function _transitionToFirstIncompleteStep_sectionAction(sectionKey) {
				this.get('controller')._transitionToFirstIncompleteStep(sectionKey);
			},
			_transitionToTerminalRoute_sectionAction: function _transitionToTerminalRoute_sectionAction() {
				this.get('controller')._transitionToTerminalRoute();
			},
			_transitionToNextIncompleteStep_sectionAction: function _transitionToNextIncompleteStep_sectionAction(sectionKey) {
				this.get('controller')._transitionToNextIncompleteStep(sectionKey);
			},
			_transitionToFlowIndex_sectionAction: function _transitionToFlowIndex_sectionAction(sectionKey, stepKey) {
				this.get('controller')._transitionToFlowIndex(sectionKey, stepKey);
			},
			_updateCurrentSection_sectionAction: function _updateCurrentSection_sectionAction(sectionKey) {
				this.get('controller')._updateCurrentSection(sectionKey);
			},
			_updateCurrentStep_sectionAction: function _updateCurrentStep_sectionAction(sectionKey, stepKey) {
				this.get('controller')._updateCurrentStep(sectionKey, stepKey);
			},
			_revealWizardOverview: function _revealWizardOverview(reveal) {
				this.get('controller').set('isOverviewRevealed', reveal);
			}
		}
	});
});