define("component-library/utils/get-text-width", ["exports"], function (exports) {
  /**
   * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
   *
   * @param {String} text The text to be rendered.
   * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
   *
   * @see http://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
   */

  "use strict";

  var canvas = undefined;

  exports["default"] = function (text, font) {
    // re-use canvas object for better performance
    canvas = canvas || document.createElement("canvas");
    var context = canvas.getContext("2d");
    context.font = font;

    return context.measureText(text).width;
  };
});