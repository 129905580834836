define('component-library/components/sortable-list-item', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		tagName: 'li',
		classNames: ['z-sortableList-item', 'js-z-sortableList-item--sortable'],
		classNameBindings: ['isReorderingDisabled:disabled'],
		isReorderingDisabled: false,
		actions: {
			removeItem: function removeItem() {
				this.sendAction('removeItem', this.get('content'));
			}
		}
	});
});