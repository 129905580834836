define('component-library/components/cards/payroll/tax-header-body', ['exports', 'ember', 'component-library/components/cards/card-base', 'component-library/mixins/payroll/tax-header-mixin'], function (exports, _ember, _componentLibraryComponentsCardsCardBase, _componentLibraryMixinsPayrollTaxHeaderMixin) {
	'use strict';

	exports['default'] = _componentLibraryComponentsCardsCardBase['default'].extend(_componentLibraryMixinsPayrollTaxHeaderMixin['default'], {

		actions: {
			addWorkersComp: function addWorkersComp() {
				var newWrappedWorkersCompRecord = _ember['default'].Object.create({
					classCode: '',
					employeeRate: 0,
					employerRate: 0,
					hourlyRate: 0, //for WA WC
					employeeWithholding: 0, // for WA WC
					isNew: true
				});
				this.get('wcQuestion.answer').pushObject(newWrappedWorkersCompRecord);
			},

			deleteWorkersComp: function deleteWorkersComp(wrappedWorkersCompRecord) {
				_ember['default'].set(wrappedWorkersCompRecord, 'shouldDelete', true);
			},

			updateLevelOfService: function updateLevelOfService() {
				this.attrs.updateLevelOfService(this.get('taxHeader'));
			},

			updateAnswerEffective: function updateAnswerEffective(question) {
				this.attrs.updateAnswerEffective(this.get('taxHeader'), question);
			},

			viewHistoricalAgencyInfo: function viewHistoricalAgencyInfo(agencyInfoKey, agencyInfoKeyName) {
				this.attrs.viewHistoricalAgencyInfo(this.get('taxHeader'), agencyInfoKey, agencyInfoKeyName);
			},

			viewContinuousReconHistory: function viewContinuousReconHistory(taxType) {
				this.attrs.viewContinuousReconHistory(this.get('taxHeader'), taxType);
			},

			toggleEditAndValidateTaxRates: function toggleEditAndValidateTaxRates() {
				if (this.get('mode') === _componentLibraryComponentsCardsCardBase.SETTINGS_CARD_MODES.READ) {
					this.validateWcRates();
					this.validateRates();
				}
				this.send('toggleEdit');
			}

		}

	});
});