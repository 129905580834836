define('ui-infra/initializers/z-routing-service', ['exports'], function (exports) {
  'use strict';

  exports.initialize = initialize;

  function initialize() {
    var application = arguments[1] || arguments[0];
    application.inject('service:routing', 'router', 'router:main');
    application.inject('service:routing', 'applicationController', 'controller:application');
  }

  exports['default'] = {
    name: 'z-routing-service',
    initialize: initialize
  };
});