define('component-library/deductions/review/details/controller', ['exports', 'ember', 'component-library/controllers/detail', 'component-library/utils/deductions/deductions-utils'], function (exports, _ember, _componentLibraryControllersDetail, _componentLibraryUtilsDeductionsDeductionsUtils) {
	'use strict';

	var MAX_DISPLAY_LENGTH = 40;

	_ember['default'].Handlebars.helper('isAboveMaxDisplayLength', function (deductionLabel) {
		return deductionLabel.length > MAX_DISPLAY_LENGTH;
	});

	_ember['default'].Handlebars.helper('truncatedDeductionTypeLabel', function (deductionLabel) {
		if (deductionLabel.length > MAX_DISPLAY_LENGTH) {
			return deductionLabel.substr(0, MAX_DISPLAY_LENGTH) + "...";
		} else {
			return deductionLabel;
		}
	});

	exports['default'] = _componentLibraryControllersDetail['default'].extend({
		deductionsLoader: _ember['default'].computed.alias('reviewController.deductionsLoader'),
		selectedDeductionType: null,

		sortedDeductionTypes: (function () {
			var _this = this;

			var deductionLines = this.get('model.deductionLines');
			return (0, _componentLibraryUtilsDeductionsDeductionsUtils.getSortDeductionTypes)(deductionLines).map(function (item) {
				return _ember['default'].ObjectProxy.create({
					content: item,
					selected: _ember['default'].get(item, 'code') === _this.get('selectedDeductionType')
				});
			});
		}).property('model.deductionLines', 'selectedDeductionType'),

		reviewDataArray: (function () {
			var employee = this.get('model');
			var promise = this.get('deductionsLoader').loadDeductionsForEmployee(employee);
			return _ember['default'].ArrayProxy.extend(_ember['default'].PromiseProxyMixin).create({
				promise: promise
			});
		}).property('model')
	});
});