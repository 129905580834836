define('ui-infra/services/routing', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var on = _ember['default'].on;
  var computed = _ember['default'].computed;
  var Service = _ember['default'].Service;
  var Evented = _ember['default'].Evented;

  // NOTE: this will be extracted to its own addon and should match the implementation of
  // https://github.com/emberjs/rfcs/pull/95

  exports['default'] = Service.extend(Evented, {
    // NOTE: router & applicationController dependencies injected via initializer

    currentRouteName: computed.alias('applicationController.currentRouteName'),
    currentPath: computed.alias('applicationController.currentPath'),
    currentRouteSegments: computed('currentPath', function () {
      return this.get('currentPath') ? this.get('currentPath').split('.') : [];
    }),
    // returns the intended transition route, which is different from the `currentRouteName`
    // when a loading route is active.
    getActiveTransitionRouteName: function getActiveTransitionRouteName() {
      return this.get('router.router.activeTransition.targetName');
    },

    transitionTo: function transitionTo() {
      var router = this.get('router');
      return router.transitionTo.apply(router, arguments);
    },

    replaceWith: function replaceWith() {
      var router = this.get('router');
      router.replaceWith.apply(router, arguments);
    },

    /**
     * Transitions to an ancestor.
     */
    transitionUp: function transitionUp() {
      var segments = this.get('currentRouteSegments');
      segments.pop();
      this.transitionTo(segments.join('.'));
    },

    _subscribeToRouterEvents: on('init', function () {
      var _this = this;

      var router = this.get('router');
      router.on('willTransition', function (tr) {
        _this.trigger('routerWillTransition', tr);
      });
      router.on('didTransition', function (tr) {
        _this.trigger('routerDidTransition', tr);
      });
    }),

    hasRoute: function hasRoute(routeName) {
      // See https://github.com/emberjs/rfcs/pull/95#issuecomment-276818921 regarding
      // possibly exposing this to be public
      return this.get('router').hasRoute(routeName);
    }
  });
});