define('z-inputs/components/z-text-field-number', ['exports', 'z-inputs/components/z-text-field-base', 'ember', 'z-inputs/templates/components/z-text-field-basic', 'z-inputs/utils/z-text-field-input-mask-mixin-factory', 'z-inputs/utils/number-fields-helper'], function (exports, _zInputsComponentsZTextFieldBase, _ember, _zInputsTemplatesComponentsZTextFieldBasic, _zInputsUtilsZTextFieldInputMaskMixinFactory, _zInputsUtilsNumberFieldsHelper) {
  'use strict';

  var NUMBER_MASK_PARAMS = ['max', 'min', 'allowMinus'];
  var zTextFieldNumberMixin = (0, _zInputsUtilsZTextFieldInputMaskMixinFactory['default'])(NUMBER_MASK_PARAMS);

  exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend(zTextFieldNumberMixin, {
    layout: _zInputsTemplatesComponentsZTextFieldBasic['default'],
    classNames: 'z-textField--number',
    type: 'number',
    mask: 'decimal',
    pattern: '[0-9]*',

    maskOptions: _ember['default'].computed.apply(_ember['default'], NUMBER_MASK_PARAMS.concat([function () {
      return {
        max: this.get('max'),
        min: this.get('min'),
        allowMinus: this.get('allowMinus')
      };
    }])),

    shadowValue: (function () {
      var shadowUnitSuffix = this.getWithDefault('shadowUnitSuffix', '');
      var value = this.get('value');
      if (shadowUnitSuffix) {
        return (_ember['default'].isEmpty(value) ? '' : value) + ' ' + shadowUnitSuffix;
      } else {
        return _ember['default'].isEmpty(value) ? '' : value;
      }
    }).property('value', 'shadowUnitSuffix'),

    didInsertElement: function didInsertElement() {
      this._super();

      this.set('value', (0, _zInputsUtilsNumberFieldsHelper['default'])(this.get('value'), {
        min: this.get('min'),
        max: this.get('max')
      }));
    }
  });
});