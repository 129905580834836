define('ui-infra/instance-initializers/ajax-override', ['exports', 'ember', 'ui-infra/utils/get-config', 'ui-infra/utils/ember-compatibility-utils'], function (exports, _ember, _uiInfraUtilsGetConfig, _uiInfraUtilsEmberCompatibilityUtils) {
  'use strict';

  exports.initialize = initialize;

  // TODO: move to ui-infra and remove from component-library/addon/ajax-override-initializer.js

  function initialize(appInstance) {
    var config = (0, _uiInfraUtilsGetConfig['default'])(appInstance);
    var logger = (0, _uiInfraUtilsEmberCompatibilityUtils.lookup)(appInstance, 'service:event-logger');
    _ember['default'].assert('The ajax-override-initializer depends on the eventLogger', logger);
    // HACK: Punching out JQuery.ajax to save the settings for each
    // request -- allows logging it for failed requests
    var origAjax = _ember['default'].$.ajax;
    _ember['default'].$.ajax = function (settings) {

      // Disallow relative paths for API calls
      var url = settings.url;
      if (/^custom_api\//.test(url) || /^api\//.test(url) || /^internal-services\//.test(url)) {
        throw new Error('API calls must use an absolute path starting with /api or /custom_api');
      } else if (/^\/api/.test(settings.url)) {
        settings.headers = settings.headers || {};
        if (logger.transitionId) {
          // TODO: we probably want to do this for custom_api's as well, maybe everything for the same domain
          settings.headers['X-Transition-Id'] = logger.transitionId;
        }
      }

      var obj = origAjax.apply(_ember['default'].$, arguments);
      obj._settings = settings;
      return obj;
    };

    if (config.environment === 'production') {
      // TODO: move to its own initializer
      // Run after the event logger has already been initialized w/ DI
      // FIXME: Use the 'after' directive for this
      _ember['default'].run.later(function () {
        _ember['default'].onerror = function (caught) {
          logger.logError(caught);
        };
      });
    }
  }

  exports['default'] = {
    name: 'ajax-override-initializer',
    after: 'logger-initializer',
    initialize: initialize
  };
});