define('component-library/components/flaggable-cell', ['exports', 'ember', 'component-library/components/popover-link'], function (exports, _ember, _componentLibraryComponentsPopoverLink) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPopoverLink['default'].extend({
		tagName: 'td',
		cell: null,
		content: _ember['default'].computed.alias('cell'),
		table: null,
		isFlagged: _ember['default'].computed.equal('cell.visibleCellStatus', 'F'),
		popoverClass: null,
		isApproved: _ember['default'].computed.equal('cell.visibleCellStatus', 'A'),
		dataTargetId: _ember['default'].computed.alias('cell.id'),
		hasDisplayableComments: _ember['default'].computed.gt('cell.displayableComments.length', 0),
		classNames: ['js-glue-flaggableCell'],
		classNameBindings: ['canShowPopover'],
		attributeBindings: ['dataTargetId:data-target-id'],

		click: function click() {
			if (this.get('canShowPopover')) {
				this._super();
			}
		},
		// Flag popover will take care of masking button if the table is not editable.
		canShowPopover: (function () {
			return this.get('table.isEditable') || this.get('hasDisplayableComments');
		}).property('table.isEditable', 'hasDisplayableComments')
	});
});