define('component-library/components/deductions/deduction-catchup-popover', ['exports', 'ember', 'component-library/components/promise-popover', 'component-library/utils/pretty-currency', 'component-library/utils/pretty-percent2', 'component-library/templates/components/deductions/deduction-catchup-popover'], function (exports, _ember, _componentLibraryComponentsPromisePopover, _componentLibraryUtilsPrettyCurrency, _componentLibraryUtilsPrettyPercent2, _componentLibraryTemplatesComponentsDeductionsDeductionCatchupPopover) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
		template: _componentLibraryTemplatesComponentsDeductionsDeductionCatchupPopover['default'],
		hasArrow: true,
		arrowOffset: 0,
		placement: 'bottom',
		attachment: 'top center',
		targetAttachment: 'bottom center',

		normalDeduction: (function () {
			var isContribution = this.get('content.isContribution');
			var data = this.get('content.data');
			var totalActualAmount = isContribution ? _ember['default'].get(data, 'totalActualContributions') : _ember['default'].get(data, 'totalActualDeductions');
			var totalExpectedAmount = isContribution ? _ember['default'].get(data, 'totalExpectedContributions') : _ember['default'].get(data, 'totalExpectedDeductions');
			var totalBalance = totalActualAmount - totalExpectedAmount;
			var action = isContribution ? 'contributed' : 'deducted';
			if (totalBalance > 0.1) {
				return 'Over ' + action + ' by ' + (_ember['default'].get(data, 'isPercentageTotals') ? (0, _componentLibraryUtilsPrettyPercent2['default'])(totalBalance) : (0, _componentLibraryUtilsPrettyCurrency['default'])(totalBalance));
			} else if (totalBalance < -0.1) {
				return 'Under ' + action + ' by ' + (_ember['default'].get(data, 'isPercentageTotals') ? (0, _componentLibraryUtilsPrettyPercent2['default'])(Math.abs(totalBalance)) : (0, _componentLibraryUtilsPrettyCurrency['default'])(Math.abs(totalBalance)));
			} else {
				return '';
			}
		}).property('content'),

		discrepancyExplanation: (function () {
			var isContribution = this.get('content.isContribution');
			var data = this.get('content.data');
			var normalAmount = isContribution ? _ember['default'].get(data, 'normalContribution') : _ember['default'].get(data, 'normalDeduction');
			var currentAmount = isContribution ? _ember['default'].get(data, 'currentContribution') : _ember['default'].get(data, 'currentDeduction');
			var currentDiff = currentAmount - normalAmount;

			var type = isContribution ? 'contribution' : 'deduction';
			if (_ember['default'].get(data, 'isPercentageTotals')) {
				return new _ember['default'].Handlebars.SafeString('Normal ' + type + ': ' + (0, _componentLibraryUtilsPrettyPercent2['default'])(normalAmount) + '<br>' + 'Current ' + type + ': ' + (0, _componentLibraryUtilsPrettyPercent2['default'])(currentAmount) + ' (= ' + (0, _componentLibraryUtilsPrettyPercent2['default'])(normalAmount) + (currentDiff < 0 ? ' - ' : ' + ') + (0, _componentLibraryUtilsPrettyPercent2['default'])(Math.abs(currentDiff)) + ')');
			} else {
				return new _ember['default'].Handlebars.SafeString('Normal ' + type + ': ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(normalAmount) + '<br>' + 'Current ' + type + ': ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(currentAmount) + ' (= ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(normalAmount) + (currentDiff < 0 ? ' - ' : ' + ') + (0, _componentLibraryUtilsPrettyCurrency['default'])(Math.abs(currentDiff)) + ')');
			}
		}).property('content')
	});
});