define('component-library/utils/tags/context-tag-computed', ['exports', 'ember', 'component-library/utils/tags/context-tag-factory'], function (exports, _ember, _componentLibraryUtilsTagsContextTagFactory) {
	'use strict';

	exports['default'] = function (strings) {
		for (var _len = arguments.length, dependentKeys = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
			dependentKeys[_key - 1] = arguments[_key];
		}

		return _ember['default'].computed.apply(_ember['default'], dependentKeys.concat([function () {
			var contextTag = (0, _componentLibraryUtilsTagsContextTagFactory['default'])(this);
			return contextTag.apply(undefined, [strings].concat(dependentKeys));
		}]));
	};
});