define('ember-multiselect-checkboxes/components/multiselect-checkboxes', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['multiselect-checkboxes'],

    tagName: 'ul',

    options: null,

    selection: null,

    labelProperty: null,

    disabled: false
  });
});