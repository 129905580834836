define('component-library/components/include-checkbox', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-includedCheckbox'],
		classNameBindings: ['checked:is-checked'],
		checked: false,
		disabled: false,
		param: null,
		action: null,

		checkboxId: (function () {
			return this.get('elementId') + '-z-includedCheckbox';
		}).property('elementId'),

		actions: {
			checkChanged: function checkChanged() {
				if (!_ember['default'].isEmpty(this.get('action'))) {
					this.sendAction('action', this.get('param'));
				}
			}
		}
	});
});