define('component-library/components/show-loading-until', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		tagName: '',
		message: 'Loading',
		promise: undefined,
		isPending: _ember['default'].computed.reads('promise.isPending'),

		showMessage: true,
		stretchVertically: false
	});
});