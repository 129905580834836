define('z-inputs/components/z-text-field-monthyear', ['exports', 'z-inputs/components/z-text-field-base', 'z-inputs/templates/components/z-text-field-basic'], function (exports, _zInputsComponentsZTextFieldBase, _zInputsTemplatesComponentsZTextFieldBasic) {
	'use strict';

	window.Inputmask.extendAliases({
		"mm / yyyy": {
			mask: "1 / y",
			placeholder: "mm / yyyy",
			leapday: "02 / 29",
			separator: " / ",
			alias: "mm/yyyy"
		}
	});

	exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend({
		layout: _zInputsTemplatesComponentsZTextFieldBasic['default'],
		classNames: 'z-textField--monthyear',
		type: 'monthyear',
		mask: 'mm / yyyy',
		hidePlaceholderOnFocus: true,

		shadowPlaceholder: 'mm / yyyy',
		showShadowPlaceholder: true
	});
});