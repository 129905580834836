define('component-library/components/popover-dialog', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-popover'],
		classNameBindings: ['placementAligment', 'hasArrow:z-popover--hasArrow'],

		// Expects a jQuery object
		anchor: null,
		hasArrow: false,
		// Default to place the popover below the anchor
		// Options: top, right, bottom, left
		placement: 'bottom',
		// Default to align the popover to the left side of the anchor
		// Options: top, right, bottom, left, center
		// TODO(ldvz): Make this smart such that the alignment takes the window into
		// account.
		alignment: 'right',

		placementAligment: (function () {
			return 'z-popover--placement' + this.get('placement').capitalize() + this.get('alignment').capitalize();
		}).property('placement', 'alignment'),

		closeHandler: (function () {
			return _ember['default'].run.bind(this, this.closePopover);
		}).property(),

		resizeHandler: (function () {
			return _ember['default'].run.bind(this, this.closePopover);
		}).property(),

		scrollHandler: (function () {
			return _ember['default'].run.bind(this, this.positionElement);
		}).property(),

		positionElement: function positionElement() {
			var left = null;
			var top = null;

			// TODO(ldvz): check browser comp
			// http://ryanve.com/lab/dimensions/
			var viewportWidth = document.documentElement.clientWidth;
			var viewportHeight = document.documentElement.clientHeight;

			// TODO(ldvz): handle dynamic widths and heights
			var popoverWidth = this.$().outerWidth();
			var popoverHeight = this.$().outerHeight();

			var $anchor = this.get('anchor');
			var anchorOffset = $anchor.offset();
			var anchorHeight = $anchor.outerHeight();
			var anchorWidth = $anchor.outerWidth();
			var pageScrollTop = this.$(document).scrollTop();
			var pageScrollLeft = this.$(document).scrollLeft();

			var arrowSize = 0;

			// Check for arrow offset
			if (this.get('hasArrow') === true) {
				// Arrow size
				arrowSize = 10;
			}

			// Placement check and alignment
			if (anchorOffset.left - popoverWidth < 0) {
				// Fix Alignment
				if (this.get('placement') === 'top' || this.get('placement') === 'bottom') {
					left = 0;
				} else {
					this.set('placement', 'right');
				}
			}
			if (anchorOffset.left + popoverWidth > viewportWidth) {
				// Fix Alignment
				if (this.get('placement') === 'top' || this.get('placement') === 'bottom') {
					left = viewportWidth - popoverWidth;
				} else {
					this.set('placement', 'left');
				}
			}

			if (anchorOffset.top - popoverHeight < 0) {
				// Fix Alignment
				if (this.get('placement') === 'left' || this.get('placement') === 'right') {
					top = 0;
				} else {
					this.set('placement', 'bottom');
				}
			}
			if (anchorOffset.top + popoverHeight > viewportHeight) {
				// Fix Alignment
				if (this.get('placement') === 'left' || this.get('placement') === 'right') {
					top = viewportHeight - popoverHeight;
				} else {
					this.set('placement', 'top');
				}
			}

			// Placement
			if (this.get('placement') === 'top') {
				top = anchorOffset.top - popoverHeight - pageScrollTop;
			} else if (this.get('placement') === 'right') {
				left = anchorOffset.left + anchorWidth - pageScrollLeft;
			} else if (this.get('placement') === 'bottom') {
				top = anchorOffset.top + anchorHeight - pageScrollTop;
			} else if (this.get('placement') === 'left') {
				left = anchorOffset.left - popoverWidth - pageScrollLeft;
			}

			// Vertical alignment of z-popover-panel
			if (top === null && (this.get('placement') === 'left' || this.get('placement') === 'right')) {
				if (this.get('alignment') === 'center') {
					top = anchorOffset.top + anchorHeight / 2 - popoverHeight / 2;
				} else if (this.get('alignment') === 'top') {
					top = anchorOffset.top;
				} else if (this.get('alignment') === 'bottom') {
					top = anchorOffset.top + anchorHeight - popoverHeight;
				}
			}

			// Horizontal alignment of z-popover-panel
			if (left === null && (this.get('placement') === 'top' || this.get('placement') === 'bottom')) {
				if (this.get('alignment') === 'center') {
					left = anchorOffset.left + anchorWidth / 2 - popoverWidth / 2;
				} else if (this.get('alignment') === 'right') {
					left = anchorOffset.left + anchorWidth - popoverWidth;
				} else if (this.get('alignment') === 'left') {
					left = anchorOffset.left;
				}
			}

			// Arrow placement

			// Opening animation
			// $element.velocity({
			// 	translateX: "200px",
			// 	rotateZ: "45deg"
			// });

			this.$().css({
				left: left,
				top: top
			});
		},

		closePopover: function closePopover(event) {
			// TODO(ldvz): Closing animation
			var that = this;
			var $parent = _ember['default'].$(event.target).parents('#' + this.get('elementId'));
			var clickedOutOfPopover = _ember['default'].isEmpty($parent);
			if (clickedOutOfPopover) {
				_ember['default'].$(window).off('click', this.get('closeHandler'));
				this.$().animate({
					opacity: 0
				}, 200, function () {
					that.sendAction('close');
				});
			}
		},

		didInsertElement: function didInsertElement() {
			var that = this;
			this.positionElement();

			this.$().animate({
				opacity: 1
			}, 300, function () {
				// NOTE: only attach click handler after animated so closePopover
				// doesn't get invoked immediately
				_ember['default'].$(window).on('click', that.get('closeHandler'));
			});

			_ember['default'].$(window).on('resize', this.get('resizeHandler'));
			_ember['default'].$(window).on('scroll', this.get('scrollHandler'));

			// TODO(ldvz): handle scroll event that might affect anchor element
		},

		willDestroyElement: function willDestroyElement() {
			_ember['default'].$(window).off('click', this.get('closeHandler'));
			_ember['default'].$(window).off('resize', this.get('resizeHandler'));
			_ember['default'].$(window).off('scroll', this.get('scrollHandler'));
		}
	});
});