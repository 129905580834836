define('model-library/models/z-payroll-run-tax', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
	'use strict';

	exports['default'] = _emberData['default'].Model.extend({
		zpTaxMeta: _emberData['default'].belongsTo('ZPayrollTaxMeta'),
		zPayrollRun: _emberData['default'].belongsTo('ZPayrollRun'),
		subTaxes: _emberData['default'].hasMany('zPayrollRunSubTax'),
		name: _emberData['default'].attr('string'),
		taxDueFrequency: _emberData['default'].attr('string'),
		amount: _emberData['default'].attr('number'),
		grossWage: _emberData['default'].attr('number'),
		subjectWage: _emberData['default'].attr('number'),
		grossSubjectWage: _emberData['default'].attr('number'),

		displayName: _ember['default'].computed('zPayrollRun.payPeriod.formattedDates', function () {
			return 'Pay Run Taxes for ' + this.get('zPayrollRun.payPeriod.formattedDates');
		})
	});
});