define('ember-cli-bundle-loader/lazy-router', ['exports', 'ember', 'ember-cli-bundle-loader/utils/get-owner'], function (exports, _ember, _emberCliBundleLoaderUtilsGetOwner) {
  'use strict';

  var get = _ember['default'].get;
  var isArray = _ember['default'].isArray;
  exports['default'] = _ember['default'].Router.extend({
    _getHandlerFunction: function _getHandlerFunction() {
      var container = (0, _emberCliBundleLoaderUtilsGetOwner.getContainer)(this);
      var DefaultRoute = (0, _emberCliBundleLoaderUtilsGetOwner.getFactory)(this, 'route:basic');
      var LazyLoaderRoute = (0, _emberCliBundleLoaderUtilsGetOwner.getFactory)(this, 'route:-lazy-loader');
      var lazyLoaderService = container.lookup('service:lazy-loader');

      var _this = this;

      return function (name) {
        var routeName = 'route:' + name;
        var lazyRouteName = routeName + '.lazy';
        var handler = container.lookup(routeName);
        var needsLazyLoading = !!lazyLoaderService.needsLazyLoading(name);

        if (!handler) {
          if (needsLazyLoading) {
            handler = container.lookup(lazyRouteName);
            if (!handler) {
              (0, _emberCliBundleLoaderUtilsGetOwner.registerFactory)(_this, lazyRouteName, LazyLoaderRoute.extend());
              handler = container.lookup(lazyRouteName);
              handler.routeName = name + '-lazy';
            }
            return handler;
          }
          (0, _emberCliBundleLoaderUtilsGetOwner.registerFactory)(_this, routeName, DefaultRoute.extend());
          handler = container.lookup(routeName);

          if (get(_this, 'namespace.LOG_ACTIVE_GENERATION')) {
            _ember['default'].Logger.info("generated -> " + routeName, { fullName: routeName });
          }
        }

        if (typeof handler._setRouteName === 'function') {
          handler._setRouteName(name);
          handler._populateQPMeta();
        } else {
          handler.routeName = name;
        }
        return handler;
      };
    },

    _queryParamsFor: function _queryParamsFor(handlerInfosOrLeafRouteName) {
      var leafRouteName = isArray(handlerInfosOrLeafRouteName) ? handlerInfosOrLeafRouteName[handlerInfosOrLeafRouteName.length - 1].name : handlerInfosOrLeafRouteName;
      var superQueryParams = this._super.apply(this, arguments);
      var container = (0, _emberCliBundleLoaderUtilsGetOwner.getContainer)(this);
      var lazyLoaderService = container.lookup('service:lazy-loader');
      var needsLazyLoading = !!lazyLoaderService.needsLazyLoading(leafRouteName);
      //If the bundle is not yet loaded, the qps for the routes in the bundle will be stored as empty in `_qpCache`.
      //Hence remove the qps of routes that are not yet loaded from `qpCache`
      if (needsLazyLoading) {
        delete this._qpCache[leafRouteName];
      }
      return superQueryParams;
    }
  });
});