define('component-library/components/radio-block-tab', ['exports', 'component-library/components/radio-block', 'component-library/templates/components/radio-block'], function (exports, _componentLibraryComponentsRadioBlock, _componentLibraryTemplatesComponentsRadioBlock) {
	'use strict';

	exports['default'] = _componentLibraryComponentsRadioBlock['default'].extend({
		classNames: ['radio-block-wrapper', 'radio-block-tab-wrapper'],
		template: _componentLibraryTemplatesComponentsRadioBlock['default'],
		hasBottomArrow: false,

		didInsertElement: function didInsertElement() {
			this._super();
			if (this.hasBottomArrow) {
				this.$().addClass('z-radioBlockTriangle');
			}
		}
	});
});