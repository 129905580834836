define('component-library/components/z-input-label-layout', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-input-label-layout'],
		classNameBindings: ['addTextFieldMargin:align-with-text-field', 'addSelectFieldMargin:align-with-select-field'],

		// Some products share cards; must pass in showLabelOnSide=true to explicitly set label on left
		showLabelOnSide: false,
		tooltipText: '',
		isRequired: true,
		label: '',
		// flag to hide "(optional)" even if field is not required
		hideOptionalSuffix: false,

		// optional property passed in to specify if label needs to be aligned with text field. should set to true
		// if yielding a z-text-field-* in the first row so that label aligns with text in the input field.
		shouldAlignWithSelectField: false,
		shouldAlignWithTextField: false,

		// Specify how many bootstrap columns the yielded content should take up. Value from 1-12.
		// Note: This is only for desktop (md and up) size.
		//       Mobile (sm and xs) will always have full width with label on top.
		contentColumns: 9,

		_labelColumns: 3,
		_labelColumnsClass: _ember['default'].computed('_labelColumns', function () {
			return 'col-md-' + this.get('_labelColumns');
		}),

		_contentColumnsClass: _ember['default'].computed('_labelColumns', 'label', 'showLabelOnSide', 'contentColumns', function () {
			var columnColumnsClass = 'col-md-';
			if (this.get('showLabelOnSide') && this.get('label')) {
				columnColumnsClass += this.get('contentColumns') - this.get('_labelColumns');
			} else {
				columnColumnsClass += this.get('contentColumns');
			}

			return columnColumnsClass;
		}),

		_labelFormatted: _ember['default'].computed('label', 'isRequired', 'hideOptionalSuffix', function () {
			var labelText = this.get('label');
			if (!this.get('isRequired') && !this.get('hideOptionalSuffix')) {
				labelText += " (optional)";
			}
			return labelText;
		}),

		addTextFieldMargin: _ember['default'].computed('showLabelOnSide', 'shouldAlignWithTextField', function () {
			return this.get('showLabelOnSide') && this.get('shouldAlignWithTextField');
		}),
		addSelectFieldMargin: _ember['default'].computed('showLabelOnSide', 'shouldAlignWithSelectField', function () {
			return this.get('showLabelOnSide') && this.get('shouldAlignWithSelectField');
		})
	});
});