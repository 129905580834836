define('component-library/data-table/cells/census-employee-review-table-cell', ['exports', 'ember', 'component-library/data-table/cells/data-table-cell'], function (exports, _ember, _componentLibraryDataTableCellsDataTableCell) {
	'use strict';

	exports['default'] = _componentLibraryDataTableCellsDataTableCell['default'].extend({
		templateName: 'data-table/cells/census-employee-review-table-cell',
		classNames: ['census-employeeReviewTable-cell', 'js-glue-census-employeeReviewTable-cell'],
		classNameBindings: ['cellHasError:has-error'],

		errorKey: _ember['default'].computed.alias('column.errorContentPath'),

		cellErrorMessage: (function () {
			var row = this.get('row');
			var errorKey = this.get('errorKey');

			if (!row || !errorKey) {
				return null;
			}

			var errorsObj = row.get('_errors') || {};

			return errorsObj[errorKey] || null;
		}).property('row'),

		cellHasError: (function () {
			return !!this.get('cellErrorMessage');
		}).property('cellErrorMessage')
	});
});