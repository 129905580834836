define('z-table/components/z-table-column', ['exports', 'ember', 'z-table/templates/components/z-table-column'], function (exports, _ember, _zTableTemplatesComponentsZTableColumn) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		layout: _zTableTemplatesComponentsZTableColumn['default'],
		tagName: '',
		header: '',
		fieldName: '',
		api: null,
		disableSorting: false
	});
});