define('model-library/models/deduction-data', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	exports['default'] = _emberData['default'].Model.extend({
		deduction: _emberData['default'].attr('number'),
		contribution: _emberData['default'].attr('number'),
		actualTotalContribution: _emberData['default'].attr('number'),
		actualTotalDeduction: _emberData['default'].attr('number'),
		catchupDeductionPayPeriods: _emberData['default'].attr('number'),
		catchupEndDate: _emberData['default'].attr('date'),
		catchupStartDate: _emberData['default'].attr('date'),
		checkDate: _emberData['default'].attr('date'),
		computeEndDate: _emberData['default'].attr('date'),
		computeStartDate: _emberData['default'].attr('date'),
		currentContribution: _emberData['default'].attr('number'),
		currentDeduction: _emberData['default'].attr('number'),
		deductionType: _emberData['default'].attr('string'),
		effectiveEndDate: _emberData['default'].attr('date'),
		effectiveStartDate: _emberData['default'].attr('date'),
		expectedTotalContribution: _emberData['default'].attr('number'),
		expectedTotalDeduction: _emberData['default'].attr('number'),
		isContributionAsPercentage: _emberData['default'].attr('boolean'),
		isContributionCatchup: _emberData['default'].attr('boolean'),
		isDeductionAsPercentage: _emberData['default'].attr('boolean'),
		isDeductionCatchup: _emberData['default'].attr('boolean'),
		isInThirdOrFifthPayPeriod: _emberData['default'].attr('boolean'),
		monthlyContribution: _emberData['default'].attr('number'),
		monthlyDeduction: _emberData['default'].attr('number'),
		nWeeklyAsNWeekly: _emberData['default'].attr('boolean'),
		normalContribution: _emberData['default'].attr('number'),
		normalDeduction: _emberData['default'].attr('number'),
		payFrequency: _emberData['default'].attr('string'),
		timestamp: _emberData['default'].attr('date')
	});
});