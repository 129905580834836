define('component-library/helpers/pretty-percent-or-currency', ['exports', 'ember', 'component-library/utils/pretty-percent-or-currency'], function (exports, _ember, _componentLibraryUtilsPrettyPercentOrCurrency) {
	'use strict';

	var _slicedToArray = (function () {
		function sliceIterator(arr, i) {
			var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;_e = err;
			} finally {
				try {
					if (!_n && _i['return']) _i['return']();
				} finally {
					if (_d) throw _e;
				}
			}return _arr;
		}return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError('Invalid attempt to destructure non-iterable instance');
			}
		};
	})();

	/**
  * sometimes we get stuff from the server such as co-insurance where it is a percent if it is <= 1 or
  * otherwise it is a currency value...
  * Use this like {{prettyPercentOrCurrency anyNumber}} or {{prettyPercentOrCurrency anyNumber default="-"}}
  * returns 0.1 -> 10% or 10 -> $10
  */
	exports['default'] = _ember['default'].HTMLBars.makeBoundHelper(function (_ref, hash) {
		var _ref2 = _slicedToArray(_ref, 1);

		var number = _ref2[0];

		var defaultValue = hash['default'] || "";
		// decimalPoint is a boolean. Make it false if you don't want 35.00% and precision will not be harmed
		var decimalPoint = hash.decimalPoint !== false;
		if (number === undefined || number === null || isNaN(+number)) {
			return defaultValue;
		}
		return (0, _componentLibraryUtilsPrettyPercentOrCurrency['default'])(number, hash.precision, decimalPoint);
	});
});