define('component-library/components/plan-package', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-planPackage', 'js-planPackage-container', 'grid-block', 'vertical'],
		classNameBindings: ['isOpen', 'hasSingleCard', 'hasNoCards', '_isExpandable:is-expandable'],
		isOpen: false,
		spacingUnit: 16,
		enableHover: true,
		enableExpand: true,
		'package': null,

		hasSingleCard: _ember['default'].computed.equal('package.packagePlans.length', 1),
		hasNoCards: _ember['default'].computed.equal('package.packagePlans.length', 0),
		hasMultipleCards: _ember['default'].computed.gt('package.packagePlans.length', 1),
		_isExpandable: _ember['default'].computed.and('enableExpand', 'hasMultipleCards'),

		didInsertElement: function didInsertElement() {
			this._super();
			this.setupPackageLayout();
		},

		setupPackageLayout: (function () {
			var _this = this;

			// when `data` changes Ember does some fancy Morph stuff and
			// updates the DOM - this causes elements to be removed and re-added
			// (with updated values).  I need to wait for this process to finish
			// before I try and re-position the cards (which are themselves components).
			_ember['default'].run.scheduleOnce('afterRender', this, function () {
				_this.setCardPositionOverlapped();
				if (_this.get('enableHover') && _this.get('hasMultipleCards')) {
					_this.onHover();
				}
			});
		}).observes('hasMultipleCards'),

		_getCardHeaderHeight: function _getCardHeaderHeight(index) {
			var topOfCardBody = this.$('.js-planCard-body').eq(index).offset().top;
			var topOfCard = this.$('.js-planCard-container').eq(index).offset().top;
			var cardHeaderHeight = topOfCardBody - topOfCard;
			return cardHeaderHeight;
		},

		setCardPositionOverlapped: function setCardPositionOverlapped() {
			var $cards = this.$('.js-planCard-container-wrapper') || [];
			var numCards = $cards.length;

			// reset first cards margin because sometimes ember rendering doesn't rerender the first card
			if (numCards) {
				$cards.eq(0).css('marginTop', '0px');
			}

			//all but first card will overlap
			for (var i = 1; i < numCards; i++) {
				var $card = $cards.eq(i);
				var headerHeight = this._getCardHeaderHeight(i);
				$card.css('marginTop', -1 * ($card.outerHeight() - headerHeight) + 'px');
			}
		},

		setCardPositionOpen: function setCardPositionOpen() {
			var spacingUnit = this.get('spacingUnit');
			var $cards = this.$('.js-planCard-container-wrapper') || [];
			var numCards = $cards.length;
			//all but first card will be moved
			for (var i = 1; i < numCards; i++) {
				var $card = $cards.eq(i);
				$card.css({
					marginTop: spacingUnit,
					transform: 'translate3d(0,0,0)'
				});
			}
		},

		isOpenObserver: _ember['default'].observer('isOpen', function () {
			if (this.get('isOpen')) {
				this.setCardPositionOpen();
			} else {
				this.setCardPositionOverlapped();
			}
		}),

		onHover: function onHover() {
			var _this2 = this;

			var $cards = this.$('.js-planCard-container-wrapper');

			if (_ember['default'].isEmpty($cards)) {
				return;
			}
			var numCards = $cards.length;
			var onHoverSpacing = this.get('spacingUnit');
			this.$().unbind('mouseenter mouseleave'); // need to unbind for resetting hover due to ember rendering
			this.$().hover(function () {
				if (!_this2.get('isOpen')) {
					// all but first card should animate on hover
					for (var i = 1; i < numCards; i++) {
						var $card = $cards.eq(i);
						var transformCalculation = onHoverSpacing * i;
						$card.css('transform', 'translate3d(0,-' + transformCalculation + 'px,0)');
					}
				}
			}, function () {
				if (!_this2.get('isOpen')) {
					$cards.css('transform', 'translate3d(0,0,0)');
				}
			});
		},

		click: function click(e) {
			if (e.target.nodeName === 'A') {
				return;
			}
			if (this.get('_isExpandable')) {
				this.toggleProperty('isOpen');
			}
		},

		willDestroyElement: function willDestroyElement() {
			this.$().unbind('mouseenter mouseleave');
			this._super();
		}
	});
});