define('component-library/constants/z-payroll/zp-employee-1099-filing', ['exports', 'component-library/utils/globals'], function (exports, _componentLibraryUtilsGlobals) {
	'use strict';

	var _FORM_1099_FILING_STATUS_TO_SHORT_DISPLAY_NAME;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
		} else {
			obj[key] = value;
		}return obj;
	}

	var FORM_1099_FILING_STATUS = {
		TO_BE_FILED_THRU_ZENEFITS: 'TO_BE_FILED_THRU_ZENEFITS',
		FILED_BY_CUSTOMER: 'FILED_BY_CUSTOMER'
	};

	exports.FORM_1099_FILING_STATUS = FORM_1099_FILING_STATUS;

	var FORM_1099_FILING_STATUS_TO_SHORT_DISPLAY_NAME = (_FORM_1099_FILING_STATUS_TO_SHORT_DISPLAY_NAME = {}, _defineProperty(_FORM_1099_FILING_STATUS_TO_SHORT_DISPLAY_NAME, FORM_1099_FILING_STATUS.TO_BE_FILED_THRU_ZENEFITS, 'Through ' + _componentLibraryUtilsGlobals.brandName), _defineProperty(_FORM_1099_FILING_STATUS_TO_SHORT_DISPLAY_NAME, FORM_1099_FILING_STATUS.FILED_BY_CUSTOMER, 'Not Through ' + _componentLibraryUtilsGlobals.brandName), _defineProperty(_FORM_1099_FILING_STATUS_TO_SHORT_DISPLAY_NAME, 'DEFAULT', "N/A"), _FORM_1099_FILING_STATUS_TO_SHORT_DISPLAY_NAME);
	exports.FORM_1099_FILING_STATUS_TO_SHORT_DISPLAY_NAME = FORM_1099_FILING_STATUS_TO_SHORT_DISPLAY_NAME;
});