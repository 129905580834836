define('component-library/modals/pto/record-modal', ['exports', 'component-library/utils/globals', 'ember', 'component-library/components/promise-modal', 'component-library/mixins/pto/ptorecordoreditrequestmixin', 'ui-infra/services/switches'], function (exports, _componentLibraryUtilsGlobals, _ember, _componentLibraryComponentsPromiseModal, _componentLibraryMixinsPtoPtorecordoreditrequestmixin, _uiInfraServicesSwitches) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend(_componentLibraryMixinsPtoPtorecordoreditrequestmixin['default'], {
		isResponsive: true,
		autoSetHeight: false,
		modalDialogClass: 'z-typography',
		init: function init() {
			this._super();
			// if model is provided, select the first item for vacationType
			if (this.get('account')) {
				var firstVacationType = this.get('filteredVacationTypes')[0];
				if (firstVacationType) {
					this.set('model.vacationType', firstVacationType);
					this.set('vacationTypeSelectPrompt', null);
				}
			}
		},

		isVacationTypeSelectDisabled: _ember['default'].computed('filteredVacationTypes.[]', function () {
			return this.get('filteredVacationTypes.length') === 1;
		}),

		vacationTypeSelectPrompt: _ember['default'].computed('filteredVacationTypes.[]', function () {
			return this.get('filteredVacationTypes.length') === 1 ? null : '-';
		}),

		templateName: 'modals/pto/record-modal',

		fillEndDate: (function () {
			var startDate = this.get('model.startDate');
			if (startDate && !this.get('model.endDate')) {
				this.set('model.endDate', startDate);
			}
		}).observes('model.startDate'),

		employee: _ember['default'].computed.alias('model.employee'),
		eligibleEmployees: _ember['default'].computed('employees', function () {
			var allowedEmployeeTypes = ['RE', 'TE', 'IN'];
			return this.get('employees').filter(function (employee) {
				return allowedEmployeeTypes.includes(employee.get('type'));
			});
		}),
		currentPolicy: _ember['default'].computed('model.employee.pto.companyPto.name', function () {
			var emp = this.get('model.employee');
			return emp ? this.get('model.employee.pto.companyPto.name') || 'No assigned policy' : '';
		}),

		showUseableBalance: _ember['default'].computed('model.employee', 'model.vacationType.account.isLimited', 'model.startDate', function () {
			var startDate = this.get('model.startDate');
			var employeeId = this.get('model.employee.id');
			var vacationTypeId = this.get('model.vacationType.id');
			var isLimited = this.get('model.vacationType.account.isLimited');
			return isLimited && (0, _componentLibraryUtilsGlobals.moment)(startDate, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT, true).isValid() && !Number.isNaN(parseInt(vacationTypeId)) && !Number.isNaN(parseInt(employeeId));
		}),

		useableBalance: _ember['default'].computed('useableBalancePromise.isPending', 'useableBalancePromise.content', function () {
			var useableBalancePromise = this.get('useableBalancePromise');
			if (useableBalancePromise.get('isPending')) {
				return null;
			}
			var useableBalance = Number.parseFloat(useableBalancePromise.get('content'));
			if (Number.isNaN(useableBalance)) {
				return null;
			}

			return useableBalance.toFixed(2);
		}),

		useableBalancePromise: _ember['default'].computed('showUseableBalance', 'model.startDate', function () {
			if (!this.get('showUseableBalance')) {
				return null;
			}

			var startDate = this.get('model.startDate');
			var employeeId = this.get('model.employee.id');
			var vacationTypeId = this.get('model.vacationType.id');

			return _ember['default'].PromiseObject.create({
				promise: _ember['default'].ajaxGet('/custom_api/calculateHoursAndAvailability', {
					vacationType: vacationTypeId,
					employee: employeeId,
					startDate: startDate
				}).then(function (response) {
					return response.startAvailable;
				})['catch'](function () {
					return null;
				})
			});
		})
	});
});