define('component-library/hacky-resolver', ['exports', 'ember', 'ember/resolver', 'component-library/lazy-eval-templates'], function (exports, _ember, _emberResolver, _componentLibraryLazyEvalTemplates) {
	'use strict';

	exports['default'] = _emberResolver['default'].extend(_componentLibraryLazyEvalTemplates['default'], {
		attemptOldParse: function attemptOldParse(name) {
			var parsedName = this.parseName(name);
			parsedName.fullNameWithoutType = parsedName.fullNameWithoutType.split('/').map(function (a) {
				return _ember['default'].String.capitalize(a);
			}).join('');
			parsedName.fullName = parsedName.fullName.split('/').map(function (a) {
				return _ember['default'].String.capitalize(a);
			}).join('');
			parsedName.name = parsedName.name.split('/').map(function (a) {
				return _ember['default'].String.capitalize(a);
			}).join('');
			parsedName.root = window.App;

			var resolveMethodName = parsedName.resolveMethodName;
			var resolved;

			if (!(parsedName.name && parsedName.type)) {
				throw new TypeError('Invalid fullName: `' + name + '`, must be of the form `type:name` ');
			}

			if (this[resolveMethodName]) {
				resolved = this[resolveMethodName](parsedName);
			}

			if (!resolved) {
				resolved = this.resolveOther(parsedName);
			}

			if (parsedName.root && parsedName.root.LOG_RESOLVER) {
				this._logLookup(resolved, parsedName);
			}

			return resolved;
		},

		resolve: function resolve(fullName) {
			window.hackyResolver = this; // I don't know how to access this in a better way globally.. If you do, please replace this.

			var superResults = this._super.apply(this, arguments);

			if (superResults) {
				return superResults;
			}

			var camelResults = this._super.call(this, _ember['default'].String.camelize(fullName));

			if (camelResults) {
				return camelResults;
			}

			var oldParseResult = this.attemptOldParse(fullName);

			if (oldParseResult) {
				return oldParseResult;
			}

			var cache = this._normalizeCache;

			var originalName = Object.keys(cache).find(function (key) {
				var cachedValue = cache[key];

				return cachedValue && cachedValue == fullName && cachedValue != key;
			});

			if (originalName) {
				var parsedName = this.parseName(originalName);
				parsedName.root = window.App;

				var resolveMethodName = parsedName.resolveMethodName;
				var resolved;

				if (!(parsedName.name && parsedName.type)) {
					throw new TypeError('Invalid fullName: `' + name + '`, must be of the form `type:name` ');
				}

				if (this[resolveMethodName]) {
					resolved = this[resolveMethodName](parsedName);
				}

				if (!resolved) {
					resolved = this.resolveOther(parsedName);
				}

				if (parsedName.root && parsedName.root.LOG_RESOLVER) {
					this._logLookup(resolved, parsedName);
				}

				return resolved;
			}

			return null;
		}
	});
});