define('component-library/data-table/cells/deletable-non-sortable-header-cell', ['exports', 'ember-table/views/header-cell'], function (exports, _emberTableViewsHeaderCell) {
	'use strict';

	exports['default'] = _emberTableViewsHeaderCell['default'].extend({
		templateName: 'data-table/cells/deletable-non-sortable-header-cell',
		classNameBindings: [],
		isDeletable: true,

		actions: {
			'delete': function _delete() {
				this.get('content.parentController').send('removePlan', this.get('content'));
			}
		}
	});
});