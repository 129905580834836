define('component-library/utils/payroll/quarters-and-months', ['exports', 'component-library/utils/globals'], function (exports, _componentLibraryUtilsGlobals) {
	'use strict';

	var getQuartersStartingFromCurrentOne = function getQuartersStartingFromCurrentOne(today) {
		today = today || (0, _componentLibraryUtilsGlobals.moment)();
		var year = today.year();

		return [year, year + 1].reduce(function (quarters, currYear, index) {
			var date = (0, _componentLibraryUtilsGlobals.moment)(currYear, 'YYYY');
			var startQuarter = index === 0 ? today.quarter() : 1;
			for (var i = startQuarter; i < 5; i++) {
				quarters.push({
					startDate: date.quarter(i).startOf('quarter').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
					endDate: date.quarter(i).endOf('quarter').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
					label: 'Q' + i + ' ' + currYear,
					id: 'Q' + i + '_' + currYear
				});
			}
			return quarters;
		}, []);
	};

	exports.getQuartersStartingFromCurrentOne = getQuartersStartingFromCurrentOne;

	var getMonthsStartingFromThisMonth = function getMonthsStartingFromThisMonth(today) {
		today = today || (0, _componentLibraryUtilsGlobals.moment)();
		var year = today.year();
		var month = today.month();
		var months = [];
		var monthsStartingFromNextMonth = _componentLibraryUtilsGlobals.MONTHS_ABBREVIATED.slice(month + 1);

		return [monthsStartingFromNextMonth, _componentLibraryUtilsGlobals.MONTHS_ABBREVIATED].reduce(function (monthsArr, currentMonthArr, index) {
			currentMonthArr.forEach(function (month) {
				var monthYear = month + ' ' + (year + index);
				var monthYearObj = (0, _componentLibraryUtilsGlobals.moment)(monthYear, 'MMM YYYY');
				monthsArr.push({
					id: month + '_' + (year + index),
					label: monthYear,
					startDate: monthYearObj.startOf('month').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
					endDate: monthYearObj.endOf('month').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT)
				});
			});
			return monthsArr;
		}, []);
	};
	exports.getMonthsStartingFromThisMonth = getMonthsStartingFromThisMonth;
});