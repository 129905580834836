define('component-library/mixins/payroll/smp/table-with-filters-mixin', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		data: _ember['default'].computed.alias('model'),
		textFilters: _ember['default'].computed(function () {
			return _ember['default'].Object.create();
		}),
		valueFilters: _ember['default'].computed(function () {
			return _ember['default'].Object.create();
		}),
		debounceInterval: 250,
		maxHeight: _ember['default'].computed(function () {
			return 400;
		}),

		maxRows: _ember['default'].computed.alias('data.length'),

		dynamicMaxHeight: (function () {
			if (!this.get('maxHeight')) {
				return null;
			}
			var maxRows = this.get('maxRows');
			if (maxRows > 1) {
				maxRows += 2; //footer and header
			} else {
					maxRows += 1; // just header
				}
			var tableHeight = Math.min(this.get('maxHeight'), maxRows * 38);
			var height = tableHeight + 'px';
			return new _ember['default'].Handlebars.SafeString("height: " + height);
		}).property('maxRows'),

		updateTextFilter: function updateTextFilter(attrKey, value) {
			var filters = this.get('textFilters');
			filters.set(attrKey, value);
			this.set('textFilters', filters);
			_ember['default'].run.debounce(this, this.notifyPropertyChange, 'filteredRows', this.get('debounceInterval'));
		},

		updateValueFilter: function updateValueFilter(attrKey, value) {
			var filters = this.get('valueFilters');
			filters.set(attrKey, value);
			this.set('valueFilters', filters);
			this.notifyPropertyChange('filteredRows');
		},

		filteredRows: (function () {
			var textFilters = this.get('textFilters');
			var valueFilters = this.get('valueFilters');
			return this.get('data').filter(function (row) {
				var unsatisfiedFilter = Object.keys(textFilters).filter(function (attrKey) {
					var text = textFilters.get(attrKey);
					return text && row.get(attrKey).toLowerCase().indexOf(text.toLowerCase()) == -1;
				});
				if (unsatisfiedFilter.length) {
					return false;
				}
				unsatisfiedFilter = Object.keys(valueFilters).filter(function (attrKey) {
					var value = valueFilters.get(attrKey);
					return value !== null && row.get(attrKey) !== value;
				});
				if (unsatisfiedFilter.length) {
					return false;
				}
				return true;
			});
		}).property('data'),

		pluralize: function pluralize(count, name) {
			if (count == 1) {
				return count + ' ' + name;
			}
			return count + ' ' + name + 's';
		},

		hasFooter: (function () {
			return this.get('filteredRows').length > 1;
		}).property('filteredRows')
	});
});