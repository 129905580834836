define('component-library/components/payroll/state-taxes/historical-taxes', ['exports', 'ember', 'component-library/mixins/payroll/state-tax-mixin'], function (exports, _ember, _componentLibraryMixinsPayrollStateTaxMixin) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsPayrollStateTaxMixin['default'], {
		model: null,
		showHistoricalTaxes: false,

		historicalTaxRates: _ember['default'].computed.oneWay('model.historicalTaxRates'),

		quarterBasedTaxes: _ember['default'].computed('historicalTaxRates', function () {
			var _this = this;

			return this.get('historicalTaxRates').map(function (tax) {
				var quarterName = _this.getQuarterName([tax['year'], tax['month']]);
				var rate = _ember['default'].isNone(tax['rate']) ? new _ember['default'].Handlebars.SafeString('&nbsp;&nbsp;&nbsp;-') : Number(tax['rate']) + '%';
				return {
					quarter: quarterName,
					rate: rate
				};
			});
		}),

		actions: {
			viewPastRates: function viewPastRates() {
				this.set('showHistoricalTaxes', true);
			},

			hidePastRates: function hidePastRates() {
				this.set('showHistoricalTaxes', false);
			}
		}
	});
});