define('component-library/proxies/payroll-taxes', ['exports', 'ember', 'lodash/lodash', 'component-library/components/notification-modal', 'component-library/constants/z-payroll/employment-types', 'component-library/constants/z-payroll/z-payroll-earning-categories', 'component-library/constants/z-payroll/z-payroll-contribution-categories'], function (exports, _ember, _lodashLodash, _componentLibraryComponentsNotificationModal, _componentLibraryConstantsZPayrollEmploymentTypes, _componentLibraryConstantsZPayrollZPayrollEarningCategories, _componentLibraryConstantsZPayrollZPayrollContributionCategories) {
	'use strict';

	var IsDirtyMixin = _ember['default'].Mixin.create({
		cleanModel: null,
		isDirty: false,
		currentVersion: 0,

		updateIsDirty: function updateIsDirty() {
			var cleanModel = this.get('cleanModel');
			var content = this.get('content');
			var isEqual = _lodashLodash['default'].isEqual(cleanModel, content);
			var isDirty = !isEqual;
			this.set('isDirty', isDirty);
			if (isDirty) {
				this.setProperties({
					isUserModified: true,
					currentVersion: this.get('currentVersion') + 1
				});
			}
		},

		validate: function validate(field, errorName) {
			this.set(errorName, false);

			var fieldValue = this.get(field);

			// '0' is a valid input for contributions and deductions. Falsy value will be corrected to `0.00` on save.
			if (!fieldValue) {
				return;
			}

			var regex = /^\d*\.?\d+$/;
			if (!regex.test(fieldValue)) {
				this.set(errorName, true);
			}
		}
	});

	var onInitValidateMixin = _ember['default'].Mixin.create({
		onInit: _ember['default'].on('init', function () {
			this.propertiesDidChange();
		})
	});

	var EarningObjectProxy = _ember['default'].ObjectProxy.extend(IsDirtyMixin, onInitValidateMixin, {
		earningTypes: null,
		isCashTip: _ember['default'].computed.oneWay('zpCompanyEarningType.isCashTip'),
		isReimbursement: _ember['default'].computed.oneWay('zpCompanyEarningType.isReimbursement'),
		isImputedPay: _ember['default'].computed.oneWay('zpCompanyEarningType.isImputedPay'),
		isReportedTip: _ember['default'].computed.oneWay('zpCompanyEarningType.isReportedTip'),

		hasAmountError: false,
		hasNumUnitsError: false,
		hasRatePerUnitError: false,
		hasErrors: _ember['default'].computed.or('hasAmountError', 'hasNumUnitsError', 'hasRatePerUnitError'),

		canEditHourAndRate: _ember['default'].computed('isEarningFromLoa', 'isEarningFromPto', 'zpCompanyEarningType.category', 'zpCompanyEarningType.isRatePerUnit', 'zprEmployee.compType', function () {
			var category = this.get('zpCompanyEarningType.category');

			if (this.get('isEarningFromLoa')) {
				return false;
			}

			if (this.get('isEarningFromPto')) {
				return false;
			}

			if (this.get('zpCompanyEarningType.isRatePerUnit')) {
				return true;
			}

			// False if category is regular for CWs and ZPR Comp Type is Fixed Amount
			if (category === _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.CO_REGULAR && this.get('zprEmployee.compType') === _componentLibraryConstantsZPayrollEmploymentTypes.COMP_TYPES.FIXED_OR_ADHOC) {
				return false;
			}

			return _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY_TYPES.HOURLY_TYPES.has(category);
		}),

		unitsAndRatePerUnitsDidChange: _ember['default'].observer('isDirty', 'canEditHourAndRate', 'numUnits', 'ratePerUnit', function () {
			if (this.get('isDirty') && this.get('canEditHourAndRate')) {
				var numUnits = this.get('numUnits') || 0;
				var ratePerUnit = this.get('ratePerUnit') || 0;
				this.set('amount', (numUnits * ratePerUnit).toFixed(2));
			}
		}),

		isEarningFromLoa: _ember['default'].computed.notEmpty('content.zprEmployeeLoa'),
		isEarningFromPto: _ember['default'].computed('canModifyPto', 'isDraftRun', 'isOffCycle', 'isCorrectionRequestPayRun', 'zpCompanyEarningType.category', 'zprEmployee.employee.pto.accounts.@each.isAvailable', 'zprEmployee.employee.pto.availableAccounts.[]', function () {
			if (!this.get('isDraftRun') || this.get('isCorrectionRequestPayRun') || !this.get('canModifyPto') || this.get('isOffCycle')) {
				return false;
			}

			var category = this.get('zpCompanyEarningType.category');
			var accountNames = this.get('zprEmployee.employee.pto.availableAccounts').map(function (account) {
				return account.get('type');
			});

			return accountNames.includes(category);
		}),

		zpCompanyEarningType: _ember['default'].computed('zpCompanyEarningType_id', 'earningTypes', function (key, value) {
			if (arguments.length == 1) {
				var id = this.get('zpCompanyEarningType_id');
				return this.get('earningTypes').findBy('id', id);
			} else {
				this.setProperties({
					name: value.get('name'),
					category: value.get('category'),
					zpCompanyEarningType_id: value.get('id')
				});
				return value;
			}
		}),

		propertiesDidChange: _ember['default'].observer('zpCompanyEarningType', 'numUnits', 'ratePerUnit', 'amount', 'laborFields', function () {
			this.updateIsDirty();
			if (!this.get('isEarningFromPto')) {
				this.validate('amount', 'hasAmountError');
				if (this.get('canEditHourAndRate')) {
					this.validate('numUnits', 'hasNumUnitsError');
					this.validate('ratePerUnit', 'hasRatePerUnitError');
				} else {
					// hour-rate-field doesn't get cleared when type switched
					this.setProperties({
						hasNumUnitsError: false,
						hasRatePerUnitError: false
					});
				}
			}
		})
	});

	var ContributionObjectProxy = _ember['default'].ObjectProxy.extend(IsDirtyMixin, onInitValidateMixin, {
		zprEmployee: null,
		contributionTypes: null,
		employeeContribution: null,

		hasAmountError: false,
		hasErrors: _ember['default'].computed.or('hasAmountError'),

		isMatchContribution: _ember['default'].computed('employeeContribution', function () {
			return this.get('employeeContribution.matchTiers.firstObject') != undefined;
		}),

		matchContribution: _ember['default'].computed('isMatchContribution', 'contribution', function () {
			var isMatchContribution = this.get('isMatchContribution');
			var contribution = this.get('contribution');
			if (isMatchContribution) {
				return parseFloat(contribution).toFixed(2);
			}
			return contribution;
		}),

		inferredContribution: _ember['default'].computed("contribution", "contributionAmount", "isPercentage", "zprEmployee.totalNonImputedEarnings", function () {
			var contributionAmount = this.get('contributionAmount');
			// Use already saved contribution amount as the amendment / adjustments are read only.
			if (this.get('zPayrollRun.isAmendmentAdjustment')) {
				return contributionAmount;
			}
			if (this.get("isPercentage")) {
				var grossPay = this.get("zprEmployee.totalNonImputedEarnings");
				var contribution = this.get("contribution");
				return contribution / 100.0 * grossPay;
			} else {
				return contributionAmount;
			}
		}),

		updateAmount: _ember['default'].observer('inferredContribution', function () {
			this.set('amount', this.get('inferredContribution'));
			this.set('contributionAmount', this.get('inferredContribution'));
		}),

		zpCompanyContributionType: _ember['default'].computed('zpCompanyContributionType_id', 'contributionTypes', function (key, value) {
			if (arguments.length == 1) {
				var id = this.get('zpCompanyContributionType_id');
				return this.get('contributionTypes').findBy('id', id);
			} else {
				this.setProperties({
					name: value.get('name'),
					category: value.get('category'),
					isPercentage: value.get('isPercentage'),
					zpCompanyContributionType_id: value.get('id')
				});
				return value;
			}
		}),

		isCovid19EmployeeRetentionCreditContribution: _ember['default'].computed('category', function () {
			return _componentLibraryConstantsZPayrollZPayrollContributionCategories.COVID19_CONTRIBUTION_CATEGORY_TYPES.COVID19_CONTRIBUTION_EMPLOYEE_RETENTION_CREDIT_TYPES.has(this.get('category'));
		}),
		isCovid19LeaveCreditContribution: _ember['default'].computed('category', function () {
			return _componentLibraryConstantsZPayrollZPayrollContributionCategories.COVID19_CONTRIBUTION_CATEGORY_TYPES.COVID19_CONTRIBUTION_LEAVE_CREDIT_TYPES.has(this.get('category'));
		}),
		isCovid19CobraCreditContribution: _ember['default'].computed('category', function () {
			return _componentLibraryConstantsZPayrollZPayrollContributionCategories.COVID19_CONTRIBUTION_CATEGORY_TYPES.COVID19_CONTRIBUTION_COBRA_CREDIT_TYPES.has(this.get('category'));
		}),
		propertiesDidChange: _ember['default'].observer('category', 'contribution', 'zpCompanyContributionType', function () {
			this.updateIsDirty();
			this.validate('contribution', 'hasAmountError');
		})
	});

	var DeductionObjectProxy = _ember['default'].ObjectProxy.extend(IsDirtyMixin, onInitValidateMixin, {
		zprEmployee: null,
		deductionTypes: null,

		hasAmountError: false,
		hasErrors: _ember['default'].computed.or('hasAmountError'),

		inferredDeduction: _ember['default'].computed("deduction", "deductionAmount", "isPercentage", "zprEmployee.totalNonImputedEarnings", function () {
			var deductionAmount = this.get('deductionAmount');
			// Use already saved deduction amount as the amendment / adjustments are read only.
			if (this.get('zPayrollRun.isAmendmentAdjustment')) {
				return deductionAmount;
			}
			if (this.get("isPercentage")) {
				var grossPay = this.get("zprEmployee.totalNonImputedEarnings");
				var deduction = this.get("deduction");
				return deduction / 100.0 * grossPay;
			} else {
				return deductionAmount;
			}
		}),

		updateAmount: _ember['default'].observer('inferredDeduction', function () {
			this.set('amount', this.get('inferredDeduction'));
			this.set('deductionAmount', this.get('inferredDeduction'));
		}),

		zpCompanyDeductionType: _ember['default'].computed('zpCompanyDeductionType_id', 'deductionTypes', function (key, value) {
			if (arguments.length == 1) {
				var id = this.get('zpCompanyDeductionType_id');
				return this.get('deductionTypes').findBy('id', id);
			} else {
				this.setProperties({
					name: value.get('name'),
					category: value.get('category'),
					isPercentage: value.get('isPercentage'),
					zpCompanyDeductionType_id: value.get('id')
				});
				return value;
			}
		}),

		propertiesDidChange: _ember['default'].observer('category', 'deduction', 'zpCompanyDeductionType', function () {
			this.updateIsDirty();
			this.validate('deduction', 'hasAmountError');
		})
	});

	var TaxObjectProxy = _ember['default'].ObjectProxy.extend(IsDirtyMixin, {
		zprEmployee: null,

		propertiesDidChange: _ember['default'].observer('subjectWage', 'grossSubjectWage', 'amount', 'userEnteredAmount', function () {
			this.updateIsDirty();
		})
	});

	var TaxCreditObjectProxy = _ember['default'].ObjectProxy.extend(IsDirtyMixin, {
		zprEmployee: null,

		propertiesDidChange: _ember['default'].observer('taxType', 'amount', function () {
			this.updateIsDirty();
		})
	});

	var GarnishmentObjectProxyV2 = _ember['default'].ObjectProxy.extend(IsDirtyMixin, onInitValidateMixin, {
		zprEmployee: null,
		hasAmountError: false,
		hasErrors: _ember['default'].computed.or('hasAmountError'),

		propertiesDidChange: _ember['default'].observer('name', 'displayAmount', 'garnishmentAmount', 'garnishmentTypes', 'isAmountOverriden', function () {
			this.updateIsDirty();
			this.validate('garnishmentAmount', 'hasAmountError');
		})
	});

	exports['default'] = _ember['default'].ObjectProxy.extend({
		employee: null,
		deductionTypes: null,
		contributionTypes: null,
		zpEmployeeContributions: [],
		matchTiers: [],
		earningTypes: null,
		reimbursementTypes: null,
		garnishmentTypes: null,
		isInitPayrollRun: false,
		zPayrollRun: null,
		isOffCycle: null,
		isDraftRun: null,
		isCorrectionRequestPayRun: null,
		canModifyPto: null,
		switches: null,
		eventLogger: null,

		employeeTaxes: _ember['default'].computed.filterByProperty('taxes', 'taxPayee', 'EE'),
		employerTaxes: _ember['default'].computed.filterByProperty('taxes', 'taxPayee', 'ER'),
		tipTaxes: _ember['default'].computed.filterByProperty('taxCredits', 'uncollectedType', 'REPORTED_TIPS'),
		nonDeletedEarnings: _ember['default'].computed.rejectByProperty('earnings', 'willDelete'),
		nonDeletedReimbursements: _ember['default'].computed.rejectByProperty('reimbursements', 'willDelete'),
		nonDeletedContributions: _ember['default'].computed.rejectByProperty('contributions', 'willDelete'),
		nonDeletedDeductions: _ember['default'].computed.rejectByProperty('deductions', 'willDelete'),
		nonDeletedGarnishments: _ember['default'].computed.rejectByProperty('garnishments', 'willDelete'),
		nonDeletedUncollectedTaxes: _ember['default'].computed.rejectByProperty('tipTaxes', 'willDelete'),
		nonDeletedEmployeeTaxes: _ember['default'].computed.rejectByProperty('employeeTaxes', 'willDelete'),
		nonDeletedEmployerTaxes: _ember['default'].computed.rejectByProperty('employerTaxes', 'willDelete'),

		init: function init() {
			this._super();
			this.calculateTax = _lodashLodash['default'].debounce(this.calculateTax, 300);
			this.calculateContributionMatches = _lodashLodash['default'].debounce(this.calculateContributionMatches, 300);
		},

		earnings: _ember['default'].computed('content.earnings.@each.isReimbursement', function () {
			var _this = this;

			return this.get('content.earnings').rejectBy('isReimbursement').map(function (earning) {
				return EarningObjectProxy.create({
					cleanModel: _lodashLodash['default'].clone(earning, true),
					content: earning,
					earningTypes: _this.get('earningTypes'),
					zprEmployee: _this,
					isOffCycle: _this.get('isOffCycle'),
					isDraftRun: _this.get('isDraftRun'),
					isCorrectionRequestPayRun: _this.get('isCorrectionRequestPayRun'),
					canModifyPto: _this.get('canModifyPto'),
					switches: _this.get('switches'),
					zPayrollRun: _this.get('zPayrollRun')
				});
			});
		}),

		reimbursements: _ember['default'].computed('content.earnings.@each.isReimbursement', function () {
			var _this2 = this;

			return this.get('content.earnings').filterBy('isReimbursement').map(function (reimbursement) {
				return EarningObjectProxy.create({
					cleanModel: _lodashLodash['default'].clone(reimbursement, true),
					content: reimbursement,
					earningTypes: _this2.get('reimbursementTypes'),
					zprEmployee: _this2
				});
			});
		}),

		contributions: _ember['default'].computed('content.contributions.[]', function () {
			var _this3 = this;

			return this.get('content.contributions').map(function (contribution) {

				var employeeContributionId = contribution.zpEmployeeContribution_id;

				var employeeContribution = _this3.get('zpEmployeeContributions').find(function (zpEmployeeContribution) {
					return zpEmployeeContribution.get('id') == employeeContributionId;
				});

				return ContributionObjectProxy.create({
					cleanModel: _lodashLodash['default'].clone(contribution, true),
					content: contribution,
					contributionTypes: _this3.get('contributionTypes'),
					employeeContribution: employeeContribution,
					zprEmployee: _this3,
					zPayrollRun: _this3.get('zPayrollRun')
				});
			});
		}),

		deductions: _ember['default'].computed('content.deductions.[]', function () {
			var _this4 = this;

			return this.get('content.deductions').map(function (deduction) {
				return DeductionObjectProxy.create({
					cleanModel: _lodashLodash['default'].clone(deduction, true),
					content: deduction,
					deductionTypes: _this4.get('deductionTypes'),
					zprEmployee: _this4,
					zPayrollRun: _this4.get('zPayrollRun')
				});
			});
		}),

		taxes: _ember['default'].computed('content.taxes.[]', function () {
			var _this5 = this;

			return this.get('content.taxes').map(function (tax) {
				return TaxObjectProxy.create({
					cleanModel: _lodashLodash['default'].clone(tax, true),
					content: tax,
					zprEmployee: _this5
				});
			});
		}),

		taxCredits: _ember['default'].computed('content.taxCredits.[]', function () {
			var _this6 = this;

			return this.get('content.taxCredits').map(function (taxCredit) {
				return TaxCreditObjectProxy.create({
					cleanModel: _lodashLodash['default'].clone(taxCredit, true),
					content: taxCredit,
					zprEmployee: _this6
				});
			});
		}),

		garnishments: _ember['default'].computed('content.garnishments.[]', function () {
			var _this7 = this;

			return this.get('content.garnishments').map(function (garnishment) {
				return GarnishmentObjectProxyV2.create({
					cleanModel: _lodashLodash['default'].clone(garnishment, true),
					content: garnishment,
					garnishmentTypes: _this7.get('garnishmentTypes'),
					zprEmployee: _this7
				});
			});
		}),

		///////////////////////////////////////////////////////////////////////////
		// isDirty Logic
		///////////////////////////////////////////////////////////////////////////

		hasInvalidPay: _ember['default'].computed('nonDeletedEarnings.@each.hasErrors', 'nonDeletedReimbursements.@each.hasErrors', 'nonDeletedContributions.@each.hasErrors', 'nonDeletedDeductions.@each.hasErrors', 'nonDeletedGarnishments.@each.hasErrors', function () {
			var hasEarningErrors = this.get('nonDeletedEarnings').any(function (pay) {
				return pay.get('hasErrors');
			});
			var hasReimbursementErrors = this.get('nonDeletedReimbursements').any(function (pay) {
				return pay.get('hasErrors');
			});
			var hasDeductionErrors = this.get('nonDeletedDeductions').any(function (pay) {
				return pay.get('hasErrors');
			});
			var hasContributionErrors = this.get('nonDeletedContributions').any(function (pay) {
				return pay.get('hasErrors');
			});
			var hasGarnishmentErrors = this.get('nonDeletedGarnishments').any(function (pay) {
				return pay.get('hasErrors');
			});
			return hasEarningErrors || hasReimbursementErrors || hasDeductionErrors || hasContributionErrors || hasGarnishmentErrors;
		}),

		matchableAmountDidChange: _ember['default'].observer('nonDeletedEarnings.@each.currentVersion', 'nonDeletedDeductions.@each.currentVersion', function () {
			var cleanModel = this.get('cleanModel');
			var content = this.get('content');
			var isEqual = _lodashLodash['default'].isEqual(cleanModel, content);
			this.set('isDirty', !isEqual);
			this.calculateContributionMatches();
		}),

		paystubDidChange: _ember['default'].observer('nonDeletedEarnings.@each.currentVersion', 'nonDeletedReimbursements.@each.currentVersion', 'nonDeletedContributions.@each.currentVersion', 'nonDeletedDeductions.@each.currentVersion', 'nonDeletedGarnishments.@each.currentVersion', function () {
			var cleanModel = this.get('cleanModel');
			var content = this.get('content');
			var isEqual = _lodashLodash['default'].isEqual(cleanModel, content);
			this.set('isDirty', !isEqual);
			this.calculateTax();
		}),

		taxesDidChange: _ember['default'].observer('nonDeletedEmployeeTaxes.@each.currentVersion', 'nonDeletedEmployerTaxes.@each.currentVersion', function () {
			// Taxes can be edited on init run and and draft run and should be allowed to dirty the editor
			if (!this.get('isInitPayrollRun') && !this.get('isDraftRun')) {
				return;
			}

			var isEqual = _lodashLodash['default'].isEqual(this.get('cleanModel'), this.get('content'));
			this.set('isDirty', !isEqual);
		}),

		taxCreditsDidChange: _ember['default'].observer('nonDeletedUncollectedTaxes.@each.currentVersion', function () {
			// Taxes can be edited on init run and should be allowed to dirty the editor
			if (!this.get('isInitPayrollRun')) {
				return;
			}

			var isEqual = _lodashLodash['default'].isEqual(this.get('cleanModel'), this.get('content'));
			this.set('isDirty', !isEqual);
		}),

		calculateContributionMatches: function calculateContributionMatches() {
			var _this8 = this;

			var request = {
				id: this.get('id'),
				earnings: this.get('content.earnings').rejectBy('willDelete'),
				deductions: this.get('content.deductions').rejectBy('willDelete'),
				contributions: this.get('content.contributions').rejectBy('willDelete')
			};

			_ember['default'].ajax({
				url: '/custom_api/z_payroll/calculate_zpr_employee_contribution_match/',
				type: 'POST',
				contentType: 'application/json',
				dataType: 'json',
				data: JSON.stringify(request)
			}).then(function (jsonData) {
				jsonData.contributionValues.forEach(function (updatedContribution) {

					var contributionToUpdate = _this8.get('contributions').find(function (contribution) {
						return contribution.get('id') == updatedContribution.id;
					});

					/*
     	Currently there is no UI for admin to edit match tiers
     	Match percent is calculated on the fly based on gross pay
     	Anytime earnings or deductions change, this recalculates
     	(recalc is triggered from the Ember observer `matchableAmountDidChange`)
     */
					contributionToUpdate.set('contribution', updatedContribution.value);

					/*
     	When pay type limits are enforced, the benefit will change to isPercentage=false
     	and the contribution amount will be reduced so as not to exceed the limit.
     	However, if we are recalculating the match percent because of a change to gross pay,
     	we need to set back isPercentage=true.
     	Pay type limits are enforced after the admin clicks "Save".
     */
					contributionToUpdate.set('isPercentage', true);

					/*
     	We need to set back isUserModified=false because `updateIsDirty` sets
     	isUserModified=true if the match percent changed but pay type limits
     	are only enforced if isUserModified=true
     */
					contributionToUpdate.set('isUserModified', false);
				});
			})['catch'](function (err) {
				_this8.get('eventLogger').log('calculate_contribution_matches_failure', {
					zprEmployeeId: _this8.get('id')
				});

				_componentLibraryComponentsNotificationModal['default'].show({
					notificationType: 'error',
					title: 'Oops!',
					message: 'Some contributions failed to refresh. Please try again.'
				});
			});
		},

		///////////////////////////////////////////////////////////////////////////
		// Tax Simulation
		///////////////////////////////////////////////////////////////////////////

		calculateTax: function calculateTax() {
			var _this9 = this;

			// don't run tax simulation if init run (so any tax is editable) this will cause conflicts
			if (this.get('isInitPayrollRun')) {
				return;
			}

			var request = _lodashLodash['default'].clone(this.get('content'), true);
			request.earnings = request.earnings.rejectBy('willDelete');
			request.deductions = request.deductions.rejectBy('willDelete');
			request.contributions = request.contributions.rejectBy('willDelete');
			request.earnings.setEach('id', null);
			request.deductions.setEach('id', null);
			request.contributions.setEach('id', null);
			return _ember['default'].ajax({
				url: '/custom_api/z_payroll/calculate_zpr_employee_taxes/',
				type: 'POST',
				contentType: 'application/json',
				dataType: 'json',
				data: JSON.stringify(request)
			}).then(function (jsonData) {
				// Save a list of taxes already calculated
				var oldTaxes = {};
				_this9.get('taxes').forEach(function (tax) {
					oldTaxes[_ember['default'].get(tax, 'zpTaxMeta_id')] = tax;
				});

				if (_this9.get('zPayrollRun.isThirdPartySickPay')) {
					var updatedTaxes = jsonData.taxes.map(function (calculatedTax) {
						if (calculatedTax.taxPayee === 'ER') {
							return calculatedTax;
						}

						// Update new calculations with old UEA if available else default it to zero
						calculatedTax.userEnteredAmount = 0;
						if (oldTaxes[calculatedTax.zpTaxMeta_id] != undefined) {
							calculatedTax.id = _ember['default'].get(oldTaxes[calculatedTax.zpTaxMeta_id], 'id');
							calculatedTax.userEnteredAmount = _ember['default'].get(oldTaxes[calculatedTax.zpTaxMeta_id], 'userEnteredAmount') || 0;
							calculatedTax.systemGeneratedAmount = calculatedTax.amount;
						}
						return calculatedTax;
					});
					_this9.set('taxes', updatedTaxes);
					_this9.set('content.taxes', updatedTaxes);
				} else {
					var updatedTaxes = jsonData.taxes;
					var isFitSitOverrideEnabled = _this9.get('content.isFitSitEnabledCompany');
					if (isFitSitOverrideEnabled) {
						(function () {
							var syncedTaxes = {};
							_this9.get('content.taxes').forEach(function (tax) {
								syncedTaxes[_ember['default'].get(tax, 'zpTaxMeta_id')] = tax;
							});
							var me = _this9;
							me.set('content.taxesNotSynced', false);
							updatedTaxes = jsonData.taxes.map(function (calculatedTax) {
								if (!(calculatedTax.taxType === 'FIT' || calculatedTax.taxType === 'SIT')) {
									return calculatedTax;
								}
								// Update new calculations with old UEA
								var oldTax = oldTaxes[calculatedTax.zpTaxMeta_id];
								if (oldTax != undefined) {
									if (_ember['default'].isEmpty(_ember['default'].get(oldTax, 'userEnteredAmount'))) {
										return calculatedTax;
									}
									calculatedTax.id = _ember['default'].get(oldTax, 'id');
									calculatedTax.userEnteredAmount = _ember['default'].get(oldTax, 'userEnteredAmount');
									calculatedTax.amount = _ember['default'].get(oldTax, 'amount');
									calculatedTax.changeHistory = _ember['default'].get(oldTax, 'changeHistory');
								}
								var syncedTax = syncedTaxes[calculatedTax.zpTaxMeta_id];
								if (syncedTax == undefined) {
									me.set('content.taxesNotSynced', true);
								}
								return calculatedTax;
							});
							updatedTaxes.sort(function (tax1, tax2) {
								return tax1.zpTaxMeta_id - tax2.zpTaxMeta_id;
							});
						})();
					}

					_this9.set('taxes', updatedTaxes);
					var calculatedTaxCredit = jsonData.taxCredits.map(function (taxCredit) {
						return taxCredit;
					});
					_this9.set('taxCredits', calculatedTaxCredit);
					_this9.set('content.taxCredits', calculatedTaxCredit);
				}
			});
		},

		///////////////////////////////////////////////////////////////////////////
		// front end calculations
		///////////////////////////////////////////////////////////////////////////

		totalEarnings: _ember['default'].computed('nonDeletedEarnings.@each.amount', function () {
			return this.getTotal(this.get('nonDeletedEarnings'), 'amount');
		}),

		totalNonImputedEarnings: _ember['default'].computed('nonDeletedEarnings.@each.amount', 'nonDeletedEarnings.@each.regularEarnings', 'nonDeletedEarnings.@each.isCashTip', 'nonDeletedEarnings.@each.isImputedPay', 'nonDeletedEarnings.@each.isReportedTip', function () {
			var nonImputedEarnings = this.get('nonDeletedEarnings').rejectBy('isCashTip').rejectBy('isImputedPay').rejectBy('isReportedTip');
			return this.getTotal(nonImputedEarnings, 'amount');
		}),

		totalReimbursements: _ember['default'].computed('nonDeletedReimbursements.@each.amount', function () {
			return this.getTotal(this.get('nonDeletedReimbursements'), 'amount');
		}),

		totalEmployeeTaxes: _ember['default'].computed('nonDeletedEmployeeTaxes.@each.amount', 'nonDeletedEmployeeTaxes.@each.userEnteredAmount', function () {
			var total = this.getTotal(this.get('nonDeletedEmployeeTaxes'), 'amount');
			if (this.get('zPayrollRun.isThirdPartySickPay')) {
				total = this.getTotal(this.get('nonDeletedEmployeeTaxes'), 'userEnteredAmount');
			}
			return total;
		}),

		totalEmployerTaxes: _ember['default'].computed('nonDeletedEmployerTaxes.@each.amount', function () {
			return this.getTotal(this.get('nonDeletedEmployerTaxes'), 'amount');
		}),

		totalGarnishments: _ember['default'].computed('garnishments.@each.garnishmentAmount', function () {
			return this.getTotal(this.get('nonDeletedGarnishments'), 'garnishmentAmount');
		}),

		totalDeductions: _ember['default'].computed('nonDeletedDeductions.@each.inferredDeduction', function () {
			return this.getTotal(this.get('nonDeletedDeductions'), 'inferredDeduction');
		}),

		totalContributions: _ember['default'].computed('nonDeletedContributions.@each.inferredContribution', function () {
			return this.getTotal(this.get('nonDeletedContributions'), 'inferredContribution');
		}),

		totalCashTips: _ember['default'].computed('nonDeletedEarnings.@each.amount', 'nonDeletedEarnings.@each.isCashTip', function () {
			var cashTips = this.get('nonDeletedEarnings').filterBy('isCashTip');
			return this.getTotal(cashTips, 'amount');
		}),

		totalReportedTips: _ember['default'].computed('nonDeletedEarnings.@each.amount', 'nonDeletedEarnings.@each.isReportedTip', function () {
			var reportedTips = this.get('nonDeletedEarnings').filterBy('isReportedTip');
			return this.getTotal(reportedTips, 'amount');
		}),

		totalImputedPay: _ember['default'].computed('nonDeletedEarnings.@each.amount', 'nonDeletedEarnings.@each.category', function () {
			var scorpEarnings = this.get('nonDeletedEarnings').filterBy('category', _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.SCORP_HEALTH_AND_DISABILITY);
			var gtlEarnings = this.get('nonDeletedEarnings').filterBy('category', _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.EXCESS_GTL);
			var rsuDeferralEarnings = this.get('nonDeletedEarnings').filterBy('category', _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.RSU_DEFERRAL);
			var generalImputedEarnings = this.get('nonDeletedEarnings').filterBy('category', _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.GENERAL_IMPUTED_INCOME);
			return this.getTotal(scorpEarnings, 'amount') + this.getTotal(gtlEarnings, 'amount') + this.getTotal(rsuDeferralEarnings, 'amount') + this.getTotal(generalImputedEarnings, 'amount');
		}),

		totalUncollectedTaxes: _ember['default'].computed('nonDeletedUncollectedTaxes.@each.amount', function () {
			return this.getTotal(this.get('nonDeletedUncollectedTaxes'), 'amount');
		}),

		// this is just an estimate. We use this while we are editing the employee
		// paystub in the payroll product and changes are not yet committed to the
		// server. So we are just estimating it on the front end
		estimatedNetPay: _ember['default'].computed('totalEarnings', 'totalReimbursements', 'totalDeductions', 'totalGarnishments', 'totalEmployeeTaxes', 'totalCashTips', 'totalImputedPay', 'netPay', 'totalNonImputedEarnings', 'totalReportedTips', function () {
			if (this.get('totalNonImputedEarnings') === 0 && this.get('totalDeductions') === 0 && this.get('totalReportedTips') > 0) {
				if (this.get('totalReimbursements')) {
					return this.get('totalReimbursements');
				} else {
					return 0;
				}
			}
			var estimatedNet = this.get('totalEarnings') + this.get('totalReimbursements') - this.get('totalDeductions') - this.get('totalGarnishments') - this.get('totalEmployeeTaxes') - this.get('totalCashTips') - this.get('totalImputedPay') - this.get('totalReportedTips');
			if (Math.abs(estimatedNet) < 0.01) {
				return 0;
			} else {
				return estimatedNet;
			}
		}),

		hasZeroCompensation: _ember['default'].computed('totalNonImputedEarnings', 'totalReimbursements', 'totalDeductions', 'totalContributions', 'totalGarnishments', 'totalEmployeeTaxes', 'totalEmployeeTaxes', function () {
			return this.get('totalNonImputedEarnings') === 0 && this.get('totalReimbursements') === 0 && this.get('totalDeductions') === 0 && this.get('totalContributions') === 0 && this.get('totalGarnishments') === 0 && this.get('totalEmployeeTaxes') === 0 && this.get('totalEmployeeTaxes') === 0;
		}),

		totalCost: _ember['default'].computed('totalEarnings', 'totalReimbursements', 'totalEmployerTaxes', 'totalContributions', 'zPayrollRun.isThirdPartySickPay', 'totalImputedPay', 'totalReportedTips', function () {
			if (this.get('zPayrollRun.isThirdPartySickPay')) {
				return this.get('totalEmployerTaxes');
			} else {
				return this.get('totalEarnings') + this.get('totalReimbursements') + this.get('totalEmployerTaxes') + this.get('totalContributions') - this.get('totalReportedTips') - this.get('totalImputedPay');
			}
		}),

		getTotal: function getTotal(array, key) {
			var total = 0;
			array.getEach(key).forEach(function (value) {
				value = parseFloat(value);
				if (!isNaN(value)) {
					total += value;
				}
			});
			return total;
		}
	});
});