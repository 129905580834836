define('component-library/utils/tags/context-tag-factory', ['exports', 'ember', 'component-library/utils/tags/default'], function (exports, _ember, _componentLibraryUtilsTagsDefault) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports['default'] = function (context) {
		return function tag(strings) {
			for (var _len = arguments.length, keys = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
				keys[_key - 1] = arguments[_key];
			}

			var values = keys.map(function (key) {
				return _ember['default'].get(context, key);
			});
			return _componentLibraryUtilsTagsDefault['default'].apply(undefined, [strings].concat(_toConsumableArray(values)));
		};
	};
});