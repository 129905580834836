define('component-library/constants/lines-of-coverage', ['exports'], function (exports) {
	'use strict';

	var _LOCS_TO_ICONS;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
		} else {
			obj[key] = value;
		}return obj;
	}

	var MEDICAL = 'medical';
	exports.MEDICAL = MEDICAL;

	var DENTAL = 'dental';
	exports.DENTAL = DENTAL;

	var VISION = 'vision';
	exports.VISION = VISION;

	var LIFE = 'lifenew';
	exports.LIFE = LIFE;

	var ADND = 'add';
	exports.ADND = ADND;

	var STD = 'std';
	exports.STD = STD;

	var LTD = 'ltd';
	exports.LTD = LTD;

	var ACCIDENT = 'acc';
	exports.ACCIDENT = ACCIDENT;

	var CANCER = 'cancer';
	exports.CANCER = CANCER;

	var CRITICAL_ILLNESS = 'ci';
	exports.CRITICAL_ILLNESS = CRITICAL_ILLNESS;

	var HOSPITAL_INDEMNITY = 'hi';

	exports.HOSPITAL_INDEMNITY = HOSPITAL_INDEMNITY;

	var LOCS = {
		linesOfCoverage: [MEDICAL, DENTAL, VISION, ACCIDENT, CANCER, CRITICAL_ILLNESS, HOSPITAL_INDEMNITY, LIFE, ADND, STD, LTD, 'transit_benefits', 'transit_benefits_post', 'parking_benefits', 'parking_benefits_post', 'fsa', 'fsa_dependent', 'health_savings', 'group_term_life_insurance', 'vol_std_insurance', 'vol_ltd_insurance'],
		MDVLifeLinesOfCoverage: [MEDICAL, DENTAL, VISION, LIFE, ADND, STD, LTD],
		SupplementalLinesOfCoverage: [ACCIDENT, CANCER, CRITICAL_ILLNESS, HOSPITAL_INDEMNITY],
		MDVSupplementalLifeLinesOfCoverage: [MEDICAL, DENTAL, VISION, ACCIDENT, CANCER, CRITICAL_ILLNESS, HOSPITAL_INDEMNITY, LIFE, ADND, STD, LTD],
		MDVLinesOfCoverage: [MEDICAL, DENTAL, VISION],
		linesOfCoverageWithBasePlan: [MEDICAL, DENTAL],
		lifeLinesOfCoverage: [LIFE, ADND, STD, LTD]
	};

	exports.LOCS = LOCS;

	var LOCS_TO_DISPLAY_NAME = {
		medical: 'Medical',
		dental: 'Dental',
		vision: 'Vision',
		lifenew: 'Life',
		ltd: 'Long-Term Disability',
		std: 'Short-Term Disability',
		add: 'AD&D',
		acc: 'Accident',
		cancer: 'Cancer',
		hi: 'Hospital Indemnity',
		ci: 'Critical Illness'
	};

	exports.LOCS_TO_DISPLAY_NAME = LOCS_TO_DISPLAY_NAME;

	var LOC_ALIASES = LOCS.MDVSupplementalLifeLinesOfCoverage.reduce(function (hash, value) {
		hash[value] = value;
		return hash;
	}, {});
	exports.LOC_ALIASES = LOC_ALIASES;

	LOC_ALIASES[LIFE] = 'life';

	// Icons
	var LOCS_TO_ICONS = (_LOCS_TO_ICONS = {}, _defineProperty(_LOCS_TO_ICONS, MEDICAL, '/static/img/icons/zapps/icon-z2-medical.svg'), _defineProperty(_LOCS_TO_ICONS, DENTAL, '/static/img/icons/zapps/icon-z2-dental.svg'), _defineProperty(_LOCS_TO_ICONS, VISION, '/static/img/icons/zapps/icon-z2-vision.svg'), _defineProperty(_LOCS_TO_ICONS, LIFE, '/static/img/icons/zapps/icon-z2-lifeInsurance.svg'), _defineProperty(_LOCS_TO_ICONS, ADND, '/static/img/icons/zapps/icon-z2-lifeInsurance.svg'), _defineProperty(_LOCS_TO_ICONS, LTD, '/static/img/icons/zapps/icon-z2-disability.svg'), _defineProperty(_LOCS_TO_ICONS, STD, '/static/img/icons/zapps/icon-z2-disability.svg'), _defineProperty(_LOCS_TO_ICONS, ACCIDENT, '/static/img/icons/zapps/icon-z2-supplemental.svg'), _defineProperty(_LOCS_TO_ICONS, CANCER, '/static/img/icons/zapps/icon-z2-supplemental.svg'), _defineProperty(_LOCS_TO_ICONS, CRITICAL_ILLNESS, '/static/img/icons/zapps/icon-z2-supplemental.svg'), _defineProperty(_LOCS_TO_ICONS, HOSPITAL_INDEMNITY, '/static/img/icons/zapps/icon-z2-supplemental.svg'), _LOCS_TO_ICONS);
	exports.LOCS_TO_ICONS = LOCS_TO_ICONS;
});