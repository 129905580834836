define('component-library/constants/enrollment-routes', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	// enrollment types
	var ENROLLMENT_FLOWS = {
		APR: 'openEnrollment',
		NGE: 'newGroupEnrollment',
		PLAN_SHOPPING: 'selfServiceRenewals'
	};

	// enrollment section keys
	var APR_SECTION_KEY = {
		SETUP: {
			BASE: ENROLLMENT_FLOWS.APR + '.setup',
			INTRO: ENROLLMENT_FLOWS.APR + '.setup.intro',
			SELECT_MEDICAL: ENROLLMENT_FLOWS.APR + '.setup.selectPlanMedical',
			SELECT_DENTAL: ENROLLMENT_FLOWS.APR + '.setup.selectPlanDental',
			SELECT_VISION: ENROLLMENT_FLOWS.APR + '.setup.selectPlanVision',
			SELECT_LIFE: ENROLLMENT_FLOWS.APR + '.setup.selectPlanLife',
			SELECT_ADD: ENROLLMENT_FLOWS.APR + '.setup.selectPlanAdd',
			SELECT_STD: ENROLLMENT_FLOWS.APR + '.setup.selectPlanStd',
			SELECT_LTD: ENROLLMENT_FLOWS.APR + '.setup.selectPlanLtd',
			CONTRIBUTION_MEDICAL: ENROLLMENT_FLOWS.APR + '.setup.contributionSchemeMedical',
			CONTRIBUTION_DENTAL: ENROLLMENT_FLOWS.APR + '.setup.contributionSchemeDental',
			CONTRIBUTION_VISION: ENROLLMENT_FLOWS.APR + '.setup.contributionSchemeVision',
			WAITING_PERIOD: ENROLLMENT_FLOWS.APR + '.setup.waitingPeriod',
			LEAVE: ENROLLMENT_FLOWS.APR + '.setup.leaveOfAbsence'
		},
		ENROLLMENT: {
			BASE: ENROLLMENT_FLOWS.APR + '.enrollment',
			COMPANY_INFO: ENROLLMENT_FLOWS.APR + '.enrollment.companyInfo',
			SIC: ENROLLMENT_FLOWS.APR + '.enrollment.companySic',
			ADDRESS: ENROLLMENT_FLOWS.APR + '.enrollment.companyAddress',
			OWNERSHIP: ENROLLMENT_FLOWS.APR + '.enrollment.companyOwnership',
			SUBSIDIARIES: ENROLLMENT_FLOWS.APR + '.enrollment.subsidiaries',
			CONTACT: ENROLLMENT_FLOWS.APR + '.enrollment.adminContact',
			EXEC_OFFICE: ENROLLMENT_FLOWS.APR + '.enrollment.principalExecutiveOffice',
			HISTORY: ENROLLMENT_FLOWS.APR + '.enrollment.companyHistory',
			PEO: ENROLLMENT_FLOWS.APR + '.enrollment.companyPeo',
			PREV_MEDICAL: ENROLLMENT_FLOWS.APR + '.enrollment.previousCoverageMedical',
			PREV_DENTAL: ENROLLMENT_FLOWS.APR + '.enrollment.previousCoverageDental',
			PREV_VISION: ENROLLMENT_FLOWS.APR + '.enrollment.previousCoverageVision',
			REIMBURSEMENT_ACCOUNT: ENROLLMENT_FLOWS.APR + '.enrollment.healthReimbursementAccount',
			WORKERS_COMPENSATION: ENROLLMENT_FLOWS.APR + '.enrollment.workersCompensation',
			GUARDIAN_INJURY: ENROLLMENT_FLOWS.APR + '.enrollment.guardianSeriousInjury',
			UNABLE_WORK: ENROLLMENT_FLOWS.APR + '.enrollment.unableToWork',
			OWNER_UNDER_PLAN_EARNING: ENROLLMENT_FLOWS.APR + '.enrollment.ownerUnderPlanEarning',
			EARNINGS_DIFFER_BY: ENROLLMENT_FLOWS.APR + '.enrollment.earningsDifferBy',
			THIRTY_HOURS_FULLTIME: ENROLLMENT_FLOWS.APR + '.enrollment.thirtyHoursFulltime',
			INDIVIDUAL_FICA_EXCEPTION: ENROLLMENT_FLOWS.APR + '.enrollment.individualFicaExemption',
			SIGNIFICANT_HEALTH_RISKS: ENROLLMENT_FLOWS.APR + '.enrollment.significantHealthRisks',
			DISABILITY_STATUTORY_PLANS: ENROLLMENT_FLOWS.APR + '.enrollment.disabilityStatutoryPlans',
			EMPLOYEE_LEAVE: ENROLLMENT_FLOWS.APR + '.enrollment.employeeLeave',
			COBRA: ENROLLMENT_FLOWS.APR + '.enrollment.cobra',
			ADDITIONAL_QUESTIONS: ENROLLMENT_FLOWS.APR + '.enrollment.additionalQuestions'
		},
		ACKNOWLEDGE: {
			BASE: ENROLLMENT_FLOWS.APR + '.acknowledge',
			SUBSIDIARY_SETUP: ENROLLMENT_FLOWS.APR + '.acknowledge.subsidiarySetup',
			SWITCH_OVERVIEW: ENROLLMENT_FLOWS.APR + '.acknowledge.switchCancellationOverview',
			SWITCH_SELECTION: ENROLLMENT_FLOWS.APR + '.acknowledge.switchCancellationSelection',
			SWITCH_LETTER: ENROLLMENT_FLOWS.APR + '.acknowledge.switchCancellationLetter',
			GA_CONTRACT: ENROLLMENT_FLOWS.APR + '.acknowledge.guardianContract',
			VSP_CONTRACT: ENROLLMENT_FLOWS.APR + '.acknowledge.vspContract',
			PARTICIPATION: ENROLLMENT_FLOWS.APR + '.acknowledge.acknowledgeParticipationRules',
			IN_STATE: ENROLLMENT_FLOWS.APR + '.acknowledge.acknowledgeOutOfStateRules',
			BANK_DETAILS: ENROLLMENT_FLOWS.APR + '.acknowledge.bankDetails',
			BROKER_RECOMMENDATIONS: ENROLLMENT_FLOWS.APR + '.acknowledge.brokerRecommendations',
			AGREEMENT: ENROLLMENT_FLOWS.APR + '.acknowledge.agreement',
			OE_PERIOD: ENROLLMENT_FLOWS.APR + '.acknowledge.openEnrollmentPeriod',
			INVITE: ENROLLMENT_FLOWS.APR + '.acknowledge.invite'
		}
	};

	var NGE_SECTION_KEY = {
		SETUP: {
			BASE: ENROLLMENT_FLOWS.NGE + '.setup',
			WAITING_PERIOD: ENROLLMENT_FLOWS.NGE + '.setup.waitingPeriod',
			ABSENCE: ENROLLMENT_FLOWS.NGE + '.setup.leaveOfAbsence',
			CONTRIBUTION_MEDICAL: ENROLLMENT_FLOWS.NGE + '.setup.contributionSchemeMedical',
			CONTRIBUTION_DENTAL: ENROLLMENT_FLOWS.NGE + '.setup.contributionSchemeDental',
			CONTRIBUTION_VISION: ENROLLMENT_FLOWS.NGE + '.setup.contributionSchemeVision'
		},
		ENROLLMENT: {
			BASE: ENROLLMENT_FLOWS.NGE + '.enrollment',
			COMPANY_INFO: ENROLLMENT_FLOWS.NGE + '.enrollment.companyInfo',
			SIC: ENROLLMENT_FLOWS.NGE + '.enrollment.companySic',
			ADDRESS: ENROLLMENT_FLOWS.NGE + '.enrollment.companyAddress',
			OWNERSHIP: ENROLLMENT_FLOWS.NGE + '.enrollment.companyOwnership',
			PAYROLL: ENROLLMENT_FLOWS.NGE + '.enrollment.payrollProof',
			SUBSIDIARIES: ENROLLMENT_FLOWS.NGE + '.enrollment.subsidiaries',
			CONTACT: ENROLLMENT_FLOWS.NGE + '.enrollment.adminContact',
			EXEC_OFFICE: ENROLLMENT_FLOWS.NGE + '.enrollment.principalExecutiveOffice',
			LIKELY_DECLINE: ENROLLMENT_FLOWS.NGE + '.enrollment.likelyToDecline',
			HISTORY: ENROLLMENT_FLOWS.NGE + '.enrollment.companyHistory',
			PEO: ENROLLMENT_FLOWS.NGE + '.enrollment.companyPeo',
			WORKERS_COMPENSATION: ENROLLMENT_FLOWS.NGE + '.enrollment.workersCompensation',
			LOCATIONS: ENROLLMENT_FLOWS.NGE + '.enrollment.companyLocations',
			PREV_MEDICAL: ENROLLMENT_FLOWS.NGE + '.enrollment.previousCoverageMedical',
			PREV_DENTAL: ENROLLMENT_FLOWS.NGE + '.enrollment.previousCoverageDental',
			PREV_VISION: ENROLLMENT_FLOWS.NGE + '.enrollment.previousCoverageVision',
			COBRA: ENROLLMENT_FLOWS.NGE + '.enrollment.cobra'
		},
		ACKNOWLEDGE: {
			BASE: ENROLLMENT_FLOWS.NGE + '.acknowledge',
			SUBSIDIARY_SETUP: ENROLLMENT_FLOWS.NGE + '.acknowledge.subsidiarySetup',
			GA_CONTRACT: ENROLLMENT_FLOWS.NGE + '.acknowledge.guardianContract',
			VSP_CONTRACT: ENROLLMENT_FLOWS.NGE + '.acknowledge.vspContract',
			PARTICIPATION: ENROLLMENT_FLOWS.NGE + '.acknowledge.acknowledgeParticipationRules',
			IN_STATE: ENROLLMENT_FLOWS.NGE + '.acknowledge.acknowledgeOutOfStateRules',
			BANK_DETAILS: ENROLLMENT_FLOWS.NGE + '.acknowledge.bankDetails',
			BROKER_RECOMMENDATIONS: ENROLLMENT_FLOWS.NGE + '.acknowledge.brokerRecommendations'
		}
	};

	var SHOPPING_SECTION_KEY = {
		INTRO: {
			BASE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.intro',
			WELCOME: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.intro.welcome',
			NEXT: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.intro.whatsNext',
			PLAN: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.intro.reviewPlan',
			PACKAGE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.intro.reviewPackage'
		},
		SELECT: {
			BASE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage',
			OFFER: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.renewalOffer',
			ALTERNATIVES: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.alternativePackages',
			UPSELL: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.upsellPackages',
			EDITOR: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.packageEditor',
			CONTRIBUTION: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.contributionScheme',

			OFFER_MEDICAL: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.renewalOffer-medical',
			ALTERNATIVES_MEDICAL: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.alternativePackages-medical',
			UPSELL_MEDICAL: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.upsellPackages-medical',
			EDITOR_MEDICAL: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.packageEditor-medical',
			CONTRIBUTION_MEDICAL: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.contributionScheme-medical',

			OFFER_DENTAL: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.renewalOffer-dental',
			ALTERNATIVES_DENTAL: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.alternativePackages-dental',
			UPSELL_DENTAL: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.upsellPackages-dental',
			EDITOR_DENTAL: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.packageEditor-dental',
			CONTRIBUTION_DENTAL: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.contributionScheme-dental',

			OFFER_VISION: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.renewalOffer-vision',
			ALTERNATIVES_VISION: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.alternativePackages-vision',
			UPSELL_VISION: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.upsellPackages-vision',
			EDITOR_VISION: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.packageEditor-vision',
			CONTRIBUTION_VISION: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.contributionScheme-vision',

			OFFER_LIFE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.renewalOffer-lifenew',
			ALTERNATIVES_LIFE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.alternativePackages-lifenew',
			UPSELL_LIFE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.upsellPackages-lifenew',
			EDITOR_LIFE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.packageEditor-lifenew',
			CONTRIBUTION_LIFE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.contributionScheme-lifenew',

			OFFER_ADD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.renewalOffer-add',
			ALTERNATIVES_ADD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.alternativePackages-add',
			UPSELL_ADD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.upsellPackages-add',
			EDITOR_ADD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.packageEditor-add',
			CONTRIBUTION_ADD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.contributionScheme-add',

			OFFER_STD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.renewalOffer-std',
			ALTERNATIVES_STD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.alternativePackages-std',
			UPSELL_STD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.upsellPackages-std',
			EDITOR_STD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.packageEditor-std',
			CONTRIBUTION_STD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.contributionScheme-std',

			OFFER_LTD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.renewalOffer-ltd',
			ALTERNATIVES_LTD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.alternativePackages-ltd',
			UPSELL_LTD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.upsellPackages-ltd',
			EDITOR_LTD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.packageEditor-ltd',
			CONTRIBUTION_LTD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.selectCoverage.contributionScheme-ltd'
		},
		CONFIRM: {
			BASE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.confirmSelection',
			CONFIRM: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.confirmSelection.confirmSelection'
		},
		ENROLLMENT: {
			BASE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment',
			ABSENCE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.leaveOfAbsence',
			WAITING_PERIOD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.waitingPeriod',
			COMPANY_INFO: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.companyInfo',
			SIC: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.companySic',
			ADDRESS: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.companyAddress',
			OWNERSHIP: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.companyOwnership',
			SUBSIDIARIES: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.subsidiaries',
			CONTACT: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.adminContact',
			HISTORY: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.companyHistory',
			PEO: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.companyPeo',
			PREV_MEDICAL: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.previousCoverageMedical',
			PREV_DENTAL: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.previousCoverageDental',
			PREV_VISION: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.previousCoverageVision',
			REIMBURSEMENT_ACCOUNT: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.healthReimbursementAccount',
			WORKERS_COMPENSATION: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.workersCompensation',
			GUARDIAN_INJURY: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.guardianSeriousInjury',
			UNABLE_WORK: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.unableToWork',
			OWNER_UNDER_PLAN_EARNING: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.ownerUnderPlanEarning',
			EARNINGS_DIFFER_BY: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.earningsDifferBy',
			THIRTY_HOURS_FULLTIME: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.thirtyHoursFulltime',
			INDIVIDUAL_FICA_EXCEPTION: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.individualFicaExemption',
			SIGNIFICANT_HEALTH_RISKS: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.significantHealthRisks',
			DISABILITY_STATUTORY_PLANS: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.disabilityStatutoryPlans',
			EMPLOYEE_LEAVE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.employeeLeave',
			COBRA: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.cobra',
			ADDITIONAL_QUESTIONS: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.enrollment.additionalQuestions'
		},
		ACKNOWLEDGE: {
			BASE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge',
			SUBSIDIARY_SETUP: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.subsidiarySetup',
			SWITCH_OVERVIEW: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.switchCancellationOverview',
			SWITCH_SELECTION: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.switchCancellationSelection',
			SWITCH_LETTER: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.switchCancellationLetter',
			WAITING_PERIOD_CHANGE_LETTER: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.waitingPeriodLetter',
			GA_CONTRACT: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.guardianContract',
			VSP_CONTRACT: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.vspContract',
			PARTICIPATION: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.acknowledgeParticipationRules',
			IN_STATE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.acknowledgeOutOfStateRules',
			BANK_DETAILS: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.bankDetails',
			BROKER_RECOMMENDATIONS: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.brokerRecommendations',
			AGREEMENT: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.agreement',
			OE_PERIOD: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.openEnrollmentPeriod',
			INVITE: ENROLLMENT_FLOWS.PLAN_SHOPPING + '.acknowledge.invite'
		}
	};

	// constants for mapping enrollment section keys with corresponding title names
	var OPEN_ENROLLMENT_ROUTE_NAMES = {
		'openEnrollment.setup': _ember['default'].Object.create({
			title: "Coverage & Contributions",
			overviewTitleComplete: "Your coverage details are good to go",
			overviewTitleDefault: "Set up your coverage details",
			overviewDescriptionComplete: "You’ve finished selecting your insurance packages, setting contribution schemes and the waiting period. If you want to adjust your plan selections, please return to this section; however, keep in mind that you must make changes before inviting your employees to participate in Open Enrollment.",
			overviewDescriptionDefault: "Review the customized insurance packages that your Zenefits Benefits Advisor put together for your company, and choose the ones that are right for you.",
			sectionSteps: (function () {
				var steps = {};
				steps[APR_SECTION_KEY.SETUP.INTRO] = "Welcome";
				steps[APR_SECTION_KEY.SETUP.SELECT_MEDICAL] = "Select Medical Package";
				steps[APR_SECTION_KEY.SETUP.SELECT_DENTAL] = "Select Dental Package";
				steps[APR_SECTION_KEY.SETUP.SELECT_VISION] = "Select Vision Package";
				steps[APR_SECTION_KEY.SETUP.SELECT_LIFE] = "Select Life Package";
				steps[APR_SECTION_KEY.SETUP.SELECT_ADD] = "Select AD&D Package";
				steps[APR_SECTION_KEY.SETUP.SELECT_STD] = "Select STD Package";
				steps[APR_SECTION_KEY.SETUP.SELECT_LTD] = "Select LTD Package";
				steps[APR_SECTION_KEY.SETUP.CONTRIBUTION_MEDICAL] = "Medical Contribution";
				steps[APR_SECTION_KEY.SETUP.CONTRIBUTION_DENTAL] = "Dental Contribution";
				steps[APR_SECTION_KEY.SETUP.CONTRIBUTION_VISION] = "Vision Contribution";
				steps[APR_SECTION_KEY.SETUP.WAITING_PERIOD] = "Waiting Period";
				steps[APR_SECTION_KEY.SETUP.LEAVE] = "Leave of Absence";
				return steps;
			})()
		}),

		'openEnrollment.enrollment': _ember['default'].Object.create({
			title: "Enrollment Settings",
			overviewTitleComplete: "Your company information is good to go",
			overviewTitleDefault: "Provide or confirm your company information",
			overviewDescriptionComplete: "You’ve verified and updated the company information needed to renew your insurance.",
			overviewDescriptionDefault: "Make sure the company details and mailing address you entered in Zenefits are up-to-date. Also, in order to complete your renewal, we need some additional details about company ownership and workers’ compensation.",
			sectionSteps: (function () {
				var steps = {};
				steps[APR_SECTION_KEY.ENROLLMENT.COMPANY_INFO] = "Provide or confirm your company information";
				steps[APR_SECTION_KEY.ENROLLMENT.SIC] = "Industry Classification";
				steps[APR_SECTION_KEY.ENROLLMENT.ADDRESS] = "Billing Address";
				steps[APR_SECTION_KEY.ENROLLMENT.OWNERSHIP] = "Ownership Details";
				steps[APR_SECTION_KEY.ENROLLMENT.SUBSIDIARIES] = "Enter Subsidiary Details";
				steps[APR_SECTION_KEY.ENROLLMENT.CONTACT] = "Admin and Billing Contacts";
				steps[APR_SECTION_KEY.ENROLLMENT.EXEC_OFFICE] = "Principal Executive's Address";
				steps[APR_SECTION_KEY.ENROLLMENT.HISTORY] = "Company History";
				steps[APR_SECTION_KEY.ENROLLMENT.PEO] = "PEO Details";
				steps[APR_SECTION_KEY.ENROLLMENT.PREV_MEDICAL] = "Previous Medical Coverage";
				steps[APR_SECTION_KEY.ENROLLMENT.PREV_DENTAL] = "Previous Dental Coverage";
				steps[APR_SECTION_KEY.ENROLLMENT.PREV_VISION] = "Previous Vision Coverage";
				steps[APR_SECTION_KEY.ENROLLMENT.REIMBURSEMENT_ACCOUNT] = "Health Reimbursement Account";
				steps[APR_SECTION_KEY.ENROLLMENT.WORKERS_COMPENSATION] = "Workers Compensation";
				steps[APR_SECTION_KEY.ENROLLMENT.GUARDIAN_INJURY] = "Employee Medical Treatment Details";
				steps[APR_SECTION_KEY.ENROLLMENT.UNABLE_WORK] = "Inactive Employees";
				steps[APR_SECTION_KEY.ENROLLMENT.OWNER_UNDER_PLAN_EARNING] = "Owner Coverage";
				steps[APR_SECTION_KEY.ENROLLMENT.EARNINGS_DIFFER_BY] = "Employee Earnings";
				steps[APR_SECTION_KEY.ENROLLMENT.THIRTY_HOURS_FULLTIME] = "Full Time Requirements";
				steps[APR_SECTION_KEY.ENROLLMENT.INDIVIDUAL_FICA_EXCEPTION] = "FICA Exemptions";
				steps[APR_SECTION_KEY.ENROLLMENT.SIGNIFICANT_HEALTH_RISKS] = "Known Health Issues";
				steps[APR_SECTION_KEY.ENROLLMENT.DISABILITY_STATUTORY_PLANS] = "State-Specific Employee Coverage";
				steps[APR_SECTION_KEY.ENROLLMENT.EMPLOYEE_LEAVE] = "Employee Injury and Illness History";
				steps[APR_SECTION_KEY.ENROLLMENT.COBRA] = "COBRA Details";
				steps[APR_SECTION_KEY.ENROLLMENT.ADDITIONAL_QUESTIONS] = "Additional Information";
				return steps;
			})()
		}),

		'openEnrollment.acknowledge': _ember['default'].Object.create({
			title: "Finalize Employee Enrollment",
			overviewTitleComplete: "Invitations have been sent to your employees",
			overviewTitleDefault: "Review documents and enroll your employees",
			overviewDescriptionComplete: "Your employees have been invited to participate in Open Enrollment. We’ll let you know when all your employees have submitted their selections.",
			overviewDescriptionDefault: "Once you’ve selected plans, set contributions, entered your company information and signed the carrier agreements—you can invite your employees to enroll in insurance.",
			sectionSteps: (function () {
				var steps = {};
				steps[APR_SECTION_KEY.ACKNOWLEDGE.SUBSIDIARY_SETUP] = "Subsidiary Setup";
				steps[APR_SECTION_KEY.ACKNOWLEDGE.SWITCH_OVERVIEW] = "Switch Cancellation Overview";
				steps[APR_SECTION_KEY.ACKNOWLEDGE.SWITCH_SELECTION] = "Switch Cancellation Policy";
				steps[APR_SECTION_KEY.ACKNOWLEDGE.SWITCH_LETTER] = "Switch Cancellation Letter";
				steps[APR_SECTION_KEY.ACKNOWLEDGE.GA_CONTRACT] = "Guardian Contract";
				steps[APR_SECTION_KEY.ACKNOWLEDGE.VSP_CONTRACT] = "VSP Contract";
				steps[APR_SECTION_KEY.ACKNOWLEDGE.PARTICIPATION] = "Participation Requirements";
				steps[APR_SECTION_KEY.ACKNOWLEDGE.IN_STATE] = "In-State Requirements";
				steps[APR_SECTION_KEY.ACKNOWLEDGE.BANK_DETAILS] = "Bank Details";
				steps[APR_SECTION_KEY.ACKNOWLEDGE.BROKER_RECOMMENDATIONS] = "Broker Recommendations";
				steps[APR_SECTION_KEY.ACKNOWLEDGE.AGREEMENT] = "Agreement Signatures";
				steps[APR_SECTION_KEY.ACKNOWLEDGE.OE_PERIOD] = "Employee Enrollment Deadline";
				steps[APR_SECTION_KEY.ACKNOWLEDGE.INVITE] = "Employee Invitations";
				return steps;
			})()
		})
	};

	var NEW_GROUP_ENROLLMENT_SECTION_ROUTE = {
		'newGroupEnrollment.setup': _ember['default'].Object.create({
			title: "Coverage & Contributions",
			overviewTitleComplete: "Your coverage details are good to go",
			overviewTitleDefault: "Set up your coverage details",
			overviewDescriptionComplete: "You’ve finished selecting your insurance packages, setting contribution schemes and the waiting period. If you want to adjust your plan selections, please return to this section; however, keep in mind that you must make changes before inviting your employees to participate in Open Enrollment.",
			overviewDescriptionDefault: "Review the customized insurance packages that your Zenefits Benefits Advisor put together for your company, and choose the ones that are right for you.",
			sectionSteps: (function () {
				var steps = {};
				steps[NGE_SECTION_KEY.SETUP.WAITING_PERIOD] = "Waiting Period";
				steps[NGE_SECTION_KEY.SETUP.ABSENCE] = "Leave of Absence";
				steps[NGE_SECTION_KEY.SETUP.CONTRIBUTION_MEDICAL] = "Medical Contribution";
				steps[NGE_SECTION_KEY.SETUP.CONTRIBUTION_DENTAL] = "Dental Contribution";
				steps[NGE_SECTION_KEY.SETUP.CONTRIBUTION_VISION] = "Vision Contribution";
				return steps;
			})()
		}),

		'newGroupEnrollment.enrollment': _ember['default'].Object.create({
			title: "Enrollment Settings",
			overviewTitleComplete: "Your company information is good to go",
			overviewTitleDefault: "Provide or confirm your company information",
			overviewDescriptionComplete: "You’ve verified and updated the company information needed to renew your insurance.",
			overviewDescriptionDefault: "Make sure the company details and mailing address you entered in Zenefits are up-to-date. Also, in order to complete your renewal, we need some additional details about company ownership and workers’ compensation.",
			sectionSteps: (function () {
				var steps = {};
				steps[NGE_SECTION_KEY.ENROLLMENT.COMPANY_INFO] = "Provide or confirm your company information";
				steps[NGE_SECTION_KEY.ENROLLMENT.SIC] = "Industry Classification";
				steps[NGE_SECTION_KEY.ENROLLMENT.ADDRESS] = "Billing Address";
				steps[NGE_SECTION_KEY.ENROLLMENT.OWNERSHIP] = "Ownership Details";
				steps[NGE_SECTION_KEY.ENROLLMENT.PAYROLL] = "Payroll History";
				steps[NGE_SECTION_KEY.ENROLLMENT.SUBSIDIARIES] = "Enter Subsidiary Details";
				steps[NGE_SECTION_KEY.ENROLLMENT.CONTACT] = "Admin and Billing Contacts";
				steps[NGE_SECTION_KEY.ENROLLMENT.EXEC_OFFICE] = "Principal Executive's Address";
				steps[NGE_SECTION_KEY.ENROLLMENT.LIKELY_DECLINE] = "Full-time Employees Coverage Details";
				steps[NGE_SECTION_KEY.ENROLLMENT.HISTORY] = "Company History";
				steps[NGE_SECTION_KEY.ENROLLMENT.PEO] = "PEO Details";
				steps[NGE_SECTION_KEY.ENROLLMENT.WORKERS_COMPENSATION] = "Workers’ Compensation";
				steps[NGE_SECTION_KEY.ENROLLMENT.LOCATIONS] = "Company Locations";
				steps[NGE_SECTION_KEY.ENROLLMENT.PREV_MEDICAL] = "Previous Medical Coverage";
				steps[NGE_SECTION_KEY.ENROLLMENT.PREV_DENTAL] = "Previous Dental Coverage";
				steps[NGE_SECTION_KEY.ENROLLMENT.PREV_VISION] = "Previous Vision Coverage";
				steps[NGE_SECTION_KEY.ENROLLMENT.COBRA] = "COBRA Details";
				return steps;
			})()
		}),

		'newGroupEnrollment.acknowledge': _ember['default'].Object.create({
			title: "Finalize Employee Enrollment",
			overviewTitleComplete: "Invitations have been sent to your employees",
			overviewTitleDefault: "Review documents and enroll your employees",
			overviewDescriptionComplete: "Your employees have been invited to participate in New Group Enrollment. We’ll let you know when all your employees have submitted their selections.",
			overviewDescriptionDefault: "Once you’ve selected plans, set contributions, entered your company information and signed the carrier agreements—you can invite your employees to enroll in insurance.",
			sectionSteps: (function () {
				var steps = {};
				steps[NGE_SECTION_KEY.ACKNOWLEDGE.SUBSIDIARY_SETUP] = "Subsidiary Setup";
				steps[NGE_SECTION_KEY.ACKNOWLEDGE.GA_CONTRACT] = "Guardian Contract";
				steps[NGE_SECTION_KEY.ACKNOWLEDGE.VSP_CONTRACT] = "VSP Contract";
				steps[NGE_SECTION_KEY.ACKNOWLEDGE.PARTICIPATION] = "Participation Requirements";
				steps[NGE_SECTION_KEY.ACKNOWLEDGE.IN_STATE] = "In-State Requirements";
				steps[NGE_SECTION_KEY.ACKNOWLEDGE.BANK_DETAILS] = "Bank Details";
				steps[NGE_SECTION_KEY.ACKNOWLEDGE.BROKER_RECOMMENDATIONS] = "Broker Recommendations";
				return steps;
			})()
		})
	};

	var SHOPPING_SECTION_ROUTE = {
		'selfServiceRenewals.intro': _ember['default'].Object.create({
			overviewTitleDefault: "Introduction",
			sectionSteps: (function () {
				var steps = {};
				steps[SHOPPING_SECTION_KEY.INTRO.WELCOME] = "Welcome";
				steps[SHOPPING_SECTION_KEY.INTRO.NEXT] = "What's Next";
				steps[SHOPPING_SECTION_KEY.INTRO.PLAN] = "Review a Plan";
				steps[SHOPPING_SECTION_KEY.INTRO.PACKAGE] = "Review a Package";
				return steps;
			})()
		}),

		'selfServiceRenewals.selectCoverage-medical': _ember['default'].Object.create({
			overviewTitleDefault: "Select Medical Coverage",
			sectionSteps: (function () {
				var steps = {};
				steps[SHOPPING_SECTION_KEY.SELECT.OFFER_MEDICAL] = "Renewal Offer";
				steps[SHOPPING_SECTION_KEY.SELECT.ALTERNATIVES_MEDICAL] = "Alternative Packages";
				steps[SHOPPING_SECTION_KEY.SELECT.UPSELL_MEDICAL] = "Select Package";
				steps[SHOPPING_SECTION_KEY.SELECT.EDITOR_MEDICAL] = "Package Editor";
				steps[SHOPPING_SECTION_KEY.SELECT.CONTRIBUTION_MEDICAL] = "Contribution Scheme";
				return steps;
			})()
		}),

		'selfServiceRenewals.selectCoverage-dental': _ember['default'].Object.create({
			overviewTitleDefault: "Select Dental Coverage",
			sectionSteps: (function () {
				var steps = {};
				steps[SHOPPING_SECTION_KEY.SELECT.OFFER_DENTAL] = "Renewal Offer";
				steps[SHOPPING_SECTION_KEY.SELECT.ALTERNATIVES_DENTAL] = "Alternative Packages";
				steps[SHOPPING_SECTION_KEY.SELECT.UPSELL_DENTAL] = "Select Package";
				steps[SHOPPING_SECTION_KEY.SELECT.EDITOR_DENTAL] = "Package Editor";
				steps[SHOPPING_SECTION_KEY.SELECT.CONTRIBUTION_DENTAL] = "Contribution Scheme";
				return steps;
			})()
		}),

		'selfServiceRenewals.selectCoverage-vision': _ember['default'].Object.create({
			overviewTitleDefault: "Select Vision Coverage",
			sectionSteps: (function () {
				var steps = {};
				steps[SHOPPING_SECTION_KEY.SELECT.OFFER_VISION] = "Renewal Offer";
				steps[SHOPPING_SECTION_KEY.SELECT.ALTERNATIVES_VISION] = "Alternative Packages";
				steps[SHOPPING_SECTION_KEY.SELECT.UPSELL_VISION] = "Select Package";
				steps[SHOPPING_SECTION_KEY.SELECT.EDITOR_VISION] = "Package Editor";
				steps[SHOPPING_SECTION_KEY.SELECT.CONTRIBUTION_VISION] = "Contribution Scheme";
				return steps;
			})()
		}),

		'selfServiceRenewals.selectCoverage-lifenew': _ember['default'].Object.create({
			overviewTitleDefault: "Select Life Coverage",
			sectionSteps: (function () {
				var steps = {};
				steps[SHOPPING_SECTION_KEY.SELECT.OFFER_LIFE] = "Renewal Offer";
				steps[SHOPPING_SECTION_KEY.SELECT.ALTERNATIVES_LIFE] = "Alternative Packages";
				steps[SHOPPING_SECTION_KEY.SELECT.UPSELL_LIFE] = "Select Package";
				steps[SHOPPING_SECTION_KEY.SELECT.EDITOR_LIFE] = "Package Editor";
				steps[SHOPPING_SECTION_KEY.SELECT.CONTRIBUTION_LIFE] = "Contribution Scheme";
				return steps;
			})()
		}),

		'selfServiceRenewals.selectCoverage-add': _ember['default'].Object.create({
			overviewTitleDefault: "Select AD&D Coverage",
			sectionSteps: (function () {
				var steps = {};
				steps[SHOPPING_SECTION_KEY.SELECT.OFFER_ADD] = "Renewal Offer";
				steps[SHOPPING_SECTION_KEY.SELECT.ALTERNATIVES_ADD] = "Alternative Packages";
				steps[SHOPPING_SECTION_KEY.SELECT.UPSELL_ADD] = "Select Package";
				steps[SHOPPING_SECTION_KEY.SELECT.EDITOR_ADD] = "Package Editor";
				steps[SHOPPING_SECTION_KEY.SELECT.CONTRIBUTION_ADD] = "Contribution Scheme";
				return steps;
			})()
		}),

		'selfServiceRenewals.selectCoverage-std': _ember['default'].Object.create({
			overviewTitleDefault: "Select STD Coverage",
			sectionSteps: (function () {
				var steps = {};
				steps[SHOPPING_SECTION_KEY.SELECT.OFFER_STD] = "Renewal Offer";
				steps[SHOPPING_SECTION_KEY.SELECT.ALTERNATIVES_STD] = "Alternative Packages";
				steps[SHOPPING_SECTION_KEY.SELECT.UPSELL_STD] = "Select Package";
				steps[SHOPPING_SECTION_KEY.SELECT.EDITOR_STD] = "Package Editor";
				steps[SHOPPING_SECTION_KEY.SELECT.CONTRIBUTION_STD] = "Contribution Scheme";
				return steps;
			})()
		}),

		'selfServiceRenewals.selectCoverage-ltd': _ember['default'].Object.create({
			overviewTitleDefault: "Select LTD Coverage",
			sectionSteps: (function () {
				var steps = {};
				steps[SHOPPING_SECTION_KEY.SELECT.OFFER_LTD] = "Renewal Offer";
				steps[SHOPPING_SECTION_KEY.SELECT.ALTERNATIVES_LTD] = "Alternative Packages";
				steps[SHOPPING_SECTION_KEY.SELECT.UPSELL_LTD] = "Select Package";
				steps[SHOPPING_SECTION_KEY.SELECT.EDITOR_LTD] = "Package Editor";
				steps[SHOPPING_SECTION_KEY.SELECT.CONTRIBUTION_LTD] = "Contribution Scheme";
				return steps;
			})()
		}),

		'selfServiceRenewals.confirmSelection': _ember['default'].Object.create({
			overviewTitleDefault: "Confirm Selection",
			sectionSteps: (function () {
				var steps = {};
				steps[SHOPPING_SECTION_KEY.CONFIRM.CONFIRM] = "Confirm Selection";
				return steps;
			})()
		}),

		'selfServiceRenewals.enrollment': _ember['default'].Object.create({
			overviewTitleDefault: "Enrollment Settings",
			sectionSteps: (function () {
				var steps = {};
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.ABSENCE] = "Leave of Absence";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.WAITING_PERIOD] = "Waiting Period";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.COMPANY_INFO] = "Confirm Your Company’s Information";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.SIC] = "Confirm Your Company's SIC code";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.ADDRESS] = "Confirm Your Billing Address";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.OWNERSHIP] = "Enter Company Ownership Information";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.SUBSIDIARIES] = "Enter Subsidiary Details";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.CONTACT] = "Enter Admin Contact Information";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.HISTORY] = "Enter Company History Information";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.PEO] = "Enter Company PEO Information";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.PREV_MEDICAL] = "Previous Medical Coverage";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.PREV_DENTAL] = "Previous Dental Coverage";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.PREV_VISION] = "Previous Vision Coverage";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.REIMBURSEMENT_ACCOUNT] = "Health Reimbursement Account";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.WORKERS_COMPENSATION] = "Enter Workers Compensation";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.GUARDIAN_INJURY] = "Provide Employee Medical Treatment Details";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.UNABLE_WORK] = "Inactive Employees";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.OWNER_UNDER_PLAN_EARNING] = "Owner Coverage";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.EARNINGS_DIFFER_BY] = "Employee Earnings";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.THIRTY_HOURS_FULLTIME] = "Full Time Requirements";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.INDIVIDUAL_FICA_EXCEPTION] = "FICA Exemptions";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.SIGNIFICANT_HEALTH_RISKS] = "Known Health Issues";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.DISABILITY_STATUTORY_PLANS] = "State-Specific Employee Coverage";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.EMPLOYEE_LEAVE] = "Employee Injury and Illness History";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.COBRA] = "Enter Cobra Information";
				steps[SHOPPING_SECTION_KEY.ENROLLMENT.ADDITIONAL_QUESTIONS] = "Additional Information";
				return steps;
			})()
		}),

		'selfServiceRenewals.acknowledge': _ember['default'].Object.create({
			overviewTitleDefault: "Finalize Employee Enrollment",
			sectionSteps: (function () {
				var steps = {};
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.SUBSIDIARY_SETUP] = "Subsidiary Setup";
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.SWITCH_OVERVIEW] = "Switch Cancellation Overview";
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.SWITCH_SELECTION] = "Select Switch Cancellation Policy";
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.SWITCH_LETTER] = "Sign Switch Cancellation Letter";
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.WAITING_PERIOD_CHANGE_LETTER] = "Waiting Period Change Letter";
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.GA_CONTRACT] = "Sign Guardian Contract";
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.VSP_CONTRACT] = "Sign VSP Contract";
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.PARTICIPATION] = "Review Participation Requirements";
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.IN_STATE] = "Review In-State Requirements";
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.BANK_DETAILS] = "Confirm Your Bank Details";
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.BROKER_RECOMMENDATIONS] = "Broker Recommendations";
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.AGREEMENT] = "Sign Agreements";
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.OE_PERIOD] = "Decide Employees’ Enrollment Deadline";
				steps[SHOPPING_SECTION_KEY.ACKNOWLEDGE.INVITE] = "Invite Your Employees";
				return steps;
			})()
		})
	};

	exports['default'] = {
		ENROLLMENT_FLOWS: ENROLLMENT_FLOWS,
		APR_SECTION_KEY: APR_SECTION_KEY,
		NGE_SECTION_KEY: NGE_SECTION_KEY,
		SHOPPING_SECTION_KEY: SHOPPING_SECTION_KEY,
		OPEN_ENROLLMENT_ROUTE_NAMES: OPEN_ENROLLMENT_ROUTE_NAMES,
		NEW_GROUP_ENROLLMENT_SECTION_ROUTE: NEW_GROUP_ENROLLMENT_SECTION_ROUTE,
		SHOPPING_SECTION_ROUTE: SHOPPING_SECTION_ROUTE
	};
});