define('component-library/components/slide-switch', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	// this component has no logic because visual toggling effects are done via CSS
	exports['default'] = _ember['default'].Component.extend({
		tagName: 'label',
		classNames: 'slide-switch',
		attributeBindings: ['name'],
		width: 70,
		onLabel: 'ON',
		offLabel: 'OFF',
		isOn: false
	});
});