define('component-library/wizard/state/flow', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].ObjectProxy.extend({
		// OVERRIDES - begin

		// required
		routeName: null,

		// optional
		currentSectionIndex: 0,
		terminalRoute: null,
		sequenceEnforced: false, // prevents skipping past an unfinished section or step
		resumableIfComplete: false,
		hasCustomFlowIndex: false,

		// OVERRIDES - end

		sections: (function () {
			// ordered list of WizardSectionState object
			return _ember['default'].A();
		}).property(),

		setSections: function setSections(sections) {
			sections.forEach(function (section) {
				section.set('flow', this);
			}, this);
			this.set('sections', sections);
		},

		sectionsHash: (function () {
			// sectionKey to WizardSectionState object
			var sections = this.get('sections');
			var sectionsHash = {};
			sections.forEach(function (section) {
				sectionsHash[section.get('sectionKey')] = section;
			});
			return sectionsHash;
		}).property('sections'),

		getSection: function getSection(sectionKey) {
			return this.get('sectionsHash')[sectionKey];
		},

		setLocation: function setLocation(sectionIndex, stepIndex) {
			this.set('currentSectionIndex', sectionIndex);
			this.get('currentSection').set('currentStepIndex', stepIndex);
		},

		getSectionAtIndex: function getSectionAtIndex(index) {
			return this.get('sections')[index];
		},

		currentSection: (function () {
			var sections = this.get('sections');
			var currentSectionIndex = this.get('currentSectionIndex');
			return sections[currentSectionIndex];
		}).property('currentSectionIndex', 'sections.length'),

		nextSection: (function () {
			var sections = this.get('sections');
			var currentSectionIndex = this.get('currentSectionIndex');
			var nextSectionIndex = currentSectionIndex + 1;
			return nextSectionIndex < sections.length ? sections[nextSectionIndex] : null;
		}).property('currentSectionIndex', 'sections.length'),

		previousSection: (function () {
			var sections = this.get('sections');
			var currentSectionIndex = this.get('currentSectionIndex');
			var previousSectionIndex = currentSectionIndex - 1;
			return previousSectionIndex >= 0 ? sections[previousSectionIndex] : null;
		}).property('currentSectionIndex', 'sections.length'),

		firstIncompleteSection: (function () {
			var firstIncompleteSection = null;
			this.get('sections').forEach(function (section) {
				if (!section.get('isComplete') && firstIncompleteSection == null) {
					firstIncompleteSection = section;
				}
			});
			return firstIncompleteSection;
		}).property('sections', 'sections.@each.isComplete'),

		stepsComplete: (function () {
			var stepsComplete = 0;
			this.get('sections').forEach(function (section) {
				stepsComplete += section.get('stepsComplete');
			});
			return stepsComplete;
		}).property('sections.@each.stepsComplete'),

		sectionsComplete: (function () {
			return this.get('sections').filterBy('isComplete', true).length;
		}).property('sections.@each.isComplete'),

		flowComplete: (function () {
			var sectionsComplete = this.get('sectionsComplete');
			var totalSections = this.get('sections.length');
			return sectionsComplete === totalSections;
		}).property('sectionsComplete', 'sections'),

		completionAsHash: (function () {
			var completionHash = {};
			var sections = this.get('sections');

			sections.forEach(function (section) {
				var sectionKey = section.get('sectionKey');
				// Trigger observing stepsCompletionString
				section.get('stepsCompletionString');
				completionHash[sectionKey] = {};

				var steps = section.get('steps');
				steps.forEach(function (step) {
					var stepKey = step.get('stepKey');
					completionHash[sectionKey][stepKey] = step.get('isComplete');
				});
			});

			return completionHash;
		}).property('sections', 'sections.@each.steps', 'sections.@each.stepsCompletionString')
	});
});