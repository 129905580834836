define('component-library/components/deductions/review/detail-breakdown', ['exports', 'ember', 'component-library/utils/globals', 'component-library/data-table/column', 'component-library/data-table/cells/non-sortable-header-cell', 'component-library/data-table/cells/tree-table-cell', 'component-library/components/deductions/deduction-money-table-cell', 'component-library/components/deductions/deduction-paystubPopover-table-cell', 'component-library/utils/deductions/deductions-utils', 'component-library/utils/payroll/payroll-utils', 'component-library/templates/components/deductions/review/detail-breakdown'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryDataTableColumn, _componentLibraryDataTableCellsNonSortableHeaderCell, _componentLibraryDataTableCellsTreeTableCell, _componentLibraryComponentsDeductionsDeductionMoneyTableCell, _componentLibraryComponentsDeductionsDeductionPaystubPopoverTableCell, _componentLibraryUtilsDeductionsDeductionsUtils, _componentLibraryUtilsPayrollPayrollUtils, _componentLibraryTemplatesComponentsDeductionsReviewDetailBreakdown) {
	'use strict';

	var TableRow = _ember['default'].ObjectProxy.extend({
		rowStyle: (function () {
			if (this.get('isTotalData')) {
				return 'z-deductionsBreakdown-totalsRow';
			} else if (this.get('isCarryoverData')) {
				return 'z-deductionsBreakdown-carryoverRow';
			} else if (this.get('isMonthData')) {
				return 'z-deductionsBreakdown-expandedRow';
			} else {
				return '';
			}
		}).property('isTotalData', 'isMonthData', 'isCarryoverData')
	});

	var getDateArrayFromDeduction = function getDateArrayFromDeduction(data) {
		var month = data.get('month');
		if (month) {
			return [data.get('year'), data.get('month'), undefined];
		} else {
			var checkDate = data.get('checkDate');
			return checkDate.split('-').map(function (item) {
				return parseInt(item);
			});
		}
	};

	// TODO(feng): I do not link this function, will refactor
	var deductionCheckDateComparator = function deductionCheckDateComparator(dataA, dataB) {
		var dateA = getDateArrayFromDeduction(dataA);
		var dateB = getDateArrayFromDeduction(dataB);
		if (dateA[0] < dateB[0]) {
			return 1;
		} else if (dateA[0] > dateB[0]) {
			return -1;
		} else {
			if (dateA[1] < dateB[1]) {
				return 1;
			} else if (dateA[1] > dateB[1]) {
				return -1;
			} else {
				if (dateA[2] === undefined) {
					return -1;
				} else if (dateB[2] === undefined) {
					return 1;
				} else {
					if (dateA[2] < dateB[2]) {
						return 1;
					} else if (dateA[2] > dateB[2]) {
						return -1;
					} else {
						return 0;
					}
				}
			}
		}
	};

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['grid-block', 'vertical'],
		layout: _componentLibraryTemplatesComponentsDeductionsReviewDetailBreakdown['default'],
		tableMaxHeight: 500,

		isCatchupOverride: false,
		displayContribution: false,
		reviewStartDate: null,

		didRender: function didRender() {
			this._super.apply(this, arguments);
			var containerHeight = _ember['default'].$('.js-z-detailPanel-tableContainer').height();
			if (this.get('tableMaxHeight') !== containerHeight) {
				this.set('tableMaxHeight', containerHeight);
			}
		},

		getReminderToDisplay: function getReminderToDisplay(row) {
			var isManual = row.get('isManual') || false;
			if (isManual) {
				var reasonCode = row.get('reasonCode');
				if (Boolean(reasonCode) && reasonCode in _componentLibraryUtilsDeductionsDeductionsUtils.manualPaystubReasons) {
					return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsBreakdown-paystubAdjustment">Adjustment</span>');
				} else {
					return '';
				}
			} else {
				return '';
			}
		},

		getCheckDateToDisplay: function getCheckDateToDisplay(row) {
			if (row.get('isTotalData')) {
				return 'Totals (to date)';
			} else if (row.get('isCarryoverData')) {
				return 'Carry over';
			} else if (row.get('isMonthData')) {
				return _componentLibraryUtilsDeductionsDeductionsUtils.deductionMonths[row.get('month')] + ' ' + row.get('year');
			} else {
				var checkDate = row.get('checkDate');
				if (checkDate) {
					return (0, _componentLibraryUtilsDeductionsDeductionsUtils.getFormattedCheckDate)(checkDate);
				} else {
					return 'Unknown';
				}
			}
		},

		doNotPush: (function () {
			return this.get('displayContribution') ? this.get('reviewData.doNotPushContrib') : this.get('reviewData.doNotPush');
		}).property('displayContribution', 'reviewData.doNotPushContrib', 'reviewData.doNotPush'),

		doNotPushExplanation: (function () {
			var explanation = 'This deduction sync is currently blocked by ' + _componentLibraryUtilsGlobals.brandName + '. This is due to a request by the admin or some work internally to get your deductions back in shape.';
			return this.get('doNotPush') ? explanation : '';
		}).property('doNotPush', 'reviewData.content'),

		isCatchup: (function () {
			if (this.get('isCatchupOverride')) {
				return true;
			} else {
				return this.get('displayContribution') ? this.get('reviewData.isCatchUpContrib') : this.get('reviewData.isCatchUp');
			}
		}).property('isCatchupOverride', 'displayContribution', 'reviewData.isCatchUpContrib', 'reviewData.isCatchUp'),

		catchupExplanation: _ember['default'].computed('isCatchup', 'reviewData.content', 'catchupDetail', 'displayContribution', function () {
			var displayContribution = this.get('displayContribution');
			if (!displayContribution) {
				if (this.get('catchupDetail') && this.get('catchupDetail')['catchupCategory']) {
					return (0, _componentLibraryUtilsDeductionsDeductionsUtils.buildCatchupExplanationFromPrd)(this.get('catchupDetail'), this.get('benefitsEventContent'));
				}
				return this.get('isCatchup') ? (0, _componentLibraryUtilsDeductionsDeductionsUtils.buildCatchupExplanation)(this.get('reviewData')) : '';
			} else {
				return this.get('isCatchup') ? (0, _componentLibraryUtilsDeductionsDeductionsUtils.buildCatchupExplanation)(this.get('reviewData'), 'contribution') : '';
			}
		}),

		columns: (function () {
			var payrollProvider = this.get('reviewData.payrollProvider');

			var fixedColumns = [_componentLibraryDataTableColumn['default'].create({
				headerCellViewClass: _componentLibraryDataTableCellsNonSortableHeaderCell['default'],
				tableCellViewClass: _componentLibraryDataTableCellsTreeTableCell['default'],
				savedWidth: 190,
				sortable: false,
				headerCellName: 'Check Dates',
				canAutoResize: true,
				contentPath: 'checkDate',
				getCellContent: this.get('getCheckDateToDisplay'),
				isDeletable: false
			}), _componentLibraryDataTableColumn['default'].create({
				headerCellViewClass: _componentLibraryDataTableCellsNonSortableHeaderCell['default'],
				tableCellViewClass: _componentLibraryComponentsDeductionsDeductionMoneyTableCell['default'],
				savedWidth: 200,
				headerCellName: 'Expected (per month)',
				sortable: false,
				canAutoResize: true,
				contentPath: 'zenefitsDeduction',
				isDeletable: false,
				textAlign: 'u-textRight'
			}), _componentLibraryDataTableColumn['default'].create({
				headerCellViewClass: _componentLibraryDataTableCellsNonSortableHeaderCell['default'],
				tableCellViewClass: _componentLibraryComponentsDeductionsDeductionMoneyTableCell['default'],
				savedWidth: 160,
				headerCellName: (0, _componentLibraryUtilsPayrollPayrollUtils.getPayrollProviderStringFromCode)(payrollProvider, 'Payroll'),
				sortable: false,
				canAutoResize: true,
				contentPath: 'payrollDeduction',
				isDeletable: false,
				textAlign: 'u-textRight'
			}), _componentLibraryDataTableColumn['default'].create({
				headerCellViewClass: _componentLibraryDataTableCellsNonSortableHeaderCell['default'],
				headerCellName: '',
				sortable: false,
				savedWidth: 150,
				canAutoResize: true,
				contentPath: 'reasonCode',
				tableCellViewClass: _componentLibraryComponentsDeductionsDeductionPaystubPopoverTableCell['default'],
				getCellContent: this.get('getReminderToDisplay'),
				isDeletable: false,
				textAlign: 'u-textLeft'
			})];
			return fixedColumns;
		}).property('reviewData.payrollProvider'),

		monthlyDeductionWithDetails: (function () {
			var displayContribution = this.get('displayContribution');
			var monthlyData = this.get('reviewData.monthlyBreakdown') || [];
			var allData = _ember['default'].A();
			var companyName = this.get('reviewData.companyName');
			var employeeName = this.get('reviewData.firstName');
			var resetDate = this.get('reviewData.resetDate');
			var resetDeduction = displayContribution ? this.get('reviewData.resetContribution') : this.get('reviewData.resetDeduction');
			var reviewStartDate = this.get('reviewStartDate');
			monthlyData.filter(function (row) {
				return Boolean(row);
			}).forEach(function (row) {
				if (reviewStartDate) {
					var year = reviewStartDate.getFullYear();
					var month = reviewStartDate.getMonth() + 1;
					if (row.year < year || row.year == year && row.month < month) {
						return;
					}
				}
				var monthHash = {
					year: row.year,
					month: row.month,
					carrierDeduction: displayContribution ? row.requestedContributionForMonth : row.requestedDeductionForMonth,
					zenefitsDeduction: displayContribution ? row.expectedContributionForMonth : row.expectedDeductionForMonth,
					payrollDeduction: displayContribution ? row.actualContributionForMonth : row.actualDeductionForMonth,
					isPercent: displayContribution ? row.isContributionAsPercentage : row.isDeductionAsPercentage,
					isCollapsed: true,
					isMonthData: true
				};
				var deductions = row.deductions || [];
				if (deductions.length === 0) {
					monthHash['isLeaf'] = true;
				} else {
					var allDeductions = _ember['default'].A();
					deductions.forEach(function (deduction) {
						var deductionHash = {
							checkDate: deduction.checkDate,
							planDeduction: null,
							zenefitsDeduction: null,
							payrollDeduction: displayContribution ? deduction.contribution : deduction.deduction,
							isManual: Boolean(deduction.isManual),
							fromEffective: Boolean(deduction.fromEffective),
							reasonCode: deduction.reasonCode,
							isLeaf: true,
							isPercent: displayContribution ? row.isContributionAsPercentage : row.isDeductionAsPercentage,
							company: companyName,
							employee: employeeName,
							indentation: 18
						};
						allDeductions.pushObject(_ember['default'].ObjectProxy.create(deductionHash));
					});
					monthHash['deductions'] = allDeductions.sort(deductionCheckDateComparator);
				}
				allData.pushObject(_ember['default'].ObjectProxy.create(monthHash));
			});
			allData.sort(deductionCheckDateComparator);
			if (resetDate) {
				allData.pushObject(_ember['default'].ObjectProxy.create({
					carrierDeduction: 0 - resetDeduction,
					zenefitsDeduction: 0 - resetDeduction,
					payrollDeduction: null,
					isPercent: false,
					isCarryoverData: true,
					isLeaf: true
				}));
			}
			return allData;
		}).property('reviewData.monthlyBreakdown', 'reviewStartDate', 'displayContribution'),

		tableRows: (function (key, value) {
			if (arguments.length === 1) {
				var allData = this.getWithDefault('monthlyDeductionWithDetails', []);
				return this._getRows(allData);
			}
			return value;
		}).property('monthlyDeductionWithDetails'),

		_getRows: function _getRows(allData) {
			var rows = [];
			var totalZenefitsDeduction = 0;
			var totalPayrollDeduction = 0;
			var isPercent = false;
			allData.forEach(function (data) {
				var row = TableRow.create({
					content: data
				});
				rows.pushObject(row);
				if (data.get('deductions') && data.get('deductions').length > 0 && !data.get('isCollapsed')) {
					var deductions = data.get('deductions');
					deductions.forEach(function (deduction) {
						var row = TableRow.create({
							content: deduction
						});
						rows.pushObject(row);
					});
				}
				if (data.get('isPercent')) {
					totalZenefitsDeduction = data.get('zenefitsDeduction');
					totalPayrollDeduction = data.get('payrollDeduction');
					isPercent = true;
				} else {
					totalZenefitsDeduction += data.get('zenefitsDeduction');
					totalPayrollDeduction += data.get('payrollDeduction');
					isPercent = false;
				}
			});
			// Construct a row for total
			var totalData = {
				carrierDeduction: null,
				zenefitsDeduction: totalZenefitsDeduction,
				payrollDeduction: totalPayrollDeduction,
				isPercent: isPercent,
				isCollapsed: false,
				isTotalData: true,
				isLeaf: true
			};
			var totalRow = TableRow.create({
				content: totalData
			});
			var allRows = [totalRow].concat(rows);
			return allRows;
		},

		actions: {
			toggleCollapse: function toggleCollapse(row) {
				var allData = this.getWithDefault('monthlyDeductionWithDetails', []);
				var rows = this._getRows(allData);
				this.set('tableRows', rows);
			}
		}
	});
});