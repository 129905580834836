define('component-library/mixins/adjust-popover-height', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		bottomOffset: 32,

		didInsertElement: function didInsertElement() {
			this._super();
			// adjust popover height to fit window, js-popover should be put on the outermost grid block
			var $popover = this.$('.js-popover');
			if ($popover) {
				var offsetTop = this.$().offset().top;
				var popoverHeight = this.$().height();
				if (window.innerHeight < popoverHeight + offsetTop) {
					popoverHeight = window.innerHeight - offsetTop - this.get('bottomOffset');
				}
				$popover.css('max-height', popoverHeight);
			}
		}
	});
});