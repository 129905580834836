define('component-library/mixins/payroll/smp/sync-diff-data-mixin', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		cellDisplayData: (function () {
			var contentPath = this.get('contentPath');
			return this.get('row.' + contentPath);
		}).property('row'),

		isContentEmpty: (function () {
			var content = this.get('cellDisplayData');
			return _ember['default'].isEmpty(content);
		}).property('cellDisplayData'),

		click: function click(event) {
			if (!this.get('handleClick')) {
				return;
			}
			event.stopPropagation();
			var row = this.get('row');
			var resolvedValue = this.get('resolvedValue');
			if (row.get('proposedResolution') === resolvedValue) {
				row.set('proposedResolution', '');
			} else {
				row.set('proposedResolution', resolvedValue);
			}
			this.notifyPropertyChange('cellSelected');
		},

		cellSelected: (function () {
			return this.get('row.proposedResolution') === this.get('resolvedValue');
		}).property('row.proposedResolution', 'resolvedValue'),

		classes: (function () {
			var classes = ['z-syncBack-diff'];
			if (this.get('cellSelected')) {
				classes.push('js-glue-syncBack-diff-selected z-syncBack-diff-selected');
			} else if (this.get('row.proposedResolution')) {
				classes.push('z-syncBack-diff-notSelected');
			}
			if (!this.get('handleClick')) {
				classes.push('z-syncBack-diff-expands');
			}
			return classes.join(' ');
		}).property('row.proposedResolution', 'cellSelected'),

		isBanksField: _ember['default'].computed.equal('row.key', 'employeeBanks')
	});
});