define('ember-table/views/footer-table-container', ['exports', 'ember', 'ember-table/views/table-container', 'ember-table/mixins/show-horizontal-scroll', 'ember-table/mixins/register-table-component', 'ember-table/mixins/mouse-wheel-handler', 'ember-table/mixins/touch-move-handler'], function (exports, _ember, _emberTableViewsTableContainer, _emberTableMixinsShowHorizontalScroll, _emberTableMixinsRegisterTableComponent, _emberTableMixinsMouseWheelHandler, _emberTableMixinsTouchMoveHandler) {
  'use strict';

  exports['default'] = _emberTableViewsTableContainer['default'].extend(_emberTableMixinsMouseWheelHandler['default'], _emberTableMixinsTouchMoveHandler['default'], _emberTableMixinsShowHorizontalScroll['default'], _emberTableMixinsRegisterTableComponent['default'], {
    templateName: 'footer-table-container',
    classNames: ['et-table-container', 'et-fixed-table-container', 'et-footer-container'],

    styleBindings: 'top',
    height: _ember['default'].computed.alias('tableComponent._footerHeight'),
    width: _ember['default'].computed.alias('tableComponent._tableContainerWidth'),
    scrollLeft: _ember['default'].computed.alias('tableComponent._tableScrollLeft'),

    top: _ember['default'].computed(function () {
      var headerHeight = this.get('tableComponent._headerHeight');
      var contentHeight = this.get('tableComponent._tableContentHeight') + headerHeight;
      var bodyHeight = this.get('tableComponent._bodyHeight') + headerHeight;
      if (contentHeight < bodyHeight) {
        return contentHeight;
      } else {
        return bodyHeight;
      }
    }).property('tableComponent._bodyHeight', 'tableComponent._headerHeight', 'tableComponent._tableContentHeight'),

    onMouseWheel: function onMouseWheel(event, delta, deltaX) {
      var scrollLeft = this.$('.et-right-table-block').scrollLeft() + deltaX;
      this.set('scrollLeft', scrollLeft);
      event.preventDefault();
    },

    onTouchMove: function onTouchMove(event, deltaX) {
      var scrollLeft = this.$('.et-right-table-block').scrollLeft() + deltaX;
      this.set('scrollLeft', scrollLeft);
      event.preventDefault();
    }
  });
});