define('component-library/components/smp-sync-files-downloader', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	// {{smp-sync-files-downloader
	// 	smp=smp
	// 	companyPaySchedules=companyPaySchedules
	// 	company=company
	//	fixRecordsPageUrl=fixRecordsPageUrl
	//	currentControllerBinding="controller"
	//  source="console"/"client"
	// }}

	exports['default'] = _ember['default'].Component.extend({
		parentModel: null,
		companyPaySchedules: null,
		company: null,
		fixRecordsPagePath: null,
		source: null,
		selectedSmpRunId: null,
		selectedCompanyPayScheduleId: null,

		actions: {
			goToFixRecordsPage: function goToFixRecordsPage() {
				this.get('currentController').transitionTo(this.get('fixRecordsPagePath'));
			}
		},

		hasBadEmployeeRecords: _ember['default'].computed.bool('parentModel.smp.badModCount'),
		selectedCompanyPayScheduleHasSmpRuns: _ember['default'].computed.bool('selectedCompanyPaySchedule.smpRuns.length'),
		isCurrentPayPeriodSmpRun: _ember['default'].computed.bool('selectedSmpRun.isOpen'),

		numChangesInTerminationReport: (function () {
			var selectedRun = this.get('selectedSmpRun');
			if (!selectedRun) {
				return 0;
			}

			return selectedRun.get('terminationCount') + selectedRun.get('terminatedEmployeeModificationCount');
		}).property('selectedSmpRun'),

		showTa: (function () {
			var taComplete = this.get('company.taSettings.settingsComplete');
			var payrollProvider = this.get('company.payrollProvider');

			return payrollProvider === 'WF' && taComplete;
		}).property('company.taSettings.settingsComplete', 'company.payrollProvider'),

		selectedCompanyPaySchedule: (function () {
			var id = this.get('selectedCompanyPayScheduleId');
			var paySchedules = this.get('companyPaySchedules');

			if (!id) {
				return paySchedules && paySchedules.findBy('status', 'ACTIVE') || null;
			}

			return paySchedules.findBy('id', id) || null;
		}).property('selectedCompanyPayScheduleId', 'companyPaySchedules.@each'),

		sortedSmpRuns: (function () {
			var selectedCompanyPaySchedule = this.get('selectedCompanyPaySchedule');

			return _ember['default'].ArrayProxy.extend(_ember['default'].SortableMixin).create({
				sortProperties: ['startDateValue'],
				sortAscending: false,
				content: selectedCompanyPaySchedule.get('smpRuns')
			});
		}).property('selectedCompanyPaySchedule'),

		smpRunsForPayPeriod: (function () {
			var selectedCompanyPaySchedule = this.get('selectedCompanyPaySchedule');

			if (!selectedCompanyPaySchedule) {
				return [_ember['default'].Object.create({
					id: null,
					dateRangeString: '--'
				})];
			}

			return this.get('sortedSmpRuns');
		}).property('selectedCompanyPaySchedule', 'sortedSmpRuns'),

		initSelectedSmpRunIdOnPayPeriodChange: (function () {
			var smpRunsForPayPeriod = this.get('smpRunsForPayPeriod');

			if (!_ember['default'].isEmpty(smpRunsForPayPeriod)) {
				this.set('selectedSmpRunId', smpRunsForPayPeriod.objectAt(0).get('id'));
			} else {
				this.set('selectedSmpRunId', null);
			}
		}).observes('smpRunsForPayPeriod'),

		selectedSmpRun: (function () {
			var selectedCompanyPaySchedule = this.get('selectedCompanyPaySchedule');
			var selectedSmpRunId = this.get('selectedSmpRunId');
			if (!selectedCompanyPaySchedule || !selectedSmpRunId) {
				return null;
			}

			return selectedCompanyPaySchedule.get('smpRuns').findBy('id', selectedSmpRunId) || null;
		}).property('selectedCompanyPaySchedule', 'selectedSmpRunId'),

		selectedSmpRunLinks: (function () {
			var selectedRun = this.get('selectedSmpRun');
			var reportUrlRoot;
			var isConsole = this.get('source') === 'console';
			var isSMP = this.get('company.isSMP');
			var ifUpload = isSMP && isConsole ? '/False' : '';

			if (!selectedRun) {
				return null;
			}
			var reportUrlsObject = _ember['default'].Object.create({
				eeAllDeductionsReportUrl: null
			});
			var selectedRunId = selectedRun.get('id');
			reportUrlRoot = '/custom_api/payroll-integrations/smp/payroll-report/' + selectedRunId;

			if (isConsole) {
				reportUrlRoot = '/console/company/' + this.get('company.id') + '/smp_reports_download/' + selectedRunId;
				reportUrlsObject.set('eeAllDeductionsReportUrl', reportUrlRoot + '/all-deduction/False');
			}

			reportUrlsObject.setProperties({
				newHireReportUrl: reportUrlRoot + '/new-hire' + ifUpload,
				eeModReportUrl: reportUrlRoot + '/modification' + ifUpload,
				eeTerminationReportUrl: reportUrlRoot + '/termination' + ifUpload,
				eeDeductionsReportUrl: reportUrlRoot + '/deduction' + ifUpload,
				taReportUrl: reportUrlRoot + '/ta' + ifUpload
			});

			return reportUrlsObject;
		}).property('selectedSmpRun', 'source', 'company')
	});
});