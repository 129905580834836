define('component-library/wizard/flow/section/step/route', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Route.extend({
		// OVERRIDES - begin
		wizardService: null,
		serviceEnabled: false,

		wizardSectionRoute: null, // required
		showLoading: false,

		// OVERRIDES - end

		setupController: function setupController(controller, model) {
			var _this = this;

			this._super.apply(this, arguments);
			if (this.get('serviceEnabled')) {
				this.get('wizardService').onStepEnter(controller.get('wizardStepKey'));
				return;
			}

			var wizardSectionRoute = this.get('wizardSectionRoute');
			var sectionModel = this.modelFor(wizardSectionRoute);
			var wizardStepKey = controller.get('wizardStepKey');
			var wizardStepState = sectionModel.wizardSectionState.getStep(wizardStepKey);
			model.wizardStepState = wizardStepState;
			controller.set('wizardStepState', wizardStepState);

			_ember['default'].run.schedule('routerTransitions', function () {
				if (_this.verifyStepAccessible(wizardStepState)) {
					_this.send('_updateCurrentStep_stepAction', wizardStepKey, controller.get('missingStepRedirectSectionKey'), controller.get('missingStepRedirectStepKey'));
				}
			});
		},

		verifyStepAccessible: function verifyStepAccessible(stepState) {
			if (stepState && !stepState.get('isAccessible')) {
				this.send('_transitionToFlowIndex_stepAction', stepState.get('stepKey'));
				return false;
			}
			return true;
		},

		actions: {
			loading: function loading(transition, originRoute) {
				return this.get('showLoading');
			}
		}
	});
});