define('component-library/modals/ta/lock-pay-period-modal', ['exports', 'ember', 'component-library/components/promise-modal', 'component-library/utils/payroll/payroll-utils'], function (exports, _ember, _componentLibraryComponentsPromiseModal, _componentLibraryUtilsPayrollPayrollUtils) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		classNames: ['js-glue-lock-pay-period-modal'],
		templateName: 'modals/ta/lock-pay-period-modal',
		modalDialogClass: 'z-confirmationModal',
		payPeriodId: null,
		useApprovalV2: false,
		companyPayrollSyncType: null,
		companyPayrollProvider: null,
		numUnapprovedTimesheets: 0,
		numUnapprovedTimesheetsWithIssues: 0,
		errorText: '',

		title: 'Submit This Pay Period to Payroll',

		isZPay: _ember['default'].computed.equal('companyPayrollSyncType', 'PYP'),
		isSync: _ember['default'].computed.equal('companyPayrollSyncType', 'Sync'),
		isPayrollIntegrated: _ember['default'].computed.or('isZPay', 'isSync'),

		payrollProviderString: _ember['default'].computed('companyPayrollProvider', function () {
			var defaultPayrollString = 'payroll';
			return (0, _componentLibraryUtilsPayrollPayrollUtils.getPayrollProviderStringFromCode)(this.get('companyPayrollProvider'), defaultPayrollString);
		}),

		payrollSpecificMessage: _ember['default'].computed('companyPayrollSyncType', function () {
			// TODO: Delete this once we get rid of the ta_approval_v2 switch
			if (this.get('companyPayrollSyncType') === 'PYP') {
				return 'you\'ll be able to run payroll for this pay period in Zenefits Payroll.';
			} else if (this.get('companyPayrollSyncType') === 'Sync') {
				return 'we\'ll start moving this pay period to your payroll system so you can run payroll.';
			} else {
				return 'we\'ll send you a report of this pay period so you can enter hours into payroll.';
			}
		}),

		actions: {
			lockPeriod: function lockPeriod(laddaButton) {
				var _this = this;

				return _ember['default'].ajaxPost('/api/ta_lock_pay_period', JSON.stringify({
					"payPeriodId": this.get('payPeriodId')
				})).then(function (response) {
					_this.send('confirm');
				})['catch'](function () {
					_this.set('errorText', 'Something went wrong. Please try again later.');
				});
			}
		}
	});
});