define('component-library/data-table/cells/census-employee-review-table-edit-link-cell', ['exports', 'ember', 'component-library/data-table/cells/data-table-cell', 'component-library/mixins/component-routing-mixin'], function (exports, _ember, _componentLibraryDataTableCellsDataTableCell, _componentLibraryMixinsComponentRoutingMixin) {
	'use strict';

	var CONTEXT_BAR_REDIRECT_RETURN_KEY_PREFIX = 'census_review_employees_return_from_profile_';
	var CONTEXT_BAR_STRING = "the employee list";
	var switches = window.App.switches;

	exports['default'] = _componentLibraryDataTableCellsDataTableCell['default'].extend(_componentLibraryMixinsComponentRoutingMixin['default'], {
		templateName: 'data-table/cells/census-employee-review-table-edit-link-cell',
		contextMessenger: _ember['default'].inject.service(),
		key: _ember['default'].computed.alias('column.contentPath'),
		employeeId: _ember['default'].computed.alias('row.realId'),
		flow: _ember['default'].computed.alias('row._flow'),

		isContextBarOn: _ember['default'].computed('switches', function () {
			return switches.isActive('enable_context_bar');
		}),

		employeeProfilePath: _ember['default'].computed('employeeId', 'switches', function () {
			return '/employeetearsheet/' + this.get('employeeId');
		}),

		profileLinkHref: _ember['default'].computed('employeeProfilePath', function () {
			var urlBase = document.location.origin + '/dashboard/#';
			var profilePath = this.get('employeeProfilePath');

			return urlBase + profilePath;
		}),

		actions: {
			goToEmployeeProfileWithContextBar: function goToEmployeeProfileWithContextBar() {
				var contextBarKey = CONTEXT_BAR_REDIRECT_RETURN_KEY_PREFIX + this.get('employeeId');
				var contextMessenger = this.get('contextMessenger');

				contextMessenger.pushMessageAfterTransition(this.get('employeeProfilePath'), 'redirect_message', contextBarKey, {
					redirectUrl: '/census/review-employees?flow=' + this.get('flow'),
					destinationString: CONTEXT_BAR_STRING
				});
			}
		}
	});
});