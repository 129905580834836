define('component-library/data-table/cells/tooltip-table-cell', ['exports', 'ember', 'component-library/data-table/cells/data-table-cell'], function (exports, _ember, _componentLibraryDataTableCellsDataTableCell) {
	'use strict';

	exports['default'] = _componentLibraryDataTableCellsDataTableCell['default'].extend({
		templateName: 'data-table/cells/tooltip-table-cell',
		tooltip: _ember['default'].computed('row', 'column.tooltipPath', function () {
			var row = this.get('row');
			var tooltipPath = this.get('column.tooltipPath');
			if (!row || !tooltipPath) {
				return;
			}
			return row.get(tooltipPath);
		})

	});
});