define('component-library/helpers/pretty-property', ['exports', 'ember', 'component-library/utils/pretty-currency', 'component-library/utils/pretty-percent', 'component-library/utils/pretty-percent-or-currency'], function (exports, _ember, _componentLibraryUtilsPrettyCurrency, _componentLibraryUtilsPrettyPercent, _componentLibraryUtilsPrettyPercentOrCurrency) {
	'use strict';

	var _slicedToArray = (function () {
		function sliceIterator(arr, i) {
			var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;_e = err;
			} finally {
				try {
					if (!_n && _i['return']) _i['return']();
				} finally {
					if (_d) throw _e;
				}
			}return _arr;
		}return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError('Invalid attempt to destructure non-iterable instance');
			}
		};
	})();

	/**
  * DO NOT USE THIS
  * It's ugly and we don't love it. -mde
  */
	exports['default'] = _ember['default'].HTMLBars.makeBoundHelper(function (_ref) {
		var _ref2 = _slicedToArray(_ref, 3);

		var object = _ref2[0];
		var key = _ref2[1];
		var type = _ref2[2];

		var emptyPlaceholder = new _ember['default'].Handlebars.SafeString("&ndash;");
		if (_ember['default'].isEmpty(object) || _ember['default'].isEmpty(key)) {
			return emptyPlaceholder;
		}
		var value = object.get(key);
		if (_ember['default'].isEmpty(value)) {
			return emptyPlaceholder;
		}
		var result;
		if (type === 'number' || type === 'string') {
			result = value;
		} else if (type === 'currency') {
			result = (0, _componentLibraryUtilsPrettyCurrency['default'])(value);
		} else if (type === 'percent') {
			result = (0, _componentLibraryUtilsPrettyPercent['default'])(value, true);
		} else if (type === 'percentOrCurrency') {
			result = (0, _componentLibraryUtilsPrettyPercentOrCurrency['default'])(value, 2, true);
		} else if (type === 'boolean') {
			result = value ? 'Yes' : 'No';
		}
		return _ember['default'].isEmpty(result) ? emptyPlaceholder : result;
	});
});