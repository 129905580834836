define('model-library/models/company-payment-method-ach', ['exports', 'ember-data', 'model-library/models/company-payment-method-abstract'], function (exports, _emberData, _modelLibraryModelsCompanyPaymentMethodAbstract) {
	'use strict';

	var attr = _emberData['default'].attr;

	exports['default'] = _modelLibraryModelsCompanyPaymentMethodAbstract['default'].extend({
		routingNumber: attr('number'),
		accountType: attr('string'),
		bankName: attr('string')
	});
});