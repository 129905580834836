define('component-library/services/analytics', ['exports', 'ember', 'zen'], function (exports, _ember, _zen) {
	'use strict';

	var handledEvents = ['willTransition', 'didTransition'];

	// TODO this is temporary so we can run some experiments on the time it takes on our first transition from the react dashboard
	var firstSoftTransitionHandled = false;
	var isFirstTransition = true;

	exports['default'] = _ember['default'].Service.extend({
		routing: _ember['default'].inject.service(),

		_subscribeToEvents: _ember['default'].on('init', function () {
			var _this = this;

			handledEvents.forEach(function (key) {
				var capKey = key.capitalize();
				var eventName = 'router' + capKey;
				var eventHandler = _this['_' + eventName].bind(_this);
				_this.get('routing').on(eventName, eventHandler);
			});
		}),
		_routerWillTransition: function _routerWillTransition(transition) {
			_zen['default'].eventLogger.setTransitionInfo(transition.targetName);
			_zen['default'].eventLogger.timings.routeTransitionStart = new Date().getTime();
		},
		_routerDidTransition: function _routerDidTransition() {
			_ember['default'].run.next(this, function () {

				var isFirstSoftTransition = !firstSoftTransitionHandled && !!_zen['default'].eventLogger.currentRoute;
				var isFirstSoftTransitionFromReactDashboard = isFirstSoftTransition && _zen['default'].eventLogger.currentRoute === '/';

				var currentRoute = this.get('routing').get('router').get('url');
				var isViewingReactDashoard = currentRoute === '/';
				if (!isViewingReactDashoard) {
					_zen['default'].eventLogger.log('pageview', {
						isFirstSoftTransitionFromReactDashboard: isFirstSoftTransitionFromReactDashboard,
						currentRoute: currentRoute,
						transitionId: _zen['default'].eventLogger.transitionId,
						currentRouteName: this.get('routing.currentRouteName')
					});
				}

				if (isFirstTransition) {
					_zen['default'].eventLogger.log('time-to-first-ember-render', { duration: performance.now() });
					isFirstTransition = false;
				}

				if (isFirstSoftTransition) {
					firstSoftTransitionHandled = true;
				}

				_zen['default'].eventLogger.transitionId = null;
			});
		}
	});
});