define('component-library/components/pay-schedules-editor', ['exports', 'ember', 'component-library/mixins/payroll/pay-schedule-editor-mixin', 'component-library/constants/cps'], function (exports, _ember, _componentLibraryMixinsPayrollPayScheduleEditorMixin, _componentLibraryConstantsCps) {
	'use strict';

	// The pay schedule editor component includes the following:
	// 1. The big button that allows people to add new pay schedule
	// 2. The collection of pay schedules

	function missing(compType) {
		return (function () {
			return !this.get('paySchedulesCompType').contains('Both') && !this.get('paySchedulesCompType').contains(compType);
		}).property('paySchedulesCompType', 'paySchedules.@each.compType');
	}

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsPayrollPayScheduleEditorMixin['default'], {

		// this is to call validations on the wizard continue button
		didInsertElement: function didInsertElement() {
			this._super();
			this.set('register-as', this);
		},

		classNames: ['z-payScheduleCards'],

		paySchedules: null,
		zPayrollEnums: null,
		bankHolidays: null,
		blockPeriod: null,
		company: null,
		employees: null,
		creationMethod: null,
		hasTA: _ember['default'].computed.alias('company.ta.isCompleteOrPending'),
		paySchedulesCompType: _ember['default'].computed.mapBy('paySchedules', 'compTypeHuman'),

		// only used to determine whether compType radio buttons
		// should be disabled when editing pay schedule
		preventChangingCompType: false,

		// TODO(Peter): remove this after prior payroll is done
		shiftCheckDateToNextQuarter: false,
		// this could be dynamically created if we were to require more type of PS
		needsHourlyPaySchedule: missing('Hourly'),
		needsSalariedPaySchedule: missing('Salaried'),
		needsAdditionalPaySchedule: _ember['default'].computed.or('needsHourlyPaySchedule', 'needsSalariedPaySchedule'),

		// Eventually this will go away and all cps will show the legend (new style)
		showLegend: false,
		allowPastEffectiveDate: true,
		blackOutDates: [],

		missingPayScheduleCopy: (function () {
			var creationMethod = this.get('creationMethod');
			var MAPPING = (0, _componentLibraryConstantsCps.getTypeName)(creationMethod);
			var missingPaySchedule = this.get('missingPaySchedule');
			if (missingPaySchedule) {
				return MAPPING[missingPaySchedule.toUpperCase()];
			}
		}).property('missingPaySchedule'),

		missingPaySchedule: (function () {
			if (this.get('needsHourlyPaySchedule') == this.get('needsSalariedPaySchedule')) {
				return;
			}
			return this.get('needsHourlyPaySchedule') ? 'Hourly' : 'Salaried';
		}).property('needsHourlyPaySchedule', 'needsSalariedPaySchedule'),

		actions: {
			refreshPaySchedules: function refreshPaySchedules(ps) {
				this.sendAction('refreshPaySchedules', ps);
			}
		}
	});
});
// for mixins in CL/addon