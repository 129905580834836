define('component-library/filters/filter-array', ['exports', 'ember', 'component-library/filters/arrangeable-array-proxy'], function (exports, _ember, _componentLibraryFiltersArrangeableArrayProxy) {
	'use strict';

	exports['default'] = _ember['default'].ArrayController.extend({
		filterIdFilter: "",

		applyFuncs: (function () {
			var applicableFilters = this.get('applicableFilters');
			return applicableFilters.getEach('applyFunc').compact();
		}).property('applicableFilters.@each.applyFunc'),

		apply: (function () {
			var _this = this;

			return function (optionsContent) {
				var result = optionsContent.slice();
				_this.get('applyFuncs').forEach(function (applyFunc) {
					result = result.filter(applyFunc);
				});
				return result;
			};
		}).property('applyFuncs.[]'),

		applicableFilters: (function () {
			return this.get('hiddenFilters').concat(this.get('activeFilters'));
		}).property('activeFilters.@each', 'hiddenFilters.@each'),

		optionsContentFilterProperties: (function () {
			var applicableFilters = this.get('applicableFilters');
			return applicableFilters.getEach('filterProperty').compact();
		}).property('applicableFilters.@each.filterProperty'),

		optionsContent: (function () {
			return _ember['default'].A();
		}).property(),

		filteredOptionsContent: (function () {
			return _componentLibraryFiltersArrangeableArrayProxy['default'].create({
				contentController: this,
				contentBinding: 'contentController.optionsContent',
				filterPropertiesBinding: 'contentController.optionsContentFilterProperties'
			});
		}).property(),

		// Returns a query meant for a traditional $.ajax call.
		query: (function () {
			var applicableFilters = this.get('applicableFilters');
			var traditionalQuery = {};
			applicableFilters.getEach('query').compact().forEach(function (query) {
				Object.keys(query).forEach(function (key) {
					var keyList = traditionalQuery[key] || [];
					keyList.pushObject(query[key]);
					traditionalQuery[key] = keyList;
				});
			});
			return traditionalQuery;
		}).property('applicableFilters.@each.query'),

		hiddenFilters: (function () {
			return this.filterBy('isHidden');
		}).property('@each.isHidden'),

		activeFilters: (function () {
			return this.filterBy('isHidden', false).filterBy('isActive');
		}).property('@each.isActive', '@each.isHidden'),

		inactiveFilters: (function () {
			return this.filterBy('isHidden', false).filterBy('isActive', false);
		}).property('@each.isActive', '@each.isHidden'),

		serializableFilters: (function () {
			return this.get('activeFilters').filterBy('isSerializable');
		}).property('activeFilters.[]', '@each.isSerializable'),

		filteredInactiveFilters: (function () {
			var filterIdFilter = this.get('filterIdFilter');
			var inactiveFilters = this.get('inactiveFilters');
			if (!filterIdFilter) {
				return inactiveFilters;
			}
			return inactiveFilters.filter(function (filterObj) {
				return ~filterObj.name.toLowerCase().indexOf(filterIdFilter.toLowerCase());
			});
		}).property('inactiveFilters.[]', 'filterIdFilter'),

		serialization: function serialization() {
			return this.get('serializableFilters').reduce(function (filters, filter) {
				var serialize = filter.get('serialize');
				if (serialize) {
					var serializedValue = serialize.call(filter);
					// explicit undefined check to avoid null,0 conflict.
					if (serializedValue !== undefined) {
						filters[filter.get('filterId')] = serializedValue;
					}
				}
				return filters;
			}, {});
		},

		actions: {
			clearFilters: function clearFilters() {
				this.get('activeFilters').setEach('isActive', false);
			},

			clearSerializableFilters: function clearSerializableFilters() {
				this.get('serializableFilters').setEach('isActive', false);
			},

			removeFilter: function removeFilter(filter) {
				_ember['default'].set(filter, 'isActive', false);
			}
		}

	});
});