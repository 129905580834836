define('ember-table/views/header-row', ['exports', 'ember', 'ember-table/mixins/style-bindings', 'ember-table/mixins/register-table-component'], function (exports, _ember, _emberTableMixinsStyleBindings, _emberTableMixinsRegisterTableComponent) {
  'use strict';

  // We hacked this. There is an inconsistency at the level in which we are
  // handling scroll event...
  exports['default'] = _ember['default'].View.extend(_emberTableMixinsStyleBindings['default'], _emberTableMixinsRegisterTableComponent['default'], {
    templateName: 'header-row',
    classNames: ['et-table-row', 'et-header-row'],
    styleBindings: ['width'],
    columns: _ember['default'].computed.alias('content'),
    width: _ember['default'].computed.alias('tableComponent._rowWidth'),
    scrollLeft: _ember['default'].computed.alias('tableComponent._tableScrollLeft'),

    // Options for jQuery UI sortable
    sortableOption: _ember['default'].computed(function () {
      return {
        axis: 'x',
        cancel: '.js-et-headerCellPopoverLink-container',
        containment: 'parent',
        cursor: 'move',
        helper: 'clone',
        items: '.et-header-cell.sortable',
        opacity: 0.9,
        placeholder: 'ui-state-highlight',
        scroll: true,
        tolerance: 'pointer',
        update: _ember['default'].$.proxy(this.onColumnSortDone, this),
        stop: _ember['default'].$.proxy(this.onColumnSortStop, this),
        sort: _ember['default'].$.proxy(this.onColumnSortChange, this)
      };
    }),

    onScrollLeftDidChange: _ember['default'].observer(function () {
      this.$().scrollLeft(this.get('scrollLeft'));
    }, 'scrollLeft'),

    didInsertElement: function didInsertElement() {
      this._super();
      if (this.get('tableComponent.enableColumnReorder')) {
        this.$('> div').sortable(this.get('sortableOption'));
      }
    },

    willDestroyElement: function willDestroyElement() {
      if (this.get('tableComponent.enableColumnReorder')) {
        // TODO(azirbel): Get rid of this check, as in onColumnSortDone?
        var $divs = this.$('> div');
        if ($divs) {
          $divs.sortable('destroy');
        }
      }
      this._super();
    },

    onScroll: function onScroll(event) {
      this.set('scrollLeft', event.target.scrollLeft);
      event.preventDefault();
    },

    onColumnSortStop: function onColumnSortStop() {
      this.set('tableComponent._isShowingSortableIndicator', false);
    },

    onColumnSortChange: function onColumnSortChange() {
      var left = this.$('.ui-state-highlight').offset().left - this.$().closest('.et-tables-container').offset().left;
      this.set('tableComponent._isShowingSortableIndicator', true);
      this.set('tableComponent._sortableIndicatorLeft', left);
    },

    onColumnSortDone: function onColumnSortDone(event, ui) {
      var newIndex = ui.item.index();
      this.$('> div').sortable('cancel');
      var view = _ember['default'].View.views[ui.item.attr('id')];
      var column = view.get('column');
      this.get('tableComponent').onColumnSort(column, newIndex);
      this.set('tableComponent._isShowingSortableIndicator', false);
    }
  });
});