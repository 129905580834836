define('component-library/components/z-inline-base-partial', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		// BASE PARAMS
		label: null,
		isRequired: false,
		isEditable: true,
		isEditing: false,
		isSaving: false,
		useWordsForActions: false,
		displayOnlyValue: null,
		hideLabel: false,

		// INTERNAL PARAMS
		classNames: ['z-inlineBase', 'grid-block']
	});
});