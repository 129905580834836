define('component-library/components/notification-modal', ['exports', 'ember', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryComponentsPromiseModal) {
	'use strict';

	var NOTIFICATION_TYPES = {
		SUCCESS: 'success',
		WARNING: 'warning',
		ERROR: 'error'
	};

	var NOTIFICATION_ICON_CLASS = {
		success: 'check',
		warning: 'alert-triangle',
		error: 'close'
	};

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		notificationType: NOTIFICATION_TYPES.SUCCESS,
		buttonText: 'OK',
		templateName: 'components/notification-modal',
		modalDialogClass: 'z-notificationModal',
		showCancelButton: false,
		buttonCancelText: 'Cancel',

		isSuccessNotification: _ember['default'].computed.equal('notificationType', NOTIFICATION_TYPES.SUCCESS),
		isWarningNotification: _ember['default'].computed.equal('notificationType', NOTIFICATION_TYPES.WARNING),
		isErrorNotification: _ember['default'].computed.equal('notificationType', NOTIFICATION_TYPES.ERROR),

		notificationIconClass: (function () {
			return NOTIFICATION_ICON_CLASS[this.get('notificationType')];
		}).property('notificationType')
	});
});