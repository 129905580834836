define('component-library/components/deductions/deduction-paystub-popover', ['exports', 'ember', 'component-library/utils/globals', 'component-library/components/promise-popover', 'component-library/utils/pretty-currency', 'component-library/templates/components/deductions/deduction-paystub-popover', 'component-library/utils/deductions/deductions-utils'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryComponentsPromisePopover, _componentLibraryUtilsPrettyCurrency, _componentLibraryTemplatesComponentsDeductionsDeductionPaystubPopover, _componentLibraryUtilsDeductionsDeductionsUtils) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
		template: _componentLibraryTemplatesComponentsDeductionsDeductionPaystubPopover['default'],
		hasArrow: true,
		arrowOffset: -10,
		placement: 'left',
		attachment: 'bottom right',
		targetAttachment: 'bottom left',

		popoverTitle: (function () {
			var reasonCode = this.get('content.reasonCode');
			return (0, _componentLibraryUtilsDeductionsDeductionsUtils.getManualPaystubReason)(reasonCode);
		}).property('content'),

		popoverContent: (function () {
			var reasonCode = this.get('content.reasonCode');
			var amount = this.get('content.payrollDeduction');
			var company = this.get('content.company');
			var employee = this.get('content.employee');
			if (reasonCode === 'FG') {
				return new _ember['default'].Handlebars.SafeString('<div>This marks the beginning of Zenefits tracking differences between expected deductions and deductions actually made in payroll.</div>' + '<div>We assume that everything has been balanced and corrected prior to this date.</div>');
			} else if (reasonCode === 'MIS') {
				return new _ember['default'].Handlebars.SafeString('This is a <span class="z-deductionsBreakdown-manualPaystubName">Catch-up Deduction Pay Stub</span>. ' + 'Our Deductions Engine discovered a deduction we weren’t able to sync with your payroll. ' + 'This can happen when the system expects to see a different code ' + '– such as when a reimbursement gets listed as “other earnings” instead of “medical” – ' + 'and isn’t immediately sure where to put it. ' + 'We’ve created this pay stub to make the correct adjustment. Only you – the admin – can see it.');
			} else if (reasonCode === 'ADJ') {
				return new _ember['default'].Handlebars.SafeString('This is a <span class="z-deductionsBreakdown-manualPaystubName">Forgiven Paystub deduction</span>. ' + 'You requested that Zenefits forgive ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(amount) + ' that ' + employee + ' would have owed ' + company + ' due to a late or inaccurate deduction entry into payroll. ' + ('We’ve created this pay stub to show how much was forgiven and make the appropriate adjustment in ' + _componentLibraryUtilsGlobals.brandName + '.'));
			} else {
				// We should never hit this condition, as we only support FG, MIS, ADJ now.
				return reasonCode;
			}
		}).property('content')
	});
});