define('component-library/modals/pto/edit-modal', ['exports', 'ember', 'component-library/components/promise-modal', 'component-library/mixins/pto/ptorecordoreditrequestmixin'], function (exports, _ember, _componentLibraryComponentsPromiseModal, _componentLibraryMixinsPtoPtorecordoreditrequestmixin) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend(_componentLibraryMixinsPtoPtorecordoreditrequestmixin['default'], {
		templateName: 'modals/pto/edit-modal',
		modalDialogClass: 'z-typography',
		isResponsive: true,
		autoSetHeight: false,

		currentPolicy: _ember['default'].computed('model.employee.pto.companyPto.name', function () {
			var emp = this.get('model.employee');
			return emp ? this.get('model.employee.pto.companyPto.name') || 'No assigned policy' : '';
		})
	});
});