define("component-library/modals/payroll/loa-modal", ["exports", "component-library/components/promise-modal"], function (exports, _componentLibraryComponentsPromiseModal) {
	"use strict";

	exports["default"] = _componentLibraryComponentsPromiseModal["default"].extend({
		templateName: "modals/payroll/loa-modal",
		modalDialogClass: "js-glue-loaModal",
		actions: {
			onConfirm: function onConfirm() {
				this.onConfirm();
			}
		}
	});
});