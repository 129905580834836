define('component-library/utils/z-spreadsheet/parsers', ['exports', 'component-library/utils/globals', 'component-library/constants/z-spreadsheet-employee'], function (exports, _componentLibraryUtilsGlobals, _componentLibraryConstantsZSpreadsheetEmployee) {
	'use strict';

	/*
  * Parse possiable field type string, to either DB acceptable/human readable string
  * @param {Object} fieldTypeMapping -- field type mapping
  * @param {String} input -- potential field type
  * @param {String} valueType -- either 'key' / 'label'
  */

	var cleanNumberInput = function cleanNumberInput(input) {
		var cleanInput = input || '';
		return ('' + cleanInput).replace(/[\D]+/gm, '');
	};

	var cleanCharInput = function cleanCharInput(input) {
		var cleanInput = input || '';
		return ('' + cleanInput).replace(/[\W\d]+/gm, '');
	};

	var fieldTypeParser = function fieldTypeParser(fieldTypeMapping, input, valueType) {
		var field = valueType || 'label';
		var parsedValue = input;

		if (typeof input === "string") {
			(function () {
				var cleanInput = cleanCharInput(input).toLowerCase();
				var option = fieldTypeMapping.find(function (fieldTypeOption) {
					return fieldTypeOption.options && fieldTypeOption.options.contains(cleanInput);
				});

				if (option) {
					parsedValue = option[field];
				}
			})();
		}
		return parsedValue;
	};

	/*
  * @param {String} input -- potential field type
  * @param {String} field -- either 'key' / 'label'
  */
	var employmentTypeParser = function employmentTypeParser(input, field) {
		return fieldTypeParser(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYMENT_TYPE_MAPPINGS, input, field);
	};

	exports.employmentTypeParser = employmentTypeParser;

	/*
  * @param {String} input -- potential field type
  * @param {String} field -- either 'key' / 'label'
  */
	var compensationTypeParser = function compensationTypeParser(input, field) {
		return fieldTypeParser(_componentLibraryConstantsZSpreadsheetEmployee.COMPENSATION_TYPE_MAPPINGS, input, field);
	};

	exports.compensationTypeParser = compensationTypeParser;

	/*
  * @param {String} input -- potential field type
  * @param {String} field -- either 'key' / 'label'
  */
	var genderParser = function genderParser(input, field) {
		return fieldTypeParser(_componentLibraryConstantsZSpreadsheetEmployee.GENDER_MAPPINGS, input, field);
	};

	exports.genderParser = genderParser;

	/*
  * @param {String} input -- potential date input
  * @param {String} format -- date format default to "DD/MM/YYYY"
  */
	var dateParser = function dateParser(input, format) {
		var inputVal = input || '';
		inputVal = '' + inputVal;
		inputVal = inputVal.trim();

		if (inputVal.length > 0 && inputVal.length < 6) {
			return input;
		}

		format = format || _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT;
		var momentValue = (0, _componentLibraryUtilsGlobals.moment)(inputVal);
		if (!momentValue.isValid()) {
			return inputVal;
		}
		return momentValue.format(format);
	};

	exports.dateParser = dateParser;

	/*
  * @param {String} input -- potential ssn input
  * @output {String} -- format ssn input to "xxx-xx-xxxx""
  */
	var ssnParser = function ssnParser(input) {
		var cleanInput = cleanNumberInput(input);

		if (cleanInput.length > 5) {
			return [cleanInput.slice(0, 3), '-', cleanInput.slice(3, 5), '-', cleanInput.slice(5)].join('');
		}
		return cleanInput;
	};

	exports.ssnParser = ssnParser;

	/*
  * @param {String} input -- potential zip input
  * @output {String} -- format zip input to "xxxxx""
  */
	var zipParser = function zipParser(input) {
		var cleanInput = input || '';

		return cleanInput.split('-')[0].trim();
	};

	exports.zipParser = zipParser;

	var booleanParser = function booleanParser(input, field) {
		return fieldTypeParser(_componentLibraryConstantsZSpreadsheetEmployee.BOOLEAN_MAPPINGS, input, field);
	};

	exports.booleanParser = booleanParser;

	var eeoRaceParser = function eeoRaceParser(input, field) {
		return fieldTypeParser(_componentLibraryConstantsZSpreadsheetEmployee.EEO_RACE_MAPPINGS, input, field);
	};

	exports.eeoRaceParser = eeoRaceParser;

	var eeoJobCategoryParser = function eeoJobCategoryParser(input, field) {
		return fieldTypeParser(_componentLibraryConstantsZSpreadsheetEmployee.EEO_JOB_CATEGORY_MAPPINGS, input, field);
	};

	exports.eeoJobCategoryParser = eeoJobCategoryParser;

	var eeoJobCategoryParserComponent2 = function eeoJobCategoryParserComponent2(input, field) {
		return fieldTypeParser(_componentLibraryConstantsZSpreadsheetEmployee.EEO_JOB_CATEGORY_COMPONENT2_MAPPINGS_FROM_OLD_KEY_TO_NEW_LABEL, input, field);
	};

	exports.eeoJobCategoryParserComponent2 = eeoJobCategoryParserComponent2;

	var employeeStatusParser = function employeeStatusParser(input, field) {
		return fieldTypeParser(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYEE_STATUS_MAPPINGS, input, field);
	};

	exports.employeeStatusParser = employeeStatusParser;

	var employeePaymentMethodParser = function employeePaymentMethodParser(input, field) {
		return fieldTypeParser(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYEE_PAYMENT_METHOD_MAPPINGS, input, field);
	};
	exports.employeePaymentMethodParser = employeePaymentMethodParser;
});