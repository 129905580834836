define('model-library/models/z-payroll-tax-identifier', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	exports['default'] = _emberData['default'].Model.extend({
		taxPayee: _emberData['default'].attr('string'),
		residentType: _emberData['default'].attr('string'),
		earningType: _emberData['default'].attr('string'),
		description: _emberData['default'].attr('string'),
		symmetryTaxCode: _emberData['default'].attr('string'),
		taxType: _emberData['default'].attr('string'),
		stateCode: _emberData['default'].attr('string'),
		countyCode: _emberData['default'].attr('string'),
		featureCode: _emberData['default'].attr('string'),
		subTypeId: _emberData['default'].attr('string')
	});
});