define('z-table/components/z-data-manager', ['exports', 'ember', 'z-table/templates/components/z-data-manager', 'lodash'], function (exports, _ember, _zTableTemplatesComponentsZDataManager, _lodash) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    layout: _zTableTemplatesComponentsZDataManager['default'],
    tagName: '',
    items: computed(function () {
      return [];
    }),
    filterDescriptor: computed(function () {
      return {};
    }),
    sortDescriptor: computed(function () {
      return {};
    }),
    pageSize: Infinity,
    currentPage: 1,
    totalPages: computed('sortedItems.length', 'pageSize', function () {
      var totalItems = this.get('sortedItems.length');
      var pageSize = this.get('pageSize');
      if (pageSize === Infinity) {
        return 1;
      }

      return Math.ceil(totalItems / pageSize);
    }),

    pageDescriptor: computed('pageSize', 'totalPages', 'currentPage', function () {
      return {
        pageSize: this.get('pageSize'),
        currentPage: this.get('currentPage'),
        totalPages: this.get('totalPages')
      };
    }),

    filteredItems: computed('filterDescriptor', 'items.[]', function () {
      var _this = this;

      var filterDescriptor = this.get('filterDescriptor');
      var items = this.get('items');
      var filterKeys = Object.keys(filterDescriptor);
      if (filterKeys.length === 0) {
        // descriptor is empty
        return items;
      }

      return _lodash['default'].filter(items, function (item) {
        return _lodash['default'].every(filterKeys, function (key) {
          return _this._passesFilter(filterDescriptor[key], item[key]);
        });
      });
    }),

    _passesFilter: function _passesFilter(filter, value) {
      var matchAnySet = new Set(filter['matchAny']);
      if (matchAnySet.size && !matchAnySet.has(value)) {
        return false;
      }

      var substring = filter['stringContains'];
      if (substring && value && !value.toLocaleLowerCase().includes(substring.toLocaleLowerCase())) {
        return false;
      }

      // add more filters here later

      var customFilter = filter['custom'];
      if (customFilter && _lodash['default'].isFunction(customFilter)) {
        return customFilter(value);
      }

      return true;
    },

    sortedItems: computed('sortDescriptor', 'filteredItems.[]', function () {
      var sortDescriptor = this.get('sortDescriptor');
      var items = this.get('filteredItems');
      if (!sortDescriptor[0]) {
        return items;
      }

      var sortedItems = _lodash['default'].sortBy(items, sortDescriptor[0].key);
      return sortDescriptor[0].isAsc ? sortedItems : sortedItems.reverse();
    }),

    pagedItems: computed('pageSize', 'currentPage', 'sortedItems.[]', function () {
      var totalPages = this.get('totalPages');
      var items = this.get('sortedItems');
      if (totalPages === 1) {
        return items;
      }

      var currentPage = this.get('currentPage');
      var pageSize = this.get('pageSize');
      var begin = pageSize * (currentPage - 1);

      return items.slice(begin, begin + pageSize);
    }),

    actions: {
      onFilterChange: function onFilterChange(newFilterDescriptor) {
        this.set('filterDescriptor', newFilterDescriptor);
        return this.set('currentPage', 1);
      },

      onSortChange: function onSortChange(newSortDescriptor) {
        this.set('sortDescriptor', newSortDescriptor);
        return this.set('currentPage', 1);
      },

      onPageChange: function onPageChange(newPageNum) {
        if (!newPageNum || newPageNum < 1 || newPageNum > this.get('totalPages')) {
          return;
        }

        this.set('currentPage', newPageNum);
      }
    }
  });
});