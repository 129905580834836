define('z-table/components/z-table-header-cell', ['exports', 'ember', 'z-table/templates/components/z-table-header-cell'], function (exports, _ember, _zTableTemplatesComponentsZTableHeaderCell) {
	'use strict';

	var computed = _ember['default'].computed;

	exports['default'] = _ember['default'].Component.extend({
		layout: _zTableTemplatesComponentsZTableHeaderCell['default'],
		attributeBindings: ['dataTest:data-test'],
		dataTest: 'z-table-header-cell',
		tagName: 'th',
		disableSorting: false,
		api: null,
		header: '',
		fieldName: '',

		isSorted: computed('api.sortDescriptor', 'fieldName', function () {
			var sorter = this.get('api.sortDescriptor');
			return sorter && sorter[0] && sorter[0].key === this.get('fieldName');
		}),

		isAsc: computed('api.sortDescriptor', function () {
			var sorter = this.get('api.sortDescriptor');
			return sorter && sorter[0] && sorter[0].isAsc;
		}),

		click: function click() {
			if (this.get('disableSorting')) {
				return;
			}

			var isSorted = this.get('isSorted');
			var isAsc = this.get('isAsc');

			// if already descending sorted, return to original list
			if (isSorted && !isAsc) {
				this.get('api.sortAction')(null);
				return;
			}

			if (isSorted) {
				this.get('api.sortAction')(this.get('fieldName'), false);
				return;
			}

			this.get('api.sortAction')(this.get('fieldName'), true);
		}
	});
});