define('component-library/components/z-progress-navigation', ['exports', 'ember', 'component-library/constants/components/z-progress-navigation'], function (exports, _ember, _componentLibraryConstantsComponentsZProgressNavigation) {
	'use strict';

	// ZProgressNavigationComponent
	var ZProgressNavigationComponent = _ember['default'].Component.extend({
		classNames: ['z-progress-navigation', 'js-z-progress-navigation'],

		sections: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		steps: _ember['default'].computed('sections.@each.steps.[]', function () {
			return this.get('sections').reduce(function (steps, section) {
				return steps.concat(section.get('steps'));
			}, []);
		}),

		selectedStepKey: null,

		didRender: function didRender() {
			this._super.apply(this, arguments);

			var selectedStepKey = this.get('selectedStepKey');
			var steps = this.get('steps');
			var selectedStep = steps.find(function (step) {
				return step.get('key') === selectedStepKey;
			});

			// unselect all steps
			steps.setEach('isSelected', false);

			// select step
			if (selectedStep) {
				selectedStep.set('isSelected', true);
			}
		},

		actions: {
			updateStepSelection: function updateStepSelection(step) {
				this.attrs.updateStepSelection(step);
			}
		}
	});

	exports.ZProgressNavigationComponent = ZProgressNavigationComponent;

	// ZProgressNavigationService
	var ZProgressNavigationService = _ember['default'].Service.extend({

		routing: _ember['default'].inject.service(),

		selectedStepKey: undefined,
		sections: undefined,

		createZProgressNavigationSectionPresenter: function createZProgressNavigationSectionPresenter(properties) {
			if (!properties.steps) {
				properties.steps = [];
			}
			return _componentLibraryConstantsComponentsZProgressNavigation.ZProgressNavigationSectionPresenter.create(properties);
		},

		createZProgressNavigationStepPresenter: function createZProgressNavigationStepPresenter(properties) {
			if (!properties.key) {
				console.error("ZProgressNavigationStepPresenter missing 'key'");
			}

			if (!properties.state) {
				console.error("ZProgressNavigationStepPresenter missing 'state");
			}

			return _componentLibraryConstantsComponentsZProgressNavigation.ZProgressNavigationStepPresenter.create(properties);
		}

	});

	exports.ZProgressNavigationService = ZProgressNavigationService;
	exports['default'] = ZProgressNavigationComponent;
});