define('component-library/timeline/components/employment-type-change', ['exports', 'ember', 'component-library/timeline/components/copy', 'component-library/timeline/mixins/change-feed-entry', 'component-library/utils/computed-props/not-blank'], function (exports, _ember, _componentLibraryTimelineComponentsCopy, _componentLibraryTimelineMixinsChangeFeedEntry, _componentLibraryUtilsComputedPropsNotBlank) {
	'use strict';

	exports['default'] = _componentLibraryTimelineComponentsCopy['default'].extend(_componentLibraryTimelineMixinsChangeFeedEntry['default'], {

		label: 'employment type',
		oldVal: mapEmploymentType('data.old_employment_type'),
		newVal: mapEmploymentType('data.new_employment_type'),
		oldValAvailable: (0, _componentLibraryUtilsComputedPropsNotBlank['default'])('data.old_employment_type'),
		newValAvailable: (0, _componentLibraryUtilsComputedPropsNotBlank['default'])('data.new_employment_type')

	});

	var employmentTypes = {
		'FT': 'Full Time',
		'PT': 'Part Time',
		'TP': 'Temporary'
	};

	function mapEmploymentType(key) {
		return _ember['default'].computed(key, function () {
			var et = _ember['default'].get(this, key);
			return employmentTypes[et] || 'Unknown';
		});
	}
});