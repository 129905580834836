define('component-library/components/ladda-button', ['exports', 'ember', 'component-library/mixins/inbound-actions'], function (exports, _ember, _componentLibraryMixinsInboundActions) {
	'use strict';

	/*
  * Purpose: Disable button presses on click, and shows a spinner or progress bar in button.
  *
  * Extends https://github.com/hakimel/Ladda. Note: The properties are accessed via camelCase from this component.
  *
  * Example use in template:
  *
  * {{#ladda-button class="btn btn--sm" onClick="save"}}Save{{/ladda-button}}
  *
  * 	In your controller:
  *
  * 	actions: {
  * 		...
  *		save: function (saveButton) {
  * 			var somePromise = ...;
  *          somePromise.then(
  *              function() { saveButton.stop(); },
  *              function() { saveButton.stop(); }
  *          );
  * 		},
  * 		...
  * 	}
  *
  * Note: If you want to pass a parameter (for instance a loop variable in the handlebar) to the action,
  * pass it as a 'param' parameter to the component.
  */

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsInboundActions['default'], {
		tagName: 'button',
		classNames: ['z-laddaButton', 'btn'],
		classNameBindings: ['_isDisabled:is-disabled', 'isPrimary:btn--primary', '_isLoading:is-loading'],
		attributeBindings: ['tabindex'],
		isDisabled: false,
		isPrimary: true,
		param: null,
		tabindex: '-1',
		dataStyle: 'zoom-out',

		_isLoading: false,
		_isDisabled: _ember['default'].computed.or('isDisabled', '_isLoading'),

		dataSpinnerColor: (function () {
			return this.get('isPrimary') ? '#fff' : '#666';
		}).property('isPrimary'),

		setDefaultProperty: function setDefaultProperty(propName, newValue) {
			var value = this.get(propName);
			if (!value) {
				this.set(propName, newValue);
			}
		},

		didRender: function didRender() {
			this._super();

			var ladda = this.get('ladda');
			if (_ember['default'].isEmpty(ladda)) {
				this.set('ladda', window.Ladda.create(this.$('.z-laddaButton-loaderWrapper').get(0)));
			}
		},

		click: function click() {
			this.start();
			this.sendAction('onClick', this, this.get('param'));
		},

		start: function start() {
			this.set('_isLoading', true);
			var laddaButton = this.get('ladda');
			if (!laddaButton.isLoading()) {
				laddaButton.toggle();
			}
		},

		stop: function stop() {
			if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
				var laddaButton = this.get('ladda');
				if (laddaButton.isLoading()) {
					laddaButton.toggle();
				}
				this.set('_isLoading', false);
			}
		},

		actions: {
			rerender: function rerender() {
				if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
					this.rerender();
				}
			}
		}
	});
});