define("component-library/templates/components/benefits/timeline/operation-panel", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.10-zenefits.3+18063622",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 12,
                    "column": 6
                  },
                  "end": {
                    "line": 12,
                    "column": 56
                  }
                },
                "moduleName": "modules/component-library/templates/components/benefits/timeline/operation-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(": ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "filter.description", ["loc", [null, [12, 34], [12, 56]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.10-zenefits.3+18063622",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 5
                },
                "end": {
                  "line": 13,
                  "column": 5
                }
              },
              "moduleName": "modules/component-library/templates/components/benefits/timeline/operation-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("						");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n						");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["content", "filter.name", ["loc", [null, [11, 6], [11, 21]]]], ["block", "if", [["get", "filter.description", ["loc", [null, [12, 12], [12, 30]]]]], [], 0, null, ["loc", [null, [12, 6], [12, 63]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.10-zenefits.3+18063622",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 3
              },
              "end": {
                "line": 18,
                "column": 3
              }
            },
            "moduleName": "modules/component-library/templates/components/benefits/timeline/operation-panel.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "u-flexContainer u-flexCenter u-bumperBottom--xs u-bumperRight--sm");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("					");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "href", "#");
            dom.setAttribute(el2, "class", "btn");
            var el3 = dom.createTextNode("\n						");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "fa fa-times");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n					");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n				");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element0, 1, 1);
            morphs[1] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["block", "popover-link", [], ["class", "u-ellipsis btn", "popoverClass", ["subexpr", "@mut", [["get", "filter.popoverClass", ["loc", [null, [8, 19], [8, 38]]]]], [], []], "content", ["subexpr", "@mut", [["get", "filter", ["loc", [null, [9, 14], [9, 20]]]]], [], []]], 0, null, ["loc", [null, [6, 5], [13, 22]]]], ["element", "action", ["removeFilter", ["get", "filter", ["loc", [null, [14, 53], [14, 59]]]]], ["target", ["get", "filters", ["loc", [null, [14, 67], [14, 74]]]]], ["loc", [null, [14, 29], [14, 76]]]]],
          locals: ["filter"],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.10-zenefits.3+18063622",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 4
              },
              "end": {
                "line": 27,
                "column": 4
              }
            },
            "moduleName": "modules/component-library/templates/components/benefits/timeline/operation-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("					Add Filter ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "zmdi zmdi-plus");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.10-zenefits.3+18063622",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 3
              },
              "end": {
                "line": 37,
                "column": 3
              }
            },
            "moduleName": "modules/component-library/templates/components/benefits/timeline/operation-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "u-flexContainer u-flexCenter u-bumperBottom--xs u-bumperRight--sm");
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n				");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "toggle-button", [], ["label", "Show Speculative", "isChecked", ["subexpr", "@mut", [["get", "selected.showSpeculative", ["loc", [null, [34, 16], [34, 40]]]]], [], []]], ["loc", [null, [32, 5], [35, 7]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.10-zenefits.3+18063622",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 3
              },
              "end": {
                "line": 48,
                "column": 3
              }
            },
            "moduleName": "modules/component-library/templates/components/benefits/timeline/operation-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "u-bumperRight--sm");
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n				");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "z-text-field-number", [], ["placeholder", "Company ID", "value", ["subexpr", "@mut", [["get", "selected.company", ["loc", [null, [44, 12], [44, 28]]]]], [], []], "isDisabled", ["subexpr", "@mut", [["get", "disableCompanySelection", ["loc", [null, [45, 17], [45, 40]]]]], [], []]], ["loc", [null, [42, 5], [46, 7]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.10-zenefits.3+18063622",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 58,
              "column": 0
            }
          },
          "moduleName": "modules/component-library/templates/components/benefits/timeline/operation-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "u-flexContainer u-flexCenter u-spaceBetween u-flexWrap-wrap u-padHorizontal");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "u-flexContainer u-flexCenter u-flexWrap-wrap u-bumperBottom--xs");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "u-flexContainer u-flexCenter u-bumperBottom--xs u-bumperRight--sm");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("			");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "u-flexContainer u-bumperBottom--xs u-flexCenter");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element2, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element3, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
          morphs[2] = dom.createMorphAt(element3, 5, 5);
          morphs[3] = dom.createMorphAt(element4, 1, 1);
          morphs[4] = dom.createMorphAt(element4, 3, 3);
          return morphs;
        },
        statements: [["block", "each", [["get", "filters.activeFilters", ["loc", [null, [4, 11], [4, 32]]]]], [], 0, null, ["loc", [null, [4, 3], [18, 12]]]], ["block", "popover-link", [], ["class", "btn u-ellipsis", "popoverClass", ["subexpr", "@mut", [["get", "FilterListPopover", ["loc", [null, [23, 18], [23, 35]]]]], [], []], "content", ["subexpr", "@mut", [["get", "filters", ["loc", [null, [24, 13], [24, 20]]]]], [], []]], 1, null, ["loc", [null, [21, 4], [27, 21]]]], ["block", "if", [["get", "enableSepeculativeToggle", ["loc", [null, [30, 9], [30, 33]]]]], [], 2, null, ["loc", [null, [30, 3], [37, 10]]]], ["block", "if", [["get", "enableCompanySelection", ["loc", [null, [40, 9], [40, 31]]]]], [], 3, null, ["loc", [null, [40, 3], [48, 10]]]], ["inline", "z-select-2", [], ["content", ["subexpr", "@mut", [["get", "candidateEmployees", ["loc", [null, [50, 12], [50, 30]]]]], [], []], "value", ["subexpr", "@mut", [["get", "selected.employees", ["loc", [null, [51, 10], [51, 28]]]]], [], []], "multiple", true, "optionLabelPath", "fullName", "placeholder", "Name"], ["loc", [null, [49, 3], [55, 5]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.10-zenefits.3+18063622",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 59,
            "column": 0
          }
        },
        "moduleName": "modules/component-library/templates/components/benefits/timeline/operation-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "isDisabled", ["loc", [null, [1, 10], [1, 20]]]]], [], 0, null, ["loc", [null, [1, 0], [58, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});