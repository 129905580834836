define('component-library/data-table/cells/avatarname-table-cell', ['exports', 'component-library/data-table/cells/data-table-cell'], function (exports, _componentLibraryDataTableCellsDataTableCell) {
	'use strict';

	exports['default'] = _componentLibraryDataTableCellsDataTableCell['default'].extend({
		templateName: 'data-table/cells/avatarname-table-cell',

		hideAvatar: (function () {
			return this.get('employee.hideAvatar');
		}).property('employee.hideAvatar'),

		employee: (function () {
			return this.get('column.contentPath') ? this.get('cellContent') : this.get('row');
		}).property('column.contentPath', 'row'),

		firstName: (function () {
			return this.get('employee.informalFirstName') || this.get('employee.first_name') || this.get('employee.firstName');
		}).property('employee.informalFirstName', 'employee.first_name', 'employee.firstName'),

		lastName: (function () {
			return this.get('employee.last_name') || this.get('employee.lastName');
		}).property('employee.last_name', 'employee.lastName'),

		fileIoUrlDoesNotEndWithSlashRegExp: (function () {
			return new RegExp('^https://www.filepicker.io/api/file/[\\w\\d]+$');
		}).property(),

		fileIoUrlEndsWithSlashRegExp: (function () {
			return new RegExp('^https://www.filepicker.io/api/file/[\\w\\d]+/$');
		}).property(),

		// TODO(feng): we should have a field in model specifically for thumbnailUrl
		avatarThumbnailUrl: (function () {
			var photoUrl = this.get('employee.photoUrl');

			if (photoUrl && this.get('fileIoUrlDoesNotEndWithSlashRegExp').test(photoUrl)) {
				return photoUrl + '/convert?w=80&h=80&fit=scale';
			} else if (photoUrl && this.get('fileIoUrlEndsWithSlashRegExp').test(photoUrl)) {
				return photoUrl + 'convert?w=80&h=80&fit=scale';
			} else {
				return photoUrl;
			}
		}).property('employee.photoUrl'),

		typeOfContractor: (function () {
			if (this.get('employee.typeOfContractor')) {
				// debugger;
			}
			return this.get('employee.typeOfContractor');
		}).property('employee.id', 'employee.typeOfContractor'),

		badgeText: (function () {
			return this.get('employee.badgeText');
		}).property('employee.id', 'employee.typeOfContractor'),

		isWorkerOnLOA: (function () {
			return this.get('employee.isWorkerOnLOA');
		}).property('employee.id', 'employee.isWorkerOnLOA')

	});
});