define('component-library/components/simple-date-filter', ['exports', 'ember', 'component-library/templates/components/simple-date-filter', 'component-library/components/promise-popover'], function (exports, _ember, _componentLibraryTemplatesComponentsSimpleDateFilter, _componentLibraryComponentsPromisePopover) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
		template: _componentLibraryTemplatesComponentsSimpleDateFilter['default'],
		hasArrow: true,
		placement: 'bottom',
		target: _ember['default'].computed.oneWay('model.target'),
		attachment: "top center",
		targetAttachment: "bottom center",

		filter: _ember['default'].computed.alias('content'),

		startDate: _ember['default'].computed.oneWay('filter.startDate'),
		endDate: _ember['default'].computed.oneWay('filter.endDate'),

		addOrApply: (function () {
			return this.get('filter.isActive') ? 'Apply' : 'Add';
		}).property('filter.isActive'),

		actions: {
			addOrApplyFilter: function addOrApplyFilter() {
				// why the fuck do we need this? fucking unless abstractions
				if (!this.get('filter.isActive')) {
					this.set('filter.isActive', true);
				}

				this.set('filter.startDate', this.get('startDate'));
				this.set('filter.endDate', this.get('endDate'));

				this.send('close');
			}
		}
	});
});