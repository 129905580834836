define('component-library/utils/benefits/timeline/query-params', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = function (selected) {
		var params = {};
		if (!_ember['default'].isEmpty(selected.get('company'))) {
			params['companyIds'] = selected.get('company.id');
		}
		if (!_ember['default'].isEmpty(selected.get('employees'))) {
			params['employeeIds'] = selected.get('employees').mapBy('id');
		}
		if (!_ember['default'].isEmpty(selected.get('benefitsApps'))) {
			params['benefitsApps'] = selected.get('benefitsApps').mapBy('text');
		}
		if (!_ember['default'].isEmpty(selected.get('benefitsTypes'))) {
			params['benefitsTypes'] = selected.get('benefitsTypes').mapBy('text');
		}
		if (!_ember['default'].isEmpty(selected.get('benefitsEvents'))) {
			params['benefitsEvents'] = selected.get('benefitsEvents').mapBy('text');
		}
		if (selected.get('historyDate')) {
			params['historyDate'] = selected.get('historyDate').format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
		}
		if (selected.get('sort')) {
			params['sort'] = selected.get('sort');
		}
		if (selected.get('sort') == 'effectiveDate') {
			params['activeOnly'] = true;
		}
		if (selected.get('effectiveDateMin')) {
			params['effectiveDateMin'] = selected.get('effectiveDateMin').format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
		}
		if (selected.get('effectiveDateMax')) {
			params['effectiveDateMax'] = selected.get('effectiveDateMax').format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
		}
		if (selected.get('creationDateMin')) {
			params['creationDateMin'] = selected.get('creationDateMin').format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
		}
		if (selected.get('creationDateMax')) {
			params['creationDateMax'] = selected.get('creationDateMax').format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
		}
		params['showSpeculative'] = !!selected.get('showSpeculative');
		return params;
	};
});