define('component-library/filters/single-select-filter-spec', ['exports', 'ember', 'component-library/filters/helpers', 'component-library/components/single-select-filter'], function (exports, _ember, _componentLibraryFiltersHelpers, _componentLibraryComponentsSingleSelectFilter) {
  'use strict';

  exports['default'] = _ember['default'].Object.extend({
    popoverClass: _componentLibraryComponentsSingleSelectFilter['default'],

    isActive: false,
    isHidden: false,
    isSerializable: true,
    filterId: undefined,
    name: "",
    placeholder: undefined,
    allSerializedSelections: {},

    async: false,
    nullAsId: false,
    // TODO(ed): comment on exactly what this means... something like the options are strings only
    stringModel: false,

    // this is required for async
    valueTypeKey: undefined,
    valuePath: undefined,
    // The labelPath is relative to the valuePath
    labelPath: '',
    labelPaths: (function () {
      return [this.get('labelPath')];
    }).property('labelPath'),
    labelFunction: (function () {
      var _this = this;

      return function (value) {
        return _ember['default'].get(value, _this.get('labelPath'));
      };
    }).property(),
    // TODO(ed): rewrite this
    labelFunctionOverride: false,

    queryValuePath: undefined,

    optionValuePath: (function () {
      return this.get('stringModel') ? '' : 'model';
    }).property('stringModel'),
    optionLabelPath: (function () {
      return this.get('stringModel') ? '' : 'label';
    }).property('stringModel'),
    optionCountMap: undefined,

    selection: null,

    selectionsWithoutNullAsId: (function () {
      var selectionsWithoutNullAsId = [this.get('selection')];
      selectionsWithoutNullAsId.removeObject(String(null));
      return selectionsWithoutNullAsId;
    }).property('selection'),

    optionsContent: undefined,

    setupDescriptionProperty: (function () {
      var dependentPaths = ['labelFunction', 'selection'];
      if (this.get('nullAsId')) {
        dependentPaths = dependentPaths.concat(this.get('labelPaths').map(function (labelPath) {
          return 'selectionsWithoutNullAsId.@each.' + labelPath;
        }));
      } else {
        dependentPaths = dependentPaths.concat(this.get('labelPaths').map(function (labelPath) {
          return 'selection.' + labelPath;
        }));
      }

      this.propertyWillChange('description');
      _ember['default'].defineProperty(this, 'description', _ember['default'].computed.apply(null, dependentPaths.concat(function () {
        return this.get('labelFunction')(this.get('selection'));
      })));
      this.propertyDidChange('description');
    }).observes('labelPaths.[]').on('init'),

    applyFuncHelper: function applyFuncHelper(key, property) {
      var selection = this.get('selection');
      // TODO(ed): async true :O
      var propertyId = _ember['default'].getWithDefault(property || null, 'id', null);
      if (!_ember['default'].isEmpty(propertyId)) {
        return _ember['default'].get(selection, 'id') == propertyId;
      }

      if (this.get('nullAsId') && property === null) {
        return selection === String(property);
      }

      return selection === property;
    },

    setupApplyFunc: (function () {
      var dependentPaths = ['selection' + (this.get('async') ? '.id' : '')];
      this.propertyWillChange('applyFunc');
      _ember['default'].defineProperty(this, 'applyFunc', _ember['default'].computed.apply(null, dependentPaths.concat(function () {
        var _this2 = this;

        return function (element) {
          return _this2.applyFuncHelper(undefined, _ember['default'].get(element, _this2.get('valuePath')));
        };
      })));
      this.propertyDidChange('applyFunc');
    }).observes('async').on('init'),

    setupFilterProperty: (function () {
      var valuePath = this.get('valuePath');
      var dependentPaths = ['selection' + (this.get('async') ? '.id' : '')];
      var applyFuncHelper = this.applyFuncHelper.bind(this);
      this.propertyWillChange('filterProperty');
      _ember['default'].defineProperty(this, 'filterProperty', _ember['default'].computed.apply(null, dependentPaths.concat(function () {
        return [valuePath, applyFuncHelper];
      })));
      this.propertyDidChange('filterProperty');
    }).observes('valuePath', 'async').on('init'),

    query: (function () {
      var query = {};
      var value;
      var selection = this.get('selection');
      if (this.get('nullAsId') && selection === String(null)) {
        value = null;
      } else {
        if (!_ember['default'].isEmpty(_ember['default'].getWithDefault(selection, 'id', null))) {
          value = selection.get('id');
        } else {
          value = selection;
        }
      }

      var valuePath = this.get('queryValuePath') || this.get('valuePath');
      var queryKey = valuePath.replace(/\./g, '__');
      if (value === null) {
        query[queryKey + '__isnull'] = true;
      } else {
        query[queryKey] = value;
      }
      return query;
    }).property('queryValuePath', 'valuePath', 'selection', 'nullAsId'),

    // TODO(ed): use an observer/computed on the 'options' property that updates selections based on the old options. otherwise selections
    // won't be updated when options change. this will be important for saved views
    // TODO(ed): the default options here takes from the content. otherwise this needs to be overridden with an array or promisearray
    setupOptions: (function () {
      if (!this.get('optionsContent')) {
        return;
      }
      var valuePath = this.get('valuePath');
      var dependentPaths = ['async', 'valueTypeKey', 'nullAsId', 'labelFunctionOverride', 'stringModel'].concat(this.get('labelPaths').map(function (labelPath) {
        return 'optionsContent.@each.' + valuePath + '.' + labelPath;
      }));
      this.propertyWillChange('options');
      _ember['default'].defineProperty(this, 'options', _ember['default'].computed.apply(null, dependentPaths.concat(function () {
        var _this3 = this;

        // TODO(ed): must fix these hacks. it needs to be generalized
        var labelPath = this.get('labelPath');
        var modelCountListOptions = {};
        modelCountListOptions.nullAsId = this.get('nullAsId');
        // TODO(ed): must fix these hacks
        modelCountListOptions.optionLabel = this.get('labelFunctionOverride') ? this.get('labelFunction') : labelPath;
        if (this.get('async')) {
          modelCountListOptions.modelKeyId = valuePath + '.id';
          modelCountListOptions.typeKey = this.get('valueTypeKey');
        }
        if (this.get('optionCountMap')) {
          modelCountListOptions.countMap = this.get('optionCountMap')();
        }
        var data = (0, _componentLibraryFiltersHelpers.modelCountList)(this.get('optionsContent'), valuePath, modelCountListOptions);

        // the sort function needs to be given. default one may account for nullAsId, but not sort order of null

        if (this.get('async')) {
          data.promise.then(function () {
            // TODO(ed): async... this is absolutely insane. There must be a better way.
            if (_this3.get('selection')) {
              var selections = [_this3.get('selection')];
              (0, _componentLibraryFiltersHelpers.updateModelSelectionsOnModelAndCountsDidChange)(_this3.get('options'), selections);
              _this3.set('selection', selections.get('firstObject') || null);
            }
            data.set('[]', data.sort(function (a, b) {
              return _ember['default'].compare(a.get('model.' + labelPath), b.get('model.' + labelPath));
            }));
          });
        }

        return this.get('stringModel') ? data.getEach('model') : data;
      })));
      this.propertyDidChange('options');
    }).observes('optionsContent', 'valuePath', 'labelPaths.[]').on('init'),

    serialize: function serialize() {
      var value;
      var selection = this.get('selection');
      if (this.get('nullAsId') && selection === String(null)) {
        value = null;
      } else if (!_ember['default'].isEmpty(_ember['default'].getWithDefault(selection, 'id', null))) {
        value = selection.get('id');
      } else {
        value = selection;
      }

      return [value];
    },

    deserialize: function deserialize(serializedSelections) {
      var _this4 = this;

      if (this.get('stringModel')) {
        return serializedSelections.slice();
      }
      var selectedOptions = null;
      if (this.get('nullAsId') && serializedSelections.contains(String(null))) {
        var nullOption = this.get('options').findBy(this.get('optionIdPath'), String(null));
        if (nullOption) {
          selectedOptions = nullOption;
        }
      } else {
        selectedOptions = this.get('options').filter(function (option) {
          return serializedSelections.contains(String(option.get(_this4.get('optionValuePath'))));
        });
      }
      if (selectedOptions && selectedOptions.get('length') == 1) {
        return selectedOptions[0].get(this.get('optionValuePath'));
      }
      return null;
    },

    updateSelection: (function () {
      var allSerializedSelections = this.get('allSerializedSelections');
      var filterId = this.get('filterId');
      if (allSerializedSelections.hasOwnProperty(filterId)) {
        var serializedSelections = allSerializedSelections[filterId];
        this.set('selection', this.deserialize(serializedSelections));
        this.set('isActive', true);
      }
    }).observes('allSerializedSelections')

  });
});