define('z-avatars/components/initials-avatar', ['exports', 'ember', 'z-avatars/templates/components/initials-avatar'], function (exports, _ember, _zAvatarsTemplatesComponentsInitialsAvatar) {
  'use strict';

  var AVATAR_SIZES = {
    'x-small': 'avatar--xs',
    small: 'avatar--sm',
    medium: 'avatar--md',
    large: 'avatar--lg',
    'x-large': 'avatar--xl'
  };

  exports['default'] = _ember['default'].Component.extend({
    layout: _zAvatarsTemplatesComponentsInitialsAvatar['default'],
    classNames: ['avatar'],
    classNameBindings: ['sizeClass'],
    firstName: null,
    lastName: null,
    photoUrl: null,
    isCompanyAdmin: null,
    size: null,

    colors: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'],

    didInsertElement: function didInsertElement() {
      var _this = this;

      // NOTE: `simulatedError` is added for testing purposes in order to avoid timing issues and
      // having to implement a wait for img network requests.
      this.$('.js-avatar-image').one('error simulatedError', function () {
        return _ember['default'].run(function () {
          if (!_this.get('isDestroyed')) {
            _this.set('photoUrl', null);
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.js-avatar-image').off('error simulatedError');
    },

    sizeClass: _ember['default'].computed('size', function () {
      return AVATAR_SIZES[this.get('size')];
    }),

    hash: function hash(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i += 1) {
        hash += str.charCodeAt(i);
      }
      return hash;
    },

    initials: _ember['default'].computed('firstName', 'lastName', function () {
      var initials = (this.get('firstName') || ' ')[0] + (this.get('lastName') || ' ')[0];

      return initials.toUpperCase();
    }),

    fullName: _ember['default'].computed('firstName', 'lastName', function () {
      var firstName = this.get('firstName') || '';
      var lastName = this.get('lastName') || '';
      return firstName + ' ' + lastName;
    }),

    altTextPicture: _ember['default'].computed('fullName', function () {
      return this.get('fullName') + '\'s picture';
    }),

    altTextInitials: _ember['default'].computed('fullName', function () {
      return this.get('fullName');
    }),

    color: _ember['default'].computed('initials', function () {
      var color = this.colors[this.hash(this.get('initials')) % this.colors.length];
      return 'avatar--' + color;
    })
  });
});