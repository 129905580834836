define('z-table/components/z-table-row', ['exports', 'ember', 'z-table/templates/components/z-table-row'], function (exports, _ember, _zTableTemplatesComponentsZTableRow) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    layout: _zTableTemplatesComponentsZTableRow['default'],
    tagName: 'tr',
    attributeBindings: ['dataTest:data-test'],
    dataTest: 'z-table-row',
    classNameBindings: ['isSelected:z-tableRow--selected'],
    selectedItem: null,
    item: null,
    isSelected: computed('item', 'selectedItem', function () {
      return this.get('item') === this.get('selectedItem');
    })
  });
});