define('component-library/components/payroll/forms-table', ['exports', 'ember', 'component-library/modals/payroll/sign-form', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryModalsPayrollSignForm, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		acceptedFileTypes: ['image', 'pdf'],
		completedForms: _ember['default'].computed.filterBy('forms', 'isComplete', true),
		incompletedForms: _ember['default'].computed.filterBy('forms', 'isComplete', false),
		unsignedFormsNotaryRequired: _ember['default'].computed.filterBy('incompletedForms', 'needsNotary', true),
		unsignedFormsNotaryNotRequired: _ember['default'].computed.filterBy('incompletedForms', 'needsNotary', false),
		showPendingForms: true,
		showSignedForms: true,
		showNotarizedForms: true,
		showNotarizedFormsUpload: true,

		actions: {
			signForm: function signForm(form) {
				_componentLibraryModalsPayrollSignForm['default'].open({ form: form });
			},
			onFileUploadSuccess: function onFileUploadSuccess(form, file, response) {
				var _this = this;

				var fileName = response.file.name;
				form.setProperties({
					notarizedFormUrl: response.url,
					isNotarized: true,
					notarizedDate: (0, _componentLibraryUtilsGlobals.moment)().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT)
				});
				form.save().then(function () {
					_this.get('forms').reload();
				})['catch'](function (error) {
					_this.eventLogger.log('payroll_company_document_uploadNotarizedForm_error', {
						formId: form.get('id'),
						zpCompanyId: form.get('zpCompany_id'),
						error: error
					});
				});
			},

			onFileUploadError: function onFileUploadError(form, file, errorMessage) {
				var company = this.get('company');
				var zpCompany = this.get('zpCompany');
				this.set('fileUploadErrorText', 'Hm, we’ve encountered an issue that requires additional help. Contact support and we\'ll help you resolve this issue.');
				this.eventLogger.log('payroll_company_document_uploadNotarizedForm_error', {
					formId: form.get('id'),
					zpCompanyId: form.get('zpCompany_id'),
					error: errorMessage
				});
			}
		}
	});
});