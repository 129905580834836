define('component-library/modals/payroll/employees-pay-schedule-modal', ['exports', 'component-library/components/promise-modal', 'component-library/data-table/column'], function (exports, _componentLibraryComponentsPromiseModal, _componentLibraryDataTableColumn) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'modals/payroll/employees-pay-schedule-modal',
		modalDialogClass: 'js-glue-eePayScheduleModal',
		autoSetHeight: false,

		paySchedule: null,
		employees: null,

		assignedEmployees: (function () {
			var _this = this;

			// Return actual assignment for Active Pay Schedules
			var paySchedule = this.get('paySchedule');
			if (paySchedule.get('isActive')) {
				return this.get('employees').filterBy('paySchedule', paySchedule);
			} else if (paySchedule.get('isPending')) {
				// Return expected assignment for Pending Pay Schedules
				var payScheduleCompType = this.get('paySchedule.compType');
				if (payScheduleCompType == 'B') {
					return this.get('employees');
				} else {
					var _ret = (function () {
						var isCPSSalaried = payScheduleCompType == 'S';

						return {
							v: _this.get('employees').filter(function (ee) {
								var isFlsaExempt = ee.get('currentEmployment.isFlsaExempt');
								var shouldBeSalaried = isFlsaExempt || isFlsaExempt === null;
								return shouldBeSalaried == isCPSSalaried;
							})
						};
					})();

					if (typeof _ret === 'object') return _ret.v;
				}
			} else {
				return [];
			}
		}).property('employees', 'employees@each.compType', 'paySchedule'),

		columns: (function () {
			return [_componentLibraryDataTableColumn['default'].create({
				savedWidth: 200,
				headerCellName: 'Name',
				contentPath: 'fullName'
			}), _componentLibraryDataTableColumn['default'].create({
				savedWidth: 170,
				headerCellName: 'Compensation Type',
				contentPath: 'compTypeDetail'
			}), _componentLibraryDataTableColumn['default'].create({
				savedWidth: 170,
				headerCellName: 'Department',
				contentPath: 'department.name'
			})];
		}).property(),

		actions: {
			closeModal: function closeModal() {
				this.send('confirm');
			}
		}
	});
});