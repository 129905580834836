define('component-library/components/date-picker-popover', ['exports', 'component-library/templates/components/date-picker-popover', 'component-library/components/promise-popover'], function (exports, _componentLibraryTemplatesComponentsDatePickerPopover, _componentLibraryComponentsPromisePopover) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
		template: _componentLibraryTemplatesComponentsDatePickerPopover['default'],
		placement: 'bottom',
		attachment: 'top left',
		targetAttachment: 'bottom left',
		actions: {
			dateSelected: function dateSelected(result) {
				if (result) {
					this.send('close', result);
				}
			}
		}
	});
});