define('component-library/wizard/flow/section/index/route', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Route.extend({
		wizardService: null,
		serviceEnabled: false,

		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);

			if (this.get('serviceEnabled')) {
				this.get('wizardService').onSectionIndexEnter(this.get('wizardService.wizardFlowState.currentSection.sectionKey'));
				return;
			} else {
				this.send('_transitionToFirstIncompleteStep_stepAction');
			}
		}
	});
});