define('component-library/mixins/census/sheets-route-mixin', ['exports', 'ember', 'component-library/utils/z-spreadsheet/configuration', 'component-library/constants/census-constants'], function (exports, _ember, _componentLibraryUtilsZSpreadsheetConfiguration, _componentLibraryConstantsCensusConstants) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		// Functions to define in the routes:
		// getCompanyId: function(),
		// getWorkLocations: function(),

		censusService: _ember['default'].inject.service('census'),
		companyId: null,
		useWorkLocations: false,

		model: function model(params, transition) {
			var _this = this;

			var flowName = transition.queryParams.flow;
			var companyIdPromise = this.getCompanyId(params, transition);

			return companyIdPromise.then(function (companyId) {
				_this.set('companyId', companyId);
				return _ember['default'].RSVP.hash({
					companyId: companyId,
					censusState: _this._getCensusState(companyId, flowName),
					workLocations: _this._getWorkLocations(companyId)
				});
			});
		},

		_getCensusState: function _getCensusState(companyId, flowName) {
			var flowConfig = (0, _componentLibraryUtilsZSpreadsheetConfiguration.getCensusConfig)(flowName);

			this._flowConfigObject = flowConfig;

			return this.get('censusService').getOrCreate(flowName, companyId);
		},

		_getWorkLocations: function _getWorkLocations(companyId) {
			if (this.get('useWorkLocations')) {
				return this.getWorkLocations(companyId);
			}
			return _ember['default'].RSVP.resolve({});
		},

		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);

			controller.set('flowConfigOptions', this._flowConfigObject);
			delete this._flowConfigObject;

			var censusState = this.get('censusService.censusState');
			var status = censusState.status;

			controller._reset();

			if (status === _componentLibraryConstantsCensusConstants.CENSUS_STATUSES.PROCESSING) {
				controller._showProcessingModal();
				controller._pollCensusSessionStatus();
			} else if (status === _componentLibraryConstantsCensusConstants.CENSUS_STATUSES.EXCEPTION) {
				controller._showCensusPromoteExceptionModal();
			} else if (!_ember['default'].testing && status === _componentLibraryConstantsCensusConstants.CENSUS_STATUSES.ACTIVE) {
				controller._showAddNewEmployeesModal();
			}
		},

		actions: {
			willTransition: function willTransition(transition) {
				if (this.controller._closeProcessingModal) {
					this.controller._closeProcessingModal();
				}

				if (this.controller._closeAddNewEmployeesModal) {
					this.controller._closeAddNewEmployeesModal();
				}
			}
		}
	});
});