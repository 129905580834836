define('component-library/components/filter-list-panel', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		filters: _ember['default'].computed.alias('content'),
		isFilterPanelOpen: false,

		actions: {
			toggleIsFilterPanelOpen: function toggleIsFilterPanelOpen() {
				this.toggleProperty('isFilterPanelOpen');
			}
		}

	});
});