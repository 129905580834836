define('component-library/timeline/components/hire-date-change', ['exports', 'component-library/timeline/components/copy', 'component-library/timeline/mixins/change-feed-entry', 'component-library/utils/computed-props/not-blank', 'component-library/utils/computed-props/pretty-date'], function (exports, _componentLibraryTimelineComponentsCopy, _componentLibraryTimelineMixinsChangeFeedEntry, _componentLibraryUtilsComputedPropsNotBlank, _componentLibraryUtilsComputedPropsPrettyDate) {
	'use strict';

	exports['default'] = _componentLibraryTimelineComponentsCopy['default'].extend(_componentLibraryTimelineMixinsChangeFeedEntry['default'], {

		label: 'hire date',
		oldVal: (0, _componentLibraryUtilsComputedPropsPrettyDate['default'])('data.old_hire_date'),
		newVal: (0, _componentLibraryUtilsComputedPropsPrettyDate['default'])('data.new_hire_date'),
		oldValAvailable: (0, _componentLibraryUtilsComputedPropsNotBlank['default'])('data.old_hire_date'),
		newValAvailable: (0, _componentLibraryUtilsComputedPropsNotBlank['default'])('data.new_hire_date')

	});
});