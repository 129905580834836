define('component-library/components/d-inline-select', ['exports', 'ember', 'component-library/components/z-inline-base', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryComponentsZInlineBase, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _componentLibraryComponentsZInlineBase['default'].extend({
		init: function init() {
			this._super();
			_ember['default'].deprecate('this inline component is deprecated', false, { id: 'inline-component-deprecation' });
		},

		content: null,
		useStateContent: false,
		hasFullWidth: null,
		hideLabel: false,

		// INTERNAL PARAMETERS
		classNames: ['js-glue-zInlineSelect'],
		isInlineSelect: true,
		focusClassName: null,
		propertyNameObjects: [{ internalProperty: 'value', modelPropertyName: 'propertyName' }],
		// value set on init hook according to propertyNameObjects
		value: null,
		verboseValue: _ember['default'].computed('value', 'content', function () {
			var value = this.get('value');
			var content = this.get('content');
			if (this.get('useStateContent')) {
				return value ? _componentLibraryUtilsGlobals.STATE_ABBREV_TO_NAME[value] : '';
			} else {
				var contentObject = content.findBy('id', value);
				return contentObject ? contentObject.option : '';
			}
		})
	});
});