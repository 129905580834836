define('ui-infra/instance-initializers/logger-initializer', ['exports', 'ember', 'zen', 'ui-infra/utils/get-config', 'ui-infra/utils/ember-compatibility-utils'], function (exports, _ember, _zen, _uiInfraUtilsGetConfig, _uiInfraUtilsEmberCompatibilityUtils) {
  'use strict';

  exports.initialize = initialize;

  function globalInjectionsForLegacyApps(appInstance, loggerInstance, modulePrefix) {
    // Globalize the logger singleton
    var isNode = new Function("try {return this===global;}catch(e){return false;}")();
    var isWq = modulePrefix === 'wq-app';

    _zen['default'].eventLogger = loggerInstance;
    (0, _uiInfraUtilsEmberCompatibilityUtils.inject)(appInstance, 'route', 'eventLogger', 'service:event-logger');
    (0, _uiInfraUtilsEmberCompatibilityUtils.inject)(appInstance, 'controller', 'eventLogger', 'service:event-logger');
    (0, _uiInfraUtilsEmberCompatibilityUtils.inject)(appInstance, 'component', 'eventLogger', 'service:event-logger');
    // Hack to get our analytics service initialized
    // Breaks the Ember Level tests -- don't run under Node
    if (!(isNode || isWq)) {
      // TODO: drop this and get the apps to use their own injections
      (0, _uiInfraUtilsEmberCompatibilityUtils.inject)(appInstance, 'route', 'analytics', 'service:analytics');
    }
  }

  // TODO: Move to a util and avoid getting from meta/
  // example:
  // ```
  // {"httpReferer": "https://secure.zenefits.com/",
  //  "sessionId": "87f1d2b320d7fa02e324c8bfce09d877",
  //  "remoteAddr": "38.88.246.74"}
  // ```
  function getClientMeta() {
    var clientMeta = _ember['default'].$(document).find('[name="client/metadata"]').attr('content');
    return clientMeta ? JSON.parse(decodeURIComponent(clientMeta)) : {};
  }

  function getAppVersionFromMeta(appName) {
    var metaName = appName + '/version';
    var metaElement = document.getElementById(metaName);
    if (metaElement) {
      return metaElement.getAttribute('content');
    }
  }

  var legacyAppPrefixes = ['client-app', 'console-app', 'public-app', 'style-guide', 'implementation-dashboard', 'wq-app'];

  function initialize(appInstance) {
    var config = (0, _uiInfraUtilsGetConfig['default'])(appInstance);
    var environment = config.environment;
    var appName = config.modulePrefix;
    var appInfo = {
      appVersion: config.APP && config.APP.version || getAppVersionFromMeta(appName) || 'missing-version',
      appName: appName
    };

    // Reuse existing logger if exists
    var loggerInstance = undefined;
    if (_zen['default'].eventLogger) {
      loggerInstance = _zen['default'].eventLogger;
      loggerInstance.setAppInfo(appInfo);
      loggerInstance.addClientMeta(getClientMeta());
    } else {
      loggerInstance = _zen['default'].eventLogger || new _zen['default'].uiEventLogger.EventLogger(appInfo, environment, _ember['default'].$.ajax, getClientMeta(), {
        debounce: function debounce(fn, wait) {
          return function () {
            return _ember['default'].run.debounce(null, fn, wait);
          };
        },
        batchDebounceWait: 5000
      });
    }

    (0, _uiInfraUtilsEmberCompatibilityUtils.register)(appInstance, 'service:event-logger', loggerInstance, { instantiate: false });

    // Avoids globals and global injections for new apps
    if (legacyAppPrefixes.indexOf(config.modulePrefix) !== -1) {
      globalInjectionsForLegacyApps(appInstance, loggerInstance, config.modulePrefix);
    }
  }

  exports['default'] = {
    name: 'logger-initializer',
    initialize: initialize
  };
});