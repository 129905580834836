define('component-library/components/date-picker', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	var getDateObject = function getDateObject(datestr) {
		return _ember['default'].isEmpty(datestr) ? null : (0, _componentLibraryUtilsGlobals.moment)(datestr, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
	};

	var _setDayInfoClassNames = function _setDayInfoClassNames(dayInfo) {
		Object.keys(dayInfo).forEach(function (key) {
			if (dayInfo[key] && CLASSNAME_MAPPING[key]) {
				dayInfo.classNames += CLASSNAME_MAPPING[key] + ' ';
			}
		});
	};

	var INDICATOR_TYPES = {
		DOT: 'dot',
		CIRCLE: 'circle',
		DISK: 'disk'
	};

	exports.INDICATOR_TYPES = INDICATOR_TYPES;

	var INDICATOR_COLORS = {
		ORANGE: 'orange',
		BLUE: 'blue',
		PINK: 'pink',
		GREEN: 'green',
		YELLOW: 'yellow'
	};

	exports.INDICATOR_COLORS = INDICATOR_COLORS;

	var CLASSNAME_MAPPING = {
		isBeforeMinDate: 'z-datePicker-day--before-min-date z-datePicker-day--disallowed',
		isAfterMaxDate: 'z-datePicker-day--after-max-date z-datePicker-day--disallowed',
		isExcluded: 'z-datePicker-day--in-disabled-range',
		isCurrentMonth: 'z-datePicker-day--current-month',
		isCurrentDay: 'z-datePicker-day--current-day',
		isSelected: 'z-datePicker-day--selected',
		isSelectionBegin: 'z-datePicker-day--selection-begin',
		isSelectionEnd: 'z-datePicker-day--selection-end',
		isInPrimaryRange: 'z-datePicker-day--in-primary-range',
		isInPrimaryRangeBegin: 'z-datePicker-day--primary-range-begin',
		isInPrimaryRangeEnd: 'z-datePicker-day--primary-range-end',
		isInSecondaryRange: 'z-datePicker-day--in-secondary-range',
		isInSecondaryRangeBegin: 'z-datePicker-day--secondary-range-begin',
		isInSecondaryRangeEnd: 'z-datePicker-day--secondary-range-end',
		isInDateRanges: 'z-datePicker-day--in-primary-range',
		isInDateRangesBegin: 'z-datePicker-day--primary-range-begin',
		isInDateRangesEnd: 'z-datePicker-day--primary-range-end',
		isInSecondaryDateRanges: 'z-datePicker-day--in-secondary-range',
		isInSecondaryDateRangesBegin: 'z-datePicker-day--secondary-range-begin',
		isInSecondaryDateRangesEnd: 'z-datePicker-day--secondary-range-end'
	};

	// creates the indicator classes mapping
	Object.keys(INDICATOR_TYPES).forEach(function (type) {
		type = INDICATOR_TYPES[type];
		var capitalizedType = type.capitalize();
		Object.keys(INDICATOR_COLORS).forEach(function (color) {
			color = INDICATOR_COLORS[color];
			var capitalizedColor = color.capitalize();
			CLASSNAME_MAPPING['has' + capitalizedColor + capitalizedType] = 'z-datePicker-day-' + type + ' z-datePicker-day-' + type + '--' + color;
		});
	});

	var WEEK_END = {
		SUN: 0,
		SAT: 6
	};

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-datePicker', 'js-datePicker'],
		classNameBindings: ['hoverableDates:z-datePicker--hoverable', 'isSmall:z-datePicker--sm', 'isResponsive:z-datePicker--responsive'],
		selectionBegin: null,
		selectionEnd: null,
		primaryRangeBegin: null,
		primaryRangeEnd: null,
		secondaryRangeBegin: null,
		secondaryRangeEnd: null,
		// assumes an array of date ranges with a startDate and endDate property
		// assumes that the startDate and endDate are in MM/DD/YYYY
		dateRanges: [],
		minDate: null,
		maxDate: null,
		excludeWeekend: false,

		_isExcluded: function _isExcluded(date) {
			if (this.get('excludeWeekend')) {
				var day = date.days();
				return day == WEEK_END.SAT || day == WEEK_END.SUN;
			}
			return false;
		},

		isStatic: false,
		isResponsive: false,
		hoverableDates: true,
		isSmall: false,

		indicators: [],
		showIndicators: false,

		_hasIndicator: function _hasIndicator(date, indicator) {
			var dates = indicator.dates;
			// if not defined
			if (!dates) {
				return false;
				// if a string
			} else if (typeof dates == 'string') {
					return date.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT) == dates;
					// if array of date
				} else if (_ember['default'].isArray(dates)) {
						var formattedDate = date.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
						return ~dates.indexOf(formattedDate);
						// if function
					} else if (typeof dates == 'function') {
							return dates(date);
						}
		},

		// check if date is in range then return color
		_addIndicatorClass: function _addIndicatorClass(date) {
			var _this = this;

			var indicators = this.get('indicators');
			var classNames = [];
			indicators.forEach(function (indicator) {
				var hasIndicator = _this._hasIndicator(date, indicator);
				if (hasIndicator) {
					var type = indicator['type'].capitalize();
					var color = indicator['color'].capitalize();
					classNames['has' + color + type] = true;
				}
			});

			return classNames;
		},

		currentDate: (function (key, value) {
			if (arguments.length === 1) {
				return (0, _componentLibraryUtilsGlobals.moment)().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
			}
			return value;
		}).property(),

		// NOTE: do not depend on selectedDate because we only want displayDate
		// to be initialized base on selectedDate and currentDate but do not
		// want it to be updated
		displayDate: (function (key, value) {
			if (arguments.length > 1 && value && (value.length == _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT.length || value._isAMomentObject)) {
				return value;
			}
			var selectedDate = this.get('selectedDate');
			var isValid = this.get('selectedDate.length') == _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT.length;

			var TODAY = (0, _componentLibraryUtilsGlobals.moment)();
			var currentDate = getDateObject(this.get('currentDate'));
			var minDateObject = getDateObject(this.get('minDate'));
			var maxDateObject = getDateObject(this.get('maxDate'));

			var minDisplayDate = minDateObject ? _componentLibraryUtilsGlobals.moment.max(TODAY, minDateObject.add(1, 'days')) : TODAY;
			var maxDisplayDate = maxDateObject ? _componentLibraryUtilsGlobals.moment.min(TODAY, maxDateObject.add(-1, 'days')) : TODAY;

			currentDate = TODAY.isBefore(minDisplayDate) ? _componentLibraryUtilsGlobals.moment.max(maxDisplayDate, minDisplayDate) : _componentLibraryUtilsGlobals.moment.min(maxDisplayDate, minDisplayDate);

			var displayDate = isValid ? getDateObject(selectedDate) : getDateObject(currentDate);
			return displayDate;
		}).property(),

		//There is a bug in Ember 1.13.10 that prevents CPs on components from binding values back up through attrs.
		//This used to be a Computed Property for no other reason than to induce the side-effect
		//of setting two other properties on the component. Since this is much more suited to an observer I have replaced it.
		selectedDateObserver: _ember['default'].on('init', _ember['default'].observer('selectedDate', function () {
			var value = this.get('selectedDate');
			this.setProperties({
				selectionBegin: value,
				selectionEnd: value
			});
		})),

		firstCalendarDate: (function () {
			var displayDate = getDateObject(this.get('displayDate'));
			var firstDayOfMonth = displayDate.startOf('month');
			return firstDayOfMonth.startOf('week').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		}).property('displayDate'),

		lastCalendarDate: (function () {
			var displayDate = getDateObject(this.get('displayDate'));
			var lastDayOfMonth = displayDate.endOf('month');
			return lastDayOfMonth.endOf('week').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		}).property('displayDate'),

		momentDateRanges: (function () {
			return this.get('dateRanges').map(function (range) {
				return {
					startDate: (0, _componentLibraryUtilsGlobals.moment)(_ember['default'].get(range, 'startDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
					endDate: (0, _componentLibraryUtilsGlobals.moment)(_ember['default'].get(range, 'endDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT)
				};
			});
		}).property('dateRanges.@each.startDate', 'dateRanges.@each.endDate'),

		isInDateRange: function isInDateRange(start, end, date) {
			return start && end && date.isAfter(start.clone().subtract(1, 'day')) && date.isBefore(end.clone().add(1, 'day'));
		},

		isInDateRanges: function isInDateRanges(date) {
			var ranges = this.get('momentDateRanges');
			for (var i = 0; i < ranges.length; ++i) {
				var range = ranges.objectAt(i);
				var startDate = _ember['default'].get(range, 'startDate');
				var endDate = _ember['default'].get(range, 'endDate');
				var matchStartDate = startDate.isSame(date, 'day');
				var matchEndDate = endDate.isSame(date, 'day');
				if (this.isInDateRange(startDate, endDate, date)) {
					return {
						matchStartDate: matchStartDate,
						matchEndDate: matchEndDate,
						isInDateRange: true,
						isPrimary: i % 2 === 0
					};
				}
			}
			return {};
		},

		daysOfWeek: (function () {
			var currentDate = getDateObject(this.get('currentDate'));
			var firstCalendarDate = getDateObject(this.get('firstCalendarDate'));
			var lastCalendarDate = getDateObject(this.get('lastCalendarDate'));
			var isCurrentDayInCalendar = this.isInDateRange(firstCalendarDate, lastCalendarDate, currentDate);
			return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(function (day, index) {
				return { day: day, isCurrentDay: isCurrentDayInCalendar && currentDate.day() === index };
			});
		}).property('currentDate', 'firstCalendarDate', 'lastCalendarDate'),

		weeks: (function () {
			var displayDate = getDateObject(this.get('displayDate'));
			var calendarCurrentDate = getDateObject(this.get('currentDate'));
			var selectionBegin = getDateObject(this.get('selectionBegin'));
			var selectionEnd = getDateObject(this.get('selectionEnd'));
			var primaryRangeBegin = getDateObject(this.get('primaryRangeBegin'));
			var primaryRangeEnd = getDateObject(this.get('primaryRangeEnd'));
			var secondaryRangeBegin = getDateObject(this.get('secondaryRangeBegin'));
			var secondaryRangeEnd = getDateObject(this.get('secondaryRangeEnd'));
			var firstCalendarDate = getDateObject(this.get('firstCalendarDate'));
			var lastCalendarDate = getDateObject(this.get('lastCalendarDate'));
			var minDateObject = getDateObject(this.get('minDate'));
			var maxDateObject = getDateObject(this.get('maxDate'));
			var week = [];
			var weeks = [week];
			for (var currentDate = firstCalendarDate; currentDate <= lastCalendarDate; currentDate = currentDate.add(1, 'days')) {
				if (week.length === 7) {
					week = [];
					weeks.pushObject(week);
				}
				var dateRangeResult = this.isInDateRanges(currentDate, false, false);
				var dayInfo = {
					date: currentDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
					day: currentDate.date(),
					isBeforeMinDate: currentDate.isBefore(minDateObject),
					isAfterMaxDate: currentDate.isAfter(maxDateObject),
					isExcluded: this._isExcluded(currentDate),
					isCurrentDay: currentDate.isSame(calendarCurrentDate),
					isCurrentMonth: currentDate.month() === displayDate.month(),
					isSelected: this.isInDateRange(selectionBegin, selectionEnd, currentDate),
					isSelectionBegin: selectionBegin && currentDate.isSame(selectionBegin),
					isSelectionEnd: selectionEnd && currentDate.isSame(selectionEnd),
					isInPrimaryRange: this.isInDateRange(primaryRangeBegin, primaryRangeEnd, currentDate),
					isInPrimaryRangeBegin: primaryRangeBegin && currentDate.isSame(primaryRangeBegin),
					isInPrimaryRangeEnd: primaryRangeEnd && currentDate.isSame(primaryRangeEnd),
					isInSecondaryRange: this.isInDateRange(secondaryRangeBegin, secondaryRangeEnd, currentDate),
					isInSecondaryRangeBegin: secondaryRangeBegin && currentDate.isSame(secondaryRangeBegin),
					isInSecondaryRangeEnd: secondaryRangeEnd && currentDate.isSame(secondaryRangeEnd),
					isInDateRanges: dateRangeResult.isPrimary && dateRangeResult.isInDateRange,
					isInDateRangesBegin: dateRangeResult.isPrimary && dateRangeResult.matchStartDate,
					isInDateRangesEnd: dateRangeResult.isPrimary && dateRangeResult.matchEndDate,
					isInSecondaryDateRanges: !dateRangeResult.isPrimary && dateRangeResult.isInDateRange,
					isInSecondaryDateRangesBegin: !dateRangeResult.isPrimary && dateRangeResult.matchStartDate,
					isInSecondaryDateRangesEnd: !dateRangeResult.isPrimary && dateRangeResult.matchEndDate
				};

				if (this.get('showIndicators') && !_ember['default'].isEmpty(this.get('indicators'))) {
					var indicators = this._addIndicatorClass(currentDate);
					Object.assign(dayInfo, indicators);
				}

				dayInfo.classNames = '';
				_setDayInfoClassNames(dayInfo);
				week.pushObject(dayInfo);
			}
			return weeks;
		}).property('currentDate', 'displayDate', 'selectionBegin', 'selectionEnd', 'primaryRangeBegin', 'primaryRangeEnd', 'secondaryRangeBegin', 'secondaryRangeEnd', 'firstCalendarDate', 'lastCalendarDate', 'minDate', 'maxDate', 'showIndicators', 'indicators.length'),

		actions: {
			selectDate: function selectDate(day) {
				var newDate = day.date;
				var minDateObject = getDateObject(this.get('minDate'));
				var maxDateObject = getDateObject(this.get('maxDate'));
				var newDateObject = getDateObject(newDate);
				var notStatic = !this.get('isStatic');
				var isHoverable = this.get('hoverableDates');
				var notExcluded = !this._isExcluded(newDateObject);
				// does not allow selecting date before or after the minDate/maxDate if defined
				var isNewDateValid = (!minDateObject || !newDateObject.isBefore(minDateObject)) && (!maxDateObject || !newDateObject.isAfter(maxDateObject));
				if (isNewDateValid && notStatic && isHoverable && notExcluded) {
					this.set('selectedDate', newDate);
					this.sendAction('dateSelected', newDate);
				}
			},

			incrementMonth: function incrementMonth() {
				var date = getDateObject(this.get('displayDate')).add(1, 'month');
				this.set('displayDate', date);
			},

			decrementMonth: function decrementMonth() {
				var date = getDateObject(this.get('displayDate')).subtract(1, 'month');
				this.set('displayDate', date);
			}
		}

	});
});