define('component-library/mixins/zfb/invoices/route', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _ember['default'].Route.extend({
		isConsolePage: false,
		model: function model(params) {
			var extraFilter = {};
			var companyId = null;
			if (this.get('isConsolePage')) {
				companyId = this.modelFor('v2.company').get('id');
				extraFilter = {
					'company_id': companyId
				};
			}
			var startSegment = (0, _componentLibraryUtilsGlobals.moment)({
				year: params.year,
				month: params.month
			}).startOf('week');
			var endSegment = (0, _componentLibraryUtilsGlobals.moment)({
				year: params.year,
				month: params.month
			}).endOf('month').startOf('week').add(1, 'week');
			var promises = {
				transactions: this.store.find('zfbTransaction', _ember['default'].$.extend({
					'invoiceDate__gte': startSegment.format('YYYY-MM-DD'),
					'invoiceDate__lt': endSegment.format('YYYY-MM-DD'),
					'transactionCode__in': '10, 12, 14'
				}, extraFilter)),
				startSegment: startSegment,
				endSegment: endSegment,
				companyId: companyId
			};
			if (!this.controller || !this.controller.get('months')) {
				promises.months = this.store.find('zfbTransactionsMonth', extraFilter);
			}
			return _ember['default'].RSVP.hash(promises);
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			if (!this.controller.get('months')) {
				controller.set('months', model.months);
			}
			controller.onSetup();
		},
		actions: {
			queryParamsDidChange: function queryParamsDidChange() {
				_ember['default'].run.next(this, 'refresh');
			}
		}
	});
});