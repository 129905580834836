define('component-library/utils/z-spreadsheet/validators', ['exports', 'ember', 'component-library/utils/globals', 'component-library/constants/z-spreadsheet-employee', 'component-library/constants/payroll'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryConstantsZSpreadsheetEmployee, _componentLibraryConstantsPayroll) {
	'use strict';

	// PLEASE NOTE: all validation messages are being tested directly
	// if you change copy or failure cases it will break tests
	// make sure to also change copy here:
	// client-app/tests/unit/pods/census/z-spreadsheet/validators-test.js

	var FIELD_VALIDATION_REGEX = {
		'address': /^(\w+)\s?(-)?\s?(\d+)?\s[\s0-9a-zA-Z]{2,30}\s[a-zA-Z]{2,15}[,.]?\s?([a-zA-Z]+)?/,
		'email': /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		'ssn': /^((?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4})$|^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/,
		'zip': /^\d{5}(-\d{4})?$/
	};

	exports.FIELD_VALIDATION_REGEX = FIELD_VALIDATION_REGEX;

	function fieldTypeValidator(fieldTypeMapping, value) {
		return fieldTypeMapping.some(function (fieldType) {
			return fieldType.label === value;
		});
	}

	var firstNameValidator = function firstNameValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'First name is a required field.';
		}
		if (value.length > 50) {
			return 'First name is too long.';
		}
		return null;
	};

	exports.firstNameValidator = firstNameValidator;

	var lastNameValidator = function lastNameValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'Last name is a required field.';
		}
		if (value.length > 50) {
			return 'Last name is too long.';
		}
		return null;
	};

	exports.lastNameValidator = lastNameValidator;

	var hireDateValidator = function hireDateValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'Hire date is a required field.';
		}
		var momentHireDate = (0, _componentLibraryUtilsGlobals.moment)(value, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		if (!momentHireDate.isValid()) {
			return 'Please use MM/DD/YYYY format.';
		}
		if ((0, _componentLibraryUtilsGlobals.moment)().subtract(100, 'years') >= momentHireDate) {
			return 'Hire date seems too far in the past.';
		}
		if ((0, _componentLibraryUtilsGlobals.moment)().add(10, 'years') <= momentHireDate) {
			return 'Hire date is too far in the future.';
		}
		return null;
	};

	exports.hireDateValidator = hireDateValidator;

	var terminationDateValidator = function terminationDateValidator(value, callback) {
		var momentTermDate = (0, _componentLibraryUtilsGlobals.moment)(value, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		if (!momentTermDate.isValid()) {
			return 'Please use MM/DD/YYYY format.';
		}
		if ((0, _componentLibraryUtilsGlobals.moment)().subtract(30, 'years') >= momentTermDate) {
			return 'Termination date seems too far in the past.';
		}
		if ((0, _componentLibraryUtilsGlobals.moment)().add(6, 'months') <= momentTermDate) {
			return 'Termination date is too far in the future.';
		}
		return null;
	};

	exports.terminationDateValidator = terminationDateValidator;

	var dateOfBirthValidator = function dateOfBirthValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'Birthdate is a required field.';
		}
		var momentDateOfBirth = (0, _componentLibraryUtilsGlobals.moment)(value, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		if (!momentDateOfBirth.isValid()) {
			return 'Please use MM/DD/YYYY format.';
		}
		if ((0, _componentLibraryUtilsGlobals.moment)().subtract(100, 'years') >= momentDateOfBirth) {
			return 'Birthdate seems too far in the past.';
		}
		if ((0, _componentLibraryUtilsGlobals.moment)() <= momentDateOfBirth) {
			return 'Birthdate is too far in the future.';
		}
		return null;
	};

	exports.dateOfBirthValidator = dateOfBirthValidator;

	var employmentTypeValidator = function employmentTypeValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'Employment type is a required field.';
		}
		return fieldTypeValidator(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYMENT_TYPE_MAPPINGS, value) ? null : 'Please select a valid employment type.';
	};

	exports.employmentTypeValidator = employmentTypeValidator;

	var compensationRangeValidator = function compensationRangeValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'Compensation is a required field.';
		}
		return _componentLibraryConstantsZSpreadsheetEmployee.MAXIMUM_SALARY >= value && value >= 1 || value === 'N/A' ? null : 'Please enter a valid compensation.';
	};

	exports.compensationRangeValidator = compensationRangeValidator;

	var compensationTypeValidator = function compensationTypeValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'Compensation type is a required field.';
		}
		return fieldTypeValidator(_componentLibraryConstantsZSpreadsheetEmployee.COMPENSATION_TYPE_MAPPINGS, value) ? null : 'Please select a valid compensation type.';
	};

	exports.compensationTypeValidator = compensationTypeValidator;

	var genderValidator = function genderValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'Gender is a required field.';
		}
		return fieldTypeValidator(_componentLibraryConstantsZSpreadsheetEmployee.GENDER_MAPPINGS, value) ? null : 'For insurance classification purposes, please select between the two genders.';
	};

	exports.genderValidator = genderValidator;

	var booleanValidator = function booleanValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'This is a required field.';
		}
		return fieldTypeValidator(_componentLibraryConstantsZSpreadsheetEmployee.BOOLEAN_MAPPINGS, value) ? null : 'Please select between the two options.';
	};

	exports.booleanValidator = booleanValidator;

	var eeoRaceValidator = function eeoRaceValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'This is a required field.';
		}
		return fieldTypeValidator(_componentLibraryConstantsZSpreadsheetEmployee.EEO_RACE_MAPPINGS, value) ? null : 'Please select from the given options.';
	};

	exports.eeoRaceValidator = eeoRaceValidator;

	var numberValidator = function numberValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'This is a required field.';
		}
		if (!(_ember['default'].typeOf(value) === 'number')) {
			return 'Please input a number';
		}
		return null;
	};

	exports.numberValidator = numberValidator;

	var eeoJobCategoryValidator = function eeoJobCategoryValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'This is a required field.';
		}
		return fieldTypeValidator(_componentLibraryConstantsZSpreadsheetEmployee.EEO_JOB_CATEGORY_MAPPINGS, value) ? null : 'Please select from the given options.';
	};

	exports.eeoJobCategoryValidator = eeoJobCategoryValidator;

	var eeoJobCategoryComponent2Validator = function eeoJobCategoryComponent2Validator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'This is a required field.';
		}
		return fieldTypeValidator(_componentLibraryConstantsZSpreadsheetEmployee.EEO_JOB_CATEGORY_COMPONENT2_MAPPINGS_FROM_OLD_KEY_TO_NEW_LABEL, value) ? null : 'Please select from the given options.';
	};
	exports.eeoJobCategoryComponent2Validator = eeoJobCategoryComponent2Validator;

	var employeeStatusValidator = function employeeStatusValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'This is a required field.';
		}
		return fieldTypeValidator(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYEE_STATUS_MAPPINGS, value) ? null : 'Please select from the given options.';
	};

	exports.employeeStatusValidator = employeeStatusValidator;

	var employeePaymentMethodValidator = function employeePaymentMethodValidator(value, callback) {
		return fieldTypeValidator(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYEE_PAYMENT_METHOD_MAPPINGS, value) ? null : 'Please select from the given options.';
	};

	exports.employeePaymentMethodValidator = employeePaymentMethodValidator;

	var emailValidator = function emailValidator(value, callback) {
		return FIELD_VALIDATION_REGEX['email'].test(value) ? null : 'Please enter a properly formatted email address.';
	};

	exports.emailValidator = emailValidator;

	var ssnValidator = function ssnValidator(value, callback) {
		return FIELD_VALIDATION_REGEX['ssn'].test(value) ? null : 'Please enter a valid SSN';
	};

	exports.ssnValidator = ssnValidator;

	var addressValidator = function addressValidator(value, callback) {
		return FIELD_VALIDATION_REGEX['address'].test(value) ? null : 'Please enter a valid Address';
	};

	exports.addressValidator = addressValidator;

	var cityValidator = function cityValidator(value, callback) {
		if (_ember['default'].isBlank(value)) {
			return 'City is a required field.';
		}
		if (value.length > 50) {
			return 'City is too long.';
		}
		return null;
	};

	exports.cityValidator = cityValidator;

	var stateValidator = function stateValidator(value, callback) {

		if (_ember['default'].isBlank(value)) {
			return 'State is a required field.';
		}

		if (value.length > 2) {
			return 'State is too long.';
		}

		if (!_componentLibraryConstantsPayroll.STATES_IN_USA.includes(value)) {
			return 'Invalid state code used.';
		}

		return null;
	};

	exports.stateValidator = stateValidator;

	var zipValidator = function zipValidator(value, callback) {
		return FIELD_VALIDATION_REGEX['zip'].test(value) ? null : 'Please enter a valid zip';
	};
	exports.zipValidator = zipValidator;
});