define('z-table/components/z-table-details', ['exports', 'ember', 'z-table/templates/components/z-table-details'], function (exports, _ember, _zTableTemplatesComponentsZTableDetails) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		layout: _zTableTemplatesComponentsZTableDetails['default'],
		classNames: ['z-table-details'],
		attributeBindings: ['dataTest:data-test'],
		dataTest: 'z-table-details',
		showClose: true, // not used yet
		item: null,
		useHeader: true,
		actions: {
			closeDetails: function closeDetails() {
				this.attrs.onClose();
			}
		}
	});
});