define('component-library/components/z-slider-nonnumeric', ['exports', 'ember', 'component-library/components/z-slider-base', 'component-library/templates/components/z-slider-base'], function (exports, _ember, _componentLibraryComponentsZSliderBase, _componentLibraryTemplatesComponentsZSliderBase) {
	'use strict';

	exports['default'] = _componentLibraryComponentsZSliderBase['default'].extend({
		classNames: 'slider--nonnumeric',
		template: _componentLibraryTemplatesComponentsZSliderBase['default'],
		popoverPartial: 'components/z-slider/popover-nonnumeric',
		choices: [],
		selectedChoiceValue: null,
		step: 1,
		minVal: 0,
		maxVal: (function () {
			return this.get('choices.length') - 1;
		}).property('choices.length'),

		selectedValue: (function (key, val) {
			// setter
			if (arguments.length > 1) {
				this.set('selectedChoiceValue', this.get('choices')[val].value);
			}

			// getter
			var selectedChoiceValue = this.get('selectedChoiceValue');
			var choice = this.get('_choiceMapByValue')[selectedChoiceValue];
			return choice ? choice.index : 0;
		}).property('selectedChoiceValue', 'choices.[]'),

		_popoverDisplayLabel: (function () {
			return this.get('choices')[this.get('_selectedValueToRender')].label;
		}).property('_selectedValueToRender', 'choices.[]'),

		stepTicks: (function () {
			var _this = this;

			return this._super().map(function (stepTick, i) {
				stepTick.label = _this.get('choices')[i].label;
				return stepTick;
			});
		}).property('_hasSteps', 'choices.[]'),

		_choiceMapByValue: (function () {
			return this.get('choices').reduce(function (choiceMap, choice, i) {
				var choiceClone = new _ember['default'].Object(choice);
				choiceClone.set('index', i);
				choiceMap[choice.value] = choiceClone;
				return choiceMap;
			}, {});
		}).property('choices.[]')
	});
});