define('component-library/constants/z-payroll/z-payroll-payrun-types', ['exports'], function (exports) {
				'use strict';

				var PAYRUN_TYPES = {
								// Please keep up-to-date with payroll/zpayroll/zenefits_payroll/enums/common.py
								REGULAR: 'REG',
								REGULAR_HOURLY: 'REG_HOURLY',
								OFF_CYCLE: 'OFF',
								INITIALIZE: 'INIT',
								CORRECTION: 'CORRECTION',
								AMENDMENT: 'AMENDMENT',
								ADJUSTMENT: 'ADJUSTMENT',
								TAX_OTHER: 'TAX_OTHER',
								CORRECTION_RECONCILIATION: 'CORRECTION_RECONCILIATION',
								CORRECTION_REQUEST: 'CORRECTION_REQUEST'
				};
				exports.PAYRUN_TYPES = PAYRUN_TYPES;
});