define("component-library/utils/validation_rules/regexRule", ["exports", "component-library/utils/validation_rules/rule"], function (exports, _componentLibraryUtilsValidation_rulesRule) {
	"use strict";

	exports["default"] = _componentLibraryUtilsValidation_rulesRule["default"].extend({
		errorMsg: "Invalid Input",
		regex: "",
		validate: function validate(value) {
			var regex = new RegExp(this.get('regex'));
			return regex.test(value);
		}
	});
});