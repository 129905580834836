define('component-library/components/popover-link', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		tagName: "a",
		classNameBindings: ['hasPopover:has-popover'],
		popoverClass: null,
		hasPopover: false,
		passTargetObject: false,
		open: null,
		close: null,
		openOnHover: false,
		hoverDelay: 500,
		runLaterHover: null,
		disableClick: false,

		// These cannot be unknown properties or else: https://github.com/emberjs/ember.js/issues/10400
		helperName: null,
		_morph: null,
		renderer: null,

		popoverProps: (function () {
			return _ember['default'].Object.create();
		}).property(),

		mouseEnter: function mouseEnter(event) {
			var _this = this;

			if (this.get('openOnHover')) {
				var hoverDelay = this.get('hoverDelay');
				var runLaterHover = _ember['default'].run.later(function () {
					_this.showPopover();
				}, hoverDelay);
				this.set('runLaterHover', runLaterHover);
			}
		},

		mouseLeave: function mouseLeave(event) {
			// Cancel open on hover if mouse leaves within delay interval
			var runLaterHover = this.get('runLaterHover');
			if (this.get('openOnHover') && runLaterHover) {
				_ember['default'].run.cancel(runLaterHover);
			}
		},

		// This no longer works in Ember 1.13
		// Attrs are no longer set throught setUnkownProperty
		// setUnknownProperty(key, value) {
		// 	var match = /^(.+)Binding$/.exec(key);
		// 	var rawKey = match && match[1];

		// 	if (rawKey && (rawKey in this)) {
		// 		// This is safe because -Binding properties are not to be observed/computed on.
		// 		this[rawKey] = value;
		// 	}
		// 	else {
		// 		Ember.set(this.get('popoverProps'), key, value);
		// 	}
		// },

		updatePopoverProps: function updatePopoverProps() {
			var _this2 = this;

			Object.keys(this.attrs).forEach(function (prop) {
				_this2.set('popoverProps.' + prop, _this2.get(prop));
			});
		},

		didInitAttrs: function didInitAttrs() {
			this.updatePopoverProps();
		},

		didUpdateAttrs: function didUpdateAttrs() {
			this.updatePopoverProps();
		},

		click: function click() {
			if (this.get('disableClick')) {
				return;
			}
			this.showPopover();
		},

		showPopover: function showPopover() {
			var _this3 = this;

			var popoverClass = this.get('popoverClass');
			if (!this.get('hasPopover') && popoverClass) {
				var popoverHash = {
					target: this.$(),
					content: this.get('content'),
					eventLogger: _ember['default'].inject.service('eventLogger')
				};
				if (this.get('passTargetObject')) {
					popoverHash.targetObject = this.get('targetObject');
				}
				if (this.get('childTemplate')) {
					popoverHash.template = this.get('childTemplate');
					// TODO: make sure that we set the right context for yielded content.
					// popoverHash.context = this.get('context');
				}
				var popover = popoverClass.open(_ember['default'].merge(popoverHash, this.get('popoverProps')));
				this.sendAction('open');
				this.set('hasPopover', true);
				popover.promise.then(function (result) {
					if (_this3.get('isDestroyed') || _this3.get('isDestroying')) {
						return;
					}
					_this3.set('hasPopover', false);
					_this3.sendAction('close', result);
				});
			}
		}
	});
});