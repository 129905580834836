define('component-library/components/data-table-filter-panel', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		allColumns: [],
		columns: [],
		allGroupings: [],
		groupings: [],

		classNames: ['js-dataTableFilterPanel'],

		actions: {
			toggleSelection: function toggleSelection(filter, optionSelectionZip) {
				filter.toggleSelection(optionSelectionZip);
			},
			singleSelection: function singleSelection(filter, optionSelectionZip) {
				// THIS ABSOLUTELY NEEDS TO BE REFACTORED IM SO SORRY IT CAUSES
				// FLICKERING IN THE RENDERING [JH]
				var curScrollTop = _ember['default'].$(".js-dataTableFilterPanel").parent()[0].scrollTop;
				filter.singleSelection(optionSelectionZip);
				_ember['default'].run.later(function () {
					_ember['default'].$(".js-dataTableFilterPanel").parent()[0].scrollTop = curScrollTop;
				}, 1);
			},
			selectAll: function selectAll(filter) {
				filter.selectAll();
			},
			toggleShowAll: function toggleShowAll(filter) {
				_ember['default'].$('.' + filter.get('jsClassName') + ' .js-filterBox-remainingOptions').velocity('slide' + (filter.toggleProperty('showAll') ? "Down" : "Up"));
			}
		}
	});
});