define('component-library/initializers/american-date-moment-type', ['exports', 'ember', 'component-library/attribute-types/american-date-moment'], function (exports, _ember, _componentLibraryAttributeTypesAmericanDateMoment) {
  'use strict';

  exports['default'] = {
    name: "american-date-moment-type",
    after: "store",
    initialize: function initialize(container, application) {
      _ember['default'].Model.dataTypes['americanDateMoment'] = _componentLibraryAttributeTypesAmericanDateMoment['default'];
    }
  };
});