define('component-library/utils/body-event-dispatcher', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  // Ember Tether attaches tethered elements to the body, so we need to listen to
  // events on the body even if the rootElement is not the body.
  exports['default'] = _ember['default'].EventDispatcher.extend({
    setupHandler: function setupHandler() {
      var newArgs = Array.prototype.slice.call(arguments);
      newArgs.shift();
      newArgs.unshift(_ember['default'].$('body'));
      this._super.apply(this, newArgs);
    }
  });
});