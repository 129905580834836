define('component-library/instance-initializers/log-code-usage', ['exports', 'ember', 'zen'], function (exports, _ember, _zen) {
	'use strict';

	var _slicedToArray = (function () {
		function sliceIterator(arr, i) {
			var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;_e = err;
			} finally {
				try {
					if (!_n && _i['return']) _i['return']();
				} finally {
					if (_d) throw _e;
				}
			}return _arr;
		}return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError('Invalid attempt to destructure non-iterable instance');
			}
		};
	})();

	exports.initialize = initialize;

	var recentlyLogged = {};
	var unparsableNameMap = {};
	var currentRouteName = null;
	var appName = null;
	// ######### blacklisted patterns for unparsable names
	var BLACKLISTED_COMPONENT_PATTERN = /^<App.(ZenSelectDropdown)|(ZenSelectEmployee)|(OrganizationChartComponent)|(ZenSelectDropdownRow)/;
	var BLACKLISTED_EMBER_COMPONENTS_SUBCLASSES = /^<\(subclass of Ember.(RadioButton)|(TextArea)|(TextField)|(Select)|(Checkbox)/;
	var BLACKLISTED_GENERATED_LOADING_CONTROLLER = /^\(generated (\w+)|(\s)\.loading controller/;
	var BLACKLISTED_MISC_PATTERN = /^<\(subclass of (Ember.Component)|(Ember.View)|(JQ.DatePicker)/;

	var generateInitOverrideObject = function generateInitOverrideObject(parsedType, logger) {
		return {
			init: function init() {
				this._super(this, arguments);
				var fullName = undefined,
				    _ = undefined,
				    parsedTypeName = undefined;
				var info = this.toString();
				var useParserForGlobals = info.indexOf('<Ember.') >= 0;

				if (useParserForGlobals) {
					var _info$match = info.match(/^<Ember.(.*):/);

					var _info$match2 = _slicedToArray(_info$match, 2);

					fullName = _info$match2[0];
					parsedTypeName = _info$match2[1];
				} else if (info.indexOf('<' + appName) >= 0) {
					var _info$match3 = info.match(/^<(.*)@(.*?):(.*?)::/);

					var _info$match32 = _slicedToArray(_info$match3, 4);

					fullName = _info$match32[0];
					_ = _info$match32[1];
					_ = _info$match32[2];
					parsedTypeName = _info$match32[3];
				} else {
					var isInfoBlacklisted = BLACKLISTED_COMPONENT_PATTERN.test(info) || BLACKLISTED_EMBER_COMPONENTS_SUBCLASSES.test(info) || BLACKLISTED_GENERATED_LOADING_CONTROLLER.test(info) || BLACKLISTED_MISC_PATTERN.test(info);
					if (!isInfoBlacklisted) {
						// log names that aren't blacklisted and the above parsers can't parse
						if (!unparsableNameMap[parsedType]) {
							unparsableNameMap[parsedType] = {};
						}
						if (!unparsableNameMap[parsedType][info]) {
							unparsableNameMap[parsedType][info] = true;
							logger.batchLog('unparsable_name', {
								appName: appName,
								unparsableName: info,
								parsedType: parsedType
							});
						}
					}
					return;
				}
				if (!recentlyLogged[parsedType + '-' + parsedTypeName]) {
					recentlyLogged[parsedType + '-' + parsedTypeName] = true;
					logger.batchLog('code_usage_info', {
						appName: appName,
						accessedType: 'non-model-object',
						parsedType: parsedType,
						parsedTypeName: parsedTypeName,
						currentRouteName: currentRouteName
					});
				}
			}
		};
	};

	function initialize(applicationInstance, testOptions /* this argument should be only used for testing */) {
		var logger = _zen['default'].eventLogger || testOptions.zenObj.eventLogger;
		var routingService = applicationInstance.container.lookup('service:routing') || testOptions.routingService;
		var isLogCodeUsageSwitchOn = undefined;
		testOptions = testOptions || {};

		if (typeof testOptions.isSwitchOn == 'undefined') {
			if (_ember['default'].$(document) && _ember['default'].$(document).find('meta[property="z:logCodeUsageInfoNonModelObjs"]').length) {
				isLogCodeUsageSwitchOn = _ember['default'].$(document).find('meta[property="z:logCodeUsageInfoNonModelObjs"]').attr('content') === 'true';
			}
		} else {
			isLogCodeUsageSwitchOn = testOptions.isSwitchOn;
		}

		if (!isLogCodeUsageSwitchOn) {
			return;
		}

		appName = applicationInstance.application.name;
		routingService.on('routerWillTransition', function (transition) {
			currentRouteName = transition.targetName;
			recentlyLogged = {};
		});

		_ember['default'].Component.reopen(generateInitOverrideObject('component', logger));
		_ember['default'].Controller.reopen(generateInitOverrideObject('controller', logger));
		_ember['default'].Route.reopen(generateInitOverrideObject('route', logger));
		_ember['default'].Service.reopen(generateInitOverrideObject('service', logger));
		_ember['default'].View.reopen(generateInitOverrideObject('view', logger));
		_ember['default'].Helper.reopen(generateInitOverrideObject('helper', logger));
	}

	exports['default'] = {
		name: 'log-resolver-info',
		initialize: initialize
	};
});