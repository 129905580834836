define('component-library/components/payroll/smp/sync-diff-table/selector-cell-view', ['exports', 'ember', 'ember-table/views/table-cell', 'component-library/mixins/payroll/smp/sync-diff-data-mixin', 'component-library/templates/components/payroll/smp/sync-diff-table/selector-cell-view'], function (exports, _ember, _emberTableViewsTableCell, _componentLibraryMixinsPayrollSmpSyncDiffDataMixin, _componentLibraryTemplatesComponentsPayrollSmpSyncDiffTableSelectorCellView) {
	'use strict';

	exports['default'] = _emberTableViewsTableCell['default'].extend(_componentLibraryMixinsPayrollSmpSyncDiffDataMixin['default'], {
		template: _componentLibraryTemplatesComponentsPayrollSmpSyncDiffTableSelectorCellView['default'],
		key: _ember['default'].computed.alias('row.key'),
		isFooter: _ember['default'].computed.alias('row.isFooter'),
		selectedCount: _ember['default'].computed.alias('row.selectedCount'),
		contentPath: _ember['default'].computed.alias('column.contentPath'),
		resolvedValue: _ember['default'].computed.alias('column.resolvedValue'),
		handleClick: (function () {
			return this.get('row.isFooter') || !this.get('isBanksField');
		}).property('row.isFooter', 'isBanksField')
	});
});