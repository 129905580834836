define('ember-cli-bundle-loader/resolver', ['exports', 'ember', 'ember-resolver', 'ember-get-config', 'ember-cli-bundle-loader/config/package-names'], function (exports, _ember, _emberResolver, _emberGetConfig, _emberCliBundleLoaderConfigPackageNames) {
  'use strict';

  var lookupFunctions = [],
      genericModuleNameLookupPatterns = [
  // NOTE: the order is important, since they're evaluted as we add them to the array
  // So we need the podBasedModuleName first and defaultModuleName last, just like the ember-resolver does

  function podBasedModuleName(packageName, parsedName) {
    // var podPrefix = this.namespace.podModulePrefix || this.namespace.modulePrefix;
    var podPrefix = packageName;
    if (_emberGetConfig['default'].podSubDirectory) {
      podPrefix = podPrefix + '/' + _emberGetConfig['default'].podSubDirectory;
    }
    return this.podBasedLookupWithPrefix(podPrefix, parsedName);
  }, function podBasedComponentsInSubdir(packageName, parsedName) {
    // var podPrefix = this.namespace.podModulePrefix || this.namespace.modulePrefix;
    var podPrefix = packageName;
    if (_emberGetConfig['default'].podSubDirectory) {
      podPrefix = podPrefix + '/' + _emberGetConfig['default'].podSubDirectory;
    }
    podPrefix = podPrefix + '/components';

    if (parsedName.type === 'component' || parsedName.fullNameWithoutType.match(/^components/)) {
      return this.podBasedLookupWithPrefix(podPrefix, parsedName);
    }
  }, function mainModuleName(packageName, parsedName) {
    // if router:main or adapter:main look for a module with just the type first
    var tmpModuleName = /*parsedName.prefix + '/' +*/packageName + '/' + parsedName.type;

    if (parsedName.fullNameWithoutType === 'main') {
      return tmpModuleName;
    }
  },
  // Extensions the ember-resolver lookuppatterns that take a 'packageName'
  function defaultModuleName(packageName, parsedName) {
    return packageName + '/' + this.pluralize(parsedName.type) + '/' + parsedName.fullNameWithoutType;
  }];

  // The order is again important
  genericModuleNameLookupPatterns.forEach(function (genericLookupPattern) {
    _emberCliBundleLoaderConfigPackageNames['default'].concat(_emberGetConfig['default'].modulePrefix).forEach(function (packageName) {
      lookupFunctions.push(function (parsedName) {
        return genericLookupPattern.call(this, packageName, parsedName);
      });
    });
  });

  exports['default'] = _emberResolver['default'].extend({
    moduleNameLookupPatterns: _ember['default'].computed(function () {
      // Not sure if we need to delegate, this might be enough.
      // return lookupFunctions.concat(this._super());
      return lookupFunctions;
    })
  });
});