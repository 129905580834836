define('component-library/wizard/state/step', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].ObjectProxy.extend({
		// OVERRIDES - begin

		// required
		routeName: null,
		stepKey: null,
		section: null, // WizardSectionState
		title: null,
		valuePath: null,
		value: null,

		// optional
		isComplete: false,
		canSkipWithoutComplete: true,
		shouldShowInReview: true,
		sequenceEnforced: _ember['default'].computed.alias('section.sequenceEnforced'), // prevents skipping past an unfinished step

		isAccessible: (function () {
			// redirects to flow index if reached while inaccessable
			if (!this.get('sequenceEnforced') || _ember['default'].isNone(this.get('previousStep'))) {
				return true;
			}

			return this.get('previousStep.isAccessible') && this.get('previousStep.isComplete');
		}).property('sequenceEnforced', 'previousStep', 'previousStep.isAccessible', 'previousStep.isComplete'),

		// OVERRIDES - end

		index: (function () {
			var section = this.get('section');
			if (section && section.get('steps')) {
				return this.get('section.steps').indexOf(this);
			} else {
				return -1;
			}
		}).property('section', 'section.steps.@each'),

		isFirst: _ember['default'].computed.equal('index', 0),
		isLast: (function () {
			return this.get('index') === this.get('section.steps.length') - 1;
		}).property('index'),

		displayIndex: (function () {
			return this.get('index') + 1;
		}).property('index'),

		previousStep: (function () {
			var index = this.get('index');
			return index > 0 ? this.get('section').getStepAtIndex(index - 1) : null;
		}).property('index', 'sections.steps.@each'),

		nextStep: (function () {
			var index = this.get('index');
			return index < this.get('sections.steps.length') - 1 ? this.get('sections').getStepAtIndex(index + 1) : null;
		}).property('index', 'sections.steps.@each'),

		isActive: (function () {
			var section = this.get('section');
			var index = this.get('index');
			return section.get('currentStepIndex') == index;
		}).property('section', 'index', 'section.currentStepIndex'),

		isActiveStepInActiveSection: _ember['default'].computed.and('isActive', 'section.isActive'),

		updateValue: function updateValue(newValue) {
			this.set('value', newValue);
		},

		setComplete: function setComplete(isComplete) {
			this.set('isComplete', isComplete);
		},

		allowSkip: _ember['default'].computed.or('canSkipWithoutComplete', 'isComplete')
	});
});