define('z-inputs/components/z-text-field-sic', ['exports', 'z-inputs/components/z-text-field-base', 'z-inputs/templates/components/z-text-field-basic'], function (exports, _zInputsComponentsZTextFieldBase, _zInputsTemplatesComponentsZTextFieldBasic) {
		'use strict';

		exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend({
				layout: _zInputsTemplatesComponentsZTextFieldBasic['default'],
				classNames: 'z-textField--sic',
				type: 'sic',
				mask: '9999',
				maskPlaceholder: '0000'
		});
});