define('z-inputs/components/z-text-field-email', ['exports', 'ember', 'z-inputs/components/z-text-field-base', 'z-inputs/templates/components/z-text-field-email'], function (exports, _ember, _zInputsComponentsZTextFieldBase, _zInputsTemplatesComponentsZTextFieldEmail) {
	'use strict';

	var escapeRegExp = function escapeRegExp(str) {
		// From http://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
		return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
	};

	var HOSTNAMES = ["aol.com", "att.net", "comcast.net", "facebook.com", "gmail.com", "gmx.com", "googlemail.com", "google.com", "hotmail.com", "hotmail.co.uk", "mac.com", "me.com", "mail.com", "msn.com", "live.com", "sbcglobal.net", "verizon.net", "yahoo.com", "zenefits.com"];

	exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend({
		layout: _zInputsTemplatesComponentsZTextFieldEmail['default'],
		classNames: 'z-textField--email',
		type: 'email',
		tooltipText: null,

		shadowValue: (function () {
			var value = this.get('value');
			value = _ember['default'].isEmpty(value) ? '' : value;
			return this.getEmailSuggestion(value);
		}).property('value'),

		getEmailSuggestion: function getEmailSuggestion(value) {
			var atIndex = value.indexOf('@');
			if (atIndex >= 0) {
				var username = value.substr(0, atIndex);
				var hostname = this.getEmailSuggestion(value.substr(atIndex + 1));
				var strippedHostName = escapeRegExp(hostname);
				for (var i = 0; i < HOSTNAMES.length; ++i) {
					if (strippedHostName.length > 0 && HOSTNAMES[i].match(new RegExp('^' + strippedHostName, 'g'))) {
						return username + '@' + HOSTNAMES[i];
					}
				}
			}
			return value;
		},

		actions: {
			keyDown: function keyDown(value, event) {
				var code = event.keyCode || event.which;
				value = this.get('value') || '';
				var suggestion = this.getEmailSuggestion(value);
				if (code === 9 || code === 39 && value !== suggestion) {
					this.set('value', suggestion);
				}
			}
		}
	});
});