define('ui-infra/utils/sanitize-html', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  exports.linkHref = linkHref;
  exports.nonUserEditableField = nonUserEditableField;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  exports['default'] = function (strings) {
    for (var _len = arguments.length, values = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      values[_key - 1] = arguments[_key];
    }

    if (!Array.isArray(strings)) {
      throw "sanitizeHtml should be used as a tagged literal (sanitizeHtml`foo`), not a method call (not: sanitizeHtml(`foo`).";
    }

    var ret = strings.reduce(function (accum, stringPart, index) {
      var value = values[index - 1];
      if (typeof value.toHtmlSafe === 'function') {
        value = value.toHtmlSafe();
      } else {
        value = _ember['default'].Handlebars.Utils.escapeExpression(value);
      }
      return accum + value + stringPart;
    });

    return _ember['default'].String.htmlSafe(ret);
  };

  var LinkHref = (function () {
    function LinkHref(href) {
      _classCallCheck(this, LinkHref);

      this.href = href;
    }

    _createClass(LinkHref, [{
      key: 'toHtmlSafe',
      value: function toHtmlSafe() {
        var urlParts = this.href.split('?');
        var firstPartEncoded = encodeURI(urlParts[0]);
        return urlParts[1] ? firstPartEncoded + '?' + encodeURIComponent(urlParts[1]) : firstPartEncoded;
      }
    }]);

    return LinkHref;
  })();

  function linkHref(href) {
    return new LinkHref(href);
  }

  var NonUserEditableField = (function () {
    function NonUserEditableField(string) {
      _classCallCheck(this, NonUserEditableField);

      this.string = string;
    }

    _createClass(NonUserEditableField, [{
      key: 'toHtmlSafe',
      value: function toHtmlSafe() {
        return this.string;
      }
    }, {
      key: 'toHTML',
      value: function toHTML() {
        return this.string;
      }
    }]);

    return NonUserEditableField;
  })();

  function nonUserEditableField(string) {
    return new NonUserEditableField(string);
  }
});