define("component-library/templates/components/radio-block", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.10-zenefits.3+18063622",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "modules/component-library/templates/components/radio-block.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("label");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2, "class", "radio-block-input");
        dom.setAttribute(el2, "type", "radio");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "radio-block");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element1, [], true);
        }
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element1, 'name');
        morphs[1] = dom.createAttrMorph(element1, 'value');
        morphs[2] = dom.createAttrMorph(element1, 'checked');
        morphs[3] = dom.createAttrMorph(element1, 'disabled');
        morphs[4] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        return morphs;
      },
      statements: [["attribute", "name", ["concat", [["get", "name", ["loc", [null, [2, 55], [2, 59]]]]]]], ["attribute", "value", ["concat", [["get", "value", ["loc", [null, [2, 72], [2, 77]]]]]]], ["attribute", "checked", ["get", "_isSelected", ["loc", [null, [2, 91], [2, 102]]]]], ["attribute", "disabled", ["get", "isDisabled", ["loc", [null, [2, 116], [2, 126]]]]], ["content", "yield", ["loc", [null, [4, 2], [4, 11]]]]],
      locals: [],
      templates: []
    };
  })());
});