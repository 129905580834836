define('component-library/components/z-header-company-logo', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-headerImageContainer'],

		canEditImage: false,
		defaultImageUrl: '/static/img/default-company-icon.svg',
		imageUrl: null,
		acceptedFileTypes: ['image'],
		fileCategory: 'company_logo',
		uploadCopy: _ember['default'].computed('imageUrl', function () {
			return (this.get('imageUrl') ? "Update" : "Add") + " your logo";
		}),
		showUploader: true,

		actions: {
			onFileUploadSuccess: function onFileUploadSuccess(file, data) {
				var _this = this;

				this.sendAction('onImageUpdate', data.url, data.url);

				// reset uploader
				this.set('showUploader', false);
				_ember['default'].run.later(function () {
					_this.set('showUploader', true);
				});
			},
			onFileUploadError: function onFileUploadError(file, error) {
				this.eventLogger.log('company-photo-logo-error', {
					message: "Error uploading file: " + error.toString()
				});
			}
		}
	});
});