define('component-library/components/z-paper-container', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var DEPTH_MAX = 3;
	var DEPTH_MIN = 0;

	exports['default'] = _ember['default'].Component.extend({
		depth: 1,
		_paperDepth: 'paper--zDepth-',
		classNames: ['paper', 'paper-container'],
		classNameBindings: ['_depthModifier', 'roundedCorners:paper--rounded'],
		roundedCorners: true,

		_depthModifier: (function () {
			var depth = this.get('depth');
			depth = depth >= DEPTH_MIN && depth <= DEPTH_MAX ? depth : DEPTH_MIN;
			return this.get('_paperDepth') + depth;
		}).property('depth')
	});
});