define('component-library/components/processing-modal', ['exports', 'component-library/components/promise-modal'], function (exports, _componentLibraryComponentsPromiseModal) {
	/*
  * To close modal, have your controller mixin Ember.Evented,
  * pass in the controller instance as `eventEmitter`, and then call
  * this.trigger('closeProcessingModal')
  * from inside of the controller when you're ready to close the modal.
  */
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		eventEmitter: null,
		allowKeyActions: false,
		cancelOnBackground: false,
		templateName: 'components/processing-modal',
		modalDialogClass: 'z-notificationModal',

		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super.apply(this, arguments);
			this.get('eventEmitter').on('closeProcessingModal', function () {
				if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
					_this._closeWithAnimation();
				}
			});
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			this.get('eventEmitter').off('closeProcessingModal');
		},

		actions: {
			startOver: function startOver() {
				if (confirm("Changes you made will not be saved. Are you want to start over?")) {
					// eslint-disable-line no-alert
					this.send('cancel');
				}
			}
		}
	});
});