define('component-library/mixins/benefits/timeline/transaction-contribution-data-mixin', ['exports', 'ember', 'lodash/lodash'], function (exports, _ember, _lodashLodash) {
	'use strict';

	var computedAmountForNew = function computedAmountForNew(propertyName) {
		return _ember['default'].computed(propertyName, function () {
			return this.get(propertyName) || 0;
		});
	};

	var computedAmountForOld = function computedAmountForOld(originalPropertyName, changePropertyName) {
		return _ember['default'].computed('transaction.isInitial', originalPropertyName, changePropertyName, function () {
			if (this.get('transaction.isInitial')) {
				return null;
			} else if (this.get(changePropertyName)) {
				return this.get(changePropertyName + '.lastObject') || 0;
			} else {
				return this.get(originalPropertyName);
			}
		});
	};

	var computedAmountForNewCombined = function computedAmountForNewCombined() {
		for (var _len = arguments.length, propertyNames = Array(_len), _key = 0; _key < _len; _key++) {
			propertyNames[_key] = arguments[_key];
		}

		return _ember['default'].computed.apply(_ember['default'], propertyNames.concat([function () {
			var _this = this;

			return _lodashLodash['default'].sum(propertyNames.map(function (propertyName) {
				return _this.get(propertyName);
			}));
		}]));
	};

	var computedAmountForOldCombined = function computedAmountForOldCombined() {
		for (var _len2 = arguments.length, propertyNames = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
			propertyNames[_key2] = arguments[_key2];
		}

		return _ember['default'].computed.apply(_ember['default'], ['transaction.isInitial'].concat(propertyNames, [function () {
			var _this2 = this;

			if (this.get('transaction.isInitial')) {
				return null;
			} else {
				return _lodashLodash['default'].sum(propertyNames.map(function (propertyName) {
					return _this2.get(propertyName);
				}));
			}
		}]));
	};

	exports['default'] = _ember['default'].Mixin.create({
		employeeContributionAmount: computedAmountForNew('transaction.jsonDataOfContribution.employeeContribution'),
		dependentsContributionAmount: computedAmountForNew('transaction.jsonDataOfContribution.dependentsContribution'),
		spouseContributionAmount: computedAmountForNew('transaction.jsonDataOfContribution.spouseContribution'),
		childrenContributionAmount: computedAmountForNew('transaction.jsonDataOfContribution.childrenContribution'),
		employeeDeductionAmount: computedAmountForNew('transaction.jsonDataOfContribution.employeeDeduction'),
		dependentsDeductionAmount: computedAmountForNew('transaction.jsonDataOfContribution.dependentsDeduction'),
		spouseDeductionAmount: computedAmountForNew('transaction.jsonDataOfContribution.spouseDeduction'),
		childrenDeductionAmount: computedAmountForNew('transaction.jsonDataOfContribution.childrenDeduction'),

		contributionAmount: computedAmountForNewCombined('employeeContributionAmount', 'dependentsContributionAmount'),
		deductionAmount: computedAmountForNewCombined('employeeDeductionAmount', 'dependentsDeductionAmount'),
		totalAmount: computedAmountForNewCombined('contributionAmount', 'deductionAmount'),
		employeeAmount: computedAmountForNewCombined('employeeContributionAmount', 'employeeDeductionAmount'),
		dependentsAmount: computedAmountForNewCombined('dependentsContributionAmount', 'dependentsDeductionAmount'),
		spouseAmount: computedAmountForNewCombined('spouseContributionAmount', 'spouseDeductionAmount'),
		childrenAmount: computedAmountForNewCombined('childrenContributionAmount', 'childrenDeductionAmount'),

		employeeContributionChange: _ember['default'].computed.alias('transaction.jsonDataOfContributionChange.employeeContribution'),
		dependentsContributionChange: _ember['default'].computed.alias('transaction.jsonDataOfContributionChange.dependentsContribution'),
		spouseContributionChange: _ember['default'].computed.alias('transaction.jsonDataOfContributionChange.spouseContribution'),
		childrenContributionChange: _ember['default'].computed.alias('transaction.jsonDataOfContributionChange.childrenContribution'),
		employeeDeductionChange: _ember['default'].computed.alias('transaction.jsonDataOfContributionChange.employeeDeduction'),
		dependentsDeductionChange: _ember['default'].computed.alias('transaction.jsonDataOfContributionChange.dependentsDeduction'),
		spouseDeductionChange: _ember['default'].computed.alias('transaction.jsonDataOfContributionChange.spouseDeduction'),
		childrenDeductionChange: _ember['default'].computed.alias('transaction.jsonDataOfContributionChange.childrenDeduction'),

		oldEmployeeContributionAmount: computedAmountForOld('employeeContributionAmount', 'employeeContributionChange'),
		oldDependentsContributionAmount: computedAmountForOld('dependentsContributionAmount', 'dependentsContributionChange'),
		oldSpouseContributionAmount: computedAmountForOld('spouseContributionAmount', 'spouseContributionChange'),
		oldChildrenContributionAmount: computedAmountForOld('childrenContributionAmount', 'childrenContributionChange'),
		oldEmployeeDeductionAmount: computedAmountForOld('employeeDeductionAmount', 'employeeDeductionChange'),
		oldDependentsDeductionAmount: computedAmountForOld('dependentsDeductionAmount', 'dependentsDeductionChange'),
		oldSpouseDeductionAmount: computedAmountForOld('spouseDeductionAmount', 'spouseDeductionChange'),
		oldChildrenDeductionAmount: computedAmountForOld('childrenDeductionAmount', 'childrenDeductionChange'),

		oldContributionAmount: computedAmountForOldCombined('oldEmployeeContributionAmount', 'oldDependentsContributionAmount'),
		oldDeductionAmount: computedAmountForOldCombined('oldEmployeeDeductionAmount', 'oldDependentsDeductionAmount'),
		oldTotalAmount: computedAmountForOldCombined('oldContributionAmount', 'oldDeductionAmount'),
		oldEmployeeAmount: computedAmountForOldCombined('oldEmployeeContributionAmount', 'oldEmployeeDeductionAmount'),
		oldDependentsAmount: computedAmountForOldCombined('oldDependentsContributionAmount', 'oldDependentsDeductionAmount'),
		oldSpouseAmount: computedAmountForOldCombined('oldSpouseContributionAmount', 'oldSpouseDeductionAmount'),
		oldChildrenAmount: computedAmountForOldCombined('oldChildrenContributionAmount', 'oldChildrenDeductionAmount')
	});
});