define('component-library/components/z-inline-base', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		label: null,
		model: null,
		errorText: null,
		useWordsForActions: false,
		isEditable: true,
		isRequired: false,
		saveActionPromise: null,

		// INTERNAL PARAMS
		isSaving: false,
		isEditing: false,
		focusClassName: null,
		propertyNameObjects: null,

		init: function init() {
			this._super();
			this._initializeInternalValues();
		},

		_validateModelAndPropertyName: function _validateModelAndPropertyName(model, modelPropertyName) {
			// run this on init() to make sure the model is loaded and the properties exist on it
			if (_ember['default'].isEmpty(model)) {
				throw new Error('model must be passed as a parameter');
			} else if (!model.get('isLoaded')) {
				throw new Error('the model must be loaded');
			} else if (typeof model.get(modelPropertyName) == 'undefined') {
				throw new Error(modelPropertyName + ' does not exist in the passed-in model');
			}
		},

		_initializeInternalValues: function _initializeInternalValues() {
			this._loopThroughPropertiesAndUpdate('internalValues', true);
		},
		_rollbackInternalValues: function _rollbackInternalValues() {
			this._loopThroughPropertiesAndUpdate('internalValues');
		},
		_updateModel: function _updateModel() {
			this._loopThroughPropertiesAndUpdate('modelValues');
		},

		_loopThroughPropertiesAndUpdate: function _loopThroughPropertiesAndUpdate(modelType) {
			var _this = this;

			var isSettingUp = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

			var model = this.get('model');
			var propertyNameObjects = this.get('propertyNameObjects');

			propertyNameObjects.forEach(function (propertyNameObject) {
				var internalProperty = propertyNameObject.internalProperty;
				var modelProperty = _this.get(propertyNameObject.modelPropertyName);

				if (isSettingUp) {
					_this._validateModelAndPropertyName(model, modelProperty);
				}

				if (modelType == 'internalValues') {
					var modelValue = model.get(modelProperty);
					_this.set(internalProperty, modelValue);
				} else if (modelType == 'modelValues') {
					var internalValue = _this.get(internalProperty);
					model.set(modelProperty, internalValue);
				}
			});
		},

		catchFunc: function catchFunc(error) {
			this._rollbackInternalValues();
			this.eventLogger.log('z-inline-base', {
				message: 'z-inline-base unable to save'
			});
			throw new Error(error);
		},
		finallyFunc: function finallyFunc() {
			if (!this.get('isDestroying')) {
				this.setProperties({
					isSaving: false,
					isEditing: false
				});
			}
		},

		_focusOnInput: function _focusOnInput() {
			var _this2 = this;

			var focusClassName = this.get('focusClassName');
			if (focusClassName) {
				_ember['default'].run.later(function () {
					var $element = _this2.$(focusClassName);
					if ($element) {
						$element.focus();
					}
				});
			}
		},

		actions: {
			edit: function edit() {
				// if user has passed in an edit action, call that one
				var passedInEditClosureAction = this.get('edit');
				if (passedInEditClosureAction) {
					return passedInEditClosureAction();
				}

				if (this.get('isEditable')) {
					this.set('isEditing', true);
					this._focusOnInput();
				}
			},
			save: function save() {
				var model = this.get('model');
				var saveActionPromise = this.get('saveActionPromise');
				var catchFunc = this.get('catchFunc').bind(this);
				var finallyFunc = this.get('finallyFunc').bind(this);

				this.set('isSaving', true);
				this._updateModel();

				if (saveActionPromise) {
					// TODO(Andrew V3) allow for error setting to occur within the .then() failure callback
					saveActionPromise()['catch'](catchFunc)['finally'](finallyFunc);
				} else {
					model.save()['catch'](catchFunc)['finally'](finallyFunc);
				}
			},
			cancel: function cancel() {
				this._rollbackInternalValues();
				this.set('isEditing', false);
			}
		}
	});
});