define('component-library/components/benefits/timeline/transactions-lazy-loader', ['exports', 'ember', 'component-library/components/benefits/timeline/transaction'], function (exports, _ember, _componentLibraryComponentsBenefitsTimelineTransaction) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports['default'] = _ember['default'].Object.extend({

		minLimit: 10,
		maxLimit: 30,

		init: function init() {
			this.set('mapFromIndexToId', new _ember['default'].Map());
			this.set('cacheById', new _ember['default'].Map());
			this.set('requestQueue', new _ember['default'].Set());
			this.set('promiseQueue', new _ember['default'].Set());
		},

		urlQueryParams: (function () {
			var urlQueryParams = {};
			var params = this.get('queryParams');
			Object.keys(params).forEach(function (key) {
				var value = params[key];
				urlQueryParams[key] = Array.isArray(value) ? value.join(';') : value;
			});
			return urlQueryParams;
		}).property('queryParams'),

		fetchTransactions: function fetchTransactions() {
			var _this = this;

			// Trying loading from cache again
			var entries = this.loadFromCache(this.get('requestQueue'));
			this.set('requestQueue', entries);
			// Remove if promised
			entries = this.removeIfPromised(entries);
			if (_ember['default'].isEmpty(entries)) {
				return;
			}
			var indexes = entries.map(function (item) {
				return item.get('index');
			});
			var minIndex = Math.min.apply(Math, _toConsumableArray(indexes));
			var maxIndex = Math.max.apply(Math, _toConsumableArray(indexes));
			var params = _ember['default'].$.extend({}, this.get('urlQueryParams'));
			var offset = minIndex;
			params['offset'] = offset;
			var limit = maxIndex - minIndex + 1;
			limit = Math.min(limit, this.get('maxLimit'));
			params['limit'] = limit;
			_ember['default'].$.get('/custom_api/benefitsTransactionFetch/', params).then(function (item) {
				var _offset = item['meta']['offset'];
				var objects = item['objects'];
				var _mapFromIndexToId = _this.get('mapFromIndexToId');
				var _cacheById = _this.get('cacheById');
				objects.forEach(function (item, index) {
					_mapFromIndexToId.set(_offset + index, item.id);
					if (!_cacheById.has(item.id)) {
						_cacheById.set(item.id, _componentLibraryComponentsBenefitsTimelineTransaction['default'].create(item));
					}
				});
				_this.loadFromCache(_this.get('requestQueue'));
				for (var i = 0; i < limit; i++) {
					_this.get('promiseQueue').remove(i + offset);
				}
			});
			for (var i = 0; i < limit; i++) {
				this.get('promiseQueue').add(i + offset);
			}
		},

		removeIfPromised: function removeIfPromised(entries) {
			var _this2 = this;

			var newQueue = new _ember['default'].Set();
			entries.forEach(function (item) {
				if (!_this2.get('promiseQueue').contains(item.get('index'))) {
					newQueue.add(item);
				}
			});
			return newQueue;
		},

		loadFromCache: function loadFromCache(entries) {
			var newQueue = new _ember['default'].Set();
			var _cacheById = this.get('cacheById');
			var _mapFromIndexToId = this.get('mapFromIndexToId');
			entries.forEach(function (item) {
				var cacheHit = false;
				var index = item.get('index');
				if (_mapFromIndexToId.has(index)) {
					var id = _mapFromIndexToId.get(index);
					if (_cacheById.has(id)) {
						item.set('content', _cacheById.get(id));
						cacheHit = true;
					}
				}
				if (!cacheHit) {
					// if cache hit missing
					newQueue.add(item);
				}
			});
			return newQueue;
		},

		// This is function called from outside
		load: function load(entries) {
			var newQueue = this.loadFromCache(entries);
			this.set('requestQueue', newQueue);
			_ember['default'].run.debounce(this, this.fetchTransactions, 100);
		},

		loadSingleTransaction: function loadSingleTransaction(id) {
			var _cacheById = this.get('cacheById');
			if (_cacheById.has(id)) {
				return _ember['default'].RSVP.resolve(_cacheById.get(id));
			} else {
				return _ember['default'].$.get('/custom_api/benefitsTransactionGet/' + id).then(function (item) {
					var trans = _componentLibraryComponentsBenefitsTimelineTransaction['default'].create(item);
					if (!_cacheById.has(item.id)) {
						_cacheById.set(item.id, trans);
					}
					return trans;
				});
			}
		},

		loadMeta: function loadMeta() {
			var _this3 = this;

			var metaQueryParams = _ember['default'].$.extend({}, this.get('urlQueryParams'));
			metaQueryParams['offset'] = 0;
			metaQueryParams['limit'] = this.get('minLimit');
			return _ember['default'].$.get('/custom_api/benefitsTransactionFetch/', metaQueryParams).then(function (item) {
				var offset = item['meta']['offset'];
				var limit = item['meta']['limit'];
				var objects = item['objects'];
				var _mapFromIndexToId = _this3.get('mapFromIndexToId');
				var _cacheById = _this3.get('cacheById');
				objects.forEach(function (item, index) {
					_mapFromIndexToId.set(offset + index, item.id);
					if (!_cacheById.has(item.id)) {
						_cacheById.set(item.id, _componentLibraryComponentsBenefitsTimelineTransaction['default'].create(item));
					}
				});
				return _ember['default'].Object.create(item['meta']);
			});
		}
	});
});