define('component-library/components/payroll/reports/naive-report-configuration-modal', ['exports', 'ember', 'component-library/components/promise-modal', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryComponentsPromiseModal, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'components/payroll/reports/naive-report-configuration-modal',
		reportName: null,
		showCompanyIdField: false,
		showPeriodField: false,
		showPreviousW2Field: false,
		showW2PreviewConfirmation: false,
		quarterNumbers: [],
		startYear: 2016,
		yearNumbers: [],
		extraDateRanges: [],
		extraPeriodOptions: [],
		selectedPeriod: _ember['default'].computed.oneWay('periodOptions.firstObject'),
		selectedPreviousW2: _ember['default'].computed.oneWay('previousW2s.firstObject'),
		eventLogger: _ember['default'].inject.service(),
		companyId: null,
		allPreviousW2s: _ember['default'].computed.oneWay('previousW2s'),
		allQuaterNumbers: _ember['default'].computed('extraDateRanges', 'quarterNumbers', function () {
			var quarterNumbers = this.get('quarterNumbers').slice();
			var extraDateRanges = this.get('extraDateRanges');
			if (_ember['default'].isEmpty(extraDateRanges)) {
				return quarterNumbers;
			}
			var startYear = this.get('startYear');
			extraDateRanges.forEach(function (dateRange) {
				var startDate = (0, _componentLibraryUtilsGlobals.moment)(dateRange.startDate, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var endDate = (0, _componentLibraryUtilsGlobals.moment)(dateRange.endDate, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var year = startDate.get('year');
				var allowedPattern = ['01/01/' + year + '-03/31/' + year, '04/01/' + year + '-06/30/' + year, '07/01/' + year + '-09/30/' + year, '10/01/' + year + '-12/31/' + year];
				if (allowedPattern.indexOf(dateRange.startDate + '-' + dateRange.endDate) != -1) {
					var cmpStartDate = (0, _componentLibraryUtilsGlobals.moment)('01/01/' + startYear, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
					var monthDiff = startDate.diff(cmpStartDate, 'month');
					var quarter = monthDiff / 3 + 1;
					if (quarterNumbers.indexOf(quarter) == -1) {
						quarterNumbers.push(quarter);
					}
				}
			});
			return quarterNumbers;
		}),
		allYearNumbers: _ember['default'].computed('extraDateRanges', 'yearNumbers', function () {
			var yearNumbers = this.get('yearNumbers').slice();
			var extraDateRanges = this.get('extraDateRanges');
			if (_ember['default'].isEmpty(extraDateRanges)) {
				return yearNumbers;
			}
			var startYear = this.get('startYear');
			extraDateRanges.forEach(function (dateRange) {
				var startDate = (0, _componentLibraryUtilsGlobals.moment)(dateRange.startDate, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var endDate = (0, _componentLibraryUtilsGlobals.moment)(dateRange.endDate, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var year = startDate.get('year');
				var allowedPattern = ['01/01/' + year + '-12/31/' + year];
				if (allowedPattern.indexOf(dateRange.startDate + '-' + dateRange.endDate) != -1) {
					var cmpStartDate = (0, _componentLibraryUtilsGlobals.moment)('01/01/' + startYear, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
					var yearDiff = startDate.diff(cmpStartDate, 'year');
					if (yearNumbers.indexOf(yearDiff + 1) == -1) {
						yearNumbers.push(yearDiff + 1);
					}
				}
			});
			return yearNumbers;
		}),
		onCompanyIdError: function onCompanyIdError(companyId) {
			return '';
		},
		companyIdError: _ember['default'].computed('companyId', 'onCompanyIdError', function () {
			var companyId = this.get('companyId');
			return this.onCompanyIdError(companyId);
		}),
		onSelectedPeriodError: function onSelectedPeriodError(selectedPeriod) {
			return '';
		},
		selectedPeriodError: _ember['default'].computed('selectedPeriod', 'onSelectedPeriodError', function () {
			var selectedPeriod = this.get('selectedPeriod');
			return this.onSelectedPeriodError(selectedPeriod);
		}),
		hasError: _ember['default'].computed.or('companyIdError', 'selectedPeriodError'),
		title: _ember['default'].computed('reportName', function () {
			var reportName = this.get('reportName');
			return 'Configure ' + reportName + ' Download';
		}),
		onDownload: function onDownload(configurations) {
			return '';
		},
		periodCompareFunction: function periodCompareFunction(a, b) {
			return 0;
		},
		periodOptions: _ember['default'].computed('allQuaterNumbers', 'startYear', 'allYearNumbers', 'extraPeriodOptions', 'periodCompareFunction', function () {
			var quarterNumbers = this.get('allQuaterNumbers') || [];
			var year = this.get('startYear');
			var yearNumbers = this.get('allYearNumbers') || [];
			var allOptions = (this.get('extraPeriodOptions') || []).slice();
			var periodCompareFunction = this.periodCompareFunction;
			// Create all quarterly options.
			quarterNumbers.forEach(function (quarterNumber) {
				if (quarterNumber <= 0) {
					return;
				}
				var realQuarter = (quarterNumber - 1) % 4 + 1;
				var realYear = Math.floor((quarterNumber - 1) / 4) + year;
				var quarterDate = (0, _componentLibraryUtilsGlobals.moment)(realQuarter + '/' + realYear, "Q/YYYY");
				var quarterStartdate = quarterDate.startOf('quarter').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var quarterEndDate = quarterDate.endOf('quarter').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var quarterName = realYear.toString() + "Q" + realQuarter.toString() + "        " + quarterStartdate + "-" + quarterEndDate;
				allOptions.push({
					startDate: quarterStartdate,
					endDate: quarterEndDate,
					name: quarterName
				});
			});
			// Create all annual options.
			yearNumbers.forEach(function (yearNumber) {
				if (yearNumber <= 0) {
					return;
				}
				var realYear = yearNumber - 1 + year;
				var yearDate = (0, _componentLibraryUtilsGlobals.moment)('' + realYear, "YYYY");
				var yearStartdate = yearDate.startOf('year').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var yearEndDate = yearDate.endOf('year').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var yearName = "Y" + realYear.toString() + "        " + yearStartdate + "-" + yearEndDate;
				allOptions.push({
					startDate: yearStartdate,
					endDate: yearEndDate,
					name: yearName
				});
			});
			return allOptions.sort(periodCompareFunction);
		}),
		previousW2Previews: _ember['default'].computed('previousW2s', function () {
			var previousW2 = this.get('previousW2s') || [];
			var allOptions = [];
			previousW2.forEach(function (w2Preview) {
				var previewId = w2Preview.id;
				var previewName = w2Preview.name;
				allOptions.push({
					name: previewName,
					id: previewId
				});
			});
			return allOptions;
		}),
		actions: {
			download: function download(laddaButton) {
				var _this = this;

				if (this.get('hasError')) {
					laddaButton.stop();
					return;
				}
				var reportName = this.get('reportName');
				var configurations = {
					reportName: reportName
				};
				if (this.get('showCompanyIdField')) {
					configurations.company_id = this.get('companyId');
				}
				if (this.get('showPeriodField')) {
					var period = this.get('selectedPeriod');
					configurations.startDate = period.startDate;
					configurations.endDate = period.endDate;
					if (!_ember['default'].isEmpty(period.extra)) {
						configurations.extra = period.extra;
					}
				}
				if (this.get('showPreviousW2Field')) {
					var previousFile = this.get('selectedPreviousW2');
					configurations.id = previousFile.id;
				}
				var promise = this.onDownload(configurations);
				if (promise.then) {
					promise.then(function (data) {
						_this.send('confirm', data);
					}, function (error) {
						_this.send('cancel', error);
					});
				} else {
					this.send('confirm');
				}
			}
		}
	});
});