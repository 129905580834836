define('component-library/timeline/components/data/default', ['exports', 'ember', 'component-library/timeline/utils/types', 'ui-infra/services/switches'], function (exports, _ember, _componentLibraryTimelineUtilsTypes, _uiInfraServicesSwitches) {
	'use strict';

	var allTypeFilters = (0, _componentLibraryTimelineUtilsTypes.getEntryTypeFilters)(_componentLibraryTimelineUtilsTypes.fullTypeList);

	exports['default'] = _ember['default'].Component.extend({
		templateName: 'components/timeline/data/default',

		// this keeps the array from being shared across instances of the component because
		// Ember uses prototypical inheritence rather than classical inheritence
		relatedEntries: (function () {
			return [];
		}).property(),

		// primitives, however aren't shared across instances of the component because
		// they get copied by value rather than by reference
		isLoading: false,
		expanded: false,

		contractor_switch_on: _ember['default'].computed('', function () {
			return _uiInfraServicesSwitches['default'].instance.get('contractor_new');
		}),

		lastEvent: _ember['default'].computed.alias('entry.data.lastEvent'),

		humanEntryType: (function () {
			var typeObj = allTypeFilters.findBy('model', this.get('entry.entry_type'));
			return _ember['default'].get(typeObj, 'label');
		}).property('entry.entry_type'),

		actions: {
			expandEntry: function expandEntry() {
				var _this = this;

				if (this.get('isLoading')) {
					return;
				}
				if (this.get('expanded')) {
					this.set('expanded', false);
					return;
				}
				var hidden = this.get('entry.data.eventsHidden');
				if (hidden > 0) {
					if (_ember['default'].isEmpty(this.get('relatedEntries'))) {
						this.get('getFullFeedEntry')(this.get('entry.id')).then(function (full) {
							return full.get('data.events');
						}).then(function (entries) {
							return entries.slice(1);
						}).then(function (entries) {
							_this.get('relatedEntries').addObjects(entries);
						})['finally'](function () {
							_this.set('expanded', true);
							_this.set('isLoading', false);
						});
						this.set('isLoading', true);
					} else {
						this.set('expanded', true);
					}
				}
			}
		}
	});
});