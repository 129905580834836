define('ember-table/mixins/scroll-handler', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    onScroll: _ember['default'].K,
    scrollElementSelector: '',

    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super();
      this.$(this.get('scrollElementSelector')).bind('scroll', function (event) {
        _ember['default'].run(_this, _this.onScroll, event);
      });
    },

    willDestroyElement: function willDestroyElement() {
      var $scrollElementSelector = this.$(this.get('scrollElementSelector'));
      if ($scrollElementSelector) {
        $scrollElementSelector.unbind('scroll');
      }
    }
  });
});