define('component-library/components/contributions-modal/constants', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var _LOC_TITLES;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
		} else {
			obj[key] = value;
		}return obj;
	}

	var LOCS = {
		MEDICAL: 'medical',
		DENTAL: 'dental',
		VISION: 'vision',
		ACC: 'acc',
		CANCER: 'cancer',
		CI: 'ci',
		HI: 'hi',
		LIFE_VOL: 'life_vol',
		ADND_VOL: 'adnd_vol',
		STD_VOL: 'std_vol',
		LTD_VOL: 'ltd_vol'
	};

	exports.LOCS = LOCS;

	var LOC_TITLES = (_LOC_TITLES = {}, _defineProperty(_LOC_TITLES, LOCS.MEDICAL, 'Medical'), _defineProperty(_LOC_TITLES, LOCS.DENTAL, 'Dental'), _defineProperty(_LOC_TITLES, LOCS.VISION, 'Vision'), _defineProperty(_LOC_TITLES, LOCS.ACC, 'Accident'), _defineProperty(_LOC_TITLES, LOCS.CANCER, 'Cancer'), _defineProperty(_LOC_TITLES, LOCS.CI, 'Critical Illness'), _defineProperty(_LOC_TITLES, LOCS.HI, 'Hospital Indemnity'), _defineProperty(_LOC_TITLES, LOCS.LIFE_VOL, 'Voluntary Life'), _defineProperty(_LOC_TITLES, LOCS.ADND_VOL, 'Voluntary Accidental Death and Dismemberment'), _defineProperty(_LOC_TITLES, LOCS.STD_VOL, 'Voluntary Short Term Disability'), _defineProperty(_LOC_TITLES, LOCS.LTD_VOL, 'Voluntary Long Term Disability'), _LOC_TITLES);

	exports.LOC_TITLES = LOC_TITLES;

	var SUPPLEMENTAL_LOCS = [LOCS.ACC, LOCS.CANCER, LOCS.CI, LOCS.HI];
	exports.SUPPLEMENTAL_LOCS = SUPPLEMENTAL_LOCS;

	var VOL_LND_LOCS = [LOCS.LIFE_VOL, LOCS.ADND_VOL, LOCS.STD_VOL, LOCS.LTD_VOL];

	exports.VOL_LND_LOCS = VOL_LND_LOCS;

	var RULE_DATA_ATTRS = {
		FIXED_AMOUNT: 'fixedAmount',
		PERCENTAGE: 'percentage',
		CAP: 'cap',
		PLAN_ID: 'planId',
		TYPE: 'type',
		LINE_OF_COVERAGE: 'lineOfCoverage'
	};

	exports.RULE_DATA_ATTRS = RULE_DATA_ATTRS;

	var HIDE_NOTIFICATION_AFTER = 7000;exports.HIDE_NOTIFICATION_AFTER = HIDE_NOTIFICATION_AFTER;

	// 7 seconds
	var LOCS_SORTED = [LOCS.MEDICAL, LOCS.DENTAL, LOCS.VISION];

	exports.LOCS_SORTED = LOCS_SORTED;

	var lineOfCoverageComparator = function lineOfCoverageComparator(a, b) {
		return LOCS_SORTED.indexOf(a) - LOCS_SORTED.indexOf(b);
	};

	exports.lineOfCoverageComparator = lineOfCoverageComparator;

	var ELIGIBILITY_SELECTION = {
		EMPLOYEE: 'employeeSpecific',
		TIER: 'tierSpecific',
		ALL: 'allEmployees'
	};

	exports.ELIGIBILITY_SELECTION = ELIGIBILITY_SELECTION;

	var INVALID_LOC = 'invalidLoc';
	exports.INVALID_LOC = INVALID_LOC;

	var INVALID_CARRIER = 'invalidCarrier';
	exports.INVALID_CARRIER = INVALID_CARRIER;

	var INVALID_PLAN = 'invalidPlan';

	exports.INVALID_PLAN = INVALID_PLAN;

	var DEDUCTION = 'Deduction';
	exports.DEDUCTION = DEDUCTION;

	var CONTRIBUTION = 'Contribution';

	exports.CONTRIBUTION = CONTRIBUTION;

	var IN_CONTRIBUTION_TIERS = 'InContributionTiers';
	exports.IN_CONTRIBUTION_TIERS = IN_CONTRIBUTION_TIERS;

	var IN_EMPLOYEE_IDS = 'InEmployeeIds';
	exports.IN_EMPLOYEE_IDS = IN_EMPLOYEE_IDS;

	var UNDEFINED = 'Undefined';

	exports.UNDEFINED = UNDEFINED;

	var UNIFORM = 'UNIFORM';
	exports.UNIFORM = UNIFORM;

	var TOTAL = 'TOTAL';
	exports.TOTAL = TOTAL;

	var DEPENDENT_TYPE = 'DependentType';
	exports.DEPENDENT_TYPE = DEPENDENT_TYPE;

	var DEPENDENT_COUNT = 'DependentCount';
	exports.DEPENDENT_COUNT = DEPENDENT_COUNT;

	var SEPARATE = 'SEPARATE';

	exports.SEPARATE = SEPARATE;

	var ZERO = 'Zero';
	exports.ZERO = ZERO;

	var FIXED = 'Fixed';
	exports.FIXED = FIXED;

	var PERCENTAGE = 'Percentage';
	exports.PERCENTAGE = PERCENTAGE;

	var PERCENTAGE_OF_PLAN_RAW = 'PercentageOfPlanRaw';

	exports.PERCENTAGE_OF_PLAN_RAW = PERCENTAGE_OF_PLAN_RAW;

	var UNSUPPORTED = 'Unsupported';exports.UNSUPPORTED = UNSUPPORTED;

	// we don't support this rule in the new component.

	var TYPES = [FIXED, PERCENTAGE];
	exports.TYPES = TYPES;

	var SUPPORTED_TYPES = [ZERO, FIXED, PERCENTAGE, PERCENTAGE_OF_PLAN_RAW];
	exports.SUPPORTED_TYPES = SUPPORTED_TYPES;

	var EMPLOYEE = 'EMPLOYEE';
	exports.EMPLOYEE = EMPLOYEE;

	var SPOUSE = 'SPOUSE';
	exports.SPOUSE = SPOUSE;

	var CHILD = 'CHILD';
	exports.CHILD = CHILD;

	var FAMILY = 'FAMILY';
	exports.FAMILY = FAMILY;

	var DEPENDENTS = 'DEPENDENTS';

	exports.DEPENDENTS = DEPENDENTS;

	var CONTRIBUTION_CHOICES = [{ id: UNIFORM, text: 'All Dependents Equal', description: '(each dependent receives same contribution)' }, { id: TOTAL, text: 'Define Total Contribution', description: '(single amount for both employee and dependents)' }, { id: DEPENDENT_TYPE, text: 'Specify by Type', description: '(spouse, child, family)' }, { id: DEPENDENT_COUNT, text: 'Specify by Quantity', description: '(one, two, three...)' }].map(function (item) {
		return _ember['default'].Object.create(item);
	});

	exports.CONTRIBUTION_CHOICES = CONTRIBUTION_CHOICES;

	var DEDUCTIONS_CHOICES = CONTRIBUTION_CHOICES.slice();
	exports.DEDUCTIONS_CHOICES = DEDUCTIONS_CHOICES;

	DEDUCTIONS_CHOICES[1] = _ember['default'].Object.create({
		id: TOTAL,
		text: 'Define Total Deduction',
		description: '(single amount for both employee and dependents)'
	});

	var TYPE_LABELS_INDIVIDUAL = _ember['default'].Object.create({
		employee: 'Employee',
		spouse: 'Spouse',
		children: 'Child(ren)',
		family: 'Family'
	});
	exports.TYPE_LABELS_INDIVIDUAL = TYPE_LABELS_INDIVIDUAL;

	var TYPE_LABELS_TOTAL = _ember['default'].Object.create({
		employee: 'Employee Only',
		spouse: 'Employee + Spouse',
		children: 'Employee + Children',
		family: 'Employee + Family'
	});

	exports.TYPE_LABELS_TOTAL = TYPE_LABELS_TOTAL;

	var CONTRIBUTION_METHODS_CHOICES = [{ id: TOTAL, text: 'Apply sum (employee & dependents) of contribution from sum of premiums.', description: '' }, { id: SEPARATE, text: 'Apply individually (each amount will be treated separately for each respective dependent)', description: '' }].map(function (item) {
		return _ember['default'].Object.create(item);
	});

	exports.CONTRIBUTION_METHODS_CHOICES = CONTRIBUTION_METHODS_CHOICES;

	var LINE_OF_COVERAGES_CHOICES = [{ id: null, text: 'All', description: 'All LOCs' }, { id: LOCS.MEDICAL, text: LOC_TITLES[LOCS.MEDICAL], description: 'Medical coverage' }, { id: LOCS.DENTAL, text: LOC_TITLES[LOCS.DENTAL], description: 'Dental coverage' }, { id: LOCS.VISION, text: LOC_TITLES[LOCS.VISION], description: 'Vision coverage' }, { id: LOCS.ACC, text: LOC_TITLES[LOCS.ACC], description: 'Accidental coverage' }, { id: LOCS.CANCER, text: LOC_TITLES[LOCS.CANCER], description: 'Cancer coverage' }, { id: LOCS.CI, text: LOC_TITLES[LOCS.CI], description: 'Critical Illness coverage' }, { id: LOCS.HI, text: LOC_TITLES[LOCS.HI], description: 'Hospital Indemnity coverage' }, { id: LOCS.LIFE_VOL, text: LOC_TITLES[LOCS.LIFE_VOL], description: 'Voluntary life coverage' }, { id: LOCS.ADND_VOL, text: LOC_TITLES[LOCS.ADND_VOL], description: 'Voluntary accidental death and dismemberment coverage' }, { id: LOCS.STD_VOL, text: LOC_TITLES[LOCS.STD_VOL], description: 'Voluntary short term disability coverage' }, { id: LOCS.LTD_VOL, text: LOC_TITLES[LOCS.LTD_VOL], description: 'Medical long term disability coverage' }].map(function (item) {
		return _ember['default'].Object.create(item);
	});

	exports.LINE_OF_COVERAGES_CHOICES = LINE_OF_COVERAGES_CHOICES;

	var TEMPLATES = [UNDEFINED, IN_CONTRIBUTION_TIERS, IN_EMPLOYEE_IDS];
	exports.TEMPLATES = TEMPLATES;

	var RULES_COMPARATOR = function RULES_COMPARATOR(r1, r2) {
		var template1Priority = TEMPLATES.indexOf(r1.get('employeeTemplate.type'));
		var template2Priority = TEMPLATES.indexOf(r2.get('employeeTemplate.type'));
		if (template1Priority > template2Priority) {
			return true;
		}
		if (template1Priority < template2Priority) {
			return false;
		}
		if (r1.get('planId') != null) {
			return true;
		}
		if (r2.get('planId') != null) {
			return false;
		}
		if (r1.get('carrierId') != null) {
			return true;
		}
		if (r2.get('carrierId') != null) {
			return false;
		}
		if (r1.get('lineOfCoverage') != null) {
			return true;
		}
		return false;
	};

	exports.RULES_COMPARATOR = RULES_COMPARATOR;

	var RULE_FIELDS_FOR_COMPARING = ['lineOfCoverage', 'carrierId', 'planId', 'employeePredicateFormula', 'description', 'employeeContributionFormula', 'dependentsContributionFormula', 'totalContributionFormula', 'employeeDeductionFormula', 'dependentsDeductionFormula', 'totalDeductionFormula'];
	exports.RULE_FIELDS_FOR_COMPARING = RULE_FIELDS_FOR_COMPARING;
});