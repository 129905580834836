define('component-library/components/deductions/deduction-doNotPush-popover', ['exports', 'ember', 'component-library/utils/globals', 'component-library/components/promise-popover', 'component-library/templates/components/deductions/deduction-doNotPush-popover'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryComponentsPromisePopover, _componentLibraryTemplatesComponentsDeductionsDeductionDoNotPushPopover) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
		template: _componentLibraryTemplatesComponentsDeductionsDeductionDoNotPushPopover['default'],
		hasArrow: true,
		arrowOffset: 0,
		placement: 'bottom',
		attachment: 'top center',
		targetAttachment: 'bottom center',

		doNotPushExplanation: (function () {
			var isContribution = this.get('content.isContribution');
			var data = this.get('content.data');
			var doNotPush = isContribution ? _ember['default'].get(data, 'doNotPushContrib') : _ember['default'].get(data, 'doNotPush');
			if (doNotPush) {
				return new _ember['default'].Handlebars.SafeString('This deduction sync is currently blocked by ' + _componentLibraryUtilsGlobals.brandName + '. This is due to a request by the admin or some work internally to get your deductions back in shape.');
			} else {
				return '';
			}
		}).property('content')
	});
});