define('component-library/mixins/benefits/timeline/transaction-dependents-data-mixin', ['exports', 'ember', 'component-library/utils/benefits/timeline/computed'], function (exports, _ember, _componentLibraryUtilsBenefitsTimelineComputed) {
	'use strict';

	var DependentChangeModel = _ember['default'].Object.extend({
		old: null,
		'new': null,
		change: null,

		coverageEffectiveDate: _ember['default'].computed.alias('new.coverage.effectiveDate'),
		coverageEffectiveDateChange: _ember['default'].computed.alias('change.coverage.effectiveDate'),
		_oldCoverageEffectiveDate: _ember['default'].computed.alias('coverageEffectiveDateChange.lastObject'),
		oldCoverageEffectiveDate: _ember['default'].computed.or('_oldCoverageEffectiveDate', 'coverageEffectiveDate'),

		isEnrolledChange: _ember['default'].computed.alias('change.coverage.isEnrolled'),
		isEnrolledNew: _ember['default'].computed.alias('new.coverage.isEnrolled'),
		isEnrolledOld: _ember['default'].computed('old.coverage.isEnrolled', 'isEnrolledNew', 'isEnrolledChange', function () {
			if (_ember['default'].get(this, 'old')) {
				return _ember['default'].get(this, 'old.coverage.isEnrolled');
			} else if (!_ember['default'].get(this, 'change')) {
				return false;
			} else {
				var isEnrolled = this.get('isEnrolledNew');
				var isEnrolledChange = this.get('isEnrolledChange');
				if (_ember['default'].isEmpty(isEnrolledChange)) {
					return isEnrolled;
				} else {
					return !!this.get('isEnrolledChange.lastObject');
				}
			}
		}),

		oldType: _ember['default'].computed.alias('old.personalInfo.type'),
		newType: _ember['default'].computed.alias('new.personalInfo.type'),

		oldFirstName: _ember['default'].computed.alias('old.personalInfo.firstName'),
		oldLastName: _ember['default'].computed.alias('old.personalInfo.lastName'),
		newFirstName: _ember['default'].computed.alias('new.personalInfo.firstName'),
		newLastName: _ember['default'].computed.alias('new.personalInfo.lastName'),

		oldDependentName: (0, _componentLibraryUtilsBenefitsTimelineComputed.computedForFullName)('oldFirstName', 'oldLastName'),

		newDependentName: (0, _componentLibraryUtilsBenefitsTimelineComputed.computedForFullName)('newFirstName', 'newLastName'),

		displayName: _ember['default'].computed.or('newDependentName', 'oldDependentName'),

		type: _ember['default'].computed.or('newType', 'oldType')
	});

	exports['default'] = _ember['default'].Mixin.create({
		dependents: _ember['default'].computed.alias('transaction.jsonDataOfContext.dependents'),
		dependentsChange: _ember['default'].computed.alias('transaction.jsonDataOfContextChange.dependents'),
		removedDependents: _ember['default'].computed.alias('dependentsChange.-'),
		addedDependents: _ember['default'].computed.alias('dependentsChange.+'),
		modifiedDependents: _ember['default'].computed.alias('dependentsChange.~'),

		dependentChangeModels: (0, _componentLibraryUtilsBenefitsTimelineComputed.computedArrayForChangeModel)(DependentChangeModel, 'personalInfo.id', 'dependents', 'addedDependents', 'removedDependents', 'modifiedDependents'),

		removedDependentsName: _ember['default'].computed('dependentChangeModels.[]', function () {
			var removed = this.get('dependentChangeModels').filter(function (item) {
				return !item.get('isEnrolledNew') && item.get('isEnrolledOld');
			});
			return removed.map(function (item) {
				var firstName = item.get('new.personalInfo.firstName') || item.get('old.personalInfo.firstName');
				var lastName = item.get('new.personalInfo.lastName') || item.get('old.personalInfo.lastName');
				return firstName + ' ' + lastName;
			});
		}),

		addedDependentsName: _ember['default'].computed('dependentChangeModels.[]', function () {
			var removed = this.get('dependentChangeModels').filter(function (item) {
				return item.get('isEnrolledNew') && !item.get('isEnrolledOld');
			});
			return removed.map(function (item) {
				var firstName = item.get('new.personalInfo.firstName') || item.get('old.personalInfo.firstName');
				var lastName = item.get('new.personalInfo.lastName') || item.get('old.personalInfo.lastName');
				return firstName + ' ' + lastName;
			});
		})
	});
});