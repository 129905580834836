define('z-table/components/z-table-cell', ['exports', 'ember', 'z-table/templates/components/z-table-cell'], function (exports, _ember, _zTableTemplatesComponentsZTableCell) {
	'use strict';

	var computed = _ember['default'].computed;

	exports['default'] = _ember['default'].Component.extend({
		layout: _zTableTemplatesComponentsZTableCell['default'],
		tagName: 'td',
		attributeBindings: ['colspan', 'rowspan', 'dataTest:data-test'],
		dataTest: 'z-table-cell',
		colspan: '1',
		rowspan: '1',
		fieldName: '',
		api: null,

		fieldValue: computed('api.item', 'fieldName', function () {
			return this.get('api.item.' + this.get('fieldName'));
		})
	});
});