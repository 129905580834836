define('component-library/helpers/z-hash', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	/**
 * This helper returns a hash object of the arguments passed
 * Use this like {{z-hash arg1='123' arg2='345'}}, or if it is a subexpression, like (z-hash arg1='123' arg2='345')
 */
	exports['default'] = _ember['default'].HTMLBars.makeBoundHelper(function () {
		return arguments[arguments.length - 1].hash;
	});
});