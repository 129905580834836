define('component-library/deductions/review/master/controller', ['exports', 'ember', 'component-library/data-table/column', 'component-library/data-table/cells/non-sortable-header-cell', 'component-library/data-table/cells/non-sortable-customize-header-cell', 'component-library/data-table/cells/avatarname-table-cell', 'component-library/components/deductions/deduction-discrepancyPopover-table-cell', 'component-library/utils/deductions/deductions-utils', 'component-library/utils/pretty-currency', 'component-library/utils/pretty-percent2'], function (exports, _ember, _componentLibraryDataTableColumn, _componentLibraryDataTableCellsNonSortableHeaderCell, _componentLibraryDataTableCellsNonSortableCustomizeHeaderCell, _componentLibraryDataTableCellsAvatarnameTableCell, _componentLibraryComponentsDeductionsDeductionDiscrepancyPopoverTableCell, _componentLibraryUtilsDeductionsDeductionsUtils, _componentLibraryUtilsPrettyCurrency, _componentLibraryUtilsPrettyPercent2) {
	'use strict';

	exports['default'] = _ember['default'].Controller.extend({
		deductionsLoader: _ember['default'].computed.oneWay('reviewController.deductionsLoader'),

		filteredDeductionContexts: _ember['default'].computed.oneWay('reviewController.filteredDeductionContexts'),

		allDeductionTypes: _ember['default'].computed.oneWay('reviewController.allDeductionTypes'),
		allContributionTypes: _ember['default'].computed.oneWay('reviewController.allContributionTypes'),

		displayNormalDeduction: _ember['default'].computed.oneWay('reviewController.displayNormalDeduction'),
		displayNormalContribution: _ember['default'].computed.oneWay('reviewController.displayNormalContribution'),
		isEligibleForStandaloneContributionCatchups: _ember['default'].computed.alias('reviewController.isEligibleForStandaloneContributionCatchups'),

		displayNormal: (function () {
			return this.get('displayContribution') ? this.get('displayNormalContribution') : this.get('displayNormalDeduction');
		}).property('displayContribution', 'displayNormalDeduction', 'displayNormalContribution'),

		getDeductionAmountToDisplay: function getDeductionAmountToDisplay(row) {
			var deduction = row.get(this.get('contentPath'));
			if (deduction === undefined) {
				this.get('controller').sendAction('loadAction', row.get('reviewData'), row.get('id'));
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-unknownText">-</span>');
			} else if (deduction === null || deduction.currentDeduction === undefined) {
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-notAvailableText">N/A</span>');
			} else if (deduction.doNotPush) {
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-doNotPushText">' + (deduction.isDeductionAsPercentage ? (0, _componentLibraryUtilsPrettyPercent2['default'])(deduction.currentDeduction) : (0, _componentLibraryUtilsPrettyCurrency['default'])(deduction.currentDeduction)) + '</span>');
			} else if (deduction.isCatchUp) {
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-catchUpText">' + (deduction.isDeductionAsPercentage ? (0, _componentLibraryUtilsPrettyPercent2['default'])(deduction.currentDeduction) : (0, _componentLibraryUtilsPrettyCurrency['default'])(deduction.currentDeduction)) + '</span>');
			} else if (deduction.isDeductionUnbalanced) {
				if (this.get('controller.showInternalDiscrepancy')) {
					return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-unbalancedText">' + (deduction.isDeductionAsPercentage ? (0, _componentLibraryUtilsPrettyPercent2['default'])(deduction.currentDeduction) : (0, _componentLibraryUtilsPrettyCurrency['default'])(deduction.currentDeduction)) + '</span>');
				} else {
					return new _ember['default'].Handlebars.SafeString('<span>' + (deduction.isDeductionAsPercentage ? (0, _componentLibraryUtilsPrettyPercent2['default'])(deduction.currentDeduction) : (0, _componentLibraryUtilsPrettyCurrency['default'])(deduction.currentDeduction)) + '</span>');
				}
			} else {
				return new _ember['default'].Handlebars.SafeString('<span>' + (deduction.isDeductionAsPercentage ? (0, _componentLibraryUtilsPrettyPercent2['default'])(deduction.currentDeduction) : (0, _componentLibraryUtilsPrettyCurrency['default'])(deduction.currentDeduction)) + '</span>');
			}
		},

		getContributionAmountToDisplay: function getContributionAmountToDisplay(row) {
			var deduction = row.get(this.get('contentPath'));
			if (deduction === undefined) {
				this.get('controller').sendAction('loadAction', row.get('reviewData'), row.get('id'));
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsRview-unknownText">-</span>');
			} else if (deduction === null || deduction.currentContribution === undefined) {
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-notAvailableText">N/A</span>');
			} else if (deduction.doNotPushContrib) {
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-doNotPushText">' + (deduction.isContributionAsPercentage ? (0, _componentLibraryUtilsPrettyPercent2['default'])(deduction.currentContribution) : (0, _componentLibraryUtilsPrettyCurrency['default'])(deduction.currentContribution)) + '</span>');
			} else if (deduction.isCatchUpContrib) {
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-catchUpText">' + (deduction.isContributionAsPercentage ? (0, _componentLibraryUtilsPrettyPercent2['default'])(deduction.currentContribution) : (0, _componentLibraryUtilsPrettyCurrency['default'])(deduction.currentContribution)) + '</span>');
			} else {
				return new _ember['default'].Handlebars.SafeString('<span>' + (deduction.isContributionAsPercentage ? (0, _componentLibraryUtilsPrettyPercent2['default'])(deduction.currentContribution) : (0, _componentLibraryUtilsPrettyCurrency['default'])(deduction.currentContribution)) + '</span>');
			}
		},

		getDeductionNormalAmountToDisplay: function getDeductionNormalAmountToDisplay(row) {
			var deduction = row.get(this.get('contentPath'));
			if (deduction === undefined) {
				this.get('controller').sendAction('loadAction', row.get('reviewData'), row.get('id'));
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsRview-unknownText">-</span>');
			} else if (deduction === null || deduction.normalDeduction === undefined) {
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-notAvailableText">N/A</span>');
			} else {
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-normalText">' + (deduction.isDeductionAsPercentage ? (0, _componentLibraryUtilsPrettyPercent2['default'])(deduction.normalDeduction) : (0, _componentLibraryUtilsPrettyCurrency['default'])(deduction.normalDeduction)) + '</span>');
			}
		},

		getContributionNormalAmountToDisplay: function getContributionNormalAmountToDisplay(row) {
			var deduction = row.get(this.get('contentPath'));
			if (deduction === undefined) {
				this.get('controller').sendAction('loadAction', row.get('reviewData'), row.get('id'));
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsRview-unknownText">-</span>');
			} else if (deduction === null || deduction.normalContribution === undefined) {
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-notAvailableText">N/A</span>');
			} else {
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-normalText">' + (deduction.isContributionAsPercentage ? (0, _componentLibraryUtilsPrettyPercent2['default'])(deduction.normalContribution) : (0, _componentLibraryUtilsPrettyCurrency['default'])(deduction.normalContribution)) + '</span>');
			}
		},

		// This function send action to controller for lazy loading
		getDiscrepancyToDisplay: function getDiscrepancyToDisplay(row) {
			var discrepancy = row.get(this.get('contentPath'));
			if (discrepancy === undefined) {
				this.get('controller').sendAction('loadAction', row.get('reviewData'), row.get('id'));
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-unknownText">-</span>');
			} else if (discrepancy === null) {
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-unknownText">-</span>');
			} else {
				return new _ember['default'].Handlebars.SafeString('<span class="z-deductionsReview-normalText">' + discrepancy + '</span>');
			}
		},

		columns: (function () {
			var _this = this;

			var displayContribution = this.get('displayContribution');
			var allColumns = _ember['default'].A();
			allColumns.addObject(_componentLibraryDataTableColumn['default'].create({
				savedWidth: 150,
				isSorted: true,
				sortAscending: true,
				headerCellName: 'Name',
				tableCellViewClass: _componentLibraryDataTableCellsAvatarnameTableCell['default'],
				canAutoResize: false,
				isDeletable: false,
				getSortValue: function getSortValue(row) {
					return _ember['default'].get(row, 'lastName').trim() + '-' + _ember['default'].get(row, 'firstName').trim();
				}
			}));

			if (this.get('displayNormal')) {
				allColumns.addObjects((0, _componentLibraryUtilsDeductionsDeductionsUtils.sortDeductionCodes)(this.get('allDeductionTypes')).map(function (deductionType) {
					return _componentLibraryDataTableColumn['default'].create({
						minWidth: 160,
						isSorted: false,
						headerCellName: _ember['default'].get((0, _componentLibraryUtilsDeductionsDeductionsUtils.getDeductionType)(deductionType), 'label'),
						contentPath: 'reviewData.' + deductionType,
						canAutoResize: true,
						isContribution: displayContribution,
						headerCellViewClass: _componentLibraryDataTableCellsNonSortableCustomizeHeaderCell['default'],
						getCellContent: displayContribution ? _this.get('getContributionNormalAmountToDisplay') : _this.get('getDeductionNormalAmountToDisplay'),
						sortable: false,
						isDeletable: true,
						textAlign: 'u-textRight'
					});
				}));
			} else {
				if (!displayContribution || this.get('isEligibleForStandaloneContributionCatchups')) {
					allColumns.addObject(_componentLibraryDataTableColumn['default'].create({
						minWidth: 160,
						canAutoResize: true,
						headerCellName: displayContribution ? 'Contribution Adjustments' : 'Deduction Adjustments',
						contentPath: displayContribution ? 'reviewData.discrepancyContrib' : 'reviewData.discrepancy',
						headerCellViewClass: _componentLibraryDataTableCellsNonSortableHeaderCell['default'],
						getCellContent: this.get('getDiscrepancyToDisplay'),
						sortable: false,
						isDeletable: false,
						textAlign: 'u-textRight'
					}));
				}
				allColumns.addObjects((0, _componentLibraryUtilsDeductionsDeductionsUtils.sortDeductionCodes)(this.get('allDeductionTypes')).map(function (deductionType) {
					return _componentLibraryDataTableColumn['default'].create({
						minWidth: 160,
						isSorted: false,
						headerCellName: _ember['default'].get((0, _componentLibraryUtilsDeductionsDeductionsUtils.getDeductionType)(deductionType), 'label'),
						contentPath: 'reviewData.' + deductionType,
						canAutoResize: true,
						isContribution: displayContribution,
						tableCellViewClass: _componentLibraryComponentsDeductionsDeductionDiscrepancyPopoverTableCell['default'],
						headerCellViewClass: _componentLibraryDataTableCellsNonSortableCustomizeHeaderCell['default'],
						getCellContent: displayContribution ? _this.get('getContributionAmountToDisplay') : _this.get('getDeductionAmountToDisplay'),
						sortable: false,
						isDeletable: true,
						textAlign: 'u-textRight'
					});
				}));
			}
			return allColumns;
		}).property('allDeductionTypes.[]', 'displayNormal'),

		actions: {
			loadDeductionsForEmployee: function loadDeductionsForEmployee(reviewData, employeeId) {
				this.get('deductionsLoader').loadDeductionsForRow(reviewData, employeeId);
			},
			reportDeductionsForEmployee: function reportDeductionsForEmployee(row) {
				// var employeeId = row.get('id');
				// var discrepancy = row.get('discrepancy');
			}
		}
	});
});