define('component-library/services/card-events', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {
		_isCardInEditMode: false,
		_currentCardInEditMode: null,

		isCardInEditMode: function isCardInEditMode() {
			return this.get('_isCardInEditMode');
		},

		// Use this method to reset state between settings pages
		reset: function reset() {
			this.set('_isCardInEditMode', false);
			this.set('_currentCardTitle', null);
		},

		editCard: function editCard(title) {
			this.set('_isCardInEditMode', true);
			this.set('_currentCardInEditMode', title);
		},

		getCurrentCardTitle: function getCurrentCardTitle() {
			return this.get('_currentCardInEditMode');
		}
	});
});