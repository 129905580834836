define('component-library/data-table/column-header-popover', ['exports', 'ember', 'component-library/components/promise-popover', 'component-library/mixins/shift-table-column-mixin'], function (exports, _ember, _componentLibraryComponentsPromisePopover, _componentLibraryMixinsShiftTableColumnMixin) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend(_componentLibraryMixinsShiftTableColumnMixin['default'], {
		templateName: 'data-table/column-header-popover',
		hasArrow: false,
		selectedIndex: 0,
		placement: 'bottom',
		target: _ember['default'].computed.oneWay('model.target'),
		attachment: 'top right',
		targetAttachment: 'bottom right',
		targetOffset: '10px 5px',
		constraints: [{
			to: 'scrollParent',
			pin: ['left']
		}],

		column: _ember['default'].computed.oneWay('content.column'),
		columns: _ember['default'].computed.oneWay('content.tableComponent.columns'),

		actions: {
			sortColumnAscending: function sortColumnAscending() {
				var column = this.get('column');
				var tableComponent = this.get('content.tableComponent');
				tableComponent.send('sortByColumn', column, true);
				this.send('close');
			},

			sortColumnDescending: function sortColumnDescending() {
				var column = this.get('column');
				var tableComponent = this.get('content.tableComponent');
				tableComponent.send('sortByColumn', column, false);
				this.send('close');
			},

			removeColumn: function removeColumn() {
				var column = this.get('column');
				var columns = this.get('columns');
				columns.removeObject(column);
				this.send('close');
			}
		}
	});
});