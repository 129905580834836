define('component-library/components/carousel-box', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		layoutName: 'components/carousel-box',
		width: null,
		numItems: null,
		itemsPerPage: 1,
		isOnePage: _ember['default'].computed('numItems', '_itemsPerPage', function () {
			return (this.get('numItems') || 0) <= (this.get('_itemsPerPage') || 0);
		}),
		isNumItemsEven: (function () {
			return this.get('_itemsPerPage') % 2 === 0;
		}).property('_itemsPerPage'),
		centerableIndex: (function () {
			return Math.floor(this.get('_itemsPerPage') / 2);
		}).property('_itemsPerPage'),
		itemWidth: null,
		height: null,
		activeIndex: null,
		defaultActiveIndex: null,
		showChevrons: true,
		centerChevrons: true,
		sendActivePageIndex: false,
		shouldSendActiveIndex: false,
		carouselBoxItemsClickable: true,
		caretsPosition: '10%',
		hideSides: true,
		centerIfOnePage: false,
		enableHiddenChevrons: true,

		_enableChevrons: _ember['default'].computed('showChevrons', '_pageCount', function () {
			return this.get('showChevrons') && this.get('_pageCount') > 1;
		}),

		_lastCenterableIndexLeft: (function () {
			var centerableIndex = this.get('centerableIndex');
			return this.get('isNumItemsEven') && centerableIndex > 1 ? centerableIndex - 1 : centerableIndex;
		}).property('numItems', '_itemsPerPage'),

		_lastCenterableIndexRight: (function () {
			if (this.get('_itemsPerPage') <= 2) {
				return this.get('numItems') - 1;
			}
			return this.get('numItems') - this.get('centerableIndex') - 1;
		}).property('numItems', '_itemsPerPage'),

		isBackwardChevronHidden: (function () {
			if (this.get('enableHiddenChevrons')) {
				return this.get('activeIndex') <= this.get('_lastCenterableIndexLeft');
			}
		}).property('activeIndex', '_lastCenterableIndexLeft', 'enableHiddenChevrons'),

		isForwardChevronHidden: (function () {
			if (this.get('enableHiddenChevrons')) {
				return this.get('activeIndex') >= this.get('_lastCenterableIndexRight');
			}
		}).property('activeIndex', '_lastCenterableIndexRight', 'enableHiddenChevrons'),

		_activePageIndex: (function () {
			return this.get('activeIndex') >= this.get('_lastCenterableIndexRight') ? this.get('_pageCount') - 1 : Math.floor(this.get('activeIndex') / this.get('_itemsPerPage'));
		}).property('_itemsPerPage', 'activeIndex', '_lastCenterableIndexRight', '_pageCount'),

		_updateActivePageIndex: '_updateActivePageIndex',
		_sendUpdateActivePageIndexAction: (function () {
			if (this.get('sendActivePageIndex')) {
				this.sendAction('_updateActivePageIndex', this.get('_activePageIndex'));
			}
		}).observes('_activePageIndex'),

		_updateActiveIndex: '_updateActiveIndex',
		_sendUpdateActiveIndexAction: (function () {
			if (this.get('shouldSendActiveIndex')) {
				this.sendAction('_updateActiveIndex', this.get('activeIndex'));
			}
		}).observes('activeIndex'),

		showPagination: _ember['default'].computed.gt('_pageCount', 1),

		_pageCount: (function () {
			return Math.min(Math.ceil(this.get('numItems') / this.get('_itemsPerPage')), this.get('numItems'));
		}).property('numItems', '_itemsPerPage'),

		pages: (function () {
			var pages = [];
			for (var i = 0; i < this.get('_pageCount'); i++) {
				pages.push({
					'active': i === this.get('_activePageIndex')
				});
			}
			return pages;
		}).property('_pageCount', '_activePageIndex'),

		activeIndexObserver: (function () {
			this.selectItemAtIndex(this.get('activeIndex'));
		}).observes('activeIndex'),

		selectItemAtIndex: function selectItemAtIndex(newIndex) {
			var $items = this.$('.carouselBox-items');
			var nthChild = newIndex + 1;
			var $this = $items.find('.carouselBox-item:nth-child(' + nthChild + ')');
			var prevIndex = $items.find('.active').index();

			var _lastCenterableIndexRight = this.get('_lastCenterableIndexRight');
			var _lastCenterableIndexLeft = this.get('_lastCenterableIndexLeft');

			$items.find('.active').removeClass('active');

			if (this.get('_pageCount') >= 1 || this.get('width') > this.get('_itemsPerPage') * this.get('itemWidth')) {
				var newCenteredIndex;
				if (newIndex > prevIndex) {
					newCenteredIndex = Math.min(newIndex, _lastCenterableIndexRight);
				} else {
					newCenteredIndex = Math.max(newIndex, _lastCenterableIndexLeft);
				}

				if (newCenteredIndex <= _lastCenterableIndexRight && newCenteredIndex >= _lastCenterableIndexLeft && !this.get('isOnePage')) {
					var newLeft = newCenteredIndex * -this.get('itemWidth');
					if (this.get('isNumItemsEven')) {
						newLeft += this.get('itemWidth');
					} else {
						newLeft += this.get('width') ? Math.max((this.get('width') - this.get('itemWidth')) / 2, 0) : 0;
					}
					$items.css({
						'left': newLeft + 'px'
					});
				}
			}

			$this.addClass('active');
		},

		clickItemAtIndex: function clickItemAtIndex(newIndex) {
			var nthChild = newIndex + 1;
			this.$('.carouselBox-items').find('.carouselBox-item:nth-child(' + nthChild + ')').click();
		},

		keyAttributesChanges: (function () {
			this.resizeForWidth();
			this.resetActiveIndex();
		}).observes('itemWidth', 'numItems'),

		heightAttributeChange: (function () {
			this.resizeForHeight();
		}).observes('height'),

		resetActiveIndex: function resetActiveIndex() {
			if (this.get('defaultActiveIndex')) {
				this.set('activeIndex', this.get('defaultActiveIndex'));
			} else {
				this.set('activeIndex', Math.floor((this.get('_itemsPerPage') - 1) / 2));
			}

			var self = this;
			if (this.get('carouselBoxItemsClickable')) {
				this.$('.carouselBox-items').on('click', '.carouselBox-item', function () {
					var newIndex = self.$(this).index();
					self.set('activeIndex', newIndex);
				});
			}
			_ember['default'].run.scheduleOnce('afterRender', this, function () {
				this.selectItemAtIndex(this.get('activeIndex'));
			});
		},

		_itemsPerPage: _ember['default'].computed('numItems', 'itemsPerPage', function () {
			if (this.get('numItems') <= this.get('itemsPerPage')) {
				return this.get('numItems');
			}
			return this.get('itemsPerPage');
		}),

		resizeForWidth: function resizeForWidth() {
			var _this = this;

			/**
    * Sets the width based on the number item to be displayed on a page
    */
			this.set('width', this.get('itemWidth') * this.get('_itemsPerPage'));
			this.$('.carouselBox').css({
				width: this.get('width') + 'px'
			});

			/**
    * Sets the width of the carousel-items to the one provided in the component
    */

			var $carouselBoxItems = this.$('.carouselBox-items');
			if (this.get('centerIfOnePage') && this.get('isOnePage')) {
				// should set the width as the window width and horizontally center items within it
				$carouselBoxItems.css({
					width: this.get('width') + 'px',
					display: 'flex',
					'justify-content': 'center',
					left: 0
				});
			} else {
				$carouselBoxItems.css('width', this.get('itemWidth') * this.get('numItems') + 'px');
			}

			if (this.get('_enableChevrons')) {
				(function () {
					/**
      * Sets the distance the chevrons are to the carousel
      */
					var caretsPosition = _this.get('caretsPosition');

					_ember['default'].run.scheduleOnce('afterRender', _this, function () {
						_this.$('.carouselBox-chevron-forward').css({
							right: '-' + caretsPosition
						});
						_this.$('.carouselBox-chevron-backward').css({
							left: '-' + caretsPosition
						});
					});
				})();
			}
		},

		resizeForHeight: function resizeForHeight() {
			var carouselheight = this.get('height');

			/**
    * Sets the height to the one provided in the component. This will be used to center the chevrons
    */
			if (_ember['default'].isNone(carouselheight)) {
				this.set('height', this.$().height());
			} else {
				this.$().css({ height: carouselheight });
			}

			/**
    * Vertically align the chevrons
    */
			if (this.get('showChevrons') && this.get('centerChevrons')) {
				this.$('.carouselBox-chevron').css({
					'line-height': carouselheight + 'px'
				});
			}
		},

		didInsertElement: function didInsertElement() {
			this._super();
			this.resizeForWidth();
			this.resizeForHeight();
			this.resetActiveIndex();
		},

		actions: {
			jumpForward: function jumpForward() {
				var newActiveIndex = Math.min(this.get('activeIndex') + this.get('_itemsPerPage'), this.get('_lastCenterableIndexRight'));
				if (newActiveIndex > this.get('activeIndex')) {
					this.set('activeIndex', newActiveIndex);
				}
			},

			jumpBackward: function jumpBackward() {
				var newActiveIndex = Math.max(this.get('activeIndex') - this.get('_itemsPerPage'), this.get('_lastCenterableIndexLeft'));

				if (newActiveIndex < this.get('activeIndex')) {
					this.set('activeIndex', newActiveIndex);
				}
			},

			/**
    * By Design, the page indicators behave differently from the jumpForward and jumpBackward above. They take the user to the first element of each page.
    * This prevents the user running into the case where they might be taken to the _lastCenterableIndexRight element of happen to be on the second to last page
    */
			jumpToPage: function jumpToPage(index) {
				var element = index * this.get('_itemsPerPage') + Math.floor(this.get('_itemsPerPage') / 2);
				var newActiveIndex = Math.min(element, this.get('_lastCenterableIndexRight'));
				this.set('activeIndex', newActiveIndex);
			}
		}
	});
});