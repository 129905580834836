define('component-library/utils/tags/default', ['exports'], function (exports) {
	'use strict';

	exports['default'] = function (strings /* , ...substitutions */) {
		var out = [];
		var i = 0,
		    k = -1,
		    n = strings.length - 1;
		while (i < n) {
			out[++k] = strings[i];
			out[++k] = arguments[++i];
		}
		out[++k] = strings[n];
		// As per the original Array.prototype.slice and Array.prototype.join.
		return out.join('');
	};
});