define('component-library/utils/squire-helper', ['exports', 'component-library/utils/squire-iframe-helper', 'squire'], function (exports, _componentLibraryUtilsSquireIframeHelper, _squire) {
	'use strict';

	exports['default'] = function ($iframe) {
		if ($iframe.contentWindow.editor) {
			return;
		}

		// modify iframe: add html if needed, add styles
		$iframe = (0, _componentLibraryUtilsSquireIframeHelper['default'])($iframe);

		var editor = new _squire['default']($iframe.contentWindow.document);
		$iframe.contentWindow.editor = editor;

		var testStyling = function testStyling(style) {
			return new RegExp('>' + style + '\\b').test(editor.getPath()) | editor.hasFormat(style);
		};

		editor.setFontFamily = function (fontFaces) {
			return editor.setFontFace(fontFaces.split(','));
		};

		editor.toggleBold = function () {
			return testStyling('B') ? editor.removeBold() : editor.bold();
		};
		editor.toggleItalic = function () {
			return testStyling('I') ? editor.removeItalic() : editor.italic();
		};
		editor.toggleUnderline = function () {
			return testStyling('U') ? editor.removeUnderline() : editor.underline();
		};

		editor.makeUnorderedStyleList = function (style) {
			if (testStyling('UL')) {
				editor.makeOrderedList();
			}
			editor.setConfig({ tagAttributes: { ul: { type: style } } });
			editor.makeUnorderedList();
		};

		editor.makeOrderedStyleList = function (style) {
			if (testStyling('OL')) {
				editor.makeUnorderedList();
			}
			editor.setConfig({ tagAttributes: { ol: { type: style } } });
			editor.makeOrderedList();
		};

		editor.toggleUnorderedList = function (style) {
			return editor.makeUnorderedStyleList(style);
		};
		editor.toggleOrderedList = function (style) {
			return editor.makeOrderedStyleList(style);
		};

		return editor;
	};
});