define('component-library/components/radio-block', ['exports', 'ember', 'component-library/templates/components/radio-block'], function (exports, _ember, _componentLibraryTemplatesComponentsRadioBlock) {
	'use strict';

	/* Radio-Block
  *
  * This component should be used within a Radio-Block-Group. It expands to
  * the complete width of its parent div-- be sure to use the grid, Luke.
  */
	exports['default'] = _ember['default'].Component.extend({
		template: _componentLibraryTemplatesComponentsRadioBlock['default'],
		classNames: ['js-glue-radio-block-wrapper', 'radio-block-wrapper', 'grid-block', 'u-justifyCenter'],
		classNameBindings: ['_selectedClass', 'action:is-action-driven'],
		value: null,
		name: null,
		selectedValue: null,
		_isSelected: false,
		isDisabled: false,
		action: null,

		didInsertElement: function didInsertElement() {
			this._super();
			this._selectedValueChanged();
		},

		_selectedClass: (function () {
			return this.get('_isSelected') ? 'is-selected' : '';
		}).property('_isSelected'),

		_selectedValueChanged: (function () {
			var selectedValue = this.get('selectedValue');
			if (selectedValue != null && this.get('value') == selectedValue) {
				this.set('_isSelected', true);
			} else {
				this.set('_isSelected', false);
			}
		}).observes('selectedValue'),

		click: function click() {
			if (!this.get('isDisabled')) {
				this.set('_isSelected', true);
				this.set('selectedValue', this.get('value'));

				if (this.get('action')) {
					this.sendAction();
				}
			}
		}
	});
});