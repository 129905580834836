define("component-library/components/banner-notification", ["exports", "ember"], function (exports, _ember) {
	"use strict";

	var BANNER_MODES = {
		SUCCESS: "success",
		WARNING: "warning",
		INFO: "info",
		ERROR: "error"
	};

	exports.BANNER_MODES = BANNER_MODES;

	var MODE_ICONS_ZMDI = {
		success: 'check-circle',
		warning: 'alert-triangle',
		info: 'info',
		error: 'close-circle'
	};

	var TRANSITION_EASING = 250;

	exports["default"] = _ember["default"].Component.extend({
		classNames: ['z-bannerNotification', 'js-glue-z-bannerNotification'],
		classNameBindings: ['centered:u-textCenter', '_modeClass'],
		contentClasses: null,
		mode: BANNER_MODES.SUCCESS,
		closeable: false,
		centered: false,
		visible: true,

		showAsCaption: true,

		didInsertElement: function didInsertElement() {
			if (!this.get('visible')) {
				this.$().hide();
			}
		},

		_modeClass: _ember["default"].computed('mode', function () {
			return "z-bannerNotification-" + this.get('mode');
		}),

		iconClass: (function () {
			return MODE_ICONS_ZMDI[this.get('mode')];
		}).property('mode'),

		visibleObserver: (function () {
			if (this.get('visible')) {
				this.$().slideDown(TRANSITION_EASING);
			} else {
				this.$().slideUp(TRANSITION_EASING);
			}
		}).observes('visible'),

		actions: {
			close: function close() {
				this.set('visible', false);
			}
		}
	});
});