define('ember-cli-bundle-loader/routes/-lazy-loader', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var retried = false;
  exports['default'] = _ember['default'].Route.extend({
    lazyLoader: _ember['default'].inject.service(),
    beforeModel: function beforeModel(transition) {
      if (retried) {
        // Shortcircuit this in case the download fails.
        retried = !retried;
        return;
      }
      retried = !retried;
      var loadPromise = transition.intent.url ? this.get('lazyLoader').loadBundleForUrl(transition.intent.url) : this.get('lazyLoader').loadBundleForRouteName(transition.intent.name);
      return loadPromise.then(function () {
        transition.abort();
        transition.retry();
        retried = false; // reset this so we can transition to another lazy loaded section
        return {};
      });
    }
  });
});