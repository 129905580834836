define('component-library/components/typeahead-search', ['exports', 'ember', 'component-library/utils/globals', 'component-library/templates/components/typeahead/default-empty', 'component-library/templates/components/typeahead/default-pending', 'component-library/templates/components/typeahead/default-header', 'component-library/templates/components/typeahead/default-footer', 'component-library/templates/components/typeahead/default-suggestion'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryTemplatesComponentsTypeaheadDefaultEmpty, _componentLibraryTemplatesComponentsTypeaheadDefaultPending, _componentLibraryTemplatesComponentsTypeaheadDefaultHeader, _componentLibraryTemplatesComponentsTypeaheadDefaultFooter, _componentLibraryTemplatesComponentsTypeaheadDefaultSuggestion) {
	'use strict';

	exports['default'] = _ember['default'].TextField.extend({
		tagName: 'input',
		attributeBindings: ['placeholder'],
		classNames: ['tt-search-input', 'z-input', 'typeahead'],
		classNameBindings: ['hasError', 'showSearchIcon', 'showCloseIcon'],
		customEvents: [],
		hasError: false,
		url: null,
		cursorSuggestion: undefined,

		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super();

			// is the dataSources overriden
			var dataSources = this.get('dataSources');
			if (!dataSources) {
				// do a lot of the boilerplate for them:
				var bloodhoundOptions = _ember['default'].$.extend({
					datumTokenizer: _componentLibraryUtilsGlobals.Bloodhound.tokenizers.whitespace,
					queryTokenizer: _componentLibraryUtilsGlobals.Bloodhound.tokenizers.whitespace
				}, this.getBloodhoundSource(), this.get('bloodhoundOptions'));

				var data = new _componentLibraryUtilsGlobals.Bloodhound(bloodhoundOptions);

				dataSources = this.generateDataSource(data, _ember['default'].$.extend({
					limit: this.get('limit'),
					displayKey: this.get('displayKey')
				}, this.get('dataSource')));
			}

			var typeaheadOptions = _ember['default'].$.extend({
				highlight: this.get('highlight') || true
			}, this.get('typeaheadOptions'));

			var args = [typeaheadOptions].concat(dataSources);
			_ember['default'].$(this.element).typeahead.apply(_ember['default'].$(this.element), args);

			this.get('customEvents').forEach(function (e) {
				_this.$().bind('typeahead:' + e.eventName, e.eventFunction);
			});

			// forward all the typeahead jQuery events into ember events
			// (mainly so those that extend this can add additional handling or override the default handling)
			this.$().on(['typeahead:active', 'typeahead:idle', 'typeahead:open', 'typeahead:close', 'typeahead:change', 'typeahead:render', 'typeahead:select', 'typeahead:autocomplete', 'typeahead:cursorchange', 'typeahead:asyncrequest', 'typeahead:asynccancel', 'typeahead:asyncreceive'].join(' '), function (evt) {
				for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
					args[_key - 1] = arguments[_key];
				}

				return _this.send.apply(_this, [evt.type].concat(args));
			});
			// TODO icon for submit as well:
			this.$().on('keyup', function (evt) {
				if (evt.keyCode === 13) {
					_this.send('typeahead:submit', _this.$().typeahead('val'));
				}
			});
			// open icon
			if (this.get('showSearchIcon')) {
				var $searchIcon = _ember['default'].$('<i class="zmdi zmdi-search zmdi-hc-fw tt-searchIcon"></i>');
				this.$().parent().append($searchIcon);
			}
			// close icon
			if (this.get('showCloseIcon')) {
				var $clearQuery = _ember['default'].$('<i class="zmdi zmdi-close zmdi-hc-fw tt-clearQuery"></i>');
				this.$().parent().append($clearQuery);
				var toggleClearIcon = function toggleClearIcon(evt) {
					var query = _this.$().typeahead('val');
					$clearQuery.toggle(!!query);
				};
				$clearQuery.on('click', function (evt) {
					_this.clearQuery();
					toggleClearIcon();
				});
				toggleClearIcon();
				this.$().on('keyup', toggleClearIcon);
			}
		},

		clearQuery: function clearQuery() {
			this.$().typeahead('val', "");
		},

		willDestroyElement: function willDestroyElement() {
			this.$().unbind();
			this.$('.typeahead').typeahead('destroy');
			this._super();
		},

		generateDataSource: function generateDataSource(data, options) {
			var _this2 = this;

			var emptyTemplate = options.emptyTemplate || this.get('emptyTemplate') || _componentLibraryTemplatesComponentsTypeaheadDefaultEmpty['default'],
			    pendingTemplate = options.pendingTemplate || this.get('pendingTemplate') || _componentLibraryTemplatesComponentsTypeaheadDefaultPending['default'],
			    headerTemplate = options.headerTemplate || this.get('headerTemplate') || _componentLibraryTemplatesComponentsTypeaheadDefaultHeader['default'],
			    footerTemplate = options.footerTemplate || this.get('footerTemplate') || _componentLibraryTemplatesComponentsTypeaheadDefaultFooter['default'],
			    suggestionTemplate = options.suggestionTemplate || this.get('suggestionTemplate') || _componentLibraryTemplatesComponentsTypeaheadDefaultSuggestion['default'];

			return _ember['default'].$.extend({
				name: options.name || 'data',
				source: data,
				display: options.displayKey,
				limit: options.limit || 5,
				async: this.get('async') || false,
				templates: {
					notFound: function notFound(data) {
						return _this2.generateElement(data, emptyTemplate);
					},
					pending: function pending(data) {
						return _this2.generateElement(data, pendingTemplate);
					},
					header: function header(data) {
						return _this2.generateElement(data, headerTemplate);
					},
					footer: function footer(data) {
						return _this2.generateElement(data, footerTemplate);
					},
					suggestion: function suggestion(data) {
						return _this2.generateElement(data, suggestionTemplate);
					}
				}
			}, options);
		},

		generateElement: function generateElement(data, template) {
			var component = _ember['default'].Component.create({
				model: data,
				layout: template,
				container: this.container
			}).createElement();
			return component.element;
		},

		getBloodhoundSource: function getBloodhoundSource() {
			var remote = this.get('remote'),
			    local = this.get('local'),
			    prefetch = this.get('prefetch');

			if (remote) {
				return {
					remote: remote
				};
			} else if (local) {
				return {
					local: local
				};
			} else if (prefetch) {
				return {
					prefetch: prefetch
				};
			} else {
				return {
					remote: {
						url: this.get('url'),
						wildcard: this.get('wildcard') || '%QUERY',
						transform: this.get('transform'),
						rateLimitBy: this.get('rateLimitBy') || 'debounce',
						rateLimitWait: this.get('rateLimitWait') || 300
					}
				};
			}
		},
		actions: {
			// TODO: this doesn't work but seems to be used in the console search?
			keyUp: function keyUp(e) {
				return this.get('keyUp')(e);
			},
			'typeahead:select': function typeaheadSelect(suggestion) {
				if (this.get('onSelect')) {
					return this.get('onSelect')(suggestion);
				}
			},
			"typeahead:autocomplete": function typeaheadAutocomplete(suggestion) {
				if (this.get('onSelect')) {
					return this.get('onSelect')(suggestion);
				}
			},
			'typeahead:active': function typeaheadActive() {},
			'typeahead:idle': function typeaheadIdle() {},
			'typeahead:open': function typeaheadOpen() {},
			'typeahead:close': function typeaheadClose() {},
			'typeahead:change': function typeaheadChange(ev) {},
			'typeahead:render': function typeaheadRender(suggestions, async, dataset) {},
			'typeahead:submit': function typeaheadSubmit(query) {
				if (!this.get('onSubmit') || !query) {
					return;
				}
				if (this.get('cursorSuggestion')) {
					return this.set('cursorSuggestion', undefined);
				}

				this.get('onSubmit')(query);
			},
			'typeahead:cursorchange': function typeaheadCursorchange(suggestion) {
				this.set('cursorSuggestion', suggestion);
			},
			'typeahead:asyncrequest': function typeaheadAsyncrequest(query, dataset) {},
			'typeahead:asynccancel': function typeaheadAsynccancel(query, dataset) {},
			'typeahead:asyncreceive': function typeaheadAsyncreceive(query, dataset) {}
		}
	});
});