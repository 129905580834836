define('component-library/components/d-z-helpbox-conditional-content', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		dataConditionalContentId: null,
		classNames: ['z-helpbox-conditional-content'],

		didInsertElement: function didInsertElement() {
			this.$().attr('data-conditional-content-id', this.get('dataConditionalContentId'));
			this.$().attr('data-conditional-helpbox-content', '');
			this.$().hide();
		},

		willDestroyElement: function willDestroyElement() {}
	});
});