define('component-library/mixins/zen-select-containers', ['exports', 'ember', 'component-library/utils/zen-multi-select-attr'], function (exports, _ember, _componentLibraryUtilsZenMultiSelectAttr) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create((function () {
		var attributes = {};
		_componentLibraryUtilsZenMultiSelectAttr['default'].forEach(function (pair) {
			['all', 'selected', 'added', 'removed'].forEach(function (descriptor) {
				attributes[descriptor + pair[1]] = _ember['default'].A();
			});
		});
		return attributes;
	})());
});