define('z-inputs/components/z-text-field-textarea', ['exports', 'ember', 'z-inputs/components/z-text-field-base', 'z-inputs/templates/components/z-text-field-textarea'], function (exports, _ember, _zInputsComponentsZTextFieldBase, _zInputsTemplatesComponentsZTextFieldTextarea) {
	'use strict';

	exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend({
		layout: _zInputsTemplatesComponentsZTextFieldTextarea['default'],
		classNames: 'z-textField--textarea',
		type: 'text-area',
		rows: 2,
		syncHeight: true,
		tooltipText: null,

		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super.apply(this, arguments);
			this.valueDidChange();
			this.$('.z-textField-textarea').keyup(function () {
				if (_this.attrs.changed) {
					_this.attrs.changed(_this.get('value'));
				}
			});
		},
		willDestroyElement: function willDestroyElement() {
			this.$('.z-textField-textarea').off();
		},

		valueDidChange: (function () {
			if (this.get('syncHeight') && !this.get('isStatic')) {
				_ember['default'].run.next(this, this.syncTextAreaHeight);
			}
		}).observes('value'),

		syncTextAreaHeight: function syncTextAreaHeight() {
			var $textArea = this.$('.js-z-textField-textarea');
			var $shadowTextArea = this.$('.js-z-textField-shadowTextarea');
			// Add 2 for borders, otherwise it shows an annoying scrollbar.
			var newHeight = $shadowTextArea.get(0).scrollHeight + 2;
			$textArea.outerHeight(newHeight);
		},

		actions: {
			keyDown: function keyDown(val, $e) {
				// stop propagation of the Enter key
				if ($e.keyCode === 13) {
					$e.stopPropagation();
				}
			}
		}
	});
});