define('component-library/components/tabbed-modal/tabbed-modal', ['exports', 'ember', 'zen', 'component-library/components/tabbed-modal/utils'], function (exports, _ember, _zen, _componentLibraryComponentsTabbedModalUtils) {
	'use strict';

	var _TAB_TEXT, _TAB_TITLES;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
		} else {
			obj[key] = value;
		}return obj;
	}

	var TABS = {
		TAB_1: 'tab 1',
		TAB_2: 'tab_2',
		TAB_3: 'tab_3'
	};

	var TAB_TEXT = (_TAB_TEXT = {}, _defineProperty(_TAB_TEXT, TABS.TAB_1, 'Tab 1'), _defineProperty(_TAB_TEXT, TABS.TAB_2, 'Tab 2'), _defineProperty(_TAB_TEXT, TABS.TAB_3, 'Tab 3'), _TAB_TEXT);

	var TAB_TITLES = (_TAB_TITLES = {}, _defineProperty(_TAB_TITLES, TABS.TAB_1, 'Tab 1 title'), _defineProperty(_TAB_TITLES, TABS.TAB_2, 'Tab 2 title'), _defineProperty(_TAB_TITLES, TABS.TAB_3, 'Tab 3 title'), _TAB_TITLES);

	var TABBED_MODAL_MODES_ARR = Object.keys(_componentLibraryComponentsTabbedModalUtils.TABBED_MODAL_MODES).map(function (k) {
		return _componentLibraryComponentsTabbedModalUtils.TABBED_MODAL_MODES[k];
	});

	exports['default'] = _ember['default'].Component.extend(_zen['default']._BlobHelperMixin, _componentLibraryComponentsTabbedModalUtils.TabbedModalModeFields, {
		tagName: '',
		store: _ember['default'].inject.service(),

		init: function init() {
			this._super.apply(this, arguments);

			if (!TABBED_MODAL_MODES_ARR.includes(this.get('modalMode'))) {
				throw new Error('tabbed-modal: modalMode ' + this.get('modalMode') + ' is not supported');
			}

			if (this.get('isSequenceMode')) {
				// set new model instances
			}
		},

		size: 'medium',

		// Provide your own TABS and TAB_TEXT
		TABS: TABS,
		TAB_TEXT: TAB_TEXT,
		TAB_TITLES: TAB_TITLES,

		/************************************* Common tabbed modal fields *************************************/
		tabs: _ember['default'].computed('TABS', function () {
			var isSequenceMode = this.get('isSequenceMode');
			var ALL_TABS = this.get('TABS');
			var _tabs = this.get('_tabs');
			var tabs = [];
			if (!_tabs || !_tabs.length) {
				tabs = Object.keys(ALL_TABS).map(function (k, i) {
					return _ember['default'].Object.create({
						id: ALL_TABS[k],
						index: i,
						number: i + 1,
						isSelected: i === 0,
						isTabDataValid: false,
						nextClicked: false,
						lastClickTime: null,
						isAccessible: !isSequenceMode,
						glueClassname: 'js-glue-tabbedModal-tab-' + ALL_TABS[k]
					});
				});
				tabs[0].set('isAccessible', true);
			} else {
				tabs = Object.keys(ALL_TABS).map(function (k) {
					return _tabs.find(function (tab) {
						return ALL_TABS[k] === tab.get('id');
					}) || _ember['default'].Object.create({
						id: ALL_TABS[k],
						isSelected: false,
						isTabDataValid: false,
						nextClicked: false,
						lastClickTime: null,
						isAccessible: !isSequenceMode,
						glueClassname: 'js-glue-tabbedModal-tab-' + ALL_TABS[k]
					});
				});
				tabs.forEach(function (tab, i) {
					tab.setProperties({ index: i, number: i + 1 });
				});
			}
			this.set("_tabs", tabs);
			return tabs;
		}),

		tabsMap: _ember['default'].computed('tabs', 'tabs.@each.{id,index,number,isSelected,isTabDataValid,nextClicked,lastClickTime,isAccessible,glueClassname}', function () {
			var tabsMap = {};
			this.get('tabs').forEach(function (t) {
				tabsMap[t.get('id')] = t;
			});
			return _ember['default'].Object.create(tabsMap);
		}),
		tabsNumberMap: _ember['default'].computed('tabs', 'tabs.@each.number', function () {
			var tabsMap = {};
			this.get('tabs').forEach(function (t) {
				tabsMap[t.get('id')] = t.get('number');
			});
			return _ember['default'].Object.create(tabsMap);
		}),

		currentTab: _ember['default'].computed('tabs.@each.isSelected', function () {
			return this.get('tabs').findBy('isSelected', true);
		}),
		currentTabId: _ember['default'].computed('currentTab', function () {
			return this.get('currentTab.id');
		}),
		currentTabNumber: _ember['default'].computed('tabs.@each.isSelected', function () {
			return this.get('tabs').findBy('isSelected', true).get('number');
		}),

		modalApi: _ember['default'].computed('tabs.length', 'tabsNextClickedMap', 'tabs.[]', 'tabs.@each.{id,index,number,isSelected,isTabDataValid,nextClicked,lastClickTime,isAccessible,glueClassname}', 'currentTabId', 'modalMode', 'isSequenceMode', 'size', function () {
			var _this = this;

			return _ember['default'].Object.create({
				total: this.get('tabs.length'),
				tabs: this.get('tabs'),
				TAB_TEXT: this.get('TAB_TEXT'),
				TAB_TITLES: this.get('TAB_TITLES'),
				modalSize: this.get('size'),
				modalSuperScript: this.get('modalSuperScript'),
				isSequenceMode: this.get('isSequenceMode'),
				modalMode: this.get('modalMode'),
				currentTabId: this.get('currentTabId'),
				onClickSidebarTab: function onClickSidebarTab() {
					return _this.onClickSidebarTab.apply(_this, arguments);
				},
				closeModal: function closeModal() {
					return _this.closeModal.apply(_this, arguments);
				}
			});
		}),

		tabApi: _ember['default'].computed('currentTabId', 'modalMode', 'currentTab.number', 'tabs.length', 'tabs', 'tabs.[]', 'tabs.@each.{nextClicked,lastClickTime}', function () {
			var _this2 = this;

			return _ember['default'].Object.create({
				modalMode: this.get('modalMode'),
				currentTabId: this.get('currentTabId'),
				number: this.get('currentTab.number'),
				total: this.get('tabs.length'),
				setTabValidationState: function setTabValidationState() {
					return _this2.setTabValidationState.apply(_this2, arguments);
				},
				nextTab: function nextTab() {
					return _this2.nextTab.apply(_this2, arguments);
				},
				closeModal: function closeModal() {
					return _this2.closeModal.apply(_this2, arguments);
				},
				tabsData: this.get('tabs').map(function (t) {
					return _ember['default'].Object.create({
						id: t.get('id'),
						nextClicked: t.get('nextClicked'),
						lastClickTime: t.get('lastClickTime')
					});
				})
			});
		}),

		lastTabId: _ember['default'].computed('TABS', 'TABS.[]', function () {
			return this.get('tabs.lastObject.id');
		}),

		tabsArr: _ember['default'].computed('TABS', 'TABS.[]', function () {
			var _this3 = this;

			return Object.keys(this.get('TABS')).map(function (k) {
				return _this3.get('TABS')[k];
			});
		}),

		// helpers
		getTabById: function getTabById(tabId) {
			return this.get('tabs').findBy('id', tabId);
		},
		getTabByIndex: function getTabByIndex(tabIndex) {
			return this.get('tabs')[tabIndex];
		},

		selectTab: function selectTab(tabId) {
			this.get('tabs').forEach(function (tab) {
				tab.set('isSelected', tab.get('id') === tabId);
			});
		},
		checkIfTabDataValid: function checkIfTabDataValid(tabId) {
			var tab = this.getTabById(tabId);
			tab.set('lastClickTime', Date.now());
			return !!tab.get('isTabDataValid');
		},

		rollbackOnDestroy: _ember['default'].on('willDestroyElement', function () {
			// .deleteRecord() all temporary model instances
		}),

		onFinish: function onFinish() {
			return this.attrs.onCloseAction();
		},

		onClickSidebarTab: function onClickSidebarTab(clickedTabId) {
			var currentTabId = this.get('currentTabId');
			if (clickedTabId === currentTabId) {
				return;
			}
			var tab = this.getTabById(clickedTabId);
			if (!tab.get('isAccessible')) {
				return;
			}
			// check current tab data validation state if trying to switch to the next tab
			if (this.get('isSequenceMode') && this.get('tabsArr').indexOf(clickedTabId) > this.get('tabsArr').indexOf(currentTabId) && !this.checkIfTabDataValid(currentTabId)) {
				return;
			}
			// in edit mode don't allow to switch tabs if current tab is invalid
			if (this.get('isEditMode') && !this.checkIfTabDataValid(currentTabId)) {
				return;
			}

			if (this.get('isSequenceMode') && currentTabId === this.get('lastTabId')) {
				this.getTabById(currentTabId).set('nextClicked', true);
			}
			this.selectTab(clickedTabId);
		},

		setTabValidationState: function setTabValidationState(tabId, isTabDataValid) {
			var tab = this.getTabById(tabId);
			tab.set('isTabDataValid', !!isTabDataValid);
		},

		nextTab: function nextTab() {
			var currentTabId = this.get('currentTabId');
			var tab = this.getTabById(currentTabId);
			tab.set('nextClicked', true);
			if (!this.checkIfTabDataValid(currentTabId)) {
				return _ember['default'].RSVP.Promise.resolve();
			}

			var nextTab = this.getTabByIndex(tab.get('index') + 1);
			if (nextTab) {
				nextTab.set('isAccessible', true);
			}

			if (currentTabId === this.get('lastTabId')) {
				// call onSave if next button is cliked on the last tab
				return _ember['default'].RSVP.Promise.resolve(this.onFinish());
			} else {
				this.selectTab(this.get('tabsArr')[this.get('tabsArr').indexOf(currentTabId) + 1]);
				return _ember['default'].RSVP.Promise.resolve();
			}
		},

		closeModal: function closeModal() {
			this.attrs.onCloseAction();
		},

		actions: {

			closeModal: function closeModal() {
				this.closeModal();
			}
		}
	});
});