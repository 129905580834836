define('component-library/simple-wizard/controller', ['exports', 'ember', 'component-library/simple-wizard/step/state'], function (exports, _ember, _componentLibrarySimpleWizardStepState) {
	'use strict';

	exports['default'] = _ember['default'].Controller.extend({
		currentActiveStep: 0,
		activeStepKey: "",
		simpleWizardFlow: [],

		hasFullWidth: _ember['default'].computed('simpleWizardFlow.currentActiveStep', function () {
			var simpleWizardFlow = this.get('simpleWizardFlow');
			var steps = simpleWizardFlow.get('steps');
			var currentStep = simpleWizardFlow.get('currentActiveStep') || simpleWizardFlow.get('currentStepIndex');
			return !_ember['default'].isEmpty(currentStep) ? steps.objectAt(currentStep).get('isFullWidth') : false;
		}),

		newSimpleWizardFlowState: function newSimpleWizardFlowState() {
			return _componentLibrarySimpleWizardStepState['default'].create();
		},

		transitionToNextIncompleteStep: function transitionToNextIncompleteStep(currentRoute) {
			this._transitionToStep(currentRoute, 'next', true);
		},

		transitionToPreviousIncompleteStep: function transitionToPreviousIncompleteStep(currentRoute) {
			this._transitionToStep(currentRoute, 'previous', true);
		},

		transitionToNextStep: function transitionToNextStep(currentRoute) {
			this._transitionToStep(currentRoute, 'next', false);
		},

		transitionToPreviousStep: function transitionToPreviousStep(currentRoute) {
			this._transitionToStep(currentRoute, 'previous', false);
		},

		transitionToStepRoute: function transitionToStepRoute(routeName) {
			this.transitionToRoute(routeName);
		},

		_transitionToStep: function _transitionToStep(currentRoute, direction, checkCompletion) {

			var simpleWizardFlow = this.get('simpleWizardFlow');
			var routes = simpleWizardFlow.steps;
			var skipCompletionCheck = !checkCompletion;
			var noValidNextStepFound = true;
			var moveBackwards = direction === 'previous';

			var endPoint = routes.length - 1;
			var nextStepIndex = simpleWizardFlow.get('currentActiveStep') + 1;
			if (moveBackwards) {
				endPoint = 0;
				nextStepIndex = simpleWizardFlow.get('currentActiveStep') - 1;
			}

			if (simpleWizardFlow.get('currentActiveStep') === endPoint || nextStepIndex < 0) {
				nextStepIndex = 0;
			} else {
				var checkPreviousSteps = true;
				for (var i = nextStepIndex; moveBackwards ? i > 0 : i < routes.length; moveBackwards ? i-- : i++) {
					var thisShouldBeTheNextStep = (skipCompletionCheck || !routes[i].isComplete) && routes[i].get('_isEnabled');
					if (thisShouldBeTheNextStep) {
						nextStepIndex = i;
						noValidNextStepFound = false;
						checkPreviousSteps = false;
						break;
					} else if (checkPreviousSteps && i === routes.length - 1) {
						// We have to check previous steps to make sure none of them are incomplete.
						i = 0;
						skipCompletionCheck = false;
						checkPreviousSteps = false;
					}
				}

				// Default back to step one if there is no enabled next/previous step.
				// TODO (mwei): make this review page
				if (noValidNextStepFound) {
					nextStepIndex = 0;
				}
			}

			simpleWizardFlow.set('currentStepIndex', nextStepIndex);
			simpleWizardFlow.set('currentActiveStep', nextStepIndex);
			simpleWizardFlow.set('activeStepKey', routes[nextStepIndex].routeName);
			var step = routes[nextStepIndex];

			var model = step.model;
			var opts = step.opts;
			var args = [step.routeName];

			if (model) {
				args.push(model);
			}
			if (opts) {
				args.push(opts);
			}
			this.transitionToRoute.apply(this, args);
		},

		_setActiveRoute: function _setActiveRoute(activeRoute) {
			var simpleWizardFlow = this.get('simpleWizardFlow');
			simpleWizardFlow.set('activeStepKey', activeRoute);

			if (simpleWizardFlow.get('steps')) {
				simpleWizardFlow.get('steps').forEach(function (flowStep, index) {
					var computedRouteName = flowStep.routeName;
					if (flowStep.model) {
						computedRouteName = computedRouteName + String(flowStep.model);
					}
					if (computedRouteName === simpleWizardFlow.get('activeStepKey')) {
						simpleWizardFlow.set('currentStepIndex', index);
						simpleWizardFlow.set('currentActiveStep', index);
						flowStep.set('isActive', true);
					} else {
						flowStep.set('isActive', false);
					}
				});
			}
		},

		_updateSimpleWizardFlow: function _updateSimpleWizardFlow() {
			this.set('simpleWizardFlow', this.newSimpleWizardFlow());
		},

		actions: {
			_handleWizardAction: function _handleWizardAction(method) {
				this[method].apply(this, Array.prototype.slice.call(arguments).slice(1));
			}
		}
	});
});