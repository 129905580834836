define("component-library/templates/components/benefits/timeline/transaction-list", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.10-zenefits.3+18063622",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 2
            },
            "end": {
              "line": 15,
              "column": 2
            }
          },
          "moduleName": "modules/component-library/templates/components/benefits/timeline/transaction-list.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("			");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "benefits/timeline/transaction-item", [], ["transaction", ["subexpr", "@mut", [["get", "entry", ["loc", [null, [8, 16], [8, 21]]]]], [], []], "click", ["subexpr", "action", [["get", "viewTransactionDetailWrapper", ["loc", [null, [9, 18], [9, 46]]]], ["get", "entry.content", ["loc", [null, [9, 47], [9, 60]]]]], [], ["loc", [null, [9, 10], [9, 61]]]], "selected", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [10, 13], [10, 21]]]]], [], []], "hasBenefitsTransactionWritePermission", false, "top", ["subexpr", "@mut", [["get", "entry.top", ["loc", [null, [12, 8], [12, 17]]]]], [], []], "height", ["subexpr", "@mut", [["get", "rowHeight", ["loc", [null, [13, 11], [13, 20]]]]], [], []]], ["loc", [null, [7, 3], [14, 5]]]]],
        locals: ["entry"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.10-zenefits.3+18063622",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 1
            },
            "end": {
              "line": 23,
              "column": 1
            }
          },
          "moduleName": "modules/component-library/templates/components/benefits/timeline/transaction-list.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("		");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "grid-block align-center align-verticalCenter z-loadingSpinnerContainer");
          var el2 = dom.createTextNode("\n			");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "z-loadingMessage u-topFlush");
          var el3 = dom.createTextNode("\n				");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
          return morphs;
        },
        statements: [["content", "emptyDataText", ["loc", [null, [20, 4], [20, 21]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.10-zenefits.3+18063622",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 1
            },
            "end": {
              "line": 26,
              "column": 1
            }
          },
          "moduleName": "modules/component-library/templates/components/benefits/timeline/transaction-list.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("		");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "partial", ["product-loading"], [], ["loc", [null, [25, 2], [25, 31]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.10-zenefits.3+18063622",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 0
          }
        },
        "moduleName": "modules/component-library/templates/components/benefits/timeline/transaction-list.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "js-benefitsTimeline-scrolling");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'style');
        morphs[2] = dom.createAttrMorph(element1, 'style');
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        morphs[4] = dom.createMorphAt(element0, 3, 3);
        morphs[5] = dom.createMorphAt(element0, 4, 4);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["u-positionRelative u-fillHorizontal js-benefitsTimeline-container\n\t", ["subexpr", "if", [["get", "isHeightFixed", ["loc", [null, [2, 6], [2, 19]]]], "", "u-fillVertical"], [], ["loc", [null, [2, 1], [2, 41]]]], "\n\t", ["subexpr", "if", [["get", "disableScrolling", ["loc", [null, [3, 6], [3, 22]]]], "", "z-benefitsTimeline-overflow"], [], ["loc", [null, [3, 1], [3, 57]]]]]]], ["attribute", "style", ["get", "viewStyle", ["loc", [null, [4, 9], [4, 18]]]]], ["attribute", "style", ["get", "scrollingStyle", ["loc", [null, [5, 52], [5, 66]]]]], ["block", "each", [["get", "activeDisplayEntries", ["loc", [null, [6, 10], [6, 30]]]]], [], 0, null, ["loc", [null, [6, 2], [15, 11]]]], ["block", "if", [["subexpr", "and", [["get", "isDataSettled", ["loc", [null, [17, 12], [17, 25]]]], ["get", "isDataEmpty", ["loc", [null, [17, 26], [17, 37]]]]], [], ["loc", [null, [17, 7], [17, 38]]]]], [], 1, null, ["loc", [null, [17, 1], [23, 8]]]], ["block", "if", [["get", "isDataLoading", ["loc", [null, [24, 7], [24, 20]]]]], [], 2, null, ["loc", [null, [24, 1], [26, 8]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});