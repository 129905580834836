define('component-library/constants/z-payroll/z-payroll-payrun-states', ['exports'], function (exports) {
				'use strict';

				var PAYRUN_STATES = {
								// Please keep up-to-date with payroll/zpayroll/zenefits_payroll/enums/common.py
								UNAPPROVABLE_PREVIEW: 'unapprovable_preview',
								DRAFT: 'draft',
								APPROVED: 'approved',
								PAYING: 'paying',
								PAID: 'paid',
								CLOSED: 'closed',
								TRANSACTIONS_FAILED: 'transactions_failed',
								CANCELLED: 'cancelled'
				};
				exports.PAYRUN_STATES = PAYRUN_STATES;
});