define('component-library/components/show-help', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		templateName: 'components/show-help',
		linkTo: '',
		linkName: null,
		tagName: 'span',
		helpArticleUrl: _ember['default'].computed('linkTo', function () {
			return 'http://help.zenefits.com/' + this.get('linkTo');
		})
	});
});