define('component-library/components/z-helptext-popover', ['exports', 'ember', 'ember-tooltips/components/popover-on-element', 'component-library/templates/components/z-helptext-popover'], function (exports, _ember, _emberTooltipsComponentsPopoverOnElement, _componentLibraryTemplatesComponentsZHelptextPopover) {
	'use strict';

	var ALERT_TYPES = {
		NONE: 'none',
		INFO: 'info',
		WARNING: 'warning'
	};

	var SPACING_BEFORE_POPOVER = "30"; // accommodate card-row padding

	exports['default'] = _emberTooltipsComponentsPopoverOnElement['default'].extend({
		layout: _componentLibraryTemplatesComponentsZHelptextPopover['default'],

		// popover-on-element options
		spacing: SPACING_BEFORE_POPOVER,
		effect: 'fade',
		delay: 250,
		delayOnChange: true,
		targetAttachment: 'top right',
		attachment: 'top left',

		// z-helptext-popover options
		title: null,
		alertType: ALERT_TYPES.INFO,

		// computed properties
		hideAlertIcon: _ember['default'].computed.equal('alertType', ALERT_TYPES.NONE),

		enableLazyRendering: false
	});
});