define('ui-infra/initializers/ajax-setup', ['exports', 'ember', 'ui-infra/utils/get-csrftoken'], function (exports, _ember, _uiInfraUtilsGetCsrftoken) {
  'use strict';

  exports.initialize = initialize;

  function initialize(registry, application) {
    // TODO: remove from eager.js when adding this to ui-infra and client-app
    // TODO: extract also /server/proxies/yp-proxy
    if (!window.document) {
      return;
    } // Safe for fast-boot?
    // TODO: change this to use either the rgistry or application
    var mtaContextService = application.__container__.lookup("service:mta-context");
    var ajaxToken = (new RegExp('(?:^|; )' + encodeURIComponent('ajaxtoken') + '=([^;]*)').exec(document.cookie) || [null, null])[1];
    _ember['default'].$.ajaxSetup({
      beforeSend: function beforeSend(xhr) {

        // Ideally would just use https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
        //  But due to compatibility used
        //  http://stackoverflow.com/questions/736513/how-do-i-parse-a-url-into-hostname-and-path-in-javascript
        //  this won't work with service workers since we don't have the dom
        var a = document.createElement('a');
        a.href = this.url;
        // The below check for !a.host is because IE for relative URLs i.e. (/my_fav_api)
        //  doesn't put anything in host which is a reasonable choice.
        if (!a.host || a.host === window.location.host) {
          xhr.setRequestHeader("X-CSRFToken", (0, _uiInfraUtilsGetCsrftoken['default'])());
          xhr.setRequestHeader("X-PAGEUrl", window.location.href);
          xhr.setRequestHeader("X-AJAXToken", ajaxToken);
          var mtaCompany = mtaContextService && mtaContextService.getCompany();
          if (mtaCompany) {
            xhr.setRequestHeader("X-MTACompany", mtaCompany);
          }
        }
      }
    });
    _ember['default'].$(document).ajaxError(function (event, xhr, settings) {
      // This handler doesn't catch ajax requests with `'global': false` param,
      // e.g. session pings and tests in session-initializer

      if (xhr.status === 403) {
        // TODO: find a way to provide whitelists or just fix this API
        // effective deductions returns 403 when you lack the proper permissions
        if (!/api\/effective_deduction/.test(settings.url)) {
          window.location.reload(false);
        }
      }
    });
  }

  exports['default'] = {
    name: 'ajax-setup',
    initialize: initialize
  };
});