define('component-library/mixins/campaign-display-status-mixin', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		createCampaignDisplayStatus: function createCampaignDisplayStatus() {
			/*
   	Create a status object for the purpose of trakcing display status and decide when to
   	cool off or display.
   	See simpleImpressionSchedule for typical scenario about how we leverage the status here
   */
			return {
				createdAt: (0, _componentLibraryUtilsGlobals.moment)().format(),
				// active/inactive/coolingOff
				status: null,
				// latest timestamp when it becomes active
				activeAt: null,
				// number of display times since activeAt
				displayCount: 0,
				// lastest timestamp when it becomes coolingOff
				coolingOffAt: null,
				// total number of coolingOff times
				coolingOffCount: 0,
				// timestamp when it becomes inactive
				inactiveAt: null

			};
		},

		recordDisplay: function recordDisplay(campaignDisplayStatus) {
			if (campaignDisplayStatus.status != 'active') {
				_ember['default'].set(campaignDisplayStatus, 'activeAt', (0, _componentLibraryUtilsGlobals.moment)().format());
				_ember['default'].set(campaignDisplayStatus, 'status', 'active');
				_ember['default'].set(campaignDisplayStatus, 'displayCount', 1);
			} else {
				_ember['default'].set(campaignDisplayStatus, 'displayCount', campaignDisplayStatus.displayCount + 1);
			}
		},

		recordCoolingOff: function recordCoolingOff(campaignDisplayStatus) {
			if (campaignDisplayStatus.status != 'coolingOff') {
				_ember['default'].set(campaignDisplayStatus, 'status', 'coolingOff');
				_ember['default'].set(campaignDisplayStatus, 'coolingOffAt', (0, _componentLibraryUtilsGlobals.moment)().format());
				_ember['default'].set(campaignDisplayStatus, 'coolingOffCount', campaignDisplayStatus.coolingOffCount + 1);
			}
		},

		recordInactive: function recordInactive(campaignDisplayStatus) {
			if (campaignDisplayStatus.status != 'inactive') {
				_ember['default'].set(campaignDisplayStatus, 'status', 'inactive');
				_ember['default'].set(campaignDisplayStatus, 'inactiveAt', (0, _componentLibraryUtilsGlobals.moment)().format());
			}
		},

		// TODO: separate this out
		// impressionThreshold:
		//		the max displayCount before the component goes to coolingOff/inactive
		// coolingOffDays:
		//		number of days the compoent cools off
		// coolingOffCountThreshold:
		//		the number of times that a ui component is allowed to coolOff.
		//		If another coolingOff decidsion is made, but we already exceeded the threshold. The component will go inactive
		simpleImpressionSchedule: function simpleImpressionSchedule(campaignDisplayStatus) {
			var displayCountThreshold = arguments.length <= 1 || arguments[1] === undefined ? 3 : arguments[1];
			var coolingOffDays = arguments.length <= 2 || arguments[2] === undefined ? 14 : arguments[2];
			var coolingOffCountThreshold = arguments.length <= 3 || arguments[3] === undefined ? 1 : arguments[3];

			if (campaignDisplayStatus.status == 'inactive') {
				return 'inactive';
			}
			var nextStatus = this._simpleImpressionSchedule(campaignDisplayStatus, displayCountThreshold, coolingOffDays);
			if (campaignDisplayStatus.status == 'active' && nextStatus == 'coolingOff' && campaignDisplayStatus.coolingOffCount >= coolingOffCountThreshold) {
				return 'inactive';
			}
			return nextStatus;
		},

		// this simply returns active or coolingOff
		_simpleImpressionSchedule: function _simpleImpressionSchedule(campaignDisplayStatus, displayCountThreshold, coolingOffDays) {
			if (!campaignDisplayStatus.status) {
				return 'active';
			}
			if (campaignDisplayStatus.status == 'active' && campaignDisplayStatus.displayCount < displayCountThreshold) {
				return 'active';
			}

			if (campaignDisplayStatus.status == 'coolingOff') {
				var coolingOffAtObj = (0, _componentLibraryUtilsGlobals.moment)(campaignDisplayStatus.coolingOffAt);
				// coolingOff period passed
				if (coolingOffAtObj.add(coolingOffDays, 'days').isBefore((0, _componentLibraryUtilsGlobals.moment)())) {
					return 'active';
				}
			}
			return 'coolingOff';
		}
	});
});