define('component-library/components/html-editor', ['exports', 'ember', 'component-library/utils/globals', 'component-library/utils/get-text-width', 'component-library/utils/squire-helper', 'component-library/components/carousel-modal', 'component-library/modals/onboarding/preview-modal', 'ui-infra/utils/sanitize-html'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryUtilsGetTextWidth, _componentLibraryUtilsSquireHelper, _componentLibraryComponentsCarouselModal, _componentLibraryModalsOnboardingPreviewModal, _uiInfraUtilsSanitizeHtml) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['', ''], ['', '']);

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-htmlEditor'],
		inputHtml: '',
		inputLink: '',
		useTemplatization: false,
		hasPreview: false,
		editor: null,
		selectedField: null,
		// Each field represent an auto-text option
		fields: _ember['default'].computed(function () {
			return null;
		}),
		size: 'medium',
		allowResize: true,
		iFrameFontSize: '16px',
		iFrameFontFamily: 'Helvetica, arial, sans-serif',
		/**
   * If manyFields is true, it means its replacement has more than one tag. For example,
   * an employee full name field could have manyFields=true and replacement='{{firstName}} {{lastName}}'
   * So basicFields are the ones with one tag replacement.
   */
		allFields: _ember['default'].computed(function () {
			return null;
		}),
		isCustomFieldPresent: false,
		isDeletedCustomFieldPresent: false,
		basicFields: _ember['default'].computed.filterBy('allFields', 'manyFields', false),
		htmlEditorStepsModalTitle: 'See how to insert auto text',
		htmlEditorSteps: ['Highlight any words you want to convert to Auto Text.', 'Choose the information you want there.', 'You can move your cursor over Auto Text to see an example.'].map(function (text, stepNum) {
			return _ember['default'].Object.create({
				heading: 'STEP ' + (stepNum + 1),
				text: text,
				url: '/static/img/html-editor-help-carousel/html-editor-step-graphics-' + (stepNum + 1) + '.jpg'
			});
		}),

		// Placeholder related fields and properties
		placeholder: '',
		placeholderActive: false,
		hasPlaceholder: _ember['default'].computed.bool('placeholder'),

		editorHeight: _ember['default'].computed('size', function () {
			switch (this.get('size')) {
				case 'small':
					return '200px';
				case 'medium':
					return '350px';
				default:
					return '500px';
			}
		}),

		convertFormat: function convertFormat(editorHtml) {
			var fields = this.get('basicFields'),
			    notFound = _ember['default'].Object.create({ 'replacement': '{{NOT_FOUND}}' }),
			    $html = (0, _componentLibraryUtilsGlobals.$)('<div />', { html: editorHtml });

			$html.find('.field-tag').each(function () {
				var $tag = (0, _componentLibraryUtilsGlobals.$)(this),
				    tagId = $tag.attr('data-field-id');

				// We hove to replace 'src' in image tags with gibberish, to stop its evaluation.
				$tag.replaceWith((fields.findProperty('id', +tagId) || notFound).get('replacement').replace(/ src=/g, ' my-src='));
			});

			$html.find('.custom-tag').each(function () {
				var $tag = (0, _componentLibraryUtilsGlobals.$)(this),
				    tagId = $tag.attr('data-field-id');

				// We hove to replace 'src' in image tags with gibberish, to stop its evaluation.
				$tag.replaceWith((fields.findProperty('id', +tagId) || notFound).get('replacement').replace(/ src=/g, ' my-src='));
			});

			// Replace back the 'src' attrs
			return $html.html().replace(/ my-src=/g, ' src=');
		},

		convertOriginal: function convertOriginal(inputHtml) {
			var _this = this;

			var pat1 = /{{custom_[0-9]+}}/g;
			var pat2 = /\[custom_\d+\]/g;
			var finalHtml = this.get('basicFields').reduce(function (ipHtml, field) {
				return ipHtml.replace(new RegExp(field.get('replacement').replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), _this.createFieldTag(field));
			}, inputHtml);
			finalHtml = finalHtml.replace(pat1, this.createDeleteField());
			finalHtml = finalHtml.replace(pat2, this.createDeleteField());
			return finalHtml;
		},

		createFieldTag: function createFieldTag(field) {
			var replacement = field.get('replacement');
			if (field.get('manyFields')) {
				return this.convertOriginal(replacement);
			}

			var isCustomField = field.get('isCustomField');

			var text = field.get('text').toUpperCase(),
			    fieldTag = document.createElement('img'),
			    example = field.get('example');

			if (isCustomField) {
				(0, _componentLibraryUtilsGlobals.$)(fieldTag).addClass('custom-tag');
			} else {
				(0, _componentLibraryUtilsGlobals.$)(fieldTag).addClass('field-tag');
			}
			(0, _componentLibraryUtilsGlobals.$)(fieldTag).attr('data-field-id', field.get('id')).attr('title', example ? 'e.g. ' + example : '').attr('width', (0, _componentLibraryUtilsGetTextWidth['default'])(text, this.get('iFrameFontSize') + '  ' + this.get('iFrameFontFamily'))).attr('src', 'data:image/svg+xml;charset=utf8,' + this.createInlineSvg(text));
			return fieldTag.outerHTML;
		},

		createDeleteField: function createDeleteField() {
			var text = '[Deleted Field]',
			    fieldTag = document.createElement('img'),
			    example = 'This custom field has been deleted. Please remove it to avoid any confusion.';

			(0, _componentLibraryUtilsGlobals.$)(fieldTag).addClass('delete-tag').attr('title', example ? '' + example : '').attr('width', (0, _componentLibraryUtilsGetTextWidth['default'])(text, this.get('iFrameFontSize') + '  ' + this.get('iFrameFontFamily'))).attr('src', 'data:image/svg+xml;charset=utf8,' + this.createDeleteInlineSvg(text));
			return fieldTag.outerHTML;
		},

		createDeleteInlineSvg: function createDeleteInlineSvg(str) {
			var svgNS = 'http://www.w3.org/2000/svg',
			    xmlNS = 'http://www.w3.org/2000/xmlns/',
			    svg = document.createElementNS(svgNS, 'svg'),
			    text = document.createElementNS(svgNS, 'text'),
			    textAttributes = {
				'x': 0,
				'y': '0.85em',
				'fill': 'rgb(192, 3, 52)',
				'font-family': this.get('iFrameFontFamily'),
				'font-size': this.get('iFrameFontSize')
			};

			Object.keys(textAttributes).forEach(function (key) {
				text.setAttributeNS(null, key, textAttributes[key]);
			});

			svg.setAttributeNS(xmlNS, 'xmlns', svgNS);
			svg.setAttributeNS(xmlNS, 'xmlns:xlink', 'http://www.w3.org/1999/xlink');
			text.appendChild(document.createTextNode(str));
			svg.appendChild(text);

			return encodeURI(new XMLSerializer().serializeToString(svg));
		},

		createInlineSvg: function createInlineSvg(str) {
			var svgNS = 'http://www.w3.org/2000/svg',
			    xmlNS = 'http://www.w3.org/2000/xmlns/',
			    svg = document.createElementNS(svgNS, 'svg'),
			    text = document.createElementNS(svgNS, 'text'),
			    textAttributes = {
				'x': 0,
				'y': '0.85em',
				'fill': 'rgb(52,52,52)',
				'font-family': this.get('iFrameFontFamily'),
				'font-size': this.get('iFrameFontSize')
			};

			Object.keys(textAttributes).forEach(function (key) {
				text.setAttributeNS(null, key, textAttributes[key]);
			});

			svg.setAttributeNS(xmlNS, 'xmlns', svgNS);
			svg.setAttributeNS(xmlNS, 'xmlns:xlink', 'http://www.w3.org/1999/xlink');
			text.appendChild(document.createTextNode(str));
			svg.appendChild(text);

			return encodeURI(new XMLSerializer().serializeToString(svg));
		},

		applyField: (function () {
			var field = this.get('selectedField'),
			    editor = this.get('editor');

			if (!editor || !field) {
				return;
			}

			if (this.get('placeholderActive')) {
				// hack to handle the case where inputHtml is empty and a field is inserted.
				this.set('placeholderActive', false);
				editor.setHTML(this.createFieldTag(field));
				this.set('inputHtml', this.convertFormat(editor.getHTML().replace(/<div><br><\/div>$/, '')));
			} else {
				editor.insertHTML(this.createFieldTag(field));
			}
			this.set('selectedField', null);
		}).observes('selectedField'),

		// Event handlers
		inputEventHandler: function inputEventHandler(e) {
			var editor = this.get('editor');
			if (!editor || this.get('placeholderActive')) {
				return;
			}

			// Remove empty trailing divs. Sometimes empty input can look like this in Squire.
			this.set('inputHtml', this.convertFormat(editor.getHTML().replace(/<div><br><\/div>$/, '')));
			var currHtml = this.get('inputHtml');
			if (currHtml.includes('custom_')) {
				this.set('isCustomFieldPresent', true);
			} else {
				this.set('isCustomFieldPresent', false);
			}

			if (currHtml.includes('delete-tag')) {
				this.set('isDeletedCustomFieldPresent', true);
			} else {
				this.set('isDeletedCustomFieldPresent', false);
			}
		},

		focusEventHandler: function focusEventHandler(e) {
			var editor = this.get('editor');
			if (!editor || !this.get('hasPlaceholder') || !this.get('placeholderActive')) {
				return;
			}

			editor.setHTML('');
			this.set('placeholderActive', false);
		},

		blurEventHandler: function blurEventHandler(e) {
			var editor = this.get('editor');
			if (!editor || !this.get('hasPlaceholder') || this.get('placeholderActive') || editor.getHTML().replace(/<div><br><\/div>$/, '')) {
				return;
			}

			editor.setHTML(this.get('placeholder'));
			this.set('placeholderActive', true);
		},

		willPasteEventHandler: function willPasteEventHandler(e) {
			// Remove useless classes (like "MSONormal") and confusing white backgrounds that
			// show up when pasting from MS Word.
			(0, _componentLibraryUtilsGlobals.$)(e.fragment.querySelectorAll('*')).removeAttr('class').css('background', '');
		},

		didInsertElement: function didInsertElement() {
			var $iframe = this.$('iframe')[0];
			var editor = (0, _componentLibraryUtilsSquireHelper['default'])($iframe);

			if (!editor) {
				return;
			}

			this.set('editor', editor);
			editor.setHTML(this.convertOriginal(this.get('inputHtml') || ''));
			this.blurEventHandler();

			editor.addEventListener('input', this.inputEventHandler.bind(this));
			editor.addEventListener('focus', this.focusEventHandler.bind(this));
			editor.addEventListener('blur', this.blurEventHandler.bind(this));
			editor.addEventListener('willPaste', this.willPasteEventHandler.bind(this));
		},

		willDestroyElement: function willDestroyElement() {
			var editor = this.get('editor');
			if (!editor) {
				return;
			}

			editor.removeEventListener('input', this.inputEventHandler.bind(this));
			editor.removeEventListener('focus', this.focusEventHandler.bind(this));
			editor.removeEventListener('blur', this.blurEventHandler.bind(this));
			editor.removeEventListener('willPaste', this.willPasteEventHandler.bind(this));
		},

		getTextForPreview: function getTextForPreview() {
			var regex1 = /<img class="delete-tag" title="This custom field has been deleted. Please remove it to avoid any confusion." width="(?:[^"]|"")*" src="(?:[^"]|"")*">/g;
			var regex2 = /{{custom_[0-9]+}}/g;
			var document = this.get('inputHtml');
			var finalHtml = this.get('basicFields').reduce(function (text, field) {
				return text.replace(new RegExp(field.get('replacement').replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), field.get('example'));
			}, document);
			finalHtml = finalHtml.replace(regex1, '');
			finalHtml = finalHtml.replace(regex2, '');
			return finalHtml;
		},

		actions: {
			applyStyle: function applyStyle(action, params) {
				var editor = this.get('editor');
				if (action == 'makeLink') {
					params = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject, params);
				}
				editor[action](params);
				editor.focus();
			},

			showAutoTextHelpModal: function showAutoTextHelpModal() {
				this.eventLogger.log('onboarding_v2_html_editor', {
					message: 'User clicks learn about auto text'
				});
				_componentLibraryComponentsCarouselModal['default'].show({
					title: this.get('htmlEditorStepsModalTitle'),
					content: this.get('htmlEditorSteps')
				});
			},

			doPreview: function doPreview() {
				this.eventLogger.log('onboarding_v2_html_editor', {
					message: 'User clicks preview link'
				});

				return _componentLibraryModalsOnboardingPreviewModal['default'].show({
					title: this.get('previewTitle') || 'Preview of your Hiring Document',
					message: this.getTextForPreview(),
					useSquireIframe: true,
					iframeHeight: this.get('editorHeight')
				});
			}
		}
	});
});