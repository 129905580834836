define('component-library/mixins/style-bindings', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		concatenatedProperties: ['styleBindings'],
		attributeBindings: ['style'],
		unitType: 'px',

		createStyleString: function createStyleString(styleName, property) {
			var value;
			value = this.get(property);
			if (_ember['default'].isNone(value)) {
				return;
			}
			if (_ember['default'].typeOf(value) === 'number') {
				value = value + this.get('unitType');
			}
			return _ember['default'].String.dasherize("" + styleName) + ":" + value + ";";
		},

		applyStyleBindings: function applyStyleBindings() {
			var lookup,
			    properties,
			    styleBindings,
			    styleComputed,
			    styles,
			    _this = this;
			styleBindings = this.styleBindings;
			if (!styleBindings) {
				return;
			}
			lookup = {};
			styleBindings.forEach(function (binding) {
				var property, style, tmp;
				tmp = binding.split(':');
				property = tmp[0];
				style = tmp[1];
				lookup[style || property] = property;
			});
			styles = Object.keys(lookup);
			properties = styles.map(function (style) {
				return lookup[style];
			});
			styleComputed = _ember['default'].computed(function () {
				var styleString, styleTokens;
				styleTokens = styles.map(function (style) {
					return _this.createStyleString(style, lookup[style]);
				});
				styleString = styleTokens.join('');
				if (styleString.length !== 0) {
					return styleString;
				}
			});
			styleComputed.property.apply(styleComputed, properties);
			return _ember['default'].defineProperty(this, 'style', styleComputed);
		},

		init: function init() {
			this.applyStyleBindings();
			return this._super();
		}
	});
});