define('component-library/components/charts/commuter', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		templateName: 'components/charts/commuter',
		height: 130,
		totalMonthly: (function () {
			return this.get('eeMonthly') + this.get('erMonthly');
		}).property('eeMonthly', 'erMonthly'),

		unit: (function () {
			var dollars = this.get('totalMonthly');
			var pixels = this.get('height');
			return pixels / dollars;
		}).property('totalMonthly', 'height'),

		eeHeight: (function () {
			return this.get('unit') * this.get('eeMonthly') - 1;
		}).property('unit', 'eeMonthly'),

		erY: (function () {
			return this.get('eeHeight') + 2;
		}).property('eeHeight'),

		erHeight: (function () {
			return this.get('height') - this.get('erY');
		}).property('height', 'erY'),

		limitY: (function () {
			return this.get('height') - this.get('limit') * this.get('unit');
		}).property('limit', 'unit', 'height'),

		hasEmployerContribution: (function () {
			return this.get('erMonthly') > 0;
		}).property('erMonthly'),

		hasPostTax: (function () {
			return this.get('totalMonthly') > this.get('limit');
		}).property('totalMonthly', 'limit'),

		postTaxTopY: (function () {
			return 1;
		}).property(),

		postTaxBottomY: (function () {
			return this.get('limitY') - 5;
		}).property('limitY'),

		postTaxMidY: (function () {
			var top = this.get('postTaxTopY');
			var bottom = this.get('postTaxBottomY');
			return (bottom - top) / 2 + top;
		}).property('postTaxBottomY', 'postTaxTopY'),

		postTaxTextY: (function () {
			return this.get('postTaxMidY') + 4;
		}).property('postTaxMidY'),

		preTaxTopY: (function () {
			if (this.get('hasPostTax')) {
				return this.get('limitY') + 5;
			}
			return 1;
		}).property('limitY', 'hasPostTax'),

		preTaxBottomY: (function () {
			return this.get('height') - 5;
		}).property('height'),

		preTaxMidY: (function () {
			var top = this.get('preTaxTopY');
			var bottom = this.get('preTaxBottomY');
			return (bottom - top) / 2 + top;
		}).property('preTaxBottomY', 'preTaxTopY'),

		preTaxTextY: (function () {
			return this.get('preTaxMidY') + 4;
		}).property('preTaxMidY'),

		eeSquareY: (function () {
			return this.get('height') + 20;
		}).property('height'),

		eeTextY: (function () {
			return this.get('eeSquareY') + 10;
		}).property('eeSquareY'),

		erSquareY: (function () {
			return this.get('eeSquareY') + 20;
		}).property('eeSquareY'),

		erTextY: (function () {
			return this.get('erSquareY') + 10;
		}).property('erSquareY'),

		limitSquareY: (function () {
			return this.get('erSquareY') + 28;
		}).property('erSquareY'),

		limitTextY: (function () {
			return this.get('limitSquareY') + 4;
		}).property('limitSquareY')

	});
});