define('component-library/proxies/plan', ['exports', 'ember', 'component-library/utils/pretty-percent-or-currency'], function (exports, _ember, _componentLibraryUtilsPrettyPercentOrCurrency) {
	'use strict';

	exports['default'] = _ember['default'].ObjectProxy.extend({
		isPlan: true,
		carrier: null,
		metadata: _ember['default'].computed.oneWay('carrier.effectiveYearMetadata'),
		packageSizes: _ember['default'].computed.oneWay('metadata.packageSizes'),
		contributionRules: _ember['default'].computed.oneWay('metadata.contributionRules'),
		validParticipationRule: _ember['default'].computed.alias('validParticipationRules.firstObject'),
		validContributionRule: _ember['default'].computed.alias('validContributionRules.firstObject'),
		isStockPlan: _ember['default'].computed.not('customPlan'),
		isMedical: _ember['default'].computed.equal('lineOfCoverage', 'medical'),
		isDental: _ember['default'].computed.equal('lineOfCoverage', 'dental'),
		isVision: _ember['default'].computed.equal('lineOfCoverage', 'vision'),
		isLife: _ember['default'].computed.equal('lineOfCoverage', 'lifenew'),
		isAdd: _ember['default'].computed.equal('lineOfCoverage', 'add'),
		isStd: _ember['default'].computed.equal('lineOfCoverage', 'std'),
		isLtd: _ember['default'].computed.equal('lineOfCoverage', 'ltd'),
		isDisability: _ember['default'].computed.or('isAdd', 'isStd', 'isLtd'),
		isLifeDisability: _ember['default'].computed.or('isLife', 'isAdd', 'isStd', 'isLtd'),
		networkName: _ember['default'].computed.oneWay('network.name'),

		planParticipationRuleV2: _ember['default'].computed.oneWay('planParticipationRule'),

		participationRules: (function () {
			var lineOfCoverage = this.get('lineOfCoverage');
			return this.getWithDefault('metadata.participationRules', []).filter(function (rule) {
				return !rule.get('softDeleted') && lineOfCoverage === rule.get('lineOfCoverage');
			});
		}).property('metadata.participationRules'),

		planDetailModelClass: (function () {
			var lineOfCoverage = this.get('lineOfCoverage');
			if (lineOfCoverage === 'medical') {
				return 'plan';
			} else if (lineOfCoverage === 'dental') {
				return 'dentalPlan';
			} else if (lineOfCoverage === 'vision') {
				return 'visionPlan';
			} else if (lineOfCoverage === 'lifenew') {
				return 'lifePlanNew';
			} else if (lineOfCoverage === 'add') {
				return 'addPlan';
			} else if (lineOfCoverage === 'std') {
				return 'stdPlanNew';
			} else if (lineOfCoverage === 'ltd') {
				return 'ltdPlanNew';
			}
		}).property('lineOfCoverage'),

		_filterValidRules: function _filterValidRules(rules) {
			var planGroup = this.get('planGroup');
			// planGroup may be undefined for a life plan.
			if (_ember['default'].isNone(planGroup)) {
				return [];
			}
			var employeeCount = this.get('employeeCount');
			return rules.filter(function (rule) {
				var planGroupMatch = _ember['default'].isEmpty(rule.get('planGroup')) || planGroup.toLowerCase() === rule.get('planGroup').toLowerCase();
				return planGroupMatch && employeeCount >= rule.get('minEmployees') && employeeCount < rule.get('maxEmployees');
			});
		},

		validParticipationRules: (function () {
			return this._filterValidRules(this.getWithDefault('participationRules', []));
		}).property('participationRules'),

		validContributionRules: (function () {
			return this._filterValidRules(this.getWithDefault('contributionRules', []));
		}).property('contributionRules'),

		contains: function contains(name, nameMap) {
			var tokens = name.toLowerCase().split(' ');
			return tokens.reduce(function (accum, token) {
				return accum || nameMap[token];
			}, false);
		},

		gold: (function () {
			var nameMap = {
				"gold": true,
				"gld": true
			};
			return this.contains(this.getWithDefault("name", ""), nameMap);
		}).property('name'),

		silver: (function () {
			var nameMap = {
				"silver": true,
				"slvr": true,
				"slv": true
			};
			return this.contains(this.getWithDefault("name", ""), nameMap);
		}).property('name'),

		bronze: (function () {
			var nameMap = {
				"bronze": true,
				"brz": true
			};
			return this.contains(this.getWithDefault("name", ""), nameMap);
		}).property('name'),

		platinum: (function () {
			var nameMap = {
				"platinum": true
			};
			return this.contains(this.getWithDefault("name", ""), nameMap);
		}).property('name'),

		_formatFreqAndMaxField: function _formatFreqAndMaxField(freqKey, allowableKey) {
			var freqValue = this.get(freqKey);
			var allowableValue = this.get(allowableKey);
			if (_ember['default'].isEmpty(freqValue) && _ember['default'].isEmpty(allowableValue)) {
				return '-';
			}
			var freqValueText = _ember['default'].isEmpty(freqValue) ? '-' : freqValue;
			var allowableValueText = _ember['default'].isEmpty(allowableValue) || allowableValue == 1 ? '-' : window.prettyCurrency(allowableValue, false);
			return freqValueText + ' | ' + allowableValueText;
		},

		_formatAllowableField: function _formatAllowableField(key) {
			var value = this.get(key);
			if (value == 1) {
				return 'Fully covered with no max';
			} else {
				return window.prettyCurrency(value, false);
			}
		},

		_formatFrequencyText: function _formatFrequencyText(key) {
			var value = this.get(key);
			if (value) {
				return value + ' months';
			}
		},

		copayAndCopaySpecialistText: (function () {
			var copay1 = this.get('coPay');
			var copay2 = this.get('specialistCoPay');
			if (_ember['default'].isEmpty(copay1) && _ember['default'].isEmpty(copay2)) {
				return '-';
			}
			copay1 = _ember['default'].isEmpty(copay1) ? '-' : (0, _componentLibraryUtilsPrettyPercentOrCurrency['default'])(copay1, 0, false);
			copay2 = _ember['default'].isEmpty(copay2) ? '-' : (0, _componentLibraryUtilsPrettyPercentOrCurrency['default'])(copay2, 0, false);
			return copay1 + ' | ' + copay2;
		}).property('coPay', 'specialistCoPay'),

		rxBrandAndrxGenericText: (function () {
			var rx1 = this.get('rxCoPayGeneric');
			var rx2 = this.get('rxCoPayBrand');
			if (_ember['default'].isEmpty(rx1) && _ember['default'].isEmpty(rx2)) {
				return '-';
			}
			rx1 = _ember['default'].isEmpty(rx1) ? '-' : rx1;
			rx2 = _ember['default'].isEmpty(rx2) ? '-' : rx2;
			return rx1 + ' | ' + rx2;
		}).property('rxCoPayGeneric', 'rxCoPayBrand'),

		frameFreqAndMaxText: (function () {
			return this._formatFreqAndMaxField('frameFrequency', 'frameAllowable');
		}).property('frameFrequency', 'frameAllowable'),

		lensFreqAndMaxText: (function () {
			return this._formatFreqAndMaxField('lensFrequency', 'lensAllowable');
		}).property('lensFrequency', 'lensAllowable'),

		contactsFreqAndMaxText: (function () {
			return this._formatFreqAndMaxField('contactsFrequency', 'contactsAllowable');
		}).property('contactsFrequency', 'contactsAllowable'),

		frameAllowableText: (function () {
			return this._formatAllowableField('frameAllowable');
		}).property('frameAllowable'),

		lensAllowableText: (function () {
			return this._formatAllowableField('lensAllowable');
		}).property('lensAllowable'),

		contactsAllowableText: (function () {
			return this._formatAllowableField('contactsAllowable');
		}).property('contactsAllowable'),

		frameFrequencyText: (function () {
			return this._formatFrequencyText('frameFrequency');
		}).property('frameFrequency'),

		lensFrequencyText: (function () {
			return this._formatFrequencyText('lensFrequency');
		}).property('lensFrequency'),

		contactsFrequencyText: (function () {
			return this._formatFrequencyText('contactsFrequency');
		}).property('contactsFrequency'),

		examFrequencyText: (function () {
			return this._formatFrequencyText('examFrequency');
		}).property('examFrequency'),

		isKaiserPlan: (function () {
			return this.get('carrier.displayName') == 'Kaiser Permanente';
		}).property('carrier.displayName'),

		// TODO(kkolipaka): This is a very bad HACK.
		lifeIsBasic: (function () {
			// TODO(kkolipaka): Remove hardcoded value. Use the Ember Models instead.
			return this.get('planType') === "basic";
		}).property('planType'),

		lifeIsVoluntary: (function () {
			return this.get('planType') === "voluntary";
		}).property('planType'),

		lifeEEContributionFrac: (function () {
			if (this.get('lifeIsBasic')) {
				return 1;
			} else if (this.get('lifeIsVoluntary')) {
				return 0;
			}
			return null;
		}).property('lifeIsBasic', 'lifeIsVoluntary'),

		lifeDepContributionFrac: (function () {
			if (this.get('lifeIsBasic') || this.get('lifeIsVoluntary')) {
				return 0;
			}
			return null;
		}).property('lifeIsBasic', 'lifeIsVoluntary'),

		_getlifeRawBenefitsMaxText: function _getlifeRawBenefitsMaxText(eeRestriction) {
			var benefitsMaxText = "-";
			var planMaxAmount = eeRestriction.planMaxAmount;
			var hasPlanMaxAmount = planMaxAmount && planMaxAmount > 0;
			if (hasPlanMaxAmount) {
				benefitsMaxText = window.prettyCurrency(eeRestriction.planMaxAmount);
			}
			var maxMultiplier = eeRestriction.maxMultiplier;
			if (maxMultiplier && maxMultiplier > 0) {
				var maxMultiplierText = maxMultiplier + " x salary";
				benefitsMaxText = hasPlanMaxAmount ? benefitsMaxText + " or " + maxMultiplierText : maxMultiplierText;
			}
			return benefitsMaxText;
		},

		lifeBenefitVolumeText: (function () {
			var benefitVolumeText = "-";
			var eeRestriction = this.get('eeRestriction');
			if (!_ember['default'].isNone(eeRestriction)) {
				if (eeRestriction.isIncrements) {
					benefitVolumeText = window.prettyCurrency(eeRestriction.incrementalUnits) + " incr.";
				} else if (eeRestriction.isSetValues) {
					if (eeRestriction.flatAmounts) {
						benefitVolumeText = eeRestriction.flatAmounts;
					}
				} else if (eeRestriction.isFixed) {
					benefitVolumeText = this._getlifeRawBenefitsMaxText(eeRestriction);
				}
			}
			return benefitVolumeText;
		}).property('eeRestriction'),

		lifeParticipationRequirementFrac: (function () {
			var req = this.get('participationRequirement');
			if (!_ember['default'].isNone(req)) {
				return Number(req) / 100;
			}
			return null;
		}).property('participationRequirement'),

		lifeBenefitMaxText: (function () {
			var eeRestriction = this.get('eeRestriction');
			if (eeRestriction) {
				if (eeRestriction.isFixed) {
					return "Same as Volume";
				} else {
					return this._getlifeRawBenefitsMaxText(eeRestriction);
				}
			}
			return "-";
		}).property('eeRestriction'),

		lifeADnDText: (function () {
			return this.get('migrationStatus') === "bundled" ? "Yes" : "No";
		}).property('migrationStatus'),

		lifeDependentsCovered: (function () {
			return this.get('applicableEnrollees') === "employee_only" ? "No" : "Yes";
		}).property('applicableEnrollees'),

		stdBenefitsDurationText: (function () {
			var benefitsDuration = this.get('benefitsDurationStructured');
			return benefitsDuration ? benefitsDuration + ' weeks' : '-';
		}).property('benefitsDurationStructured'),

		stdEliminationPeriodText: (function () {
			var eliminationPeriodAccident = this.get('eliminationPeriodAccident');
			var eliminationPeriodIllness = this.get('eliminationPeriodIllness');

			if (eliminationPeriodAccident || eliminationPeriodIllness) {
				return eliminationPeriodAccident + ' Days Accident, ' + eliminationPeriodIllness + ' Days Illness';
			}
			return '-';
		}).property('eliminationPeriodAccident', 'eliminationPeriodIllness'),

		durationValueToText: function durationValueToText(value) {
			var displayNameMap = {
				'to_sixty_five': "Until Age 65",
				'ssnra': "Until Social Security Age",

				'one_year': "1 Year",
				'two_year': "2 Years",
				'three_year': "3 Years",
				'four_year': "4 Years",
				'five_year': "5 Years",
				'six_year': "6 Years",
				'seven_year': "7 Years",
				'eight_year': "8 Years",
				'nine_year': "9 Years",
				'ten_year': "10 Years",
				'eleven_year': "11 Years",
				'twelve_year': "12 Years",
				'thirteen_year': "13 Years",
				'fourteen_year': "14 Years",
				'fifteen_year': "15 Years",
				'sixteen_year': "16 Years",
				'seventeen_year': "17 Years",
				'eighteen_year': "18 Years",
				'nineteen_year': "19 Years",
				'twenty_year': "20 Years",
				'twenty_one_year': "21 Years",
				'twenty_two_year': "22 Years",
				'twenty_three_year': "23 Years",
				'twenty_four_year': "24 Years",
				'twenty_five_year': "25 Years"
			};

			return displayNameMap[value] || value.split('_').join(' ');
		},

		ownOccPeriodToText: function ownOccPeriodToText(value) {
			if (isNaN(value)) {
				return "-";
			} else {
				var ret = value.toString() + " Month";
				if (parseInt(value) > 1) {
					ret = ret + "s";
				}
				return ret;
			}
		},

		ltdBenefitsDurationText: (function () {
			return this.durationValueToText(this.get('benefitsDurationChar'));
		}).property('benefitsDurationChar'),

		ltdOwnOccText: (function () {
			return this.ownOccPeriodToText(this.get('ownOccPeriodStructured'));
		}).property('ownOccPeriodStructured'),

		ltdEliminationPeriodText: (function () {
			var eliminationPeriod = this.get('eliminationPeriodAccidentIllness');
			return eliminationPeriod ? eliminationPeriod + ' days' : '-';
		}).property('eliminationPeriodAccidentIllness'),

		getBenefit: function getBenefit(field) {
			var benefitsData = this.get('benefitsData');
			return benefitsData && benefitsData[field] ? benefitsData[field] : 'See SBC';
		},

		isFullyInsured: _ember['default'].computed('fundingType', function () {
			return this.get('fundingType') === 'FI';
		}),
		isLevelFunded: _ember['default'].computed('fundingType', function () {
			return this.get('fundingType') === 'L';
		})
	});
});