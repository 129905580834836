define('ember-table/views/header-block', ['exports', 'ember', 'ember-table/views/table-block'], function (exports, _ember, _emberTableViewsTableBlock) {
  'use strict';

  exports['default'] = _emberTableViewsTableBlock['default'].extend({
    classNames: ['et-header-block'],
    // TODO(new-api): Eliminate view alias
    itemView: 'header-row',
    itemViewClass: _ember['default'].computed.alias('itemView'),

    content: _ember['default'].computed(function () {
      return [this.get('columns')];
    }).property('columns')
  });
});