define("component-library/components/payroll/select-deductions-contributions-modal", ["exports", "component-library/components/promise-modal"], function (exports, _componentLibraryComponentsPromiseModal) {
	"use strict";

	exports["default"] = _componentLibraryComponentsPromiseModal["default"].extend({
		templateName: "components/payroll/select-deductions-contributions-modal",
		zpCompanyDeductionTypes: [],
		zpCompanyContributionTypes: [],
		actions: {
			confirm: function confirm() {
				this.send("close");
			}
		}
	});
});