define('component-library/components/payroll/checklist-document-section', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	var CHECKLIST_DOCS_CREATE_BASE_URL = '/custom_api/z_payroll/onboarding/createChecklistDocument/';
	var CHECKLIST_DOCS_DELETE_BASE_URL = '/custom_api/z_payroll/onboarding/deleteChecklistDocument/';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['implementation-checklist-section', 'implementation-checklist-card-body-horizontalPadding', 'js-glue-implementation-checklist-section'],
		companyId: null,
		showDeletion: true,
		data: _ember['default'].computed(function () {
			return {};
		}),
		uploadLinkName: _ember['default'].computed('data', function () {
			return 'Upload ' + this.get('data').header;
		}),
		documentsWithTimestampText: _ember['default'].computed('data.documents.@each.createdAt', 'data.documents.@each.uploaderName', function () {
			var _this = this;

			return this.get('data').documents.map(function (doc) {
				if (!doc.timestampText) {
					doc.timestampText = _this.get('timestampText')(doc);
				}
				return doc;
			});
		}),
		errorText: '',
		showErrorText: _ember['default'].computed.notEmpty('errorText'),

		timestampText: function timestampText(doc) {
			var date = (0, _componentLibraryUtilsGlobals.moment)(doc.createdAt).format('MMM DD, YYYY');
			return 'Uploaded ' + date + ' by ' + doc.uploaderName;
		},

		actions: {
			onUploadSuccess: function onUploadSuccess(file, response) {
				var _this2 = this;

				_ember['default'].ajaxPost('' + CHECKLIST_DOCS_CREATE_BASE_URL + this.get('companyId'), JSON.stringify({
					name: file.name,
					storageKey: response.key,
					checklistDocumentSection_id: this.get('data.checklistDocumentSection_id')
				})).then(function (resp) {
					_this2.get('onUploadSucceed')(resp.data, _this2.get('data'));
				});
			},

			deleteFile: function deleteFile(doc) {
				var _this3 = this;

				doc.set('isDeleting', true);
				this.set('errorText', '');
				_ember['default'].ajaxPost('' + CHECKLIST_DOCS_DELETE_BASE_URL + this.get('companyId'), JSON.stringify({
					checklistDocument_id: doc.checklistDocument_id
				})).then(function () {
					_this3.get('onDeleteFile')(doc, _this3.get('data'));
				})['catch'](function () {
					_this3.set('errorText', 'Failed to delete the document.');
				})['finally'](function () {
					doc.set('isDeleting', false);
				});
			},

			openInstructionModal: function openInstructionModal() {
				this.get('openInstructionModal')(this.get('data.priorProvider'), this.get('data.sectionType'));
			}
		}
	});
});