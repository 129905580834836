define('component-library/views/master', ['exports', 'ember', 'component-library/mixins/resize-handler'], function (exports, _ember, _componentLibraryMixinsResizeHandler) {
	'use strict';

	exports['default'] = _ember['default'].View.extend(_componentLibraryMixinsResizeHandler['default'], {
		// Note: 600 is @stateDetailPanel-minWidth,
		minDetailWidth: 600,
		minMasterWidth: 200,

		maxMasterWidth: function maxMasterWidth() {
			// 32 is the horizontal padding on the grid-frame. We need to add this in
			// right now because we have styling issues with v2.
			return _ember['default'].$('.js-masterDetail-container').width() - this.get('minDetailWidth') - 32;
		},

		onResizeEnd: function onResizeEnd() {
			this.set('masterPanelMaxWidth', this.maxMasterWidth());
		},

		didInsertElement: function didInsertElement() {
			this._super();
			var minWidth = this.get('minMasterWidth');
			var maxWidth = this.maxMasterWidth();

			_ember['default'].$('.js-masterPanel').resizable({
				handles: 'e',
				minWidth: minWidth,
				maxWidth: maxWidth,
				containment: 'parent',
				start: function start() {
					_ember['default'].$('.js-detailPanel').css('width', 'auto');
				},
				resize: function resize() {
					_ember['default'].$('.js-detailPanel').css('left', _ember['default'].$('.js-masterPanel').width());
				},
				stop: function stop() {
					_ember['default'].$('.js-detailPanel').css('left', _ember['default'].$('.js-masterPanel').width());
				}
			});
		}
	});
});