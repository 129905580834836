define('component-library/components/z-video', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		eventLogger: _ember['default'].inject.service(),
		classNames: ['u-fillHorizontal'],
		identifier: null,
		title: null,
		videoSource: null,
		thumbnailUrl: null,
		isLoading: false,
		showVideoModal: false,
		isHidden: _ember['default'].computed.empty('videoSource'),

		updateZVideoProperties: function updateZVideoProperties() {
			var _this = this;

			var identifier = this.get('identifier');
			this.set('isLoading', true);
			this.getVideoByIdentifier(identifier).then(function (response) {
				_this.setProperties({
					isLoading: false,
					title: response.title,
					videoSource: response.videoSource,
					thumbnailUrl: response.thumbnailUrl
				});
			})['catch'](function (error) {
				_this.set('isLoading', false);
				_this.get('eventLogger').log('z_video_marketing_error', {
					error: JSON.stringify(error)
				});
			});
		},

		onInit: _ember['default'].on('init', function () {
			this.updateZVideoProperties();
		}),

		didUpdateAttrs: function didUpdateAttrs() {
			this.updateZVideoProperties();
		},

		getVideoByIdentifier: function getVideoByIdentifier(identifier) {
			var url = '/custom_api/marketing/getVideoByIdentifier?identifier=' + identifier;
			return _ember['default'].ajaxGet(url);
		},

		actions: {
			toggleVideoModal: function toggleVideoModal() {
				this.toggleProperty('showVideoModal');
				this.get('eventLogger').log('z_video_marketing', {
					identifier: this.get('identifier'),
					videoSource: this.get('videoSource')
				});
			}
		}
	});
});