define('component-library/helpers/pretty-money', ['exports', 'ember', 'component-library/utils/pretty-currency', 'component-library/utils/pretty-percent2', 'component-library/utils/has-actual-value'], function (exports, _ember, _componentLibraryUtilsPrettyCurrency, _componentLibraryUtilsPrettyPercent2, _componentLibraryUtilsHasActualValue) {
	'use strict';

	var _slicedToArray = (function () {
		function sliceIterator(arr, i) {
			var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;_e = err;
			} finally {
				try {
					if (!_n && _i['return']) _i['return']();
				} finally {
					if (_d) throw _e;
				}
			}return _arr;
		}return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError('Invalid attempt to destructure non-iterable instance');
			}
		};
	})();

	/**
  * Use this like {{pretty-money anyNumber}} or {{pretty-money anyNumber default="N/A"}}
  * or {{pretty-money anyNumer isPercent=true}} or {{pretty-money anyNumber passthrough="-"}}
  * Optional params:
  * @param {Number} number - The number that will be formatted and returned as either a currency or a percent
  * @param {Object} hash - A key-value dict of the optional params such as `default="N/A"`
  * @param {string} hash.default - The value to return if `number` is an invalid value (default "N/A")
  * @param {string} hash.passthrough - Optional value that gets returned or "passed-through" if `number === passthrough`
  * @param {boolean} hash.isPercent - Whether to format the number as a percent (default false)
  * @param {Number} hash.precision - Optional value for number of decimal places to truncate (default 2)
  * @param {boolean} hash.preserveDecimals - Optional value for preserving trailing zeros (default false)
  * Returns $0, $1, $1.10, -$1.23, $123,456
  */
	exports['default'] = _ember['default'].HTMLBars.makeBoundHelper(function (_ref, hash) {
		var _ref2 = _slicedToArray(_ref, 1);

		var number = _ref2[0];

		if (number === hash.passthrough) {
			return number;
		}
		var defaultValue = hash['default'] || new _ember['default'].Handlebars.SafeString("N/A");
		if (typeof number == 'string') {
			// javascript's parseFloat treats commas as the
			// radix point because they're often used that way in Europe
			number = number.replace(',', '');
		}
		number = parseFloat(number);
		if (!(0, _componentLibraryUtilsHasActualValue['default'])(number)) {
			return defaultValue;
		}
		var precision = _ember['default'].isNone(hash.precision) ? 2 : hash.precision;
		if (hash.isPercent) {
			return (0, _componentLibraryUtilsPrettyPercent2['default'])(number, hash.preserveDecimals, precision);
		}
		return (0, _componentLibraryUtilsPrettyCurrency['default'])(number, hash.preserveDecimals, precision);
	});
});