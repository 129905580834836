define('component-library/mixins/payroll/abstract-tax-mixin', ['exports', 'ember', 'component-library/utils/globals', 'component-library/constants/payroll'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryConstantsPayroll) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		defaultSutaRate: 0,
		defaultSutaScRate: 0,
		defaultSutaSc2Rate: 0,
		defaultSutaSc3Rate: 0,

		employmentTaxDepositFrequencyEnums: _ember['default'].computed.filterBy('zPayrollEnums', 'type', 'TaxDepositFrequency'),

		mbtTypeEnums: _ember['default'].computed.filterBy('zPayrollEnums', 'type', 'MbtType'),

		showEmploymentTaxDepositFrequencySelect: _ember['default'].computed.gt('zpCompanyJurisdiction.employmentTaxDepositFrequencyChoices.length', 1),

		isEntityNonProfit: _ember['default'].computed.equal('company.type', 'NP'),

		bothTaxId: _ember['default'].computed('zpCompanyJurisdiction.employmentTaxId', 'zpCompanyJurisdiction.unemploymentTaxId', function (key, value) {
			if (arguments.length > 1) {
				this.set('zpCompanyJurisdiction.employmentTaxId', value);
				this.set('zpCompanyJurisdiction.unemploymentTaxId', value);
			}
			var employmentTaxId = this.get('zpCompanyJurisdiction.employmentTaxId');
			var unemploymentTaxId = this.get('zpCompanyJurisdiction.unemploymentTaxId');
			if (employmentTaxId === unemploymentTaxId) {
				return employmentTaxId;
			}
			return '';
		}),

		employmentTaxDepositFrequencyOptions: _ember['default'].computed('zpCompanyJurisdiction.employmentTaxDepositFrequencyChoices.[]', function () {
			var taxDepositOptionList = this.get('zpCompanyJurisdiction.employmentTaxDepositFrequencyChoices');
			var employmentTaxDepositFrequencyEnums = this.get('employmentTaxDepositFrequencyEnums');

			function isTaxDepositOption(x) {
				return taxDepositOptionList.contains(x.get('value'));
			}

			return employmentTaxDepositFrequencyEnums.filter(isTaxDepositOption);
		}),

		updateExemption: function updateExemption(jurisdiction, taxType, exemptionStatus) {
			var exemptions = this.get('exemptions');
			var exemption = exemptions.filterBy('jurisdiction', jurisdiction).findBy('taxType', taxType);
			if (exemption) {
				exemption.set('exemptionStatus', exemptionStatus);
			}
			// if isExempt and we don't have an exemption model, create one
			else if (exemptionStatus !== _componentLibraryConstantsPayroll.ExemptionStatus.IS_NOT_EXEMPT) {
					this.createExemption(jurisdiction, taxType, exemptionStatus);
				}
		},

		createExemption: function createExemption(jurisdiction, taxType, exemptionStatus) {
			var exemption = window.App.CompanyExemption.createRecord({
				company: this.get('company'),
				jurisdiction: jurisdiction,
				taxType: taxType,
				exemptionStatus: exemptionStatus
			});
			if (!this.get('exemptions').contains(exemption)) {
				// avoid push twice if record already exists
				this.get('exemptions').pushObject(exemption);
			}
		},

		updateSutaAndSurcharges: function updateSutaAndSurcharges(isSutaReimbursable) {

			var suta = this.get('zpCompanyJurisdiction.suta');
			var sutaSc = this.get('zpCompanyJurisdiction.sutaSc');
			var sutaSc2 = this.get('zpCompanyJurisdiction.sutaSc2');
			var sutaSc3 = this.get('zpCompanyJurisdiction.sutaSc3');
			this.set('zpCompanyJurisdiction.isReimbursable', isSutaReimbursable);
			if (isSutaReimbursable) {
				if (suta) {
					suta.setProperties({ previousRate: suta.get('rate'), rate: this.get('defaultSutaRate') });
				}
				if (sutaSc) {
					sutaSc.setProperties({ previousRate: sutaSc.get('rate'), rate: this.get('defaultSutaScRate') });
				}
				if (sutaSc2) {
					sutaSc2.setProperties({ previousRate: sutaSc2.get('rate'), rate: this.get('defaultSutaSc2Rate') });
				}
				if (sutaSc3) {
					sutaSc3.setProperties({ previousRate: sutaSc3.get('rate'), rate: this.get('defaultSutaSc3Rate') });
				}
			} else {
				if (suta) {
					suta.set('rate', suta.get('previousRate'));
				}
				if (sutaSc) {
					sutaSc.set('rate', sutaSc.get('previousRate'));
				}
				if (sutaSc2) {
					sutaSc2.set('rate', sutaSc2.get('previousRate'));
				}
				if (sutaSc3) {
					sutaSc3.set('rate', sutaSc3.get('previousRate'));
				}
			}
		},

		isSutaReimbursable: _ember['default'].computed.alias('isReimbursable'),
		isFutaReimbursable: _ember['default'].computed.alias('isReimbursable'),

		// merge isSutaReimbursable isFutaReimbursable into one
		isReimbursable: _ember['default'].computed('exemptions', 'exemptions.@each.jurisdiction', 'exemptions.@each.taxType', 'exemptions.@each.exemptionStatus', 'zpCompanyJurisdiction.isFederal', function (key, value) {
			var isFederal = this.get('zpCompanyJurisdiction.isFederal');
			var jurisdiction = this.get('zpCompanyJurisdiction.jurisdiction');
			var taxType = isFederal ? 'FUTA' : 'SUTA';
			if (arguments.length == 1) {
				var exemptions = this.get('exemptions');
				var exemption = exemptions.filterBy('jurisdiction', jurisdiction).findBy('taxType', taxType);
				if (exemption) {
					return exemption.get('exemptionStatus') !== _componentLibraryConstantsPayroll.ExemptionStatus.IS_NOT_EXEMPT;
				} else if (isFederal) {
					// always create CompanyExemption for FED + FUTA https://jira.inside-zen.com/browse/PYP-4042
					this.createExemption(jurisdiction, taxType, _componentLibraryConstantsPayroll.ExemptionStatus.IS_NOT_EXEMPT);
				}
				return false;
			} else {
				if (isFederal) {
					this.updateExemption(jurisdiction, taxType, value ? _componentLibraryConstantsPayroll.ExemptionStatus.IS_EXEMPT : _componentLibraryConstantsPayroll.ExemptionStatus.IS_NOT_EXEMPT);
				} else {
					this.updateExemption(jurisdiction, taxType, value ? _componentLibraryConstantsPayroll.ExemptionStatus.REIMBURSABLE : _componentLibraryConstantsPayroll.ExemptionStatus.IS_NOT_EXEMPT);
					this.updateSutaAndSurcharges(value);
				}
				return value;
			}
		}),

		showAtLeastOneRiskCodeError: _ember['default'].computed('wrappedWorkersCompRecordArray.[]', function () {
			var wrappedWorkersCompRecordArray = this.get('wrappedWorkersCompRecordArray');
			return _ember['default'].isEmpty(wrappedWorkersCompRecordArray);
		}),

		hasDuplicateWorkersCompRecord: _ember['default'].computed('wrappedWorkersCompRecordArray.@each.classCode', 'wrappedWorkersCompRecordArray.[]', function () {
			var wrappedWorkersCompRecordArray = this.get('wrappedWorkersCompRecordArray');
			if (_ember['default'].isEmpty(wrappedWorkersCompRecordArray)) return false;
			var classCodeArray = wrappedWorkersCompRecordArray.mapBy('classCode');
			return classCodeArray.length > classCodeArray.uniq().length;
		}),

		actions: {

			addWorkersComp: function addWorkersComp() {
				var _this = this;

				var newWrappedWorkersCompRecord = _ember['default'].Object.create({ classCode: '' });
				[_componentLibraryConstantsPayroll.TaxPayee.EMPLOYER, _componentLibraryConstantsPayroll.TaxPayee.EMPLOYEE].forEach(function (taxPayee) {
					var wcTaxRate = window.App.ZPayrollCompanyJurisdictionTaxRate.createRecord({
						zpCompanyJurisdiction: _this.get('zpCompanyJurisdiction'),
						year: (0, _componentLibraryUtilsGlobals.moment)().year(),
						taxType: 'WC',
						rate: 0,
						taxPayee: taxPayee,
						workersCompClassCode: ''
					});

					_this.get('zpCompanyJurisdiction.zpCompanyJurisdictionTaxRates').pushObject(wcTaxRate);
					newWrappedWorkersCompRecord[taxPayee == _componentLibraryConstantsPayroll.TaxPayee.EMPLOYER ? 'employerRateObj' : 'employeeRateObj'] = wcTaxRate;
				});
				newWrappedWorkersCompRecord['employeeRate'] = 0;
				newWrappedWorkersCompRecord['hourlyRate'] = 0;
				this.get('wrappedWorkersCompRecordArray').pushObject(newWrappedWorkersCompRecord);
				this.set('showAtLeastOneRiskCodeError', false);
			},

			deleteWorkersComp: function deleteWorkersComp(wrappedWorkersCompRecord) {
				var _this2 = this;

				var wrappedWorkersCompRecordArray = this.get('wrappedWorkersCompRecordArray');
				if (wrappedWorkersCompRecordArray.length == 1) {
					this.set('showAtLeastOneRiskCodeError', true);
					return;
				}

				var matchedIndex = undefined;
				wrappedWorkersCompRecordArray.forEach(function (records, index) {
					if (records.employerRateObj.get('workersCompClassCode') == wrappedWorkersCompRecord.employerRateObj.get('workersCompClassCode')) {
						matchedIndex = index;
					}
				});
				if (matchedIndex >= 0) {
					wrappedWorkersCompRecordArray.removeAt(matchedIndex);
					[_componentLibraryConstantsPayroll.TaxPayee.EMPLOYER, _componentLibraryConstantsPayroll.TaxPayee.EMPLOYEE].forEach(function (taxPayee) {
						var taxRateRecord = wrappedWorkersCompRecord[taxPayee == _componentLibraryConstantsPayroll.TaxPayee.EMPLOYER ? 'employerRateObj' : 'employeeRateObj'];
						if (taxRateRecord) {
							_this2.get('recordsToDelete').pushObject(taxRateRecord);
						}
					});
				}
			}
		}

	});
});