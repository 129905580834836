define('component-library/utils/react-app/find-js-assets', ['exports'], function (exports) {
	'use strict';

	exports['default'] = findJsAssets;

	var OMIT_ASSETS = ['babel-polyfill'];
	function findJsAssets(htmlContent, appName) {
		var re = new RegExp('src="(.*?)/app/' + appName + '/(.*?).([^.]*).js"></script>', 'g');
		var assets = [];
		var match = re.exec(htmlContent);
		while (match) {
			var assetOrigin = match[1];
			var assetName = match[2];
			var assetHash = match[3];
			if (OMIT_ASSETS.indexOf(assetName) === -1) {
				assets.push({ assetOrigin: assetOrigin, assetName: assetName, assetHash: assetHash });
			}
			match = re.exec(htmlContent);
		}

		return assets;
	}
});