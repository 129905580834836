define('component-library/components/z-text-field-date', ['exports', 'ember', 'z-inputs/components/z-text-field-base'], function (exports, _ember, _zInputsComponentsZTextFieldBase) {
	'use strict';

	window.Inputmask.extendAliases({
		"mm / dd / yyyy": {
			mask: "1 / 2 / y",
			placeholder: "mm / dd / yyyy",
			leapday: "02 / 29",
			separator: " / ",
			alias: "mm/dd/yyyy"
		}
	});

	exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend({
		classNames: 'z-textField--date',
		type: 'date',
		isDate: true,
		mask: 'mm / dd / yyyy',
		minDate: null,
		maxDate: null,
		shadowPlaceholder: 'mm / dd / yyyy',
		showShadowPlaceholder: true,
		showIcon: true,
		hidePlaceholderOnFocus: true,
		tooltipText: null,
		excludeWeekend: false,
		hideDatePickerPopover: false,
		zIndexLevel: false,
		popoverClass: '',
		entryInteractionEventsHack: ['click'],

		datePickerPopoverClass: _ember['default'].computed('zIndexLevel', 'popoverClass', function () {
			var zIndexLevel = this.get('zIndexLevel');
			var zIndexClass = zIndexLevel ? 'zIndex-level--' + (zIndexLevel + 1) : '';
			var popoverClass = this.get('popoverClass');
			return 'z-datePickerPopover ' + popoverClass + ' ' + zIndexClass;
		}),

		formattedDateValue: _ember['default'].computed('value', {
			get: function get(key) {
				var val = this.get('value');
				if (!_ember['default'].isEmpty(val)) {
					return val.replace(/\//g, ' / ');
				} else {
					return '';
				}
			},
			set: function set(key, value) {
				if (!_ember['default'].isNone(value)) {
					var formattedValue = value.toString().replace(/\s+/g, '');
					this.set('value', formattedValue);
					if (!this.get('hideDatePickerPopover') && formattedValue.length == 10) {
						this.set('datePickerDisplayDate', formattedValue);
						this.set('datePickerSelectedDate', formattedValue);
					}
				}
				return value;
			}
		}),

		// Initialize the date picker values
		datePickerDisplayDate: _ember['default'].computed.reads('formattedDateValue'),
		datePickerSelectedDate: _ember['default'].computed.reads('formattedDateValue'),

		actions: {
			close: function close(popoverHide, newDate) {
				//Ember 1.13 does not support the quantum binding trick that popover-link used to sync data between
				//this component and the date-picker-popover component. This is better anyway...
				if (newDate) {
					this.set('value', newDate);
				}
				popoverHide();
			},
			resetDatePickerDisplayDate: function resetDatePickerDisplayDate() {
				this.set('datePickerDisplayDate', this.get('value'));
			},
			preventPropagation: function preventPropagation(event) {
				// Prevents the parent popoverlink from closing
				// This is used in case of a z-popover nested inside of the
				// deprecated popover-link
				event.stopPropagation();
			}
		}
	});
});