define('component-library/mixins/payroll/workers-comp-mixin', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	var WC_RATE_LIMIT = 2200;

	exports['default'] = _ember['default'].Mixin.create({
		isWorkersCompRequired: function isWorkersCompRequired(zpCompanyJurisdiction) {
			return zpCompanyJurisdiction && zpCompanyJurisdiction.get('jurisdiction') == 'WA';
		},

		currentYear: _ember['default'].computed(function () {
			return (0, _componentLibraryUtilsGlobals.moment)().year();
		}),

		// used in route.js from client-app and console-app
		filterToGetCurrentYearWcRates: function filterToGetCurrentYearWcRates(zpCompanyJurisdictionTaxRates) {
			return zpCompanyJurisdictionTaxRates.filterBy('isWorkersComp').filterBy('year', this.get('currentYear'));
		},

		prepareWrappedWcRecords: function prepareWrappedWcRecords(zpCompanyJurisdiction) {
			var _this = this;

			var workersCompTaxRates = this.filterToGetCurrentYearWcRates(zpCompanyJurisdiction.get('zpCompanyJurisdictionTaxRates'));

			// A wrappedWorkersCompRecord is of format:
			// {
			// 	'classCode': (string),
			// 	'employeeRate': (number),
			// 	'hourlyRate': (number)
			// 	'employerRateObj': (zpCompanyJurisdictionTaxRate),
			// 	'employeeRateObj': (zpCompanyJurisdictionTaxRate)
			// }
			var classCodeToWrappedWcRecords = workersCompTaxRates.reduce(function (res, item) {
				var classCode = item.get('workersCompClassCode');
				if (!(classCode in res)) {
					res[classCode] = _ember['default'].Object.create({ classCode: classCode });
				}
				res[classCode][item.get('taxPayee') == 'ER' ? 'employerRateObj' : 'employeeRateObj'] = item;
				return res;
			}, {});

			var wrappedWorkersCompRecordArray = Object.values(classCodeToWrappedWcRecords);
			wrappedWorkersCompRecordArray.forEach(function (record) {
				// to guard against incomplete data, i.e. when only ee or er portion of the wc exists
				var missingTaxPayee = undefined;
				if (!record.employeeRateObj) missingTaxPayee = 'EE';
				if (!record.employerRateObj) missingTaxPayee = 'ER';
				if (missingTaxPayee) {
					record[missingTaxPayee == 'ER' ? 'employerRateObj' : 'employeeRateObj'] = window.App.ZPayrollCompanyJurisdictionTaxRate.createRecord({
						zpCompanyJurisdiction: zpCompanyJurisdiction,
						year: _this.get('currentYear'),
						taxType: 'WC',
						rate: 0,
						taxPayee: missingTaxPayee,
						workersCompClassCode: record.classCode
					});
				}

				record['employeeRate'] = record.employeeRateObj.get('rate');
				record['hourlyRate'] = record.employeeRateObj.get('rate') + record.employerRateObj.get('rate');
			});
			return wrappedWorkersCompRecordArray;
		},

		// returns true if there's an error related to wc rates setup
		validateWcRates: function validateWcRates(zpCompanyJurisdiction) {
			var wrappedWorkersCompRecordArray = this.get('wrappedWorkersCompRecordArray');
			if (_ember['default'].isEmpty(wrappedWorkersCompRecordArray)) {
				return this.isWorkersCompRequired(zpCompanyJurisdiction);
			}

			// wrappedWorkersCompRecordArray is non empty only when we need to set up WC
			var hasError = false;
			var errorToMessages = {
				emptyRiskCode: 'This field is missing.',
				wrongHourlyRate: 'Hourly Rate should be greater than or equal to Employee Withholding.',
				wrongRate: 'This field is invalid.'
			};

			wrappedWorkersCompRecordArray.forEach(function (record) {
				var classCodeError = undefined,
				    employeeRateError = undefined,
				    hourlyRateError = undefined;

				if (_ember['default'].isEmpty(record.classCode)) {
					classCodeError = errorToMessages.emptyRiskCode;
				}

				var employeeRate = Number(record.employeeRate);
				if (employeeRate < 0 || employeeRate > WC_RATE_LIMIT) {
					employeeRateError = errorToMessages.wrongRate;
				}

				var hourlyRate = Number(record.hourlyRate);
				if (hourlyRate - employeeRate > WC_RATE_LIMIT) {
					hourlyRateError = errorToMessages.wrongRate;
				} else if (hourlyRate < employeeRate) {
					hourlyRateError = errorToMessages.wrongHourlyRate;
				}
				var errors = [classCodeError, employeeRateError, hourlyRateError];
				record.setProperties({
					classCodeError: classCodeError,
					employeeRateError: employeeRateError,
					hourlyRateError: hourlyRateError
				});
				hasError = hasError || errors.any(function (error) {
					return !_ember['default'].isEmpty(error);
				});
			});

			// check duplicate
			var classCodeArray = this.get('wrappedWorkersCompRecordArray').mapBy('classCode');
			var hasDuplicate = classCodeArray.length > classCodeArray.uniq().length;
			return hasError || hasDuplicate;
		},

		writeRiskCodeToWcRecords: function writeRiskCodeToWcRecords() {
			var wrappedWorkersCompRecordArray = this.get('wrappedWorkersCompRecordArray');
			wrappedWorkersCompRecordArray.forEach(function (record) {
				var classCode = record.classCode;
				record.employeeRateObj.setProperties({
					workersCompClassCode: classCode,
					rate: record.employeeRate
				});
				record.employerRateObj.setProperties({
					workersCompClassCode: classCode,
					rate: record.hourlyRate - record.employeeRate
				});
			});
		}
	});
});