define('component-library/timeline/components/fulfillment-event', ['exports', 'component-library/timeline/components/copy', 'component-library/timeline/mixins/feed-entry', 'component-library/timeline/utils/status', 'component-library/timeline/utils/computed-copy', 'component-library/utils/tags/context-tag-computed'], function (exports, _componentLibraryTimelineComponentsCopy, _componentLibraryTimelineMixinsFeedEntry, _componentLibraryTimelineUtilsStatus, _componentLibraryTimelineUtilsComputedCopy, _componentLibraryUtilsTagsContextTagComputed) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['', 's application was submitted to  ', ' for ', ' but their coverage has not yet been confirmed'], ['', 's application was submitted to  ', ' for ', ' but their coverage has not yet been confirmed']),
	    _templateObject2 = _taggedTemplateLiteral(['', 's application was created with ', ' for ', ' and is expected to be submitted'], ['', 's application was created with ', ' for ', ' and is expected to be submitted']),
	    _templateObject3 = _taggedTemplateLiteral(['', 's application was submitted to  ', ' for ', ' and the confirmation of the enrollment is expected'], ['', 's application was submitted to  ', ' for ', ' and the confirmation of the enrollment is expected']),
	    _templateObject4 = _taggedTemplateLiteral(['', 's coverage with ', ' for ', ' was confirmed'], ['', 's coverage with ', ' for ', ' was confirmed']);

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	exports['default'] = _componentLibraryTimelineComponentsCopy['default'].extend(_componentLibraryTimelineMixinsFeedEntry['default'], {
		copy: _componentLibraryTimelineUtilsComputedCopy['default'].apply(undefined, _toConsumableArray(_componentLibraryTimelineUtilsStatus.fulfillmentHealthEventStatusTypes)),
		ARG_WQ_CLOSURE: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject, 'companyStr', 'carrierNameStr', 'lineOfCoverageStr'),
		ARG_WQ_CREATION: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject2, 'companyStr', 'carrierNameStr', 'lineOfCoverageStr'),
		FARG_WQ_CREATION: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject3, 'companyStr', 'carrierNameStr', 'lineOfCoverageStr'),
		FARG_WQ_CLOSURE: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject4, 'companyStr', 'carrierNameStr', 'lineOfCoverageStr')

	});
});