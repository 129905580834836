define('component-library/wizard/flow/controller', ['exports', 'ember', 'component-library/wizard/state/flow'], function (exports, _ember, _componentLibraryWizardStateFlow) {
	'use strict';

	// TODO(DQ): action handlers for saveWizardState requests?
	// TODO(DQ): refactor promises on laddas for always...
	var SCREEN_TYPES = {
		STEP: 'step',
		FLOW_INDEX: 'flowIndex'
	};

	exports['default'] = _ember['default'].Controller.extend({
		// OVERRIDES - begin
		wizardService: null,
		serviceEnabled: false,

		newWizardFlowState: function newWizardFlowState() {
			return _componentLibraryWizardStateFlow['default'].create({});
		},

		// override if hooks are needed after steps are complete, may return a promise
		stepCompletionChanged: function stepCompletionChanged(section, step) {},

		flowComplete: function flowComplete() {},

		isOverviewRevealed: true,

		// OVERRIDES - end

		wizardFlowState: null, // automatically set by route
		_lastWizardScreen: {
			screenType: null,
			sectionKey: null
		},

		_setStepCompletion: function _setStepCompletion(sectionKey, stepKey, isComplete) {
			var flow = this.get('wizardFlowState');
			var section = flow.getSection(sectionKey);
			var step = section.getStep(stepKey);
			step.setComplete(isComplete);
			var stepCompletionPromise = this.stepCompletionChanged(section, step);
			if (stepCompletionPromise) {
				return stepCompletionPromise;
			} else {
				return new _ember['default'].RSVP.Promise(function (resolve, reject) {
					resolve();
				});
			}
		},

		_transitionToNextStep: function _transitionToNextStep() {
			this._transitionToStep(this.get('_nextStep'));
		},

		_transitionToPreviousStep: function _transitionToPreviousStep() {
			this._transitionToStep(this.get('_previousStep'));
		},

		_transitionToStepIndex: function _transitionToStepIndex(sectionKey, stepIndex) {
			var flow = this.get('wizardFlowState');
			var section = flow.getSection(sectionKey);
			var steps = section.get('steps');

			if (stepIndex < 0 || stepIndex >= steps.length) {
				return;
			}

			var step = steps[stepIndex];
			this._transitionToStep(step);
		},

		_transitionToStepKey: function _transitionToStepKey(sectionKey, stepKey) {
			var flow = this.get('wizardFlowState');
			var section = flow.getSection(sectionKey);
			if (section == null) {
				return;
			}

			var step = section.getStep(stepKey);
			if (step == null) {
				return;
			}

			this._transitionToStep(step);
		},

		_transitionToFirstIncompleteStep: function _transitionToFirstIncompleteStep(sectionKey) {
			var flow = this.get('wizardFlowState');
			var section = flow.getSection(sectionKey);
			var steps = section.get('steps');

			var firstIncompleteStep = null;
			var foundIncomplete = false;
			for (var i = 0; i < steps.length; i++) {
				firstIncompleteStep = steps[i];
				if (!steps[i].get('isComplete')) {
					foundIncomplete = true;
					break;
				}
			}

			if (foundIncomplete) {
				this._transitionToStep(firstIncompleteStep);
			} else {
				this._transitionToStepIndex(sectionKey, 0);
			}
		},

		_transitionToNextIncompleteStep: function _transitionToNextIncompleteStep(sectionKey) {
			var flow = this.get('wizardFlowState');
			var section = flow.getSection(sectionKey);
			var steps = section.get('steps');

			var firstIncompleteStep = null;
			var foundIncomplete = false;
			for (var i = section.get('currentStepIndex'); i < steps.length; i++) {
				firstIncompleteStep = steps[i];
				if (!steps[i].get('isComplete')) {
					foundIncomplete = true;
					break;
				}
			}

			if (foundIncomplete) {
				this._transitionToStep(firstIncompleteStep);
			} else {
				this._transitionToStepIndex(sectionKey, 0);
			}
		},

		_transitionToFlowIndex: function _transitionToFlowIndex(sectionKey, stepKey) {
			var flow = this.get('wizardFlowState');
			this._transitionToStep(flow);
		},

		_transitionToTerminalRoute: function _transitionToTerminalRoute() {
			var terminalRoute = this.get('wizardFlowState.terminalRoute');
			if (terminalRoute !== null) {
				// since '' is a valid route
				this.transitionTo(terminalRoute);
			}
		},

		_transitionToStep: function _transitionToStep(step) {
			if (step == null) {
				return;
			}
			var model = step.get('model');
			var opts = step.get('opts');
			var args = [step.get('routeName')];

			if (model) {
				args.push(model);
			}
			if (opts) {
				args.push(opts);
			}
			this.transitionToRoute.apply(this, args);
		},

		_nextStep: (function () {
			var flow = this.get('wizardFlowState');
			var section = flow.get('currentSection');
			var nextStep = section.get('nextStep');
			var nextSection = flow.get('nextSection');
			var terminalRoute = flow.get('terminalRoute');

			if (nextStep) {
				return nextStep;
			} else if (flow.get('hasCustomFlowIndex')) {
				return flow;
			} else if (nextSection) {
				// showing the wizard overview when navigating to a new section -- for new wizard flow
				if (nextSection.get('revealOverviewOnEnter')) {
					this.set('isOverviewRevealed', true);
				}
				return flow.get('nextSection.steps.firstObject');
			} else if (terminalRoute && flow.get('flowComplete')) {
				this.flowComplete();
				return _ember['default'].Object.create({ routeName: terminalRoute });
			} else {
				return flow;
			}
		}).property('wizardFlowState.currentSection', 'wizardFlowState.currentSection.steps.@each', 'wizardFlowState.currentSection.nextStep'),

		_previousStep: (function () {
			var flow = this.get('wizardFlowState');
			var section = flow.get('currentSection');
			var previousStep = section.get('previousStep');
			var previousSection = flow.get('previousSection');

			if (previousStep) {
				return previousStep;
			} else if (previousSection) {
				// showing the wizard overview when navigating to a new section -- for new wizard flow
				if (previousSection.get('revealOverviewOnEnter')) {
					this.set('isOverviewRevealed', true);
				}
				return flow.get('previousSection.steps.lastObject');
			} else {
				return flow;
			}
		}).property('wizardFlowState.currentSection.steps.@each', 'wizardFlowState.currentSection.previousStep'),

		// TODO(DQ): rename to _visited..
		_updateCurrentSection: function _updateCurrentSection(sectionKey) {
			var flow = this.get('wizardFlowState');
			var newCurrentSection = flow.getSection(sectionKey);
			flow.set('currentSectionIndex', newCurrentSection.get('index'));
		},

		// TODO(DQ): rename to _visited..
		_updateCurrentStep: function _updateCurrentStep(sectionKey, stepKey) {
			this._updateCurrentSection(sectionKey);

			var flow = this.get('wizardFlowState');
			var section = flow.get('currentSection');
			var newCurrentStep = section.getStep(stepKey);

			if (newCurrentStep) {
				section.set('currentStepIndex', newCurrentStep.get('index'));
			} // else {
			//	console.error("Section '" + section.get('sectionKey') + "' is missing step '" + stepKey + "'. Check your section initialization or branching logic.");
			//}
			this._observesScreenTransition({
				screenType: SCREEN_TYPES.STEP,
				sectionKey: sectionKey,
				stepKey: stepKey
			});
		},

		_visitedFlowIndex: function _visitedFlowIndex() {
			this._observesScreenTransition({
				screenType: SCREEN_TYPES.FLOW_INDEX
			});
		},

		_observesScreenTransition: function _observesScreenTransition(currentScreen) {
			var lastScreen = this.get('_lastWizardScreen');

			// check for flow index entry
			if (lastScreen.screenType != SCREEN_TYPES.FLOW_INDEX && currentScreen.screenType == SCREEN_TYPES.FLOW_INDEX) {
				this.send('enteringFlowIndex', lastScreen);
			}
			// check for section to section transition
			else if (currentScreen.sectionKey && lastScreen.sectionKey != currentScreen.sectionKey) {
					this.send('enteringSection', currentScreen.sectionKey);
				}

			this.set('_lastWizardScreen', currentScreen);
		},

		actions: {
			_showOverview: function _showOverview(reveal) {
				this.set('isOverviewRevealed', reveal);
			},

			_handleWizardAction: function _handleWizardAction(method) {
				this[method].apply(this, Array.prototype.slice.call(arguments).slice(1));
			}
		}
	});
});