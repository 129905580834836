define('component-library/utils/has-actual-value', ['exports'], function (exports) {
	// Source: https://github.com/mde/utilities/blob/master/lib/core.js#L88
	// Idea to add invalid number & Date from Michael J. Ryan,
	// http://frugalcoder.us/post/2010/02/15/js-is-empty.aspx
	'use strict';

	exports['default'] = function (val) {
		// Empty string, null or undefined (these two are double-equal)
		if (val === '' || val == null) {
			return false;
		}
		// Invalid numerics
		if (typeof val == 'number' && isNaN(val)) {
			return false;
		}
		// Invalid Dates
		if (val instanceof Date && isNaN(val.getTime())) {
			return false;
		}
		return true;
	};
});