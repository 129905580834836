define('component-library/simple-wizard/route', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Route.extend({
		scrollToTopAfterRouteTransition: false,
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			// If the simple wizard is driven by backend flow, we don't need these
			if (controller.newSimpleWizardFlow) {
				var simpleWizardFlow = controller.newSimpleWizardFlow();
				model.simpleWizardFlow = simpleWizardFlow;
				controller.set('simpleWizardFlow', simpleWizardFlow);
			}
		},

		actions: {
			_transitionToNextStep_action: function _transitionToNextStep_action() {
				var currentRoute = this.controllerFor('application').get('currentRouteName');
				this.get('controller').transitionToNextStep(currentRoute);
			},
			_transitionToPreviousStep_action: function _transitionToPreviousStep_action() {
				var currentRoute = this.controllerFor('application').get('currentRouteName');
				this.get('controller').transitionToPreviousStep(currentRoute);
			},
			_transitionToNextIncompleteStep_action: function _transitionToNextIncompleteStep_action() {
				var currentRoute = this.controllerFor('application').get('currentRouteName');
				this.get('controller').transitionToNextIncompleteStep(currentRoute);
			},
			_transitionToPreviousIncompleteStep_action: function _transitionToPreviousIncompleteStep_action() {
				var currentRoute = this.controllerFor('application').get('currentRouteName');
				this.get('controller').transitionToPreviousIncompleteStep(currentRoute);
			},
			_transitionToStepRoute_action: function _transitionToStepRoute_action(routeName) {
				this.get('controller').transitionToStepRoute(routeName);
			},
			_setActiveRoute_action: function _setActiveRoute_action(activeRoute) {
				this.get('controller')._setActiveRoute(activeRoute);
			},
			_updateSimpleWizardFlow: function _updateSimpleWizardFlow() {
				this.get('controller')._updateSimpleWizardFlow();
			},
			didTransition: function didTransition() {
				_ember['default'].run.once(this, function () {
					var controller = this.controllerFor('application');
					var lastWindow = this.get('router.url').split('/').splice(-1)[0];
					var modelValue = !isNaN(lastWindow) ? lastWindow : '';
					this.send('_setActiveRoute_action', controller.get('currentRouteName') + modelValue);
				});
				if (this.get('scrollToTopAfterRouteTransition')) {
					_ember['default'].$('.js-simple-wizard-flow-layout').scrollTop(0);
				}
				return true;
			}
		}
	});
});