define('component-library/components/cost-breakdown', ['exports', 'ember', 'component-library/templates/components/cost-breakdown'], function (exports, _ember, _componentLibraryTemplatesComponentsCostBreakdown) {
	'use strict';

	var COST_BREAKDOWN_DOT_COLORS = {
		PRIMARY: 'primary',
		SECONDARY: 'secondary'
	};

	exports.COST_BREAKDOWN_DOT_COLORS = COST_BREAKDOWN_DOT_COLORS;
	exports['default'] = _ember['default'].Component.extend({
		template: _componentLibraryTemplatesComponentsCostBreakdown['default'],
		classNames: ['z-costBreakdown', 'grid-block', 'vertical'],

		title: '',
		description: '',
		tooltipText: '',
		footnote: '',
		percentage: 0,
		// LineItems should be an array of objects, where each object looks like this:
		// 	{
		// 		dotColor: 'primary', //'primary', 'secondary', or '' for invisible. Use primary for numerator in completion.
		// 		label: 'You', //'You', 'Employee', 'Employer', etc
		// 		value: '$0',
		// 		labelClass: null, // string of css classes for line-item label
		// 	}
		lineItems: [],

		showFullBreakdown: _ember['default'].computed('percentage', function () {
			return this.get('percentage') > 0 && this.get('percentage') < 100;
		})
	});
});