define('z-table/components/z-table', ['exports', 'ember', 'z-table/templates/components/z-table'], function (exports, _ember, _zTableTemplatesComponentsZTable) {
	'use strict';

	var computed = _ember['default'].computed;

	exports['default'] = _ember['default'].Component.extend({
		layout: _zTableTemplatesComponentsZTable['default'],
		tagName: 'table',
		attributeBindings: ['dataTest:data-test'],
		dataTest: 'z-table',
		classNames: ['z-table'],
		items: computed(function () {
			return [];
		}), // initialize to empty array
		selectedItem: null,
		onSelectItem: function onSelectItem() {},
		sortAction: function sortAction() {}
	});
});