define('component-library/components/d-inline-text-field', ['exports', 'ember', 'component-library/components/z-inline-base'], function (exports, _ember, _componentLibraryComponentsZInlineBase) {
	'use strict';

	// TODO(Andrew V3) replace Jamie's similar components

	exports['default'] = _componentLibraryComponentsZInlineBase['default'].extend({
		init: function init() {
			this._super();
			_ember['default'].deprecate('this inline component is deprecated', false, { id: 'inline-component-deprecation' });
		},

		textFieldType: 'z-text-field-basic',
		propertyName: null,
		propertyValidation: null,
		hideLabel: false,

		// #z-text-field-* PARAMS
		hint: null,
		maxLength: null,
		minDate: null,
		shadowUnitSuffix: null,
		rows: 2,
		syncHeight: true,
		min: undefined, // these can't be null or the input is uneditable within {{z-text-field number}}
		max: undefined, // these can't be null or the input is uneditable within {{z-text-field number}}

		// INTERNAL PARAMETERS
		classNames: ['js-glue-zInlineTextField'],
		isTextFieldTextarea: _ember['default'].computed.equal('textFieldType', 'z-text-field-textarea'),
		focusClassName: _ember['default'].computed('isTextFieldTextarea', function () {
			// .js-z-textField-* classes already exist within the {{z-text-field-*}} components
			return this.get('isTextFieldTextarea') ? '.js-z-textField-textarea' : '.js-z-textField-input';
		}),
		propertyNameObjects: [{ internalProperty: 'value', modelPropertyName: 'propertyName' }],
		// value set on init hook according to propertyNameObjects
		value: null,

		hasError: false,
		errorTextComputed: _ember['default'].computed('errorText', 'hasError', function () {
			return this.get('hasError') ? this.get('errorText') : null;
		}),

		actions: {
			save: function save() {
				var propertyValidation = this.get('propertyValidation');
				var value = this.get('value');
				var hasError = undefined;

				// if validation exists update 'hasError' and return if hasError
				if (propertyValidation instanceof RegExp) {
					hasError = !value.match(propertyValidation);
				} else if (typeof propertyValidation == 'function') {
					hasError = !propertyValidation(value);
				}
				this.set('hasError', hasError);
				if (hasError) {
					this._focusOnInput();
					return;
				}

				this._super();
			},
			cancel: function cancel() {
				this.set('hasError', false);
				this._super();
			}
		}
	});
});