define('component-library/components/promise-overlay', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var container = null;
	function getContainer() {
		if (!container) {
			var containerId = _ember['default'].$('.js-overlay-container').attr('id');
			container = _ember['default'].View.views[containerId];
		}
		return container;
	}

	var OverlayComponent = _ember['default'].Component.extend({
		classNameBindings: ['zIndexLevelClass'],
		classNames: ['js-walkme-modal'],
		switches: _ember['default'].inject.service(),

		zIndexLevelClass: (function () {
			return 'zIndex-level--%@'.fmt(this.get('zIndexLevel'));
		}).property('zIndexLevel'),

		_close: function _close() {
			getContainer().removeChild(this);
			this.destroy();
		},

		// NOTE: ember is terrible, the component_template_deprecation removes
		// templateName from the props which forces us to have to specify
		// templateName when we call modal.open({ templateName: 'foo' })
		// this fixes that problem
		willMergeMixin: function willMergeMixin(props) {
			var templateName = props.templateName;
			this._super.apply(this, arguments);
			props.templateName = templateName;
		}
	});

	OverlayComponent.reopenClass({
		open: function open() {
			_ember['default'].deprecate('Usage of promiseOverlay.open is deprecated, use promiseOverlay.show instead.', false, {
				id: 'promise-overlay-deferred'
			});
			return {
				promise: this.show.apply(this, arguments)
			};
		},
		show: function show() {
			var obj = this.create.apply(this, arguments);
			// Adjust zIndexLevel
			var container = getContainer();
			container.pushObject(obj);
			container.forEach(function (view, index) {
				view.set('zIndexLevel', index + 1);
			});

			// Create and return a promise
			var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
				obj.set('_resolve', resolve);
				obj.set('_reject', reject);
			});
			return promise;
		}
	});

	exports['default'] = OverlayComponent;
});