define('component-library/mixins/census/spreadsheet-mixin', ['exports', 'ember', 'lodash/lodash', 'component-library/utils/z-spreadsheet/configuration', 'component-library/components/processing-modal', 'component-library/components/census/census-sheet-exception-modal', 'component-library/constants/census-constants', 'component-library/utils/globals'], function (exports, _ember, _lodashLodash, _componentLibraryUtilsZSpreadsheetConfiguration, _componentLibraryComponentsProcessingModal, _componentLibraryComponentsCensusCensusSheetExceptionModal, _componentLibraryConstantsCensusConstants, _componentLibraryUtilsGlobals) {
	'use strict';

	var PROMOTION_STATUS_POLLING_INTERVAL = 3000;
	var MAX_POLLING_RETRIES = 3;

	exports['default'] = _ember['default'].Mixin.create(_ember['default'].Evented, {

		// Import census services
		censusService: _ember['default'].inject.service('census'),
		censusState: _ember['default'].computed.alias('censusService.censusState'),

		// Please override this from controller
		schemaDefinition: null,
		schemaDefinitionForOptionalFields: null,

		// Configurations for census type (assigned in setupController)
		flowConfigOptions: {},

		// Optional function to set cells to read-only. Function should have an api of
		// function(row, col, prop, cellValue, cellSource, sourceData, schema), where the
		// cellValue is the handsontable-stored value and sourceData is the source for ALL cells in
		// case you want to compare against values in other cells.
		// Return true from the function if you want a particular cell to be read-only.
		// Example:
		// readOnlyFunction: function(row, col, prop, cellValue, sourceData) {
		// 	return cellValue === 'readonly';
		// },
		// Note: "prop" is now just the col number since we translate to array-of-arrays before
		// passing the source into handsontable
		readOnlyFunction: function readOnlyFunction(row, col, prop, cellValue, sourceData, schema) {
			// IF YOU OVERRIDE THIS FUNCTION, BE WARE OF THE ZENEFITS ID COL - YOU MAY STILL
			// WANT TO KEEP IT AS READ-ONLY!!!
			var colReadOnly = schema && schema[col] && !!schema[col].readOnly;
			var cellReadOnly = sourceData && sourceData[row] && sourceData[row].readOnly && sourceData[row].readOnly[col];
			return colReadOnly || cellReadOnly;
		},
		createBlankRecordFunction: function createBlankRecordFunction() {
			return { data: {} };
		},
		errorCount: 0,
		columnSorting: false,
		hasAttemptedSpreadsheetSubmit: false,
		saveButtonText: _ember['default'].computed.reads('censusConfigOptions.saveButtonText'),
		initialSaveButtonText: _ember['default'].computed.reads('censusConfigOptions.saveButtonText'),
		processingModalInstance: null,
		dirtyRealIds: {},

		_reset: function _reset() {
			this.setProperties({
				errorCount: 0,
				hasAttemptedSpreadsheetSubmit: false,
				saveButtonText: this.get('censusConfigOptions.saveButtonText'),
				dirtyRealIds: {},
				pollingHandle: null
			});
		},

		// for the purpose of logging
		_submitStartTimestamp: null,

		// Config options which are flowConfigOptions combined with defaults.
		censusConfigOptions: (function () {
			return _ember['default'].Object.create(this.get('flowConfigOptions'));
		}).property('flowConfigOptions'),

		bannerMode: (function () {
			if (!this.get('hasAttemptedSpreadsheetSubmit')) {
				return 'info';
			}

			return this.get('errorCount') > 0 ? 'error' : 'success';
		}).property('hasAttemptedSpreadsheetSubmit', 'errorCount'),

		bannerText: (function () {
			if (!this.get('hasAttemptedSpreadsheetSubmit')) {
				return this.get('censusConfigOptions.bannerInitial');
			}

			var errorCount = this.get('errorCount');

			if (errorCount > 0) {
				return 'We found ' + errorCount + ' error' + (errorCount === 1 ? '' : 's') + '. Please correct them before proceeding.';
			} else {
				return this.get('censusConfigOptions.bannerFixedErrors');
			}
		}).property('hasAttemptedSpreadsheetSubmit', 'errorCount', 'censusConfigOptions.bannerInitial', 'censusConfigOptions.bannerFixedErrors'),

		censusColumns: _ember['default'].computed.alias('censusState.fields'),

		spreadsheetSchema: (function (census) {
			var censusSchema = [];
			var columns = this.get('censusColumns');
			var schemaDefinition = this.get('schemaDefinition');
			var optionalFieldsDefinition = this.get('schemaDefinitionForOptionalFields');
			var optionalFields = this.get('censusState.optionalFields') || [];
			if (schemaDefinition) {
				columns.forEach(function (col) {
					var colSchema = schemaDefinition[col];
					// overwrite the schema if col is among optional fields
					if (optionalFields && optionalFields.contains(col) && optionalFieldsDefinition && optionalFieldsDefinition[col]) {
						colSchema = optionalFieldsDefinition[col];
					}
					if (colSchema) {
						censusSchema.push(colSchema);
					} else {
						// We will ignore cols that don't have a spreadsheet schema. Adding a console.error
						// so it's clear to the developer why the column is not appearing.
						console.error("No schema defined for column '" + col + "'. Skipping.");
					}
				});
			}

			return censusSchema;
		}).property('censusColumns', 'schemaDefinition'),

		// Data to pass to z-spreadsheet. This gets updated on the census
		// getOrCreate, update, promote, etc calls.
		_spreadsheetRecords: (function () {
			return this.get('censusState.records');
		}).property('censusState'),

		_isEmptyRow: function _isEmptyRow(rowObj) {
			var columns = this.get('censusColumns');

			return columns.every(function (col) {
				return _ember['default'].isBlank(rowObj[col]);
			});
		},

		_hasCensusStateErrors: function _hasCensusStateErrors(censusState) {
			var errorsInRecords = censusState.records.some(function (record) {
				return record._errors && Object.keys(record._errors).length;
			});

			return censusState.error || errorsInRecords;
		},

		_censusLogger: function _censusLogger(action, extra) {
			if (_ember['default'].isEmpty(action)) {
				return;
			}
			extra = extra || {};

			if (this.eventLogger) {
				extra.censusId = this.get('censusState.censusId');
				extra.censusRecordsCount = this.get('censusState.records.length');
				this.eventLogger.log(action, extra);
			}
		},

		_censusLogDurationAfterSubmit: function _censusLogDurationAfterSubmit(action) {
			if (this.get('_submitStartTimestamp')) {
				this._censusLogger(action, {
					secondsSinceStart: (0, _componentLibraryUtilsGlobals.moment)().diff(this.get('_submitStartTimestamp'), 'seconds')
				});
			} else {
				this._censusLogger(action, {
					currentTimestamp: (0, _componentLibraryUtilsGlobals.moment)().format()
				});
			}
		},
		_preProcessCensusRecords: function _preProcessCensusRecords(spreadsheetState) {
			var _this = this;

			var newRecord = undefined,
			    recordId = undefined,
			    realId = undefined;
			var records = spreadsheetState.get('data') || [];
			var updatesAndCreationRecordsList = [];
			var deletionRecordsList = [];

			records.forEach(function (record) {
				realId = record.realId;

				if (_this._isEmptyRow(record.data)) {
					if (record.id && !record.realId) {
						//Pre-existing row that has not been tied to a real user yet.
						//Add to the list for deletion.
						deletionRecordsList.push({
							id: record.id,
							data: record.data
						});
					}
					// Else it's just a blank row if no record id exists, so ignore it.
				} else {
						updatesAndCreationRecordsList.push(record);
					}
			});

			return {
				'updatesAndCreationRecordsList': updatesAndCreationRecordsList,
				'deletionRecordsList': deletionRecordsList
			};
		},

		_saveCensusPromise: function _saveCensusPromise(spreadsheetState) {
			var preProcessedResult = this._preProcessCensusRecords(spreadsheetState);
			var censusId = this.get('censusState.censusId');

			return this.get('censusService').update(censusId, preProcessedResult.updatesAndCreationRecordsList, preProcessedResult.deletionRecordsList);
		},

		_showProcessingModal: function _showProcessingModal() {
			var _this2 = this;

			_componentLibraryComponentsProcessingModal['default'].show({
				eventEmitter: this
			}).then(function () {
				// NOOP
			}, function () {
				var pollingHandle = _this2.get('pollingHandle');
				if (pollingHandle) {
					clearInterval(pollingHandle);
					_this2.set('pollingHandle', null);
				}

				_this2._resetCensusSession();
			});
		},

		_resetCensusSession: function _resetCensusSession() {
			var _this3 = this;

			return this.get('censusService').getOrCreate(this.get('censusState.flowName'), this.get('censusState.companyId'), true).then(function () {
				_this3._reset();
			});
		},

		_closeProcessingModal: function _closeProcessingModal() {
			this.trigger('closeProcessingModal');
		},

		_showAddNewEmployeesModal: function _showAddNewEmployeesModal() {
			// Modal is only defined in client-app
		},

		_closeAddNewEmployeesModal: function _closeAddNewEmployeesModal() {
			this.trigger('closeAddNewEmployeesModal');
		},

		_showCensusPromoteExceptionModal: function _showCensusPromoteExceptionModal() {
			var _this4 = this;

			// Note: We should almost never get an exception in the employee creation tasks. However,
			// in the event that our workers are down, this will be a fallback.
			this._closeProcessingModal();

			_componentLibraryComponentsCensusCensusSheetExceptionModal['default'].show()['catch'](function () {
				// "Reset" link is hooked up to PromiseModal "cancel" action,
				// which triggers the promise's catch block.
				_this4._resetCensusSession();
			});
		},

		_promoteCensus: function _promoteCensus(spreadsheetState) {
			var _this5 = this;

			var censusId = this.get('censusState.censusId');
			var dirtyRealIds = this.get('dirtyRealIds') || {};
			var dirtyRealRecords = (spreadsheetState.get('data') || []).filter(function (record) {
				return record.realId && !!dirtyRealIds[record.realId];
			});

			this._showProcessingModal();

			this._censusLogDurationAfterSubmit('census_start_create_eta_tasks');
			return this.get('censusService').promote(censusId, dirtyRealRecords).then(function () {
				_this5._censusLogDurationAfterSubmit('census_create_eta_tasks_finished');
				// Kicked off parallelized worker tasks to create new employees. Poll to see
				// if those tasks have completed. Show a ProcessingModal while the status
				// is still "processing".
				if (_ember['default'].testing) {
					return _ember['default'].RSVP.resolve();
				} else {
					_this5._pollCensusSessionStatus();
					return _ember['default'].RSVP.resolve();
				}
			})['catch'](function () {
				_this5._censusLogDurationAfterSubmit('census_promote_exceptions');
				// Unknown error. Kicking off promotion shouldn't fail...
				_this5._closeProcessingModal();
				_this5._showCensusPromoteExceptionModal();
			});
		},

		_pollCensusSessionStatus: function _pollCensusSessionStatus() {
			var _this6 = this;

			var censusService = this.get('censusService');
			var isPollingComplete = false;
			var numRetries = 0;

			var pollingHandle = setInterval(function () {
				censusService.getStatus().then(function (status) {
					if (isPollingComplete) {
						_this6._censusLogDurationAfterSubmit('census_promote_polling_already_completed');
						return;
					}
					if (status === _componentLibraryConstantsCensusConstants.CENSUS_STATUSES.PROCESSING) {
						_this6._censusLogDurationAfterSubmit('census_promote_polling_processing');
						return;
					}

					_this6._closeProcessingModal();
					isPollingComplete = true;
					clearInterval(pollingHandle);
					_this6.set('pollingHandle', null);

					if (status === _componentLibraryConstantsCensusConstants.CENSUS_STATUSES.COMPLETE) {
						if (typeof _this6.onCensusPromoteSuccess === 'function') {
							_this6._censusLogDurationAfterSubmit('census_promote_polling_completed_successfully');
							return _this6.onCensusPromoteSuccess();
						}
					} else if (status === _componentLibraryConstantsCensusConstants.CENSUS_STATUSES.EXCEPTION || status === _componentLibraryConstantsCensusConstants.CENSUS_STATUSES.EXPIRED) {
						_this6._censusLogDurationAfterSubmit('census_promote_polling_completed_with_exception');
						// Note: We should never see the EXPIRED case, but just treat as an exception for now.
						// We shouldn't really see the EXCEPTION case at all either unless something
						// catastrophicly wrong is happening.
						_this6._showCensusPromoteExceptionModal();
					}

					// ACTIVE case is a no-op. Shouldn't hit that case, either.
				})['catch'](function () {
					if (++numRetries >= MAX_POLLING_RETRIES) {
						clearInterval(pollingHandle);
						_this6.set('pollingHandle', null);
						_this6._showCensusPromoteExceptionModal();
					}
				});
			}, PROMOTION_STATUS_POLLING_INTERVAL);
			this.set('pollingHandle', pollingHandle);
		},

		actions: {
			afterChange: function afterChange(stateObject, source) {
				this.set('errorCount', stateObject.get('errorCount') || 0);
				var dirtyRealIds = this.get('dirtyRealIds') || {};

				stateObject.get('changes').forEach(function (change) {
					var changeRealId = change.get('record.realId');

					if (changeRealId) {
						dirtyRealIds[changeRealId] = 1;
					}
				});

				this.set('dirtyRealIds', dirtyRealIds);
			},

			save: function save(spreadsheetState) {
				var _this7 = this;

				// Todo(DaveTian): Move the copy change logic back to z-spreadsheet component once we figure out a
				// solution on how to to communicate with the component that the promise has been resolved.
				this.set('saveButtonText', 'Saving...');

				var savePromise = this._saveCensusPromise(spreadsheetState);

				this._censusLogger('start_save_census', {});
				savePromise.then(function () {
					_this7._censusLogger('finish_save_census', {});
					_this7.set('saveButtonText', 'Saved');
					// Defensive check
					if (typeof _this7.onCensusSaveSuccess === 'function') {
						return _this7.onCensusSaveSuccess();
					}
				});
			},

			submit: function submit(spreadsheetState, laddaButton) {
				var _this8 = this;

				if (typeof this.markTryCensusTodo === 'function') {
					this.markTryCensusTodo();
				}

				this.set('hasAttemptedSpreadsheetSubmit', true);
				this.set('errorCount', spreadsheetState.get('errorCount') || 0);

				var hasErrors = spreadsheetState.get('hasErrors') || false;

				if (!hasErrors) {
					// If no client-side validation errors, then save first before promoting.
					// Check the returned data from the save call for any server-side validation errors
					// before promoting
					this.set('_submitStartTimestamp', (0, _componentLibraryUtilsGlobals.moment)());
					this._censusLogger('census_submit_start', {
						startTimestamp: this.get('_submitStartTimestamp').format()
					});
					var savePromise = this._saveCensusPromise(spreadsheetState);
					savePromise.then(function (censusState) {

						_this8._censusLogDurationAfterSubmit('save_census_state_finished');
						// If saving returned with validation errors, show errors modal and don't kick off promotion.
						if (_this8._hasCensusStateErrors(censusState) && typeof _this8.onCensusValidationErrorsOnSave === 'function') {
							return _this8.onCensusValidationErrorsOnSave();
						}

						// If save returned successfully, check for server-returned errors
						// If no errors exist, kick off promotion + status polling.
						return _this8._promoteCensus(spreadsheetState);
					})['finally'](function () {
						if (laddaButton) {
							// using run next to make sure the button stopped after modal pop up
							// as the actual rendering of modal is in the next run
							_ember['default'].run.next(function () {
								laddaButton.stop();
							});
						}
					});
				} else {
					if (laddaButton) {
						laddaButton.stop();
					}
				}
			}
		}
	});
});