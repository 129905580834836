define('component-library/deductions/review/details/route', ['exports', 'component-library/routes/detail', 'component-library/utils/deductions/deductions-utils'], function (exports, _componentLibraryRoutesDetail, _componentLibraryUtilsDeductionsDeductionsUtils) {
	'use strict';

	exports['default'] = _componentLibraryRoutesDetail['default'].extend({
		singleDeduction: null,

		beforeModel: function beforeModel() {
			this.keepPreviousSingleDeductionType();
		},

		afterModel: function afterModel(model, transition) {
			this.transitToPropriateSingleIfPossible(model, transition);
		},

		setupController: function setupController(controller, model) {
			this._super(controller, model);
			this.zeneSend('subrouteObjectSet', model);
		},

		keepPreviousSingleDeductionType: function keepPreviousSingleDeductionType() {
			var singleModel = this.modelFor([this.routeName, 'single'].join('.'));
			if (singleModel) {
				this.set('singleDeduction', singleModel);
			}
		},

		transitToPropriateSingleIfPossible: function transitToPropriateSingleIfPossible(model, transition) {
			if (transition.targetName == [this.routeName, 'index'].join('.')) {
				var previousDeductionType = this.get('singleDeduction');
				var allDeductionTypes = model.get('deductionLines');
				var deductionType = null;
				if (previousDeductionType && allDeductionTypes.indexOf(previousDeductionType) >= 0) {
					deductionType = previousDeductionType;
				} else {
					deductionType = (0, _componentLibraryUtilsDeductionsDeductionsUtils.sortDeductionCodes)(allDeductionTypes).get('firstObject');
				}
				if (deductionType) {
					this.transitionTo([this.routeName, 'single'].join('.'), deductionType);
				}
			}
		},

		actions: {
			closeDetails: function closeDetails() {
				this.transitionTo(this.routeName.split('.').slice(0, -1).join('.'));
			},

			switchDetails: function switchDetails(deductionType) {
				this.transitionTo([this.routeName, 'single'].join('.'), deductionType);
			}
		}
	});
});