define('z-tooltips/components/z-tooltip', ['exports', 'ember', 'z-tooltips/templates/components/z-tooltip'], function (exports, _ember, _zTooltipsTemplatesComponentsZTooltip) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    tooltipText: null,
    tooltipClassNames: null,

    // default ember-tooltip options
    delay: 0,
    delayOnChange: true,
    duration: 0,
    effect: 'slide', // fade, slide, none
    event: 'hover', // hover, click, focus, none
    hideOn: null,
    role: 'tooltip',
    side: 'top',
    showOn: null,
    spacing: 10,
    tabindex: '0', // A positive integer (to enable) or -1 (to disable)
    isShown: false,
    keepInWindow: true,

    tagName: 'span',
    layout: _zTooltipsTemplatesComponentsZTooltip['default']
  });
});