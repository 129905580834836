define('component-library/utils/z-spreadsheet/configuration', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	var defaultSpreadsheetConfigs = {
		fields: [], // Legacy

		// Banner properties
		showBanner: true,

		// Client-app configs
		bannerInitial: 'Add your team to ' + _componentLibraryUtilsGlobals.brandName + ' by filling out this spreadsheet',
		bannerFixedErrors: 'Great! Click the button to add your team members to ' + _componentLibraryUtilsGlobals.brandName + '.',
		submitButtonText: 'Submit',
		saveButtonText: 'Save as Draft',

		// Success modal properties
		modalSuccessTitle: 'You\'re all set!',
		modalSuccessMessage: 'Your changes have been saved in ' + _componentLibraryUtilsGlobals.brandName + '.',
		modalSuccessButtonText: 'Continue',

		// Error modal properties
		modalErrorTitle: 'We found some errors!',
		modalErrorMessage: 'Check out the highlighted fields and correct the errors.',
		modalErrorButtonText: 'OK',

		// Wizard properties (client-app only)
		afterSubmitTransitionRoute: 'index'
	};

	exports.defaultSpreadsheetConfigs = defaultSpreadsheetConfigs;

	var CENSUS_CONFIG_SELF_SERVE_SIGNUP = {
		modalSuccessTitle: 'All done!',
		submitButtonText: 'Submit',
		saveButtonText: 'Save Draft',
		afterSaveTransitionRoute: 'index'
	};

	var CENSUS_CONFIG_BOR = {
		afterSubmitTransitionRoute: 'borSetupWizard.sendInvites.introduction'
	};

	var CENSUS_CONFIG_BORLIFE = {
		afterSubmitTransitionRoute: 'borSetupWizard.sendInvites.introduction'
	};

	var CENSUS_CONFIG_NGE = {
		afterSubmitTransitionRoute: 'initialEnrollment.miniFlow.companyOwner',
		modalSuccessButtonText: 'Continue'
	};

	var CENSUS_CONFIG_EEO = {
		bannerInitial: 'This report contains company employment data for your W-2 workers. Please take a few minutes to add the missing information to the blank fields below.',
		// added this line below to disable save draft functionality
		saveButtonText: null,
		submitButtonText: 'Continue',
		bannerFixedErrors: 'Great! Click the button to save your changes to ' + _componentLibraryUtilsGlobals.brandName + '.',

		modalSuccessTitle: 'EEO information has been saved!',
		modalSuccessMessage: 'Click continue to go to the summary page where you can generate your EEO report.',
		modalSuccessButtonText: 'Continue',

		afterSubmitTransitionRoute: 'eeo.summary'
	};

	var CENSUS_CONFIG_NGE_LOC_SPECIFIC = {
		modalSuccessButtonText: 'Continue'
	};

	//For NGE we need to show census in plan selectors as well. only the afterSubmitTransitionRoute change. So copying a common config and changing it instead of maintaining new configs
	var CENSUS_CONFIG_NGEMEDICAL = _ember['default'].$.extend({ afterSubmitTransitionRoute: 'initialEnrollment.planSelector.selectPlanMedical' }, CENSUS_CONFIG_NGE_LOC_SPECIFIC);

	var CENSUS_CONFIG_NGEDENTAL = _ember['default'].$.extend({ afterSubmitTransitionRoute: 'initialEnrollment.planSelector.selectPlanDental' }, CENSUS_CONFIG_NGE_LOC_SPECIFIC);

	var CENSUS_CONFIG_NGEVISION = _ember['default'].$.extend({ afterSubmitTransitionRoute: 'initialEnrollment.planSelector.selectPlanVision' }, CENSUS_CONFIG_NGE_LOC_SPECIFIC);

	// Used for testing, only
	var CENSUS_CONFIG_CUSTOM = {};
	// Add configuration objects for other census usages, above. Note that these are extended off of the default configs, so
	// don't repeat any defaults that you aren't overriding.

	var flowNameToConfigsMap = {
		HR: CENSUS_CONFIG_SELF_SERVE_SIGNUP,
		BOR: CENSUS_CONFIG_BOR,
		BORLIFE: CENSUS_CONFIG_BORLIFE,
		NGE: CENSUS_CONFIG_NGE,
		EEO: CENSUS_CONFIG_EEO,
		CUSTOM: CENSUS_CONFIG_CUSTOM,
		NGEMEDICAL: CENSUS_CONFIG_NGEMEDICAL,
		NGEDENTAL: CENSUS_CONFIG_NGEDENTAL,
		NGEVISION: CENSUS_CONFIG_NGEVISION
	};

	// Add future mappings, above
	var getCensusConfig = function getCensusConfig(flowName) {
		var configObject = flowNameToConfigsMap[flowName] || {};
		configObject.flowName = flowName;
		return _ember['default'].$.extend(defaultSpreadsheetConfigs, configObject);
	};
	exports.getCensusConfig = getCensusConfig;
});