define('component-library/constants/plan-benefits', ['exports', 'component-library/constants/lines-of-coverage'], function (exports, _componentLibraryConstantsLinesOfCoverage) {
	'use strict';

	exports.splitDeductibleIndicator = splitDeductibleIndicator;
	exports.getBenefitsForLineOfCoverage = getBenefitsForLineOfCoverage;

	// All the plan benefits data information are kept in this file.
	var MEDICAL_BENEFITS = {
		COINSURANCE: {
			name: 'Coinsurance (In Network)',
			shortName: 'Coinsurance',
			field: 'coInsurance',
			description: 'The share of the cost of a service that one has to pay out of pocket.'
		},
		COINSURANCE_OUT_OF_NETWORK: {
			name: 'Coinsurance (Out of Network)',
			shortName: 'Coinsurance',
			field: 'outOfNetworkCoInsurance',
			description: 'The share of the cost of a service from out-of-network doctors or hospitals that one has to pay out of pocket.'
		},
		COINSURANCE_PREFERRED_NETWORK: {
			name: 'Coinsurance (Preferred Network)',
			shortName: 'Coinsurance',
			field: 'preferredNetworkCoInsurance',
			description: 'The share of the cost of a service from preferred network doctors or hospitals that one has to pay out of pocket.'
		},
		DAILY_LIMITS: {
			name: 'Daily Limit (In Network)',
			shortName: 'Daily Limit',
			field: 'dailyLimits',
			description: 'The maximum cost covered by the plan per day.'
		},
		DAILY_LIMITS_PREFERRED_NETWORK: {
			name: 'Daily Limit (Preferred Network)',
			shortName: 'Daily Limit',
			field: 'dailyLimitsPreferredNetwork',
			description: 'The maximum cost covered by the plan per day for preferred network doctors and hospitals.'
		},
		DEDUCTIBLE_FAMILY: {
			name: 'Family Deductible (In Network)',
			shortName: 'Deductible',
			field: 'deductibleFamily',
			description: 'The amount of money one has to pay out-of-pocket for their family\'s medical expenses before their insurance begins covering medical expenses.'
		},
		DEDUCTIBLE_FAMILY_OUT_OF_NETWORK: {
			name: 'Family Deductible (Out of Network)',
			shortName: 'Deductible',
			field: 'deductibleFamilyOutOfNetwork',
			description: 'The amount of money one has to pay out-of-pocket for their family\'s medical expenses before their insurance begins covering medical expenses while visiting out-of-network doctors or hospitals.'
		},
		DEDUCTIBLE_FAMILY_PREFERRED_NETWORK: {
			name: 'Family Deductible (Preferred Network)',
			shortName: 'Deductible',
			field: 'deductibleFamilyPreferredNetwork',
			description: 'The amount of money one has to pay out-of-pocket for their family\'s medical expenses before their insurance begins covering medical expenses while visiting preferred network doctors or hospitals.'
		},
		DEDUCTIBLE_INDIVIDUAL: {
			name: 'Individual Deductible (In Network)',
			shortName: 'Deductible',
			field: 'deductibleIndividual',
			description: 'The amount of money one has to pay out-of-pocket for their own medical expenses before their insurance begins covering medical expenses.'
		},
		DEDUCTIBLE_INDIVIDUAL_OUT_OF_NETWORK: {
			name: 'Individual Deductible (Out of Network)',
			shortName: 'Deductible',
			field: 'deductibleIndividualOutOfNetwork',
			description: 'The amount of money one has to pay out-of-pocket for their own medical expenses before their insurance begins covering medical expenses while visiting out-of-network network doctors or hospitals.'
		},
		DEDUCTIBLE_INDIVIDUAL_PREFERRED_NETWORK: {
			name: 'Individual Deductible (Preferred Network)',
			shortName: 'Deductible',
			field: 'deductibleIndividualPreferredNetwork',
			description: 'The amount of money one has to pay out-of-pocket for their own medical expenses before their insurance begins covering medical expenses while visiting preferred network doctors or hospitals.'
		},
		EMERGENCY_SERVICE: {
			name: 'Emergency Care Services (In Network)',
			shortName: 'ER Services',
			field: 'emergencyServicePrimary',
			description: 'The amount of money one pays out-of-pocket for emergency room services (not including transport).'
		},
		EMERGENCY_SERVICE_PREFERRED_NETWORK: {
			name: 'Emergency Care Services (Preferred Network)',
			shortName: 'ER Services',
			field: 'emergencyServicePrimaryPreferredNetwork',
			description: 'The amount of money one pays out-of-pocket for emergency room services (not including transport) while visiting preferred network doctors or hospitals.'
		},
		HOSPITAL_INPATIENT: {
			name: 'In Patient Care (In Network)',
			shortName: 'In-Patient',
			field: 'hospitalInpatient',
			description: 'Cost one must pay toward the \'Facility Fee\' for an in-patient surgery.'
		},
		HOSPITAL_INPATIENT_PREFERRED_NETWORK: {
			name: 'In Patient Care (Preferred Network)',
			shortName: 'In-Patient',
			field: 'hospitalInpatientPreferredNetwork',
			description: 'Cost one must pay toward the \'Facility Fee\' for an in-patient surgery while visiting preferred network doctors or hospitals.'
		},
		HOSPITAL_OUTPATIENT: {
			name: 'Out Patient Surgery (In Network)',
			shortName: 'Out-Patient',
			field: 'hospitalOutpatient',
			description: 'Cost one must pay toward the \'Facility Fee\' for an out-patient surgery.'
		},
		HOSPITAL_OUTPATIENT_PREFERRED_NETWORK: {
			name: 'Out Patient Surgery (Preferred Network)',
			shortName: 'Out-Patient',
			field: 'hospitalOutpatientPreferredNetwork',
			description: 'Cost one must pay toward the \'Facility Fee\' for an out-patient surgery while visiting preferred network doctors or hospitals.'
		},
		MAX_DAYS: {
			name: 'Max Days (In Network)',
			shortName: 'Max Days',
			field: 'maxDays',
			description: 'The maximum number of in-patient days covered by the plan.'
		},
		MAX_DAYS_PREFERRED_NETWORK: {
			name: 'Max Days (Preferred Network)',
			shortName: 'Max Days',
			field: 'maxDaysPreferredNetwork',
			description: 'The maximum number of in-patient days covered by the plan while visiting preferred network doctors or hospitals.'
		},
		METAL_TIER: {
			name: 'Metal Tier',
			shortName: 'Metal Tier',
			field: 'metalTier',
			description: 'Metal tiers indicate the portion of medical costs typically covered by the insurance plan. Bronze plans have limited coverage, while platinum plans have broad coverage.'
		},
		NETWORK: {
			name: 'Provider Network',
			shortName: 'Network',
			field: 'network',
			description: 'The set of doctors, hospitals, therapists, and other clinicians that are part of this health insurance plan.'
		},
		OOP_MAX_FAMILY: {
			name: 'Out of Pocket Max Family (In Network)',
			shortName: 'OOP Max',
			field: 'oopMaxFamily',
			description: 'The amount of money one has to pay out-of-pocket for their family\'s medical expenses before their insurance covers 100% of medical expenses.'
		},
		OOP_MAX_FAMILY_OUT_OF_NETWORK: {
			name: 'Out of Pocket Max Family (Out of Network)',
			shortName: 'OOP Max',
			field: 'oopMaxFamilyOutOfNetwork',
			description: 'The amount of money one has to pay out-of-pocket for their family\'s medical expenses before their insurance covers 100% of medical expenses while visiting out-of-network network doctors or hospitals.'
		},
		OOP_MAX_FAMILY_PREFERRED_NETWORK: {
			name: 'Out of Pocket Max Family (Preferred Network)',
			shortName: 'OOP Max',
			field: 'oopMaxFamilyPreferredNetwork',
			description: 'The amount of money one has to pay out-of-pocket for their family\'s medical expenses before their insurance covers 100% of medical expenses while visiting preferred network doctors or hospitals.'
		},
		OOP_MAX_INCLUDE_DEDUCTIBLE: {
			name: 'Out of Pocket Max Include Deductible',
			shortName: 'OOP Max incl. Deductible',
			field: 'oopMaxIncludeDeductible'
		},
		OOP_MAX_INDIVIDUAL: {
			name: 'Out of Pocket Max Individual (In Network)',
			shortName: 'OOP Max',
			field: 'oopMaxIndividual',
			description: 'The amount of money one has to spend on medical care before their insurance covers 100% of their expenses.'
		},
		OOP_MAX_INDIVIDUAL_OUT_OF_NETWORK: {
			name: 'Out of Pocket Max Individual (Out of Network)',
			shortName: 'OOP Max',
			field: 'oopMaxIndividualOutOfNetwork',
			description: 'The amount of money one has to spend on medical care before their insurance covers 100% of their expenses while visiting out-of-network network doctors or hospitals.'
		},
		OOP_MAX_INDIVIDUAL_PREFERRED_NETWORK: {
			name: 'Out of Pocket Max Individual (Preferred Network)',
			shortName: 'OOP Max',
			field: 'oopMaxIndividualPreferredNetwork',
			description: 'The amount of money one has to spend on medical care before their insurance covers 100% of their expenses while visiting preferred network doctors or hospitals.'
		},
		PHARMACY_DEDUCTIBLE: {
			name: 'Pharmacy Deductible (In Network)',
			shortName: 'Rx Deductible',
			field: 'pharmacyDeductible',
			description: 'The amount you must pay out-of-pocket for prescriptions - in addition to any medical deductible - before insurance covers pharmacy expenses.'
		},
		PHARMACY_DEDUCTIBLE_OUT_OF_NETWORK: {
			name: 'Pharmacy Deductible (Out of Network)',
			shortName: 'Rx Deductible',
			field: 'pharmacyDeductibleOutOfNetwork',
			description: 'The amount you must pay out-of-pocket for prescriptions - in addition to any medical deductible - before insurance covers pharmacy expenses while visiting out-of-network network doctors or hospitals.'
		},
		PHARMACY_DEDUCTIBLE_PREFERRED_NETWORK: {
			name: 'Pharmacy Deductible (Preferred Network)',
			shortName: 'Rx Deductible',
			field: 'pharmacyDeductiblePreferredNetwork',
			description: 'The amount you must pay out-of-pocket for prescriptions - in addition to any medical deductible - before insurance covers pharmacy expenses while visiting preferred network doctors or hospitals.'
		},
		PLAN_TYPE: {
			name: 'Plan Type',
			shortName: 'Plan Type',
			field: 'HMOPPO'
		},
		PREVENTATIVE_CARE: {
			name: 'Preventative Care Visit (In Network)',
			shortName: 'Preventative',
			field: 'preventativeCare',
			description: 'The cost one is responsible for during a Preventative Care Visit.'
		},
		PREVENTATIVE_CARE_OUT_OF_NETWORK: {
			name: 'Preventative Care Visit (Out of Network)',
			shortName: 'Preventative',
			field: 'preventativeCareOutOfNetwork',
			description: 'The cost one is responsible for during a Preventative Care Visit while visiting out-of-network network doctors or hospitals.'
		},
		PREVENTATIVE_CARE_PREFERRED_NETWORK: {
			name: 'Preventative Care Visit (Preferred Network)',
			shortName: 'Preventative',
			field: 'preventativeCarePreferredNetwork',
			description: 'The cost one is responsible for during a Preventative Care Visit while visiting preferred network doctors or hospitals.'
		},
		PRIMARY_CARE_COPAY: {
			name: 'Primary Care Visit (In Network)',
			shortName: 'PCP',
			field: 'coPay',
			description: 'The amount of money one has to pay out-of-pocket for doctor visits.'
		},
		PRIMARY_CARE_COPAY_OUT_OF_NETWORK: {
			name: 'Primary Care Visit (Out of Network)',
			shortName: 'PCP',
			field: 'coPayOutOfNetwork',
			description: 'The amount of money one has to pay out-of-pocket for doctor visits outside their network.'
		},
		PRIMARY_CARE_COPAY_PREFERRED_NETWORK: {
			name: 'Primary Care Visit (Preferred Network)',
			shortName: 'PCP',
			field: 'coPayPreferredNetwork',
			description: 'The amount of money one has to pay out-of-pocket for doctor visits in the carrier\'s preferred network.'
		},
		SPECIALIST_COPAY: {
			name: 'Specialist Visit (In Network)',
			shortName: 'Specialist',
			field: 'specialistCoPay',
			description: 'The amount of money one has to pay out-of-pocket for specialist visits.'
		},
		SPECIALIST_COPAY_OUT_OF_NETWORK: {
			name: 'Specialist Visit (Out of Network)',
			shortName: 'Specialist',
			field: 'specialistCoPayOutOfNetwork',
			description: 'The amount of money one has to pay out-of-pocket for specialist visits outside their network.'
		},
		SPECIALIST_COPAY_PREFERRED_NETWORK: {
			name: 'Specialist Visit (Preferred Network)',
			shortName: 'Specialist',
			field: 'specialistCoPayPreferredNetwork',
			description: 'The amount of money one has to pay out-of-pocket for specialist visits in the carrier\'s preferred network.'
		},
		RX_SUPPLY_DAYS: {
			name: 'Supply Days',
			shortName: 'Supply Days',
			field: 'rxSupplyDaysRetail',
			description: 'The number of days covered by the copay for retail prescription drugs.'
		},
		TIER_1_DRUGS_COPAY: {
			name: 'Tier 1 Drugs',
			shortName: 'Rx Tier 1',
			field: 'rxCoPayGenericRetail',
			description: 'The cost of generic prescription drugs.'
		},
		TIER_2_DRUGS_COPAY: {
			name: 'Tier 2 Drugs',
			shortName: 'Rx Tier 2',
			field: 'rxCoPayBrandRetail',
			description: 'The cost of preferred brand name prescription drugs.'
		},
		TIER_3_DRUGS_COPAY: {
			name: 'Tier 3 Drugs',
			shortName: 'Rx Tier 3',
			field: 'rxCoPayNonFormularyRetail',
			description: 'The cost of non-preferred brand name prescription drugs.'
		},
		TIER_4_DRUGS_COPAY: {
			name: 'Tier 4 Drugs',
			shortName: 'Rx Tier 4',
			field: 'specialtyPharmacy',
			description: 'The cost of specialty prescription drugs.'
		},
		URGENT_CARE: {
			name: 'Urgent Care (In Network)',
			shortName: 'Urgent Care',
			field: 'urgentCare',
			description: 'Cost one must pay toward Urgent Care services.'
		},
		URGENT_CARE_PREFERRED_NETWORK: {
			name: 'Urgent Care (Preferred Network)',
			shortName: 'Urgent Care',
			field: 'urgentCarePreferredNetwork',
			description: 'Cost one must pay toward Urgent Care services while visiting preferred network doctors or hospitals.'
		},
		FUNDING_TYPE: {
			name: 'Funding Type',
			shortName: 'Funding Type',
			field: 'fundingType',
			description: 'The funding type may be self funded, level funded, or fully insured.'
		},
		STOP_LOSS_ISL: {
			name: 'Individual Stop Loss Level',
			shortName: 'ISL',
			field: 'individualStopLossLevel',
			description: 'This is the maximum claims amount, incurred by a single individual, that a group will be responsible for.',
			isAlternativeFundingField: true
		},
		STOP_LOSS_ASL: {
			name: 'Aggregate Stop Loss Level',
			shortName: 'ASL',
			field: 'aggregateStopLossLevel',
			description: 'This is the maximum claim liability for the overall group.',
			isAlternativeFundingField: true
		},
		STOP_LOSS_ISL_INCLUDES_RX: {
			name: 'Individual Stop Loss Includes Prescriptions?',
			shortName: 'ISL Includes Rx?',
			field: 'islIncludesPrescriptions',
			description: 'If yes, any costs incurred from prescription costs count toward a member’s ISL.',
			isAlternativeFundingField: true
		},
		STOP_LOSS_CONTRACT_TERMS: {
			name: 'Stop Loss Contract Terms',
			shortName: 'Contract Terms',
			field: 'stopLossContractTerms',
			description: 'The period during which a claim must be incurred and paid to qualify for reimbursement.',
			isAlternativeFundingField: true
		}
	};

	exports.MEDICAL_BENEFITS = MEDICAL_BENEFITS;

	var DENTAL_BENEFITS = {
		BENEFIT_FEE_STRUCTURE: {
			name: 'Benefits Fee Structure',
			shortName: 'Fee Structure',
			field: 'benefitFeeStructure'
		},
		BENEFIT_PERIOD: {
			name: 'Deductible Reset',
			shortName: 'Ded. Reset',
			field: 'benefitPeriod',
			description: 'The period of time after which the plan\'s deductible resets. Plan Year deductibles reset on the anniversary of the plan\'s effective date, while Calendar Year deductibles reset on January 1st of each year.'
		},
		BASIC_SERVICES: {
			name: 'Basic Services (In Network)',
			shortName: 'Basic',
			field: 'coInsuranceBasic',
			description: 'The percentage of the cost of Basic Services (such as fillings) that one has to pay out of pocket'
		},
		BASIC_SERVICES_OUT_OF_NETWORK: {
			name: 'Basic Services (Out of Network)',
			shortName: 'Basic',
			field: 'coInsuranceBasicOutOfNetwork',
			description: 'The percentage of the cost of Basic Services (such as fillings) that one has to pay out of pocket while visiting out-of-network doctors or hospitals.'
		},
		CROWN: {
			name: 'Crown (In Network)',
			shortName: 'Crown',
			field: 'crown'
		},
		CROWN_OUT_OF_NETWORK: {
			name: 'Crown (Out of Network)',
			shortName: 'Crown',
			field: 'crownOutOfNetwork'
		},
		DEDUCTIBLE_FAMILY: {
			name: 'Family Deductible (In Network)',
			shortName: 'Deductible',
			field: 'deductibleFamily',
			description: 'The amount of money one has to pay out-of-pocket for their family\'s dental expenses before their insurance begins covering dental expenses.'
		},
		DEDUCTIBLE_FAMILY_OUT_OF_NETWORK: {
			name: 'Family Deductible (Out of Network)',
			shortName: 'Deductible',
			field: 'deductibleFamilyOutOfNetwork',
			description: 'The amount of money one has to pay out-of-pocket for their family\'s dental expenses before their insurance begins covering dental expenses while visiting out-of-network doctors or hospitals.'
		},
		DEDUCTIBLE_INDIVIDUAL: {
			name: 'Individual Deductible (In Network)',
			shortName: 'Deductible',
			field: 'deductibleIndividual',
			description: 'The amount of money one has to pay out-of-pocket for their own dental expenses before their insurance begins covering dental expenses.'
		},
		DEDUCTIBLE_INDIVIDUAL_OUT_OF_NETWORK: {
			name: 'Individual Deductible (Out of Network)',
			shortName: 'Deductible',
			field: 'deductibleIndividualOutOfNetwork',
			description: 'The amount of money one has to pay out-of-pocket for their own dental expenses before their insurance begins covering dental expenses while visiting out-of-network doctors or hospitals.'
		},
		ENDODONTIC_SERVICES: {
			name: 'Endodontic Services (In Network)',
			shortName: 'Endodontic',
			field: 'coInsuranceEndo',
			description: 'The percentage of the cost of Endodontic Services (such as root canals) that one has to pay out of pocket.'
		},
		ENDODONTIC_SERVICES_OUT_OF_NETWORK: {
			name: 'Endodontic Services (Out of Network)',
			shortName: 'Endodontic',
			field: 'coInsuranceEndoOutOfNetwork',
			description: 'The percentage of the cost of Endodontic Services (such as root canals) that one has to pay out of pocket while visiting out-of-network doctors or hospitals.'
		},
		FILLING: {
			name: 'Filling (In Network)',
			shortName: 'Filling',
			field: 'filling'
		},
		FILLING_OUT_OF_NETWORK: {
			name: 'Filling (Out of Network)',
			shortName: 'Filling',
			field: 'fillingOutOfNetwork'
		},
		MAXIMUM_BENEFITS: {
			name: 'Annual Benefit Max',
			shortName: 'Annual Max',
			field: 'maxBenefits',
			description: 'The maximum amount the insurance company will pay for dental expenses in a single plan year.'
		},
		MAJOR_SERVICES: {
			name: 'Major Services (In Network)',
			shortName: 'Major',
			field: 'coInsuranceMajor',
			description: 'The percentage of the cost of Major Services (such as dentures or implants) that one has to pay out of pocket.'
		},
		MAJOR_SERVICES_OUT_OF_NETWORK: {
			name: 'Major Services (Out of Network)',
			shortName: 'Major',
			field: 'coInsuranceMajorOutOfNetwork',
			description: 'The percentage of the cost of Major Services (such as dentures or implants) that one has to pay out of pocket while visiting out-of-network doctors or hospitals.'
		},
		NETWORK: {
			name: 'Provider Network',
			shortName: 'Network',
			field: 'network',
			description: 'The set of doctors, hospitals, therapists, and other clinicians that are part of this health insurance plan.'
		},
		ORTHO_COVERAGE: {
			name: 'Ortho Coverage',
			shortName: 'Ortho Covered?',
			field: 'orthoCoverage',
			description: 'Indicates if this plan covers certain procedures, such as braces. See the SBC for specific details.'
		},
		ORTHO_DEDUCTIBLE: {
			name: 'Orthodontic Deductible',
			shortName: 'Ortho Ded.',
			field: 'orthoDeductible',
			prerequisite: 'orthoCoverage'
		},
		ORAL_EXAM: {
			name: 'Oral Exam (In Network)',
			shortName: 'Oral Exam',
			field: 'oralExam'
		},
		ORAL_EXAM_OUT_OF_NETWORK: {
			name: 'Oral Exam (Out of Network)',
			shortName: 'Oral Exam',
			field: 'oralExamOutOfNetwork'
		},
		ORTHO_MAX_BENEFITS: {
			name: 'Lifetime Benefit Max',
			shortName: 'Lifetime Max',
			field: 'orthoMaxBenefits'
		},
		ORTHO_MAX_AGE: {
			name: 'Maximum Coverage Age',
			shortName: 'Max Age',
			field: 'orthoMaxAge',
			description: 'The maximum age a child can be to receive covered orthodontic care.'
		},
		ORTHO_SERVICES_ADULT: {
			name: 'Adult Orthodontic Services (In Network)',
			shortName: 'Ortho (Adult)',
			field: 'coInsuranceOrtho',
			description: 'The percentage of the cost of Orthodontic Services that one has to pay out of pocket.',
			prerequisite: 'orthoCoverage'
		},
		ORTHO_SERVICES_ADULT_OUT_OF_NETWORK: {
			name: 'Adult Orthodontic Services (Out of Network)',
			shortName: 'Ortho (Adult)',
			field: 'coInsuranceOrthoOutOfNetwork',
			description: 'The percentage of the cost of Orthodontic Services that one has to pay out of pocket while visiting out-of-network doctors or hospitals.'
		},
		ORTHO_SERVICES_CHILD: {
			name: 'Child Orthodontic Services (In Network)',
			shortName: 'Ortho (Child)',
			field: 'coInsuranceOrthoChild'
		},
		ORTHO_SERVICES_CHILD_OUT_OF_NETWORK: {
			name: 'Child Orthodontic Services (Out of Network)',
			shortName: 'Ortho (Child)',
			field: 'coInsuranceOrthoChildOutOfNetwork'
		},
		PERIODONTIC_SERVICES: {
			name: 'Periodontal Services (In Network)',
			shortName: 'Periodontic',
			field: 'coInsurancePerio',
			description: 'The percentage of the cost of Periodontal Services (such as root planing) that one has to pay out of pocket.'
		},
		PERIODONTIC_SERVICES_OUT_OF_NETWORK: {
			name: 'Periodontal Services (Out of Network)',
			shortName: 'Periodontic',
			field: 'coInsurancePerioOutOfNetwork',
			description: 'The percentage of the cost of Periodontal Services (such as root planing) that one has to pay out of pocket while visiting out-of-network doctors or hospitals.'
		},
		PLAN_TYPE: {
			name: 'Plan Type',
			shortName: 'Plan Type',
			field: 'HMOPPO'
		},
		PREVENTATIVE_SERVICES: {
			name: 'Preventative Care Visit (In Network)',
			shortName: 'Preventative',
			field: 'coInsurancePreventative',
			description: 'The percentage of the cost of Preventative Care Services (such as cleanings) that one has to pay out of pocket.'
		},
		PREVENTATIVE_SERVICES_OUT_OF_NETWORK: {
			name: 'Preventative Care Visit (Out of Network)',
			shortName: 'Preventative',
			field: 'coInsurancePreventativeOutOfNetwork',
			description: 'The percentage of the cost of Preventative Care Services (such as cleanings) that one has to pay out of pocket while visiting out-of-network doctors or hospitals'
		},
		ROOT_CANAL: {
			name: 'Root Canal (In Network)',
			shortName: 'Root Canal',
			field: 'rootCanal'
		},
		ROOT_CANAL_OUT_OF_NETWORK: {
			name: 'Root Canal (Out of Network)',
			shortName: 'Root Canal',
			field: 'rootCanalOutOfNetwork'
		},
		SCALING_ROOT_PLANING: {
			name: 'Scaling & Root Planing (In Network)',
			shortName: 'Root Planing',
			field: 'scalingRootPlaning'
		},
		SCALING_ROOT_PLANING_OUT_OF_NETWORK: {
			name: 'Scaling & Root Planing (Out of Network)',
			shortName: 'Root Planing',
			field: 'scalingRootPlaningOutOfNetwork'
		}
	};

	exports.DENTAL_BENEFITS = DENTAL_BENEFITS;

	var VISION_BENEFITS = {
		CONTACTS_COVERAGE: {
			name: 'Contacts Coverage',
			shortName: 'Contacts Cvrg.',
			field: 'contactsAllowable',
			description: 'The amount of money this plan will contribute to the purchase of prescription contact lenses.'
		},
		CONTACTS_FREQUENCY: {
			name: 'Contacts Frequency',
			shortName: 'Contacts Freq',
			field: 'contactsFrequency',
			description: 'How often someone can purchase prescription contact lenses that will be covered by the plan? An individual can get contact lenses or eyeglass lenses during this time period, but not both.'
		},
		EXAM_COST: {
			name: 'Exam Cost',
			shortName: 'Exam Cost',
			field: 'coPay',
			description: 'The amount of money one has to pay out-of-pocket for a vision exam.'
		},
		EXAM_FREQUENCY: {
			name: 'Exam Frequency',
			shortName: 'Exam Freq',
			field: 'examFrequency',
			description: 'How often one can get an exam that will be covered by the plan?'
		},
		FRAMES_COVERAGE: {
			name: 'Frames Coverage',
			shortName: 'Frames Cvrg.',
			field: 'frameAllowable',
			description: 'The amount of money this plan will contribute to the purchase of eyeglass frames.'
		},
		FRAMES_FREQUENCY: {
			name: 'Frames Frequency',
			shortName: 'Frames Freq',
			field: 'frameFrequency',
			description: 'How often one can purchase eyeglass frames that will be covered by the plan?'
		},
		LASIK_COVERAGE: {
			name: 'Lasik Discount',
			shortName: 'Lasik Cvrg.',
			field: 'lasikCoverage',
			description: 'Indicates if this plan covers Lasik surgery.'
		},
		LENSES_COVERAGE: {
			name: 'Lenses Coverage',
			shortName: 'Lenses Cvrg.',
			field: 'lensAllowable',
			description: 'The amount of money this plan will contribute to the purchase of prescription eyeglass lenses.'
		},
		LENSES_FREQUENCY: {
			name: 'Lenses Frequency',
			shortName: 'Lenses Freq',
			field: 'lensFrequency',
			description: 'How often someone can purchase prescription eyeglass lenses that will be covered by the plan? An individual can get eyeglass lenses or contact lenses during this time period, but not both.'
		},
		NETWORK: {
			name: 'Provider Network',
			shortName: 'Network',
			field: 'network',
			description: 'The set of doctors, hospitals, therapists, and other clinicians that are part of this health insurance plan.'
		}
	};

	exports.VISION_BENEFITS = VISION_BENEFITS;

	var DEDUCTIBLE_INDICATORS = {
		DEDUCTIBLE_WAIVED: {
			description: 'This applies whether or not you have met your deductible.',
			value: 'deductible waived'
		},
		AFTER_DEDUCTIBLE: {
			description: 'This plan requires you to pay the full cost of your deductible before this applies.',
			value: 'after deductible'
		}
	};

	exports.DEDUCTIBLE_INDICATORS = DEDUCTIBLE_INDICATORS;

	function splitDeductibleIndicator(value) {
		// Returns an object containing value and the deductible indicator constant if deductible indicator is applicable.

		if (!value) {
			return;
		}

		var regex = new RegExp('after deductible|deductible waived');
		var matches = value.match(regex);
		if (matches) {
			var trimmedValue = value.replace(regex, '').trim();
			var deductibleIndicator = matches[0] === DEDUCTIBLE_INDICATORS.AFTER_DEDUCTIBLE.value ? DEDUCTIBLE_INDICATORS.AFTER_DEDUCTIBLE : DEDUCTIBLE_INDICATORS.DEDUCTIBLE_WAIVED;
			return {
				value: trimmedValue,
				deductibleIndicator: deductibleIndicator,
				tooltip: deductibleIndicator.description
			};
		} else {
			return {
				value: value
			};
		}
	}

	function getBenefitsForLineOfCoverage(lineOfCoverage) {
		switch (lineOfCoverage) {
			case _componentLibraryConstantsLinesOfCoverage.MEDICAL:
				return MEDICAL_BENEFITS;
			case _componentLibraryConstantsLinesOfCoverage.DENTAL:
				return DENTAL_BENEFITS;
			case _componentLibraryConstantsLinesOfCoverage.VISION:
				return VISION_BENEFITS;
			default:
				return;
		}
	}
});