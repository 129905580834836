define('component-library/modals/inbox-action/sign-form', ['exports', 'ember', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryComponentsPromiseModal) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'modals/inbox-action/sign-form',

		didInsertElement: function didInsertElement() {
			this._super();
			this.pad = this.$('.sigPad').signaturePad({
				'drawOnly': true,
				'bgColour': 'transparent'
			});
		},

		validate: function validate() {
			var isValid = true;
			this.set('signatureNameError', '');

			if (!this.pad.validateSignature()) {
				isValid = false;
				this.set('signatureError', 'The signature is too small. Please try again.');
				this.pad.clearCanvas();
			}
			if (_ember['default'].isEmpty(this.get('subAction.name'))) {
				isValid = false;
				this.set('signatureNameError', 'The required field is missing.');
			}
			return isValid;
		},

		actions: {
			apply: function apply(laddaButton) {
				if (!this.validate()) {
					laddaButton.stop();
					return;
				}
				this.get('subAction').set('signature', this.pad.getSignatureImage());
				this.send('confirm');
			}
		}
	});
});