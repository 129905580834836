define('component-library/filters/abstract-filter-spec', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Object.extend({
		popoverClass: null,

		isActive: false,
		isHidden: false,
		isSerializable: false,

		name: '',
		description: '',

		// wtf is this for? is it even needed?
		filterProperty: null,

		applyFunc: function applyFunc() {
			return false;
		}
	});
});