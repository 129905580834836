define('z-modals/components/z-modal', ['exports', 'ember', 'ember-modal-dialog/components/modal-dialog'], function (exports, _ember, _emberModalDialogComponentsModalDialog) {
  'use strict';

  exports['default'] = _emberModalDialogComponentsModalDialog['default'].extend({
    // ModalDialog attrs
    translucentOverlay: true,
    targetAttachment: 'none',
    'wrapper-class': 'z-modal-wrapper',
    'overlay-class': 'z-modal-overlay',
    containerClassNames: ['z-modal', 'js-walkme-modal', 'js-test-z-modal'],
    onClickOverlay: null,

    // z-modal attrs
    close: undefined, // the action called when the modal overlay is clicked (ex: 'toggleModal')
    size: 'medium', // 'small', 'medium', or 'large'
    disableMaxHeight: false,
    cancelOnEscape: true,

    // internals
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // We can't use classNameBindings because ModalDialog is a tagless component
      // and because we need to target containerClassNames. This has the same effect.
      var originalContainerClassNames = this.get('containerClassNames');
      var maxHeightClass = 'z-modal--maxHeight';
      if (this.get('disableMaxHeight')) {
        maxHeightClass = '';
      }

      var size = this.get('size');
      this.set('containerClassNames', originalContainerClassNames.concat(size, maxHeightClass));
    },

    init: function init() {
      this._super.apply(this, arguments);

      if (typeof this.get('close') === 'undefined') {
        throw Error('The "close" parameter cannot be undefined. Create an action that toggles the modal\'s visibility and set close="thatFunctionName" or close=(action "thatFunctionName")');
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('cancelOnEscape')) {
        _ember['default'].$('body').on('keyup.modal-dialog', function (e) {
          if (e.keyCode === 27) {
            _this.get('close')();
          }
        });
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      _ember['default'].$('body').off('keyup.modal-dialog');
    }
  });
});