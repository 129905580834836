define('z-inputs/components/z-text-field-percent', ['exports', 'z-inputs/components/z-text-field-base', 'ember', 'z-inputs/templates/components/z-text-field-basic', 'z-inputs/utils/number-fields-helper'], function (exports, _zInputsComponentsZTextFieldBase, _ember, _zInputsTemplatesComponentsZTextFieldBasic, _zInputsUtilsNumberFieldsHelper) {
  'use strict';

  exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend({
    layout: _zInputsTemplatesComponentsZTextFieldBasic['default'],
    classNames: 'z-textField--percent',
    type: 'percent',
    min: null,
    max: null,
    mask: 'decimal',
    precision: 2,
    maskOptions: (function () {
      return {
        digits: this.get('precision'),
        min: this.get('min') || 0,
        max: this.get('max') || 100
      };
    }).property('min', 'max', 'precision'),

    shadowValue: (function () {
      var value = this.get('value');
      return (_ember['default'].isEmpty(value) ? '' : value) + ' %';
    }).property('value', 'isFocused', 'showShadowPlaceholder'),

    didInsertElement: function didInsertElement() {
      if (!this.get('min')) {
        this.set('min', 0);
      }
      if (!this.get('max')) {
        this.set('max', 100);
      }
      if (this.get('value')) {
        var sanitizedValue = this.get('value').toString().replace(/[^0-9.]/g, '');
        this.set('value', (0, _zInputsUtilsNumberFieldsHelper['default'])(sanitizedValue, {
          min: this.get('min'),
          max: this.get('max')
        }));
      }
      this._super();
    }
  });
});