define('component-library/timeline/components/pto-balance-adjustment', ['exports', 'ember', 'component-library/timeline/mixins/feed-entry', 'component-library/timeline/mixins/pto-feed-entry', 'component-library/timeline/components/copy', 'component-library/timeline/utils/status', 'component-library/timeline/utils/computed-copy'], function (exports, _ember, _componentLibraryTimelineMixinsFeedEntry, _componentLibraryTimelineMixinsPtoFeedEntry, _componentLibraryTimelineComponentsCopy, _componentLibraryTimelineUtilsStatus, _componentLibraryTimelineUtilsComputedCopy) {
	'use strict';

	exports['default'] = _componentLibraryTimelineComponentsCopy['default'].extend(_componentLibraryTimelineMixinsPtoFeedEntry['default'], _componentLibraryTimelineMixinsFeedEntry['default'], {

		copy: (0, _componentLibraryTimelineUtilsComputedCopy.computedCopyFormatted)(_componentLibraryTimelineUtilsStatus.ptoBalanceAdjustmentStatusTypes.map(function (st) {
			return '_' + st;
		}), 'dataStatusFormatted'),

		employeeName: _ember['default'].computed.alias('data.employee.fullName'),
		oldBalance: _ember['default'].computed.truncated('data.oldBalance', 2),
		newBalance: _ember['default'].computed.truncated('data.newBalance', 2),

		// NOTE: this needed to prefix an underscore so the 'set' status doesn't collide with an Ember.Component reserved function
		dataStatusFormatted: _ember['default'].computed('data.status', function () {
			return '_' + this.get('data.status');
		}),

		_adjustment: (function () {
			var str = this.get('balanceChangeStr') + this.get('byUserStr');
			if (this.get('data.reason') !== null && this.get('data.reason').trim() !== "") {
				str += " with the following reason: " + this.get('data.reason');
			}
			return str;
		}).property('byUserStr', 'balanceChangeStr', 'data.reason'),

		_set: (function () {
			return '' + this.get('balanceChangeStr') + this.get('byUserStr');
		}).property('byUserStr', 'balanceChangeStr'),

		_import: (function () {
			return '' + this.get('balanceChangeStr') + this.get('byUserStr');
		}).property('byUserStr', 'balanceChangeStr'),

		_rollover: (function () {
			return this.get('rolloverStr') + ' carryover cap';
		}).property('rolloverStr'),

		_reset: (function () {
			return this.get('rolloverStr') + ' reset';
		}).property('rolloverStr'),

		migration: (function () {
			return 'Zenefits upgraded their time off system to make it easier to track employee leave. Instead of showing the amount of leave taken, the time off system now display the current balance. ' + this.get('employeePossessive') + ' ' + this.get('balanceStr') + ' was set to ' + this.get('newBalance') + ' ' + this.get('hours');
		}).property('employeePossessive', 'balanceStr', 'newBalance'),

		rolloverStr: (function () {
			return this.get('employeePossessive') + ' ' + this.get('balanceStr') + ' was reset to ' + this.get('newBalance') + ' hours by the policy’s end of year';
		}).property('employeePossessive', 'balanceStr', 'newBalance'),

		balanceChangeStr: (function () {
			var str = "";
			if (this.get('oldBalance')) {
				str = this.get('employeePossessive') + ' ' + this.get('balanceStr') + ' was changed from ' + this.get('oldBalance') + ' hours';
			} else {
				str = this.get('employeePossessive') + ' ' + this.get('balanceStr') + ' was set';
			}
			return this.capitalizeFirstLetter(str) + ' to ' + this.get('newBalance') + ' hours';
		}).property('oldBalance', 'newBalance', 'employeePossessive', 'balanceStr'),

		balanceStr: (function () {
			return this.get('data.accountName') + ' balance';
		}).property('data.accountName'),

		byUserStr: (function () {
			if (this.get('data.operatedBy') === null) {
				return "";
			} else {
				return ' by ' + this.get('user');
			}
		}).property('data.operatedBy', 'user')

	});
});