define('component-library/components/d-signature-field', ['exports', 'ember', 'component-library/utils/globals', 'component-library/components/signature-modal'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryComponentsSignatureModal) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);

			if (this.get('initialSignatureImage')) {
				this.set('signatureImage', this.get('initialSignatureImage'));
			}

			if (this.get('spoofData')) {
				_ember['default'].ajaxGet('/api/admin_spoof/signature/' + this.get('spoofData.id')).then(function (data) {
					if (data.data) {
						var spoofedSignatureData = {
							signatureImage: data.data,
							signatureDate: (0, _componentLibraryUtilsGlobals.moment)().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT)
						};
						_this.setProperties(spoofedSignatureData);
						_this.sendAction('signatureComplete', spoofedSignatureData);
					}
				});
			}
		},

		// Controller is a singleton.
		// In cases where we reuse controllers with different models init() is not fired and we need to reset signatureImage.
		didRender: function didRender() {
			this._super.apply(this, arguments);
			this.set('signatureImage', this.get('initialSignatureImage'));
		},

		showError: true,
		errorType: 'text',
		showErrorComputed: _ember['default'].computed('errorText.[]', 'showError', 'errorType', function () {
			return !!this.get('errorText.length') && this.get('showError');
		}),
		showErrorTooltip: _ember['default'].computed('showErrorComputed', 'errorType', function () {
			return this.get('showErrorComputed') && this.get('errorType') === 'tooltip';
		}),
		showErrorText: _ember['default'].computed('showErrorComputed', 'errorType', function () {
			return this.get('showErrorComputed') && this.get('errorType') === 'text';
		}),

		actions: {
			openModal: function openModal() {
				var _this2 = this;

				_componentLibraryComponentsSignatureModal['default'].open().promise.then(function (signatureData) {
					var signatureElement = _this2.get('signatureElement');
					_this2.setProperties(signatureData);
					_this2.sendAction('signatureComplete', signatureData, signatureElement);
				});
			}
		}
	});
});