define('component-library/modals/payroll/audit-trail-modal', ['exports', 'ember', 'component-library/components/promise-modal', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryComponentsPromiseModal, _componentLibraryUtilsGlobals) {
    'use strict';

    exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
        templateName: 'modals/payroll/audit-trail-modal',

        taxData: _ember['default'].computed.alias('taxRecord'),

        modalTitle: _ember['default'].computed('taxData', function () {
            return this.get("taxData.name") + " Override History";
        }),

        trailHistoryItems: _ember['default'].computed('taxData', function () {
            var taxData = this.get('taxData');
            var changeHistory = taxData.changeHistory;
            var name = taxData.name;

            var defaultChangedBy = {
                firstName: '' + _componentLibraryUtilsGlobals.brandName,
                lastName: "Support",
                photoUrl: "/static/img/rebranding/trinet-monogram-202311.svg"
            };
            changeHistory.sort(function (a, b) {
                return new Date(b.updatedAt) - new Date(a.updatedAt);
            });
            return changeHistory.map(function (taxRecord) {
                var changedBy = taxRecord.changedBy;
                var updatedAt = taxRecord.updatedAt;
                var oldAmount = taxRecord.oldAmount;
                var newAmount = taxRecord.newAmount;
                var userEnteredAmount = taxRecord.userEnteredAmount;

                var _ref = changedBy || defaultChangedBy;

                var photoUrl = _ref.photoUrl;
                var firstName = _ref.firstName;
                var lastName = _ref.lastName;

                var fullName = firstName + " " + lastName;
                var updatedTime = (0, _componentLibraryUtilsGlobals.moment)(updatedAt).format("Do MMMM YYYY (h:mm A)");
                var updateString = userEnteredAmount === null ? 'Reverted to ' + _componentLibraryUtilsGlobals.brandName + '’ calculated ' + name + ' withholding amount ($' + newAmount + ')' : 'Manually adjusted ' + name + ' witholding amount from $' + oldAmount + ' to $' + newAmount;
                return { employee: { photoUrl: photoUrl, first_name: firstName, last_name: lastName, fullName: fullName }, updatedTime: updatedTime, updateString: updateString };
            });
        })
    });
});