define('component-library/components/button-group', ['exports', 'ember'], function (exports, _ember) {
	//////////////////////////////////////////////////////////////////////////////////////////////////
	// button-group
	//
	// Usage: Uses Bootstrap's btn-group (http://getbootstrap.com/components/#btn-groups) styles and
	// creates a component.
	//
	// Example:
	// {{button-group
	// 	data=someData
	// 	class="whatever-class-you-want"
	// 	action="myAction",
	//	initialSelectionValue=1
	// }}
	//
	// @param data - Array of objects that looks like:
	// var someData = [
	// 	{label: 'Option 1', value: 1},
	// 	{label: 'Option 2', value: 2}
	// 	//...
	// ];
	// Note that the values must be unique between each button.
	//
	// @param action - The action specified will be sent the value of the selected button upon click.
	// @param initialSelectionValue - (optional) Updates UI to select button with specified value on init.
	//////////////////////////////////////////////////////////////////////////////////////////////////
	'use strict';

	var buttonGroupSelector = '.js-buttonGroup';
	var buttonsSelector = buttonGroupSelector + ' button';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['js-glue-button-group-wrapper'],
		initialSelectionValue: null, // deprecated
		selectedValue: null,
		layoutName: 'components/button-group',
		templateName: 'components/basic-button',

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			var initialSelectionValue = this.get('initialSelectionValue');

			if (!_ember['default'].isEmpty(initialSelectionValue)) {
				this._updateActiveButtonStyle(initialSelectionValue);
			}
		},

		observeSelectedValue: (function () {
			_ember['default'].run.scheduleOnce('afterRender', this, function () {
				this._updateActiveButtonStyle(this.get('selectedValue'));
			});
		}).observes('selectedValue', 'data.@each').on('init'),

		actions: {
			_onButtonGroupSelection: function _onButtonGroupSelection(value) {
				this._updateActiveButtonStyle(value);
				this.set('selectedValue', value);
			}
		},

		_updateActiveButtonStyle: function _updateActiveButtonStyle(value) {
			var $allButtons = this.$(buttonsSelector);
			var $selectedButton = this.$(buttonsSelector + '[data-value="' + value + '"]');

			$allButtons.removeClass('active');
			$selectedButton.addClass('active');
		}
	});
});