define('component-library/components/data-table-toolbar', ['exports', 'ember', 'component-library/components/filter-list-popover', 'component-library/data-table/configure-table-modal'], function (exports, _ember, _componentLibraryComponentsFilterListPopover, _componentLibraryDataTableConfigureTableModal) {
	'use strict';

	var FilterListPopover = _componentLibraryComponentsFilterListPopover['default'].extend({
		showFilterControls: false
	});

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-tableFilters-container'],
		FilterListPopover: FilterListPopover,
		canConfigureGroupings: false,
		canConfigureColumns: true,
		allColumns: [],
		columns: [],
		allGroupings: [],
		groupings: [],

		actions: {
			showConfigureTableModal: function showConfigureTableModal() {
				_componentLibraryDataTableConfigureTableModal['default'].open({
					canConfigureGroupings: this.get('canConfigureGroupings'),
					canConfigureColumns: this.get('canConfigureColumns'),
					allColumns: this.get('allColumns'),
					columns: this.get('columns'),
					allGroupings: this.get('allGroupings'),
					groupings: this.get('groupings')
				});
			}
		}
	});
});