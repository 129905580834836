define('component-library/constants/z-payroll/tax-header-const', ['exports'], function (exports) {
	'use strict';

	var SUTA_TAX_TYPES = ['SUTA', 'SUTA_SC', 'SUTA_SC_2', 'SUTA_SC_3'];
	exports.SUTA_TAX_TYPES = SUTA_TAX_TYPES;

	var DefaultSutaRate = 0;
	exports.DefaultSutaRate = DefaultSutaRate;

	var WC_RATE_LIMIT = 2200;
	exports.WC_RATE_LIMIT = WC_RATE_LIMIT;

	var ErrorMessage = {
		Missing: 'This field is missing.',
		Invalid: 'This field is invalid.',
		WrongHourlyRate: 'Hourly Rate should be greater than or equal to Employee Withholding.',
		IntegerOnly: 'Please enter a whole number.'
	};
	exports.ErrorMessage = ErrorMessage;

	var TAX_RATE_QUESTION_TYPE = 'Tax Rate';
	exports.TAX_RATE_QUESTION_TYPE = TAX_RATE_QUESTION_TYPE;

	var WC_TAX_RATE_QUESTION_TYPE = 'Workers Comp Tax Rate';
	exports.WC_TAX_RATE_QUESTION_TYPE = WC_TAX_RATE_QUESTION_TYPE;

	var FLI_ER_COVERAGE_PERCENT = ['fliERCoverage', 'pflERCoverage', 'pmlERCoverage'];
	exports.FLI_ER_COVERAGE_PERCENT = FLI_ER_COVERAGE_PERCENT;
});