define('component-library/filters/multi-select-filter-spec', ['exports', 'ember', 'component-library/filters/helpers', 'component-library/components/multi-select-filter'], function (exports, _ember, _componentLibraryFiltersHelpers, _componentLibraryComponentsMultiSelectFilter) {
	'use strict';

	exports['default'] = _ember['default'].Object.extend({
		popoverClass: _componentLibraryComponentsMultiSelectFilter['default'],
		isActive: _ember['default'].computed.oneWay('showAlways'),
		isHidden: false,
		isSerializable: true,
		filterId: undefined,
		name: "",
		placeholder: undefined,
		showAlways: false,
		allSerializedSelections: {},
		allowEmptySelection: true,

		async: false,
		nullAsId: false,
		nullLabel: '',
		// nullAsId (doesn't work when stringModel == true) vs optionsContentSkipNulls
		optionsContentSkipNulls: false,
		// NOTE: if substringMatch is true, stringModel should also be true.
		substringMatch: false,
		// TODO(ed): comment on exactly what this means... something like the options are strings only
		stringModel: false,
		showCountInLabel: true,

		// this is required for async
		valueTypeKey: undefined,
		valuePath: undefined,
		// The labelPath is relative to the valuePath
		labelPath: '',
		labelPaths: (function () {
			return [this.get('labelPath')];
		}).property('labelPath'),
		labelFunction: (function () {
			var _this = this;

			return function (value) {
				return _ember['default'].get(value, _this.get('labelPath'));
			};
		}).property(),
		// TODO(ed): rewrite this
		labelFunctionOverride: false,

		deserializableOptions: _ember['default'].computed.oneWay('options'),

		queryValuePath: undefined,

		// select-2 stuff
		optionValuePath: (function () {
			return this.get('stringModel') ? '' : 'model';
		}).property('stringModel'),
		optionLabelPath: (function () {
			return this.get('stringModel') ? '' : 'label';
		}).property('stringModel'),
		optionLabelSelectedPath: (function () {
			return this.get('stringModel') ? '' : null;
		}).property('stringModel'),
		optionHeadlinePath: (function () {
			return this.get('stringModel') ? '' : 'text';
		}).property('stringModel'),
		optionDescriptionPath: (function () {
			return this.get('stringModel') ? '' : 'description';
		}).property('stringModel'),
		optionIdPath: (function () {
			return this.get('stringModel') ? '' : 'id';
		}).property('stringModel'),
		tags: (function () {
			if (this.get('substringMatch')) {
				return true;
			}
		}).property('substringMatch'),
		tokenSeparators: (function () {
			if (this.get('substringMatch')) {
				return [','];
			}
		}).property('substringMatch'),
		optionCountMap: undefined,
		typeaheadSearchingText: "Searching…",
		typeaheadNoMatchesText: "No matches found",
		queryFunction: undefined,

		// String options to exclude initially from the filter if showAlways=true
		stringOptionsDefaultOff: (function () {
			return [];
		}).property(),

		// Consider all strings with these prefixes to be the prefix value
		stringOptionsPrefixSquash: (function () {
			return [];
		}).property(),

		isStringModelPrefixFiltering: (function () {
			return this.get('stringOptionsPrefixSquash.length') > 0;
		}).property('stringOptionsPrefixSquash', 'stringOptionsPrefixSquash.[]'),

		selections: (function () {
			var _this2 = this;

			if (this.get('showAlways')) {
				var opts = this.get('options');
				if (this.get('stringModel')) {
					if (this.get('isStringModelPrefixFiltering')) {
						opts = opts.reject(function (elem) {
							var prefixes = _this2.get('stringOptionsPrefixSquash');
							for (var i = 0; i < prefixes.length; i++) {
								if (elem.indexOf(prefixes[i]) === 0) {
									return elem.length > prefixes[i].length;
								}
							}
							return false;
						});
					}
					opts = opts.filter(function (elem) {
						return _this2.get('stringOptionsDefaultOff').indexOf(elem) == -1;
					});
				}
				return opts.getEach(this.get('optionValuePath')).slice(); // no dependence right now. the first time we get selections it slices options
			}
			return _ember['default'].A();
		}).property('showAlways'),

		selectionsWithoutNullAsId: (function () {
			var selectionsWithoutNullAsId = this.get('selections').slice();
			selectionsWithoutNullAsId.removeObject(String(null));
			return selectionsWithoutNullAsId;
		}).property('selections.[]'),

		optionsContent: undefined,

		setupDescriptionProperty: (function () {
			var dependentPaths = ['labelFunction', 'selections.[]'];
			if (this.get('nullAsId')) {
				dependentPaths = dependentPaths.concat(this.get('labelPaths').map(function (labelPath) {
					return 'selectionsWithoutNullAsId.@each.' + labelPath;
				}));
			} else {
				dependentPaths = dependentPaths.concat(this.get('labelPaths').map(function (labelPath) {
					return 'selections.@each.' + labelPath;
				}));
			}

			this.propertyWillChange('description');
			_ember['default'].defineProperty(this, 'description', _ember['default'].computed.apply(null, dependentPaths.concat(function () {
				var _this3 = this;

				return this.descriptionFunction(this.get('selections').map(function (selection) {
					return _this3.get('labelFunction')(selection);
				}));
			})));
			this.propertyDidChange('description');
		}).observes('labelPaths.[]').on('init'),

		descriptionFunction: function descriptionFunction(selectionDescriptions) {
			if (selectionDescriptions.compact().length && this.get('options.length') === selectionDescriptions.get('length')) {
				return "(All)";
			} else {
				if (selectionDescriptions.get('length') > 2) {
					return [selectionDescriptions.slice(0, 2).join(', '), '... (', selectionDescriptions.get('length') - 2, ' more)'].join('');
				} else {
					return selectionDescriptions.join(', ') || "(None)";
				}
			}
		},

		applyFuncHelper: function applyFuncHelper(key, property) {
			var selections = this.get('selections');
			// TODO(ed): async true :O
			if (!_ember['default'].isArray(property)) {
				property = [property];
			} // Match any entry of an array-like property.
			return property.some((function (property) {
				var propertyId = _ember['default'].getWithDefault(property || null, 'id', null);
				if (!_ember['default'].isEmpty(propertyId)) {
					return selections.findBy('id', propertyId);
				}

				if (this.get('nullAsId') && property === null) {
					return selections.contains(String(property));
				}

				if (this.get('substringMatch')) {
					if (_ember['default'].typeOf(property) !== 'string') {
						return false;
					}
					var propertyStr = property.toLowerCase();
					return selections.find(function (selection) {
						if (selection === "") {
							return propertyStr === selection;
						}
						return _ember['default'].typeOf(selection) === 'string' && ~propertyStr.indexOf(selection.toLowerCase());
					}) != null;
				}

				return selections.contains(property);
			}).bind(this));
		},

		setupApplyFunc: (function () {
			var dependentPaths = ['selections.' + (this.get('async') ? '@each.id' : '[]')];
			this.propertyWillChange('applyFunc');
			_ember['default'].defineProperty(this, 'applyFunc', _ember['default'].computed.apply(null, dependentPaths.concat(function () {
				var _this4 = this;

				return function (element) {
					return _this4.applyFuncHelper(undefined, _ember['default'].get(element, _this4.get('valuePath')));
				};
			})));
			this.propertyDidChange('applyFunc');
		}).observes('async').on('init'),

		setupFilterProperty: (function () {
			var valuePath = this.get('valuePath');
			var dependentPaths = ['selections.' + (this.get('async') ? '@each.id' : '[]')];
			var applyFuncHelper = this.applyFuncHelper.bind(this);
			this.propertyWillChange('filterProperty');
			_ember['default'].defineProperty(this, 'filterProperty', _ember['default'].computed.apply(null, dependentPaths.concat(function () {
				return [valuePath, applyFuncHelper];
			})));
			this.propertyDidChange('filterProperty');
		}).observes('valuePath', 'async').on('init'),

		query: (function () {
			var query = {};
			var value = [];
			if (this.get('nullAsId') && this.get('selections').contains(String(null))) {
				value.pushObject(String(null));
			}
			var selectionsToTest = this.get('nullAsId') ? this.get('selectionsWithoutNullAsId') : this.get('selections');
			if (selectionsToTest.get('length')) {
				if (!_ember['default'].isEmpty(_ember['default'].getWithDefault(selectionsToTest.get('firstObject'), 'id', null))) {
					value = value.concat(selectionsToTest.getEach('id'));
				} else {
					value = value.concat(selectionsToTest);
				}
			}
			var valuePath = this.get('queryValuePath') || this.get('valuePath');
			var contains = this.get('substringMatch') ? 'icontains' : '';
			query[valuePath.replace(/\./g, '__') + '__' + contains + 'in'] = value; // TODO(ed, manpreet): commas need to be escaped or something...
			return query;
		}).property('queryValuePath', 'valuePath', 'selections.[]', 'nullAsId', 'substringMatch'),

		// TODO(ed): use an observer/computed on the 'options' property that updates selections based on the old options. otherwise selections
		// won't be updated when options change. this will be important for saved views
		// TODO(ed): the default options here takes from the content. otherwise this needs to be overridden with an array or promisearray
		setupOptions: (function () {
			if (!this.get('optionsContent')) {
				return;
			}
			var valuePath = this.get('valuePath');
			var dependentPaths = ['async', 'valueTypeKey', 'nullAsId', 'optionsContentSkipNulls', 'labelFunctionOverride', 'stringModel'].concat(this.get('labelPaths').map(function (labelPath) {
				return 'optionsContent.@each.' + valuePath + '.' + labelPath;
			}));
			this.propertyWillChange('options');
			_ember['default'].defineProperty(this, 'options', _ember['default'].computed.apply(null, dependentPaths.concat(function () {
				var _this5 = this;

				// TODO(ed): must fix these hacks. it needs to be generalized
				var labelPath = this.get('labelPath');
				var modelCountListOptions = {};
				modelCountListOptions.showCountInLabel = this.get('showCountInLabel');
				modelCountListOptions.nullAsId = this.get('nullAsId');
				modelCountListOptions.nullLabel = this.get('nullLabel');
				modelCountListOptions.optionsContentSkipNulls = this.get('optionsContentSkipNulls');
				// TODO(ed): must fix these hacks
				modelCountListOptions.optionLabel = this.get('labelFunctionOverride') ? this.get('labelFunction') : labelPath;
				if (this.get('async')) {
					modelCountListOptions.modelKeyId = valuePath + '.id';
					modelCountListOptions.typeKey = this.get('valueTypeKey');
				}
				if (this.get('optionCountMap')) {
					modelCountListOptions.countMap = this.get('optionCountMap')();
				}
				var data = (0, _componentLibraryFiltersHelpers.modelCountList)(this.get('optionsContent'), valuePath, modelCountListOptions);

				// the sort function needs to be given. default one may account for nullAsId, but not sort order of null

				if (this.get('async')) {
					data.promise.then(function () {
						// TODO(ed): async... this is absolutely insane. There must be a better way.
						(0, _componentLibraryFiltersHelpers.updateModelSelectionsOnModelAndCountsDidChange)(_this5.get('options'), _this5.get('selections'));
						data.set('[]', data.sort(function (a, b) {
							return _ember['default'].compare(a.get('model.' + labelPath), b.get('model.' + labelPath));
						}));
					});
				}

				return this.get('stringModel') ? data.getEach('model') : data;
			})));

			this.propertyDidChange('options');
		}).observes('optionsContent', 'valuePath', 'labelPaths.[]').on('init'),

		serialize: function serialize() {
			// TODO: refactor so that query and serialize share the same code. make sure the computed dependency is correct everywhere
			var value = [];
			if (this.get('nullAsId') && this.get('selections').contains(String(null))) {
				value.pushObject(String(null));
			}
			var selectionsToTest = this.get('nullAsId') ? this.get('selectionsWithoutNullAsId') : this.get('selections');
			if (selectionsToTest.get('length')) {
				if (!_ember['default'].isEmpty(_ember['default'].get(selectionsToTest.get('firstObject'), 'id'))) {
					value = value.concat(selectionsToTest.getEach('id'));
				} else {
					value = value.concat(selectionsToTest);
				}
			}
			return value;
		},

		deserialize: function deserialize(serializedSelections) {
			var _this6 = this;

			if (this.get('substringMatch') || this.get('stringModel')) {
				return serializedSelections.slice();
			}

			var selectedOptions = [];
			if (this.get('nullAsId') && serializedSelections.contains(String(null))) {
				var nullOption = this.get('options').findBy(this.get('optionIdPath'), String(null));
				if (nullOption) {
					selectedOptions.pushObject(nullOption);
				}
				serializedSelections.removeObject(String(null));
			}

			selectedOptions = selectedOptions.concat(this.get('deserializableOptions').filter(function (option) {
				var optionId = _ember['default'].getWithDefault(_ember['default'].get(option, _this6.get('optionValuePath')) || null, 'id', null);
				if (!_ember['default'].isEmpty(optionId)) {
					return serializedSelections.contains(String(optionId));
				}

				return serializedSelections.contains(String(option)) || serializedSelections.contains(String(_ember['default'].get(option, _this6.get('optionValuePath'))));
			}));

			return selectedOptions.getEach(this.get('optionValuePath'));
		},

		updateSelection: (function () {
			var allSerializedSelections = this.get('allSerializedSelections');
			var filterId = this.get('filterId');
			if (allSerializedSelections.hasOwnProperty(filterId) && !this.get('isHidden')) {
				var serializedSelections = allSerializedSelections[filterId];
				this.set('selections', this.deserialize(serializedSelections));
				this.set('isActive', true);
			}
		}).observes('allSerializedSelections')

	});
});