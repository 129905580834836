define('component-library/components/file-uploader', ['exports', 'ember', 'spark-md5'], function (exports, _ember, _sparkMd5) {
	'use strict';

	// Defaults
	var FU_DEFAULT_FILE_UPLOAD_URL = '/placeholder'; // Dropzone initialization fails without url
	var FU_DICT_DEFAULT_MESSAGE = 'Drag File or Browse';
	var FU_DEFAULT_MAX_FILE_SIZE = 25; //in MB

	// Please modify mimetypes as needed.
	// Spreadsheet - includes .csv, and .xls and .xlsx
	// (ms excel and openxml spreasheet, and also google docs)
	var FU_DICT_ACCEPTED_FILE_TYPES = {
		image: '.jpg,.jpeg,.png,.gif,.tiff',
		spreadsheet: 'application/vnd.ms-excel,application/vnd.' + 'openxmlformats-officedocument.spreadsheetml.' + 'sheet,application/vnd.google-apps.spreadsheet,text/csv,' + '.xls,.xlsx,',
		pdf: 'application/pdf',
		zip: 'application/zip,application/x-zip-compressed',
		text: 'text/plain,application/qbooks,application/qbookspro,text/iif,.iif',
		doc: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		xml: 'text/xml'
	};

	var FU_DICT_ACCEPTED_FILE_TYPES_ALL = Object.keys(FU_DICT_ACCEPTED_FILE_TYPES).map(function (type) {
		return FU_DICT_ACCEPTED_FILE_TYPES[type];
	}).join(',');

	// Icons
	var FU_ICON_ERROR = '<i class="zmdi zmdi-alert-triangle fileUploader-icon"></i>';

	// Timeout durations in ms
	var FU_ERROR_MESSAGE_RESET_DURATION = 3000;
	var FU_PROGRESS_SUCCESS_DURATION = 1000;
	var FU_PROGRESS_INITIATE_DURATION = 500;
	var FU_PROGRESS_OFFSET = 10;

	// Class Selectors
	var FU_DOM_SELECTOR_PREFIX = '.js-fileUploader-dropzone';
	var FU_DOM_MESSAGE_SELECTOR_PREFIX = '.js-fileUploader-dropzone-message';
	var FU_DOM_BROWSE_SELECTOR_PREFIX = '.js-fileUploader-dropzone-browse';

	// Error Messages
	var FU_GENERIC_ERROR = 'We were unable to upload this file.';
	var FU_MAX_FILES_ERROR = 'You can only upload one file.';
	var FU_FILE_SIZE_LIMIT_SERVER_ERROR = 'File size is too large';
	var DZ_FILE_SIZE_LIMIT_CLIENT_ERROR = 'File is too big';
	var DZ_INVALID_MIME_TYPE_ERROR = 'You can\'t upload files of this type.';
	var DZ_MAX_FILES_ERROR = 'You can not upload any more files.';

	// Form Factor
	var FU_LARGE = 'lg';
	var FU_SMALL = 'sm';
	var FU_X_SMALL = 'xs';
	var FU_LINK_NAME_DEFAULT = 'Browse Files';

	var TWO_MEGABYTES = 2097152;

	var FileUploaderFile = _ember['default'].Object.extend({
		name: null,
		url: null
	});

	exports.FileUploaderFile = FileUploaderFile;
	exports['default'] = _ember['default'].Component.extend({
		/* Configurable properties */

		// 'all', 'image', 'spreadsheet', 'pdf', 'text', 'doc'.
		// Could also accept an array of these types. See FU_DICT_ACCEPTED_FILE_TYPES for supported types.
		acceptedFileTypes: 'all',

		// Used to identify which file uploader is sending the action to
		// the parent controller, if multiple of these components exist on a page.
		componentSize: FU_LARGE,
		defaultMessage: FU_DICT_DEFAULT_MESSAGE,
		isSquare: false,
		linkName: FU_LINK_NAME_DEFAULT,
		useCustomErrorMessage: false,
		removeAfterUpload: false,

		// Max file size in MB
		maxFilesize: FU_DEFAULT_MAX_FILE_SIZE,

		// Actions:
		error: null,
		success: null,
		uploadingBegin: null,
		uploadingEnd: null,

		// if this flag is true, it means the user is not logged in so use different apis for initiate and done
		anonymous: false,

		initiateUrl: _ember['default'].computed('anonymous', function () {
			return this.get('anonymous') ? '/custom_api/fileupload_initiate_anonymous' : '/custom_api/fileupload_initiate';
		}),

		doneUrl: _ember['default'].computed('anonymous', function () {
			return this.get('anonymous') ? '/custom_api/fileupload_done_anonymous' : '/custom_api/fileupload_done';
		}),

		//External Error Field

		showError: true,
		errorType: 'text',
		showErrorComputed: _ember['default'].computed('errorText.[]', 'showError', 'errorType', function () {
			return !!this.get('errorText.length') && this.get('showError');
		}),
		showErrorTooltip: _ember['default'].computed('showErrorComputed', 'errorType', function () {
			return this.get('showErrorComputed') && this.get('errorType') === 'tooltip';
		}),
		showErrorText: _ember['default'].computed('showErrorComputed', 'errorType', function () {
			return this.get('showErrorComputed') && this.get('errorType') === 'text';
		}),

		// external file metadata
		file: _ember['default'].computed(function () {
			return FileUploaderFile.create();
		}),

		/* End configurable properties */
		_file: null, // internal file data reference for dropzone usage
		_fileUrl: _ember['default'].computed.alias('file.url'),
		_fileName: _ember['default'].computed.alias('file.name'),
		_uploadUrl: null,
		_hasError: false,
		_hasFile: _ember['default'].computed.bool('file.url'),
		_isUploadComplete: false,
		_fullFileUrl: null,

		isLarge: _ember['default'].computed.equal('componentSize', FU_LARGE),
		isSmall: _ember['default'].computed.equal('componentSize', FU_SMALL),
		isXSmall: _ember['default'].computed.equal('componentSize', FU_X_SMALL),

		_defaultMessageWithIcon: _ember['default'].computed('defaultMessage', function () {
			return this.get('defaultMessage');
		}),

		// Note: Have to use a counter because dropzone is incorrectly triggering extra dragenter and
		// dragleave events which is causing the drag-over css styles to prematurely disappear.
		_dragCount: 0,
		_isDragEntered: _ember['default'].computed.gt('_dragCount', 0),
		_isUploadingOrComplete: _ember['default'].computed.or('_isUploading', '_isUploadComplete'),

		_acceptedFiles: _ember['default'].computed('acceptedFileTypes', function () {
			var acceptedFileTypes = this.get('acceptedFileTypes');

			if (_ember['default'].isArray(acceptedFileTypes)) {
				return acceptedFileTypes.map(function (type) {
					return FU_DICT_ACCEPTED_FILE_TYPES[type] || '';
				}).join(',');
			}

			if (acceptedFileTypes === 'all' || !FU_DICT_ACCEPTED_FILE_TYPES[acceptedFileTypes]) {
				return FU_DICT_ACCEPTED_FILE_TYPES_ALL;
			}

			return FU_DICT_ACCEPTED_FILE_TYPES[acceptedFileTypes];
		}),

		classNames: ['u-overflowHidden'],
		classNameBindings: ['isSquare:fileUploader-square-container:fileUploader-rectangle-container', 'isSmall:fileUploader-small', 'isLarge:fileUploader-large', 'isXSmall:fileUploader-x-small'],

		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super.apply(this, arguments);

			var dropzoneObjectClass = FU_DOM_SELECTOR_PREFIX + '-' + this.elementId;

			this.set('_message', this.get('_fileName') || this.get('_defaultMessageWithIcon'));
			this.set('_isUploadComplete', !!this.get('_fileUrl'));

			var dropzones = _ember['default'].$(dropzoneObjectClass).dropzone({
				autoProcessQueue: false,
				url: this.get('_fileUrl') || FU_DEFAULT_FILE_UPLOAD_URL,
				createImageThumbnails: false,
				addRemoveLinks: true,
				clickable: FU_DOM_BROWSE_SELECTOR_PREFIX + '-' + this.elementId,
				maxFilesize: this.get('maxFilesize'),
				maxFiles: 1,
				acceptedFiles: this.get('_acceptedFiles'),
				method: 'PUT',

				// Events:
				dragenter: this._onDragEnter.bind(this),
				dragleave: this._onDragLeave.bind(this),
				drop: this._onDrop.bind(this),
				addedfile: this._addedFile.bind(this),
				sending: this._onSending.bind(this),
				uploadprogress: this._onUploadProgress.bind(this),
				success: this._onSuccess.bind(this),
				error: this._onError.bind(this)
			});

			this._dropzone = dropzones[0].dropzone;

			this._$dropzone = this.$(dropzoneObjectClass);

			this._$dropzoneMessage = this._$dropzone.find(FU_DOM_MESSAGE_SELECTOR_PREFIX + '-' + this.elementId);

			this._$dropzoneMessage.click(function () {
				// Hack - inner content isn't bubbling click events back up to
				// dropzone parent to trigger file selection click.
				_this._$dropzone.trigger('click');
			});
		},

		willDestroyElement: function willDestroyElement() {
			this._$dropzone.off();
			this._$dropzone = null;
			this._$dropzoneMessage.off();
			this._$dropzoneMessage = null;

			this._super.apply(this, arguments);
		},

		_calculateMD5: function _calculateMD5(file) {
			var _this2 = this;

			var deferred = {};
			var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
				deferred.resolve = resolve;
				deferred.reject = reject;
			});
			var blobSlice = File.prototype.slice;
			var chunkSize = TWO_MEGABYTES;
			var chunks = Math.ceil(file.size / chunkSize);
			var fileReader = new FileReader();
			var spark = new _sparkMd5['default'].ArrayBuffer();

			var currentChunk = 0;

			function loadNext() {
				var start = currentChunk * chunkSize;
				var end = start + chunkSize >= file.size ? file.size : start + chunkSize;
				// const blob = new Blob([JSON.stringify(file)], {type : 'application/json'});
				fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
			}

			fileReader.onload = function (e) {
				spark.append(e.target.result);
				currentChunk++;

				if (currentChunk < chunks) {
					loadNext();
				} else {
					var digest = btoa(spark.end(true));
					deferred.resolve(digest);
				}
			};

			fileReader.onerror = function () {
				_this2.eventLogger.log('file-uploader', {
					message: 'Error occurred during MD5 generation.'
				});
			};

			loadNext();

			return promise;
		},

		_formatFilename: function _formatFilename(message) {
			var msgArray = message.split(/[. ]+/);
			var extension = msgArray.pop();
			var fileName = msgArray.join('');

			if (fileName.length >= 10) {
				return fileName.slice(0, 7) + '...' + fileName.slice(-3) + '.' + extension;
			}

			return message;
		},

		_addedFile: function _addedFile(file) {
			// Have to debounce all the calls in case they add multiples
			if (file && file.size < this.get('maxFilesize') * 1024 * 1024) {
				_ember['default'].run.debounce(this, '_doAddFileFromDropZone', 1);
			}
		},

		_getFileContentType: function _getFileContentType(file) {
			// TODO comment back in when we decide/fix the links opening in the same tab
			if (file.type === FU_DICT_ACCEPTED_FILE_TYPES['pdf']) {
				// This allows pdfs to have the correct content-type from s3 which is needed for the <object type="application/pdf">
				return file.type;
			}
			return 'application/octet';
		},

		_getFullFileUrl: function _getFullFileUrl(fileUrl) {
			return window.location.origin + fileUrl;
		},

		_doAddFileFromDropZone: function _doAddFileFromDropZone() {
			var _this3 = this;

			if (this._dropzone.files.length !== 1) {
				return;
			}
			var file = this._dropzone.files[0];
			if (this.get('_isUploading')) {
				return;
			}
			this.set('_isUploading', true);
			_ember['default'].run.later(this, function () {
				this.set('_progressBarWidth', FU_PROGRESS_OFFSET);
			}, FU_PROGRESS_INITIATE_DURATION);

			var fileContentType = this._getFileContentType(file);

			return this._calculateMD5(file).then(function (hash) {
				var initiateUrlData = {
					fileContentType: fileContentType,
					category: _this3.get('category'),
					uploadAcl: _this3.get('uploadAcl'),
					employeeId: _this3.get('employeeId'),
					filename: file.name,
					size: file.size,
					contentMD5: hash
				};

				if (fileContentType === 'application/pdf') {
					initiateUrlData.contentDisposition = 'attachment';
				}

				return _ember['default'].ajax({
					url: _this3.get('initiateUrl'),
					type: 'POST',
					async: false,
					data: initiateUrlData
				}).then(function (response) {
					_this3.setProperties({
						_fileUrl: response.file_url,
						_fileName: file.name,
						_uploadUrl: response.upload_url,
						_randomKey: response.random_key,
						_fileId: response.file_id,
						_fullFileUrl: _this3._getFullFileUrl(response.file_url)
					});

					_this3._dropzone.options.url = response.upload_url;
					var dropzoneOptionHeaders = {
						'Content-MD5': hash,
						'Content-Type': fileContentType
					};

					if (fileContentType === 'application/pdf') {
						dropzoneOptionHeaders['Content-Disposition'] = 'attachment';
					}

					_this3._dropzone.options.headers = dropzoneOptionHeaders;
					_this3._dropzone.processQueue();
				})['catch'](function (e) {
					_this3.eventLogger.log('file-uploader', {
						message: 'Error occurred in added file, likely an ajax failure.'
					});
				});
			})['catch'](function (e) {
				_this3.eventLogger.log('file-uploader', {
					message: 'Error occurred during MD5 generation.'
				});
			});
		},

		_onDragEnter: function _onDragEnter() {
			this.incrementProperty('_dragCount');
		},

		_onDragLeave: function _onDragLeave() {
			this.decrementProperty('_dragCount');
		},

		_onDrop: function _onDrop() {
			this.set('_dragCount', 0);
		},

		_onSending: function _onSending(file, xhr, formData) {
			var _send = xhr.send;
			xhr.send = function () {
				_send.call(xhr, file);
			};
			this.set('_isUploading', true);
		},

		_onUploadProgress: function _onUploadProgress(file, progressPct) {
			var perceivedProgress = Math.ceil(progressPct + FU_PROGRESS_OFFSET) * 80 / 100;
			this.set('_progressBarWidth', perceivedProgress);
		},

		_onUploadStartOrEnd: _ember['default'].observer('_isUploading', function () {
			if (this.get('_isUploading')) {
				if (this.attrs.uploadingBegin) {
					this.attrs.uploadingBegin();
				}
			} else {
				if (this.attrs.uploadingEnd) {
					this.attrs.uploadingEnd();
				}
			}
		}),

		_onSuccess: function _onSuccess(file, serverResponse) {
			// Need to push this to the back of the next render queue
			// because otherwise the bar turns green too soon.
			_ember['default'].run.scheduleOnce('afterRender', this, function () {
				this.setProperties({
					_isProgressComplete: true,
					_progressBarWidth: 100
				});
			});

			var doneResult = _ember['default'].ajaxPost(this.get('doneUrl'), {
				key: this.get('_randomKey'),
				uploaded: true
			});

			_ember['default'].run.later(this, function () {
				var _this4 = this;

				doneResult.then(function (resp) {
					_this4.eventLogger.log('file_uploader_done_response', {
						resp: resp,
						key: _this4.get('_randomKey')
					});
					var response = {
						file: file,
						url: _this4.get('_fileUrl'),
						key: _this4.get('_randomKey'),
						id: _this4.get('_fileId'),
						uploadUrl: _this4.get('_uploadUrl'),
						name: _this4.get('name'),
						fullFileUrl: _this4.get('_fullFileUrl')
					};

					_this4.setProperties({
						_message: _this4._formatFilename(file.name),
						_isUploading: false,
						_isUploadComplete: true,
						_file: file,
						_isProgressComplete: false
					});

					if (_this4.attrs.success) {
						_this4.attrs.success(file, response, _this4.elementId);
					}
					if (_this4.get('removeAfterUpload')) {
						_this4.send('removeFile');
					}
				})['catch'](function (e) {
					return _this4._onError(file, e.responseText);
				});
			}, FU_PROGRESS_SUCCESS_DURATION);
		},

		_onError: function _onError(file, dzErrorMsg) {
			this.eventLogger.log('file_uploader_on_error', {
				file: file,
				dzErrorMsg: dzErrorMsg,
				message: this.get('_message'),
				key: this.get('_randomKey')
			});
			var previousMessage = this.get('_message');

			var message = FU_GENERIC_ERROR;
			var resetForm = true;

			if (this.get('_hasFile')) {
				resetForm = false;
			}

			if (!dzErrorMsg || !dzErrorMsg.length) {
				// If the error msg doesn't exist or it's too long (which means it might be a
				// stack trace due to some server failure), show a generic error msg.
				message = FU_GENERIC_ERROR;
			} else if (dzErrorMsg.includes(DZ_FILE_SIZE_LIMIT_CLIENT_ERROR) || dzErrorMsg.includes(FU_FILE_SIZE_LIMIT_SERVER_ERROR)) {
				// First message is client-side DZ-generated error.
				// Second message is from the server.
				// Just show client-side limit error message for now.
				message = 'This file exceeds the limit of ' + this.get('maxFilesize') + 'MB.';
			} else if (dzErrorMsg.includes(DZ_INVALID_MIME_TYPE_ERROR)) {
				// DZ-generated error message for incompatible MIME type.
				message = dzErrorMsg;
			} else if (dzErrorMsg.includes(DZ_MAX_FILES_ERROR)) {
				message = FU_MAX_FILES_ERROR;
				// We need to reset if they tried to upload more than one file
				// so they can try again
				resetForm = true;
			} else if (this.get('useCustomErrorMessage')) {
				message = dzErrorMsg;
			} else {
				message = dzErrorMsg;
			}

			this.setProperties({
				_message: FU_ICON_ERROR + ' ' + message,
				_hasError: true
			});

			// Hide error and reset form after FU_ERROR_MESSAGE_RESET_DURATION miliseconds.
			if (resetForm) {
				_ember['default'].run.later(this, this._reset, FU_ERROR_MESSAGE_RESET_DURATION);
			} else {
				_ember['default'].run.later(this, this.setProperties, {
					_hasError: false,
					_isUploading: false,
					_message: previousMessage
				}, FU_ERROR_MESSAGE_RESET_DURATION);
			}

			if (this.attrs.error) {
				this.attrs.error(file, dzErrorMsg, this.elementId);
			}
		},

		_reset: function _reset() {
			this._dropzone.removeAllFiles();
			this.setProperties({
				_hasError: false,
				_message: this.get('_defaultMessageWithIcon'),
				_dragCount: 0,
				_isUploadComplete: false,
				_isUploading: false,
				_progressBarWidth: null,
				file: FileUploaderFile.create(),
				_file: null,
				_uploadUrl: null
			});
		},

		actions: {
			removeFile: function removeFile() {
				var file = this.get('_file');
				var key = this.get('_randomKey');
				if (file) {
					// only exists if file was uploaded in the same session
					this._dropzone.removeFile(file);
				}
				this._reset();

				if (this.attrs.removeFile) {
					this.attrs.removeFile(file, key);
				}
			}
		}
	});
});