define('component-library/components/external-list-item-readonly', ['exports', 'ember', 'component-library/templates/components/external-list-item-readonly'], function (exports, _ember, _componentLibraryTemplatesComponentsExternalListItemReadonly) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		layout: _componentLibraryTemplatesComponentsExternalListItemReadonly['default'],
		api: null,
		item: null,
		actions: {
			edit: function edit() {
				if (this.api.onedit) {
					this.api.onedit(this.get('item'));
				}
			},
			remove: function remove() {
				if (this.api.ondelete) {
					this.api.ondelete(this.get('item'));
				}
			}
		}
	});
});