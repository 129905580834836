define('component-library/zflow/module-groups/controller', ['exports', 'ember', 'component-library/simple-wizard/step/controller'], function (exports, _ember, _componentLibrarySimpleWizardStepController) {
	'use strict';

	exports['default'] = _componentLibrarySimpleWizardStepController['default'].extend({
		zFlowService: _ember['default'].inject.service('zflow'),
		incompleteGroups: _ember['default'].computed.rejectByProperty('moduleGroups', 'isCompleteOrDeclinedOrSkipped'),
		validateIncompleteGroups: function validateIncompleteGroups(incompleteGroups) {
			return incompleteGroups.get('length') == 0;
		},
		propertiesToValidate: [{
			propertyName: 'incompleteGroups',
			errorPropertyName: 'globalErrors',
			validationFunctionName: 'validateIncompleteGroups'
		}],
		saveAndSyncBackendFlow: function saveAndSyncBackendFlow(validate) {
			var _this = this;

			if (!validate) {
				return _ember['default'].RSVP.resolve();
			}
			return this.get('zFlowService').validateAndPersistModuleGroups(this.get('moduleGroups')).then(function (moduleGroups) {
				return _this.set('moduleGroups', moduleGroups);
			});
		}
	});
});