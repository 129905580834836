define('component-library/mixins/payroll/tax-header-mixin', ['exports', 'ember', 'component-library/components/cards/card-base', 'component-library/constants/z-payroll/service-levels', 'component-library/components/z-status-tag/constants', 'component-library/components/notification-modal', 'ui-infra/utils/sanitize-html', 'component-library/constants/z-payroll/tax-header-const'], function (exports, _ember, _componentLibraryComponentsCardsCardBase, _componentLibraryConstantsZPayrollServiceLevels, _componentLibraryComponentsZStatusTagConstants, _componentLibraryComponentsNotificationModal, _uiInfraUtilsSanitizeHtml, _componentLibraryConstantsZPayrollTaxHeaderConst) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['', ' has been '], ['', ' has been ']),
	    _templateObject2 = _taggedTemplateLiteral(['We\'ll verify your tax info for ', '. If we need any more info, we\'ll reach out.'], ['We\\\'ll verify your tax info for ', '. If we need any more info, we\\\'ll reach out.']);

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	exports['default'] = _ember['default'].Mixin.create({
		zPayrollEnums: undefined,
		company: undefined,
		taxHeader: undefined,
		title: _ember['default'].computed.oneWay('taxHeader.taxAgency'),
		serviceLevel: _ember['default'].computed.oneWay('taxHeader.serviceLevel'),
		status: _ember['default'].computed.oneWay('taxHeader.status'),
		hasActiveEmployees: _ember['default'].computed.oneWay('taxHeader.hasActiveEmployees'),
		changeAgencyStatusButtonText: _ember['default'].computed('status', function () {
			return this.get('status') === 'inactive' ? 'Activate' : 'Deactivate';
		}),
		isFederal: _ember['default'].computed.oneWay('taxHeader.isFederal'),
		taxHeaderClassName: _ember['default'].computed('title', function () {
			var title = !_ember['default'].isEmpty(this.get('title')) ? this.get('title').replace(/\s/g, '-') : '';
			return 'js-glue-edit-tax-header-' + title;
		}),

		displayMode: _ember['default'].computed.not('isEditing'),
		hasConsolePermissions: false,
		isWAJuri: _ember['default'].computed.equal('title', 'Washington Department of Labor & Industries'),
		isFullService: _ember['default'].computed.equal('serviceLevel', _componentLibraryConstantsZPayrollServiceLevels.SERVICE_LEVELS.FULL),
		shouldAlignWithTextField: _ember['default'].computed.not('displayMode'),
		showLabelOnSide: true,
		mode: _componentLibraryComponentsCardsCardBase.SETTINGS_CARD_MODES.READ,
		statusTagContent: _ember['default'].computed('isFullService', function () {
			var popoverMessage = undefined;
			var statusTagColor = undefined;
			var statusTagText = undefined;
			if (this.get('isFullService')) {
				statusTagColor = _componentLibraryComponentsZStatusTagConstants.STATUS_TAG_COLORS.GREEN;
				statusTagText = 'FULL SERVICE';
				popoverMessage = "At this level, we calculate, remit, and file your taxes for this agency.";
			} else {
				statusTagColor = _componentLibraryComponentsZStatusTagConstants.STATUS_TAG_COLORS.RED;
				statusTagText = 'CALCULATE ONLY';
				popoverMessage = "At this level, we calculate your payroll taxes. Then, it's your responsibility to file and remit your taxes to the agency.";
			}

			return {
				popover: { message: popoverMessage },
				color: statusTagColor,
				text: statusTagText
			};
		}),

		showFutureStateTax: true,
		futureStateTaxes: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		displayHistoricalTaxesOnEdit: false,

		isReimbursableOld: undefined,
		isReimbursable: _ember['default'].computed('taxHeader.questions.@each.answer', function () {
			var isReimbursable = !!this.get('taxHeader.questions').filterBy('storageKey', 'isReimbursable').get('firstObject.answer');
			if (!this.get('isReimbursableOld')) {
				this.set('isReimbursableOld', isReimbursable);
			} else if (this.get('isReimbursableOld') !== isReimbursable) {
				this.set('isReimbursableOld', isReimbursable);
				if (!this.get('isFederal')) {
					this._updateSutaAndSurcharges(isReimbursable);
				}
			}
			return isReimbursable;
		}),

		_updateSutaAndSurcharges: function _updateSutaAndSurcharges(isReimbursable) {
			var sutaQuestions = this.get('taxHeader.questions').filter(function (question) {
				return _componentLibraryConstantsZPayrollTaxHeaderConst.SUTA_TAX_TYPES.contains(question.taxType);
			});
			sutaQuestions.forEach(function (question) {
				var defaultRate = !_ember['default'].isEmpty(question.defaultValue) ? parseFloat(question.defaultValue) : _componentLibraryConstantsZPayrollTaxHeaderConst.DefaultSutaRate;
				var rateObj = question.answer[0];
				if (isReimbursable) {
					_ember['default'].set(rateObj, 'previousRate', rateObj.rate);
					_ember['default'].set(rateObj, 'rate', defaultRate);
				} else {
					if (!_ember['default'].isEmpty(rateObj.previousRate)) {
						_ember['default'].set(rateObj, 'rate', rateObj.previousRate);
					}
				}
			});
		},

		_resetFutureStateTaxes: function _resetFutureStateTaxes() {
			var futureStateTaxes = this.get('futureStateTaxes');
			if (futureStateTaxes) {
				futureStateTaxes.setEach('futureTaxError', null);
				futureStateTaxes.setEach('shouldReload', true);
				futureStateTaxes.setObjects([]);
			}
		},

		showAgencyStatusChangeNotification: function showAgencyStatusChangeNotification() {
			var changedStatus = null;
			if (this.get('status') != 'active') {
				changedStatus = 'activated';
			} else {
				changedStatus = 'deactivated';
			}
			var message = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject, this.get('title')) + changedStatus;
			_componentLibraryComponentsNotificationModal['default'].show({
				notificationType: 'success',
				title: 'Status of the agency has been changed.',
				message: message,
				buttonText: 'Done'
			});
		},

		showAgencyStatusChangeFailureNotification: function showAgencyStatusChangeFailureNotification() {
			_componentLibraryComponentsNotificationModal['default'].show({
				notificationType: 'error',
				title: 'Oops! Something went wrong.',
				message: 'An error occurred while changing ths status of the agency. Please try again later.'
			});
		},

		showNotification: function showNotification() {
			var supportHref = "https://secure.zenefits.com/dashboard/#/support";
			var message = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject2, this.get('title'));

			_componentLibraryComponentsNotificationModal['default'].show({
				notificationType: 'success',
				title: 'Thank you!',
				message: message,
				buttonText: 'Got It'
			});
		},

		wcQuestion: _ember['default'].computed('taxHeader.questions.@each.questionType', function () {
			return this.get('taxHeader.questions').findBy('questionType', 'Workers Comp Tax Rate');
		}),

		showAtLeastOneRiskCodeError: _ember['default'].computed('wcQuestion', 'wcQuestion.answer.@each.shouldDelete', function () {
			return this.get('wcQuestion') && _ember['default'].isEmpty(this.get('wcQuestion.answer').rejectBy('shouldDelete', true));
		}),

		hasDuplicateWorkersCompRecord: _ember['default'].computed('wcQuestion.answer.@each.classCode', 'wcQuestion.answer.@each.shouldDelete', 'wcQuestion.answer.[]', function () {
			var wcQuestion = this.get('wcQuestion');
			if (!wcQuestion) {
				return false;
			}
			var classCodeArray = wcQuestion.answer.rejectBy('shouldDelete', true).mapBy('classCode');
			return classCodeArray.length > classCodeArray.uniq().length;
		}),

		getWcClassCode: function getWcClassCode() {
			var wcQuestion = this.get('wcQuestion');
			var set = new Set();
			if (!wcQuestion) {
				return set;
			}
			wcQuestion.answer.forEach(function (ans) {
				set.add(ans.classCode);
			});
			return set;
		},

		convertWcRatesForSave: function convertWcRatesForSave(record) {
			var eeRate = undefined;
			var erRate = undefined;
			if (this.get('isWAJuri')) {
				eeRate = record.employeeWithholding * 100;
				erRate = (record.hourlyRate - record.employeeWithholding) * 100;
			} else {
				eeRate = record.employeeRate;
				erRate = record.employerRate;
			}

			return { 'ER': erRate, 'EE': eeRate };
		},

		convertWcRatesForEdit: function convertWcRatesForEdit() {
			if (this.get('isWAJuri')) {
				var wcQuestion = this.get('wcQuestion');

				wcQuestion.answer.forEach(function (record) {
					var employeeWithholding = record.employeeRate / 100;
					_ember['default'].set(record, 'employeeWithholding', employeeWithholding);

					var hourlyRate = record.employeeRate / 100 + record.employerRate / 100;
					_ember['default'].set(record, 'hourlyRate', hourlyRate);
				});
			}
		},

		// returns true if there's an error related to wc rates setup
		validateWcRates: function validateWcRates() {
			var wcQuestion = this.get('wcQuestion');
			if (!wcQuestion) {
				return false;
			}

			if (this.get('showAtLeastOneRiskCodeError') || this.get('hasDuplicateWorkersCompRecord')) {
				return true;
			}

			var isWaJurisdiction = this.get('isWAJuri');
			var hasError = false;
			wcQuestion.answer.rejectBy('shouldDelete', true).forEach(function (record) {
				var errors = [];

				var classCodeError = _ember['default'].isEmpty(record.classCode) ? _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Missing : '';
				errors.push(classCodeError);
				_ember['default'].set(record, 'classCodeError', classCodeError);

				if (isWaJurisdiction) {
					var hourlyRateError = _ember['default'].isEmpty(record.hourlyRate) ? _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Missing : '';
					var employeeWithholdingError = _ember['default'].isEmpty(record.employeeWithholding) ? _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Missing : '';

					if (_ember['default'].isEmpty(employeeWithholdingError) && _ember['default'].isEmpty(hourlyRateError)) {
						var employeeWithholding = Number(record.employeeWithholding);
						if (employeeWithholding < 0 || employeeWithholding > _componentLibraryConstantsZPayrollTaxHeaderConst.WC_RATE_LIMIT) {
							employeeWithholdingError = _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Invalid;
						}

						var hourlyRate = Number(record.hourlyRate);
						if (hourlyRate - employeeWithholding > _componentLibraryConstantsZPayrollTaxHeaderConst.WC_RATE_LIMIT) {
							hourlyRateError = _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Invalid;
						} else if (hourlyRate < employeeWithholding) {
							hourlyRateError = _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.WrongHourlyRate;
						}
					}

					errors.push(hourlyRateError);
					_ember['default'].set(record, 'hourlyRateError', hourlyRateError);

					errors.push(employeeWithholdingError);
					_ember['default'].set(record, 'employeeWithholdingError', employeeWithholdingError);
				} else {
					var employeeRateError = _ember['default'].isEmpty(record.employeeRate) ? _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Missing : '';
					errors.push(employeeRateError);
					_ember['default'].set(record, 'employeeRateError', employeeRateError);

					var employerRateError = _ember['default'].isEmpty(record.employerRate) ? _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Missing : '';
					errors.push(employerRateError);
					_ember['default'].set(record, 'employerRateError', employerRateError);
				}

				hasError = hasError || errors.any(function (error) {
					return !_ember['default'].isEmpty(error);
				});
			});

			return hasError;
		},

		validateRates: function validateRates() {
			var hasError = false;
			this.get('taxHeader.questions').filterBy('questionType', 'Tax Rate').forEach(function (question) {
				if (!_ember['default'].isEmpty(question.answer)) {
					var rateObj = question.answer[0];
					var currentTaxError = _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Missing;
					if (!_ember['default'].isEmpty(rateObj.rate)) {
						currentTaxError = Number(rateObj.rate) < question.minValue || Number(rateObj.rate) > question.maxValue ? _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Invalid : '';
					}
					_ember['default'].set(rateObj, 'errorText', currentTaxError);

					var futureTax = rateObj.futureStateTax;
					var futureTaxError = undefined;
					if (futureTax && !futureTax.shouldDelete) {
						futureTaxError = _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Missing;
						if (!_ember['default'].isEmpty(futureTax.rate)) {
							futureTaxError = Number(futureTax.rate) < question.minValue || Number(futureTax.rate) > question.maxValue ? _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Invalid : '';
						}
						_ember['default'].set(futureTax, 'futureTaxError', futureTaxError);
					}
					hasError = hasError || !_ember['default'].isEmpty(currentTaxError) || !_ember['default'].isEmpty(futureTaxError);
				}
			});
			return hasError;
		},

		populateUneditedRates: function populateUneditedRates() {
			this.get('taxHeader.questions').filterBy('questionType', 'Tax Rate').forEach(function (question) {
				if (!_ember['default'].isEmpty(question.answer)) {
					var rateObj = question.answer[0];
					var rateValue = rateObj.rate;
					_ember['default'].set(rateObj, 'uneditedRate', rateValue);
				}
			});
		},

		validatePFMLCoverage: function validatePFMLCoverage() {
			var hasError = false;
			this.get('taxHeader.questions').filterBy('questionType', 'Text Field').forEach(function (question) {
				if (question.storageKey === 'pflERCoverage' || question.storageKey === 'pmlERCoverage') {
					var coverage = question.answer;
					var currentCoverageError = !_ember['default'].isEmpty(coverage) ? '' : _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Missing;
					if (!_ember['default'].isEmpty(coverage)) {
						currentCoverageError = Number(coverage) < question.minValue || Number(coverage) > question.maxValue ? _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Invalid : '';
					}
					hasError = hasError || !_ember['default'].isEmpty(currentCoverageError);
					_ember['default'].set(question, 'hasError', hasError);
					_ember['default'].set(question, 'errorDescription', currentCoverageError);
				}
			});
			return hasError;
		},

		validateFLIERCoverage: function validateFLIERCoverage() {
			var hasError = false;
			this.get('taxHeader.questions').filterBy('questionType', 'Text Field').forEach(function (question) {
				if (_componentLibraryConstantsZPayrollTaxHeaderConst.FLI_ER_COVERAGE_PERCENT.includes(question.storageKey)) {
					var coverage = question.answer;
					var currentCoverageError = !_ember['default'].isEmpty(coverage) ? '' : _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.Missing;
					if (!_ember['default'].isEmpty(coverage)) {
						currentCoverageError = Number.isInteger(parseFloat(coverage)) ? '' : _componentLibraryConstantsZPayrollTaxHeaderConst.ErrorMessage.IntegerOnly;
					}
					hasError = hasError || !_ember['default'].isEmpty(currentCoverageError);
					_ember['default'].set(question, 'hasError', hasError);
					_ember['default'].set(question, 'errorDescription', currentCoverageError);
				}
			});
			return hasError;
		},

		_resetFutureQuestions: function _resetFutureQuestions() {
			var _this = this;

			if (this.get('taxHeader.futureQuestions') == null) {
				return;
			}
			//reset needs update flag
			this.get('taxHeader.futureQuestions').forEach(function (futureQuestion) {
				_ember['default'].set(futureQuestion, 'needsUpdate', false);
			});
			_ember['default'].ajaxPost('/custom_api/z_payroll/getFutureQuestionsForAgency/', JSON.stringify({
				company_id: this.get('company.id'),
				agencyId: this.get('title'),
				storageKeys: this.get('futureQuestions').mapBy('storageKey')
			})).then(function (res) {
				var futureQuestionResponses = res.data;
				if (!_ember['default'].isEmpty(futureQuestionResponses)) {
					_this.get('taxHeader.futureQuestions').forEach(function (futureQuestion) {
						if (futureQuestion.storageKey in futureQuestionResponses) {
							_ember['default'].set(futureQuestion, 'answer', futureQuestionResponses[futureQuestion.storageKey].answer);
							_ember['default'].set(futureQuestion, 'futureEffectiveDate', futureQuestionResponses[futureQuestion.storageKey].futureEffectiveDate);
						}
					});
				}
			});
		}
		// actions: {

		// },
	});
});