define('component-library/mixins/zen-select-wrapper', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		typeToField: { 'E': 'fullName', 'U': 'displayName', 'G': 'username', 'P': 'informalFullName' }, // default is 'name'
		addToItems: function addToItems(items, type, initialItemsName) {
			var initialItems = this.get(initialItemsName);
			if (!initialItems) {
				return;
			}
			initialItems.forEach((function (item) {
				items.pushObject(this.createWrapperItem(item, type));
			}).bind(this));
		},
		createWrapperItem: function createWrapperItem(item, type) {
			return item ? _ember['default'].Object.create({
				'id': item.get('id'),
				'displayName': item.get(this.typeToField[type] || 'name'),
				'item': item,
				'type': type
			}) : null;
		},
		setDiffs: function setDiffs(type, initialItemsName, addedItemsName, removedItemsName) {
			var newItems = this.get('selectedItems').filterProperty('type', type).mapProperty('item');
			var initialItems = this.get(initialItemsName);
			this.set(addedItemsName, newItems.filter(function (item) {
				return initialItems.indexOf(item) < 0;
			}));
			this.set(removedItemsName, initialItems.filter(function (item) {
				return newItems.indexOf(item) < 0;
			}));
		}
	});
});