define('component-library/mixins/editable-paystub-mixin', ['exports', 'ember', 'component-library/proxies/payroll-taxes', 'component-library/components/notification-modal', 'lodash/lodash', 'component-library/constants/payroll'], function (exports, _ember, _componentLibraryProxiesPayrollTaxes, _componentLibraryComponentsNotificationModal, _lodashLodash, _componentLibraryConstantsPayroll) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		// expects the following input
		model: null,
		employee: null,
		zprEmployee: null,
		deductionTypes: [],
		contributionTypes: [],
		zpEmployeeContributions: [],
		earningTypes: [],

		isInitPayrollRun: false,
		isTaxEditable: false,
		payrollRun: false,
		isAddingNewEarningPayItem: false,
		isAddingNewDeductionPayItem: false,
		isAddingNewContributionPayItem: false,
		isAddingNewReimbursementPayItem: false,

		earnings: _ember['default'].computed.oneWay('model.earnings'),
		deductions: _ember['default'].computed.oneWay('model.deductions'),
		contributions: _ember['default'].computed.oneWay('model.contributions'),
		taxes: _ember['default'].computed.oneWay('model.taxes'),
		taxCredits: _ember['default'].computed.oneWay('model.taxCredits'),
		garnishments: _ember['default'].computed.oneWay('model.garnishments'),
		activeEarningTypes: _ember['default'].computed.alias('earningTypes'),
		activeDeductionTypes: _ember['default'].computed.alias('deductionTypes'),
		activeContributionTypes: _ember['default'].computed.alias('contributionTypes'),
		activeGarnishmentTypes: _ember['default'].computed.filterByProperty('garnishments', 'isActive'),
		normalEarningTypes: _ember['default'].computed.rejectByProperty('activeEarningTypes', 'isReimbursement'),
		reimbursementTypes: _ember['default'].computed.filterByProperty('activeEarningTypes', 'isReimbursement'),

		isDirty: _ember['default'].computed('wrappedModel.isDirty', 'zprEmployee.isDirty', function () {
			return this.get('wrappedModel.isDirty') || this.get('zprEmployee.isDirty');
		}),

		canSavePaystub: _ember['default'].computed('isEditable', 'isPaymentMethodEditable', 'isDirty', 'apiErrors.@each', function () {
			if (this.get('apiErrors.length')) {
				return true;
			}
			if (!this.get('isEditable') && !this.get('isPaymentMethodEditable')) {
				return false;
			}
			if (!this.get('isDirty')) {
				return false;
			}
			return true;
		}),
		wrappedModel: _ember['default'].computed('model', function () {
			return _componentLibraryProxiesPayrollTaxes['default'].create({
				content: this.get('model'),
				cleanModel: this.get('cleanModel'),
				isInitPayrollRun: this.get('isInitPayrollRun'),
				employee: this.get('employee'),
				deductionTypes: this.get('activeDeductionTypes'),
				contributionTypes: this.get('activeContributionTypes'),
				zpEmployeeContributions: this.get('zpEmployeeContributions'),
				earningTypes: this.get('normalEarningTypes'),
				garnishmentTypes: this.get('activeGarnishmentTypes'),
				reimbursementTypes: this.get('reimbursementTypes'),
				zPayrollRun: this.get('zprEmployee.zPayrollRun'),
				isOffCycle: this.get('zprEmployee.zPayrollRun.isOffCycle'),
				isDraftRun: this.get('zprEmployee.zPayrollRun.isDraft'),
				isCorrectionRequestPayRun: this.get('zprEmployee.zPayrollRun.isCorrectionRequestPayRun'),
				canModifyPto: this.get('canModifyPto'),
				switches: this.get('switches'),
				eventLogger: this.get('eventLogger')
			});
		}),

		hasDuplicate: function hasDuplicate(array, key) {
			array = array.getEach(key);
			return array.uniq().get('length') < array.get('length');
		},

		// TODO: Bob Z
		// - refactor editable paystub to use z-text-field
		// - add decimal option to z-text-field-number
		// - replace this function with above
		verifyAndCorrectEarningValues: function verifyAndCorrectEarningValues() {
			// Correct Earning decimal places if needed:
			if (this.get('isEditable')) {
				this.get('wrappedModel.earnings').forEach(function (earning) {
					if (earning.get('canEditHourAndRate') && earning.get('numUnits') != 0 && earning.get('ratePerUnit') != 0 && earning.get('isUserModified') == true) {
						var origNumUnits = parseFloat(earning.get('numUnits')).toFixed(2);
						var origRatePerUnit = parseFloat(earning.get('ratePerUnit')).toFixed(6);
						earning.set('numUnits', origNumUnits);
						earning.set('ratePerUnit', origRatePerUnit);
						var newAmount = (origNumUnits * origRatePerUnit).toFixed(6);
						earning.set('amount', newAmount);
					}
				});
			}
		},

		savePayrollRunEmployeeInfo: function savePayrollRunEmployeeInfo(ldmprSwitch) {
			var _this = this;

			var model = this.get('wrappedModel');
			var employeeFirstName = _ember['default'].isEmpty(this.get('employee.firstName')) ? 'this employee' : this.get('employee.firstName');

			// Keep these if statements in this order so that it maps the order of pay item types displayed on pay stubs
			if (this.hasDuplicate(model.get('nonDeletedEarnings'), 'zpCompanyEarningType_id')) {
				if (!ldmprSwitch) {
					// Only reject duplicate earning line items if LDMPR project
					// Is not enabled for this company
					_componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'You can’t have duplicate earnings.',
						message: 'To save your changes, make sure ' + employeeFirstName + ' doesn’t have more than one of the same earning type. You can combine them into a single line item, or create a new earning type in your payroll settings.'
					});
					return _ember['default'].RSVP.reject();
				}
			}
			if (this.hasDuplicate(model.get('nonDeletedDeductions'), 'zpCompanyDeductionType_id')) {
				_componentLibraryComponentsNotificationModal['default'].show({
					notificationType: 'error',
					title: 'You can’t have duplicate deductions.',
					message: 'To save your changes, make sure ' + employeeFirstName + ' doesn’t have more than one of the same deduction type. You can combine them into a single line item, or create a new deduction type in your payroll settings.'
				});
				return _ember['default'].RSVP.reject();
			}
			if (this.hasDuplicate(model.get('nonDeletedReimbursements'), 'zpCompanyEarningType_id')) {
				if (!ldmprSwitch) {
					// Only reject duplicate reimbursement line items if LDMPR project
					// Is not enabled for this company
					_componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'You can’t have duplicate reimbursements.',
						message: 'To save your changes, make sure ' + employeeFirstName + ' doesn’t have more than one of the same reimbursement type. You can combine them into a single line item, or create a new reimbursement type in your payroll settings.'
					});
					return _ember['default'].RSVP.reject();
				}
			}
			if (this.hasDuplicate(model.get('nonDeletedContributions'), 'zpCompanyContributionType_id')) {
				_componentLibraryComponentsNotificationModal['default'].show({
					notificationType: 'error',
					title: 'You can’t have duplicate contributions.',
					message: 'To save your changes, make sure ' + employeeFirstName + ' doesn’t have more than one of the same contribution type. You can combine them into a single line item, or create a new contribution type in your payroll settings.'
				});
				return _ember['default'].RSVP.reject();
			}

			var zprEmployee = this.get('zprEmployee');
			var zprEmployeeId = zprEmployee.get('id');
			var json = this.get('wrappedModel.content');
			var url = '/custom_api/z_payroll/post_zpr_employee_editable_paystub/' + json.id;
			if (zprEmployee.get('isDirty')) {
				var dirtyAttributes = zprEmployee.get('_dirtyAttributes');
				if (dirtyAttributes.indexOf('isPaidByCheck') >= 0) {
					zprEmployee.set('isUserModifiedPaymentMethod', true);
					zprEmployee.set('isUserModified', true);
				}
			}

			this.verifyAndCorrectEarningValues();

			// Eager saving - mark ee as clean to allow background saving and validation
			if (this.get('isDeferSaveEnabled')) {
				this.set('wrappedModel.isDirty', false);
			}

			return _ember['default'].ajax({
				url: url,
				type: 'POST',
				contentType: 'application/json',
				dataType: 'json',
				data: JSON.stringify(json)
			}).then(function (json) {
				// Refresh model only if user has not loaded a different EE
				if (_this.get('zprEmployee.id') === zprEmployeeId) {
					_this.setProperties({
						model: json,
						cleanModel: _lodashLodash['default'].clone(json, true)
					});

					// either way we need to reload zprEmployee from server because some of
					// the totals are updated
					return zprEmployee.get('isDirty') ? zprEmployee.save() : zprEmployee.reload();
				}
			})['catch'](function (response) {
				_this.eventLogger.log('payroll_pay_runs_edit_paystub_save_error', {
					error: JSON.stringify(response),
					model: json,
					message: 'Failed to save changes on paystub.'
				});

				if (_this.get('isDeferSaveEnabled')) {
					return _ember['default'].RSVP.reject();
				}

				return _componentLibraryComponentsNotificationModal['default'].show({
					notificationType: 'error',
					title: 'Internal error',
					message: 'Oops! Something went wrong. We are working on getting this fixed as soon as we can. Please try again.'
				});
			});
		},

		resetPayItemControls: function resetPayItemControls() {
			this.setProperties({
				isAddingNewEarningPayItem: false,
				isAddingNewDeductionPayItem: false,
				isAddingNewContributionPayItem: false,
				isAddingNewReimbursementPayItem: false
			});
		},

		actions: {
			confirmNewPayItem: function confirmNewPayItem(payItemType, newPayItem) {
				var _this2 = this;

				if (!newPayItem) {
					return _componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'Missing ' + payItemType + ' type',
						message: 'There is no company ' + payItemType + ' type selected.'
					});
				}
				var newPayItemId = newPayItem.get('id');

				// only calculate rate for earning
				if (payItemType === _componentLibraryConstantsPayroll.ZPCOMPANY_PAY_ITEM_TYPES.earning) {
					(function () {
						// Make api call to calculate ratePerUnit w.r.t multiplier
						var employeeId = _this2.get('employee.id');
						var zprEmployeeId = _this2.get('model.id');
						var getRatePerUnitUrl = '/custom_api/z_payroll/get_company_earning_ratePerUnit/';
						_ember['default'].ajaxGet(getRatePerUnitUrl, {
							isPayTemplate: false,
							employeeId: employeeId,
							zprEmployeeId: zprEmployeeId,
							zpCompanyEarningTypeId: newPayItemId
						}).then(function (data) {
							_this2.get('earnings').pushObject({
								amount: '',
								isUserModified: true,
								isReimbursement: false,
								ratePerUnit: data.ratePerUnit,
								category: newPayItem.get('category'),
								zpCompanyEarningType_id: Number(newPayItemId),
								zprEmployee_id: Number(_this2.get('model.id')),
								showTimeOffRequests: true
							});
						})['catch'](function (error) {
							_this2.eventLogger.log('payroll_pay_runs_edit_paystub_calculateRatePerUnitError', {
								employeeId: employeeId,
								zpCompanyEarningTypeId: newPayItemId,
								message: 'Failed to calculate ratePerUnit for a new earning being added.'
							});
							return _componentLibraryComponentsNotificationModal['default'].show({
								notificationType: 'error',
								title: 'Internal error',
								message: 'We experienced an unexpected error. Please try again in a few minutes.'
							});
						})['finally'](function () {
							_this2.set('isAddingNewEarningPayItem', false);
						});
					})();
				} else if (payItemType === _componentLibraryConstantsPayroll.ZPCOMPANY_PAY_ITEM_TYPES.deduction) {
					var deduction = newPayItem.get('deduction');
					this.get('deductions').pushObject({
						isUserModified: true,
						category: newPayItem.get('category'),
						name: newPayItem.get('name'),
						deduction: deduction > 0 ? deduction : '',
						isPercentage: newPayItem.get('isPercentage'),
						zpCompanyDeductionType_id: Number(newPayItemId),
						zprEmployee_id: Number(this.get('model.id'))
					});
					this.set('isAddingNewDeductionPayItem', false);
				} else if (payItemType === _componentLibraryConstantsPayroll.ZPCOMPANY_PAY_ITEM_TYPES.contribution) {
					var contribution = newPayItem.get('contribution');
					this.get('contributions').pushObject({
						isUserModified: true,
						category: newPayItem.get('category'),
						name: newPayItem.get('name'),
						contribution: contribution > 0 ? contribution : '',
						isPercentage: newPayItem.get('isPercentage'),
						zpCompanyContributionType_id: Number(newPayItemId),
						zprEmployee_id: Number(this.get('model.id'))
					});
					this.set('isAddingNewContributionPayItem', false);
				} else if (payItemType === _componentLibraryConstantsPayroll.ZPCOMPANY_PAY_ITEM_TYPES.reimbursement) {
					this.get('earnings').pushObject({
						amount: '',
						isUserModified: true,
						isReimbursement: true,
						category: newPayItem.get('category'),
						zpCompanyEarningType_id: Number(newPayItemId),
						zprEmployee_id: Number(this.get('model.id'))
					});
					this.set('isAddingNewReimbursementPayItem', false);
				} else {
					this.resetPayItemControls();
				}
			},

			deletePayItem: function deletePayItem(record) {
				record.set('willDelete', true);
				if (record.get('laborFields')) {
					var laborFields = record.get('laborFields');
					laborFields.forEach(function (laborField) {
						laborField.willDelete = true;
						laborField.id = null;
					});
				}
			},

			rollback: function rollback(laddaButton) {
				var zprEmployee = this.get('zprEmployee');
				var cleanModel = this.get('cleanModel');
				this.set('model', _lodashLodash['default'].clone(cleanModel, true));
				zprEmployee.rollback();
			}
		}
	});
});