define('component-library/utils/payroll/smp/fix-records/employee-errors-object', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Object.extend({
		employeeId: null,
		firstName: '',
		lastName: '',
		errors: null,
		numErrors: _ember['default'].computed('errors', function () {
			var errors = this.get('errors');

			if (!errors) {
				return 0;
			} else {
				return errors.length;
			}
		}),
		numErrorsHuman: _ember['default'].computed('numErrors', function () {
			var numErrors = this.get('numErrors');
			return numErrors + (numErrors === 1 ? ' issue' : ' issues');
		})
	});
});