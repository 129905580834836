define('component-library/modals/payroll/confirmation-modal', ['exports', 'ember', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryComponentsPromiseModal) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'modals/payroll/confirmation-modal',
		modalDialogClass: 'z-payroll-confirmationModal js-glue-payroll-confirmationModal z-typography',
		showCancelButton: true,
		autoSetHeight: false,
		isResponsive: true,
		isLoading: false,
		title: '',
		message: '',
		confirmButtonClass: 'btn--primary',
		confirmButtonDisplayText: 'Confirm',
		cancelButtonDisplayText: 'Cancel',
		onConfirm: _ember['default'].K,
		leftAlign: false,
		parameters: null,
		showIllustration: false,
		showProceedLink: false,

		actions: {
			apply: function apply() {
				var _this = this;

				this.set('isLoading', true);
				var promise = this.onConfirm(this.get('parameters'));
				if (promise && promise.then) {
					promise.then(function (data) {
						_this.send('confirm', data);
					}, function (error) {
						_this.send('cancel', error);
					});
				} else {
					this.send('confirm');
				}
			},

			// forced proceed submitting pay run
			proceed: function proceed() {
				this.set('isLoading', true);
				this.send('confirm', 'forced_proceed');
			}
		}
	});
});