define('component-library/constants/bor-broker-services', ['exports'], function (exports) {
	// AdminReviewSuperSession status
	'use strict';

	var ADMIN_REVIEW_SUPER_SESSION_STATUS = {
		ACTIVE: 'active',
		LOCKED: 'locked',
		COMPLETED: 'completed'
	};
	exports.ADMIN_REVIEW_SUPER_SESSION_STATUS = ADMIN_REVIEW_SUPER_SESSION_STATUS;
});