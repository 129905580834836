define('component-library/components/payroll/overview-timeline', ['exports', 'ember', 'component-library/utils/globals', 'component-library/filters/filter-array', 'component-library/filters/multi-select-filter-spec', 'component-library/utils/traditional-query-converter', 'component-library/timeline/utils/types', 'component-library/components/payroll-timeline'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryFiltersFilterArray, _componentLibraryFiltersMultiSelectFilterSpec, _componentLibraryUtilsTraditionalQueryConverter, _componentLibraryTimelineUtilsTypes, _componentLibraryComponentsPayrollTimeline) {
	'use strict';

	var DisplayEntry = _ember['default'].Object.extend({
		entry: null,

		dataComponent: (function () {
			return this.get('entry.data.component') || 'paystubs/components/default';
		}).property('entry.data.component')
	});

	exports['default'] = _ember['default'].Component.extend({
		templateName: 'components/payroll/overview-timeline',

		store: _ember['default'].inject.service(),
		consoleCompanyId: null,
		employeeId: null,
		currentDate: null,
		requestId: 1,

		isLoading: false,

		entryTypes: (function () {
			return (0, _componentLibraryTimelineUtilsTypes.getEntryTypeFilters)(_componentLibraryComponentsPayrollTimeline.payrollTypeList);
		}).property(),

		init: function init() {
			this._super.apply(this, arguments);

			this.initLoad();
		},

		initLoad: (function () {
			this.set('entries', []);
			this.set('isDone', false);
			this.fetch();
		}).observes('employeeId'),

		fetch: function fetch() {
			var _this = this;

			// TODO(mnm): replace this with an action

			var qps = { 'entry_type__in': this.get('entryTypes').map(function (entryType) {
					return entryType.model;
				}).join(',') };
			var currentDate = this.get('currentDate');
			if (_componentLibraryUtilsGlobals.moment.isMoment(currentDate)) {
				qps['from'] = this.get('currentDate').utc().format();
			}
			var employeeId = this.get('employeeId');
			var consoleCompanyId = this.get('consoleCompanyId');
			if (employeeId) {
				qps['employee'] = employeeId;
			}

			if (consoleCompanyId) {
				qps['company'] = consoleCompanyId;
			}
			qps['limit'] = 4; // only display the first 4 feeds

			var entriesPromise = this.get('store').find('feedEntry', qps);

			this.incrementProperty('requestId');
			var thisRequestId = this.get('requestId');
			entriesPromise.then(function (entries) {
				if (_this.get('requestId') != thisRequestId) {
					throw new Error('outdated request');
				}
				_this.addEntries(entries.slice());
				if (_ember['default'].isEmpty(entries)) {
					_this.set('isDone', true);
				} else {
					// timestamp is a js Date object
					_this.set('currentDate', (0, _componentLibraryUtilsGlobals.moment)(entries.get('lastObject.timestamp')));
				}
			}).then(function () {
				_this.set('isLoading', false);
			})['catch'](function (err) {
				if (!err || err.message != 'outdated request') {
					_this.eventLogger.logError(err);
				}
			});
			this.set('isLoading', true);
		},

		// this function attempts to modify the entries array without replacing it, which would result in a complete re-render
		// this means avoiding all the fun ES5 methods designed for operating on immutable arrays
		addEntries: function addEntries(newEntries) {
			newEntries = newEntries.map(function (ent) {
				return DisplayEntry.create({
					entry: ent
				});
			});

			this.get('entries').pushObjects(newEntries);
		},

		getFullFeedEntry: (function () {
			var _this2 = this;

			return function (id) {
				return _this2.get('store').find('feedFullEntry', id);
			};
		}).property()
	});
});