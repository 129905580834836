define("component-library/initializers/setup-app-store", ["exports"], function (exports) {
  "use strict";

  exports["default"] = {
    name: "app-store",
    after: "store",
    initialize: function initialize(container, application) {
      var store = container.lookup('store:main');
      if (!window.App.store) {
        window.App.store = store;
      }
      container.register('service:store', store, { instantiate: false });
    }
  };
});