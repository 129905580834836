define('component-library/components/reports/timeattendance/download-summary-report-modal', ['exports', 'ember', 'component-library/components/promise-modal', 'component-library/utils/globals', 'component-library/mixins/reports/timeattendance/download-report-modal-mixin'], function (exports, _ember, _componentLibraryComponentsPromiseModal, _componentLibraryUtilsGlobals, _componentLibraryMixinsReportsTimeattendanceDownloadReportModalMixin) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var URL_BY_REPORTS = {
		summary: '/ta_reports/summary',
		hours: '/ta_reports/hours',
		mealBreaks: '/ta_reports/meal_breaks',
		salaryNonExempt: '/ta_reports/salary_non_exempt',
		contingentWorker: '/ta_reports/contingent_worker'
	};

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend(_componentLibraryMixinsReportsTimeattendanceDownloadReportModalMixin['default'], {
		modalDialogClass: _ember['default'].computed('modalType', function () {
			var modalType = this.get('modalType');
			if (modalType === 'summary') {
				return 'js-glue-download-summary-report-modal';
			} else if (modalType === 'hours') {
				return 'js-glue-download-hours-report-modal';
			} else if (modalType === 'mealBreaks') {
				return 'js-glue-download-meal-breaks-report-modal';
			} else if (modalType === 'salaryNonExempt') {
				return 'js-glue-download-salary-non-exempt-report-modal';
			}
		}),
		templateName: 'components/reports/timeattendance/download-summary-report-modal',
		modalType: 'summary',

		generateReportByDateRangeUrl: function generateReportByDateRangeUrl() {
			var queryOptions = this.generateCommonQueryParams(true);
			return URL_BY_REPORTS[this.get('modalType')] + '?' + _ember['default'].$.param(queryOptions, true);
		},

		generateReportByPayPeriodUrl: function generateReportByPayPeriodUrl() {
			if (this.get('payPeriodSelection')) {
				var queryOptions = this.generateCommonQueryParams();
				queryOptions.taPayPeriod_id = this.get('payPeriodSelection.model.id');
				return URL_BY_REPORTS[this.get('modalType')] + '?' + _ember['default'].$.param(queryOptions, true);
			}
		},

		shouldBtnBeEnabled: _ember['default'].computed('selectedEmployees.@each.id', 'isDateValid', 'isAllEmployeesSelected', 'isSingleEmployee', 'isSummaryByPayPeriod', function () {
			return this._hasAllGenericInfo();
		}),

		// Labor fields only apply to EEs, not contingent employees yet

		filteredEmployees: _ember['default'].computed('allEmployees', 'selectedEmployees', function () {
			return this.get('selectedEmployees').length ? this.get('selectedEmployees') : this.get('allEmployees');
		}),

		filteredDepartments: _ember['default'].computed('filteredEmployees', function () {
			return [].concat(_toConsumableArray(new Set(this.get('filteredEmployees').filter(function (ee) {
				return ee.get('department');
			}).map(function (ee) {
				return ee.get('department');
			}))));
		}),

		filteredLocations: _ember['default'].computed('allEmployees', 'selectedEmployees', function () {
			return [].concat(_toConsumableArray(new Set(this.get('filteredEmployees').filter(function (ee) {
				return ee.get('location');
			}).map(function (ee) {
				return ee.get('location');
			}))));
		}),

		actions: {
			onToggleDateFormat: function onToggleDateFormat() {
				this.sendAction('_onToggleDateFormat');
			}
		}
	});
});