define('component-library/mixins/submit-form-mixin', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		submitOnEnter: false,
		submitButtonClass: 'btn--primary',

		submitFormEnterKeyHandler: function submitFormEnterKeyHandler(event) {
			// 'ENTER' key
			if (this.get('submitOnEnter') && event.keyCode === 13) {
				this.eventLogger.log('enter-key-pressed-to-submit-form');
				_ember['default'].$('.' + this.get('submitButtonClass')).click();
			}
		}
	});
});