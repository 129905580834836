define('component-library/components/cards/payroll/tax-header', ['exports', 'ember', 'component-library/components/cards/card-base', 'component-library/constants/z-payroll/service-levels', 'component-library/components/notification-modal', 'ui-infra/utils/sanitize-html', 'component-library/constants/z-payroll/tax-header-const', 'component-library/mixins/payroll/tax-header-mixin', 'component-library/modals/payroll/tax-rate-change-confirmation-modal'], function (exports, _ember, _componentLibraryComponentsCardsCardBase, _componentLibraryConstantsZPayrollServiceLevels, _componentLibraryComponentsNotificationModal, _uiInfraUtilsSanitizeHtml, _componentLibraryConstantsZPayrollTaxHeaderConst, _componentLibraryMixinsPayrollTaxHeaderMixin, _componentLibraryModalsPayrollTaxRateChangeConfirmationModal) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['', ''], ['', '']);

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	var formatTaxHeader = function formatTaxHeader(taxHeader) {
		taxHeader.topHelpText = taxHeader.topHelpText ? (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject, (0, _uiInfraUtilsSanitizeHtml.nonUserEditableField)(taxHeader.topHelpText)) : taxHeader.topHelpText;
		taxHeader.questions.forEach(function (question) {
			question.tooltip = question.helpText ? (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject, (0, _uiInfraUtilsSanitizeHtml.nonUserEditableField)(question.helpText)) : question.helpText;
			question.dropdownContent = !_ember['default'].isEmpty(question.dropdownContent) ? JSON.parse(question.dropdownContent) : undefined;
			question.errorDescription = question.errorMessageCopy ? question.errorMessageCopy : 'This field is invalid.';
			question.minValue = !_ember['default'].isEmpty(question.minValue) ? parseFloat(question.minValue) : undefined;
			question.maxValue = !_ember['default'].isEmpty(question.maxValue) ? parseFloat(question.maxValue) : undefined;
			question.minLength = !_ember['default'].isEmpty(question.minLength) ? parseInt(question.minLength) : undefined;
			question.maxLength = !_ember['default'].isEmpty(question.maxLength) ? parseInt(question.maxLength) : undefined;
			question.isRequired = !_ember['default'].isEmpty(question.isRequired) ? Boolean(question.isRequired) : true;
			if ([_componentLibraryConstantsZPayrollTaxHeaderConst.TAX_RATE_QUESTION_TYPE, _componentLibraryConstantsZPayrollTaxHeaderConst.WC_TAX_RATE_QUESTION_TYPE].includes(question.questionType)) {
				question.taxType = question.storageKey.split('/').slice(-1)[0];
			}

			if (question.questionType == _componentLibraryConstantsZPayrollTaxHeaderConst.WC_TAX_RATE_QUESTION_TYPE) {
				question.answer.forEach(function (ans) {
					if (ans.classCode == '') {
						_ember['default'].set(ans, 'shouldDelete', true);
					}
				});
			}
		});
		if (!taxHeader.serviceLevel) {
			taxHeader.serviceLevel = _componentLibraryConstantsZPayrollServiceLevels.SERVICE_LEVELS.CALCULATE_ONLY;
		}
		return taxHeader;
	};

	exports.formatTaxHeader = formatTaxHeader;
	exports['default'] = _componentLibraryComponentsCardsCardBase['default'].extend(_componentLibraryMixinsPayrollTaxHeaderMixin['default'], {

		futureQuestions: _ember['default'].computed.alias('taxHeader.futureQuestions'),

		actions: {
			addWorkersComp: function addWorkersComp() {
				var newWrappedWorkersCompRecord = _ember['default'].Object.create({
					classCode: '',
					employeeRate: 0,
					employerRate: 0,
					hourlyRate: 0, // for WA WC
					employeeWithholding: 0, // for WA WC
					isNew: true
				});
				this.get('wcQuestion.answer').pushObject(newWrappedWorkersCompRecord);
			},

			deleteWorkersComp: function deleteWorkersComp(wrappedWorkersCompRecord) {
				_ember['default'].set(wrappedWorkersCompRecord, 'shouldDelete', true);
			},

			updateLevelOfService: function updateLevelOfService(taxHeader) {
				this.attrs.updateLevelOfService(taxHeader);
			},

			updateAnswerEffective: function updateAnswerEffective(taxHeader, question) {
				this.attrs.updateAnswerEffective(taxHeader, question);
			},

			viewHistoricalAgencyInfo: function viewHistoricalAgencyInfo(taxHeader, agencyInfoKey, agencyInfoKeyName) {
				this.attrs.viewHistoricalAgencyInfo(taxHeader, agencyInfoKey, agencyInfoKeyName);
			},

			viewContinuousReconHistory: function viewContinuousReconHistory(taxHeader, taxType) {
				this.attrs.viewContinuousReconHistory(taxHeader, taxType);
			},

			changeAgencyStatus: function changeAgencyStatus(laddaButton) {
				var _this = this;

				var agencyInfo = {};
				if (this.get('status') != 'active') {
					agencyInfo['status'] = 'active';
				} else {
					agencyInfo['status'] = 'inactive';
				}

				var postObj = {
					company_id: this.get('company.id'),
					agencyId: this.get('title'),
					agencyInfo: agencyInfo
				};
				_ember['default'].ajaxPost('/custom_api/z_payroll/createOrUpdateAgencyInfo/', JSON.stringify(postObj)).then(function (result) {
					if (result.status === 'success') {
						_this.showAgencyStatusChangeNotification();
						_this.send('cancel');
					} else {
						_ember['default'].RSVP.reject();
					}
				})['catch'](function (err) {
					_this.showAgencyStatusChangeFailureNotification();
				})['finally'](function () {
					laddaButton.stop();
				});
			},

			reviewRateChangesAndSave: function reviewRateChangesAndSave(laddaButton) {
				var _this2 = this;

				var enableSelfServeRecon = this.get('enableSelfServeRecon');

				var rateChangesToConfirm = [];
				if (enableSelfServeRecon) {
					rateChangesToConfirm = this.get('taxHeader.questions').filter(function (question) {
						if (question.questionType != _componentLibraryConstantsZPayrollTaxHeaderConst.TAX_RATE_QUESTION_TYPE) {
							return false;
						}
						if (!_componentLibraryConstantsZPayrollTaxHeaderConst.SUTA_TAX_TYPES.contains(question.taxType)) {
							return false;
						}
						var rateObj = question.answer[0];
						return rateObj.uneditedRate != rateObj.rate;
					});
				}

				if (rateChangesToConfirm.length > 0) {
					// Some tax rate changes require recon, show legal text and force user to confirm before proceeding
					var title = this.get('title') + ' Rate Change';
					var rateChanges = rateChangesToConfirm.map(function (question) {
						var rateObj = question.answer[0];
						return {
							'label': question.questionCopy,
							'uneditedRate': rateObj.uneditedRate,
							'newRate': rateObj.rate
						};
					});
					_componentLibraryModalsPayrollTaxRateChangeConfirmationModal['default'].show({
						title: title,
						rateChanges: rateChanges
					}).then(function () {
						var rateChangeAcknowledgements = rateChangesToConfirm.map(function (question) {
							return {
								questionKey: question.questionKey,
								isAcknowledged: true
							};
						});
						_this2.send('save', laddaButton, rateChangeAcknowledgements);
					})['catch'](function () {
						laddaButton.stop();
					});
				} else {
					// No rate changes require recon, omit legal text and proceed straight away
					this.send('save', laddaButton, []);
				}
			},

			save: function save(laddaButton, rateChangeAcknowledgements) {
				var _this3 = this;

				var hasWcError = this.validateWcRates();
				var hasRateError = this.validateRates();
				var hasPFMLCoverageError = this.validatePFMLCoverage();
				var hasFLIIntegerError = this.validateFLIERCoverage();
				if (hasRateError || hasWcError || hasPFMLCoverageError || hasFLIIntegerError) {
					laddaButton.stop();
					return;
				}

				var agencyInfo = {};
				agencyInfo['adminCanFlip'] = this.get('taxHeader.adminCanFlip');
				this.get('taxHeader.questions').forEach(function (question) {
					var answer = [];
					if (question.questionType == _componentLibraryConstantsZPayrollTaxHeaderConst.TAX_RATE_QUESTION_TYPE) {
						var rateObj = question.answer[0];
						var ackObj = rateChangeAcknowledgements.find(function (ack) {
							return ack.questionKey === question.questionKey;
						}) || {};
						answer.pushObject({
							taxType: question.taxType,
							taxPayee: rateObj.taxPayee,
							taxRate: rateObj.rate,
							isAcknowledged: ackObj.isAcknowledged
						});
						var futureTax = rateObj.futureStateTax;
						if (futureTax) {
							answer.pushObject({
								taxType: question.taxType,
								taxPayee: futureTax.taxPayee,
								taxRate: futureTax.rate,
								effectiveDate: futureTax.date,
								shouldDelete: futureTax.shouldDelete
							});
						}
					} else if (question.questionType == _componentLibraryConstantsZPayrollTaxHeaderConst.WC_TAX_RATE_QUESTION_TYPE) {
						(function () {
							var codeSet = _this3.getWcClassCode();
							question.answer.forEach(function (record) {
								// prevent deleting invalid tax rates
								if (codeSet.has(record.classCode) || !record.shouldDelete) {

									var taxRates = _this3.convertWcRatesForSave(record);

									answer.pushObject({
										taxType: question.taxType,
										taxPayee: 'EE',
										taxRate: taxRates['EE'],
										workersCompClassCode: record.classCode,
										shouldDelete: record.shouldDelete
									});
									answer.pushObject({
										taxType: question.taxType,
										taxPayee: 'ER',
										taxRate: taxRates['ER'],
										workersCompClassCode: record.classCode,
										shouldDelete: record.shouldDelete
									});
								}
							});
							// Do not pass FEIN in request if company is fully onboarded and user does not have permission to edit
						})();
					} else if (_this3.get('title') === 'Federal Internal Revenue Service' && question.storageKey === 'taxId' && _this3.get('isPayrollOnboarded') === true && !_this3.get('canEditFEIN')) {
							return;
						} else {
							answer = question.answer;
						}
					agencyInfo[question.storageKey] = {
						minLength: question.minLength,
						maxLength: question.maxLength,
						minValue: question.minValue,
						maxValue: question.maxValue,
						answer: answer,
						isRequired: question.isRequired
					};
				});

				var postObj = {
					company_id: this.get('company.id'),
					agencyId: this.get('title'),
					agencyInfo: agencyInfo
				};
				if (this.get('futureQuestions') != null) {
					postObj.futureQuestions = this.get('futureQuestions').filterBy('needsUpdate', true);
				}
				_ember['default'].ajaxPost('/custom_api/z_payroll/createOrUpdateAgencyInfo/', JSON.stringify(postObj)).then(function (result) {
					var errorKeys = result.status === 'success' ? [] : result.errorMessages;
					var isIncomplete = false;
					_this3.get('taxHeader.questions').forEach(function (question) {
						_ember['default'].set(question, 'hasError', !_ember['default'].isEmpty(errorKeys) && errorKeys.contains(question.storageKey));
						isIncomplete = isIncomplete || question.hasError;
					});
					_this3.set('taxHeader.isIncomplete', isIncomplete);
					if (result.status === 'success') {
						if (!_this3.get('isFullService')) {
							_this3.showNotification();
						}
						// reload future tax rates
						_this3.send('cancel');
					}
				})['catch'](function (err) {
					_componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'Oops! Something went wrong.',
						message: 'An error occurred while saving changes. Please try again later.'
					});
				})['finally'](function () {
					laddaButton.stop();
				});
			},

			cancel: function cancel() {
				var _this4 = this;

				_ember['default'].ajaxPost('/custom_api/z_payroll/getAgencyInfoForOnboarding/', JSON.stringify({ company_id: this.get('company.id'), agencyIds: [this.get('title')] })).then(function (res) {
					var agencyInfo = res.data[_this4.get('title')];
					if (!_ember['default'].isEmpty(agencyInfo)) {
						_this4.get('taxHeader.questions').forEach(function (question) {
							if (question.storageKey in agencyInfo) {
								_ember['default'].set(question, 'answer', agencyInfo[question.storageKey]);
							}
						});
						_this4.set('taxHeader.status', agencyInfo['status']);
					}
				}).then(function () {
					_this4._resetFutureStateTaxes();
					_this4.send('toggleEdit');
				});
				this._resetFutureQuestions();
			},

			toggleEditAndValidateTaxRates: function toggleEditAndValidateTaxRates() {
				if (this.get('mode') === _componentLibraryComponentsCardsCardBase.SETTINGS_CARD_MODES.READ) {
					this.convertWcRatesForEdit();
					this.validateWcRates();
					this.validateRates();
					this.populateUneditedRates();
				}
				this.send('toggleEdit');
			}
		}

	});
});