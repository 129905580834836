define('component-library/helpers/pretty-number', ['exports', 'ember', 'component-library/utils/pretty-number'], function (exports, _ember, _componentLibraryUtilsPrettyNumber) {
  'use strict';

  /**
   * Use this like {{prettyNumber anyNumber}}
   */
  exports['default'] = _ember['default'].HTMLBars.makeBoundHelper(function (params) {
    var number = params[0];
    return (number || number === 0) && (0, _componentLibraryUtilsPrettyNumber['default'])(number) || '';
  });
});