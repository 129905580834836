define('component-library/mixins/component-layout', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var LAYOUTS = {
		VERTICAL: 'vertical',
		INLINE: 'inline'
	};

	exports['default'] = _ember['default'].Mixin.create({
		classNameBindings: ['isComponentInlineLayout:z-componentLayout--inline', 'isComponentVerticalLayout:z-componentLayout--vertical'],

		componentLayout: LAYOUTS.INLINE,
		isComponentInlineLayout: _ember['default'].computed.equal('componentLayout', LAYOUTS.INLINE),
		isComponentVerticalLayout: _ember['default'].computed.equal('componentLayout', LAYOUTS.VERTICAL)
	});
});