define('component-library/initializers/last-activity', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	var LastActivityService = _ember['default'].Object.extend({
		ACTIVITY_EVENTS: ['mousemove', 'scroll', 'keyup', 'click'],
		updateThrottleTime: 1,
		// This is the last activity timestamp. This may be up to one second (updateThrottleTime) out of date,
		// meaning that you should only treat the user as idle for X seconds when now - timestamp >= X + updateThrottleTime
		timestamp: _ember['default'].computed.oneWay('_timestamp').readOnly(),
		_timestamp: (function () {
			return (0, _componentLibraryUtilsGlobals.moment)();
		}).property(),
		// The user has been idle for AT LEAST this much time. In seconds.
		getIdleDuration: function getIdleDuration() {
			var duration = ((0, _componentLibraryUtilsGlobals.moment)().diff(this.get('timestamp')) - this.updateThrottleTime * 1000) / 1000;
			return duration > 0 ? duration : 0;
		},
		// Default threshold is 7 minutes.
		isUserIdle: function isUserIdle(threshold) {
			threshold = threshold || 7 * 60;
			return this.getIdleDuration() > threshold;
		},
		updateLastActivityTimestamp: function updateLastActivityTimestamp() {
			if (!this.get('isDestroyed')) {
				this.set('_timestamp', (0, _componentLibraryUtilsGlobals.moment)());
			}
		},
		_throttledUpdate: function _throttledUpdate() {},
		setupLastActivityCallback: (function () {
			var _this = this;

			var subscriber = function subscriber() {
				return _ember['default'].run.throttle(_this, _this.updateLastActivityTimestamp, _this.updateThrottleTime * 1000);
			};
			this.set('subscriber', subscriber);
			this.ACTIVITY_EVENTS.map(function (eventName) {
				return window.addEventListener(eventName, subscriber, false);
			});
		}).on('init'),
		willDestroy: function willDestroy() {
			var _this2 = this;

			this._super.apply(this, arguments);
			// NOTE: this is helpful mainly for tests since service's are singleton
			this.ACTIVITY_EVENTS.forEach(function (eventName) {
				return window.removeEventListener(eventName, _this2.get('subscriber'), false);
			});
		}
	});

	exports['default'] = {
		name: "last-activity",
		initialize: function initialize(container, application) {
			container.register('last-activity:service', LastActivityService);
			application.inject('controller', 'lastActivityService', 'last-activity:service');
		}
	};
});