define('component-library/components/z-scroll-shadow-container', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-scroll-shadow-wrapper', 'grid-block', 'u-overflowHidden'],
		guid: null,
		$componentWrapper: null,
		$componentContent: null,

		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super();

			this.set('guid', _ember['default'].guidFor(this));
			this.set('$componentWrapper', this.$());

			// this is what actually scrolls
			this.set('$componentContent', this.$().children().first());

			this._attachMutationObserver();
			this._updateShadowsOnScroll();
			this._attachResizeHandler();

			_ember['default'].run.scheduleOnce('afterRender', function () {
				_this.updateShadows();
			});
		},

		updateShadows: function updateShadows() {
			// Add shadow to the footer when scrollTop is 0, add shadow to title when
			// scrollTop is between 0 and the height of the body, and only add the
			// shadow to the footer when the scrollTop is equal to the height of the body

			var $componentWrapper = this.get('$componentWrapper');
			var $componentContent = this.get('$componentContent');
			var $componentContentChildren = $componentContent.children();

			// x position of top of first child
			var componentContentFirstChildTop = $componentContentChildren.first().offset().top;

			// x position of bottom of last child
			var componentContentLastChildBottom = $componentContentChildren.last().offset().top + $componentContentChildren.last().height();

			// x position of top of wrapper
			var topOfWrapper = $componentWrapper.offset().top;

			// x position of bottom of wrapper
			var bottomOfWrapper = $componentWrapper.height() + topOfWrapper;

			// first child's top is higher up than the wrapper's top
			if (componentContentFirstChildTop < topOfWrapper) {
				$componentContent.addClass('has-shadow--top');
			} else {
				$componentContent.removeClass('has-shadow--top');
			}

			// last child's bottom is below the wrapper's bottom
			if (componentContentLastChildBottom > bottomOfWrapper) {
				$componentContent.addClass('has-shadow--bottom');
			} else {
				$componentContent.removeClass('has-shadow--bottom');
			}
		},

		_attachResizeHandler: function _attachResizeHandler() {
			var _this2 = this;

			// namespaced b/c we're attaching a handler on `window`
			var namespacedResizeEvent = ['resize', this.get('guid')].join('.');
			_ember['default'].$(window).on(namespacedResizeEvent, function () {
				_this2.updateShadows();
			});
		},

		_attachMutationObserver: function _attachMutationObserver() {
			var _this3 = this;

			if (!window.MutationObserver) {
				return;
			}

			var $componentWrapper = this.get('$componentWrapper');

			// recalc helpbox position anytime the DOM subtree of anchor changes
			var observer = new MutationObserver(function (mutations) {
				_this3.updateShadows();
			});

			// store a ref to the observer we just defined so we can disconnet it
			// when the component is destroyed
			this._observer = observer;

			// https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
			// mutation obersevers are the best
			// mutation observer needs a real DOM node, not a jQuery object.
			observer.observe($componentWrapper[0], {
				childList: true,
				characterData: true,
				subtree: true,
				attributes: true
			});
		},

		_updateShadowsOnScroll: function _updateShadowsOnScroll() {
			var _this4 = this;

			var $componentContent = this.get('$componentContent');

			$componentContent.on('scroll', function () {
				_this4.updateShadows();
			});
		},

		willDestroyElement: function willDestroyElement() {
			var $componentContent = this.get('$componentContent');

			$componentContent.off('scroll');

			if (this._observer) {
				this._observer.disconnect();
			}

			var namespacedResizeEvent = ['resize', this.get('guid')].join('.');
			_ember['default'].$(window).off(namespacedResizeEvent);
		}
	});
});