define('z-table/utils/update-filters', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  exports['default'] = updateFilters;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function updateFilters(filterDescriptor, type, key, value, addFilter) {
    var filters = _lodash['default'].cloneDeep(filterDescriptor);
    if (!(key in filters)) {
      filters[key] = {};
    }

    // TODO: break it up later into multiple functions
    if (type === 'matchAny') {
      var matchSet = new Set(filters[key]['matchAny']);
      if (addFilter) {
        matchSet.add(value);
      } else {
        matchSet['delete'](value);
      }

      if (!matchSet.size) {
        delete filters[key]['matchAny'];
      } else {
        filters[key]['matchAny'] = [].concat(_toConsumableArray(matchSet));
      }
    }

    // TODO: break it up later into multiple functions
    if (type === 'stringContains') {
      filters[key]['stringContains'] = value;
    }

    if (type === 'custom') {
      if (addFilter) {
        filters[key]['custom'] = value;
      } else {
        delete filters[key]['custom'];
      }
    }

    return filters;
  }
});