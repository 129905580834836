define('component-library/wizard/flow/section/step/controller', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Controller.extend({
		// OVERRIDES - begin
		wizardService: null,
		serviceEnabled: false,

		wizardStepKey: null, // required

		// will redirect to this state if step is not found in current flow state
		missingStepRedirectStepKey: null,
		missingStepRedirectSectionKey: null,

		// OVERRIDES - end

		wizardStepState: null, // automatically set by route

		actions: {
			updateStepComplete: function updateStepComplete(isComplete) {
				var valuePath = this.get('wizardStepState.valuePath');
				var newValue = valuePath ? this.get(valuePath) : null;
				var stepKey = this.get('wizardStepKey');
				if (this.get('serviceEnabled')) {
					var sectionKey = this.get('wizardService.computedWizardFlowState.currentSection.sectionKey');
					this.get('wizardService').updateStepComplete(isComplete, sectionKey, stepKey);
				} else {
					this.send('_updateStepComplete_stepAction', this.get('wizardStepKey'), isComplete, false, newValue);
				}
			},

			updateStepCompleteAndTransition: function updateStepCompleteAndTransition(isComplete) {
				var _this = this;

				var valuePath = this.get('wizardStepState.valuePath');
				var newValue = valuePath ? this.get(valuePath) : null;
				var stepKey = this.get('wizardStepKey');
				if (this.get('serviceEnabled')) {
					var sectionKey = this.get('wizardService.computedWizardFlowState.currentSection.sectionKey');
					var promise = this.get('wizardService').updateStepComplete(isComplete, sectionKey, stepKey);
					promise.then(function () {
						return _this.send('transitionToNextStep');
					});
				} else {
					this.send('_updateStepComplete_stepAction', this.get('wizardStepState.stepKey'), isComplete, true, newValue);
				}
			},

			transitionToNextStep: function transitionToNextStep() {
				if (this.get('serviceEnabled')) {
					this.get('wizardService').transitionToNextStep();
				} else {
					this.send('_transitionToNextStep_stepAction', this.get('wizardStepKey'));
				}
			},

			transitionToPreviousStep: function transitionToPreviousStep() {
				if (this.get('serviceEnabled')) {
					this.get('wizardService').transitionToPreviousStep();
				} else {
					this.send('_transitionToPreviousStep_stepAction');
				}
			},

			transitionToStepIndex: function transitionToStepIndex(index) {
				this.send('_transitionToStepIndex_stepAction', index);
			},

			transitionToStepKey: function transitionToStepKey(stepKey) {
				this.send('_transitionToStepKey_stepAction', stepKey);
			},

			transitionToFirstIncompleteStep: function transitionToFirstIncompleteStep() {
				this.send('_transitionToFirstIncompleteStep_stepAction');
			},

			transitionToNextIncompleteStep: function transitionToNextIncompleteStep() {
				this.send('_transitionToNextIncompleteStep_stepAction');
			}
		}
	});
});