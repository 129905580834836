define("component-library/utils/waiting-period/waiting-period", ["exports", "ember"], function (exports, _ember) {
	"use strict";

	var SIMILAR_WAITING_PERIODS = [["30 Days", "One Month"], ["60 Days", "Two Months"], ["90 Days", "Three Months"], ["180 Days", "Six Months"], ["Hire + 30", "Hire + 1 Month"], ["Hire + 60", "Hire + 2 Months"], ["Hire + 90", "Hire + 3 Months"]];

	exports.SIMILAR_WAITING_PERIODS = SIMILAR_WAITING_PERIODS;

	var getSupportedWaitingPeriodForChc = function getSupportedWaitingPeriodForChc(chcId) {
		var _this = this;

		var getWaitingPeriodsUrl = '/custom_api/company_carrier_waiting_periods/' + chcId;

		var supportedWaitingPeriods = _ember["default"].ajaxGet(getWaitingPeriodsUrl).then(function (supportedWaitingPeriods) {
			var supportedWaitingPeriodsList = supportedWaitingPeriods.map(function (waitingPeriodObject) {
				return _ember["default"].Object.create(waitingPeriodObject);
			});

			return supportedWaitingPeriodsList;
		})["catch"](function (errorDetails) {
			_this.eventLogger.log('waitingPeriods_from_zrules', {
				chcId: chcId,
				error: errorDetails
			});
			return _ember["default"].RSVP.reject();
		});
		return supportedWaitingPeriods;
	};
	exports.getSupportedWaitingPeriodForChc = getSupportedWaitingPeriodForChc;
});