define('component-library/services/wizard', ['exports', 'ember', 'component-library/wizard/state/flow'], function (exports, _ember, _componentLibraryWizardStateFlow) {
	'use strict';

	exports['default'] = _ember['default'].Service.extend({
		backendFlowModel: null, // optional if we want auto sync with backend flow app
		shouldSyncBackendFlowModel: false, // set true if we want auto sync

		computedWizardFlowState: _ember['default'].computed('wizardFlowState', function () {
			var flow = this.get('wizardFlowState');
			// TODO: DQ maybe save the save so we can get the previous state the next go around 😬
			flow.setLocation(this.get('_currentSectionIndexBookmark'), this.get('_currentStepIndexBookmark'));
			return flow;
		}),

		wizardFlowState: _ember['default'].computed(function () {
			return _componentLibraryWizardStateFlow['default'].create({});
		}),

		routing: _ember['default'].inject.service('-routing'),

		_currentSectionIndexBookmark: 0,
		_currentStepIndexBookmark: 0,

		// handlers

		onFlowIndexEnter: function onFlowIndexEnter() {
			// transition to first incomplete section
			var firstIncompleteSection = this.get('computedWizardFlowState.firstIncompleteSection');
			var firstSection = this.get('computedWizardFlowState.sections.firstObject');
			this.transitionToStep(firstIncompleteSection || firstSection);
		},

		onSectionEnter: function onSectionEnter(sectionKey) {
			var flowState = this.get('computedWizardFlowState');
			var sectionState = flowState.getSection(sectionKey);
			if (!sectionState || !sectionState.get('isAccessible')) {
				this.transitionToFlowIndex();
			} else {
				this.updateCurrentSection(sectionKey);
			}
		},

		onSectionIndexEnter: function onSectionIndexEnter(sectionKey) {
			// transition to first incomplete step
			var flow = this.get('computedWizardFlowState');
			var currentSection = flow.getSection(sectionKey);
			var firstIncompleteStep = currentSection.get('firstIncompleteStep');
			var firstStep = currentSection.get('steps.firstObject');
			if (!firstIncompleteStep && !firstStep.get('isAccessible')) {
				// No-Op
				return;
			}
			this.transitionToStep(firstIncompleteStep || firstStep);
		},

		onStepEnter: function onStepEnter(stepKey) {
			var flowState = this.get('computedWizardFlowState');
			var sectionState = flowState.get('currentSection');
			var stepState = sectionState.getStep(stepKey);

			if (!stepState || !stepState.get('isAccessible')) {
				this.transitionToStep(sectionState);
			} else {
				this.updateCurrentStep(stepKey);
			}
		},

		// actions

		transitionToFlowIndex: function transitionToFlowIndex() {
			this.transitionToStep(this.get('computedWizardFlowState'));
		},

		updateCurrentSection: function updateCurrentSection(sectionKey) {
			var flow = this.get('computedWizardFlowState');
			var currentSection = flow.getSection(sectionKey);
			flow.set('currentSectionIndex', currentSection.get('index'));

			// NOTE: this line needed to initialize bookmark and locate yourself the first time the wizard is loaded
			this.set('_currentSectionIndexBookmark', currentSection.get('index'));
		},

		updateCurrentStep: function updateCurrentStep(stepKey) {
			var flow = this.get('computedWizardFlowState');
			var currentSection = flow.get('currentSection');
			var currentStep = currentSection.getStep(stepKey);
			currentSection.set('currentStepIndex', currentStep.get('index'));

			// NOTE: this line needed to initialize bookmark and locate yourself the first time the wizard is loaded
			this.set('_currentStepIndexBookmark', currentStep.get('index'));
		},

		transitionToTerminateStep: function transitionToTerminateStep() {
			this.transitionToStep(this.get('_terminalStep'));
		},

		transitionToNextStep: function transitionToNextStep() {
			var nextStep = this.get('_nextStep');
			var nextSection = nextStep.get('section');
			var currentSection = this.get('computedWizardFlowState.currentSection');
			var ancestorModels = [];

			// if next step is in a different section, pass any section models into the transition
			if (nextSection && nextSection != currentSection) {
				ancestorModels.push(nextSection.get('model'));
			}

			this.transitionToStep(this.get('_nextStep'), ancestorModels.compact());
		},

		transitionToPreviousStep: function transitionToPreviousStep() {
			var previousStep = this.get('_previousStep');
			var previousSection = previousStep.get('section');
			var currentSection = this.get('computedWizardFlowState.currentSection');
			var ancestorModels = [];

			// if previous step is in a different section, pass any section models into the transition
			if (previousSection && previousSection != currentSection) {
				ancestorModels.push(previousSection.get('model'));
			}

			this.transitionToStep(this.get('_previousStep'), ancestorModels.compact());
		},

		transitionToStep: function transitionToStep(step) {
			var ancestorModels = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

			if (step == null) {
				return;
			}

			var models = ancestorModels.concat([step.get('model')]).compact();
			var opts = step.get('opts');
			var args = [step.get('routeName')];
			if (models.length) {
				args.push(models);
			}

			if (opts) {
				args.push(opts);
			}

			// Set current step and current section indexes so we can resume where we left off after recomputing state
			var isTransitioningToStepState = !!step.get('section'); // and not flow or section
			if (isTransitioningToStepState) {
				this.set('_currentSectionIndexBookmark', step.get('section.index'));
				this.set('_currentStepIndexBookmark', step.get('index'));
			}

			var routing = this.get('routing');
			routing.transitionTo.apply(routing, args);
		},

		updateStepComplete: function updateStepComplete(isComplete, sectionKey, stepKey) {
			var flow = this.get('computedWizardFlowState');
			var section = flow.getSection(sectionKey);
			var step = section.getStep(stepKey);
			step.setComplete(isComplete);
			var promise = _ember['default'].RSVP.resolve();
			if (this.get('shouldSyncBackendFlowModel')) {
				promise = this.updateCurrentStepAndReload();
			}
			return promise;
		},

		// computed props

		_terminalStep: _ember['default'].computed('computedWizardFlowState.terminalRoute', function () {
			var flow = this.get('computedWizardFlowState');
			var terminalRoute = flow.get('terminalRoute');
			if (terminalRoute && flow.get('flowComplete')) {
				return _ember['default'].Object.create({ routeName: terminalRoute });
			}
			return flow;
		}),

		_nextStep: _ember['default'].computed('computedWizardFlowState.currentSection', 'computedWizardFlowState.currentSection.steps.@each', 'computedWizardFlowState.currentSection.nextStep', function () {
			var flow = this.get('computedWizardFlowState');
			var section = flow.get('currentSection');
			var nextStep = section.get('nextStep');
			var nextSection = flow.get('nextSection');
			var terminalRoute = flow.get('terminalRoute');

			if (nextStep) {
				return nextStep;
			} else if (nextSection) {
				// showing the wizard overview when navigating to a new section -- for new wizard flow
				if (nextSection.get('revealOverviewOnEnter')) {
					this.set('isOverviewRevealed', true);
				}
				return flow.get('nextSection.steps.firstObject');
			} else if (terminalRoute && flow.get('flowComplete')) {
				return _ember['default'].Object.create({ routeName: terminalRoute });
			} else {
				return flow;
			}
		}),

		_previousStep: _ember['default'].computed('computedWizardFlowState.currentSection.steps.@each', 'computedWizardFlowState.currentSection.previousStep', function () {
			var flow = this.get('computedWizardFlowState');
			var section = flow.get('currentSection');
			var previousStep = section.get('previousStep');
			var previousSection = flow.get('previousSection');

			if (previousStep) {
				return previousStep;
			} else if (previousSection) {
				// showing the wizard overview when navigating to a new section -- for new wizard flow
				if (previousSection.get('revealOverviewOnEnter')) {
					this.set('isOverviewRevealed', true);
				}
				return flow.get('previousSection.steps.lastObject');
			} else {
				return flow;
			}
		}),

		// BACKEND FLOW MODEL

		reloadBackendFlowModel: function reloadBackendFlowModel() {
			var _this = this;

			if (!this.get('shouldSyncBackendFlowModel')) {
				return _ember['default'].RSVP.resolve();
			}
			var backendFlowModel = this.get('backendFlowModel');
			if (!backendFlowModel) {
				return _ember['default'].RSVP.reject('Missing backend flow model.');
			}

			// reload flow
			return backendFlowModel.reload().then(function (flowModel) {
				// reload sections
				return _ember['default'].RSVP.all(flowModel.get('sections').invoke('reload'));
			}).then(function (sections) {
				return _ember['default'].RSVP.all(sections.mapBy('errors.content').reduce(function (arr, ele) {
					return arr.concat(ele);
				}, []).invoke('reload'));
			}).then(function () {
				_this.notifyPropertyChange('backendFlowModel');
				return _this.get('backendFlowModel');
			});
		},

		updateBackendFlowModelCurrentStep: function updateBackendFlowModelCurrentStep() {
			if (!this.get('shouldSyncBackendFlowModel')) {
				return _ember['default'].RSVP.resolve();
			}
			var flow = this.get('wizardFlowState');
			var currentStepKey = flow.get('currentSection.currentStep.stepKey');

			var backendFlowModel = this.get('backendFlowModel');

			if (!backendFlowModel) {
				return _ember['default'].RSVP.reject('Missing backend flow model.');
			}

			// update current step from backend model
			var currentStepModel = backendFlowModel.get('sections').findBy('name', '' + currentStepKey);
			if (!currentStepModel) {
				// step key can be the combination of section name and dispatcherArgs
				currentStepModel = backendFlowModel.get('sections').find(function (section) {
					return section.get('name') + '.' + section.get('dispatcherArgs') == currentStepKey;
				});
			}

			if (currentStepModel && !currentStepModel.get('isEntered')) {
				currentStepModel.set('isEntered', true);
			}

			// Plus `entered` count of this section by 1.
			currentStepModel.incrementProperty('entered', 1);

			return currentStepModel.save();
		},

		updateCurrentStepAndReload: function updateCurrentStepAndReload() {
			var _this2 = this;

			return this.updateBackendFlowModelCurrentStep().then(function () {
				return _this2.reloadBackendFlowModel();
			});
		}
	});
});