define('component-library/data-table/configure-table-modal', ['exports', 'ember', 'component-library/data-table/column-selection-popover', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryDataTableColumnSelectionPopover, _componentLibraryComponentsPromiseModal) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		modalDialogClass: 'd-z-modal-dialog--sm',
		autoSetHeight: false,

		templateName: 'data-table/configure-table-modal',
		allColumns: _ember['default'].computed.oneWay('model.allColumns'),
		allGroupings: _ember['default'].computed.oneWay('model.allGroupings'),
		columns: _ember['default'].computed.oneWay('model.columns'),
		groupings: _ember['default'].computed.oneWay('model.groupings'),
		ColumnSelectionPopover: _componentLibraryDataTableColumnSelectionPopover['default'],
		canConfigureGroupings: true,

		newColumns: (function () {
			return this.get('columns').slice();
		}).property('columns'),

		newGroupings: (function () {
			return this.getWithDefault('groupings', []).slice();
		}).property('groupings'),

		availableColumns: (function () {
			var columns = this.get('allColumns').slice();
			columns.removeObjects(this.get('newColumns'));
			return columns;
		}).property('allColumns.[]', 'newColumns.[]'),

		availableGroupings: (function () {
			var columns = this.get('allGroupings').slice();
			columns.removeObjects(this.get('newGroupings'));
			return columns;
		}).property('allGroupings.[]', 'newGroupings.[]'),

		actions: {
			apply: function apply() {
				var columns = this.get('columns') || [];
				var newColumns = this.get('newColumns');
				columns.clear();
				columns.pushObjects(newColumns);

				var groupings = this.get('groupings') || [];
				var newGroupings = this.get('newGroupings');
				groupings.clear();
				groupings.pushObjects(newGroupings);
				this.send('confirm');
			},

			addGrouping: function addGrouping(column) {
				if (column) {
					this.get('newGroupings').pushObject(column);
				}
			},

			removeGrouping: function removeGrouping(column) {
				this.get('newGroupings').removeObject(column);
			},

			sortGrouping: function sortGrouping(column, index) {
				var columns = this.get('newGroupings');
				columns.removeObject(column);
				columns.insertAt(index, column);
			},

			addColumn: function addColumn(column) {
				if (column) {
					this.get('newColumns').pushObject(column);
				}
			},

			removeColumn: function removeColumn(column) {
				this.get('newColumns').removeObject(column);
			},

			sortColumn: function sortColumn(column, index) {
				var columns = this.get('newColumns');
				columns.removeObject(column);
				columns.insertAt(index, column);
			}
		}
	});
});