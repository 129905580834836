define('component-library/components/z-module-inner', ['exports', 'ember', 'lodash/lodash', 'component-library/templates/components/z-module-inner'], function (exports, _ember, _lodashLodash, _componentLibraryTemplatesComponentsZModuleInner) {
	'use strict';

	var computed = _ember['default'].computed;
	var oneWay = computed.oneWay;

	exports['default'] = _ember['default'].Component.extend({
		layout: _componentLibraryTemplatesComponentsZModuleInner['default'],
		classNames: ['z-module-core', 'u-fillHorizontal'],
		spec: oneWay('moduleContext.flowModule.spec'),
		// For now the templates include moduleContext.flow.data directly
		// since they grab the scope.locals.moduleContext. We need to fix it
		// by creating a wrapper component that sets the right context to templates
		// TODO: make sure they can't do something like parentView.parentView.parentiView..parentView.parentiView.currentEmployee
		// or something similar that will give them additional info than what they should have access to.
		// for now parentView is disabled, but as we do changes we need to ensure we don't add it back.
		data: oneWay('moduleContext.flow.data'),
		// For now the templates include moduleContext.errors
		moduleErrors: oneWay('moduleContext.errors'),
		serviceDown: _ember['default'].computed.alias('moduleContext.serviceDown'),
		showGlobalErrors: true,
		isDisabled: false,
		defaultTemplate: computed('spec.templateString', 'spec.compiledTemplate', function () {
			var templateString = this.get('spec.templateString');
			if (this.get('isDisabled') && this.get('spec.disabledTemplateString')) {
				templateString = this.get('spec.disabledTemplateString');
			}
			if (templateString) {
				return _ember['default'].HTMLBars.compile(templateString);
			}
			// if (this.get('spec.compiledTemplate')) {
			// 	// this simulates a compiled template
			// 	// NOTE: disabled this rulle because we need to eval code that is coming from the server with dynamically generated templates
			// 	return eval(this.get('spec.compiledTemplate')); // eslint-disable-line no-eval
			// }
		})
	});
});