define('component-library/components/benefits/timeline/transaction-list', ['exports', 'ember', 'ui-infra/utils/sanitize-html', 'component-library/mixins/resize-handler', 'component-library/constants/benefits', 'component-library/templates/components/benefits/timeline/transaction-list', 'component-library/utils/benefits/timeline/query-params', 'component-library/components/benefits/timeline/transactions-lazy-loader'], function (exports, _ember, _uiInfraUtilsSanitizeHtml, _componentLibraryMixinsResizeHandler, _componentLibraryConstantsBenefits, _componentLibraryTemplatesComponentsBenefitsTimelineTransactionList, _componentLibraryUtilsBenefitsTimelineQueryParams, _componentLibraryComponentsBenefitsTimelineTransactionsLazyLoader) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral([''], ['']),
	    _templateObject2 = _taggedTemplateLiteral(['height: ', 'px;'], ['height: ', 'px;']),
	    _templateObject3 = _taggedTemplateLiteral(['height: ', 'px'], ['height: ', 'px']);

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	var DisplayEntry = _ember['default'].ObjectProxy.extend({
		top: _ember['default'].computed('index', 'height', function () {
			return this.get('height') * this.get('index');
		}),

		isSelected: _ember['default'].computed('id', 'selectedId', function () {
			return parseInt(this.get('id')) == parseInt(this.get('selectedId'));
		})
	});

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsResizeHandler['default'], {
		layout: _componentLibraryTemplatesComponentsBenefitsTimelineTransactionList['default'],
		// Some peroperty user can customize
		rowHeight: 100,
		maxNumOfRows: null,
		disableScrolling: false,

		classNames: ['u-fillHorizontal u-positionRelative u-overflowHidden'],
		classNameBindings: ['isHeightFixed::u-fillVertical'],

		emptyDataText: 'No events match your search criteria',

		viewStyle: _ember['default'].computed('fixedViewHeight', function () {
			if (!this.get('fixedViewHeight')) {
				return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject);
			} else {
				var height = this.get('fixedViewHeight');
				return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject2, height);
			}
		}),

		isHeightFixed: _ember['default'].computed.notEmpty('maxNumOfRows'),
		isDataSettled: _ember['default'].computed.oneWay('transactionsMeta.isSettled'),
		isDataLoading: _ember['default'].computed.not('isDataSettled'),

		fixedViewHeight: _ember['default'].computed('isHeightFixed', 'isDataSettled', 'rowHeight', 'maxNumOfRows', 'totalCount', function () {
			if (this.get('isHeightFixed') && this.get('isDataSettled')) {
				var rowCount = Math.min(this.get('maxNumOfRows'), this.get('totalCount'));
				return this.get('rowHeight') * rowCount;
			} else {
				return 0;
			}
		}),

		dynamicViewHeight: 0,

		viewHeight: _ember['default'].computed('isHeightFixed', 'fixedViewHeight', 'dynamicViewHeight', function () {
			if (this.get('isHeightFixed')) {
				return this.get('fixedViewHeight') || 0;
			} else {
				return this.get('dynamicViewHeight') || 0;
			}
		}),

		viewElement: null,
		scrollingElement: null,

		currentTransactionId: null,

		// lazy loader for transaction row data
		transactionsLoader: _ember['default'].computed('selected.employees.[]', 'selected.benefitsApps.[]', 'selected.benefitsTypes.[]', 'selected.benefitsEvents.[]', 'selected.sort', 'selected.effectiveDateMin', 'selected.effectiveDateMax', 'selected.creationDateMin', 'selected.creationDateMax', 'selected.historyDate', function () {
			var queryParams = (0, _componentLibraryUtilsBenefitsTimelineQueryParams['default'])(this.get('selected'));
			return _componentLibraryComponentsBenefitsTimelineTransactionsLazyLoader['default'].create({
				queryParams: queryParams
			});
		}),

		transactionsMeta: _ember['default'].computed('transactionsLoader', function () {
			var metaPromise = this.get('transactionsLoader').loadMeta();
			// PromiseProxy
			return _ember['default'].ObjectProxy.extend(_ember['default'].PromiseProxyMixin).create({
				promise: metaPromise
			});
		}),

		totalCount: _ember['default'].computed.oneWay('transactionsMeta.total_count'),

		scrollingHeight: _ember['default'].computed('totalCount', 'rowHeight', function () {
			return this.get('rowHeight') * (this.get('totalCount') || 0);
		}),

		isDataEmpty: _ember['default'].computed.equal('totalCount', 0),

		scrollingStyle: _ember['default'].computed('scrollingHeight', function () {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject3, this.get('scrollingHeight'));
		}),

		displayEntries: _ember['default'].computed('viewHeight', 'rowHeight', function () {
			var height = this.get('rowHeight');
			var numOfEntries = Math.ceil(this.get('viewHeight') / height) + 1;
			var selectedId = this.get('currentTransactionId');
			var result = new Array(numOfEntries).fill().map(function (item) {
				return DisplayEntry.create({
					content: null,
					height: height,
					selectedId: selectedId,
					index: 0,
					isActive: false
				});
			});
			return result;
		}),

		activeDisplayEntries: _ember['default'].computed.filterBy('displayEntries', 'isActive', true),

		_scrollingHandler: function _scrollingHandler() {
			var _this = this;

			var _viewElement = this.get('viewElement');
			var _scrollingElement = this.get('scrollingElement');
			if (_viewElement && _scrollingElement) {
				var scrollDistance;

				(function () {
					scrollDistance = _viewElement.offset().top - _scrollingElement.offset().top;

					var totalCount = _this.get('transactionsMeta.total_count');
					var startIndex = Math.min(Math.floor(scrollDistance / _this.get('rowHeight')), totalCount - 1);
					var entries = _this.get('displayEntries');
					entries.forEach(function (item, index) {
						var idx = index + startIndex;
						item.set('content', null);
						item.set('index', idx);
						item.set('isActive', idx >= 0 && idx < totalCount);
					});
					var activeEntries = entries.filterBy('isActive', true);
					_this.get('transactionsLoader').load(activeEntries);
				})();
			}
		},

		scrollingHandler: function scrollingHandler() {
			// Running _scrollingHandler on every single scroll event is expensive,
			// and we don’t really need to be that responsive, especially for fast-scroll.
			// Note that tranasactionsLazyLoader is also doing debounce
			return _ember['default'].run.debounce(this, this._scrollingHandler, 20);
		},

		// Life cycle callbacks
		didInsertElement: function didInsertElement() {
			this._super();
			var _viewElement = this.$('.js-benefitsTimeline-container');
			var _scrollingElement = this.$('.js-benefitsTimeline-scrolling');
			this.set('viewElement', _viewElement);
			this.set('scrollingElement', _scrollingElement);
			if (!this.get('isHeightFixed')) {
				this.set('dynamicViewHeight', _viewElement.height());
			}
			var _whenScrolling = this.scrollingHandler.bind(this);
			_viewElement.off('scroll.benefitsTimeline').on('scroll.benefitsTimeline', function () {
				_whenScrolling();
			});
		},

		willDestroyElement: function willDestroyElement() {
			var _viewElement = this.get('viewElement');
			if (_viewElement) {
				_viewElement.off('scroll.benefitsTimeline');
			}
			this.set('viewElement', null);
			this.set('scrollingElement', null);
		},

		onResizeEnd: function onResizeEnd() {
			this._super();
			var _viewElement = this.get('viewElement');
			if (_viewElement) {
				this.set('viewHeight', _viewElement.height());
			}
			this.scrollingHandler();
		},

		// Observers
		onScrollingHeightChanged: _ember['default'].observer('scrollingHeight', function () {
			// immediate refresh
			this._scrollingHandler();
		}),

		currentTransactionObserver: _ember['default'].observer('currentTransactionId', function () {
			var _this2 = this;

			this.get('displayEntries').forEach(function (item) {
				return item.set('selectedId', _this2.get('currentTransactionId'));
			});
		}),

		viewTransactionDetailWrapper: function viewTransactionDetailWrapper(transaction) {
			if (this.viewTransactionDetail) {
				this.viewTransactionDetail(transaction);
			}
		}
	});
});