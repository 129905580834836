define('component-library/components/cards/card-base', ['exports', 'ember', 'component-library/mixins/wizard-validation-and-error', 'component-library/mixins/component-routing-mixin'], function (exports, _ember, _componentLibraryMixinsWizardValidationAndError, _componentLibraryMixinsComponentRoutingMixin) {
	'use strict';

	var SETTINGS_CARD_MODES = {
		READ: 'read',
		EDIT: 'edit',
		READ_ONLY: 'read-only'
	};

	exports.SETTINGS_CARD_MODES = SETTINGS_CARD_MODES;
	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsWizardValidationAndError['default'], _componentLibraryMixinsComponentRoutingMixin['default'], {

		//Options
		cardDisabled: false,
		mode: SETTINGS_CARD_MODES.EDIT,

		isEditing: _ember['default'].computed.equal('mode', SETTINGS_CARD_MODES.EDIT),

		// Card Data
		data: {},

		cardEventsService: _ember['default'].inject.service('card-events'),

		//Card Data Properties

		//Field and card validations
		propertiesToValidate: {},

		actions: {
			toggleEdit: function toggleEdit() {
				var mode = this.get('mode');
				var edit = SETTINGS_CARD_MODES.EDIT;
				var read = SETTINGS_CARD_MODES.READ;

				if (mode == edit) {
					this.set('mode', read);
					this.get('cardEventsService').reset();
				} else if (mode == read) {
					this.set('mode', edit);
				}
				return _ember['default'].RSVP.resolve();
			}
		}
	});
});