define('component-library/components/typeahead-wrapper', ['exports', 'component-library/components/typeahead-search'], function (exports, _componentLibraryComponentsTypeaheadSearch) {
	'use strict';

	exports['default'] = _componentLibraryComponentsTypeaheadSearch['default'].extend({
		classNames: ['js-typeahead-wrapper'],

		didInsertElement: function didInsertElement() {
			this._super();
			this.setValue('Ma');
			this.openSuggestionMenu();
			this.getSuggestions();
		},

		setValue: function setValue(value) {
			this.$().typeahead('val', value);
		},

		openSuggestionMenu: function openSuggestionMenu() {
			this.$().typeahead('open');
		},

		closeSuggestionMenu: function closeSuggestionMenu() {
			this.$().typeahead('close');
		},

		getSuggestions: function getSuggestions() {
			var suggestions = [];
			this.$().siblings('.tt-menu').find('.z-typeahead-suggestion').each(function (index, element) {
				suggestions.push(element.textContent);
			});

			return suggestions;
		}
	});
});