define('ember-table/mixins/show-horizontal-scroll', ['exports', 'ember'], function (exports, _ember) {
  // HACK: We want the horizontal scroll to show on mouse enter and leave.
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    mouseEnter: function mouseEnter(event) {
      var $tablesContainer = _ember['default'].$(event.target).parents('.et-tables-container');
      var $horizontalScroll = $tablesContainer.find('.antiscroll-scrollbar-horizontal');
      $horizontalScroll.addClass('antiscroll-scrollbar-shown');
    },

    mouseLeave: function mouseLeave(event) {
      var $tablesContainer = _ember['default'].$(event.target).parents('.et-tables-container');
      var $horizontalScroll = $tablesContainer.find('.antiscroll-scrollbar-horizontal');
      $horizontalScroll.removeClass('antiscroll-scrollbar-shown');
    }
  });
});