define('component-library/components/ladda-button-promise', ['exports', 'ember', 'component-library/components/ladda-button'], function (exports, _ember, _componentLibraryComponentsLaddaButton) {
	'use strict';

	/*
  * Purpose: Disable button presses on click, and shows a spinner or progress bar in button.
  *
  * Extends Ladda Button.
  *
  * Example use in template:
  *
  * {{#ladda-button-promise class="btn btn--sm" promiseAction="save"}}Save{{/ladda-button-promise}}
  *
  * 	In your controller:
  *
  * 	actions: {
  * 		...
  *		save: function () {
  * 			var some_promise = ...;
  * 			return somePromise;
  * 		},
  * 		...
  * 	},
  *
  * Note: If you want to pass a parameter (for instance a loop variable in the handlebar) to the action,
  * pass it as a 'param' parameter to the component.
  */

	exports['default'] = _componentLibraryComponentsLaddaButton['default'].extend({
		layoutName: 'components/ladda-button',
		promiseAction: _ember['default'].computed.alias('onClick'),
		param: null,

		click: function click() {
			var _this = this;

			this.start();

			var actionName = this.get('promiseAction');
			if (!actionName) {
				return _ember['default'].warn("Promise Button requires a promiseAction to be specified");
			}

			var parentController = this.get('targetObject');
			// TODO: MM: refactor this to use closure actions
			var actions = parentController._actions;

			if (!actions) {
				return _ember['default'].warn("Action " + actionName + " must be defined within your controller's actions, do not use old-style Ember");
			}

			var func = actions[actionName];
			if (!func) {
				if (parentController[actionName]) {
					return _ember['default'].warn("Include " + actionName + " inside your controller's actions hash, do not use old-style Ember");
				}

				return _ember['default'].warn("Action " + actionName + " not found for ladda-button-promise");
			}

			var promise = func.apply(parentController, [].concat(this.get('param') || []));
			if (!(promise && promise.then)) {
				return _ember['default'].warn("Action " + actionName + " must return a promise");
			}

			return promise['finally'](function () {
				_this.stop();
			});
		}
	});
});