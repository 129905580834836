define('component-library/data-table/cells/editable/select-cell', ['exports', 'component-library/data-table/cells/data-table-cell'], function (exports, _componentLibraryDataTableCellsDataTableCell) {
	'use strict';

	exports['default'] = _componentLibraryDataTableCellsDataTableCell['default'].extend({
		templateName: 'data-table/cells/editable/select-cell',

		displayName: (function () {
			var displayName = this.get('content.selectOptions').findBy('value', this.get('cellContent'));
			if (displayName) {
				return displayName.label;
			} else {
				return this.get('cellContent');
			}
		}).property('cellContent')
	});
});