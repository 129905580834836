define('component-library/deductions/review/deduction-context-wrapper', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	// This wrapper for EmployeeDeductionContext will help to store deduciton review
	// data (reviewData) for this employee.
	// The reviewData is lazy loaded in deductions-lazy-loader.
	exports['default'] = _ember['default'].ObjectProxy.extend({
		reviewData: (function () {
			return _ember['default'].Object.create();
		}).property()
	});
});