define('component-library/data-table/cells/z-checkbox-header-cell', ['exports', 'ember', 'ember-table/views/header-cell'], function (exports, _ember, _emberTableViewsHeaderCell) {
	'use strict';

	exports['default'] = _emberTableViewsHeaderCell['default'].extend({
		templateName: 'data-table/cells/z-checkbox-header-cell',

		checkmarkClicked: function checkmarkClicked() {
			this.get('content.parentController').send('checkmarkClicked');
		}
	});
});