define('component-library/modals/payroll/employee-labor-distribution-modal', ['exports', 'ember', 'component-library/components/promise-modal', 'ui-infra/utils/sanitize-html'], function (exports, _ember, _componentLibraryComponentsPromiseModal, _uiInfraUtilsSanitizeHtml) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['Location Code'], ['Location Code']),
	    _templateObject2 = _taggedTemplateLiteral(['Department Code'], ['Department Code']),
	    _templateObject3 = _taggedTemplateLiteral(['Position Code<br><span class="z-label--optional">Optional</span>'], ['Position Code<br><span class="z-label--optional">Optional</span>']),
	    _templateObject4 = _taggedTemplateLiteral(['Project Code<br><span class="z-label--optional">Optional</span>'], ['Project Code<br><span class="z-label--optional">Optional</span>']),
	    _templateObject5 = _taggedTemplateLiteral(['', '<br><span class="z-label--optional">Optional</span>'], ['', '<br><span class="z-label--optional">Optional</span>']);

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	function transformForDisplay(rawData) {
		var outList = [];
		if (rawData) {
			outList = rawData.map(function (currentItem) {
				var laborCode = currentItem.domainData.laborCode || "No Labor Code";
				var itemName = laborCode + " - " + currentItem.name;
				return {
					id: currentItem.id,
					name: itemName
				};
			});
		}
		return outList;
	}

	function getLaborDataForId(laborDataList, laborDataId) {
		var selectedItem = null;
		if (laborDataId != null) {
			selectedItem = laborDataList.find(function (laborData) {
				return laborData.id == laborDataId;
			});
		}
		return selectedItem;
	}

	function getLaborDataForType(laborDataList, laborType) {
		var selectedItem = null;
		if (laborType != null) {
			selectedItem = laborDataList.find(function (laborData) {
				return laborData.laborType == laborType;
			});
		}
		return selectedItem;
	}

	function getLaborGroupIdForMember(laborDataList, employeeId) {
		var selectedItem = null;
		if (employeeId != null) {
			selectedItem = laborDataList.find(function (laborData) {
				return laborData.memberIds && laborData.memberIds.length > 0 && laborData.memberIds.includes(employeeId.toString());
			});
		}
		return selectedItem ? selectedItem.id : null;
	}

	function filterByEmployeeId(laborDataList, employeeId) {
		var outList = [];
		outList = laborDataList.filter(function (laborData) {
			return laborData.memberIds.includes(employeeId.toString());
		});
		return outList;
	}

	function findMatchingLabor(laborData, laborList) {
		if (!laborData || !laborList) {
			return null;
		}

		return laborList.find(function (laborItem) {
			return laborItem.name == laborData.laborName && laborItem.domainData.laborCode == laborData.laborCode;
		});
	}

	function getSelectedLaborId(employeeLaborFields, laborType, employerLaborValues, defaultLaborId) {
		if (employeeLaborFields.length == 0) {
			return { id: defaultLaborId };
		} else {
			var employeeLaborData = getLaborDataForType(employeeLaborFields, laborType);
			var matchedLaborData = findMatchingLabor(employeeLaborData, employerLaborValues);
			return { id: matchedLaborData ? matchedLaborData.id : null };
		}
	}

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'modals/payroll/employee-labor-distribution-modal',
		modalDialogClass: 'js-glue-eeLaborDistributionModal',
		autoSetHeight: false,

		ldmprPositions: [],
		ldmprLocations: [],
		ldmprDepartments: [],
		ldmprProjects: [],
		ldmprCustomGroups: [],
		ldmprCustomGroupName: null,
		ldmprCompensations: [],
		inputDisabled: false,

		employeePositions: _ember['default'].computed('ldmprPositions', 'earning', function () {
			var ldmprPositions = this.get('ldmprPositions') || [];
			var earning = this.get('earning');
			var filteredPositions = filterByEmployeeId(ldmprPositions, earning.zprEmployee.employee.get('id'));
			return transformForDisplay(filteredPositions);
		}),

		employeeLocations: _ember['default'].computed('ldmprLocations', 'earning', function () {
			var ldmprLocations = this.get('ldmprLocations') || [];
			var earning = this.get('earning');
			var filteredLocations = filterByEmployeeId(ldmprLocations, earning.zprEmployee.employee.get('id'));
			return transformForDisplay(filteredLocations);
		}),

		employeeDepartments: _ember['default'].computed('ldmprDepartments', function () {
			var ldmprDepartments = this.get('ldmprDepartments') || [];
			var showEmployeeDepartmentsOnly = this.get('switches.multiple_departments_support');
			var filteredDepartments = [];
			if (showEmployeeDepartmentsOnly) {
				// if multiple departments is enabled, filter based on employee
				var earning = this.get('earning');
				filteredDepartments = filterByEmployeeId(ldmprDepartments, earning.zprEmployee.employee.get('id'));
			} else {
				// if multiple departments isn't enabled, there's no filtering to be done
				filteredDepartments = ldmprDepartments;
			}
			return transformForDisplay(filteredDepartments);
		}),

		employeeProjects: _ember['default'].computed('ldmprProjects', 'earning', function () {
			var ldmprProjects = this.get('ldmprProjects') || [];
			var earning = this.get('earning');
			var filteredProjects = filterByEmployeeId(ldmprProjects, earning.zprEmployee.employee.get('id'));
			return transformForDisplay(filteredProjects);
		}),

		employeeCustomFields: _ember['default'].computed('ldmprCustomGroups', 'earning', function () {
			var ldmprCustomGroups = this.get('ldmprCustomGroups') || [];
			var earning = this.get('earning');
			var filteredCustomGroups = filterByEmployeeId(ldmprCustomGroups, earning.zprEmployee.employee.get('id'));
			return transformForDisplay(filteredCustomGroups);
		}),

		zprEmployee: null,
		earning: null,

		showProjects: _ember['default'].computed(function () {
			return true;
		}),

		undeletedLaborFields: _ember['default'].computed('earning', function () {
			return (this.get('earning.laborFields') || []).rejectBy('willDelete');
		}),

		selectedDepartment: _ember['default'].computed('earning', 'ldmprDepartments', function () {
			var earning = this.get('earning');
			var laborFields = this.get('undeletedLaborFields');
			var ldmprDepartments = this.get('ldmprDepartments');
			var defaultDepartmentId = getLaborGroupIdForMember(ldmprDepartments, earning.zprEmployee.employee.get('id'));
			return getSelectedLaborId(laborFields, "Department", ldmprDepartments, defaultDepartmentId);
		}),

		selectedLocation: _ember['default'].computed('earning', 'ldmprLocations', function () {
			var earning = this.get('earning');
			var laborFields = this.get('undeletedLaborFields');
			var ldmprLocations = this.get('ldmprLocations');
			var defaultLocationId = getLaborGroupIdForMember(ldmprLocations, earning.zprEmployee.employee.get('id'));
			return getSelectedLaborId(laborFields, "Location", ldmprLocations, defaultLocationId);
		}),

		selectedPosition: _ember['default'].computed('earning', 'ldmprPositions', function () {
			var laborFields = this.get('undeletedLaborFields');
			var ldmprPositions = this.get('ldmprPositions');
			var defaultPositionId = null;
			return getSelectedLaborId(laborFields, "Position", ldmprPositions, defaultPositionId);
		}),

		selectedProject: _ember['default'].computed('earning', 'ldmprProjects', function () {
			var laborFields = this.get('undeletedLaborFields');
			var ldmprProjects = this.get('ldmprProjects');
			var defaultProjectId = null;
			return getSelectedLaborId(laborFields, "Project", ldmprProjects, defaultProjectId);
		}),

		selectedCustomGroup: _ember['default'].computed('earning', 'ldmprCustomGroups', function () {
			var laborFields = this.get('undeletedLaborFields');
			var ldmprCustomGroups = this.get('ldmprCustomGroups');
			var defaultCustomGroupId = null;
			return getSelectedLaborId(laborFields, this.get('ldmprCustomGroupName'), ldmprCustomGroups, defaultCustomGroupId);
		}),

		enteredHours: _ember['default'].computed.oneWay('earning.numUnits'),
		enteredHourlyRate: _ember['default'].computed.oneWay('earning.ratePerUnit'),

		hourlyRateChange: _ember['default'].observer('earning', 'selectedPosition.id', 'ldmprCompensations', function () {
			var _this = this;

			// Salaried employees only have one hourly rate (at least, for now)
			var compType = this.get('zprEmployee.employee.compType');
			// TODO: Can we convert from hardcode to enum?
			if (compType == "S") {
				return;
			}
			var earning = this.get('earning');
			var selectedPosition = this.get('selectedPosition');
			var newRate = earning.get('ratePerUnit');
			if (selectedPosition != null) {
				(function () {
					var compensations = _this.get('ldmprCompensations');
					var defaultCompensation = _this.get('zprEmployee.employee.payRate');
					compensations.then(function (result) {
						var selectedCompensation = result.find(function (compensation) {
							return compensation.groupId == selectedPosition.id;
						});
						newRate = selectedCompensation ? selectedCompensation.amount : defaultCompensation;
						_this.set('enteredHourlyRate', newRate);
					});
				})();
			}
		}),

		customGroupTypeName: _ember['default'].computed.alias('ldmprCustomGroupName'),

		locationLabel: _ember['default'].computed(function () {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject);
		}),

		departmentLabel: _ember['default'].computed(function () {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject2);
		}),

		positionOptionalLabel: _ember['default'].computed(function () {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject3);
		}),

		projectOptionalLabel: _ember['default'].computed(function () {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject4);
		}),

		customGroupOptionalLabel: _ember['default'].computed(function () {
			var customGroupTypeName = this.get('customGroupTypeName');
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject5, customGroupTypeName);
		}),

		completeActionButtonName: _ember['default'].computed('undeletedLaborFields', function () {
			var laborFields = this.get('undeletedLaborFields');
			return laborFields.length ? "Save" : "Add";
		}),

		completeActionButtonJSClass: _ember['default'].computed('undeletedLaborFields', function () {
			var laborFields = this.get('undeletedLaborFields');
			return laborFields.length ? "js-glue-edit-modal" : "js-glue-add-modal";
		}),

		actions: {
			onAddLaborFields: function onAddLaborFields() {
				var selectedDepartment = this.get('selectedDepartment');
				var ldmprDepartments = this.get('ldmprDepartments');
				var selectedLocation = this.get('selectedLocation');
				var ldmprLocations = this.get('ldmprLocations');
				var selectedPosition = this.get('selectedPosition');
				var ldmprPositions = this.get('ldmprPositions');
				var selectedProject = this.get('selectedProject');
				var ldmprProjects = this.get('ldmprProjects');
				var selectedCustomGroup = this.get('selectedCustomGroup');
				var ldmprCustomGroups = this.get('ldmprCustomGroups');

				var selectedDepartmentId = selectedDepartment ? selectedDepartment.id : null;
				var selectedDepartmentData = getLaborDataForId(ldmprDepartments, selectedDepartmentId);
				var selectedLocationId = selectedLocation ? selectedLocation.id : null;
				var selectedLocationData = getLaborDataForId(ldmprLocations, selectedLocationId);
				var selectedPositionId = selectedPosition ? selectedPosition.id : null;
				var selectedPositionData = getLaborDataForId(ldmprPositions, selectedPositionId);
				var selectedProjectId = selectedProject ? selectedProject.id : null;
				var selectedProjectData = getLaborDataForId(ldmprProjects, selectedProjectId);
				var selectedCustomGroupId = selectedCustomGroup ? selectedCustomGroup.id : null;
				var selectedCustomGroupData = getLaborDataForId(ldmprCustomGroups, selectedCustomGroupId);

				var earning = this.get('earning');
				var isHourlyEarning = earning.get('canEditHourAndRate');
				var enteredHours = isHourlyEarning ? this.get('enteredHours') : null;
				var canEditHourlyRate = earning.get('canEditHourAndRate') || earning.get('isEarningFromPto');
				var enteredHourlyRate = canEditHourlyRate ? this.get('enteredHourlyRate') : null;

				this.send('confirm', {
					enteredHours: enteredHours,
					enteredHourlyRate: enteredHourlyRate,
					selectedDepartmentId: selectedDepartmentId,
					selectedDepartmentData: selectedDepartmentData,
					selectedLocationId: selectedLocationId,
					selectedLocationData: selectedLocationData,
					selectedPositionId: selectedPositionId,
					selectedPositionData: selectedPositionData,
					selectedProjectId: selectedProjectId,
					selectedProjectData: selectedProjectData,
					selectedCustomGroupId: selectedCustomGroupId,
					selectedCustomGroupData: selectedCustomGroupData
				});
			},

			onCancel: function onCancel() {
				this.send('close');
			}
		}
	});
});