define('component-library/deductions/review/master/view', ['exports', 'ember', 'component-library/views/master', 'component-library/mixins/resize-handler'], function (exports, _ember, _componentLibraryViewsMaster, _componentLibraryMixinsResizeHandler) {
	'use strict';

	exports['default'] = _componentLibraryViewsMaster['default'].extend(_componentLibraryMixinsResizeHandler['default'], {
		classNames: ['grid-block', 'vertical'],
		templateName: 'deductions/review/master',

		tableMaxHeight: 500,
		showInternalDiscrepancy: false,

		adjustHeightOfTableContainer: function adjustHeightOfTableContainer() {
			var containerHeight = _ember['default'].$('.js-z-tableContainer').height();
			this.set('tableMaxHeight', containerHeight);
		},

		onResizeEnd: function onResizeEnd() {
			this._super();
			this.adjustHeightOfTableContainer();
		},

		didInsertElement: function didInsertElement() {
			this._super();
			this.adjustHeightOfTableContainer();
		}
	});
});