define('component-library/data-table/cells/employee-sortable-header-cell', ['exports', 'ember-table/views/header-cell', 'component-library/data-table/column-employee-sortable-header-popover'], function (exports, _emberTableViewsHeaderCell, _componentLibraryDataTableColumnEmployeeSortableHeaderPopover) {
	'use strict';

	exports['default'] = _emberTableViewsHeaderCell['default'].extend({
		templateName: 'data-table/cells/sortable-header-cell',
		classNames: ['js-sortable-header-cell'],
		ColumnHeaderPopover: _componentLibraryDataTableColumnEmployeeSortableHeaderPopover['default'],

		actions: {}
	});
});