define('component-library/mixins/payroll-connect/edit-payroll-id-mixin', ['exports', 'ember', 'component-library/utils/payroll-connect/constants'], function (exports, _ember, _componentLibraryUtilsPayrollConnectConstants) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		// _setWfOrArEmployeePayrollIds(employees, company) {
		// 	// wfOrAr employees must set their payrollIds off of wfCoCode and wfFileNumber
		// 	employees.forEach((employee) => {
		// 		let wfCoCode = company.get('wfCoCode');
		// 		let wfFileNumber = employee.get('wfFileNumber');
		//
		// 		// we allow this so users can remove just the file number to delete the payroll ID
		// 		// this is ideal UX and is described here https://jira.inside-zen.com/browse/PAYSYNC-1083
		// 		if (Ember.isEmpty(wfFileNumber)) {
		// 			employee.set('payrollId', '');
		// 		} else {
		// 			employee.set('payrollId', wfCoCode + wfFileNumber);
		// 		}
		// 	});
		// },
		validatePayrollIdsAndSaveEmployees: function validatePayrollIdsAndSaveEmployees(employees, company) {
			// returns a promise
			var validationMap = _componentLibraryUtilsPayrollConnectConstants.PAYROLL_ID_VALIDATIONS[company.get('payrollProvider')];
			var dirtyEmployees = employees.filterBy('isDirty');
			var employeesToSave = undefined;
			var areAllEmployeePayrollIdsValid = undefined;
			var employeeSavePromises = undefined;

			if (!validationMap) {
				employeesToSave = dirtyEmployees;
			} else {
				employeesToSave = dirtyEmployees.filter(function (employee) {
					var payrollId = employee.getWithDefault('payrollId', '');
					var isPayrollIdValid = !!payrollId.match(validationMap.regExp);
					employee.set('isPayrollIdInvalid', !isPayrollIdValid);
					return isPayrollIdValid;
				});
			}

			areAllEmployeePayrollIdsValid = employeesToSave.length == dirtyEmployees.length;
			employeeSavePromises = employeesToSave.map(function (employee) {
				return employee.save();
			});
			this.set('errorMessage', areAllEmployeePayrollIdsValid ? null : validationMap.errorMessage);

			return _ember['default'].RSVP.all(employeeSavePromises);
		}
	});
});