define('component-library/components/multi-select-filter', ['exports', 'ember', 'component-library/components/promise-popover', 'component-library/mixins/adjust-popover-height'], function (exports, _ember, _componentLibraryComponentsPromisePopover, _componentLibraryMixinsAdjustPopoverHeight) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend(_componentLibraryMixinsAdjustPopoverHeight['default'], {
		templateName: 'components/multi-select-filter',
		hasArrow: true,
		placement: 'bottom',
		target: _ember['default'].computed.oneWay('model.target'),
		attachment: "top center",
		targetAttachment: "bottom center",

		filter: _ember['default'].computed.alias('content'),
		filterOptions: _ember['default'].computed('filter.options.[]', 'filter.options.@each', function () {
			return this.get('filter.options') && this.get('filter.options').compact();
		}),
		query: (function () {
			return this.get('filter.queryFunction') ? 'queryFunction' : null;
		}).property('filter.queryFunction'),

		newSelections: null,
		initFilterValue: (function () {
			this.set('newSelections', this.get('filter.selections').slice());
		}).on('init'),

		addOrApply: (function () {
			return this.get('filter.isActive') ? 'Apply' : 'Add';
		}).property('filter.isActive'),

		actions: {
			addOrApplyFilter: function addOrApplyFilter() {
				if (!this.get('filter.isActive')) {
					this.set('filter.isActive', true);
					this.sendAction('added');
				}
				var selections = this.get('newSelections');

				// don't let janky shit cross the boundaries -- select-2 is sending String objs instead of strings..
				if (this.get('filter.stringModel')) {
					selections = selections.map(function (x) {
						return x.toString();
					});
				}

				this.set('filter.selections', selections);
				this.send('close');
			},
			clear: function clear() {
				this.set('newSelections', []);
			},
			queryFunction: function queryFunction() {
				this.get('filter.queryFunction').apply(this, arguments);
			}
		}
	});
});