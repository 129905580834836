define('component-library/mixins/benefits/timeline/transaction-contact-data-mixin', ['exports', 'ember', 'component-library/utils/tags/default', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsTagsDefault, _componentLibraryUtilsGlobals) {
	'use strict';

	var computedPropertyForOld = function computedPropertyForOld(originalPropertyName, changePropertyName) {
		return _ember['default'].computed('transaction.isInitial', originalPropertyName, changePropertyName, function () {
			if (this.get('transaction.isInitial')) {
				return null;
			} else if (this.get(changePropertyName)) {
				return this.get(changePropertyName + '.lastObject');
			} else {
				return this.get(originalPropertyName);
			}
		});
	};

	exports['default'] = _ember['default'].Mixin.create({
		zip: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.personalInfo.zip'),
		address: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.personalInfo.address1'),
		city: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.personalInfo.city'),
		// Note that state is forbidden keyword, so we use contactState
		contactState: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.personalInfo.state'),

		zipChange: _ember['default'].computed.alias('transaction.jsonDataOfContextChange.employee.personalInfo.zip'),
		addressChange: _ember['default'].computed.alias('transaction.jsonDataOfContextChange.employee.personalInfo.address1'),
		cityChange: _ember['default'].computed.alias('transaction.jsonDataOfContextChange.employee.personalInfo.city'),
		contactStateChange: _ember['default'].computed.alias('transaction.jsonDataOfContextChange.employee.personalInfo.state'),

		oldZip: computedPropertyForOld('zip', 'zipChange'),
		oldAddress: computedPropertyForOld('address', 'addressChange'),
		oldCity: computedPropertyForOld('city', 'cityChange'),
		oldContactState: computedPropertyForOld('contactState', 'contactStateChange')
	});
});