define('component-library/components/text-input', ['exports', 'ember', 'component-library/mixins/style-bindings', 'fieldkit'], function (exports, _ember, _componentLibraryMixinsStyleBindings, _fieldkit) {
	'use strict';

	var _createClass = (function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
			}
		}return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
		};
	})();

	var _get = function get(_x5, _x6, _x7) {
		var _again = true;_function: while (_again) {
			var object = _x5,
			    property = _x6,
			    receiver = _x7;_again = false;if (object === null) object = Function.prototype;var desc = Object.getOwnPropertyDescriptor(object, property);if (desc === undefined) {
				var parent = Object.getPrototypeOf(object);if (parent === null) {
					return undefined;
				} else {
					_x5 = parent;_x6 = property;_x7 = receiver;_again = true;desc = parent = undefined;continue _function;
				}
			} else if ('value' in desc) {
				return desc.value;
			} else {
				var getter = desc.get;if (getter === undefined) {
					return undefined;
				}return getter.call(receiver);
			}
		}
	};

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError('Cannot call a class as a function');
		}
	}

	function _inherits(subClass, superClass) {
		if (typeof superClass !== 'function' && superClass !== null) {
			throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass);
		}subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	var buildValidator = function buildValidator(formatterClass, regex) {
		if (!formatterClass) {
			formatterClass = _fieldkit['default'].DelimitedTextFormatter;
		}
		return function (event) {
			var isNotNumber = event.inserted.text.length > 0 && !regex.test(event.inserted.text);
			return isNotNumber ? false : formatterClass.prototype.isChangeValid.call(this, event);
		};
	};

	var buildIntegerValidator = function buildIntegerValidator(formatterClass) {
		return buildValidator(formatterClass, /^\d+$/);
	};

	/** NumberFormatter **/

	var NumberFormatter = (function (_FieldKit$NumberFormatter) {
		_inherits(NumberFormatter, _FieldKit$NumberFormatter);

		function NumberFormatter() {
			var maximumLength = arguments.length <= 0 || arguments[0] === undefined ? undefined : arguments[0];
			var regex = arguments.length <= 1 || arguments[1] === undefined ? /^[-\d\.]+$/ : arguments[1];

			_classCallCheck(this, NumberFormatter);

			_get(Object.getPrototypeOf(NumberFormatter.prototype), 'constructor', this).call(this);
			this.regex = regex;
			this.maximumLength = maximumLength;
		}

		/** StrictNumberFormatter **/

		_createClass(NumberFormatter, [{
			key: 'isChangeValid',
			value: function isChangeValid(change, error) {
				var isNotNumber = change.inserted.text.length > 0 && !this.regex.test(change.inserted.text);
				return isNotNumber ? false : _get(Object.getPrototypeOf(NumberFormatter.prototype), 'isChangeValid', this).call(this, change, error);
			}
		}]);

		return NumberFormatter;
	})(_fieldkit['default'].NumberFormatter);

	var StrictNumberFormatter = (function (_FieldKit$NumberFormatter2) {
		_inherits(StrictNumberFormatter, _FieldKit$NumberFormatter2);

		function StrictNumberFormatter() {
			var maximumLength = arguments.length <= 0 || arguments[0] === undefined ? undefined : arguments[0];
			var regex = arguments.length <= 1 || arguments[1] === undefined ? /^[0-9]+$/ : arguments[1];

			_classCallCheck(this, StrictNumberFormatter);

			_get(Object.getPrototypeOf(StrictNumberFormatter.prototype), 'constructor', this).call(this);
			this.regex = regex;
			this.maximumLength = maximumLength;
		}

		/** DelimitedNumberFormatter **/

		_createClass(StrictNumberFormatter, [{
			key: 'isChangeValid',
			value: function isChangeValid(change, error) {
				var isNotNumber = change.inserted.text.length > 0 && !this.regex.test(change.inserted.text);
				return isNotNumber ? false : _get(Object.getPrototypeOf(StrictNumberFormatter.prototype), 'isChangeValid', this).call(this, change, error);
			}
		}]);

		return StrictNumberFormatter;
	})(_fieldkit['default'].NumberFormatter);

	var DelimitedNumberFormatter = (function (_FieldKit$DelimitedTextFormatter) {
		_inherits(DelimitedNumberFormatter, _FieldKit$DelimitedTextFormatter);

		function DelimitedNumberFormatter(format, delimiter) {
			_classCallCheck(this, DelimitedNumberFormatter);

			_get(Object.getPrototypeOf(DelimitedNumberFormatter.prototype), 'constructor', this).call(this, delimiter, false);
			this.maximumLength = format.length;
			this.regex = delimiter ? new RegExp('^[' + delimiter + '\\d\\.]+$') : /^[\d\.]+$/;
			this.delimiterIndex = [];
			for (var i = 0; i < format.length; ++i) {
				if (format.charAt(i) === delimiter) {
					this.delimiterIndex.pushObject(i);
				}
			}
		}

		/** DelimitedInputFormatter **/

		_createClass(DelimitedNumberFormatter, [{
			key: 'isChangeValid',
			value: function isChangeValid(change, error) {
				var isNotNumber = change.inserted.text.length > 0 && !this.regex.test(change.inserted.text);
				return isNotNumber ? false : _get(Object.getPrototypeOf(DelimitedNumberFormatter.prototype), 'isChangeValid', this).call(this, change, error);
			}
		}, {
			key: 'hasDelimiterAtIndex',
			value: function hasDelimiterAtIndex(index) {
				return this.delimiterIndex.indexOf(index) >= 0;
			}
		}]);

		return DelimitedNumberFormatter;
	})(_fieldkit['default'].DelimitedTextFormatter);

	var DelimitedInputFormatter = (function (_FieldKit$DelimitedTextFormatter2) {
		_inherits(DelimitedInputFormatter, _FieldKit$DelimitedTextFormatter2);

		function DelimitedInputFormatter(format, delimiter) {
			_classCallCheck(this, DelimitedInputFormatter);

			_get(Object.getPrototypeOf(DelimitedInputFormatter.prototype), 'constructor', this).call(this, delimiter, false);
			this.maximumLength = format.length;
			this.regex = delimiter ? new RegExp('^[' + delimiter + 'a-zA-Z0-9]+$') : /^[a-zA-Z0-9]+$/;
			this.delimiterIndex = [];
			for (var i = 0; i < format.length; ++i) {
				if (format.charAt(i) === delimiter) {
					this.delimiterIndex.pushObject(i);
				}
			}
		}

		/** OKTaxCommissionAccountNumberFormatter **/

		_createClass(DelimitedInputFormatter, [{
			key: 'isChangeValid',
			value: function isChangeValid(change, error) {
				var isNotAlphaNumeric = change.inserted.text.length > 0 && !this.regex.test(change.inserted.text);
				return isNotAlphaNumeric ? false : _get(Object.getPrototypeOf(DelimitedInputFormatter.prototype), 'isChangeValid', this).call(this, change, error);
			}
		}, {
			key: 'hasDelimiterAtIndex',
			value: function hasDelimiterAtIndex(index) {
				return this.delimiterIndex.indexOf(index) >= 0;
			}
		}]);

		return DelimitedInputFormatter;
	})(_fieldkit['default'].DelimitedTextFormatter);

	var OKTaxCommissionAccountNumberFormatter = function OKTaxCommissionAccountNumberFormatter() {
		this.maximumLength = 'WTH-99999999-99'.length;
		return this.maximumLength;
	};
	OKTaxCommissionAccountNumberFormatter.prototype = new _fieldkit['default'].DelimitedTextFormatter("-", false);
	OKTaxCommissionAccountNumberFormatter.prototype.validate = buildIntegerValidator();
	OKTaxCommissionAccountNumberFormatter.prototype.isChangeValid = function (event) {
		if (!/^WTH-$/.test(event.proposed.text.slice(0, 4))) {
			return false;
		}
		return this.validate(event);
	};
	OKTaxCommissionAccountNumberFormatter.prototype.hasDelimiterAtIndex = function (index) {
		return index === 3 || index === 12;
	};
	OKTaxCommissionAccountNumberFormatter.prototype.parse = function (text) {
		if (_ember['default'].isEmpty(text)) {
			return 'WTH';
		}
		return _fieldkit['default'].DelimitedTextFormatter.prototype.parse.call(this, text);
	};

	/** UTTaxCommissionAccountNumberFormatter **/
	var UTTaxCommissionAccountNumberFormatter = function UTTaxCommissionAccountNumberFormatter() {
		this.maximumLength = '99999999-999-WTH'.length;
		return this.maximumLength;
	};
	UTTaxCommissionAccountNumberFormatter.prototype = new _fieldkit['default'].DelimitedTextFormatter("-", false);
	UTTaxCommissionAccountNumberFormatter.prototype.validate = buildIntegerValidator();
	UTTaxCommissionAccountNumberFormatter.prototype.isChangeValid = function (event) {
		// if (!/^WTH-$/.test(event.proposed.text.slice(0, 4))) { return false; }
		return this.validate(event);
	};
	UTTaxCommissionAccountNumberFormatter.prototype.hasDelimiterAtIndex = function (index) {
		return index === 8 || index === 12;
	};
	UTTaxCommissionAccountNumberFormatter.prototype.parse = function (text) {
		if (_ember['default'].isEmpty(text)) {
			return 'WTH';
		}
		return _fieldkit['default'].DelimitedTextFormatter.prototype.parse.call(this, text);
	};

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsStyleBindings['default'], {
		classNames: ['z-textInput'],
		disabled: false,
		hasError: false,
		inputClassNames: _ember['default'].computed({
			get: function get() {
				return 'z-input js-textField-input';
			},
			set: function set(key, value) {
				_ember['default'].assert("inputClassNames for the {{text-input}} component must be a string", typeof value === 'string');
				var inputClasses = ['z-input', 'js-textField-input'];
				value.split(' ').forEach(function (name) {
					if (!inputClasses.contains(name)) {
						inputClasses.push(name);
					}
				});

				return inputClasses.join(' ');
			}
		}),

		format: null,
		maxLength: null,

		tooltipTitle: null,
		tooltipPlacement: 'bottom',

		didInsertElement: function didInsertElement() {
			var type = this.get('type');
			var $input = this.$('.js-textField-input');
			var formatter;
			var field;
			//Something is causing didInsertElement to run multiple times. FieldKit gets very angry if you attempt to re-apply
			if ($input.get(0)["field-kit-text-field"]) {
				return;
			}
			if (type === 'tel') {
				formatter = new _fieldkit['default'].PhoneFormatter();
				field = new _fieldkit['default'].TextField($input.get(0), formatter);
			} else if (type === 'ssn') {
				formatter = new _fieldkit['default'].SocialSecurityNumberFormatter();
				field = new _fieldkit['default'].TextField($input.get(0), formatter);
			} else if (type === 'date') {
				formatter = new DelimitedNumberFormatter('MM/DD/YYYY', '/');
				field = new _fieldkit['default'].TextField($input.get(0), formatter);
			} else if (type === 'ein') {
				formatter = new _fieldkit['default'].EmployerIdentificationNumberFormatter();
				field = new _fieldkit['default'].TextField($input.get(0), formatter);
			} else if (type === 'delimitedNumber') {
				formatter = new DelimitedNumberFormatter(this.get('format'), this.get('delimiter'));
				field = new _fieldkit['default'].TextField($input.get(0), formatter);
			} else if (type === 'delimitedInput') {
				formatter = new DelimitedInputFormatter(this.get('format'), this.get('delimiter'));
				field = new _fieldkit['default'].TextField($input.get(0), formatter);
			} else if (type === 'OKTaxCommissionAccountNumber') {
				formatter = new OKTaxCommissionAccountNumberFormatter();
				field = new _fieldkit['default'].TextField($input.get(0), formatter);
			} else if (type === 'integer') {
				formatter = new NumberFormatter(this.get('maxLength'), /^\d+$/);
				field = new _fieldkit['default'].TextField($input.get(0), formatter);
			} else if (type === 'currency') {
				formatter = new NumberFormatter();
				formatter.setMinimumFractionDigits(2);
				formatter.setMaximumFractionDigits(2);
				field = new _fieldkit['default'].TextField($input.get(0), formatter).setValue($input.val());
			} else if (type === 'number') {
				// TODO: BobZ formatter for number is broken. Only fixing currency formatter for now
				// as changing the number formatter will cause many places to break
				formatter = new NumberFormatter(this.get('maxLength'));
				// it is a bug in FieldKit, they should not enforce maximumFractionDigits if
				// it is not set...
				formatter.setMaximumFractionDigits(Number.MAX_SAFE_INTEGER);
				field = new _fieldkit['default'].TextField($input.get(0), formatter);
			} else if (type === 'strictNumber') {
				formatter = new StrictNumberFormatter(this.get('maxLength'));
				formatter.setMaximumFractionDigits(Number.MAX_SAFE_INTEGER);
				field = new _fieldkit['default'].TextField($input.get(0), formatter);
			}
		}
	});
});