define('component-library/components/truncate-lines', ['exports', 'ember', 'component-library/mixins/truncate-lines'], function (exports, _ember, _componentLibraryMixinsTruncateLines) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsTruncateLines['default'], {
		truncateLines: _ember['default'].computed.oneWay('truncateLinesDefault'),
		classNames: ['js-truncate-lines'],
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			this.truncate(this.$(), this.get('truncateLines'));
		}
	});
});