define('ember-table/controllers/row-array', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].ArrayController.extend({
    itemController: null,
    content: null,

    rowContent: _ember['default'].computed(function () {
      return [];
    }).property(),

    controllerAt: function controllerAt(idx, object) {
      var subControllers = this.get('_subControllers');
      var subController = subControllers[idx];
      if (subController) {
        return subController;
      }
      subController = this.get('itemController').create({
        target: this,
        parentController: this.get('parentController') || this,
        content: object
      });
      subControllers[idx] = subController;
      return subController;
    }
  });
});