define('component-library/components/d-z-helpbox', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-helpbox js-z-helpbox'],
		shouldAutoPosition: true,
		fadeDelay: 200,
		initialDisplayDelay: 200,
		topOffset: 65, // for main nav
		leftOffset: 0, // for any side nav
		hoverIntentDelay: 100,
		show: true,
		pollingInterval: 20, // this will get multiplied by 5 b/c we wait for 5 intervals to pass before checking anything
		pollingIntervalWindowSize: 5, // how many intervals pass before we do anything
		positionAnimationTiming: 100, // duration of CSS animation for calcPosition()
		helpBoxAnchorSelector: '[data-helpbox]',
		animateConditional: true, // Add additional animation to change text (need this if content changes without position change)
		offsetParentPosition: false, //For use when anchor is not full width or height
		triggerConditionalOnHover: true,
		triggerConditionalOnFocus: true,
		repositionConditional: false,
		shouldRecalcOnClick: true,

		_isConditionalContent: false,

		updateShadows: function updateShadows() {
			// Add shadow to the footer when scrollTop is 0, add shadow to title when
			// scrollTop is between 0 and the height of the body, and only add the
			// shadow to the footer when the scrollTop is equal to the height of the body
			var $helpBoxBody = _ember['default'].$(".js-z-helpbox-body");

			if (!$helpBoxBody || !$helpBoxBody.length) {
				return;
			}

			var helpBoxHeight = $helpBoxBody.height();
			var helpBoxTop = $helpBoxBody.offset().top;
			var helpBoxBottom = helpBoxHeight + helpBoxTop;

			// not all helptext have a header, so grab first child instead
			// the check for helpBoxHeight != 0 prevents a shadow from being set to an empty helpbox
			if ($helpBoxBody.children().length && helpBoxHeight !== 0) {
				var helpBoxFirstChildtop = $helpBoxBody.children().first().offset().top;
				var helpBoxLastChildBottom = $helpBoxBody.children().last().offset().top + $helpBoxBody.children().last().height();

				if (helpBoxFirstChildtop < helpBoxTop) {
					$helpBoxBody.addClass('has-shadow--top');
				} else {
					$helpBoxBody.removeClass('has-shadow--top');
				}

				if (helpBoxLastChildBottom > helpBoxBottom) {
					$helpBoxBody.addClass('has-shadow--bottom');
				} else {
					$helpBoxBody.removeClass('has-shadow--bottom');
				}
			}
		},

		calcPosition: function calcPosition(hoverTarget) {
			var _this = this;

			//If helpbox is intended to be static, do not calculate and change positions
			if (!this.get('shouldAutoPosition')) {
				return;
			}

			var target, helpBoxAnchor;
			var topOffset = this.get('topOffset');
			var leftOffset = this.get('leftOffset');
			var $helpBox = this.get('$helpBox');
			var $helpboxAnchorElement = _ember['default'].$(this.get('helpBoxAnchorSelector'))[0];

			if (hoverTarget) {
				helpBoxAnchor = hoverTarget;
				target = hoverTarget;
			} else {
				helpBoxAnchor = this.get('helpBoxAnchor');
				target = helpBoxAnchor.dataset.helpbox ? document.querySelectorAll(helpBoxAnchor.dataset.helpbox)[0] : helpBoxAnchor;
			}

			if (target) {
				var left = parseInt(target.getBoundingClientRect().right - leftOffset);
				var top = parseInt(target.getBoundingClientRect().top - topOffset);

				if (this.get('offsetParentPosition')) {
					left -= parseInt($helpboxAnchorElement.getBoundingClientRect().left);
					top -= parseInt($helpboxAnchorElement.getBoundingClientRect().top);
				}

				var animateHelpBox = function animateHelpBox() {
					$helpBox.animate({
						'top': top,
						'left': left
					}, _this.get('_isConditionalContent') ? 0 : _this.get('positionAnimationTiming'));
				};

				var previousTop = parseInt($helpBox.css('top'));
				var previousLeft = parseInt($helpBox.css('left'));

				if (previousTop != top || previousLeft != left) {
					this.set('show', false);
					animateHelpBox();
					this.set('show', true);
				} else {
					animateHelpBox();
				}
			}
		},

		toggleDisplay: (function () {
			var $helpBox = this.get('$helpBox');

			if (this.get('show')) {
				$helpBox.stop(false, true).fadeIn(this.get('fadeDelay'));
			} else {
				$helpBox.stop(false, true).fadeOut(this.get('fadeDelay'));
			}
		}).observes('show'),

		attachResizeHandler: function attachResizeHandler() {
			var _this2 = this;

			// when we resize the window we should recalc
			// where the helpbox goes since it's fixed position.
			// namespaced b/c we're attaching a handler on `window`
			var namespacedResizeEvent = ['resize', this.get('guid')].join('.');
			_ember['default'].$(window).on(namespacedResizeEvent, function () {
				_this2.calcPosition();
			});
		},

		handleConditionalContent: function handleConditionalContent() {

			var component = this;

			// this data attribute is added automatically by the z-helpbox-conditional-content component
			// the presence of any is enough to indicate that the following actions should be taken.
			if (this.$('[data-conditional-helpbox-content]').length) {

				this.set('_isConditionalContent', true);
				// insert secret wrapper if conditional items are present
				this.$('.js-z-helpbox-body').prepend('<div class="js-z-helpbox-conditional-content-wrapper"></div>');

				// hovering over anchors will selectively show/hide some help text
				var $helpBoxHoverAnchors = _ember['default'].$('[data-helpbox-conditional-hover-target]');

				// everytime we hover over an anchor we create a new timeout to guess hover intent
				// this can end up generating a lot of timeouts.  we push them all to an array
				// so we can be sure to clear each one, otherwise errant ones can cause weird UX
				var _hoverIntentDetectionTimeout = [];

				// hover wasn't working for some reason
				// do not want fat arrow here, b/c i want `this` to stay in jquery context

				var changeContent = function changeContent() {
					// setting this as a prop so we can grab it in the toggleConditionalContent() method later
					component.set('hoveredConditionalAnchor', component.$(this));

					// toggle content if the anchor is hovered for a specified duration of time
					// this prevents someone from triggering the wrong conditional content when they move their mouse
					// over to read the newly shown content.
					_hoverIntentDetectionTimeout.push(_ember['default'].run.later(this, function () {
						if (component.get('repositionConditional')) {
							component.calcPosition(component.get('hoveredConditionalAnchor')[0]);
						}
						component.toggleConditionalContent();
						component.set('show', true);
					}, component.get('hoverIntentDelay')));
				};

				var resetContent = function resetContent() {
					// clear all the timeouts set by the 'mouseover' handler
					for (var i = 0; i < _hoverIntentDetectionTimeout.length; i++) {
						_ember['default'].run.cancel(_hoverIntentDetectionTimeout[i]);
					}

					// reset this array so it doesn't grow forever
					_hoverIntentDetectionTimeout = [];
				};

				if (this.get('triggerConditionalOnHover')) {
					$helpBoxHoverAnchors.on('mouseover', changeContent);
					$helpBoxHoverAnchors.on('mouseleave', resetContent);
				}

				if (this.get('triggerConditionalOnFocus')) {
					$helpBoxHoverAnchors.on('focusin', changeContent);
					$helpBoxHoverAnchors.on('focusout', resetContent);
				}
			}
		},

		toggleConditionalContent: function toggleConditionalContent() {
			var _this3 = this;

			var $el = this.get('hoveredConditionalAnchor');
			var $wrapper = this.$('.js-z-helpbox-conditional-content-wrapper');
			var whichHoverTarget = $el.data('helpbox-conditional-hover-target');
			var $helpBoxConditonalTarget = this.$('[data-conditional-content-id="' + whichHoverTarget + '"]');

			if ($wrapper.data('shownContent') != whichHoverTarget) {

				// store reference to which content is being shown. this prevents
				// animation from re-running when same item is re-hovered
				$wrapper.data('shownContent', whichHoverTarget);

				// opting for slideUp/Down b/c cross-fading is difficult to achieve cleanly.
				// issues include:
				// - dealing with blank whitespace when content is faded out
				// - variable height content
				// - timing the fade transitions
				if (this.get('animateConditional')) {
					_ember['default'].animate(function () {
						var fadeDelay = _this3.get('fadeDelay');
						return $wrapper.slideUp(fadeDelay, function () {
							return $wrapper.html($helpBoxConditonalTarget.html());
						}).slideDown(fadeDelay);
					});
				} else {
					$wrapper.html($helpBoxConditonalTarget.html());
				}
			}
		},

		attachMutationObserver: function attachMutationObserver() {
			var _this4 = this;

			if (!window.MutationObserver) {
				return; //PhantomJS does not have this.
			}

			var helpBoxAnchor = this.get('helpBoxAnchor');

			// recalc helpbox position anytime the DOM subtree of anchor changes
			var observer = new MutationObserver(function (mutations) {
				var conditionalAnchor = _this4.get('hoveredConditionalAnchor');
				if (conditionalAnchor && _this4.get('shouldRecalcOnClick')) {
					_this4.calcPosition(conditionalAnchor[0]);
				} else {
					_this4.calcPosition();
				}
				_this4.updateShadows();
			});

			// store a ref to the observer we just defined so we can disconnet it
			// when the component is destroyed
			this.observer = observer;

			// mutation obersevers are the best
			// https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
			if (helpBoxAnchor !== undefined) {
				observer.observe(helpBoxAnchor, {
					childList: true,
					characterData: true,
					subtree: true
				});
			}
		},

		updateShadowsOnScroll: function updateShadowsOnScroll() {
			var _this5 = this;

			var $helpBox = this.get('$helpBox');

			// update shadows when we scroll helpbox content
			$helpBox.find('.js-z-helpbox-body').on('scroll', function () {
				_this5.updateShadows();
			});
		},

		positionAndFadeIn: function positionAndFadeIn() {
			var _this6 = this;

			var $helpBox = this.get('$helpBox');

			_ember['default'].run.later(this, function () {
				//NOTE: Recalculate position before fading in. There was some race-condition where
				//animations in the anchor (such as liquidfire transitions) causes the positioning of the
				//helpbox to be off. If you want to delay when the initial render (and therefore the
				//final position calculation of where to put the helpbox) happens, then set the
				//initialDisplayDelay value.
				//Talk to Kazu and Albert for more info on this.
				if (_this6.get('shouldAutoPosition')) {
					_this6.calcPosition();
				}

				$helpBox.fadeIn(function () {
					// once it's faded in figure out if we need shadows and where they go
					_this6.updateShadows();
				});
			}, this.get('initialDisplayDelay'));
		},

		_allAreEqual: function _allAreEqual(array) {
			if (!array.length) {
				return true;
			}
			// I also made sure it works with [false, false] array
			return array.reduce(function (a, b) {
				return a === b ? a : "false" + b;
			}) === array[0];
		},

		// So this isn't really a scroll handler.  The reason we need polling here is that the element our helpbox
		// is anchored to may be inside something else that scrolls, but doesn't itself scroll.
		// AFAIK there is no easier way to determine if the position of an element has changed - no event is fired.
		attachScrollHandler: function attachScrollHandler() {
			var that = this;

			// pat == previous anchor tops
			var _pat = [];
			var _rateLimit = false;

			//Don't do a polling repositioning if static.
			if (!this.get('shouldAutoPosition')) {
				return;
			}

			var anchorPollingIntervalID = setInterval(function () {

				// requery this with every interval b/c using a cached copy means we won't get updated positions
				var helpBoxAnchor = _ember['default'].$(that.get('helpBoxAnchorSelector'));
				var currentPosition = _ember['default'].$(helpBoxAnchor).position();
				if (!currentPosition) {
					return; // early if the anchor has no position (e.g. while the page is being aninmated)
				}
				_pat.push(currentPosition.top);

				// cache this here b/c when we splice _pat (to reduce mem footprint) len changes
				var _len = _pat.length;

				// polling interval window size
				var _piws = that.get('pollingIntervalWindowSize');

				// wait for 5 iterations as best guess to indicate user has stopped scrolling
				if (_len >= _piws) {

					// this will change the _pat, and therefore the length
					// using splice instead of slice keeps our array small so it doesn't grow insanely large over time
					var _allHeightsEqual = that._allAreEqual(_pat.splice(-1 * _piws, _piws));

					// _rateLimit exists to prevent unnecessary calls to `that.calcPosition()`;
					// if _allHeightsEqual is true for more than 1 interval there is no point in running calcPosition again;
					// the moment _allHeightsEqual goes false _once_, that implies that it's worth running calcPosition again;
					if (!_allHeightsEqual) {
						_rateLimit = false;
					}

					// run this once and then wait until _allHeightsEqual is false again
					if (_allHeightsEqual && !_rateLimit) {
						that.calcPosition();
						_rateLimit = true;
					}
				}
			}, this.get('pollingInterval'));

			this.set('anchorPollingIntervalID', anchorPollingIntervalID);
		},

		didInsertElement: function didInsertElement() {
			var _this7 = this;

			this._super();

			// this way other methods can use a cached version
			// instead of re-querying the DOM
			this.set('$helpBox', this.$());
			this.set('helpBoxAnchor', _ember['default'].$(this.get('helpBoxAnchorSelector'))[0]);
			this.set('guid', _ember['default'].guidFor(this));
			var $helpBox = this.get('$helpBox');

			if (!this.get('shouldAutoPosition')) {
				$helpBox.addClass('z-helpbox--static');
			}

			_ember['default'].run.scheduleOnce('afterRender', function () {

				// hide the helpbox initially while we determine where to place it
				$helpBox.hide();

				// slowly fade it in for a nice effect
				_this7.positionAndFadeIn();
				_this7.attachMutationObserver();
				_this7.attachScrollHandler();
				_this7.updateShadowsOnScroll();
				_this7.attachResizeHandler();
				_this7.handleConditionalContent();
			});
		},

		willDestroyElement: function willDestroyElement() {
			this.$(".js-z-helpbox-body").off('scroll');

			if (this.observer) {
				this.observer.disconnect();
			}

			clearInterval(this.get('anchorPollingIntervalID'));

			var namespacedResizeEvent = ['resize', this.get('guid')].join('.');
			_ember['default'].$(window).off(namespacedResizeEvent);
		}
	});
});