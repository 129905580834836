define('component-library/components/payroll/smp/sync-diff-table/details-panel', ['exports', 'ember', 'component-library/mixins/payroll/smp/sync-diff-data-mixin'], function (exports, _ember, _componentLibraryMixinsPayrollSmpSyncDiffDataMixin) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsPayrollSmpSyncDiffDataMixin['default'], {
		row: null,
		contentPath: null,
		resolvedValue: null,
		classNames: ['grid-block'],
		handleClick: true,

		type: (function () {
			return this.get('contentPath') === 'inZenefits' ? 'zenefits' : 'payroll';
		}).property('contentPath')
	});
});