define('component-library/mixins/inbound-actions', ['exports', 'ember', 'component-library/helpers/action-proxy'], function (exports, _ember, _componentLibraryHelpersActionProxy) {
  'use strict';

  // We'll need to modify this. This is copied/modified from https://github.com/GavinJoyce/ember-component-inbound-actions

  exports['default'] = _ember['default'].Mixin.create({
    _inbound_actions_setup: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        var proxy = _componentLibraryHelpersActionProxy['default'].create({ target: this });
        this.set('actionReceiver', proxy);
      });
    }).on('init')
  });
});