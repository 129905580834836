define('z-inputs/components/z-text-field-money', ['exports', 'ember', 'z-inputs/components/z-text-field-base', 'z-inputs/templates/components/z-text-field-money', 'z-inputs/utils/number-fields-helper'], function (exports, _ember, _zInputsComponentsZTextFieldBase, _zInputsTemplatesComponentsZTextFieldMoney, _zInputsUtilsNumberFieldsHelper) {
  'use strict';

  exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend({
    layout: _zInputsTemplatesComponentsZTextFieldMoney['default'],
    classNames: 'z-textField--money',
    type: 'money',
    currencySymbol: '$',
    mask: 'decimal',
    //for action identification
    fieldId: undefined,
    tooltipText: null,
    digits: 2,

    maskOptions: _ember['default'].computed('min', 'max', function () {
      return {
        radixPoint: '.',
        digits: this.get('digits'),
        min: this.get('min'),
        max: this.get('max'),
        showMaskOnHover: true,
        showMaskOnFocus: true,
        groupSeparator: ',',
        autoGroup: true
      };
    }),

    shadowValue: _ember['default'].computed.alias('currencySymbol'),

    didInsertElement: function didInsertElement() {
      this._super();
      var value = this.get('value');
      if (value) {
        // replaces all letters and commas w/ empty string
        var sanitizedValue = value.toString().replace(/[a-zA-Z,]/g, '').trim();
        var newValue = (0, _zInputsUtilsNumberFieldsHelper['default'])(sanitizedValue, {
          min: this.get('min'),
          max: this.get('max')
        });
        //TODO: Do not set Object properties in didInsertElement
        this.set('formattedMoneyValue', parseFloat(newValue).toFixed(this.get('digits')));
      }
    },

    formattedMoneyValue: _ember['default'].computed('value', {
      get: function get() {
        return this.get('value');
      },

      set: function set(key, userInputValue) {
        if (arguments.length > 1) {
          if (_ember['default'].isEmpty(userInputValue)) {
            this.set('value', '');
          } else {
            // trim to strip padding and remove `,` that's added from inputmask
            this.set('value', parseFloat(userInputValue.replace(/,/g, '').trim()).toFixed(this.get('digits')));
          }
        }

        return userInputValue;
      }
    }),

    actions: {
      focusedOut: function focusedOut() {
        var value = this.get('value');
        if (value) {
          this.set('value', (0, _zInputsUtilsNumberFieldsHelper['default'])(value, {
            min: this.get('min'),
            max: this.get('max')
          }));
          this.set('formattedMoneyValue', parseFloat(this.get('value')).toFixed(this.get('digits')));
        }

        this.sendAction('focusedOut', this);
      }
    }
  });
});