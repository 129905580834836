define('component-library/components/z-simple-popover', ['exports', 'ember', 'component-library/components/promise-popover'], function (exports, _ember, _componentLibraryComponentsPromisePopover) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
		templateName: 'components/z-simple-popover',
		classNames: 'z-simplePopover',
		hasArrow: true,
		content: null,
		target: _ember['default'].computed.oneWay('content.target')
	});
});