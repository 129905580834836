define('ember-table/views/lazy-item', ['exports', 'ember', 'ember-table/mixins/style-bindings'], function (exports, _ember, _emberTableMixinsStyleBindings) {
  'use strict';

  exports['default'] = _ember['default'].View.extend(_emberTableMixinsStyleBindings['default'], {
    itemIndex: null,
    prepareContent: _ember['default'].K,
    teardownContent: _ember['default'].K,
    rowHeightBinding: 'parentView.rowHeight',
    styleBindings: ['width', 'top', 'display'],

    top: _ember['default'].computed(function () {
      return this.get('itemIndex') * this.get('rowHeight');
    }).property('itemIndex', 'rowHeight'),

    // TODO(azirbel): Add explicit else case
    display: _ember['default'].computed(function () {
      if (!this.get('content')) {
        return 'none';
      }
    }).property('content')
  });
});