define('component-library/components/tabbed-modal/utils', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var TABBED_MODAL_MODES = {
		// this is a wizard mode - every tab has a footer with step number, next and cancel buttons.
		// Tabs links has numbers, which change to either a checkmark or an exclamation mark depending on
		// step validation. Can be use for creating complex or related data entries
		SEQUENCE: 'sequence',

		// in this mode tab links don't have any numbers of validity indication, tabs don't have any footer
		// should be used for view mode with no edititng capabilities
		READ: 'read',

		// same as read mode, but content of the tabs can be editable. 'enable editing' button should be implemented
		// and should be switching from view mode to edit mode (a form). tab component's validaion system should can be used
		// in which case user won't be able to switch to another tab while tab is invalid
		EDIT: 'edit'
	};

	exports.TABBED_MODAL_MODES = TABBED_MODAL_MODES;

	var TabbedModalModeFields = _ember['default'].Mixin.create({
		modalMode: null,
		isEditMode: _ember['default'].computed.equal('modalMode', TABBED_MODAL_MODES.EDIT),
		isSequenceMode: _ember['default'].computed.equal('modalMode', TABBED_MODAL_MODES.SEQUENCE),
		isReadMode: _ember['default'].computed.equal('modalMode', TABBED_MODAL_MODES.READ)
	});
	exports.TabbedModalModeFields = TabbedModalModeFields;
});