define('model-library/models/company-payment-method-cc', ['exports', 'ember-data', 'model-library/models/company-payment-method-abstract'], function (exports, _emberData, _modelLibraryModelsCompanyPaymentMethodAbstract) {
	'use strict';

	var attr = _emberData['default'].attr;

	exports['default'] = _modelLibraryModelsCompanyPaymentMethodAbstract['default'].extend({
		expirationMonth: attr('number'),
		expirationYear: attr('number'),
		cardType: attr('string')
	});
});