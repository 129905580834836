define('component-library/filters/token-search-filter-spec-client', ['exports', 'ember', 'component-library/filters/abstract-filter-spec', 'component-library/components/number-filter'], function (exports, _ember, _componentLibraryFiltersAbstractFilterSpec, _componentLibraryComponentsNumberFilter) {
	'use strict';

	exports['default'] = _componentLibraryFiltersAbstractFilterSpec['default'].extend({

		isTokenSearchFilter: true,

		popoverClass: null,
		placeholder: 'Search tokens',
		minQueryLength: 0,
		searchQuery: null,

		description: _ember['default'].computed('searchQuery', function () {
			var searchQuery = this.get('searchQuery');

			if (searchQuery) {
				return 'Searching for ' + searchQuery;
			} else {
				return "*ALL* the values";
			}
		}),

		applyFunc: _ember['default'].computed('searchQuery', 'valuePath', function () {
			var searchQuery = this.get('searchQuery');
			var valuePath = this.get('valuePath');
			var minQueryLength = this.get('minQueryLength');

			return function (dataRow) {
				var result = true;
				var val = dataRow.get(valuePath);
				if (searchQuery && searchQuery.length >= minQueryLength && val) {
					val = val.toLowerCase();
					var searchParams = searchQuery.toLowerCase().split(' ');
					for (var i = 0; i < searchParams.length; i++) {
						result = result && val.indexOf(searchParams[i]) >= 0;
					}
				}
				return result;
			};
		})

	});
});