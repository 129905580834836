define('component-library/components/star-rating', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		maxRating: 5,
		minRating: _ember['default'].computed('allowHalfStar', function () {
			return this.get('allowHalfStar') ? 0.5 : 1;
		}),
		step: _ember['default'].computed('allowHalfStar', function () {
			return this.get('allowHalfStar') ? 0.5 : 1;
		}),
		readOnly: false,
		selectedRating: null,
		allowHalfStar: true,
		blueStars: false,
		onChange: null,

		ratings: _ember['default'].computed('maxRating', 'minRating', function () {
			var ratingsArray = [];
			var maxRating = this.get('maxRating');
			var minRating = this.get('minRating');
			for (var i = maxRating; i >= minRating; i -= this.get('step')) {
				ratingsArray.pushObject(_ember['default'].Object.create({
					value: i,
					active: i === this.get('selectedRating'),
					isHalf: i % 1 !== 0
				}));
			}
			return ratingsArray;
		}),

		didInsertElement: function didInsertElement() {
			this._super();
			this.$('.js-star-rating-item').on('mouseleave', function () {
				_ember['default'].$(this).removeClass('reset-selected');
			});
		},

		willDestroyElement: function willDestroyElement() {
			this._super();
			this.$('.js-star-rating-item').off('mouseleave');
		},

		actions: {
			setRating: function setRating(ratingItem) {
				var _this = this;

				if (this.get('readOnly')) {
					return;
				}
				var currentRating = this.get('selectedRating');
				var newRating = ratingItem.get('value');
				// Disabling the active state of the previous selected rating (if any)
				if (currentRating === newRating) {
					_ember['default'].run.scheduleOnce('afterRender', this, function () {
						_this.$(event.target).addClass('reset-selected');
					});
				} else if (currentRating) {
					this.get('ratings').findBy('value', currentRating).toggleProperty('active');
				}
				// Toggles current rating
				ratingItem.toggleProperty('active');

				// Set new Rating
				this.set('selectedRating', currentRating == newRating ? null : newRating);

				if (!_ember['default'].isNone(this.get('onChange'))) {
					this.sendAction('onChange');
				}
			}
		}
	});
});