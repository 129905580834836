define('component-library/components/native-select-field', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['selectField', 'native-select-field'],
		classNameBindings: ['errorText:has-error', 'isDisabled', 'isStatic', 'label:has-label', 'isRequired'],

		optionValuePath: 'content',
		optionLabelPath: 'content',
		optionDisabledPath: null,

		label: null,
		errorText: null,
		helpText: null,
		isDisabled: false,
		isStatic: false,
		isRequired: false,
		placeholder: _ember['default'].computed.alias('prompt'),

		showHelpText: _ember['default'].computed('helpText', 'errorText', function () {
			return this.get('helpText') && !this.get('errorText');
		})
	});
});