define('component-library/data-table/cells/tree-table-cell', ['exports', 'ember-table/views/table-cell'], function (exports, _emberTableViewsTableCell) {
	'use strict';

	exports['default'] = _emberTableViewsTableCell['default'].extend({
		templateName: 'data-table/cells/tree-table-cell',

		paddingStyle: (function () {
			return 'padding-left:' + this.get('row.indentation') + 'px;';
		}).property('row.indentation')
	});
});