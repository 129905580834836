define('component-library/utils/inState-rule', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var App = window.App;
	var switches = App.switches;

	var InStateRulePromise = function InStateRulePromise(lineOfCoverage, carrierId, effectiveDate) {
		if (switches.isActive('zrules_engine_in_state_rules')) {
			return _ember['default'].PromiseObject.create({
				promise: _ember['default'].getJSON('/api/inStateRule?q_carrierId=' + carrierId + '&q_lineOfCoverage=' + lineOfCoverage + '&q_effectiveDate=' + effectiveDate)
			});
		} else {
			return App.InStateRule.find({
				q_isSearch: true,
				q_lineOfCoverage: lineOfCoverage,
				q_carrierId: carrierId,
				q_effectiveDate: effectiveDate
			});
		}
	};

	exports.InStateRulePromise = InStateRulePromise;

	var InStateRuleV2Promise = function InStateRuleV2Promise(lineOfCoverage, carrierId, effectiveDate) {
		if (!switches.isActive('zrules_engine_in_state_rules')) {
			return _ember['default'].RSVP.resolve([]);
		}
		return App.store.find('App.InStateRuleV2', {
			q_isSearch: true,
			q_lineOfCoverage: lineOfCoverage,
			q_carrierId: carrierId,
			q_effectiveDate: effectiveDate
		});
	};
	exports.InStateRuleV2Promise = InStateRuleV2Promise;
});