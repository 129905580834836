define('component-library/data-table/cells/remote-sortable-header-cell', ['exports', 'ember-table/views/header-cell'], function (exports, _emberTableViewsHeaderCell) {
	'use strict';

	exports['default'] = _emberTableViewsHeaderCell['default'].extend({
		templateName: 'data-table/cells/remote-sortable-header-cell',

		click: function click() {
			var actionName = this.get('column.headerAction');
			// this.get('tableComponent').get('columns').setEach('isSorted', false);
			this.get('tableComponent').sendAction(actionName, this.get('column'));
		}
	});
});