define('component-library/components/promise-iframe-modal', ['exports', 'ember', 'component-library/utils/globals', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryComponentsPromiseModal) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'components/promise-iframe-modal',

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			var $iframe = _ember['default'].$('.js-modal-iframe');
			var doc = $iframe[0].contentWindow.document;
			doc.open();
			doc.write(this.get('iframeContent'));
			doc.close();
			var $doc = (0, _componentLibraryUtilsGlobals.$)(doc);
			$iframe.width($doc.width());
			$iframe.height($doc.height());
			(0, _componentLibraryUtilsGlobals.$)('.js-modal-dialog').css('width', 'initial');
		}
	});
});