define('component-library/components/d-icon-tooltip', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		anchorSelector: 'body',
		tagName: 'i',
		classNames: ['zmdi', 'zmdi-alert-circle-o'],
		tooltipClass: '',
		placement: 'bottom',
		isHtml: false,

		tooltipOptions: (function () {
			return {
				container: this.get('anchorSelector'),
				placement: this.get('placement'),
				title: this.get('title'),
				html: this.get('isHtml'),
				template: '<div class="tooltip ' + this.get('tooltipClass') + '"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
			};
		}).property('anchorSelector', 'placement', 'title', 'isHtml'),

		updateTooltip: function updateTooltip() {
			_ember['default'].$.extend(this.$().data('bs.tooltip').options, this.get('tooltipOptions'));
		},

		init: function init() {
			this._super();
			_ember['default'].deprecate('icon-tooltip, error-icon-tooltip, and info-icon-tooltip will be fully deprecated in Q4 2016. Use the Style Guide version instead, https://console.zenefits.com/console/style-guide#/components/tooltip', false, { id: 'tooltip-deprecation' });
		},

		didInsertElement: function didInsertElement() {
			this.$().bstooltip(this.get('tooltipOptions'));
			this.$().on('show.bs.tooltip', this.updateTooltip.bind(this));
		},

		willDestroyElement: function willDestroyElement() {
			this.$().off('show.bs.tooltip');
			this.$().bstooltip('destroy');
		}
	});
});