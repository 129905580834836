define('z-inputs/components/z-text-field-base', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['z-textField', 'js-z-textField'],
    classNameBindings: ['showErrorComputed:has-error', 'isDisabled', 'isStatic', 'isFocused', 'hasValue:has-value', 'hasLabel:has-label', 'isRequired', 'showHelpText:has-help-text'],
    isFocused: false,
    isDisabled: false,
    isRequired: false,
    isStatic: false,
    errorType: 'text',
    hasValue: _ember['default'].computed.bool('value'),
    hasLabel: _ember['default'].computed.bool('label'),
    showError: true,
    tooltipText: null,
    showShadowPlaceholder: false,
    maskPlaceholder: '',
    maskOptions: {},

    type: 'default',
    label: undefined,
    hint: _ember['default'].computed.deprecatingAlias('placeholder'),

    // explictily set it to an empty string as
    // it shows null if native placeholder attr is null/undefined
    placeholder: '',

    value: undefined,
    //for action identification
    fieldName: undefined,
    fieldId: undefined,
    errorText: undefined,
    helpText: undefined,
    shadowPlaceholder: undefined,
    shadowUnitSuffix: undefined,
    hidePlaceholderOnFocus: false,
    maxLength: undefined,
    pattern: '.*',
    _setCurrentMaskOptions: function _setCurrentMaskOptions() {},

    showErrorComputed: _ember['default'].computed('errorText.[]', 'showError', 'errorType', function () {
      return this.get('errorText.length') > 0 && this.get('showError');
    }),

    showErrorTooltip: _ember['default'].computed('showErrorComputed', 'errorType', function () {
      return this.get('showErrorComputed') && this.get('errorType') === 'tooltip';
    }),

    showErrorText: _ember['default'].computed('showErrorComputed', 'errorType', function () {
      return this.get('showErrorComputed') && this.get('errorType') === 'text';
    }),

    showHelpText: _ember['default'].computed('helpText', 'showErrorTooltip', 'showErrorText', function () {
      return this.get('helpText') && !this.get('showErrorTooltip') && !this.get('showErrorText');
    }),

    isHelpTextTriggered: _ember['default'].computed('isFocused', 'isDisabled', 'isStatic', function () {
      return this.get('isStatic') || this.get('isDisabled') || this.get('isFocused');
    }),

    defaultMaskOptions: _ember['default'].computed('maskPlaceholder', function () {
      return {
        placeholder: this.get('maskPlaceholder'),
        showMaskOnHover: false,
        showMaskOnFocus: false,
        rightAlign: false
      };
    }),

    _applyMask: function _applyMask(maskOptions) {
      var mask = this.get('mask');
      if (!_ember['default'].isEmpty(mask) && !_ember['default'].isEmpty(maskOptions)) {
        var calculatedMaskOptions = _ember['default'].merge(this.get('defaultMaskOptions'), maskOptions);
        if (this.get('type') === 'phone' && this.get('isInternational')) {
          // need run.next to wait for country code input to show before applying the mask
          _ember['default'].run.next(this, function () {
            this.$('.js-z-textField-countryCodeInput').inputmask(this.get('countryCodeMask'), calculatedMaskOptions);
          });
          this.$('.js-z-textField-input').inputmask('remove');
        } else {
          this.$('.js-z-textField-input').inputmask(mask, calculatedMaskOptions);
        }
        this._setCurrentMaskOptions();
      }
    },

    didInsertElement: function didInsertElement() {
      this._super();
      this._applyMask(this.get('maskOptions'));
      this.set('_placeholderCopy', this.get('placeholder'));
    },

    shadowValue: _ember['default'].computed('value', 'isFocused', 'showShadowPlaceholder', 'formattedDateValue', 'formattedTimeValue', 'formattedPasswordValue', 'formattedMoneyValue', function () {
      var value = this.get('value');
      value = _ember['default'].isEmpty(value) ? '' : value;
      var placeholder = this.getWithDefault('shadowPlaceholder', '');

      if (this.get('showShadowPlaceholder') && this.get('isFocused')) {
        // NOTE: use the value from the input directly so formatting is correct
        value = this.$(".js-z-textField-input").val() || '';
        return value + placeholder.substr(value.length);
      }
    }),

    actions: {
      focusedIn: function focusedIn() {
        if (this.get('hidePlaceholderOnFocus')) {
          this.set('placeholder', '');
        }

        // set focus
        if (!this.get('isDisabled')) {
          this.set('isFocused', true);
        }

        this.sendAction('focusedIn', this);
      },

      focusedOut: function focusedOut() {
        if (this.get('hidePlaceholderOnFocus')) {
          this.set('placeholder', this.get('_placeholderCopy'));
        }

        this.set('isFocused', false);
        this.set('showError', true);
        this.sendAction('focusedOut', this);
      }
    }
  });
});