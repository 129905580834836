define('component-library/constants/components/z-progress-navigation', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var _STEP_STATES_MAPPING;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
		} else {
			obj[key] = value;
		}return obj;
	}

	var STEP_STATES = {
		INACTIVE: 'inactive',
		INACTIVE_LOCKED: 'inactive_locked',
		PENDING: 'pending',
		COMPLETE: 'complete',
		COMPLETE_LOCKED: 'complete_locked'
	};

	exports.STEP_STATES = STEP_STATES;

	var STEP_STATES_MAPPING = (_STEP_STATES_MAPPING = {}, _defineProperty(_STEP_STATES_MAPPING, STEP_STATES.INACTIVE, 'inactive'), _defineProperty(_STEP_STATES_MAPPING, STEP_STATES.INACTIVE_LOCKED, 'inactiveLocked'), _defineProperty(_STEP_STATES_MAPPING, STEP_STATES.PENDING, 'pending'), _defineProperty(_STEP_STATES_MAPPING, STEP_STATES.COMPLETE, 'complete'), _defineProperty(_STEP_STATES_MAPPING, STEP_STATES.COMPLETE_LOCKED, 'completeLocked'), _STEP_STATES_MAPPING);

	exports.STEP_STATES_MAPPING = STEP_STATES_MAPPING;

	var ZProgressNavigationSectionPresenter = _ember['default'].Object.extend({
		key: null,
		title: null,
		steps: []
	});

	exports.ZProgressNavigationSectionPresenter = ZProgressNavigationSectionPresenter;

	var ZProgressNavigationStepPresenter = _ember['default'].Object.extend({
		key: null,
		title: null,
		state: null,
		progress: null,
		isSelected: false,

		isComplete: _ember['default'].computed('state', function () {
			var state = this.get('state');
			return state == STEP_STATES.COMPLETE_LOCKED || state == STEP_STATES.COMPLETE;
		})
	});
	exports.ZProgressNavigationStepPresenter = ZProgressNavigationStepPresenter;
});