define('component-library/components/payroll/choose-pay-run-type-card', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		selectedPayRunType: 'offcycle',
		isOffCycle: _ember['default'].computed.equal('selectedPayRunType', 'offcycleWithoutCloning'),
		isCloning: _ember['default'].computed.equal('selectedPayRunType', 'offcycleWithCloning'),
		payRunCardTitle: _ember['default'].computed(function () {
			return this.get('isContractorPaymentsCompany') ? 'Create a Payment' : 'Create a one-time run';
		}),
		payRunTypeBtnLabel: _ember['default'].computed(function () {
			return this.get('isContractorPaymentsCompany') ? 'Payment Type' : 'Pay Run Type';
		}),
		createNewPayBtnLabel: _ember['default'].computed(function () {
			return this.get('isContractorPaymentsCompany') ? 'Create a new payment' : 'Create a new pay run';
		}),
		cloneExistingPayBtnLabel: _ember['default'].computed(function () {
			return this.get('isContractorPaymentsCompany') ? 'Clone an existing payment' : 'Clone an existing pay run';
		}),

		actions: {
			next: function next(button) {
				this.attrs.onContinue(button, { selectedPayRunType: this.get('selectedPayRunType') });
			}
		}
	});
});