define('component-library/components/billing-payment-method', ['exports', 'ember', 'component-library/templates/components/billing-payment-method'], function (exports, _ember, _componentLibraryTemplatesComponentsBillingPaymentMethod) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		template: _componentLibraryTemplatesComponentsBillingPaymentMethod['default'],

		didRender: function didRender() {
			this._super.apply(this, arguments);
			this.set('paymentMethodInfo', this.get('paymentInfo'));
		},

		paddingClass: 'u-pad--md',

		billingPaymentMethod: _ember['default'].inject.service(),

		// by default, form has not been submitted. So the error message doesn't need to be shown. If user clicks next without
		// having a payment method, the error message should show up.
		formSubmitted: false,

		// for zuora popup
		showZuoraPaymentMethodPopup: false,

		// selectedType is the default payment method use picks on the UI
		selectedType: null,
		paymentInfo: _ember['default'].computed('creditCard', 'bank', 'defaultPaymentMethodIsBank', 'defaultPaymentMethodIsCc', function () {
			if (this.get('defaultPaymentMethodIsBank')) {
				return this.get('bank');
			}
			if (this.get('defaultPaymentMethodIsCc')) {
				return this.get('creditCard');
			}
			return null;
		}),

		paymentInfoChanged: _ember['default'].observer('paymentInfo', function () {
			this.set('paymentMethodInfo', this.get('paymentInfo'));
		}),

		// cc and bank info read from our db
		creditCard: null,
		bank: null,

		// this can be used to check if the payment method is picked or not. e.g. when user clicks "purchase"
		paymentMethodError: _ember['default'].computed('creditCard', 'bank', 'formSubmitted', function () {
			return this.get('formSubmitted') && !this.get('bank') && !this.get('creditCard');
		}),

		defaultPaymentMethod: _ember['default'].computed('creditCard', 'bank', 'selectedType', function () {
			if (this.get('selectedType')) {
				return this.get('selectedType');
			}
			if (this.get('creditCard.isActive')) {
				return 'creditCard';
			}
			if (this.get('bank.isActive')) {
				return 'bank';
			}
			return null;
		}),

		defaultPaymentMethodIsBank: _ember['default'].computed('defaultPaymentMethod', function () {
			return this.get('defaultPaymentMethod') === 'bank';
		}),

		defaultPaymentMethodIsCc: _ember['default'].computed('defaultPaymentMethod', function () {
			return this.get('defaultPaymentMethod') === 'creditCard';
		}),

		actions: {
			addCreditCard: function addCreditCard() {
				this.set('mode', 'cc');
				this.set('showZuoraPaymentMethodPopup', true);
			},

			addBankAccount: function addBankAccount() {
				this.set('mode', 'ach');
				this.set('showZuoraPaymentMethodPopup', true);
			},

			addPaymentMethod: function addPaymentMethod(info) {
				var paymentMethod = this.get('billingPaymentMethod').createPaymentMethod(info);

				if (paymentMethod.get('type') === 'cc') {
					this.set('creditCard', paymentMethod);
					this.set('selectedType', 'creditCard');
				} else if (paymentMethod.get('type') === 'ach') {
					this.set('bank', paymentMethod);
					this.set('selectedType', 'bank');
				}

				this.set('showZuoraPaymentMethodPopup', false);

				this.send('onPaymentMethodSubmit');
			},

			onPaymentMethodPopupClose: function onPaymentMethodPopupClose() {
				this.set('showZuoraPaymentMethodPopup', false);
			},

			useCreditCard: function useCreditCard() {
				this.set('selectedType', 'creditCard');
			},

			useBank: function useBank() {
				this.set('selectedType', 'bank');
			},

			onPaymentMethodSubmit: function onPaymentMethodSubmit() {
				this.sendAction('onPaymentMethodSubmit');
			}
		}
	});
});