define('component-library/utils/globals', ['exports'], function (exports) {
	/* globals zen */
	// TODO: remove dependency to client-app
	'use strict';

	var dashboard = window.dashboard;
	exports.dashboard = dashboard;

	var prettyCurrency = function prettyCurrency() {
		return window.prettyCurrency.apply(window, arguments);
	};

	exports.prettyCurrency = prettyCurrency;

	// TODO: remove dependency on static/js/app.js
	// @deprecated use getFilePickerMixin instead because this one won't work with js_static_lazy_load
	var _FilePickerMixin = zen._FilePickerMixin;
	exports._FilePickerMixin = _FilePickerMixin;

	var getFilePickerMixin = function getFilePickerMixin() {
		return zen._FilePickerMixin;
	};

	exports.getFilePickerMixin = getFilePickerMixin;

	// constants
	var AMERICAN_DATE_FORMAT = 'MM/DD/YYYY';
	exports.AMERICAN_DATE_FORMAT = AMERICAN_DATE_FORMAT;

	var DATABASE_DATE_FORMAT = 'YYYY-MM-DD';
	exports.DATABASE_DATE_FORMAT = DATABASE_DATE_FORMAT;

	var FIELD_TYPES = {
		PERCENT: 'percent',
		NUMBER: 'number',
		PHONE: 'phone',
		EIN: 'ein',
		SSN: 'ssn',
		DATE: 'date',
		MONTHYEAR: 'monthyear',
		EMAIL: 'email',
		TEXTAREA: 'text-area',
		PASSWORD: 'password',
		MONEY: 'money',
		ZIP: 'zip',
		DEFAULT: 'default'
	};
	exports.FIELD_TYPES = FIELD_TYPES;

	var STATE_ABBREV_TO_NAME = window.STATE_ABBREV_TO_NAME;
	exports.STATE_ABBREV_TO_NAME = STATE_ABBREV_TO_NAME;

	var MONTHS_ABBREVIATED = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	exports.MONTHS_ABBREVIATED = MONTHS_ABBREVIATED;

	var FILEPICKER_KEY = window.FILEPICKER_KEY;

	exports.FILEPICKER_KEY = FILEPICKER_KEY;

	// Libraries
	var $ = window.$;
	exports.$ = $;

	var Bloodhound = window.Bloodhound;
	exports.Bloodhound = Bloodhound;

	var $clamp = window.$clamp;
	exports.$clamp = $clamp;

	// @deprecated use vendor/shims/fieldkit instead
	var FieldKit = window.FieldKit;
	exports.FieldKit = FieldKit;

	var filepicker = window.filepicker;
	exports.filepicker = filepicker;

	// @deprecated use $.dropzone instead. Please note that $.dropzone returns something different
	// than new Dropzone(). The equivalent return val for `new Dropzone(element, {options})` is `$element.dropzone({options})[0].dropzone`
	// export var Dropzone = window.Dropzone;
	var moment = window.moment;
	exports.moment = moment;

	// @deprecated use vendor/shims/rangy instead.
	var rangy = window.rangy;
	exports.rangy = rangy;

	// @deprecated use vendor/shims/spark-md5 instead.
	var SparkMD5 = window.SparkMD5;
	exports.SparkMD5 = SparkMD5;

	// @deprecated use vendor/shims/squire instead.
	var Squire = window.Squire;
	exports.Squire = Squire;

	var brandName = window.BRAND_NAME;

	exports.brandName = brandName;

	var getD3 = function getD3() {
		return window.d3;
	};
	exports.getD3 = getD3;
});