define('component-library/components/read-only-agreement-modal', ['exports', 'ember', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryComponentsPromiseModal) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'components/read-only-agreement-modal',
		modalDialogClass: 'js-glue-billingFlow-termsModal z-typography',
		autoSetHeight: false,
		eventLogger: null,
		agreementPath: null
	});
});