define('component-library/components/payroll/im-pay-schedule', ['exports', 'ember', 'ui-infra/utils/sanitize-html', 'component-library/utils/globals'], function (exports, _ember, _uiInfraUtilsSanitizeHtml, _componentLibraryUtilsGlobals) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['<a href=\'https://help.zenefits.com/Payroll/Using_Zenefits_Payroll/FAQs_About_Pay_Schedules/\'>Learn more</a>'], ['<a href=\'https://help.zenefits.com/Payroll/Using_Zenefits_Payroll/FAQs_About_Pay_Schedules/\'>Learn more</a>']);

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	exports['default'] = _ember['default'].Component.extend({
		paySchedules: _ember['default'].computed(function () {
			return [];
		}),
		today: _ember['default'].computed(function () {
			return (0, _componentLibraryUtilsGlobals.moment)().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		}),
		createPayScheduleRecord: function createPayScheduleRecord() {},
		displayForm: true,
		employeePayTypeErrorText: null,
		payFrequencyErrorText: null,
		lastCheckDateErrorText: null,
		lastFullPayPeriodStartDateErrorText: null,
		lastFullPayPeriodEndDateErrorText: null,
		onlyShowSaveBtn: false,
		payFrequencyOptions: [{ id: 1, label: 'Weekly (Every Week)', value: 'Weekly' }, { id: 2, label: 'Bi-Weekly (Every Second Week)', value: 'Bi-Weekly' }, { id: 3, label: 'Semi-Monthly (Twice Each Month)', value: 'Semi-Monthly' }, { id: 4, label: 'Monthly (Every Month)', value: 'Monthly' }, { id: 4, label: 'Other', value: 'Other' }],
		payFrequencyHelpText: _ember['default'].computed(function () {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject);
		}),
		formPaySchedule: _ember['default'].computed(function () {
			return _ember['default'].Object.create({});
		}),
		init: function init() {
			this._super.apply(this, arguments);
			this.get('paySchedules').forEach(function (ps) {
				return ps.set('isEditing', false);
			});
		},

		formValuePropertyNames: _ember['default'].computed(function () {
			return ['employeePayType', 'payFrequency', 'lastFullPayPeriodStartDate', 'lastFullPayPeriodEndDate', 'lastCheckDate'];
		}),
		completedPaySchedules: _ember['default'].computed('paySchedules.@each.isEditing', 'paySchedules.@each.isDeleted', function () {
			return this.get('paySchedules').filter(function (ps) {
				return !ps.get('isEditing') && !ps.get('isDeleted');
			});
		}),

		updateLastFullPayPeriodEndDate: _ember['default'].observer('formPaySchedule.lastFullPayPeriodStartDate', 'formPaySchedule.payFrequency', function () {
			var formPaySchedule = this.get('formPaySchedule');
			var payFrequency = formPaySchedule.get('payFrequency');
			var lastFullPayPeriodStartDate = formPaySchedule.get('lastFullPayPeriodStartDate');
			if (payFrequency && lastFullPayPeriodStartDate && payFrequency !== 'Semi-Monthly') {
				var count = undefined;
				var weekOrMonth = undefined;
				var startDateObj = (0, _componentLibraryUtilsGlobals.moment)(lastFullPayPeriodStartDate);
				if (payFrequency === 'Weekly') {
					count = 1;
					weekOrMonth = 'week';
				} else if (payFrequency === 'Bi-Weekly') {
					count = 2;
					weekOrMonth = 'week';
				} else {
					count = 1;
					weekOrMonth = 'month';
				}
				var endDate = (0, _componentLibraryUtilsGlobals.moment)(startDateObj).add(count, weekOrMonth).format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				formPaySchedule.set('lastFullPayPeriodEndDate', endDate);
			}
		}),

		validateForm: function validateForm() {
			var _this = this;

			var isValid = true;
			var properties = {};
			this.get('formValuePropertyNames').forEach(function (propertyName) {
				if (!_this.get('formPaySchedule.' + propertyName)) {
					properties[propertyName + 'ErrorText'] = 'Required';
					isValid = false;
				}
			});
			this.setProperties(properties);
			return isValid;
		},

		resetForm: function resetForm() {
			var properties = {};
			this.get('formValuePropertyNames').forEach(function (propertyName) {
				properties[propertyName + 'ErrorText'] = null;
			});
			this.set('formPaySchedule', _ember['default'].Object.create({}));
			this.setProperties(properties);
		},

		savePaySchedule: function savePaySchedule() {
			var _this2 = this;

			var properties = {};
			this.get('formValuePropertyNames').forEach(function (propertyName) {
				properties[propertyName] = _this2.get('formPaySchedule.' + propertyName);
			});
			if (this.get('formPaySchedule.isEditing')) {
				this.set('formPaySchedule.isEditing', false);
				return;
			}
			if (!this.attrs.createPayScheduleRecord) {
				throw new Error('createPayScheduleRecord action is undefined');
			}
			properties.isEditing = false;
			this.attrs.createPayScheduleRecord(properties);
		},

		actions: {
			removePayScheduleForm: function removePayScheduleForm() {
				this.toggleProperty('displayForm');
			},
			addNewPaySchedule: function addNewPaySchedule() {
				if (!this.get('displayForm')) {
					this.send('removePayScheduleForm');
					return;
				}
				if (this.validateForm()) {
					// save form info
					this.savePaySchedule();
					this.resetForm();
					return;
				}
			},
			goToPreviousStep: function goToPreviousStep(laddaButton) {
				this.attrs.goToPreviousStep(laddaButton);
			},
			saveAndContinue: function saveAndContinue(laddaButton) {
				if (!this.get('displayForm')) {
					this.attrs.validateSaveAndContinue(laddaButton);
				} else if (this.validateForm()) {
					this.savePaySchedule();
					this.resetForm();
					this.attrs.validateSaveAndContinue(laddaButton);
				} else {
					laddaButton.stop();
				}
			},
			skipStep: function skipStep() {
				this.attrs.validateSaveAndContinue();
			},
			deletePaySchedule: function deletePaySchedule(paySchedule) {
				return paySchedule.deleteRecord();
			},
			editPaySchedule: function editPaySchedule(paySchedule) {
				paySchedule.set('isEditing', true);
				this.setProperties({
					formPaySchedule: paySchedule,
					displayForm: true
				});
			}
		}
	});
});