define('component-library/deductions/review/route', ['exports', 'ember', 'component-library/utils/deductions/deductions-utils'], function (exports, _ember, _componentLibraryUtilsDeductionsDeductionsUtils) {
	'use strict';

	exports['default'] = _ember['default'].Route.extend({
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			var payrollProvider = this.get('currentModel.company.payrollProvider');
			controller.set('displayNormalDeduction', !(0, _componentLibraryUtilsDeductionsDeductionsUtils.isPayrollSynableForDeduction)(payrollProvider));
			controller.set('displayNormalContribution', !(0, _componentLibraryUtilsDeductionsDeductionsUtils.isPayrollSynableForContribution)(payrollProvider));
		}
	});
});