define('component-library/components/filter-list', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['grid-block'],
		filters: _ember['default'].computed.alias('content'),

		showFilterControls: true,
		actions: {
			filterAdded: function filterAdded() {
				this.sendAction('filterAdded');
			}
		}
	});
});