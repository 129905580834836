define('component-library/mixins/benefits/timeline/transaction-fulfillment-data-mixin', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		hasFulfillmentInfoSummary: _ember['default'].computed.notEmpty('transaction.fulfillmentInfo') && _ember['default'].computed.notEmpty('transaction.fulfillmentInfo.currentStatus'),
		hasFulfillmentInfoDetails: _ember['default'].computed.notEmpty('transaction.fulfillmentInfo') && _ember['default'].computed.notEmpty('transaction.fulfillmentInfo.details'),
		fulfillmentInfoSummaryStatus: _ember['default'].computed.alias('transaction.fulfillmentInfo.currentStatus.statusSummary'),
		fulfillmentChangeLogTransactions: _ember['default'].computed.alias('transaction.fulfillmentInfo.details.changeLogTransactions'),

		fulfillmentSettlementObjectLinks: _ember['default'].computed.alias('transaction.fulfillmentInfo.details.settlementObjectLinks'),
		hasSettlementObjectLinks: _ember['default'].computed.notEmpty('transaction.fulfillmentInfo.details.settlementObjectLinks'),

		fulfillmentBundleLinks: _ember['default'].computed.alias('transaction.fulfillmentInfo.details.bundleLinks'),
		hasBundleLinks: _ember['default'].computed.notEmpty('transaction.fulfillmentInfo.details.bundleLinks')

	});
});