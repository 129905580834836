define('component-library/components/payroll/clone-pay-run-card', ['exports', 'ember', 'component-library/constants/payroll', 'component-library/components/notification-modal', 'component-library/utils/globals', 'component-library/utils/validator'], function (exports, _ember, _componentLibraryConstantsPayroll, _componentLibraryComponentsNotificationModal, _componentLibraryUtilsGlobals, _componentLibraryUtilsValidator) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		isContractorPaymentsCompany: _ember['default'].computed.oneWay('model.company.isContractorPaymentsCompany'),
		payrunHelpLabel: _ember['default'].computed(function () {
			if (this.get('isContractorPaymentsCompany')) {
				return 'This payment name will be displayed on contractor payments.';
			}
			return 'This pay run name will be displayed on worker pay stubs.';
		}),
		payRunValidationService: _ember['default'].inject.service('payroll/pay-run-validation-service'),
		payRunText: _ember['default'].computed('isContractorPaymentsCompany', function () {
			return this.get('isContractorPaymentsCompany') ? 'Payment' : 'Pay Run';
		}),
		payRunHeader: _ember['default'].computed('isContractorPaymentsCompany', function () {
			return this.get('isContractorPaymentsCompany') ? 'Payment Details' : 'Pay Run Details';
		}),
		payPeriodHeader: _ember['default'].computed('isContractorPaymentsCompany', function () {
			return this.get('isContractorPaymentsCompany') ? 'Payment Period' : 'Pay Period';
		}),
		payDateHeader: _ember['default'].computed('isContractorPaymentsCompany', function () {
			return this.get('isContractorPaymentsCompany') ? 'Payment Date' : 'Pay Date';
		}),
		regularPayRunsHeader: _ember['default'].computed('isContractorPaymentsCompany', function () {
			return this.get('isContractorPaymentsCompany') ? 'Regular Payments' : 'Regular Payruns';
		}),
		offCyclePayRunsHeader: _ember['default'].computed('isContractorPaymentsCompany', function () {
			return this.get('isContractorPaymentsCompany') ? 'Off Cycle Payments' : 'Off Cycle Runs';
		}),
		datePlaceholder: 'MM/DD/YYYY',
		selectedPayRun: null,
		notCloneablePayRunId: null,
		payrunTitleErrorText: null,
		checkDateErrorText: null,
		selectPayRunErrorText: null,
		activeTab: 'regular',
		hasValidationErrors: false,
		isSaving: false,
		checkDate: _ember['default'].computed.alias('nearestCheckDate'),
		isPayByCheckRequested: false,
		showPayByCheckRequestedModalInV2: false,
		// Mandatory pay period settings controlled by switch
		showPayPeriodSettings: _ember['default'].computed(function () {
			return this.get('switches.payroll_off_cycle_pay_period_enabled');
		}),
		payPeriodStart: null,
		payPeriodEnd: null,
		payrollRuns: _ember['default'].computed(function () {
			return [];
		}),
		cloneablePayRuns: _ember['default'].computed('payrollRuns.[]', function () {
			var notCloneablePayRunId = this.get('notCloneablePayRunId');
			return this.get('payrollRuns').filter(function (payrollRun) {
				return payrollRun.get('id') !== notCloneablePayRunId;
			});
		}),
		regularPayruns: _ember['default'].computed.rejectByProperty('cloneablePayRuns', 'payrollType', 'Off-Cycle'),
		offCyclePayruns: _ember['default'].computed.filterByProperty('cloneablePayRuns', 'payrollType', 'Off-Cycle'),
		lastFiveOffCyclePayRuns: _ember['default'].computed('offCyclePayruns', function () {
			var sortedPayRuns = this.get('offCyclePayruns').sortBy('checkDate');
			if (sortedPayRuns.length <= 5) {
				return sortedPayRuns;
			}
			return sortedPayRuns.slice(-5);
		}),
		lastFiveRegularPayRuns: _ember['default'].computed('regularPayruns', function () {
			var sortedPayRuns = this.get('regularPayruns').sortBy('checkDate');
			if (sortedPayRuns.length <= 5) {
				return sortedPayRuns;
			}
			return sortedPayRuns.slice(-5);
		}),
		payrunsToShow: _ember['default'].computed('activeTab', 'lastFiveOffCyclePayRuns', 'lastFiveRegularPayRuns', function () {
			if (this.get('activeTab') === 'regular') {
				return this.get('lastFiveRegularPayRuns');
			}
			if (this.get('activeTab') === 'offCycle') {
				return this.get('lastFiveOffCyclePayRuns');
			}
		}),

		createPayPeriod: function createPayPeriod() {
			return window.App.ZPayrollPayPeriod.createRecord({
				zpCompany: this.get('zPayrollCompany'),
				startDate: this.get('payPeriodStart'),
				endDate: this.get('payPeriodEnd')
			}).save().then(function (zpPayPeriod) {
				return { zpPayPeriod: zpPayPeriod };
			});
		},

		validatePayPeriodDate: function validatePayPeriodDate(date) {
			if (!date || date.includes("-")) {
				return false;
			}
			return _componentLibraryUtilsValidator['default'].getRegexRule('date').test(date);
		},

		validatePayPeriodDateRange: function validatePayPeriodDateRange(startDate, endDate) {
			return !(0, _componentLibraryUtilsGlobals.moment)(endDate).isBefore(startDate);
		},

		validatePayrollRun: function validatePayrollRun() {
			var _this = this;

			this.resetErrorMessages();
			var checkDate = this.get('checkDate');
			var payrunTitle = this.get('payrunTitle') ? this.get('payrunTitle').trim() : '';
			var selectedPayRun = this.get('selectedPayRun');
			var isOffCycle = true;
			var blockPeriod = this.get('zPayrollCompany.blockPeriod');
			var payRunValidationService = this.get('payRunValidationService');
			var isContractorPaymentsCompany = this.get('isContractorPaymentsCompany');

			if (_ember['default'].isEmpty(this.get('selectedPayRun'))) {
				if (isContractorPaymentsCompany) {
					this.set('selectPayRunErrorText', _componentLibraryConstantsPayroll._PAY_RUN_VALIDATIONS.selectPayRunContractorErrorText);
				} else {
					this.set('selectPayRunErrorText', _componentLibraryConstantsPayroll._PAY_RUN_VALIDATIONS.selectPayRunErrorText);
				}
				this.set('hasValidationErrors', true);
			}

			if (_ember['default'].isEmpty(payrunTitle)) {
				if (isContractorPaymentsCompany) {
					this.set('payrunTitleErrorText', _componentLibraryConstantsPayroll._PAY_RUN_VALIDATIONS.payRunNameContractorErrorText);
				} else {
					this.set('payrunTitleErrorText', _componentLibraryConstantsPayroll._PAY_RUN_VALIDATIONS.payRunNameErrorText);
				}
				this.set('hasValidationErrors', true);
			}

			if (this.get('showPayPeriodSettings')) {
				// Pay Period Validations
				if (!this.validatePayPeriodDate(this.get('payPeriodStart'))) {
					this.set('payPeriodStartErrorText', _componentLibraryConstantsPayroll._PAY_RUN_PAY_PERIOD_VALIDATIONS.invalidPayPeriodStart);
					this.set('hasValidationErrors', true);
				}
				if (!this.validatePayPeriodDate(this.get('payPeriodEnd'))) {
					this.set('payPeriodEndErrorText', _componentLibraryConstantsPayroll._PAY_RUN_PAY_PERIOD_VALIDATIONS.invalidPayPeriodEnd);
					this.set('hasValidationErrors', true);
				}
				if (!this.validatePayPeriodDateRange(this.get('payPeriodStart'), this.get('payPeriodEnd'))) {
					this.set('payPeriodEndErrorText', _componentLibraryConstantsPayroll._PAY_RUN_PAY_PERIOD_VALIDATIONS.invalidPayPeriodEndAfterStartDate);
					this.set('hasValidationErrors', true);
				}
			}

			return payRunValidationService.validateCheckDate(checkDate, blockPeriod, isOffCycle).then(function (response) {
				if (response.status === 'error') {
					_this.set('hasValidationErrors', true);
					_this.set('checkDateErrorText', response.message);
				} else if (response.status === 'warning') {
					_this.setProperties({
						isPayByCheckRequested: true,
						showPayByCheckRequestedModalInV2: true
					});
					_this.set('checkDateConfirmationText', response.message);
					return _ember['default'].RSVP.reject('checkConfirmation');
				}
				return _this.get('hasValidationErrors') ? _ember['default'].RSVP.reject('validationErrors') : _ember['default'].RSVP.resolve();
			});
		},

		resetErrorMessages: function resetErrorMessages() {
			this.setProperties({
				hasValidationErrors: false,
				selectPayRunErrorText: null,
				payrunTitleErrorText: null,
				checkDateErrorText: null,
				isPaidByCheckOnly: false,
				payPeriodStartErrorText: null,
				payPeriodEndErrorText: null
			});
		},

		createClonePayRun: function createClonePayRun(zpPayPeriod) {
			var selectedPayRun = this.get('selectedPayRun');
			var zpPayPeriodId = zpPayPeriod ? zpPayPeriod.get('id') : null;
			var param = JSON.stringify({
				description: this.get('payrunTitle'),
				checkDate: this.get('checkDate'),
				isPaidByCheckOnly: this.get('isPaidByCheckOnly'),
				zpPayPeriodId: zpPayPeriodId
			});
			return _ember['default'].ajaxPost('/custom_api/z_payroll/clone_payroll_run/' + selectedPayRun.get('id'), param);
		},

		actions: {
			onChangeTab: function onChangeTab(tabName) {
				this.set('activeTab', tabName);
			},
			onCancel: function onCancel(laddaButton) {
				this.attrs.goToPreviousStep(laddaButton);
			},
			closePayByCheckModal: function closePayByCheckModal() {
				this.toggleProperty('showPayByCheckRequestedModalInV2');
				this.set('checkDateConfirmationText', null);
				this.set('isPaidByCheckOnly', false);
			},
			selectPayRun: function selectPayRun(payRun) {
				this.set('selectedPayRun', payRun);
			},
			validateAndClonePayRun: function validateAndClonePayRun(button) {
				var _this2 = this;

				this.set('isSaving', true);
				this.validatePayrollRun().then(function () {
					return _this2.get('showPayPeriodSettings') ? _this2.createPayPeriod() : _ember['default'].RSVP.resolve(null);
				}).then(function (result) {
					var zpPayPeriod = result ? result.zpPayPeriod : null;
					return _this2.createClonePayRun(zpPayPeriod);
				}).then(function (resp) {
					_this2.attrs.onCreatePayRun(resp.clone_run_id, button);
				})['catch'](function (error) {
					if (error === 'validationErrors') {
						return;
					}
					_componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'Oops! Something went wrong.',
						message: 'We were unable to create the pay run. Please come back later.',
						buttonText: 'Close'
					});
				})['finally'](function () {
					button.stop();
					_this2.set('isSaving', false);
				});
			},

			confirmPayByCheck: function confirmPayByCheck(confirmButton) {
				var _this3 = this;

				this.set('isSaving', true);
				var payPeriodPromise = this.get('showPayPeriodSettings') ? this.createPayPeriod() : _ember['default'].RSVP.resolve(null);
				payPeriodPromise.then(function (result) {
					_this3.set('isPaidByCheckOnly', true);
					var zpPayPeriod = result ? result.zpPayPeriod : null;
					return _this3.createClonePayRun(zpPayPeriod);
				}).then(function (resp) {
					_this3.attrs.onCreatePayRun(resp.clone_run_id, confirmButton);
				})['catch'](function (error) {
					_componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'Oops! Something went wrong.',
						message: 'We were unable to create the pay run. Please come back later.',
						buttonText: 'Close'
					});
				})['finally'](function () {
					confirmButton.stop();
					_this3.set('isSaving', false);
				});
			},
			onErrorCreatingPayRun: function onErrorCreatingPayRun() {
				this.attrs.onErrorCreatingPayRun();
			}
		}
	});
});