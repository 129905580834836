define('component-library/components/z-settings-navigation-step', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-settingsWizardNavigationStep', 'u-flexContainer', 'u-bumperLeft--md'],
		classNameBindings: ['wizardStepState.isComplete:is-complete', 'wizardStepState.isAccessible::is-disabled', 'isSectionAndStepActive:is-active'],

		wizardStepState: null,

		isSectionAndStepActive: _ember['default'].computed.and('wizardStepState.section.isActive', 'wizardStepState.isActive'),

		click: function click() {
			this.attrs.click();
		}
	});
});