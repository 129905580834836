define('component-library/timeline/mixins/feed-entry', ['exports', 'ember', 'component-library/utils/computed-props/pretty-date'], function (exports, _ember, _componentLibraryUtilsComputedPropsPrettyDate) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({

		hasOneApprover: _ember['default'].computed.equal('data.approvedBy.length', 1),
		requesterIdEqualsApproverId: (function () {
			var rId = this.get('data.requestedBy.id');
			var aId = this.get('data.approvedBy.firstObject.id');
			return rId && aId && rId == aId;
		}).property('data.requestedBy.id', 'data.approvedBy.firstObject.id'),
		requesterIsApprover: _ember['default'].computed.and('hasOneApprover', 'requesterIdEqualsApproverId'),

		requesterIsYou: (function () {
			var rId = this.get('data.requestedBy.id');
			var eId = this.get('employee.id');
			return rId && eId && rId == eId;
		}).property('data.requestedBy.id', 'employee.id'),
		employeeIdEqualsApproverId: (function () {
			var eId = this.get('employee.id');
			var aId = this.get('data.approvedBy.firstObject.id');
			return eId && aId && eId == aId;
		}).property('employee.id', 'data.approvedBy.firstObject.id'),
		approverIsYou: _ember['default'].computed.and('hasOneApprover', 'employeeIdEqualsApproverId'),

		// creating requester string
		requesterName: _ember['default'].computed.alias('data.requestedBy.fullName'),
		requesterStr: (function () {
			if (this.get('requesterIsYou')) {
				return 'you';
			}
			var requesterName = this.get('requesterName');
			var isRequesterAdmin = this.get('data.isRequesterAdmin');
			var title = isRequesterAdmin ? 'Admin' : 'Manager';
			if (!requesterName) {
				return 'Unknown ' + title;
			}
			return title + ' ' + requesterName;
		}).property('requesterName', 'data.isRequesterAdmin'),
		requesterStrPossessive: (function () {
			var requesterStr = this.get('requesterStr');
			var requesterIsApprover = this.get('requesterIsApprover');
			if (this.get('requesterIsYou')) {
				if (requesterIsApprover) {
					// make reflexive
					return 'your own';
				}
				return 'your';
			}
			if (requesterIsApprover) {
				// make reflexive
				return 'their own';
			}
			return requesterStr + '\'s';
		}).property('requesterStr', 'requesterIsYou', 'requesterIsApprover'),

		// creating employee string
		companyStr: (function () {
			var companyName = this.get('data.companyName');
			return '<span class="timeline-name-highlight">' + companyName + '</span>';
		}).property('data.companyName'),
		employeeStr: (function () {
			var fullName = this.get('data.employee.fullName');
			return '<span class="timeline-name-highlight">' + fullName + '</span>';
		}).property('data.employee.fullName'),
		lineOfCoverageStr: (function () {
			var lineOfCoverage = this.get('data.lineOfCoverage');
			return '<span class="timeline-name-highlight">' + lineOfCoverage + '</span>';
		}).property('data.lineOfCoverage'),
		enrollmentTypeStr: (function () {
			var enrollmentType = this.get('data.enrollmentType');
			return '<span class="timeline-name-highlight">' + enrollmentType + '</span>';
		}).property('data.enrollmentType'),
		carrierNameStr: (function () {
			var carrierName = this.get('data.carrierName');
			return '<span class="timeline-name-highlight">' + carrierName + '</span>';
		}).property('data.carrierName'),
		planNameStr: (function () {
			var planName = this.get('data.planName');
			return '<span class="timeline-name-highlight">' + planName + '</span>';
		}).property('data.planName'),
		enrollmentEndDateStr: (function () {
			var enrollmentEndDatePretty = this.get('prettyEnrollmentEndDate');
			return '<span class="timeline-name-highlight">' + enrollmentEndDatePretty + '</span>';
		}).property('prettyEnrollmentEndDate'),
		employeeStrPossessive: (function () {
			var fullName = this.get('data.employee.fullName');
			return '<span class="timeline-name-highlight">' + fullName + '\'s</span>';
		}).property('data.employee.fullName'),

		// creating admin string
		numApprovers: _ember['default'].computed.alias('data.approvedBy.length'),
		approverNames: _ember['default'].computed.mapBy('data.approvedBy', 'fullName'),
		approverStr: (function () {
			if (this.get('approverIsYou')) {
				return 'you';
			}
			var numApprovers = this.get('numApprovers');
			var lastAdmin = this.get('approverNames.lastObject');
			var onlyAdmin = undefined;
			var firstAdmin = undefined;
			var firstAdmins = undefined;
			switch (numApprovers) {
				case 0:
					return 'Unknown Admin';
				case 1:
					onlyAdmin = this.get('approverNames.firstObject');
					return 'Admin ' + onlyAdmin;
				case 2:
					firstAdmin = this.get('approverNames.firstObject');
					return 'Admins ' + firstAdmin + ' and ' + lastAdmin;
				default:
					firstAdmins = this.get('approverNames').slice(0, -1).join(', ');
					return 'Admins ' + firstAdmins + ' and ' + lastAdmin;
			}
		}).property('numApprovers', 'approverNames.[]'),

		// creating effective date string
		isEffectiveDate: _ember['default'].computed.bool('data.effectiveDate'),
		isTerminationDate: _ember['default'].computed.bool('data.terminationDate'),
		prettyEnrollmentEndDate: (0, _componentLibraryUtilsComputedPropsPrettyDate['default'])('data.enrollmentEndDate'),
		prettyEffectiveDate: (0, _componentLibraryUtilsComputedPropsPrettyDate['default'])('data.effectiveDate'),
		prettyTerminationDate: (0, _componentLibraryUtilsComputedPropsPrettyDate['default'])('data.terminationDate'),
		effectiveDateStr: (function () {
			if (this.get('isEffectiveDate')) {
				return 'effective on ' + this.get('prettyEffectiveDate');
			}
			if (this.get('isTerminationDate')) {
				return 'effective on ' + this.get('prettyTerminationDate');
			}
			return 'effective immediately';
		}).property('isEffectiveDate', 'prettyEffectiveDate', 'isTerminationDate', 'prettyTerminationDate')
	});
});