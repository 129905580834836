define('component-library/components/toggle-button', ['exports', 'ember', 'component-library/mixins/component-layout'], function (exports, _ember, _componentLibraryMixinsComponentLayout) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsComponentLayout['default'], {
		classNames: ['z-toggleButton', 'grid-block', 'vertical'],
		classNameBindings: ['isDisabled'],
		label: null,
		description: null,
		isDisabled: false,
		isChecked: false,
		action: null,
		tooltipText: null,

		didInsertElement: function didInsertElement() {
			this._super();
			this.$('input[type="checkbox"]').prop('checked', this.get('isChecked'));
		},

		click: function click() {
			if (!this.get('isDisabled')) {
				this.set('isChecked', this.$('input[type="checkbox"]').prop('checked'));
				if (this.get('action')) {
					this.sendAction();
				}
			}
		}
	});
});