define('component-library/components/external-list-item-editable', ['exports', 'ember', 'component-library/templates/components/external-list-item-editable'], function (exports, _ember, _componentLibraryTemplatesComponentsExternalListItemEditable) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		layout: _componentLibraryTemplatesComponentsExternalListItemEditable['default'],
		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
			this.set('itemCopy', _ember['default'].copy(this.get('item')));
		},
		api: null,
		isNewItem: _ember['default'].computed.bool('item._unsaved'),
		saveCaption: _ember['default'].computed('isNewItem', function () {
			var isNewItem = this.get('isNewItem');
			return isNewItem ? "Add" : "Done";
		}),
		actions: {
			save: function save() {
				var requiredFields = this.get('requiredFields');
				if (requiredFields) {
					var requiredFieldsList = requiredFields.split(',');
					for (var i = 0; i < requiredFieldsList.length; i++) {
						var requiredField = requiredFieldsList[i];
						if (requiredField) {
							var requiredFieldKeyValue = requiredField.split(':');
							var fieldValue = this.get('item.' + requiredFieldKeyValue[0]);
							if (!fieldValue) {
								var message = requiredFieldKeyValue[1] + ' cannot be empty';
								this.set('errorText', message);
								return;
							}
						}
					}
				}
				this.set('item._unsaved', false);
				this.set('item.isExpanded', false);
			},
			cancel: function cancel() {
				var _this = this;

				var isUnsaved = this.get('item._unsaved') || false;
				var unchangedVals = this.get('itemCopy');
				if (isUnsaved) {
					if (this.api.ondelete) {
						this.api.ondelete(this.get('item'));
					}
				} else {
					Object.keys(unchangedVals).forEach(function (key) {
						if (key != '_unsaved' && key != 'isExpanded') {
							_this.set('item.' + key, unchangedVals[key]);
						}
					});
					this.set('item.isExpanded', false);
				}
			},
			remove: function remove() {
				if (this.api.ondelete) {
					this.api.ondelete(this.get('item'));
				}
			}
		}
	});
});