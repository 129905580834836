define('component-library/components/status-filter', ['exports', 'ember', 'component-library/templates/components/status-filter', 'component-library/components/promise-popover'], function (exports, _ember, _componentLibraryTemplatesComponentsStatusFilter, _componentLibraryComponentsPromisePopover) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
		/*
  	Overrides
  */

		template: _componentLibraryTemplatesComponentsStatusFilter['default'],
		hasArrow: true,
		placement: 'bottom',
		target: _ember['default'].computed.oneWay('model.target'),
		attachment: "top center",
		targetAttachment: "bottom center",

		constraints: [{
			to: 'scrollParent',
			pin: true
		}, {
			to: 'window',
			attachment: 'together'
		}],

		/*
  	Implementation
  */
		filter: _ember['default'].computed.alias('content'),

		options: _ember['default'].computed(function () {
			// Need a one-way binding by value rather than reference.
			return this.get('filter.options').map(function (option) {
				return { value: option.value, css: option.css, selected: option.selected };
			});
		}).property('filter.options.@each'),

		includeOrExclude: _ember['default'].computed.oneWay('filter.includeOrExclude'),
		isInclude: _ember['default'].computed.equal('includeOrExclude', 'include'),
		isExclude: _ember['default'].computed.equal('includeOrExclude', 'exclude'),

		addOrApply: (function () {
			return this.get('filter.isActive') ? 'Apply' : 'Add';
		}).property('filter.isActive'),

		actions: {
			toggleSelection: function toggleSelection(property) {
				this.toggleProperty(property);
			},

			addOrApplyFilter: function addOrApplyFilter() {
				// why the fuck do we need this? fucking useless abstractions
				if (!this.get('filter.isActive')) {
					this.set('filter.isActive', true);
				}

				this.set('filter.includeOrExclude', this.get('includeOrExclude'));
				this.set('filter.options', this.get('options'));

				this.send('close');
			}
		}
	});
});