define('component-library/components/confirmation-modal-with-textfield', ['exports', 'ember', 'component-library/components/confirmation-modal'], function (exports, _ember, _componentLibraryComponentsConfirmationModal) {
	'use strict';

	var sanitizeCase = function sanitizeCase(text) {
		if (!text) {
			return '';
		}
		return text.toUpperCase();
	};

	exports['default'] = _componentLibraryComponentsConfirmationModal['default'].extend({
		templateName: 'components/confirmation-modal-with-textfield',

		// support for CONFIRM text field
		confirmTextFieldMessage: 'To continue, type "CONFIRM":',
		confirmCode: 'CONFIRM',
		textFieldPlaceholder: _ember['default'].computed('confirmCode', function () {
			return 'Type "' + this.get('confirmCode') + '"';
		}),
		confirmationTextNotCorrect: _ember['default'].computed('typedConfirmationText', 'confirmCode', function () {
			var userInput = sanitizeCase(this.get('typedConfirmationText'));
			var confirmCode = sanitizeCase(this.get('confirmCode'));
			return !(userInput && userInput === confirmCode);
		}),
		disableConfirmButton: _ember['default'].computed('confirmationTextNotCorrect', function () {
			return this.get('confirmationTextNotCorrect');
		})
	});
});