define('component-library/timeline/components/deduction', ['exports', 'ember', 'component-library/timeline/components/copy', 'component-library/timeline/mixins/feed-entry', 'component-library/timeline/utils/status', 'component-library/timeline/utils/computed-copy', 'component-library/utils/tags/context-tag-computed', 'component-library/utils/pretty-currency', 'component-library/utils/deductions/deductions-utils', 'component-library/utils/computed-props/pretty-date'], function (exports, _ember, _componentLibraryTimelineComponentsCopy, _componentLibraryTimelineMixinsFeedEntry, _componentLibraryTimelineUtilsStatus, _componentLibraryTimelineUtilsComputedCopy, _componentLibraryUtilsTagsContextTagComputed, _componentLibraryUtilsPrettyCurrency, _componentLibraryUtilsDeductionsDeductionsUtils, _componentLibraryUtilsComputedPropsPrettyDate) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['', ' ', '\n\t\t\tdeduction will change on ', ' to ', ''], ['', ' ', '\n\t\t\tdeduction will change on ', ' to ', '']),
	    _templateObject2 = _taggedTemplateLiteral(['', ' ', '\n\t\t\tdeduction has been changed to ', ''], ['', ' ', '\n\t\t\tdeduction has been changed to ', '']),
	    _templateObject3 = _taggedTemplateLiteral(['', ' had a ', ' catchup\n\t\t\tdeduction of ', ''], ['', ' had a ', ' catchup\n\t\t\tdeduction of ', '']);

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	exports['default'] = _componentLibraryTimelineComponentsCopy['default'].extend(_componentLibraryTimelineMixinsFeedEntry['default'], {

		copy: _componentLibraryTimelineUtilsComputedCopy['default'].apply(undefined, _toConsumableArray(_componentLibraryTimelineUtilsStatus.deductionStatusTypes)),

		requested: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject, 'employeeStrPossessive', 'humanDeductionType', 'startDatePretty', 'deductionPretty'),

		realized: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject2, 'employeeStrPossessive', 'humanDeductionType', 'currentDeductionPretty'),

		catchup: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject3, 'employeeStr', 'humanDeductionType', 'catchupDeductionText'),

		humanDeductionType: (function () {
			var deductionTypeObj = (0, _componentLibraryUtilsDeductionsDeductionsUtils.getDeductionType)(this.get('data.deductionType'));
			return _ember['default'].getWithDefault(deductionTypeObj, 'label', 'unknown').toLowerCase();
		}).property('data.deductionType'),

		deductionPretty: (function () {
			return (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('data.deductionData.deduction'));
		}).property('data.deductionData.deduction'),

		currentDeductionPretty: (function () {
			return (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('data.deductionData.currentDeduction'));
		}).property('data.deductionData.currentDeduction'),

		currentContributionPretty: (function () {
			return (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('data.deductionData.currentContribution'));
		}).property('data.deductionData.currentContribution'),

		catchupText: function catchupText(current, normal) {
			if (current > normal) {
				return (0, _componentLibraryUtilsPrettyCurrency['default'])(current - normal) + ' more than normal';
			} else if (normal > current) {
				return (0, _componentLibraryUtilsPrettyCurrency['default'])(normal - current) + ' less than normal';
			} else {
				return 'the normal amount';
			}
		},

		catchupDeductionText: (function () {
			return this.catchupText(this.get('data.deductionData.currentDeduction'), this.get('data.deductionData.normalDeduction'));
		}).property('data.deductionData.currentDeduction', 'data.deductionData.normalDeduction'),

		startDatePretty: (0, _componentLibraryUtilsComputedPropsPrettyDate['default'])('data.deductionData.effectiveStartDate')

	});
});