define('component-library/components/d-inline-radio', ['exports', 'ember', 'component-library/components/z-inline-base'], function (exports, _ember, _componentLibraryComponentsZInlineBase) {
	'use strict';

	var MAX_RADIO_OPTIONS = 5;

	exports['default'] = _componentLibraryComponentsZInlineBase['default'].extend({
		init: function init() {
			this._super();
			_ember['default'].deprecate('this inline component is deprecated', false, { id: 'inline-component-deprecation' });
		},

		propertyName: null,
		customDisplayMap: null,
		displayMapName: 'booleanToBooleanStringMap',
		hideLabel: false,

		// INTERNAL PARAMETERS
		classNames: ['js-glue-zInlineRadio'],
		propertyNameObjects: [{ internalProperty: 'value', modelPropertyName: 'propertyName' }],
		// value set on init hook according to propertyNameObjects
		value: null,

		displayMap: _ember['default'].computed('customDisplayMap', 'displayMapName', function () {
			var customDisplayMap = this.get('customDisplayMap');
			if (customDisplayMap) {
				return customDisplayMap;
			} else {
				var displayMapName = this.get('displayMapName');
				return this.get(displayMapName);
			}
		}),
		_getDisplayValue: function _getDisplayValue(displayProperty) {
			var displayMap = this.get('displayMap');
			if (_ember['default'].isNone(displayProperty)) {
				return "";
			}
			return displayMap[displayProperty];
		},
		displayValue: _ember['default'].computed('value', function () {
			var value = this.get('value');
			return this._getDisplayValue(value);
		}),

		isMoreThanTwoRadioButtons: _ember['default'].computed('displayMap', function () {
			var displayMap = this.get('displayMap');
			return displayMap && Object.keys(displayMap).length > 2;
		}),

		radioButtonObjects: _ember['default'].computed('propertyName', 'displayMap', function () {
			var _this = this;

			var uniqueModelPropertyName = this.get('propertyName');
			var displayMap = this.get('displayMap');
			var valuesArray = Object.keys(displayMap);

			if (valuesArray.length > MAX_RADIO_OPTIONS) {
				throw new Error('d-inline-radio should only be used with ' + MAX_RADIO_OPTIONS + ' or fewer options.');
			}

			return valuesArray.map(function (value) {
				// we must convert the 'true', 'false' strings to their boolean equivalents
				if (['true', 'false'].contains(value)) {
					value = value == 'true';
				}
				return {
					name: uniqueModelPropertyName, // a string unique to this component
					value: value, // the model value
					label: _this._getDisplayValue(value) };
			});
		}),

		// the visual representation of the value
		// Internal Display Maps
		booleanToBooleanStringMap: {
			'true': 'True',
			'false': 'False'
		},
		booleanToYesNoMap: {
			'true': 'Yes',
			'false': 'No'
		},
		binaryGenderMap: {
			M: 'Male',
			F: 'Female'
		}
	});
});