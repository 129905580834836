define('component-library/constants/z-payroll/employment-types', ['exports'], function (exports) {
	'use strict';

	var EMPLOYMENT_TYPES = {
		FULL_TIME: 'FT',
		PART_TIME: 'PT',
		INTERN: 'IN',
		TEMP_INTERN: 'TP',
		CASUAL: 'CA',
		CONTRACT: 'CO',
		LABOUR_HIRE: 'LH'
	};

	exports.EMPLOYMENT_TYPES = EMPLOYMENT_TYPES;

	var EMPLOYMENT_SUB_TYPES = {
		FULL_TIME: 'FT',
		PART_TIME: 'PT',
		INTERN: 'IN',
		TEMP_INTERN: 'TP',
		CASUAL: 'CA',
		LABOUR_HIRE: 'LH',
		AGENCY_TEMPORARY_WORKER: 'AW',
		COMPANY_PAID_TEMPORARY_WORKER: 'CW',
		VENDOR_EMPLOYEE: 'VE',
		INDEPENDENT_CONTRACTOR: 'IC',
		VOLUNTEER: 'VO'
	};

	exports.EMPLOYMENT_SUB_TYPES = EMPLOYMENT_SUB_TYPES;

	var EMPLOYMENT_SUB_TYPES_CONTRACTORS = new Set([EMPLOYMENT_SUB_TYPES.INDEPENDENT_CONTRACTOR]);

	exports.EMPLOYMENT_SUB_TYPES_CONTRACTORS = EMPLOYMENT_SUB_TYPES_CONTRACTORS;

	var EMPLOYMENT_SUB_TYPES_EMPLOYEES = new Set([EMPLOYMENT_SUB_TYPES.FULL_TIME, EMPLOYMENT_SUB_TYPES.PART_TIME, EMPLOYMENT_SUB_TYPES.INTERN, EMPLOYMENT_SUB_TYPES.TEMP_INTERN, EMPLOYMENT_SUB_TYPES.CASUAL, EMPLOYMENT_SUB_TYPES.LABOUR_HIRE, EMPLOYMENT_SUB_TYPES.COMPANY_PAID_TEMPORARY_WORKER]);

	exports.EMPLOYMENT_SUB_TYPES_EMPLOYEES = EMPLOYMENT_SUB_TYPES_EMPLOYEES;

	var WORK_TYPE_HUMAN_READABLE = {
		FTE: 'Full Time Employee',
		PTE: 'Part Time Employee',
		IN: 'Intern',
		AT: 'Agency-paid Temp',
		TPW: 'Company-paid Temp',
		VE: 'Vendor Employee',
		IC: 'Independent Contractor',
		VO: 'Volunteer',
		CO: 'Contingent Worker'
	};

	exports.WORK_TYPE_HUMAN_READABLE = WORK_TYPE_HUMAN_READABLE;

	var COMP_TYPES = {
		SALARY: 'S',
		HOURLY: 'H',
		FIXED_OR_ADHOC: 'A',
		NOT_APPLICABLE: 'N'
	};

	exports.COMP_TYPES = COMP_TYPES;

	// Only Salary, Hourly and Adhoc(aka Fixed) are the supported comp types for Pay Schedule
	var PAY_SCHEDULE_SUPPORTED_COMP_TYPES = [COMP_TYPES.SALARY, COMP_TYPES.HOURLY, COMP_TYPES.FIXED_OR_ADHOC];
	exports.PAY_SCHEDULE_SUPPORTED_COMP_TYPES = PAY_SCHEDULE_SUPPORTED_COMP_TYPES;
});