define('component-library/components/external-yes-no', ['exports', 'ember', 'component-library/templates/components/external-yes-no'], function (exports, _ember, _componentLibraryTemplatesComponentsExternalYesNo) {
	'use strict';

	var getUUID = function getUUID() {
		return 'xxxxxxxxxxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 | 0,
			    v = c == 'x' ? r : r & 0x3 | 0x8;
			return v.toString(16);
		}).replace('-', '');
	};

	exports['default'] = _ember['default'].Component.extend({
		layout: _componentLibraryTemplatesComponentsExternalYesNo['default'],
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			this.set('radioGroup', getUUID());
		}
	});
});