define('component-library/mixins/zen-select', ['exports', 'ember'], function (exports, _ember) {
	/* globals $ */
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		tabKeyDown: false,
		backspaceDown: false,
		isZenSelect: false,
		isZenMultiSelect: false,
		isTextFilter: false,
		text: '',
		enter: function enter() {
			// TODO(ed): Refactor a keyUpHelper that only takes the keyCode
			this.keyUp({ which: $.ui.keyCode.ENTER });
		},
		escapePress: function escapePress() {
			this.keyUp({ which: $.ui.keyCode.ESCAPE });
		},
		keyUp: function keyUp(e) {
			var items;
			var pos;
			switch (e.which) {
				case $.ui.keyCode.DOWN:
					// e.preventDefault();
					if (this.get('tooManyItems')) {
						return;
					}
					items = this.get('dropdownItems');
					pos = items.indexOf(this.get('activeItem'));
					return this.set('activeItem', items[(pos + 1) % items.length]);
				case $.ui.keyCode.UP:
					// e.preventDefault();
					if (this.get('tooManyItems')) {
						return;
					}
					items = this.get('dropdownItems');
					pos = items.indexOf(this.get('activeItem'));
					return this.set('activeItem', items[(pos < 1 ? items.length : pos) - 1]);
				case $.ui.keyCode.BACKSPACE:
					if (!this.get('text') && this.get('backspaceDown')) {
						this.removeLastItem();
					}
					return this.set('backspaceDown', false);
				case $.ui.keyCode.ENTER:
					if (this.get('activeItem')) {
						this.selectItem(this.get('activeItem'));
					}
					return;
				case $.ui.keyCode.TAB:
					if (this.get('activeItem') && this.get('tabKeyDown')) {
						this.selectItem(this.get('activeItem'));
					}
					this.set('tabKeyDown', false);
					return;
				case $.ui.keyCode.ESCAPE:
					return this.set('activeItem', null);
				default:
					return;
			}
		},
		keyDown: function keyDown(e) {
			switch (e.which) {
				case $.ui.keyCode.DOWN:
				case $.ui.keyCode.UP:
					return e.preventDefault();
				case $.ui.keyCode.TAB:
					if (this.get('activeItem') && !e.shiftKey && !this.get('selectedItem')) {
						e.preventDefault();
						this.set('tabKeyDown', true);
					}return;
				case $.ui.keyCode.BACKSPACE:
					if (!this.get('text') && this.get('isZenMultiSelect')) {
						e.preventDefault();
						this.set('backspaceDown', true);
					}return;
				default:
					return;
			}
		},
		matchedItems: (function () {
			if (this.get('text') == null) {
				return [];
			}
			var lcText = this.get('text').toLowerCase();
			return this.get('notSelectedItems').filter(function (item) {
				var displayName = item.get('displayName');
				return displayName != null && displayName.toLowerCase() == lcText;
			});
		}).property('text', 'notSelectedItems.length', 'notSelectedItems.@each.displayName'),
		dropdownItems: (function () {
			this.set('activeItem', null);
			if (this.get('text') == null) {
				return [];
			}
			var lcText = this.get('text').toLowerCase();
			return this.get('notSelectedItems').filter(function (item) {
				var displayName = item.get('displayName');
				return displayName != null && displayName.toLowerCase().indexOf(lcText) > -1;
			});
		}).property('text', 'notSelectedItems.length', 'notSelectedItems.@each.displayName'),
		tooManyItems: (function () {
			return this.get('dropdownItems.length') > 50;
		}).property('dropdownItems.length'),
		didInsertElement: function didInsertElement() {
			this._super();
			var inputField = this.$().find('input');
			if (!this.get('isZenMultiSelect')) {
				inputField.css('padding-right', inputField.height());
			}
			if (this.get('isTextFilter')) {
				return;
			}

			var _this = this;
			this.inFocus = function (e) {
				if (_this.get('text.length') > 0) {
					setTimeout(function (e) {
						inputField.select();
					}, 10);
				}
				if (!_this.get('isZenSelect')) {
					inputField.parent().addClass('border-active');
				}
			};
			this.outFocus = function (e) {
				setTimeout(function (e) {
					if (_this.get('isDestroying') || _this.get('isDestroyed')) {
						return;
					}
					if (!_this.get('selectedItem')) {
						_this.set('text', '');
					}
					if (!_this.get('isZenSelect')) {
						inputField.parent().removeClass('border-active');
					}
				}, 150);
			};

			inputField[0].addEventListener('focus', this.inFocus, true);
			inputField[0].addEventListener('blur', this.outFocus, true);
			// Observers will not fire on a computed property until after the property is computed at least once.
			this.get('matchedItems');
		},
		willDestroyElement: function willDestroyElement() {
			if (this.get('isTextFilter')) {
				return;
			}
			var inputField = this.$().find('input');
			inputField[0].removeEventListener('focus', this.inFocus, true);
			inputField[0].removeEventListener('blur', this.outFocus, true);
		}
	});
});