define('component-library/filters/status-filter-spec', ['exports', 'ember', 'component-library/filters/abstract-filter-spec', 'component-library/components/status-filter'], function (exports, _ember, _componentLibraryFiltersAbstractFilterSpec, _componentLibraryComponentsStatusFilter) {
	'use strict';

	exports['default'] = _componentLibraryFiltersAbstractFilterSpec['default'].extend({
		/*
  	Overrides
  */
		isSerializable: true,
		popoverClass: _componentLibraryComponentsStatusFilter['default'],
		allSerializedSelections: {},

		description: _ember['default'].computed(function () {
			var ret = '';

			var includeOrExclude = this.get('includeOrExclude');

			if (includeOrExclude == 'include') {
				ret += 'Showing: ';
			} else {
				ret += 'Hiding: ';
			}

			var selections = this.get('options').filter(function (option) {
				return option.selected;
			}).map(function (option) {
				return option.value;
			});

			return ret + (selections.join(', ') || 'nothing');
		}).property('options.@each.selected'),

		applyFunc: _ember['default'].computed(function () {
			var isInclude = this.get('includeOrExclude') == 'include';

			var selectedVals = {};
			this.get('options').filter(function (option) {
				return option.selected;
			}).forEach(function (option) {
				selectedVals[option.value] = true;
			});

			var valuePath = this.get('valuePath');

			return function (x) {
				var ret = selectedVals[x.get(valuePath)];

				return isInclude ? ret : !ret;
			};
		}).property('options.@each.selected', 'includeOrExclude'),

		includeOrExclude: 'include',

		serialize: function serialize() {
			return this.get('options').reduce(function (values, option) {
				if (option.selected) {
					values.addObject("o" + option.value);
				}
				return values;
			}, []).addObject(this.get('includeOrExclude'));
		},

		deserialize: function deserialize(serializedSelections) {
			var serializedOptions = this.get('options').filter(function (option) {
				return serializedSelections.contains("o" + option.value);
			});
			var includeOrExclude = serializedSelections.contains("include");
			var ret = {
				'options': serializedOptions,
				'includeOrExclude': includeOrExclude ? 'include' : 'exclude'
			};
			return ret;
		},

		updateSelection: (function () {
			var allSerializedSelections = this.get('allSerializedSelections');
			var filterId = this.get('filterId');
			if (allSerializedSelections.hasOwnProperty(filterId)) {
				var serializedSelections = allSerializedSelections[filterId];
				var selection = this.deserialize(serializedSelections);
				selection.options.forEach(function (option) {
					_ember['default'].set(option, 'selected', true);
				});
				this.set('includeOrExclude', selection.includeOrExclude);
				this.set('isActive', true);
			}
		}).observes('allSerializedSelections')
	});
});