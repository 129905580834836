define('component-library/components/payroll/state-taxes/ma-pfml-tax', ['exports', 'ember', 'component-library/mixins/payroll/state-tax-mixin', 'component-library/mixins/payroll/tax-header-mixin'], function (exports, _ember, _componentLibraryMixinsPayrollStateTaxMixin, _componentLibraryMixinsPayrollTaxHeaderMixin) {
    'use strict';

    var MEDICAL_ER_SUBJECT_MINIMUM = 60;
    var MEDICAL_ER_SUBJECT_PERCENT = 0.4;
    var EXEMPT_BOTH = 'BO';
    var EXEMPT_FAMILY = 'FA';
    var EXEMPT_MEDICAL = 'ME';
    var EXEMPT_NEITHER = 'NA';

    exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsPayrollStateTaxMixin['default'], _componentLibraryMixinsPayrollTaxHeaderMixin['default'], {
        maPfmlPopulated: _ember['default'].computed('exemptionQuestion.answer', function () {
            var exemptionQuestion = this.get('exemptionQuestion');
            if (exemptionQuestion.answer) {
                return true;
            }
            return false;
        }),

        exemptionQuestion: _ember['default'].computed('questions', function () {
            var questions = this.get('questions');
            questions.forEach(function (question) {
                _ember['default'].set(question, 'hasError', false);
            });
            var exemptionQuestion = questions.find(function (question) {
                return question.storageKey === 'pfmlExemption';
            });
            return exemptionQuestion;
        }),

        exemptionDisplayed: _ember['default'].computed('isEditing', 'exemptionQuestion.answer', function () {
            var exemptionQuestion = this.get('exemptionQuestion');
            if (exemptionQuestion.answer) {
                if (exemptionQuestion.answer === EXEMPT_BOTH) {
                    this.get('questions').forEach(function (question) {
                        question.isRequired = false;
                    });
                } else if (exemptionQuestion.answer === EXEMPT_MEDICAL) {
                    this.get('questions').forEach(function (question) {
                        if (question.storageKey === 'pmlERSubject') {
                            question.isRequired = false;
                        }
                    });
                }
            }
            if (this.get('isEditing') || this.get('maPfmlPopulated')) {
                return true;
            }
            return false;
        }),

        exemptionDisplayAnswerName: _ember['default'].computed('exemptionQuestion.answer', function () {
            var exemptionQuestion = this.get('exemptionQuestion');
            if (exemptionQuestion.answer) {
                var exemptionAnswer = exemptionQuestion.dropdownContent.find(function (content) {
                    return content.value === exemptionQuestion.answer;
                });
                return exemptionAnswer ? exemptionAnswer['name'] : null;
            } else {
                return null;
            }
        }),

        contractorsSubjectQuestion: _ember['default'].computed('questions', function () {
            var questions = this.get('questions');
            return questions.find(function (question) {
                return question.storageKey === 'pfmlContractorsSubject';
            });
        }),

        contractorsSubjectDisplayed: _ember['default'].computed('exemptionQuestion.answer', function () {
            var contractorsSubjectQuestion = this.get('contractorsSubjectQuestion');
            var exemptionAnswer = this.get('exemptionQuestion').answer;
            if (exemptionAnswer !== EXEMPT_BOTH && exemptionAnswer !== null) {
                // TODO: This should set to default value from Rules engine, but no easy to way to convert string from Excel to boolean
                if (this.get('contractorsSubjectQuestion').answer !== true) {
                    _ember['default'].set(contractorsSubjectQuestion, 'answer', false);
                }
                return true;
            } else {
                _ember['default'].set(contractorsSubjectQuestion, 'answer', null);
                return false;
            }
        }),

        pmlSubjectQuestion: _ember['default'].computed('questions', function () {
            var questions = this.get('questions');
            return questions.find(function (question) {
                return question.storageKey === 'pmlERSubject';
            });
        }),

        pmlSubjectDisplayed: _ember['default'].computed('exemptionQuestion.answer', function () {
            var pmlSubjectQuestion = this.get('pmlSubjectQuestion');
            if ([EXEMPT_NEITHER, EXEMPT_FAMILY].includes(this.get('exemptionQuestion').answer)) {
                // TODO: This should set to default value from Rules engine, but no easy to way to convert string from Excel to boolean
                if (this.get('pmlSubjectQuestion').answer !== true) {
                    _ember['default'].set(pmlSubjectQuestion, 'answer', false);
                }
                return true;
            } else {
                _ember['default'].set(pmlSubjectQuestion, 'answer', null);
                return false;
            }
        }),

        pmlCoverageQuestion: _ember['default'].computed('questions', function () {
            var questions = this.get('questions');
            return questions.find(function (question) {
                return question.storageKey === 'pmlERCoverage';
            });
        }),

        pflCoverageQuestion: _ember['default'].computed('questions', function () {
            var questions = this.get('questions');
            return questions.find(function (question) {
                return question.storageKey === 'pflERCoverage';
            });
        }),

        taxIdQuestion: _ember['default'].computed('questions', function () {
            var questions = this.get('questions');
            return questions.find(function (question) {
                return question.storageKey === 'taxId';
            });
        }),

        // Medical and family leave coverage and tax id fields will all reset and be hidden if user reverts exemption choice
        pmlCoverageDisplayed: _ember['default'].computed('exemptionQuestion.answer', function () {
            if ([EXEMPT_NEITHER, EXEMPT_FAMILY].includes(this.get('exemptionQuestion').answer)) {
                return true;
            } else {
                var pmlCoverageQuestion = this.get('pmlCoverageQuestion');
                _ember['default'].set(pmlCoverageQuestion, 'answer', pmlCoverageQuestion.defaultAttributeValue);
                return false;
            }
        }),

        pflCoverageDisplayed: _ember['default'].computed('exemptionQuestion.answer', function () {
            if ([EXEMPT_NEITHER, EXEMPT_MEDICAL].includes(this.get('exemptionQuestion').answer)) {
                return true;
            } else {
                var pflCoverageQuestion = this.get('pflCoverageQuestion');
                _ember['default'].set(pflCoverageQuestion, 'answer', pflCoverageQuestion.defaultAttributeValue);
                return false;
            }
        }),

        taxIdDisplayed: _ember['default'].computed('exemptionQuestion.answer', function () {
            var exemptionAnswer = this.get('exemptionQuestion').answer;
            if (exemptionAnswer !== EXEMPT_BOTH && exemptionAnswer !== null) {
                return true;
            } else {
                var taxIdQuestion = this.get('taxIdQuestion');
                _ember['default'].set(taxIdQuestion, 'answer', taxIdQuestion.defaultAttributeValue);
                return false;
            }
        }),

        //Used to calculate the total Medical Leave tax employer will cover in real time
        pmlERSubjectContribution: _ember['default'].computed('pmlCoverageQuestion.answer', 'pmlSubjectQuestion.answer', function () {
            var pmlCoverageAnswer = this.get('pmlCoverageQuestion').answer;
            if (this.get('pmlSubjectQuestion').answer) {
                // If employer is subject to Medical Leave tax, then employer must cover at least the minimum (60% of the tax), and can choose to cover any portion of the remaining 40%
                return pmlCoverageAnswer * MEDICAL_ER_SUBJECT_PERCENT + MEDICAL_ER_SUBJECT_MINIMUM;
            } else {
                return pmlCoverageAnswer;
            }
        }),

        actions: {
            toggleEditAndValidateTaxRates: function toggleEditAndValidateTaxRates() {
                this.attrs.toggleEditAndValidateTaxRates();
            },

            updateLevelOfService: function updateLevelOfService() {
                this.attrs.updateLevelOfService();
            },

            viewHistoricalAgencyInfo: function viewHistoricalAgencyInfo(agencyInfoKey, agencyInfoKeyName) {
                this.attrs.viewHistoricalAgencyInfo(agencyInfoKey, agencyInfoKeyName);
            },

            updateAnswerEffective: function updateAnswerEffective(question) {
                this.attrs.updateAnswerEffective(question);
            }
        }
    });
});