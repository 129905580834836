define('component-library/data-table/cells/editable-table-cell', ['exports', 'ember', 'ember-table/views/table-cell'], function (exports, _ember, _emberTableViewsTableCell) {
	'use strict';

	exports['default'] = _emberTableViewsTableCell['default'].extend({
		hasError: false, // this can be overridden on extension for cell-level error binding and handling

		templateName: 'data-table/cells/editable-table-cell',
		classNames: ['editableTableCell', 'js-glue-editableTableCell'],
		hasFocus: _ember['default'].computed.alias('row._hasFocus'),
		init: function init() {
			this._super();

			// defaultEmptyCellValue is normally '-', we want '' because editableTableCell uses inputs
			this.set('column.defaultEmptyCellValue', '');

			// set the _hasFocus property on the row model if model exists
			var row = this.get('row');
			if (row && row.set) {
				row.set('_hasFocus', false);
			}
		},

		// built-in, component-level interaction methods
		focusIn: function focusIn() {
			this.set('hasFocus', true);
		},
		focusOut: function focusOut() {
			this.set('hasFocus', false);
		}
	});
});