define('component-library/constants/z-payroll/z-pay-types', ['exports'], function (exports) {
	'use strict';

	var PAY_TYPES = {
		EARNINGS: 'earnings',
		DEDUCTIONS: 'deductions',
		GARNISHMENTS: 'garnishments',
		REIMBURSEMENTS: 'reimbursements',
		EMPLOYER_CONTRIBUTIONS: 'employer_contributions',
		EMPLOYEE_TAXES: 'employee_taxes',
		EMPLOYER_TAXES: 'employer_taxes'
	};

	exports.PAY_TYPES = PAY_TYPES;

	var EMPLOYMENT_TYPE_TO_PAY_TYPES = {
		// If the employment type is unknown, allow all DEFAULT types.
		DEFAULT: new Set([PAY_TYPES.EARNINGS, PAY_TYPES.DEDUCTIONS, PAY_TYPES.GARNISHMENTS, PAY_TYPES.REIMBURSEMENTS, PAY_TYPES.EMPLOYER_CONTRIBUTIONS, PAY_TYPES.EMPLOYEE_TAXES, PAY_TYPES.EMPLOYER_TAXES]),
		CONTRACTOR: new Set([PAY_TYPES.EARNINGS, PAY_TYPES.REIMBURSEMENTS, PAY_TYPES.DEDUCTIONS, PAY_TYPES.EMPLOYER_CONTRIBUTIONS, PAY_TYPES.EMPLOYEE_TAXES, PAY_TYPES.EMPLOYER_TAXES])
	};
	exports.EMPLOYMENT_TYPE_TO_PAY_TYPES = EMPLOYMENT_TYPE_TO_PAY_TYPES;
});