define('component-library/components/reports/timeattendance/choose-pay-period-or-date-range', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		maxDate: (0, _componentLibraryUtilsGlobals.moment)().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
		startDate: null,
		endDate: null,
		payPeriodOptions: [],
		payPeriodSelection: null,
		isDateRangeValid: true,
		isByPayPeriod: true,
		includeContingentWorkers: false,

		showsError: _ember['default'].computed.or('showDateRangeError', 'showInvalidDateError'),

		showDateRangeError: _ember['default'].computed('startDate', 'endDate', function () {
			if (!this.get('startDate') || !this.get('endDate')) {
				return false;
			}
			var startDate = (0, _componentLibraryUtilsGlobals.moment)(this.get('startDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT, true);
			var endDate = (0, _componentLibraryUtilsGlobals.moment)(this.get('endDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT, true);

			return startDate > endDate;
		}),

		showInvalidDateError: _ember['default'].computed('startDate', 'endDate', function () {
			if (this.get('startDate') && this.get('endDate')) {
				var startDate = (0, _componentLibraryUtilsGlobals.moment)(this.get('startDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT, true);
				var endDate = (0, _componentLibraryUtilsGlobals.moment)(this.get('endDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT, true);
				return startDate && !startDate.isValid() || endDate && !endDate.isValid();
			}
			return false;
		}),

		dateRangeObserver: _ember['default'].observer('isByPayPeriod', 'payPeriodSelection', 'showsError', function () {
			if (this.get('isByPayPeriod')) {
				this.set('isDateValid', !_ember['default'].isEmpty(this.get('payPeriodSelection')));
			} else {
				var dateRangeExists = this.get('startDate') && this.get('endDate');
				this.set('isDateValid', dateRangeExists && !this.get('showsError'));
			}
		}),

		isByPayPeriodObserver: _ember['default'].observer('isByPayPeriod', function () {
			this.clearData();
		}),

		clearData: function clearData() {
			this.setProperties({
				startDate: null,
				endDate: null,
				payPeriodSelection: null
			});
			this.onToggleDateFormat();
		}
	});
});