define('component-library/constants/z-spreadsheet-schema', ['exports', 'ember', 'component-library/utils/globals', 'lodash', 'component-library/constants/census-constants', 'component-library/utils/z-spreadsheet/validators', 'component-library/constants/z-spreadsheet-employee', 'component-library/utils/z-spreadsheet/parsers'], function (exports, _ember, _componentLibraryUtilsGlobals, _lodash, _componentLibraryConstantsCensusConstants, _componentLibraryUtilsZSpreadsheetValidators, _componentLibraryConstantsZSpreadsheetEmployee, _componentLibraryUtilsZSpreadsheetParsers) {
	'use strict';

	var modelPropToRow = function modelPropToRow(prop) {
		return function (record) {
			return record.data[prop];
		};
	};

	var modelPropFromRow = function modelPropFromRow(prop) {
		return function (rowObj, record) {
			record.data = record.data || {};
			record.data[prop] = rowObj[prop];
			return record;
		};
	};

	// this deletedEmployeeValidator is to skip validations for PII fields for deleted Employees for ccpa
	var deletedEmployeeValidator = function deletedEmployeeValidator(prop) {
		return function (record) {
			if (record && record.data && record.data['isDeletedEmployee']) {
				return null;
			} else {
				return prop;
			}
		};
	};

	var modelPropFromRowOptional = function modelPropFromRowOptional(prop) {
		return function (rowObj, record) {
			record.data = record.data || {};
			record.data[prop] = rowObj[prop] || '';
			return record;
		};
	};

	var initRecordDataObject = function initRecordDataObject(record) {
		record.data = record.data || {};
	};

	var fromRowNoop = function fromRowNoop(rowObj, record) {
		return record;
	};

	var ALL_WORKERS_TYPE_LABELS = [_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYMENT_TYPE_LABELS.FT, _componentLibraryConstantsZSpreadsheetEmployee.EMPLOYMENT_TYPE_LABELS.PT, _componentLibraryConstantsZSpreadsheetEmployee.EMPLOYMENT_TYPE_LABELS.TP, _componentLibraryConstantsZSpreadsheetEmployee.CONTRACTOR_TYPE_LABELS.AW, _componentLibraryConstantsZSpreadsheetEmployee.CONTRACTOR_TYPE_LABELS.CW, _componentLibraryConstantsZSpreadsheetEmployee.CONTRACTOR_TYPE_LABELS.IC, _componentLibraryConstantsZSpreadsheetEmployee.CONTRACTOR_TYPE_LABELS.VE, _componentLibraryConstantsZSpreadsheetEmployee.CONTRACTOR_TYPE_LABELS.VO];

	// Todo(DaveTian): Move all these hardcode value to dynamic generate.
	var EMPLOYEE_CENSUS_FIELD_SCHEMA = {
		'id': {
			colHeader: 'Zenefits ID',
			modelProp: 'id',
			type: 'text',
			trimWhitespace: true,
			readOnly: true,
			toRow: function toRow(record) {
				if (!record.realId) {
					return "";
				}

				var label = [];
				var data = record.data;

				var hasFirstName = !_ember['default'].isBlank(data.first_name);
				var hasLastName = !_ember['default'].isBlank(data.last_name);
				var name = hasFirstName ? data.first_name : '';
				if (hasFirstName && hasLastName) {
					name += ' ';
				}
				name += hasLastName ? data.last_name : '';
				return (record.displayId || '') + ' (' + name + ')';
			},
			fromRow: fromRowNoop
		},
		'first_name': {
			colHeader: 'First Name',
			modelProp: 'first_name',
			helperText: 'Please enter the employee\'s legal first name.',
			type: 'text',
			trimWhitespace: true,
			advanceValidator: deletedEmployeeValidator(_componentLibraryUtilsZSpreadsheetValidators.firstNameValidator),
			validationMessage: 'First name is a required field.',
			toRow: modelPropToRow('first_name'),
			fromRow: modelPropFromRow('first_name')
		},
		'last_name': {
			colHeader: 'Last Name',
			modelProp: 'last_name',
			helperText: 'Please enter the employee\'s legal last name.',
			type: 'text',
			trimWhitespace: true,
			advanceValidator: deletedEmployeeValidator(_componentLibraryUtilsZSpreadsheetValidators.lastNameValidator),
			validationMessage: 'Last name is a required field.',
			toRow: modelPropToRow('last_name'),
			fromRow: modelPropFromRow('last_name')
		},
		'dob': {
			colHeader: 'Date of Birth',
			modelProp: 'dob',
			helperText: 'The employee\'s date of birth in MM/DD/YYYY format. (e.g. 10/25/1980)',
			type: 'date',
			dateFormat: 'MM/DD/YYYY',
			parser: _componentLibraryUtilsZSpreadsheetParsers.dateParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.dateOfBirthValidator,
			toRow: function toRow(record) {
				return (0, _componentLibraryUtilsZSpreadsheetParsers.dateParser)(record.data.dob);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.dob = rowObj.dob ? (0, _componentLibraryUtilsZSpreadsheetParsers.dateParser)(rowObj.dob, _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT) : '';
				return record;
			},
			validationMessage: 'Please enter a valid birth date.'
		},
		'email': {
			colHeader: 'Email',
			modelProp: 'email',
			helperText: 'Please enter the employee\'s email address, preferably the corporate email.',
			type: 'text',
			trimWhitespace: true,
			advanceValidator: deletedEmployeeValidator(_componentLibraryUtilsZSpreadsheetValidators.emailValidator),
			validationMessage: 'Please enter a properly formatted email address.',
			toRow: modelPropToRow('email'),
			fromRow: modelPropFromRow('email')
		},
		'ssn': {
			colHeader: 'SSN',
			modelProp: 'ssn',
			helperText: 'The employee\'s 9-digit Social Security number, with dashes.',
			type: 'text',
			trimWhitespace: true,
			parser: _componentLibraryUtilsZSpreadsheetParsers.ssnParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.ssnValidator,
			toRow: function toRow(record) {
				return (0, _componentLibraryUtilsZSpreadsheetParsers.ssnParser)(record.data.ssn);
			},
			fromRow: function fromRow(rowObj, record) {
				var ssnValue = rowObj.ssn;
				record.data = record.data || {};
				record.data.ssn = ssnValue ? ssnValue.replace(/-/g, '') : '';
				return record;
			},
			validationMessage: 'Please enter a valid SSN'
		},
		'hire_date': {
			colHeader: 'Hire Date',
			modelProp: 'hire_date',
			helperText: 'The official start date of the employee, usually found on offer letters, payrolls, eligibility documents, etc.',
			type: 'date',
			dateFormat: 'MM/DD/YYYY',
			parser: _componentLibraryUtilsZSpreadsheetParsers.dateParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.hireDateValidator,
			toRow: function toRow(record) {
				return (0, _componentLibraryUtilsZSpreadsheetParsers.dateParser)(record.data.hire_date);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.hire_date = rowObj.hire_date ? (0, _componentLibraryUtilsZSpreadsheetParsers.dateParser)(rowObj.hire_date, _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT) : '';
				return record;
			},
			validationMessage: 'Please enter a valid hire date'
		},
		'termination_date': {
			colHeader: 'Termination Date',
			modelProp: 'termination_date',
			helperText: 'The official termination date of the employee.',
			type: 'date',
			dateFormat: 'MM/DD/YYYY',
			parser: _componentLibraryUtilsZSpreadsheetParsers.dateParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.terminationDateValidator,
			toRow: function toRow(record) {
				return (0, _componentLibraryUtilsZSpreadsheetParsers.dateParser)(record.data.termination_date);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.termination_date = rowObj.termination_date ? (0, _componentLibraryUtilsZSpreadsheetParsers.dateParser)(rowObj.termination_date, _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT) : '';
				return record;
			},
			validationMessage: 'Please enter a valid termination date'
		},
		'gender': {
			colHeader: 'Gender',
			modelProp: 'gender',
			helperText: 'Please specify the employee\'s gender as one of the following:\nMale, Female.',
			type: 'dropdown',
			source: [_componentLibraryConstantsZSpreadsheetEmployee.GENDER_LABELS.M, _componentLibraryConstantsZSpreadsheetEmployee.GENDER_LABELS.F],
			parser: _componentLibraryUtilsZSpreadsheetParsers.genderParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.genderValidator,
			toRow: function toRow(record) {
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.GENDER_MAPPINGS, (0, _componentLibraryUtilsZSpreadsheetParsers.genderParser)(record.data.gender, 'key'));
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.gender = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.GENDER_MAPPINGS, rowObj.gender);
				return record;
			},
			validationMessage: 'Please select a valid gender.'
		},
		'employment_type': {
			colHeader: 'Employment Type',
			modelProp: 'employment_type',
			helperText: 'Please specify the employee\'s employment type as one of the following:\nFull-time, Part-time, Temp/Intern.',
			type: 'dropdown',
			source: ALL_WORKERS_TYPE_LABELS,
			parser: _componentLibraryUtilsZSpreadsheetParsers.employmentTypeParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.employmentTypeValidator,
			toRow: function toRow(record) {
				var etype = (0, _componentLibraryUtilsZSpreadsheetParsers.employmentTypeParser)(record.data.employment_type, 'key');
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYMENT_TYPE_MAPPINGS, etype);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.employment_type = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYMENT_TYPE_MAPPINGS, rowObj.employment_type);
				return record;
			},
			validationMessage: 'Please select a valid employment type.',
			afterChange: {
				dependentColumn: 'Compensation Type',
				'callback': function callback(changes, columnIndex, dependentColumnIndex) {
					var _this = this;

					// Remove value from Compensation Type cell if there is a mismatch with employment type
					var removeCompensationTypeCellValue = function removeCompensationTypeCellValue(changes, columnIndex, dependentColumnIndex) {
						changes.filter(function (change) {
							return change[1] === columnIndex;
						}).forEach(function (change) {
							var changeRowIndex = change[0];
							var employmentTypeKey = change[3];
							var dependentCellMeta = _this.getCellMeta(changeRowIndex, dependentColumnIndex);
							var dependentCellCompensationValue = dependentCellMeta.instance.getDataAtCell(changeRowIndex, dependentColumnIndex);
							var compensationObject = _componentLibraryConstantsZSpreadsheetEmployee.EMPLOYMENT_TYPE_TO_COMPENSATION_TYPE_MAPPING.filter(function (object) {
								return object.key === employmentTypeKey;
							})[0];
							if (dependentCellCompensationValue === null) {
								return;
							}
							if (compensationObject !== null && compensationObject !== undefined && compensationObject.label.indexOf(dependentCellCompensationValue) < 0) {
								dependentCellMeta.instance.setDataAtCell(changeRowIndex, dependentColumnIndex, "");
							}
						});
					};

					// Change the drop down list in Compensation Type cell based on selected value in employment type
					var changeAutocompleteOptionListInCompensationTypeCell = function changeAutocompleteOptionListInCompensationTypeCell(changes, columnIndex, dependentColumnIndex) {
						changes.filter(function (change) {
							return change[1] === columnIndex;
						}).forEach(function (change) {
							var changeRowIndex = change[0];
							var employmentTypeKey = change[3];
							var dependentCellMeta = _this.getCellMeta(changeRowIndex, dependentColumnIndex);
							var compensationTypeLabels = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYMENT_TYPE_TO_COMPENSATION_TYPE_MAPPING, employmentTypeKey);
							if (compensationTypeLabels === undefined || compensationTypeLabels === "") {
								dependentCellMeta.source = Object.values(_componentLibraryConstantsZSpreadsheetEmployee.ALL_COMPENSATION_TYPE_LABELS);
							} else {
								dependentCellMeta.source = compensationTypeLabels;
							}
							_this.render();
						});
					};

					// Call functions to do the changes to dependant column
					removeCompensationTypeCellValue(changes, columnIndex, dependentColumnIndex);
					changeAutocompleteOptionListInCompensationTypeCell(changes, columnIndex, dependentColumnIndex);
				}
			}
		},
		'_compensation': {
			colHeader: 'Compensation',
			modelProp: '_compensation', //Controller will infer this based on compensation_type at submit time
			helperText: 'The employee\'s annual or hourly salary in US dollar amount.',
			type: 'numeric',
			format: '$ 0,0.00',
			validator: _componentLibraryUtilsZSpreadsheetValidators.compensationRangeValidator,
			toRow: function toRow(record) {
				var compensationType = (0, _componentLibraryUtilsZSpreadsheetParsers.compensationTypeParser)(record.data.compensation_type, 'key');
				var serverValidationErrors = record._errors;
				var _compensation = '';

				if (compensationType === _componentLibraryConstantsZSpreadsheetEmployee.COMPENSATION_TYPE_KEYS.SALARY && _ember['default'].isPresent(record.data.annual_salary)) {
					_compensation = record.data.annual_salary;
				} else if (compensationType === _componentLibraryConstantsZSpreadsheetEmployee.COMPENSATION_TYPE_KEYS.HOURLY && _ember['default'].isPresent(record.data.pay_rate)) {
					_compensation = record.data.pay_rate;
				} else if (compensationType === _componentLibraryConstantsZSpreadsheetEmployee.CONTRACTOR_COMPENSATION_TYPE_KEYS.FIXED && _ember['default'].isPresent(record.data.fixed_amount)) {
					_compensation = record.data.fixed_amount;
				}

				// XXX side effect
				if (serverValidationErrors) {
					if (serverValidationErrors.annual_salary) {
						serverValidationErrors._compensation = serverValidationErrors.annual_salary;
						delete serverValidationErrors.annual_salary;
					} else if (serverValidationErrors.pay_rate) {
						serverValidationErrors._compensation = serverValidationErrors.pay_rate;
						delete serverValidationErrors.pay_rate;
					}
				}

				return _compensation;
			},
			fromRow: function fromRow(rowObj, record) {
				var compensationType = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.COMPENSATION_TYPE_MAPPINGS, rowObj.compensation_type);

				if (!compensationType) {
					return record;
				}

				record.data = record.data || {};

				// Compensation type is converted to string label at this point
				if (compensationType === _componentLibraryConstantsZSpreadsheetEmployee.COMPENSATION_TYPE_KEYS.SALARY) {
					record.data.annual_salary = rowObj._compensation;
					delete record.data.pay_rate;
					delete record.data.fixed_amount;
				} else if (compensationType === _componentLibraryConstantsZSpreadsheetEmployee.COMPENSATION_TYPE_KEYS.HOURLY) {
					record.data.pay_rate = rowObj._compensation;
					delete record.data.annual_salary;
					delete record.data.fixed_amount;
				} else if (compensationType === _componentLibraryConstantsZSpreadsheetEmployee.CONTRACTOR_COMPENSATION_TYPE_KEYS.FIXED) {
					record.data.fixed_amount = rowObj._compensation;
					delete record.data.annual_salary;
					delete record.data.pay_rate;
				} else if (compensationType === _componentLibraryConstantsZSpreadsheetEmployee.CONTRACTOR_COMPENSATION_TYPE_KEYS.NOT_APPLICABLE) {
					delete record.data.annual_salary;
					delete record.data.pay_rate;
				}

				return record;
			},
			isCurrency: true
		},
		'compensation_type': {
			colHeader: 'Compensation Type',
			modelProp: 'compensation_type',
			helperText: 'Please specify the employee\'s compensation type as one of the following:\nPer Hour (Hourly Rate), Per Year (Annual Salary).',
			type: 'dropdown',
			source: Object.values(_componentLibraryConstantsZSpreadsheetEmployee.ALL_COMPENSATION_TYPE_LABELS),
			parser: _componentLibraryUtilsZSpreadsheetParsers.compensationTypeParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.compensationTypeValidator,
			toRow: function toRow(record) {
				var ctype = (0, _componentLibraryUtilsZSpreadsheetParsers.compensationTypeParser)(record.data.compensation_type, 'key');
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.COMPENSATION_TYPE_MAPPINGS, ctype);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.compensation_type = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.COMPENSATION_TYPE_MAPPINGS, rowObj.compensation_type);
				return record;
			},
			validationMessage: 'Please select a valid compensation type.',
			afterChange: {
				dependentColumn: 'Compensation',
				'callback': function callback(changes, columnIndex, dependentColumnIndex) {
					var _this2 = this;

					// Deactivate Compensation cell if Compensation Type is Not Applicable
					var deactivateCompensationCell = function deactivateCompensationCell(changes, columnIndex, dependentColumnIndex) {
						changes.filter(function (change) {
							return change[1] === columnIndex;
						}).forEach(function (change) {
							var changeRowIndex = change[0];
							var compensationTypeKey = change[3];
							var dependentCellMeta = _this2.getCellMeta(changeRowIndex, dependentColumnIndex);
							if (compensationTypeKey === _componentLibraryConstantsZSpreadsheetEmployee.ALL_COMPENSATION_TYPE_LABELS.N) {
								dependentCellMeta.instance.setDataAtCell(changeRowIndex, dependentColumnIndex, "N/A");
								dependentCellMeta.source = ["N/A"];
							} else {
								if (dependentCellMeta.instance.getDataAtCell(changeRowIndex, dependentColumnIndex) === "N/A") {
									dependentCellMeta.instance.setDataAtCell(changeRowIndex, dependentColumnIndex, "");
									dependentCellMeta.source = undefined;
								}
							}
						});
					};
					// Call functions to do the changes to dependent column
					deactivateCompensationCell(changes, columnIndex, dependentColumnIndex);
				}
			}
		},
		'address': {
			colHeader: 'Address',
			modelProp: 'address',
			helperText: 'The employee\'s primary residential address.',
			type: 'text',
			trimWhitespace: true,
			validator: _componentLibraryUtilsZSpreadsheetValidators.addressValidator,
			validationMessage: 'Please enter a valid Address',
			toRow: modelPropToRow('address'),
			fromRow: modelPropFromRow('address')
		},
		'city': {
			colHeader: 'City',
			modelProp: 'city',
			helperText: 'The city associated with the employee\'s primary residential address.',
			type: 'text',
			trimWhitespace: true,
			validator: _componentLibraryUtilsZSpreadsheetValidators.cityValidator,
			validationMessage: 'Please enter a valid city',
			toRow: modelPropToRow('city'),
			fromRow: modelPropFromRow('city')
		},
		'state': {
			colHeader: 'State',
			modelProp: 'state',
			helperText: 'The state associated with the employee\'s primary residential address.',
			trimWhitespace: true,
			validator: _componentLibraryUtilsZSpreadsheetValidators.stateValidator,
			validationMessage: 'Please enter a valid state',
			toRow: modelPropToRow('state'),
			fromRow: modelPropFromRow('state')
		},
		'zip': {
			colHeader: 'Zip',
			modelProp: 'zip',
			helperText: 'Postal code associated with the employee\'s primary residential address.',
			type: 'text',
			trimWhitespace: true,
			parser: _componentLibraryUtilsZSpreadsheetParsers.zipParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.zipValidator,
			toRow: function toRow(record) {
				return (0, _componentLibraryUtilsZSpreadsheetParsers.zipParser)(record.data.zip);
			},
			fromRow: modelPropFromRow('zip'),
			validationMessage: 'Please enter a valid zip'
		},
		'underMedicare': {
			colHeader: 'Under Medicare?',
			modelProp: 'underMedicare',
			helperText: 'Is the employee under medicare?',
			type: 'dropdown',
			source: [_componentLibraryConstantsZSpreadsheetEmployee.BOOLEAN_LABELS.Y, _componentLibraryConstantsZSpreadsheetEmployee.BOOLEAN_LABELS.N],
			parser: _componentLibraryUtilsZSpreadsheetParsers.booleanParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.booleanValidator,
			toRow: function toRow(record) {
				var underMedicare = typeof record.data.underMedicare !== 'undefined' ? String(record.data.underMedicare) : '';
				underMedicare = (0, _componentLibraryUtilsZSpreadsheetParsers.booleanParser)(underMedicare, 'key');
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.BOOLEAN_MAPPINGS, underMedicare);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.underMedicare = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.BOOLEAN_MAPPINGS, rowObj.underMedicare);
				return record;
			},
			validationMessage: 'Please select a valid option.'
		},
		'eeoRace': {
			colHeader: 'EEO Race',
			modelProp: 'eeoRace',
			helperText: 'What is the gender and race of this employee?',
			type: 'dropdown',
			source: _lodash['default'].values(_componentLibraryConstantsZSpreadsheetEmployee.EEO_RACE_LABELS),
			toRow: function toRow(record) {
				var eeoRace = (0, _componentLibraryUtilsZSpreadsheetParsers.eeoRaceParser)(record.data.eeoRace, 'key');
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.EEO_RACE_MAPPINGS, eeoRace);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.eeoRace = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.EEO_RACE_MAPPINGS, rowObj.eeoRace);
				return record;
			},
			parser: _componentLibraryUtilsZSpreadsheetParsers.eeoRaceParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.eeoRaceValidator,
			validationMessage: 'Please select a valid race option.'
		},
		'eeoJobCategory': {
			colHeader: 'EEO Job Category',
			modelProp: 'eeoJobCategory',
			helperText: 'What is the job category for this employee?',
			type: 'dropdown',
			source: _lodash['default'].values(_componentLibraryConstantsZSpreadsheetEmployee.EEO_JOB_CATEGORY_LABELS),
			toRow: function toRow(record) {
				var eeoJobCategory = (0, _componentLibraryUtilsZSpreadsheetParsers.eeoJobCategoryParser)(record.data.eeoJobCategory, 'key');
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.EEO_JOB_CATEGORY_MAPPINGS, eeoJobCategory);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.eeoJobCategory = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.EEO_JOB_CATEGORY_MAPPINGS, rowObj.eeoJobCategory);
				return record;
			},
			parser: _componentLibraryUtilsZSpreadsheetParsers.eeoJobCategoryParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.eeoJobCategoryValidator,
			validationMessage: 'Please select a valid job category option.'
		},
		'eeoIsFilledByEmployee': {
			colHeader: 'Filled By Employee',
			modelProp: 'eeoIsFilledByEmployee',
			helperText: 'Did the employee provide the EEO information?',
			type: 'dropdown',
			source: [_componentLibraryConstantsZSpreadsheetEmployee.BOOLEAN_LABELS.Y, _componentLibraryConstantsZSpreadsheetEmployee.BOOLEAN_LABELS.N],
			parser: _componentLibraryUtilsZSpreadsheetParsers.booleanParser,
			validator: function validator() {
				return null; //Always valid
			},
			toRow: function toRow(record) {
				if (typeof record.data.eeoIsFilledByEmployee !== "boolean") {
					return "";
				}
				var eeoIsFilledByEmployee = (0, _componentLibraryUtilsZSpreadsheetParsers.booleanParser)(String(record.data.eeoIsFilledByEmployee), 'key');
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.BOOLEAN_MAPPINGS, eeoIsFilledByEmployee);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};

				if (typeof rowObj.eeoIsFilledByEmployee !== "string") {
					record.data.eeoIsFilledByEmployee = null; //only expect "Yes" or "No". Translate to boolean before submission
					return record;
				}
				record.data.eeoIsFilledByEmployee = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.BOOLEAN_MAPPINGS, rowObj.eeoIsFilledByEmployee);
				return record;
			},
			validationMessage: 'Please select a valid option.'
		},
		'status': {
			colHeader: 'Employee Status',
			modelProp: 'status',
			helperText: 'What is the status of the employee?',
			type: 'dropdown',
			source: _lodash['default'].values(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYEE_STATUS_LABELS),
			parser: _componentLibraryUtilsZSpreadsheetParsers.employeeStatusParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.employeeStatusValidator,
			toRow: function toRow(record) {
				var estatus = (0, _componentLibraryUtilsZSpreadsheetParsers.employeeStatusParser)(record.data.status, 'key');
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYEE_STATUS_MAPPINGS, estatus);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.status = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYEE_STATUS_MAPPINGS, rowObj.status);
				return record;
			},
			validationMessage: 'Please select a valid option.'
		},
		'paymentMethod': {
			colHeader: 'Payment Method',
			modelProp: 'paymentMethod',
			helperText: 'What is the payment method for the employee?',
			type: 'dropdown',
			source: _lodash['default'].values(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYEE_PAYMENT_METHOD_LABELS),
			parser: _componentLibraryUtilsZSpreadsheetParsers.employeePaymentMethodParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.employeePaymentMethodValidator,
			toRow: function toRow(record) {
				var emethod = (0, _componentLibraryUtilsZSpreadsheetParsers.employeePaymentMethodParser)(record.data.paymentMethod, 'key');
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYEE_PAYMENT_METHOD_MAPPINGS, emethod);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.paymentMethod = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYEE_PAYMENT_METHOD_MAPPINGS, rowObj.paymentMethod);
				return record;
			},
			validationMessage: 'Please select a valid option.'
		},
		'workType': {
			colHeader: 'Work Type',
			modelProp: 'paymentMethod',
			helperText: 'What is the type of work for the employee?',
			type: 'dropdown',
			source: _lodash['default'].values(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYEE_PAYMENT_METHOD_LABELS),
			parser: _componentLibraryUtilsZSpreadsheetParsers.employeePaymentMethodParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.employeePaymentMethodValidator,
			toRow: function toRow(record) {
				var emethod = (0, _componentLibraryUtilsZSpreadsheetParsers.employeePaymentMethodParser)(record.data.paymentMethod, 'key');
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYEE_PAYMENT_METHOD_MAPPINGS, emethod);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.paymentMethod = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYEE_PAYMENT_METHOD_MAPPINGS, rowObj.paymentMethod);
				return record;
			},
			validationMessage: 'Please select a valid option.'
		},

		// Below are keys only related to EEO-Component2 and read from cache

		'workingHours': {
			colHeader: 'Total Hours Worked',
			modelProp: 'workingHours',
			helperText: 'Please enter the total hours worked by the employee.',
			type: 'numeric',
			validator: _componentLibraryUtilsZSpreadsheetValidators.numberValidator,
			validationMessage: 'Please enter a valid integer.',
			toRow: modelPropToRow('workingHours'),
			fromRow: modelPropFromRow('workingHours')
		},
		'taxableEarnings': {
			colHeader: 'Total Taxable Wage',
			modelProp: 'taxableEarnings',
			helperText: 'Please enter the total taxable earnings for the employee.',
			type: 'numeric',
			format: '$ 0,0.00',
			validator: _componentLibraryUtilsZSpreadsheetValidators.numberValidator,
			validationMessage: 'Please enter a valid integer.',
			toRow: modelPropToRow('taxableEarnings'),
			fromRow: modelPropFromRow('taxableEarnings')
		},
		'jobCategory': {
			colHeader: 'EEO Job Category',
			modelProp: 'jobCategory',
			helperText: 'What is the job category for this employee?.',
			type: 'dropdown',
			source: _lodash['default'].values(_componentLibraryConstantsZSpreadsheetEmployee.EEO_JOB_CATEGORY_COMPONENT_2_LABELS),
			toRow: function toRow(record) {
				var eeoJobCategory = (0, _componentLibraryUtilsZSpreadsheetParsers.eeoJobCategoryParser)(record.data.jobCategory, 'key');
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.EEO_JOB_CATEGORY_COMPONENT2_MAPPINGS_FROM_OLD_KEY_TO_NEW_LABEL, eeoJobCategory);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.jobCategory = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.EEO_JOB_CATEGORY_COMPONENT2_MAPPINGS_FROM_OLD_KEY_TO_NEW_LABEL, rowObj.jobCategory);
				return record;
			},
			parser: _componentLibraryUtilsZSpreadsheetParsers.eeoJobCategoryParserComponent2,
			validator: _componentLibraryUtilsZSpreadsheetValidators.eeoJobCategoryComponent2Validator,
			validationMessage: 'Please select a valid job category option.'
		},
		'race': {
			colHeader: 'EEO Race',
			modelProp: 'race',
			helperText: 'What is the gender and race of this employee?',
			type: 'dropdown',
			source: _lodash['default'].values(_componentLibraryConstantsZSpreadsheetEmployee.EEO_RACE_LABELS),
			toRow: function toRow(record) {
				var eeoRace = (0, _componentLibraryUtilsZSpreadsheetParsers.eeoRaceParser)(record.data.race, 'key');
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.EEO_RACE_MAPPINGS, eeoRace);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.race = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.EEO_RACE_MAPPINGS, rowObj.race);
				return record;
			},
			parser: _componentLibraryUtilsZSpreadsheetParsers.eeoRaceParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.eeoRaceValidator,
			validationMessage: 'Please select a valid race option.'
		},
		'employmentType': {
			colHeader: 'Employment Type',
			modelProp: 'employmentType',
			helperText: 'Please specify the employee employment type',
			type: 'dropdown',
			source: ALL_WORKERS_TYPE_LABELS,
			parser: _componentLibraryUtilsZSpreadsheetParsers.employmentTypeParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.employmentTypeValidator,
			toRow: function toRow(record) {
				var etype = (0, _componentLibraryUtilsZSpreadsheetParsers.employmentTypeParser)(record.data.employmentType, 'key');
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYMENT_TYPE_MAPPINGS, etype);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.employmentType = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYMENT_TYPE_MAPPINGS, rowObj.employmentType);
				return record;
			},
			validationMessage: 'Please select a valid employment type.'
		},
		'employmentSubType': {
			colHeader: 'Employment SubType',
			modelProp: 'employmentSubType',
			helperText: 'Please specify the employee employment Sub type',
			type: 'dropdown',
			source: ALL_WORKERS_TYPE_LABELS,
			parser: _componentLibraryUtilsZSpreadsheetParsers.employmentTypeParser,
			validator: _componentLibraryUtilsZSpreadsheetValidators.employmentTypeValidator,
			toRow: function toRow(record) {
				var etype = (0, _componentLibraryUtilsZSpreadsheetParsers.employmentTypeParser)(record.data.employmentSubType, 'key');
				return (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingLabelByKey)(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYMENT_TYPE_MAPPINGS, etype);
			},
			fromRow: function fromRow(rowObj, record) {
				record.data = record.data || {};
				record.data.employmentSubType = (0, _componentLibraryConstantsZSpreadsheetEmployee.getMappingKeyByLabel)(_componentLibraryConstantsZSpreadsheetEmployee.EMPLOYMENT_TYPE_MAPPINGS, rowObj.employmentSubType);
				return record;
			},
			validationMessage: 'Please select a valid employment type.'
		}
	};

	exports.EMPLOYEE_CENSUS_FIELD_SCHEMA = EMPLOYEE_CENSUS_FIELD_SCHEMA;

	// Wraps original schema for field, while overriding the validator function to allow
	// empty values. If it's a non-empty value, then it uses the validator from the original
	// field schema.
	var getOptionalFieldSchema = function getOptionalFieldSchema(originalKeyName) {
		var additionalOverwrites = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
		var schema = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

		var fieldSchema = schema || EMPLOYEE_CENSUS_FIELD_SCHEMA;
		var originalDefinition = fieldSchema[originalKeyName];
		if (originalDefinition) {
			if (!additionalOverwrites) {
				additionalOverwrites = {};
			}
			return _ember['default'].$.extend({}, originalDefinition, {
				colHeader: originalDefinition.colHeader + ' (optional)',
				validator: function validator(value, callback) {
					if (_ember['default'].isBlank(value)) {
						return null;
					}

					return originalDefinition.validator(value, callback);
				}
			}, additionalOverwrites);
		}
	};

	exports.getOptionalFieldSchema = getOptionalFieldSchema;

	// Override properties of original EMPLOYEE_CENSUS_FIELD_SCHEMA to allow for optional fields.
	var EMPLOYEE_CENSUS_OPTIONAL_FIELD_SCHEMA = {
		email: getOptionalFieldSchema('email'),
		_compensation: getOptionalFieldSchema('_compensation'),
		hire_date: getOptionalFieldSchema('hire_date'),
		compensation_type: getOptionalFieldSchema('compensation_type'),
		employment_type: getOptionalFieldSchema('employment_type'),
		// this is to fix the following scenario
		// say fill basic info and leaving zip empty, and then click "submit", promotion would fail as zip is None in the backend
		// however, if click save as draft first then click 'submit', or click submit twice, it will sucessed with zip being ''
		// this is because, after first save, we will re-render spreadsheet which will populate None zip as ''
		// however, although 'submit'(spreadsheet-mixin.js) save census as draft first, it is using an outdated 'spreadsheetState' passed from 'z-spreadsheet.js'
		// TODO: the utlimate fix to 'submit'(spreadsheet-mixin.js) is to somehow call _getSpreadsheetState() in z-spreadsheet.js to get the updated 'spreadsheetState' after inital save
		// or simply exucte the 'save' action first as part of 'submit' aciton in z-spreadsheet.js
		zip: getOptionalFieldSchema('zip', {
			fromRow: modelPropFromRowOptional('zip')
		}),
		dob: getOptionalFieldSchema('dob'),
		ssn: getOptionalFieldSchema('ssn'),
		gender: getOptionalFieldSchema('gender'),
		termination_date: getOptionalFieldSchema('termination_date'),
		paymentMethod: getOptionalFieldSchema('paymentMethod'),
		address: getOptionalFieldSchema('address'),
		city: getOptionalFieldSchema('city'),
		state: getOptionalFieldSchema('state')
	};

	exports.EMPLOYEE_CENSUS_OPTIONAL_FIELD_SCHEMA = EMPLOYEE_CENSUS_OPTIONAL_FIELD_SCHEMA;

	// Add other optional fields, here, in a similar fashion,
	var EMPLOYEE_CENSUS_COLUMNS_NAME_TO_FIELD = (function () {
		var columnNameToFieldMapping = {};
		var key = undefined,
		    schema = undefined;

		columnNameToFieldMapping[_componentLibraryConstantsCensusConstants.ZENEFITS_ID] = 'realId';

		Object.keys(EMPLOYEE_CENSUS_FIELD_SCHEMA).forEach(function (key) {
			schema = EMPLOYEE_CENSUS_FIELD_SCHEMA[key];
			columnNameToFieldMapping[schema.colHeader] = schema.modelProp;
		});

		Object.keys(EMPLOYEE_CENSUS_OPTIONAL_FIELD_SCHEMA).forEach(function (key) {
			schema = EMPLOYEE_CENSUS_OPTIONAL_FIELD_SCHEMA[key];
			columnNameToFieldMapping[schema.colHeader] = schema.modelProp;
		});

		return columnNameToFieldMapping;
	})();
	exports.EMPLOYEE_CENSUS_COLUMNS_NAME_TO_FIELD = EMPLOYEE_CENSUS_COLUMNS_NAME_TO_FIELD;
});