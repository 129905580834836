define('component-library/components/z-mobile-nav', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var ANIMATION_DURATION = 500;

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-mobile-nav'],
		router: _ember['default'].inject.service('routing'),

		// array of objects with format {label: "Display Label", route: "path.to.route"}
		navItems: [],
		showNavList: false,

		// set by route transition listener
		activeNavItem: null,

		_setCurrentRoute: function _setCurrentRoute() {
			var currentRoute = this.get('router').get('currentRouteName');
			// Ember's link-to will declare a link as "active" if it is a parent of the current route.
			// Since this component only shows a single active route, we will show the closest parent if
			// an exact match is not found.
			var closestParentRouteItem = null;
			this.set('activeNavItem', this.get('navItems').find(function (elt) {
				// we do not have an exact match, check if it's a sub route.
				// if the current route is a sub route of the nav item route, and we do not
				// have an exact match, then we will set the closest parent route to be the current one.
				if (elt.route !== currentRoute && currentRoute.startsWith(elt.route)) {
					if (!closestParentRouteItem || closestParentRouteItem && closestParentRouteItem.route.length < elt.route.length) {
						closestParentRouteItem = elt;
					}
				}
				return elt.route === currentRoute;
			}));
			if (!this.get('activeNavItem.route') && closestParentRouteItem) {
				this.set('activeNavItem', closestParentRouteItem);
			}
		},

		init: function init() {
			this._super.apply(this, arguments);

			var routingService = this.get('router');
			// subscribe to route transition changes
			_ember['default'].run.next(this, function () {
				var _this = this;

				routingService.transitionTo.apply(routingService)['finally'](function () {
					routingService.on('routerDidTransition', _this, _this._setCurrentRoute);

					// set current route
					_this._setCurrentRoute();
				});
			});
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			this.get('router').off('routerDidTransition', this, this._setCurrentRoute);
		},

		actions: {
			onClickItem: function onClickItem(route) {
				this.send('toggleShowNavItems');
			},
			toggleShowNavItems: function toggleShowNavItems() {
				this.set('showNavList', !this.get('showNavList'));
				if (this.get('showNavList')) {
					_ember['default'].$('.z-mobile-nav-list').show(ANIMATION_DURATION);
				} else {
					_ember['default'].$('.z-mobile-nav-list').hide(ANIMATION_DURATION);
				}
			}
		}
	});
});