define('component-library/components/z-module-core', ['exports', 'ember', 'component-library/templates/components/z-module-core'], function (exports, _ember, _componentLibraryTemplatesComponentsZModuleCore) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		/*
  	z-module-inner renders a different template based on whether or not
  	it is disabled. Ember components cannot have their template be a
  	dynamic property. The interim solution is to use this wrapper
  	component which actually shows a seperate component in the two cases.
  */
		template: _componentLibraryTemplatesComponentsZModuleCore['default'],

		updateModuleContext: function updateModuleContext() {
			if (this.get('moduleContext')) {
				this.set('moduleContext.isReadOnlyMode', this.get('isReadOnlyMode'));
			}
		},

		classNames: ['z-module-core', 'u-fillHorizontal'],
		init: function init() {
			this._super.apply(this, arguments);
			this.updateModuleContext();
		},
		setModuleIsOpen: _ember['default'].observer('isReadOnlyMode', function () {
			this.updateModuleContext();
		})
	});
});