define('component-library/mixins/pto/ptorecordoreditrequestmixin', ['exports', 'ember', 'component-library/utils/globals', 'component-library/mixins/pto/request-modal'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryMixinsPtoRequestModal) {
	'use strict';

	var _PtoRecordOrEditRequestMixin = _ember['default'].Mixin.create(_componentLibraryMixinsPtoRequestModal['default'], {
		open: function open() {
			var ret = this._super();
			this.set('isHoursDirty', false);
			this.set('calculatedHours', null);
			this.set('loaReasons', null);
			this.set('loaReasonsPromise', _ember['default'].RSVP.resolve());
			this.set('hoursPromise', _ember['default'].RSVP.resolve());
			return ret;
		},

		actions: {
			confirm: function confirm(laddaButton) {
				var _this = this,
				    _arguments = arguments;

				this.set('model.errorText', '');
				this.set('model.hours', this.get('displayHours'));
				if (this.get('model.associatedLoaReason')) {
					this.set('model.loaRequestId', this.get('model.associatedLoaReason.loaRequestId'));
				}

				this.get('model').validateAndSetErrorMessage();

				if (this.get('payPeriodStartDate') && this.get('payPeriodEndDate')) {
					var startDate = (0, _componentLibraryUtilsGlobals.moment)(this.get('model.startDate'));
					var endDate = (0, _componentLibraryUtilsGlobals.moment)(this.get('model.endDate'));
					var payPeriodEndDate = (0, _componentLibraryUtilsGlobals.moment)(this.get('payPeriodEndDate'));
					var payPeriodStartDate = (0, _componentLibraryUtilsGlobals.moment)(this.get('payPeriodStartDate'));
					var isStartDateValid = startDate <= payPeriodEndDate;
					var isEndDateValid = endDate >= payPeriodStartDate;
					var areBothDatesOutsideRange = startDate < payPeriodStartDate && endDate > payPeriodEndDate;

					if (!(isStartDateValid && isEndDateValid) || areBothDatesOutsideRange) {
						this.set('model.errorText', 'Vacation request outside of pay period.');
						laddaButton.stop();
						return;
					}
				}

				if (this.get('model.hours') > this.get('maximumHours')) {
					this.set('model.errorText', 'Exceeds number of hours in this timespan.');
					laddaButton.stop();
					return;
				}

				if (this.get('confirmPromise')) {
					return this.get('confirmPromise')().then(function () {
						if (_this.get('model.errorText')) {
							laddaButton.stop();
						} else {
							_this.send('close');
							return _this.get('_resolve').apply(_this, _arguments);
						}
					});
				} else if (this.get('model.errorText')) {
					laddaButton.stop();
				} else {
					this._super();
					laddaButton.stop();
				}
			}
		},

		filteredVacationTypes: (function () {
			var _this2 = this;

			if (this.get('account')) {
				return this.get('availableVacationTypes') && this.get('availableVacationTypes').filter(function (type) {
					return type.get('countsAs') === _this2.get('account.type') || type.get('countsAs') == '';
				});
			} else {
				return this.get('availableVacationTypes');
			}
		}).property('availableVacationTypes.@each.countsAs', 'account', 'account.type'),

		displayHours: (function (key, value) {
			if (arguments.length > 1) {
				this.set('isHoursDirty', true);
				this.set('model.hours', value);
			}
			if (this.get('isHoursDirty')) {
				return this.get('model.hours');
			} else {
				var calculatedHours = this.get('calculatedHours');
				return _ember['default'].$.isNumeric(calculatedHours) ? calculatedHours : this.get('model.hours');
			}
		}).property('isHoursDirty', 'calculatedHours', 'model.hours'),

		displayLoaReasons: (function (key, value) {
			if (arguments.length > 1) {
				this.set('model.loaReasons', value);
			}
			var loaReasons = this.get('loaReasons');
			if (loaReasons && loaReasons.length === 0) {
				return null;
			}
			return loaReasons;
		}).property('loaReasons', 'model.loaReasons'),

		selectedReason: (function () {
			var _this3 = this;

			if (!this.get('model.associatedLoaReason')) {
				return;
			} else {
				return this.get('model.loaReasonsForEmployee').find(function (f) {
					return f.loaRequestId === _this3.get('model.associatedLoaReason.loaRequestId');
				});
			}
		}).property('model.loaReasonsForEmployee', 'model.associatedLoaReason'),

		calcHours: (function () {
			var _this4 = this;

			if (!this.get('model.startDate') || !this.get('model.endDate')) {
				return;
			}
			var hoursPromise = this.get('hoursPromise');
			var calcHoursPromise = this.calcHoursPromise();
			if (calcHoursPromise) {
				// the idea here is that we start fetching the new hours immediatly,
				// but wait until the previous value is set to update the latest calculation
				var newHoursPromise = _ember['default'].RSVP.all([calcHoursPromise, hoursPromise]).then(function (ps) {
					return !_this4.get('isDestroyed') && _this4.set('calculatedHours', ps[0]);
				});
				this.set('hoursPromise', newHoursPromise);
			}
		}).observes('model.endDate', 'model.startDate', 'model.employee')

	});

	// getLoaReasons: function(){
	// 	if (!this.get('model.vacationType') || !this.get('model.startDate') || !this.get('model.endDate')){
	// 		return;
	// 	}
	// 	var loaReasonsPromise = this.get('loaReasonsPromise');
	// 	var getLoaReasonsPromise = this.getLoaReasonsPromise();
	// 	if (getLoaReasonsPromise){
	// 		const newLoaReasonsPromise = Ember.RSVP.all([getLoaReasonsPromise, loaReasonsPromise])
	// 			.then(ps => {
	// 				return !this.get('isDestroyed') && this.set('loaReasons', ps[0]);
	// 			});
	// 		this.set('loaReasonsPromise', newLoaReasonsPromise);
	// 	}
	// }.observes('calcHours', 'model.endDate', 'model.startDate', 'model.vacationType', 'model.employee'),

	// employeeLoaReasons: function(){
	// 	this.getLoaReasons();
	// 	var loaReasons = this.get('loaReasons');
	// 	if(!loaReasons){
	// 		const employeeLoaReasons = this.get('model.loaReasonsForEmployee');
	// 		if (employeeLoaReasons){
	// 			var selectedReason = this.get('model.loaReasonsForEmployee').find(f => f.loaRequestId === this.get('model.associatedLoaReason.loaRequestId'));
	// 			this.set('model.associatedLoaReason', selectedReason);
	// 			return employeeLoaReasons;
	// 		}
	// 	}
	// 	return loaReasons;
	// }.property('model.endDate', 'model.startDate'),
	exports['default'] = _PtoRecordOrEditRequestMixin;
});