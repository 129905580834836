define('component-library/components/cards/base/card-foot', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['settings-card-foot'],
		classNameBindings: ['useFoundation:grid-block', 'useFoundation:vertical', '_notFoundation:u-flexContainer', '_notFoundation:u-spaceBetween'],
		useFoundation: true, // whether or not to use foundation classes
		_notFoundation: _ember['default'].computed.not('useFoundation'),

		cardErrorText: '',
		actions: {
			save: function save() {
				if (this.get('save')) {
					this.get('save')();
				}
			},
			toggleEdit: function toggleEdit() {
				if (this.get('toggleEdit')) {
					this.get('toggleEdit')();
				}
			}
		}
	});
});