define('component-library/components/cards/payroll/accordion-card', ['exports', 'ember', 'component-library/components/cards/card-base'], function (exports, _ember, _componentLibraryComponentsCardsCardBase) {
	'use strict';

	exports['default'] = _componentLibraryComponentsCardsCardBase['default'].extend({
		zPayrollEnums: undefined,
		company: undefined,
		taxHeader: undefined,
		title: _ember['default'].computed.oneWay('taxHeader.taxAgency'),
		mode: _componentLibraryComponentsCardsCardBase.SETTINGS_CARD_MODES.READ,
		classNames: ['accordion-card', 'js-accordion-card'],
		isExpanded: false,
		thirdButton: undefined,
		showCardControls: _ember['default'].computed('isExpanded', 'mode', function () {
			return this.get('isExpanded') && this.get('mode') === _componentLibraryComponentsCardsCardBase.SETTINGS_CARD_MODES.READ;
		}),
		headerTitleIconClass: _ember['default'].computed('isExpanded', function () {
			return this.get('isExpanded') ? 'zmdi-chevron-down' : 'zmdi-chevron-right';
		}),

		actions: {
			cancel: function cancel(button) {
				var _this = this;

				if (this.attrs.cancel) {
					this.attrs.cancel(button)['finally'](function () {
						_this.send('toggleEdit');
						button.stop();
					});
				} else {
					this.send('toggleEdit');
					button.stop();
				}
			},

			save: function save(button) {
				var _this2 = this;

				if (this.attrs.save) {
					this.attrs.save(button)['finally'](function () {
						_this2.send('cancel', button);
						button.stop();
					});
				} else {
					this.send('toggleEdit');
					button.stop();
				}
			},

			toggleAccordion: function toggleAccordion() {
				if (!this.get('isEditing')) {
					this.toggleProperty('isExpanded');
				}
			},

			thirdButtonAction: function thirdButtonAction(button) {
				var _this3 = this;

				if (this.attrs.changeAgencyStatus) {
					this.attrs.changeAgencyStatus(button)['finally'](function () {
						_this3.send('cancel', button);
						button.stop();
					});
				} else {
					this.send('toggleEdit');
					button.stop();
				}
			}
		}

	});
});