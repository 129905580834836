define('component-library/components/avatar-with-badge', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var AVATAR_SIZES = {
		'x-small': 'avatar-container--xs',
		small: 'avatar-container--sm',
		medium: 'avatar-container--md',
		large: 'avatar-container--lg',
		'x-large': 'avatar-container--xl'
	};

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['avatar-with-badge'],
		classNameBindings: ['sizeClass'],
		isVerticalAligned: false,
		showLastName: true,
		firstName: null,
		lastName: null,
		photoUrl: null,
		size: null,
		tooltipText: null,
		badgeText: null,
		tooltipHeader: _ember['default'].computed(function () {
			return this.get('isContractorPaymentsCompany') ? 'Independent Contractor' : 'Contingent Worker';
		}),

		sizeClass: _ember['default'].computed('size', function () {
			return AVATAR_SIZES[this.get('size')];
		})
	});
});