define('component-library/utils/computed-props/blank', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	// submitted this in PR to Ember: https://github.com/emberjs/ember.js/pull/12668

	exports['default'] = function (dependentKey) {
		return _ember['default'].computed(dependentKey, dependentKey + '.length', function () {
			return _ember['default'].isBlank(_ember['default'].get(this, dependentKey));
		});
	};
});