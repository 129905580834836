define('component-library/timeline/utils/types', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports.getEntryTypeFilters = getEntryTypeFilters;

	var fullTypeList = ['DepartmentChange', 'EmployeeTermination', 'EmploymentTypeChange', 'HireDateChange', 'LocationChange', 'ManagerChange', 'NewHire', 'SalaryChange', 'TitleChange', 'Deduction', 'EmployeeHealthEvent', 'CompanyHealthEvent', 'FulfillmentEvent', 'PtoRequest', 'PtoBalanceAdjustment'];

	exports.fullTypeList = fullTypeList;

	var typeDict = {};
	fullTypeList.forEach(function (t) {
		var modelName = t.decamelize();
		var modelLabel = modelName.replace(/_/g, ' ').w().map(_ember['default'].String.capitalize).join(' ');
		if (t == 'EmployeeTermination') {
			modelLabel = 'Termination';
		}
		typeDict[t] = {
			model: modelName,
			label: modelLabel
		};
	});

	function getEntryTypeFilters(typeList) {
		return typeList.map(function (t) {
			return typeDict[t];
		});
	}
});