define('component-library/components/deductions/deduction-money-table-cell', ['exports', 'ember-table/views/table-cell'], function (exports, _emberTableViewsTableCell) {
	'use strict';

	exports['default'] = _emberTableViewsTableCell['default'].extend({
		templateName: 'components/deductions/deduction-money-table-cell',

		isPercent: (function () {
			return Boolean(this.get('row.isPercent') || this.get('row.isPercentage'));
		}).property('row.isPercent', 'row.isPercentage')

	});
});