define('component-library/timeline/components/department-change', ['exports', 'ember', 'component-library/timeline/components/copy', 'component-library/timeline/mixins/change-feed-entry'], function (exports, _ember, _componentLibraryTimelineComponentsCopy, _componentLibraryTimelineMixinsChangeFeedEntry) {
	'use strict';

	exports['default'] = _componentLibraryTimelineComponentsCopy['default'].extend(_componentLibraryTimelineMixinsChangeFeedEntry['default'], {

		label: 'department',
		oldVal: _ember['default'].computed.oneWay('data.old_department.name'),
		newVal: _ember['default'].computed.oneWay('data.new_department.name')

	});
});