define('component-library/components/contribution-scheme/contribution-scheme-selector', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var CONTRIBUTION_SCHEME_TYPE_VALUES = {
		Percent: {
			label: 'Percent',
			value: 'P',
			imgUrl: '/static/img/oe/contribution-icons_percentage.svg',
			showArrow: true
		},
		Fixed: {
			label: 'Fixed',
			value: 'F',
			imgUrl: '/static/img/oe/contribution-icons_fixed.svg',
			showArrow: true
		},
		PercentOfPlan: {
			label: 'Percent of Base Plan',
			value: 'B',
			imgUrl: '/static/img/oe/contribution-icons_by-plan.svg',
			showArrow: true
		},
		Custom: {
			label: 'Custom',
			value: 'C',
			imgUrl: '/static/img/oe/contribution-icons_custom.svg',
			showArrow: false
		}
	};

	var CONTRIBUTION_SCHEME_TYPES = {
		medical: ['Percent', 'Fixed', 'PercentOfPlan', 'Custom'],
		dental: ['Percent', 'Fixed', 'PercentOfPlan', 'Custom'],
		vision: ['Percent', 'Fixed', 'Custom']
	};

	var EE_ER_BREAKDOWN_CALCULATOR = {
		P: 'calculateBreakdownPercentage',
		F: 'calculateBreakdownFixed',
		B: 'calculateBreakdownPercentOfPlan'
	};

	exports['default'] = _ember['default'].Component.extend({
		contributionTypeValues: (function () {
			var lineOfCoverage = this.get('lineOfCoverage');
			var excludeContributionType = this.get('disableCustom') || this.get('hasAdjustablePlans') ? 'Custom' : '';

			return CONTRIBUTION_SCHEME_TYPES[lineOfCoverage].filter(function (contributionType) {
				return contributionType !== excludeContributionType;
			}).map(function (contributionType) {
				return CONTRIBUTION_SCHEME_TYPE_VALUES[contributionType];
			});
		}).property('disableCustom', 'hasAdjustablePlans', 'lineOfCoverage'),

		isCustom: (function () {
			return this.get('contributionType') === 'C';
		}).property('contributionType'),

		displayContributionBreakdown: false,
		showValidationErrors: false,
		basePlanErrorText: '',
		contributionEmployeeErrorText: '',
		contributionDependentsErrorText: '',
		customTabTemplate: 'components/contribution-scheme/custom-contribution-tab-partial',
		contributionModalAction: null,
		disableCustom: false,
		plans: [],
		selectedPlanRatesAndFactors: null,
		expandedPlanId: _ember['default'].computed.oneWay('selectedPlanRatesAndFactors.plans.firstObject.planId'),
		requiredParticipation: 0,

		isAncillaryLineOfCoverage: _ember['default'].computed('lineOfCoverage', function () {
			return this.get('lineOfCoverage') === 'dental' || this.get('lineOfCoverage') === 'vision';
		}),

		eeFixedElementId: _ember['default'].computed('lineOfCoverage', function () {
			return this.get('lineOfCoverage') + '-fixed-' + Math.floor(Math.random() * 100);
		}),

		eePercentOfPlanElementId: _ember['default'].computed('lineOfCoverage', function () {
			return this.get('lineOfCoverage') + '-percentOfPlan-' + Math.floor(Math.random() * 100);
		}),

		eePercentageElementId: _ember['default'].computed('lineOfCoverage', function () {
			return this.get('lineOfCoverage') + '-percentage-' + Math.floor(Math.random() * 100);
		}),

		// For NGE flow, employeePercentage, employeeFixed and employeePlanPercentage are aliasing the same variable
		// So when the user set employeeFixed to some value greater than 100, and then switch contribution type,
		// we need to make sure the contribution values for percentage are valid
		employeePercentage: _ember['default'].computed('contributionObject.employeePercentage', 'contributionType', {
			get: function get(key) {
				var contributionEmployee = this.get('contributionObject.employeePercentage');
				if (contributionEmployee && contributionEmployee > 100) {
					contributionEmployee = 100;
					this.set('contributionObject.employeePercentage', contributionEmployee);
				}
				return contributionEmployee;
			},

			set: function set(key, value) {
				if (value > 100) {
					value = 100;
				}
				this.set('contributionObject.employeePercentage', value);
				return value;
			}
		}),

		dependentPercentage: _ember['default'].computed('contributionObject.dependentPercentage', 'contributionType', {
			get: function get(key) {
				var contributionDependent = this.get('contributionObject.dependentPercentage');
				if (contributionDependent && contributionDependent > 100) {
					contributionDependent = 100;
					this.set('contributionObject.dependentPercentage', contributionDependent);
				}
				return contributionDependent;
			},

			set: function set(key, value) {
				if (value > 100) {
					value = 100;
				}
				this.set('contributionObject.dependentPercentage', value);
				return value;
			}
		}),

		employeePlanPercentage: _ember['default'].computed('contributionObject.employeePlanPercentage', 'contributionType', {
			get: function get(key) {
				var contributionEmployee = this.get('contributionObject.employeePlanPercentage');
				if (contributionEmployee && contributionEmployee > 100) {
					contributionEmployee = 100;
					this.set('contributionObject.employeePlanPercentage', contributionEmployee);
				}
				return contributionEmployee;
			},

			set: function set(key, value) {
				if (value > 100) {
					value = 100;
				}
				this.set('contributionObject.employeePlanPercentage', value);
				return value;
			}
		}),

		dependentPlanPercentage: _ember['default'].computed('contributionObject.dependentPlanPercentage', 'contributionType', {
			get: function get(key) {
				var contributiondependent = this.get('contributionObject.dependentPlanPercentage');
				if (contributiondependent && contributiondependent > 100) {
					contributiondependent = 100;
					this.set('contributionObject.dependentPlanPercentage', contributiondependent);
				}
				return contributiondependent;
			},

			set: function set(key, value) {
				if (value > 100) {
					value = 100;
				}
				this.set('contributionObject.dependentPlanPercentage', value);
				return value;
			}
		}),

		selectedPlanRateGivenSelectedContributionsChanged: _ember['default'].observer('selectedPlanRateGivenSelectedContribution', 'selectedPlanRateGivenSelectedContribution.[]', 'hasAdjustablePlans', function () {
			var _this = this;

			_ember['default'].run.next(this, function () {
				var container = _ember['default'].$('.js-contributionSchemeEditorDetail-container')[0];
				if (_this.get('hasAdjustablePlans')) {
					_ember['default'].$(container).css('height', _ember['default'].$(container).outerHeight());
				} else {
					_ember['default'].$(container).removeAttr('style');
				}
			});
		}),

		hasAdjustablePlans: (function () {
			if (!this.get('isAncillaryLineOfCoverage') || !this.get('selectedPlanRatesAndFactors.plans.length')) {
				return false;
			}
			return this.get('plans').isAny('adjustableRates');
		}).property('plans.@each.adjustableRates', 'isAncillaryLineOfCoverage', 'selectedPlanRatesAndFactors.plans.length'),

		convertEEContributionValue: function convertEEContributionValue(plan, basePlanInfo) {
			var contributionType = this.get('contributionType');
			if (contributionType === 'P') {
				return this.get('contributionObject.employeePercentage');
			} else if (contributionType === 'F') {
				var contributionEmployee = this.get('contributionObject.employeeFixed');
				if (plan.rates.youPremium) {
					return Math.min(contributionEmployee / plan.rates.youPremium * 100, 100);
				}
				return 0;
			} else if (contributionType === 'B') {
				var contributionEmployee = this.get('contributionObject.employeePlanPercentage');
				if (basePlanInfo && plan.rates.youPremium) {
					return Math.min(contributionEmployee * basePlanInfo.rates.youPremium / plan.rates.youPremium, 100);
				}

				return contributionEmployee;
			}

			return 75;
		},

		selectedPlanRateGivenSelectedContribution: (function () {
			var _this2 = this;

			if (!this.get('hasAdjustablePlans')) {
				return [];
			}

			var rateWithContributionAndFactors = [];
			var breakdownCalculator = EE_ER_BREAKDOWN_CALCULATOR[this.get('contributionType')];
			var basePlanInfo = null;

			if (this.get('contributionType') === 'B') {
				var basePlanId = this.get('basePlan.id');
				basePlanInfo = this.get('selectedPlanRatesAndFactors.plans').findBy('planId', basePlanId);
			}

			var requiredParticipation = 0;

			this.get('selectedPlanRatesAndFactors.plans').forEach(function (planInfo) {
				var factors = planInfo.factors;
				var factor = 1;
				if (factors.length) {
					(function () {
						var eePercentage = Math.floor(Math.max(_this2.convertEEContributionValue(planInfo, basePlanInfo), 0));
						var matchingRule = factors.find(function (rule) {
							return rule.minContribution <= eePercentage && rule.maxContribution >= eePercentage;
						});

						if (matchingRule) {
							factor = matchingRule.rateFactor;
							requiredParticipation = Math.max(requiredParticipation, matchingRule.requiredParticipation);
						}
					})();
				}

				var youPremium = planInfo.rates.youPremium * factor;
				var youAndSpousePremium = planInfo.rates.youAndSpousePremium * factor;
				var youAndChildPremium = planInfo.rates.youAndChildPremium * factor;
				var familyPremium = planInfo.rates.familyPremium * factor;
				var rates = _this2.get(breakdownCalculator)(planInfo.planId, planInfo.displayName, planInfo.rates.isFourElection, youPremium, youAndSpousePremium, youAndChildPremium, familyPremium, _this2.get('contributionObject'), _this2._constructBreakdownObject, basePlanInfo);

				rateWithContributionAndFactors.pushObject(rates);
			});

			this.set('requiredParticipation', requiredParticipation);

			return rateWithContributionAndFactors;
		}).property('selectedPlanRatesAndFactors.plans.[]', 'hasAdjustablePlans', 'contributionType', 'basePlan.id', 'contributionObject.employeePercentage', 'contributionObject.dependentPercentage', 'contributionObject.employeeFixed', 'contributionObject.dependentFixed', 'contributionObject.employeePlanPercentage', 'contributionObject.dependentPlanPercentage'),

		_constructBreakdownObject: function _constructBreakdownObject(planId, planName, isFourElection, youPremiumEE, youPremiumER, youPremium, youAndSpousePremiumEE, youAndSpousePremiumER, youAndSpousePremium, youAndChildPremiumEE, youAndChildPremiumER, youAndChildPremium, familyPremiumEE, familyPremiumER, familyPremium) {
			return {
				id: planId,
				name: planName,
				isFourElection: isFourElection,
				youPremiumEE: Math.max(youPremiumEE, 0).toFixed(2),
				youPremiumER: Math.max(youPremiumER, 0).toFixed(2),
				youPremium: Math.max(youPremium, 0).toFixed(2),
				youAndSpousePremiumEE: Math.max(youAndSpousePremiumEE, 0).toFixed(2),
				youAndSpousePremiumER: Math.max(youAndSpousePremiumER, 0).toFixed(2),
				youAndSpousePremium: Math.max(youAndSpousePremium, 0).toFixed(2),
				youAndChildPremiumEE: Math.max(youAndChildPremiumEE, 0).toFixed(2),
				youAndChildPremiumER: Math.max(youAndChildPremiumER, 0).toFixed(2),
				youAndChildPremium: Math.max(youAndChildPremium, 0).toFixed(2),
				familyPremiumEE: Math.max(familyPremiumEE, 0).toFixed(2),
				familyPremiumER: Math.max(familyPremiumER, 0).toFixed(2),
				familyPremium: Math.max(familyPremium, 0).toFixed(2)
			};
		},

		calculateBreakdownPercentage: function calculateBreakdownPercentage(planId, planName, isFourElection, youPremium, youAndSpousePremium, youAndChildPremium, familyPremium, contributionObject, objectConstructor) {
			var eePercentage = Math.max(contributionObject.get('employeePercentage'), 0);
			var depPercentage = Math.max(contributionObject.get('dependentPercentage'), 0);
			var youPremiumER = youPremium * eePercentage / 100;
			var youPremiumEE = youPremium - youPremiumER;
			var youAndSpousePremiumER = youAndSpousePremium ? youPremiumER + (youAndSpousePremium - youPremium) * depPercentage / 100 : 0;

			var youAndSpousePremiumEE = youAndSpousePremium ? youAndSpousePremium - youAndSpousePremiumER : 0;
			var youAndChildPremiumER = youAndChildPremium ? youPremiumER + (youAndChildPremium - youPremium) * depPercentage / 100 : 0;

			var youAndChildPremiumEE = youAndChildPremium ? youAndChildPremium - youAndChildPremiumER : 0;
			var familyPremiumER = familyPremium ? youPremiumER + (familyPremium - youPremium) * depPercentage / 100 : 0;
			var familyPremiumEE = familyPremium ? familyPremium - familyPremiumER : 0;

			return objectConstructor(planId, planName, isFourElection, youPremiumEE, youPremiumER, youPremium, youAndSpousePremiumEE, youAndSpousePremiumER, youAndSpousePremium, youAndChildPremiumEE, youAndChildPremiumER, youAndChildPremium, familyPremiumEE, familyPremiumER, familyPremium);
		},

		calculateBreakdownFixed: function calculateBreakdownFixed(planId, planName, isFourElection, youPremium, youAndSpousePremium, youAndChildPremium, familyPremium, contributionObject, objectConstructor) {
			var eeFixed = Math.max(contributionObject.get('employeeFixed'), 0);
			var depFixed = Math.max(contributionObject.get('dependentFixed'), 0);
			var youPremiumER = Math.min(youPremium, eeFixed);
			var youPremiumEE = youPremium - youPremiumER;
			var youAndSpousePremiumER = youAndSpousePremium ? youPremiumER + Math.min(depFixed, youAndSpousePremium - youPremium) : 0;

			var youAndSpousePremiumEE = youAndSpousePremium ? youAndSpousePremium - youAndSpousePremiumER : 0;
			var youAndChildPremiumER = youAndChildPremium ? youPremiumER + Math.min(depFixed, youAndChildPremium - youPremium) : 0;
			var youAndChildPremiumEE = youAndChildPremium ? youAndChildPremium - youAndChildPremiumER : 0;
			var familyPremiumER = familyPremium ? youPremiumER + Math.min(depFixed, familyPremium - youPremium) : 0;
			var familyPremiumEE = familyPremium ? familyPremium - familyPremiumER : 0;

			return objectConstructor(planId, planName, isFourElection, youPremiumEE, youPremiumER, youPremium, youAndSpousePremiumEE, youAndSpousePremiumER, youAndSpousePremium, youAndChildPremiumEE, youAndChildPremiumER, youAndChildPremium, familyPremiumEE, familyPremiumER, familyPremium);
		},

		calculateBreakdownPercentOfPlan: function calculateBreakdownPercentOfPlan(planId, planName, isFourElection, youPremium, youAndSpousePremium, youAndChildPremium, familyPremium, contributionObject, objectConstructor, basePlanInfo) {
			var eePercentage = Math.max(contributionObject.get('employeePlanPercentage'), 0);
			var depPercentage = Math.max(contributionObject.get('dependentPlanPercentage'), 0);
			var baseYouPremium = 0;
			var baseYouAndSpousePremium = 0;
			var baseYouAndChildPremium = 0;
			var baseFamilyPremium = 0;

			if (basePlanInfo) {
				baseYouPremium = basePlanInfo.rates.youPremium;
				baseYouAndSpousePremium = basePlanInfo.rates.youAndSpousePremium;
				baseYouAndChildPremium = basePlanInfo.rates.youAndChildPremium;
				baseFamilyPremium = basePlanInfo.rates.familyPremium;
			}

			var youPremiumER = Math.min(youPremium, baseYouPremium * eePercentage / 100);
			var youPremiumEE = youPremium - youPremiumER;

			var youAndSpousePremiumER = youAndSpousePremium ? youPremiumER + Math.min((baseYouAndSpousePremium - baseYouPremium) * depPercentage / 100, youAndSpousePremium - youPremium) : 0;

			var youAndSpousePremiumEE = youAndSpousePremium ? youAndSpousePremium - youAndSpousePremiumER : 0;

			var youAndChildPremiumER = youAndChildPremium ? youPremiumER + Math.min((baseYouAndChildPremium - baseYouPremium) * depPercentage / 100, youAndChildPremium - youPremium) : 0;

			var youAndChildPremiumEE = youAndChildPremium ? youAndChildPremium - youAndChildPremiumER : 0;

			var familyPremiumER = familyPremium ? youPremiumER + Math.min((baseFamilyPremium - baseYouPremium) * depPercentage / 100, familyPremium - youPremium) : 0;

			var familyPremiumEE = familyPremium ? familyPremium - familyPremiumER : 0;

			return objectConstructor(planId, planName, isFourElection, youPremiumEE, youPremiumER, youPremium, youAndSpousePremiumEE, youAndSpousePremiumER, youAndSpousePremium, youAndChildPremiumEE, youAndChildPremiumER, youAndChildPremium, familyPremiumEE, familyPremiumER, familyPremium);
		},

		actions: {
			seeContributionBreakdown: function seeContributionBreakdown() {
				if (this.get('contributionModalAction')) {
					this.sendAction('contributionModalAction');
				}
			},

			selectPlanInfoToExpand: function selectPlanInfoToExpand(id) {
				this.set('expandedPlanId', id);
			}
		}
	});
});