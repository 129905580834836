define('component-library/components/z-status-tag/status-popover', ['exports', 'ember', 'component-library/components/promise-popover', 'component-library/templates/components/z-status-tag/status-popover'], function (exports, _ember, _componentLibraryComponentsPromisePopover, _componentLibraryTemplatesComponentsZStatusTagStatusPopover) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
		template: _componentLibraryTemplatesComponentsZStatusTagStatusPopover['default'],
		attachment: 'top left',
		targetAttachment: 'bottom left',

		buttonsPerRow: 2,
		closeDelay: 1,
		closeOnClickHandler: false,

		statusPopoverClassNames: '',

		statusPopoverClassNamesComputed: _ember['default'].computed('content.addLeftBumper', function () {
			var statusPopoverClassNames = this.get('statusPopoverClassNames');
			var statusPopoverClassNamesArray = statusPopoverClassNames ? statusPopoverClassNames.split(' ') : [];

			var classNames = ['z-popover-panel', 'status-tag-popover', 'vertical'].concat(statusPopoverClassNamesArray);

			if (this.get('content.addLeftBumper')) {
				classNames.push('u-bumperLeft--md');
			}

			return classNames.join(' ');
		}),

		buttonRows: _ember['default'].computed('content.buttons', 'buttonsPerRow', function () {
			var rows = [];
			var allButtons = this.get('content.buttons');
			var buttonsPerRow = this.get('buttonsPerRow');

			allButtons.forEach(function (button, index) {
				var bucketIndex = parseInt(index / buttonsPerRow);
				if (bucketIndex >= rows.length) {
					rows.push([]);
				}

				rows[bucketIndex].push(button);
			});

			return rows;
		}),

		actions: {
			onClick: function onClick(callbackFunction) {
				this.send('close');
				callbackFunction();
			}
		}
	});
});