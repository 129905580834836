define('z-inputs/components/z-text-field-ein', ['exports', 'z-inputs/components/z-text-field-base', 'z-inputs/templates/components/z-text-field-basic'], function (exports, _zInputsComponentsZTextFieldBase, _zInputsTemplatesComponentsZTextFieldBasic) {
  'use strict';

  exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend({
    layout: _zInputsTemplatesComponentsZTextFieldBasic['default'],
    classNames: 'z-textField--ein',
    type: 'ein',
    mask: '99-9999999',
    tooltipText: null
  });
});