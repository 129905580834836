define('component-library/components/contributions-modal/index', ['exports', 'ember', 'component-library/components/notification-modal', 'component-library/components/contributions-modal/utils', 'component-library/components/tabbed-modal/utils', 'component-library/utils/globals', 'component-library/components/contributions-modal/constants'], function (exports, _ember, _componentLibraryComponentsNotificationModal, _componentLibraryComponentsContributionsModalUtils, _componentLibraryComponentsTabbedModalUtils, _componentLibraryUtilsGlobals, _componentLibraryComponentsContributionsModalConstants) {
	'use strict';

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
		} else {
			obj[key] = value;
		}return obj;
	}

	exports['default'] = _ember['default'].Component.extend({
		// =================== VARIABLES ========================
		TABBED_MODAL_MODES: _componentLibraryComponentsTabbedModalUtils.TABBED_MODAL_MODES,
		store: _ember['default'].inject.service(),
		// CONTRIBUTION FIELDS
		contributionTypeSelection: null,
		contributionMethodSelection: null,
		dependentContributionTypeSelection: null,
		dependentContributionUniformSelection: null,
		dependentContributionCountSelection: null,
		totalContributionTotalSelection: null,
		contributionTypes: _componentLibraryComponentsContributionsModalConstants.CONTRIBUTION_CHOICES,
		// DEDUCTIONS FIELDS
		deductionTypeSelection: null,
		deductionMethodSelection: null,
		dependentDeductionTypeSelection: null,
		dependentDeductionUniformSelection: null,
		dependentDeductionCountSelection: null,
		totalDeductionTotalSelection: null,
		deductionTypes: _componentLibraryComponentsContributionsModalConstants.DEDUCTIONS_CHOICES,
		// COMMON FIELDS
		readOnlyView: false,
		allowSchemePublish: false,
		isCSModalOpen: false,
		isDeductionsEnabled: false,
		isWaterFall: false,
		currentEligibilitySelection: null,
		selectedTypeDefaultValue: null,
		coverageSelection: null,
		selectedEmployees: null,
		selectedTiers: null,
		ruleInEditing: null,
		schemePublishedSuccessfully: null,
		schemePublishErrorText: null,
		displayNotificationBanners: false,
		waterFallAmount: 1,
		numberOfEmployees: _ember['default'].computed.alias('employees.length'),
		schemeCopy: _ember['default'].computed.oneWay('scheme'),
		rules: _ember['default'].computed.alias('schemeCopy.rules'),
		locs: _ember['default'].computed('mapping', function () {
			var mapping = this.get('mapping') || {};
			return Object.keys(mapping).filter(function (loc) {
				return Object.keys(mapping[loc]).length;
			});
		}),
		schemeInTheFuture: _ember['default'].computed('scheme.effectiveDate', function () {
			return (0, _componentLibraryUtilsGlobals.moment)(this.get('scheme.effectiveDate')) > (0, _componentLibraryUtilsGlobals.moment)();
		}),
		typeSelectOptions: _ember['default'].computed('types.[]', 'plans.[]', 'coverageSelection.selectedLoc.id', function () {
			var typesData = _componentLibraryComponentsContributionsModalUtils['default'].allTypes(this.get('plans'), this.get('coverageSelection.selectedLoc.id'));
			var sortedTypes = _componentLibraryComponentsContributionsModalConstants.TYPES.concat(Object.keys(typesData).filter(function (obj) {
				return !_componentLibraryComponentsContributionsModalConstants.TYPES.includes(obj);
			}));
			return sortedTypes.map(function (d) {
				return {
					id: typesData[d].type,
					text: typesData[d].label,
					description: ''
				};
			});
		}),
		getCoverageSelection: _ember['default'].computed('coverageSelection.selectedLoc.id', 'coverageSelection.selectedCarrier.id', 'coverageSelection.selectedPlan.id', function () {
			return {
				lineOfCoverage: this.get('coverageSelection.selectedLoc.id'),
				carrierId: this.get('coverageSelection.selectedCarrier.id'),
				planId: this.get('coverageSelection.selectedPlan.id')
			};
		}),
		isAllCoverageSelected: _ember['default'].computed('coverageSelection.selectedLoc.id', 'coverageSelection.selectedCarrier.id', 'coverageSelection.selectedPlan.id', function () {
			return _ember['default'].isNone(this.get('coverageSelection.selectedLoc.id')) && _ember['default'].isNone(this.get('coverageSelection.selectedCarrier.id')) && _ember['default'].isNone(this.get('coverageSelection.selectedPlan.id'));
		}),
		currentRuleId: _ember['default'].computed('ruleInEditing', function () {
			var rule = this.get('ruleInEditing');
			return rule ? rule.get('id') : null;
		}),
		generateContributionTypeDefaults: function generateContributionTypeDefaults() {
			return _ember['default'].Object.create({
				selectedType: this.get('selectedTypeDefaultValue'),
				data: _componentLibraryComponentsContributionsModalUtils['default'].allTypes(this.get('plans'), this.get('coverageSelection.selectedLoc.id'))[this.get('selectedTypeDefaultValue.id')]
			});
		},
		getDeductionOrContributionHelper: function getDeductionOrContributionHelper(deductionOrContribution) {
			//deductionOrContribution can be Deduction or Contribution String
			var selectedType = this.get(deductionOrContribution.toLowerCase() + 'TypeSelection.id');
			var selectedMethod = this.get(deductionOrContribution.toLowerCase() + 'MethodSelection.id');
			var employeeFormulaData = {};
			var dependentsFormulaData = {};
			var totalFormulaData = {};
			var returnTotal = false;
			var commonRuleData = {
				type: selectedType,
				lineSpanningCap: null,
				ignoresEmployeeAndDependents: true
			};
			var employeeData = Object.assign({}, commonRuleData);
			var dependentsData = Object.assign({}, commonRuleData);
			var totalData = Object.assign({}, commonRuleData);
			switch (selectedType) {
				case _componentLibraryComponentsContributionsModalConstants.UNIFORM:
					{
						var dependentsSelectedType = this.get('dependent' + deductionOrContribution + 'UniformSelection.' + _componentLibraryComponentsContributionsModalConstants.UNIFORM + '.selectedType.id');
						var employeeSelectedType = this.get('dependent' + deductionOrContribution + 'UniformSelection.' + _componentLibraryComponentsContributionsModalConstants.EMPLOYEE + '.selectedType.id');
						dependentsFormulaData = _componentLibraryComponentsContributionsModalUtils['default'].contributionsFormulaFilter(this.get('dependent' + deductionOrContribution + 'UniformSelection.' + _componentLibraryComponentsContributionsModalConstants.UNIFORM + '.data.' + dependentsSelectedType));
						employeeFormulaData = _componentLibraryComponentsContributionsModalUtils['default'].contributionsFormulaFilter(this.get('dependent' + deductionOrContribution + 'UniformSelection.' + _componentLibraryComponentsContributionsModalConstants.EMPLOYEE + '.data.' + employeeSelectedType));
						break;
					}
				case _componentLibraryComponentsContributionsModalConstants.TOTAL:
					{
						returnTotal = true;
						var totalSelectedType = this.get('total' + deductionOrContribution + 'TotalSelection.' + _componentLibraryComponentsContributionsModalConstants.TOTAL + '.selectedType.id');
						totalFormulaData = _componentLibraryComponentsContributionsModalUtils['default'].contributionsFormulaFilter(this.get('total' + deductionOrContribution + 'TotalSelection.' + _componentLibraryComponentsContributionsModalConstants.TOTAL + '.data.' + totalSelectedType));
						break;
					}
				case _componentLibraryComponentsContributionsModalConstants.DEPENDENT_TYPE:
					{
						var dependentType = this.get('dependent' + deductionOrContribution + 'TypeSelection');
						var employeeSelectedType = this.get('dependent' + deductionOrContribution + 'TypeSelection.' + _componentLibraryComponentsContributionsModalConstants.EMPLOYEE + '.selectedType.id');
						var spouseSelectedType = this.get('dependent' + deductionOrContribution + 'TypeSelection.' + _componentLibraryComponentsContributionsModalConstants.SPOUSE + '.selectedType.id');
						var childSelectedType = this.get('dependent' + deductionOrContribution + 'TypeSelection.' + _componentLibraryComponentsContributionsModalConstants.CHILD + '.selectedType.id');
						var familySelectedType = this.get('dependent' + deductionOrContribution + 'TypeSelection.' + _componentLibraryComponentsContributionsModalConstants.FAMILY + '.selectedType.id');
						employeeFormulaData = _componentLibraryComponentsContributionsModalUtils['default'].contributionsFormulaFilter(dependentType[_componentLibraryComponentsContributionsModalConstants.EMPLOYEE].data[employeeSelectedType]);
						dependentsFormulaData = {
							employeeOnly: _componentLibraryComponentsContributionsModalUtils['default'].contributionsFormulaFilter(dependentType[_componentLibraryComponentsContributionsModalConstants.EMPLOYEE].data[employeeSelectedType]),
							employeeAndChildren: _componentLibraryComponentsContributionsModalUtils['default'].contributionsFormulaFilter(dependentType[_componentLibraryComponentsContributionsModalConstants.CHILD].data[childSelectedType]),
							employeeAndSpouse: _componentLibraryComponentsContributionsModalUtils['default'].contributionsFormulaFilter(dependentType[_componentLibraryComponentsContributionsModalConstants.SPOUSE].data[spouseSelectedType]),
							employeeAndChild: _componentLibraryComponentsContributionsModalUtils['default'].contributionsFormulaFilter(dependentType[_componentLibraryComponentsContributionsModalConstants.CHILD].data[childSelectedType]),
							employeeAndChildAndSpouse: _componentLibraryComponentsContributionsModalUtils['default'].contributionsFormulaFilter(dependentType[_componentLibraryComponentsContributionsModalConstants.FAMILY].data[familySelectedType]),
							family: _componentLibraryComponentsContributionsModalUtils['default'].contributionsFormulaFilter(dependentType[_componentLibraryComponentsContributionsModalConstants.FAMILY].data[familySelectedType])
						};
						if (selectedMethod === _componentLibraryComponentsContributionsModalConstants.TOTAL) {
							returnTotal = true;
							totalFormulaData = dependentsFormulaData;
						}
						break;
					}
				case _componentLibraryComponentsContributionsModalConstants.DEPENDENT_COUNT:
					{
						var dependentType = this.get('dependent' + deductionOrContribution + 'CountSelection');
						var employeeSelectedType = this.get('dependent' + deductionOrContribution + 'CountSelection.' + _componentLibraryComponentsContributionsModalConstants.EMPLOYEE + '.selectedType.id');
						employeeFormulaData = _componentLibraryComponentsContributionsModalUtils['default'].contributionsFormulaFilter(dependentType[_componentLibraryComponentsContributionsModalConstants.EMPLOYEE].data[employeeSelectedType]);
						dependentsFormulaData = this.get('dependent' + deductionOrContribution + 'CountSelection.' + _componentLibraryComponentsContributionsModalConstants.DEPENDENTS).reduce(function (p, c) {
							p[c.count] = _componentLibraryComponentsContributionsModalUtils['default'].contributionsFormulaFilter(c.data[c.selectedType.id]);
							return p;
						}, {});
						dependentsFormulaData[0] = employeeFormulaData;
						if (selectedMethod === _componentLibraryComponentsContributionsModalConstants.TOTAL) {
							returnTotal = true;
							totalFormulaData = dependentsFormulaData;
						}
						break;
					}
				default:
					break;
			}
			return _ember['default'].Object.create({
				employee: Object.assign(employeeData, employeeFormulaData),
				dependents: Object.assign(dependentsData, dependentsFormulaData),
				total: returnTotal ? Object.assign(totalData, totalFormulaData) : null
			});
		},
		getContribution: function getContribution() {
			return this.getDeductionOrContributionHelper(_componentLibraryComponentsContributionsModalConstants.CONTRIBUTION);
		},
		getDeduction: function getDeduction() {
			return this.getDeductionOrContributionHelper(_componentLibraryComponentsContributionsModalConstants.DEDUCTION);
		},
		getEligibility: function getEligibility() {
			var _this = this;

			var currentSelectedEligibility = this.get('currentEligibilitySelection');
			var lineOfCoverage = this.get('coverageSelection.selectedLoc.id');
			var employeeTemplate = {
				lineOfCoverage: lineOfCoverage,
				overrideFormulas: true
			};
			if (_ember['default'].isEqual(currentSelectedEligibility, 'allEmployees')) {
				return Object.assign({
					type: 'Undefined'
				}, employeeTemplate);
			}
			if (_ember['default'].isEqual(currentSelectedEligibility, _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.TIER)) {
				var _ret = (function () {
					var selectedTiers = _this.get('selectedTiers').mapBy('id');
					return {
						v: Object.assign({
							type: _componentLibraryComponentsContributionsModalConstants.IN_CONTRIBUTION_TIERS,
							tiers: selectedTiers,
							lineOfCoverage: _this.get('tiers').filter(function (obj) {
								return _ember['default'].isEqual(obj.name, selectedTiers[0]);
							}).get('firstObject.lineOfCoverage')
						}, employeeTemplate)
					};
				})();

				if (typeof _ret === 'object') return _ret.v;
			}
			if (_ember['default'].isEqual(currentSelectedEligibility, _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.EMPLOYEE)) {
				return Object.assign({
					type: _componentLibraryComponentsContributionsModalConstants.IN_EMPLOYEE_IDS,
					employeeIds: this.get('selectedEmployees').mapBy('id')
				}, employeeTemplate);
			}
		},
		getWaterFallRules: function getWaterFallRules() {
			var _this2 = this;

			var scheme = this.get('schemeCopy');
			var orderedLines = this.get('locs').sort(_componentLibraryComponentsContributionsModalConstants.lineOfCoverageComparator);
			var amount = this.get('waterFallAmount');
			var firstLine = orderedLines[0];
			var restOfLines = orderedLines.slice(1);
			var eligibility = this.getEligibility();
			var firstContributionSchemeRule = {
				scheme: scheme,
				lineOfCoverage: firstLine,
				carrierId: null,
				planId: null,
				template: {
					totalContribution: {
						type: 'Fixed',
						fixedAmount: amount
					},
					overrideFormulas: true
				},
				employeeTemplate: eligibility
			};
			var remainingRules = restOfLines.map(function (line, index) {
				return {
					scheme: scheme,
					lineOfCoverage: line,
					carrierId: null,
					planId: null,
					template: {
						totalContribution: {
							type: 'Percentage',
							percentage: '100',
							lineSpanningCap: {
								value: amount,
								lines: orderedLines.slice(0, index + 1)
							}
						},
						overrideFormulas: true
					},
					employeeTemplate: _this2.getEligibility()
				};
			});
			return [firstContributionSchemeRule].concat(remainingRules).reverse();
		},
		reInitDeductionOrContributionHelper: function reInitDeductionOrContributionHelper(deductionOrContribution) {
			//deductionOrContribution can be Deduction or Contribution String
			var deductionOrContributionLowerCase = deductionOrContribution.toLowerCase();
			this.set(deductionOrContributionLowerCase + 'TypeSelection', this.get(deductionOrContributionLowerCase + 'Types.firstObject'));
			this.set('dependent' + deductionOrContribution + 'UniformSelection', _ember['default'].Object.create({
				EMPLOYEE: this.generateContributionTypeDefaults(),
				UNIFORM: this.generateContributionTypeDefaults()
			}));
			this.set('total' + deductionOrContribution + 'TotalSelection', _ember['default'].Object.create({
				TOTAL: this.generateContributionTypeDefaults()
			}));
			this.set('dependent' + deductionOrContribution + 'TypeSelection', _ember['default'].Object.create({
				EMPLOYEE: this.generateContributionTypeDefaults(),
				SPOUSE: this.generateContributionTypeDefaults(),
				CHILD: this.generateContributionTypeDefaults(),
				FAMILY: this.generateContributionTypeDefaults()
			}));
			this.set('dependent' + deductionOrContribution + 'CountSelection', _ember['default'].Object.create({
				EMPLOYEE: this.generateContributionTypeDefaults(),
				DEPENDENTS: [Object.assign({ count: 1 }, this.generateContributionTypeDefaults())]
			}));
		},
		reInitDetails: function reInitDetails() {
			this.set('coverageSelection', _ember['default'].Object.create({
				selectedLoc: _ember['default'].Object.create({
					id: null,
					text: 'All'
				}),
				selectedCarrier: _ember['default'].Object.create({
					id: null,
					text: 'All'
				}),
				selectedPlan: _ember['default'].Object.create({
					id: null,
					text: 'All'
				})
			}));
		},
		reInitEligibility: function reInitEligibility() {
			this.set('selectedEmployees', []);
			this.set('selectedTiers', []);
			this.set('currentEligibilitySelection', _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.ALL);
		},
		reInitContribution: function reInitContribution() {
			this.reInitDeductionOrContributionHelper(_componentLibraryComponentsContributionsModalConstants.CONTRIBUTION);
		},
		reInitDeduction: function reInitDeduction() {
			this.reInitDeductionOrContributionHelper(_componentLibraryComponentsContributionsModalConstants.DEDUCTION);
		},
		reInitializeTheCSModal: function reInitializeTheCSModal() {
			var details = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];
			var eligibility = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];
			var contribution = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];
			var deduction = arguments.length <= 3 || arguments[3] === undefined ? true : arguments[3];

			this.set('ruleInEditing', null);
			this.set('isDeductionsEnabled', false);
			this.set('waterFallAmount', 1);
			this.set('isWaterFall', false);
			this.set('selectedTypeDefaultValue', this.get('typeSelectOptions.firstObject'));
			this.set('contributionMethodSelection', _componentLibraryComponentsContributionsModalConstants.CONTRIBUTION_METHODS_CHOICES[0]);
			this.set('deductionMethodSelection', _componentLibraryComponentsContributionsModalConstants.CONTRIBUTION_METHODS_CHOICES[0]);
			if (details) {
				this.reInitDetails();
			}
			if (eligibility) {
				this.reInitEligibility();
			}
			if (contribution) {
				this.reInitContribution();
			}
			if (deduction) {
				this.reInitDeduction();
			}
		},
		getSchemeCopyPromise: function getSchemeCopyPromise(scheme) {
			var _this3 = this;

			return _ember['default'].ajaxPost('/custom_api/contribution_scheme/copy/' + scheme.get('id'), {
				effectiveDate: (0, _componentLibraryUtilsGlobals.moment)(this.get('effectiveDate')).format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT)
			}).then(function (schemeCopyResponse) {
				return _this3.get('store').find('ContributionScheme', schemeCopyResponse.schemeId).then(function (newScheme) {
					if (_this3.attrs.hasOwnProperty('onNewScheme')) {
						_this3.attrs.onNewScheme(newScheme);
					}
					return _ember['default'].RSVP.resolve(newScheme);
				});
			});
		},
		getRuleStringForComparison: function getRuleStringForComparison(rule) {
			return JSON.stringify(rule, _componentLibraryComponentsContributionsModalConstants.RULE_FIELDS_FOR_COMPARING);
		},
		getNewRule: function getNewRule(newScheme, oldRule) {
			var _this4 = this;

			var oldRuleString = this.getRuleStringForComparison(oldRule);
			return newScheme.get('rules').filter(function (rule) {
				return _this4.getRuleStringForComparison(rule) == oldRuleString;
			}).get('firstObject');
		},
		putTheSchemeInTheEditMode: function putTheSchemeInTheEditMode(scheme) {
			var _this5 = this;

			if (!scheme.get('isEditable')) {
				return this.getSchemeCopyPromise(scheme).then(function (newScheme) {
					_this5.set('schemeCopy', newScheme);
				});
			}
			return _ember['default'].RSVP.resolve(null);
		},
		createNewRule: function createNewRule(scheme, rule) {
			var _this6 = this;

			if (scheme.get('isEditable')) {
				return this.get('store').createRecord('ContributionSchemeRule', rule).save();
			} else {
				return this.getSchemeCopyPromise(scheme).then(function (newScheme) {
					rule['scheme'] = newScheme;
					return _this6.get('store').createRecord('ContributionSchemeRule', rule).save().then(function () {
						_this6.set('schemeCopy', newScheme);
					});
				});
			}
		},
		fillDetailsTab: function fillDetailsTab(rule) {
			var loc = rule.get('lineOfCoverage');
			var locFirstCharUpper = loc.charAt(0).toUpperCase() + loc.slice(1);
			var carrierId = rule.get('carrierId');
			var planId = rule.get('planId');
			var carrier = this.get('carriers').find(function (obj) {
				return obj.get('id') == carrierId && obj.get('is' + locFirstCharUpper + 'Carrier') == true;
			});
			var plan = this.get('plans').find(function (obj) {
				return obj.get('id') == planId && obj.get('lineOfCoverage') == loc;
			});

			// if the plan doesn't exist put planId as null, same goes for the carrierId
			planId = plan ? planId : null;
			carrierId = carrier ? carrierId : null;

			var carrierName = carrier ? carrier.get('displayName') : 'All';
			var planName = plan ? plan.get('displayName') : 'All';

			this.set('coverageSelection', _ember['default'].Object.create({
				selectedLoc: _componentLibraryComponentsContributionsModalConstants.LINE_OF_COVERAGES_CHOICES.find(function (obj) {
					return obj.id == loc;
				}),
				selectedCarrier: _ember['default'].Object.create({
					id: carrierId,
					text: carrierName
				}),
				selectedPlan: _ember['default'].Object.create({
					id: planId,
					text: planName
				})
			}));
		},
		fillEligibilityTab: function fillEligibilityTab(rule) {
			var _this7 = this;

			var employeeTemplate = rule.get('employeeTemplate');
			switch (employeeTemplate.type) {
				case _componentLibraryComponentsContributionsModalConstants.IN_CONTRIBUTION_TIERS:
					{
						var tiers = employeeTemplate.tiers.map(function (tierName) {
							return { id: tierName, text: tierName };
						});
						this.set('currentEligibilitySelection', _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.TIER);
						this.set('selectedTiers', tiers);
						break;
					}
				case _componentLibraryComponentsContributionsModalConstants.IN_EMPLOYEE_IDS:
					{
						var _ret2 = (function () {
							_this7.set('currentEligibilitySelection', _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.EMPLOYEE);
							var employeeIds = employeeTemplate.employeeIds.map(function (eId) {
								return parseInt(eId);
							});
							var selectedEmployees = _this7.get('employees').filter(function (e) {
								return employeeIds.includes(e.get('id'));
							}).map(function (item) {
								return {
									id: item.get('id'),
									text: item.get('fullName'),
									description: ''
								};
							});
							_this7.set('selectedEmployees', selectedEmployees);
							return 'break';
						})();

						if (_ret2 === 'break') break;
					}
				default:
					this.set('currentEligibilitySelection', _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.ALL);
					break;
			}
		},
		fillBaseRulesData: function fillBaseRulesData(selectionVariable, toWhome, ruleTemplate) {
			var ruleType = _componentLibraryComponentsContributionsModalUtils['default'].getRuleTypeForSelection(ruleTemplate);
			var ruleTypeSelection = _componentLibraryComponentsContributionsModalUtils['default'].getRuleTypeHelper(ruleTemplate.type);
			var data = _componentLibraryComponentsContributionsModalUtils['default'].allTypes(this.get('plans'), this.get('coverageSelection.selectedLoc.id'))[ruleType];
			this.set(selectionVariable + '.' + toWhome + '.selectedType', this.get('typeSelectOptions').find(function (obj) {
				return obj.id == ruleType;
			}));
			switch (ruleTypeSelection) {
				case _componentLibraryComponentsContributionsModalConstants.FIXED:
					{
						data.fixedAmount = ruleTemplate.fixedAmount || 0;
						this.set(selectionVariable + '.' + toWhome + '.selectedType', this.get('typeSelectOptions').find(function (obj) {
							return obj.id == ruleTypeSelection;
						}));
						this.set(selectionVariable + '.' + toWhome + '.data.' + ruleType, data);
						break;
					}
				case _componentLibraryComponentsContributionsModalConstants.PERCENTAGE:
					{
						data.percentage = ruleTemplate.percentage || 0;
						data.cap = ruleTemplate.cap;
						this.set(selectionVariable + '.' + toWhome + '.data.' + ruleType, data);
						break;
					}
				case _componentLibraryComponentsContributionsModalConstants.PERCENTAGE_OF_PLAN_RAW:
					{
						data.percentage = ruleTemplate.percentage || 0;
						data.cap = ruleTemplate.cap;
						this.set(selectionVariable + '.' + toWhome + '.data.' + ruleType, data);
						break;
					}
				default:
					break;
			}
		},
		fillContributionOrDeductionHelper: function fillContributionOrDeductionHelper(rule, contributionOrDeduction) {
			var _this8 = this;

			var contributionOrDeductionLowerCase = contributionOrDeduction.toLowerCase();
			var dependentRuleType = contributionOrDeduction == _componentLibraryComponentsContributionsModalConstants.CONTRIBUTION ? _componentLibraryComponentsContributionsModalUtils['default'].getRuleContributionType(rule) : _componentLibraryComponentsContributionsModalUtils['default'].getRuleDeductionType(rule);
			this.set(contributionOrDeductionLowerCase + 'TypeSelection', this.get(contributionOrDeductionLowerCase + 'Types').find(function (obj) {
				return obj.id == dependentRuleType;
			}));
			switch (dependentRuleType) {
				case _componentLibraryComponentsContributionsModalConstants.UNIFORM:
					{
						var employeeTemplate = rule.get('template.employee' + contributionOrDeduction);
						var dependentsTemplate = rule.get('template.dependents' + contributionOrDeduction);
						this.fillBaseRulesData('dependent' + contributionOrDeduction + 'UniformSelection', _componentLibraryComponentsContributionsModalConstants.EMPLOYEE, employeeTemplate);
						this.fillBaseRulesData('dependent' + contributionOrDeduction + 'UniformSelection', _componentLibraryComponentsContributionsModalConstants.UNIFORM, dependentsTemplate);
						break;
					}
				case _componentLibraryComponentsContributionsModalConstants.TOTAL:
					{
						var totalTemplate = rule.get('template.total' + contributionOrDeduction);
						this.fillBaseRulesData('total' + contributionOrDeduction + 'TotalSelection', _componentLibraryComponentsContributionsModalConstants.TOTAL, totalTemplate);
						break;
					}
				case _componentLibraryComponentsContributionsModalConstants.DEPENDENT_TYPE:
					{
						var totalTypeTemplate = rule.get('template.total' + contributionOrDeduction);
						var dependentsTemplate = rule.get('template.dependents' + contributionOrDeduction);
						if (totalTypeTemplate.type != _componentLibraryComponentsContributionsModalConstants.UNDEFINED) {
							this.set(contributionOrDeductionLowerCase + 'MethodSelection', _componentLibraryComponentsContributionsModalConstants.CONTRIBUTION_METHODS_CHOICES[0]);
							dependentsTemplate = totalTypeTemplate;
						} else {
							this.set(contributionOrDeductionLowerCase + 'MethodSelection', _componentLibraryComponentsContributionsModalConstants.CONTRIBUTION_METHODS_CHOICES[1]);
						}
						this.fillBaseRulesData('dependent' + contributionOrDeduction + 'TypeSelection', _componentLibraryComponentsContributionsModalConstants.EMPLOYEE, dependentsTemplate.employeeOnly);
						this.fillBaseRulesData('dependent' + contributionOrDeduction + 'TypeSelection', _componentLibraryComponentsContributionsModalConstants.SPOUSE, dependentsTemplate.employeeAndSpouse);
						this.fillBaseRulesData('dependent' + contributionOrDeduction + 'TypeSelection', _componentLibraryComponentsContributionsModalConstants.CHILD, dependentsTemplate.employeeAndChild);
						this.fillBaseRulesData('dependent' + contributionOrDeduction + 'TypeSelection', _componentLibraryComponentsContributionsModalConstants.FAMILY, dependentsTemplate.family);
						break;
					}
				case _componentLibraryComponentsContributionsModalConstants.DEPENDENT_COUNT:
					{
						var _ret3 = (function () {
							_this8.set('dependent' + contributionOrDeduction + 'CountSelection.' + _componentLibraryComponentsContributionsModalConstants.DEPENDENTS, []);
							var totalTypeTemplate = rule.get('template.total' + contributionOrDeduction);
							if (totalTypeTemplate.type == _componentLibraryComponentsContributionsModalConstants.UNDEFINED) {
								totalTypeTemplate = rule.get('template.dependents' + contributionOrDeduction);
								_this8.set(contributionOrDeductionLowerCase + 'MethodSelection', _componentLibraryComponentsContributionsModalConstants.CONTRIBUTION_METHODS_CHOICES[1]);
							} else {
								_this8.set(contributionOrDeductionLowerCase + 'MethodSelection', _componentLibraryComponentsContributionsModalConstants.CONTRIBUTION_METHODS_CHOICES[0]);
							}
							_this8.fillBaseRulesData('dependent' + contributionOrDeduction + 'CountSelection', _componentLibraryComponentsContributionsModalConstants.EMPLOYEE, totalTypeTemplate[0]);
							var dependentsCount = totalTypeTemplate.cases.slice(1);
							dependentsCount.forEach(function (indx) {
								var ruleTemplate = totalTypeTemplate[indx];
								var ruleType = _componentLibraryComponentsContributionsModalUtils['default'].getRuleTypeForSelection(ruleTemplate);
								var ruleData = _componentLibraryComponentsContributionsModalUtils['default'].allTypes(_this8.get('plans'), _this8.get('coverageSelection.selectedLoc.id'))[ruleType];
								if (ruleType == _componentLibraryComponentsContributionsModalConstants.FIXED) {
									ruleData.fixedAmount = ruleTemplate.fixedAmount || 0;
								} else {
									ruleData.percentage = ruleTemplate.percentage || 0;
									ruleData.cap = ruleTemplate.cap;
								}
								_this8.get('dependent' + contributionOrDeduction + 'CountSelection.' + _componentLibraryComponentsContributionsModalConstants.DEPENDENTS).pushObject(_ember['default'].Object.create({
									count: indx,
									selectedType: _this8.get('typeSelectOptions').find(function (obj) {
										return obj.id == ruleType;
									}),
									data: _defineProperty({}, ruleType, ruleData)
								}));
							});
							return 'break';
						})();

						if (_ret3 === 'break') break;
					}
				default:
					{
						break;
					}
			}
		},
		fillContributionsTab: function fillContributionsTab(rule) {
			this.fillContributionOrDeductionHelper(rule, _componentLibraryComponentsContributionsModalConstants.CONTRIBUTION);
		},
		fillDeductionTab: function fillDeductionTab(rule) {
			this.fillContributionOrDeductionHelper(rule, _componentLibraryComponentsContributionsModalConstants.DEDUCTION);
		},
		isTheRuleEditable: function isTheRuleEditable(rule) {
			var loc = rule.get('lineOfCoverage');
			var carrierId = rule.get('carrierId');
			var planId = rule.get('planId');
			if (rule.get('template.totalContribution.lineSpanningCap')) {
				return false;
			}
			if (_componentLibraryComponentsContributionsModalUtils['default'].isRuleForDeduction(rule) && _componentLibraryComponentsContributionsModalUtils['default'].getRuleDeductionType(rule) == _componentLibraryComponentsContributionsModalConstants.UNSUPPORTED) {
				return false;
			}
			if (_componentLibraryComponentsContributionsModalUtils['default'].getRuleContributionType(rule) == _componentLibraryComponentsContributionsModalConstants.UNSUPPORTED) {
				return false;
			}
			return true;
		},
		saveRuleAfterEdit: function saveRuleAfterEdit(rule, newData) {
			var _this9 = this;

			function applyDataAfterEditing(newRule) {
				newRule.set('employeeTemplate', newData.employeeTemplate);
				newRule.set('template', newData.template);
				newRule.set('lineOfCoverage', newData.lineOfCoverage);
				newRule.set('carrierId', newData.carrierId);
				newRule.set('planId', newData.planId);
				return newRule;
			}
			var scheme = rule.get('scheme');
			if (scheme.get('isEditable')) {
				return applyDataAfterEditing(rule).save();
			} else {
				return this.getSchemeCopyPromise(scheme).then(function (newScheme) {
					return _ember['default'].RSVP.all(newScheme.get('rules').map(function (rule) {
						return rule.reload();
					})).then(function () {
						return applyDataAfterEditing(_this9.getNewRule(newScheme, rule)).save().then(function () {
							return _this9.set('schemeCopy', newScheme);
						});
					});
				});
			}
		},
		// ======================= OBSERVERS ===================
		clearSessionObserver: _ember['default'].observer('coverageSelection.selectedLoc.id', function () {
			var rule = this.get('ruleInEditing');
			var isRuleForDeduction = this.get('isDeductionsEnabled');
			this.reInitializeTheCSModal(false);
			if (rule) {
				this.set('ruleInEditing', rule);
			}
			if (isRuleForDeduction) {
				this.set('isDeductionsEnabled', true);
			}
		}),
		isWaterFallObserver: _ember['default'].observer('isAllCoverageSelected', 'currentEligibilitySelection', function () {
			var res = this.get('currentEligibilitySelection') == _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.ALL && this.get('isAllCoverageSelected');
			this.set('isWaterFall', res);
			if (res) {
				this.set('isDeductionsEnabled', false);
			}
		}),
		// =========================== ACTIONS ==========================
		actions: {
			openNewCSModal: function openNewCSModal() {
				this.reInitializeTheCSModal();
				this.set('isCSModalOpen', true);
			},
			closeNewCSModal: function closeNewCSModal() {
				this.set('isCSModalOpen', false);
			},
			saveNewCSRule: function saveNewCSRule() {
				var _this10 = this;

				var rule = this.get('ruleInEditing');
				var scheme = this.get('schemeCopy');
				var coverageSelection = this.get('getCoverageSelection');
				var promise = undefined;
				if (this.get('isDeductionsEnabled')) {
					var totalDeductions = this.getDeduction();
					var template = {
						overrideFormulas: true,
						employeeContribution: _componentLibraryComponentsContributionsModalUtils['default'].getFullContribution(this.get('plans'), this.get('coverageSelection.selectedLoc.id')),
						dependentsContribution: _componentLibraryComponentsContributionsModalUtils['default'].getFullContribution(this.get('plans'), this.get('coverageSelection.selectedLoc.id'))
					};
					if (totalDeductions.total) {
						template.totalDeduction = totalDeductions.total;
					} else {
						template.employeeDeduction = totalDeductions.employee;
						template.dependentsDeduction = totalDeductions.dependents;
					}
					if (rule) {
						var newData = {
							template: template,
							employeeTemplate: this.getEligibility(),
							lineOfCoverage: coverageSelection.lineOfCoverage,
							carrierId: coverageSelection.carrierId,
							planId: coverageSelection.planId
						};
						promise = this.saveRuleAfterEdit(rule, newData);
					} else {
						var newRule = { scheme: scheme, template: template, employeeTemplate: this.getEligibility() };
						promise = this.createNewRule(scheme, Object.assign(coverageSelection, newRule));
					}
				} else {
					if (this.get('isWaterFall')) {
						promise = this.putTheSchemeInTheEditMode(scheme).then(function () {
							return _this10.getWaterFallRules().map(function (rule) {
								return _this10.createNewRule(_this10.get('schemeCopy'), rule);
							});
						});
					} else {
						var totalContributions = this.getContribution();
						var template = {
							overrideFormulas: true
						};
						if (totalContributions.total) {
							template.totalContribution = totalContributions.total;
						} else {
							template.employeeContribution = totalContributions.employee;
							template.dependentsContribution = totalContributions.dependents;
						}
						if (rule) {
							var newData = {
								template: template,
								employeeTemplate: this.getEligibility(),
								lineOfCoverage: coverageSelection.lineOfCoverage,
								carrierId: coverageSelection.carrierId,
								planId: coverageSelection.planId
							};
							promise = this.saveRuleAfterEdit(rule, newData);
						} else {
							var newRule = {
								scheme: scheme,
								template: template,
								employeeTemplate: this.getEligibility()
							};
							promise = this.createNewRule(scheme, Object.assign(coverageSelection, newRule));
						}
					}
				}
				return promise.then(function (result) {
					_this10.set('isCSModalOpen', false);
					return result;
				});
			},
			deleteRule: function deleteRule(rule) {
				var _this11 = this;

				var scheme = rule.get('scheme');
				if (scheme.get('isEditable')) {
					return rule.destroyRecord();
				} else {
					return this.getSchemeCopyPromise(scheme).then(function (newScheme) {
						return _ember['default'].RSVP.all(newScheme.get('rules').map(function (rule) {
							return rule.reload();
						})).then(function () {
							return _this11.getNewRule(newScheme, rule).destroyRecord().then(function () {
								return _this11.set('schemeCopy', newScheme);
							});
						});
					});
				}
			},
			editRule: function editRule(rule) {
				if (this.isTheRuleEditable(rule)) {
					//order matter observer will delete the ruleInEdit if changed, and this will lead to creating a new rule.
					this.reInitializeTheCSModal();
					this.fillDetailsTab(rule);
					this.fillEligibilityTab(rule);
					this.set('ruleInEditing', rule);
					if (_componentLibraryComponentsContributionsModalUtils['default'].isRuleForDeduction(rule)) {
						this.set('isDeductionsEnabled', true);
						this.fillDeductionTab(rule);
					} else {
						this.fillContributionsTab(rule);
					}
					this.set('isCSModalOpen', true);
				} else {
					_componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'Cannot Edit Rule',
						message: 'This rule type was created by Zenefits Support and cannot be edited here. Please reach out to Zenefits Support to update this rule. If you do not want to use this rule anymore, you can delete it.',
						buttonText: 'Close Message'
					});
				}
			},
			makeSchemeEditable: function makeSchemeEditable() {
				this.set('readOnlyView', false);
			},
			cancelSchemeChanges: function cancelSchemeChanges() {
				var scheme = this.get('schemeCopy');
				if (scheme.get('isEditable')) {
					this.set('schemeCopy', this.get('scheme'));
				}
				this.set('readOnlyView', true);
				return _ember['default'].RSVP.resolve();
			},
			publishSchemeChanges: function publishSchemeChanges() {
				var _this12 = this;

				var scheme = this.get('schemeCopy');
				return _ember['default'].ajaxPost('/custom_api/contribution_scheme/validate_and_publish/' + scheme.get('id')).then(function (response) {
					var success = response.status == 'success';
					_this12.set('displayNotificationBanners', true);
					_this12.set('schemePublishedSuccessfully', success);
					if (success) {
						// important to reload the scheme as we published it, some values got changes like isEditable
						_this12.get('schemeCopy').reload().then(function () {
							_this12.set('scheme', _this12.get('schemeCopy'));
						});
					} else {
						var missingRules = response.missingRules;
						var invalidRules = response.invalidRules;
						if (invalidRules.length) {
							_this12.set('schemePublishErrorText', 'Invalid rules for ' + invalidRules.map(function (obj) {
								return obj.loc;
							}).uniq().join(', ') + '. Please ensure that all policies are complete before continuing.');
						} else if (missingRules.length) {
							_this12.set('schemePublishErrorText', 'You are offering ' + missingRules.map(function (obj) {
								return obj.loc;
							}).uniq().join(', ') + ' but have not created a policy defining company contributions. Please do so before proceeding.');
						} else {
							_this12.set('schemeValidationErrorText', 'Scheme is not valid');
						}
					}
					setTimeout(function () {
						// hide the notifications after 7 seconds
						_this12.set('displayNotificationBanners', false);
					}, _componentLibraryComponentsContributionsModalConstants.HIDE_NOTIFICATION_AFTER);
					_this12.set('readOnlyView', success);
					return _ember['default'].RSVP.resolve();
				});
			}
		}
	});
});