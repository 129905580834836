define('component-library/components/z-wizard-overview', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		wizardFlowState: undefined,
		isRevealed: false,
		flowFAQs: undefined,
		flowCompletedCTAText: 'Finish',
		flowInProgressCTAText: 'Continue',
		flowNotStartedCTAText: 'Start',

		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super();
			_ember['default'].run.scheduleOnce('afterRender', function () {
				_this.bindClickFunctionToSections();
				_this.$('.js-z-wizardOverviewIndexSection-wrapper.js-is-active .js-z-wizardOverviewIndexSection').click();
			});
		},

		bindClickFunctionToSections: function bindClickFunctionToSections() {
			var component = this;
			this.$('.js-z-wizardOverviewIndexSection').on('click', function () {
				var $sectionHeader = component.$(this);
				var $sectionWrapper = $sectionHeader.parents('.js-z-wizardOverviewIndexSection-wrapper');
				var $sectionSteps = $sectionWrapper.find('.js-z-wizardOverviewIndexSteps');
				var $allSectionWrappers = component.$('.js-z-wizardOverviewIndexSection-wrapper');

				if ($sectionWrapper.attr('style') || !$sectionHeader.hasClass('is-accessible')) {
					$sectionWrapper.removeAttr('style').removeClass('is-expanded');
				} else {
					$allSectionWrappers.removeAttr('style').removeClass('is-expanded');
					var fullHeight = component.fullSectionHeight($sectionHeader, $sectionSteps) + 1;
					$sectionWrapper.css('height', fullHeight + 'px').addClass('is-expanded');
					component.eventLogger.log('z_wizard_overview_action', {
						action: 'expand_section',
						section: $sectionWrapper.find('.z-wizardOverviewIndexSection-title').text()
					});
				}
			});
		},

		hasAnyCompletedSteps: _ember['default'].computed.bool('wizardFlowState.stepsComplete'),

		isFlowCompleted: _ember['default'].computed.bool('wizardFlowState.flowComplete'),

		fullSectionHeight: function fullSectionHeight(sectionHeader, sectionSteps) {
			return sectionHeader.outerHeight() + sectionSteps.outerHeight() + 10;
		},

		sectionChangeObserver: (function () {
			var _this2 = this;

			_ember['default'].run.scheduleOnce('afterRender', function () {
				// unbind click functions on sections first
				_this2.$('.js-z-wizardOverviewIndexSection').off('click');
				_this2.bindClickFunctionToSections();
			});
		}).observes('wizardFlowState.sections.[]'),

		activeSectionsObserver: (function () {
			var _this3 = this;

			_ember['default'].run.scheduleOnce('afterRender', function () {
				var $currentSection = _this3.$('.js-z-wizardOverviewIndexSection-wrapper.js-is-active .js-z-wizardOverviewIndexSection');
				var $allSectionWrappers = _this3.$('.js-z-wizardOverviewIndexSection-wrapper');
				if ($currentSection && !$currentSection.parent().hasClass('is-expanded')) {
					$allSectionWrappers.removeAttr('style').removeClass('is-expanded');
					$currentSection.click();
				}
			});
		}).observes('wizardFlowState.currentSectionIndex'),

		newStepsObserver: (function () {
			var _this4 = this;

			var component = this;
			_ember['default'].run.scheduleOnce('afterRender', function () {
				var $currentSection = component.$('.js-z-wizardOverviewIndexSection-wrapper.js-is-active');
				var $allSectionWrappers = _this4.$('.js-z-wizardOverviewIndexSection-wrapper');
				if ($currentSection) {
					var $currentSectionHeader = $currentSection.find('.js-z-wizardOverviewIndexSection');
					var $currentSectionSteps = $currentSection.find('.js-z-wizardOverviewIndexSteps');
					$allSectionWrappers.removeAttr('style').removeClass('is-expanded');
					var fullHeight = component.fullSectionHeight($currentSectionHeader, $currentSectionSteps);
					$currentSection.css('height', fullHeight + 'px').addClass('is-expanded');
				}
			});
		}).observes('wizardFlowState.currentSection.steps.[]'),

		hasPreviousStep: (function () {
			var currentSectionIndex = this.get('wizardFlowState.currentSection.index');
			var previousStepExists = !_ember['default'].isNone(this.get('wizardFlowState.currentSection.previousStep'));
			return currentSectionIndex > 0 || previousStepExists;
		}).property('wizardFlowState.currentSection.index', 'wizardFlowState.currentSection.previousStep'),

		hasNextStep: (function () {
			var currentSectionIndex = this.get('wizardFlowState.currentSection.index');
			var numSections = this.get('wizardFlowState.sections.length');
			var nextStepExists = !_ember['default'].isNone(this.get('wizardFlowState.currentSection.nextStep'));
			return currentSectionIndex < numSections - 1 || nextStepExists;
		}).property('wizardFlowState.sections.length', 'wizardFlowState.currentSection.index', 'wizardFlowState.currentSection.nextStep'),

		nextStepReachable: (function () {
			if (!this.get('hasNextStep')) {
				return false;
			}

			var nextStep = this.get('wizardFlowState.currentSection.nextStep') || this.get('wizardFlowState.nextSection.steps.firstObject');
			return nextStep && nextStep.get('isAccessible');
		}).property('hasNextStep', 'wizardFlowState.currentSection.nextStep.isAccessible'),

		sectionStepsCompleted: _ember['default'].computed('wizardFlowState.currentSection.steps.@each.isComplete', function () {
			var steps = this.get('wizardFlowState.currentSection.steps') || [];
			return steps.filterBy('isComplete').length;
		}),
		sectionStepsPercentCompleted: _ember['default'].computed('sectionStepsCompleted', 'wizardFlowState.currentSection.steps', function () {
			var sectionStepsCompleted = this.get('sectionStepsCompleted');
			var sectionSteps = this.get('wizardFlowState.currentSection.steps') || [];

			if (sectionSteps.length) {
				return Math.round(sectionStepsCompleted / sectionSteps.length * 100) + '%';
			}
			return 'n/a';
		}),

		actions: {
			transitionToPreviousStep: function transitionToPreviousStep() {
				this.eventLogger.log('z_wizard_overview_action', {
					action: 'transitionToPreviousStep'
				});
				this.sendAction('triggerWizardAction', '_transitionToPreviousStep');
			},

			transitionToNextStep: function transitionToNextStep() {
				this.eventLogger.log('z_wizard_overview_action', {
					action: 'transitionToNextStep'
				});
				if (!this.get('nextStepReachable')) {
					return;
				}
				this.sendAction('triggerWizardAction', '_transitionToNextStep');
			},

			transitionToStep: function transitionToStep(step) {
				this.eventLogger.log('z_wizard_overview_action', {
					action: 'transitionToStep',
					stepKey: step.get('stepKey')
				});
				if (!step.get('isAccessible')) {
					return;
				}

				this.send('revealOverview', false);
				this.sendAction('triggerWizardAction', '_transitionToStep', step);
			},

			transitionToFirstIncompleteStep: function transitionToFirstIncompleteStep(continueButton) {
				var firstIncompleteSection = this.get('wizardFlowState.firstIncompleteSection');
				if (firstIncompleteSection) {
					this.sendAction('triggerWizardAction', '_transitionToFirstIncompleteStep', firstIncompleteSection.get('sectionKey'));
					this.send('revealOverview', false);
				} else {
					this.sendAction('triggerWizardAction', '_transitionToTerminalRoute');
				}
				continueButton.stop();
			},

			revealOverview: function revealOverview(isRevealed) {
				this.eventLogger.log('z_wizard_overview_action', {
					action: 'revealOverview',
					isRevealed: isRevealed
				});
				this.set('isRevealed', isRevealed);
			}
		}
	});
});