define('component-library/mixins/fixed-sidebar-resize', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var MD_DEVICE_BREAKPOINT = 768; // $md-breakpoint-min-width
	var HEADER_HEIGHT = 95; // $z-navigation-height + $z-navigation-margin-bottom

	exports['default'] = _ember['default'].Mixin.create({

		// selectors need to be set from consumers of mixin if values are different
		sidebarSelector: '.sidebar-outer', // contains sidebar content, inside `.sidebar-wrapper`
		sidebarNavSelector: '.sidebar-nav', // element that wraps navigation
		profileInfoSelector: '.profile-info', // element that wraps image and title text above navigation
		widthToHideSidebar: MD_DEVICE_BREAKPOINT,

		setSidebarWidth: function setSidebarWidth() {
			// set width of fixed position sidebar element based on its parent `.sidebar-wrapper` (should have column width)
			_ember['default'].$(this.get('sidebarSelector')).width(_ember['default'].$('.sidebar-wrapper').width());

			// hide sidebar if width is too small
			if (_ember['default'].$(window).width() <= this.get('widthToHideSidebar')) {
				_ember['default'].$(this.get('sidebarSelector')).hide();
			} else {
				_ember['default'].$(this.get('sidebarSelector')).show();

				// Calculate height so that there are scrollbars when length of nav is too long
				if (_ember['default'].$(this.get('sidebarNavSelector')).length > 0) {
					_ember['default'].$(this.get('sidebarNavSelector')).height(_ember['default'].$(window).height() - HEADER_HEIGHT - _ember['default'].$(this.get('profileInfoSelector')).height());
				}
			}
		},

		addSidebarWidthResizingSupport: function addSidebarWidthResizingSupport() {
			this.setSidebarWidth();
			this.set('guid', _ember['default'].guidFor(this));

			var scopedResizeEvent = 'resize.' + this.get('guid');
			_ember['default'].$(window).on(scopedResizeEvent, _ember['default'].$.proxy(this.setSidebarWidth, this));
		},

		removeSidebarWidthResizingSupport: function removeSidebarWidthResizingSupport() {
			var scopedResizeEvent = 'resize.' + this.get('guid');
			_ember['default'].$(window).off(scopedResizeEvent, _ember['default'].$.proxy(this.setSidebarWidth, this));
		}

	});
});