define('component-library/mixins/zfb/invoices/controller', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	var DayBarView = _ember['default'].View.extend({
		classNames: ['invoices-week-bar-wrapper'],
		classNameBindings: ['isCurrentDay:clicked'],
		day: null,

		isCurrentDay: (function (event) {
			return this.get('day.id') == this.get('controller.currentDay.id');
		}).property('controller.currentDay', 'day'),

		click: function click(event) {
			this.set('controller.currentDay', this.get('day'));
		},

		mouseEnter: function mouseEnter(event) {
			this.set('controller.hoverDay', this.get('day'));
			var bar = this.$();
			var tagPopup = _ember['default'].$('.invoices-week-day-info');
			var pos = bar.find('.invoices-week-bar-manual').position();
			var shift = _ember['default'].$('.invoices-weeks-scroll-wrapper').position();
			tagPopup.css({
				left: pos.left + shift.left + bar.width() * 0.5,
				top: Math.min(220, pos.top + shift.top)
			}).addClass('show');
		},

		mouseLeave: function mouseLeave() {
			_ember['default'].$('.invoices-week-day-info').removeClass('show');
		}
	});

	exports['default'] = _ember['default'].Controller.extend({
		queryParams: ['year', 'month'],
		year: (0, _componentLibraryUtilsGlobals.moment)().year(),
		month: (0, _componentLibraryUtilsGlobals.moment)().month(),
		startSegment: _ember['default'].computed.alias('model.startSegment'),
		endSegment: _ember['default'].computed.alias('model.endSegment'),
		transactions: _ember['default'].computed.alias('model.transactions'),
		companyId: _ember['default'].computed.alias('model.companyId'),
		_currentDay: null,
		hoverDay: null,
		DayBarView: DayBarView,

		currentDay: (function (key, value) {
			if (arguments.length > 1) {
				this.set('_currentDay', value);
			}
			if (this.get('_currentDay') !== null) {
				return this.get('_currentDay');
			}
			var now = (0, _componentLibraryUtilsGlobals.moment)();
			if (this.get('year') === now.year() && this.get('month') === now.month()) {
				var st = this.get('startSegment');
				var weekIndex = now.diff(st, 'week');
				var dayIndex = now.diff(st, 'day') - 7 * weekIndex;
				return this.get('weeks')[weekIndex].days[dayIndex];
			}
			return this.get('weeks')[0].days[0];
		}).property('weeks', '_currentDay'),

		currentMonth: (function (key, value) {
			if (arguments.length > 1 && value) {
				var mn = (0, _componentLibraryUtilsGlobals.moment)(value);
				this.transitionTo({
					queryParams: {
						year: mn.year(),
						month: mn.month()
					}
				});
			}
			return (0, _componentLibraryUtilsGlobals.moment)({
				year: this.get('year'),
				month: this.get('month')
			}).format('YYYY-MM-DD');
		}).property('year', 'month'),

		monthsChoices: (function () {
			var result = [];
			var cm = (0, _componentLibraryUtilsGlobals.moment)(this.get('currentMonth'));
			var found = false;
			this.get('months').forEach(function (month) {
				var mn = (0, _componentLibraryUtilsGlobals.moment)(month.get('month'));
				if (mn.month() == cm.month() && cm.year() == mn.year()) {
					found = true;
				}
				result.push({
					value: month.get('month'),
					label: (0, _componentLibraryUtilsGlobals.moment)(month.get('month')).format('MMMM YYYY')
				});
			});
			if (!found && result && result.length > 0) {
				this.set('currentMonth', result[0].value);
			}
			return result;
		}).property('months', 'currentMonth'),

		initWeeks: function initWeeks(startSegment, endSegment) {
			var weeksCount = endSegment.diff(startSegment, 'week');
			var weeks = [];
			for (var i = 0; i < weeksCount; ++i) {
				var startWeek = (0, _componentLibraryUtilsGlobals.moment)(startSegment).add(i, 'week');
				var endWeek = (0, _componentLibraryUtilsGlobals.moment)(startWeek).endOf('week');
				var week = {
					start: startWeek.format('MMM D'),
					end: endWeek.format('MMM D'),
					manualClaim: 0.0,
					cardSwipes: 0.0,
					days: []
				};
				for (var j = 0; j < 7; ++j) {
					var dayDate = (0, _componentLibraryUtilsGlobals.moment)(startWeek).add(j, 'day');
					week.days.push({
						id: i * 7 + j,
						totalAmount: 0.0,
						manualClaim: 0.0,
						cardSwipes: 0.0,
						transactions: [],
						isEmpty: true,
						date: dayDate.format('MMMM Do'),
						day: dayDate.date()
					});
				}
				weeks.push(week);
			}
			return weeks;
		},

		weeks: (function () {
			var startSegment = this.get('startSegment');
			var endSegment = this.get('endSegment');
			var weeks = this.initWeeks(startSegment, endSegment);
			var maxTotalAmount = 0;
			this.get('transactions').forEach(function (transaction) {
				var date = (0, _componentLibraryUtilsGlobals.moment)(transaction.get('invoiceDate'));
				var weekStart = (0, _componentLibraryUtilsGlobals.moment)(date).startOf('week');
				var weekIndex = date.diff(startSegment, 'week');
				var dayIndex = date.diff(weekStart, 'day');
				var amount = transaction.get('transactionAmount');
				var week = weeks[weekIndex];
				var day = week.days[dayIndex];
				if (transaction.get('isManualClaim')) {
					week.manualClaim += amount;
					day.manualClaim += amount;
				} else if (transaction.get('isCardSwipe') || transaction.get('isRefund')) {
					week.cardSwipes += amount;
					day.cardSwipes += amount;
				}
				day.totalAmount += amount;
				day.transactions.push(transaction);
				maxTotalAmount = Math.max(maxTotalAmount, day.totalAmount);
			});
			for (var i = 0; i < weeks.length; ++i) {
				var week = weeks[i];
				for (var j = 0; j < 7; ++j) {
					var day = week.days[j];
					if (maxTotalAmount > 0) {
						day.barManualClaim = 100.0 * Math.max(day.manualClaim / maxTotalAmount, 0);
						day.barCardSwipe = 100.0 * Math.max(day.cardSwipes / maxTotalAmount, 0);
						day.barPadding = 100.0 - day.barManualClaim - day.barCardSwipe;
					}
					if (Math.abs(day.totalAmount) > 1e-9) {
						day.isEmpty = false;
					}
				}
			}
			return weeks;
		}).property('transactions'),

		addCompanyIdParam: function addCompanyIdParam(url) {
			if (this.get('companyId')) {
				url += '?company_id=' + this.get('companyId');
			}
			return url;
		},

		linkToPdf: (function () {
			var url = '/get_zfb_invoice_pdf/' + this.get('year') + '/' + this.get('month') + '/';
			return this.addCompanyIdParam(url);
		}).property('month', 'year'),

		linkToXlsx: (function () {
			var url = '/get_zfb_invoice_xlsx/' + this.get('year') + '/' + this.get('month') + '/';
			return this.addCompanyIdParam(url);
		}).property('month', 'year')
	});
});