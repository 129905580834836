define('component-library/components/z-progress-navigation-step', ['exports', 'ember', 'component-library/constants/components/z-progress-navigation'], function (exports, _ember, _componentLibraryConstantsComponentsZProgressNavigation) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-progress-navigation-step', 'js-z-progress-navigation-step'],
		classNameBindings: ['isSelected:z-progress-navigation-step--selected'],
		step: null,

		stepState: _ember['default'].computed.alias('step.state'),
		isSelected: _ember['default'].computed.alias('step.isSelected'),
		isInactiveLocked: _ember['default'].computed.equal('stepState', _componentLibraryConstantsComponentsZProgressNavigation.STEP_STATES.INACTIVE_LOCKED),
		isCompleteLocked: _ember['default'].computed.equal('stepState', _componentLibraryConstantsComponentsZProgressNavigation.STEP_STATES.COMPLETE_LOCKED),
		isLocked: _ember['default'].computed.or('isInactiveLocked', 'isCompleteLocked'),
		isPending: _ember['default'].computed.equal('stepState', _componentLibraryConstantsComponentsZProgressNavigation.STEP_STATES.PENDING),

		stepStateClass: _ember['default'].computed('stepState', function () {
			return _componentLibraryConstantsComponentsZProgressNavigation.STEP_STATES_MAPPING[this.get('stepState')];
		}),

		actions: {
			updateStepSelection: function updateStepSelection() {
				if (!this.get('isInactiveLocked')) {
					this.attrs.updateStepSelection(this.get('step'));
				}
			}
		}
	});
});