define('component-library/components/benefits/timeline/transaction-item', ['exports', 'ember', 'ui-infra/utils/sanitize-html', 'component-library/utils/globals', 'component-library/templates/components/benefits/timeline/transaction-item', 'component-library/constants/benefits', 'component-library/mixins/benefits/timeline/transaction-employee-data-mixin', 'component-library/mixins/benefits/timeline/transaction-dependents-data-mixin', 'component-library/mixins/benefits/timeline/transaction-contribution-data-mixin', 'component-library/mixins/benefits/timeline/transaction-requester-data-mixin', 'component-library/mixins/benefits/timeline/transaction-contact-data-mixin', 'component-library/mixins/benefits/timeline/transaction-fulfillment-data-mixin', 'component-library/utils/benefits/timeline/copy-for-verbal-description', 'component-library/utils/benefits/timeline/computed'], function (exports, _ember, _uiInfraUtilsSanitizeHtml, _componentLibraryUtilsGlobals, _componentLibraryTemplatesComponentsBenefitsTimelineTransactionItem, _componentLibraryConstantsBenefits, _componentLibraryMixinsBenefitsTimelineTransactionEmployeeDataMixin, _componentLibraryMixinsBenefitsTimelineTransactionDependentsDataMixin, _componentLibraryMixinsBenefitsTimelineTransactionContributionDataMixin, _componentLibraryMixinsBenefitsTimelineTransactionRequesterDataMixin, _componentLibraryMixinsBenefitsTimelineTransactionContactDataMixin, _componentLibraryMixinsBenefitsTimelineTransactionFulfillmentDataMixin, _componentLibraryUtilsBenefitsTimelineCopyForVerbalDescription, _componentLibraryUtilsBenefitsTimelineComputed) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['top:', 'px; height: ', 'px'], ['top:', 'px; height: ', 'px']),
	    _templateObject2 = _taggedTemplateLiteral(['', ''], ['', '']);

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	var EFFECTIVE_DATE_FORMAT = 'MMM D YYYY';

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsBenefitsTimelineTransactionRequesterDataMixin['default'], _componentLibraryMixinsBenefitsTimelineTransactionEmployeeDataMixin['default'], _componentLibraryMixinsBenefitsTimelineTransactionContactDataMixin['default'], _componentLibraryMixinsBenefitsTimelineTransactionContributionDataMixin['default'], _componentLibraryMixinsBenefitsTimelineTransactionDependentsDataMixin['default'], _componentLibraryMixinsBenefitsTimelineTransactionFulfillmentDataMixin['default'], {
		layout: _componentLibraryTemplatesComponentsBenefitsTimelineTransactionItem['default'],
		height: 100,
		top: 0,

		didReceiveAttrs: function didReceiveAttrs() {
			var _this = this;

			this._super.apply(this, arguments);
			this.get('planNamePromise').then(function (name) {
				return _this.set('planName', name);
			});
			this.get('oldPlanNameDisplayPromise').then(function (name) {
				return _this.set('oldPlanName', name);
			});
		},

		classNames: ['u-positionAbsolute u-fillHorizontal'],
		attributeBindings: ['styleString:style'],

		store: _ember['default'].inject.service(),

		styleString: _ember['default'].computed('top', 'height', function () {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject, this.get('top'), this.get('height'));
		}),

		dateOfCreationDate: _ember['default'].computed('transaction.momentOfCreationDate', function () {
			return this.get('transaction.momentOfCreationDate').format(EFFECTIVE_DATE_FORMAT);
		}),

		timeOfCreationDate: _ember['default'].computed('transaction.momentOfCreationDate', function () {
			return this.get('transaction.momentOfCreationDate').format('h:mm a z');
		}),

		lineOfCoverage: _ember['default'].computed('transaction.benefitsType', function () {
			return _componentLibraryConstantsBenefits.benefitsTypes.findBy('text', this.get('transaction.benefitsType')).get('label');
		}),

		coverageDescription: _ember['default'].computed('transaction.benefitsType', function () {
			return _componentLibraryConstantsBenefits.benefitsTypes.findBy('text', this.get('transaction.benefitsType')).get('description');
		}),

		effectiveDate: _ember['default'].computed('transaction.momentOfEffectiveDate', function () {
			return this.get('transaction.momentOfEffectiveDate').format(EFFECTIVE_DATE_FORMAT);
		}),

		effectiveDateChange: _ember['default'].computed.alias('transaction.jsonDataOfEffectiveDateChange'),

		oldEffectiveDate: _ember['default'].computed('effectiveDateChange.oldEffectiveDate', 'effectiveDate', function () {
			var _oldEffectiveDate = this.get('effectiveDateChange.oldEffectiveDate');
			if (_oldEffectiveDate) {
				return (0, _componentLibraryUtilsGlobals.moment)(_oldEffectiveDate, _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT).format(EFFECTIVE_DATE_FORMAT);
			} else {
				// NOTE: effectiveDate should already exist and be formatted
				return this.get('effectiveDate');
			}
		}),

		employee: (0, _componentLibraryUtilsBenefitsTimelineComputed.computedForEmployeePromiseObject)('transaction.employeeId'),

		employeeName: _ember['default'].computed.alias('employee.fullName'),

		displayEffectiveDate: _ember['default'].computed.equal('selected.sort', 'effectiveDate'),
		historyDate: _ember['default'].computed.oneWay('selected.historyDate'),

		isActive: _ember['default'].computed('historyDate', 'transaction.deactivationDate', function () {
			var historyDate = this.get('historyDate');
			var deactivationDate = this.get('transaction.deactivationDate');
			if (_ember['default'].isEmpty(deactivationDate)) {
				return true;
			} else {
				if (_ember['default'].isEmpty(historyDate)) {
					return false;
				} else {
					return (0, _componentLibraryUtilsGlobals.moment)(deactivationDate) > historyDate;
				}
			}
		}),

		isDeactivated: _ember['default'].computed.not('isActive'),

		titleCopy: _ember['default'].computed('transaction.reason', 'transaction.event', 'transaction.eventCycle', function () {
			var event = this.get('transaction.event');
			var reason = this.get('transaction.reason');
			var eventCycle = this.get('transaction.eventCycle');

			var title = [];

			if (reason) title.push(reason.split('_').join(' '));
			if (event) title.push(event.split('_').join(' '));
			if (eventCycle) title.push(eventCycle);

			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject2, title.join(' | '));
		}),

		verbalDescription: _ember['default'].computed('transaction.event', 'transaction.reason', 'requesterName', 'employeeName', 'transaction.content', 'planName', 'oldPlanName', function () {
			var event = this.get('transaction.event');
			var reason = this.get('transaction.reason');
			var changeAction = reason || event;
			return (0, _componentLibraryUtilsBenefitsTimelineCopyForVerbalDescription['default'])(changeAction, this);
		}),

		mouseEnter: function mouseEnter() {
			this.set('hovering', true);
		},

		mouseLeave: function mouseLeave() {
			this.set('hovering', false);
		}
	});
});