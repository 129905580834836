define('component-library/mixins/payroll/add-pay-run-mixin', ['exports', 'ember', 'component-library/components/notification-modal', 'component-library/utils/globals', 'component-library/utils/validator', 'component-library/constants/payroll', 'component-library/components/payroll/select-deductions-contributions-modal'], function (exports, _ember, _componentLibraryComponentsNotificationModal, _componentLibraryUtilsGlobals, _componentLibraryUtilsValidator, _componentLibraryConstantsPayroll, _componentLibraryComponentsPayrollSelectDeductionsContributionsModal) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		payRunValidationService: _ember['default'].inject.service('payroll/pay-run-validation-service'),
		datePlaceholder: 'MM/DD/YYYY',
		autoSetHeight: false,
		isResponsive: true,
		payFrequencies: [],
		bankHolidays: null,
		employees: [],
		zpCompanyContributionTypes: [],
		zpCompanyDeductionTypes: [],
		selectedEmployees: [],
		selectedEmployeeFilters: [],
		selectedEmployeesFiltersValidationHandler: null,
		nearestCheckDate: null,
		description: null,
		zPayrollCompany: null,
		shouldIncludeDeductions: false,
		shouldIncludeDeductionsErrorText: null,
		shouldIncludeDeductionsObserver: _ember['default'].observer('shouldIncludeDeductions', function () {
			if (!this.get('shouldIncludeDeductions')) {
				this.set('shouldIncludeDeductionsErrorText', null);
			}
		}),
		shouldIncludeGarnishments: false,
		selectedPeople: 'everyone',
		isSelectedPeopleCustom: _ember['default'].computed.equal('selectedPeople', 'custom'),
		isSelectedPeopleFilters: _ember['default'].computed.equal('selectedPeople', 'filters'),
		isPayByCheckRequested: false,
		showPayByCheckRequestedModalInV2: false,
		isPaidByCheckOnly: false,
		showPayPeriodOption: false,
		isTimeBasedRate: false,
		isThirdPartySickPay: false,
		hasValidationErrors: false,
		payPeriodStartErrorText: null,
		payPeriodEndErrorText: null,
		payRunNameErrorText: null,
		checkDateErrorText: null,
		timeBasedErrorText: null,
		offCyclePayFrequency: null,
		checkDateConfirmationText: null,
		payrunHelpLabel: _ember['default'].computed(function () {
			if (this.get('isContractorPaymentsCompany')) {
				return 'This payment name will be displayed on contractor payments.';
			}
			return 'This pay run name will be displayed on worker pay stubs.';
		}),
		// Mandatory pay period settings controlled by switch
		showPayPeriodSettings: _ember['default'].computed(function () {
			return this.get('switches.payroll_off_cycle_pay_period_enabled');
		}),
		payPeriodStart: _ember['default'].computed(function () {
			return this.get('showPayPeriodSettings') ? null : (0, _componentLibraryUtilsGlobals.moment)().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		}),
		payPeriodEnd: _ember['default'].computed(function () {
			return this.get('showPayPeriodSettings') ? null : (0, _componentLibraryUtilsGlobals.moment)().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		}),
		shouldValidatePayPeriod: _ember['default'].computed(function () {
			if (this.get('isContractorPaymentsCompany') || this.get('isThirdPartySickPay')) {
				return false;
			} else {
				return this.get('showPayPeriodOption') || this.get('showPayPeriodSettings');
			}
		}),
		companyUsesRegularRateOfPay: _ember['default'].computed(function () {
			var overtimeCalculationMethod = this.get('zPayrollCompany.overtimeCalculationMethod') || "";
			var companyUsesRegularRateOfPay = overtimeCalculationMethod == "regular";
			if (companyUsesRegularRateOfPay) {
				// When using regular rate of pay, we want to
				//   1) auto-select to use pay period on the offcycle pay run
				//   2) preselect pay period as 1 day, today
				// This code will do #1. The default values for startDate and endDate are today
				//   so this will automatically handle #2 as well.
				this.set('showPayPeriodOption', true);
			}
			return companyUsesRegularRateOfPay;
		}),
		checkDate: _ember['default'].computed.alias('nearestCheckDate'),
		allActivePeopleLabel: _ember['default'].computed(function () {
			if (this.get('isContractorPaymentsCompany')) {
				return 'All Active contractors';
			}
			return 'All Active Workers';
		}),

		resetValidations: function resetValidations() {
			this.setProperties({
				hasValidationErrors: false,
				checkDateErrorText: null,
				timeBasedErrorText: null,
				payRunNameErrorText: null,
				payPeriodStartErrorText: null,
				payPeriodEndErrorText: null,
				checkDateConfirmationText: null,
				isPayByCheckRequested: false,
				isPaidByCheckOnly: false,
				shouldIncludeDeductionsErrorText: null
			});
		},

		validatePayPeriodDate: function validatePayPeriodDate(date) {
			if (!date || date.includes("-")) {
				return false;
			}
			return _componentLibraryUtilsValidator['default'].getRegexRule('date').test(date);
		},

		validatePayPeriodDateRange: function validatePayPeriodDateRange(startDate, endDate) {
			return !(0, _componentLibraryUtilsGlobals.moment)(endDate).isBefore(startDate);
		},

		createPayrollRun: function createPayrollRun(zpPayPeriod) {
			var createZPayrollRunUrl = '/custom_api/z_payroll/create_off_cycle_payroll_run/';
			var zpPayPeriodId = zpPayPeriod ? zpPayPeriod.get('id') : null;
			var selectedEmployeeIds = this.get('selectedEmployees').map(function (selectedEmployee) {
				return selectedEmployee.get('id');
			});
			var employeeIds = this.get('isSelectedPeopleCustom') ? selectedEmployeeIds : null;
			var employeeFilters = this.get('isSelectedPeopleFilters') ? this.get('selectedEmployeeFilters') : null;
			var isPaidByCheckOnly = this.get('isPaidByCheckOnly');
			var isSupplementalFlatRate = !this.get('isTimeBasedRate');
			var offCyclePayFrequency = !this.get('isTimeBasedRate') ? null : this.get('offCyclePayFrequency');
			var shouldIncludeDeductions = this.get('shouldIncludeDeductions');
			var shouldIncludeGarnishments = this.get('shouldIncludeGarnishments');
			if (this.get('switches.pyp_third_party_sick_pay') && this.get('isThirdPartySickPay')) {
				isPaidByCheckOnly = true;
				isSupplementalFlatRate = true;
				offCyclePayFrequency = null;
				shouldIncludeDeductions = false;
				shouldIncludeGarnishments = false;
			}

			var request = {
				zpCompanyId: this.get('zPayrollCompany.id'),
				description: this.get('description'),
				checkDate: this.get('checkDate'),
				employeeIds: employeeIds,
				employeeFilters: employeeFilters,
				isPaidByCheckOnly: isPaidByCheckOnly,
				zpPayPeriodId: zpPayPeriodId,
				isThirdPartySickPay: this.get('isThirdPartySickPay'),
				isSupplementalFlatRate: isSupplementalFlatRate,
				offCyclePayFrequency: offCyclePayFrequency,
				shouldIncludeDeductions: shouldIncludeDeductions,
				shouldIncludeGarnishments: shouldIncludeGarnishments,
				selectedDeductions: this.get('selectedDeductions').mapBy('id'),
				selectedContributions: this.get('selectedContributions').mapBy('id')
			};
			return _ember['default'].ajaxPost(createZPayrollRunUrl, JSON.stringify(request));
		},

		createPayPeriod: function createPayPeriod() {
			return window.App.ZPayrollPayPeriod.createRecord({
				zpCompany: this.get('zPayrollCompany'),
				startDate: this.get('payPeriodStart'),
				endDate: this.get('payPeriodEnd')
			}).save().then(function (zpPayPeriod) {
				return { zpPayPeriod: zpPayPeriod };
			});
		},

		validatePayrollRun: function validatePayrollRun() {
			var checkDate, blockPeriod, payRunValidationService, isOffCycle, isContractorPaymentsCompany, filtersValidator, isValid;
			return regeneratorRuntime.async(function validatePayrollRun$(context$1$0) {
				var _this = this;

				while (1) switch (context$1$0.prev = context$1$0.next) {
					case 0:
						this.resetValidations();
						checkDate = this.get('checkDate');
						blockPeriod = this.get('zPayrollCompany.blockPeriod');
						payRunValidationService = this.get('payRunValidationService');
						isOffCycle = true;
						isContractorPaymentsCompany = this.get('isContractorPaymentsCompany');

						// Pay Run Title Validations
						if (_ember['default'].isEmpty(this.get('description')) || _ember['default'].isEmpty(this.get('description').trim())) {
							if (isContractorPaymentsCompany) {
								this.set('payRunNameErrorText', _componentLibraryConstantsPayroll._PAY_RUN_VALIDATIONS.payRunNameContractorErrorText);
							} else {
								this.set('payRunNameErrorText', _componentLibraryConstantsPayroll._PAY_RUN_VALIDATIONS.payRunNameErrorText);
							}
							this.set('hasValidationErrors', true);
						}

						// Pay Run Employee Ids Validations
						if (this.get('isSelectedPeopleCustom') && _ember['default'].isEmpty(this.get('selectedEmployees'))) {
							this.set('employeeIdsErrorText', _componentLibraryConstantsPayroll._PAY_RUN_VALIDATIONS.employeeIdsErrorText);
							this.set('hasValidationErrors', true);
						}

						// Pay Run Employee Filters Validations

						if (!this.get('isSelectedPeopleFilters')) {
							context$1$0.next = 14;
							break;
						}

						filtersValidator = this.get('selectedEmployeesFiltersValidationHandler') || function () {
							return false;
						};

						context$1$0.next = 12;
						return regeneratorRuntime.awrap(filtersValidator());

					case 12:
						isValid = context$1$0.sent;

						if (!isValid) {
							this.set('hasValidationErrors', true);
						}

					case 14:

						if (this.get('shouldValidatePayPeriod')) {
							// Pay Period Validations
							if (!this.validatePayPeriodDate(this.get('payPeriodStart'))) {
								this.set('payPeriodStartErrorText', _componentLibraryConstantsPayroll._PAY_RUN_PAY_PERIOD_VALIDATIONS.invalidPayPeriodStart);
								this.set('hasValidationErrors', true);
							}
							if (!this.validatePayPeriodDate(this.get('payPeriodEnd'))) {
								this.set('payPeriodEndErrorText', _componentLibraryConstantsPayroll._PAY_RUN_PAY_PERIOD_VALIDATIONS.invalidPayPeriodEnd);
								this.set('hasValidationErrors', true);
							}
							if (!this.validatePayPeriodDateRange(this.get('payPeriodStart'), this.get('payPeriodEnd'))) {
								this.set('payPeriodEndErrorText', _componentLibraryConstantsPayroll._PAY_RUN_PAY_PERIOD_VALIDATIONS.invalidPayPeriodEndAfterStartDate);
								this.set('hasValidationErrors', true);
							}
						}

						// Pay Frequency Validations
						if (this.get('isTimeBasedRate')) {
							if (_ember['default'].isEmpty(this.get('offCyclePayFrequency'))) {
								this.set('timeBasedErrorText', _componentLibraryConstantsPayroll._PAY_RUN_PAY_PERIOD_VALIDATIONS.invalidPayFrequency);
								this.set('hasValidationErrors', true);
							}
						}

						if (this.get('shouldIncludeDeductions')) {
							if (this.get('selectedDeductions').length === 0 && this.get('selectedContributions').length === 0) {
								this.set('shouldIncludeDeductionsErrorText', _componentLibraryConstantsPayroll._PAY_RUN_VALIDATIONS.shouldIncludeDeductionsErrorText);
								this.set('hasValidationErrors', true);
							}
						}

						if (!this.get('isThirdPartySickPay')) {
							context$1$0.next = 21;
							break;
						}

						return context$1$0.abrupt('return', this.get('hasValidationErrors') ? _ember['default'].RSVP.reject('validationErrors') : _ember['default'].RSVP.resolve());

					case 21:
						return context$1$0.abrupt('return', payRunValidationService.validateCheckDate(checkDate, blockPeriod, isOffCycle, isContractorPaymentsCompany).then(function (response) {
							if (response.status === 'error') {
								_this.set('hasValidationErrors', true);
								_this.set('checkDateErrorText', response.message);
							}
							if (response.status === 'warning' && !_this.get('hasValidationErrors')) {
								_this.setProperties({
									isPayByCheckRequested: true,
									showPayByCheckRequestedModalInV2: true
								});
								_this.set('checkDateConfirmationText', response.message);
								return _ember['default'].RSVP.resolve();
							}
							return _this.get('hasValidationErrors') ? _ember['default'].RSVP.reject('validationErrors') : _ember['default'].RSVP.resolve();
						}));

					case 22:
					case 'end':
						return context$1$0.stop();
				}
			}, null, this);
		},
		selectedContributions: _ember['default'].computed.filterByProperty('zpCompanyContributionTypes', 'isSelected'),
		selectedDeductions: _ember['default'].computed.filterByProperty('zpCompanyDeductionTypes', 'isSelected'),
		selectedContributionsDeductionsCount: _ember['default'].computed('selectedContributions', 'selectedDeductions', function () {
			return this.get('selectedContributions.length') + this.get('selectedDeductions.length');
		}),
		totalContributionsDeductionsCount: _ember['default'].computed('zpCompanyContributionTypes', 'zpCompanyDeductionTypes', function () {
			return this.get('zpCompanyContributionTypes.length') + this.get('zpCompanyDeductionTypes.length');
		}),

		actions: {
			openSelectContributionsDeductionModal: function openSelectContributionsDeductionModal() {
				_componentLibraryComponentsPayrollSelectDeductionsContributionsModal['default'].show({
					zpCompanyContributionTypes: this.get('zpCompanyContributionTypes'),
					zpCompanyDeductionTypes: this.get('zpCompanyDeductionTypes')
				});
			},

			onToggleTPSP: function onToggleTPSP() {
				if (this.get('isThirdPartySickPay')) {
					this.set('payPeriodStart', null);
					this.set('payPeriodEnd', null);
				}
			},

			closePayByCheckModal: function closePayByCheckModal() {
				this.toggleProperty('showPayByCheckRequestedModalInV2');
				this.set('checkDateConfirmationText', null);
			},

			cancelAddNewPayRun: function cancelAddNewPayRun() {
				if (this.get('isPayByCheckRequested')) {
					this.set('isPayByCheckRequested', false);
				} else {
					this.send('onCancel');
				}
			},

			updateFilters: function updateFilters(filters) {
				this.set('selectedEmployeeFilters', filters);
			},

			receiveFiltersValidationHandler: function receiveFiltersValidationHandler(handler) {
				this.set('selectedEmployeesFiltersValidationHandler', handler);
			},

			validateAndCreatePayRun: function validateAndCreatePayRun(createButton) {
				var _this2 = this;

				this.set('isSaving', true);
				this.validatePayrollRun().then(function () {
					if (_this2.get('isPayByCheckRequested')) {
						return _ember['default'].RSVP.reject('checkConfirmation');
					}
					return _this2.get('shouldValidatePayPeriod') ? _this2.createPayPeriod() : _ember['default'].RSVP.resolve(null);
				}).then(function (result) {
					var zpPayPeriod = result ? result.zpPayPeriod : null;
					return _this2.createPayrollRun(zpPayPeriod);
				}).then(function (response) {
					_this2.send('onCreatePayRun', response.zPayrollRunId, createButton);
				})['catch'](function (error) {
					if (error === 'checkConfirmation' || error === 'validationErrors') {
						return;
					}
					_this2.send('onErrorCreatingPayRun');
					_this2.set('isPayByCheckRequested', false);
					_this2.get('eventLogger').log('pyp_off_cycle_compliance_create_error', {
						error: JSON.stringify(error),
						zpCompanyId: _this2.get('zPayrollCompany.id'),
						description: _this2.get('description'),
						checkDate: _this2.get('checkDate')
					});
					var errorCode = (error.responseJSON || {}).error_code;
					var shouldUseMessageFromError = [_componentLibraryConstantsPayroll._CREATE_PAY_RUN_API_ERRORS_CODES.invalidFilterSelection].includes(errorCode);
					var errorMessage = shouldUseMessageFromError && error.responseJSON && error.responseJSON.error_message || 'We were unable to create the pay run. Please come back later.';
					_componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'Oops! Something went wrong.',
						message: errorMessage,
						buttonText: 'Close'
					});
				})['finally'](function () {
					createButton.stop();
					_this2.set('isSaving', false);
				});
			},

			confirmPayByCheck: function confirmPayByCheck(confirmButton) {
				var _this3 = this;

				this.set('isSaving', true);
				var payPeriodPromise = this.get('shouldValidatePayPeriod') ? this.createPayPeriod() : _ember['default'].RSVP.resolve(null);
				payPeriodPromise.then(function (result) {
					_this3.set('isPaidByCheckOnly', true);
					var zpPayPeriod = result ? result.zpPayPeriod : null;
					return _this3.createPayrollRun(zpPayPeriod);
				}).then(function (response) {
					_this3.send('onCreatePayRun', response.zPayrollRunId, confirmButton);
				})['catch'](function (error) {
					_this3.send('onErrorCreatingPayRun');
					_this3.get('eventLogger').log('pyp_off_cycle_compliance_confirm_error', {
						error: JSON.stringify(error),
						zpCompanyId: _this3.get('zPayrollCompany.id'),
						description: _this3.get('description'),
						checkDate: _this3.get('checkDate')
					});
					_componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'Oops! Something went wrong.',
						message: 'We were unable to create the pay run. Please come back later.',
						buttonText: 'Close'
					});
				})['finally'](function () {
					confirmButton.stop();
					_this3.set('isSaving', false);
				});
			}
		}
	});

	// We can have any check date for third party sick pay runs

	// Check Date Validations
});