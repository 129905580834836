define('component-library/components/d-tooltip-link', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		tagName: "div",
		classNameBindings: ['hasPopover:has-popover'],
		popoverClass: null,
		content: null,
		hasPopover: false,

		init: function init() {
			this._super();
			_ember['default'].deprecate('tooltip-link will be fully deprecated in Q4 2016. Use the Style Guide version instead, https://console.zenefits.com/console/style-guide#/components/tooltip', false, { id: 'tooltip-deprecation' });
		},

		mouseEnter: function mouseEnter() {
			var _this = this;

			var popoverClass = this.get('popoverClass');
			if (!this.get('hasPopover')) {
				var popover = popoverClass.open({
					target: this.$(),
					content: this.get('content'),
					animationDuration: 5,
					openOnHover: true
				});
				this.set('hasPopover', true);
				popover.promise.then(function (result) {
					if (_this.get('isDestroyed') || _this.get('isDestroying')) {
						return;
					}
					_this.set('hasPopover', false);
					if (result) {
						_this.sendAction('close', result);
					}
				});
			}
		}
	});
});