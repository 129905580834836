define('component-library/services/wizard-settings-setup', ['exports', 'ember', 'component-library/services/wizard', 'component-library/wizard/state/flow', 'component-library/wizard/settings-setup'], function (exports, _ember, _componentLibraryServicesWizard, _componentLibraryWizardStateFlow, _componentLibraryWizardSettingsSetup) {
	'use strict';

	exports['default'] = _componentLibraryServicesWizard['default'].extend({
		settingsSetupWizardMode: _componentLibraryWizardSettingsSetup.SETTINGS_SETUP_MODES.SETUP,
		isSetupMode: _ember['default'].computed.equal('settingsSetupWizardMode', _componentLibraryWizardSettingsSetup.SETTINGS_SETUP_MODES.SETUP),
		isSettingsMode: _ember['default'].computed.equal('settingsSetupWizardMode', _componentLibraryWizardSettingsSetup.SETTINGS_SETUP_MODES.SETTINGS),

		settingsWizardFlowState: _ember['default'].computed(function () {
			return _componentLibraryWizardStateFlow['default'].create({});
		}),
		setupWizardFlowState: _ember['default'].computed(function () {
			return _componentLibraryWizardStateFlow['default'].create({});
		}),

		wizardFlowState: _ember['default'].computed('settingsWizardFlowState', 'setupWizardFlowState', 'isSetupMode', function () {
			return this.get('isSetupMode') ? this.get('setupWizardFlowState') : this.get('settingsWizardFlowState');
		}),

		// handlers

		onSectionIndexEnter: function onSectionIndexEnter(sectionKey) {
			if (this.get('isSettingsMode')) {
				// accept the transition by doing nothing
			} else {
					this._super(sectionKey);
				}
		},

		onStepEnter: function onStepEnter(stepKey) {
			if (this.get('isSettingsMode')) {
				// transition to section index
				var flowState = this.get('wizardFlowState');
				var sectionState = flowState.get('currentSection');
				this.transitionToStep(sectionState);
			} else {
				this._super(stepKey);
			}
		}
	});
});