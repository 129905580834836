define('component-library/components/carousel-modal', ['exports', 'component-library/components/promise-modal'], function (exports, _componentLibraryComponentsPromiseModal) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'components/carousel-modal',
		modalDialogClass: 'z-confirmationModal',
		cancelOnBackground: true,
		content: [],
		title: ''
	});
});