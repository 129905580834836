define('component-library/data-table/cells/avatarname-multi-sort-table-cell', ['exports', 'component-library/data-table/cells/avatarname-table-cell', 'ui-infra/services/switches'], function (exports, _componentLibraryDataTableCellsAvatarnameTableCell, _uiInfraServicesSwitches) {
	'use strict';

	exports['default'] = _componentLibraryDataTableCellsAvatarnameTableCell['default'].extend({
		templateName: 'data-table/cells/avatarname-multi-sort-table-cell',
		isSortingByFirstName: (function () {
			return this.get('column.isSorted') && this.get('column.sortProperty') == 'firstName';
		}).property('column.sortProperty', 'column.isSorted'),
		isSortingByLastName: (function () {
			return this.get('column.isSorted') && this.get('column.sortProperty') == 'lastName';
		}).property('column.sortProperty', 'column.isSorted')

	});
});