define('z-table/components/z-table-filter-panel-multi-select', ['exports', 'ember', 'z-table/templates/components/z-table-filter-panel-multi-select'], function (exports, _ember, _zTableTemplatesComponentsZTableFilterPanelMultiSelect) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['z-table-filter-panel-multi-select', 'js-zTableFilterPanelMultiSelect'],
    layout: _zTableTemplatesComponentsZTableFilterPanelMultiSelect['default'],
    filter: null,

    actions: {
      toggleSelection: function toggleSelection(filter, option) {
        _ember['default'].set(option, 'isSelected', !option.isSelected);
        this.get('applyFilters')(filter, [option]);
      },

      selectAll: function selectAll(filter) {
        filter.options.setEach('isSelected', true);
        this.get('applyFilters')(filter, filter.options);
      },

      singleSelection: function singleSelection(filter, option) {
        filter.options.setEach('isSelected', false);
        _ember['default'].set(option, 'isSelected', true);
        this.get('applyFilters')(filter, filter.options);
      }
    }
  });
});