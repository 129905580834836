define("component-library/utils/date-ranges", ["exports", "component-library/utils/globals"], function (exports, _componentLibraryUtilsGlobals) {
	"use strict";

	var _slicedToArray = (function () {
		function sliceIterator(arr, i) {
			var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}return _arr;
		}return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	})();

	/*
 	Given a year & a quarter (or month), these functions will return the corresponding startDate and endDate.
 	e.g:
 	func(year = 2016, quarter = 1) -> [moment('01/01/2016'), moment('03/31/2016')]
 */

	var getDateRangeFromQuarter = function getDateRangeFromQuarter(quarter, year) {
		var startDate = undefined,
		    endDate = undefined;

		var mapping = {
			1: ["01/01/" + year, "03/31/" + year],
			2: ["04/01/" + year, "06/30/" + year],
			3: ["07/01/" + year, "09/30/" + year],
			4: ["10/01/" + year, "12/31/" + year]
		};

		var _mapping$quarter = _slicedToArray(mapping[quarter], 2);

		startDate = _mapping$quarter[0];
		endDate = _mapping$quarter[1];

		return [(0, _componentLibraryUtilsGlobals.moment)(startDate), (0, _componentLibraryUtilsGlobals.moment)(endDate)];
	};

	exports.getDateRangeFromQuarter = getDateRangeFromQuarter;

	var getDateRangeFromMonth = function getDateRangeFromMonth(month, year) {
		if (month < 10) month = "0" + month;

		var daysInMonth = (0, _componentLibraryUtilsGlobals.moment)(year + "-" + month, "YYYY-MM").daysInMonth();
		var startDate = month + "/01/" + year;
		var endDate = month + "/" + daysInMonth + "/" + year;
		return [(0, _componentLibraryUtilsGlobals.moment)(startDate), (0, _componentLibraryUtilsGlobals.moment)(endDate)];
	};
	exports.getDateRangeFromMonth = getDateRangeFromMonth;
});