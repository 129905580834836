define('component-library/components/payroll/add-pay-run-card', ['exports', 'ember', 'component-library/mixins/payroll/add-pay-run-mixin'], function (exports, _ember, _componentLibraryMixinsPayrollAddPayRunMixin) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsPayrollAddPayRunMixin['default'], {
		payRunAdditionalDetails: _ember['default'].computed(function () {
			return this.get('isContractorPaymentsCompany') ? 'Payment Additional Details' : 'Pay Run Additional Details';
		}),
		addPayDateLabel: _ember['default'].computed(function () {
			return this.get('isContractorPaymentsCompany') ? 'Payment Date' : 'Pay Date';
		}),
		title: _ember['default'].computed('isContractorPaymentsCompany', function () {
			return this.get('isContractorPaymentsCompany') ? 'You\'ll Have To Pay Contractors By Check' : 'You\'ll Have To Pay Employees By Check';
		}),

		actions: {
			onCancel: function onCancel(laddaButton) {
				this.attrs.goToPreviousStep(laddaButton);
			},
			onCreatePayRun: function onCreatePayRun(payRunId) {
				this.attrs.onCreatePayRun(payRunId);
			},
			onErrorCreatingPayRun: function onErrorCreatingPayRun() {
				this.attrs.onErrorCreatingPayRun();
			}
		}
	});
});