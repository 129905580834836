define('component-library/simple-wizard/state', ['exports', 'component-library/wizard/state/section'], function (exports, _componentLibraryWizardStateSection) {
	'use strict';

	exports['default'] = _componentLibraryWizardStateSection['default'].extend({
		// For reference from wizard section state

		// required
		routeName: null,
		sectionKey: null,
		flow: null,
		title: null,

		// optional
		currentStepIndex: null,
		sequenceEnforced: false,
		currentActiveStep: 0,
		activeStepKey: ''
	});
});