define('component-library/components/date-picker-legends', ['exports', 'ember', 'component-library/components/date-picker'], function (exports, _ember, _componentLibraryComponentsDatePicker) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var TYPES = Object.values(_componentLibraryComponentsDatePicker.INDICATOR_TYPES);
	var COLORS = [].concat(_toConsumableArray(Object.values(_componentLibraryComponentsDatePicker.INDICATOR_COLORS)), ['primaryRangeColor', 'secondaryRangeColor']);

	var constructMappingKey = function constructMappingKey(type, color) {
		return 'has' + type.capitalize() + 'In' + color.capitalize();
	};

	var CLASSNAME_MAPPING = {};
	TYPES.forEach(function (type) {
		COLORS.forEach(function (color) {
			CLASSNAME_MAPPING[constructMappingKey(type, color)] = 'z-datePicker-legend-' + type + ' z-datePicker-legend-' + type + '--' + color;
		});
	});

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-datePicker-legends'],
		data: [],

		_data: _ember['default'].computed('data', 'data.@each.color', 'data.@each.type', 'data.@each.label', 'data.@each.infoTooltipText', 'data.@each.date', function () {
			return this.get('data').map(function (row) {
				return Object.assign({}, row, {
					dotClass: CLASSNAME_MAPPING[constructMappingKey(row.type, row.color)]
				});
			});
		})

	});
});