define('component-library/mixins/propagate-action', ['exports', 'ember'], function (exports, _ember) {
  /*
  Types of failure...
  1) Not including PropagateActionMixin on each intermediate component
  Result: "You need to use PropagateAction mixin at every level of the Component hierarchy."
  
  2) Not providing the action on the top-level controller
  Result: "Nothing handled the action 'sayBonjour'. If you did handle the action...."
  */

  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    // this behaves similar to sendAction in that the first parameter is a
    // reference to a property. This will propagate as long as the mixin is included
    sendActionWithPropagation: function sendActionWithPropagation() {
      var args = Array.prototype.slice.call(arguments);
      var actionPropertyString = args[0];
      if (actionPropertyString && this.get(actionPropertyString)) {
        var actionString = this.get(actionPropertyString);
        args[0] = actionString;
        this.sendActionWithPropagationByName.apply(this, args);
      }
    },
    // this is just like sendActionWithPropagation but you pass in the name
    // of the action rather than a reference to a property
    sendActionWithPropagationByName: function sendActionWithPropagationByName() {
      var args = Array.prototype.slice.call(arguments);
      args.unshift('_propagateActionChannel');
      this.set('_propagateActionChannel', '_propagateActionChannel');
      try {
        this.sendAction.apply(this, args);
      } catch (error) {
        // every error unravels back to this original try/catch
        // and is analyzed/presented here
        if (error instanceof _ember['default'].Error) {
          throw error;
        } else {
          throw new Error('You need to use PropagateAction mixin ' + 'at every level of the Component hierarchy.');
        }
      }
    },
    actions: {
      _propagateActionChannel: function _propagateActionChannel() {
        var args = Array.prototype.slice.call(arguments);
        var actionString = args[0];
        if (this._actions && this._actions[actionString]) {
          this.send.apply(this, arguments);
        }

        try {
          this.set(actionString, actionString);
          this.sendAction.apply(this, arguments);
          // this catches if the action is not found on the next level up
        } catch (errorFirst) {

          try {
            this.sendActionWithPropagationByName.apply(this, arguments);
          } catch (errorSecond) {

            if (errorSecond.message.indexOf('_propagateActionChannel') > -1) {
              throw errorFirst;
            } else if (errorFirst instanceof _ember['default'].Error) {
              throw errorFirst;
            } else {
              throw errorSecond;
            }
          }
        }
      }
    }
  });
});