define('component-library/components/benefits/timeline/transaction-detail-summary', ['exports', 'ember', 'component-library/templates/components/benefits/timeline/transaction-detail-summary', 'component-library/utils/pretty-currency', 'component-library/mixins/benefits/timeline/transaction-employee-data-mixin', 'component-library/mixins/benefits/timeline/transaction-dependents-data-mixin', 'component-library/mixins/benefits/timeline/transaction-contact-data-mixin', 'component-library/constants/benefits'], function (exports, _ember, _componentLibraryTemplatesComponentsBenefitsTimelineTransactionDetailSummary, _componentLibraryUtilsPrettyCurrency, _componentLibraryMixinsBenefitsTimelineTransactionEmployeeDataMixin, _componentLibraryMixinsBenefitsTimelineTransactionDependentsDataMixin, _componentLibraryMixinsBenefitsTimelineTransactionContactDataMixin, _componentLibraryConstantsBenefits) {
	'use strict';

	var getLnDVolume = function getLnDVolume(obj, attrName) {
		return obj ? (0, _componentLibraryUtilsPrettyCurrency['default'])(obj[attrName]) : 'NA';
	};

	// mimic backend logic to decide whether EE has coverage
	var hasCoverage = function hasCoverage(event, eventCycle) {
		if (_componentLibraryConstantsBenefits.benefitsCancellingEventCycles.has(eventCycle) || _componentLibraryConstantsBenefits.benefitsCancellingEvents.has(event)) {
			return false;
		}
		return true;
	};

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsBenefitsTimelineTransactionEmployeeDataMixin['default'], _componentLibraryMixinsBenefitsTimelineTransactionDependentsDataMixin['default'], _componentLibraryMixinsBenefitsTimelineTransactionContactDataMixin['default'], {
		store: _ember['default'].inject.service(),

		layout: _componentLibraryTemplatesComponentsBenefitsTimelineTransactionDetailSummary['default'],

		didReceiveAttrs: function didReceiveAttrs() {
			var _this = this;

			this._super.apply(this, arguments);

			var signatureId = this.get('signatureId');
			if (signatureId) {
				this.get('store').find('signature', signatureId).then(function (signature) {
					_this.set('signature', signature.get('signature'));
				});
			}

			this.get('planDisplayNamePromise').then(function (name) {
				return _this.set('planName', name);
			});
		},

		employeeFullAddress: _ember['default'].computed('address', 'city', 'contactState', 'zip', function () {
			return [this.get('address'), this.get('city'), this.get('contactState'), this.get('zip')].join(', ');
		}),

		showPlan: _ember['default'].computed('employeePlan', function () {
			return this.get('employeePlan.hasPlan');
		}),

		showDependent: _ember['default'].computed('transaction.event', 'transaction.eventCycle', 'dependentSummary.@each.isEnrolled', function () {
			var event = this.get('transaction.event');
			var eventCycle = this.get('transaction.eventCycle');

			return hasCoverage(event, eventCycle) && this.get('dependentSummary').any(function (dependent) {
				return dependent.isEnrolled;
			});
		}),

		employeePlan: _ember['default'].computed('employeeMDVPlan', 'employeeLnDBasicPlan', 'employeeLnDVoluntaryPlan', 'planName', function () {
			var mdvPlan = this.get('employeeMDVPlan');
			var lndBasicPlan = this.get('employeeLnDBasicPlan');
			var lndVoluntaryPlan = this.get('employeeLnDVoluntaryPlan');
			var planName = this.get('planName');
			var isMDV = Boolean(mdvPlan);

			if (isMDV) {
				return {
					planName: planName,
					isMDV: isMDV,
					hasPlan: true
				};
			}

			return {
				planName: planName,
				isMDV: isMDV,
				hasPlan: lndBasicPlan || lndVoluntaryPlan,
				basicElected: getLnDVolume(lndBasicPlan, 'electedAmount'),
				basicApproved: getLnDVolume(lndBasicPlan, 'amount'),
				voluntaryElected: getLnDVolume(lndVoluntaryPlan, 'electedAmount'),
				voluntaryApproved: getLnDVolume(lndVoluntaryPlan, 'amount')
			};
		}),

		dependentSummary: _ember['default'].computed.map('dependents', function (item) {
			var firstName = item.personalInfo.firstName;
			var lastName = item.personalInfo.lastName;
			var type = item.personalInfo.type;
			var dob = item.personalInfo.dateOfBirth;
			var gender = item.personalInfo.gender;
			var isEnrolled = item.coverage.isEnrolled;
			var effectiveDate = item.coverage.effectiveDate;
			var lndBasicPlan = item.coverage.lndBasicPlan;
			var lndVoluntaryPlan = item.coverage.lndVoluntaryPlan;

			var fullName = firstName + ' ' + lastName;
			var isMDV = !lndBasicPlan && !lndVoluntaryPlan;

			var ret = { fullName: fullName, type: type, dob: dob, gender: gender, isMDV: isMDV, isEnrolled: isEnrolled, effectiveDate: effectiveDate };

			if (isMDV) {
				return ret;
			}

			ret.basicElected = getLnDVolume(lndBasicPlan, 'electedAmount');
			ret.basicApproved = getLnDVolume(lndBasicPlan, 'amount');
			ret.voluntaryElected = getLnDVolume(lndVoluntaryPlan, 'electedAmount');
			ret.voluntaryApproved = getLnDVolume(lndVoluntaryPlan, 'amount');
			return ret;
		})

	});
});