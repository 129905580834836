define('component-library/timeline/components/new-hire', ['exports', 'component-library/timeline/components/copy', 'component-library/timeline/mixins/feed-entry', 'component-library/timeline/utils/status', 'component-library/timeline/utils/computed-copy', 'component-library/utils/pretty-currency', 'component-library/utils/tags/context-tag-computed', 'component-library/utils/computed-props/pretty-date'], function (exports, _componentLibraryTimelineComponentsCopy, _componentLibraryTimelineMixinsFeedEntry, _componentLibraryTimelineUtilsStatus, _componentLibraryTimelineUtilsComputedCopy, _componentLibraryUtilsPrettyCurrency, _componentLibraryUtilsTagsContextTagComputed, _componentLibraryUtilsComputedPropsPrettyDate) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['', ' made a request to hire ', ' as ', ' starting ', ' for ', ''], ['', ' made a request to hire ', ' as ', ' starting ', ' for ', '']),
	    _templateObject2 = _taggedTemplateLiteral(['', ' approved ', ' request to hire ', '\n\t\t\tas ', ' starting ', ' for ', ''], ['', ' approved ', ' request to hire ', '\n\t\t\tas ', ' starting ', ' for ', '']),
	    _templateObject3 = _taggedTemplateLiteral(['', ' declined ', ' request to hire ', '\n\t\t\tas ', ' starting ', ' for ', ''], ['', ' declined ', ' request to hire ', '\n\t\t\tas ', ' starting ', ' for ', '']),
	    _templateObject4 = _taggedTemplateLiteral(['', ' signed the offer letter'], ['', ' signed the offer letter']),
	    _templateObject5 = _taggedTemplateLiteral(['', ' has completed onboarding and will start on ', ''], ['', ' has completed onboarding and will start on ', '']);

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	exports['default'] = _componentLibraryTimelineComponentsCopy['default'].extend(_componentLibraryTimelineMixinsFeedEntry['default'], {

		copy: _componentLibraryTimelineUtilsComputedCopy['default'].apply(undefined, _toConsumableArray(_componentLibraryTimelineUtilsStatus.newHireStatusTypes)),
		waiting_on_app: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject, 'requesterStr', 'employeeStr', 'data.title', 'hireDate', 'payStr'),
		no_action: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject2, 'approverStr', 'requesterStrPossessive', 'employeeStr', 'data.title', 'hireDate', 'payStr'),
		declined: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject3, 'approverStr', 'requesterStrPossessive', 'employeeStr', 'data.title', 'hireDate', 'payStr'),
		signed: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject4, 'employeeStr'),
		complete: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject5, 'employeeStr', 'hireDate'),

		payStr: (function () {
			var compType = this.get('data.compensationType');
			if (compType == 'H') {
				var payRate = this.get('data.payRate');
				return (0, _componentLibraryUtilsPrettyCurrency['default'])(payRate) + ' per hour';
			}
			if (compType == 'S') {
				var salary = this.get('data.annualSalary');
				return (0, _componentLibraryUtilsPrettyCurrency['default'])(salary) + ' per year';
			}
			return 'unknown salary';
		}).property('data.annualSalary', 'data.payRate', 'data.compensationType'),

		hireDate: (0, _componentLibraryUtilsComputedPropsPrettyDate['default'])('data.hireDate')

	});
});