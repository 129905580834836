define('ember-cli-bundle-loader/services/lazy-loader', ['exports', 'ember', 'ember-cli-bundle-loader/config/bundles', 'ember-cli-bundle-loader/utils/get-owner', 'ember-cli-bundle-loader/utils/load-assets'], function (exports, _ember, _emberCliBundleLoaderConfigBundles, _emberCliBundleLoaderUtilsGetOwner, _emberCliBundleLoaderUtilsLoadAssets) {
  'use strict';

  var A = _ember['default'].A;
  var computed = _ember['default'].computed;

  var loadedBundles = {};

  exports['default'] = _ember['default'].Service.extend({
    bundles: null,
    loadedBundles: computed(function () {
      return loadedBundles;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.setBundles(_emberCliBundleLoaderConfigBundles['default']);
    },
    setBundles: function setBundles(bundles) {
      loadedBundles = {};
      bundles.forEach(function (bundle) {
        return loadedBundles[bundle.name] = false;
      });
      this.set('bundles', bundles);
    },
    needsLazyLoading: function needsLazyLoading(routeName) {
      var bundle = this.getBundleForRouteName(routeName);
      return bundle && !this.isBundleLoaded(bundle.name);
    },
    isBundleLoaded: function isBundleLoaded(bundleName) {
      return loadedBundles[bundleName];
    },
    markBundleAsLoaded: function markBundleAsLoaded(bundleName) {
      loadedBundles[bundleName] = true;
    },
    getBundleForRouteName: function getBundleForRouteName(routeName) {
      return A(this.get('bundles')).find(function (bundle) {
        return A(bundle.routeNames || []).find(function (pattern) {
          return routeName.match(pattern) && !A(bundle.blacklistedRouteNames || []).find(function (blacklist) {
            return routeName.match(blacklist);
          });
        });
      });
    },
    getBundleByName: function getBundleByName(bundleName) {
      return A(this.get('bundles')).find(function (bundle) {
        return bundle.name === bundleName;
      });
    },
    loadBundleForUrl: function loadBundleForUrl(url) {
      return this.loadBundleForRouteName(this._getRouteNameFromUrl(url));
    },
    loadBundleForRouteName: function loadBundleForRouteName(routeName) {
      return this.loadBundle(this.getBundleForRouteName(routeName));
    },
    loadBundle: function loadBundle(bundleOrBundleName) {
      var _this = this;

      var bundle = typeof bundleOrBundleName === 'string' ? this.getBundleByName(bundleOrBundleName) : bundleOrBundleName;
      if (!bundle) {
        _ember['default'].assert('A bundle was expected, but we couldn\'t find it', bundle);
        return _ember['default'].RSVP.resolve();
      }
      if (this.isBundleLoaded(bundle.name)) {
        return _ember['default'].RSVP.resolve();
      }

      return _ember['default'].RSVP.all(this.getDependentBundlesForBundle(bundle).map(function (bundle) {
        return (0, _emberCliBundleLoaderUtilsLoadAssets['default'])(bundle.urls).then(function () {
          return _this.markBundleAsLoaded(bundle.name);
        });
      }));
    },

    getDependentBundlesForBundle: function getDependentBundlesForBundle(rootBundle) {
      var self = this;

      var dependentBundles = [];
      //  seenBundleNames is a map of bundle name -> bundle -- need O(1) collection management.
      var seenBundleNames = {};
      seenBundleNames[rootBundle.name] = true;

      function _addDependents(dependentBundleNames) {
        dependentBundleNames.forEach(function (dependencyName) {
          if (seenBundleNames[dependencyName]) {
            return;
          }
          var dependencyBundle = self.getBundleByName(dependencyName);
          seenBundleNames[dependencyBundle.name] = true;
          // Adds its dependents before we add this module
          _addDependents(dependencyBundle.dependsOn || []);
          dependentBundles.push(dependencyBundle);
        });
      }
      _addDependents(rootBundle.dependsOn || []);
      // Added at the end, since this is the last bundle we should load
      dependentBundles.push(rootBundle);

      return dependentBundles;
    },

    _getRouteNameFromUrl: function _getRouteNameFromUrl(url) {
      var router = (0, _emberCliBundleLoaderUtilsGetOwner.getContainer)(this).lookup('router:main');
      var routes = router.router.recognizer.recognize(url);
      if (routes && routes.length) {
        return routes[routes.length - 1].handler;
      }
    }
  });
});