define('component-library/data-table/cells/editable/table-controls-cell', ['exports', 'component-library/data-table/cells/data-table-cell'], function (exports, _componentLibraryDataTableCellsDataTableCell) {
	'use strict';

	exports['default'] = _componentLibraryDataTableCellsDataTableCell['default'].extend({
		templateName: 'data-table/cells/editable/table-controls-cell',

		actions: {
			edit: function edit() {
				this.set('row.isEditing', true);
				this.get('row.target.parentController').sendAction('editFn', this.get('row.content'));
			},

			cancel: function cancel() {
				this.set('row.isEditing', false);
				this.get('row.target.parentController').sendAction('cancelFn', this.get('row.content'));
			},

			save: function save() {
				this.set('row.isEditing', false);
				this.get('row.target.parentController').sendAction('saveFn', this.get('row.content'));
			}
		}
	});
});