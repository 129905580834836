define('ui-infra/instance-initializers/session-timer', ['exports', 'ember', 'ui-infra/utils/get-config', 'ui-infra/utils/ember-compatibility-utils'], function (exports, _ember, _uiInfraUtilsGetConfig, _uiInfraUtilsEmberCompatibilityUtils) {
  'use strict';

  exports.initialize = initialize;

  var getWithDefault = _ember['default'].getWithDefault;

  function initialize(appInstance) {
    var config = (0, _uiInfraUtilsGetConfig['default'])(appInstance);
    // Boot app starts a session timer, so don't start if it's alreadty ther
    // Don't use the session ping in tests
    // Avoid this error: https://github.com/rwjblue/ember-qunit/issues/155
    if (config.environment === 'test') {
      return;
    }

    // Re-use existing timer
    var sessionTimerInstance = window.zen.sessionTimer || new window.zen.SessionTimer({
      ajaxFn: _ember['default'].$.ajax,
      throttle: function throttle(fn, timeout) {
        return function () {
          return _ember['default'].run.throttle(null, fn, timeout);
        };
      }
    });
    (0, _uiInfraUtilsEmberCompatibilityUtils.register)(appInstance, 'service:session-timer', sessionTimerInstance, { instantiate: false });
    if (getWithDefault(config, 'ui-infra.sessionTimer.startOnBoot', true)) {
      console.log('starting timer');
      sessionTimerInstance.start();
    }
  }

  exports['default'] = {
    name: 'session-timer',
    initialize: initialize
  };
});