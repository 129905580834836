define('component-library/components/contributions-modal/tab-eligibility', ['exports', 'ember', 'component-library/components/tabbed-modal/tab', 'component-library/components/contributions-modal/constants'], function (exports, _ember, _componentLibraryComponentsTabbedModalTab, _componentLibraryComponentsContributionsModalConstants) {
	'use strict';

	exports['default'] = _componentLibraryComponentsTabbedModalTab['default'].extend({
		ELIGIBILITY_SELECTION: _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION,
		employeesOptions: _ember['default'].computed(function () {
			return this.get('employees').map(function (item) {
				return {
					id: item.get('id'),
					text: item.get('fullName'),
					description: ''
				};
			});
		}),
		companyTiersChoices: _ember['default'].computed('tiers', function () {
			return this.get('tiers').map(function (tier) {
				return tier.name;
			}).uniq().map(function (tierName) {
				return {
					id: tierName,
					text: tierName
				};
			});
		}),
		selectedCarrier: _ember['default'].computed.alias('coverageSelection.selectedCarrier'),
		selectedPlan: _ember['default'].computed.alias('coverageSelection.selectedPlan'),
		selectedLoc: _ember['default'].computed.alias('coverageSelection.selectedLoc'),
		employeeSpecificSelected: _ember['default'].computed.equal('currentEligibilitySelection', _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.EMPLOYEE),
		tierSpecificSelected: _ember['default'].computed.equal('currentEligibilitySelection', _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.TIER),
		revealErrorObserver: _ember['default'].observer('revealErrors', 'isValid', function () {
			// this is because onNext function set the revealErrors to be true, so I override it here.
			this.set('revealErrors', !this.get('isValid'));
		}),
		revealErrors: false,
		isValid: _ember['default'].computed('validation', function () {
			return this.get('validation.isValid');
		}),
		isDuplicatedRule: _ember['default'].computed('currentRuleId', 'rules', 'selectedLoc', 'selectedCarrier', 'selectedPlan', 'currentEligibilitySelection', 'selectedEmployees', 'selectedTiers', function () {
			var _this = this;

			var loc = this.get('selectedLoc.id');
			var carrierId = this.get('selectedCarrier.id');
			var planId = this.get('selectedPlan.id');
			var allEmployeesSelected = this.get('currentEligibilitySelection');
			var selectedEmployeeIds = this.get('selectedEmployees').map(function (obj) {
				return obj.id;
			});
			var selectedTiers = this.get('selectedTiers').map(function (obj) {
				return obj.id;
			});
			// exclude the current rule if the user want to edit, to prevent checking the same rule.
			var rulesLhs = this.get('rules').filter(function (rule) {
				return rule.get('id') != _this.get('currentRuleId');
			}).map(function (obj) {
				return JSON.stringify([obj.get('lineOfCoverage'), obj.get('carrierId'), obj.get('planId')]);
			});
			if (rulesLhs.includes(JSON.stringify([loc, carrierId, planId]))) {
				var matchingRulesSoFar = this.get('rules').filter(function (obj) {
					return obj.get('id') != _this.get('currentRuleId') && obj.get('lineOfCoverage') == loc && obj.get('carrierId') == carrierId && obj.get('planId') == planId;
				});
				if (this.get('currentEligibilitySelection') == _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.EMPLOYEE) {
					var _ret = (function () {
						var allEmployeeIdsInTheMatchingRules = new Set(matchingRulesSoFar.map(function (obj) {
							return obj.get('employeeTemplate.employeeIds');
						}).filter(function (o) {
							return o;
						}).flat().map(function (id) {
							return parseInt(id);
						}));

						if (selectedEmployeeIds.filter(function (eId) {
							return allEmployeeIdsInTheMatchingRules.has(eId);
						}).length > 0) {
							return {
								v: true
							};
						}
						return {
							v: false
						};
					})();

					if (typeof _ret === 'object') return _ret.v;
				} else if (this.get('currentEligibilitySelection') == _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.TIER) {
					var _ret2 = (function () {
						var allTiersInTheMatchingRules = new Set(matchingRulesSoFar.map(function (obj) {
							return obj.get('employeeTemplate.tiers');
						}).filter(function (o) {
							return o;
						}).flat());
						if (selectedTiers.filter(function (tier) {
							return allTiersInTheMatchingRules.has(tier);
						}).length > 0) {
							return {
								v: true
							};
						}
						return {
							v: false
						};
					})();

					if (typeof _ret2 === 'object') return _ret2.v;
				} else if (this.get('currentEligibilitySelection') == _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.ALL) {
					return matchingRulesSoFar.filter(function (rule) {
						return rule.get('employeeTemplate.type') == _componentLibraryComponentsContributionsModalConstants.UNDEFINED;
					}).length > 0;
				}
			}
			return false;
		}),
		validation: _ember['default'].computed('currentEligibilitySelection', 'selectedEmployees', 'selectedTiers', 'isDuplicatedRule', function () {
			var isValid = true;
			var reason = '';
			if (this.get('isDuplicatedRule')) {
				isValid = false;
				reason = 'This rule contains employees that is already associated with another rule, please remove them to continue';
			} else if (this.get('currentEligibilitySelection') == _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.TIER) {
				isValid = !_ember['default'].isEmpty(this.get('selectedTiers'));
				reason = isValid ? '' : 'Please fill all required fields';
			} else if (this.get('currentEligibilitySelection') == _componentLibraryComponentsContributionsModalConstants.ELIGIBILITY_SELECTION.EMPLOYEE) {
				isValid = !_ember['default'].isEmpty(this.get('selectedEmployees'));
				reason = isValid ? '' : 'Please fill all required fields';
			}
			return _ember['default'].Object.create({
				isValid: isValid,
				reason: reason
			});
		})
	});
});