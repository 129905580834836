define('component-library/components/contributions-modal/tab-coverage', ['exports', 'ember', 'component-library/components/tabbed-modal/tab', 'component-library/components/contributions-modal/constants', 'ui-infra/services/switches'], function (exports, _ember, _componentLibraryComponentsTabbedModalTab, _componentLibraryComponentsContributionsModalConstants, _uiInfraServicesSwitches) {
	'use strict';

	exports['default'] = _componentLibraryComponentsTabbedModalTab['default'].extend({
		doNotChangeCarrier: false,
		doNotChangePlan: false,
		selectedCarrier: _ember['default'].computed.alias('coverageSelection.selectedCarrier'),
		selectedPlan: _ember['default'].computed.alias('coverageSelection.selectedPlan'),
		selectedLoc: _ember['default'].computed.alias('coverageSelection.selectedLoc'),
		createSelectOptions: function createSelectOptions(objectsPath, labelPath, valuePath, nullLabel) {
			var objects = this.get(objectsPath);
			var options = nullLabel === undefined ? [] : [{
				id: null,
				text: nullLabel
			}];
			if (!objects) {
				return options;
			}
			return options.concat(objects.map(function (obj) {
				return {
					id: obj.get(valuePath),
					text: obj.get(labelPath)
				};
			}));
		},
		getLocFromCarrierId: function getLocFromCarrierId(carrierId) {
			var mapping = this.get('mapping');
			return Object.keys(mapping).find(function (item) {
				return mapping[item][carrierId];
			});
		},
		lineOfCoveragesChoices: _ember['default'].computed('locs', function () {
			var locs = this.get('locs');
			return _componentLibraryComponentsContributionsModalConstants.LINE_OF_COVERAGES_CHOICES.filter(function (item) {
				if (_componentLibraryComponentsContributionsModalConstants.SUPPLEMENTAL_LOCS.includes(item.id) && !_uiInfraServicesSwitches['default'].instance.get('benefits_contribution_scheme_supplemental')) {
					return false;
				}
				if (_componentLibraryComponentsContributionsModalConstants.VOL_LND_LOCS.includes(item.id) && !_uiInfraServicesSwitches['default'].instance.get('benefits_contribution_scheme_voluntary_lnd')) {
					return false;
				}
				return _ember['default'].isNone(item.id) || locs.includes(item.id);
			});
		}),
		filteredCarriers: _ember['default'].computed('selectedLoc.id', function () {
			var loc = this.get('selectedLoc.id');
			var mapping = this.get('mapping');
			if (_ember['default'].isNone(loc)) {
				return this.get('carriers');
			}
			return this.get('carriers').filter(function (item) {
				return mapping[loc] && mapping[loc][item.get('id')];
			});
		}),
		filteredPlans: _ember['default'].computed('selectedLoc.id', 'selectedCarrier.id', function () {
			var carrierId = this.get('selectedCarrier.id');
			var loc = this.get('selectedLoc.id');
			return this.get('plans').filter(function (plan) {
				return (_ember['default'].isNone(carrierId) || _ember['default'].isEqual(plan.get('stateCarrier_id'), carrierId)) && (_ember['default'].isNone(loc) || _ember['default'].isEqual(loc, plan.get('lineOfCoverage')));
			});
		}),
		locChangeHook: _ember['default'].observer('selectedLoc', function () {
			if (!this.get('doNotChangeCarrier')) {
				this.set('selectedCarrier', this.get('carrierSelectOptions.firstObject'));
			}
			if (!this.get('doNotChangePlan')) {
				this.set('selectedPlan', this.get('planSelectOptions.firstObject'));
			}
		}),
		carrierChangeHook: _ember['default'].observer('selectedCarrier.id', function () {
			var selectedCarrierId = this.get('selectedCarrier.id');
			if (!this.get('doNotChangePlan')) {
				this.set('selectedPlan', this.get('planSelectOptions.firstObject'));
			}
			if (selectedCarrierId && _ember['default'].isNone(this.get('selectedLoc.id'))) {
				var loc = this.getLocFromCarrierId(selectedCarrierId);
				this.set('doNotChangeCarrier', true);
				this.set('selectedLoc', this.get('lineOfCoveragesChoices').findBy('id', loc));
				this.set('doNotChangeCarrier', false);
			}
		}),
		planChangeHook: _ember['default'].observer('plans.@each.id', 'selectedPlan.id', function () {
			var planId = this.get('selectedPlan.id');
			var plan = this.get('plans').findBy('id', planId);
			if (planId) {
				var loc = plan.get('lineOfCoverage');
				this.set('doNotChangePlan', true);
				this.set('doNotChangeCarrier', true);
				this.set('selectedLoc', this.get('lineOfCoveragesChoices').findBy('id', loc));
				var carrierId = plan.get('stateCarrier_id');
				this.set('selectedCarrier', this.get('carrierSelectOptions').findBy('id', carrierId));
				this.set('doNotChangePlan', false);
				this.set('doNotChangeCarrier', false);
			}
		}),
		carrierSelectOptions: _ember['default'].computed('filteredCarriers.[]', function () {
			return this.createSelectOptions('filteredCarriers', 'displayName', 'id', 'All');
		}),
		planSelectOptions: _ember['default'].computed('filteredPlans.[]', 'selectedLoc', function () {
			return this.createSelectOptions('filteredPlans', 'displayName', 'id', 'All');
		})
	});
});