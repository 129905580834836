define('component-library/components/pay-schedule-card', ['exports', 'ember', 'component-library/mixins/pay-schedule-mixin', 'component-library/utils/globals', 'component-library/components/date-picker'], function (exports, _ember, _componentLibraryMixinsPayScheduleMixin, _componentLibraryUtilsGlobals, _componentLibraryComponentsDatePicker) {
	'use strict';

	// this is the pay schedule card. It shows the pay periods and has buttons for editing
	// and deleting the pay schedule.
	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsPayScheduleMixin['default'], {
		classNames: ['z-payScheduleCard'],
		classNameBindings: ['isSmall:z-payScheduleCard--sm', 'isHorizontal:z-payScheduleCard--horizontal'],
		calendarMode: true,
		isSmall: false,
		isEditable: true,
		zPayrollEnums: undefined,
		blockPeriod: undefined,
		bankHolidays: undefined,
		allowPastEffectiveDate: true,

		showLegend: false,
		isHorizontal: false,

		arrearsDays: _ember['default'].computed.oneWay('model.arrearsDays'),
		compType: _ember['default'].computed.oneWay('model.compType'),
		payFrequency: _ember['default'].computed.oneWay('model.payFrequency'),
		payDayOfWeek: _ember['default'].computed.oneWay('model.payDayOfWeek'),
		payDayOfMonth: _ember['default'].computed.oneWay('model.payDayOfMonth'),
		secondPayDayOfMonth: _ember['default'].computed.oneWay('model.secondPayDayOfMonth'),
		holidayShift: _ember['default'].computed.oneWay('model.holidayShift'),
		saturdayShift: _ember['default'].computed.oneWay('model.saturdayShift'),
		sundayShift: _ember['default'].computed.oneWay('model.sundayShift'),
		indicators: _ember['default'].computed('bankHolidays', 'checkDates', function () {
			return [{
				dates: this.get('bankHolidays'),
				color: _componentLibraryComponentsDatePicker.INDICATOR_COLORS.PINK,
				type: _componentLibraryComponentsDatePicker.INDICATOR_TYPES.DOT
			}, {
				dates: this.get('checkDates'),
				color: _componentLibraryComponentsDatePicker.INDICATOR_COLORS.BLUE,
				type: _componentLibraryComponentsDatePicker.INDICATOR_TYPES.DOT
			}];
		}),

		// need to convert to moment date
		unshiftedAnchorCheckDate: (function () {
			return (0, _componentLibraryUtilsGlobals.moment)(this.get('model.unshiftedAnchorCheckDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		}).property('model.unshiftedAnchorCheckDate'),

		// This is so that the pay schedule components shows
		// the current date if the effective date was in the past
		displayDate: (function () {
			var effectiveDate = (0, _componentLibraryUtilsGlobals.moment)(this.get('activePayPeriods.firstObject.startDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
			var today = (0, _componentLibraryUtilsGlobals.moment)();
			var displayDate = effectiveDate > today ? effectiveDate : today;

			return displayDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		}).property('activePayPeriods.firstObject.startDate'),

		actions: {
			editPaySchedule: function editPaySchedule(model) {
				this.sendAction('editPaySchedule', model);
			},

			deletePaySchedule: function deletePaySchedule(laddaButton) {
				this.sendAction('deletePaySchedule', laddaButton, this.get('model'));
			},

			showReadOnly: function showReadOnly(model) {
				this.sendAction('showReadOnly', model);
			},

			showAssignedEmployees: function showAssignedEmployees() {
				this.sendAction('showAssignedEmployees', this.get('model'));
			},

			setViewModeToGrid: function setViewModeToGrid() {
				this.set('calendarMode', false);
			},

			setViewModeToCalendar: function setViewModeToCalendar() {
				this.set('calendarMode', true);
			}
		}
	});
});
// for mixins in CL/app
// for globals in CL/app