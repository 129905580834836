define('component-library/components/date-picker-field', ['exports', 'component-library/components/date-picker-popover', 'component-library/components/z-text-field-date'], function (exports, _componentLibraryComponentsDatePickerPopover, _componentLibraryComponentsZTextFieldDate) {
	'use strict';

	// TODO (Fong): remove this file after date-picker-field is replaced with z-text-field-date.
	exports['default'] = _componentLibraryComponentsZTextFieldDate['default'].extend({
		classNames: ['z-datePickerField', 'js-z-datePickerField'],
		classNameBindings: ['isDisabled'],
		additionalClasses: null,
		displayCalendar: true,
		showIcon: false,

		popoverClassIfExists: (function () {
			return this.get('displayCalendar') && !this.get('isDisabled') ? _componentLibraryComponentsDatePickerPopover['default'] : null;
		}).property('displayCalendar', 'isDisabled'),

		actions: {
			focusedIn: function focusedIn(fieldId) {
				this.sendAction('focusedIn', fieldId);
			},
			focusedOut: function focusedOut(fieldId) {
				this.sendAction('focusedOut', fieldId);
			}
		}
	});
});