define("z-table/templates/components/z-pager", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.10-zenefits.3+18063622",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 0
          }
        },
        "moduleName": "modules/z-table/templates/components/z-pager.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "zmdi zmdi-chevron-left zmdi-hc-lg");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "class", "z-table-paginator-input u-noSpinner u-bumperRight--sm");
        dom.setAttribute(el1, "type", "number");
        dom.setAttribute(el1, "min", "1");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "mute");
        var el2 = dom.createTextNode("of ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "zmdi zmdi-chevron-right zmdi-hc-lg");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [6]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createAttrMorph(element1, 'max');
        morphs[3] = dom.createAttrMorph(element1, 'value');
        morphs[4] = dom.createAttrMorph(element1, 'oninput');
        morphs[5] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
        morphs[6] = dom.createAttrMorph(element2, 'class');
        morphs[7] = dom.createElementMorph(element2);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["z-table-paginator-link u-pad--sm u-bumperRight--sm ", ["subexpr", "if", [["get", "isFirstPage", ["loc", [null, [1, 97], [1, 108]]]], "disabled"], [], ["loc", [null, [1, 92], [1, 121]]]]]]], ["element", "action", ["onPageBackwards"], [], ["loc", [null, [1, 5], [1, 33]]]], ["attribute", "max", ["get", "totalPages", ["loc", [null, [9, 8], [9, 18]]]]], ["attribute", "value", ["get", "currentPage", ["loc", [null, [10, 10], [10, 21]]]]], ["attribute", "oninput", ["subexpr", "action", [["get", "onPageChange", ["loc", [null, [11, 19], [11, 31]]]]], ["value", "target.value"], ["loc", [null, [11, 10], [11, 54]]]]], ["content", "totalPages", ["loc", [null, [14, 22], [14, 36]]]], ["attribute", "class", ["concat", ["z-table-paginator-link u-pad--sm u-bumperLeft--sm ", ["subexpr", "if", [["get", "isFinalPage", ["loc", [null, [16, 95], [16, 106]]]], "disabled"], [], ["loc", [null, [16, 90], [16, 119]]]]]]], ["element", "action", ["onPageForwards"], [], ["loc", [null, [16, 5], [16, 32]]]]],
      locals: [],
      templates: []
    };
  })());
});