define('component-library/services/permissions', ['exports', 'ember', 'lodash/lodash'], function (exports, _ember, _lodashLodash) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var DATA_ENDPOINT = '/custom_api/roles_permissions_data';
	var GET_ADMINS_ENDPOINT = '/custom_api/get_company_admins_data';
	var ADD_ADMIN_ENDPOINT = '/custom_api/change_company_admins_data/';
	var DELETE_ADMIN_ENDPOINT = '/custom_api/delete_company_admin/';

	var setDifference = function setDifference(setA, setB) {
		return new Set([].concat(_toConsumableArray(setA)).filter(function (a) {
			return !setB.has(a);
		}));
	};

	var setIntersection = function setIntersection(setA, setB) {
		return new Set([].concat(_toConsumableArray(setA)).filter(function (a) {
			return setB.has(a);
		}));
	};

	var setUnion = function setUnion(setA, setB) {
		return new Set([].concat(_toConsumableArray(setA), _toConsumableArray(setB)));
	};

	exports['default'] = _ember['default'].Service.extend({
		_data: null,

		currentEmployeeHas: function currentEmployeeHas(permissions, objectId, mode) {
			/*
   check if the current user has permissions.
   permissions: string or array of strings to check permissions for
   objectId: parameter (usually employee id) to check if applicable
   mode: 'and' or 'or'
   	returns a boolean
   */
			var data = this.get('_data');
			mode = (mode || 'and').toLowerCase();
			if (_lodashLodash['default'].isString(permissions)) {
				permissions = [permissions];
			}
			var parameterError = this._checkParameters(data, permissions, !!objectId);
			if (parameterError) {
				throw new Error(parameterError);
			}
			if (objectId) {
				return this._computeFilter(data, permissions, new Set([objectId]), mode).has(objectId);
			} else {
				if (mode == 'and') {
					return _lodashLodash['default'].every(permissions, function (permission) {
						return data.grants.hasOwnProperty(permission);
					});
				} else if (mode == 'or') {
					return _lodashLodash['default'].some(permissions, function (permission) {
						return data.grants.hasOwnProperty(permission);
					});
				} else {
					throw new Error();
				}
			}
		},

		currentEmployeeFilter: function currentEmployeeFilter(permissions, objectIds, mode) {
			/*
   check which objectIds the current user has access to. if not passed,
   return the subset of possible objectIds
   permissions: string or array of strings to check permissions for
   objectIds: parameters (usually employee ids)
   mode: 'and' or 'or'
   	returns an array of ids
   */
			mode = mode || 'and';
			if (_lodashLodash['default'].isString(permissions)) {
				permissions = [permissions];
			}
			if (!objectIds && permissions.length == 0 && mode == 'and') {
				//empty array is truthy in js
				throw new Error("infinite return");
			}
			var setObjectIds = objectIds && new Set(objectIds);
			var data = this.get('_data');
			var filtered = this._computeFilter(data, permissions, setObjectIds, mode);
			if (objectIds) {
				return objectIds.filter(function (objectId) {
					return filtered.has(objectId);
				});
			} else {
				return [].concat(_toConsumableArray(filtered));
			}
		},

		currentEmployeeFilterPermissions: function currentEmployeeFilterPermissions(permissions, objectId, mode) {
			var _this = this;

			if (_lodashLodash['default'].isString(permissions)) {
				permissions = [permissions];
			}
			return permissions.filter(function (permission) {
				return _this.currentEmployeeHas(permission, objectId);
			});
		},

		_checkParameters: function _checkParameters(data, permissions, passedParam) {
			var illegalPermission = _lodashLodash['default'].find(permissions, function (permission) {
				return data.grants.hasOwnProperty(permission) && !!data.grants[permission] != passedParam;
			});
			if (illegalPermission) {
				if (passedParam) {
					return 'PermissionParameterMismatch: ' + illegalPermission + ' does not take a parameter';
				} else {
					return 'PermissionParameterMismatch: ' + illegalPermission + ' requires a parameter';
				}
			}
		},

		_computeFilter: function _computeFilter(data, permissions, objectIds, mode) {
			if (mode == 'and') {
				return this._computeAnd(data, permissions, objectIds);
			} else if (mode == 'or') {
				return this._computeOr(data, permissions, objectIds);
			}
		},

		_computeOr: function _computeOr(data, permissions, objectIds) {
			var allSpans = [].concat(_toConsumableArray(permissions)).reduce(function (accumlatedSpans, permission) {
				var grants = data.grants[permission] || [];
				return [].concat(_toConsumableArray(accumlatedSpans), _toConsumableArray(grants));
			}, []);

			if (!objectIds) {
				return allSpans.reduce(function (accumlatedObjectIds, span) {
					return setUnion(accumlatedObjectIds, data.spans[span[0]][span[1]]);
				}, new Set());
			}

			var inaccessable = objectIds;
			var _iteratorNormalCompletion = true;
			var _didIteratorError = false;
			var _iteratorError = undefined;

			try {
				for (var _iterator = allSpans[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
					var span = _step.value;

					if (inaccessable.size == 0) {
						return objectIds;
					}
					inaccessable = setDifference(inaccessable, data.spans[span[0]][span[1]]);
				}
			} catch (err) {
				_didIteratorError = true;
				_iteratorError = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion && _iterator['return']) {
						_iterator['return']();
					}
				} finally {
					if (_didIteratorError) {
						throw _iteratorError;
					}
				}
			}

			return setDifference(objectIds, inaccessable);
		},

		_computeAnd: function _computeAnd(data, permissions, objectIds) {
			var _iteratorNormalCompletion2 = true;
			var _didIteratorError2 = false;
			var _iteratorError2 = undefined;

			try {
				for (var _iterator2 = permissions[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
					var permission = _step2.value;

					if (objectIds && objectIds.size == 0) {
						return objectIds;
					}
					var grants = data.grants[permission] || new Set();
					var newObjectIds = [].concat(_toConsumableArray(grants)).reduce(function (objIds, span) {
						return setUnion(objIds, data.spans[span[0]][span[1]]);
					}, new Set());
					objectIds = objectIds ? setIntersection(objectIds, newObjectIds) : newObjectIds;
				}
			} catch (err) {
				_didIteratorError2 = true;
				_iteratorError2 = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion2 && _iterator2['return']) {
						_iterator2['return']();
					}
				} finally {
					if (_didIteratorError2) {
						throw _iteratorError2;
					}
				}
			}

			return objectIds;
		},

		fetch: function fetch() {
			var _this2 = this;

			return _ember['default'].getJSON(DATA_ENDPOINT).then(function (data) {
				var spans = _lodashLodash['default'].mapValues(data.spans, function (obj) {
					return _lodashLodash['default'].mapValues(obj, function (arr) {
						return arr ? new Set(arr) : null;
					});
				});
				_this2.set('_data', {
					spans: spans,
					grants: _lodashLodash['default'].mapValues(data.grants, function (arr) {
						return arr ? new Set(arr) : null;
					})
				});
			});
		},

		fetchCompanyAdminsData: function fetchCompanyAdminsData() {
			return _ember['default'].getJSON(GET_ADMINS_ENDPOINT).then(function (data) {
				return _lodashLodash['default'].mapValues(data, function (obj) {
					return _lodashLodash['default'].mapValues(obj, function (arr) {
						return arr ? new Set(arr) : null;
					});
				});
			});
		},

		addCompanyAdmin: function addCompanyAdmin(adminId, permissionsData) {
			return _ember['default'].ajaxPost(ADD_ADMIN_ENDPOINT + adminId, JSON.stringify(permissionsData));
		},

		removeAdminPermissions: function removeAdminPermissions(adminId) {
			return _ember['default'].ajaxPost(DELETE_ADMIN_ENDPOINT + adminId);
		}
	});
});