define('component-library/data-table/cells/select-all-or-none-header-cell', ['exports', 'ember-table/views/header-cell', 'component-library/templates/data-table/cells/select-all-or-none-header-cell'], function (exports, _emberTableViewsHeaderCell, _componentLibraryTemplatesDataTableCellsSelectAllOrNoneHeaderCell) {
	'use strict';

	exports['default'] = _emberTableViewsHeaderCell['default'].extend({
		template: _componentLibraryTemplatesDataTableCellsSelectAllOrNoneHeaderCell['default'],
		actions: {
			toggleAll: function toggleAll(selectAll) {
				this.get('controller').sendAction('toggleAllCheckboxes', selectAll);
			}
		}
	});
});