define('component-library/utils/dirty', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = directModelIsDirty;

	function directModelIsDirty(model) {
		var relationshipsNameSet = {};
		var relationships = _ember['default'].get(model.constructor, 'relationshipNames');

		if (relationships) {
			relationships.belongsTo.forEach(function (key) {
				relationshipsNameSet[key] = true;
			});
		}

		if (_ember['default'].isEmpty(model._data)) {
			return false;
		}

		return !Object.keys(model._data).every(function (key) {
			if (key == "pk") {
				return true;
			}

			var oldVal = model._data[key];
			var curVal = model.get(key);

			if (relationshipsNameSet[key] && oldVal == curVal.get('id')) {
				return true;
			}

			if (_ember['default'].isEmpty(oldVal) && _ember['default'].isEmpty(curVal)) {
				return true;
			}

			if (_ember['default'].isArray(oldVal) && _ember['default'].isArray(curVal)) {
				if (oldVal.get('length') !== curVal.get('length')) {
					return false;
				}

				var oldAllInCur = oldVal.every(function (obj) {
					if (obj instanceof _ember['default'].Object) {
						return curVal.findProperty('id', obj.get('id'));
					} else {
						return curVal.indexOf(obj) >= 0;
					}
				});

				var curAllInOld = curVal.every(function (obj) {
					if (obj instanceof _ember['default'].Object) {
						return oldVal.findProperty('id', obj.get('id'));
					} else {
						return oldVal.indexOf(obj) >= 0;
					}
				});

				return oldAllInCur && curAllInOld;
			}

			return oldVal == curVal;
		});
	}
});