define('component-library/components/census/import-spreadsheet', ['exports', 'ember', 'component-library/templates/components/census/import-spreadsheet', 'component-library/constants/z-spreadsheet-schema', 'component-library/constants/census-constants'], function (exports, _ember, _componentLibraryTemplatesComponentsCensusImportSpreadsheet, _componentLibraryConstantsZSpreadsheetSchema, _componentLibraryConstantsCensusConstants) {
	'use strict';

	// lazy load libs
	var SHEETJS_URL = '/static/js/xlsx-0.8.0.full.min.js';
	var MOXIE_JS_URL = '/static/js/moxie-1.4.1.min.js';
	var MOXIE_SWF_URL = '/static/js/Moxie-1.4.1.min.swf';

	var XLSX = undefined,
	    mOxie = undefined;

	exports['default'] = _ember['default'].Component.extend({ layout: _componentLibraryTemplatesComponentsCensusImportSpreadsheet['default'] }, {
		classNames: ['z-census', 'js-importSpreadsheetComponent', 'z-typography', 'grid-frame'],

		/////////////////////////////////////////////////////////////////////////////////
		// Pass these into component
		model: null, // censusState
		flow: null, // query param for flow, such as 'HR' or 'BOR'
		sheetsRoute: null, // route for census in-browser spreadsheet
		//Also, pass in action name that gets triggered once xlsx file is done importing.
		/////////////////////////////////////////////////////////////////////////////////

		helpText: '',
		uploaderState: '',
		hasUploadedSpreadsheet: false,
		dropZoneHover: false,
		uploadedFileName: '',
		spreadsheet: null,
		fileInput: null,
		isMoxieInitialized: false,

		censusService: _ember['default'].inject.service('census'),

		_getSpreadsheetColumnHeadersData: (function () {
			var spreadsheetContent = this.get('spreadsheet');
			// get the range
			var range = XLSX.utils.decode_range(spreadsheetContent['!ref']);
			var columnHeadersData = {};
			var cellEncode = undefined,
			    cellValue = undefined;

			// range.s.c: column start index
			// range.e.c: column end index
			for (var colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
				cellEncode = XLSX.utils.encode_cell({ c: colIndex, r: 0 });
				if (spreadsheetContent[cellEncode]) {
					cellValue = spreadsheetContent[cellEncode].v;
					columnHeadersData[cellValue] = colIndex;
				}
			}
			return columnHeadersData;
		}).property('spreadsheet'),

		_getSpreadsheetColumnHeaders: (function () {
			return Object.keys(this.get('_getSpreadsheetColumnHeadersData'));
		}).property('_getSpreadsheetColumnHeadersData'),

		_getExpectedColumnHeaders: (function () {
			var columnHeaders = [_componentLibraryConstantsCensusConstants.ZENEFITS_ID];
			var columns = this.get('model.fields');

			columns.forEach(function (col) {
				columnHeaders.push(_componentLibraryConstantsZSpreadsheetSchema.EMPLOYEE_CENSUS_FIELD_SCHEMA[col]['colHeader']);
			});

			return columnHeaders;
		}).property('model.fields'),

		_hasCorrectColumnHeaders: (function () {
			var expectedColumnHeaders = this.get('_getExpectedColumnHeaders');
			var spreadsheetColumnHeaders = this.get('_getSpreadsheetColumnHeaders');

			return expectedColumnHeaders.every(function (colHeader) {
				return spreadsheetColumnHeaders.contains(colHeader);
			});
		}).property('_getExpectedColumnHeaders', '_getSpreadsheetColumnHeaders'),

		_convertSpreadsheetToCensusFormat: function _convertSpreadsheetToCensusFormat(spreadsheetContent) {
			var expectedColumnHeaders = this.get('_getExpectedColumnHeaders');
			var spreadsheetColumnHeadersData = this.get('_getSpreadsheetColumnHeadersData');
			var range = XLSX.utils.decode_range(spreadsheetContent['!ref']);
			var employeeIdColumIndex = spreadsheetColumnHeadersData[_componentLibraryConstantsCensusConstants.ZENEFITS_ID];
			var censusRecords = this.get('censusService').censusState.records;

			var rowIndex = undefined,
			    colIndex = undefined,
			    cellEncode = undefined,
			    modelProp = undefined,
			    record = undefined,
			    censusRecord = undefined,
			    realId = undefined,
			    cellValue = undefined,
			    nonEmptyCellCount = undefined;
			var parser = undefined,
			    beforeSubmit = undefined;
			var records = [];

			for (rowIndex = range.s.r + 1; rowIndex <= range.e.r; rowIndex++) {
				record = {};
				realId = null;
				censusRecord = null;

				parser = null;
				nonEmptyCellCount = 0;

				expectedColumnHeaders.forEach(function (colHeader) {
					// eslint-disable-line no-loop-func
					colIndex = spreadsheetColumnHeadersData[colHeader];
					cellEncode = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex });

					if (spreadsheetContent[cellEncode]) {
						cellValue = spreadsheetContent[cellEncode].w;

						if (colHeader === _componentLibraryConstantsCensusConstants.ZENEFITS_ID && cellValue) {
							censusRecord = censusRecords.findBy('realId', cellValue);
							//Stop processing this iteration, no need to parse readId here
							return;
						}

						modelProp = _componentLibraryConstantsZSpreadsheetSchema.EMPLOYEE_CENSUS_COLUMNS_NAME_TO_FIELD[colHeader];

						if (_componentLibraryConstantsZSpreadsheetSchema.EMPLOYEE_CENSUS_FIELD_SCHEMA.hasOwnProperty(modelProp)) {
							parser = _componentLibraryConstantsZSpreadsheetSchema.EMPLOYEE_CENSUS_FIELD_SCHEMA[modelProp]['parser'];
						}

						record[modelProp] = parser ? parser(cellValue) : cellValue;

						if (!_ember['default'].isBlank(cellValue)) {
							nonEmptyCellCount++;
						}
					}
				});

				// Some field has data depencency (e.g. _compensation), so we have to do this in separate loop
				expectedColumnHeaders.forEach(function (colHeader) {
					// eslint-disable-line no-loop-func
					beforeSubmit = null;

					modelProp = _componentLibraryConstantsZSpreadsheetSchema.EMPLOYEE_CENSUS_COLUMNS_NAME_TO_FIELD[colHeader];
					if (_componentLibraryConstantsZSpreadsheetSchema.EMPLOYEE_CENSUS_FIELD_SCHEMA.hasOwnProperty(modelProp)) {
						beforeSubmit = _componentLibraryConstantsZSpreadsheetSchema.EMPLOYEE_CENSUS_FIELD_SCHEMA[modelProp]['beforeSubmit'];
					}

					if (beforeSubmit) {
						beforeSubmit(record);
					}
				});
				/*jshint +W083 */

				if (censusRecord) {
					record._censusRecordId = censusRecord.id;
					record._censusRecordRealId = censusRecord.realId;

					records.push({
						id: censusRecord.id,
						realId: censusRecord.realId,
						displayId: censusRecord.displayId,
						data: record
					});
				} else if (nonEmptyCellCount) {
					records.push({
						data: record
					});
				}
			}

			return records;
		},

		setupSpreadsheetParser: function setupSpreadsheetParser() {
			var _this = this;

			XLSX = window.XLSX;
			mOxie = window.mOxie;

			if (!XLSX || !mOxie || this.get('isMoxieInitialized')) {
				return;
			}

			mOxie.Env.swf_url = MOXIE_SWF_URL;
			var reader = new FileReader();

			reader.onloadend = function () {
				var workbook = XLSX.read(reader.result, { type: 'binary' });
				var spreadsheetContent = workbook.Sheets['EmployeeInfo'];

				_this.set('spreadsheet', spreadsheetContent);

				if (!_this.get('_hasCorrectColumnHeaders')) {
					_this.set('helpText', 'The headers of this file are incorrect. Are you sure you used the template?');
					_this.set('uploaderState', 'error');
					return;
				}

				var originalCensusState = _this.get('censusService').censusState;

				_this.get('censusService').getOrCreate(originalCensusState.flowName, originalCensusState.companyId).then(function () {
					var updatesAndCreationRecordsList = _this._convertSpreadsheetToCensusFormat(spreadsheetContent);
					var currentCensusState = _this.get('censusService').censusState;

					_this.get('censusService').update(currentCensusState.censusId, updatesAndCreationRecordsList, []).then(function (censusState) {
						_this.set('uploaderState', 'success');
						_this.set('helpText', _this.get('uploadedFileName') + ' successfully uploaded!');

						// Have controller handle redirect on success
						_this.sendAction('action');
					});
				});
			};

			var fileInput = this.get('fileInput');
			fileInput = new mOxie.FileInput({
				browse_button: 'jsId-importSpreadsheet-browseButton', // or document.getElementById('file-picker')
				accept: [{
					title: 'spreadsheets',
					extensions: 'xls,xlsx,xlsm,xlsb'
				}]
			});

			fileInput.onchange = function (e) {
				_this.setProperties({
					'uploaderState': '',
					'hasUploadedSpreadsheet': true,
					'uploadedFileName': fileInput.files[0]['name']
				});
				var spreadsheetContent = fileInput.files[0];
				reader.readAsBinaryString(spreadsheetContent.getSource());
			};
			fileInput.init();

			var fileDrop = new mOxie.FileDrop({
				drop_zone: 'file-dropper',
				accept: [{
					title: 'spreadsheets',
					extensions: 'xls,xlsx,xlsm,xlsb'
				}]
			});

			fileDrop.ondrop = function () {
				_this.set('uploaderState', '');
				if (!fileDrop.files.length) {
					_this.setProperties({
						'uploaderState': 'error',
						'helpText': 'Incorrect file type.  Please upload a file with one of the supported extensions.'
					});
				} else {
					_this.setProperties({
						'helpText': '',
						'hasUploadedSpreadsheet': true,
						'uploadedFileName': fileDrop.files[0]['name']
					});
					var spreadsheetContent = fileDrop.files[0];
					reader.readAsBinaryString(spreadsheetContent.getSource());
				}
				_this.set('dropZoneHover', false);
			};
			fileDrop.ondragenter = function () {
				_this.set('dropZoneHover', true);
			};
			fileDrop.ondragleave = function () {
				_this.set('dropZoneHover', false);
			};
			fileDrop.init();
			this.set('isMoxieInitialized', true);
		},

		// lazy-load js libs for this route only don't load if already loaded
		_loadLibs: function _loadLibs() {
			var _this2 = this;

			if (_ember['default'].testing) {
				// Libs should be preloaded in tests
				this.setupSpreadsheetParser();
				return;
			}

			[SHEETJS_URL, MOXIE_JS_URL].forEach(function (fileUrl) {
				_ember['default'].ajax({
					url: fileUrl,
					dataType: 'script',
					cache: true,
					success: _this2.setupSpreadsheetParser.bind(_this2)
				});
			});
		},

		init: function init() {
			this._super.apply(this, arguments);
			this._loadLibs();
		},

		willDestroyElement: function willDestroyElement() {
			var fileInput = this.get('fileInput');
			if (fileInput) {
				fileInput.destroy();
			}

			this.set('isMoxieInitialized', false);
		},

		actions: {
			downloadTemplate: function downloadTemplate() {
				window.location.assign('/custom_api/census/get-sheet/xls/' + this.get('flow') + '/' + encodeURIComponent(this.get('censusService').censusState.companyId) + '/');
			}
		}
	});
});