define('component-library/timeline/utils/computed-copy', ['exports', 'ember', 'ui-infra/utils/sanitize-html'], function (exports, _ember, _uiInfraUtilsSanitizeHtml) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	var generateStatus = function generateStatus(dependentKeys, statusProperty) {
		var status = _ember['default'].get(this, statusProperty);
		if (!dependentKeys.contains(status)) {
			return 'Unknown Status';
		}
		var copyStr = _ember['default'].get(this, status);
		// copyStr ideally should be sanitized before arriving here but may not be. so here is an soft measure
		// to render the content as a safe string.
		// to locate an example of copyStr containing markup, trace computedCopy to mixins/feed-entry.js
		return (0, _uiInfraUtilsSanitizeHtml.nonUserEditableField)(capitalizeFirstLetter(copyStr));
	};

	var computedCopy = function computedCopy() {
		for (var _len = arguments.length, dependentKeys = Array(_len), _key = 0; _key < _len; _key++) {
			dependentKeys[_key] = arguments[_key];
		}

		return _ember['default'].computed.apply(_ember['default'], ['data.status'].concat(dependentKeys, [function () {
			return generateStatus.bind(this)(dependentKeys, 'data.status');
		}]));
	};

	var computedCopyFormatted = function computedCopyFormatted(dependentKeys) {
		var statusProperty = arguments.length <= 1 || arguments[1] === undefined ? 'data.status' : arguments[1];

		return _ember['default'].computed.apply(_ember['default'], [statusProperty].concat(_toConsumableArray(dependentKeys), [function () {
			return generateStatus.bind(this)(dependentKeys, statusProperty);
		}]));
	};

	exports.computedCopyFormatted = computedCopyFormatted;
	exports['default'] = computedCopy;
});