define('component-library/deductions/review/controller', ['exports', 'ember', 'component-library/deductions/review/deductions-lazy-loader', 'component-library/utils/deductions/deductions-utils'], function (exports, _ember, _componentLibraryDeductionsReviewDeductionsLazyLoader, _componentLibraryUtilsDeductionsDeductionsUtils) {
	'use strict';

	exports['default'] = _ember['default'].Controller.extend({
		companyModel: _ember['default'].computed.alias('companyController.model'),
		isEligibleForStandaloneContributionCatchups: _ember['default'].computed.alias('companyController.isEligibleForStandaloneContributionCatchups'),

		searchQuery: null,
		discrepancyOnly: false,
		isDiscrepancyFilterEnabled: true,
		displayNormalContribution: false,
		displayNormalDeduction: false,

		deductionTabTitle: (function () {
			return 'Deductions - ' + (this.get('displayNormalDeduction') ? 'Expected' : 'Current');
		}).property('displayNormalDeduction'),

		contributionTabTitle: (function () {
			return 'Contributions - ' + (this.get('displayNormalContribution') ? 'Expected' : 'Current');
		}).property('displayNormalContribution'),

		allDeductionTypes: (function () {
			var contexts = this.get('model.employeeDeductionContexts');
			if (_ember['default'].isEmpty(contexts)) {
				return _ember['default'].A();
			} else {
				return contexts.reduce(function (types, emd) {
					var all = emd.get('deductionLines');
					all.forEach(function (type) {
						if (types.indexOf(type) < 0) {
							types.addObject(type);
						}
					});
					return types;
				}, _ember['default'].A());
			}
		}).property('model.employeeDeductionContexts.[]'),
		allContributionTypes: _ember['default'].computed.alias('allDeductionTypes'),

		filteredDeductionContexts: (function () {
			return _ember['default'].A();
		}).property(),

		applySearchFilter: function applySearchFilter(data) {
			var searchQuery = this.get('searchQuery') || '';
			if (_ember['default'].isEmpty(searchQuery)) {
				return data;
			}
			searchQuery = searchQuery.toLowerCase();
			return data.filter(function (row) {
				var fullName = row.get('fullName').toLowerCase() || '';
				return fullName.indexOf(searchQuery) >= 0;
			});
		},

		applyDiscrepancyOnlyFilter: function applyDiscrepancyOnlyFilter(data) {
			if (this.get('discrepancyOnly')) {
				if (!this.get('displayContribution')) {
					return data.filter(function (row) {
						var catchups = row.get('catchUpLines');
						var donotpushes = row.get('doNotPushLines');
						var filteredCatchups = catchups.filter(function (item) {
							return donotpushes.indexOf(item) === -1;
						});
						return Boolean(filteredCatchups.length);
					});
				} else if (this.get('isEligibleForStandaloneContributionCatchups')) {
					return data.filter(function (row) {
						var catchups = row.get('catchUpContribLines');
						var donotpushes = row.get('doNotPushContribLines');
						var filteredCatchups = catchups.filter(function (item) {
							return donotpushes.indexOf(item) === -1;
						});
						return Boolean(filteredCatchups.length);
					});
				}
			}
			return data;
		},

		applyFnDidChange: (function () {
			var data = this.get('model.employeeDeductionContexts');
			if (_ember['default'].isEmpty(data)) {
				return;
			}
			var filteredData = this.applySearchFilter(data);
			if (this.get('isDiscrepancyFilterEnabled')) {
				filteredData = this.applyDiscrepancyOnlyFilter(filteredData);
			}
			this.set('filteredDeductionContexts', filteredData);
		}).observes('searchQuery', 'model.employeeDeductionContexts.[]', 'isDiscrepancyFilterEnabled', 'discrepancyOnly', 'displayContribution').on('init'),

		validateCheckDate: (function () {
			var frequencyToDateMap = {};
			var allFrequencies = [];
			this.get('model.employeeDeductionContexts').forEach(function (data) {
				var payFrequency = data.get('payFrequency');
				var payDate = data.get('nextCheckDate');
				payDate = (0, _componentLibraryUtilsDeductionsDeductionsUtils.getFormattedCheckDate)(payDate);
				if (payFrequency in frequencyToDateMap) {
					var existingPayDates = frequencyToDateMap[payFrequency];
					if (!existingPayDates.contains(payDate)) {
						frequencyToDateMap[payFrequency] = frequencyToDateMap[payFrequency].concat([payDate]);
					}
				} else {
					frequencyToDateMap[payFrequency] = [payDate];
				}
				if (allFrequencies.indexOf(payFrequency) < 0) {
					allFrequencies.pushObject(payFrequency);
				}
			});
			var dateToFrequencyMap = {};
			allFrequencies.forEach(function (frequency) {
				var dates = frequencyToDateMap[frequency];
				dates.forEach(function (date) {
					if (date in dateToFrequencyMap) {
						dateToFrequencyMap[date] = dateToFrequencyMap[date].concat([frequency]);
					} else {
						dateToFrequencyMap[date] = [frequency];
					}
				});
			});
			if (allFrequencies.length > 0) {
				var nextCheckDates = Object.keys(dateToFrequencyMap).sort().map(function (date) {
					var frequencies = dateToFrequencyMap[date].map(function (frequency) {
						return (0, _componentLibraryUtilsDeductionsDeductionsUtils.getPayFrequency)(frequency);
					});
					return date + ' (' + frequencies.join(', ') + ')';
				});
				return 'Next check date: ' + nextCheckDates.join(', ');
			} else {
				return '';
			}
		}).property('model.employeeDeductionContexts.[]'),

		deductionsLoader: (function () {
			var deductionsLoader = _componentLibraryDeductionsReviewDeductionsLazyLoader['default'].create({
				companyId: this.get('companyModel.id'),
				deductionTypes: this.get('allDeductionTypes'),
				id2ReviewData: _ember['default'].Map.create(),
				pendingIds: _ember['default'].A(),
				timeoutRequest: null,
				reviewStartDate: this.get('reviewStartDate')
			});
			return deductionsLoader;
		}).property('companyModel.id', 'allDeductionTypes', 'reviewStartDate')
	});
});