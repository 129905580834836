define('z-table/utils/update-sorter', ['exports'], function (exports) {
  'use strict';

  exports['default'] = updateSorter;

  function updateSorter(sortDescriptor, sortKey, isAscending) {
    if (!sortKey) {
      return {};
    }

    return {
      '0': {
        key: sortKey,
        isAsc: isAscending
      }
    };
  }
});