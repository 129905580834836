define('component-library/mixins/tether', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var observer = _ember['default'].observer;
	var get = _ember['default'].get;
	var run = _ember['default'].run;
	var computed = _ember['default'].computed;

	exports['default'] = _ember['default'].Mixin.create({
		classNames: ['ember-tether'],
		classPrefix: 'ember-tether',
		target: null,
		attachment: null,
		targetAttachment: null,
		offset: null,
		targetOffset: null,
		targetModifier: null,
		constraints: null,
		optimizations: null,

		didInsertElement: function didInsertElement() {
			this.addTether();
		},

		willDestroyElement: function willDestroyElement() {
			var _this = this;

			var tether = this._tether;
			var element = this.element;
			run.schedule('render', function () {
				_this.removeElement(element);
				_this.removeTether(tether);
			});
		},

		tetherDidChange: observer('classPrefix', 'target', 'attachment', 'targetAttachment', 'offset', 'targetOffset', 'targetModifier', 'constraints', 'optimizations', function () {
			this.removeTether(this._tether);
			this.addTether();
		}),

		addTether: function addTether() {
			if (get(this, '_tetherTarget')) {
				this._tether = new window.Tether(this._tetherOptions());
			}
		},

		removeTether: function removeTether(tether) {
			if (tether) {
				tether.destroy();
			}
		},

		removeElement: function removeElement(element) {
			if (element.parentNode) {
				element.parentNode.removeChild(element);
			}
		},

		_tetherTarget: computed('target', function () {
			var t = get(this, 'target');
			if (t && t.element) {
				t = t.element;
			}
			return t;
		}),

		_tetherOptions: function _tetherOptions() {
			var _this2 = this;

			var options = {
				element: this.element,
				target: get(this, '_tetherTarget')
			};
			['classPrefix', 'attachment', 'targetAttachment', 'offset', 'targetOffset', 'targetModifier', 'constraints', 'optimizations'].forEach(function (k) {
				var v = get(_this2, k);
				if (!_ember['default'].isNone(v)) {
					options[k] = v;
				}
			});
			return options;
		}
	});
});