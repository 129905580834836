define('component-library/components/cards/payroll/implementation-checklist-card', ['exports', 'ember', 'component-library/components/cards/card-base'], function (exports, _ember, _componentLibraryComponentsCardsCardBase) {
	'use strict';

	exports['default'] = _componentLibraryComponentsCardsCardBase['default'].extend({
		mode: _componentLibraryComponentsCardsCardBase.SETTINGS_CARD_MODES.readOnly,
		classNames: ['implementation-checklist-card'],
		showModal: false,
		companyId: null,
		showDeletion: true,
		data: [],
		lastCheckListId: _ember['default'].computed('data.length', function () {
			var checklist = this.get('data')[this.get('data').length - 1];
			return checklist ? checklist.checklistDocumentSection_id : '';
		}),

		actions: {
			toggleInstructionModal: function toggleInstructionModal() {
				this.toggleProperty('showModal');
			},
			onUploadSucceed: function onUploadSucceed(newFile, sectionData) {
				sectionData.documents.pushObject(_ember['default'].Object.create(newFile));
			},
			deleteFile: function deleteFile(checklistDoc, sectionData) {
				sectionData.documents.removeObject(checklistDoc);
			},
			openInstructionModal: function openInstructionModal(priorProvider, checklistSectionName) {
				this.setProperties({
					instructionsForPriorProvider: priorProvider,
					instructionsForChecklistSectionName: checklistSectionName
				});
				this.send('toggleInstructionModal');
			}
		}
	});
});