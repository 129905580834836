define('component-library/components/deductions/discrepant-deduction', ['exports', 'ember', 'component-library/utils/pretty-currency', 'component-library/utils/computed-props/pretty-date', 'component-library/utils/deductions/deductions-utils', 'ui-infra/utils/sanitize-html'], function (exports, _ember, _componentLibraryUtilsPrettyCurrency, _componentLibraryUtilsComputedPropsPrettyDate, _componentLibraryUtilsDeductionsDeductionsUtils, _uiInfraUtilsSanitizeHtml) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['<span> ', '\'s </span><span class="deductionsReview-actionListItem-deductionText">', ' deduction</span>\n\t\t\t\tis <span class="deductionsReview-actionListItem-catchUpText">', '-deducted by ', '.</span>'], ['<span> ', '\\\'s </span><span class="deductionsReview-actionListItem-deductionText">', ' deduction</span>\n\t\t\t\tis <span class="deductionsReview-actionListItem-catchUpText">', '-deducted by ', '.</span>']),
	    _templateObject2 = _taggedTemplateLiteral(['<span> ', '\'s </span>\n\t\t\t\t\t\t<span class="deductionsReview-actionListItem-deductionText">', ' deduction</span> is\n\t\t\t\t\t\t<span class="deductionsReview-actionListItem-catchUpText">', '-deducted by ', '.</span>'], ['<span> ', '\\\'s </span>\n\t\t\t\t\t\t<span class="deductionsReview-actionListItem-deductionText">', ' deduction</span> is\n\t\t\t\t\t\t<span class="deductionsReview-actionListItem-catchUpText">', '-deducted by ', '.</span>']),
	    _templateObject3 = _taggedTemplateLiteral(['Company has <span class="deductionsReview-actionListItem-catchUpText">', '-contributed ', ' to ', '\'s\n\t\t\t\t\t\t<span class="deductionsReview-actionListItem-deductionText">', ' plan.</span>'], ['Company has <span class="deductionsReview-actionListItem-catchUpText">', '-contributed ', ' to ', '\\\'s\n\t\t\t\t\t\t<span class="deductionsReview-actionListItem-deductionText">', ' plan.</span>']),
	    _templateObject4 = _taggedTemplateLiteral(['<span> ', '\'s </span>\n\t\t\t\t\t\t<span class="deductionsReview-actionListItem-deductionText">', ' deduction</span> is\n\t\t\t\t\t\t<span class="deductionsReview-actionListItem-catchUpText">', '-deducted by ', '. </span>\n\t\t\t\t\t\tAlso, the company has <span class="deductionsReview-actionListItem-catchUpText">', '-contributed ', ' to ', '\'s\n\t\t\t\t\t\t<span class="deductionsReview-actionListItem-deductionText">', ' plan.</span>'], ['<span> ', '\\\'s </span>\n\t\t\t\t\t\t<span class="deductionsReview-actionListItem-deductionText">', ' deduction</span> is\n\t\t\t\t\t\t<span class="deductionsReview-actionListItem-catchUpText">', '-deducted by ', '. </span>\n\t\t\t\t\t\tAlso, the company has <span class="deductionsReview-actionListItem-catchUpText">', '-contributed ', ' to ', '\\\'s\n\t\t\t\t\t\t<span class="deductionsReview-actionListItem-deductionText">', ' plan.</span>']),
	    _templateObject5 = _taggedTemplateLiteral(['', ''], ['', '']);

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	exports['default'] = _ember['default'].ObjectProxy.extend({

		adjustmentType: (function () {
			if (!this.get('isEligibleForStandaloneContributionCatchups')) {
				return _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.DEDUCTION_ONLY;
			}

			var totalOffsetDeductions = this.get('totalActualDeductions') - this.get('totalExpectedDeductions');
			var totalOffsetContributions = this.get('totalActualContributions') - this.get('totalExpectedContributions');
			if (totalOffsetDeductions !== 0 && totalOffsetContributions !== 0) {
				return _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.BOTH;
			} else if (totalOffsetDeductions !== 0) {
				return _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.DEDUCTION_ONLY;
			} else {
				return _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.CONTRIBUTION_ONLY;
			}
		}).property('totalActualDeductions', 'totalExpectedDeductions', 'totalActualContributions', 'totalExpectedContributions'),

		catchupExplanation: (function () {
			var totalOffsetDeductions = this.get('totalActualDeductions') - this.get('totalExpectedDeductions');
			var totalOffsetContributions = this.get('totalActualContributions') - this.get('totalExpectedContributions');
			var deductionType = (0, _componentLibraryUtilsDeductionsDeductionsUtils.getLabelForDeductionType)(this.get('deductionType'));
			if (!this.get('isEligibleForStandaloneContributionCatchups')) {
				var _status = totalOffsetDeductions > 0 ? 'over' : 'under';
				return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject, this.get("fullName"), deductionType, _status, (0, _componentLibraryUtilsPrettyCurrency['default'])(Math.abs(totalOffsetDeductions)));
			} else {
				var adjustmentType = this.get('adjustmentType');
				var deductionStatus = totalOffsetDeductions > 0 ? 'over' : 'under';
				var contributionStatus = totalOffsetContributions > 0 ? 'over' : 'under';
				var exp = '';
				if (adjustmentType === _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.DEDUCTION_ONLY) {
					exp = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject2, this.get("fullName"), deductionType, deductionStatus, (0, _componentLibraryUtilsPrettyCurrency['default'])(Math.abs(totalOffsetDeductions)));
				} else if (adjustmentType === _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.CONTRIBUTION_ONLY) {
					exp = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject3, contributionStatus, (0, _componentLibraryUtilsPrettyCurrency['default'])(Math.abs(totalOffsetContributions)), this.get("fullName"), deductionType);
				} else if (adjustmentType === _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.BOTH) {
					exp = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject4, this.get("fullName"), deductionType, deductionStatus, (0, _componentLibraryUtilsPrettyCurrency['default'])(Math.abs(totalOffsetDeductions)), contributionStatus, (0, _componentLibraryUtilsPrettyCurrency['default'])(Math.abs(totalOffsetContributions)), this.get("fullName"), deductionType);
				}
				return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject5, exp);
			}
		}).property('totalActualDeductions', 'totalExpectedDeductions', 'totalActualContributions', 'totalExpectedContributions', 'adjustmentType'),

		status: (function () {
			if (this.get('accepted') == 'yes') {
				return "CATCHUP";
			} else if (this.get('accepted') == 'no') {
				return "FORGIVE";
			} else if (this.get('totalExpectedDeductions') < this.get('totalActualDeductions')) {
				return "OVER-DEDUCTION";
			} else {
				return "UNDER-DEDUCTION";
			}
		}).property('accepted'),

		catchupAmount: (function () {
			if (!this.get('amountToCatchupPerPP')) {
				// if amountToCatchupPerPP is None for some reason, we use noraml deducton amount or the whole offset
				return this.get('normalDeduction') || Math.abs(this.get('totalActualDeductions') - this.get('totalExpectedDeductions'));
			}
			return Math.min(this.get('amountToCatchupPerPP'), Math.abs(this.get('totalActualDeductions') - this.get('totalExpectedDeductions')));
		}).property('totalActualDeductions', 'totalExpectedDeductions', 'amountToCatchupPerPP', 'normalDeduction'),

		currentDeduction: (function () {
			return this.get('totalActualDeductions') > this.get('totalExpectedDeductions') ? this.get('normalDeduction') - this.get('catchupAmount') : this.get('normalDeduction') + this.get('catchupAmount');
		}).property('totalActualDeductions', 'totalExpectedDeductions', 'normalDeduction', 'catchupAmount'),

		validCheckDate: (function () {
			var validateCheckDate = this.get('nextCheckDate');
			return (0, _componentLibraryUtilsComputedPropsPrettyDate['default'])(validateCheckDate || this.get('currentPayPeriodEndDate'));
		}).property('validateCheckDate'),

		quantizedDeductionPayPeriods: (function () {
			var numPayPeriods = 0;
			var totalOffsetDeductions = Math.round((this.get('totalActualDeductions') - this.get('totalExpectedDeductions')) * 100) / 100;
			var catchupAmount = Math.round(this.get('catchupAmount') * 100) / 100;

			if (totalOffsetDeductions === 0) {
				return numPayPeriods;
			}

			if (Math.abs(totalOffsetDeductions) <= catchupAmount + 0.1) {
				numPayPeriods += 1;
			} else if (Math.abs(totalOffsetDeductions * 100).toFixed(2) % (catchupAmount * 100).toFixed(2) / 100 < 0.1) {
				numPayPeriods += Math.floor(Math.abs(totalOffsetDeductions) / catchupAmount);
			} else {
				numPayPeriods += Math.abs(totalOffsetDeductions) / catchupAmount;
			}
			return numPayPeriods;
		}).property('totalActualDeductions', 'totalExpectedDeductions', 'catchupAmount'),

		contributionCatchupAmount: (function () {
			var isContributionCatchupEnabled = this.get('isEligibleForStandaloneContributionCatchups');
			if (!isContributionCatchupEnabled) {
				return 0;
			}

			var quantizedDeductionPayPeriods = this.get('quantizedDeductionPayPeriods');
			if (quantizedDeductionPayPeriods !== 0) {
				var totalOffsetContributions = Math.round((this.get('totalActualContributions') - this.get('totalExpectedContributions')) * 100) / 100;
				return Math.abs(totalOffsetContributions) / quantizedDeductionPayPeriods;
			}

			if (!this.get('contributionAmountToCatchupPerPP')) {
				// if contributionAmountToCatchupPerPP is None for some reason, we use normal contribution amount or the whole offset.
				return this.get('normalContribution') || Math.abs(this.get('totalActualContributions') - this.get('totalExpectedContributions'));
			}

			return Math.min(this.get('contributionAmountToCatchupPerPP'), Math.abs(this.get('totalActualContributions') - this.get('totalExpectedContributions')));
		}).property('quantizedDeductionPayPeriods', 'totalActualContributions', 'totalExpectedContributions', 'contributionAmountToCatchupPerPP', 'normalContribution'),

		quantizedContributionPayPeriods: (function () {
			if (this.get('quantizedDeductionPayPeriods') !== 0 || !this.get('isEligibleForStandaloneContributionCatchups')) {
				return 0;
			}

			var numPayPeriods = 0;
			var totalOffsetContributions = Math.round((this.get('totalActualContributions') - this.get('totalExpectedContributions')) * 100) / 100;
			var contributionCatchupAmount = Math.round(this.get('contributionCatchupAmount') * 100) / 100;

			if (Math.abs(totalOffsetContributions) <= contributionCatchupAmount + 0.1) {
				numPayPeriods += 1;
			} else if (Math.abs(totalOffsetContributions * 100).toFixed(2) % (contributionCatchupAmount * 100).toFixed(2) / 100 < 0.1) {
				numPayPeriods += Math.floor(Math.abs(totalOffsetContributions) / contributionCatchupAmount);
			} else {
				numPayPeriods += Math.abs(totalOffsetContributions) / contributionCatchupAmount;
			}
			return numPayPeriods;
		}).property('quantizedDeductionPayPeriods', 'totalActualContributions', 'totalExpectedContributions'),

		quantizedPayPeriods: (function () {
			var quantizedDeductionPayPeriods = this.get('quantizedDeductionPayPeriods');
			if (quantizedDeductionPayPeriods !== 0) {
				return quantizedDeductionPayPeriods;
			}

			return this.get('quantizedContributionPayPeriods');
		}).property('quantizedDeductionPayPeriods'),

		adjustmentProposeForDeduction: (function () {
			var quantizedPayPeriods = this.get('quantizedPayPeriods');
			if (quantizedPayPeriods === 0) {
				return '';
			}

			var totalOffsetDeductions = Math.round((this.get('totalActualDeductions') - this.get('totalExpectedDeductions')) * 100) / 100;
			var catchupAmount = Math.round(this.get('catchupAmount') * 100) / 100;

			if (quantizedPayPeriods === 1) {
				return 'Adjust employee deduction from ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('normalDeduction')) + ' to ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('currentDeduction')) + ' for the next pay period?';
			} else if (Math.floor(quantizedPayPeriods) === quantizedPayPeriods) {
				return 'Adjust employee deduction from ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('normalDeduction')) + ' to ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('currentDeduction')) + ' for ' + Math.floor(Math.abs(totalOffsetDeductions) / catchupAmount) + ' pay periods?';
			} else {
				var prompt;
				if (Math.floor(quantizedPayPeriods) === 1) {
					prompt = 'Adjust employee deduction from ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('normalDeduction')) + ' to ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('currentDeduction')) + ' for ' + Math.floor(Math.abs(totalOffsetDeductions) / catchupAmount) + ' pay period';
				} else {
					prompt = 'Adjust employee deduction from ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('normalDeduction')) + ' to ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('currentDeduction')) + ' for ' + Math.floor(Math.abs(totalOffsetDeductions) / catchupAmount) + ' pay periods';
				}
				var followUp;
				var remaining = Math.abs(totalOffsetDeductions) % catchupAmount;
				if (this.get('totalActualDeductions') > this.get('totalExpectedDeductions')) {
					followUp = ',\nthen  ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('normalDeduction') - remaining) + ' for 1 pay period?';
					prompt += followUp;
				} else {
					followUp = ',\nthen ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('normalDeduction') + remaining) + ' for 1 pay period?';
					prompt += followUp;
				}
				return prompt;
			}
		}).property('quantizedPayPeriods', 'normalDeduction', 'currentDeduction', 'totalActualDeductions', 'totalExpectedDeductions', 'catchupAmount'),

		currentContribution: (function () {
			if (!this.get('isEligibleForStandaloneContributionCatchups')) {
				return 0;
			}

			var totalOffsetContributions = this.get('totalActualContributions') - this.get('totalExpectedContributions');
			var contributionCatchupAmount = this.get('contributionCatchupAmount');
			var normalContribution = this.get('normalContribution');
			return totalOffsetContributions > 0 ? normalContribution - contributionCatchupAmount : normalContribution + contributionCatchupAmount;
		}).property('quantizedPayPeriods', 'totalActualContributions', 'totalExpectedContributions'),

		adjustmentProposeForContribution: (function () {
			if (!this.get('isEligibleForStandaloneContributionCatchups')) {
				return '';
			}

			var quantizedPayPeriods = this.get('quantizedPayPeriods');
			if (quantizedPayPeriods === 0) {
				return '';
			}

			var totalActualContributions = this.get('totalActualContributions');
			var totalExpectedContributions = this.get('totalExpectedContributions');
			var totalOffsetContributions = Math.round((totalActualContributions - totalExpectedContributions) * 100) / 100;
			if (totalOffsetContributions === 0) {
				return '';
			}

			var normalContribution = this.get('normalContribution');
			var currentContribution = this.get('currentContribution');
			var contributionCatchupAmount = this.get('contributionCatchupAmount');
			var remainingPayPeriods = quantizedPayPeriods - Math.floor(quantizedPayPeriods);

			var prompt = this.get('adjustmentType') === _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.BOTH ? 'And adjust' : 'Adjust';
			if (quantizedPayPeriods === 1) {
				prompt += ' employer contribution from ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(normalContribution) + ' to ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(currentContribution) + ' for the next pay period';
			} else {
				prompt += ' employer contribution from ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(normalContribution) + ' to ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(currentContribution) + ' for ' + Math.floor(Math.abs(totalOffsetContributions) / contributionCatchupAmount) + ' pay period';
				if (Math.floor(quantizedPayPeriods) > 1) {
					prompt += 's';
				}
			}
			if (remainingPayPeriods > 0) {
				var remaining = Math.abs(totalOffsetContributions) % contributionCatchupAmount;
				if (this.get('totalActualContributions') > this.get('totalExpectedContributions')) {
					prompt += ',\nthen  ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(normalContribution - remaining) + ' for 1 pay period?';
				} else {
					prompt += ',\nthen ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(normalContribution + remaining) + ' for 1 pay period?';
				}
			} else {
				prompt += '?';
			}
			return prompt;
		}).property('normalContribution', 'currentContribution', 'totalActualContributions', 'totalExpectedContributions', 'contributionCatchupAmount', 'adjustmentType'),

		adjustmentPropose: (function () {
			switch (this.get('adjustmentType')) {
				case _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.DEDUCTION_ONLY:
					return this.get('adjustmentProposeForDeduction');
				case _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.CONTRIBUTION_ONLY:
					return this.get('adjustmentProposeForContribution');
				case _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.BOTH:
					return this.get('adjustmentProposeForDeduction') + ' ' + this.get('adjustmentProposeForContribution');
				default:
					return this.get('adjustmentProposeForDeduction');
			}
		}).property('adjustmentType', 'adjustmentProposeForDeduction', 'adjustmentProposeForContribution'),

		deductionCatchupConfirm: (function () {
			var prettyCurrentDeduction = (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('currentDeduction'));
			var validateCheckDate = this.get('validCheckDate');
			return 'Employee deduction will be adjusted to ' + prettyCurrentDeduction + ' for the next pay period.';
		}).property('currentDeduction', 'validCheckDate', 'accepted'),

		contributionCatchupConfirm: (function () {
			if (!this.get('isEligibleForStandaloneContributionCatchups')) {
				return '';
			}

			var prettyCurrentContribution = (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('currentContribution'));
			var validateCheckDate = this.get('validCheckDate');
			return 'Employer contribution will be adjusted to ' + prettyCurrentContribution + ' for the next pay period.';
		}).property('currentContribution', 'validCheckDate', 'accepted'),

		catchupConfirm: (function () {
			switch (this.get('adjustmentType')) {
				case _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.DEDUCTION_ONLY:
					return this.get('deductionCatchupConfirm');
				case _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.CONTRIBUTION_ONLY:
					return this.get('contributionCatchupConfirm');
				case _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.BOTH:
					return this.get('deductionCatchupConfirm') + ' ' + this.get('contributionCatchupConfirm');
				default:
					return this.get('deductionCatchupConfirm');
			}
		}).property('adjustmentType', 'deductionCatchupConfirm', 'contributionCatchupConfirm'),

		deductionDeclineConfirm: (function () {
			var prettyNormalDeduction = (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('normalDeduction'));
			var validateCheckDate = this.get('validCheckDate');
			return 'Employee deduction will remain at ' + prettyNormalDeduction + '.';
		}).property('normalDeduction', 'accepted'),

		contributionDeclineConfirm: (function () {
			if (!this.get('isEligibleForStandaloneContributionCatchups')) {
				return '';
			}

			var prettyNormalContribution = (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get('normalContribution'));
			return 'Employer contribution will remain at ' + prettyNormalContribution + '.';
		}).property('normalContribution', 'accepted'),

		declineConfirm: (function () {
			switch (this.get('adjustmentType')) {
				case _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.DEDUCTION_ONLY:
					return this.get('deductionDeclineConfirm');
				case _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.CONTRIBUTION_ONLY:
					return this.get('contributionDeclineConfirm');
				case _componentLibraryUtilsDeductionsDeductionsUtils.COD_ADJUSTMENT_TYPES.BOTH:
					return this.get('deductionDeclineConfirm') + ' ' + this.get('contributionDeclineConfirm');
				default:
					return this.get('deductionDeclineConfirm');
			}
		}).property('adjustmentType', 'deductionDeclineConfirm', 'contributionDeclineConfirm'),

		acceptCatchup: _ember['default'].computed.equal('accepted', 'yes'),
		forgiveCatchup: _ember['default'].computed.equal('accepted', 'no'),
		actionPerformed: _ember['default'].computed.or('acceptCatchup', 'forgiveCatchup')

	});
});