define('component-library/proxies/realization-state', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	var _REALIZATION_INPUT_META;

	exports.hasExceptionInputType = hasExceptionInputType;
	exports.hasAnyInput = hasAnyInput;
	exports.hasOnlyCarrierResolutionInput = hasOnlyCarrierResolutionInput;
	exports.hasCarrierResolutionInputForLine = hasCarrierResolutionInputForLine;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
		} else {
			obj[key] = value;
		}return obj;
	}

	// ---- START realization input types ----
	var PERSONAL_EMAIL = 'personal email';
	exports.PERSONAL_EMAIL = PERSONAL_EMAIL;

	var WORK_EMAIL = 'work email';

	exports.WORK_EMAIL = WORK_EMAIL;

	var TERMINATION_CHANGE_COMPLETED_BY_ADMIN = 'whether termination change is completed with carrier by admin';
	exports.TERMINATION_CHANGE_COMPLETED_BY_ADMIN = TERMINATION_CHANGE_COMPLETED_BY_ADMIN;

	var ADDITION_CHANGE_COMPLETED_BY_ADMIN = 'whehter plan addition change is completed with carrier by admin';
	exports.ADDITION_CHANGE_COMPLETED_BY_ADMIN = ADDITION_CHANGE_COMPLETED_BY_ADMIN;

	var SELECTION_CHANGE_COMPLETED_BY_ADMIN = 'whether plan selection change has been completed with carrier by admin';

	exports.SELECTION_CHANGE_COMPLETED_BY_ADMIN = SELECTION_CHANGE_COMPLETED_BY_ADMIN;

	var NEEDS_TO_ENROLL = 'whether employee needs to enroll';

	exports.NEEDS_TO_ENROLL = NEEDS_TO_ENROLL;

	var ENROLLMENT_REASON = 'enrollment reason';
	exports.ENROLLMENT_REASON = ENROLLMENT_REASON;

	var ENROLLMENT_REASON_EVENT_DATE = 'event date related to enrollment reason';

	exports.ENROLLMENT_REASON_EVENT_DATE = ENROLLMENT_REASON_EVENT_DATE;

	var MISSED_ENROLLMENT_REASON = 'missed enrollment reason';
	exports.MISSED_ENROLLMENT_REASON = MISSED_ENROLLMENT_REASON;

	// TODO(lei): do we really need this?
	var MISSED_ENROLLMENT_EVENT_DATE = 'event date related to missed enrollment reason';

	exports.MISSED_ENROLLMENT_EVENT_DATE = MISSED_ENROLLMENT_EVENT_DATE;

	var CHANGE_REASON = 'change reason';
	exports.CHANGE_REASON = CHANGE_REASON;

	var CHANGE_REASON_EVENT_DATE = 'event date related to change reason';

	exports.CHANGE_REASON_EVENT_DATE = CHANGE_REASON_EVENT_DATE;

	var MISSED_CHANGE_REASON = 'missed change reason';

	exports.MISSED_CHANGE_REASON = MISSED_CHANGE_REASON;

	var MISSED_TERMINATION_REASON = 'missed termination reason';
	exports.MISSED_TERMINATION_REASON = MISSED_TERMINATION_REASON;

	var TERMINATION_DATE = 'termination date';
	exports.TERMINATION_DATE = TERMINATION_DATE;

	var FT_TO_PT_DATE = 'full-time to part-time date';
	exports.FT_TO_PT_DATE = FT_TO_PT_DATE;

	var PT_TO_FT_DATE = 'part-time to full-time date';

	exports.PT_TO_FT_DATE = PT_TO_FT_DATE;

	var NEED_ZENEFITS_COBRA = 'whether needs zenefits to conduct cobra';
	exports.NEED_ZENEFITS_COBRA = NEED_ZENEFITS_COBRA;

	var ENROLLMENT_EXCEPTION_LETTER = 'exception letter for enrollment';
	exports.ENROLLMENT_EXCEPTION_LETTER = ENROLLMENT_EXCEPTION_LETTER;

	var TERMINATION_EXCEPTION_LETTER = 'exception letter for benefits termination';
	exports.TERMINATION_EXCEPTION_LETTER = TERMINATION_EXCEPTION_LETTER;

	var CHANGE_PLAN_EXCEPTION_LETTER = 'exception letter for change plan selection';
	exports.CHANGE_PLAN_EXCEPTION_LETTER = CHANGE_PLAN_EXCEPTION_LETTER;

	var SUPPORT_DOC = 'support document';

	exports.SUPPORT_DOC = SUPPORT_DOC;

	var EMPLOYEE_EFFECTIVE_DATE = 'enrollment effective date';

	exports.EMPLOYEE_EFFECTIVE_DATE = EMPLOYEE_EFFECTIVE_DATE;

	var DATE_OF_BIRTH = 'date of birth';
	exports.DATE_OF_BIRTH = DATE_OF_BIRTH;

	var ZIP_CODE = 'zip code';
	exports.ZIP_CODE = ZIP_CODE;

	var GENDER = 'gender';

	exports.GENDER = GENDER;

	// -- END realization input types ---

	// reason codes
	var NEW_HIRE_ENROLLMENT = 'new hire enollment';
	exports.NEW_HIRE_ENROLLMENT = NEW_HIRE_ENROLLMENT;

	var RECENT_OE = 'recent open enrollment';
	exports.RECENT_OE = RECENT_OE;

	var QLE = 'qualifying event';
	exports.QLE = QLE;

	var MISTAKE = '(hr/broker)mistake';
	exports.MISTAKE = MISTAKE;

	var OTHER_REASON = 'other reason';
	exports.OTHER_REASON = OTHER_REASON;

	var PRIOR_HR_MISTAKE = 'prior hr mistake';
	exports.PRIOR_HR_MISTAKE = PRIOR_HR_MISTAKE;

	var PRIOR_BORKER_MISTAKE = 'prior broker mistake';

	exports.PRIOR_BORKER_MISTAKE = PRIOR_BORKER_MISTAKE;

	// data types
	var EMAIL_DATA_TYPE = 'email data type';
	var DATE_DATA_TYPE = 'date data type';
	var DROPDOWN_LIST_DATA_TYPE = 'dropdown list data type';
	var BOOLEAN_DATA_TYPE = 'boolean data type';
	var SIGN_LETTER_DATA_TYPE = 'sign letter data type';
	var DOC_DATA_TYPE = 'doc data type';
	var ZIP_CODE_DATA_TYPE = 'zip code data type';

	// gender choices
	var MALE = 'M';
	var FEMALE = 'F';

	// metadata for realization input
	// TODO(lei): pass this data from backend??
	// TODO(lei): copy for description (some may need customization like support doc)
	var REALIZATION_INPUT_META = (_REALIZATION_INPUT_META = {}, _defineProperty(_REALIZATION_INPUT_META, PERSONAL_EMAIL, {
		dataType: EMAIL_DATA_TYPE,
		description: 'Personal Email',
		helpText: 'Zenefits uses Personal Email addresses to communicate with terminated employees.'
	}), _defineProperty(_REALIZATION_INPUT_META, WORK_EMAIL, {
		dataType: EMAIL_DATA_TYPE,
		description: 'Work Email',
		helpText: 'Zenefits uses Work Email addresses to communicate with current employees.'
	}), _defineProperty(_REALIZATION_INPUT_META, TERMINATION_CHANGE_COMPLETED_BY_ADMIN, {
		dataType: BOOLEAN_DATA_TYPE,
		isCarrierRes: true
	}), _defineProperty(_REALIZATION_INPUT_META, ADDITION_CHANGE_COMPLETED_BY_ADMIN, {
		dataType: BOOLEAN_DATA_TYPE,
		isCarrierRes: true
	}), _defineProperty(_REALIZATION_INPUT_META, SELECTION_CHANGE_COMPLETED_BY_ADMIN, {
		dataType: BOOLEAN_DATA_TYPE,
		isCarrierRes: true
	}), _defineProperty(_REALIZATION_INPUT_META, NEEDS_TO_ENROLL, {
		dataType: BOOLEAN_DATA_TYPE,
		description: 'Needs to enrollment in benefits?',
		helpText: 'Zenefits uses this information to understand if employees need to enroll in benefits.'
	}), _defineProperty(_REALIZATION_INPUT_META, ENROLLMENT_REASON, {
		dataType: DROPDOWN_LIST_DATA_TYPE,
		selectOptions: [{ option: 'Select a reason', id: null }, { option: 'New Hire Enrollment', id: NEW_HIRE_ENROLLMENT }, { option: 'Recent Open Enrollment', id: RECENT_OE }, { option: 'Qualifying Event', id: QLE }, { option: 'Other', id: OTHER_REASON }],
		description: 'Reason for enrollment',
		helpText: 'Zenefits uses this information to understand if the employee needs supporting documentation with their application.'
	}), _defineProperty(_REALIZATION_INPUT_META, ENROLLMENT_REASON_EVENT_DATE, {
		dataType: DATE_DATA_TYPE,
		description: 'Event date for enrollment reason',
		helpText: 'Zenefits uses this information to understand when the employee became eligible for benefits.'
	}), _defineProperty(_REALIZATION_INPUT_META, MISSED_ENROLLMENT_REASON, {
		dataType: DROPDOWN_LIST_DATA_TYPE,
		selectOptions: [{ option: 'Select a reason', id: null }, { option: 'Prior HR Mistake', id: PRIOR_HR_MISTAKE }, { option: 'Prior Broker Mistake', id: PRIOR_BORKER_MISTAKE },
		// TODO(lei): can we use the same reason code here for mistakes?
		{ option: 'Missed during OE', id: RECENT_OE },
		// TODO(lei): can we use the same reason code here for mistakes?
		{ option: 'Recent Qualifying Event', id: QLE }, { option: 'Other', id: OTHER_REASON }],
		description: 'Reason for missed enrollment window',
		isForException: true,
		helpText: 'Zenefits uses this information to understand why this was not submitted to the insurance carrier.'
	}), _defineProperty(_REALIZATION_INPUT_META, MISSED_ENROLLMENT_EVENT_DATE, {
		dataType: DATE_DATA_TYPE,
		description: 'Event date for missed enrollment reason',
		isForException: true
	}), _defineProperty(_REALIZATION_INPUT_META, CHANGE_REASON, {
		dataType: DROPDOWN_LIST_DATA_TYPE,
		selectOptions: [{ option: 'Select a reason', id: null }, { option: 'Recent Open Enrollment', id: RECENT_OE }, { option: 'Qualifying Event', id: QLE }, { option: 'Mistake', id: MISTAKE }, { option: 'Other', id: OTHER_REASON }],
		description: 'Reason for enrollment change',
		helpText: 'Zenefits uses this information to understand why the employee’s enrollment changed.'
	}), _defineProperty(_REALIZATION_INPUT_META, CHANGE_REASON_EVENT_DATE, {
		dataType: DATE_DATA_TYPE,
		description: 'Event date for the enrollment change reason',
		helpText: 'Zenefits uses this information to understand when the employee’s enrollment changed.'
	}), _defineProperty(_REALIZATION_INPUT_META, MISSED_CHANGE_REASON, {
		dataType: DROPDOWN_LIST_DATA_TYPE,
		selectOptions: [{ option: 'Select a reason', id: null }, { option: 'Prior HR Mistake', id: PRIOR_HR_MISTAKE }, { option: 'Prior Broker Mistake', id: PRIOR_BORKER_MISTAKE }, { option: 'Other', id: OTHER_REASON }],
		description: 'Reason for missed enrollment change window',
		isForException: true,
		helpText: 'Zenefits uses this information to understand why this was not submitted to the insurance carrier.'
	}), _defineProperty(_REALIZATION_INPUT_META, MISSED_TERMINATION_REASON, {
		dataType: DROPDOWN_LIST_DATA_TYPE,
		selectOptions: [{ option: 'Select a reason', id: null }, { option: 'Prior HR Mistake', id: PRIOR_HR_MISTAKE }, { option: 'Prior Broker Mistake', id: PRIOR_BORKER_MISTAKE }, { option: 'Other', id: OTHER_REASON }],
		description: 'Reason for missed enrollment termination window',
		isForException: true,
		helpText: 'Zenefits uses this information to understand why this was not submitted to the insurance carrier.'
	}), _defineProperty(_REALIZATION_INPUT_META, TERMINATION_DATE, {
		dataType: DATE_DATA_TYPE,
		description: 'Termination date',
		helpText: 'Zenefits uses this information to understand when the employee’s benefits end.'
	}), _defineProperty(_REALIZATION_INPUT_META, FT_TO_PT_DATE, {
		dataType: DATE_DATA_TYPE,
		description: 'Full time to Part time date',
		helpText: 'Zenefits uses this information to understand when the employee’s benefits end.'
	}), _defineProperty(_REALIZATION_INPUT_META, PT_TO_FT_DATE, {
		dataType: DATE_DATA_TYPE,
		description: 'Part time to Full time date',
		helpText: 'Zenefits uses this information to understand when the employee became eligible for benefits.'
	}), _defineProperty(_REALIZATION_INPUT_META, NEED_ZENEFITS_COBRA, {
		dataType: BOOLEAN_DATA_TYPE,
		description: 'Need Zenefits to administrate COBRA?',
		helpText: 'Zenefits uses this information to decide if the employee needs to be sent a COBRA letter. Zenefits will prompt you to send out COBRA notices later.'
	}), _defineProperty(_REALIZATION_INPUT_META, ENROLLMENT_EXCEPTION_LETTER, {
		dataType: SIGN_LETTER_DATA_TYPE,
		isForException: true,
		description: 'Exception letter for missed enrollment',
		helpText: 'Zenefits requires this document as written consent to submit retroactive enrollment changes to your insurance carrier.'
	}), _defineProperty(_REALIZATION_INPUT_META, TERMINATION_EXCEPTION_LETTER, {
		dataType: SIGN_LETTER_DATA_TYPE,
		isForException: true,
		description: 'Exception letter for missed termination',
		helpText: 'Zenefits requires this document as written consent to submit retroactive enrollment changes to your insurance carrier.'
	}), _defineProperty(_REALIZATION_INPUT_META, CHANGE_PLAN_EXCEPTION_LETTER, {
		dataType: SIGN_LETTER_DATA_TYPE,
		isForException: true,
		description: 'Exception letter for missed change plan selection',
		'helpText': 'Zenefits requires this document as written consent to submit retroactive enrollment changes to your insurance carrier.'
	}), _defineProperty(_REALIZATION_INPUT_META, SUPPORT_DOC, {
		dataType: DOC_DATA_TYPE,
		isForException: true,
		description: 'Support docuemnts for excetpion',
		helpText: 'Your insurance carrier may require supporting documentation for this change. Any relevant documents may increase chances of approval. Some supporting documents would be: prior enrollment forms; payroll history; prior email correspondence; or prior confirmation of coverage.'
	}), _defineProperty(_REALIZATION_INPUT_META, EMPLOYEE_EFFECTIVE_DATE, {
		dataType: DATE_DATA_TYPE,
		description: 'Enrollment effective date',
		helpText: 'This is the date which the employee should be active on insurance'
	}), _defineProperty(_REALIZATION_INPUT_META, DATE_OF_BIRTH, {
		dataType: DATE_DATA_TYPE,
		description: 'Date of birth',
		helpText: 'Carriers sometimes calculate rates using date of birth'
	}), _defineProperty(_REALIZATION_INPUT_META, ZIP_CODE, {
		dataType: ZIP_CODE_DATA_TYPE,
		description: 'ZIP code',
		helpText: 'Carriers sometimes calculate rates using ZIP Code'
	}), _defineProperty(_REALIZATION_INPUT_META, GENDER, {
		dataType: DROPDOWN_LIST_DATA_TYPE,
		description: 'Gender',
		helpText: 'Carriers sometimes calculate rates using gender',
		selectOptions: [{ option: 'Select a gender', id: null }, { option: 'Male', id: MALE }, { option: 'Female', id: FEMALE }]
	}), _REALIZATION_INPUT_META);

	exports.REALIZATION_INPUT_META = REALIZATION_INPUT_META;

	function getMetaValue(inputType, property) {
		var meta = REALIZATION_INPUT_META[inputType] ? REALIZATION_INPUT_META[inputType] : {};
		return meta[property];
	}

	function hasExceptionInputType(realizationState, loc) {
		var rawRealizationInputs = realizationState.get('realizationInputs');
		for (var idx = 0; idx < rawRealizationInputs.length; idx++) {
			var ip = rawRealizationInputs.objectAt(idx);
			if (getMetaValue(ip.type, 'isForException') && ip.linesOfCoverage.contains(loc)) {
				return true;
			}
		}
		return false;
	}

	function hasAnyInput(realizationState) {
		var rawRealizationInputs = realizationState.get('realizationInputs');
		return rawRealizationInputs.length > 0;
	}

	function hasOnlyCarrierResolutionInput(realizationState) {
		var rawRealizationInputs = realizationState.get('realizationInputs');
		for (var idx = 0; idx < rawRealizationInputs.length; idx++) {
			var ip = rawRealizationInputs.objectAt(idx);
			if (!getMetaValue(ip.type, 'isCarrierRes')) {
				return false;
			}
		}
		return true;
	}

	function hasCarrierResolutionInputForLine(realizationState, loc) {
		var rawRealizationInputs = realizationState.get('realizationInputs');
		for (var idx = 0; idx < rawRealizationInputs.length; idx++) {
			var ip = rawRealizationInputs.objectAt(idx);
			if (getMetaValue(ip.type, 'isCarrierRes') && ip.linesOfCoverage.contains(loc)) {
				return true;
			}
		}
		return false;
	}

	// realization input wrapper
	var RealizationInputWrapper = _ember['default'].Object.extend({
		type: null,
		linesOfCoverage: null,
		answer: null,
		carrierId: null,
		description: null,

		// the following are not persisted
		realizationStateWrapper: null,
		errorText: null,
		// for letter type
		signatureObj: null,
		// for support doc type
		documentObj: null,

		answerChanged: (function () {
			var handler = this.get('realizationStateWrapper');
			if (this.get('isDateType')) {
				// This is for avoidiing trigger hook when user type date manually instead of picking a date from popup
				// TODO(lei): we may need to check focusedOut event as currently when user change a single digit we will trigger an update
				if (this.get('isAnswerValid')) {
					this.get('realizationStateWrapper').answerChanged(this);
				}
			} else {
				this.get('realizationStateWrapper').answerChanged(this);
			}
		}).observes('answer'),

		appliesToLine: function appliesToLine(loc) {
			return this.get('linesOfCoverage').indexOf(loc) > -1;
		},

		validateAndSetErrorText: function validateAndSetErrorText() {
			this.set('errorText', null);
			return this.validate(true);
		},

		validateDateType: function validateDateType(answer) {
			if (!answer) {
				return "please provide a date";
			}
			if (answer.length !== 10) {
				return "please provide a date in the format of MM/DD/YYYY";
			}
			var dateObj = (0, _componentLibraryUtilsGlobals.moment)(answer, 'MM-DD-YYYY');
			var today = (0, _componentLibraryUtilsGlobals.moment)().startOf('day');
			if (dateObj.isAfter(today)) {
				// TODO(lei): need update as the date can be dates that are not specific to EE, how about today??
				return 'Since this change is applicable in the future, you should update it inside the employee’s profile.';
			}
			return null;
		},

		validateBooleanType: function validateBooleanType(answer) {
			return typeof answer == 'boolean' ? null : 'Please specify yes or no';
		},

		validateSignatureType: function validateSignatureType() {
			var errorText = 'Please provide a signature';
			if (!this.get('answer') && !this.get('signatureObj')) {
				return errorText;
			}
			if (this.get('signatureObj')) {
				var signatureObj = this.get('signatureObj');
				if (!signatureObj.get('signatureName') || !signatureObj.get('signatureTitle') || !signatureObj.get('signature')) {
					return errorText;
				}
			}
			return null;
		},

		validateDocType: function validateDocType() {
			var errorText = 'Please upload a document';
			// TODO(lei): this may change as support doc can be optional
			if (!this.get('answer') && !this.get('documentObj')) {
				return errorText;
			}
			if (this.get('documentObj')) {
				var documentObj = this.get('documentObj');
				if (!documentObj.get('url')) {
					return errorText;
				}
			}
			return null;
		},

		validateOther: function validateOther(answer) {
			return !!answer ? null : 'Please provide an input';
		},

		validate: function validate(setErrorText) {
			var errorText = null;
			var answer = this.get('answer');
			if (this.get('isDateType')) {
				errorText = this.validateDateType(answer);
			} else if (this.get('isBooleanType')) {
				errorText = this.validateBooleanType(answer);
			} else if (this.get('isSignLetterType')) {
				errorText = this.validateSignatureType();
			} else if (this.get('isDocType')) {
				errorText = this.validateDocType();
			} else {
				errorText = this.validateOther(answer);
			}
			if (!!errorText) {
				if (!!setErrorText) {
					this.set('errorText', errorText);
				}
				return false;
			} else {
				return true;
			}
		},

		isAnswerValid: (function () {
			return this.validate(false);
		}).property('answer', 'documentObj.url', 'signatureObj.signatureName', 'signatureObj.signatureTitle', 'signatureObj.signature'),

		isBooleanType: (function () {
			return getMetaValue(this.get('type'), 'dataType') == BOOLEAN_DATA_TYPE;
		}).property('type'),

		isDateType: (function () {
			return getMetaValue(this.get('type'), 'dataType') == DATE_DATA_TYPE;
		}).property('type'),

		isEmailType: (function () {
			return getMetaValue(this.get('type'), 'dataType') == EMAIL_DATA_TYPE;
		}).property('type'),

		isDropdownListType: (function () {
			return getMetaValue(this.get('type'), 'dataType') == DROPDOWN_LIST_DATA_TYPE;
		}).property('type'),

		isSignLetterType: (function () {
			return getMetaValue(this.get('type'), 'dataType') == SIGN_LETTER_DATA_TYPE;
		}).property('type'),

		isZipCodeType: (function () {
			return getMetaValue(this.get('type'), 'dataType') == ZIP_CODE_DATA_TYPE;
		}).property('type'),

		isDocType: (function () {
			return getMetaValue(this.get('type'), 'dataType') == DOC_DATA_TYPE;
		}).property('type'),

		isSupportDocType: (function () {
			return this.get('type') == SUPPORT_DOC;
		}).property('type'),

		selectOptions: (function () {
			return getMetaValue(this.get('type'), 'selectOptions');
		}).property('type'),

		isCarrierResInputType: (function () {
			return !!getMetaValue(this.get('type'), 'isCarrierRes');
		}).property('type'),

		isForException: (function () {
			return getMetaValue(this.get('type'), 'isForException');
		}).property('type'),

		displayText: (function () {
			if (this.get('description')) {
				return this.get('description');
			} else {
				return getMetaValue(this.get('type'), 'description');
			}
		}).property('description', 'type'),

		helpText: (function () {
			return getMetaValue(this.get('type'), 'helpText');
		}).property('type'),

		toRawObject: function toRawObject() {
			return {
				type: this.get('type'),
				linesOfCoverage: this.get('linesOfCoverage'),
				answer: this.get('answer'),
				carrierId: this.get('carrierId'),
				description: this.get('description')
			};
		}

	});

	// answers this types should lead to realization state refersh
	var BRANCHING_TYPES = [NEEDS_TO_ENROLL, TERMINATION_CHANGE_COMPLETED_BY_ADMIN, ADDITION_CHANGE_COMPLETED_BY_ADMIN, SELECTION_CHANGE_COMPLETED_BY_ADMIN, ENROLLMENT_REASON, EMPLOYEE_EFFECTIVE_DATE, FT_TO_PT_DATE, PT_TO_FT_DATE, TERMINATION_DATE, CHANGE_REASON, CHANGE_REASON_EVENT_DATE];

	exports['default'] = _ember['default'].Object.extend({
		realizationState: null,
		realizationInputsJsonStr: null,
		// will call this handler when realizationState needs a refresh
		refreshHandler: null,
		customChangeHandler: null,

		realizationInputWrappers: (function () {
			var _this = this;

			var rawInputs = JSON.parse(this.get('realizationInputsJsonStr'));
			return rawInputs.map(function (realizationInput) {
				return RealizationInputWrapper.create({
					type: realizationInput.type,
					linesOfCoverage: realizationInput.linesOfCoverage,
					answer: realizationInput.answer,
					carrierId: realizationInput.carrierId,
					description: realizationInput.description,
					realizationStateWrapper: _this
				});
			});
		}).property('realizationInputsJsonStr'),

		answerChanged: function answerChanged(realizationInputWrapper) {
			var _this2 = this;

			var refreshHandler = this.get('refreshHandler');
			var customChangeHandler = this.get('customChangeHandler');
			if (!refreshHandler && !customChangeHandler) {
				return;
			}
			if (refreshHandler && BRANCHING_TYPES.contains(realizationInputWrapper.get('type'))) {
				_ember['default'].run.next(function () {
					refreshHandler.refresh(_this2);
				});
			}
			if (customChangeHandler) {
				_ember['default'].run.next(function () {
					customChangeHandler.customChangeHandler(realizationInputWrapper);
				});
			}
		},

		hasAllAnswers: (function () {
			return this.get('realizationInputWrappers').rejectBy('isAnswerValid').length == 0;
		}).property('realizationInputWrappers.@each.isAnswerValid'),

		toJsonStr: function toJsonStr() {
			var rawRealizationInputs = this.get('realizationInputWrappers').map(function (inputWrapper) {
				return inputWrapper.toRawObject();
			});
			return JSON.stringify(rawRealizationInputs);
		}
	});
});