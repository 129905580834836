define('component-library/wizard/flow/section/controller', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Controller.extend({
		// OVERRIDES - begin
		wizardService: null,
		serviceEnabled: false,

		wizardSectionKey: null, // required

		// override if hooks are needed after steps are complete, may return a promise
		stepCompletionChanged: function stepCompletionChanged(step) {},

		showWizardIndexPopover: false,

		// set to true if nav title should link to review instead of index
		linkToReview: false,

		// OVERRIDES - end

		wizardSectionState: null, // automatically set by route

		_setStepCompletion: function _setStepCompletion(stepKey, isComplete, newValue) {
			var section = this.get('wizardSectionState');
			var step = section.getStep(stepKey);

			if (!step) {
				return;
			}

			step.updateValue(newValue);
			step.setComplete(isComplete);
			var stepCompletionPromise = this.stepCompletionChanged(step);

			if (stepCompletionPromise) {
				return stepCompletionPromise;
			} else {
				return new _ember['default'].RSVP.Promise(function (resolve, reject) {
					resolve();
				});
			}
		},

		_updateCurrentStep: function _updateCurrentStep(stepKey) {
			var section = this.get('wizardSectionState');
			var step = section.getStep(stepKey);

			if (step) {
				section.set('currentStepIndex', step.get('index'));
			}
			return step;
		},

		actions: {
			transitionToNextStep: function transitionToNextStep() {
				if (this.get('wizardSectionState.currentStep.allowSkip')) {
					this.send('_transitionToNextStep_stepAction', this.get('wizardSectionKey'));
				}
			},

			transitionToPreviousStep: function transitionToPreviousStep() {
				this.send('_transitionToPreviousStep_stepAction');
			},

			transitionToFlowIndex: function transitionToFlowIndex(shouldTransition) {
				this.send('_transitionToFlowIndex_stepAction');
			},

			transitionToSection: function transitionToSection(section) {
				var sectionKey = section.get('sectionKey');
				this.send('_transitionToFirstIncompleteStep_sectionAction', sectionKey);
			},

			clickNavTitle: function clickNavTitle() {
				if (!this.get('showWizardIndexPopover')) {
					// transition when popover is not shown
					this.send('_transitionToFlowIndex_stepAction');
				}
			}
		}
	});
});