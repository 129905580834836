define('z-table/components/z-table-filter-panel', ['exports', 'ember', 'z-table/templates/components/z-table-filter-panel'], function (exports, _ember, _zTableTemplatesComponentsZTableFilterPanel) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['z-table-filter-panel', 'js-zTableFilterPanel'],
    layout: _zTableTemplatesComponentsZTableFilterPanel['default'],

    filters: _ember['default'].computed(function () {
      return _ember['default'].A();
    })
  });
});