define('component-library/mixins/resize-handler', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		resizeEndDelay: 200,
		resizing: false,
		onResizeStart: _ember['default'].K,
		onResizeEnd: _ember['default'].K,
		onResize: _ember['default'].K,

		endResize: function endResize(event) {
			if (this.isDestroyed) {
				return;
			}
			this.set('resizing', false);
			this.onResizeEnd(event);
		},

		handleWindowResize: function handleWindowResize(event) {
			if (this.isDestroyed) {
				return;
			}
			if (!this.get('resizing')) {
				this.set('resizing', true);
				this.onResizeStart(event);
			}
			this.onResize(event);
			var resizeEndDelay = this.get('resizeEndDelay');
			return _ember['default'].run.debounce(this, this.endResize, event, resizeEndDelay, false);
		},

		didInsertElement: function didInsertElement() {
			this._super();
			return this._setupDocumentHandlers();
		},

		willDestroyElement: function willDestroyElement() {
			this._removeDocumentHandlers();
			return this._super();
		},

		_setupDocumentHandlers: function _setupDocumentHandlers() {
			if (this._resizeHandler) {
				return;
			}
			this._resizeHandler = _ember['default'].$.proxy(this.handleWindowResize, this);
			return _ember['default'].$(window).on("resize." + this.elementId, this._resizeHandler);
		},

		_removeDocumentHandlers: function _removeDocumentHandlers() {
			_ember['default'].$(window).off("resize." + this.elementId, this._resizeHandler);
			this._resizeHandler = null;
			return this._resizeHandler;
		}
	});
});