define('component-library/components/checkbox-filter', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['checkboxFilter', 'grid-block', 'vertical'],
		items: [],
		allText: 'All',
		noneText: 'None',
		showAll: true,
		showNone: true,

		title: null,
		labelKey: 'name',
		selectedKey: 'isSelected',
		tooltipText: null,

		showBullet: _ember['default'].computed.and('showAll', 'showNone'),

		checkboxItems: (function () {
			var _this = this;

			return this.get('items').map(function (item) {
				return _ember['default'].Object.create({
					name: item.get(_this.get('labelKey')),
					isSelected: _ember['default'].computed.oneWay('origItem.' + _this.get('selectedKey')),
					origItem: item
				});
			});
		}).property('items', 'labelKey', 'selectedKey'),

		actions: {
			toggleCheckbox: function toggleCheckbox(checkboxItem, value) {
				if (typeof value === 'undefined') {
					checkboxItem.origItem.toggleProperty(this.get('selectedKey'));
				} else {
					checkboxItem.origItem.set(this.get('selectedKey'), value);
				}
				return false;
			},

			toggleAllCheckboxes: function toggleAllCheckboxes(val) {
				var _this2 = this;

				this.get('checkboxItems').forEach(function (checkboxItem) {
					_this2.send('toggleCheckbox', checkboxItem, val);
				});
			}
		}
	});
});