define('component-library/components/angular-app', ['exports', 'ember', 'lodash', 'ember-cli-bundle-loader/utils/load-assets', 'component-library/utils/angular-app/find-js-assets', 'component-library/utils/angular-app/send-message-to-angular'], function (exports, _ember, _lodash, _emberCliBundleLoaderUtilsLoadAssets, _componentLibraryUtilsAngularAppFindJsAssets, _componentLibraryUtilsAngularAppSendMessageToAngular) {
    'use strict';

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
        } else {
            obj[key] = value;
        }return obj;
    }

    // This file (a replica of react-app.js at the time of creation) would be used to load Angular apps within the Zenefits app

    /* eslint no-lazy-globals: 0 */

    window.__WITHIN_EMBER_APP__ = true;
    // Only add this function if it isn't already there. We'll be moving to another file
    window.sendActionToEmber = window.sendActionToEmber || function (appName, type, payload) {
        try {
            window.embeddedAngularApps[appName].performEmberAction(type, payload);
        } catch (e) {
            console.error('You tried to fire an ember action on a non-existent NG App: ', appName);
        }
    };

    // Only add this function if it isn't already there. We'll be moving to another file
    window.sendMessageToAngular = window.sendMessageToAngular || _componentLibraryUtilsAngularAppSendMessageToAngular['default'];

    var ON_LOAD_MESSAGE_TYPE = 'ANGULAR_APP_LOADED';

    window.registerReactMessageHandler = window.registerReactMessageHandler || function (appName, type, handler) {
        var handlers = window.embeddedAngularApps[appName].registeredAngularMessages[type] || [];
        handlers.push(handler);
        window.embeddedAngularApps[appName].registeredAngularMessages[type] = handlers;
    };

    var CONFLICTING_EMBER_MODULES = ['Highcharts', "jQuery.fn.highcharts"];

    function getDeployShaFromUrl(appName) {
        var searchString = window.location.search;
        if (!searchString) {
            return;
        }

        var keyValuePairs = searchString.substring(1).split('&');
        for (var i = 0; i < keyValuePairs.length; i++) {
            var keyValuePair = keyValuePairs[i].split('=');
            if (keyValuePair[0] === appName + '-sha') {
                return keyValuePair[1];
            }
        }
    }

    exports['default'] = _ember['default'].Component.extend({
        adjustWrapperHeight: true,
        stashedEmberModules: {},
        showLoadingSpinner: true,
        appLoadingId: _ember['default'].computed('appName', function () {
            return 'ng-app-loader-' + this.get('appName');
        }),

        appRootId: _ember['default'].computed('appName', function () {
            return 'ng-app-component-' + this.get('appName');
        }),

        windowOrigin: _ember['default'].computed(function () {
            return window.location.origin;
        }),

        isLocalUwsgi: _ember['default'].computed('windowOrigin', function () {
            var windowOrigin = this.get('windowOrigin');

            return ['http://localhost:8000', 'http://127.0.0.1:8000'].indexOf(windowOrigin) > -1;
        }),

        indexFileOrigin: _ember['default'].computed('appName', 'isLocalUwsgi', 'windowOrigin', function () {
            var appName = this.get('appName');
            var isLocalUwsgi = this.get('isLocalUwsgi');

            if (isLocalUwsgi && appName === 'boot') {
                return 'http://zenefits-ui-assets-us-west-2.s3-website-us-west-2.amazonaws.com';
            } else {
                return this.get('windowOrigin');
            }
        }),

        appIndexUri: _ember['default'].computed('appName', 'indexFileOrigin', 'isLocalUwsgi', function () {
            var appName = this.get('appName');
            var isLocalUwsgi = this.get('isLocalUwsgi');

            if (isLocalUwsgi && appName !== 'boot') {
                throw new Error('When running embedded NG Apps, use react port (eg. 302X)');
            }

            var indexUri = this.get('indexFileOrigin') + '/' + appName + '/index.html';

            var deploySha = getDeployShaFromUrl(appName);
            if (deploySha) {
                indexUri += ':' + deploySha;
            }

            return indexUri;
        }),

        getAssets: function getAssets() {
            var _this = this;

            var appName = this.get('appName');
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                _ember['default'].$.ajax(_this.get('appIndexUri'), {
                    method: 'GET',
                    success: function success(indexContents) {
                        resolve((0, _componentLibraryUtilsAngularAppFindJsAssets['default'])(indexContents, appName));
                    },
                    error: reject
                });
            });
        },

        setWrapperHeight: function setWrapperHeight() {
            var $app = this.$();
            var appTop = $app.offset().top;
            $app.css('min-height', 'calc(100vh - ' + appTop + 'px)');

            var $appLoading = this.$('#' + this.get('appLoadingId'));
            if ($appLoading[0]) {
                $appLoading.css('min-height', 'calc(100vh - ' + appTop + 'px)');
            }
        },

        stashEmberModules: function stashEmberModules() {
            var _this2 = this;

            if (this.get('appName') !== 'boot') {
                CONFLICTING_EMBER_MODULES.forEach(function (moduleName) {
                    _this2.get('stashedEmberModules')[moduleName] = _lodash['default'].get(window, moduleName);
                    _lodash['default'].set(window, moduleName, undefined);
                });
            }
        },

        unstashEmberModules: function unstashEmberModules() {
            var _this3 = this;

            if (this.get('appName') !== 'boot') {
                CONFLICTING_EMBER_MODULES.forEach(function (moduleName) {
                    _lodash['default'].set(window, moduleName, _this3.get('stashedEmberModules')[moduleName]);
                });
            }
        },

        didInsertElement: function didInsertElement() {
            var _this4 = this;

            var appName = this.get('appName');

            this.stashEmberModules();

            if (_ember['default'].testing) {
                return;
            }

            /**
             * embeddedAngularApps.${appName}.isInUse indicates whether the embedded React component is used
             * on the current page.
             */
            _lodash['default'].set(window, 'embeddedAngularApps.' + appName + '.isInUse', true);

            var promise = _ember['default'].RSVP.resolve();

            if (this.get('adjustWrapperHeight')) {
                this.setWrapperHeight();
            }

            if (!_lodash['default'].get(window, 'embeddedAngularApps.' + appName + '.jsLoaded')) {
                (function () {
                    var indexFileOrigin = _this4.get('indexFileOrigin');
                    _this4.willDestroyElement(true);

                    promise = _this4.getAssets(appName).then(function (assets) {
                        var loadUrls = assets.map(function (_ref, index) {
                            var assetOrigin = _ref.assetOrigin;
                            var assetName = _ref.assetName;
                            var assetHash = _ref.assetHash;

                            return assetName === 'styles' ? (0, _emberCliBundleLoaderUtilsLoadAssets.loadStylesheet)((assetOrigin || indexFileOrigin) + '/' + appName + '/' + assetName + '.' + assetHash + '.css').then(function () {
                                return console.log(assetName + ' CSS STYLES loaded.');
                            }) : (0, _emberCliBundleLoaderUtilsLoadAssets.loadScript)((assetOrigin || indexFileOrigin) + '/' + appName + '/' + assetName + '.' + assetHash + '.js').then(function () {
                                console.log(assetName + ' loaded.');
                                if (index === assets.length - 1) {
                                    _this4.set('showLoadingSpinner', false);
                                }
                            });
                        });
                        return _ember['default'].RSVP.all(loadUrls);
                    }).then(function () {
                        // In IE11, load event is fired too early, so make sure start is available
                        // before continuing
                        return new _ember['default'].RSVP.Promise(function (resolve) {
                            var checkJsReady = setInterval(function () {
                                if (_lodash['default'].get(window, 'embeddedAngularApps.' + appName + '.start')) {
                                    clearInterval(checkJsReady);
                                    resolve();
                                }
                            }, 100);
                        });
                    });
                })();
            }

            promise.then(function () {
                // Note: this is a react message handler we are registering here. Generally
                // these should be specified in the NG Apps and not in ember. This is an
                // exceptional case and serves just to log a confirmation that messages are
                // being sent correctly

                // Also, rewriting the registeredAngularMessages object is intentional here.
                // Remounting a NG App will require new handlers to be specified.
                _lodash['default'].set(window, 'embeddedAngularApps.' + appName + '.registeredAngularMessages', _defineProperty({}, ON_LOAD_MESSAGE_TYPE, [function (payload) {
                    console.log('NG App: ' + appName + ' loaded with payload: ' + payload + '.');
                }]));
                _lodash['default'].set(window, 'embeddedAngularApps.' + appName + '.performEmberAction', function (type, payload) {
                    console.log('sending action: ' + type + ' with payload: ' + payload);
                    if (_this4.attrs[type]) {
                        _this4.attrs[type](payload);
                    }
                });
                _lodash['default'].set(window, 'embeddedAngularApps.' + appName + '.jsLoaded', true);

                var startedApp = window.embeddedAngularApps[appName].start(_ember['default'].$('#' + _this4.get('ui-pay-payp-trinetpay'))[0]);

                if (startedApp) {
                    // In older releases of z-frontend, start doesn't return a promise
                    startedApp.then(function () {
                        var loadData = _this4.get('loadData');
                        window.sendMessageToReact(appName, ON_LOAD_MESSAGE_TYPE, loadData);
                        _lodash['default'].set(window, 'embeddedAngularApps.' + appName + '.isAppStarted', true);
                        var queuedMessages = _lodash['default'].get(window, 'embeddedAngularApps.' + appName + '.queuedMessages');
                        if (queuedMessages) {
                            queuedMessages.forEach(function (_ref2) {
                                var type = _ref2.type;
                                var payload = _ref2.payload;

                                window.sendMessageToReact(appName, type, payload);
                            });
                        }
                        window.embeddedAngularApps[appName].queuedMessages = [];
                    });
                }

                _this4.set('hasAppLoaded', true);
            });
        },

        // keepRoot, is used to indicate not remove the Angular App root element
        // It's only used for when running the destroy method for the second time, currently it's triggered before loading the new assets
        willDestroyElement: function willDestroyElement(keepRoot) {
            this.unstashEmberModules();

            var appName = this.get('appName');
            _lodash['default'].set(window, 'embeddedAngularApps.' + appName + '.isInUse', false);

            try {

                if (!keepRoot) {
                    document.getElementById("ui-pay-payp-trinetpay").remove();
                }

                var links = document.getElementsByTagName("link");
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = links[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var asset = _step.value;

                        if (asset.href.includes("/ui-pay-payp-trinetpay/")) {
                            asset.remove();
                        }
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator['return']) {
                            _iterator['return']();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                var scripts = document.getElementsByTagName("script");
                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                    for (var _iterator2 = scripts[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                        var asset = _step2.value;

                        if (asset.src.includes("/ui-pay-payp-trinetpay/") || asset.src.includes("appdynamics")) {
                            asset.remove();
                        }
                    }
                } catch (err) {
                    _didIteratorError2 = true;
                    _iteratorError2 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion2 && _iterator2['return']) {
                            _iterator2['return']();
                        }
                    } finally {
                        if (_didIteratorError2) {
                            throw _iteratorError2;
                        }
                    }
                }
            } catch (e) {
                return;
            }
        }
    });
});