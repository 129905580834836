define('component-library/components/benefits/timeline/transaction-detail-cost', ['exports', 'ember', 'component-library/templates/components/benefits/timeline/transaction-detail-cost', 'component-library/utils/pretty-currency', 'component-library/mixins/benefits/timeline/transaction-employee-data-mixin', 'component-library/mixins/benefits/timeline/transaction-dependents-data-mixin', 'component-library/mixins/benefits/timeline/transaction-contribution-data-mixin'], function (exports, _ember, _componentLibraryTemplatesComponentsBenefitsTimelineTransactionDetailCost, _componentLibraryUtilsPrettyCurrency, _componentLibraryMixinsBenefitsTimelineTransactionEmployeeDataMixin, _componentLibraryMixinsBenefitsTimelineTransactionDependentsDataMixin, _componentLibraryMixinsBenefitsTimelineTransactionContributionDataMixin) {
	'use strict';

	var oldContributionForDisplay = function oldContributionForDisplay(propertyName) {
		return _ember['default'].computed('transaction.isInitial', 'transaction.isContributionChangeError', propertyName, function () {
			if (this.get('transaction.isInitial')) {
				return '-';
			} else if (this.get('transaction.isContributionChangeError')) {
				return '?';
			} else {
				return (0, _componentLibraryUtilsPrettyCurrency['default'])(this.get(propertyName));
			}
		});
	};

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsBenefitsTimelineTransactionEmployeeDataMixin['default'], _componentLibraryMixinsBenefitsTimelineTransactionDependentsDataMixin['default'], _componentLibraryMixinsBenefitsTimelineTransactionContributionDataMixin['default'], {
		layout: _componentLibraryTemplatesComponentsBenefitsTimelineTransactionDetailCost['default'],

		spouses: _ember['default'].computed.filterBy('dependentViewModels', 'type', 'Spouse'),

		children: _ember['default'].computed.filterBy('dependentViewModels', 'type', 'Child'),

		hasSpouse: _ember['default'].computed.notEmpty('spouse'),

		hasChild: _ember['default'].computed.notEmpty('children'),

		spouseName: _ember['default'].computed('spouses.@each.displayName', function () {
			var names = _ember['default'].get(this, 'spouses').map(function (item) {
				return _ember['default'].get(item, 'displayName');
			});
			return names.join(', ');
		}),

		childrenName: _ember['default'].computed('children.@each.displayName', function () {
			var names = _ember['default'].get(this, 'children').map(function (item) {
				return _ember['default'].get(item, 'displayName');
			});
			return names.join(', ');
		}),

		canDependentsSperated: _ember['default'].computed('oldDependentsAmount', 'oldSpouseAmount', 'oldChildrenAmount', 'dependentsAmount', 'spouseAmount', 'childrenAmount', function () {
			var oldEqual = this.get('oldDependentsAmount') == this.get('oldSpouseAmount') + this.get('oldChildrenAmount');
			var newEqual = this.get('dependentsAmount') == this.get('spouseAmount') + this.get('childrenAmount');
			return oldEqual && newEqual;
		}),

		oldEmployerCostForDisplay: oldContributionForDisplay('oldContributionAmount'),
		oldEmployeeCostForDisplay: oldContributionForDisplay('oldDeductionAmount'),
		oldTotalCostForDisplay: oldContributionForDisplay('oldTotalAmount'),
		oldEmployeeContributionForDisplay: oldContributionForDisplay('oldEmployeeContributionAmount'),
		oldSpouseContributionForDispaly: oldContributionForDisplay('oldSpouseContributionAmount'),
		oldChildrenContributionForDispaly: oldContributionForDisplay('oldChildrenContributionAmount'),
		oldDependentsContributionForDisplay: oldContributionForDisplay('oldDependentsContributionAmount')
	});
});