define('component-library/data-table/cells/editable/date-cell', ['exports', 'component-library/data-table/cells/data-table-cell'], function (exports, _componentLibraryDataTableCellsDataTableCell) {
	'use strict';

	exports['default'] = _componentLibraryDataTableCellsDataTableCell['default'].extend({
		templateName: 'data-table/cells/editable/date-cell',
		stringifyCellContent: (function () {
			// hack to nullify escaped values
			if (this.get('row.isEditing') && this.get('cellContent') && this.isEmpty(this.get('cellContent'))) {
				this.set('cellContent', null);
			}
		}).observes('cellContent', 'row.isEditing'),

		// Dash is considered as empty.
		isEmpty: function isEmpty(cellContent) {
			return cellContent && cellContent.toString() === '&dash;';
		}
	});
});