define('component-library/data-table/column-selection-popover', ['exports', 'ember', 'component-library/components/promise-popover'], function (exports, _ember, _componentLibraryComponentsPromisePopover) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
		templateName: 'data-table/column-selection-popover',
		hasArrow: false,
		placement: 'bottom',
		target: _ember['default'].computed.oneWay('model.target'),
		attachment: 'top center',
		targetAttachment: 'bottom center',
		constraints: null,

		actions: {
			selectItem: function selectItem(item) {
				this.get('_resolve')(item);
				this._close();
			}
		}
	});
});