define('component-library/components/validation-error-input', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		textFieldClassNames: undefined,
		placeholder: "",

		// TODO(ed): put in a mixin
		model: undefined,
		valuePath: '',
		valuePropertyOverride: false,

		validationErrors: (function () {
			return [];
		}).property(),

		// setupValueProperty: function() {
		// 	this.set('valueBinding', 'model.' + this.get('valuePath'));
		//  this.notifyPropertyChange('value');
		// }.observes('valuePath').on('init'),

		setupValueProperty: (function () {
			if (this.get('valuePropertyOverride')) {
				return;
			}
			var path = 'model.' + this.get('valuePath');
			this.propertyWillChange('value');
			_ember['default'].defineProperty(this, 'value', _ember['default'].computed.apply(null, [path].concat(function (key, value) {
				if (arguments.length > 1) {
					this.set(path, value);
				}
				return this.get(path);
			})));
			this.propertyDidChange('value');
		}).observes('valuePath').on('init'),

		modelType: (function () {
			return window.App.store.modelFor(this.get('model.constructor'));
		}).property('model'),

		relevantValidationErrors: (function () {
			var modelType = this.get('modelType');
			var modelId = this.get('model.id');
			var valuePath = this.get('valuePath');
			if (!modelType || !modelId) {
				return [];
			}
			return this.get('validationErrors').filter(function (validationError) {
				return validationError.get('relatedModelType') === modelType && validationError.get('relatedModelId') === modelId && validationError.get('fields').contains(valuePath);
			});
		}).property('validationErrors.[]', 'model', 'valuePath')
	});
});