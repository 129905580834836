define('component-library/components/payroll/state-taxes/state-tax', ['exports', 'ember', 'component-library/utils/globals', 'component-library/mixins/payroll/state-tax-mixin'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryMixinsPayrollStateTaxMixin) {
	'use strict';

	var StateTax = _ember['default'].Object.extend({
		id: null,
		rate: null,
		taxPayee: null,

		date: null,
		shouldDelete: false,
		shouldReload: false,
		futureTaxError: null,

		month: _ember['default'].computed('date', function () {
			return (0, _componentLibraryUtilsGlobals.moment)(this.get('date'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT).month() + 1; // Jan = 0
		}),
		year: _ember['default'].computed('date', function () {
			return (0, _componentLibraryUtilsGlobals.moment)(this.get('date'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT).year();
		})
	});

	var SUTA_TAX_TYPES = ['SUTA', 'SUTA_SC', 'SUTA_SC_2', 'SUTA_SC_3'];

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsPayrollStateTaxMixin['default'], {
		didReceiveAttrs: function didReceiveAttrs() {
			if (this.get('hasFutureStateTax')) {
				if (this.get('futureStateTax.shouldReload')) {
					this.set('hasFutureStateTax', this.get('model.hasFutureTaxRateRequested'));
					this._removeFutureTax();
				}
				this._createFutureStateTax();
			}
		},

		// Private
		_createFutureStateTax: function _createFutureStateTax() {
			if (!_ember['default'].isEmpty(this.get('futureStateTax'))) {
				return;
			}
			var futureTax = StateTax.create({
				id: this.get('model.id'),
				rate: this.get('model.futureTaxRate'),
				taxPayee: this.get('model.taxPayee'),
				date: this.getDate(this.get('model.futureTaxRateEffectiveDate'))
			});

			this.set('futureStateTax', futureTax);
			this.get('futureStateTaxes').pushObject(futureTax);
		},

		_removeFutureTax: function _removeFutureTax() {
			var futureTax = this.get('futureStateTax');
			this.get('futureStateTaxes').removeObject(futureTax);
			this.set('futureStateTax', null);
		},

		_setSaveStatus: function _setSaveStatus() {
			this._removeFutureTax();
			this._createFutureStateTax();
			this.set('hasFutureStateTax', true);
		},

		_setDeleteStatus: function _setDeleteStatus() {
			var futureTax = this.get('futureStateTax');
			if (futureTax) {
				futureTax.set('shouldDelete', true);
				this.set('hasFutureStateTax', false);
			}
		},

		// Passed properties
		model: null,
		futureStateTaxes: null,
		currentRateTooltip: null,
		futureRateToolltip: null,
		showFutureStateTax: false,

		// className
		currentRateClassName: '',
		futureRateClassName: '',

		// Computed properties
		taxType: _ember['default'].computed.oneWay('model.taxType'),
		isDisabled: _ember['default'].computed('model.taxType', 'isSutaReimbursable', function () {
			return this.get('isSutaReimbursable') && SUTA_TAX_TYPES.contains(this.get('taxType'));
		}),
		enableFutureStateTax: _ember['default'].computed('showFutureStateTax', 'isDisabled', function () {
			return this.get('showFutureStateTax') && !this.get('isDisabled');
		}),
		hasFutureStateTax: _ember['default'].computed.oneWay('model.hasFutureTaxRateRequested'),
		futureStateTax: _ember['default'].computed.alias('model.futureStateTax'),
		futureTaxRate: _ember['default'].computed.alias('futureStateTax.rate'),
		hasFutureTaxRateRequested: _ember['default'].computed.alias('model.hasFutureTaxRateRequested'),
		futureTaxRatesArray: _ember['default'].computed('model.futureTaxRateAvailableEffectiveDates', function () {
			return this.getTaxDateOptions(this.get('model.futureTaxRateAvailableEffectiveDates'));
		}),

		// user may toggle reimbursable option, should delete future rate when it's disabled
		deleteFutureStateTax: _ember['default'].observer('enableFutureStateTax', function () {
			if (!this.get('enableFutureStateTax')) {
				this._setDeleteStatus();
			}
		}),

		actions: {
			addStateTax: function addStateTax() {
				this._setSaveStatus();
			},
			deleteStateTax: function deleteStateTax() {
				this._setDeleteStatus();
			}
		}
	});
});