define('component-library/components/date-range-quick-filter', ['exports', 'ember', 'component-library/templates/components/date-range-quick-filter', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryTemplatesComponentsDateRangeQuickFilter, _componentLibraryUtilsGlobals) {
	'use strict';

	var _getXBefore = function _getXBefore(x, units) {
		var isFromMidnight = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

		var result = (0, _componentLibraryUtilsGlobals.moment)().subtract(x, units);

		if (isFromMidnight) {
			return result.startOf('date');
		}

		return result;
	};

	// isFromMidnight indicates whether the start date of the range should be from midnight.
	// For example, if it's currently 2pm, we could either get 2pm of X days before, or
	// we could get the start of the day (i.e. midnight) of X days before if the flag is true.
	var xDaysAgo = function xDaysAgo(x) {
		var isFromMidnight = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

		return _getXBefore(x, 'days', isFromMidnight);
	};

	var xMonthsAgo = function xMonthsAgo(x) {
		var isFromMidnight = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

		return _getXBefore(x, 'months', isFromMidnight);
	};

	var xYearsAgo = function xYearsAgo(x) {
		var isFromMidnight = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

		return _getXBefore(x, 'years', isFromMidnight);
	};

	var PRESET_CHOICES = {
		'past30Days': {
			label: 'Past 30 days',
			startDate: function startDate() {
				return xDaysAgo(30);
			},
			endDate: function endDate() {
				return (0, _componentLibraryUtilsGlobals.moment)();
			}
		},
		'past90Days': {
			label: 'Past 90 days',
			startDate: function startDate() {
				return xDaysAgo(90);
			},
			endDate: function endDate() {
				return (0, _componentLibraryUtilsGlobals.moment)();
			}
		},
		'past180Days': {
			label: 'Past 180 days',
			startDate: function startDate() {
				return xDaysAgo(180);
			},
			endDate: function endDate() {
				return (0, _componentLibraryUtilsGlobals.moment)();
			}
		},
		'currentMonth': { // From the 1st of current month till now
			label: 'Current month',
			startDate: function startDate() {
				return (0, _componentLibraryUtilsGlobals.moment)().startOf('month');
			},
			endDate: function endDate() {
				return (0, _componentLibraryUtilsGlobals.moment)();
			}
		},
		'currentYear': {
			label: 'Current year',
			startDate: function startDate() {
				return (0, _componentLibraryUtilsGlobals.moment)().startOf('year');
			},
			endDate: function endDate() {
				return (0, _componentLibraryUtilsGlobals.moment)();
			}
		},
		'previousMonth': { //1st through last date of previous month
			label: 'Previous month',
			startDate: function startDate() {
				return xMonthsAgo(1).startOf('month');
			},
			endDate: function endDate() {
				return xMonthsAgo(1).endOf('month');
			}
		},
		'previousYear': { //From 1/1 of previous year to 12/31 of previous year
			label: 'Previous year',
			startDate: function startDate() {
				return xYearsAgo(1).startOf('year');
			},
			endDate: function endDate() {
				return xYearsAgo(1).endOf('year');
			}
		}
	};

	var CUSTOM_DATES_KEY = 'custom';
	var SINGLE_DATE_KEY = 'single';

	exports['default'] = _ember['default'].Component.extend({
		layout: _componentLibraryTemplatesComponentsDateRangeQuickFilter['default'],
		presetChoices: ['past30Days', 'past90Days', 'past180Days', 'currentMonth', 'currentYear', 'previousMonth', 'previousYear'],
		includeSingleDate: false,
		selectedPreset: null,
		startDate: null,
		endDate: null,
		startDateErrorText: null,
		endDateErrorText: null,
		label: 'Date Filter',
		minStartDate: xYearsAgo(5).format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT), //TODO: Find out what min. we want to use
		minEndDate: xYearsAgo(5).format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
		maxStartDate: (0, _componentLibraryUtilsGlobals.moment)().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
		maxEndDate: (0, _componentLibraryUtilsGlobals.moment)().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT), // Override this if we want future dates
		isDisabled: false,
		hideDatePickerPopover: false,
		isSingleDateSelected: false, // toggle value to true if "Specific Date" is selected. Can be used by consumer.
		isHorizontalLayout: false, // if true, it'll show the date input fields to the right of the dropdown (instead of below)

		_customStartDate: null,
		_customEndDate: null,
		_isCustomDates: _ember['default'].computed.equal('selectedPreset', CUSTOM_DATES_KEY),
		_isSingleDate: _ember['default'].computed.equal('selectedPreset', SINGLE_DATE_KEY),
		_showDateInputs: _ember['default'].computed.or('_isCustomDates', '_isSingleDate'),

		// If dropdown label is empty and it's a horizontal layout, hide the "From/To" range input
		// labels and put the "To" between the two inputs, instead. Also, don't bump down the
		// single date input.
		_useHorizontalRangeLabel: _ember['default'].computed('label', 'isHorizontalLayout', function () {
			return this.get('isHorizontalLayout') && _ember['default'].isEmpty(this.get('label'));
		}),

		_fromLabel: _ember['default'].computed('_useHorizontalRangeLabel', function () {
			return this.get('_useHorizontalRangeLabel') ? null : 'From';
		}),

		_toLabel: _ember['default'].computed('_useHorizontalRangeLabel', function () {
			return this.get('_useHorizontalRangeLabel') ? null : 'To';
		}),

		didInsertElement: function didInsertElement() {
			this._super();

			var now = (0, _componentLibraryUtilsGlobals.moment)();
			var nowString = now.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
			var initialPreset = this.selectedPreset || 'past30Days';

			this.setProperties({
				_customStartDate: nowString,
				_customEndDate: nowString
			});
		},

		_selectFieldContent: _ember['default'].computed('presetChoices', function () {
			var choices = this.get('presetChoices');

			var options = choices.map(function (key) {
				return {
					option: PRESET_CHOICES[key].label,
					id: key
				};
			});

			if (this.get('includeSingleDate')) {
				options.push({
					option: 'Specific date',
					id: SINGLE_DATE_KEY
				});
			}

			options.push({
				option: 'Specific date range',
				id: CUSTOM_DATES_KEY
			});

			return options;
		}),

		_onQuickFilterChange: _ember['default'].observer('selectedPreset', function () {
			var selectedPreset = this.get('selectedPreset');
			var nowString = (0, _componentLibraryUtilsGlobals.moment)().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
			var props = {
				startDate: nowString,
				endDate: nowString,
				_customStartDate: nowString,
				_customEndDate: nowString,
				isSingleDateSelected: false
			};

			if (selectedPreset == SINGLE_DATE_KEY) {
				props.isSingleDateSelected = true;
			} else if (selectedPreset !== CUSTOM_DATES_KEY) {
				props = this._getPresetChoiceDates(selectedPreset);
			}

			this.setProperties(props);
		}),

		_getPresetChoiceDates: function _getPresetChoiceDates(selectedPreset) {
			var presetDetails = PRESET_CHOICES[selectedPreset];

			if (!presetDetails) {
				return null;
			}

			return {
				startDate: presetDetails.startDate().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
				endDate: presetDetails.endDate().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
				isSingleDateSelected: false
			};
		},

		_onCustomStartDateChange: _ember['default'].observer('_customStartDate', function () {
			var updateProps = {};
			var customStartDate = this.get('_customStartDate');
			var isCustomDates = this.get('_isCustomDates');
			var isSingleDate = this.get('_isSingleDate');

			if (isCustomDates || isSingleDate) {
				updateProps.startDate = customStartDate;
			}
			if (isSingleDate) {
				updateProps.endDate = customStartDate;
			}

			this.setProperties(updateProps);
		}),

		_onCustomEndDateChange: _ember['default'].observer('_customEndDate', function () {
			if (this.get('_isCustomDates')) {
				this.set('endDate', this.get('_customEndDate'));
			}
		})
	});
});