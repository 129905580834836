define('component-library/components/point-rating', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var POSITION = {
    right: 'u-floatRight',
    left: 'u-floatLeft',
    center: ''
  };

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['point-rating', 'js-glue-point-rating'],
    classNameBindings: ['_alignClass'],
    minRating: 1,
    maxRating: 5,
    descOrder: false,
    step: 1,
    sizeModifier: null,
    readOnly: false,
    selectedRating: null,
    hideNA: false,
    align: 'center',
    enableUnselect: true,
    tooltipTexts: null,

    // Get the align class from constant above
    _alignClass: (function () {
      return POSITION[this.get('align')];
    }).property('align'),

    // Creates the point-rating array using minRating and maxRating
    ratings: (function () {
      var ratingsArray = [];
      var minRating = this.get('minRating');
      var maxRating = this.get('maxRating');
      var step = this.get('step');
      var tooltipTexts = this.get('tooltipTexts') || {};

      for (var i = minRating; i <= maxRating; i += step) {
        ratingsArray.pushObject(_ember['default'].Object.create({
          value: i,
          active: i == this.get('selectedRating'),
          tooltipText: tooltipTexts[i]
        }));
      }

      if (this.get('descOrder')) {
        ratingsArray.reverse();
      }

      if (!this.get('hideNA')) {
        ratingsArray.pushObject(_ember['default'].Object.create({
          value: 'NA',
          active: this.get('selectedRating') == 'NA',
          className: 'NA',
          tooltipText: tooltipTexts['NA']
        }));
      }

      return ratingsArray;
    }).property('minRating', 'maxRating'),

    // Resizing the component when sizeModifier is changed
    resize: (function () {
      var _this = this;

      var componentSelector = '.js-point-rating-item';
      var cssProperties = ['height', 'width', 'line-height', 'margin', 'font-size', 'border-width'];
      var sizeModifier = this.get('sizeModifier');

      // Scale the component if a size modifier was provider
      if (sizeModifier) {
        (function () {
          var componentCss = _this.$(componentSelector).css(cssProperties);

          cssProperties.forEach(function (property) {
            var newSize = Math.round(componentCss[property].replace('px', '') * sizeModifier);
            componentCss[property] = newSize + 'px';
          });
          _this.$(componentSelector).css(componentCss);
        })();
      }
    }).observes('sizeModifier'),

    didInsertElement: function didInsertElement() {
      this.resize();
    },

    actions: {
      setRating: function setRating(newRating) {
        // Dont' go through function if readonly is true
        if (this.get('readOnly')) {
          return;
        }

        var currentRating = this.get('selectedRating');
        // Do nothing if unselect is disabled and rating doesn't change
        if (currentRating === newRating && !this.get('enableUnselect')) {
          return;
        }

        // Disabling the active state of the previous selected rating (if any)
        if (!_ember['default'].isNone(currentRating) && currentRating != newRating) {
          this.get('ratings').findBy('value', currentRating).toggleProperty('active');
        }

        // Toggles current rating
        this.get('ratings').findBy('value', newRating).toggleProperty('active');

        // Set new Rating
        this.set('selectedRating', currentRating == newRating ? null : newRating);
      }
    }
  });
});