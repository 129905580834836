define("z-inputs/utils/number-fields-helper", ["exports"], function (exports) {
  "use strict";

  exports["default"] = getAdjustedAmount;

  function getAdjustedAmount(value, options) {
    if (value) {
      options = options || {};
      var minValue = options.min;
      var maxValue = options.max;
      if (minValue !== undefined && Number(value) < Number(minValue)) {
        return minValue;
      }
      if (maxValue !== undefined && Number(value) > Number(maxValue)) {
        return maxValue;
      }
    }
    return value;
  }
});