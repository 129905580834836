define('component-library/data-table/cells/avatarname-or-check-table-link-cell', ['exports', 'ember', 'component-library/data-table/cells/avatarname-table-link-cell'], function (exports, _ember, _componentLibraryDataTableCellsAvatarnameTableLinkCell) {
	'use strict';

	exports['default'] = _componentLibraryDataTableCellsAvatarnameTableLinkCell['default'].extend({
		templateName: 'data-table/cells/avatarname-or-check-table-link-cell',

		isChecked: _ember['default'].computed.alias('row.employee.isAcked'),

		tearsheetUrl: (function () {
			return '/dashboard/#/employeetearsheet/' + this.get('row.employee.id');
		}).property()
	});
});