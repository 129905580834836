define('component-library/utils/fmt-pluralize', ['exports', 'component-library/utils/pluralize'], function (exports, _componentLibraryUtilsPluralize) {
  'use strict';

  /* Usage:
   *   arg: number
   *   arg: phrase is the string you want pluralized.
   *   arg: omitNumber (bool) whether to include the number in the result or not.
   *        By default, the number will be included
   *   returns: Pluralized phrase with the number before it
   *
   *   By default, the pluralized form of myPhrase will have an 's' appended to it.
   *   If the word ends with consonant + y, the 'y' will be changed to 'ies'.
   *
   *   This can be customized by specifying modifiers of the form:
   *   myPhrase{<singular ending>|<plural ending>}.
   *
   *	 Examples:
   *	 fmtPluralize(boyCount, "boy") --> 1 boy OR 2 boys
   *	 fmtPluralize(pharmacyCount, "pharmacy") --> 1 pharmacy OR 2 pharmacies
   *	 fmtPluralize(boyCount, "boy{ is|s are}") --> 1 boy is OR 2 boys are
   *	 fmtPluralize(numToys, "toy") --> toys
   */

  exports['default'] = function (number, phrase) {
    var omitNumber = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

    var phraseMatch = phrase.match(/(.*?)\{(.*?)\|(.*?)\}/);
    var word, singular, plural;
    if (phraseMatch) {
      word = phraseMatch[1];
      singular = word + phraseMatch[2];
      plural = word + phraseMatch[3];
    } else {
      word = phrase;
      singular = word;
      plural = (0, _componentLibraryUtilsPluralize['default'])(word);
    }

    var modifiedString = Math.abs(number) == 1 ? singular : plural;
    if (omitNumber) {
      return modifiedString;
    }
    return number + ' ' + modifiedString;
  };
});