define('component-library/utils/pretty-percent2', ['exports'], function (exports) {
	'use strict';

	exports['default'] = function (number, preserveDecimals, precision) {
		precision = parseInt(precision);
		number = precision >= 0 ? number.toFixed(precision) : number.toString();
		if (!preserveDecimals) {
			number = number.replace(/\.0+$/, '').replace(/(\.\d*?)0+$/, '$1');
		}

		return (number || number === 0) && number + '%' || '';
	};
});