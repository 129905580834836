define('component-library/components/z-status-tag', ['exports', 'ember', 'component-library/components/z-status-tag/status-popover', 'component-library/components/z-status-tag/constants'], function (exports, _ember, _componentLibraryComponentsZStatusTagStatusPopover, _componentLibraryComponentsZStatusTagConstants) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		StatusTagPopoverClass: _ember['default'].computed('attachment', 'targetAttachment', function () {
			var attachment = this.get('attachment');
			var targetAttachment = this.get('targetAttachment');

			if (_ember['default'].isEmpty(attachment) || _ember['default'].isEmpty(targetAttachment)) {
				return _componentLibraryComponentsZStatusTagStatusPopover['default'];
			} else {
				return _componentLibraryComponentsZStatusTagStatusPopover['default'].extend({
					attachment: attachment,
					targetAttachment: targetAttachment
				});
			}
		}),

		popoverContent: null,
		statusTagMessage: null,
		statusTagColor: _componentLibraryComponentsZStatusTagConstants.STATUS_TAG_COLORS.GREY,
		addLeftBumper: false,
		isInTable: false,
		classNameBindings: ['isInTable:z-tag-table'],
		statusPopoverClassNames: null,

		statusTagClassNames: _ember['default'].computed('statusTagColor', function () {
			var classes = ['z-tag', 'z-status-tag'];
			var statusTagColor = this.get('statusTagColor');
			if (this.get('addLeftBumper')) {
				classes.push('u-bumperLeft--md');
			}
			if ([_componentLibraryComponentsZStatusTagConstants.STATUS_TAG_COLORS.GREY, _componentLibraryComponentsZStatusTagConstants.STATUS_TAG_COLORS.GREEN, _componentLibraryComponentsZStatusTagConstants.STATUS_TAG_COLORS.RED].indexOf(statusTagColor) > -1) {
				classes.push('z-tag--' + statusTagColor);
			}
			return classes.join(' ');
		}),
		statusTagPopoverContent: _ember['default'].computed('addLeftBumper', 'popoverContent', 'popoverContent.buttons', 'popoverContent.message', function () {
			var popoverContent = this.get('popoverContent');
			// if there is no popoverContent, we will not show a popover at all upon hover
			if (_ember['default'].isEmpty(popoverContent)) {
				return null;
			}
			popoverContent.addLeftBumper = this.get('addLeftBumper');
			return popoverContent;
		})
	});
});