define('component-library/mixins/benefits/timeline/transaction-requester-data-mixin', ['exports', 'ember', 'component-library/utils/benefits/timeline/computed'], function (exports, _ember, _componentLibraryUtilsBenefitsTimelineComputed) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({

		requester: (0, _componentLibraryUtilsBenefitsTimelineComputed.computedForEmployeePromiseObject)('transaction.log.requestEmployee_id'),

		requesterName: _ember['default'].computed.alias('requester.fullName'),

		requesterCategory: _ember['default'].computed.alias('transaction.log.requesterCategory')
	});
});