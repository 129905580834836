define('component-library/timeline/components/data/bulk', ['exports', 'ember', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryComponentsPromiseModal) {
	'use strict';

	var TOP_EMPLOYEE_NUM = 6;

	var DeductionsBulkModal = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'components/timeline/deduction-bulk-modal',
		data: _ember['default'].computed.alias('fullBulkEntry.data'),

		topEmployees: (function () {
			return this.get('data.events').slice(0, TOP_EMPLOYEE_NUM).mapBy('employee');
		}).property('data.events.@each.employee'),

		surplusEmployeeCount: (function () {
			return Math.max(this.get('data.eventsCount') - TOP_EMPLOYEE_NUM, 0);
		}).property('data.eventsCount')
	});

	exports['default'] = _ember['default'].Component.extend({
		templateName: 'components/timeline/data/bulk',

		surplusEmployeeCount: (function () {
			return Math.max(this.get('entry.data.eventsCount') - TOP_EMPLOYEE_NUM, 0);
		}).property('entry.data.eventsCount'),

		actions: {
			openDetails: function openDetails() {
				var _this = this;

				this.get('getFullFeedEntry')(this.get('entry.id')).then(function (full) {
					return DeductionsBulkModal.show({
						modalDialogClass: 'd-z-modal-dialog--lg',
						fullBulkEntry: full,
						adminEmployee: _this.get('adminEmployee')
					});
				}).then(function (result) {
					console.log('success', result);
				}, function (reason) {
					console.log('error', reason);
				});
			}
		}
	});
});