define('component-library/constants/z-payroll/z-payroll-contribution-categories', ['exports'], function (exports) {
	// This file will hold all the Contribution categories as needed. Currently adding only the covid19 related categories.
	// This should be kept in sync with zenefits_payroll.enums.common.Covid19ContributionCategories
	'use strict';

	var COVID19_CONTRIBUTION_CATEGORY = {
		COVID_19_QUALIFIED_HEALTH_PLAN_EXPENSES_SELF_CARE: 'covid_19_qualified_health_plan_expenses_self_care',
		COVID_19_QUALIFIED_HEALTH_PLAN_EXPENSES_FAMILY_CARE: 'covid_19_qualified_health_plan_expenses_family_care',
		COVID_19_QUALIFIED_HEALTH_PLAN_EXPENSES_SELF_CARE_ARPA: 'covid_19_qualified_health_plan_expenses_self_care_arpa',
		COVID_19_QUALIFIED_HEALTH_PLAN_EXPENSES_FAMILY_CARE_ARPA: 'covid_19_qualified_health_plan_expenses_family_care_arpa',
		COVID_19_QUALIFIED_WAGES_EMPLOYEE_RETENTION: 'covid_19_qualified_wages_employee_retention',
		COVID_19_QUALIFIED_WAGES_EMPLOYEE_RETENTION_MARCH: 'covid_19_qualified_wages_employee_retention_march',
		COVID_19_QUALIFIED_HEALTH_PLAN_EXPENSES_EMPLOYEE_RETENTION: 'covid_19_qualified_health_plan_expenses_employee_retention',
		COVID_19_QUALIFIED_HEALTH_PLAN_EXPENSES_EMPLOYEE_RETENTION_MARCH: 'covid_19_qualified_health_plan_expenses_employee_retention_march',
		COVID_19_COBRA_PREMIUM_ASSISTANCE: 'covid_19_cobra_premium_assistance'
	};

	exports.COVID19_CONTRIBUTION_CATEGORY = COVID19_CONTRIBUTION_CATEGORY;

	// NOTE: These should be kept private. Use CONTRIBUTION_CATEGORY_TYPES if you need them.
	var COVID19_CONTRIBUTION_EMPLOYEE_RETENTION_CREDIT_TYPES = new Set([COVID19_CONTRIBUTION_CATEGORY.COVID_19_QUALIFIED_WAGES_EMPLOYEE_RETENTION, COVID19_CONTRIBUTION_CATEGORY.COVID_19_QUALIFIED_WAGES_EMPLOYEE_RETENTION_MARCH, COVID19_CONTRIBUTION_CATEGORY.COVID_19_QUALIFIED_HEALTH_PLAN_EXPENSES_EMPLOYEE_RETENTION, COVID19_CONTRIBUTION_CATEGORY.COVID_19_QUALIFIED_HEALTH_PLAN_EXPENSES_EMPLOYEE_RETENTION_MARCH]);

	var COVID19_CONTRIBUTION_LEAVE_CREDIT_TYPES = new Set([COVID19_CONTRIBUTION_CATEGORY.COVID_19_QUALIFIED_HEALTH_PLAN_EXPENSES_SELF_CARE, COVID19_CONTRIBUTION_CATEGORY.COVID_19_QUALIFIED_HEALTH_PLAN_EXPENSES_FAMILY_CARE, COVID19_CONTRIBUTION_CATEGORY.COVID_19_QUALIFIED_HEALTH_PLAN_EXPENSES_SELF_CARE_ARPA, COVID19_CONTRIBUTION_CATEGORY.COVID_19_QUALIFIED_HEALTH_PLAN_EXPENSES_FAMILY_CARE_ARPA]);

	var COVID19_CONTRIBUTION_COBRA_CREDIT_TYPES = new Set([COVID19_CONTRIBUTION_CATEGORY.COVID_19_COBRA_PREMIUM_ASSISTANCE]);

	var COVID19_CONTRIBUTION_CATEGORY_TYPES = {
		COVID19_CONTRIBUTION_EMPLOYEE_RETENTION_CREDIT_TYPES: COVID19_CONTRIBUTION_EMPLOYEE_RETENTION_CREDIT_TYPES,
		COVID19_CONTRIBUTION_LEAVE_CREDIT_TYPES: COVID19_CONTRIBUTION_LEAVE_CREDIT_TYPES,
		COVID19_CONTRIBUTION_COBRA_CREDIT_TYPES: COVID19_CONTRIBUTION_COBRA_CREDIT_TYPES
	};
	exports.COVID19_CONTRIBUTION_CATEGORY_TYPES = COVID19_CONTRIBUTION_CATEGORY_TYPES;
});