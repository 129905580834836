define('component-library/routes/detail', ['exports', 'ember', 'component-library/routes/super-route', 'component-library/utils/dirty', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryRoutesSuperRoute, _componentLibraryUtilsDirty, _componentLibraryComponentsPromiseModal) {
	'use strict';

	exports['default'] = _componentLibraryRoutesSuperRoute['default'].extend({
		hidePanel: (function () {
			this.send('hideDetails');
		}).on('outerTransitionDeactivate'),

		attemptTransition: function attemptTransition() {
			var model = this.controller.get('model');

			var isEditing = this.controller.get('isEditing');
			var isDirty = (0, _componentLibraryUtilsDirty['default'])(model);
			var isDeleted = model.get('isDeleted');

			var blockForEdit = isEditing && isDirty && !isDeleted;
			if (!blockForEdit) {
				return _ember['default'].RSVP.resolve();
			}

			var route = this;

			return _componentLibraryComponentsPromiseModal['default'].show({
				templateName: 'confirm-save-edits'
			}).then(function () {
				route.controller.doSave();

				return _ember['default'].RSVP.resolve();
			});
		}
	});
});