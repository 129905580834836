define('z-inputs/components/z-text-field-ssn', ['exports', 'ember', 'z-inputs/components/z-text-field-base', 'z-inputs/templates/components/z-text-field-ssn'], function (exports, _ember, _zInputsComponentsZTextFieldBase, _zInputsTemplatesComponentsZTextFieldSsn) {
  'use strict';

  exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend({
    layout: _zInputsTemplatesComponentsZTextFieldSsn['default'],
    classNames: 'z-textField--ssn',
    type: 'ssn',
    mask: '(999-99-9999)|(***-**-9999)',
    maskOptions: {
      definitions: {
        "*": {
          validator: '[*]',
          cardinality: 1
        }
      }
    },

    formattedSSN: _ember['default'].computed('value', {
      get: function get() {
        var value = this.get('value') || '';
        var mask = this.get('mask');
        var definitions = this.get('maskOptions.definitions');
        return window.Inputmask.format(value, {
          definitions: definitions,
          mask: mask,
          placeholder: ''
        });
      },

      set: function set(key, userInputValue) {
        var newValue = userInputValue.replace(/-/g, '');
        this.set('value', newValue);
        return userInputValue;
      }
    })
  });
});