define('component-library/utils/benefits/timeline/url-query-params', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	var computedForUrlQueryParamOfDate = function computedForUrlQueryParamOfDate(dependentKey) {
		return _ember['default'].computed(dependentKey, {
			get: function get(key) {
				var value = this.get(dependentKey);
				if (value) {
					return value.format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
				}
			},
			set: function set(key, value) {
				var oldDate = this.get(dependentKey);
				var date = value ? (0, _componentLibraryUtilsGlobals.moment)(value, _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT) : null;
				if (oldDate != date) {
					this.set(dependentKey, date);
				}
			}
		});
	};
	exports.computedForUrlQueryParamOfDate = computedForUrlQueryParamOfDate;
});