define('component-library/controllers/master', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Controller.extend({
		isDetailsOpen: false,
		selection: null,

		filteredContent: _ember['default'].computed(function () {
			return this.get('model');
		}).property(),

		sortedContent: _ember['default'].computed(function () {
			if (!this.get('filteredContent')) {
				return [];
			}

			var sortPath = this.get('sortPath');
			var sortComparator = this.get('sortComparator');
			var sortMultiplier = this.get('sortMultiplier');

			if (!sortPath || !sortComparator) {
				return this.get('filteredContent').toArray();
			}

			return this.get('filteredContent').toArray().sort(function (a, b) {
				return sortMultiplier * sortComparator(a.get(sortPath), b.get(sortPath));
			});
		}).property('sortPath', 'sortComparator', 'sortMultiplier', 'filteredContent'),

		actions: {
			toggleIsFilterPanelOpen: function toggleIsFilterPanelOpen() {
				this.toggleProperty('isFilterPanelOpen');
			},

			sortColumn: function sortColumn(column) {
				if (this.get('sortComparator') != column.get('comparator')) {
					column.set('sortAscending', false);
				} else {
					column.toggleProperty('sortAscending');
				}

				this.set('sortPath', column.get('contentPath'));
				this.set('sortComparator', column.get('comparator'));
				this.set('sortMultiplier', column.get('sortAscending') ? 1 : -1);
			}
		}
	});
});