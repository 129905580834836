define("component-library/constants/z-payroll/z-payroll-report-types", ["exports"], function (exports) {
	"use strict";

	var REPORT_TYPES = {

		PayrollJournal: "PayrollJournal",
		EmployeeInformation: "EmployeeInformation",
		EmployeeDetailsReport: "EmployeeDetails",
		BenefitsReport: "BenefitsReport",
		YearToDateReport: "YearToDateReport",
		BankAccountTransaction: "BankAccountTransaction",
		TaxTransactionReport: "TaxTransactionReport",
		EmployerDebitsReport: "EmployerDebitsReport",
		EmployeeCreditsReport: "EmployeeCreditsReport",
		TaxPaymentsReport: "TaxPaymentsReport",
		InboundPaymentsReport: "InboundPaymentsReport",
		OutboundPaymentsReport: "OutboundPaymentsReport",
		DailyReconTaxPaymentsReport: "DailyReconTaxPaymentsReport",
		TaxDataDump: "TaxDataDump",
		BenefitsDataDump: "BenefitsDataDump",
		DoomsdayReport: "DoomsdayReport",
		ClientFilingReport: "ClientFilingReport",
		NewHireReport: "NewHireReport",
		TaxFilingReport: "TaxFilingReport",
		TaxPackage: "TaxPackage",
		CompanyTaxCreditsReport: "CompanyTaxCreditsReport",
		JurisdictionPaymentReport: "JurisdictionPaymentReport",
		CompanyDebitVariance: "CompanyDebitVariance",
		PayrollWireReport: "PayrollWireReport",
		AllInOneReport: "AllInOneReport",
		EmployeeRiskCodeReport: "EmployeeRiskCodeReport",
		PaymentBackfillCompanyVarianceReport: "PaymentBackfillCompanyVarianceReport",
		EmployeeHistoryReport: "EmployeeHistoryReport",
		CompanyHistoryReport: "CompanyHistoryReport",
		TaxNoticeReport: "TaxNoticeReport",
		EmployeeDetailReport: "EmployeeDetailReport",
		CompanyDetailReport: "CompanyDetailReport",
		PayrollDetailReport: "PayrollDetailReport",
		PayrollDetailReportBeta: "PayrollDetail",
		ContractorPaymentReport: 'ContractorPaymentReport',
		PayrollSummaryReport: "PayrollSummaryReport",
		LaborDistributionReport: "LaborDistribution",
		LiabilitiesReport: "Liabilities",
		W2PreviewReport: "W2PreviewReport",
		W2PreviewGenerate: "W2PreviewGenerate",
		ClientTaxPaymentsReport: "ClientTaxPaymentsReport",
		HistorySummaryReport: "HistorySummary",
		PayrollRegisterReport: "PayrollRegister",
		PayrollSummaryReportBeta: "PayrollSummary",
		TaxLiabilityReport: "TaxLiability",
		DeductionAndContributionReport: "DeductionsAndContributions",
		TaxPaymentsBetaReport: "TaxPayments",
		WageAndTaxSummaryReport: "WageAndTaxSummary",
		AllInOneReportBeta: 'AllInOne',
		GarnishmentsAuditReport: 'GarnishmentsAuditReport',
		GarnishmentsSetupReport: 'GarnishmentsSetupReport',
		LaborDistributionDetailReport: 'LaborDistributionDetail',
		NewLaborDistributionReport: "LaborDistributionNew",
		ReportedTips: 'ReportedTips',
		GarnishmentTerminationReport: "GarnishmentTerminationReport"
	};
	exports.REPORT_TYPES = REPORT_TYPES;
});