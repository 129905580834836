define('component-library/mixins/pay-schedule-mixin', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	var SUNDAY = 6;
	exports['default'] = _ember['default'].Mixin.create({
		bankHolidays: undefined,
		blockPeriod: undefined,

		payFrequency: null,
		payDayOfWeek: null,
		payDayOfMonth: null,
		unshiftedAnchorCheckDate: null,
		secondPayDayOfMonth: null,
		arrearsDays: null,
		holidayShift: null,
		saturdayShift: null,
		sundayShift: null,

		// TODO: when we have time
		holidayOverrideShift: null,
		saturdayOverrideShift: null,
		sundayOverrideShift: null,

		isWeekly: _ember['default'].computed.equal('payFrequency', 'We'),
		isBiWeekly: _ember['default'].computed.equal('payFrequency', 'BW'),
		isSemiMonthly: _ember['default'].computed.equal('payFrequency', 'SM'),
		isMonthly: _ember['default'].computed.equal('payFrequency', 'Mo'),

		_validate: function _validate(date) {
			var allowPastEffectiveDate = this.get('allowPastEffectiveDate');
			var payPeriodStartDate = this.get('payPeriodStartDate');
			var clusterDate = this.get('clusterDate');

			// Check that it is after the specified start date if any
			var isAfterStartDate = payPeriodStartDate ? date.isAfter(this.get('payPeriodStartDate').endOf('day')) : true;

			// Check that it is in the future if not allowed
			var isAllowed = allowPastEffectiveDate ? true : date.isAfter((0, _componentLibraryUtilsGlobals.moment)().endOf('day'));

			var isWithinRange = clusterDate ? date.isBetween(clusterDate.clone().subtract(30, 'day'), clusterDate.clone().add(30, 'day')) : true;

			return isAfterStartDate && isAllowed && isWithinRange;
		},

		weekendShift: (function (key, value) {
			if (arguments.length > 1) {
				this.setProperties({
					holidayShift: value,
					saturdayShift: value,
					sundayShift: value
				});
			}
			return this.get('holidayShift');
		}).property('holidayShift', 'saturdayShift', 'sundayShift'),

		// Check if date is a no-bank-service day
		isBankDay: function isBankDay(date) {
			return date.day() === 0 || date.day() === 6 || ~this.get('bankHolidays').indexOf(date.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT));
		},

		getPayDayOfMonth: function getPayDayOfMonth(date, payDayOfMonth) {
			var endOfMonth = date.clone().endOf('month');
			date = date.clone().date(payDayOfMonth);
			return endOfMonth.isBefore(date, 'month') ? endOfMonth : date;
		},

		// Shift `checkDate` by `amount` in `shift` direction
		shiftDate: function shiftDate(shift, checkDate, amount) {
			amount = amount || 0;
			var shiftedDate = checkDate;
			var shiftNum = shift === 'BEFORE' ? -1 : 1;

			// find first business day before or after checkDate
			while (this.isBankDay(shiftedDate)) {
				shiftedDate = shiftedDate.add(shiftNum, 'day');
			}
			for (var i = 0; i < amount; ++i) {
				// find first business day before or after checkDate
				shiftedDate = shiftedDate.add(shiftNum, 'day');
				while (this.isBankDay(shiftedDate)) {
					shiftedDate = shiftedDate.add(shiftNum, 'day');
				}
			}
			return shiftedDate;
		},

		getCurrentPayPeriods: function getCurrentPayPeriods(startDate, applyArrears) {
			var today = startDate.clone().subtract(2, 'months');
			var periods = this.calculateUnshiftedAnchorCheckDateOptions(today, 10);
			if (this.get('isBiWeekly')) {
				var payDayOfWeek = this.get('payDayOfWeek') == SUNDAY ? 0 : this.get('payDayOfWeek') + 1;
				startDate = applyArrears ? startDate.clone().weekday(payDayOfWeek) : startDate;
				var startPeriod = periods.findBy('label', startDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT));
				var startIndex = periods.indexOf(startPeriod);
				var startIndexParity = startIndex % 2;
				return periods.filter(function (period, index) {
					return index % 2 === startIndexParity;
				});
			}
			return periods;
		},

		calculateUnshiftedAnchorCheckDateWeeklyOptions: function calculateUnshiftedAnchorCheckDateWeeklyOptions(today, numWeeks, payDayOfWeek, numMonths) {
			if (payDayOfWeek == null) {
				return [];
			}
			var options = [];
			var numPeriodsInMonth = 52 / 12 / numWeeks;
			var numOptions = numPeriodsInMonth * numMonths;
			// we have to add 1 to payDayOfWeek because moment starts from 1-7
			// and our payDayOfWeek starts from 0-6
			var currentDate = today.clone().day((payDayOfWeek + 1) % 7);
			while (numOptions > 0) {
				// only add day if it is after today
				if (!currentDate.isBefore(today, 'day')) {
					options.pushObject({
						value: currentDate.clone(),
						label: currentDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT)
					});
					numOptions--;
				}
				currentDate = currentDate.add(numWeeks, 'week');
			}
			return options;
		},

		calculateUnshiftedAnchorCheckDateMonthlyOptions: function calculateUnshiftedAnchorCheckDateMonthlyOptions(today, payDayOfMonth, secondPayDayOfMonth, isSemiMonthly, numMonths) {
			if (payDayOfMonth == null) {
				return [];
			}
			if (isSemiMonthly && secondPayDayOfMonth == null) {
				return [];
			}
			var options = [];
			var numPeriodsInMonth = isSemiMonthly ? 2 : 1;
			var numOptions = numPeriodsInMonth * numMonths;
			var currentDate = this.getPayDayOfMonth(today.clone(), payDayOfMonth);
			while (numOptions > 0) {
				// only add day if it is after today
				if (!currentDate.isBefore(today, 'day')) {
					options.pushObject({
						value: currentDate.clone(),
						label: currentDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT)
					});
					numOptions--;
				}

				if (isSemiMonthly) {
					currentDate = this.getPayDayOfMonth(currentDate.clone(), secondPayDayOfMonth);
					if (!currentDate.isBefore(today, 'day') && numOptions > 0) {
						options.pushObject({
							value: currentDate.clone(),
							label: currentDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT)
						});
						numOptions--;
					}
				}
				currentDate = this.getPayDayOfMonth(currentDate.clone().add(1, 'month'), payDayOfMonth);
			}
			return options;
		},

		calculateUnshiftedAnchorCheckDateOptions: function calculateUnshiftedAnchorCheckDateOptions(today, numMonthsOfOptions) {
			numMonthsOfOptions = numMonthsOfOptions || 8;
			var payDayOfWeek = this.get('payDayOfWeek');
			var payDayOfMonth = this.get('payDayOfMonth');
			var secondPayDayOfMonth = this.get('secondPayDayOfMonth');
			if (this.get('isWeekly') || this.get('isBiWeekly')) {
				return this.calculateUnshiftedAnchorCheckDateWeeklyOptions(today, 1, payDayOfWeek, numMonthsOfOptions);
			} else if (this.get('isSemiMonthly')) {
				return this.calculateUnshiftedAnchorCheckDateMonthlyOptions(today, payDayOfMonth, secondPayDayOfMonth, true, numMonthsOfOptions);
			} else if (this.get('isMonthly')) {
				return this.calculateUnshiftedAnchorCheckDateMonthlyOptions(today, payDayOfMonth, secondPayDayOfMonth, false, numMonthsOfOptions);
			}
			return [];
		},

		checkDates: (function () {
			return this.get('activePayPeriods').map(function (range) {
				return _ember['default'].get(range, 'checkDate');
			});
		}).property('activePayPeriods.[]', 'activePayPeriods.@each.checkDate'),

		_getPayPeriods: function _getPayPeriods(startDate) {
			var _this = this;

			var arrearsDays = this.get('arrearsDays');
			var blockPeriod = this.get('blockPeriod');
			var weekendShift = this.get('weekendShift');

			if (!(!_ember['default'].isEmpty(arrearsDays) && startDate && weekendShift)) {
				return [];
			}

			var unshiftedPayDateOptions = this.getCurrentPayPeriods(startDate, true);
			var previews = [];
			unshiftedPayDateOptions.forEach(function (unshiftedDateOption, index) {
				var unshiftedDate = _ember['default'].get(unshiftedDateOption, 'value');
				if (unshiftedDate.isBefore(startDate)) {
					return;
				}
				var previousUnshiftedDate = _ember['default'].get(unshiftedPayDateOptions[index - 1], 'value');

				var payPeriodEndDate = unshiftedDate.clone().subtract(arrearsDays, 'day');
				var payPeriodStartDate = previousUnshiftedDate.clone().subtract(arrearsDays, 'day').add(1, 'day');
				var checkDate = weekendShift === 'NONE' ? unshiftedDate : _this.shiftDate(weekendShift, unshiftedDate.clone());
				var approvalDeadline = _this.shiftDate('BEFORE', checkDate.clone(), blockPeriod);
				previews.pushObject({
					startDate: payPeriodStartDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
					endDate: payPeriodEndDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
					checkDate: checkDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
					originalCheckDate: unshiftedDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
					approvalDeadline: approvalDeadline.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT)
				});
			});
			return previews;
		},
		// show Active Pay Period
		activePayPeriods: (function () {
			var today = (0, _componentLibraryUtilsGlobals.moment)();
			var checkDate = this.get('unshiftedAnchorCheckDate') || (0, _componentLibraryUtilsGlobals.moment)();
			var startDate = null;
			if (this.get('isBiWeekly') && checkDate.isBefore(today)) {
				// getting reminder to have perfect biweekly sequence
				var biweeklyOffset = today.diff(checkDate, 'days') % 14;
				startDate = today.clone().startOf('day').subtract(biweeklyOffset, 'days');
			} else {
				startDate = _componentLibraryUtilsGlobals.moment.max(today, checkDate);
			}
			return this._getPayPeriods(startDate);
		}).property('isWeekly', 'isBiWeekly', 'isSemiMonthly', 'isMonthly', 'arrearsDays', 'blockPeriod', 'unshiftedAnchorCheckDate', 'weekendShift', 'payFrequency', 'payDayOfWeek', 'payDayOfMonth', 'secondPayDayOfMonth', 'payPeriodAnchorEndDate'),

		previewPayPeriods: (function () {
			return this._getPayPeriods(this.get('unshiftedAnchorCheckDate'));
		}).property('isWeekly', 'isBiWeekly', 'isSemiMonthly', 'isMonthly', 'arrearsDays', 'blockPeriod', 'unshiftedAnchorCheckDate', 'weekendShift', 'payFrequency', 'payDayOfWeek', 'payDayOfMonth', 'secondPayDayOfMonth', 'payPeriodAnchorEndDate')
	});
});