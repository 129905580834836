define('component-library/filters/multi-select-filter-spec-client', ['exports', 'ember', 'component-library/filters/multi-select-filter-spec'], function (exports, _ember, _componentLibraryFiltersMultiSelectFilterSpec) {
	'use strict';

	var _extends = Object.assign || function (target) {
		for (var i = 1; i < arguments.length; i++) {
			var source = arguments[i];for (var key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					target[key] = source[key];
				}
			}
		}return target;
	};

	var DEFAULT_NUM_VISIBLE_OPTIONS = 12;

	/*******************************************************************************
 * MultiSelectFilterSpecClient
 * ---
 *
 * Extends MultiSelectFilterSpec. At its core, we use two things: options and
 * selections. Options are the options for the select values of the filter,
 * while selections are the checked selections (a subset of the options).
 *
 * Options can be either string values or object values, and the filters work on
 * either object or string values.
 *
 * Most of the magic around these filters happens in the MultiSelectFilter file,
 * where the options are defined dynamically with some hocus pocus that only Ed
 * understands. Tread into that territory with care!
 *
 * Because of the powerful hack we have with options, everything in the filters
 * is entirely observer driven-- be careful.
 *
 * This extension zips options and selections together to render them in an
 * alternative UI.  Filtering here is strictly front-end, which is okay for most
 * of our client-app.  Filters HAVE to be back-end only in the tasks page, which
 *
 *
 *******************************************************************************/

	exports['default'] = _componentLibraryFiltersMultiSelectFilterSpec['default'].extend({
		popoverClass: null, // override basic multi select filter
		showAlways: true,
		isActive: true,

		isMultiSelectFilter: true,
		showCountInLabel: false,
		searchValue: "",
		nullLabel: "No Value", // TODO: refactor this to be part of the option creation itself like labelFunction

		// this is optional, to show a zmdi icon next to the option
		optionNameToZmdiIconClassMap: {},

		searchTerm: (function () {
			return this.get("searchValue").toLowerCase();
		}).property('searchValue'),
		visibleOptionsLimit: (function () {
			return DEFAULT_NUM_VISIBLE_OPTIONS;
		}).property(),
		searchPlaceholder: (function () {
			var name = this.get('name');
			return name ? 'Search for more ' + this.get('name') + 's...' : 'Search for more options...';
		}).property('name'),

		showExpandToggle: (function () {
			return this.get('optionSelectionsZip.length') > this.get("visibleOptionsLimit");
		}).property('options.[]'),

		jsClassName: _ember['default'].computed('name', function () {
			return 'js-filter-' + this.get('name').dasherize();
		}),

		showAll: false,

		selectAll: function selectAll() {
			var selections = _ember['default'].A();
			var options = this.get("options");
			if (this.get("stringModel")) {
				selections = options.slice();
			} else {
				selections = options.map(function (opt) {
					return opt.get("model");
				});
			}

			this.set("selections", selections);

			if (this.get("showSearchInput")) {
				this.set("searchValue", "");
			}
		},

		singleSelection: function singleSelection(optionSelectionZip) {
			var _this = this;

			var newSelections = _ember['default'].A();
			if (this.get("stringModel")) {
				newSelections.push(optionSelectionZip.name);
			} else {
				// TODO[JH]: recommendations to refactor this welcome
				this.get("options").slice().forEach(function (option) {
					if (_ember['default'].get(option, _this.get('optionValuePath')) === optionSelectionZip.value) {
						newSelections.push(optionSelectionZip.value);
					}
				});
			}
			this.set("selections", newSelections);
		},

		toggleSelection: function toggleSelection(optionSelectionZip) {
			var selections = this.get("selections").slice();
			if (this.get("stringModel")) {
				if (selections.contains(optionSelectionZip.name)) {
					selections.removeObject(optionSelectionZip.name);
				} else {
					selections.pushObject(optionSelectionZip.name);
				}
				this.set("selections", selections);
			} else {
				if (selections.contains(optionSelectionZip.value)) {
					this.set('selections', selections.filter(function (selection) {
						if (selection === optionSelectionZip.value) {
							return !optionSelectionZip.isSelected;
						} else {
							return true;
						}
					}));
				} else {
					selections.pushObject(optionSelectionZip.value);
					this.set("selections", selections);
				}
			}
		},

		optionSelectionsZip: (function () {
			var _this2 = this;

			var options = this.get('options');
			var selections = this.get('selections').slice();
			var optionNameToZmdiIconClassMap = this.get('optionNameToZmdiIconClassMap');

			// TODO[JH]: recommendations welcome for cleaning up this code
			if (this.get("stringModel")) {
				return options.map(function (option) {
					var zmdiIconClass = optionNameToZmdiIconClassMap[option];
					return _ember['default'].Object.create({
						'name': option,
						'isSelected': selections.indexOf(option) > -1,
						'zmdiIconClass': zmdiIconClass
					});
				});
			} else {
				return options.map(function (option) {
					var labelVal = option.get("label");
					var value = _ember['default'].get(option, _this2.get('optionValuePath'));
					var zmdiIconClass = optionNameToZmdiIconClassMap[labelVal];
					var filterOnQueryParam = _this2.get('filterOnQueryParam');

					var isSelected = selections.contains(value);
					if (filterOnQueryParam) {
						isSelected = isSelected && filterOnQueryParam === labelVal;
					}

					// reset as soon as we consume this because the employee directory can also be accessed on its own
					_this2.set('filterOnQueryParam', null);

					return _ember['default'].Object.create({
						'name': labelVal,
						'isSelected': isSelected,
						'value': value,
						'zmdiIconClass': zmdiIconClass
					});
				});
			}
		}).property('options.[]', 'selections.[]'),

		visibleOptionSelectionsZip: _ember['default'].computed('optionsSelectionsZip.[]', 'optionSelectionsZip.@each.isSelected', 'optionSelectionsZip.@each.name', 'visibleOptionsLimit', function () {
			var options = this.get("optionSelectionsZip").slice(0, this.get("visibleOptionsLimit"));
			if (options.length <= 1) {
				return options;
			}
			options = options.map(function (item) {
				return _extends({
					jsClassName: _ember['default'].typeOf(item.name) == 'string' && item.name.replace(' ', '')
				}, item);
			});
			options.forEach(function (item, index) {
				if (_ember['default'].typeOf(item.name) == 'object' || _ember['default'].typeOf(item.name) == 'string' && item.name == 'N/A') {
					var nullOption = item;
					options[index] = options[0];
					options[0] = nullOption;
					return options;
				}
			});
			return options;
		}),

		remainingOptionSelectionsZip: _ember['default'].computed('optionsSelectionsZip.[]', 'optionSelectionsZip.@each.isSelected', 'optionSelectionsZip.@each.name', 'visibleOptionsLimit', function () {
			return this.get("optionSelectionsZip").slice(this.get("visibleOptionsLimit"));
		})
	});
});