define('component-library/modals/onboarding/confirmation-modal', ['exports', 'ember', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryComponentsPromiseModal) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'modals/onboarding/confirmation-modal',
		modalDialogClass: 'z-confirmationModal js-glue-onboarding-confirmationModal',
		showCancelButton: true,
		showConfirmButton: true,
		isLoading: false,
		title: '',
		message: '',
		confirmButtonClass: 'btn--primary js-glue-onboarding-confirmationModal-confirm',
		confirmButtonDisplayText: 'Confirm',
		cancelButtonDisplayText: 'Cancel',
		onConfirm: _ember['default'].K,
		parameters: null,
		leftAlign: false,

		actions: {
			apply: function apply() {
				var _this = this;

				this.set('isLoading', true);
				var promise = this.onConfirm(this.get('parameters'));
				if (promise.then) {
					promise.then(function (data) {
						_this.send('confirm', data);
					})['catch'](function (error) {
						_this.send('cancel', error);
					});
				} else {
					this.send('confirm');
				}
			}
		}
	});
});