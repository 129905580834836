define('ember-table/views/column-sortable-indicator', ['exports', 'ember', 'ember-table/mixins/style-bindings', 'ember-table/mixins/register-table-component'], function (exports, _ember, _emberTableMixinsStyleBindings, _emberTableMixinsRegisterTableComponent) {
  'use strict';

  exports['default'] = _ember['default'].View.extend(_emberTableMixinsStyleBindings['default'], _emberTableMixinsRegisterTableComponent['default'], {
    classNames: 'et-column-sortable-indicator',
    classNameBindings: 'tableComponent._isShowingSortableIndicator:active',
    styleBindings: ['left', 'height'],
    left: _ember['default'].computed.alias('tableComponent._sortableIndicatorLeft'),
    height: _ember['default'].computed.alias('tableComponent._height')
  });
});