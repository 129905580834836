define('component-library/utils/angular-app/find-js-assets', ['exports'], function (exports) {
	'use strict';

	exports['default'] = findJsAssets;

	var OMIT_ASSETS = ['babel-polyfill'];
	function findJsAssets(htmlContent, appName) {
		var re = new RegExp('(rel="stylesheet")* (href|src)="(.*?)(.*?).([^.]*).(js|css)"(.*?)></(script|link|noscript)>', 'g');

		var assets = [];
		var match = re.exec(htmlContent);

		while (match) {
			var assetOrigin = window.location.origin;
			var assetName = match[4];
			var assetHash = match[5];

			if (OMIT_ASSETS.indexOf(assetName) === -1) {
				assets.push({ assetOrigin: assetOrigin, assetName: assetName, assetHash: assetHash });
			}
			match = re.exec(htmlContent);
		}

		return assets;
	}
});