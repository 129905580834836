define('ember-cli-bundle-loader/utils/-resource-helper', ['exports', 'ember', 'ember-get-config'], function (exports, _ember, _emberGetConfig) {
  'use strict';

  var get = _ember['default'].get;
  var $ = _ember['default'].$;

  exports['default'] = {
    loadJavascript: function loadJavascript(url) {
      var async = get(_emberGetConfig['default'], 'ember-cli-bundle-loader.asyncScriptExecution');
      if (async === undefined) {
        // For backwards compatibility we use async true
        async = true;
      }

      var scriptElement = $("<script>").prop({ src: url, async: async });
      var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        scriptElement.one('load', function () {
          return _ember['default'].run(null, resolve);
        });
        scriptElement.one('error', function (evt) {
          return _ember['default'].run(null, reject, evt);
        });
      })['catch'](function () {
        document.head.removeChild(scriptElement[0]);
        return _ember['default'].RSVP.reject();
      });
      document.head.appendChild(scriptElement[0]);

      return promise;
    },
    isJavascriptLoaded: function isJavascriptLoaded(url) {
      return !!document.querySelector('script[src="' + url + '"]');
    },
    loadStylesheet: function loadStylesheet(url) {
      var linkElement = $('<link rel="stylesheet" href="' + url + '" type="text/css"/>');
      var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        linkElement.one('load', function () {
          return _ember['default'].run(null, resolve);
        });
        linkElement.one('error', function (evt) {
          return _ember['default'].run(null, reject, evt);
        });
      })['catch'](function () {
        document.head.removeChild(linkElement[0]);
        return _ember['default'].RSVP.reject();
      });
      document.head.appendChild(linkElement[0]);

      return promise;
    },
    isStylesheetLoaded: function isStylesheetLoaded(url) {
      return !!document.querySelector('link[href="' + url + '"]');
    }
  };
});