define('component-library/utils/pretty-percent-or-currency', ['exports', 'component-library/utils/pretty-currency', 'component-library/utils/pretty-percent'], function (exports, _componentLibraryUtilsPrettyCurrency, _componentLibraryUtilsPrettyPercent) {
	'use strict';

	exports['default'] = function (number, precision, decimalPoint) {
		var value = +number;

		if (value < 0 || value > 1.0) {
			return (0, _componentLibraryUtilsPrettyCurrency['default'])(value, false, precision);
		} else if (value === 0) {
			// TODO(Andrew) should 0 be presented as 0%, $0, or 0? Chat with Airat
			return value;
		}
		return (0, _componentLibraryUtilsPrettyPercent['default'])(number, decimalPoint);
	};
});