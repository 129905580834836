define('component-library/utils/react-app/send-message-to-react', ['exports', 'lodash'], function (exports, _lodash) {
	'use strict';

	exports['default'] = sendMessageToReact;

	function sendMessageToReact(appName, type, payload) {
		if (!_lodash['default'].get(window, 'embeddedReactApps.' + appName + '.isAppStarted')) {
			var queuedMessages = _lodash['default'].get(window, 'embeddedReactApps.' + appName + '.queuedMessages') || [];
			queuedMessages.push({ type: type, payload: payload });
			_lodash['default'].set(window, 'embeddedReactApps.' + appName + '.queuedMessages', queuedMessages);
		} else {
			try {
				/* eslint-disable no-lazy-globals */
				var handlers = window.embeddedReactApps[appName].registeredReactMessages[type];
				/* eslint-enable no-lazy-globals */

				if (!handlers) {
					console.error('No React action of type ' + type + ' was registered on app: ' + appName);
				} else {
					handlers.forEach(function (handler) {
						return handler(payload);
					});
				}
			} catch (e) {
				console.error('Tried to send action to react app: ' + appName + ' that is not loaded.');
			}
		}
	}
});