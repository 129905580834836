define('component-library/utils/payroll/payroll-utils', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	// keep these alphabetized
	var payrollProviders = [{ value: 'AW', label: 'AccountantsWorld' }, { value: 'AR', label: 'ADP Portal' }, { value: 'AD', label: 'ADP RUN' }, { value: 'WF', label: 'ADP WorkForce Now' }, { value: 'AT', label: 'Advantage' }, { value: 'CA', label: 'CASA' }, { value: 'CE', label: 'CERIDIAN' }, { value: 'CP', label: 'CheckPoint' }, { value: 'CO', label: 'Corporate' }, { value: 'ES', label: 'eSmartPayroll' }, { value: 'FR', label: 'Fidelity' }, { value: 'GL', label: 'Greatland' }, { value: 'GH', label: 'Greenshades' }, { value: 'GR', label: 'GTM' }, { value: 'ZN', label: 'Gusto' }, { value: 'IN', label: 'Intuit' }, { value: 'MP', label: 'ManagePayroll' }, { value: 'MT', label: 'MasterTax' }, { value: 'NC', label: 'Netchex' }, { value: 'OT', label: 'Other' }, { value: 'ON', label: 'OnPay' }, { value: 'PA', label: 'Padgett' }, { value: 'PX', label: 'Paychex' }, { value: 'PC', label: 'Paycom' }, { value: 'PO', label: 'Paycor' }, { value: 'PL', label: 'Paylocity' }, { value: 'PM', label: 'PayMaster' }, { value: 'PP', label: 'Payroll People' }, { value: 'PR', label: 'PrimePay' }, { value: 'PG', label: 'Paragon' }, { value: 'QB', label: 'QuickBooks Online' }, { value: 'SP', label: 'SurePayroll' }, { value: 'TP', label: 'Time+Plus' }, { value: 'TR', label: 'Thomson Reuters' }, { value: 'UL', label: 'Ultimate' }, { value: 'WG', label: 'Wells Fargo' }, { value: 'YP', label: 'Zenefits Payroll' }];

	exports.payrollProviders = payrollProviders;

	// keep these alphabetized
	var PAYROLL_PROVIDERS_COMMON = [{ value: 'AD', label: 'ADP RUN' }, { value: 'WF', label: 'ADP WorkForce Now' }, { value: 'BA', label: 'BambooHR' }, { value: 'CE', label: 'CERIDIAN' }, { value: 'GU', label: 'Gusto' }, { value: 'IN', label: 'Intuit' }, { value: 'MP', label: 'ManagePayroll' }, { value: 'NA', label: 'Namely' }, { value: 'PX', label: 'Paychex Flex' }, { value: 'PC', label: 'Paycom' }, { value: 'PO', label: 'Paycor' }, { value: 'PL', label: 'Paylocity' }, { value: 'PM', label: 'PayMaster' }, { value: 'PG', label: 'Paragon' }, { value: 'QB', label: 'QuickBooks Online' }, { value: 'UL', label: 'Ultimate' }, { value: 'OT', label: 'Other' }];

	exports.PAYROLL_PROVIDERS_COMMON = PAYROLL_PROVIDERS_COMMON;

	var DAY_OF_WEEK_OPTIONS = [{ value: 0, label: 'Monday' }, { value: 1, label: 'Tuesday' }, { value: 2, label: 'Wednesday' }, { value: 3, label: 'Thursday' }, { value: 4, label: 'Friday' }];

	exports.DAY_OF_WEEK_OPTIONS = DAY_OF_WEEK_OPTIONS;

	var DAY_OF_MONTH_OPTIONS = [];
	exports.DAY_OF_MONTH_OPTIONS = DAY_OF_MONTH_OPTIONS;

	for (var i = 1; i <= 30; ++i) {
		DAY_OF_MONTH_OPTIONS.pushObject({ value: i, label: String(i) });
	}
	DAY_OF_MONTH_OPTIONS.pushObject({ value: 31, label: 'Last Day of Month' });

	var getPayrollProviderStringFromCode = function getPayrollProviderStringFromCode(code, defaultPPStr) {
		var providerStr;

		if (code === 'OT' && !_ember['default'].isEmpty(defaultPPStr)) {
			return defaultPPStr;
		}

		var matchingPayroll = payrollProviders.find(function (payroll) {
			return payroll.value === code;
		});

		if (matchingPayroll) {
			providerStr = matchingPayroll.label;
		}

		return providerStr || defaultPPStr;
	};

	exports.getPayrollProviderStringFromCode = getPayrollProviderStringFromCode;

	var isAfterApprovalDeadlineDateTime = function isAfterApprovalDeadlineDateTime(approvalDeadlineDateTime) {
		return (0, _componentLibraryUtilsGlobals.moment)().isAfter(approvalDeadlineDateTime);
	};
	exports.isAfterApprovalDeadlineDateTime = isAfterApprovalDeadlineDateTime;
});