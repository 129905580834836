define('component-library/components/number-range-filter', ['exports', 'ember', 'component-library/templates/components/number-range-filter', 'component-library/components/promise-popover'], function (exports, _ember, _componentLibraryTemplatesComponentsNumberRangeFilter, _componentLibraryComponentsPromisePopover) {
  'use strict';

  var valueAsStringToNumberOrNull = function valueAsStringToNumberOrNull(valueString, asFloat) {
    var fn = asFloat ? parseFloat : parseInt;
    var value = _ember['default'].typeOf(valueString) === 'string' ? fn(valueString) : undefined;
    return isNaN(value) ? null : value;
  };

  exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
    template: _componentLibraryTemplatesComponentsNumberRangeFilter['default'],
    hasArrow: true,
    placement: 'bottom',
    target: _ember['default'].computed.oneWay('model.target'),
    attachment: "top center",
    targetAttachment: "bottom center",

    filter: _ember['default'].computed.alias('content'),

    newMinValueStr: "",
    newMaxValueStr: "",
    initFilterValue: (function () {
      // TODO(ed): the component should take the value using filter.formatAs
      if (this.get('filter.minValue') !== null) {
        this.set('newMinValueStr', "" + this.get('filter.minValue'));
      }
      if (this.get('filter.maxValue') !== null) {
        this.set('newMaxValueStr', "" + this.get('filter.maxValue'));
      }
    }).on('init'),

    addOrApply: (function () {
      return this.get('filter.isActive') ? 'Apply' : 'Add';
    }).property('filter.isActive'),

    actions: {
      addOrApplyFilter: function addOrApplyFilter() {
        if (!this.get('filter.isActive')) {
          this.set('filter.isActive', true);
        }
        var asFloat = this.get('filter.formatAs') == 'float';
        var minVal = valueAsStringToNumberOrNull(this.get('newMinValueStr'), asFloat);
        var maxVal = valueAsStringToNumberOrNull(this.get('newMaxValueStr'), asFloat);
        this.set('filter.minValue', minVal);
        this.set('filter.maxValue', maxVal);
        this.send('close');
      }
    }
  });
});