define('component-library/components/pay-schedule', ['exports', 'ember', 'component-library/utils/payroll/payroll-utils', 'component-library/utils/globals', 'component-library/mixins/inbound-actions', 'component-library/mixins/pay-schedule-mixin', 'component-library/data-table/column', 'component-library/constants/cps'], function (exports, _ember, _componentLibraryUtilsPayrollPayrollUtils, _componentLibraryUtilsGlobals, _componentLibraryMixinsInboundActions, _componentLibraryMixinsPayScheduleMixin, _componentLibraryDataTableColumn, _componentLibraryConstantsCps) {
	'use strict';

	var DEFAULT_NUM_ROLLING_GATE_DAYS = 30;
	var PAY_DAY_OF_MONTH = {
		FIRST: 15,
		SECOND: 31
	};

	var MODAL_SIZE = {
		ADMIN: 'z-payScheduleConfiguration--admin',
		READ_ONLY: 'z-payScheduleConfiguration--readonly',
		DEFAULT: 'z-payScheduleConfiguration--wide'
	};

	// this is the pay-schedule modal content. It allows users to edit the pay schedule
	// and preview the pay periods in a table
	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsInboundActions['default'], _componentLibraryMixinsPayScheduleMixin['default'], {
		isStatic: false,
		isNotStatic: _ember['default'].computed.not('isStatic'),
		isReadOnly: false,
		isAdminView: false,

		cpsCopy: _ember['default'].computed(function () {
			var creationMethod = this.get('paySchedule.creationMethod');
			return (0, _componentLibraryConstantsCps.getLongTypeName)(creationMethod);
		}),

		modalSizeClass: _ember['default'].computed('isReadOnly', 'isAdminView', function () {
			if (this.get('isAdminView')) {
				return MODAL_SIZE.ADMIN;
			}
			if (this.get('isReadOnly')) {
				return MODAL_SIZE.READ_ONLY;
			}
			return MODAL_SIZE.DEFAULT;
		}),

		zPayrollEnums: undefined,
		blockPeriod: undefined,
		bankHolidays: undefined,
		paySchedule: undefined,
		initPaySchedule: false,
		blackOutDates: undefined,
		numRollingGateDays: 30,
		allowPastEffectiveDate: true,

		// TODO(Peter): remove this after prior payroll is done
		shiftCheckDateToNextQuarter: false,

		dayOfWeekOptions: _componentLibraryUtilsPayrollPayrollUtils.DAY_OF_WEEK_OPTIONS,
		dayOfMonthOption: _componentLibraryUtilsPayrollPayrollUtils.DAY_OF_MONTH_OPTIONS,
		payFrequencies: _ember['default'].computed.filterBy('zPayrollEnums', 'type', 'PayFrequency'),
		payScheduleShifts: _ember['default'].computed.filterBy('zPayrollEnums', 'type', 'PayScheduleShift'),
		// TODO(ed): Remove none
		validPayScheduleShifts: _ember['default'].computed.rejectByProperty('payScheduleShifts', 'value', 'NONE'),

		compType: null,
		isCreationMethodPYP: _ember['default'].computed.equal('paySchedule.creationMethod', 'PYP'),
		hasTA: undefined,
		showBothCompType: _ember['default'].computed.not('hasTA'),
		compensationTypeDisplayName: _ember['default'].computed.alias('paySchedule.displayName'),

		payScheduleName: null,
		name: _ember['default'].computed.alias('payScheduleName'),

		isCompTypeDisabled: false,
		isPayDays15AndLastDay: true,

		payFrequency: null,
		payDayOfWeek: null,
		payDayOfMonth: null,
		secondPayDayOfMonth: null,

		unshiftedAnchorCheckDate: null,
		// date pay period can start if specified
		payPeriodStartDate: (0, _componentLibraryUtilsGlobals.moment)().endOf('day'),

		// use to force CPS2 to be within 31 days of CPS1
		clusterDate: null,

		payPeriodAnchorEndDate: null,
		holidayShift: null,
		saturdayShift: null,
		sundayShift: null,

		needsSevenDaysArrears: (function () {
			return this.get('compType') != 'S' && this.get('isCreationMethodPYP') && this.get('hasTA');
		}).property('compType', 'isCreationMethodPYP', 'hasTA'),

		// This action is sent when the paySchedule changes have been applied.
		applied: undefined,

		arrearsDays: (function (key, value) {
			if (arguments.length == 1) {
				var end = this.get('unshiftedAnchorCheckDate');
				var start = this.get('payPeriodAnchorEndDate');
				if (start && end) {
					return end.diff(start, 'days', true);
				}
				return undefined;
			}
			return value;
		}).property('unshiftedAnchorCheckDate', 'payPeriodAnchorEndDate'),

		columns: _ember['default'].computed('isStatic', function () {
			var payPeriodColumn = [_componentLibraryDataTableColumn['default'].create({
				savedWidth: 270,
				headerCellName: 'Pay Period',
				contentPath: 'startDate',
				getCellContent: function getCellContent(row) {
					if (row.get('key')) {
						return row.get('key');
					}
					var startDate = (0, _componentLibraryUtilsGlobals.moment)(row.get('startDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
					var endDate = (0, _componentLibraryUtilsGlobals.moment)(row.get('endDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
					return (startDate.format('ddd, MM/DD/YYYY') + '&ndash;' + endDate.format('ddd, MM/DD/YYYY')).htmlSafe();
				},
				rollup: function rollup(grouping) {
					_ember['default'].set(grouping, 'rowStyle', 'z-paySchedulePreview-month');
					return grouping;
				}
			}), _componentLibraryDataTableColumn['default'].create({
				savedWidth: 170,
				headerCellName: 'Payday',
				contentPath: 'checkDate',
				getCellContent: function getCellContent(row) {
					if (row.get('key')) {
						return '';
					}
					var checkDate = (0, _componentLibraryUtilsGlobals.moment)(row.get('checkDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
					return checkDate.format('ddd, MM/DD/YYYY');
				},
				rollup: function rollup(grouping) {
					return grouping;
				}
			})];
			if (this.get('isAdminView')) {
				payPeriodColumn.push(_componentLibraryDataTableColumn['default'].create({
					savedWidth: 170,
					headerCellName: 'Original CheckDate',
					contentPath: 'originalCheckDate',
					getCellContent: function getCellContent(row) {
						if (row.get('key')) {
							return '';
						}
						var originalCheckDate = (0, _componentLibraryUtilsGlobals.moment)(row.get('originalCheckDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
						var checkDate = (0, _componentLibraryUtilsGlobals.moment)(row.get('checkDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
						if (!originalCheckDate.isSame(checkDate)) {
							return _ember['default'].String.htmlSafe('<span class="u-error">' + originalCheckDate.format('ddd, MM/DD/YYYY') + '</span>');
						}
						return checkDate.format('ddd, MM/DD/YYYY');
					},
					rollup: function rollup(grouping) {
						return grouping;
					}
				}));
			}
			if (this.get('isReadOnly')) {
				payPeriodColumn.push(_componentLibraryDataTableColumn['default'].create({
					savedWidth: 170,
					headerCellName: 'Approval Deadline',
					contentPath: 'approvalDeadline',
					getCellContent: function getCellContent(row) {
						if (row.get('key')) {
							return '';
						}
						var approvalDate = (0, _componentLibraryUtilsGlobals.moment)(row.get('approvalDeadline'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
						return approvalDate.format('ddd, MM/DD/YYYY');
					},
					rollup: function rollup(grouping) {
						return grouping;
					}
				}));
			}
			return payPeriodColumn;
		}),

		groupings: (function () {
			return [_componentLibraryDataTableColumn['default'].create({
				contentPath: 'startDate',
				getCellContent: function getCellContent(row) {
					var startDate = (0, _componentLibraryUtilsGlobals.moment)(_ember['default'].get(row, 'startDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
					return startDate.format('MMMM');
				}
			})];
		}).property(),

		///////////////////////////////////////////////////////////////////////////
		// UI Computeds/Observers
		///////////////////////////////////////////////////////////////////////////

		previewPayPeriodsLengthDidChange: (function () {
			if (this.get('previewPayPeriods.length') > 0) {
				_ember['default'].$(window).one(_ember['default'].$.support.transition.end, function () {
					_ember['default'].$(window).trigger('resize');
				});
			}
		}).observes('previewPayPeriods.length'),

		uiPayFrequency: (function (key, value) {
			if (arguments.length > 1 && this.get('payFrequency') !== value) {
				this.set('payFrequency', value);
				var state = {
					payDayOfWeek: null,
					payDayOfMonth: null,
					secondPayDayOfMonth: null,
					payPeriodAnchorEndDate: null,
					unshiftedAnchorCheckDateLabel: null,
					isPayDays15AndLastDay: true
				};
				if (this.get('isSemiMonthly')) {
					state.payDayOfMonth = PAY_DAY_OF_MONTH.FIRST;
					state.secondPayDayOfMonth = PAY_DAY_OF_MONTH.SECOND;
				}
				this.setProperties(state);
			}
			return this.get('payFrequency');
		}).property('payFrequency'),

		uiPayFrequencyDisplayName: _ember['default'].computed.alias('paySchedule.payFrequencyHuman'),

		isPayDays15AndLastDayDidChange: (function () {
			if (this.get('isPayDays15AndLastDay') && (this.get('payDayOfMonth') !== PAY_DAY_OF_MONTH.FIRST || this.get('secondPayDayOfMonth') !== PAY_DAY_OF_MONTH.SECOND)) {
				this.setProperties({
					payDayOfMonth: PAY_DAY_OF_MONTH.FIRST,
					secondPayDayOfMonth: PAY_DAY_OF_MONTH.SECOND,
					payPeriodAnchorEndDate: null,
					unshiftedAnchorCheckDateLabel: null
				});
			}
		}).observes('isPayDays15AndLastDay'),

		// This is to ensure that the user does not inadvertently choose an incorrect pay day
		compTypeDidChange: (function () {
			if (this.get('needsSevenDaysArrears')) {
				this.set('payPeriodAnchorEndDate', null);
			}
		}).observes('needsSevenDaysArrears'),

		// TODO(ed): This is really bad, we should use React
		uiPayDayOfWeek: (function (key, value) {
			if (arguments.length > 1 && this.get('payDayOfWeek') !== value) {
				this.setProperties({
					payDayOfWeek: value,
					weekendShift: null,
					unshiftedAnchorCheckDateLabel: null,
					payPeriodAnchorEndDate: null
				});
			}
			return this.get('payDayOfWeek');
		}).property('payDayOfWeek'),

		uiPayDayOfWeekDisplayName: (function () {
			var dayOfWeekObj = this.get('dayOfWeekOptions').findBy('value', this.get('paySchedule.payDayOfWeek'));
			return dayOfWeekObj ? dayOfWeekObj.label : null;
		}).property('paySchedule.payDayOfWeek', 'dayOfWeekOptions'),

		// TODO(ed): This is really bad, we should use React
		uiPayDayOfMonth: (function (key, value) {
			if (arguments.length > 1 && this.get('payDayOfMonth') !== value) {
				this.setProperties({
					payDayOfMonth: value,
					weekendShift: null,
					unshiftedAnchorCheckDateLabel: null,
					payPeriodAnchorEndDate: null
				});
			}
			return this.get('payDayOfMonth');
		}).property('payDayOfMonth'),

		uiPayDayOfMonthDisplayName: _ember['default'].computed.alias('payDayOfMonth'),

		// TODO(ed): This is really bad, we should use React
		uiSecondPayDayOfMonth: (function (key, value) {
			if (arguments.length > 1 && this.get('secondPayDayOfMonth') !== value) {
				this.setProperties({
					secondPayDayOfMonth: value,
					weekendShift: null,
					unshiftedAnchorCheckDateLabel: null,
					payPeriodAnchorEndDate: null
				});
			}
			return this.get('secondPayDayOfMonth');
		}).property('secondPayDayOfMonth'),

		uiSecondPayDayOfMonthDisplayName: _ember['default'].computed.alias('secondPayDayOfMonth'),

		uiWeekendShift: (function (key, value) {
			if (arguments.length > 1 && this.get('weekendShift') !== value) {
				this.setProperties({
					weekendShift: value,
					unshiftedAnchorCheckDateLabel: null,
					payPeriodAnchorEndDate: null
				});
			}
			return this.get('weekendShift');
		}).property('weekendShift'),

		uiWeekendShiftDisplayName: (function () {
			var weekendShift = this.get('weekendShift');
			return weekendShift ? weekendShift.toLowerCase().capitalize() : null;
		}).property('weekendShift'),

		// TODO(ed): This is really bad, we should use React
		unshiftedAnchorCheckDateLabel: (function (key, value) {
			if (arguments.length > 1 && (!this.get('unshiftedAnchorCheckDate') || this.get('unshiftedAnchorCheckDate').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT) !== value)) {
				this.set('payPeriodAnchorEndDate', null);
				this.set('unshiftedAnchorCheckDate', value && (0, _componentLibraryUtilsGlobals.moment)(value, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT));
			}
			return this.get('unshiftedAnchorCheckDate') && this.get('unshiftedAnchorCheckDate').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		}).property('unshiftedAnchorCheckDate'),

		uiFirstCheckDate: (function () {
			return this.get('paySchedule.unshiftedAnchorCheckDate');
		}).property('paySchedule.unshiftedAnchorCheckDate'),

		// TODO(ed): This is really bad, we should use React
		payPeriodAnchorEndDateLabel: (function (key, value) {
			if (arguments.length > 1 && (!this.get('payPeriodAnchorEndDate') || this.get('payPeriodAnchorEndDate').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT) !== value)) {
				this.set('payPeriodAnchorEndDate', value && (0, _componentLibraryUtilsGlobals.moment)(value, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT));
			}
			return this.get('payPeriodAnchorEndDate') && this.get('payPeriodAnchorEndDate').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		}).property('payPeriodAnchorEndDate'),

		uiFirstPayPeriodEndDate: (function () {
			var startDate = this.get('paySchedule.unshiftedAnchorCheckDate');
			return (0, _componentLibraryUtilsGlobals.moment)(startDate).subtract(this.get('arrearsDays'), 'day').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		}).property('paySchedule.unshiftedAnchorCheckDate', 'paySchedule.arrearsDays'),

		firstDayOfMonthOption: (function () {
			return this.get('dayOfMonthOption').slice(0, 17);
		}).property('dayOfMonthOption'),

		secondDayOfMonthOption: (function () {
			var dayOfMonthOption = this.get('dayOfMonthOption');
			var uiPayDayOfMonth = this.get('uiPayDayOfMonth');
			if (_ember['default'].isEmpty(uiPayDayOfMonth)) {
				return dayOfMonthOption;
			}
			return dayOfMonthOption.filter(function (day) {
				return day.value >= uiPayDayOfMonth + 13 && day.value <= uiPayDayOfMonth + 17;
			});
		}).property('dayOfMonthOption', 'uiPayDayOfMonth'),

		///////////////////////////////////////////////////////////////////////////
		// Weekend Shift
		///////////////////////////////////////////////////////////////////////////

		shouldShowWeekendShift: (function () {
			if (this.get('isWeekly') || this.get('isBiWeekly')) {
				return !_ember['default'].isEmpty(this.get('payDayOfWeek'));
			} else if (this.get('isSemiMonthly')) {
				return !_ember['default'].isEmpty(this.get('payDayOfMonth')) && !_ember['default'].isEmpty(this.get('secondPayDayOfMonth'));
			} else if (this.get('isMonthly')) {
				return !_ember['default'].isEmpty(this.get('payDayOfMonth'));
			}
			return false;
		}).property('isWeekly', 'isBiWeekly', 'isSemiMonthly', 'isMonthly', 'payDayOfWeek', 'payDayOfMonth', 'secondPayDayOfMonth'),

		///////////////////////////////////////////////////////////////////////////
		// unshiftedAnchorCheckDateOptions
		///////////////////////////////////////////////////////////////////////////

		// TODO(ed): refactor this later
		applyRollingGateFilter: function applyRollingGateFilter(unshiftedAnchorCheckDates, weekendShift) {
			var _this = this;

			var numRollingGateDays = this.get('numRollingGateDays');
			var rollGateStartDate;

			if (_ember['default'].isNone(numRollingGateDays)) {
				numRollingGateDays = DEFAULT_NUM_ROLLING_GATE_DAYS;
			}

			rollGateStartDate = (0, _componentLibraryUtilsGlobals.moment)().add(numRollingGateDays, 'days');

			return unshiftedAnchorCheckDates.filter(function (unshiftedDate) {
				var checkDate = weekendShift === 'NONE' ? unshiftedDate.value : _this.shiftDate(weekendShift, unshiftedDate.value.clone());
				var isValidRollGate = checkDate.isAfter(rollGateStartDate, 'day');
				return isValidRollGate;
			});
		},

		_applyPypCustomFilter: function _applyPypCustomFilter(unshiftedAnchorCheckDates, payPeriodStartDate, offset) {
			var _this2 = this;

			return unshiftedAnchorCheckDates.filter(function (unshiftedDate, index) {
				var startDate = unshiftedAnchorCheckDates[index - offset];

				if (!startDate) {
					return false;
				}
				startDate = startDate.value.clone();

				var weekendShift = _this2.get('weekendShift');
				var checkDateStartDate = _this2.get('checkDateStartDate');
				var shiftedDate = _this2.shiftDate(weekendShift, unshiftedDate.value.clone());

				// If TA and PYP for hourly CPS
				if (_this2.get('needsSevenDaysArrears')) {
					startDate = startDate.clone().subtract(7, 'days');
				}

				if (_this2.get('blackOutDates.length')) {
					if (~_this2.get('blackOutDates').indexOf(shiftedDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT))) {
						return false;
					}
				}

				if (checkDateStartDate) {
					if (shiftedDate.isBefore(checkDateStartDate, 'day')) {
						return false;
					}
				}

				return _this2._validate(startDate);
			});
		},

		unshiftedAnchorCheckDateOptions: (function () {
			var weekendShift = this.get('weekendShift');
			if (_ember['default'].isEmpty(weekendShift)) {
				return [];
			}

			// use for filtering. Passed value, otherwise today.
			var payPeriodStartDate = this.get('payPeriodStartDate') || (0, _componentLibraryUtilsGlobals.moment)().endOf('day');
			var offset = this.get('isBiWeekly') ? 2 : 1;
			var unshiftedAnchorCheckDates = this.calculateUnshiftedAnchorCheckDateOptions(payPeriodStartDate.clone().subtract(2, 'months'));

			// If no arrears day then do not apply pyp validations
			if (this.get('numRollingGateDays') == -1) {
				return this.applyRollingGateFilter(unshiftedAnchorCheckDates, weekendShift);
			}

			// Filter to return check dates with effective date after payPeriodStartDate.
			var result = this._applyPypCustomFilter(unshiftedAnchorCheckDates, payPeriodStartDate, offset);
			return this.applyRollingGateFilter(result, weekendShift);
		}).property('isWeekly', 'isBiWeekly', 'isSemiMonthly', 'isMonthly', 'payFrequency', 'payDayOfWeek', 'payDayOfMonth', 'secondPayDayOfMonth', 'weekendShift', 'shiftCheckDateToNextQuarter'),

		///////////////////////////////////////////////////////////////////////////
		// payPeriodAnchorEndDateOptions
		///////////////////////////////////////////////////////////////////////////

		calculatepayPeriodAnchorEndDateOptions: function calculatepayPeriodAnchorEndDateOptions(start, end, previousCheckDate, allowPastEffectiveDate) {
			var options = [];
			var currentCheck = start.clone();
			// we want to do isBefore or equal, but there is no function for it... so !isAfter
			for (var endDate = start.clone().endOf('day'); !endDate.isAfter(end, 'day'); endDate = endDate.clone().add(1, 'day')) {
				var startDate = this.calculateUIStartDate(endDate, previousCheckDate, currentCheck);
				if (this._validate(startDate)) {
					options.pushObject({
						value: endDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
						label: startDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT) + ' - ' + endDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT)
					});
				}
			}
			return options.reverse();
		},

		// Computing start day for front end filtering
		calculateUIStartDate: function calculateUIStartDate(date, previousCheckDate, currentCheck) {
			if (this.get('isMonthly') || this.get('isSemiMonthly')) {
				var checkDate = this.get('unshiftedAnchorCheckDate');
				var arrears = date.clone().startOf('day').diff(checkDate, 'days');
				return currentCheck.clone().add('days', 1).add('days', arrears);
			} else {
				return previousCheckDate.add(1, 'day');
			}
		},

		payPeriodAnchorEndDateOptions: (function () {
			var endDate = this.get('unshiftedAnchorCheckDate');
			if (!endDate) {
				return [];
			}

			var options = this.getCurrentPayPeriods(endDate);
			var endDateDate = endDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
			var selectedAnchorDate = options.findBy('label', endDateDate);
			var allowPastEffectiveDate = this.get('allowPastEffectiveDate');

			var index = options.indexOf(selectedAnchorDate);
			if (index < 0) {
				return [];
			}

			// if the pay Schedule covers hourly ee, needs 7 day arrears
			if (this.get('needsSevenDaysArrears')) {
				endDate = endDate.clone().subtract(7, 'days');
			}

			var offset = _componentLibraryConstantsCps.FREQUENCY_OFFSET[this.get('payFrequency')];
			var startDate = _ember['default'].get(options[index - offset], 'value');
			var previousCheckDate = _ember['default'].get(options[index - offset - 1], 'value').clone();
			return this.calculatepayPeriodAnchorEndDateOptions(startDate, endDate, previousCheckDate, allowPastEffectiveDate);
		}).property('isWeekly', 'isBiWeekly', 'isSemiMonthly', 'isMonthly', 'payFrequency', 'unshiftedAnchorCheckDate', 'payDayOfMonth', 'secondPayDayOfMonth', 'needsSevenDaysArrears'),

		resetErrors: (function () {
			this.setProperties({
				uiPayFrequencyError: null,
				uiPayDayOfWeekError: null,
				uiPayDayOfMonthError: null,
				uiSecondPayDayOfMonthError: null,
				unshiftedAnchorCheckDateLabelError: null,
				payPeriodAnchorEndDateLabelError: null,
				weekendShiftError: '',
				compTypeError: null
			});
		}).observes('uiPayFrequency', 'uiPayDayOfWeek', 'uiPayDayOfMonth', 'uiSecondPayDayOfMonth', 'unshiftedAnchorCheckDateLabel', 'payPeriodAnchorEndDateLabel', 'weekendShift', 'compType'),

		setErrors: function setErrors() {
			var _this3 = this;

			var properties = this.getProperties(['uiPayFrequency', 'uiPayDayOfWeek', 'uiPayDayOfMonth', 'uiSecondPayDayOfMonth', 'unshiftedAnchorCheckDateLabel', 'payPeriodAnchorEndDateLabel', 'weekendShift', 'compType']);
			Object.keys(properties).forEach(function (key) {
				if (_ember['default'].isEmpty(properties[key])) {
					_this3.set(key + 'Error', 'The required field is missing.');
				}
			});
		},

		///////////////////////////////////////////////////////////////////////////
		// Serialize / Deserialize
		///////////////////////////////////////////////////////////////////////////

		loadFromPaySchedule: (function () {
			var props = this.get('paySchedule').getProperties(['payFrequency', 'payDayOfWeek', 'payDayOfMonth', 'secondPayDayOfMonth', 'unshiftedAnchorCheckDate', 'arrearsDays', 'holidayShift', 'saturdayShift', 'sundayShift', 'compType', 'name']);
			if (!_ember['default'].isEmpty(props.unshiftedAnchorCheckDate)) {
				props.unshiftedAnchorCheckDate = (0, _componentLibraryUtilsGlobals.moment)(props.unshiftedAnchorCheckDate, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				props.payPeriodAnchorEndDate = props.unshiftedAnchorCheckDate.clone().subtract(props.arrearsDays, 'days');
			}
			this.setProperties(props);
			var isPayDays15AndLastDay = this.get('isSemiMonthly') && this.get('payDayOfMonth') === PAY_DAY_OF_MONTH.FIRST && this.get('secondPayDayOfMonth') === PAY_DAY_OF_MONTH.SECOND;
			this.set('isPayDays15AndLastDay', isPayDays15AndLastDay);
		}).on('init'),

		apply: function apply() {
			var props = this.getProperties(['payFrequency', 'payDayOfWeek', 'payDayOfMonth', 'secondPayDayOfMonth', 'unshiftedAnchorCheckDate', 'arrearsDays', 'holidayShift', 'saturdayShift', 'sundayShift', 'compType', 'name']);
			if (!_ember['default'].isEmpty(props.unshiftedAnchorCheckDate)) {
				props.unshiftedAnchorCheckDate = props.unshiftedAnchorCheckDate.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
			}
			if (this.get('isSemiMonthly')) {
				_ember['default'].setProperties(props, {
					secondArrearsDays: this.get('arrearsDays'),
					secondHolidayShift: this.get('holidayShift'),
					secondSaturdayShift: this.get('saturdayShift'),
					secondSundayShift: this.get('sundayShift')
				});
			} else {
				_ember['default'].setProperties(props, {
					secondArrearsDays: null,
					secondHolidayShift: null,
					secondSaturdayShift: null,
					secondSundayShift: null
				});
			}
			var paySchedule = this.get('paySchedule');
			paySchedule.setProperties(props);
			if (!paySchedule.get('isValid')) {
				this.setErrors();
				return false;
			}
			this.sendAction('applied');
			return true;
		}
	});
});
// for globals in CL/app
// for mixins in CL/app
// for mixins in CL/app