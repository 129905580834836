define('component-library/components/date-range', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	/**
  * Format date range.
  *
  * adds year to result by default, if year is different from current or dates have different years
  * e.g 'September 19, 2016'
  * can be disabled if empty val passed to outputYearSuffixFormat
  * for same dates:
  * 		if September 19
  * September 19 - 27
  */

	'use strict';

	var INPUT_DATE_FORMAT = 'MM-DD-YYYY';
	var OUTPUT_DATE_FORMAT = 'MMMM D';

	exports['default'] = _ember['default'].Component.extend({
		startDate: null,
		endDate: null,
		inputDateFormat: null,
		outputDateFormat: null,
		outputYearSuffixFormat: ', YYYY',

		transformedDateRange: (function () {
			var inputDateFormat = this.get('inputDateFormat') || INPUT_DATE_FORMAT;
			var outputDateFormat = this.get('outputDateFormat') || OUTPUT_DATE_FORMAT;
			var outputYearSuffixFormat = this.get('outputYearSuffixFormat');
			var start = (0, _componentLibraryUtilsGlobals.moment)(this.get('startDate'), inputDateFormat);
			var end = (0, _componentLibraryUtilsGlobals.moment)(this.get('endDate'), inputDateFormat);
			var isSameMonth = start.format('MM') === end.format('MM');
			var isSameDay = start.format('DD') === end.format('DD');
			var isSameYear = start.format('YYYY') === end.format('YYYY');
			var isThisYear = start.format('YYYY') === (0, _componentLibraryUtilsGlobals.moment)().format('YYYY');
			var yearFormatSuffix = isThisYear || !outputYearSuffixFormat ? '' : outputYearSuffixFormat;

			if (isSameDay && isSameMonth && isSameYear) {
				return start.format(outputDateFormat + yearFormatSuffix);
			} else if (isSameMonth && isSameYear) {
				return start.format(outputDateFormat) + ' - ' + end.format('D' + yearFormatSuffix);
			} else if (isSameYear) {
				return start.format(outputDateFormat) + ' - ' + end.format(outputDateFormat + yearFormatSuffix);
			}

			return start.format(outputDateFormat + yearFormatSuffix) + ' - ' + end.format(outputDateFormat + yearFormatSuffix);
		}).property('startDate', 'endDate')
	});
});