define('component-library/data-table/employee-sortable-column', ['exports', 'ember', 'component-library/data-table/column', 'component-library/data-table/cells/avatarname-multi-sort-table-cell', 'component-library/data-table/cells/employee-sortable-header-cell'], function (exports, _ember, _componentLibraryDataTableColumn, _componentLibraryDataTableCellsAvatarnameMultiSortTableCell, _componentLibraryDataTableCellsEmployeeSortableHeaderCell) {
	'use strict';

	exports['default'] = _componentLibraryDataTableColumn['default'].extend({
		tableCellViewClass: _componentLibraryDataTableCellsAvatarnameMultiSortTableCell['default'],
		headerCellViewClass: _componentLibraryDataTableCellsEmployeeSortableHeaderCell['default'],
		sortProperty: 'lastName',
		getSortValue: function getSortValue(row) {
			var path = this.get('contentPath');
			var sortProperty = this.get('sortProperty');
			if (path) {
				return row.get(path).get(sortProperty);
			} else {
				return row.get(sortProperty);
			}
		}
	});
});