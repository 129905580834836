define('component-library/routes/super-route', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Route.extend({
		attemptTransition: function attemptTransition() {
			return _ember['default'].RSVP.resolve();
		},

		triggerActivateTransitions: (function () {
			if (this.get('transitionToSelf')) {
				this.trigger('selfTransitionActivate');
			} else {
				this.trigger('outerTransitionActivate');
			}
		}).on('activate'),

		triggerDeactivateTransitions: (function () {
			if (this.get('transitionToSelf')) {
				this.trigger('selfTransitionDeactivate');
			} else {
				this.trigger('outerTransitionDeactivate');
			}
		}).on('deactivate'),

		actions: {
			didTransition: function didTransition() {
				this.set('transitionAccepted', false);
			},

			willTransition: function willTransition(transition) {
				var selfTransition = transition.targetName == this.routeName;
				this.set('transitionToSelf', selfTransition);

				var ignoreTransition = this.get('transitionAccepted');
				if (ignoreTransition) {
					return;
				}

				var route = this;
				transition.abort();
				this.attemptTransition().then(function () {
					route.set('transitionAccepted', true);
					transition.retry();
				});
			}
		}
	});
});