define("ui-infra/utils/ember-compatibility-utils", ["exports"], function (exports) {
  "use strict";

  exports.register = register;
  exports.inject = inject;
  exports.lookup = lookup;

  function register(appInstance) {
    for (var _len = arguments.length, rest = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }

    if (appInstance.register) {
      appInstance.register.apply(appInstance, rest);
    } else {
      var _appInstance$registry;

      // For backwards compatibility pre 2.1. See deprecation id: ember-application.app-instance-registry
      // http://emberjs.com/deprecations/v2.x/#toc_ember-application-registry-ember-applicationinstance-registry
      (_appInstance$registry = appInstance.registry).register.apply(_appInstance$registry, rest);
    }
  }

  function inject(appInstance) {
    for (var _len2 = arguments.length, rest = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      rest[_key2 - 1] = arguments[_key2];
    }

    if (appInstance.inject) {
      appInstance.inject.apply(appInstance, rest);
    } else {
      var _appInstance$registry2;

      // For backwards compatibility pre 2.1. See deprecation id: ember-application.app-instance-registry
      // http://emberjs.com/deprecations/v2.x/#toc_ember-application-registry-ember-applicationinstance-registry
      (_appInstance$registry2 = appInstance.registry).injection.apply(_appInstance$registry2, rest);
    }
  }

  function lookup(appInstance) {
    for (var _len3 = arguments.length, rest = Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
      rest[_key3 - 1] = arguments[_key3];
    }

    if (appInstance.lookup) {
      return appInstance.lookup.apply(appInstance, rest);
    } else {
      var _appInstance$container;

      // For backwards compatibility pre 2.1. See deprecation id: ember-application.app-instance-container
      // http://emberjs.com/deprecations/v2.x/#toc_ember-applicationinstance-container
      return (_appInstance$container = appInstance.container).lookup.apply(_appInstance$container, rest);
    }
  }
});