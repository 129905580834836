define('component-library/components/d-modal-dialog', ['exports', 'ember', 'component-library/mixins/resize-handler'], function (exports, _ember, _componentLibraryMixinsResizeHandler) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsResizeHandler['default'], {
		modalDialogClass: null,
		allowResize: false,
		autoFocus: true,
		autoSetHeight: true,

		onResizeEnd: function onResizeEnd() {
			if (this.get('allowResize')) {
				var $modalContent = this.$('.js-modal-content');
				var $modalDialog = this.$('.js-modal-dialog');

				$modalContent.height($modalDialog.height());

				// TODO(Louis): Handle case where the browser has been resized to a small
				// height and then expanded. Need to check for 90% of window height
			}
		},

		didInsertElement: function didInsertElement() {
			this._super();
			var $modal = this.$('.js-modal');
			var $backdrop = this.$('.js-modal-backdrop');
			var $modalDialog = this.$('.js-modal-dialog');
			var $modalContent = this.$('.js-modal-content');

			// Only one option if modalDialogClass is defined as: modal-dialog--card
			// Card has of max-height: 90% or otherwise sizes itself according to the
			// the content.
			if (this.get('autoSetHeight')) {
				if (this.get('modalDialogClass')) {
					$modalContent.height($modalDialog.height());
				} else {
					$backdrop.height($modal[0].scrollHeight);
				}
			}

			$backdrop.addClass('in');
			$modalDialog.addClass('in');
			_ember['default'].$('body').addClass('d-z-modal-open');
		},

		willDestroyElement: function willDestroyElement() {
			this._super();
			_ember['default'].$('body').removeClass('d-z-modal-open');
		},

		keyPress: function keyPress(e) {
			this.sendAction('modalKeyPressed', e);
		},

		becomeFocused: (function () {
			if (this.get('autoFocus')) {
				this.$('.js-modal').focus();
			}
		}).on('didInsertElement'),

		actions: {
			close: function close() {
				this.sendAction();
			}
		}
	});
});