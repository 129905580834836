(function() {
  function vendorModule(Ember) {
    'use strict';
    Ember['default'].assert('Make sure spark-md5.js is imported and loaded before importing this module.' +
    	'in client-app this library is imported into vendor-lazy.js. self.SparkMD5 is not defined',
    	!!self.SparkMD5);
    return { 'default': self.SparkMD5 };
  }

  define('spark-md5', ['ember'], vendorModule);
})();
