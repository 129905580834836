define('component-library/components/z-timeline', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	/**
 	Timeline Component
 
 	Description: Renders a timeline with points. Dynamically space out points by their dates,
 	but will adjust to have some minimum spacing (10%) between points to prevent label overlap.
 
 	WARNING: This is still a first-pass implementation, and will not handle certain cases such as
 	having a large number of points.
 
 	Usage:
 
 		(in controller.js)
 			someData: [
 				Ember.Object.create({
 					label: 'point 0',
 					date: '1/1/2015',
 					color: 'green'
 				}),
 				Ember.Object.create({
 					label: 'point 1',
 					date: '1/3/2015',
 					color: 'blue'
 				}),
 				Ember.Object.create({
 					label: 'point 2',
 					date: '1/20/2015',
 					color: 'red'
 				}),
 				Ember.Object.create({
 					label: 'point 3',
 					date: '1/21/2015',
 					color: 'orange'
 				}),
 				Ember.Object.create({
 					label: 'point 4',
 					date: '1/22/2015',
 					color: 'green'
 				}),
 			];
 
 		(in template.hbs)
 			{{z-timeline points=someData}}
 
 
 	Arguments:
 	-points: An array of Ember.Objects that represent a dot on the timeline.
 		Each Ember.Object in the array should look as follows:
 		{
 			label: 'Some text', //label for a point
 			color: 'red', //color of dot - red, green, blue, or orange
 			date: '01/01/2015',
 		}
 	-minPointSpacingPct (optional): Minimum % spacing between points (to prevent label clash)
 	-firstPointOffsetPct (optional): Shifts first point of timeline X% from left.
 	-lastPointOffsetPct (optional): Shifts last point of timeline X% from left.
 	-showToday (optional): Defaults to true. Shows today's date on timeline as a star. If any points
 		are for today, the dot will default to the star, with a "Today" shown above the label.
 	-todayColor (optional): Color of "Today" text and its star. "green", "red", "orange", or "blue" (default).
 
 	Notes:
 	-Keep label texts short. This is not meant for long text blocks.
 	-This component is not meant to handle more than about 9 points, max. Note that this is
 		a function of the spacing between points and how much text labels may overlap.
 **/

	'use strict';

	// Defaults
	var MINIMUM_PERCENTAGE_BETWEEN_POINTS = 10;
	var FIRST_POINT_OFFSET = 7;
	var TODAY_STAR_DIMENSION_PCT = 15;

	exports['default'] = _ember['default'].Component.extend({
		classNames: '',
		points: [],
		minPointSpacingPct: MINIMUM_PERCENTAGE_BETWEEN_POINTS,
		firstPointOffsetPct: FIRST_POINT_OFFSET,
		lastPointOffsetPct: null,
		lastPointOffsetPctAuto: (function () {
			var lastPointOffsetPct = this.get('lastPointOffsetPct');
			if (_ember['default'].isNone(lastPointOffsetPct)) {
				lastPointOffsetPct = 100 - this.get('firstPointOffsetPct');
			}
			return lastPointOffsetPct;
		}).property('firstPointOffsetPct', 'lastPointOffsetPct'),
		showToday: true,
		defaultPointColor: 'orange',
		todayColor: 'blue',
		todayStarDimensionPct: TODAY_STAR_DIMENSION_PCT,

		timelinePoints: (function () {
			var _this = this;

			var points = this.get('points');
			var today = new Date();
			var momentToday = (0, _componentLibraryUtilsGlobals.moment)(today);
			var hasPointFromToday = false;
			var showToday = this.get('showToday');
			var defaultPointColor = this.get('defaultPointColor');

			if (!points || !points.length) {
				return [];
			}

			points = points.map(function (point) {
				var momentDate = (0, _componentLibraryUtilsGlobals.moment)(point.get('date'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var isToday = false;
				var color = point.get('color') || defaultPointColor;

				if (showToday) {
					isToday = momentDate.isSame(today, 'day');

					if (isToday && !hasPointFromToday) {
						color = _this.get('todayColor');
						hasPointFromToday = true;
					}
				}

				point.setProperties({
					_color: color,
					_momentDate: momentDate,
					_momentValue: momentDate.valueOf(),
					_monthDayStr: momentDate.format('MMM D'),
					_yearStr: momentDate.format('YYYY'),
					_isToday: showToday && isToday
				});

				return point;
			});

			//If we want to show a point for today, and there's no point in the data that's
			//today's date, then add it into the list.
			if (showToday && !hasPointFromToday) {
				points.push(_ember['default'].Object.create({
					label: null,
					date: momentToday.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT),
					_momentDate: momentToday,
					_momentValue: momentToday.valueOf(),
					_monthDayStr: momentToday.format('MMM D'),
					_yearStr: momentToday.format('YYYY'),
					_color: this.get('todayColor'),
					_isToday: true
				}));
			}

			points = points.sortBy('_momentValue');
			this._setPointsOffsets(points);
			return points;
		}).property('points.@each.date'),

		_setPointsOffsets: function _setPointsOffsets(points) {
			var _this2 = this;

			var firstPoint = points.get('firstObject');
			var daysSpread = _componentLibraryUtilsGlobals.moment.duration(points.get('lastObject._momentValue') - firstPoint.get('_momentValue')).asDays() || 1; //Total days between first day to last day.
			var daySpreadPercentage = 100 / daysSpread; // % width between each day on timeline
			var minPointSpacingPct = this.get('minPointSpacingPct');
			var firstPointOffsetPct = this.get('firstPointOffsetPct');
			var lastPointOffsetPctAuto = this.get('lastPointOffsetPctAuto');
			var previousPoint;

			points.forEach(function (currentPoint, i) {
				var isLastPoint = i === points.length - 1;
				var daysSinceStart;
				var percentageOffset;
				var previousPointOffset;

				//If this is the first point, put it at the left of the timeline.
				if (!previousPoint) {
					currentPoint.set('_leftOffset', firstPointOffsetPct);
					previousPoint = currentPoint;
					return;
				}

				daysSinceStart = _componentLibraryUtilsGlobals.moment.duration(currentPoint.get('_momentValue') - firstPoint.get('_momentValue')).asDays();
				percentageOffset = daysSinceStart * daySpreadPercentage;
				previousPointOffset = previousPoint.get('_leftOffset');

				if (isLastPoint || percentageOffset > lastPointOffsetPctAuto) {
					percentageOffset = lastPointOffsetPctAuto;
				}

				//If the offset of this point will be too close to the previous point...
				if (percentageOffset - previousPointOffset < minPointSpacingPct) {
					//If this is the last point, put this at the end, and then start shifting previous
					//points back left. This takes care of the case where there's plenty of room on the
					//graph but the last few points are bunched up on the right.
					if (isLastPoint) {
						currentPoint.set('_leftOffset', lastPointOffsetPctAuto);
						_this2._shiftPointsFromEnd(points);
					}
					//Else it's not the last point, so just set it the minimum offset away from
					//the previous ones and let any subsequent points handle re-aligning themselves.
					else {
							currentPoint.set('_leftOffset', previousPointOffset + minPointSpacingPct);
						}
				}
				//Else, spacing is fine. Set offset as normal.
				else {
						currentPoint.set('_leftOffset', percentageOffset);
					}

				previousPoint = currentPoint;
			});

			//Offset of today-star needs to get adjusetd.
			if (this.get('showToday')) {
				this._adjustTodayStarOffset(points);
			}
		},

		//NOTE: This would have an edge case where, if we have too many points,
		//we'll end up spacing backwards until the left-most points will be too close to each other..
		//However, with a default spacing of 10% and an addition of a media query to
		//not show the timeline on narrow views, this should be an edge case that we will
		//ignore and not add absurd complexity to the code for. Sensibility ftw.
		_shiftPointsFromEnd: function _shiftPointsFromEnd(points) {
			var i = points.length - 1;
			var minPointSpacingPct = this.get('minPointSpacingPct');
			var rightPoint;
			var leftPoint;
			var rightPointOffset;

			for (i; i > 0; i--) {
				rightPoint = points[i];
				leftPoint = points[i - 1];
				rightPointOffset = rightPoint.get('_leftOffset');

				if (rightPointOffset - leftPoint.get('_leftOffset') >= minPointSpacingPct) {
					break;
				}

				leftPoint.set('_leftOffset', rightPointOffset - minPointSpacingPct);
			}
		},

		//Do math to adjust the position of the today star because, well, SVG.
		_adjustTodayStarOffset: function _adjustTodayStarOffset(points) {
			var _this3 = this;

			var todayStartOffsetCorrection = this.get('todayStarDimensionPct') / 2;
			var offset;

			points.forEach(function (point) {
				if (point.get('_isToday')) {
					offset = point.get('_leftOffset');
					offset = Math.max(offset, _this3.get('firstPointOffsetPct')) - todayStartOffsetCorrection;
					point.set('_starLeftOffset', offset);
				}
			});
		}

	});
});