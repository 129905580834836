define('component-library/components/tabbed-modal/tab-content', ['exports', 'ember', 'component-library/components/tabbed-modal/utils'], function (exports, _ember, _componentLibraryComponentsTabbedModalUtils) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend(_componentLibraryComponentsTabbedModalUtils.TabbedModalModeFields, {
		tagName: '',

		tabApi: null,

		modalMode: _ember['default'].computed.reads('tabApi.modalMode'),

		actions: {
			next: function next() {
				return _ember['default'].RSVP.Promise.resolve(this.get('footerActions').onNext());
			},
			cancel: function cancel() {
				return _ember['default'].RSVP.Promise.resolve(this.get('footerActions').onCancel());
			}
		}
	});
});