define('ui-infra/utils/get-config', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = getConfig;

	/* globals require */

	var get = _ember['default'].get;
	function getConfig(appInstance) {
		// fallback to "application.modulePrefix" as per
		// https://github.com/zenefits/yourPeople3/pull/48903/commits/709d389cc4ba3e6df510ea1bbe861230849b88be
		var appName = get(appInstance, 'application.name') || get(appInstance, 'base.name') || get(appInstance, 'application.modulePrefix');
		if (appName === 'ui-infra') {
			appName = 'dummy';
		}
		return require(appName + '/config/environment')['default'];
	}
});