define('component-library/components/settings-page/exit-link', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		exitLinkText: 'Exit',
		actions: {
			exit: function exit() {
				this.attrs.exit();
			}
		}
	});
});