define('component-library/components/text-field', ['exports', 'ember', 'component-library/mixins/style-bindings', 'component-library/utils/escape-regexp'], function (exports, _ember, _componentLibraryMixinsStyleBindings, _componentLibraryUtilsEscapeRegexp) {
	'use strict';

	window.Inputmask.extendAliases({
		"mm / dd / yyyy": {
			mask: "1 / 2 / y",
			placeholder: "mm / dd / yyyy",
			leapday: "02 / 29",
			separator: " / ",
			alias: "mm/dd/yyyy"
		},
		"mm / yyyy": {
			mask: "1 / y",
			placeholder: "mm / yyyy",
			leapday: "02 / 29",
			separator: " / ",
			alias: "mm/yyyy"
		}
	});

	var HOSTNAMES = ["aol.com", "att.net", "comcast.net", "facebook.com", "gmail.com", "gmx.com", "googlemail.com", "google.com", "hotmail.com", "hotmail.co.uk", "mac.com", "me.com", "mail.com", "msn.com", "live.com", "sbcglobal.net", "verizon.net", "yahoo.com", "zenefits.com"];

	var FIELD_TYPES = {
		PERCENT: 'percent',
		NUMBER: 'number',
		PHONE: 'phone',
		EIN: 'ein',
		SSN: 'ssn',
		SIC: 'sic',
		DATE: 'date',
		MONTHYEAR: 'monthyear',
		EMAIL: 'email',
		TEXTAREA: 'text-area',
		PASSWORD: 'password',
		MONEY: 'money',
		ZIP: 'zip',
		DEFAULT: 'default'
	};

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsStyleBindings['default'], {
		classNames: ['textField'],
		classNameBindings: ['errorText:has-error', 'isDisabled', 'isStatic', 'hasFloatingLabels:has-floatingLabels', 'isFocused', 'hasValue:has-value'],
		styleBindings: ['height'],

		isFocused: false,
		isDisabled: false,
		isStatic: false,
		hasFloatingLabels: true,
		showShadowPlaceholder: false,
		isDate: _ember['default'].computed.equal('type', FIELD_TYPES.DATE),
		isTextArea: _ember['default'].computed.equal('type', FIELD_TYPES.TEXTAREA),
		isPassword: _ember['default'].computed.equal('type', FIELD_TYPES.PASSWORD),
		isMoney: _ember['default'].computed.equal('type', FIELD_TYPES.MONEY),

		type: FIELD_TYPES.DEFAULT,
		label: undefined,
		hint: undefined,
		placeholder: _ember['default'].computed.alias('hint'),
		value: undefined,
		errorText: undefined,
		helpText: undefined,
		height: undefined,
		shadowPlaceholder: undefined,
		showShadowValue: _ember['default'].computed.or('hasValue', 'isFocused'),
		shadowUnitSuffix: undefined,

		didInsertElement: function didInsertElement() {
			var type = this.get('type');
			var mask = this.get('mask');
			var placeholder = this.get('maskPlaceholder');
			var maskOptions;
			if (type === FIELD_TYPES.PERCENT) {
				this.$('.js-textField-input').inputmask("decimal", {
					digits: 2,
					rightAlign: false,
					min: this.get('min') || 0,
					max: this.get('max') || 100
				});
			} else if (type === FIELD_TYPES.NUMBER) {
				maskOptions = {
					rightAlign: false,
					max: this.get('max'),
					min: this.get('min'),
					allowMinus: this.get('allowMinus')
				};

				this.$('.js-textField-input').inputmask("decimal", maskOptions);
			} else if (type === FIELD_TYPES.MONEY) {
				maskOptions = {
					radixPoint: ".",
					rightAlign: false,
					digits: 2,
					prefix: '   ', // padding for shadow $
					showMaskOnHover: false,
					max: this.get('max'),
					min: this.get('min')
				};

				this.$('.js-textField-input').inputmask("decimal", maskOptions);
			} else if (type === FIELD_TYPES.PHONE) {
				mask = mask || "(999) 999-9999";
				this.$('.js-textField-input').inputmask(mask, {
					placeholder: '',
					showMaskOnFocus: false,
					showMaskOnHover: false
				});

				this.$('.js-textField-shadowInput').inputmask(mask, {});
			} else if (type === FIELD_TYPES.EIN) {
				mask = mask || "99-9999999";
				this.$('.js-textField-input').inputmask(mask, {
					placeholder: "",
					showMaskOnFocus: false,
					showMaskOnHover: false
				});
			} else if (type === FIELD_TYPES.SSN) {
				mask = mask || "999-99-9999";
				this.$('.js-textField-input').inputmask(mask, {
					placeholder: "",
					showMaskOnFocus: false,
					showMaskOnHover: false
				});
			} else if (type === FIELD_TYPES.SIC) {
				mask = mask || "9999";
				this.$('.js-textField-input').inputmask(mask, {
					placeholder: "0000",
					showMaskOnFocus: false,
					showMaskOnHover: false
				});
			} else if (type === FIELD_TYPES.DATE) {
				this.set('shadowPlaceholder', 'mm / dd / yyyy');
				this.set('showShadowPlaceholder', true);
				this.$('.js-textField-input').inputmask("mm / dd / yyyy", {
					showMaskOnHover: false,
					showMaskOnFocus: false
				});
			} else if (type === FIELD_TYPES.MONTHYEAR) {
				this.set('shadowPlaceholder', 'mm / yyyy');
				this.set('showShadowPlaceholder', true);
				this.$('.js-textField-input').inputmask("mm / yyyy", {
					showMaskOnHover: false,
					showMaskOnFocus: false
				});
			} else if (type === FIELD_TYPES.ZIP) {
				mask = mask || "99999";
				this.$('.js-textField-input').inputmask(mask, {
					placeholder: "",
					showMaskOnFocus: false,
					showMaskOnHover: false
				});
			} else if (mask) {
				this.$('.js-textField-input').inputmask(mask, {
					placeholder: placeholder,
					showMaskOnHover: false
				});
			}

			this.valueDidChange();
		},

		formattedDateValue: (function (key, value, previousValue) {
			if (arguments.length > 1 && !_ember['default'].isNone(value)) {
				this.set('value', value.toString().replace(/\s+/g, ''));
			}

			var val = this.get('value');
			if (!_ember['default'].isNone(val)) {
				return val.replace(/\//g, ' / ');
			} else {
				return val;
			}
		}).property('value'),

		formattedPasswordValue: (function (key, value, previousValue) {
			if (arguments.length > 1 && !_ember['default'].isEmpty(value)) {
				this.set('value', value.toString());
			}

			var val = this.get('value');
			if (!_ember['default'].isEmpty(val)) {
				var passwordMask = new Array(val.length + 1).join('*');

				// ATTN: This is hack required for resolving a race condition between template rendering and input field rendering in Ember.
				//       Without this hack, the first character typed into a password field would not be masked by asterisks. - DQ
				if (val.length == 1 && passwordMask == '*' && passwordMask != val && this.get('isPassword')) {
					var $input = this.$('.js-textField-input');
					_ember['default'].run.scheduleOnce('afterRender', function () {
						$input.val(passwordMask);
					});
				}

				return passwordMask;
			} else {
				return val;
			}
		}).property('value'),

		formattedMoneyValue: (function (key, value, previousValue) {
			if (arguments.length > 1 && !_ember['default'].isEmpty(value)) {
				this.set('value', parseFloat(value.trim())); // trim to strip padding
			}

			return this.get('value');
		}).property('value'),

		syncTextAreaHeight: function syncTextAreaHeight() {
			var $textArea = this.$('.js-textField-textarea');
			var $shadowTextArea = this.$('.js-textField-shadowTextarea');
			var $underline = this.$('.js-textField-underline');
			var $focusUnderline = this.$('.js-textField-focus-underline');
			var newHeight = $shadowTextArea.get(0).scrollHeight;
			$textArea.outerHeight(newHeight);
			var underlineOffset = 10;
			$underline.css({
				top: newHeight - underlineOffset
			});
			$focusUnderline.css({
				top: newHeight - underlineOffset
			});
			this.set('height', newHeight);
		},

		getEmailSuggestion: function getEmailSuggestion(value) {
			var atIndex = value.indexOf('@');
			if (atIndex >= 0) {
				var username = value.substr(0, atIndex);
				var hostname = this.getEmailSuggestion(value.substr(atIndex + 1));
				var strippedHostName = (0, _componentLibraryUtilsEscapeRegexp['default'])(hostname);
				for (var i = 0; i < HOSTNAMES.length; ++i) {
					if (hostname.length > 0 && HOSTNAMES[i].match(new RegExp('^' + strippedHostName, 'g'))) {
						return '%@@%@'.fmt(username, HOSTNAMES[i]);
					}
				}
			}
			return value;
		},

		shadowValue: (function () {
			var type = this.get('type');
			var value = this.get('value') || '';
			var placeholder = this.getWithDefault('shadowPlaceholder', '');
			var shadowUnitSuffix = this.getWithDefault('shadowUnitSuffix', '');

			if (type === FIELD_TYPES.PERCENT) {
				return (_ember['default'].isEmpty(value) ? '' : value) + ' %';
			} else if (type === FIELD_TYPES.NUMBER) {
				if (shadowUnitSuffix) {
					return (_ember['default'].isEmpty(value) ? '' : value) + ' ' + shadowUnitSuffix;
				} else {
					return _ember['default'].isEmpty(value) ? '' : value;
				}
			} else if (type === FIELD_TYPES.MONEY) {
				return '$';
			} else if (type === FIELD_TYPES.EMAIL) {
				return this.getEmailSuggestion(value);
			} else if (this.get('showShadowPlaceholder') && this.get('isFocused')) {
				// NOTE: use the value from the input directly so formatting is correct
				value = this.$(".js-textField-input").val() || '';
				return value + placeholder.substr(value.length);
			}
		}).property('value', 'isFocused', 'showShadowPlaceholder', 'formattedDateValue', 'formattedPasswordValue', 'formattedMoneyValue'),

		valueDidChange: (function () {
			var type = this.get('type');
			if (type === FIELD_TYPES.TEXTAREA) {
				_ember['default'].run.next(this, this.syncTextAreaHeight);
			}
		}).observes('value', 'formattedDateValue', 'formattedPasswordValue', 'formattedMoneyValue'),

		hasValue: (function () {
			return !_ember['default'].isEmpty(this.get('value'));
		}).property('value'),

		showErrorText: (function () {
			var errorText = this.get('errorText');
			return errorText && errorText.length > 0;
		}).property('errorText'),

		didFocusOut: function didFocusOut() {
			var type = this.get('type');
			var value = this.get('value');
			if (type === FIELD_TYPES.MONEY) {
				this.set('value', parseFloat(value).toFixed(2).toString());
			}
		},

		actions: {
			focusedIn: function focusedIn() {
				// set focus
				if (!this.get('isDisabled')) {
					this.set('isFocused', true);
				}
				this.sendAction('focusedIn');
			},
			focusedOut: function focusedOut() {
				this.set('isFocused', false);
				this.didFocusOut();
				this.sendAction('focusedOut');
			},
			insertNewline: function insertNewline() {
				// TODO(L)
				this.sendAction('insertNewline');
			},
			escapePress: function escapePress() {
				// TODO(L)
				this.sendAction('escapePress');
			},
			keyDown: function keyDown(value, event) {
				var type = this.get('type');
				var code = event.keyCode || event.which;
				if (type === FIELD_TYPES.EMAIL) {
					value = this.get('value') || '';
					var suggestion = this.getEmailSuggestion(value);
					if (code === 9 || code === 39 && value !== suggestion) {
						this.set('value', suggestion);
					}
				}
			}
		}
	});
});