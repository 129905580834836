define('component-library/deductions/review/master/route', ['exports', 'ember', 'component-library/routes/master'], function (exports, _ember, _componentLibraryRoutesMaster) {
	'use strict';

	exports['default'] = _componentLibraryRoutesMaster['default'].extend({
		title: 'Deductions Review',

		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.set('isDetailsOpen', false);
			this.controllerFor(this.routeName.split('.').slice(0, -1).join('.')).set('isDiscrepancyFilterEnabled', !controller.get('displayNormal'));
			this.controllerFor(this.routeName.split('.').slice(0, -1).join('.')).set('displayContribution', controller.get('displayContribution'));
		},

		actions: {
			showDetails: function showDetails() {
				this.controller.set('isDetailsOpen', true);
			},

			hideDetails: function hideDetails() {
				this.controller.set('isDetailsOpen', false);
			},

			subrouteObjectSet: function subrouteObjectSet(selection) {
				this.controller.set('selection', selection);
				this.send('showDetails');
			},

			selectItem: function selectItem(row) {
				var currentPath = this.controllerFor("application").get("currentPath");
				var targetRouteName = [this.routeName, 'details'].join('.');
				if (!currentPath.startsWith(targetRouteName)) {
					this.transitionTo(targetRouteName, row);
				} else {
					var singleModel = this.modelFor(targetRouteName);
					if (singleModel == row) {
						this.transitionTo(this.routeName);
					} else {
						this.transitionTo(targetRouteName, row);
					}
				}
			}
		}
	});
});