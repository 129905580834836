define('component-library/components/z-bank-information', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['bankInfo', 'grid-block', 'vertical'],
		label: "Bank Information",

		// TODO(DQ/KR): remove validations out of this component
		routingMaskOptions: {
			definitions: {
				'*': {
					validator: '[*]',
					cardinality: 1
				}
			}
		},

		accountMaskOptions: {
			definitions: {
				'i': {
					validator: '[0-9*]',
					cardinality: 1
				}
			}
		},
		account: {},
		routing: {},

		bankName: null,
		tooltipText: null,

		getBankDetails: function getBankDetails() {
			var _this = this;

			var value = this.get('routing.value');
			if (_ember['default'].isEmpty(value)) {
				return;
			}
			_ember['default'].getJSON('/custom_api/lookup_routing_number/' + value).then(function (data) {
				var result = data.name;
				_this.setProperties({
					bankName: result.toLowerCase(),
					'routing.errorText': null
				});
			})['catch'](function (error) {
				_this.setProperties({
					bankName: null,
					'routing.errorText': 'Bank routing number not found.'
				});
			});
		},

		actions: {
			routingFieldFocusedOut: function routingFieldFocusedOut() {
				this.getBankDetails();
				this.sendAction('focusedOut');
			}
		}
	});
});