define('component-library/mixins/payroll/pay-schedule-editor-mixin', ['exports', 'ember', 'component-library/modals/payroll/add-pay-schedule-modal', 'component-library/components/notification-modal', 'component-library/modals/payroll/confirmation-modal', 'component-library/modals/payroll/employees-pay-schedule-modal', 'component-library/constants/cps'], function (exports, _ember, _componentLibraryModalsPayrollAddPayScheduleModal, _componentLibraryComponentsNotificationModal, _componentLibraryModalsPayrollConfirmationModal, _componentLibraryModalsPayrollEmployeesPayScheduleModal, _componentLibraryConstantsCps) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		// TODO(Peter): remove this after prior payroll is done
		shiftCheckDateToNextQuarter: false,

		actions: {
			addPaySchedule: function addPaySchedule(compType, showBothCompType) {
				var _this = this;

				var company = this.get('company');
				var payrollSyncType = company.get('payrollSyncType');
				var paySchedule = window.App.CompanyPaySchedule.createRecord({
					company: this.get('company'),
					creationMethod: this.get('creationMethod') || (payrollSyncType === 'PYP' ? _componentLibraryConstantsCps.CREATION_METHOD.PYP : _componentLibraryConstantsCps.CREATION_METHOD.SMP),
					compType: _componentLibraryConstantsCps.COMP_TYPE_FLAG[compType]
				});

				var modalOptions = {
					shiftCheckDateToNextQuarter: this.get('shiftCheckDateToNextQuarter'),
					zPayrollEnums: this.get('zPayrollEnums'),
					bankHolidays: this.get('bankHolidays'),
					blockPeriod: this.get('blockPeriod'),
					company: this.get('company'),
					paySchedule: paySchedule,
					numRollingGateDays: this.get('isComplete') ? 0 : this.get('numRollingGateDays'),
					isCompTypeDisabled: !!compType,
					clusterDate: this.get('clusterDate'),
					blackOutDates: this.get('isComplete') ? false : this.get('blackOutDates') || [],
					checkDateStartDate: this.get('checkDateStartDate')
				};

				// Fong: require this check to ensure `hasTA` logic in pay-schedule isn't
				// overwritten if hasTA is undefined
				if (typeof this.get('hasTA') !== 'undefined') {
					var hasTA = this.get('hasTA');
					modalOptions.hasTA = hasTA;
					if (compType != 'Both') {
						modalOptions.showBothCompType = showBothCompType || !hasTA;
					}
				}
				if (typeof showBothCompType !== 'undefined') {
					modalOptions.showBothCompType = showBothCompType;
				}

				if (typeof this.get('allowPastEffectiveDate') !== 'undefined') {
					modalOptions.allowPastEffectiveDate = this.get('allowPastEffectiveDate');
				}

				if (this.get('payPeriodStartDate')) {
					modalOptions.payPeriodStartDate = this.get('payPeriodStartDate');
				}

				if (this.get('skipValidations')) {
					modalOptions.numRollingGateDays = 0;
					modalOptions.blackOutDates = [];
				}

				if (this.get('preventChangingCompType') && paySchedule.get('compType')) {
					modalOptions.isCompTypeDisabled = true;
				}

				if (this.get('creationMethod') == _componentLibraryConstantsCps.CREATION_METHOD.PYP) {
					modalOptions.isReadOnly = true;
				}
				_componentLibraryModalsPayrollAddPayScheduleModal['default'].show(modalOptions).then(function (ps) {
					_this.get('paySchedules').pushObject(ps);
				})['catch'](function () {
					paySchedule.deleteRecord();
				});
			},

			editPaySchedule: function editPaySchedule(paySchedule) {
				var _this2 = this;

				var originalStatus = paySchedule.get('status');

				var modalOptions = {
					shiftCheckDateToNextQuarter: this.get('shiftCheckDateToNextQuarter'),
					zPayrollEnums: this.get('zPayrollEnums'),
					bankHolidays: this.get('bankHolidays'),
					blockPeriod: this.get('blockPeriod'),
					paySchedule: paySchedule,
					numRollingGateDays: this.get('isComplete') ? 0 : this.get('numRollingGateDays'),
					// Can edit pay Schedule if there less than 2 paySchedule
					// Does not affect products that do not display the compType
					blackOutDates: this.get('isComplete') ? false : this.get('blackOutDates') || [],
					isCompTypeDisabled: this.get('paySchedules.length') >= 2 || paySchedule.get('isActive'),
					hasQuarterlyBlackOut: this.get('hasQuarterlyBlackOut'),
					checkDateStartDate: this.get('checkDateStartDate')
				};

				if (typeof this.get('hasTA') !== 'undefined') {
					var hasTA = this.get('hasTA');
					modalOptions.hasTA = hasTA;
					if (paySchedule.compType != 'Both') {
						modalOptions.showBothCompType = !hasTA;
					}
				}

				// Fong: require this check to ensure `hasTA` logic in pay-schedule isn't
				// overwritten if hasTA is undefined
				if (typeof this.get('allowPastEffectiveDate') !== 'undefined') {
					modalOptions.allowPastEffectiveDate = this.get('allowPastEffectiveDate');
				}

				if (this.get('payPeriodStartDate')) {
					modalOptions.payPeriodStartDate = this.get('payPeriodStartDate');
				}

				if (this.get('paySchedules.firstObject.id') !== paySchedule.get('id')) {
					modalOptions.clusterDate = this.get('clusterDate');
				}

				if (this.get('skipValidations')) {
					modalOptions.numRollingGateDays = 0;
					modalOptions.blackOutDates = [];
				}

				if (this.get('preventChangingCompType') && paySchedule.get('compType')) {
					modalOptions.isCompTypeDisabled = true;
				}

				if (paySchedule.get('creationMethod') == _componentLibraryConstantsCps.CREATION_METHOD.PYP) {
					modalOptions.isReadOnly = true;
				}

				_componentLibraryModalsPayrollAddPayScheduleModal['default'].show(modalOptions).then(function (editedCPS) {
					// This is necessary because the back end isActive overrides the comp prop
					delete editedCPS.isActive;
					if (originalStatus == editedCPS.status) {
						paySchedule.setProperties(editedCPS);
					} else {
						var newCps = window.App.CompanyPaySchedule.createRecord(editedCPS);
						_this2.get('paySchedules').pushObject(newCps);
					}
					_this2.get('paySchedules').invoke('reload');
				})['catch'](function () {
					if (paySchedule.get('isValid')) {
						// Pressing cancel should revert back to 'Complete Setup' if model isn't valid
						paySchedule.rollback();
					}
				});
			},

			deletePaySchedule: function deletePaySchedule(laddaButton, paySchedule) {
				var _this3 = this;

				var employeesOnPaySchedule = this.get('employees').filterBy('paySchedule.id', paySchedule.get('id'));
				if (!_ember['default'].isEmpty(employeesOnPaySchedule)) {
					if (laddaButton) {
						laddaButton.stop();
					}
					_componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'Unable to delete pay schedule',
						message: 'You cannot delete a pay schedule that is currently in use.'
					});
					return;
				}

				_componentLibraryModalsPayrollConfirmationModal['default'].open({
					title: 'Delete Pay Schedule',
					message: 'Are you sure you want to delete this pay schedule?',
					confirmButtonClass: 'btn--danger',
					onConfirm: function onConfirm() {
						return paySchedule.destroyRecord();
					}
				}).promise.then(function () {
					_this3.get('paySchedules').removeObject(paySchedule);
				})['catch'](function (error) {
					if (error) {
						var errorMessage = JSON.parse(error.responseText).error_message;
						_componentLibraryComponentsNotificationModal['default'].show({
							notificationType: 'error',
							title: 'Internal error',
							message: errorMessage
						});
					}
				})['finally'](function () {
					if (laddaButton) {
						laddaButton.stop();
					}
				});
			},

			showReadOnly: function showReadOnly(paySchedule, isAdmin) {
				var modalOptions = {
					paySchedule: paySchedule,
					isStatic: true,
					zPayrollEnums: this.get('zPayrollEnums'),
					bankHolidays: this.get('bankHolidays'),
					isReadOnly: true,
					blockPeriod: this.get('blockPeriod')
				};

				if (isAdmin) {
					modalOptions.isAdminView = true;
				}
				_componentLibraryModalsPayrollAddPayScheduleModal['default'].show(modalOptions);
			},

			showAssignedEmployees: function showAssignedEmployees(paySchedule) {
				_componentLibraryModalsPayrollEmployeesPayScheduleModal['default'].show({
					paySchedule: paySchedule,
					// gets valid employees from validation mixin
					employees: this.get('validEmployees')
				});
			}
		}
	});
});