define('component-library/components/address-component', ['exports', 'ember'], function (exports, _ember) {
	// DEPRECIATED: DO NOT USE THIS COMPONENT
	//`z-address` is the only supported address component
	'use strict';

	//
	// {{address-component streetAddress=streetAddress
	// 					   city=city
	// 					   stateCode=state
	// 					   zipCode=zip
	// 					   hideHeadings=true
	// 					   companyName=companyName
	// 					   showStreetAddress2=true
	//					   streetAddress2=streetAddress2
	// 					   validations=validations}}
	//
	// {{address-component streetAddress=streetAddress
	// 					   city=city
	// 					   stateCode=state
	// 					   zipCode=zip
	// 					   hideHeadings=true
	// 					   companyName=companyName
	// 					   showStreetAddress2=true
	//					   streetAddress2=streetAddress2
	// 					   textFieldClassNames="flat"
	// 					   componentClass="flat"}}
	//
	exports['default'] = _ember['default'].Component.extend({
		// Class at component level, specify the width and other properties using this class.
		componentClass: null,
		// This class will fit the address component relatively with respect to the parent component.
		textFieldClassNames: 'relative-fit',
		// Hide the labels, they are redundant to place holders.
		hideHeadings: false,

		// Optional company name you can pass IN for USPS validations for better result.
		companyName: null,

		streetAddress: null,
		streetAddress2: null,
		city: null,
		// To not to collide with internal view/component attribute (state), we need to name it as stateCode.
		stateCode: null,
		zipCode: null,

		showStreetAddress2: false,

		// This value can be bounded by clients to get error messages.
		errorText: null,
		// Validations to mimic the validation mixin, this currently has the zipcode validations only.
		validations: null,
		_emptyValidations: _ember['default'].Set.create(),
		zipCodeValidations: _ember['default'].computed.or('validations', '_emptyValidations'),

		// The final status would be one of 'success', 'address_not_found', 'address_found_mismatch_in_fields' or 'failed_to_validate'
		validationStatus: null,
		uspsAddress: null,

		googlePlacesComponent: {
			street_number: ['streetAddress', 'long_name'],
			route: ['streetAddress', 'long_name'],
			locality: ['city', 'long_name'],
			sublocality_level_1: ['city', 'long_name'],
			administrative_area_level_1: ['stateCode', 'short_name'],
			postal_code: ['zipCode', 'long_name']
		},
		displayAddressSuggestion: false,

		didInsertElement: function didInsertElement() {
			var options = {
				types: ['address'],
				componentRestrictions: { country: 'US' }
			};
			var autocomplete = null;
			if (window.google) {
				autocomplete = new window.google.maps.places.Autocomplete(this.$('#googleApiLinkField')[0], options);
				window.google.maps.event.addListener(autocomplete, 'place_changed', _ember['default'].run.next(this, this.fillInAddress));
			}
			this.set('autocomplete', autocomplete);
			this.set('validationStatus', 'failed');
			this.set('validationEnabled', true);
		},
		fillInAddress: function fillInAddress() {
			var googlePlacesComponent = this.get('googlePlacesComponent');
			var autocomplete = this.get('autocomplete');
			var place = null;
			if (autocomplete) {
				place = this.get('autocomplete').getPlace();
			}

			if (!place || !place.address_components) {
				return;
			}

			this.set('validationEnabled', false);
			var streetAddress = '';
			for (var i = 0; i < place.address_components.length; i++) {
				var addressType = place.address_components[i].types[0];
				if (googlePlacesComponent[addressType]) {
					var fieldAttr = googlePlacesComponent[addressType];
					var value = place.address_components[i][fieldAttr[1]];
					// Special handling for street address as it comes from two fields from Google API.
					if (fieldAttr[0] == 'streetAddress') {
						streetAddress = addressType == 'street_number' ? value + ' ' + streetAddress.trim() : streetAddress.trim() + ' ' + value;
					} else {
						this.set(fieldAttr[0], value);
					}
				}
			}

			this.set('streetAddress', streetAddress.trim());
			this.set('validationEnabled', true);
			this.validateAddress();
		},

		validateAddress: function validateAddress() {
			var _this = this;

			if (!this.get('validationEnabled')) {
				return;
			}

			var streetAddress = this.get('streetAddress') || '';
			var streetAddress2 = this.get('streetAddress2') || '';
			var city = this.get('city') || '';
			var state = this.get('stateCode') || '';
			var zipCode = this.get('zipCode') || '';
			var companyName = this.get('companyName') || '';

			if (!streetAddress) {
				this.set('errorText', 'Please input street address');
				return;
			}
			if (!city) {
				this.set('errorText', 'Please input city');
				return;
			}
			if (!state) {
				this.set('errorText', 'Please select state.');
				return;
			}

			var address = [streetAddress, streetAddress2, city, state, zipCode].join().toLowerCase();
			if (this.get('previousValidatedAddress') == address) {
				// No change in addres, skip validation.
				return;
			}

			this.set('errorText', null);
			this.set('previousValidatedAddress', address);

			_ember['default'].$.ajax({
				url: '/validateAddressUsingUSPS',
				data: JSON.stringify({ "streetAddress": streetAddress || '',
					"streetAddress2": streetAddress2 || '',
					"city": city || '',
					"state": state || '',
					"zipCode": zipCode || '',
					"companyName": companyName || ''
				}),
				type: "POST",
				dataType: "json"
			}).then(function (data) {
				_this.set('validationStatus', data.status);
				_this.set('displayAddressSuggestion', false);
				_this.set('errorText', null);
				if (data.status == 'address_found_mismatch_in_fields' || data.status == 'address_found') {
					var addr = data.address;
					var uspsAddressLine = [addr.address, addr.extendedAddress, addr.city, addr.state, addr.zip].join().toLowerCase();
					if (uspsAddressLine != address) {
						_this.set('displayAddressSuggestion', true);
						var uspsAddressLine1 = addr.address + ' ' + addr.extendedAddress;
						var uspsAddressLine2 = addr.city + ', ' + addr.state + ' ' + addr.zip;
						_this.set('uspsAddressLine_1', uspsAddressLine1);
						_this.set('uspsAddressLine_2', uspsAddressLine2);
						_this.set('uspsAddress', addr);
					}
					if (data.comment) {
						var comment = data.comment.replace('Default address: ', '');
						if (_this.get('streetAddress2')) {
							comment += ' If you believe the address you entered is correct, please disregard this error message and continue.';
						}
						_this.set('errorText', comment);
					}
				}
				// Don't display USPS server or API related failures.
				else if (data.status != 'failed_to_validate') {
						_this.set('errorText', data.comment);
					}
			}, function () {
				// Let's silently ignore any error on our server side, we catch them and send mail to dev-swat.
				// No need to alarm clients about our issue.
				// this.set('errorText', 'Failed to validate address using USPS service.');
			});
		},

		onStateChange: (function () {
			this.validateAddress();
		}).observes('stateCode'),
		onZipCodeChange: (function () {
			// Validate the address on valid zip code.
			if (!this.get('zipCodeValidations.length')) {
				this.validateAddress();
			}
		}).observes('zipCode'),
		zipCodeClassNames: (function () {
			return this.get('textFieldClassNames') ? this.get('textFieldClassNames') + ' js-glue-zip' : 'js-glue-zip';
		}).property('textFieldClassNames'),

		actions: {
			onStreetAddressFocusOut: function onStreetAddressFocusOut() {
				var streetAddress = this.get('streetAddress');
				_ember['default'].run.next(this, function () {
					this.set('streetAddress', streetAddress);
					this.$('#googleApiLinkField').val(streetAddress);
				});

				this.validateAddress();
			},
			onFocusOut: function onFocusOut() {
				this.validateAddress();
			},
			changeAddressToUspsAddress: function changeAddressToUspsAddress() {
				_ember['default'].run.next(this, function () {
					var uspsAddr = this.get('uspsAddress');
					this.set('streetAddress', uspsAddr.address);
					if (this.get('showStreetAddress2')) {
						this.set('streetAddress2', uspsAddr.extendedAddress);
					}
					this.set('city', uspsAddr.city);
					this.set('stateCode', uspsAddr.state);
					this.set('zipCode', uspsAddr.zip);
					var uspsAddressLine = [uspsAddr.address, uspsAddr.extendedAddress, uspsAddr.city, uspsAddr.state, uspsAddr.zip].join().toLowerCase();
					this.set('previousValidatedAddress', uspsAddressLine);
					this.set('displayAddressSuggestion', false);
				});
			},
			ignoreUspsAddressSuggestion: function ignoreUspsAddressSuggestion() {
				this.set('displayAddressSuggestion', false);
			}
		}
	});
});