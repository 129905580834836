define('component-library/data-table/date-column', ['exports', 'ember', 'component-library/data-table/column'], function (exports, _ember, _componentLibraryDataTableColumn) {
	'use strict';

	exports['default'] = _componentLibraryDataTableColumn['default'].extend({

		getSortValue: function getSortValue(row) {
			var key = this.get('contentPath');

			if (_ember['default'].isEmpty(key)) {
				return this.get('defaultEmptyCellValue');
			}

			var value = _ember['default'].get(row, key);

			if (this.get('type') === 'validations') {
				return _ember['default'].isEmpty(Object.keys(value));
			}

			if (this.get('type') === 'date') {
				return window.moment(value, 'YYYY-MM-DD');
			}
			return value;
		}

	});
});