define('component-library/components/payroll/state-taxes/future-tax-question', ['exports', 'ember', 'component-library/mixins/payroll/state-tax-mixin'], function (exports, _ember, _componentLibraryMixinsPayrollStateTaxMixin) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsPayrollStateTaxMixin['default'], {
		futureQuestions: null,
		question: null,
		showFutureChanges: false,
		isDisplayMode: false,
		futureQuestion: _ember['default'].computed('futureQuestions', 'question', function () {
			if (this.get('futureQuestions') != null) {
				return this.get('futureQuestions').findBy('questionKey', this.get('question.questionKey'));
			}
			return null;
		}),

		futureQuestionDisplayText: _ember['default'].computed('futureQuestion.answer', 'futureQuestion.questionType', function () {
			if (this.get('futureQuestion.answer') != null) {
				if (this.get('futureQuestion.questionType') == 'Boolean') {
					return this.get('futureQuestion.answer') ? "Yes" : "No";
				}
				if (this.get('futureQuestion.questionType') == 'Dropdown') {
					return this.get('question.dropdownContent').findBy('value', this.get('futureQuestion.answer')).name;
				}
			}
			return null;
		}),

		hasFutureAnswer: _ember['default'].computed('futureQuestion.answer', function () {
			return this.get('futureQuestion.answer') != null;
		}),

		futureEffectiveDate: _ember['default'].computed.alias('futureQuestion.futureEffectiveDate'),
		futureAvailableEffectiveDates: _ember['default'].computed.alias('futureQuestion.futureAvailableEffectiveDates'),

		onAnswerChange: _ember['default'].observer('futureQuestion.answer', 'futureEffectiveDate', function () {
			this.set('futureQuestion.needsUpdate', true);
			this.set('futureQuestion.isDelete', false);
		}),

		futureEffectiveDatesArray: _ember['default'].computed('futureQuestion.futureAvailableEffectiveDates', function () {
			if (this.get('futureQuestion.futureAvailableEffectiveDates')) {
				return this.getTaxDateOptions(this.get('futureQuestion.futureAvailableEffectiveDates'));
			}
		}),

		actions: {
			viewFutureChanges: function viewFutureChanges() {
				if (this.get('futureQuestion.answer') != null) {
					this.set('showFutureChanges', true);
				}
			},
			hideFutureChanges: function hideFutureChanges() {
				this.set('showFutureChanges', false);
			},
			addFutureQuestion: function addFutureQuestion(futureQuestion) {
				this.set('hasFutureAnswer', true);
				futureQuestion.needsUpdate = true;
				futureQuestion.isDelete = false;
			},
			deleteFutureQuestion: function deleteFutureQuestion(futureQuestion) {
				this.set('hasFutureAnswer', false);
				futureQuestion.needsUpdate = true;
				futureQuestion.isDelete = true;
			}
		}
	});
});