define('component-library/constants/z-payroll/z-payroll-tax-categories', ['exports'], function (exports) {
	'use strict';

	var _TAX_CATEGORY_VERBOSE_NAMES;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
		} else {
			obj[key] = value;
		}return obj;
	}

	var TAX_CATEGORIES = {
		EmploymentTax: 'employment_tax',
		UnemploymentTax: 'unemployment_tax',
		WorkersCompensation: 'workers_compensation'
	};

	exports.TAX_CATEGORIES = TAX_CATEGORIES;

	var TAX_CATEGORY_VERBOSE_NAMES = (_TAX_CATEGORY_VERBOSE_NAMES = {}, _defineProperty(_TAX_CATEGORY_VERBOSE_NAMES, TAX_CATEGORIES.EmploymentTax, 'Employment Tax'), _defineProperty(_TAX_CATEGORY_VERBOSE_NAMES, TAX_CATEGORIES.UnemploymentTax, 'Unemployment Tax'), _defineProperty(_TAX_CATEGORY_VERBOSE_NAMES, TAX_CATEGORIES.WorkersCompensation, 'Workers Compensation'), _TAX_CATEGORY_VERBOSE_NAMES);
	exports.TAX_CATEGORY_VERBOSE_NAMES = TAX_CATEGORY_VERBOSE_NAMES;
});