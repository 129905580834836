define('ember-table/views/multi-item-collection', ['exports', 'ember', 'ember-table/mixins/style-bindings'], function (exports, _ember, _emberTableMixinsStyleBindings) {
  'use strict';

  exports['default'] = _ember['default'].CollectionView.extend(_emberTableMixinsStyleBindings['default'], {
    styleBindings: 'width',
    itemViewClassField: null,

    createChildView: function createChildView(view, attrs) {
      var itemViewClassField = this.get('itemViewClassField');
      var itemViewClass = attrs.content.get(itemViewClassField);
      if (typeof itemViewClass === 'string') {
        if (/[A-Z]+/.exec(itemViewClass)) {
          // Global var lookup - 'App.MessagePreviewView'
          itemViewClass = _ember['default'].get(_ember['default'].lookup, itemViewClass);
        } else {
          // Ember CLI Style lookup - 'message/preview'
          itemViewClass = this.container.lookupFactory("view:" + itemViewClass);
        }
      }
      return this._super(itemViewClass, attrs);
    }
  });
});