define('z-inputs/components/z-text-field-phone', ['exports', 'z-inputs/components/z-text-field-base', 'z-inputs/templates/components/z-text-field-phone', 'z-inputs/utils/z-text-field-input-mask-mixin-factory'], function (exports, _zInputsComponentsZTextFieldBase, _zInputsTemplatesComponentsZTextFieldPhone, _zInputsUtilsZTextFieldInputMaskMixinFactory) {
  'use strict';

  var PHONE_MASK_PARAMS = ['isInternational'];
  var zTextFieldPhoneMixin = (0, _zInputsUtilsZTextFieldInputMaskMixinFactory['default'])(PHONE_MASK_PARAMS);

  exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend(zTextFieldPhoneMixin, {
    layout: _zInputsTemplatesComponentsZTextFieldPhone['default'],
    classNames: 'z-textField--phone',
    classNameBindings: ['isInternational:z-textField--phoneWithCountryCode'],
    type: 'phone',
    mask: '(999) 999-9999',
    pattern: '[0-9]*',

    countryCodeMask: '+99999',
    countryCode: null,
    isInternational: false,
    countryCodePlaceholder: 'Country Code'
  });
});