define('component-library/components/data-table', ['exports', 'ember', 'ember-table/components/ember-table', 'component-library/utils/globals', 'component-library/filters/filter-array'], function (exports, _ember, _emberTableComponentsEmberTable, _componentLibraryUtilsGlobals, _componentLibraryFiltersFilterArray) {
	'use strict';

	var KEY_EVENTS = {
		37: 'leftArrowPressed',
		38: 'upArrowPressed',
		39: 'rightArrowPressed',
		40: 'downArrowPressed'
	};

	exports['default'] = _emberTableComponentsEmberTable['default'].extend({
		attributeBindings: 'tabindex',
		tabindex: -1,
		collapseRowByDefault: false,
		indentationSize: 16,
		filters: _ember['default'].computed(function () {
			return _componentLibraryFiltersFilterArray['default'].create({ content: [] });
		}), // FilterArray

		data: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		groupings: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		click: function click(event, byPassShouldSelect) {
			var currentSelection;
			// if the selectionMode is 'single', remember the previous selection
			// and pass it along when `itemClicked` action is sent
			if (this.get('selectionMode') === 'single') {
				currentSelection = this.get('selection');
			}
			this._super(event);
			var row = this.getRowForEvent(event);
			if (!row || !row.get('content')) {
				return;
			}
			this.sendAction('itemClicked', row.get('content'), currentSelection);
		},

		didInsertElement: function didInsertElement() {
			var _this = this;

			if (this.isDestroyed) {
				return;
			}
			this._super();
			// this is a hack to trigger a rerender,
			// recalc of antiscroll dimensions happens prematurely and sometimes
			// obscures some of the table
			var resizeHasBeenCalled = false;
			var $window = _ember['default'].$(window);
			var triggerResize = function triggerResize() {
				if (!resizeHasBeenCalled) {
					$window.trigger('resize');
					resizeHasBeenCalled = true;
				}
				_ember['default'].run.later(_this, function () {
					$window.one('resize.ember-table-' + _this.get('elementId'), triggerResize);
					var $antiscroll = _this.$('.antiscroll-wrap');
					if ($antiscroll && $antiscroll.antiscroll) {
						$antiscroll.antiscroll().data('antiscroll').rebuild();
					}
				}, 800);
			};
			_ember['default'].run.once(this, triggerResize);

			if (this.get('ref')) {
				var parentController = this.get('parentView.controller');
				if (parentController) {
					parentController.set(this.get('ref'), this);
				}
			}
		},

		willDestroyElement: function willDestroyElement() {
			this._super();
			_ember['default'].$(window).off('resize.ember-table-' + this.get('elementId'));
		},

		columns: (function (key, value) {
			if (arguments.length > 1) {
				(value || []).forEach(function (column, index) {
					column.set('index', index);
				});
			}
			return value;
		}).property(),

		filteredData: _ember['default'].computed('data.[]', 'filters.[]', 'filters.apply', function () {
			var filters = this.get('filters');
			var data = this.get('data') || _ember['default'].A();

			if (!filters || !filters.length) {
				return data;
			}

			return filters.get('apply')(data);
		}),

		groupedData: (function () {
			var data = this.get('filteredData');
			var groupings = this.get('groupings');
			if (_ember['default'].isEmpty(groupings)) {
				return data || [];
			}
			var nesting = (0, _componentLibraryUtilsGlobals.getD3)().nest();
			groupings.forEach(function (groupingColumn) {
				nesting = nesting.key(function (row) {
					return groupingColumn.getCellContent(row);
				});
			});
			var tree = nesting.entries(data);

			// don't roll up or hide data if we aren't using groupings
			if (groupings.length) {
				this.rollup(tree);
			}
			return tree;
		}).property('filteredData.[]', 'groupings.@each'),

		sortedData: (function () {
			var data = this.get('groupedData');
			data = this.sortData(data);
			// don't flatten tree if no groupings (flatten tree resets indentation, when we specify it at the row level)
			if (this.get('groupings.length')) {
				data = this.flattenTree(data, 0);
			}
			return data;
		}).property('groupedData', 'groupings.@each', 'columns.@each.isSorted'),

		rollup: function rollup(tree) {
			var that = this;
			var collapseRowByDefault = this.get('collapseRowByDefault');
			var columns = this.get('columns');
			tree.forEach(function (node) {
				var children = _ember['default'].get(node, 'values');
				if (!_ember['default'].isEmpty(children)) {
					_ember['default'].set(node, 'isCollapsed', collapseRowByDefault);
					that.rollup(children);
					columns.forEach(function (column, i) {
						// enable row collapsing on the first column
						if (i === 0) {
							_ember['default'].set(node, 'isCollapsible', true);
						}
						column.setCellContent(node, column.rollup(node, children));
					});
				}
			});
		},

		sortData: function sortData(tree) {
			var sortColumn = this.get('columns').findBy('isSorted');
			if (!sortColumn) {
				return tree;
			}
			tree.forEach(function (node) {
				var children = _ember['default'].get(node, 'values');
				if (!_ember['default'].isEmpty(children)) {
					children.sort(function (a, b) {
						return sortColumn.comparator(a, b);
					});
				}
			});
			// NOTE: need to use toArray because tree could be DS records
			var rows = tree.toArray().sort(function (a, b) {
				return sortColumn.comparator(a, b);
			});
			return rows;
		},

		flattenTree: function flattenTree(tree, indentation) {
			var that = this;
			var rows = [];
			var indentationSize = this.get('indentationSize');
			var columns = this.get('columns');

			tree.forEach(function (node) {
				_ember['default'].set(node, 'indentation', indentation);
				var children = _ember['default'].get(node, 'values');
				if (_ember['default'].isEmpty(children)) {
					rows.pushObject(node);
				} else {
					rows.pushObject(node);
					_ember['default'].set(node, 'isLeaf', false);
					children.setEach('indentation', indentation + indentationSize);
					children.setEach('isLeaf', true);
					// recursively flatten children
					// NOTE: we need the recursive whether node is collapse or not
					// so that we can get the roll up properly
					var childrenRows = that.flattenTree(children, indentation + indentationSize);
					if (!_ember['default'].get(node, 'isCollapsed')) {
						rows.pushObjects(childrenRows);
					}
					// calculate rollup
					columns.forEach(function (column) {
						if (column.get('disableRollup')) {
							return;
						}
						column.setCellContent(node, column.rollup(node, children));
					});
				}
			});

			// assign index to row, this is for keyboard events
			rows.forEach(function (row, index) {
				_ember['default'].set(row, 'index', index);
			});
			return rows;
		},

		content: (function (key, value) {
			if (arguments.length === 1) {
				return this.get('sortedData');
			}
			return value;
		}).property('sortedData'),

		selectColumn: function selectColumn(column) {
			this.get('columns').setEach('isSelected', false);
			this.set('selectedColumn', column);
			column.set('isSelected', true);
		},

		keyDown: function keyDown(event) {
			var value = String.fromCharCode(event.keyCode);
			var methodName = KEY_EVENTS[event.keyCode];
			if (methodName) {
				this.get(methodName).apply(this, arguments);
				event.preventDefault();
				event.stopPropagation();
			}
			// tab is pressed
			else if (event.keyCode === 9) {
					if (event.shiftKey) {
						this.leftArrowPressed(event);
					} else {
						this.rightArrowPressed(event);
					}
					event.preventDefault();
					event.stopPropagation();
				}
				// if is alphanumeric or is enter
				else if (this.get('isEditable') && this.get('selection') && this.get('selectedColumn') && (/[a-zA-Z0-9]/.test(value) || event.keyCode === 13)) {
						this.set('selection.isEditing', true);
						this.set('selectedColumn.isEditing', true);
					}
		},

		upArrowPressed: function upArrowPressed(event) {
			var content = this.get('content');
			var selection = this.get('selection');
			var index = selection ? _ember['default'].get(selection, 'index') : 0;
			var prevIndex = Math.max(index - 1, 0);
			prevIndex = event.metaKey ? 0 : prevIndex;
			this.set('selection', content.objectAt(prevIndex));
			this.ensureRowVisible(prevIndex);
		},

		downArrowPressed: function downArrowPressed(event) {
			var content = this.get('content');
			var selection = this.get('selection');
			var index = selection ? _ember['default'].get(selection, 'index') : 0;
			var nextIndex = Math.min(index + 1, content.get('length') - 1);
			nextIndex = event.metaKey ? content.get('length') - 1 : nextIndex;
			this.set('selection', content.objectAt(nextIndex));
			this.ensureRowVisible(nextIndex);
		},

		leftArrowPressed: function leftArrowPressed(event) {
			var content = this.get('columns');
			var selection = this.get('selectedColumn');
			if (!selection) {
				return;
			}
			var index = _ember['default'].get(selection, 'index');
			var prevIndex = Math.max(index - 1, 0);
			prevIndex = event.metaKey ? 0 : prevIndex;
			this.selectColumn(content.objectAt(prevIndex));
			this.ensureColumnVisible(prevIndex);
		},

		rightArrowPressed: function rightArrowPressed(event) {
			var content = this.get('columns');
			var selection = this.get('selectedColumn');
			if (!selection) {
				return;
			}
			var index = _ember['default'].get(selection, 'index');
			var nextIndex = Math.min(index + 1, content.get('length') - 1);
			nextIndex = event.metaKey ? content.get('length') - 1 : nextIndex;
			this.selectColumn(content.objectAt(nextIndex));
			this.ensureColumnVisible(nextIndex);
		},

		ensureRowVisible: function ensureRowVisible(index) {
			var startIndex = this.get('_startIndex');
			var numRows = this.get('_numItemsShowing');
			var endIndex = startIndex + numRows;
			if (index <= startIndex) {
				this.scrollToRowIndex(index, true);
			} else if (index >= endIndex) {
				this.scrollToRowIndex(index, false);
			}
		},

		ensureColumnVisible: function ensureColumnVisible(index) {
			var numFixedColumns = this.get('fixedColumns.length');
			var $rightTableBlocks = _ember['default'].$('.et-right-table-block');
			var $scrollContainer = _ember['default'].$('.et-scroll-container');
			var $bodyHeaderCells = $rightTableBlocks.find('.et-header-cell');
			// get cell for index
			var $cell = _ember['default'].$($bodyHeaderCells[index - numFixedColumns]);
			if (_ember['default'].isEmpty($cell)) {
				return;
			}
			var leftOffset = $cell.position().left;
			// cell left + cell width - view port width
			var rightOffset = leftOffset + $cell.outerWidth() - this.get('_tableBlockWidth');
			if (this.get('_hasVerticalScrollbar')) {
				rightOffset += this.get('_scrollbarSize');
			}
			if (rightOffset > $rightTableBlocks.scrollLeft()) {
				$rightTableBlocks.scrollLeft(rightOffset);
				$scrollContainer.scrollLeft(rightOffset);
			} else if (leftOffset < $rightTableBlocks.scrollLeft()) {
				$rightTableBlocks.scrollLeft(leftOffset);
				$scrollContainer.scrollLeft(leftOffset);
			}
		},

		scrollToRowIndex: function scrollToRowIndex(index, alignTop) {
			var scrollTop;
			var rowHeight = this.get('rowHeight');
			// TODO(Peter): add js class
			var $container = _ember['default'].$('.et-body-container');
			if (alignTop) {
				scrollTop = index * rowHeight;
				$container.scrollTop(scrollTop);
			} else {
				var bodyHeight = this.get('_bodyHeight');
				scrollTop = (index + 1) * rowHeight - bodyHeight;
				$container.scrollTop(scrollTop);
			}
		},

		actions: {
			sortByColumn: function sortByColumn(column, sortAscending) {
				this.get('columns').setEach('isSorted', false);
				column.set('sortAscending', sortAscending);
				column.set('isSorted', true);

				this.set('content', this.get('sortedData'));
				this.sendAction('sortColumn', column);
			},

			toggleSortColumn: function toggleSortColumn(column) {
				var sortAscending = column.get('sortAscending');
				this.send('sortByColumn', column, !sortAscending);
			},

			toggleCollapse: function toggleCollapse(row) {
				row.toggleProperty('isCollapsed');
				// need to notify property change since groupedData.@each.isCollapsed does not trigger a recompute for some reason...
				this.notifyPropertyChange('sortedData');
				this.set('content', this.get('sortedData'));
				this.sendAction('toggleCollapse', row);
			}
		}
	});
});