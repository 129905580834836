define('component-library/components/charts/mdv', ['exports', 'ember', 'component-library/utils/computed-props/pretty-currency'], function (exports, _ember, _componentLibraryUtilsComputedPropsPrettyCurrency) {
	'use strict';

	var radians = Math.PI * 2;

	exports['default'] = _ember['default'].Component.extend({
		templateName: 'components/charts/mdv',
		size: 160,
		topMargin: 0,
		innerCirclePercent: 0.5375,

		total: (function () {
			return this.get('deduction') + this.get('contribution');
		}).property('deduction', 'contribution'),
		prettyTotal: (0, _componentLibraryUtilsComputedPropsPrettyCurrency['default'])('total'),

		totalFontSize: (function () {
			var strLen = this.get('prettyTotal.length');
			if (strLen > 6) {
				return 20;
			}
			if (strLen > 5) {
				return 21;
			}
			if (strLen > 4) {
				return 23;
			}
			return 25;
		}).property('prettyTotal'),

		prettyDeduction: (0, _componentLibraryUtilsComputedPropsPrettyCurrency['default'])('deduction'),
		prettyContribution: (0, _componentLibraryUtilsComputedPropsPrettyCurrency['default'])('contribution'),

		deductionText: (function () {
			return {
				line1: 'You pay ' + this.get('prettyDeduction')
			};
		}).property('prettyDeduction'),

		contributionText: (function () {
			return {
				line1: 'Company pays ' + this.get('prettyContribution')
			};
		}).property('prettyContribution'),

		r1: (function () {
			return this.get('size') / 2;
		}).property('size'),

		cx: _ember['default'].computed.oneWay('r1'),
		cy: (function () {
			return this.get('r1') + this.get('topMargin');
		}).property('r1', 'topMargin'),

		r2: (function () {
			return this.get('r1') * this.get('innerCirclePercent');
		}).property('r1', 'innerCirclePercent'),

		largeArcTooltipX: _ember['default'].computed.oneWay('cx'),
		largeArcTooltipY: (function () {
			return this.get('cy') - this.get('r1');
		}).property('cy', 'r1'),

		smallArcTooltipX: _ember['default'].computed.oneWay('cx'),
		smallArcTooltipY: (function () {
			return this.get('cy') + this.get('r2');
		}).property('cy', 'r2'),

		smallHalfArc: (function () {
			var d = this.get('deduction');
			var c = this.get('contribution');
			var small = Math.min(d, c) / (d + c);
			return small / 2 * radians;
		}).property('deduction', 'contribution'),

		startAngle: (function () {
			return Math.PI - this.get('smallHalfArc');
		}).property('smallHalfArc'),

		endAngle: (function () {
			return Math.PI + this.get('smallHalfArc');
		}).property('smallHalfArc'),

		x1: (function () {
			var r1 = this.get('r1');
			return this.get('cx') + r1 * Math.sin(this.get('startAngle'));
		}).property('cx', 'r1', 'startAngle'),

		y1: (function () {
			var r1 = this.get('r1');
			return this.get('cy') - r1 * Math.cos(this.get('startAngle'));
		}).property('cy', 'r1', 'startAngle'),

		x2: (function () {
			var r1 = this.get('r1');
			return this.get('cx') + r1 * Math.sin(this.get('endAngle'));
		}).property('cx', 'r1', 'endAngle'),

		y2: (function () {
			var r1 = this.get('r1');
			return this.get('cy') - r1 * Math.cos(this.get('endAngle'));
		}).property('cy', 'r1', 'endAngle'),

		x3: (function () {
			var r2 = this.get('r2');
			return this.get('cx') + r2 * Math.sin(this.get('endAngle'));
		}).property('cx', 'r2', 'endAngle'),

		y3: (function () {
			var r2 = this.get('r2');
			return this.get('cy') - r2 * Math.cos(this.get('endAngle'));
		}).property('cy', 'r2', 'endAngle'),

		x4: (function () {
			var r2 = this.get('r2');
			return this.get('cx') + r2 * Math.sin(this.get('startAngle'));
		}).property('cx', 'r2', 'startAngle'),

		y4: (function () {
			var r2 = this.get('r2');
			return this.get('cy') - r2 * Math.cos(this.get('startAngle'));
		}).property('cy', 'r2', 'startAngle'),

		contributionIsLarger: (function () {
			return this.get('contribution') > this.get('deduction');
		}).property('deduction', 'contribution'),

		largeArcText: (function () {
			return this.get('contributionIsLarger') ? this.get('contributionText') : this.get('deductionText');
		}).property('contributionIsLarger', 'deductionText', 'contributionText'),

		smallArcText: (function () {
			return !this.get('contributionIsLarger') ? this.get('contributionText') : this.get('deductionText');
		}).property('contributionIsLarger', 'deductionText', 'contributionText'),

		bottomY: (function () {
			return this.get('cy') + this.get('r1');
		}).property('cy', 'r1')

	});
});