define('component-library/components/routing-number-field', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		bankName: null,
		value: null,

		didInsertElement: function didInsertElement() {
			this.getBankDetails();
		},

		getBankDetails: function getBankDetails() {
			var _this = this;

			var value = this.get('value');
			if (_ember['default'].isEmpty(value)) {
				this.set('bankName', null);
				return;
			}
			_ember['default'].getJSON('/custom_api/lookup_routing_number/' + value).then(function (data) {
				var result = data.name;
				_this.set('bankName', result.toLowerCase());
				_this.set('errorText', ''); // TODO(DQ): need better inside and outside validations
			})['catch'](function () {
				_this.set('bankName', null);
				_this.set('errorText', "Bank routing number not found.");
			});
		},

		actions: {
			focusedOut: function focusedOut() {
				this.getBankDetails();
				this.sendAction('focusedOut');
			}
		}
	});
});