define('z-inputs/components/z-checkbox', ['exports', 'ember', 'z-inputs/templates/components/z-checkbox'], function (exports, _ember, _zInputsTemplatesComponentsZCheckbox) {
	'use strict';

	var Component = _ember['default'].Component;
	var computed = _ember['default'].computed;
	var observer = _ember['default'].observer;

	exports['default'] = Component.extend({
		layout: _zInputsTemplatesComponentsZCheckbox['default'],
		classNames: ['z-checkbox', 'js-test-z-checkbox'],
		classNameBindings: ['isDisabled'],

		// attrs
		isDisabled: false,
		isChecked: false,
		value: null,
		changed: null, // the name of an action, or closure action, to call when the input is clicked
		tooltipText: null,
		stopPropagationOnClick: false,

		labelClass: computed('isDisabled', function () {
			return this.get('isDisabled') ? 'is-disabled' : ' ';
		}),

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			this.$('input').prop('checked', this.get('isChecked'));
			this.$('input').prop('disabled', this.get('isDisabled'));
		},

		isDisabledObserver: observer('isDisabled', function () {
			this.$('input').prop('disabled', this.get('isDisabled'));
		}),

		isCheckedObserver: observer('isChecked', function () {
			this.$('input').prop('checked', this.get('isChecked'));
		}),

		// if no action subscribed, use two-way binding
		_checkedChanged: function _checkedChanged(inputValue) {
			if (!this.get('changed')) {
				this.set('isChecked', inputValue);
			} else {
				this.sendAction('changed', inputValue, this.get('value'));
			}
		},

		// fires automatically when checkbox input changes
		change: function change() {
			var inputValue = this.$('input').prop('checked');

			this._checkedChanged(inputValue);
		},

		actions: {
			// needed to prevent the click from propagating since we use an observer in this component to handle the change
			clickedCheckbox: function clickedCheckbox(e) {
				if (this.get('stopPropagationOnClick')) {
					e.stopPropagation();
				}
			},

			// fires when label is clicked
			clickedLabel: function clickedLabel() {
				if (this.get('isDisabled')) {
					return;
				}

				var inputValue = this.$('input').prop('checked');
				this.$('input').prop('checked', !inputValue); // this line doesn't trigger the `change` hook

				this._checkedChanged(!inputValue);
			}
		}
	});
});