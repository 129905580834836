define('component-library/filters/helpers', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
	'use strict';

	// todo only use when async
	// TODO(ed): Need to do this for each async model. Argh async
	var updateModelSelectionsOnModelAndCountsDidChange = function updateModelSelectionsOnModelAndCountsDidChange(modelOptions, selections) {
		// Think about using promiseArrays EVERYWHERE, but we'll have flashing problems
		var selectionIds = selections.getEach('id');
		selections.clear();
		modelOptions.forEach(function (modelOption) {
			if (selectionIds.contains(modelOption.get('model.id'))) {
				selections.pushObject(modelOption.get('model'));
			}
		});
	};

	exports.updateModelSelectionsOnModelAndCountsDidChange = updateModelSelectionsOnModelAndCountsDidChange;

	var realPromiseObject = function realPromiseObject(typeKey, id) {
		var store = _emberData['default'].appStore;
		var type = store.modelFor(typeKey);
		var record = store.recordForId(type, id);
		var promiseObject = store._findByRecord(record);
		promiseObject.set('content', record);
		return promiseObject;
	};

	exports.realPromiseObject = realPromiseObject;

	var modelCountList = function modelCountList(content, modelKey, options) {
		// options: modelKeyId, typeKey, optionLabel, nullAsId, optionsContentSkipNulls, showCountInLabel // TODO: current value set inside the countMap
		options = options || {};
		var countMap = options.countMap || new _ember['default'].MapWithDefault({
			defaultValue: function defaultValue() {
				return 0;
			}
		});
		content.forEach(function (element) {
			var model = _ember['default'].get(element, options.modelKeyId || modelKey);
			if (options.nullAsId && model === null) {
				model = String(null);
			}
			if (options.optionsContentSkipNulls && model === null) {
				return;
			}
			// TODO(ed): async :O
			countMap.set(model, countMap.get(model) + 1);
		});
		var data = [];
		var promises = [];
		countMap.forEach(function (count, model) {
			var modelValue = model;
			if (options.modelKeyId) {
				modelValue = realPromiseObject(options.typeKey, model);
				promises.pushObject(modelValue);
			}
			if (options.optionLabel != null) {
				var optionLabelKey = _ember['default'].$.isFunction(options.optionLabel) ? '' : options.optionLabel;
				data.pushObject(_ember['default'].Object.createWithMixins({
					id: model || modelValue,
					model: modelValue,
					modelCount: count,
					label: (function () {
						// TODO(ed): Unfortunately, this isn't bound
						var label = _ember['default'].$.isFunction(options.optionLabel) ? options.optionLabel.call(this, this.get('model')) : this.get('model.' + options.optionLabel);
						if (model === String(null) && options.nullLabel) {
							label = options.nullLabel;
						}
						if (options.showCountInLabel) {
							return "(" + count + ") " + label;
						} else {
							return label;
						}
					}).property('model.' + options.optionLabelKey)
				}));
			} else {
				data.pushObject(_ember['default'].Object.create({
					id: model || modelValue,
					model: modelValue,
					modelCount: count,
					label: modelValue
				}));
			}
		});
		data.promise = _ember['default'].RSVP.all(promises);
		return data;
	};
	exports.modelCountList = modelCountList;
});