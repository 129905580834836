define('z-modals/components/z-modal-header', ['exports', 'ember', 'z-modals/templates/components/z-modal-header', 'z-modals/components/z-modal'], function (exports, _ember, _zModalsTemplatesComponentsZModalHeader, _zModalsComponentsZModal) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var zModal = this.nearestOfType(_zModalsComponentsZModal['default']);

      if (zModal) {
        this.set('_zModal', zModal);
      } else {
        _ember['default'].debug('z-modal-header can only be used inside the context of a z-modal');
      }
    },
    actions: {
      close: function close() {
        this.get('_zModal').sendAction('close');
      }
    },
    layout: _zModalsTemplatesComponentsZModalHeader['default'],
    tagName: 'header',
    classNames: ['z-modal-header', 'js-modal-header']
  });
});