define('component-library/mixins/validation-manager', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		// DON'T SET VALUE - WILL OVERRIDE ANY COMPUTEDS ON WHATEVER YOU MIX INTO
		// value: null,
		validations: null,

		classNameBindings: ["useCustomErrorClass"],
		optional: true,

		allowEmpty: true,

		isCompleted: _ember['default'].computed(function () {
			var value = this.get('value');
			var optional = this.get('optional') && this.get('allowEmpty');
			if (!optional && (!value || String(value).length === 0)) {
				return false;
			} else {
				return true;
			}
		}).property('value', 'optional', 'allowEmpty'),

		isValid: _ember['default'].computed.alias('isCompleted'),

		error: _ember['default'].computed(function () {
			var message;
			var placeholder = this.get('placeholder');
			if (placeholder) {
				message = "Please fix your input for " + placeholder;
			} else {
				message = "Please fix your input";
			}

			return {
				reason: message
			};
		}).property('placeholder'),

		useCustomErrorClass: (function () {
			if (!this.get('isValid') && this.get('customErrorClass')) {
				return this.get('customErrorClass');
			}
		}).property('isValid', 'customErrorClass'),

		manageValidations: (function () {
			var validations = this.get('validations');
			if (!validations) {
				return;
			}

			var error = this.get('error');

			if (this.get('isValid')) {
				validations.remove(error);
			} else {
				validations.add(error);
			}
		}).observes('isValid'),

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			this.manageValidations();
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			var validations = this.get('validations');
			if (!validations) {
				return;
			}

			var error = this.get('error');
			validations.remove(error);
		}
	});
});