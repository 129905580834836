define('component-library/data-table/data-table-cell-wrapper', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		templateName: 'data-table/data-table-cell-wrapper',
		row: null,
		column: null,
		actionName: null,
		classNames: ['u-clearfix', 'u-fillHorizontal'],

		collapseInner: false,

		_isFirstColumn: _ember['default'].computed.equal('column.index', 0),

		// determines whether or not to render the space for the toggle when there's grouping involved
		_renderCollapseToggle: (function () {
			// if its collapsible or is designated as a leaf, there must be grouping involved, so we must render
			var isCollapsible = this.get('row.isCollapsible') || this.get('row.isLeaf');
			if (this.get('column')) {
				return isCollapsible && this.get('_isFirstColumn');
			} else {
				return isCollapsible;
			}
		}).property('row.isCollapsible', 'row.isLeaf', 'column', '_isFirstColumn'),

		// determines whether or not to reveal the toggle
		_revealCollapseToggle: (function () {
			// if there's grouping involved, only reveal if it's collapsible and not a leaf
			return this.get('row.isCollapsible') && !this.get('row.isLeaf');
		}).property('_renderCollapseToggle', 'row.isLeaf'),

		_paddingStyle: (function () {
			return 'padding-left:' + this.get('row.indentation') + 'px;';
		}).property('row.indentation'),

		actions: {
			toggleCollapse: function toggleCollapse(row) {
				this.sendAction('toggleCollapse', row);
			},
			bubbleUpAction: function bubbleUpAction(row) {
				var actionName = this.get('actionName');
				this.sendAction(actionName, row);
			}
		}
	});
});