define('component-library/constants/payroll', ['exports'], function (exports) {
	'use strict';

	var SIGNATORY_TITLES = ['Owner', 'President', 'Vice President', 'Treasurer', 'Corporate Officer', 'Partner', 'Member'];

	exports.SIGNATORY_TITLES = SIGNATORY_TITLES;

	var COMPANY_TYPES = [{ value: 'CO', label: 'Corporation' }, { value: 'LC', label: 'LLC' }, { value: 'PA', label: 'Partnership' }, { value: 'SC', label: 'S-Corporation' }, { value: 'SP', label: 'Sole Proprietorship' }, { value: 'LP', label: 'LLP' }, { value: 'NP', label: 'Non-Profit Corporation' }, { value: 'SM', label: 'Single-Member LLC' }];

	exports.COMPANY_TYPES = COMPANY_TYPES;

	var COMPENSATION_TYPES = {
		HOURLY: 'H'
	};

	exports.COMPENSATION_TYPES = COMPENSATION_TYPES;

	var ZPCOMPANY_PAY_ITEM_TYPES = {
		earning: 'earning',
		deduction: 'deduction',
		contribution: 'contribution',
		reimbursement: 'reimbursement'
	};

	exports.ZPCOMPANY_PAY_ITEM_TYPES = ZPCOMPANY_PAY_ITEM_TYPES;

	var ZPCOMPANY_PAY_ITEM_STATES = {
		earning: 'isAddingNewEarningPayItem',
		deduction: 'isAddingNewDeductionPayItem',
		contribution: 'isAddingNewContributionPayItem',
		reimbursement: 'isAddingNewReimbursementPayItem'
	};

	exports.ZPCOMPANY_PAY_ITEM_STATES = ZPCOMPANY_PAY_ITEM_STATES;

	var ZPCOMPANY_PAY_ITEM_DROPDOWN_SELECTIONS = {
		earning: 'newZPCompanyEarningType',
		deduction: 'newZPCompanyDeductionType',
		contribution: 'newZPCompanyContributionType',
		reimbursement: 'newZPCompanyReimbursementType'
	};

	exports.ZPCOMPANY_PAY_ITEM_DROPDOWN_SELECTIONS = ZPCOMPANY_PAY_ITEM_DROPDOWN_SELECTIONS;

	var TaxPayee = {
		EMPLOYEE: 'EE',
		EMPLOYER: 'ER'
	};

	exports.TaxPayee = TaxPayee;

	var ExemptionStatus = {
		IS_NOT_EXEMPT: 'NEX',
		IS_EXEMPT: 'EX',
		REIMBURSABLE: 'REIM'
	};

	exports.ExemptionStatus = ExemptionStatus;

	// Following categories are required till all the garnishment will be calculation enabled
	var CALCULATION_ENABLED_CATEGORIES = ['childSupport', 'federalTaxLevy', 'stateTaxLevy', 'creditorGarnishment', 'defaultedStudentLoan', 'spousalSupport'];
	exports.CALCULATION_ENABLED_CATEGORIES = CALCULATION_ENABLED_CATEGORIES;

	var LEGACY_CATEGORIES = ['other', 'childSupport', 'defaultedStudentLoan', 'taxes', 'unpaidCourtCosts'];

	exports.LEGACY_CATEGORIES = LEGACY_CATEGORIES;

	var DEDUCTION_IRS_LIMIT = ['health_savings', 'health_savings_catch_up', 'health_savings_family', 'health_savings_family_catch_up', 'transit_benefits', 'parking_benefits'];
	exports.DEDUCTION_IRS_LIMIT = DEDUCTION_IRS_LIMIT;

	var IS_GARNISHMENT_PAYMENT_ENABLED_URL = '/custom_api/z_payroll/is_garnishment_payment_enabled/';
	exports.IS_GARNISHMENT_PAYMENT_ENABLED_URL = IS_GARNISHMENT_PAYMENT_ENABLED_URL;

	var ZPAY_401K_LIMITS_HELP_URL = 'https://help.zenefits.com/401(k)/Managing_a_Company_401(k)_and_Risk_and_Compliance/2018_contribution_limit_for_401(k)s/';

	exports.ZPAY_401K_LIMITS_HELP_URL = ZPAY_401K_LIMITS_HELP_URL;

	var PRENOTE_OPTIN_URL = '/custom_api/z_payroll/prenote/opt_in/';
	exports.PRENOTE_OPTIN_URL = PRENOTE_OPTIN_URL;

	var PRENOTE_OPTOUT_URL = '/custom_api/z_payroll/prenote/opt_out/';
	exports.PRENOTE_OPTOUT_URL = PRENOTE_OPTOUT_URL;

	var IS_PRENOTE_ENABLED_URL = '/custom_api/z_payroll/prenote/is_enabled/';
	exports.IS_PRENOTE_ENABLED_URL = IS_PRENOTE_ENABLED_URL;

	var CREATE_PRENOTE_URL = '/custom_api/z_payroll/prenote/create_prenote_for_employee_bank/';

	exports.CREATE_PRENOTE_URL = CREATE_PRENOTE_URL;

	var STATES_IN_USA = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

	exports.STATES_IN_USA = STATES_IN_USA;

	var _PAY_RUN_CHECK_DATE_VALIDATIONS = {
		error_check_date_invalid_format: 'Please enter a valid date in the mm/dd/yyyy format.',
		error_check_date_invalid_business_day: 'The check date can’t be on a weekend or holiday.',
		error_check_date_invalid_not_offcycle: 'It takes %BLOCK_PERIOD% business days to process payroll. %NEAREST_CHECK_DATE% is the earliest you can pay your people.',
		error_check_date_invalid_direct_deposit: 'It takes %BLOCK_PERIOD% business days to process direct deposits. If you want to pay your people on %CHECK_DATE%, you’ll have to pay them by paper check, and your payroll taxes can\'t exceed $20,000.',
		error_check_date_invalid_direct_deposit_past_approval_deadline: 'It’s past the 5 PM deadline for a same-day check date. Tomorrow is the earliest you can pay your people.',
		error_check_date_invalid_direct_deposit_switch_off: 'To process direct deposits, the check date needs to be at least %BLOCK_PERIOD% business days from today.',
		error_check_date_after_three_month: 'The check date can’t be more than 3 months in the future.',
		error_check_date_after_year_end: 'The check date needs to be in the current year.',
		error_check_date_before_today: 'The check date can’t be in the past.',
		'default': 'We ran into an error. Please try again.'
	};

	exports._PAY_RUN_CHECK_DATE_VALIDATIONS = _PAY_RUN_CHECK_DATE_VALIDATIONS;

	var _PAY_RUN_PAY_PERIOD_VALIDATIONS = {
		invalidPayPeriodStart: 'Please enter a valid start date.',
		invalidPayPeriodEnd: 'Please enter a valid end date.',
		invalidPayPeriodEndAfterStartDate: 'Please enter a pay period end date that is after the start date.',
		invalidPayFrequency: 'Please select a time-based rate.'
	};

	exports._PAY_RUN_PAY_PERIOD_VALIDATIONS = _PAY_RUN_PAY_PERIOD_VALIDATIONS;

	var _PAY_RUN_VALIDATIONS = {
		payRunNameErrorText: 'Please enter a name for this one-time payroll.',
		payRunNameContractorErrorText: 'Please enter a name for this one-time payment.',
		selectPayRunErrorText: 'Select a pay run to clone.',
		selectPayRunContractorErrorText: 'Select a payment to clone.',
		employeeIdsErrorText: 'Please select individuals.',
		shouldIncludeDeductionsErrorText: 'Please select at least one deduction or contribution.'
	};

	exports._PAY_RUN_VALIDATIONS = _PAY_RUN_VALIDATIONS;

	var _CREATE_PAY_RUN_API_ERRORS_CODES = {
		invalidFilterSelection: 'InvalidFilterSelection'
	};

	exports._CREATE_PAY_RUN_API_ERRORS_CODES = _CREATE_PAY_RUN_API_ERRORS_CODES;

	var DEDUCTION_401K_CATEGORIES = ['401k', '401k_catchup', 'roth_401k', 'roth_401k_catchup', 'loan_401k', 'loan_401k_2', 'loan_401k_3', 'loan_401k_4', 'loan_401k_5'];
	exports.DEDUCTION_401K_CATEGORIES = DEDUCTION_401K_CATEGORIES;

	var COMBINED_401K_CATEGORIES = ['401k', '401k_catchup'];
	exports.COMBINED_401K_CATEGORIES = COMBINED_401K_CATEGORIES;
});