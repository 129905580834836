define('component-library/components/payroll/smp/sync-diff-table/banks-viewer', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		'data': null,

		'banks': (function () {
			var data = this.get('data');
			var banks = [];
			if (!data) {
				return banks;
			}
			try {
				data = JSON.parse(data);
			} catch (e) {
				data = [];
			}
			if (!_ember['default'].isArray(data) || !data.length) {
				return banks;
			}
			data.forEach(function (bank, idx) {
				bank.isPrimary = false;
				bank.idx = idx + 1;
				bank.accountType = bank.bankAccountType === 'C' ? 'Checking' : 'Savings';
				if (bank.percentagePerPaycheck !== null) {
					bank.share = bank.percentagePerPaycheck + '%';
				} else if (bank.amountPerPaycheck !== null) {
					bank.share = '$' + bank.amountPerPaycheck;
				} else {
					bank.share = 'not specified';
				}
			});
			var primary = data[data.length - 1];
			primary.isPrimary = true;
			return [primary].concat(data.splice(0, data.length - 1));
		}).property('data')
	});
});