define('component-library/constants/census-constants', ['exports'], function (exports) {
	'use strict';

	var EMPLOYMENT_TYPE_OPTIONS = [{ value: 'FT', label: 'Full-time' }, { value: 'PT', label: 'Part-time' }, { value: 'TP', label: 'Intern' }];

	exports.EMPLOYMENT_TYPE_OPTIONS = EMPLOYMENT_TYPE_OPTIONS;

	var COMPENSATION_TYPE_OPTIONS = [{ value: 'S', label: 'Salary' }, { value: 'H', label: 'Hourly' }];

	exports.COMPENSATION_TYPE_OPTIONS = COMPENSATION_TYPE_OPTIONS;
	exports['default'] = {
		EMPLOYMENT_TYPE_OPTIONS: EMPLOYMENT_TYPE_OPTIONS,
		COMPENSATION_TYPE_OPTIONS: COMPENSATION_TYPE_OPTIONS
	};

	var CENSUS_STATUS_ACTIVE = 'ACT';
	var CENSUS_STATE_EXPIRED = 'EXP';
	var CENSUS_STATUS_COMPLETE = 'CPT';
	var CENSUS_STATUS_PROCESSING = 'PRC';
	var CENSUS_STATUS_EXCEPTION = 'EXC';

	var CENSUS_STATUSES = {
		ACTIVE: CENSUS_STATUS_ACTIVE,
		EXPIRED: CENSUS_STATE_EXPIRED, // Front-end should never ever see Expired
		PROCESSING: CENSUS_STATUS_PROCESSING,
		COMPLETE: CENSUS_STATUS_COMPLETE,
		EXCEPTION: CENSUS_STATUS_EXCEPTION
	};

	exports.CENSUS_STATUSES = CENSUS_STATUSES;

	var ZENEFITS_ID = 'Zenefits Use Only';
	exports.ZENEFITS_ID = ZENEFITS_ID;
});