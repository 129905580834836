define('component-library/data-table/cells/expand-table-cell', ['exports', 'ember', 'ember-table/views/table-cell', 'component-library/modals/zevents/json-payload-modal'], function (exports, _ember, _emberTableViewsTableCell, _componentLibraryModalsZeventsJsonPayloadModal) {
	'use strict';

	exports['default'] = _emberTableViewsTableCell['default'].extend({
		ajax: _ember['default'].inject.service(),

		templateName: 'data-table/cells/expand-table-cell',

		jsonPayload: _ember['default'].computed('row.payload', function () {
			return JSON.stringify(this.get('row.payload'), null, 3);
		}),

		click: function click(event) {
			_componentLibraryModalsZeventsJsonPayloadModal['default'].show({
				title: 'Payload',
				message: this.get('jsonPayload')
			});
		}
	});
});