define('z-inputs/components/z-text-field-time', ['exports', 'ember', 'z-inputs/components/z-text-field-base', 'z-inputs/templates/components/z-text-field-time'], function (exports, _ember, _zInputsComponentsZTextFieldBase, _zInputsTemplatesComponentsZTextFieldTime) {
  'use strict';

  exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend({
    layout: _zInputsTemplatesComponentsZTextFieldTime['default'],
    classNames: 'z-textField--time',
    type: 'time',
    mask: 'hh:mm t',

    hidePlaceholderOnFocus: true,
    tooltipText: null,

    formattedTimeValue: _ember['default'].computed('value', {
      get: function get() {
        return this.get('value') || '';
      },
      set: function set(key, value) {
        var newValue = value.toString().replace(/\s+/g, '');

        this.set('value', newValue);
        return value;
      }
    })
  });
});