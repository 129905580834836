define('component-library/initializers/switches-service', ['exports'], function (exports) {
	// NOTE: we don't move this to ui-infra since it's only required here for backward
	// compat. Going forward we avoid global-injections in favor of explicit injections
	'use strict';

	exports['default'] = {
		name: 'switches-service',
		initialize: function initialize(container, application) {
			application.inject('route', 'switches', 'service:switches');
			application.inject('controller', 'switches', 'service:switches');
			application.inject('component', 'switches', 'service:switches');
		}
	};
});