define('component-library/timeline/utils/status', ['exports'], function (exports) {
  'use strict';

  var newHireStatusTypes = ['waiting_on_app', 'no_action', 'signed', 'declined', 'complete'];
  exports.newHireStatusTypes = newHireStatusTypes;

  var changeStatusTypes = ['created', 'approved', 'declined', 'completed'];
  exports.changeStatusTypes = changeStatusTypes;

  var employeeTerminationStatusTypes = ['requested', 'approved', 'processed'];
  exports.employeeTerminationStatusTypes = employeeTerminationStatusTypes;

  var deductionStatusTypes = ['requested', 'realized', 'catchup'];
  exports.deductionStatusTypes = deductionStatusTypes;

  var ptoRequestStatusTypes = ['requested', 'recorded', 'approved', 'edited', 'taken', 'cancelled', 'denied', 'deleted', 'refunded', 'undeleted'];
  exports.ptoRequestStatusTypes = ptoRequestStatusTypes;

  var ptoBalanceAdjustmentStatusTypes = ['adjustment', 'set', 'import', 'rollover', 'reset', 'migration'];
  exports.ptoBalanceAdjustmentStatusTypes = ptoBalanceAdjustmentStatusTypes;

  var employeeHealthEventStatusTypes = ['ENROLL_STARTED', 'ENROLL_COMPLETED', 'ENROLL_NOT_STARTED'];
  exports.employeeHealthEventStatusTypes = employeeHealthEventStatusTypes;

  var companyHealthEventStatusTypes = ['ENROLL_APPROVED', 'ENROLL_COMPLETE'];
  exports.companyHealthEventStatusTypes = companyHealthEventStatusTypes;

  var fulfillmentHealthEventStatusTypes = ['ARG_WQ_CREATION', 'FARG_WQ_CREATION', 'ARG_WQ_CLOSURE', 'FARG_WQ_CLOSURE'];
  exports.fulfillmentHealthEventStatusTypes = fulfillmentHealthEventStatusTypes;
});