define('component-library/constants/z-payroll/z-payroll-pay-types', ['exports'], function (exports) {
  'use strict';

  var UnsupportedEarningCategories = ['pto'];

  exports.UnsupportedEarningCategories = UnsupportedEarningCategories;

  var UnsupportedBenefitCategories = ['dependent_care', 'imputed_income_life', 'other_taxable', 'scorp_combined', 'simple_401k', 'simple_401k_catchup'];

  exports.UnsupportedBenefitCategories = UnsupportedBenefitCategories;

  var UnsupportedDeductionCategories = UnsupportedBenefitCategories.concat([]);

  exports.UnsupportedDeductionCategories = UnsupportedDeductionCategories;

  var UnsupportedContributionCategories = UnsupportedBenefitCategories.concat(['loan_401k', 'loan_401k_2', 'loan_401k_3', 'loan_401k_4', 'loan_401k_5']);
  exports.UnsupportedContributionCategories = UnsupportedContributionCategories;
});