define('component-library/components/pay-schedule-legend', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-payScheduleLegend'],
		classNameBindings: ['isHorizontal:z-payScheduleLegend--side', 'isHorizontal:u-bumperLeft'],
		paySchedule: null,
		showDetails: false,
		isHorizontal: false,

		// The following are here for other implementations (TA/SMP) if needed
		hideDeleteIcon: false,
		hideEditIcon: false,
		hideEmployeeIcon: false,
		hideIcons: false,
		holidayShiftDetail: (function () {
			var isholidayShiftAfter = this.get('paySchedule.holidayShift') == 'AFTER';
			return isholidayShiftAfter ? '(workers paid next business day)' : '(workers paid in advance)';
		}).property('paySchedule.holidayShift'),

		actions: {
			editPaySchedule: function editPaySchedule(paySchedule) {
				this.sendAction('editPaySchedule', paySchedule);
			},

			deletePaySchedule: function deletePaySchedule(paySchedule) {
				this.sendAction('deletePaySchedule', null, paySchedule);
			},
			showAssignedEmployees: function showAssignedEmployees(paySchedule) {
				this.sendAction('showAssignedEmployees', paySchedule);
			},
			showReadOnly: function showReadOnly(paySchedule) {
				this.sendAction('showReadOnly', paySchedule);
			}
		}
	});
});