define('component-library/data-table/cells/checkbox-table-cell', ['exports', 'ember', 'ember-table/views/table-cell'], function (exports, _ember, _emberTableViewsTableCell) {
	'use strict';

	exports['default'] = _emberTableViewsTableCell['default'].extend({
		templateName: 'data-table/cells/checkbox-table-cell',

		isDisabled: (function () {
			var disabledPath = this.get('column.disabled');
			return !_ember['default'].isEmpty(disabledPath) && this.get('row.' + disabledPath);
		}).property('column.disabled'),

		checked: (function (key, value) {
			var row = this.get('row');
			var column = this.get('column');
			var contentPath = column.get('contentPath');
			if (arguments.length === 1) {
				return _ember['default'].get(row, contentPath);
			} else {
				_ember['default'].set(row, contentPath, value);
				return value;
			}
		}).property('cellContent'),

		click: function click(event) {
			// NOTE: we do not want to propagate the click
			event.stopPropagation();
		}

	});
});