define('ember-table/views/body-table-container', ['exports', 'ember', 'ember-table/views/table-container', 'ember-table/mixins/show-horizontal-scroll', 'ember-table/mixins/register-table-component', 'ember-table/mixins/mouse-wheel-handler', 'ember-table/mixins/touch-move-handler', 'ember-table/mixins/scroll-handler'], function (exports, _ember, _emberTableViewsTableContainer, _emberTableMixinsShowHorizontalScroll, _emberTableMixinsRegisterTableComponent, _emberTableMixinsMouseWheelHandler, _emberTableMixinsTouchMoveHandler, _emberTableMixinsScrollHandler) {
  'use strict';

  exports['default'] = _emberTableViewsTableContainer['default'].extend(_emberTableMixinsMouseWheelHandler['default'], _emberTableMixinsTouchMoveHandler['default'], _emberTableMixinsScrollHandler['default'], _emberTableMixinsShowHorizontalScroll['default'], _emberTableMixinsRegisterTableComponent['default'], {
    templateName: 'body-table-container',
    classNames: ['et-table-container', 'et-body-container', 'antiscroll-wrap'],

    height: _ember['default'].computed.alias('tableComponent._bodyHeight'),
    width: _ember['default'].computed.alias('tableComponent._width'),
    scrollTop: _ember['default'].computed.alias('tableComponent._tableScrollTop'),
    scrollLeft: _ember['default'].computed.alias('tableComponent._tableScrollLeft'),
    scrollElementSelector: '.antiscroll-inner',

    onScroll: function onScroll(event) {
      this.set('scrollTop', event.target.scrollTop);
      return event.preventDefault();
    },

    // `event` here is a jQuery event
    onMouseWheel: function onMouseWheel(event, delta, deltaX, deltaY) {
      if (Math.abs(deltaX) <= Math.abs(deltaY)) {
        return;
      }
      var scrollLeft = this.$('.et-right-table-block').scrollLeft() + deltaX;
      this.set('scrollLeft', scrollLeft);
      event.preventDefault();
    },

    // `event` here is a jQuery event
    onTouchMove: function onTouchMove(event, deltaX, deltaY) {
      if (Math.abs(deltaX) <= Math.abs(deltaY)) {
        return;
      }
      var scrollLeft = this.$('.et-right-table-block').scrollLeft() + deltaX;
      this.set('scrollLeft', scrollLeft);
      event.preventDefault();
    }
  });
});