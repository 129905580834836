define('component-library/mixins/wizard-validation-and-error', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		propertiesAreValid: function propertiesAreValid(validationObjects) {
			var _this = this;

			if (_ember['default'].isNone(validationObjects)) {
				throw new Error('Must provide a validationObjects array to propertiesAreValid()');
			}
			// Clear all error text first
			validationObjects.forEach(function (validationObject) {
				var errorPropertyName = validationObject.errorPropertyName || 'errorText';
				_this.set(errorPropertyName, null);
			});
			return validationObjects.map(function (validationObject) {
				var validationFunction = _this[validationObject.validationFunctionName] || _this.get('propertyExists');
				var propertyToValidate = _this.get(validationObject.propertyName);
				var validationFunctionExtraParams = validationObject.validationFunctionExtraParams || [];
				var argumentList = [propertyToValidate].concat(validationFunctionExtraParams) || [];
				var errorPropertyName = validationObject.errorPropertyName || 'errorText';
				var errorText = validationObject.errorText || 'Required';

				if (validationFunction.apply(_this, argumentList)) {
					return true;
				} else {
					// never override an errorProperty.
					// If one property has multiple errors, only the first error will show
					if (_ember['default'].isEmpty(_this.get(errorPropertyName))) {
						_this.set(errorPropertyName, errorText);
					}
					return false;
				}
			}).every(function (bool) {
				return bool === true;
			});
		},

		clearErrorPropertyNames: function clearErrorPropertyNames() {
			var _this2 = this;

			if (!this.get('propertiesToValidate')) {
				return;
			}

			this.get('propertiesToValidate').forEach(function (error) {
				_this2.set(error.errorPropertyName, '');
			});
		},

		propertyExists: function propertyExists(propertyToValidate) {
			return !_ember['default'].isEmpty(propertyToValidate);
		},

		_createStrictDate: function _createStrictDate(date, dateFormat) {
			return (0, _componentLibraryUtilsGlobals.moment)(date, dateFormat, true);
		},

		dateIsValid: function dateIsValid(date) {
			var dateFormat = arguments.length <= 1 || arguments[1] === undefined ? _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT : arguments[1];

			return this._createStrictDate(date, dateFormat).isValid();
		},

		dateIsValidBusinessDay: function dateIsValidBusinessDay(date) {
			var dateFormat = arguments.length <= 1 || arguments[1] === undefined ? _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT : arguments[1];

			return this.dateIsValid(date, dateFormat) && this._createStrictDate(date, dateFormat).isBusinessDay();
		},

		dateIsNotAfterDate: function dateIsNotAfterDate(date, otherDate) {
			var dateFormat = arguments.length <= 2 || arguments[2] === undefined ? _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT : arguments[2];

			return this.dateIsValid(date, dateFormat) && !this._createStrictDate(date, dateFormat).isAfter(this._createStrictDate(otherDate, dateFormat));
		},

		dateIsNotBeforeDate: function dateIsNotBeforeDate(date, otherDate) {
			var dateFormat = arguments.length <= 2 || arguments[2] === undefined ? _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT : arguments[2];

			return this.dateIsValid(date, dateFormat) && !this._createStrictDate(date, dateFormat).isBefore(this._createStrictDate(otherDate, dateFormat));
		},

		resetErrorText: function resetErrorText() {
			var _this3 = this;

			var errorProperties = this.get('propertiesToValidate').mapBy('errorPropertyName').uniq();
			errorProperties.forEach(function (errorProperty) {
				_this3.set(errorProperty, null);
			});
		},

		actions: {
			validateSaveAndContinue: function validateSaveAndContinue(continueButton) {
				if (this.propertiesAreValid(this.get('propertiesToValidate'))) {
					this.send('saveAndContinue', continueButton);
				} else {
					continueButton.stop();
					this.send('updateStepComplete', false);
				}
			}
		}
	});
});