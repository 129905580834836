define('component-library/routes/master', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Route.extend({
		actions: {
			showDetails: function showDetails() {
				this.controller.set('isDetailsOpen', true);
			},

			closeDetails: function closeDetails() {
				this.controller.set('selection', 'clear me'); // null doesn't work

				return this.transitionTo(this.routeName);
			},

			hideDetails: function hideDetails() {
				this.controller.set('isDetailsOpen', false);
			},

			subrouteObjectSet: function subrouteObjectSet(selection) {
				this.controller.set('selection', selection);
				this.send('showDetails');
			},

			attemptSelectionChange: function attemptSelectionChange(obj) {
				this.transitionTo(this.routeName + '.details', obj.item.get('id'), {
					queryParams: {
						isEditing: false
					}
				});
			},

			objectDeleted: function objectDeleted(obj) {
				this.controller.get('model').removeObject(obj);

				this.send('closeDetails');
			}
		}
	});
});