define('component-library/timeline/components/pto-request', ['exports', 'ember', 'component-library/timeline/mixins/feed-entry', 'component-library/timeline/mixins/pto-feed-entry', 'component-library/timeline/components/copy', 'component-library/timeline/utils/status', 'component-library/timeline/utils/computed-copy'], function (exports, _ember, _componentLibraryTimelineMixinsFeedEntry, _componentLibraryTimelineMixinsPtoFeedEntry, _componentLibraryTimelineComponentsCopy, _componentLibraryTimelineUtilsStatus, _componentLibraryTimelineUtilsComputedCopy) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports['default'] = _componentLibraryTimelineComponentsCopy['default'].extend(_componentLibraryTimelineMixinsFeedEntry['default'], _componentLibraryTimelineMixinsPtoFeedEntry['default'], {

		copy: _componentLibraryTimelineUtilsComputedCopy['default'].apply(undefined, _toConsumableArray(_componentLibraryTimelineUtilsStatus.ptoRequestStatusTypes)),

		employeeName: _ember['default'].computed.alias('data.employee.fullName'),
		hours: _ember['default'].computed.alias('data.hours'),
		requestType: _ember['default'].computed.alias('data.requestType'),
		startDate: _ember['default'].computed.prettyDate('data.startDate'),
		endDate: _ember['default'].computed.prettyDate('data.endDate'),
		startBalance: _ember['default'].computed.truncated('data.startBalance', 2),
		endBalance: _ember['default'].computed.truncated('data.endBalance', 2),
		oldStartBalance: _ember['default'].computed.truncated('data.oldStartBalance', 2),
		oldEndBalance: _ember['default'].computed.truncated('data.oldEndBalance', 2),
		accountName: _ember['default'].computed.alias('data.accountName'),
		oldAccountName: _ember['default'].computed.alias('data.oldAccountName'),

		requested: (function () {
			return this.get('user') + ' submitted a ' + this.get('requestInfo');
		}).property('user', 'requestInfo'),

		approved: (function () {
			return this.removeDupPeriod(this.get('user') + ' approved ' + this.get('employeePossessive') + this.get('requestInfo') + '. ' + this.get('balanceChangeStr'));
		}).property('user', 'employeeName', 'requestInfo', 'balanceChangeStr', 'employeePossessive'),

		recorded: (function () {
			var recordedStr = "";
			recordedStr += this.get('user') + " recorded " + this.get('hoursString') + " of " + this.get('requestType');
			if (this.get('startDate') === this.get('endDate')) {
				recordedStr += " on " + this.get('startDate');
			} else {
				recordedStr += " from " + this.get('startDate') + " to " + this.get('endDate');
			}
			recordedStr += " for " + this.get('employeeName') + ". " + this.get('balanceChangeStr');
			return this.removeDupPeriod(recordedStr);
		}).property('user', 'hoursString', 'requestType', 'startDate', 'endDate', 'employeeName', 'balanceChangeStr'),

		cancelled: (function () {
			return this.removeDupPeriod(this.get('user') + ' cancelled ' + this.get('employeePossessive') + this.get('requestInfo') + '. ' + this.get('balanceChangeStr'));
		}).property('user', 'requestInfo', 'balanceChangeStr', 'employeePossessive'),

		edited: (function () {
			return this.removeDupPeriod(this.get('user') + ' edited ' + this.get('employeePossessive') + this.get('requestInfo') + '. ' + this.get('balanceChangeStr'));
		}).property('user', 'employeeName', 'requestInfo', 'balanceChangeStr', 'employeePossessive'),

		deleted: (function () {
			return this.removeDupPeriod(this.get('user') + ' deleted ' + this.get('employeePossessive') + this.get('requestInfo') + '. ' + this.get('balanceChangeStr'));
		}).property('user', 'requestFor', 'requestInfo', 'balanceChangeStr', 'employeePossessive'),

		undeleted: (function () {
			return this.removeDupPeriod(this.get('user') + ' undeleted ' + this.get('employeePossessive') + this.get('requestInfo') + '. ' + this.get('balanceChangeStr'));
		}).property('user', 'requestFor', 'requestInfo', 'balanceChangeStr', 'employeePossessive'),

		denied: (function () {
			return this.removeDupPeriod(this.get('user') + ' denied ' + this.get('employeePossessive') + this.get('requestInfo') + '. ' + this.get('balanceChangeStr'));
		}).property('user', 'employeeName', 'requestInfo', 'balanceChangeStr', 'employeePossessive'),

		taken: (function () {
			return this.removeDupPeriod(this.get('balanceChangeStr'));
		}).property('balanceChangeStr'),

		requestFor: (function () {
			var selfOperated = this.get('employeeName') === this.get('user');
			if (selfOperated) {
				return "their";
			} else {
				return this.get('employeeName') + "'s";
			}
		}).property('employeeName', 'user'),

		requestInfo: (function () {
			if (this.get('startDate') === this.get('endDate')) {
				return ' request to take ' + this.get('hoursString') + ' of ' + this.get('requestType') + ' on ' + this.get('startDate');
			} else {
				return ' request to take ' + this.get('hoursString') + ' of ' + this.get('requestType') + ' from ' + this.get('startDate') + ' to ' + this.get('endDate');
			}
		}).property('hoursString', 'requestType', 'startDate', 'endDate'),

		hoursString: (function () {
			if (Number(this.get('hours')) === 1) {
				return this.get('hours') + " hour";
			} else {
				return this.get('hours') + " hours";
			}
		}).property('hours'),

		balanceChangeStr: (function () {
			var balanceChange = function balanceChange(accountName, startBalance, endBalance) {
				var change = "";
				if (Number(startBalance) > Number(endBalance)) {
					change = "decreased";
				} else {
					change = "increased";
				}
				return accountName + " balance " + change + " from " + startBalance + " hours to " + endBalance + " hours effective immediately";
			};
			var ret = "";
			if (this.get('endBalance') !== null && this.get('startBalance') !== null && Number(this.get('startBalance')) !== Number(this.get('endBalance'))) {
				ret = this.capitalizeFirstLetter(this.get('employeePossessive')) + " " + balanceChange(this.get('accountName'), this.get('startBalance'), this.get('endBalance')) + ".";
			}
			if (this.get('oldEndBalance') !== null && this.get('oldStartBalance') !== null && Number(this.get('oldStartBalance')) !== Number(this.get('oldEndBalance'))) {
				ret += " " + this.capitalizeFirstLetter(this.get('employeePossessive')) + " " + balanceChange(this.get('oldAccountName'), this.get('oldStartBalance'), this.get('oldEndBalance')) + ".";
			}
			return ret;
		}).property('employeeName', 'accountName', 'startBalance', 'endBalance', 'oldAccountName', 'oldStartBalance', 'oldEndBalance', 'employeePossessive'),
		removeDupPeriod: function removeDupPeriod(str) {
			return str.trim().replace(/\.+$/, "");
		}

	});
});