define('component-library/components/z-slider-base', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var SIZE_PROPERTIES = {
		'default': {
			sliderKnobSizeClass: ''
		},
		small: {
			sliderKnobSizeClass: 'sliderKnobPopover--sm'
		}
	};

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['slider'],
		classNameBindings: ['showPopoverOnActive:slider--sliderOnActive'],

		selectedValue: 0,
		step: null,
		minVal: 0,
		maxVal: 100,
		showStepTicks: false,
		showStepTickValues: false,

		showPopover: false,
		showPopoverOnActive: false,
		popoverPartial: null,
		popoverLabel: null,
		size: 'default',
		sliderKnobPopoverClass: (function () {
			return SIZE_PROPERTIES[this.get('size')].sliderKnobSizeClass;
		}).property('size'),

		_selectedValueToRender: 0,
		_hasSteps: _ember['default'].computed.notEmpty('step'),
		_popoverDisplayValue: _ember['default'].computed.alias('_selectedValueToRender'),
		_popoverDisplayLabel: _ember['default'].computed('popoverLabel', '_isPopoverFormatted', function () {
			return this.get('_isPopoverFormatted') ? '' : this.get('popoverLabel');
		}),
		_isPopoverFormatted: _ember['default'].computed('popoverLabel', function () {
			return typeof this.get('popoverLabel') === 'function';
		}),
		_popoverDisplayFormatted: _ember['default'].computed('popoverLabel', '_isPopoverFormatted', '_selectedValueToRender', function () {
			if (this.get('_isPopoverFormatted')) {
				var popoverLabel = this.get('popoverLabel');
				var selectedValueToRender = this.get('_selectedValueToRender');
				return popoverLabel(selectedValueToRender);
			}
			return '';
		}),
		_showPopover: _ember['default'].computed.or('showPopover', 'showPopoverOnActive'),

		_debounceInterval: 50,
		_externalUpdateDebounce: 200,
		_isSliding: false,
		_pxMin: null,
		_pxMax: null,

		stepTicks: (function () {
			if (!this.get('_hasSteps')) {
				return [];
			}

			var stepTicks = [];
			var minVal = this.get('minVal');
			var maxVal = this.get('maxVal');
			var valRange = maxVal - minVal;
			var numTicks = Math.floor(valRange / this.get('step'));
			var percentPerStep = 100.0 / numTicks;

			for (var i = 0; i <= numTicks; i++) {
				var percentOffsetLeft = i * percentPerStep;
				var tickLabel = Math.floor(percentOffsetLeft * valRange / 100.0) + minVal;
				var positionStyle = percentOffsetLeft < 100 ? 'left: ' + percentOffsetLeft + '%;' : 'right: 0%;';
				stepTicks.push({
					positionStyle: new _ember['default'].Handlebars.SafeString(positionStyle),
					label: tickLabel
				});
			}
			return stepTicks;
		}).property('_hasSteps', 'minVal', 'maxVal'),

		_percentSlid: (function () {
			var val = this.get('_selectedValueToRender') - this.get('minVal');
			var valRange = this.get('maxVal') - this.get('minVal');
			return Math.min(val / valRange * 100.0, 100.0);
		}).property('_selectedValueToRender', 'minVal', 'maxVal'),

		_recalculateValue: function _recalculateValue(event) {
			var step = this.get('step');
			var pxMin = this.get('_pxMin');
			var pxMax = this.get('_pxMax');
			var newPx = Math.min(Math.max(pxMin, event.pageX), pxMax);
			var newPercentage = (newPx - pxMin) / this.get('_sliderWidthPx') * 100.0;
			var valRange = this.get('maxVal') - this.get('minVal');
			var newVal = newPercentage * valRange / 100.0 + this.get('minVal');
			if (this.get('_hasSteps')) {
				newVal = Math.round(newVal / step) * step;
			}

			this.set('_selectedValueToRender', newVal);
		},

		_recalculateOnExternalValChange: (function () {
			var externalUpdateDebounce = this.get('_externalUpdateDebounce');
			_ember['default'].run.debounce(this, function () {
				this.set('_selectedValueToRender', this.get('selectedValue'));
			}, externalUpdateDebounce);
		}).observes('selectedValue'),

		didInsertElement: function didInsertElement() {
			var _this = this;

			var $slider = this.$('.js-slider-track');
			var $mainKnob = $slider.find('.js-slider-knob--main');
			var debounceInterval = this.get('_debounceInterval');

			var pxMin = $slider.offset().left;
			var sliderWidthPx = $slider.width();
			this.setProperties({
				_selectedValueToRender: this.get('selectedValue'),
				_isSliding: false,
				_pxMin: pxMin,
				_sliderWidthPx: sliderWidthPx,
				_pxMax: pxMin + sliderWidthPx
			});

			_ember['default'].$(document).mouseup(function () {
				if (_this.get('_isSliding')) {
					_this.set('_isSliding', false);
					$mainKnob.removeClass('active');
					_this.set('selectedValue', _this.get('_selectedValueToRender'));
				}
			});

			$mainKnob.mousedown(function (event) {
				_ember['default'].run.debounce(_this, function () {
					if (!this.get('_isSliding')) {
						var pxMin = $slider.offset().left;
						var sliderWidthPx = $slider.width();
						this.setProperties({
							_pxMin: pxMin,
							_sliderWidthPx: sliderWidthPx,
							_pxMax: pxMin + sliderWidthPx
						});
					}

					this.set('_isSliding', true);
					$mainKnob.addClass('active');
					event.stopPropagation();
				}, debounceInterval);
			});

			_ember['default'].$(document).mousemove(function (event) {
				_ember['default'].run.debounce(_this, function () {
					if (this.get('_isSliding')) {
						this._recalculateValue(event);
					}
				}, debounceInterval);
			});

			this.$('.js-slider-click-mask').click(function (event) {
				_this._recalculateValue(event);
				_this.set('selectedValue', _this.get('_selectedValueToRender'));
			});
		}
	});
});