define('component-library/filters/number-filter-spec', ['exports', 'ember', 'component-library/filters/abstract-filter-spec', 'component-library/components/number-filter'], function (exports, _ember, _componentLibraryFiltersAbstractFilterSpec, _componentLibraryComponentsNumberFilter) {
	'use strict';

	exports['default'] = _componentLibraryFiltersAbstractFilterSpec['default'].extend({
		/*
  	Overrides
  */
		popoverClass: _componentLibraryComponentsNumberFilter['default'],

		description: _ember['default'].computed(function () {
			var min = this.get('min');
			var max = this.get('max');

			var hasMin = !_ember['default'].isEmpty(min);
			var hasMax = !_ember['default'].isEmpty(max);

			if (hasMin && hasMax) {
				return "From %@ to %@".fmt(min, max);
			} else if (hasMin) {
				return "%@ or more".fmt(min);
			} else if (hasMax) {
				return "%@ or less".fmt(max);
			} else {
				return "*ALL* the values";
			}
		}).property('min', 'max'),

		applyFunc: _ember['default'].computed(function () {
			var min = this.get('min');
			var max = this.get('max');
			var valuePath = this.get('valuePath');

			return function (x) {
				var matchesMin = true;
				var matchesMax = true;
				var val = x.get(valuePath);

				if (!_ember['default'].isEmpty(min)) {
					matchesMin = min <= val;
				}

				if (!_ember['default'].isEmpty(max)) {
					matchesMax = max >= val;
				}

				return matchesMin && matchesMax;
			};
		}).property('min', 'max', 'valuePath'),

		/*
  	Specifics
  */

		// Interface assumes that these are properly set to number values
		min: null,
		max: null
	});
});