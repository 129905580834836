define('component-library/utils/presenter-from-deps', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = createPresenterFromDependencyList;

	/**
 * Creates a constructor for a presenter object from a list of props
 * @param {string[]} propArray - array of keys on the underlying object
 * @param {string} dependentKey - name of the key that will hold the underlying object
 * @param {Object} otherProps - object of instantiated computed properties for the presenter
 * @return {function} - presentation object constructor
 */
	function createPresenterFromDependencyList(propArray, dependentKey, otherProps) {
		var presenterKeys = otherProps || {};
		presenterKeys[dependentKey] = null;
		propArray.forEach(function (prop) {
			presenterKeys[prop] = _ember['default'].computed.oneWay(dependentKey + '.' + prop);
		});
		var Presenter = _ember['default'].Object.extend(presenterKeys);
		return Presenter;
	}
});