define('component-library/components/editable-paystub', ['exports', 'ember', 'component-library/constants/payroll', 'component-library/constants/z-payroll/employment-types', 'component-library/constants/z-payroll/z-pay-types', 'component-library/constants/z-payroll/z-payroll-payrun-states', 'component-library/constants/z-payroll/z-payroll-payrun-types', 'component-library/utils/globals', 'component-library/constants/z-payroll/z-payroll-earning-categories', 'component-library/modals/payroll/audit-trail-modal', 'component-library/modals/onboarding/confirmation-modal', 'component-library/modals/payroll/employee-labor-distribution-modal', 'component-library/modals/payroll/editable-paystub-tax-override-modal'], function (exports, _ember, _componentLibraryConstantsPayroll, _componentLibraryConstantsZPayrollEmploymentTypes, _componentLibraryConstantsZPayrollZPayTypes, _componentLibraryConstantsZPayrollZPayrollPayrunStates, _componentLibraryConstantsZPayrollZPayrollPayrunTypes, _componentLibraryUtilsGlobals, _componentLibraryConstantsZPayrollZPayrollEarningCategories, _componentLibraryModalsPayrollAuditTrailModal, _componentLibraryModalsOnboardingConfirmationModal, _componentLibraryModalsPayrollEmployeeLaborDistributionModal, _componentLibraryModalsPayrollEditablePaystubTaxOverrideModal) {
	'use strict';

	var HOURLY_EARNING_NOT_ALLOWED = "We cannot use these earnings towards their overtime premium earnings. To help us calculate the correct amount, update the pay period to one day. You can create multiple pay runs to account for the entire amount.";
	var OUTSIDE_OF_CURRENT_PAY_PERIOD = "You've selected dates outside of the current pay period. Please calculate and pay any overtime premium earnings corrections for these workers, if any.";

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-paystubDetailPanel-body', 'js-z-paystubDetailPanel-body'],
		model: null,
		earnings: null,
		reimbursements: null,
		zprEmployee: null,
		deductions: null,
		contributions: null,
		garnishments: null,
		earningTypes: null,
		deductionTypes: null,
		contributionTypes: null,
		deductionErrors: null,
		earningErrors: null,

		newZPCompanyEarningType: null,
		newZPCompanyDeductionType: null,
		newZPCompanyContributionType: null,
		newZPCompanyReimbursementType: null,

		isEditable: true,
		canOverrideSyncedDeductions: true,
		canOverrideSyncedConributions: true,
		isInitPayrollRun: false,
		isTaxEditable: false,
		isAddingNewEarningPayItem: false,
		isConfirmingNewEarningPayItem: false,
		isAddingNewDeductionPayItem: false,
		isAddingNewContributionPayItem: false,
		isAddingNewReimbursementPayItem: false,
		isContractorPaymentsCompany: _ember['default'].computed.alias('model.zPayrollRun.zpCompany.company.isContractorPaymentsCompany'),
		isNotContractorPaymentsCompany: _ember['default'].computed.not('isContractorPaymentsCompany'),
		canAddNewDeductionAndContribution: true,
		inputDisabled: false,

		ldmprPositions: null,
		ldmprDepartments: null,
		ldmprLocations: null,
		ldmprProjects: null,
		ldmprCustomGroups: null,
		ldmprCustomGroupName: null,
		employeeCompensations: [],

		disableOverrideSyncedDeductions: _ember['default'].computed.not('canOverrideSyncedDeductions'),
		disableOverrideSyncedConributions: _ember['default'].computed.not('canOverrideSyncedConributions'),

		// TODO (bill-zen): move business logic to caller
		isThirdPartySickPay: _ember['default'].computed.alias('model.zPayrollRun.isThirdPartySickPay'),
		isNotThirdPartySickPay: _ember['default'].computed.not('isThirdPartySickPay'),
		isEETaxEditable: _ember['default'].computed.and('isThirdPartySickPay', 'isEditable'),
		showReimbursementsSection: _ember['default'].computed.and('isNotThirdPartySickPay', 'isNotContractorPaymentsCompany'),
		showDeductionsContributionsSection: _ember['default'].computed.and('isNotThirdPartySickPay', 'isNotContractorPaymentsCompany'),

		// Adding/Deleting pay item controls
		allowAddNewEarning: _ember['default'].computed.notEmpty('activeEarningTypes'),
		allowAddNewReimbursement: _ember['default'].computed.notEmpty('activeReimbursementTypes'),
		allowAddNewDeduction: _ember['default'].computed.notEmpty('activeDeductionTypes'),
		allowAddNewContribution: _ember['default'].computed.notEmpty('activeContributionTypes'),

		supportedPayTypes: _ember['default'].computed('zprEmployee.employmentType', function () {
			return _componentLibraryConstantsZPayrollEmploymentTypes.EMPLOYMENT_SUB_TYPES_CONTRACTORS.has(this.get('zprEmployee.employmentType')) ? _componentLibraryConstantsZPayrollZPayTypes.EMPLOYMENT_TYPE_TO_PAY_TYPES.CONTRACTOR : _componentLibraryConstantsZPayrollZPayTypes.EMPLOYMENT_TYPE_TO_PAY_TYPES.DEFAULT;
		}),

		allowEarnings: _ember['default'].computed('supportedPayTypes.[]', function () {
			return this.get('supportedPayTypes').has(_componentLibraryConstantsZPayrollZPayTypes.PAY_TYPES.EARNINGS);
		}),

		allowDeductions: _ember['default'].computed('supportedPayTypes.[]', function () {
			return this.get('supportedPayTypes').has(_componentLibraryConstantsZPayrollZPayTypes.PAY_TYPES.DEDUCTIONS);
		}),

		allowGarnishments: _ember['default'].computed('supportedPayTypes.[]', function () {
			return this.get('supportedPayTypes').has(_componentLibraryConstantsZPayrollZPayTypes.PAY_TYPES.GARNISHMENTS);
		}),

		allowReimbursements: _ember['default'].computed('supportedPayTypes.[]', function () {
			return this.get('supportedPayTypes').has(_componentLibraryConstantsZPayrollZPayTypes.PAY_TYPES.REIMBURSEMENTS);
		}),

		allowContributions: _ember['default'].computed('supportedPayTypes.[]', function () {
			return this.get('supportedPayTypes').has(_componentLibraryConstantsZPayrollZPayTypes.PAY_TYPES.EMPLOYER_CONTRIBUTIONS);
		}),

		allowEmployeeTaxes: _ember['default'].computed('supportedPayTypes.[]', function () {
			return this.get('supportedPayTypes').has(_componentLibraryConstantsZPayrollZPayTypes.PAY_TYPES.EMPLOYEE_TAXES);
		}),

		allowEmployerTaxes: _ember['default'].computed('supportedPayTypes.[]', function () {
			return this.get('supportedPayTypes').has(_componentLibraryConstantsZPayrollZPayTypes.PAY_TYPES.EMPLOYER_TAXES);
		}),

		vacationRequests: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),
		loaRequests: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		isLoaPushToPayrollR1Enabled: _ember['default'].computed('switches', function () {
			// Migrating R1 switch to R3 switch
			return this.get('switches.loa_push_to_payroll_r3');
		}),

		// Earnings
		nonDeletedEarnings: _ember['default'].computed.rejectByProperty('earnings', 'willDelete'),
		activeEarningTypes: _ember['default'].computed('earningTypes.@each.id', 'earningTypes.@each.category', 'earningTypes.@each.isGenerated', 'earningTypes.@each.isReimbursement', 'isLoaPushToPayrollR1Enabled', 'nonDeletedEarnings.@each.zpCompanyEarningType.id', 'zprEmployee.employmentType', function () {
			var _this = this;

			return this.get('earningTypes').reject(function (earningType) {
				if (earningType.get("isGenerated") && earningType.get("category") === _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.LEAVE_OF_ABSENCE) {
					return !_this.get("isLoaPushToPayrollR1Enabled");
				}
				if (_this.get('isContractorPaymentsCompany')) {
					if (earningType.get('category') === _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.CO_REGULAR) {
						return false;
					}
					return true;
				}

				if (earningType.get('isReimbursement')) {
					return true;
				}

				var ldmprSwitch = _this.get('showLaborDistribution');
				if (!ldmprSwitch) {
					if (_this.get('nonDeletedEarnings').isAny('zpCompanyEarningType.id', earningType.get('id'))) {
						return true;
					}
				}

				/******************************************************************************************************
    Some EarningCategories are for employees only while some EarningCategories are for contractors only.
    Hide the earning types that are not compatible with the current zprEmployee to prevent invalid records
    from being created.
    *******************************************************************************************************/
				return _componentLibraryConstantsZPayrollEmploymentTypes.EMPLOYMENT_SUB_TYPES_CONTRACTORS.has(_this.get('zprEmployee.employmentType')) ? !earningType.get('isCompatibleWithContractors') : !earningType.get('isCompatibleWithEmployees');
			});
		}),
		isCorrectionRequestPayRun: _ember['default'].computed('model.zPayrollRun', function () {
			return this.get('model.zPayrollRun.payrollType') == _componentLibraryConstantsZPayrollZPayrollPayrunTypes.PAYRUN_TYPES.CORRECTION_REQUEST;
		}),
		isCorrectionPayRun: _ember['default'].computed('model.zPayrollRun', function () {
			var payRunType = this.get('model.zPayrollRun.payrollType');
			return payRunType == _componentLibraryConstantsZPayrollZPayrollPayrunTypes.PAYRUN_TYPES.CORRECTION_REQUEST || payRunType == _componentLibraryConstantsZPayrollZPayrollPayrunTypes.PAYRUN_TYPES.CORRECTION || payRunType == _componentLibraryConstantsZPayrollZPayrollPayrunTypes.PAYRUN_TYPES.ADJUSTMENT || payRunType == _componentLibraryConstantsZPayrollZPayrollPayrunTypes.PAYRUN_TYPES.AMENDMENT;
		}),
		showEarningErrors: _ember['default'].computed('earningErrorsMap', 'nonDeletedEarnings.@each.category', function () {
			var zPayrollRun = this.get('model.zPayrollRun');
			var payrollType = zPayrollRun.get('payrollType');
			// only offcycles should show the RRP error
			if (payrollType !== _componentLibraryConstantsZPayrollZPayrollPayrunTypes.PAYRUN_TYPES.OFF_CYCLE) {
				return false;
			}
			var zpCompany = zPayrollRun.get('zpCompany');
			var overtimeCalculationMethod = zpCompany.get('overtimeCalculationMethod');
			if (overtimeCalculationMethod !== _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.REGULAR) {
				return false;
			}
			var errorsMap = this.get('earningErrorsMap');
			if (_ember['default'].isEmpty(errorsMap)) {
				return false;
			}
			this.get('nonDeletedEarnings').forEach(function (earning) {
				earning.set('errorMessage', errorsMap[earning.get('zpCompanyEarningType_id')]);
			});

			return true;
		}),

		impactsPreviousRegHourlyPayrollRuns: _ember['default'].computed(function () {
			var impactedRunStates = [_componentLibraryConstantsZPayrollZPayrollPayrunStates.PAYRUN_STATES.PAID, _componentLibraryConstantsZPayrollZPayrollPayrunStates.PAYRUN_STATES.APPROVED, _componentLibraryConstantsZPayrollZPayrollPayrunStates.PAYRUN_STATES.DRAFT];
			var payrollStartDate = this.get('model.zPayrollRun.startDate');
			var payrollEndDate = this.get('model.zPayrollRun.endDate');
			// If we have both start and end date, then we can load
			if (payrollStartDate && payrollEndDate) {
				var payrollStartDateObject = (0, _componentLibraryUtilsGlobals.moment)(payrollStartDate).format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
				var payrollEndDateObject = (0, _componentLibraryUtilsGlobals.moment)(payrollEndDate).format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
				var hasImpactedRun = window.App.ZPayrollRun.find({
					payrollType: _componentLibraryConstantsZPayrollZPayrollPayrunTypes.PAYRUN_TYPES.REGULAR_HOURLY,
					zpPayPeriod__startDate__lte: payrollEndDateObject,
					zpPayPeriod__endDate__gte: payrollStartDateObject
				}).then(function (zPayrollRuns) {
					zPayrollRuns.forEach(function (run) {
						if (impactedRunStates.includes(run.get('payrollRunState'))) {
							return true;
						}
					});
					return false;
				});
				return hasImpactedRun;
			}
			return false;
		}),

		earningErrorsMap: _ember['default'].computed('earningErrors', 'nonDeletedEarnings', function () {
			var _this2 = this;

			var errorsMap = {};
			var earnings = this.get('nonDeletedEarnings');
			var zPayrollRun = this.get('model.zPayrollRun');
			var zpPayPeriod = zPayrollRun.get('zpPayPeriod');
			if (zpPayPeriod) {
				var thisPayrollStartDate = zpPayPeriod.get('startDate');
				var thisPayrollEndDate = zpPayPeriod.get('endDate');
				var thisPayrollStartDateObject = (0, _componentLibraryUtilsGlobals.moment)(thisPayrollStartDate, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var thisPayrollEndDateObject = (0, _componentLibraryUtilsGlobals.moment)(thisPayrollEndDate, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				// Need to add 1 for correct number of days of payrun
				var daysInPayrun = thisPayrollEndDateObject.diff(thisPayrollStartDateObject, "days") + 1;
				earnings.forEach(function (earning) {
					var category = earning.get('category');
					var earningTypeId = earning.get('zpCompanyEarningType_id');
					var isIncluded = earning.get('zpCompanyEarningType.isIncludedInRegularRateOfPay');
					if (isIncluded) {
						var isHourly = earning.get('canEditHourAndRate');
						var isInPreviousPayPeriod = _this2.get('impactsPreviousRegHourlyPayrollRuns');
						if (isInPreviousPayPeriod) {
							errorsMap[earningTypeId] = OUTSIDE_OF_CURRENT_PAY_PERIOD;
						} else if (isHourly && daysInPayrun > 1) {
							errorsMap[earningTypeId] = HOURLY_EARNING_NOT_ALLOWED;
						}
					}
				});
			}
			return errorsMap;
		}),

		// Reimbursements
		nonDeletedReimbursements: _ember['default'].computed.rejectByProperty('reimbursements', 'willDelete'),
		reimbursementTypes: _ember['default'].computed.filterByProperty('earningTypes', 'isReimbursement'),
		activeReimbursementTypes: _ember['default'].computed('reimbursementTypes.@each.id', 'nonDeletedReimbursements.@each.zpCompanyEarningType.id', function () {
			var _this3 = this;

			return this.get('reimbursementTypes').reject(function (reimbursementType) {
				if (_this3.get('showLaborDistribution')) {
					// LDMPR so don't reject if already there
					return false;
				} else {
					return _this3.get('nonDeletedReimbursements').isAny('zpCompanyEarningType.id', reimbursementType.get('id'));
				}
			});
		}),

		// Deductions
		nonDeletedDeductions: _ember['default'].computed.rejectByProperty('deductions', 'willDelete'),
		activeDeductionTypes: _ember['default'].computed('deductionTypes.@each.id', 'nonDeletedDeductions.@each.zpCompanyDeductionType.id', function () {
			var _this4 = this;

			return this.get('deductionTypes').reject(function (deductionType) {
				return _this4.get('nonDeletedDeductions').isAny('zpCompanyDeductionType.id', deductionType.get('id'));
			});
		}),
		showDeductionErrors: _ember['default'].computed('deductionErrorsMap', 'nonDeletedDeductions.@each.category', function () {
			var errorsMap = this.get('deductionErrorsMap');
			if (_ember['default'].isEmpty(errorsMap)) {
				return false;
			}
			this.get('nonDeletedDeductions').forEach(function (deduction) {
				deduction.set('errorMessage', errorsMap[deduction.get('category')]);
			});

			return true;
		}),
		deductionErrorsMap: _ember['default'].computed('deductionErrors', 'deductionErrors.@each.fields.[]', 'deductionErrors.@each.errorDescription', function () {
			if (_ember['default'].isEmpty(this.get('deductionErrors'))) {
				return {};
			}
			var deductionErrors = this.get('deductionErrors').reject(function (deductionError) {
				return ['ANNUAL_LIMIT_EXCEEDED', 'COVID_19_ANNUAL_LIMIT_EXCEEDED'].includes(deductionError.errorCode);
			});
			var errorsMap = {};
			deductionErrors.forEach(function (err) {
				var description = err.errorDescription;
				var code = err.errorCode;
				err.fields.forEach(function (field) {
					if (code == 'I') {
						errorsMap[field] = description;
					} else if (!errorsMap[field]) {
						errorsMap[field] = description;
					}
				});
			});
			return errorsMap;
		}),

		// Contributions
		nonDeletedContributions: _ember['default'].computed.rejectByProperty('contributions', 'willDelete'),
		activeContributionTypes: _ember['default'].computed('contributionTypes.@each.id', 'nonDeletedContributions.@each.zpCompanyContributionType.id', function () {
			var _this5 = this;

			return this.get('contributionTypes').reject(function (contributionType) {
				return _this5.get('nonDeletedContributions').isAny('zpCompanyContributionType.id', contributionType.get('id'));
			});
		}),
		showContrbutionErrors: _ember['default'].computed('contributionErrorsMap', 'nonDeletedContributions.@each.category', function () {
			var errorsMap = this.get('contributionErrorsMap');
			if (_ember['default'].isEmpty(errorsMap)) {
				return false;
			}
			this.get('nonDeletedContributions').forEach(function (contribution) {
				contribution.set('errorMessage', errorsMap[contribution.get('category')]);
			});

			return true;
		}),
		contributionErrorsMap: _ember['default'].computed('deductionErrors', 'deductionErrors.@each.fields.[]', 'deductionErrors.@each.errorDescription', function () {
			if (_ember['default'].isEmpty(this.get('deductionErrors'))) {
				return {};
			}
			var deductionErrors = this.get('deductionErrors').filter(function (deductionError) {
				return ['ANNUAL_LIMIT_EXCEEDED', 'COVID_19_ANNUAL_LIMIT_EXCEEDED'].includes(deductionError.errorCode);
			});
			var errorsMap = {};
			deductionErrors.forEach(function (err) {
				var description = err.errorDescription;
				err.fields.forEach(function (field) {
					errorsMap[field] = description;
				});
			});
			return errorsMap;
		}),

		// Garnishments
		nonDeletedGarnishments: _ember['default'].computed.rejectByProperty('garnishments', 'willDelete'),
		hasNonDeletedGarnishmentsSection: _ember['default'].computed.gt('nonDeletedGarnishments.length', 0),
		showGarnishmentsSection: _ember['default'].computed.and('hasNonDeletedGarnishmentsSection', 'isNotContractorPaymentsCompany'),
		overriddenGarnishments: _ember['default'].computed.filterBy('garnishments', 'isAmountOverriden', true),
		areGarnishmentsOverridden: _ember['default'].computed.gt('overriddenGarnishments.length', 0),
		disableGarnishmentAmounts: _ember['default'].computed.not('areGarnishmentsOverridden'),

		employeeTaxes: null,
		employerTaxes: null,

		hasEmployeeTaxes: _ember['default'].computed.notEmpty('employeeTaxes'),
		showEmployeeTaxesSection: _ember['default'].computed.or('isNotContractorPaymentsCompany', 'hasEmployeeTaxes'),

		hasEmployerTaxes: _ember['default'].computed.notEmpty('employerTaxes'),
		showEmployerTaxesSection: _ember['default'].computed.or('isNotContractorPaymentsCompany', 'hasEmployerTaxes'),

		nonDeletedEmployeeTax: _ember['default'].computed('employeeTaxes.@each.willDelete', 'employeeTaxes.@each.amount', function () {
			var _this6 = this;

			var taxes = this.get('employeeTaxes').rejectBy('willDelete');
			if (this.get('isEETaxEditable')) {
				return taxes.filter(function (tax) {
					return _ember['default'].get(tax, 'earningType') === 'Reg';
				});
			} else {
				return taxes.filter(function (tax) {
					return _this6.isFitSitTaxRecord(tax) && _ember['default'].get(tax, 'userEnteredAmount') != null || Number(_ember['default'].get(tax, 'amount')) != 0;
				});
			}
		}),

		nonDeletedEmployerTax: _ember['default'].computed('employerTaxes.@each.willDelete', 'employerTaxes.@each.amount', function () {
			var taxes = this.get('employerTaxes').rejectBy('willDelete');
			return taxes.filter(function (tax) {
				return Number(_ember['default'].get(tax, 'amount')) != 0;
			});
		}),

		uncollectedTax: _ember['default'].computed('model.taxCredits.@each.amount', function () {
			var taxCredit = this.get('model.taxCredits');
			return taxCredit.filter(function (credit) {
				return Number(_ember['default'].get(credit, 'amount')) != 0;
			});
		}),
		showUncollectedTaxes: _ember['default'].computed.gt('uncollectedTax.length', 0),
		filteredVacationRequests: _ember['default'].computed('vacationRequests.@each.startDate', 'vacationRequests.@each.endDate', function () {
			// The Vacation Requests coming into this CP are already filtered somewhat by the API.
			var payPeriod = this.get('model.zPayrollRun.zpPayPeriod');

			return this.get('vacationRequests').map(function (request) {
				var requestStartsBeforePayPeriod = (0, _componentLibraryUtilsGlobals.moment)(request.get('startDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT) < (0, _componentLibraryUtilsGlobals.moment)(payPeriod.get('startDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var requestEndsAfterPayPeriod = (0, _componentLibraryUtilsGlobals.moment)(request.get('endDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT) > (0, _componentLibraryUtilsGlobals.moment)(payPeriod.get('endDate'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var isOverlappingPayPeriod = requestStartsBeforePayPeriod || requestEndsAfterPayPeriod;

				request.set('isOverlappingPayPeriod', isOverlappingPayPeriod);
				return !(requestStartsBeforePayPeriod && requestEndsAfterPayPeriod) ? request : null;
			}).filter(function (x) {
				return x;
			});
		}),

		showLaborDistribution: false,

		isFitSitTaxRecord: function isFitSitTaxRecord(tax) {
			if (!tax) return false;
			var taxType = _ember['default'].get(tax, 'taxType');
			if (taxType === 'FIT' || taxType === 'SIT') return true;
			return false;
		},

		actions: {
			openLoaModal: function openLoaModal(zprEmployeeEarning) {
				return this.attrs.openLoaModal(zprEmployeeEarning);
			},
			deleteLeaveRequest: function deleteLeaveRequest(loaEarningId) {
				return this.attrs.deleteLeaveRequest(loaEarningId);
			},
			confirmNewPayItem: function confirmNewPayItem(payItemType, newPayItem) {
				if (payItemType === _componentLibraryConstantsPayroll.ZPCOMPANY_PAY_ITEM_TYPES.earning) {
					this.set('isConfirmingNewEarningPayItem', true);
				}
				this.sendAction('confirmNewPayItem', payItemType, newPayItem);
			},

			cancelNewPayItem: function cancelNewPayItem(payItemType) {
				this.set('isConfirmingNewEarningPayItem', false);
				this.set(_componentLibraryConstantsPayroll.ZPCOMPANY_PAY_ITEM_STATES[payItemType], false);
			},

			addNewPayItemRow: function addNewPayItemRow(payItemType) {
				this.set(_componentLibraryConstantsPayroll.ZPCOMPANY_PAY_ITEM_DROPDOWN_SELECTIONS[payItemType], null);
				this.set('isConfirmingNewEarningPayItem', false);
				this.set(_componentLibraryConstantsPayroll.ZPCOMPANY_PAY_ITEM_STATES[payItemType], true);
			},

			addNewPtoVacationRequest: function addNewPtoVacationRequest(category) {
				return this.attrs.addNewPtoVacationRequest(category);
			},

			editPtoVacationRequest: function editPtoVacationRequest(request) {
				return this.attrs.editPtoVacationRequest(request);
			},

			deleteVacationRequest: function deleteVacationRequest(request) {
				return this.attrs.deleteVacationRequest(request);
			},

			deletePayItem: function deletePayItem(record) {
				// we want to set willDelete instead of deleting because if we remove the deleted
				// record from the array, we wont have a refernce to it to call delete and save
				// when user wants to save the paystub
				this.sendAction('deletePayItem', record);
			},

			toggleShowTimeOffRequests: function toggleShowTimeOffRequests(earning) {
				if (earning.get('category') === "LEAVE_OF_ABSENCE") {
					earning.toggleProperty('showLoaRequests');
				}
				if (earning.get('isEarningFromPto')) {
					earning.toggleProperty('showTimeOffRequests');
				}
			},

			updateOverridenFields: function updateOverridenFields(result) {
				var garnishments = this.get('nonDeletedGarnishments');

				garnishments.forEach(function (garnishment) {
					garnishment.set("isAmountOverriden", result);
				});
			},

			showLaborDistributionModal: function showLaborDistributionModal(earning) {
				var zprEmployee = this.get('zprEmployee');
				var ldmprLocations = this.get('ldmprLocations');
				var ldmprDepartments = this.get('ldmprDepartments');
				var ldmprPositions = this.get('ldmprPositions');
				var ldmprProjects = this.get('ldmprProjects');
				var ldmprCustomGroups = this.get('ldmprCustomGroups');
				var ldmprCustomGroupName = this.get('ldmprCustomGroupName');
				var ldmprCompensations = this.get('employeeCompensations');
				var inputDisabled = this.get('inputDisabled');

				_componentLibraryModalsPayrollEmployeeLaborDistributionModal['default'].show({
					zprEmployee: zprEmployee,
					earning: earning,
					ldmprPositions: ldmprPositions,
					ldmprDepartments: ldmprDepartments,
					ldmprLocations: ldmprLocations,
					ldmprProjects: ldmprProjects,
					ldmprCustomGroups: ldmprCustomGroups,
					ldmprCustomGroupName: ldmprCustomGroupName,
					ldmprCompensations: ldmprCompensations,
					inputDisabled: inputDisabled
				}).then(function (response) {
					var enteredHours = response.enteredHours;
					var enteredHourlyRate = response.enteredHourlyRate;

					if (enteredHours !== null) {
						earning.set('numUnits', enteredHours);
					}
					if (enteredHourlyRate !== null) {
						earning.set('ratePerUnit', enteredHourlyRate);
					}
					var currentLaborFields = earning.get('laborFields') || [];
					var laborFields = [];
					var currentLocation = currentLaborFields.find(function (laborField) {
						return laborField.laborType == "Location";
					});
					if (response.selectedLocationData) {
						var data = response.selectedLocationData;
						var locData = {
							groupId: data.id,
							groupTypeId: data.groupTypeId,
							laborCode: data.domainData.laborCode,
							laborName: data.name,
							laborType: "Location"
						};
						if (parseInt(earning.get('id'))) {
							locData["zprEmployeeEarning_id"] = parseInt(earning.get('id'));
						}
						if (currentLocation) {
							// We are modifying an existing location
							locData.id = currentLocation.id;
						}
						laborFields.push(locData);
					} else {
						if (currentLocation) {
							// We used to have a Location but no longer do
							currentLocation.willDelete = true;
							laborFields.push(currentLocation);
						}
					}

					var currentDepartment = currentLaborFields.find(function (laborField) {
						return laborField.laborType == "Department";
					});
					if (response.selectedDepartmentData) {
						var data = response.selectedDepartmentData;
						var deptData = {
							groupId: data.id,
							groupTypeId: data.groupTypeId,
							laborCode: data.domainData.laborCode,
							laborName: data.name,
							laborType: "Department"
						};
						if (parseInt(earning.get('id'))) {
							deptData["zprEmployeeEarning_id"] = parseInt(earning.get('id'));
						}
						if (currentDepartment) {
							// We are modifying an existing Department
							deptData.id = currentDepartment.id;
						}
						laborFields.push(deptData);
					} else {
						if (currentDepartment) {
							// We used to have a Department but no longer do
							currentDepartment.willDelete = true;
							laborFields.push(currentDepartment);
						}
					}

					var currentPosition = currentLaborFields.find(function (laborField) {
						return laborField.laborType == "Position";
					});
					if (response.selectedPositionData) {
						var data = response.selectedPositionData;
						var posData = {
							groupId: data.id,
							groupTypeId: data.groupTypeId,
							laborCode: data.domainData.laborCode,
							laborName: data.name,
							laborType: "Position"
						};
						if (parseInt(earning.get('id'))) {
							posData["zprEmployeeEarning_id"] = parseInt(earning.get('id'));
						}
						if (currentPosition) {
							// We are modifying an existing Position
							posData.id = currentPosition.id;
						}
						laborFields.push(posData);
					} else {
						if (currentPosition) {
							// We used to have a Position but no longer do
							currentPosition.willDelete = true;
							laborFields.push(currentPosition);
						}
					}

					var currentProject = currentLaborFields.find(function (laborField) {
						return laborField.laborType == "Project";
					});
					if (response.selectedProjectData) {
						var data = response.selectedProjectData;
						var projData = {
							groupId: data.id,
							groupTypeId: data.groupTypeId,
							laborCode: data.domainData.laborCode,
							laborName: data.name,
							laborType: "Project"
						};
						if (parseInt(earning.get('id'))) {
							projData["zprEmployeeEarning_id"] = parseInt(earning.get('id'));
						}
						if (currentProject) {
							// We are modifying an existing Project
							projData.id = currentProject.id;
						}
						laborFields.push(projData);
					} else {
						if (currentProject) {
							// We used to have a Project but no longer do
							currentProject.willDelete = true;
							laborFields.push(currentProject);
						}
					}

					var currentCustomGroup = currentLaborFields.find(function (laborField) {
						return laborField.laborType == ldmprCustomGroupName;
					});
					if (response.selectedCustomGroupData) {
						var data = response.selectedCustomGroupData;
						var custData = {
							groupId: data.id,
							groupTypeId: data.groupTypeId,
							laborCode: data.domainData.laborCode,
							laborName: data.name,
							laborType: ldmprCustomGroupName
						};
						if (parseInt(earning.get('id'))) {
							custData["zprEmployeeEarning_id"] = parseInt(earning.get('id'));
						}
						if (currentCustomGroup) {
							// We are modifying an existing Custom Group
							custData.id = currentCustomGroup.id;
						}
						laborFields.push(custData);
					} else {
						if (currentCustomGroup) {
							// We used to have a Custom Group value but no longer do
							currentCustomGroup.willDelete = true;
							laborFields.push(currentCustomGroup);
						}
					}

					// If we have labor fields returned from modal, set them on the earning
					if (laborFields.length > 0) {
						earning.set('laborFields', laborFields);
					}
				});
			},

			openFitSitOverrideModal: function openFitSitOverrideModal(tax, model) {
				if (!model.content || model.content && model.content.taxesNotSynced) {
					_componentLibraryModalsOnboardingConfirmationModal['default'].show({
						title: 'Please save the changes',
						message: 'Please save your changes before overriding the tax amount',
						cancelButtonDisplayText: 'Ok',
						showConfirmButton: false,
						leftAlign: true
					});
					return;
				}
				var taxRecord;
				if (tax && tax.content) {
					taxRecord = tax.content;
				} else {
					taxRecord = tax;
				}
				_componentLibraryModalsPayrollEditablePaystubTaxOverrideModal['default'].show({
					taxRecord: taxRecord, model: model
				});
			},

			openAuditTrailModal: function openAuditTrailModal(tax) {
				var zprEmployee = this.get('zprEmployee');
				var taxRecord;
				if (tax && tax.content) {
					taxRecord = tax.content;
				} else {
					taxRecord = tax;
				}
				_componentLibraryModalsPayrollAuditTrailModal['default'].show({
					taxRecord: taxRecord, zprEmployee: zprEmployee
				});
			}
		},
		isFitSitOverrideEnabled: _ember['default'].computed('model.isFitSitEnabledCompany', 'isCorrectionPayRun', function () {
			return this.get('model.isFitSitEnabledCompany') && !this.get('isCorrectionPayRun');
		})
	});
});