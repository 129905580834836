define('component-library/utils/payroll/smp/fix-records/error-info-object', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Object.extend({
		key: null,
		value: null,
		newValue: null,
		reason: '',
		placeholder: null,
		hasOriginalValue: _ember['default'].computed('value', function () {
			//Note: value of 0 is not considered empty
			return !_ember['default'].isEmpty(this.get('value'));
		})
	});
});