define('component-library/components/z-sic-search', ['exports', 'ember', 'component-library/utils/globals', 'component-library/components/promise-modal', 'component-library/templates/components/z-sic-search/sic-code-suggestion'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryComponentsPromiseModal, _componentLibraryTemplatesComponentsZSicSearchSicCodeSuggestion) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var PopularCodesModal = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'components/z-sic-search/popular-codes',
		modalDialogClass: 'z-okModal',
		popularCodes: [],
		oshaLink: 'https://www.osha.gov/pls/imis/sicsearch.html',
		classNames: ['z-sicSearchDisplay-modal', 'js-glue-popularCodesModal']
	});

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-sicSearch', 'js-glue-sicSearch'],

		value: null,
		errorMessage: 'Please enter a valid SIC code.',
		placeholder: 'Enter SIC code or search keyword',
		label: 'SIC Code or Industrial Classification',
		tooltipText: null,

		customEvents: [],
		suggestionTemplate: _componentLibraryTemplatesComponentsZSicSearchSicCodeSuggestion['default'],
		displayKey: codeToString,
		hasUserInteracted: false,
		showErrorMessage: _ember['default'].computed.and('isInvalidSicCode', 'hasUserInteracted'),
		openModalCopy: 'See Popular Codes',
		modalHeading: 'Most Popular Industrial Classifications',
		modalRadioGroupTitle: 'Choose a classification',
		modalButtonCancelText: 'Cancel',
		modalButtonSubmitText: 'Submit',
		showPopularCodes: _ember['default'].computed.notEmpty("popularCodes"),
		prefetchUrl: '/custom_api/get_all_sic_codes',

		// NOTE: these 2 initialization calls MUST happen in the `init` before Ember starts rendering
		// the component. This is because Bloodhound is loaded in `typeahead-search` component used in
		// the template of this sic component. Since typeahead-search initializes bloodhound and finishes rendering before this sic
		// component finishes, any bloodhound options that sic search requires needs to be set BEFORE
		// the rendering for typeahead-search begins.
		init: function init() {
			this._super();
			this.initializeBloodhoundOptions();
			this.initializeCustomEvents();
		},

		didInsertElement: function didInsertElement() {
			this._super();
			this.initializeTypeaheadValue(); // Sync the typeahead with prepopulated value
		},

		setSic: function setSic(val) {
			this.set('hasUserInteracted', true);
			this.set('value', stringToSic(val) || val);
		},

		setPopularCodes: function setPopularCodes(popularCodes) {
			popularCodes.forEach(function (c) {
				c.displayValue = codeToString(c); // used as display value in modal
			});
			this.set('popularCodes', popularCodes);
		},

		isInvalidSicCode: (function () {
			return !isSic(this.get('value'));
		}).property('value'),

		handleTypeaheadChange: function handleTypeaheadChange(_ref) {
			var target = _ref.target;

			this.setSic(target.value);
		},

		updateTypeahead: function updateTypeahead(value) {
			var $typeahead = _ember['default'].$(this.element).find('.typeahead');
			$typeahead.typeahead('val', value);
		},

		initializeCustomEvents: function initializeCustomEvents() {
			var _this = this;

			var eventFunction = function eventFunction(e) {
				_this.get('handleTypeaheadChange').call(_this, e);
			};
			var selection = {
				eventName: 'select',
				eventFunction: eventFunction
			};
			var change = {
				eventName: 'change',
				eventFunction: eventFunction
			};
			var customEvents = [selection, change];
			this.set('customEvents', customEvents);
		},

		initializeBloodhoundOptions: function initializeBloodhoundOptions() {
			var _this2 = this;

			var bloodhoundOptions = {
				datumTokenizer: function datumTokenizer(code) {
					return _componentLibraryUtilsGlobals.Bloodhound.tokenizers.whitespace(codeToString(code));
				},
				prefetch: {
					cache: false, // Caching means we can't populate popularCodes :grimace:
					url: this.get('prefetchUrl'),
					transform: function transform(response) {
						_this2.setPopularCodes(response.commonCodes);
						return _flattenSicCodes(response);
					}
				}
			};
			this.set('bloodhoundOptions', bloodhoundOptions);
		},

		initializeTypeaheadValue: function initializeTypeaheadValue() {
			var value = this.get('value');
			if (value) {
				this.updateTypeahead(value);
			}
		},

		// TODO(DQ/anyone): remove configurability of this modal
		getModalState: function getModalState() {
			return {
				popularCodes: this.get('popularCodes'),
				selectedValue: this.get('value'),
				title: this.get('modalHeading'),
				radioGroupTitle: this.get('modalRadioGroupTitle'),
				buttonSubmitText: this.get('modalButtonSubmitText'),
				buttonCancelText: this.get('modalButtonCancelText')
			};
		},

		actions: {
			showPopularCodesModal: function showPopularCodesModal() {
				var _this3 = this;

				var modalState = this.getModalState();
				var handleModalConfirm = function handleModalConfirm(code) {
					if (code) {
						_this3.setSic(code); // explicitly set the value on the component (since it feels sketchy to rely on typeahead to fire a change event)
						_this3.updateTypeahead(code);
					}
				};
				PopularCodesModal.show(modalState).then(handleModalConfirm);
			}
		}
	});

	function codeToString(code) {
		return code.value + ' ' + code.name;
	}

	function stringToSic(str) {
		// Extract the 4-digit sic code from an input string
		// * Looks for four numeric chars at beginning of string
		// * Ignores leading whitespace
		//
		var result = /^\s*(\d{4})/.exec(str);
		return result && result[1];
	}

	function isSic(val) {
		return (/^\d{4}$/.test(val)
		);
	}

	// Peep `/custom_api/get_all_sic_codes` to see expected format of data
	function _flattenSicCodes(_ref2) {
		var divisions = _ref2.divisions;

		return divisions.reduce(_flattenDivisions, []);
	}

	function _flattenDivisions(resultCodes, _ref3) {
		var groups = _ref3.groups;

		return groups.reduce(_flattenGroups, resultCodes);
	}

	function _flattenGroups(resultCodes, _ref4) {
		var industries = _ref4.industries;

		return industries.reduce(_flattenIndustries, resultCodes);
	}

	function _flattenIndustries(resultCodes, _ref5) {
		var codes = _ref5.codes;

		return [].concat(_toConsumableArray(resultCodes), _toConsumableArray(codes));
	}
});