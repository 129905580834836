define('component-library/utils/validator', ['exports', 'ember', 'component-library/utils/validation_rules/regexRule'], function (exports, _ember, _componentLibraryUtilsValidation_rulesRegexRule) {
	'use strict';

	var RegexHash = {
		text: /^[a-zA-Z0-9\s]*$/,
		date: /^((0?[1-9]|1[012])[- \/.](0?[1-9]|[12][0-9]|3[01])[- \/.](19|20)?[0-9]{2})*$/,
		ssn: /^(?!000|666)(?:[0-8][0-9]{2}|7(?:[0-6][0-9]|7[0-2]))-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/,
		ssnWithMask: /^\*\*\*-\*\*-\d{4}$/,
		itinOrSSNWithMask: /^\*\*\*-\*\*-\d{4}$/,
		ssnWithoutHyphen: /^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/,
		itinWithoutHyphen: /^9\d{2}(7|8|9)\d{5}$/,
		zip: /^\d{5}(-\d{4})?$/,
		email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		phone: /^(\+1)?\s?\(?\d{3}\)?\s?[-]?\d{3}[-]?\d{4}$/,
		establishedMonthYear: /^((0[1-9])|(1[0-2])) \/ (\d{4})$/,
		ein: /^(0[1-6]|1[0-6]|2[0-7]|[35]\d|[468][0-8]|7[1-7]|9[^d67])-\d{7}$/,
		routingNumber: /^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2])|(6[1-9])|(7[0-2])|80)([0-9]{7})$/,
		accountNumber: /^(?!0+$)[0-9]+$/,
		naics: /^[0-9]{6}$/
	};

	var Validator = _ember['default'].Object.extend({
		originalValue: undefined,

		shouldValidate: function shouldValidate(newValue) {
			// We've saved the original value, so we can check if the value has actually changed
			// If the value hasn't changed, no need to validate
			if (typeof this.get('originalValue') !== 'undefined') {
				// Maybe use `Ember.isEmpty` here?
				if (newValue === this.get('originalValue')) {
					return false;
				} else {
					return true;
				}
			}
			// If there was no original value for comparison, always assume we need to validate
			else {
					return true;
				}
		},

		addRule: function addRule(rule) {
			var rules = this.get('rules') || [];
			// TODO: Duplicate handling.
			rules.push(rule);
			this.set('rules', rules);
		},

		removeRule: function removeRule(rule) {
			var rules = this.get('rules') || [];

			var index = rules.indexOf(rule);

			if (index > -1) {
				rules.splice(index, 1);
				this.set('rules', rules);
			}
		},

		isValid: function isValid(newValue) {
			return !this.validate(newValue);
		},

		// Validate the rules array in order. if error, return errorMsg.
		validate: function validate(newValue) {
			if (!this.shouldValidate(newValue)) {
				return;
			}

			var rules = this.get('rules') || [];

			for (var i = 0; i < rules.length; i++) {
				var rule = rules[i];

				// TODO: maybe the validate function accept more then one params.
				var isValid = rule.validate(newValue);

				if (!isValid) {
					return rule.get('errorMsg');
				}
			}

			// return nothing means all validate rules passed.
			return;
		}
	});

	Validator.createRegexRule = function (ruleObj) {
		return _componentLibraryUtilsValidation_rulesRegexRule['default'].create(ruleObj);
	};

	Validator.getRegexRule = function (type) {
		return RegexHash[type] || RegexHash['text'];
	};

	exports['default'] = Validator;
});