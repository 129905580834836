define('ui-infra/services/authentication', ['exports', 'ember', 'ui-infra/utils/get-csrftoken'], function (exports, _ember, _uiInfraUtilsGetCsrftoken) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Service.extend({
    csrfToken: computed(function () {
      return (0, _uiInfraUtilsGetCsrftoken['default'])();
    })
  });
});