define('component-library/components/list-item', ['exports', 'ember', 'component-library/mixins/propagate-action'], function (exports, _ember, _componentLibraryMixinsPropagateAction) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsPropagateAction['default'], {
		classNames: ['listItem-row'],
		targetModel: null,
		onChangeEventName: 'listItemValueChanged',
		animateEdit: false,
		isEditing: (function (key, value) {
			if (arguments.length > 1) {
				this.set('_isEditing', value);
			}
			return this.get('_isEditing');
		}).property('_isEditing'),
		_isEditing: false,
		listItemIsEditable: true,

		_valueChanged: (function () {
			var value = this.get('value'),
			    attrName = this.get('attrName'),
			    model = this.get('targetModel'),
			    eventName = this.get('onChangeEventName');

			if (eventName) {
				this[eventName] = eventName;
				this.sendActionWithPropagation(eventName, model, attrName, value);
			}
		}).observes('value'),
		// doubleClick: function(evt) {
		// 	// enable editing and focus on input
		// 	this.set('_isEditing', true);
		// 	var elementId = '#' + this.elementId;
		// 	Ember.run.later(function() {
		// 		Ember.$(elementId).find('input')[0].focus();
		// 	});
		// },
		_isEditingObserver: (function () {
			_ember['default'].run.scheduleOnce('afterRender', this, function () {
				this._toggleEditMode();
			});
		}).observes('_isEditing').on('init'),
		_toggleEditMode: function _toggleEditMode() {
			var $value = this.$('.js-listItem-value');

			if ($value) {
				$value.toggleClass('edit-mode', this.get('_isEditing'));
			}
		},
		_toggleEditModeAnimated: function _toggleEditModeAnimated() {
			var $value = this.$('.js-listItem-value');
			if (this.get('_isEditing')) {
				$value.addClass('edit-transition');
				_ember['default'].run.later(function () {
					$value.addClass('edit-mode');
				}, 500);
			} else {
				$value.removeClass('edit-mode');
				_ember['default'].run.later(function () {
					$value.removeClass('edit-transition');
				}, 1);
			}
		}
	});
});