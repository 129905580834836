define('component-library/components/number-filter', ['exports', 'ember', 'component-library/templates/components/number-filter', 'component-library/components/promise-popover'], function (exports, _ember, _componentLibraryTemplatesComponentsNumberFilter, _componentLibraryComponentsPromisePopover) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
		template: _componentLibraryTemplatesComponentsNumberFilter['default'],
		hasArrow: true,
		placement: 'bottom',
		target: _ember['default'].computed.oneWay('model.target'),
		attachment: "top center",
		targetAttachment: "bottom center",

		filter: _ember['default'].computed.alias('content'),

		min: _ember['default'].computed.oneWay('filter.min'),
		max: _ember['default'].computed.oneWay('filter.max'),

		addOrApply: (function () {
			return this.get('filter.isActive') ? 'Apply' : 'Add';
		}).property('filter.isActive'),

		actions: {
			addOrApplyFilter: function addOrApplyFilter() {
				// why the fuck do we need this? fucking unless abstractions
				if (!this.get('filter.isActive')) {
					this.set('filter.isActive', true);
				}

				var min = this.get('min');
				min = !_ember['default'].isEmpty(min) ? Number(min) : null;

				var max = this.get('max');
				max = !_ember['default'].isEmpty(max) ? Number(max) : null;

				this.set('filter.min', min);
				this.set('filter.max', max);

				this.send('close');
			}
		}

	});
});