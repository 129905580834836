define('ui-infra/services/analytics', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Service.extend({
    routing: _ember['default'].inject.service(),
    eventLogger: _ember['default'].inject.service(),

    _subscribeToEvents: _ember['default'].on('init', function () {
      this.get('routing').on('routerWillTransition', this._routerWillTransition.bind(this));
      this.get('routing').on('routerDidTransition', this._routerDidTransition.bind(this));
    }),

    _routerWillTransition: function _routerWillTransition(transition) {
      this.get('eventLogger').setTransitionInfo(transition.targetName);
      this.get('eventLogger').timings.routeTransitionStart = new Date().getTime();
    },
    _routerDidTransition: function _routerDidTransition() {
      _ember['default'].run.next(this, function () {
        this.get('eventLogger').log('pageview', {
          transitionId: this.get('eventLogger').transitionId,
          currentRoute: this.get('routing').get('router').get('url'),
          currentRouteName: this.get('routing.currentRouteName')
        });

        this.get('eventLogger').transitionId = null;
      });
    }
  });
});