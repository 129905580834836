define('component-library/utils/pluralize', ['exports'], function (exports) {
	'use strict';

	var EXCEPTIONS = {
		child: 'children',
		woman: 'women',
		man: 'men',
		person: 'people',
		people: 'people',
		staff: 'staff'
	};

	exports['default'] = function (word) {
		if (word in EXCEPTIONS) {
			return EXCEPTIONS[word];
		} else if (/[^aeiou]y$/.test(word)) {
			word = word.replace(/y$/, 'ie');
		} else if (/sh$|ch$|[xs]$/.test(word)) {
			word += 'e';
		} else if (/f$|fe$/.test(word)) {
			word = word.replace(/f$|fe$/, 've');
		}
		return word + 's';
	};
});