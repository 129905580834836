define('component-library/mixins/payroll/deduction-mappings-mixin', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		_reloadRelevantModels: function _reloadRelevantModels() {
			var mappingsPromise = this.deductionMappingsPromise(this.get('company.id'));
			return _ember['default'].RSVP.all([mappingsPromise]);
		},

		_readMappingsResponse: function _readMappingsResponse(companyId) {
			return _ember['default'].ajaxGet('/api/company/' + companyId + '/read_mappings').then(function (response) {
				return response;
			});
		},

		deductionMappingsPromise: function deductionMappingsPromise(companyId) {
			var _this = this;

			return this._readMappingsResponse(companyId).then(function (response) {
				var mappings = _ember['default'].isNone(response) ? null : response.mappings;
				_this.set('mappings', mappings);
				return _ember['default'].RSVP.resolve();
			});
		},

		isDeductionInfoComplete: (function () {
			var missingMappings = !_ember['default'].isEmpty(this.get('mappings')) && this.get('mappings').any(function (mapping) {
				return _ember['default'].isNone(mapping.deductionId);
			});
			return !missingMappings;
		}).property('mappings', 'mappings.@each.deductionId'),

		payrollProvider: _ember['default'].computed.alias('company.payrollProvider'),

		description: (function () {
			switch (this.get('payrollProvider')) {
				case 'PX':
					return 'name';
				case 'QB':
				case 'IN':
				case 'MP':
					return 'description';
				case 'ZN':
					return 'benefit name';
				default:
					return 'code';
			}
		}).property('payrollProvider'),

		pluralize: function pluralize(sentence) {
			return sentence + 's';
		},

		titlize: function titlize(sentence) {
			var words = sentence.split(' ').map(function (word) {
				return word.substr(0, 1).toUpperCase() + word.substr(1).toLowerCase();
			});
			return words.join(' ');
		},

		descriptionP: (function () {
			return this.pluralize(this.get('description'));
		}).property('description'),

		descriptionTP: (function () {
			return this.titlize(this.get('descriptionP'));
		}).property('descriptionP')
	});
});