define('component-library/constants/benefits-qle', ['exports'], function (exports) {
	'use strict';

	var QLE_TYPE_CHOICES = {
		move_to_ft: 'Moved to full-time',
		move_out_of_ft: 'Moved out of full-time',
		loss_of_coverage: 'Loss of coverage',
		age_out: 'Aged out; became 26 years of age',
		dependent: 'Dependent',
		change_plan: 'Change Plan'
	};

	exports.QLE_TYPE_CHOICES = QLE_TYPE_CHOICES;

	var QLE_SUBTYPE_CHOICES = {
		marriage: 'Marriage',
		domestic_partnership: 'Domestic partnership',
		divorce: 'Divorce',
		legal_separation: 'Legal separation',
		spouse_death: 'Death of a spouse',
		domestic_partner_death: 'Death of a domestic partner',
		birth: 'Birth of a child',
		adoption: 'Adoption',
		legal_guardianship: 'Legal guardianship of a child',
		dependent_death: 'Death of a child',
		court_order: 'Court order dependent',
		ageout: 'Age-out',
		dep_other_coverage: 'Dependent gained coverage elsewhere',
		coveragelost: 'Dependent loss of coverage under their own plan',
		dep_parent_plan: 'Dependent loss of coverage under parent\'s plan',
		dep_cobra_expire: 'Dependent COBRA coverage expired',
		dep_international_insurance: 'Dependent loss of international insurance eligibility',
		dep_medicaid: 'Dependent loss of Medicaid eligibility',
		spouse_plan: 'Loss of coverage under spouse\'s plan',
		domestic_partner_plan: 'Loss of coverage under domestic partner\'s plan',
		parent_plan: 'Loss of coverage under parent\'s plan',
		individual_plan: 'Loss of coverage under individual or marketplace plan',
		cobra_expire: 'Expiration of COBRA coverage',
		international_insurance: 'Loss of international insurance eligibility',
		medicaid: 'Loss of Medicaid eligibility',
		relocation: 'Relocation outside of network',
		other_coverage: 'Employee gained coverage elsewhere',
		other: 'Other',
		backfill: 'Backfill',
		aca: 'ACA',
		bor_broker_service: 'BoR broker services'
	};
	exports.QLE_SUBTYPE_CHOICES = QLE_SUBTYPE_CHOICES;
});