(function() {
  function vendorModule(Ember) {
    'use strict';
    Ember['default'].assert('Make sure squire-raw.js is imported and loaded before to import this module.' +
    	'in client-app this library is imported into vendor-lazy.js. self.Squire is not defined',
    	!!self.Squire);
    return { 'default': self.Squire };
  }

  define('squire', ['ember'], vendorModule);
})();
