define('component-library/mixins/payroll/state-tax-mixin', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	var _slicedToArray = (function () {
		function sliceIterator(arr, i) {
			var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;_e = err;
			} finally {
				try {
					if (!_n && _i['return']) _i['return']();
				} finally {
					if (_d) throw _e;
				}
			}return _arr;
		}return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError('Invalid attempt to destructure non-iterable instance');
			}
		};
	})();

	var CURRENT_MONTH = (0, _componentLibraryUtilsGlobals.moment)().month();
	var CURRENT_YEAR = (0, _componentLibraryUtilsGlobals.moment)().year();

	var getQuarterStartDate = function getQuarterStartDate(quarter) {
		return ({
			1: '01/01/',
			2: '04/01/',
			3: '07/01/',
			4: '10/01/'
		})[quarter];
	};

	// Copy for state Quarter select field
	var getQuarterName = function getQuarterName(date) {
		var _date = _slicedToArray(date, 2);

		var year = _date[0];
		var month = _date[1];

		var quarter = Math.floor(month / 3) + 1;
		return 'Q' + quarter + ' - ' + getQuarterStartDate(quarter) + year;
	};

	var getDate = function getDate(date) {
		var _date2 = _slicedToArray(date, 2);

		var year = _date2[0];
		var month = _date2[1];

		var quarter = Math.floor(month / 3) + 1;
		return '' + getQuarterStartDate(quarter) + year;
	};

	var getTaxDateOptions = function getTaxDateOptions(dates) {
		return dates.map(function (date) {
			return {
				value: getDate(date), // '01/01/2016'
				label: getQuarterName(date)
			};
		});
	};
	exports['default'] = _ember['default'].Mixin.create({
		getQuarterName: getQuarterName,
		getDate: getDate,
		getTaxDateOptions: getTaxDateOptions,
		currentQuarterHuman: getQuarterName([CURRENT_YEAR, CURRENT_MONTH])
	});
});