define('component-library/services/features', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].ObjectProxy.extend({
		init: function init(features) {
			features = features || [];
			var featuresObj = {};
			features.forEach(function (featureData) {
				featuresObj[featureData['feature']] = true;
			});
			this.set('content', featuresObj);
		},

		allFeatureData: null,
		initAllFeatureData: function initAllFeatureData(features) {
			features = features || [];
			this.set('allFeatureData', features);
		},

		reload: function reload() {
			var _this = this;

			return _ember['default'].getJSON('/custom_api/all_active_features_data/').then(function (featuresData) {
				_this.init(featuresData);
				_this.initAllFeatureData(featuresData);
			});
		},

		isActive: function isActive(feature) {
			return this.get(feature);
		},

		isEffectiveStartDatePassed: function isEffectiveStartDatePassed(feature) {
			if (!this.isActive(feature)) {
				return false;
			}

			var isEffective = true;
			var allFeatureData = this.get('allFeatureData');
			var featureData = allFeatureData ? allFeatureData.findBy('feature', feature) : null;
			isEffective = featureData ? featureData.isEffective : isEffective;

			return isEffective;
		}
	});
});