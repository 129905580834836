define('component-library/components/external-radio', ['exports', 'ember', 'component-library/templates/components/external-radio'], function (exports, _ember, _componentLibraryTemplatesComponentsExternalRadio) {
	'use strict';

	var getUUID = function getUUID() {
		return 'xxxxxxxxxxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 | 0,
			    v = c == 'x' ? r : r & 0x3 | 0x8;
			return v.toString(16);
		}).replace('-', '');
	};

	exports['default'] = _ember['default'].Component.extend({
		layout: _componentLibraryTemplatesComponentsExternalRadio['default'],
		/*
  * Input options should be a json string with the format -
  *	'[["Option Label 1", "value1"], ["Option Label 2", "value2"], ["Option Label 3", "value3"]]'
  */
		parsedOptions: (function () {
			var options = this.get('options');
			var optionsList = JSON.parse(options);
			return optionsList.map(function (option) {
				return _ember['default'].Object.create({
					'label': option[0],
					'value': option[1]
				});
			});
		}).property('options')
	});
});