define('component-library/components/contribution-card', ['exports', 'ember', 'component-library/components/confirmation-modal', 'component-library/components/contributions-modal/constants'], function (exports, _ember, _componentLibraryComponentsConfirmationModal, _componentLibraryComponentsContributionsModalConstants) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: 'z-contributionCard',
		isReadOnly: false,
		mapping: [],
		isValidRule: _ember['default'].computed('rule.carrierId', 'rule.planId', 'rule.lineOfCoverage', 'mapping', function () {
			var mapping = this.get('mapping');
			var rule = this.get('rule');
			var loc = rule.get('lineOfCoverage');
			var carrierId = rule.get('carrierId');
			var planId = rule.get('planId');
			var isValid = true;
			var reason = '';
			if (!(loc in mapping)) {
				isValid = false;
				reason = _componentLibraryComponentsContributionsModalConstants.INVALID_LOC;
			} else if (carrierId && !(carrierId in mapping[loc])) {
				isValid = false;
				reason = _componentLibraryComponentsContributionsModalConstants.INVALID_CARRIER;
			} else if (planId && !mapping[loc][carrierId].includes(planId)) {
				isValid = false;
				reason = _componentLibraryComponentsContributionsModalConstants.INVALID_PLAN;
			}
			return {
				isValid: isValid,
				reason: reason
			};
		}),
		ruleEligibility: _ember['default'].computed('rule.employeeTemplate.type', function () {
			var _this = this;

			var ruleEligibilityResult = {
				tierSpecfic: false,
				tierNames: [],
				displayEmployees: false,
				employeesForAvatar: []
			};
			switch (this.get('rule.employeeTemplate.type')) {
				case _componentLibraryComponentsContributionsModalConstants.IN_EMPLOYEE_IDS:
					{
						var _ret = (function () {
							var employeeIds = _this.get('rule.employeeTemplate.employeeIds').map(function (eId) {
								return parseInt(eId);
							});
							var employeesForAvatar = _this.get('employees').filter(function (e) {
								return employeeIds.includes(e.get('id'));
							}).map(function (employee) {
								return {
									firstName: employee.get('first_name'),
									lastName: employee.get('last_name'),
									photoUrl: employee.get('photoThumbnailUrl')
								};
							});
							ruleEligibilityResult.displayEmployees = true;
							ruleEligibilityResult.employeesForAvatar = employeesForAvatar;
							return {
								v: ruleEligibilityResult
							};
						})();

						if (typeof _ret === 'object') return _ret.v;
					}
				case _componentLibraryComponentsContributionsModalConstants.IN_CONTRIBUTION_TIERS:
					{
						var tierNames = this.get('rule.employeeTemplate.tiers');
						ruleEligibilityResult.tierSpecfic = true;
						ruleEligibilityResult.tierNames = tierNames;
						return ruleEligibilityResult;
					}
				default:
					{
						return ruleEligibilityResult;
					}
			}
		}),
		descriptions: _ember['default'].computed('rule.description', function () {
			var ruleDescription = this.get('rule.description') || 'generating the descriptions.....';
			return ruleDescription.replace(/ and /g, ',').replace(/: /g, ',').split(',').map(function (rule) {
				return rule.trim();
			});
		}),
		appliedLocs: _ember['default'].computed('rule.lineOfCoverage', function () {
			var loc = this.get('rule.lineOfCoverage');
			if (loc) {
				return [_componentLibraryComponentsContributionsModalConstants.LOC_TITLES[loc]];
			}
			return Object.keys(_componentLibraryComponentsContributionsModalConstants.LOCS).map(function (loc) {
				return _componentLibraryComponentsContributionsModalConstants.LOC_TITLES[_componentLibraryComponentsContributionsModalConstants.LOCS[loc]];
			});
		}),
		getCarrier: function getCarrier(carrierId) {
			return carrierId ? this.get('carriers').findBy('id', carrierId) : null;
		},
		appliedCarrier: _ember['default'].computed('rule.carrierId', function () {
			var carrierId = this.get('rule.carrierId');
			return this.getCarrier(carrierId);
		}),
		appliedPlan: _ember['default'].computed('rule.lineOfCoverage', 'rule.planId', function () {
			var loc = this.get('rule.lineOfCoverage');
			var planId = this.get('rule.planId');
			var appliedPlanResult = _ember['default'].Object.create({
				coverageText: 'All',
				displayName: null
			});
			// we support only MDV, this is a safeguard
			if (loc && Object.keys(_componentLibraryComponentsContributionsModalConstants.LOCS).indexOf(loc.toUpperCase()) == -1) {
				return appliedPlanResult;
			}
			if (loc) {
				appliedPlanResult.coverageText = '' + _componentLibraryComponentsContributionsModalConstants.LOC_TITLES[loc];
			}
			if (planId) {
				var plan = this.get('plans').findBy('id', planId);
				appliedPlanResult.displayName = plan ? plan.get('displayName') : 'Not Active Plan';
			}
			return appliedPlanResult;
		}),
		actions: {
			remove: function remove() {
				var _this2 = this;

				_componentLibraryComponentsConfirmationModal['default'].show({
					title: 'Delete Rule',
					message: "There's no way to reinstate a rule that's been deleted.",
					ctaText: 'Continue'
				}).then(function () {
					_this2.attrs.deleteSelectedRule(_this2.get('rule'));
				});
			},
			edit: function edit() {
				this.attrs.editSelectedRule(this.get('rule'));
			}
		}
	});
});