define('component-library/components/data-table-wrapper-in-modal', ['exports', 'ember', 'component-library/templates/components/data-table-wrapper-in-modal'], function (exports, _ember, _componentLibraryTemplatesComponentsDataTableWrapperInModal) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		layout: _componentLibraryTemplatesComponentsDataTableWrapperInModal['default'],
		classNames: ['u-positionRelative', 'u-fillHorizontal'],

		didInsertElement: function didInsertElement() {
			this.$().css('height', this.get('height'));
		}
	});
});