define('ui-infra/instance-initializers/analytics-service', ['exports', 'ui-infra/utils/ember-compatibility-utils'], function (exports, _uiInfraUtilsEmberCompatibilityUtils) {
  'use strict';

  exports.initialize = initialize;

  function initialize(appInstance) {
    // We need to eagerly look it up so it starts tracking
    (0, _uiInfraUtilsEmberCompatibilityUtils.lookup)(appInstance, 'service:analytics');
  }

  exports['default'] = {
    name: 'analytics-service',
    initialize: initialize
  };
});