define('component-library/components/benefits/timeline/transaction-detail-plan', ['exports', 'ember', 'component-library/templates/components/benefits/timeline/transaction-detail-plan', 'component-library/mixins/benefits/timeline/transaction-employee-data-mixin', 'component-library/mixins/benefits/timeline/transaction-dependents-data-mixin', 'component-library/mixins/benefits/timeline/transaction-contact-data-mixin'], function (exports, _ember, _componentLibraryTemplatesComponentsBenefitsTimelineTransactionDetailPlan, _componentLibraryMixinsBenefitsTimelineTransactionEmployeeDataMixin, _componentLibraryMixinsBenefitsTimelineTransactionDependentsDataMixin, _componentLibraryMixinsBenefitsTimelineTransactionContactDataMixin) {
	'use strict';

	var oldEmployeeValueForDisplay = function oldEmployeeValueForDisplay(propertyName) {
		return _ember['default'].computed('transaction.isInitial', 'transaction.isContextChangeError', propertyName, function () {
			if (this.get('transaction.isInitial')) {
				return '-';
			} else if (this.get('transaction.isContextChangeError')) {
				return '?';
			} else {
				return this.get(propertyName) || '-';
			}
		});
	};

	var newEmployeeValueForDisplay = function newEmployeeValueForDisplay() {
		for (var _len = arguments.length, propertyNames = Array(_len), _key = 0; _key < _len; _key++) {
			propertyNames[_key] = arguments[_key];
		}

		var newValue = propertyNames[0];
		var oldValue = propertyNames[1];
		var valueChange = propertyNames[2];

		return _ember['default'].computed.apply(_ember['default'], ['transaction.isInitial', 'transaction.isContextChangeError'].concat(propertyNames, [function () {
			if (!_ember['default'].isEmpty(this.get(valueChange)) || this.get('transaction.isInitial') || this.get('transaction.isContextChangeError')) {
				return this.get(newValue) || '-';
			} else if (this.get(oldValue)) {
				return 'No Change';
			} else {
				return '-';
			}
		}]));
	};

	var oldDependentValueForDisplay = function oldDependentValueForDisplay(propertyName) {
		return _ember['default'].computed('parent.transaction.isInitial', 'parent.transaction.isContextChangeError', 'isEnrolledOld', propertyName, function () {
			if (this.get('parent.transaction.isInitial')) {
				return '-';
			} else if (this.get('parent.transaction.isContextChangeError')) {
				return '?';
			} else if (this.get('isEnrolledOld')) {
				return this.get(propertyName) || '-';
			} else {
				return '-';
			}
		});
	};

	var newDependentValueForDisplay = function newDependentValueForDisplay() {
		for (var _len2 = arguments.length, propertyNames = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
			propertyNames[_key2] = arguments[_key2];
		}

		var newValue = propertyNames[0];
		var oldValue = propertyNames[1];
		var valueChange = propertyNames[2];

		return _ember['default'].computed.apply(_ember['default'], ['parent.transaction.isInitial', 'parent.transaction.isContextChangeError', 'isEnrolledNew', 'isEnrolledOld'].concat(propertyNames, [function () {
			if (!this.get('isEnrolledNew')) {
				return '-';
			} else {
				if (!this.get('isEnrolledOld') || !_ember['default'].isEmpty(this.get(valueChange)) || this.get('parent.transaction.isInitial') || this.get('parent.transaction.isContextChangeError')) {
					return this.get(newValue) || '-';
				} else if (this.get(oldValue)) {
					return 'No Change';
				} else {
					return '-';
				}
			}
		}]));
	};

	var DependentViewModel = _ember['default'].ObjectProxy.extend({
		oldCarrierNameForDisplay: oldDependentValueForDisplay('parent.oldCarrierName'),
		oldPlanNameForDisplay: oldDependentValueForDisplay('parent.oldPlanName'),
		oldCoverageEffectiveDateForDisplay: oldDependentValueForDisplay('oldCoverageEffectiveDate'),
		carrierNameForDisplay: newDependentValueForDisplay('parent.carrierName', 'parent.oldCarrierName', 'parent.carrierChange'),
		planNameForDisplay: newDependentValueForDisplay('parent.planName', 'parent.oldPlanName', 'parent.planChange'),
		coverageEffectiveDateForDisplay: newDependentValueForDisplay('coverageEffectiveDate', 'oldCoverageEffectiveDate', 'coverageEffectiveDateChange')
	});

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsBenefitsTimelineTransactionEmployeeDataMixin['default'], _componentLibraryMixinsBenefitsTimelineTransactionDependentsDataMixin['default'], _componentLibraryMixinsBenefitsTimelineTransactionContactDataMixin['default'], {
		layout: _componentLibraryTemplatesComponentsBenefitsTimelineTransactionDetailPlan['default'],
		store: _ember['default'].inject.service(),

		didReceiveAttrs: function didReceiveAttrs() {
			var _this = this;

			this._super.apply(this, arguments);
			this.get('planNamePromise').then(function (name) {
				return _this.set('planName', name);
			});
			this.get('oldPlanNameDisplayPromise').then(function (name) {
				return _this.set('oldPlanName', name);
			});
		},

		oldCarrierNameForDisplay: oldEmployeeValueForDisplay('oldCarrierName'),
		oldPlanNameForDisplay: oldEmployeeValueForDisplay('oldPlanName'),
		oldCoverageEffectiveDateForDisplay: oldEmployeeValueForDisplay('oldCoverageEffectiveDate'),
		oldRidersNameForDisplay: oldEmployeeValueForDisplay('oldRidersName'),
		oldAddressForDisplay: oldEmployeeValueForDisplay('oldAddress'),
		oldCityForDisplay: oldEmployeeValueForDisplay('oldCity'),
		oldContactStateForDisplay: oldEmployeeValueForDisplay('oldContactState'),
		oldZipForDisplay: oldEmployeeValueForDisplay('oldZip'),

		carrierNameForDisplay: newEmployeeValueForDisplay('carrierName', 'oldCarrierName', 'carrierChange'),
		planNameForDisplay: newEmployeeValueForDisplay('planName', 'oldPlanName', 'planChange'),
		coverageEffectiveDateForDisplay: newEmployeeValueForDisplay('coverageEffectiveDate', 'oldCoverageEffectiveDate', 'coverageEffectiveDateChange'),
		ridersNameForDisplay: newEmployeeValueForDisplay('ridersName', 'oldRidersName', 'ridersChange'),
		addressForDisplay: newEmployeeValueForDisplay('address', 'oldAddress', 'addressChange'),
		cityForDisplay: newEmployeeValueForDisplay('city', 'oldCity', 'cityChange'),
		contactStateForDisplay: newEmployeeValueForDisplay('contactState', 'oldContactState', 'contactStateChange'),
		zipForDisplay: newEmployeeValueForDisplay('zip', 'oldZip', 'zipChange'),

		dependentViewModels: _ember['default'].computed('dependentChangeModels', function () {
			var self = this;
			return this.get('dependentChangeModels').map(function (item) {
				return DependentViewModel.create({
					content: item,
					parent: self
				});
			});
		})
	});
});