define('model-library/models/default-feed-entry-data', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	exports['default'] = _emberData['default'].Model.extend({
		eventsCount: _emberData['default'].attr('number'),
		eventsHidden: (function () {
			return this.get('eventsCount') - 1;
		}).property('eventsCount'),

		lastEvent: _emberData['default'].belongsTo(_emberData['default'].Model, {
			isPolymorphic: true,
			key: 'lastEvent',
			modelNameKey: 'lastEvent_type',
			embedded: true
		})
	});
});