/* globals define, self */
(function() {
  function vendorModule() {
    'use strict';

    return { 'default': self['zen'] };
  }

  define('zen', [], vendorModule);
})();
