define('component-library/components/master-service-agreement', ['exports', 'ember', 'component-library/templates/components/master-service-agreement', 'component-library/components/master-service-agreement-modal'], function (exports, _ember, _componentLibraryTemplatesComponentsMasterServiceAgreement, _componentLibraryComponentsMasterServiceAgreementModal) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		template: _componentLibraryTemplatesComponentsMasterServiceAgreement['default'],
		tagName: '',
		agreementPath: null,

		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super.apply(this, arguments);

			_ember['default'].ajaxGet('/custom_api/saas_agreement/').then(function (agreement) {
				_this.set('agreementPath', agreement.path);
			});
		},

		actions: {
			openMsaModal: function openMsaModal() {
				_componentLibraryComponentsMasterServiceAgreementModal['default'].show({
					agreementPath: this.get('agreementPath')
				});
			}
		}
	});
});