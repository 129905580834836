define('component-library/components/z-simple-wizard-navigation', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNameBindings: ['isScrollable:is-scrollable'],
		isScrollable: true,
		sequenceEnforced: false,
		steps: {},
		activeRoute: "",

		simpleWizardFlow: {},
		actions: {
			transitionToStepRoute: function transitionToStepRoute(routeName) {
				this.sendAction('triggerWizardAction', 'transitionToStepRoute', routeName);
			}
		}
	});
});