define('component-library/timeline/components/location-change', ['exports', 'ember', 'component-library/timeline/components/copy', 'component-library/timeline/mixins/change-feed-entry'], function (exports, _ember, _componentLibraryTimelineComponentsCopy, _componentLibraryTimelineMixinsChangeFeedEntry) {
	'use strict';

	exports['default'] = _componentLibraryTimelineComponentsCopy['default'].extend(_componentLibraryTimelineMixinsChangeFeedEntry['default'], {

		label: 'location',
		oldVal: _ember['default'].computed.oneWay('data.old_location.nameOrAddress'),
		newVal: _ember['default'].computed.oneWay('data.new_location.nameOrAddress')

	});
});