define('component-library/components/cards/payroll/payroll-tax-state-level-group', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		jurisdiction: undefined,
		zPayrollEnums: undefined,
		company: undefined,
		// taxHeaders: undefined,
		localHeaders: undefined,
		stateHeaders: undefined,
		hasPermissionToChangeAgencyStatus: undefined,
		enableSelfServeRecon: false,

		actions: {
			updateLevelOfService: function updateLevelOfService(taxHeader) {
				this.attrs.updateLevelOfService(taxHeader);
			},

			updateAnswerEffective: function updateAnswerEffective(taxHeader, question) {
				this.attrs.updateAnswerEffective(taxHeader, question);
			},

			viewHistoricalAgencyInfo: function viewHistoricalAgencyInfo(taxHeader, agencyInfoKey, agencyInfoKeyName) {
				this.attrs.viewHistoricalAgencyInfo(taxHeader, agencyInfoKey, agencyInfoKeyName);
			},

			viewContinuousReconHistory: function viewContinuousReconHistory(taxHeader, taxType) {
				this.attrs.viewContinuousReconHistory(taxHeader, taxType);
			}
		}

	});
});