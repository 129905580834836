define('component-library/components/payroll/smp/sync-diff-table/main-component', ['exports', 'ember', 'component-library/utils/globals', 'component-library/mixins/payroll/smp/table-with-filters-mixin', 'component-library/data-table/column', 'component-library/data-table/cells/avatarname-table-cell', 'component-library/data-table/cells/checkbox-table-cell', 'component-library/components/payroll/smp/sync-diff-table/selector-cell-view'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryMixinsPayrollSmpTableWithFiltersMixin, _componentLibraryDataTableColumn, _componentLibraryDataTableCellsAvatarnameTableCell, _componentLibraryDataTableCellsCheckboxTableCell, _componentLibraryComponentsPayrollSmpSyncDiffTableSelectorCellView) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsPayrollSmpTableWithFiltersMixin['default'], {
		model: null,
		renderData: null,
		maxHeight: _ember['default'].computed.alias('renderData.maxHeight'),

		data: (function () {
			return this.get('model').map(function (row) {
				var sortKey = row.get('last_name') + '-' + row.get('first_name') + '-' + row.get('employeeId') + '-' + row.get('keyPretty');
				row.setProperties({
					sortKey: sortKey,
					resolution: row.get('resolution') || '',
					proposedResolution: row.get('resolution') || '',
					selected: false
				});
				return row;
			}).sortBy('sortKey').filterBy('ignored', false);
		}).property('model', 'model.@each.ignored'),

		columns: (function () {
			return [_componentLibraryDataTableColumn['default'].create({
				savedWidth: 25,
				isSortable: false,
				headerCellName: '',
				contentPath: 'selected',
				tableCellViewClass: _componentLibraryDataTableCellsCheckboxTableCell['default']
			}), _componentLibraryDataTableColumn['default'].create({
				isSorted: false,
				isSortable: false,
				headerCellName: 'Employee',
				canAutoResize: true,
				tableCellViewClass: _componentLibraryDataTableCellsAvatarnameTableCell['default']
			}), _componentLibraryDataTableColumn['default'].create({
				isSorted: false,
				isSortable: false,
				headerCellName: 'Property',
				contentPath: 'keyPretty'
			}), _componentLibraryDataTableColumn['default'].create({
				isSorted: false,
				isSortable: false,
				headerCellName: 'In ' + _componentLibraryUtilsGlobals.brandName,
				resolvedValue: 'ZENEFITS',
				contentPath: 'inZenefits',
				tableCellViewClass: _componentLibraryComponentsPayrollSmpSyncDiffTableSelectorCellView['default']
			}), _componentLibraryDataTableColumn['default'].create({
				isSorted: false,
				isSortable: false,
				headerCellName: 'In Payroll',
				resolvedValue: 'PAYROLL',
				contentPath: 'inPayroll',
				tableCellViewClass: _componentLibraryComponentsPayrollSmpSyncDiffTableSelectorCellView['default']
			})];
		}).property(),

		footer: (function () {
			var rows = this.get('filteredRows.length');
			var numEmployees = this.get('filteredRows').map(function (row) {
				return row.get('employeeId');
			}).uniq().get('length');
			var employeeText = this.pluralize(numEmployees, "Employee") + " (" + this.pluralize(rows, "row") + ")";
			// NOTE It might seem strange to use 'lastName' to render the footer content,
			// but the AvatarNameTableCellViewClass view gets its data from lastName,
			// so that is where the footer content shall go
			return _ember['default'].Object.create({
				'selected': null,
				'lastName': employeeText,
				'default_content': employeeText,
				'selectedCount': 0,
				'photoUrl': '/static/img/payroll/sync-back/employees-footer.svg',
				'groupSubIndex': 0,
				'keyPretty': ' ',
				'inZenefits': '' + _componentLibraryUtilsGlobals.brandName,
				'inPayroll': 'Payroll',
				'isFooter': true,
				'proposedResolution': null
			});
		}).property('filteredRows'),

		selectObserver: (function () {
			_ember['default'].run.once(this, 'footerTextSetter');
		}).observes('filteredRows.@each.selected'),

		footerTextSetter: function footerTextSetter() {
			var selectedCount = this.get('filteredRows').filterBy('selected').length;
			var totalCount = this.get('filteredRows.length');
			var footer = this.get('footer');
			var changes = {};
			if (selectedCount === 0) {
				changes.lastName = footer.get('default_content');
			} else {
				changes.lastName = this.pluralize(selectedCount, 'row') + ' selected';
			}
			changes.selectedCount = selectedCount;
			if (selectedCount > 0 && selectedCount < totalCount) {
				changes.selected = null;
			}
			footer.setProperties(changes);
		},

		footerMassAction: (function () {
			var footer = this.get('footer');
			var proposedResolution = footer.get('proposedResolution');
			if (!proposedResolution) {
				return;
			}
			var changedRows = this.get('filteredRows').filter(function (row) {
				if (row.get('selected') && row.get('proposedResolution') !== proposedResolution) {
					row.set('proposedResolution', proposedResolution);
					return true;
				}
				return false;
			});
			// unset the resolution for selected rows if clicked twice
			if (changedRows.length === 0) {
				this.get('filteredRows').map(function (row) {
					return row.get('selected') && row.set('proposedResolution', '');
				});
			}
			footer.set('proposedResolution', null);
		}).observes('footer.proposedResolution'),

		footerMassSelector: (function () {
			var footer = this.get('footer');
			var checked = footer.get('selected');
			if (checked === null) {
				return;
			}
			this.get('filteredRows').forEach(function (row) {
				return row.set('selected', checked);
			});
		}).observes('footer.selected'),

		footerRow: (function () {
			return [this.get('footer')];
		}).property('footer'),

		undecidedOnlyChanged: (function () {
			this.get('footer').set('selected', null);
			if (this.get('renderData.undecidedOnly')) {
				this.updateValueFilter('proposedResolution', '');
			} else {
				this.updateValueFilter('proposedResolution', null);
			}
		}).observes('renderData.undecidedOnly'),

		search: (function () {
			this.get('footer').set('selected', null);
			this.updateTextFilter('fullName', this.get('renderData.employeeSearchQuery'));
			this.updateTextFilter('keyPretty', this.get('renderData.keySearchQuery'));
		}).observes('renderData.employeeSearchQuery', 'renderData.keySearchQuery'),

		actions: {
			selectItem: function selectItem(row) {
				if (row.get('id') != this.get('renderData.detailsRow.id')) {
					this.set('renderData.detailsRow', row);
				} else {
					this.set('renderData.detailsRow', null);
				}
			},
			closeDetails: function closeDetails() {
				this.set('renderData.detailsRow', null);
			}
		}
	});
});