define('component-library/components/rate-filter', ['exports', 'ember', 'component-library/templates/components/rate-filter', 'component-library/components/promise-popover'], function (exports, _ember, _componentLibraryTemplatesComponentsRateFilter, _componentLibraryComponentsPromisePopover) {
  'use strict';

  var valueAsStringToNumberOrNull = function valueAsStringToNumberOrNull(valueString, asFloat) {
    var fn = asFloat ? parseFloat : parseInt;
    var value = _ember['default'].typeOf(valueString) === 'string' ? fn(valueString) : undefined;
    return isNaN(value) ? null : value;
  };

  exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
    template: _componentLibraryTemplatesComponentsRateFilter['default'],
    hasArrow: true,
    placement: 'bottom',
    target: _ember['default'].computed.oneWay('model.target'),
    attachment: "top center",
    targetAttachment: "bottom center",

    filter: _ember['default'].computed.alias('content'),

    newAgeStr: "",
    newZipStr: "",
    newRateStr: "",
    initFilterValue: (function () {
      // TODO(ed): the component should take the value using filter.formatAs
      if (this.get('filter.age') !== null) {
        this.set('newAgeStr', "" + this.get('filter.age'));
      }
      if (this.get('filter.zip') !== null) {
        this.set('newZipStr', "" + this.get('filter.zip'));
      }
      if (this.get('filter.rate') !== null) {
        this.set('newRateStr', "" + this.get('filter.rate'));
      }
    }).on('init'),

    addOrApply: (function () {
      return this.get('filter.isActive') ? 'Apply' : 'Add';
    }).property('filter.isActive'),

    actions: {
      addOrApplyFilter: function addOrApplyFilter() {
        if (!this.get('filter.isActive')) {
          this.set('filter.isActive', true);
        }
        var asFloat = this.get('filter.formatAs') == 'float';
        var age = valueAsStringToNumberOrNull(this.get('newAgeStr'), asFloat);
        var zip = valueAsStringToNumberOrNull(this.get('newZipStr'), asFloat);
        var rate = valueAsStringToNumberOrNull(this.get('newRateStr'), asFloat);
        this.set('filter.age', age);
        this.set('filter.zip', zip);
        this.set('filter.rate', rate);
        this.send('close');
      }
    }
  });
});