define('component-library/data-table/cells/reordering-table-cell', ['exports', 'ember-table/views/table-cell'], function (exports, _emberTableViewsTableCell) {
	'use strict';

	exports['default'] = _emberTableViewsTableCell['default'].extend({
		templateName: 'data-table/cells/reordering-table-cell',

		actions: {
			incrementOrder: function incrementOrder() {
				this.get('controller').send('moveRow', this.get('row'), 'up');
			},
			decrementOrder: function decrementOrder() {
				this.get('controller').send('moveRow', this.get('row'), 'down');
			}
		}
	});
});