(function() {
  function vendorModule(Ember) {
    'use strict';
    Ember['default'].assert('Make sure fieldkit.js is imported and loaded before importing this module.' +
    	'in client-app this library is imported into vendor-lazy.js. self.FieldKit is not defined',
    	!!self.FieldKit);
    return { 'default': self.FieldKit };
  }

  define('fieldkit', ['ember'], vendorModule);
})();
