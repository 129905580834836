define('component-library/mixins/pto/request-modal', ['exports', 'component-library/utils/globals', 'ember'], function (exports, _componentLibraryUtilsGlobals, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		availableVacationTypes: _ember['default'].computed.filterByProperty('model.employee.pto.companyPto.vacationTypes', 'isAvailable'),
		employees: _ember['default'].computed.filterByProperty('baseController.employees', 'pto.companyPto'),
		empPtos: _ember['default'].computed.alias('baseController.empPtos'),

		maximumHours: _ember['default'].computed('model.startDate', 'model.endDate', function () {
			var startDate = (0, _componentLibraryUtilsGlobals.moment)(this.get('model.startDate'));
			var endDate = (0, _componentLibraryUtilsGlobals.moment)(this.get('model.endDate'));

			// Ensure that users can't request more than 24 hours/day
			return 24 * (endDate.diff(startDate, 'days') + 1);
		}),

		calcHoursPromise: function calcHoursPromise(requireNew, includeHours) {
			var model = this.get('model');
			//guards against triggering the calculation when the model is loaded or rolled back for cancel
			if (!model.get('isNew') && requireNew) {
				return;
			}
			if (!model.get('employee')) {
				return;
			}
			if (!model.get('datesAreValid')) {
				return;
			}
			var endDate = model.get('endDate');
			var startDate = model.get('startDate');
			var isHalfDay = false;
			var vacationType = model.get('vacationType.id');
			var employeeId = model.get('employee.id');
			var hours = model.get('hours');
			var querystring = '?startDate=' + startDate + "&endDate=" + endDate + "&vacationType=" + vacationType + "&isHalfDay=" + isHalfDay + "&employee=" + employeeId;
			if (includeHours) {
				querystring += '&hours=' + hours;
			}
			return _componentLibraryUtilsGlobals.$.getJSON('/custom_api/calculateHoursAndAvailability' + querystring).then(function (response) {
				return response.hours;
			});
		},

		getLoaReasonsPromise: function getLoaReasonsPromise() {
			var model = this.get('model');
			//guards against triggering the calculation when the model is loaded or rolled back for cancel
			if (!model.get('employee')) {
				return;
			}
			if (!this.get('model.startDate') || !this.get('model.endDate') || !this.get('model.vacationType.id')) {
				return;
			}
			var employeeId = model.get('employee.id');
			var vacationTypeId = model.get('vacationType.id');
			var endDate = model.get('endDate');
			var startDate = model.get('startDate');
			var vacationRequestId = model.get('id') ? model.get('id') : null;
			var querystring = "?employeeId=" + employeeId + "&vacationRequestId=" + vacationRequestId + "&vacationTypeId=" + vacationTypeId + "&startDate=" + startDate + "&endDate=" + endDate;
			return _componentLibraryUtilsGlobals.$.getJSON('/custom_api/getPolicyReasonsForPto' + querystring).then(function (response) {
				return response.reasons;
			});
		}
	});
});