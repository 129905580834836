define('component-library/components/billing-payment-gateway', ['exports', 'ember', 'ember-cli-bundle-loader/utils/load-assets'], function (exports, _ember, _emberCliBundleLoaderUtilsLoadAssets) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		mode: null,
		onAdd: null,
		onClose: null,

		fakeZuoraId: function fakeZuoraId() {
			var text = "";
			var possible = "abcdef0123456789";

			for (var i = 0; i < 32; i++) {
				text += possible.charAt(Math.floor(Math.random() * possible.length));
			}

			return text;
		},

		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super.apply(this, arguments);

			_ember['default'].ajaxGet('/custom_api/zuora-api-enabled/').then(function (result) {
				if (result.status == 'enabled') {
					_this._loadZuoraSource();
				} else {
					var paymentMethodInfo = {
						zuoraId: _this.fakeZuoraId()
					};
					if (_this.get('mode') === "cc") {
						paymentMethodInfo.type = "cc";
						paymentMethodInfo.holderName = "John Doe";
						paymentMethodInfo.maskedNumber = "***********0005";
						paymentMethodInfo.expirationMonth = 10;
						paymentMethodInfo.expirationYear = 2020;
						paymentMethodInfo.cardType = "AmericanExpress";
					} else if (_this.get('mode') === "ach") {
						paymentMethodInfo.type = "ach";
						paymentMethodInfo.holderName = "Jonh Doe";
						paymentMethodInfo.maskedNumber = "****5678";
						paymentMethodInfo.routingNumber = "112200439";
						paymentMethodInfo.accountType = "Saving";
						paymentMethodInfo.bankName = "RBC";
					}
					_this.processPaymentMethod(paymentMethodInfo);
				}
			});
		},

		_createPopupElement: function _createPopupElement() {
			if (_ember['default'].$('body #zuora_payment').length === 0) {
				_ember['default'].$('body').append('<div id="zuora_payment"></div>');
			}
		},

		_destroyPopupElement: function _destroyPopupElement() {
			if (_ember['default'].$('body #zuora_payment').length) {
				_ember['default'].$('body #zuora_payment').remove();
			}
		},

		_loadZuoraSource: function _loadZuoraSource() {
			var _this2 = this;

			var zuoraSourcePromise = _ember['default'].RSVP.resolve();
			if (!window.Z) {
				zuoraSourcePromise = (0, _emberCliBundleLoaderUtilsLoadAssets.loadScript)('https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js');
			}
			return zuoraSourcePromise.then(function () {
				window.ZXD.receiveMessage(function (message) {
					var jsonData = message.data;
					var data = JSON.parse(jsonData);
					if (data.action === 'close') {
						_this2._destroyPopupElement();
						_this2.attrs.onClose();
					}
				});

				// add element to the page
				_this2._createPopupElement();

				_ember['default'].ajax({
					url: '/custom_api/gateway-params/' + _this2.get('mode') + '/',
					cache: false
				}).then(function (params) {
					window.Z.render(params, {}, function (result) {
						_this2.callback(result);
					});
				});
			});
		},

		didDestroyElement: function didDestroyElement() {
			this._destroyPopupElement();
		},

		callback: function callback(result) {
			var _this3 = this;

			if (result.success) {
				_ember['default'].ajaxPost(result.redirectUrl, result).then(function (paymentMethodInfo) {
					_this3.processPaymentMethod(paymentMethodInfo);
					_this3._destroyPopupElement();
				});
			} else {
				alert("errorcode=" + result.errorCode + ", errorMessage=" + result.errorMessage);
			}
		},

		processPaymentMethod: function processPaymentMethod(paymentMethodInfo) {
			paymentMethodInfo.isActive = true;
			this.attrs.onAdd(paymentMethodInfo);
		}
	});
});