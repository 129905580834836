define('component-library/components/promise-popover', ['exports', 'ember', 'component-library/mixins/tether', 'component-library/components/promise-overlay'], function (exports, _ember, _componentLibraryMixinsTether, _componentLibraryComponentsPromiseOverlay) {
	'use strict';

	var container = null;

	function getContainer() {
		if (!container) {
			var containerId = _ember['default'].$('.js-overlay-container').attr('id');
			container = _ember['default'].View.views[containerId];
		}
		return container;
	}

	var popoverComponent = _componentLibraryComponentsPromiseOverlay['default'].extend(_componentLibraryMixinsTether['default'], {
		classNames: ['z-popover'],
		classNameBindings: ['placementAligment', 'hasArrow:z-popover--hasArrow'],
		isPopover: true,

		// TODO: these are for positioning the arrow
		placement: 'bottom',
		alignment: 'center',
		hasArrow: false,
		arrowOffset: 10,
		// END TODO
		animationDuration: 0,
		isOpened: false,
		openOnHover: false,
		closeOnResize: true,
		closeOnClickHandler: true,
		closeDelay: 0,
		isMouseOverSelf: false,

		targetOffset: (function () {
			var arrowOffset = this.get('arrowOffset');
			var offsets = {
				top: '%@px 0'.fmt(arrowOffset),
				right: '0 %@px'.fmt(arrowOffset),
				bottom: '-%@px 0'.fmt(arrowOffset),
				left: '0 %@px'.fmt(arrowOffset)
			};
			return this.get('hasArrow') ? offsets[this.get('placement')] : undefined;
		}).property('hasArrow', 'arrowOffset'),

		placementAligment: (function () {
			return 'z-popover--placement' + this.get('placement').capitalize() + this.get('alignment').capitalize();
		}).property('placement', 'alignment'),

		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super();

			_ember['default'].run.scheduleOnce('afterRender', function () {
				var animationPromise = _ember['default'].animate(_ember['default'].$.Velocity.animate(_this.$(), {
					opacity: 1
				}, _this.get('animationDuration')));

				// in PhantomJS, Ember.$.Velocity.animate returns null, so we need to hack around it
				if (animationPromise) {
					animationPromise.then(function () {
						_this.set('isOpened', true);
						_this.setupTargetMouseleaveHandler();
					});
				} else {
					_this.set('isOpened', true);
					_this.setupTargetMouseleaveHandler();
				}
			});
		},

		willDestroyElement: function willDestroyElement() {
			this.tearDownTargetMouseleaveHandler();
			this._super();
		},

		handleClick: function handleClick(event) {
			if (!this.get('isOpened')) {
				return true;
			}

			// Check if there are parents for popover link popover
			var $popoverLinkParent = _ember['default'].$(event.target).parents('#' + this.get('elementId'));
			// Check if there are parents for date picker popover (Primarily used for date range filter)
			var $datePickerParent = _ember['default'].$(event.target).parents('.z-datePickerPopover');
			var clickedOutsidePopover = _ember['default'].isEmpty($popoverLinkParent);
			var clickedOnDatePicker = !!$datePickerParent.length;

			// only close if clicked outside popover and it wasn't a date picker selection
			if (clickedOutsidePopover && !clickedOnDatePicker) {
				this.send('close');
				return true;
			}
			return false;
		},

		tearDownTargetMouseleaveHandler: function tearDownTargetMouseleaveHandler() {
			if (this.get('openOnHover')) {
				this.get('target').off('mouseleave.' + this.get('elementId'));
			}
		},

		setupTargetMouseleaveHandler: function setupTargetMouseleaveHandler() {
			var _this2 = this;

			if (this.get('openOnHover')) {
				var thisId = this.get('elementId');
				var isOpened = this.get('isOpened');
				this.get('target').on('mouseleave.' + thisId, function (event) {
					var $parent = _ember['default'].$(event.toElement).parents('#' + thisId);
					var mouseLeaveOutOfParent = _ember['default'].isEmpty($parent);
					if (mouseLeaveOutOfParent && isOpened) {
						_this2.handleClose();
					}
				});
			}
		},

		mouseEnter: function mouseEnter(event) {
			this.set('isMouseOverSelf', true);
		},

		mouseLeave: function mouseLeave(event) {
			this.set('isMouseOverSelf', false);
			if (this.get('openOnHover')) {
				var targetId = this.get('target').attr('id');
				var $self = _ember['default'].$(event.toElement);
				var $parent = $self.parents('#' + targetId);
				var mouseLeaveOutOfTarget = _ember['default'].isEmpty($parent) && $self.attr('id') !== targetId;
				if (mouseLeaveOutOfTarget && this.get('isOpened')) {
					this.handleClose();
				}
			}
		},

		handleClose: function handleClose() {
			var _this3 = this;

			var closeDelay = this.get('closeDelay');
			if (closeDelay > 0) {
				_ember['default'].run.later(function () {
					// check if mouse is over popup; if so, do not close
					if (!_this3.get('isMouseOverSelf')) {
						_this3.send('close');
					}
				}, closeDelay);
			} else {
				this.send('close');
			}
		},

		actions: {
			close: function close(result) {
				var _this4 = this;

				_ember['default'].run.scheduleOnce('afterRender', function () {
					var animationPromise = _ember['default'].$.Velocity.animate(_this4.$(), {
						opacity: 0
					}, _this4.get('animationDuration'));
					_this4.get('_resolve')(result);
					// in PhantomJS, Ember.$.Velocity.animate returns null, so we need to hack around it
					if (animationPromise) {
						_ember['default'].animate(animationPromise).then(function () {
							_ember['default'].run.once(_this4, '_close');
						});
					} else {
						_ember['default'].run.once(_this4, '_close');
					}
				});
			}
		},

		constraints: [{
			to: 'window',
			attachment: 'together'
		}]
	});

	// TODO: move this global subscriptions to an initializer so we can remove them
	// when we destroy the app. This is important for testing, but also if we
	// ever embed the app (e.g. salesforce widget)
	// NOTE: we want to globally handle click for all popover, so we can
	// control how popover close is handled. e.g. when we have two popover
	// one on top of the other. We don't want to close bottom popover when
	// user click on the top popover
	_ember['default'].$(window).on('click', function (event) {
		var container = getContainer();
		if (!container) {
			// The container has either been removed or it was never there.
			// For example, after tests.
			return;
		}
		// TODO investigate why container.length can != container.childViews.length
		var childViews = container.get('childViews');
		var length = childViews && childViews.length || 0;
		for (var i = length - 1; i >= 0; --i) {
			var view = container.get('childViews')[i];
			if (view.get('_state') === 'inDOM' && view.get('isPopover') && view.get('closeOnClickHandler') && !view.handleClick(event)) {
				break;
			}
		}
	});

	_ember['default'].$(window).on('resize', function () {
		var container = getContainer();
		if (!container) {
			return;
		}
		var childViews = container.get('childViews') || [];
		childViews.forEach(function (view) {
			if (view.get('_state') === 'inDOM' && view.get('isPopover') && view.get('closeOnResize')) {
				view.send('close');
			}
		});
	});

	exports['default'] = popoverComponent;
});