define('component-library/components/promise-modal', ['exports', 'ember', 'component-library/components/promise-overlay'], function (exports, _ember, _componentLibraryComponentsPromiseOverlay) {
	'use strict';

	var _slice = Array.prototype.slice;

	var modalComponent = _componentLibraryComponentsPromiseOverlay['default'].extend({
		layoutName: 'components/promise-modal',

		modalDialogClass: null,
		cancelOnBackground: false,
		cancelOnBrowserNav: true,
		allowKeyActions: true,
		enterKeyAction: 'confirm',
		escKeyAction: 'cancel',
		isResponsive: false,
		closeAnimationLength: 300,
		autoFocus: true,
		enableMaxHeight: true,

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			if (this.get('autoFocus')) {
				// Note: need to take the focus off the element that triggered the modal.
				// Otherwise, when the clicks enter it opens another modal.
				this.$('.js-modal').focus();
			}

			var $backdrop = this.$('.js-modal-backdrop');
			var $modalDialog = this.$('.js-modal-dialog');
			var $modalContent = this.$('.js-modal-content');

			_ember['default'].$('body').addClass('d-z-modal-open');
			if (this.get('cancelOnBackground')) {
				this.onClickCancel();
			}
			if (this.get('cancelOnBrowserNav')) {
				this.onBrowserNavButton();
			}

			// Necessary for the animation to work
			_ember['default'].run.next(this, function () {
				$backdrop.addClass('in');
				$modalDialog.addClass('in');
			});
		},

		scrollableModal: function scrollableModal() {
			var $modalHeaderHeight = this.$('.js-modal-header').innerHeight();
			var $modalFooterHeight = this.$('.js-modal-footer').innerHeight();
			var $modalBody = this.$('.js-modal-body');
			var $modalContentHeight = this.$('.js-modal-content').height();
			var totalOverflowContent = $modalBody.innerHeight() + $modalHeaderHeight + $modalFooterHeight;

			if (totalOverflowContent > $modalContentHeight) {
				var maxBodyHeight = $modalContentHeight - ($modalHeaderHeight + $modalFooterHeight);
				$modalBody.css('height', maxBodyHeight);
			}
		},

		onClickCancel: function onClickCancel() {
			var _this = this;

			this.$('.js-modal-backdrop').click(function () {
				_this.send('cancel');
			});
		},

		onBrowserNavButton: function onBrowserNavButton() {
			var _this2 = this;

			var callback = function callback() {
				_ember['default'].$(window).off('popstate.browserNav');
				_this2.send('cancel');
			};
			_ember['default'].$(window).on('popstate.browserNav', callback);
		},

		willDestroyElement: function willDestroyElement() {
			this._super();
			_ember['default'].$('body').removeClass('d-z-modal-open');
			_ember['default'].$(window).off('popstate');
		},

		keyDown: function keyDown(e) {
			if (!this.get('allowKeyActions')) {
				return;
			}

			var enterKeyAction = this.get('enterKeyAction');
			var escKeyAction = this.get('escKeyAction');

			if (e.keyCode === 13 && enterKeyAction) {
				this.send(enterKeyAction);
			} else if (e.keyCode === 27 && escKeyAction) {
				this.send(escKeyAction);
			}
		},

		_closeWithAnimation: function _closeWithAnimation(toResolve) {
			var _this3 = this;

			toResolve = toResolve || function () {};

			var close = function close() {
				_this3._close();
				toResolve();
			};
			var animationPromise = _ember['default'].$.Velocity.animate(this.$('.js-modal'), { opacity: 0 }, this.get('closeAnimationLength'));
			// in PhantomJS, Ember.$.Velocity.animate returns null, so we need to hack around it
			if (animationPromise) {
				_ember['default'].animate(animationPromise).then(close);
			} else {
				close();
			}
		},

		actions: {
			confirm: function confirm() {
				var _resolveFunction = this.get('_resolve');
				if (_resolveFunction) {
					var _resolveFunction2;

					_resolveFunction = (_resolveFunction2 = _resolveFunction).bind.apply(_resolveFunction2, [this].concat(_slice.call(arguments)));
				}
				this.send('close', _resolveFunction);
			},

			cancel: function cancel() {
				var _rejectFunction = this.get('_reject');
				if (_rejectFunction) {
					var _rejectFunction2;

					_rejectFunction = (_rejectFunction2 = _rejectFunction).bind.apply(_rejectFunction2, [this].concat(_slice.call(arguments)));
				}
				this.send('close', _rejectFunction);
			},

			close: function close(toResolve) {
				this._closeWithAnimation(toResolve);
			}
		}
	});

	exports['default'] = modalComponent;
});