define('component-library/mixins/zapp-icon', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var inject = _ember['default'].inject;
	var DEFAULT_INBOX_ICON_URL = '/static/img/rebranding/trinet-monogram-202311.svg';

	exports['default'] = _ember['default'].Mixin.create({

		zAppData: inject.service(),
		zAppIconPath: null,
		inboxItem: null,
		showIcon: true,

		setZappIconPath: function setZappIconPath() {
			var _this = this;

			// check if service is available
			if (this.get('zAppData')) {
				this.get('zAppData').getZAppData().then(function () {
					_this.set('zAppIconPath', _this.get('zAppData').getAppIcon(_this.get('inboxItem.zAppId')));
				});
			}
		},

		iconPath: _ember['default'].computed('zAppIconPath', function () {
			return this.get('showIcon') ? this.get('zAppIconPath') || DEFAULT_INBOX_ICON_URL : '';
		})

	});
});