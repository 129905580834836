define('component-library/components/contributions-modal/tab-contributions', ['exports', 'ember', 'component-library/components/tabbed-modal/tab', 'component-library/components/contributions-modal/constants', 'component-library/components/contributions-modal/utils'], function (exports, _ember, _componentLibraryComponentsTabbedModalTab, _componentLibraryComponentsContributionsModalConstants, _componentLibraryComponentsContributionsModalUtils) {
	'use strict';

	exports['default'] = _componentLibraryComponentsTabbedModalTab['default'].extend({
		isDependentTypeSelected: _ember['default'].computed.equal('contributionTypeSelection.id', _componentLibraryComponentsContributionsModalConstants.DEPENDENT_TYPE),
		isDependentCountSelected: _ember['default'].computed.equal('contributionTypeSelection.id', _componentLibraryComponentsContributionsModalConstants.DEPENDENT_COUNT),
		isUniformSelected: _ember['default'].computed.equal('contributionTypeSelection.id', _componentLibraryComponentsContributionsModalConstants.UNIFORM),
		isTotalSelected: _ember['default'].computed.equal('contributionTypeSelection.id', _componentLibraryComponentsContributionsModalConstants.TOTAL),
		canRemoveDependent: _ember['default'].computed.gt('dependentContributionCountSelection.DEPENDENTS.length', 1),
		contributionTypeLabels: _ember['default'].computed('contributionMethodSelection.id', function () {
			return _componentLibraryComponentsContributionsModalUtils['default'].getContributionTypeLabels(this.get('contributionMethodSelection.id'));
		}),
		actions: {
			addDependent: function addDependent() {
				this.get('dependentContributionCountSelection.DEPENDENTS').pushObject(_ember['default'].Object.create({
					count: this.get('dependentContributionCountSelection.DEPENDENTS.length') + 1,
					selectedType: this.get('typeSelectOptions.firstObject'),
					data: {}
				}));
			},
			removeDependent: function removeDependent() {
				this.get('dependentContributionCountSelection.DEPENDENTS').removeAt(this.get('dependentContributionCountSelection.DEPENDENTS.length') - 1);
			},
			enableDeductions: function enableDeductions() {
				this.set('isDeductionsEnabled', true);
			}
		}
	});
});