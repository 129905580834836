define('component-library/data-table/cells/checkbox-header-cell', ['exports', 'ember', 'ember-table/views/header-cell'], function (exports, _ember, _emberTableViewsHeaderCell) {
	'use strict';

	exports['default'] = _emberTableViewsHeaderCell['default'].extend({
		templateName: 'data-table/cells/checkbox-header-cell',
		checked: _ember['default'].computed.alias('column.checked'),

		filterDidChange: (function () {
			if (this.get('content.parentController.allChecked')) {
				this.set('checked', true);
			} else {
				this.set('checked', false);
			}
		}).observes('content.parentController.allChecked'),

		click: function click(event) {
			// NOTE: we do not want to propagate the click
			event.stopPropagation();
		},
		actions: {
			toggleCheckbox: function toggleCheckbox() {
				this.toggleProperty('checked');
				this.get('tableComponent').sendAction('toggleSelectAll', this.get('checked'));
			}
		}
	});
});