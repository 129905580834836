define('component-library/components/z-header-employee-photo', ['exports', 'ember', 'component-library/utils/globals', 'component-library/modals/image-crop/image-crop-modal'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryModalsImageCropImageCropModal) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend((0, _componentLibraryUtilsGlobals.getFilePickerMixin)(), {
		classNames: ['z-headerImageContainer'],

		canEditImage: false,
		defaultImageUrl: '/static/img/default-employee-icon.svg',
		imageUrl: null,
		canCropImage: false,

		// copied from _EmployeephotouploadMixin's uploadFile function due to our need to use a v2 modal
		uploadAndCropFile: function uploadAndCropFile() {
			var _this = this;

			_componentLibraryUtilsGlobals.filepicker.setKey(_componentLibraryUtilsGlobals.FILEPICKER_KEY);
			_componentLibraryUtilsGlobals.filepicker.pickAndStore({
				mimeTypes: ['image/*'],
				container: 'window',
				services: ['COMPUTER', 'WEBCAM', 'FACEBOOK', 'INSTAGRAM', 'PICASA', 'FLICKR', 'DROPBOX', 'GOOGLE_DRIVE', 'SKYDRIVE', 'GMAIL', 'URL'],
				maxSize: 20 * 1024 * 1024 // Max file size 20MB
			}, { location: "S3" }, function (FPFile) {
				_componentLibraryUtilsGlobals.filepicker.convert(FPFile[0].url, { height: 1100, width: 1100, fit: 'max', rotate: "exif" }, { location: "S3" }, function (newFPFile) {
					_componentLibraryModalsImageCropImageCropModal['default'].show({
						fpFile: newFPFile
					}).then(function (photoUrlObject) {
						_this.sendAction('onImageUpdate', photoUrlObject.photoUrl, photoUrlObject.photoThumbnailUrl);
					});
				}, function (newFPError) {
					console.log(newFPError.toString());
				});
			}, function (FPError) {
				console.log(FPError.toString());
			});
		},

		actions: {
			openFilePicker: function openFilePicker() {
				var _this2 = this;

				if (!this.get('canEditImage')) {
					return false;
				}
				if (this.get('canCropImage')) {
					this.uploadAndCropFile();
				} else {
					var imageFieldName = this.get("imageFieldName");
					this.pickAndStore('bannerImage', {
						extension: ['.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG']
					}, function (arg1, FPFile) {
						if (FPFile) {
							var newImgUrl = FPFile[0].url;
							_this2.set('imageUrl', newImgUrl);
							_this2.sendAction('onImageUpdate', newImgUrl);
						}
					});
				}
			}
		}
	});
});