define('z-inputs/components/z-component-label', ['exports', 'ember', 'z-inputs/templates/components/z-component-label'], function (exports, _ember, _zInputsTemplatesComponentsZComponentLabel) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _zInputsTemplatesComponentsZComponentLabel['default'],
    classNames: ['z-componentLabel', 'js-test-z-componentLabel'],
    classNameBindings: ['isRequired'],
    isRequired: false,
    value: null,
    tooltipText: null,

    click: function click() {
      this.sendAction('on-click');
    }
  });
});