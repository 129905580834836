define('global-styles/components/z-logo', ['exports', 'ember', 'global-styles/templates/components/z-logo'], function (exports, _ember, _globalStylesTemplatesComponentsZLogo) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _globalStylesTemplatesComponentsZLogo['default'],
    tagName: '',

    classes: _ember['default'].computed('classNames', {
      get: function get() {
        return this.get('classNames').filter(function (value) {
          return value !== 'ember-view';
        }).join(' ');
      },

      set: function set(key, value) {
        return value;
      }
    })
  });
});