define('component-library/components/list-item-dropdown', ['exports', 'ember', 'component-library/components/list-item'], function (exports, _ember, _componentLibraryComponentsListItem) {
	'use strict';

	exports['default'] = _componentLibraryComponentsListItem['default'].extend({
		oldValue: undefined,

		// NOTE: This block of code needed and repetitive with list-item.js because of
		// https://github.com/emberjs/ember.js/issues/5259 and the fact that we must pre-select
		// 'nothing' if no selection is provided.
		_valueChanged: (function () {
			var value = this.get('value'),
			    attrName = this.get('attrName'),
			    model = this.get('targetModel'),
			    eventName = this.get('onChangeEventName'),
			    oldValue = this.get('oldValue');

			if (eventName && value !== oldValue) {
				this[eventName] = eventName;
				this.sendActionWithPropagation(eventName, model, attrName, value);
			}
			this.set('oldValue', value);
		}).observes('value'),

		optionValuePathFormatted: (function () {
			return "content." + this.get('data.optionValuePath');
		}).property('data', 'data.optionValuePath'),

		optionLabelPathFormatted: (function () {
			return "content." + this.get('data.optionLabelPath');
		}).property('data', 'data.optionLabelPath'),

		optionsValueToLabelMap: (function () {
			var options = this.get('data.options') || [];
			var valueToLabelMap = {};
			options.forEach(function (option) {
				valueToLabelMap[option[this.get('data.optionValuePath')]] = option[this.get('data.optionLabelPath')];
			}, this);
			return valueToLabelMap;
		}).property('data', 'data.options'),

		selectedValue: (function () {
			return this.get('optionsValueToLabelMap')[this.get('value')];
		}).property('value'),

		valueExists: (function () {
			return !_ember['default'].isNone(this.get('value'));
		}).property('value')
	});
});