define('ember-table/mixins/register-table-component', ['exports', 'ember'], function (exports, _ember) {
  // Gives views access to the table component. With the current architecture,
  // this is necessary because views need access to the component's properties
  // (like height and columnMode) and may even need to call component functions
  // (trigger refresh layout).
  //
  // It is possible to override this behavior by passing your own tableComponent
  // to the views instead.
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    tableComponent: null,

    init: function init() {
      if (!this.get('tableComponent')) {
        this.set('tableComponent', this.nearestWithProperty('isEmberTable'));
      }
      return this._super();
    }
  });
});