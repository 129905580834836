define('component-library/components/level-funding-progress-tracking', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var NOT_REGISTERED = 'Not Registered';

	exports['default'] = _ember['default'].Component.extend({
		showMore: false,

		employees: _ember['default'].computed(function () {
			return [];
		}),

		employeeIMQProgress: _ember['default'].computed(function () {
			return {
				endDate: '',
				imqStatuses: []
			};
		}),

		carrierName: _ember['default'].computed.alias('employeeIMQProgress.carrierName'),
		endDate: _ember['default'].computed.alias('employeeIMQProgress.endDate'),
		numTotal: _ember['default'].computed.alias('employeeIMQProgress.imqStatuses.length'),

		numCompleted: _ember['default'].computed('employeeIMQProgress.imqStatuses.@each.isCompleted', function () {
			return this.get('employeeIMQProgress.imqStatuses').filterBy('isCompleted').length;
		}),

		numInProgress: _ember['default'].computed('numTotal', 'numCompleted', function () {
			return this.get('numTotal') - this.get('numCompleted');
		}),

		employeeIMQProgressData: _ember['default'].computed('employeeIMQProgress.imqStatuses.[]', 'employees.[]', 'employees.@each.isRegistered', 'numTotal', function () {
			var employees = this.get('employees');
			var numTotal = this.get('numTotal');
			if (!numTotal || _ember['default'].isEmpty(employees)) {
				return [];
			}
			var dataTableContent = this.get('employeeIMQProgress.imqStatuses').map(function (imqStatus) {
				var employee = employees.findBy('id', imqStatus.id);
				if (_ember['default'].isEmpty(employee)) {
					return null;
				}
				return {
					name: employee.get('fullName'),
					email: employee.get('isRegistered') ? employee.get('email') : NOT_REGISTERED,
					isCompleted: imqStatus.isCompleted
				};
			}).compact().sort(function (imq1, imq2) {
				if (imq1.isCompleted == imq2.isCompleted) {
					return imq1.fullName <= imq2.fullName ? 1 : -1;
				}
				return imq1.isCompleted ? 1 : -1;
			});

			return dataTableContent;
		}),

		canShowMore: _ember['default'].computed.gt('employeeIMQProgressData.length', 4),

		actions: {
			viewMore: function viewMore() {
				this.toggleProperty('showMore');
			}
		}

	});
});