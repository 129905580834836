define("component-library/utils/angular-app/send-message-to-angular", ["exports", "lodash"], function (exports, _lodash) {
    "use strict";

    exports["default"] = sendMessageToAngular;

    function sendMessageToAngular(appName, type, payload) {

        console.log("\n\n    [sendMessageToAngular]\n", appName);

        if (!_lodash["default"].get(window, "embeddedAngularApps." + appName + ".isAppStarted")) {

            var queuedMessages = _lodash["default"].get(window, "embeddedAngularApps." + appName + ".queuedMessages") || [];
            queuedMessages.push({ type: type, payload: payload });
            _lodash["default"].set(window, "embeddedAngularApps." + appName + ".queuedMessages", queuedMessages);
        } else {
            try {
                /* eslint-disable no-lazy-globals */
                var handlers = window.embeddedAngularApps[appName].registeredAngularMessages[type];
                /* eslint-enable no-lazy-globals */

                if (!handlers) {
                    console.error("No angular action of type " + type + " was registered on app: " + appName);
                } else {
                    handlers.forEach(function (handler) {
                        return handler(payload);
                    });
                }
            } catch (e) {
                console.error("Tried to send action to angular app: " + appName + " that is not loaded.");
            }
        }
    }
});