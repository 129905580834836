define('component-library/timeline/mixins/change-feed-entry', ['exports', 'ember', 'component-library/timeline/mixins/feed-entry', 'component-library/timeline/utils/status', 'component-library/timeline/utils/computed-copy', 'component-library/utils/tags/context-tag-computed', 'component-library/utils/computed-props/not-blank'], function (exports, _ember, _componentLibraryTimelineMixinsFeedEntry, _componentLibraryTimelineUtilsStatus, _componentLibraryTimelineUtilsComputedCopy, _componentLibraryUtilsTagsContextTagComputed, _componentLibraryUtilsComputedPropsNotBlank) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['', ' made a request to ', ''], ['', ' made a request to ', '']),
	    _templateObject2 = _taggedTemplateLiteral(['', ' approved ', ' request to ', ''], ['', ' approved ', ' request to ', '']),
	    _templateObject3 = _taggedTemplateLiteral(['', ' denied ', ' request to ', ''], ['', ' denied ', ' request to ', '']),
	    _templateObject4 = _taggedTemplateLiteral(['', ' ', ''], ['', ' ', '']);

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	exports['default'] = _ember['default'].Mixin.create(_componentLibraryTimelineMixinsFeedEntry['default'], {

		copy: _componentLibraryTimelineUtilsComputedCopy['default'].apply(undefined, _toConsumableArray(_componentLibraryTimelineUtilsStatus.changeStatusTypes)),
		created: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject, 'requesterStr', 'changeStr'),
		approved: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject2, 'approverStr', 'requesterStrPossessive', 'changeStr'),
		declined: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject3, 'approverStr', 'requesterStrPossessive', 'changeStr'),
		completed: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject4, 'requesterStr', 'pastTenseChangeStr'),

		pastTenseChangeStr: (function () {
			return this.get('changeStr').replace(/change|increase|decrease/i, '$&d').replace(/switch/i, '$&ed');
		}).property('changeStr'),

		// must override these
		oldVal: null,
		newVal: null,
		label: null,

		// override this if you want to change way this is computed
		oldValAvailable: (0, _componentLibraryUtilsComputedPropsNotBlank['default'])('oldVal'),
		newValAvailable: (0, _componentLibraryUtilsComputedPropsNotBlank['default'])('newVal'),

		// more complex change types will override this function
		changeStr: (function () {
			var oldValAvailable = this.get('oldValAvailable');
			var newValAvailable = this.get('newValAvailable');
			var oldVal = this.get('oldVal');
			var newVal = this.get('newVal');
			var label = this.get('label');
			var employeeStrPossessive = this.get('employeeStrPossessive');
			if (oldValAvailable && newValAvailable) {
				return 'change ' + employeeStrPossessive + ' ' + label + ' from ' + oldVal + ' to ' + newVal;
			}
			if (oldValAvailable) {
				return 'unset ' + employeeStrPossessive + ' ' + label + ' (was ' + oldVal + ')';
			}
			return 'set ' + employeeStrPossessive + ' ' + label + ' to ' + newVal;
		}).property('oldVal', 'newVal', 'label', 'employeeStrPossessive')

	});
});