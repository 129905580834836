define('component-library/utils/payroll/smp/fix-records/utils', ['exports', 'ember', 'component-library/utils/payroll/smp/fix-records/employee-errors-object', 'component-library/utils/payroll/smp/fix-records/error-info-object'], function (exports, _ember, _componentLibraryUtilsPayrollSmpFixRecordsEmployeeErrorsObject, _componentLibraryUtilsPayrollSmpFixRecordsErrorInfoObject) {
	'use strict';

	var SORT_ORDER_LIST = ['first_name', 'last_name', 'address', 'city', 'state', 'zip', 'socialSecurity', 'hireDate', 'compType', 'salary', 'payRate', 'federalFilingStatus', 'federalWithholdingAllowance', 'additionalFederalWitholdings', 'taxState', 'stateFilingStatus', 'stateWithholdingAllowance', 'additionalStateWitholdings', 'paymentMethod', 'bankRoutingNumber', 'bankAccountType', 'bankAccountNumber', 'localWithholdingAllowance', 'additionalLocalWithholdings', 'isFicaExempt', 'departmentCode', 'terminationReason'];

	var fieldKeyToInputProps = function fieldKeyToInputProps(key, employeeId, employeeTaxState) {
		switch (key) {
			case 'state':
				return { view: 'App.StateSelectView' };
			case 'zip':
				return { view: 'App.ZipCodeFieldComponent' };
			case 'salary':
				return { view: 'App.FormattedNumberField' };
			case 'socialSecurity':
				return { view: 'App.SSNFieldComponent' };
			case 'bankAccountType':
				return { view: 'App.InternationalBankAccountTypeSelectView' };
			case 'hireDate':
				return { view: 'App.DateFieldComponent' };
			case 'payRate':
				return { view: 'App.FormattedNumberField' };
			case 'isFicaExempt':
				return { view: 'Ember.Checkbox' };
			case 'compType':
				return { view: 'App.HourlyOrSalarySelectView' };
			//For all tax-related stuff, just show a link to the tearsheet page
			case 'federalFilingStatus':
			case 'federalWithholdingAllowance':
			case 'additionalFederalWitholdings':
			case 'taxState':
			case 'stateFilingStatus':
			case 'stateWithholdingAllowance':
			case 'additionalStateWitholdings':
			case 'localWithholdingAllowance':
			case 'additionalLocalWithholdings':
			case 'bankRoutingNumber': //Bank information goes to tearsheet
			case 'bankAccountNumber':
			case 'paymentMethod':
				return { view: 'tearSheetLink' };
			default:
				return { view: 'Ember.TextField' };
		}
	};

	var errorsSortFunction = function errorsSortFunction(a, b) {
		var aIndex = SORT_ORDER_LIST.indexOf(a.get('key'));
		var bIndex = SORT_ORDER_LIST.indexOf(b.get('key'));

		if (bIndex === -1) {
			return -1;
		} else if (aIndex === -1) {
			return 1;
		} else {
			return aIndex - bIndex;
		}
	};

	var BAD_RECORDS_API_URL = '/api/baddata_validate';

	exports.BAD_RECORDS_API_URL = BAD_RECORDS_API_URL;

	var convertFieldKeyToInputProps = fieldKeyToInputProps;

	exports.convertFieldKeyToInputProps = convertFieldKeyToInputProps;

	var getBadRecordsByEmployee = function getBadRecordsByEmployee(badRecords) {
		var badRecordsByEmployee = {};
		var employeeErrorsObj;
		var employeeId;
		var employee;
		var errorInfoObj;
		var badRecordKey;
		var badRecordVerboseKey;
		var inputFieldData;
		var reason;
		var formattedReason;
		var sortedList;
		var reasonMessage;

		badRecords.forEach(function (badRecord) {
			employeeId = badRecord.get('employee.id');
			employee = badRecord.get('employee');
			employeeErrorsObj = badRecordsByEmployee[employeeId];
			badRecordVerboseKey = badRecord.get('verbose_key');
			badRecordKey = badRecord.get('key');
			reason = badRecord.get('reason');

			if (!badRecordKey) {
				return;
			}

			if (!employeeErrorsObj) {

				badRecordsByEmployee[employeeId] = _componentLibraryUtilsPayrollSmpFixRecordsEmployeeErrorsObject['default'].create({
					employeeId: employeeId,
					firstName: employee.get('first_name'),
					lastName: employee.get('last_name'),
					fullName: employee.get('fullName'),
					photoUrl: employee.get('photoUrl'),
					email: employee.get('email'),
					errors: _ember['default'].A(),
					tearSheetHref: '/dashboard/#/employeetearsheet/' + employeeId
				});

				employeeErrorsObj = badRecordsByEmployee[employeeId];
			}

			reasonMessage = getBadRecordReasonMessageString(reason);

			if (!reasonMessage) {
				return; //Ignore cases where we can't get a formatted reason. Most likely,
				//there's no key associated with this bad record.
			}

			//TODO: Keep formattedReason for the legacy UI. Delete later.
			formattedReason = badRecordVerboseKey + ': ' + reasonMessage + '.';

			errorInfoObj = _componentLibraryUtilsPayrollSmpFixRecordsErrorInfoObject['default'].create({
				key: badRecordKey,
				value: employee.get(badRecordKey),
				newValue: null,
				formattedReason: formattedReason,
				formattedKey: badRecordVerboseKey,
				formattedMessage: reasonMessage
			});

			//Get the appropriate input field types and args for this error type
			inputFieldData = fieldKeyToInputProps(badRecordKey, employee.get('id'), employee.get('taxState'));
			errorInfoObj.set('inputFieldName', inputFieldData.view);
			if (inputFieldData.view === "tearSheetLink") {
				errorInfoObj.set('isTearSheetLink', true);
			}
			if (inputFieldData.content) {
				errorInfoObj.set('inputFieldContent', inputFieldData.content);
			}

			employeeErrorsObj.errors.push(errorInfoObj);
		});

		//Convert to Array
		badRecordsByEmployee = _ember['default'].$.map(badRecordsByEmployee, function (value, key) {
			return value;
		});

		//Sort errors for each employee based on sort ordering array
		badRecordsByEmployee.forEach(function (employeeBadRecords) {
			sortedList = employeeBadRecords.get('errors');
			sortedList = sortedList.sort(errorsSortFunction);
			employeeBadRecords.set('errors', sortedList);
		});

		return badRecordsByEmployee;
	};

	exports.getBadRecordsByEmployee = getBadRecordsByEmployee;

	var processBadRecordsForEmployeeObjectsForJson = function processBadRecordsForEmployeeObjectsForJson(data) {
		var processedObjects = [];
		var employeeObject;
		var newValue;

		data.forEach(function (employeeData) {
			employeeObject = {
				employeeId: employeeData.get('employeeId'),
				errors: []
			};

			employeeData.errors.forEach(function (errorData) {
				newValue = errorData.get('newValue');

				//0 is a perfectly valid entry so explicitly check for
				//undefined and null entries and skip over those.
				if (typeof newValue === 'undefined' || newValue === null) {
					return;
				}

				employeeObject.errors.push({
					key: errorData.get('key'),
					value: newValue
				});
			});

			if (employeeObject.errors.length) {
				processedObjects.push(employeeObject);
			}
		});

		return processedObjects;
	};

	exports.processBadRecordsForEmployeeObjectsForJson = processBadRecordsForEmployeeObjectsForJson;

	var getBadRecordReasonMessageString = function getBadRecordReasonMessageString(reasonCode) {
		var message;

		switch (reasonCode) {
			case 'address_incomplete_type':
			case 'federal_filing_status_missing':
			case 'tax_state_missing':
				message = 'Missing information';
				break;
			case 'invalid_payment_information':
			case 'invalid_bank_information':
				message = 'Invalid information';
				break;
			default:
				break;
		}

		if (!message && reasonCode && reasonCode.length) {
			//If it's not one of the specified reason code's but a reason code does
			//exist, just strip the underscores and use it as the message.
			message = reasonCode.replace(/_/g, ' ');
			message = message.charAt(0).toUpperCase() + message.slice(1);
		} else {
			//Else no reason code. Show a generic error message for this field.
			message = "Invalid entry";
		}

		return message;
	};
	exports.getBadRecordReasonMessageString = getBadRecordReasonMessageString;
});