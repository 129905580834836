define('component-library/modals/payroll/editable-paystub-tax-override-modal', ['exports', 'component-library/components/promise-modal', 'ember'], function (exports, _componentLibraryComponentsPromiseModal, _ember) {
    'use strict';

    exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
        templateName: 'modals/payroll/editable-paystub-tax-override-modal',
        modalDialogClass: 'z-editablePaystubTaxOverrideModal',

        title: _ember['default'].computed('taxRecord', function () {
            var suffix = '';
            var taxRecord = this.get('taxRecord');
            if (taxRecord && taxRecord.earningType == 'Sup') {
                suffix = ' - Supplemental';
            }
            var title = taxRecord ? 'Override ' + taxRecord.name + suffix : 'Override';
            return title;
        }),

        strikeCalculatedAmount: _ember['default'].computed('overrideAmount', function () {
            if (this.validateAmount(this.get('overrideAmount'))) {
                return true;
            }
            return false;
        }),

        overrideAmount: undefined,
        overrideAmountCopy: null,
        overrideAmountErrorText: '',
        isOverrideAmountDisabled: _ember['default'].computed('isRevertOverrideAmountChecked', function () {
            return this.get('isRevertOverrideAmountChecked');
        }),
        isRevertOverrideAmountDisabled: true,
        isRevertOverrideAmountChecked: false,

        isConfirmDisabled: _ember['default'].computed('overrideAmount', 'isRevertOverrideAmountChecked', function () {
            var overrideAmount = this.get('overrideAmount');
            var isRevertOverrideAmountChecked = this.get('isRevertOverrideAmountChecked');
            if (isRevertOverrideAmountChecked) return false;
            return !this.validateAmount(overrideAmount);
        }),

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.initModal();
        },

        initModal: function initModal() {
            this.setOverrideFields();
        },

        setOverrideFields: function setOverrideFields() {
            var taxRecord = this.get('taxRecord');
            this.isRevertOverrideAmountDisabled = true;
            if (taxRecord && taxRecord.userEnteredAmount != null) {
                this.set('overrideAmount', taxRecord.userEnteredAmount);
                this.isRevertOverrideAmountDisabled = false;
            }
        },

        onRevertOverrideCheckChange: (function () {
            if (this.isRevertOverrideAmountChecked) {
                this.set('overrideAmountCopy', this.get('overrideAmount'));
                this.set('overrideAmount', undefined);
            } else {
                this.set('overrideAmount', this.get('overrideAmountCopy'));
                this.set('overrideAmountCopy', null);
            }
        }).observes('isRevertOverrideAmountChecked'),

        onOverrideAmountChange: (function () {
            if (this.validateAmount(this.get('overrideAmount'))) {
                this.set('strikeCalculatedAmount', true);
            } else {
                this.set('strikeCalculatedAmount', false);
            }
        }).observes('overrideAmount'),

        validateAmount: function validateAmount(amount) {
            this.set('overrideAmountErrorText', undefined);
            if (!amount || isNaN(amount)) {
                return false;
            }
            var netPay = this.model.get('estimatedNetPay');
            var decimalAmount = parseFloat(amount);
            var decimalNetPay = parseFloat(netPay);
            var zCalculatedAmount = parseFloat(this.get('taxRecord').systemGeneratedAmount);

            if (decimalAmount < 0) {
                this.set('overrideAmountErrorText', "Override Amount cannot be negative.");
                return false;
            }

            if (zCalculatedAmount == decimalAmount) {
                this.set('overrideAmountErrorText', "Override Amount cannot be same as Zenefits calculated amount.");
                return false;
            }
            return true;
        },

        actions: {
            prepConfirm: function prepConfirm() {
                var taxRecord = this.get('taxRecord');
                var overrideAmount = this.get('overrideAmount');
                var sysGeneratedAmount = this.get('taxRecord.systemGeneratedAmount');
                var isRevertOverrideAmountChecked = this.get('isRevertOverrideAmountChecked');
                if (isRevertOverrideAmountChecked) {
                    this.set('taxRecord.userEnteredAmount', null);
                    this.set('taxRecord.amount', sysGeneratedAmount);
                } else {
                    this.set('taxRecord.userEnteredAmount', overrideAmount);
                    this.set('taxRecord.amount', overrideAmount);
                }
                this.send('confirm', taxRecord);
            }
        }
    });
});