define("component-library/components/carousel-item-state", ["exports", "component-library/components/carousel-item"], function (exports, _componentLibraryComponentsCarouselItem) {
   "use strict";

   var STATE_DETAILS = {
      "AL": {
         "letter": "B",
         "fullName": "Alabama",
         "templateId": ".template-state-rules-alaska"
      },
      "AK": {
         "letter": "A",
         "fullName": "Alaska",
         "templateId": ".template-state-rules-default"
      },
      "AZ": {
         "letter": "D",
         "fullName": "Arizona",
         "templateId": ".template-state-rules-default"
      },
      "AR": {
         "letter": "C",
         "fullName": "Arkansas",
         "templateId": ".template-state-rules-default"
      },
      "CA": {
         "letter": "E",
         "fullName": "California",
         "templateId": ".template-state-rules-california"
      },
      "CO": {
         "letter": "F",
         "fullName": "Colorado",
         "templateId": ".template-state-rules-colorado"
      },
      "CT": {
         "letter": "G",
         "fullName": "Connecticut",
         "templateId": ".template-state-rules-default"
      },
      "DE": {
         "letter": "H",
         "fullName": "Delaware",
         "templateId": ".template-state-rules-default"
      },
      "DC": {
         "letter": "y",
         "fullName": "Washington D.C.",
         "templateId": ".template-state-rules-default"
      },
      "FL": {
         "letter": "I",
         "fullName": "Florida",
         "templateId": ".template-state-rules-default"
      },
      "GA": {
         "letter": "J",
         "fullName": "Georgia",
         "templateId": ".template-state-rules-default"
      },
      "HI": {
         "letter": "K",
         "fullName": "Hawaii",
         "templateId": ".template-state-rules-default"
      },
      "ID": {
         "letter": "M",
         "fullName": "Idaho",
         "templateId": ".template-state-rules-default"
      },
      "IL": {
         "letter": "N",
         "fullName": "Illinois",
         "templateId": ".template-state-rules-default"
      },
      "IN": {
         "letter": "O",
         "fullName": "Indiana",
         "templateId": ".template-state-rules-default"
      },
      "IA": {
         "letter": "L",
         "fullName": "Iowa",
         "templateId": ".template-state-rules-default"
      },
      "KS": {
         "letter": "P",
         "fullName": "Kansas",
         "templateId": ".template-state-rules-default"
      },
      "KY": {
         "letter": "Q",
         "fullName": "Kentucky",
         "templateId": ".template-state-rules-kentucky"
      },
      "LA": {
         "letter": "R",
         "fullName": "Louisiana",
         "templateId": ".template-state-rules-default"
      },
      "ME": {
         "letter": "U",
         "fullName": "Maine",
         "templateId": ".template-state-rules-default"
      },
      "MD": {
         "letter": "T",
         "fullName": "Maryland",
         "templateId": ".template-state-rules-default"
      },
      "MA": {
         "letter": "S",
         "fullName": "Massachusetts",
         "templateId": ".template-state-rules-default"
      },
      "MI": {
         "letter": "V",
         "fullName": "Michigan",
         "templateId": ".template-state-rules-default"
      },
      "MN": {
         "letter": "W",
         "fullName": "Minnesota",
         "templateId": ".template-state-rules-default"
      },
      "MS": {
         "letter": "Y",
         "fullName": "Mississippi",
         "templateId": ".template-state-rules-default"
      },
      "MO": {
         "letter": "X",
         "fullName": "Missouri",
         "templateId": ".template-state-rules-default"
      },
      "MT": {
         "letter": "Z",
         "fullName": "Montana",
         "templateId": ".template-state-rules-default"
      },
      "NE": {
         "letter": "c",
         "fullName": "Nebraska",
         "templateId": ".template-state-rules-default"
      },
      "NV": {
         "letter": "g",
         "fullName": "Nevada",
         "templateId": ".template-state-rules-nevada"
      },
      "NH": {
         "letter": "d",
         "fullName": "New Hampshire",
         "templateId": ".template-state-rules-default"
      },
      "NJ": {
         "letter": "e",
         "fullName": "New Jersey",
         "templateId": ".template-state-rules-default"
      },
      "NM": {
         "letter": "f",
         "fullName": "New Mexico",
         "templateId": ".template-state-rules-default"
      },
      "NY": {
         "letter": "h",
         "fullName": "New York",
         "templateId": ".template-state-rules-default"
      },
      "NC": {
         "letter": "a",
         "fullName": "North Carolina",
         "templateId": ".template-state-rules-default"
      },
      "ND": {
         "letter": "b",
         "fullName": "North Dakota",
         "templateId": ".template-state-rules-default"
      },
      "OH": {
         "letter": "i",
         "fullName": "Ohio",
         "templateId": ".template-state-rules-default"
      },
      "OK": {
         "letter": "j",
         "fullName": "Oklahoma",
         "templateId": ".template-state-rules-default"
      },
      "OR": {
         "letter": "k",
         "fullName": "Oregon",
         "templateId": ".template-state-rules-default"
      },
      "PA": {
         "letter": "l",
         "fullName": "Pennsylvania",
         "templateId": ".template-state-rules-default"
      },
      "RI": {
         "letter": "m",
         "fullName": "Rhode Island",
         "templateId": ".template-state-rules-rhodeisland"
      },
      "SC": {
         "letter": "n",
         "fullName": "South Carolina",
         "templateId": ".template-state-rules-default"
      },
      "SD": {
         "letter": "o",
         "fullName": "South Dakota",
         "templateId": ".template-state-rules-default"
      },
      "TN": {
         "letter": "p",
         "fullName": "Tennessee",
         "templateId": ".template-state-rules-default"
      },
      "TX": {
         "letter": "q",
         "fullName": "Texas",
         "templateId": ".template-state-rules-default"
      },
      "UT": {
         "letter": "r",
         "fullName": "Utah",
         "templateId": ".template-state-rules-default"
      },
      "VT": {
         "letter": "t",
         "fullName": "Vermont",
         "templateId": ".template-state-rules-default"
      },
      "VA": {
         "letter": "s",
         "fullName": "Virginia",
         "templateId": ".template-state-rules-default"
      },
      "WA": {
         "letter": "u",
         "fullName": "Washington",
         "templateId": ".template-state-rules-default"
      },
      "WV": {
         "letter": "w",
         "fullName": "West Virginia",
         "templateId": ".template-state-rules-default"
      },
      "WI": {
         "letter": "v",
         "fullName": "Wisconsin",
         "templateId": ".template-state-rules-default"
      },
      "WY": {
         "letter": "x",
         "fullName": "Wyoming",
         "templateId": ".template-state-rules-default"
      },
      "US": {
         "letter": "z",
         "fullName": "United States",
         "templateId": ".template-state-rules-default"
      }
   };

   // BEGIN-SNIPPET carousel-state-item-controller
   exports["default"] = _componentLibraryComponentsCarouselItem["default"].extend({
      didInsertElement: function didInsertElement() {
         this._super();

         // gracefully handle invalid state key
         var value = this.get('value');
         if (!STATE_DETAILS[value]) {
            this.set(value, 'US');
            console.error("Invalid value '" + value + "' set for 'carouselBox-item-state' component.");
         }
      },

      stateFontLetter: (function () {
         if (!STATE_DETAILS[this.get('value')]) {
            return STATE_DETAILS['US'].letter;
         }
         return STATE_DETAILS[this.get('value')].letter;
      }).property('value'),

      click: function click() {
         var details = STATE_DETAILS[this.get('value')];
         if (!details) {
            details = STATE_DETAILS['US'];
         }
         this.sendAction('selectedState', details.fullName, details.templateId);
      }
   });

   // END-SNIPPET
});