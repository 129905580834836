define('component-library/initializers/features-service', ['exports'], function (exports) {
	'use strict';

	exports['default'] = {
		name: 'features-service',
		initialize: function initialize(container, application) {
			application.inject('route', 'features', 'service:features');
			application.inject('controller', 'features', 'service:features');
			application.inject('component', 'features', 'service:features');
		}
	};
});