define('component-library/components/settings-page/child-content-container', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		cardEventsService: _ember['default'].inject.service('card-events'),

		onInit: _ember['default'].on('init', function () {
			this.get('cardEventsService').reset();
		})
	});
});