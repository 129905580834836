define('component-library/wizard/flow/index/route', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Route.extend({
		// OVERRIDES - begin
		wizardService: null,
		serviceEnabled: false,

		wizardFlowRoute: null, // required for serviceEnabled: false

		// OVERRIDES - end

		model: function model() {
			return this.modelFor(this.get('wizardFlowRoute'));
		},

		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);

			if (this.get('serviceEnabled')) {
				this.get('wizardService').onFlowIndexEnter();
				return;
			}

			if (model.wizardFlowState.get('hasCustomFlowIndex')) {
				return;
			}

			var firstIncompleteSection = model.wizardFlowState.get('firstIncompleteSection');
			if (firstIncompleteSection) {
				// incomplete so let's resume
				this.send('_transitionToFirstIncompleteStep_sectionAction', firstIncompleteSection.get('sectionKey'));
			} else if (model.wizardFlowState.get('resumableIfComplete')) {
				// complete and resumable
				this.send('_transitionToStepIndex_sectionAction', model.wizardFlowState.get('sections.firstObject.sectionKey'), 0);
			} else {
				// complete and not resumable
				this.send('_transitionToTerminalRoute_sectionAction');
			}
		}
	});
});