define('z-signature/utils/get-date-in-american-format', ['exports'], function (exports) {
	'use strict';

	exports['default'] = getDateInAmericanFormat;

	// http://stackoverflow.com/a/15764763/3304337

	function getDateInAmericanFormat(date) {
		var year = date.getFullYear();
		var month = (1 + date.getMonth()).toString();
		month = month.length > 1 ? month : '0' + month;
		var day = date.getDate().toString();
		day = day.length > 1 ? day : '0' + day;
		return month + '/' + day + '/' + year;
	}
});