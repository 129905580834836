define('component-library/components/date-range-filter', ['exports', 'ember', 'component-library/templates/components/date-range-filter', 'component-library/components/promise-popover', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryTemplatesComponentsDateRangeFilter, _componentLibraryComponentsPromisePopover, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
		classNames: ['z-dateRangeFilter'],
		template: _componentLibraryTemplatesComponentsDateRangeFilter['default'],
		hasArrow: true,
		placement: 'bottom',
		target: _ember['default'].computed.oneWay('model.target'),
		attachment: "top center",
		targetAttachment: "bottom center",
		displayDatePicker: false,
		hint: "",

		filter: _ember['default'].computed.alias('content'),

		newMinValue: "",
		newMaxValue: "",
		didInsertElement: function didInsertElement() {
			this._super();
			if (this.get('filter.minValue')) {
				this.set('newMinValue', this.get('filter.minValue').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT));
			}
			if (this.get('filter.maxValue')) {
				this.set('newMaxValue', this.get('filter.maxValue').format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT));
			}
		},

		addOrApply: (function () {
			return this.get('filter.isActive') ? 'Apply' : 'Add';
		}).property('filter.isActive'),

		actions: {
			addOrApplyFilter: function addOrApplyFilter() {
				if (!this.get('filter.isActive')) {
					this.set('filter.isActive', true);
				}
				var minVal = this.get('newMinValue') ? (0, _componentLibraryUtilsGlobals.moment)(this.get('newMinValue')) : null;
				var maxVal = this.get('newMaxValue') ? (0, _componentLibraryUtilsGlobals.moment)(this.get('newMaxValue')) : null;
				this.set('filter.minValue', minVal);
				this.set('filter.maxValue', maxVal);
				this.send('close');
			}
		}
	});
});