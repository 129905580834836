define('component-library/mixins/qr-code', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		qrCodeRequestApi: '/qr/generate_qr_code',

		qrCodeQueryApi: '/qr/ping_signature',

		qrCodeClearDataApi: '/qr/clear_signature/',

		interval: 1500,

		//run at certain interval to query the status of the related data
		intervalJob: null,

		//hook: run after qr code img is retrieved
		afterQrCode: null,

		//hook: run after qr is expired
		qrExpired: null,

		//hook: run after query qr status
		afterQrQuery: null,

		expirySeconds: 300,

		//flag to indicate whether qr funcionality is terminated
		qrTerminated: false,

		intervalTask: function intervalTask() {
			_ember['default'].ajax({
				url: this.get('qrCodeQueryApi') + "?hashKey=" + this.get('qrKey'),
				type: "get",
				success: (function (response) {
					//stop interval job if it is expired
					if (response.isExpired) {
						this.expired();
					} else {
						this.afterQrQuery(response.contentUrl);
					}
				}).bind(this)
			});
		},

		requestQrCode: function requestQrCode(purpose) {
			var _this = this;

			var requestUrl = this.get('qrCodeRequestApi');
			if (purpose == 'upload') {
				requestUrl = this.get('qrCodeRequestApi') + "?purpose=" + purpose;
			}
			this.terminateQr();
			this.set('qrTerminated', false);
			_ember['default'].ajax({
				url: requestUrl,
				type: "get"
			}).then(function (response) {
				// User might terminate QR funcionality while waiting for QR generation
				if (!_this.get('qrTerminated')) {
					_this.set('qrKey', response.hashKey);
					//define your customized function
					_this.afterQrCode(response.qrCodeImgUrl, response.hashKey);
					var intervalJob = setInterval(_this.intervalTask.bind(_this), _this.get('interval'));
					_this.set('intervalJob', intervalJob);
					_this.startCountDown(_this.get('expirySeconds'));
				}
			});
		},

		stopIntervalJob: function stopIntervalJob() {
			if (this.get('intervalJob')) {
				clearInterval(this.get('intervalJob'));
			}
		},

		clearQrCodeData: function clearQrCodeData() {
			if (this.get('qrKey')) {
				_ember['default'].ajax({
					url: this.get('qrCodeClearDataApi') + "?hashKey=" + this.get('qrKey'),
					type: "put",
					data: { hashKey: this.get('qrKey') }
				}).then(function (response) {});
			}
		},

		expired: function expired() {
			this.terminateQr();
			this.qrExpired();
		},
		terminateQr: function terminateQr() {
			this.set('qrTerminated', true);
			this.stopIntervalJob();
			this.stopCountDown();
		},

		//Timer
		time: 300,
		//hook
		oneSecLess: null,

		countDownJob: null,
		countDownTask: function countDownTask() {
			this.set('time', this.get('time') - 1);

			if (this.get('time') <= 0) {
				this.expired();
				return;
			}
			this.oneSecLess(this.get('time'));
		},
		startCountDown: function startCountDown(initTime) {
			this.stopCountDown();
			this.set('time', initTime);
			var countDownJob = setInterval(this.countDownTask.bind(this), 1000);
			this.set('countDownJob', countDownJob);
		},
		stopCountDown: function stopCountDown() {
			if (this.get('countDownJob')) {
				clearInterval(this.get('countDownJob'));
			}
		}
	});
});