define('component-library/components/external-editable-list', ['exports', 'ember', 'component-library/templates/components/external-editable-list'], function (exports, _ember, _componentLibraryTemplatesComponentsExternalEditableList) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		layout: _componentLibraryTemplatesComponentsExternalEditableList['default'],
		value: _ember['default'].computed(function () {
			return [];
		}),
		empty: _ember['default'].computed.empty('value'),
		addCaption: "Add...",
		actions: {
			addItem: function addItem() {
				var currentList = this.get('value') || [];
				var newItem = {
					isExpanded: true,
					_unsaved: true
				};
				this.set('value', currentList.concat(newItem));
			},
			editItem: function editItem(item) {
				_ember['default'].set(item, 'isExpanded', true);
			},
			removeItem: function removeItem(item) {
				var items = this.get('value') || [];
				var filtered = items.filter(function (i) {
					return i != item;
				});
				this.set('value', filtered);
			}
		}
	});
});