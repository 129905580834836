define('component-library/components/cards/modals/unsaved-changes-modal', ['exports', 'ember', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryComponentsPromiseModal) {
  'use strict';

  exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
    classNames: ['unsaved-changes-modal'],
    templateName: 'components/cards/modals/unsaved-changes-modal',

    cardEventsService: _ember['default'].inject.service('card-events'),

    cardTitleInMessage: _ember['default'].computed('cardEventsService', function () {
      var cardTitle = this.get('cardEventsService').getCurrentCardTitle();
      return cardTitle ? 'to ' + cardTitle : '';
    })

  });
});