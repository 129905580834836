define('component-library/components/z-progress-navigation-section', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-progress-navigation-section', 'js-z-progress-navigation-section'],
		title: null,
		steps: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		actions: {
			updateStepSelection: function updateStepSelection(step) {
				this.attrs.updateStepSelection(step);
			}
		}
	});
});