define('component-library/filters/simple-date-filter-spec', ['exports', 'ember', 'component-library/utils/globals', 'component-library/filters/abstract-filter-spec', 'component-library/components/simple-date-filter'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryFiltersAbstractFilterSpec, _componentLibraryComponentsSimpleDateFilter) {
	'use strict';

	exports['default'] = _componentLibraryFiltersAbstractFilterSpec['default'].extend({
		/*
  	Overrides
  */
		popoverClass: _componentLibraryComponentsSimpleDateFilter['default'],

		description: _ember['default'].computed(function () {
			var startDate = this.get('startDate');
			var endDate = this.get('endDate');
			var startDatePretty;
			var endDatePretty;

			if (startDate && endDate) {
				startDatePretty = this.get('startDatePretty');
				endDatePretty = this.get('endDatePretty');

				return "%@ - %@".fmt(startDatePretty, endDatePretty);
			} else if (startDate) {
				startDatePretty = this.get('startDatePretty');

				return "%@ and later".fmt(startDatePretty);
			} else if (endDate) {
				endDatePretty = this.get('endDatePretty');

				return "%@ and earlier".fmt(endDatePretty);
			} else {
				return "*ALL* the dates";
			}
		}).property('startDate', 'endDate'),

		applyFunc: _ember['default'].computed(function () {
			var startDateMoment = this.get('startDateMoment');
			var endDateMoment = this.get('endDateMoment');
			var valuePath = this.get('valuePath');

			return function (x) {
				var matchesStart = true;
				var matchesEnd = true;
				var value = _ember['default'].get(x, valuePath);
				var xMoment = (0, _componentLibraryUtilsGlobals.moment)(value);

				if (startDateMoment) {
					matchesStart = !xMoment.isBefore(startDateMoment) && value;
				}

				if (endDateMoment) {
					matchesEnd = !xMoment.isAfter(endDateMoment) && value;
				}

				return matchesStart && matchesEnd;
			};
		}).property('startDateMoment', 'endDateMoment', 'valuePath'),

		/*
  	Implementation
  */
		startDate: null,
		endDate: null,

		startDateMoment: _ember['default'].computed(function () {
			var startDate = this.get('startDate');

			if (_ember['default'].isEmpty(startDate)) {
				return null;
			}

			return (0, _componentLibraryUtilsGlobals.moment)(startDate);
		}).property('startDate'),

		endDateMoment: _ember['default'].computed(function () {
			var endDate = this.get('endDate');

			if (_ember['default'].isEmpty(endDate)) {
				return null;
			}

			return (0, _componentLibraryUtilsGlobals.moment)(endDate);
		}).property('endDate'),

		startDatePretty: _ember['default'].computed(function () {
			var startDateMoment = this.get('startDateMoment');

			if (!startDateMoment) {
				return "";
			}

			return startDateMoment.format('MMM D, \'YY');
		}).property('startDateMoment'),

		endDatePretty: _ember['default'].computed(function () {
			var endDateMoment = this.get('endDateMoment');

			if (!endDateMoment) {
				return "";
			}

			return endDateMoment.format('MMM D, \'YY');
		}).property('endDateMoment')
	});
});