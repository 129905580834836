define('model-library/models/z-payroll-run-sub-tax', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	exports['default'] = _emberData['default'].Model.extend({
		zpTaxIdentifier: _emberData['default'].belongsTo('zPayrollTaxIdentifier'),
		zPayrollRun: _emberData['default'].belongsTo('ZPayrollRun'),
		parentTax: _emberData['default'].belongsTo('zPayrollRunTax'),
		name: _emberData['default'].attr('string'),
		amount: _emberData['default'].attr('number'),
		grossWage: _emberData['default'].attr('number'),
		subjectWage: _emberData['default'].attr('number'),
		grossSubjectWage: _emberData['default'].attr('number')
	});
});