define('component-library/data-table/utils', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var currencyFormatter = function currencyFormatter(row) {
		var value = row.get(this.get('contentPath'));
		return _ember['default'].isEmpty(value) ? '-' : window.prettyCurrency(value, true, 2);
	};

	var dateFormatter = function dateFormatter(row) {
		return window.moment(row.get(this.get('contentPath'))).format('MMM Do YYYY');
	};

	var groupingColumnRollup = function groupingColumnRollup(node) {
		if (_ember['default'].get(node, 'key')) {
			return _ember['default'].get(node, 'key');
		}
		return _ember['default'].get(node, 'name');
	};

	var copyRollup = function copyRollup(node, rows) {
		var that = this;
		var childrenContent = rows.map(function (row) {
			return that.getCellContent(row);
		}).uniq();
		return childrenContent.get('length') > 1 ? '-' : childrenContent.get('firstObject');
	};

	exports['default'] = {
		currencyFormatter: currencyFormatter,
		dateFormatter: dateFormatter,
		copyRollup: copyRollup,
		groupingColumnRollup: groupingColumnRollup
	};
});