define('component-library/components/census/census-sheet-exception-modal', ['exports', 'ember', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryComponentsPromiseModal) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'components/census/census-sheet-exception-modal',
		showCancelButton: false

	});

	// Utilize the "cancel" action to indicate "Start Over"
});