define('component-library/mixins/liquid-fire-transition-hack-route-mixin-factory', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	// WHY
	// -------------------
	// Liquid-fire has trouble tearing down some complicated components (like ember-table and carousel-package) when transitioning away form a page,
	// which sometimes leads to non-responsive UI. This mixin-factory can be used for optimizing the teardown process and improve transition perf.

	// USAGE
	// -------------------
	// Intended for just routes, and a boolean controller property is required to control the show/hide of desired page content/components.

	// USAGE EXAMPLE
	// -------------------

	// controller.js - Initialize the boolean controller property to true.
	/*
 	export default WizardStepController.extend({
 		showTable: true,
 	});
 */

	// route.js - Import the factory method from this file and pass in the boolean property name to create the mixin, then extend your route with the output mixin.
	/*
 	import LiquidFireTransitionHackRouteMixinFactory from 'component-library/mixins/liquid-fire-transition-hack-route-mixin-factory';
 	const LiquidFireTransitionHackRouteMixin = LiquidFireTransitionHackRouteMixinFactory('showTable');
 
 	export default WizardStepRoute.extend(LiquidFireTransitionHackRouteMixin, {
 		...
 	});
 */

	// template.hbs - Use the boolean property to toggle the component in template.
	/*
 	{{#wizard-layout}}
 		{{#if showTable}}
 			{{component "initial-enrollment/components/ie-custom-package-builder"
 				...
 			}}
 		{{/if}}
 	{{/wizard-layout}}
 */

	exports['default'] = function (controllerToggleBooleanPropertyName) {
		return _ember['default'].Mixin.create({
			setupController: function setupController(controller, model) {
				this._super.apply(this, arguments);
				controller.set(controllerToggleBooleanPropertyName, true);
			},

			actions: {
				willTransition: function willTransition(transition) {
					if (this.controller.get(controllerToggleBooleanPropertyName)) {
						transition.abort();
						this.controller.set(controllerToggleBooleanPropertyName, false);
						_ember['default'].run.next(this, function () {
							transition.retry();
							this.controller.set(controllerToggleBooleanPropertyName, true);
						});
					}
				}
			}
		});
	};
});