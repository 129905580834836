define('component-library/components/z-video-modal', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		templateName: 'components/z-video-modal',
		title: null,
		videoSrc: null,

		actions: {
			closeModal: function closeModal() {
				this.attrs.closeModal();
			}
		}
	});
});