define('component-library/mixins/pto/auth-admin-write-access-mixin', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	// TODO @cstoneham: Move this to time-off package when all pto routes use packages
	exports['default'] = _ember['default'].Mixin.create({
		beforeModel: function beforeModel(transition) {
			this._super();
			var _this = this;
			return (0, _componentLibraryUtilsGlobals.dashboard)('isAdmin').then(function (isAdmin) {
				if (!isAdmin) {
					_this.transitionTo('/');
				}
			});
		}
	});
});