define('component-library/components/z-select-2', ['exports', 'ember', 'component-library/templates/components/z-select-2'], function (exports, _ember, _componentLibraryTemplatesComponentsZSelect2) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		// https://github.com/zenefits/ember-select-2/blob/master/addon/components/select-2.js#L33
		// ember-select-2 Bindings that may be overwritten in the template
		inputSize: "input-md",
		containerCssClass: null,
		dropdownCssClass: null,
		optionIdPath: "id",
		optionValuePath: null,
		optionLabelPath: 'text',
		optionLabelSelectedPath: null,
		optionHeadlinePath: 'text',
		optionDescriptionPath: 'description',
		optionCssClassPath: 'cssClass',
		placeholder: null,
		multiple: false,
		allowClear: false,
		enabled: true,
		query: null,
		tags: false,
		tokenSeparators: null,
		typeaheadSearchingText: 'Searching…',
		typeaheadNoMatchesText: 'No matches found',
		typeaheadErrorText: 'Loading failed',
		searchEnabled: true,
		minimumInputLength: null,
		maximumInputLength: null,
		valueSeparator: ',',

		isRequired: false,
		label: null,
		tooltipText: null,
		content: null,
		value: null,
		errorText: null,
		helpText: null,
		hasFullWidth: false,

		template: _componentLibraryTemplatesComponentsZSelect2['default'],
		classNames: ['z-select-2'],
		classNameBindings: ['errorText:has-error', 'hasFullWidth:u-fillHorizontal'],
		showErrorText: _ember['default'].computed.alias('errorText') });

	// the error partial requires showErrorText
});