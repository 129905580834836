define('component-library/components/external-text-field', ['exports', 'ember', 'component-library/templates/components/external-text-field'], function (exports, _ember, _componentLibraryTemplatesComponentsExternalTextField) {
  'use strict';

  var computed = _ember['default'].computed;

  var allowedType = ['basic', 'email', 'number'];

  exports['default'] = _ember['default'].Component.extend({
    layout: _componentLibraryTemplatesComponentsExternalTextField['default'],
    // attrs
    /*
     * Indicates the type of field. By default a basic text-field, but it can be one of the
     * ${allowedType}
     */
    type: 'basic',
    // props
    textFieldComponentName: computed('type', function () {
      var type = this.get('type');
      var inAllowedTypes = allowedType.indexOf(type !== -1);
      _ember['default'].assert('The specified type must be one of the allowed types (' + allowedType + ')', inAllowedTypes);
      if (!inAllowedTypes) {
        type = 'basic';
      }
      return 'z-text-field-' + type;
    })
  });
});