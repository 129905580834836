define('component-library/components/z-select-field-base', ['exports', 'ember', 'component-library/components/native-select-field'], function (exports, _ember, _componentLibraryComponentsNativeSelectField) {
	'use strict';

	exports['default'] = _componentLibraryComponentsNativeSelectField['default'].extend({
		classNames: ['z-selectField', 'u-fillHorizontal'],
		classNameBindings: ['hasLabel:has-label', 'isRequired'],
		hasLabel: _ember['default'].computed.bool('label'),
		templateName: 'components/native-select-field',
		tooltipText: null,
		isRequired: false,

		_oldValue: null,

		willInsertElement: function willInsertElement() {
			this._super();
			this.set('_oldValue', this.get('value'));
		},

		valueDidChange: (function () {
			if (!this.get('value') || this.get('_oldValue') == this.get('value')) {
				return;
			}
			if (this.onSelectionChange) {
				this.onSelectionChange(this.get('value'), this.get('_oldValue'));
			}
			this.set('_oldValue', this.get('value'));
		}).observes('value')
	});
});