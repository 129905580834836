define('component-library/modals/payroll/add-pay-schedule-modal', ['exports', 'component-library/components/promise-modal', 'ember'], function (exports, _componentLibraryComponentsPromiseModal, _ember) {
	'use strict';

	var putAPI = '/api/company_pay_schedule';
	var putCall = function putCall(id, payload) {
		return {
			url: putAPI + '/' + id + '/',
			type: 'PUT',
			data: payload,
			contentType: 'application/json'
		};
	};

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'modals/payroll/add-pay-schedule-modal',
		modalDialogClass: 'z-payScheduleConfigurationModal',
		autoSetHeight: false,

		// TODO(Peter): remove this after prior payroll is done
		shiftCheckDateToNextQuarter: false,

		company: null,
		defaultPayPeriodSettings: null,
		paySchedule: null,
		payScheduleComponent: null,
		numRollingGateDays: null,
		showBothCompType: true,

		isStatic: false,

		// Setting this to false because 'enter' key bypasses validations
		allowKeyActions: false,

		actions: {

			apply: function apply(laddaButton) {
				var _this = this;

				if (!this.get('payScheduleComponent.target').apply()) {
					laddaButton.stop();
					return;
				}
				var paySchedule = this.get('paySchedule');

				// Regular .save for new cps
				if (!paySchedule.get('id')) {
					this.get('paySchedule').save().then(function (paySchedule) {
						_this.send('confirm', paySchedule);
					});
				} else {
					var cpsPayload = JSON.stringify(paySchedule);
					var id = paySchedule.get('id');
					var data = putCall(id, cpsPayload);
					_ember['default'].ajax(data).then(function (ps) {
						_this.send('confirm', ps);
					});
				}
			}
		}
	});
});