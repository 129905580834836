define('component-library/filters/rate-filter-spec', ['exports', 'ember', 'component-library/components/rate-filter'], function (exports, _ember, _componentLibraryComponentsRateFilter) {
  'use strict';

  exports['default'] = _ember['default'].Object.extend({
    popoverClass: (function () {
      switch (this.get('formatAs')) {
        default:
          return _componentLibraryComponentsRateFilter['default'];
      }
    }).property('formatAs'),

    isActive: false,
    isHidden: false,
    filterId: undefined,
    name: "",

    // 'number', 'float', 'date', or 'datetime'
    // If formatAs == 'date', the minValue and maxValue objects are expected to be moment objects.
    // TODO(ed): 'money' (value with $), 'percent'. That will not work if we need to dynamically format
    // individual moneyValues with different currencies. Otherwise, the label function can be overridden.
    formatAs: 'number',

    valuePath: undefined,
    // The labelPath is relative to the valuePath.
    labelPath: '',
    labelPaths: (function () {
      return [this.get('labelPath')];
    }).property('labelPath'),
    labelFunction: (function (value) {
      var _this = this;

      return function (value) {
        var labelValue = _ember['default'].get(value, _this.get('labelPath'));
        return labelValue;
      };
    }).property(),

    queryValuePath: undefined,

    // The number filter components should convert these to numbers or moment objects.
    age: null,
    zip: null,
    rate: null,

    setupDescriptionProperty: (function () {
      var dependentPaths = ['labelFunction', 'age', 'zip', 'rate', 'formatAs'];
      dependentPaths.push.apply(dependentPaths, this.get('labelPaths').map(function (labelPath) {
        return 'age.' + labelPath;
      }));
      dependentPaths.push.apply(dependentPaths, this.get('labelPaths').map(function (labelPath) {
        return 'zip.' + labelPath;
      }));
      dependentPaths.push.apply(dependentPaths, this.get('labelPaths').map(function (labelPath) {
        return 'rate.' + labelPath;
      }));
      this.propertyWillChange('description');
      _ember['default'].defineProperty(this, 'description', _ember['default'].computed.apply(null, dependentPaths.concat(function () {
        var ageLabel = this.get('labelFunction')(this.get('age'));
        var zipLabel = this.get('labelFunction')(this.get('zip'));
        var rateLabel = this.get('labelFunction')(this.get('rate'));
        return this.descriptionFunction(ageLabel, zipLabel, rateLabel);
      })));
      this.propertyDidChange('description');
    }).observes('labelPaths.[]').on('init'),

    descriptionFunction: function descriptionFunction(ageLabel, zipLabel, rateLabel) {
      if (ageLabel == null && zipLabel == null && rateLabel == null) {
        return "(None)";
      }

      return (ageLabel ? "= " + ageLabel + ", " : "") + (zipLabel ? "= " + zipLabel + ", " : "") + (rateLabel ? "= " + rateLabel : "");
    },

    applyFuncHelper: function applyFuncHelper(key, property, valueTransform) {
      return false;
    },

    applyFunc: (function (key, property, valueTransform) {
      var _this2 = this;

      return function (element) {
        return _this2.applyFuncHelper(undefined, _ember['default'].get(element, _this2.get('valuePath')));
      };
    }).property('valuePath', 'age', 'zip', 'rate', 'formatAs'),

    filterProperty: (function () {
      var valuePath = this.get('valuePath');
      var applyFuncHelper = this.applyFuncHelper.bind(this);
      return [valuePath, applyFuncHelper];
    }).property('valuePath', 'applyFunc'),

    query: (function () {
      var query = {};
      var valuePath = this.get('queryValuePath') || this.get('valuePath');
      var age = this.get('age');
      var zip = this.get('zip');
      var rate = this.get('rate');

      if (age !== null) {
        query[valuePath + '__age'] = age;
      }
      if (zip !== null) {
        query[valuePath + '__zip'] = zip;
      }
      if (rate !== null) {
        query[valuePath + '__rate'] = rate;
      }
      return query;
    }).property('queryValuePath', 'valuePath', 'age', 'zip', 'rate')

  });
});