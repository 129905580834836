define('component-library/graphql/graphql-request', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	// polyfill for IE11 in compatibility mode
	var origin = window.location.origin || window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

	function graphqlRequest(query, variables, timeout) {
		var queryTimeout = isNaN(timeout) ? 0 : timeout;

		var data = JSON.stringify({ query: query, variables: variables });
		return _ember['default'].ajaxSettings({
			data: data,
			url: '/graphql/',
			contentType: 'application/json',
			method: 'POST',
			timeout: queryTimeout,
			headers: {
				'x-origin': origin,
				'IS-BACKGROUND-QUERY': true
			}
		});
	}
	/* eslint-disable no-lazy-globals */
	_ember['default'].graphqlRequest = graphqlRequest;
	/* eslint-disable no-lazy-globals */

	exports['default'] = graphqlRequest;
});