define('component-library/helpers/humanize-list', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var _slicedToArray = (function () {
		function sliceIterator(arr, i) {
			var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;_e = err;
			} finally {
				try {
					if (!_n && _i['return']) _i['return']();
				} finally {
					if (_d) throw _e;
				}
			}return _arr;
		}return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError('Invalid attempt to destructure non-iterable instance');
			}
		};
	})();

	/* Usage:
  *   {{humanize-list listOfStrings}}
  *
  *   arg: capitalize (boolean, default: false)
  *   arg: toLowerCase (boolean, default: false)
  *   arg: conjunction (string, default: "and")
  */
	exports['default'] = _ember['default'].Helper.helper(function (_ref) {
		var _ref2 = _slicedToArray(_ref, 1);

		var list = _ref2[0];
		var hash = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

		if (!list) {
			return '';
		}
		if (hash.capitalize) {
			list = list.map(_ember['default'].String.capitalize);
		}
		if (hash.toLowerCase) {
			list = list.map(Function.prototype.call, String.prototype.toLowerCase);
		}
		// see humanizeList in abstract.js
		return window.humanizeList(list, hash.conjunction);
	});
});