define('component-library/data-table/column', ['exports', 'ember', 'ember-table/models/column-definition', 'component-library/data-table/cells/sortable-header-cell'], function (exports, _ember, _emberTableModelsColumnDefinition, _componentLibraryDataTableCellsSortableHeaderCell) {
	'use strict';

	exports['default'] = _emberTableModelsColumnDefinition['default'].extend({
		showHeaderMenu: true,
		headerCellViewClass: _componentLibraryDataTableCellsSortableHeaderCell['default'],

		getSortValue: function getSortValue(row) {
			var key = this.get('contentPath');
			if (_ember['default'].isEmpty(key)) {
				return this.get('defaultEmptyCellValue');
			}
			var value = _ember['default'].get(row, key);
			if (this.get('type') === 'validations') {
				return _ember['default'].isEmpty(Object.keys(value));
			}
			if (this.get('type') === 'date') {
				return window.moment(value, 'MM/DD/YYYY');
			}
			return value;
		},

		defaultEmptyCellValue: "&dash;".htmlSafe(),
		getCellContent: function getCellContent(row) {
			var key = this.get('contentPath');
			if (_ember['default'].isEmpty(key)) {
				return this.get('defaultEmptyCellValue');
			}
			var value = _ember['default'].get(row, key);
			if (_ember['default'].isEmpty(value)) {
				return this.get('defaultEmptyCellValue');
			} else {
				return value;
			}
		},

		setCellContent: function setCellContent(row, value) {
			var key = this.get('contentPath');
			if (key && row) {
				_ember['default'].set(row, key, value);
			}
		},

		comparator: function comparator(lhs, rhs) {
			var sortAscending = this.get('sortAscending');
			var sign;
			if (typeof sortAscending === 'boolean') {
				sign = sortAscending ? 1 : -1;
			} else {
				sign = 0;
			}
			var value1 = this.getSortValue(lhs);
			var value2 = this.getSortValue(rhs);

			if (this.get('type') === 'date') {
				return sign * (value1 - value2);
			} else {
				return sign * _ember['default'].compare(value1, value2);
			}
		},

		filter: function filter(rows) {
			return rows;
		},

		rollup: function rollup() {
			return '-';
		}
	});
});