define('component-library/components/z-loading-spinner', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['grid-block'],
		messages: [],
		intervalId: null,
		cycleDuration: 3000,
		isMessagesEmpty: _ember['default'].computed.empty('filteredMessages'),
		numMessages: _ember['default'].computed.alias('filteredMessages.length'),

		// filteredMessages for defensive programming and robustness
		filteredMessages: _ember['default'].computed('messages', function () {
			var messages = this.get('messages');
			return _ember['default'].isEmpty(messages) ? [] : messages.compact();
		}),

		didInsertElement: function didInsertElement() {
			this._super();
			var isMessagesEmpty = this.get('isMessagesEmpty');
			var numMessages = this.get('numMessages');
			if (isMessagesEmpty) {
				this.showDefaultMessage();
			} else if (numMessages == 1) {
				// If only one message is passed, we don't want to use fade animation classes.
				// Instead, we will style like default message.
				this.showSingleMessage();
			} else {
				this.showCycledMessages();
			}
		},

		willDestroyElement: function willDestroyElement() {
			this._super();
			clearInterval(this.get('intervalId'));
		},

		showDefaultMessage: function showDefaultMessage() {
			this.$('.js-z-loadingSpinner-defaultMessage').css('opacity', 1);
		},

		showSingleMessage: function showSingleMessage() {
			this.$('.js-z-loadingSpinner-message:eq(0)').css('opacity', 1);
		},

		showCycledMessages: function showCycledMessages() {
			var _this = this;

			var index = 0;
			var numMessages = this.get('numMessages');
			var cycleDuration = this.get('cycleDuration');

			// Initialize first message
			this.$('.js-z-loadingSpinner-message:eq(' + index + ')').addClass('z-loadingSpinner-message--cycle');
			var intervalId = setInterval(function () {
				_this.$('.js-z-loadingSpinner-message:eq(' + index + ')').removeClass('z-loadingSpinner-message--cycle');
				index = ++index % numMessages;
				_this.$('.js-z-loadingSpinner-message:eq(' + index + ')').addClass('z-loadingSpinner-message--cycle');
			}, cycleDuration);
			clearInterval(this.get('intervalId'));
			this.set('intervalId', intervalId);
		}
	});
});