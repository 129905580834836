define('component-library/components/radio-button-group', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-radioButtonGroup', 'js-glue-radioButtonGroup'],
		attributeBindings: ['name:data-name'],
		tagName: 'radiogroup',
		label: null,
		tooltipText: null,
		errorText: '',

		showError: true,
		errorType: 'text',
		showErrorComputed: _ember['default'].computed('errorText.[]', 'showError', 'errorType', function () {
			return !!this.get('errorText.length') && this.get('showError');
		}),
		showErrorTooltip: _ember['default'].computed('showErrorComputed', 'errorType', function () {
			return this.get('showErrorComputed') && this.get('errorType') === 'tooltip';
		}),
		showErrorText: _ember['default'].computed('showErrorComputed', 'errorType', function () {
			return this.get('showErrorComputed') && this.get('errorType') === 'text';
		})
	});
});