define('component-library/simple-wizard/step/state', ['exports', 'ember', 'component-library/wizard/state/step'], function (exports, _ember, _componentLibraryWizardStateStep) {
	'use strict';

	exports['default'] = _componentLibraryWizardStateStep['default'].extend({
		// For reference from wizard step state
		routeName: null,
		stepKey: null,
		title: null,
		valuePath: null,
		value: null,

		// optional
		isComplete: false,
		isEnabled: true,
		canSkipWithoutComplete: true,
		shouldShowInReview: true,

		_isEnabled: (function () {
			if (typeof this.get('isEnabled') === 'function') {
				return !!this.get('isEnabled')();
			} else {
				return !!this.get('isEnabled');
			}
		}).property('isEnabled'),

		_isAccessible: _ember['default'].computed('sequenceEnforced', 'isAccessible', '_isEnabled', function () {
			return this.get('sequenceEnforced') ? this.get('isAccessible') && this.get('_isEnabled') : this.get('_isEnabled');
		})
	});
});