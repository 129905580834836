define('component-library/components/contributions-modal/utils', ['exports', 'ember', 'component-library/components/contributions-modal/constants'], function (exports, _ember, _componentLibraryComponentsContributionsModalConstants) {
	'use strict';

	exports['default'] = {
		getContributionTypeLabels: function getContributionTypeLabels(totalOrIndividual) {
			if (totalOrIndividual == _componentLibraryComponentsContributionsModalConstants.TOTAL) {
				return _componentLibraryComponentsContributionsModalConstants.TYPE_LABELS_TOTAL;
			}
			return _componentLibraryComponentsContributionsModalConstants.TYPE_LABELS_INDIVIDUAL;
		},
		getRuleTypeHelper: function getRuleTypeHelper(selectedContributionType) {
			// selectedContributionType might be the PlanId in case of PercentageOfPlanRaw
			if (selectedContributionType == _componentLibraryComponentsContributionsModalConstants.ZERO) {
				return _componentLibraryComponentsContributionsModalConstants.FIXED;
			}
			return [_componentLibraryComponentsContributionsModalConstants.FIXED, _componentLibraryComponentsContributionsModalConstants.PERCENTAGE].includes(selectedContributionType) ? selectedContributionType : _componentLibraryComponentsContributionsModalConstants.PERCENTAGE_OF_PLAN_RAW;
		},
		getRuleTypeForSelection: function getRuleTypeForSelection(ruleTemplate) {
			var ruleType = ruleTemplate.type;
			if (ruleType == _componentLibraryComponentsContributionsModalConstants.ZERO) {
				return _componentLibraryComponentsContributionsModalConstants.FIXED;
			}
			return [_componentLibraryComponentsContributionsModalConstants.FIXED, _componentLibraryComponentsContributionsModalConstants.PERCENTAGE].includes(ruleType) ? ruleType : ruleTemplate.planId;
		},
		contributionsFormulaFilter: function contributionsFormulaFilter(formulaData) {
			var _this = this;

			return Object.keys(formulaData).filter(function (x) {
				return Object.values(_componentLibraryComponentsContributionsModalConstants.RULE_DATA_ATTRS).includes(x);
			}).reduce(function (map, obj) {
				map[obj] = obj != 'type' ? formulaData[obj] : _this.getRuleTypeHelper(formulaData[obj]);
				return map;
			}, {});
		},
		allTypes: function allTypes(plans, loc) {
			var Fixed = _ember['default'].Object.create({
				type: _componentLibraryComponentsContributionsModalConstants.FIXED,
				label: _componentLibraryComponentsContributionsModalConstants.FIXED,
				fixedAmount: '0.00'
			});
			var Percentage = _ember['default'].Object.create({
				type: _componentLibraryComponentsContributionsModalConstants.PERCENTAGE,
				label: _componentLibraryComponentsContributionsModalConstants.PERCENTAGE,
				percentage: '0',
				cap: null
			});
			var filteredPlans = plans.filter(function (plan) {
				return loc == null || _ember['default'].isEqual(loc, plan.get('lineOfCoverage'));
			});
			var percentageOfPlanChoices = filteredPlans.map(function (p) {
				return _ember['default'].Object.create({
					label: 'Percentage of ' + p.get('displayName'),
					type: p.get('id'),
					planId: p.get('id'),
					lineOfCoverage: p.get('lineOfCoverage'),
					percentage: '0',
					cap: null
				});
			});
			var typesData = _ember['default'].Object.create({
				Fixed: Fixed,
				Percentage: Percentage
			});
			percentageOfPlanChoices.forEach(function (p) {
				typesData[p.planId] = p;
			});
			return typesData;
		},
		getRuleDeductionContributionTypeHelper: function getRuleDeductionContributionTypeHelper(rule, deductionOrcontribution) {
			var totalRuleType = rule.get('template.total' + deductionOrcontribution + '.type');
			var dependentsRuleType = rule.get('template.dependents' + deductionOrcontribution + '.type');
			var employeeRuleType = rule.get('template.employee' + deductionOrcontribution + '.type');
			// we don't support all as a line of coverage in the new component.
			if (!rule.get('lineOfCoverage')) {
				return _componentLibraryComponentsContributionsModalConstants.UNSUPPORTED;
			}
			if ([_componentLibraryComponentsContributionsModalConstants.DEPENDENT_TYPE, _componentLibraryComponentsContributionsModalConstants.DEPENDENT_COUNT].includes(totalRuleType)) {
				return totalRuleType;
			}
			if ([_componentLibraryComponentsContributionsModalConstants.DEPENDENT_TYPE, _componentLibraryComponentsContributionsModalConstants.DEPENDENT_COUNT].includes(dependentsRuleType)) {
				return dependentsRuleType;
			}
			if (totalRuleType == _componentLibraryComponentsContributionsModalConstants.DEPENDENT_COUNT) {
				return _componentLibraryComponentsContributionsModalConstants.DEPENDENT_COUNT;
			}
			if (_componentLibraryComponentsContributionsModalConstants.SUPPORTED_TYPES.includes(totalRuleType)) {
				return _componentLibraryComponentsContributionsModalConstants.TOTAL;
			}
			if (_componentLibraryComponentsContributionsModalConstants.SUPPORTED_TYPES.includes(employeeRuleType) && _componentLibraryComponentsContributionsModalConstants.SUPPORTED_TYPES.includes(dependentsRuleType)) {
				return _componentLibraryComponentsContributionsModalConstants.UNIFORM;
			}
			return _componentLibraryComponentsContributionsModalConstants.UNSUPPORTED;
		},
		getRuleContributionType: function getRuleContributionType(rule) {
			return this.getRuleDeductionContributionTypeHelper(rule, _componentLibraryComponentsContributionsModalConstants.CONTRIBUTION);
		},
		getRuleDeductionType: function getRuleDeductionType(rule) {
			return this.getRuleDeductionContributionTypeHelper(rule, _componentLibraryComponentsContributionsModalConstants.DEDUCTION);
		},
		isRuleForDeduction: function isRuleForDeduction(rule) {
			return rule.get('dependentsDeductionFormula') || rule.get('employeeDeductionFormula') || rule.get('totalDeductionFormula');
		},
		getFullContribution: function getFullContribution(plans, loc) {
			var contributionFormula = this.allTypes(plans, loc)[_componentLibraryComponentsContributionsModalConstants.PERCENTAGE];
			contributionFormula.percentage = 100;
			return contributionFormula;
		}
	};
});