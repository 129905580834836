define('component-library/components/payroll/checklist-where-to-find-docs-modal', ['exports', 'ember', 'component-library/templates/components/payroll/checklist-where-to-find-docs-modal'], function (exports, _ember, _componentLibraryTemplatesComponentsPayrollChecklistWhereToFindDocsModal) {
	'use strict';

	var DEFAULT_INSTRUCTION = {
		'prior_filing': {
			introductions: ['In this section, we need your quarterly tax returns and quarter to date reports.  Your tax provider should be providing you with quarterly tax filings that include your Federal, State, and Local returns.  In addition, this may include reconciliation reports.', 'Most payroll providers have a quarterly tax package that should include this data.  These reports are usually provided in a Reports section under Tax Filings.', 'Please provide one tax filing report for every completed quarter of the current year.']
		},
		'pay_run_summary': {
			introductions: ['A payroll journal is a summary of an individual pay run. This information should include all Wage and Tax information. In general, payroll providers should have some variation of what we call a quarter to date wage and tax report.', 'Please provide one report for every quarter of the current year.']
		},
		'pay_item_summary': {
			introductions: ['Not all providers may give you this information. But ideally, you have a list of all your earnings, deductions, contributions, and garnishments. If you do, please provide a list of these items, so we can configure Zenefits Payroll to match your current provider settings.']
		},
		'employee_register': {
			introductions: ['Most providers have a report that lists all your current (and former) employees, their home locations, and W4 information. In general, this is called an Employee Register report. We use this report to properly configure and setup your employees in Zenefits Payroll.']
		},
		'other': {
			introductions: ['This section is a placeholder for other documents.', 'Add as many other Reports or Documents as you think necessary to help us onboard you quickly and easily.  Some examples may be copies of your tax exemption letters, rate notices, etc.']
		}
	};

	var INSTRUCTIONS = {
		'default': DEFAULT_INSTRUCTION
	};
	exports['default'] = _ember['default'].Component.extend({
		layout: _componentLibraryTemplatesComponentsPayrollChecklistWhereToFindDocsModal['default'],
		documentName: null,
		priorProvider: null,
		data: _ember['default'].computed('documentName', function () {
			return INSTRUCTIONS[this.get('priorProvider')][this.get('documentName')];
		}),
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			_ember['default'].$('.js-doc-location').load(function () {
				_ember['default'].$('.js-doc-location').show();
			});
		}
	});
});