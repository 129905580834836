define('component-library/components/sortable-list', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		tagName: 'ul',
		classNames: ['z-sortableList'],
		itemSelector: '.js-z-sortableList-item--sortable',

		sortableOption: _ember['default'].computed(function () {
			return {
				axis: 'y',
				containment: 'parent',
				items: this.get('itemSelector') + ':not(.disabled)',
				tolerance: 'pointer',
				stop: _ember['default'].$.proxy(this.onItemSortDone, this)
			};
		}),

		didInsertElement: function didInsertElement() {
			this.$().sortable(this.get('sortableOption'));
		},

		willDestroyElement: function willDestroyElement() {
			this.$().sortable('destroy');
		},

		onItemSortDone: function onItemSortDone(event, ui) {
			var newIndex = ui.item.index();
			var $view = ui.item;
			var view = _ember['default'].View.views[$view.attr('id')];
			var content = view.get('content');
			view.sendAction.apply(view, ['sortItem', content, newIndex]);
			// TODO(Peter): this is super crappy. Ember has a bug where it leaked the
			// dom element. Even though ember thinks it destroyed the view, the dom
			// element still exists. I think somehow jquery ui causes discrepancy...
			_ember['default'].run.next(this, function () {
				if (_ember['default'].isEmpty(view.$())) {
					$view.remove();
				}
			});
		}
	});
});