define('component-library/utils/payroll/prior-payroll-utils', ['exports'], function (exports) {
  // keep these alphabetized
  'use strict';

  var payrollProvidersWithFileUploadSupport = [{ value: 'AD', label: 'ADP RUN' }];

  exports.payrollProvidersWithFileUploadSupport = payrollProvidersWithFileUploadSupport;

  var payrollProvidersWithAutomaticSupport = [{ value: 'AR', label: 'ADP Portal' }, { value: 'WF', label: 'ADP WorkForce Now' }, { value: 'ZN', label: 'Gusto' }, { value: 'IN', label: 'Intuit' }, { value: 'MP', label: 'ManagePayroll' }, { value: 'ON', label: 'OnPay' }, { value: 'PX', label: 'Paychex' }, { value: 'QB', label: 'QuickBooks Online' }];
  exports.payrollProvidersWithAutomaticSupport = payrollProvidersWithAutomaticSupport;
});