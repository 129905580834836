define('z-inputs/components/z-text-field-password', ['exports', 'z-inputs/components/z-text-field-base', 'z-inputs/templates/components/z-text-field-password'], function (exports, _zInputsComponentsZTextFieldBase, _zInputsTemplatesComponentsZTextFieldPassword) {
  'use strict';

  exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend({
    layout: _zInputsTemplatesComponentsZTextFieldPassword['default'],
    classNames: 'z-textField--password',
    type: 'password',
    formattedPasswordValue: (function () {
      var value = this.get('value');
      if (value) {
        // \u2022 is unicode for a bullet point
        return new Array(this.get('value').toString().length + 1).join('•');
      }
      return '';
    }).property('value'),
    tooltipText: null
  });
});