define('component-library/helpers/pretty-variable-comp-type', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var _slicedToArray = (function () {
		function sliceIterator(arr, i) {
			var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;_e = err;
			} finally {
				try {
					if (!_n && _i['return']) _i['return']();
				} finally {
					if (_d) throw _e;
				}
			}return _arr;
		}return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError('Invalid attempt to destructure non-iterable instance');
			}
		};
	})();

	var VARIABLE_COMPENSATION_TYPES = [{ id: 'commission', name: 'Commission' }, { id: 'bonus', name: 'Bonus' }, { id: 'owners_draw', name: 'Owner\'s Draw' }, { id: 'other', name: 'Other' }];

	exports['default'] = _ember['default'].HTMLBars.makeBoundHelper(function (_ref) {
		var _ref2 = _slicedToArray(_ref, 1);

		var compType = _ref2[0];

		if (compType && compType.length > 0) {
			return VARIABLE_COMPENSATION_TYPES.filter(function (o) {
				return o.id === compType;
			})[0].name;
		}
	});
});