define('z-inputs/components/z-text-field-zip', ['exports', 'z-inputs/components/z-text-field-base', 'z-inputs/templates/components/z-text-field-basic'], function (exports, _zInputsComponentsZTextFieldBase, _zInputsTemplatesComponentsZTextFieldBasic) {
	'use strict';

	exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend({
		classNames: 'z-textField--zip',
		layout: _zInputsTemplatesComponentsZTextFieldBasic['default'],
		type: 'zip',
		mask: '99999',
		tooltipText: null
	});
});