define('component-library/components/radio-block-with-pills', ['exports', 'ember', 'component-library/components/radio-block', 'component-library/templates/components/radio-block-with-pills'], function (exports, _ember, _componentLibraryComponentsRadioBlock, _componentLibraryTemplatesComponentsRadioBlockWithPills) {
	'use strict';

	exports['default'] = _componentLibraryComponentsRadioBlock['default'].extend({
		template: _componentLibraryTemplatesComponentsRadioBlockWithPills['default'],
		title: null,
		escapeTitle: true,
		description: null,
		escapeDescription: true,
		subtitle: null,
		subtext: null,
		dataHelpbox: null,
		dataHelpboxConditionalHover: null,

		showTitlePill: _ember['default'].computed.notEmpty('title'),
		showDescriptionPill: _ember['default'].computed.notEmpty('description'),
		showSubtitlePill: _ember['default'].computed.notEmpty('subtitle'),
		showSubtextPill: _ember['default'].computed.notEmpty('subtext')
	});
});