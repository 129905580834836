define('component-library/constants/cps', ['exports'], function (exports) {
	'use strict';

	var COMP_TYPE_KEYS = {
		H: 'hasHourly',
		B: 'hasBoth',
		S: 'hasSalaried'
	};

	exports.COMP_TYPE_KEYS = COMP_TYPE_KEYS;

	var CREATION_METHOD = {
		PYP: 'PYP',
		SMP: 'SMP'
	};

	exports.CREATION_METHOD = CREATION_METHOD;

	var PAYROLL_PROVIDER = {
		YP: 'YP'
	};

	exports.PAYROLL_PROVIDER = PAYROLL_PROVIDER;

	var COMP_TYPE_FLAG = {
		Hourly: 'H',
		Salaried: 'S',
		Both: 'B'
	};

	exports.COMP_TYPE_FLAG = COMP_TYPE_FLAG;

	// SMP
	var COMP_TYPE_MAPPING = {
		HOURLY: 'Hourly',
		SALARIED: 'Salaried',
		BOTH: 'Both Hourly and Salaried'
	};

	exports.COMP_TYPE_MAPPING = COMP_TYPE_MAPPING;

	var COMP_TYPE_NAME_MAPPING = {
		HOURLY: 'All hourly employees',
		SALARIED: 'All Salaried employees',
		BOTH: 'Both Hourly and Salaried'
	};

	exports.COMP_TYPE_NAME_MAPPING = COMP_TYPE_NAME_MAPPING;

	// PYP
	var SNE_TYPE_MAPPING = {
		HOURLY: 'Hourly and Non-exempt',
		SALARIED: 'Salaried Exempt',
		BOTH: 'All types of Employees'
	};

	exports.SNE_TYPE_MAPPING = SNE_TYPE_MAPPING;

	var SNE_TYPE_NAME_MAPPING = {
		HOURLY: 'Hourly and Non-exempt Employees',
		SALARIED: 'Salaried Exempt Employees',
		BOTH: 'All types of Employees'
	};

	exports.SNE_TYPE_NAME_MAPPING = SNE_TYPE_NAME_MAPPING;

	// This is for UI pay frequency calculations
	var FREQUENCY_OFFSET = {
		Mo: 1,
		SM: 1,
		BW: 1,
		We: 2
	};

	exports.FREQUENCY_OFFSET = FREQUENCY_OFFSET;

	var getTypeName = function getTypeName(creationMethod) {
		return creationMethod === 'SMP' ? COMP_TYPE_MAPPING : SNE_TYPE_MAPPING;
	};

	exports.getTypeName = getTypeName;

	var getLongTypeName = function getLongTypeName(creationMethod) {
		return creationMethod === 'SMP' ? COMP_TYPE_NAME_MAPPING : SNE_TYPE_NAME_MAPPING;
	};
	exports.getLongTypeName = getLongTypeName;
});