define('component-library/helpers/filter-deleted-items', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  /* Usage:
   *   {{filter-deleted-items itemsToFilter}}
   *
   *   arg: itemsToFilter is a list of items, each of which may or
   *        may not have the willDelete flag set
   *   returns: List of items without any items which have willDelete flag set
   *
   */
  exports['default'] = _ember['default'].Helper.helper(function (params) {
    var itemList = params[0] || [];
    return itemList.rejectBy('willDelete');
  });
});