define('component-library/mixins/ember-model-validations', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    'use strict';

    exports['default'] = _ember['default'].Mixin.create(_emberValidations['default'], {
        init: function init() {
            var _this = this;

            this.set('validations', {});
            this.constructor.getAttributes().forEach(function (attr) {
                var options = _this.constructor.metaForProperty(attr).options;
                if (options && options.validations) {
                    _this.set('validations.' + attr, options.validations);
                }
            });
            this._super();
        },
        validateThenSave: function validateThenSave() {
            var _this2 = this;

            return this.validate().then(function () {
                return _this2.save();
            }, function () {
                var errors = _this2.get('errors');
                errors.responseText = _this2.getReasonString(_this2.constructor.getAttributes(), errors);
                return _ember['default'].RSVP.Promise.reject(errors);
            });
        },
        //helper function to extract reasons from errors in form of a single string which can be split later as per use
        getReasonString: function getReasonString(attributes, errors) {
            return attributes.map(function (key) {
                if (errors[key]) {
                    return errors[key].map(function (anError) {
                        return key + " " + anError;
                    }).join(': ');
                } else {
                    return '';
                }
            }).filter(function (x) {
                return x;
            }).uniq().join(', '); //filter for removing empty strings
        }
    });
});