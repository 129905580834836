define('component-library/wizard/flow/section/route', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Route.extend({
		// OVERRIDES - begin
		wizardService: null,
		serviceEnabled: false,

		// model: must return an Ember.RSVP.hash
		wizardFlowRoute: null, // required

		// OVERRIDES - end

		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);

			if (this.get('serviceEnabled')) {
				this.get('wizardService').onSectionEnter(controller.get('wizardSectionKey'));
				return;
			}

			var wizardFlowRoute = this.get('wizardFlowRoute');
			var flowModel = this.modelFor(wizardFlowRoute);
			var wizardSectionKey = controller.get('wizardSectionKey');
			var wizardSectionState = flowModel.wizardFlowState.getSection(wizardSectionKey);
			model.wizardSectionState = wizardSectionState;
			controller.set('wizardSectionState', wizardSectionState);

			if (this.verifySectionAccessible(wizardSectionState)) {
				this.send('_updateCurrentSection_sectionAction', wizardSectionKey);
			}
		},

		verifySectionAccessible: function verifySectionAccessible(sectionState) {
			if (sectionState && !sectionState.get('isAccessible')) {
				this.send('_transitionToFlowIndex_sectionAction', sectionState.get('sectionKey'));
				return false;
			}
			return true;
		},

		actions: {
			_updateStepComplete_stepAction: function _updateStepComplete_stepAction(stepKey, isComplete, shouldTransition, newValue) {
				var _this = this;

				var sectionKey = this.get('controller.wizardSectionKey');

				var propagateStepCompleted = function propagateStepCompleted() {
					_this.send('_updateStepComplete_sectionAction', sectionKey, stepKey, isComplete, shouldTransition);
				};

				this.get('controller')._setStepCompletion(stepKey, isComplete, newValue).then(propagateStepCompleted, propagateStepCompleted);
			},

			_transitionToNextStep_stepAction: function _transitionToNextStep_stepAction() {
				var sectionKey = this.get('controller.wizardSectionKey');
				this.send('_transitionToNextStep_sectionAction', sectionKey);
			},

			_transitionToPreviousStep_stepAction: function _transitionToPreviousStep_stepAction() {
				var sectionKey = this.get('controller.wizardSectionKey');
				this.send('_transitionToPreviousStep_sectionAction', sectionKey);
			},

			_transitionToStepIndex_stepAction: function _transitionToStepIndex_stepAction(index) {
				var sectionKey = this.get('controller.wizardSectionKey');
				this.send('_transitionToStepIndex_sectionAction', sectionKey, index);
			},

			_transitionToStepKey_stepAction: function _transitionToStepKey_stepAction(stepKey) {
				var sectionKey = this.get('controller.wizardSectionKey');
				this.send('_transitionToStepKey_sectionAction', sectionKey, stepKey);
			},

			_transitionToFirstIncompleteStep_stepAction: function _transitionToFirstIncompleteStep_stepAction() {
				var sectionKey = this.get('controller.wizardSectionKey');
				this.send('_transitionToFirstIncompleteStep_sectionAction', sectionKey);
			},

			_transitionToNextIncompleteStep_stepAction: function _transitionToNextIncompleteStep_stepAction() {
				var sectionKey = this.get('controller.wizardSectionKey');
				this.send('_transitionToNextIncompleteStep_sectionAction', sectionKey);
			},

			_transitionToFlowIndex_stepAction: function _transitionToFlowIndex_stepAction(stepKey) {
				var sectionKey = this.get('controller.wizardSectionKey');
				this.send('_transitionToFlowIndex_sectionAction', sectionKey, stepKey);
			},

			_updateCurrentStep_stepAction: function _updateCurrentStep_stepAction(stepKey, missingStepRedirectSectionKey, missingStepRedirectStepKey) {
				var sectionKey = this.get('controller.wizardSectionKey');
				if (this.get('controller')._updateCurrentStep(stepKey)) {
					this.send('_updateCurrentStep_sectionAction', sectionKey, stepKey);
				} else {
					this.send('_transitionToStepKey_sectionAction', missingStepRedirectSectionKey, missingStepRedirectStepKey);
				}
			}
		}
	});
});