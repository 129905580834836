define('component-library/components/bank-account-verification', ['exports', 'ember'], function (exports, _ember) {
	// Please do not change this file unless you talked to the Zenefits Payroll
	// team about it. We are using it in the setup flow and payroll pending pages
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		defaultTestAmountsErrorMessage: 'An error occurred while attempting to verify the test amounts. Please try again later.',
		// display, setup or verification
		mode: 'setup',
		hasVoidCheckUrl: _ember['default'].computed.oneWay('account.voidCheckUrl'),
		showVoidCheckUrl: _ember['default'].computed.not('hasVoidCheckUrl'),

		actions: {
			submitTestAmount: function submitTestAmount(laddaButton) {
				var _this = this;

				this.set('errorMessage', '');
				var testAmount1 = this.get('testAmount1');
				var testAmount2 = this.get('testAmount2');
				if (_ember['default'].isEmpty(testAmount1) || isNaN(testAmount1) || _ember['default'].isEmpty(testAmount2) || isNaN(testAmount2)) {
					laddaButton.stop();
					this.set('errorMessage', "Please enter a valid amount");
					return;
				}
				var data = {
					testDetails: {
						'verificationId': this.get('account.bankAccountVerification_id'),
						'testAmount1': testAmount1,
						'testAmount2': testAmount2
					}
				};

				return _ember['default'].ajax({
					url: "/custom_api/bank_account_verification/test_amounts/",
					type: "POST",
					data: JSON.stringify(data)
				}).then(function (response) {
					if (response.errorOccurred) {
						laddaButton.stop();
						_this.set('errorMessage', response.errorMessage || _this.get('defaultTestAmountsErrorMessage'));
						return;
					}
					_this.get('account').reload().then(function (account) {
						laddaButton.stop();
						if (!account.get('isCompleted')) {
							_this.set('errorMessage', 'The test amounts you have entered did not match our records. Please try again.');
						}
					});
				});
			},

			changeAccountInformation: function changeAccountInformation() {
				this.sendAction('changeAccountInformation');
			},

			showVoidCheckUrl: function showVoidCheckUrl() {
				this.set('showVoidCheckUrl', true);
			},

			uploadVoidCheck: function uploadVoidCheck(laddaButton) {
				this.sendAction('uploadVoidCheck', laddaButton);
			}
		}
	});
});