define('component-library/wizard/state/section', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].ObjectProxy.extend({
		// OVERRIDES - begin

		// required
		routeName: null,
		sectionKey: null,
		flow: null, // WizardFlowState
		title: null,

		// optional
		currentStepIndex: null,
		sequenceEnforced: _ember['default'].computed.alias('flow.sequenceEnforced'), // prevents skipping past an unfinished section
		revealOverviewOnEnter: true,
		isAccessible: (function () {
			// redirects to flow index if reached while inaccessable
			if (!this.get('sequenceEnforced') || _ember['default'].isNone(this.get('previousSection'))) {
				return true;
			}
			return this.get('previousSection.isAccessible') && this.get('previousSection.isComplete');
		}).property('sequenceEnforced', 'previousSection', 'previousSection.isAccessible', 'previousSection.isComplete'),

		// OVERRIDES - end

		steps: (function () {
			// ordered list of WizardStepState object
			return _ember['default'].A();
		}).property(),

		setSteps: function setSteps(steps) {
			steps.forEach(function (step) {
				step.set('section', this);
			}, this);
			this.set('steps', steps);

			if (_ember['default'].isNone(this.get('currentStepIndex'))) {
				// prevents recompute when branching
				this.set('currentStepIndex', this.get('firstIncompleteStep.index') || 0);
			}
		},

		stepNames: (function () {
			return this.get('steps').map(function (step) {
				return step.get("stepKey");
			});
		}).property('steps'),

		index: (function () {
			var flow = this.get('flow');
			if (flow && flow.get('sections')) {
				return this.get('flow.sections').indexOf(this);
			} else {
				return -1;
			}
		}).property('flow', 'flow.sections'),

		displayIndex: (function () {
			return this.get('index') + 1;
		}).property('index'),

		previousSection: (function () {
			var index = this.get('index');
			return index > 0 ? this.get('flow').getSectionAtIndex(index - 1) : null;
		}).property('index', 'flow.sections.@each'),

		nextSection: (function () {
			var index = this.get('index');
			return index < this.get('flow.sections.length') - 1 ? this.get('flow').getSectionAtIndex(index + 1) : null;
		}).property('index', 'flow.sections.@each'),

		isActive: (function () {
			return this.get('flow.currentSectionIndex') == this.get('index');
		}).property('flow.currentSectionIndex', 'index'),

		isFirst: _ember['default'].computed.equal('index', 0),
		isLast: (function () {
			return this.get('index') === this.get('flow.sections.length') - 1;
		}).property('index'),

		isComplete: (function () {
			var isComplete = true;
			this.get('steps').forEach(function (step) {
				isComplete = isComplete && step.get('isComplete');
			});
			return isComplete;
		}).property('steps.@each.isComplete'),

		stepsComplete: (function () {
			var stepsComplete = 0;
			this.get('steps').forEach(function (step) {
				stepsComplete += step.get('isComplete') ? 1 : 0;
			});
			return stepsComplete;
		}).property('steps.@each.isComplete'),

		stepKeysIncomplete: (function () {
			return this.get('steps').filterBy('isComplete', false).mapBy('stepKey');
		}).property('steps.@each.isComplete'),

		stepsHash: (function () {
			// stepKey to WizardStepState object
			var steps = this.get('steps');
			var stepsHash = {};
			steps.forEach(function (step) {
				stepsHash[step.get('stepKey')] = step;
			});
			return stepsHash;
		}).property('steps.@each.stepKey'),

		getStep: function getStep(stepKey) {
			return this.get('stepsHash')[stepKey];
		},

		getStepAtIndex: function getStepAtIndex(index) {
			return this.get('steps')[index];
		},

		currentStep: (function () {
			var steps = this.get('steps');
			var currentStepIndex = this.get('currentStepIndex');
			return steps[currentStepIndex];
		}).property('currentStepIndex', 'steps.@each'),

		nextStep: (function () {
			var steps = this.get('steps');
			var currentStepIndex = this.get('currentStepIndex');
			var nextStepIndex = currentStepIndex + 1;
			return nextStepIndex < steps.length ? steps[nextStepIndex] : null;
		}).property('currentStepIndex', 'steps.@each'),

		previousStep: (function () {
			var steps = this.get('steps');
			var currentStepIndex = this.get('currentStepIndex');
			var previousStepIndex = currentStepIndex - 1;
			return previousStepIndex >= 0 ? steps[previousStepIndex] : null;
		}).property('currentStepIndex', 'steps.@each'),

		firstStep: _ember['default'].computed.alias('steps.firstObject'),
		lastStep: _ember['default'].computed.alias('steps.lastObject'),

		firstIncompleteStep: (function () {
			var firstIncompleteStep = null;
			this.get('steps').forEach(function (step) {
				if (!step.get('isComplete') && step.get('isAccessible') && firstIncompleteStep == null) {
					firstIncompleteStep = step;
				}
			});
			return firstIncompleteStep;
		}).property('steps', 'steps.@each.isComplete'),

		stepsCompletionString: (function () {
			return this.get('steps').map(function (step) {
				return step.get('stepKey') + ':' + step.get('isComplete');
			}).join(',');
		}).property('steps.@each.stepKey', 'steps.@each.isComplete')

	});
});