define('component-library/utils/benefits/timeline/copy-for-verbal-description', ['exports', 'ember', 'ui-infra/utils/sanitize-html', 'component-library/utils/globals'], function (exports, _ember, _uiInfraUtilsSanitizeHtml, _componentLibraryUtilsGlobals) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['<span class="u-bold">', '</span>'], ['<span class="u-bold">', '</span>']),
	    _templateObject2 = _taggedTemplateLiteral(['An Admin, ', ','], ['An Admin, ', ',']),
	    _templateObject3 = _taggedTemplateLiteral(['Information about ', '’s ', ' coverage'], ['Information about ', '’s ', ' coverage']),
	    _templateObject4 = _taggedTemplateLiteral([' on the ', ' plan, ', ','], [' on the ', ' plan, ', ',']),
	    _templateObject5 = _taggedTemplateLiteral([' on the ', ' plan'], [' on the ', ' plan']),
	    _templateObject6 = _taggedTemplateLiteral([' on the plan, ', ''], [' on the plan, ', '']),
	    _templateObject7 = _taggedTemplateLiteral(['', '', '', '.'], ['', '', '', '.']),
	    _templateObject8 = _taggedTemplateLiteral(['', ''], ['', '']),
	    _templateObject9 = _taggedTemplateLiteral([' added ', ' to the ', ''], [' added ', ' to the ', '']),
	    _templateObject10 = _taggedTemplateLiteral([' ', ' plan, ', ''], [' ', ' plan, ', '']),
	    _templateObject11 = _taggedTemplateLiteral([' ', ' plan'], [' ', ' plan']),
	    _templateObject12 = _taggedTemplateLiteral([' plan, ', ''], [' plan, ', '']),
	    _templateObject13 = _taggedTemplateLiteral(['', ' completed their ', ' enrollment'], ['', ' completed their ', ' enrollment']),
	    _templateObject14 = _taggedTemplateLiteral([' in the ', ' plan, ', ''], [' in the ', ' plan, ', '']),
	    _templateObject15 = _taggedTemplateLiteral([' in the ', ' plan'], [' in the ', ' plan']),
	    _templateObject16 = _taggedTemplateLiteral([' in the plan, ', ''], [' in the plan, ', '']),
	    _templateObject17 = _taggedTemplateLiteral(['', '', '.'], ['', '', '.']),
	    _templateObject18 = _taggedTemplateLiteral(['', ' completed their application'], ['', ' completed their application']),
	    _templateObject19 = _taggedTemplateLiteral(['', ' completed ', '\'s application'], ['', ' completed ', '\'s application']),
	    _templateObject20 = _taggedTemplateLiteral([' for ', ' coverage for ', ' during open enrollment'], [' for ', ' coverage for ', ' during open enrollment']),
	    _templateObject21 = _taggedTemplateLiteral([', and chose the ', ' plan, ', ''], [', and chose the ', ' plan, ', '']),
	    _templateObject22 = _taggedTemplateLiteral([', and chose the ', ' plan'], [', and chose the ', ' plan']),
	    _templateObject23 = _taggedTemplateLiteral([', and chose the plan, ', ''], [', and chose the plan, ', '']),
	    _templateObject24 = _taggedTemplateLiteral([' for the ', ' plan, ', ''], [' for the ', ' plan, ', '']),
	    _templateObject25 = _taggedTemplateLiteral([' for the ', ' plan'], [' for the ', ' plan']),
	    _templateObject26 = _taggedTemplateLiteral([' for ', ' plan'], [' for ', ' plan']),
	    _templateObject27 = _taggedTemplateLiteral(['', ' added ', ' to the ', ''], ['', ' added ', ' to the ', '']),
	    _templateObject28 = _taggedTemplateLiteral(['Zenefits added ', ' to the ', ''], ['Zenefits added ', ' to the ', '']),
	    _templateObject29 = _taggedTemplateLiteral(['Zenefits switched insurance company for ', ''], ['Zenefits switched insurance company for ', '']),
	    _templateObject30 = _taggedTemplateLiteral(['', ' switched insurance company for ', ''], ['', ' switched insurance company for ', '']),
	    _templateObject31 = _taggedTemplateLiteral(['', ' submitted an application to switch insurance companies'], ['', ' submitted an application to switch insurance companies']),
	    _templateObject32 = _taggedTemplateLiteral([' from ', ' to ', ''], [' from ', ' to ', '']),
	    _templateObject33 = _taggedTemplateLiteral([' to ', ''], [' to ', '']),
	    _templateObject34 = _taggedTemplateLiteral([', ', ''], [', ', '']),
	    _templateObject35 = _taggedTemplateLiteral(['', '', ' on the ', ' plan', '.'], ['', '', ' on the ', ' plan', '.']),
	    _templateObject36 = _taggedTemplateLiteral([' ', '\'s'], [' ', '\'s']),
	    _templateObject37 = _taggedTemplateLiteral([' ', ''], [' ', '']),
	    _templateObject38 = _taggedTemplateLiteral(['', ' submitted an application to switch ', '', ' ', ' plans', '.'], ['', ' submitted an application to switch ', '', ' ', ' plans', '.']),
	    _templateObject39 = _taggedTemplateLiteral(['', ' submitted an application to change ', '', ' ', ' plans', '.'], ['', ' submitted an application to change ', '', ' ', ' plans', '.']),
	    _templateObject40 = _taggedTemplateLiteral(['Zenefits cancelled ', '\'s'], ['Zenefits cancelled ', '\'s']),
	    _templateObject41 = _taggedTemplateLiteral(['', ' cancelled ', '\'s'], ['', ' cancelled ', '\'s']),
	    _templateObject42 = _taggedTemplateLiteral(['', ' cancelled their'], ['', ' cancelled their']),
	    _templateObject43 = _taggedTemplateLiteral([' under the ', ' plan, ', ''], [' under the ', ' plan, ', '']),
	    _templateObject44 = _taggedTemplateLiteral([' under the ', ' plan'], [' under the ', ' plan']),
	    _templateObject45 = _taggedTemplateLiteral([' under the plan, ', ''], [' under the plan, ', '']),
	    _templateObject46 = _taggedTemplateLiteral(['', ' ', ' coverage', '.'], ['', ' ', ' coverage', '.']),
	    _templateObject47 = _taggedTemplateLiteral(['', ' declined ', ' coverage.'], ['', ' declined ', ' coverage.']),
	    _templateObject48 = _taggedTemplateLiteral(['', ' was terminated on ', '.'], ['', ' was terminated on ', '.']),
	    _templateObject49 = _taggedTemplateLiteral(['add dependents, ', ', to'], ['add dependents, ', ', to']),
	    _templateObject50 = _taggedTemplateLiteral(['add a dependent, ', ', to'], ['add a dependent, ', ', to']),
	    _templateObject51 = _taggedTemplateLiteral([' on the ', ' plan, ', ''], [' on the ', ' plan, ', '']),
	    _templateObject52 = _taggedTemplateLiteral(['', ' submitted an application to ', ' their ', ' coverage', '.'], ['', ' submitted an application to ', ' their ', ' coverage', '.']),
	    _templateObject53 = _taggedTemplateLiteral(['remove dependents, ', ', from'], ['remove dependents, ', ', from']),
	    _templateObject54 = _taggedTemplateLiteral(['remove a dependent, ', ', from'], ['remove a dependent, ', ', from']),
	    _templateObject55 = _taggedTemplateLiteral(['', ' changed ', '’s eligibility for ', ' coverage.'], ['', ' changed ', '’s eligibility for ', ' coverage.']),
	    _templateObject56 = _taggedTemplateLiteral(['Zenefits changed ', '’s eligibility for ', ' coverage.'], ['Zenefits changed ', '’s eligibility for ', ' coverage.']),
	    _templateObject57 = _taggedTemplateLiteral(['', '\'s eligibility for ', ' coverage was changed.'], ['', '\'s eligibility for ', ' coverage was changed.']),
	    _templateObject58 = _taggedTemplateLiteral(['', ' changed the ZIP code for their'], ['', ' changed the ZIP code for their']),
	    _templateObject59 = _taggedTemplateLiteral(['', ' changed the ZIP code for ', '\'s'], ['', ' changed the ZIP code for ', '\'s']),
	    _templateObject60 = _taggedTemplateLiteral(['Zenefits changed the ZIP code for ', '\'s'], ['Zenefits changed the ZIP code for ', '\'s']),
	    _templateObject61 = _taggedTemplateLiteral(['', ' home address from ', ' to ', ', which resulted in a change in their rates for ', ' benefits.'], ['', ' home address from ', ' to ', ', which resulted in a change in their rates for ', ' benefits.']),
	    _templateObject62 = _taggedTemplateLiteral(['', ' changed their'], ['', ' changed their']),
	    _templateObject63 = _taggedTemplateLiteral(['', ' changed ', '\'s'], ['', ' changed ', '\'s']),
	    _templateObject64 = _taggedTemplateLiteral(['Zenefits changed ', '\'s'], ['Zenefits changed ', '\'s']),
	    _templateObject65 = _taggedTemplateLiteral([' deduction from ', ' to ', ' per month'], [' deduction from ', ' to ', ' per month']),
	    _templateObject66 = _taggedTemplateLiteral([' company\'s contribution from ', ' to ', ' per month'], [' company\'s contribution from ', ' to ', ' per month']),
	    _templateObject67 = _taggedTemplateLiteral([' deduction to ', ' per month'], [' deduction to ', ' per month']),
	    _templateObject68 = _taggedTemplateLiteral([' company\'s contribution to ', ' per month'], [' company\'s contribution to ', ' per month']),
	    _templateObject69 = _taggedTemplateLiteral(['', ' ', ' ', ', and ', '.'], ['', ' ', ' ', ', and ', '.']),
	    _templateObject70 = _taggedTemplateLiteral(['', ' ', ' deduction and company contribution.'], ['', ' ', ' deduction and company contribution.']),
	    _templateObject71 = _taggedTemplateLiteral(['', ' changed employee, ', '\'s,'], ['', ' changed employee, ', '\'s,']),
	    _templateObject72 = _taggedTemplateLiteral(['Zenefits changed employee, ', '\'s,'], ['Zenefits changed employee, ', '\'s,']),
	    _templateObject73 = _taggedTemplateLiteral(['', '\'s'], ['', '\'s']),
	    _templateObject74 = _taggedTemplateLiteral(['', ' effective date', '', '.'], ['', ' effective date', '', '.']),
	    _templateObject75 = _taggedTemplateLiteral(['Information about ', '’s HSA coverage has been added to ', '. Zenefits is now company\'s benefits broker.'], ['Information about ', '’s HSA coverage has been added to ', '. Zenefits is now company\'s benefits broker.']),
	    _templateObject76 = _taggedTemplateLiteral(['deduction changed to ', ' per month'], ['deduction changed to ', ' per month']),
	    _templateObject77 = _taggedTemplateLiteral(['company\'s contribution changed to ', ' per month'], ['company\'s contribution changed to ', ' per month']),
	    _templateObject78 = _taggedTemplateLiteral(['', '\'s ', ' ', ', and ', '.'], ['', '\'s ', ' ', ', and ', '.']),
	    _templateObject79 = _taggedTemplateLiteral(['', '\'s ', ' deduction and/or company\'s contribution changed.\''], ['', '\'s ', ' deduction and/or company\'s contribution changed.\'']);

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	exports['default'] = function (action, data) {
		if (action in mapFromActionToGenerateFunction) {
			return mapFromActionToGenerateFunction[action](data);
		} else {
			return getDefaultCopy(data);
		}
	};

	var mapFromActionToGenerateFunction = {
		'initial_bor': getInitialBorCopy,
		'initial_enrollment': getInitialEnrollmentCopy,
		'renewal': getRenewalCopy,
		'short_circuit_open_enrollment': getRenewalCopy,
		'new_hire_enrollment': getNewHireEnrollmentCopy,
		'switch_carrier': getSwitchCarrierCopy,
		'switch_plan': getSwitchPlanCopy,
		'change_plan': getChangePlanCopy,
		'cancel_coverage': getCancelCoverageCopy,
		'decline_coverage': getDeclineCoverageCopy,
		'termination': getTerminationCopy,
		'add_dependent': getAddDependentCopy,
		'remove_dependent': getRemoveDependentCopy,
		'move_out_of_ft': getMoveOutOfFulltimeCopy,
		'move_to_ft': getMoveToFulltimeCopy,
		'address_Change': getAddressChangeCopy,
		'contribution_change': getContributionChangeCopy,
		'effective_date_change': getEffectiveDateChangeCopy
	};

	function _decorteEmployee(name) {
		if (_ember['default'].isEmpty(name)) {
			return 'employee';
		} else {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject, name);
		}
	}

	function _decorateAdmin(name) {
		if (_ember['default'].isEmpty(name)) {
			return 'An Admin';
		} else {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject2, name);
		}
	}

	function _decorateMoney(money) {
		if (_ember['default'].isEmpty(money)) {
			return null;
		} else {
			return (0, _componentLibraryUtilsGlobals.prettyCurrency)(money);
		}
	}

	function _decorateNames(names) {
		var length = names.get('length');
		if (length == 0) {
			return '';
		} else if (length == 1) {
			return names[0];
		} else {
			return names.slice(0, length - 1).join(', ') + ' and ' + names[length - 1];
		}
	}

	function getInitialBorCopy(data) {
		var _data$getProperties = data.getProperties('employeeName', 'lineOfCoverage', 'carrierName', 'planName');

		var employeeName = _data$getProperties.employeeName;
		var lineOfCoverage = _data$getProperties.lineOfCoverage;
		var carrierName = _data$getProperties.carrierName;
		var planName = _data$getProperties.planName;

		var employee = _decorteEmployee(employeeName);
		var segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject3, employee, lineOfCoverage);
		var segment2 = '';
		if (carrierName && planName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject4, carrierName, planName);
		} else if (carrierName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject5, carrierName);
		} else if (planName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject6, planName);
		}
		var segment3 = ' was added to ' + _componentLibraryUtilsGlobals.brandName + '. ' + _componentLibraryUtilsGlobals.brandName + ' is now company’s benefits broker.';
		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject7, segment1, segment2, segment3);
	}

	function getInitialEnrollmentCopy(data) {
		var _data$getProperties2 = data.getProperties('requesterCategory', 'requesterName', 'employeeName', 'lineOfCoverage', 'carrierName', 'planName');

		var requesterCategory = _data$getProperties2.requesterCategory;
		var requesterName = _data$getProperties2.requesterName;
		var employeeName = _data$getProperties2.employeeName;
		var lineOfCoverage = _data$getProperties2.lineOfCoverage;
		var carrierName = _data$getProperties2.carrierName;
		var planName = _data$getProperties2.planName;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		if (requesterCategory == 'admin' || requesterCategory == 'zenefits') {
			var segment1 = '' + _componentLibraryUtilsGlobals.brandName;
			if (requesterCategory == 'admin') {
				segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject8, admin);
			}
			var segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject9, employee, lineOfCoverage);
			var segment3 = ' plan';
			if (carrierName && planName) {
				segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject10, carrierName, planName);
			} else if (carrierName) {
				segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject11, carrierName);
			} else if (planName) {
				segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject12, planName);
			}
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject7, segment1, segment2, segment3);
		} else {
			var segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject13, employee, lineOfCoverage);
			var segment2 = '';
			if (carrierName && planName) {
				segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject14, carrierName, planName);
			} else if (carrierName) {
				segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject15, carrierName);
			} else if (planName) {
				segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject16, planName);
			}
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject17, segment1, segment2);
		}
	}

	function getRenewalCopy(data) {
		var _data$getProperties3 = data.getProperties('requesterCategory', 'requesterName', 'employeeName', 'lineOfCoverage', 'carrierName', 'planName');

		var requesterCategory = _data$getProperties3.requesterCategory;
		var requesterName = _data$getProperties3.requesterName;
		var employeeName = _data$getProperties3.employeeName;
		var lineOfCoverage = _data$getProperties3.lineOfCoverage;
		var carrierName = _data$getProperties3.carrierName;
		var planName = _data$getProperties3.planName;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		var segment1 = 'Zenefits submitted an application';
		if (requesterCategory == 'self') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject18, employee);
		} else if (requesterCategory == 'admin') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject19, admin, employee);
		}
		var segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject20, lineOfCoverage, employee);
		var segment3 = '';
		if (requesterCategory == 'self') {
			if (carrierName && planName) {
				segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject21, carrierName, planName);
			} else if (carrierName) {
				segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject22, carrierName);
			} else if (planName) {
				segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject23, planName);
			}
		} else {
			if (carrierName && planName) {
				segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject24, carrierName, planName);
			} else if (carrierName) {
				segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject25, carrierName);
			} else if (planName) {
				segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject26, planName);
			}
		}
		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject7, segment1, segment2, segment3);
	}

	function getNewHireEnrollmentCopy(data) {
		var _data$getProperties4 = data.getProperties('requesterCategory', 'requesterName', 'employeeName', 'lineOfCoverage', 'carrierName', 'planName');

		var requesterCategory = _data$getProperties4.requesterCategory;
		var requesterName = _data$getProperties4.requesterName;
		var employeeName = _data$getProperties4.employeeName;
		var lineOfCoverage = _data$getProperties4.lineOfCoverage;
		var carrierName = _data$getProperties4.carrierName;
		var planName = _data$getProperties4.planName;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		var segment1 = '';
		if (requesterCategory == 'admin') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject27, admin, employee, lineOfCoverage);
		} else if (requesterCategory == 'zenefits') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject28, employee, lineOfCoverage);
		} else {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject13, employee, lineOfCoverage);
		}
		var segment2 = '';
		if (requesterCategory in ['zenefits', 'admin']) {
			segment2 = ' plan';
			if (carrierName && planName) {
				segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject10, carrierName, planName);
			} else if (carrierName) {
				segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject11, carrierName);
			} else if (planName) {
				segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject12, planName);
			}
		} else {
			if (carrierName && planName) {
				segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject14, carrierName, planName);
			} else if (carrierName) {
				segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject15, carrierName);
			} else if (planName) {
				segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject16, planName);
			}
		}
		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject17, segment1, segment2);
	}

	function getSwitchCarrierCopy(data) {
		var _data$getProperties5 = data.getProperties('requesterCategory', 'requesterName', 'employeeName', 'lineOfCoverage', 'carrierName', 'planName', 'oldCarrierName');

		var requesterCategory = _data$getProperties5.requesterCategory;
		var requesterName = _data$getProperties5.requesterName;
		var employeeName = _data$getProperties5.employeeName;
		var lineOfCoverage = _data$getProperties5.lineOfCoverage;
		var carrierName = _data$getProperties5.carrierName;
		var planName = _data$getProperties5.planName;
		var oldCarrierName = _data$getProperties5.oldCarrierName;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		var segment1 = '';
		if (requesterCategory == 'zenefits') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject29, employee);
		} else if (requesterCategory == '') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject30, admin, employee);
		} else {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject31, employee);
		}
		var segment2 = '';
		if (oldCarrierName && carrierName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject32, oldCarrierName, carrierName);
		} else if (carrierName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject33, carrierName);
		}
		var segment3 = '';
		if (planName) {
			segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject34, planName);
		}
		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject35, segment1, segment2, lineOfCoverage, segment3);
	}

	function getSwitchPlanCopy(data) {
		var _data$getProperties6 = data.getProperties('requesterCategory', 'requesterName', 'employeeName', 'lineOfCoverage', 'carrierName', 'planName', 'oldPlanName');

		var requesterCategory = _data$getProperties6.requesterCategory;
		var requesterName = _data$getProperties6.requesterName;
		var employeeName = _data$getProperties6.employeeName;
		var lineOfCoverage = _data$getProperties6.lineOfCoverage;
		var carrierName = _data$getProperties6.carrierName;
		var planName = _data$getProperties6.planName;
		var oldPlanName = _data$getProperties6.oldPlanName;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		var segment1 = '';
		if (requesterCategory == 'zenefits') {
			segment1 = '' + _componentLibraryUtilsGlobals.brandName;
		} else if (requesterCategory == 'admin') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject8, admin);
		} else {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject8, employee);
		}
		var segment2 = '';
		if (requesterCategory in ['zenefits', 'admin']) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject36, employee);
		}
		var segment3 = '';
		if (carrierName) {
			segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject37, carrierName);
		}
		var segment4 = '';
		if (oldPlanName && planName) {
			segment4 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject32, oldPlanName, planName);
		} else if (planName) {
			segment4 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject33, planName);
		}

		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject38, segment1, segment2, segment3, lineOfCoverage, segment4);
	}

	function getChangePlanCopy(data) {
		var _data$getProperties7 = data.getProperties('requesterCategory', 'requesterName', 'employeeName', 'lineOfCoverage', 'carrierName', 'planName', 'oldPlanName');

		var requesterCategory = _data$getProperties7.requesterCategory;
		var requesterName = _data$getProperties7.requesterName;
		var employeeName = _data$getProperties7.employeeName;
		var lineOfCoverage = _data$getProperties7.lineOfCoverage;
		var carrierName = _data$getProperties7.carrierName;
		var planName = _data$getProperties7.planName;
		var oldPlanName = _data$getProperties7.oldPlanName;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		var segment1 = '';
		if (requesterCategory == 'zenefits') {
			segment1 = '' + _componentLibraryUtilsGlobals.brandName;
		} else if (requesterCategory == 'admin') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject8, admin);
		} else {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject8, employee);
		}
		var segment2 = '';
		if (requesterCategory in ['zenefits', 'admin']) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject36, employee);
		}
		var segment3 = '';
		if (carrierName) {
			segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject37, carrierName);
		}
		var segment4 = '';
		if (oldPlanName && planName) {
			segment4 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject32, oldPlanName, planName);
		} else if (planName) {
			segment4 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject33, planName);
		}

		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject39, segment1, segment2, segment3, lineOfCoverage, segment4);
	}

	function getCancelCoverageCopy(data) {
		var _data$getProperties8 = data.getProperties('requesterCategory', 'requesterName', 'employeeName', 'lineOfCoverage', 'oldCarrierName', 'oldPlanName');

		var requesterCategory = _data$getProperties8.requesterCategory;
		var requesterName = _data$getProperties8.requesterName;
		var employeeName = _data$getProperties8.employeeName;
		var lineOfCoverage = _data$getProperties8.lineOfCoverage;
		var oldCarrierName = _data$getProperties8.oldCarrierName;
		var oldPlanName = _data$getProperties8.oldPlanName;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		var segment1 = '';
		if (requesterCategory == 'zenefits') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject40, employee);
		} else if (requesterCategory == 'admin') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject41, admin, employee);
		} else {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject42, employee);
		}
		var segment2 = '';
		if (oldCarrierName && oldPlanName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject43, oldCarrierName, oldPlanName);
		} else if (oldCarrierName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject44, oldCarrierName);
		} else if (oldPlanName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject45, oldPlanName);
		}
		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject46, segment1, lineOfCoverage, segment2);
	}

	function getDeclineCoverageCopy(data) {
		var _data$getProperties9 = data.getProperties('employeeName', 'lineOfCoverage');

		var employeeName = _data$getProperties9.employeeName;
		var lineOfCoverage = _data$getProperties9.lineOfCoverage;

		var employee = _decorteEmployee(employeeName);
		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject47, employee, lineOfCoverage);
	}

	function getTerminationCopy(data) {
		var _data$getProperties10 = data.getProperties('employeeName', 'lineOfCoverage', 'effectiveDate');

		var employeeName = _data$getProperties10.employeeName;
		var lineOfCoverage = _data$getProperties10.lineOfCoverage;
		var effectiveDate = _data$getProperties10.effectiveDate;

		var employee = _decorteEmployee(employeeName);
		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject48, employee, effectiveDate);
	}

	function getAddDependentCopy(data) {
		var _data$getProperties11 = data.getProperties('requesterName', 'employeeName', 'lineOfCoverage', 'carrierName', 'planName', 'addedDependentsName');

		var requesterName = _data$getProperties11.requesterName;
		var employeeName = _data$getProperties11.employeeName;
		var lineOfCoverage = _data$getProperties11.lineOfCoverage;
		var carrierName = _data$getProperties11.carrierName;
		var planName = _data$getProperties11.planName;
		var addedDependentsName = _data$getProperties11.addedDependentsName;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		var segment1 = 'add dependen(s) to';
		if (addedDependentsName) {
			var names = _decorateNames(addedDependentsName);
			if (addedDependentsName.length > 1) {
				segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject49, names);
			} else {
				segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject50, names);
			}
		}
		var segment2 = '';
		if (carrierName && planName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject51, carrierName, planName);
		} else if (carrierName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject5, carrierName);
		} else if (planName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject6, planName);
		}
		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject52, employee, segment1, lineOfCoverage, segment2);
	}

	function getRemoveDependentCopy(data) {
		var _data$getProperties12 = data.getProperties('requesterName', 'employeeName', 'lineOfCoverage', 'carrierName', 'planName', 'removedDependentsName');

		var requesterName = _data$getProperties12.requesterName;
		var employeeName = _data$getProperties12.employeeName;
		var lineOfCoverage = _data$getProperties12.lineOfCoverage;
		var carrierName = _data$getProperties12.carrierName;
		var planName = _data$getProperties12.planName;
		var removedDependentsName = _data$getProperties12.removedDependentsName;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		var segment1 = 'remove dependent(s) from';
		if (removedDependentsName) {
			var names = _decorateNames(removedDependentsName);
			if (removedDependentsName.length > 1) {
				segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject53, names);
			} else {
				segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject54, names);
			}
		}
		var segment2 = '';
		if (carrierName && planName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject51, carrierName, planName);
		} else if (carrierName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject5, carrierName);
		} else if (planName) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject6, planName);
		}
		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject52, employee, segment1, lineOfCoverage, segment2);
	}

	function getMoveOutOfFulltimeCopy(data) {
		var _data$getProperties13 = data.getProperties('requesterCategory', 'requesterName', 'employeeName', 'lineOfCoverage');

		var requesterCategory = _data$getProperties13.requesterCategory;
		var requesterName = _data$getProperties13.requesterName;
		var employeeName = _data$getProperties13.employeeName;
		var lineOfCoverage = _data$getProperties13.lineOfCoverage;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		if (requesterCategory == 'admin') {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject55, admin, employee, lineOfCoverage);
		} else if (requesterCategory == 'zenefits') {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject56, employee, lineOfCoverage);
		} else {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject57, employee, lineOfCoverage);
		}
	}

	function getMoveToFulltimeCopy(data) {
		var _data$getProperties14 = data.getProperties('requesterCategory', 'requesterName', 'employeeName', 'lineOfCoverage');

		var requesterCategory = _data$getProperties14.requesterCategory;
		var requesterName = _data$getProperties14.requesterName;
		var employeeName = _data$getProperties14.employeeName;
		var lineOfCoverage = _data$getProperties14.lineOfCoverage;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		if (requesterCategory == 'admin') {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject55, admin, employee, lineOfCoverage);
		} else if (requesterCategory == 'zenefits') {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject56, employee, lineOfCoverage);
		} else {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject57, employee, lineOfCoverage);
		}
	}

	function getAddressChangeCopy(data) {
		var _data$getProperties15 = data.getProperties('requesterCategory', 'requesterName', 'employeeName', 'lineOfCoverage', 'zipCode', 'oldZipCode');

		var requesterCategory = _data$getProperties15.requesterCategory;
		var requesterName = _data$getProperties15.requesterName;
		var employeeName = _data$getProperties15.employeeName;
		var lineOfCoverage = _data$getProperties15.lineOfCoverage;
		var zipCode = _data$getProperties15.zipCode;
		var oldZipCode = _data$getProperties15.oldZipCode;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		var segment1 = '';
		if (requesterCategory == 'self') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject58, employee);
		} else if (requesterCategory == 'admin') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject59, admin, employee);
		} else {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject60, employee);
		}
		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject61, segment1, oldZipCode, zipCode, lineOfCoverage);
	}

	function getContributionChangeCopy(data) {
		var _data$getProperties16 = data.getProperties('requesterCategory', 'requesterName', 'employeeName', 'lineOfCoverage', 'contributionAmount', 'deductionAmount', 'oldContributionAmount', 'oldDeductionAmount');

		var requesterCategory = _data$getProperties16.requesterCategory;
		var requesterName = _data$getProperties16.requesterName;
		var employeeName = _data$getProperties16.employeeName;
		var lineOfCoverage = _data$getProperties16.lineOfCoverage;
		var contributionAmount = _data$getProperties16.contributionAmount;
		var deductionAmount = _data$getProperties16.deductionAmount;
		var oldContributionAmount = _data$getProperties16.oldContributionAmount;
		var oldDeductionAmount = _data$getProperties16.oldDeductionAmount;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		var oldContribution = _decorateMoney(oldContributionAmount);
		var oldDeduction = _decorateMoney(oldDeductionAmount);
		var deduction = _decorateMoney(deductionAmount);
		var contribution = _decorateMoney(contributionAmount);

		var segment1 = '';
		if (requesterCategory == 'self') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject62, employee);
		} else if (requesterCategory == 'admin') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject63, admin, employee);
		} else {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject64, employee);
		}
		var segment2 = null;
		var segment3 = null;
		if (!_ember['default'].isEmpty(oldContribution) && !_ember['default'].isEmpty(oldDeduction) && !_ember['default'].isEmpty(contribution) && !_ember['default'].isEmpty(deduction)) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject65, oldDeduction, deduction);
			segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject66, oldContribution, contribution);
		} else if (!_ember['default'].isEmpty(contribution) && !_ember['default'].isEmpty(deduction)) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject67, deduction);
			segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject68, contribution);
		}
		if (segment2 && segment3) {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject69, segment1, lineOfCoverage, segment2, segment3);
		} else {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject70, segment1, lineOfCoverage);
		}
	}

	function getEffectiveDateChangeCopy(data) {
		var _data$getProperties17 = data.getProperties('requesterCategory', 'requesterName', 'employeeName', 'effectiveDate', 'oldEffectiveDate');

		var requesterCategory = _data$getProperties17.requesterCategory;
		var requesterName = _data$getProperties17.requesterName;
		var employeeName = _data$getProperties17.employeeName;
		var effectiveDate = _data$getProperties17.effectiveDate;
		var oldEffectiveDate = _data$getProperties17.oldEffectiveDate;

		var employee = _decorteEmployee(employeeName);
		var admin = _decorateAdmin(requesterName);
		var segment1 = '';
		var segment2 = '';
		if (requesterCategory == 'admin') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject71, admin, employee);
		} else if (requesterCategory == 'zenefits') {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject72, employee);
		} else {
			segment1 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject73, employee);
			segment2 = ' was changed';
		}
		var segment3 = '';
		if (oldEffectiveDate && effectiveDate) {
			segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject32, oldEffectiveDate, effectiveDate);
		} else if (effectiveDate) {
			segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject33, effectiveDate);
		}
		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject74, segment1, segment2, segment3);
	}

	function getHsaFrontLoadingCopy(data) {
		var _data$getProperties18 = data.getProperties('employeeName');

		var employeeName = _data$getProperties18.employeeName;

		var employee = _decorteEmployee(employeeName);
		return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject75, employee, _componentLibraryUtilsGlobals.brandName);
	}

	function getDefaultCopy(data) {
		var _data$getProperties19 = data.getProperties('employeeName', 'lineOfCoverage', 'contributionAmount', 'deductionAmount');

		var employeeName = _data$getProperties19.employeeName;
		var lineOfCoverage = _data$getProperties19.lineOfCoverage;
		var contributionAmount = _data$getProperties19.contributionAmount;
		var deductionAmount = _data$getProperties19.deductionAmount;

		var employee = _decorteEmployee(employeeName);
		var deduction = _decorateMoney(deductionAmount);
		var contribution = _decorateMoney(contributionAmount);
		var segment2 = '';
		var segment3 = '';
		if (!_ember['default'].isEmpty(deduction)) {
			segment2 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject76, deduction);
		}
		if (!_ember['default'].isEmpty(contribution)) {
			segment3 = (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject77, contribution);
		}
		if (segment2 && segment3) {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject78, employee, lineOfCoverage, segment2, segment3);
		} else {
			return (0, _uiInfraUtilsSanitizeHtml['default'])(_templateObject79, employee, lineOfCoverage);
		}
	}
});