define('component-library/helpers/pretty-currency-with-zero-cents', ['exports', 'ember', 'component-library/utils/pretty-currency'], function (exports, _ember, _componentLibraryUtilsPrettyCurrency) {
	'use strict';

	var _slicedToArray = (function () {
		function sliceIterator(arr, i) {
			var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;_e = err;
			} finally {
				try {
					if (!_n && _i['return']) _i['return']();
				} finally {
					if (_d) throw _e;
				}
			}return _arr;
		}return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError('Invalid attempt to destructure non-iterable instance');
			}
		};
	})();

	/**
  * Use this like {{prettyCurrencyWithZeroCents anyNumber}} or {{prettyCurrencyWithZeroCents anyNumber default="-"}}
  * Returns $0.00, $1.00, $1.10, -$1.23, $123,456.00
  */
	exports['default'] = _ember['default'].HTMLBars.makeBoundHelper(function (_ref, hash) {
		var _ref2 = _slicedToArray(_ref, 1);

		var number = _ref2[0];

		//FIXME: The method signature indicates that there is one "number" argument, but the
		//code expects an optional second argument that handlebars will convert to an object hash.
		//We should change these to either have no arguments in the method signature and then
		//determine behavior depending on length of (and possibly types of each element in) of
		//the arguments, or accept one hash with optional key-value pairs.

		var defaultValue = hash['default'] || "";
		if (number === undefined || number === null) {
			return defaultValue;
		}
		return (0, _componentLibraryUtilsPrettyCurrency['default'])(number, true, hash.precision);
	});
});