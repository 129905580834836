define('component-library/simple-wizard/step/controller', ['exports', 'ember', 'component-library/mixins/wizard-validation-and-error'], function (exports, _ember, _componentLibraryMixinsWizardValidationAndError) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports['default'] = _ember['default'].Controller.extend(_componentLibraryMixinsWizardValidationAndError['default'], {
		errorScrollPadding: 100,

		_animateToErrorMessage: function _animateToErrorMessage() {
			var _this = this;

			_ember['default'].run.next(function () {
				var $bodyContainer = _ember['default'].$('.z-productBody-container');
				var $hasZInputError = _ember['default'].$('.has-error').first();

				if ($hasZInputError && $hasZInputError.length > 0) {
					$bodyContainer.animate({
						scrollTop: $bodyContainer.scrollTop() + $hasZInputError.offset().top - _this.get('errorScrollPadding') + 'px'
					}, 'fast');
				}
			});
		},

		// Default implementation. Can be overridden by sub-class for flows that are backend driven
		validateStep: function validateStep() {
			var _this2 = this;

			var propertiesToValidate = typeof this.get('propertiesToValidate') === 'undefined' ? [] : this.get('propertiesToValidate');
			var isValid = this.propertiesAreValid(propertiesToValidate);
			if (isValid) {
				var allSteps = this.get('simpleWizardFlow').steps;

				allSteps.forEach(function (step) {
					if (step.routeName == _this2.controllerFor('application').get('currentRouteName')) {
						step.set('isComplete', true);
					}
				});
			}

			return isValid;
		},

		_validateAndTransition: function _validateAndTransition(button, action, validate) {
			var _this3 = this;

			var actionArgs = arguments.length <= 3 || arguments[3] === undefined ? [] : arguments[3];

			return this.saveAndSyncBackendFlow(validate).then(function () {
				if (!validate) {
					if (button && button.stop) {
						button.stop();
					}
					_this3.send.apply(_this3, [action].concat(_toConsumableArray(actionArgs)));
					return _ember['default'].RSVP.resolve();
				}
				if (_this3.validateStep()) {
					_this3.send.apply(_this3, [action].concat(_toConsumableArray(actionArgs)));
				} else {
					if (button && button.stop) {
						button.stop();
					}
					_this3._animateToErrorMessage();
					// To capture the fact that backend flow did not accept the section changes
					return _ember['default'].RSVP.reject();
				}
				return _ember['default'].RSVP.resolve();
			});
		},

		// Default implementation. Overridden by sub-class for flows that are backend driven
		saveAndSyncBackendFlow: function saveAndSyncBackendFlow(validate) {
			return _ember['default'].RSVP.resolve();
		},

		// For backend managed flows
		goToNextStepPromise: function goToNextStepPromise() {
			return this._validateAndTransition(undefined, '_transitionToNextStep_action', true);
		},

		goToPreviousStepPromise: function goToPreviousStepPromise() {
			return this._validateAndTransition(undefined, '_transitionToPreviousStep_action', false);
		},

		goToNextIncompleteStepPromise: function goToNextIncompleteStepPromise() {
			return this._validateAndTransition(undefined, '_transitionToNextIncompleteStep_action', true, this.controllerFor('application').get('currentRouteName'));
		},

		actions: {
			goToNextIncompleteStep: function goToNextIncompleteStep(continueButton) {
				this.send('_goToStep', continueButton, '_transitionToNextIncompleteStep_action', true);
			},

			goToPreviousIncompleteStep: function goToPreviousIncompleteStep(backButton) {
				this.send('_goToStep', backButton, '_transitionToPreviousIncompleteStep_action', false);
			},

			goToNextStep: function goToNextStep(continueButton) {
				this.send('_goToStep', continueButton, '_transitionToNextStep_action', true);
			},

			goToStepRoute: function goToStepRoute(routeName) {
				this.send('_transitionToStepRoute_action', routeName);
			},

			goToPreviousStep: function goToPreviousStep(backButton) {
				this.send('_goToStep', backButton, '_transitionToPreviousStep_action', false);
			},

			_goToStep: function _goToStep(button, action, validate) {
				this._validateAndTransition(button, action, validate);
			}
		}
	});
});