define('component-library/deductions/review/single/controller', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Controller.extend({
		reviewStartDate: _ember['default'].computed.oneWay('reviewController.reviewStartDate'),
		reviewData: (function () {
			var deductionType = this.get('model');

			var reviewDataArray = this.get('detailsController.reviewDataArray');
			var promise = reviewDataArray.then(function (items) {
				return _ember['default'].Object.create(items.filterBy('deductionType', deductionType)[0] || {});
			});
			return _ember['default'].ObjectProxy.extend(_ember['default'].PromiseProxyMixin).create({
				promise: promise
			});
		}).property('model', 'detailsController.reviewDataArray')
	});
});