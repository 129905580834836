define('component-library/components/external-select', ['exports', 'ember', 'component-library/templates/components/external-select'], function (exports, _ember, _componentLibraryTemplatesComponentsExternalSelect) {
  'use strict';

  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;

  exports['default'] = _ember['default'].Component.extend({
    layout: _componentLibraryTemplatesComponentsExternalSelect['default'],
    // attrs
    /*
     * Value can be used to get the current selection's value or
     * set the selection by its value.
     * @default null
     */
    value: null,
    /*
     * The path of the option value. If not specified it defaults
     * to content. If the content is a list of object its it will
     * default to id.
     * see content
     * @default 'content'
     */
    optionValuePath: 'content',
    /*
     * The path of the option labels. If not specified it defaults
     * to content. If the content is a list of object its it will
     * default to name or description depending on the shape of the
     * object.
     * see content
     * @default 'content'
     */
    optionLabelPath: 'content',
    optionContentKey: '',
    /*
     * The list of options.
     * If optionLabelPath and optionValuePath are not overridden,
     * this should be a list of strings, which will serve simultaneously as labels and values.
     * Otherwise, this should be a list of objects. For instance:
     * ```
     *  content: [
     *     { id: 1, name: 'Miguel' },
     *     { id: 2, name: 'John' }
     *   ]
     * ```
     */
    content: computed('contentMap', 'optionContentKey', function () {
      if (this.get('optionContentKey')) {
        return this.get('contentMap')[this.get('optionContentKey')];
      }
      return [];
    }),
    contentTransformed: computed('content', function () {
      var content = this.get('content');
      if (!content) {
        content = [];
      }
      return _ember['default'].$.map(content, function (d, i) {
        return {
          id: i,
          data: d
        };
      });
    }),
    contentMap: computed(function () {
      return {};
    }),

    // props

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (isEmpty(this.get('content'))) {
        return;
      }
      var contentsFirstObject = this.get('content.firstObject');

      if (!this.attrs.optionValuePath && contentsFirstObject.hasOwnProperty('id')) {
        this.set('optionValuePath', 'content.id');
      }
      if (!this.attrs.optionLabelPath && contentsFirstObject.hasOwnProperty('name')) {
        this.set('optionLabelPath', 'content.name');
      } else if (!this.attrs.optionLabelPath && contentsFirstObject.hasOwnProperty('description')) {
        this.set('optionLabelPath', 'content.description');
      }
    }
  });
});