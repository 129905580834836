define('component-library/components/modal/modal-header', ['exports', 'ember', 'component-library/templates/components/modal/modal-header'], function (exports, _ember, _componentLibraryTemplatesComponentsModalModalHeader) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['d-z-modal-header', 'js-modal-header', 'z-typography'],
		template: _componentLibraryTemplatesComponentsModalModalHeader['default'],

		title: null,
		showClose: true,

		actions: {
			close: function close() {
				this.sendAction('close');
			}
		}
	});
});