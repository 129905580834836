define('component-library/filters/date-filter-spec', ['exports', 'ember', 'component-library/filters/helpers'], function (exports, _ember, _componentLibraryFiltersHelpers) {
  'use strict';

  var valueAsStringToIntOrUndefined = function valueAsStringToIntOrUndefined(valueString) {
    var value = _ember['default'].typeOf(valueString) === 'string' ? parseInt(valueString) : undefined;
    return isNaN(value) ? undefined : value;
  };

  exports['default'] = _ember['default'].Object.extend({
    isActive: false,
    filterId: undefined,
    name: "",
    // this is required for async
    valueTypeKey: undefined,
    valuePath: undefined,
    // The labelPath is relative to the valuePath
    labelPath: undefined,
    labelPaths: (function () {
      return [this.get('labelPath')];
    }).property('labelPath'),
    labelFunction: function labelFunction(value) {
      return value.get(this.get('labelPath'));
    },

    minValue: "",
    maxValue: "",

    optionsContent: undefined,

    setupDescriptionProperty: (function () {
      var dependentPaths = this.get('labelPaths').map(function (labelPath) {
        return 'selections.@each.' + labelPath;
      });
      this.propertyWillChange('description');
      _ember['default'].defineProperty(this, 'description', _ember['default'].computed.apply(null, dependentPaths.concat(function () {
        var _this = this;

        return this.descriptionFunction(this.get('selections').map(function (selection) {
          return _this.get('labelFunction')(selection);
        }));
      })));
      this.propertyDidChange('description');
    }).observes('labelPaths.[]', 'labelFunction').on('init'),

    descriptionFunction: function descriptionFunction(selectionDescriptions) {
      return selectionDescriptions.join(', ') || "(None)";
    },

    applyFunc: function applyFunc(key, property, valueTransform) {
      var minString = this.get('minValue');
      var maxString = this.get('maxValue');
      var minVal = valueAsStringToIntOrUndefined(minString);
      var maxVal = valueAsStringToIntOrUndefined(maxString);

      // Date filter: assume ISO8601 in the future.
      // If we use ISO8601 then we need to transform value into ISO8601. TODO(ed): must fix this

      // No filter is applied.
      if (minVal === undefined && maxVal === undefined) {
        return undefined;
      }

      var value = property;
      if (_ember['default'].typeOf(valueTransform) === 'function') {
        value = valueTransform.call(null, property);
      }
      if (minVal !== undefined && value < minVal) {
        return false;
      }
      if (maxVal !== undefined && value > maxVal) {
        return false;
      }
      return true;
    },

    setupFilterProperty: (function () {
      var valuePath = this.get('valuePath');
      var dependentPaths = ['minValue', 'maxValue'];
      var applyFunc = this.applyFunc.bind(this);
      this.propertyWillChange('filterProperty');
      _ember['default'].defineProperty(this, 'filterProperty', _ember['default'].computed.apply(null, dependentPaths.concat(function () {
        return [valuePath, applyFunc];
      })));
      this.propertyDidChange('filterProperty');
    }).observes('valuePath').on('init'),

    // TODO(ed): use an observer/computed on the 'options' property that updates selections based on the old options. otherwise selections
    // won't be updated when options change. this will be important for saved views
    // TODO(ed): the default options here takes from the content. otherwise this needs to be overridden with an array or promisearray
    setupOptions: (function () {
      var valuePath = this.get('valuePath');
      var dependentPaths = [valuePath].concat(this.get('labelPaths').map(function (labelPath) {
        return 'optionsContent.@each.' + valuePath + '.' + labelPath;
      }));
      this.propertyWillChange('options');
      _ember['default'].defineProperty(this, 'options', _ember['default'].computed.apply(null, dependentPaths.concat(function () {
        var _this2 = this;

        // TODO(ed): must fix these hacks. it needs to be generalized
        var labelPath = this.get('labelPath');
        var modelCountListOptions = {};
        // TODO(ed): must fix these hacks
        modelCountListOptions.optionLabel = labelPath;
        if (this.get('async')) {
          modelCountListOptions.modelKeyId = valuePath + '.id';
          modelCountListOptions.typeKey = this.get('valueTypeKey');
        }
        if (this.get('countMap')) {
          modelCountListOptions.countMap = this.get('countMap');
        }
        var data = (0, _componentLibraryFiltersHelpers.modelCountList)(valuePath, modelCountListOptions);

        // the sort function needs to be given. default one may account for nullAsId, but not sort order of null

        if (this.get('async')) {
          data.promise.then(function () {
            // TODO(ed): async... this is absolutely insane. There must be a better way.
            (0, _componentLibraryFiltersHelpers.updateModelSelectionsOnModelAndCountsDidChange)(_this2.get('options'), _this2.get('selections'));
            data.set('[]', data.sort(function (a, b) {
              return _ember['default'].compare(a.get('model.' + labelPath), b.get('model.' + labelPath));
            }));
          });
          return data;
        }

        return data.sort(function (a, b) {
          return _ember['default'].compare(a.get('model.' + labelPath), b.get('model.' + labelPath));
        });
      })));
      this.propertyDidChange('options');
    }).observes('valuePath', 'labelPaths.[]')

  });
});