define('component-library/components/z-sidebar-nav', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-sidebar-nav'],

		// array of objects with format {label: "Display Label", route: "path.to.route"}
		navItems: [],

		init: function init() {
			this._super.apply(this, arguments);
			// if items in array aren't Ember objects, convert them to be
			this.set('navItems', this.get('navItems').map(function (elt) {
				if (elt instanceof _ember['default'].Object) {
					return elt;
				} else {
					return _ember['default'].Object.create(elt);
				}
			}));
		},

		actions: {
			onNavItemClick: function onNavItemClick(route) {
				if (this.attrs.onClickAction) {
					this.attrs.onClickAction(route);
				}
			}
		}
	});
});