define("z-table/templates/components/z-data-manager", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.10-zenefits.3+18063622",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "modules/z-table/templates/components/z-data-manager.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "yield", [["subexpr", "hash", [], ["filteredItems", ["get", "filteredItems", ["loc", [null, [2, 15], [2, 28]]]], "filterDescriptor", ["get", "filterDescriptor", ["loc", [null, [3, 18], [3, 34]]]], "onFilterChange", ["subexpr", "action", ["onFilterChange"], [], ["loc", [null, [4, 16], [4, 41]]]], "sortedItems", ["get", "sortedItems", ["loc", [null, [6, 13], [6, 24]]]], "sortDescriptor", ["get", "sortDescriptor", ["loc", [null, [7, 16], [7, 30]]]], "onSortChange", ["subexpr", "action", ["onSortChange"], [], ["loc", [null, [8, 14], [8, 37]]]], "pagedItems", ["get", "pagedItems", ["loc", [null, [10, 12], [10, 22]]]], "pageDescriptor", ["get", "pageDescriptor", ["loc", [null, [11, 16], [11, 30]]]], "onPageChange", ["subexpr", "action", ["onPageChange"], [], ["loc", [null, [12, 14], [12, 37]]]]], ["loc", [null, [1, 8], [13, 1]]]]], [], ["loc", [null, [1, 0], [13, 3]]]]],
      locals: [],
      templates: []
    };
  })());
});