define('component-library/utils/squire-iframe-helper', ['exports', 'component-library/utils/globals'], function (exports, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = function (iframe, body) {
		// Make sure we're in standards mode.
		var doc = iframe.contentDocument;
		if (doc.compatMode !== 'CSS1Compat') {
			doc.open();
			doc.write('<!DOCTYPE html><title></title>');
			// doc.close() can cause a re-entrant load event in some browsers, such as IE9.
			doc.close();
		}

		var $iframe = (0, _componentLibraryUtilsGlobals.$)(iframe);
		var $head = $iframe.contents().find("head");
		$head.append((0, _componentLibraryUtilsGlobals.$)("<link/>", {
			rel: "stylesheet",
			href: "/static/css/squire-iframe.css",
			type: "text/css"
		}));

		if (body) {
			$iframe.contents().find("body").html(body);
		}

		return iframe;
	};
});