define('component-library/components/radio-block-boolean', ['exports', 'ember', 'component-library/templates/components/radio-block-boolean'], function (exports, _ember, _componentLibraryTemplatesComponentsRadioBlockBoolean) {
	'use strict';

	var _SIZES_TO_CLASS;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
		} else {
			obj[key] = value;
		}return obj;
	}

	// SIZES:
	var SMALL = 'small';
	var MEDIUM = 'medium';
	var LARGE = 'large';

	var SIZES_TO_CLASS = (_SIZES_TO_CLASS = {}, _defineProperty(_SIZES_TO_CLASS, SMALL, 'radio-block-boolean-wrapper--sm'), _defineProperty(_SIZES_TO_CLASS, MEDIUM, 'radio-block-boolean-wrapper--md'), _defineProperty(_SIZES_TO_CLASS, LARGE, 'radio-block-boolean-wrapper--lg'), _SIZES_TO_CLASS);

	exports['default'] = _ember['default'].Component.extend({
		template: _componentLibraryTemplatesComponentsRadioBlockBoolean['default'],
		showLabel: true,

		selectedValue: null,
		isDisabled: false,

		yesValue: true,
		noValue: false,

		noIconClass: 'fa-times',
		yesIconClass: 'fa-check',
		noLabelText: 'NO',
		yesLabelText: 'YES',
		size: LARGE,

		didInsertElement: function didInsertElement() {
			this._super();

			// hide label on size small
			if (this.get('size') !== LARGE) {
				this.set('showLabel', false);
			}
		},

		_sizeClass: (function () {
			var size = this.get('size');
			if (!(size in SIZES_TO_CLASS)) {
				console.warn('Invalid Radio Block Boolean Size');
				return '';
			}
			return SIZES_TO_CLASS[size];
		}).property('size')
	});
});