define('component-library/components/payroll-timeline', ['exports', 'component-library/timeline/component', 'component-library/timeline/utils/types'], function (exports, _componentLibraryTimelineComponent, _componentLibraryTimelineUtilsTypes) {
	'use strict';

	var payrollTypeList = ['DepartmentChange', 'EmployeeTermination', 'EmploymentTypeChange', 'HireDateChange', 'LocationChange', 'ManagerChange', 'NewHire', 'SalaryChange', 'TitleChange'];

	exports.payrollTypeList = payrollTypeList;

	// Deductions is behind switch(feed_deductions) and disabled by default
	// 'Deduction',
	exports['default'] = _componentLibraryTimelineComponent['default'].extend({

		title: 'Payroll Timeline',
		// Deduction is behind switch(feed_dedctions)
		entryTypes: (function () {
			var filteredList = this.get('switches.feed_deductions') ? payrollTypeList.concat(['Deduction']) : payrollTypeList;
			return (0, _componentLibraryTimelineUtilsTypes.getEntryTypeFilters)(filteredList);
		}).property('switches.feed_deductions')

	});
});