define('z-address/components/z-address', ['exports', 'ember', 'z-inputs/components/z-text-field-base', 'z-address/mixins/address-mixin', 'z-address/templates/z-address'], function (exports, _ember, _zInputsComponentsZTextFieldBase, _zAddressMixinsAddressMixin, _zAddressTemplatesZAddress) {
  /* globals requirejs */
  'use strict';

  // TODO: move to a helper
  function ajaxPromise(settings) {
    return new _ember['default'].RSVP.Promise(function (resolve, reject) {
      _ember['default'].$.ajax(settings).done(function (data) {
        resolve(data);
      }).fail(function (xhr) {
        reject(xhr);
      });
    });
  }

  var FIELD_MAPPING = {
    street_number: {
      type: 'short_name',
      key: 'addressStreet'
    },
    route: {
      type: 'short_name',
      key: 'addressStreet'
    },
    locality: {
      type: 'long_name',
      key: 'city'
    },
    administrative_area_level_1: {
      type: 'short_name',
      key: 'state'
    },
    administrative_area_level_2: {
      type: 'short_name',
      key: 'county'
    },
    postal_code: {
      type: 'short_name',
      key: 'zipCode'
    }
  };

  exports['default'] = _zInputsComponentsZTextFieldBase['default'].extend(_zAddressMixinsAddressMixin['default'], {
    layout: _zAddressTemplatesZAddress['default'],
    classNames: ['z-address', 'grid-block', 'vertical', 'u-flexContainer', 'u-overflowHidden'],
    classNameBindings: ['errorText:hasError', 'isDisabled:is-disabled'],
    country: null,
    city: null,
    zipCode: null,
    state: null,
    aptSuite: null,
    latitude: null,
    longitude: null,
    type: 'address',
    maskOptions: {
      showMaskOnFocus: false,
      showMaskOnHover: false
    },
    street: null,
    enableAutocomplete: true,
    includeSanctionedCountries: false,
    isDisabled: false,
    tooltipText: null,
    validate: false,
    uspsAddress: {},
    prevStreet: null,
    prevCity: null,
    prevState: null,
    prevZipCode: null,
    showUspsAddress: _ember['default'].computed.bool('uspsAddress.address'),
    stateDropdownOptions: _ember['default'].computed(function () {
      return [];
    }),

    // International address is currently only supported in company settings
    // See details in https://github.com/zenefits/yourPeople3/pull/7551
    showCountry: false,
    isCountryDisabled: _ember['default'].computed.or('isDisabled', 'countryDisabled'),
    isStateDisabled: _ember['default'].computed.or('isDisabled', 'stateDisabled'),

    // Most of our backend doesn't support street2 yet. Also we need to move from
    // Apartment to street2 format when we support it
    hideApartment: false,

    didInsertElement: function didInsertElement() {
      this._super();

      var input = this.$('.js-z-textField-input').get(0);

      if (window.google && window.google.maps && this.get('enableAutocomplete')) {
        var options = { types: ['geocode'] };
        if (!this.get('showCountry')) {
          // if country is not visible, results must be limited to the US only
          options['componentRestrictions'] = { country: "us" };
        }
        var autocomplete = new window.google.maps.places.Autocomplete(input, options);
        window.google.maps.event.addListener(autocomplete, 'place_changed', _ember['default'].run.next(this, this.fillInPhysicalAddress));
        this.set('autocomplete', autocomplete);
      } else {
        this.set('enableAutocomplete', false);
      }
    },

    willDestroyElement: function willDestroyElement() {
      if (window.google && window.google.maps && this.get('enableAutocomplete')) {
        window.google.maps.event.clearListeners(this.get('autocomplete'), 'place_changed');
      }
    },

    fillInPhysicalAddress: function fillInPhysicalAddress() {
      var _this = this;

      var autocomplete = this.get('autocomplete');
      var place = autocomplete.getPlace();
      // sometimes place is empty
      if (!place) {
        return;
      }

      var street = '';
      if (place.address_components) {
        place.address_components.forEach(function (component) {
          var addressType = component.types[0];
          var field = FIELD_MAPPING[addressType];
          //sometimes field is undefined
          if (!field) {
            return;
          }
          if (addressType === 'street_number' || addressType === 'route') {
            street += component[field.type] + ' ';
          } else {
            _this.set(field.key, component[field.type]);
          }
        });
      }

      this.setProperties({
        street: street.trim(),
        errorText: null
      });
      this._debouncedValidationFn();
    },

    _hasPowerSelect: _ember['default'].computed(function () {
      return !!requirejs._eak_seen["ember-power-select/components/power-select"];
    }),

    _hasAddressChanged: function _hasAddressChanged() {
      return this.get('street') != this.get('prevStreet') || this.get('city') != this.get('prevCity') || this.get('state') != this.get('prevState') || this.get('zipCode') != this.get('prevZipCode');
    },

    _shouldRunValidation: function _shouldRunValidation() {
      return this.get('validate') && !this.get('isInternational') && this.get('street') && this.get('city') && this.get('state') && this.get('zipCode') && this._hasAddressChanged();
    },

    _logEvent: function _logEvent(eventName) {
      var properties = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      if (this.get('eventLogger')) {
        this.get('eventLogger').log(eventName, properties);
      }
    },

    _runValidation: function _runValidation() {
      var _this2 = this;

      if (this._shouldRunValidation()) {
        var street = this.get('street');
        var city = this.get('city') || '';
        var state = this.get('state') || '';
        var zipCode = this.get('zipCode') || '';

        this._clearUspsAddress();
        this.setProperties({
          errorText: '',
          prevStreet: street,
          prevCity: city,
          prevState: state,
          prevZipCode: zipCode
        });
        var data = JSON.stringify({
          streetAddress: street,
          streetAddress2: '',
          city: city,
          state: state,
          zipCode: zipCode,
          companyName: ''
        });
        ajaxPromise({
          type: 'POST',
          url: '/custom_api/validateAddressUsingUSPS',
          data: data,
          dataType: 'json'
        }).then(function (result) {
          var address = result.address;
          if (result.status == 'failed') {
            _this2.set('errorText', result.comment);
            _this2._logEvent('z-address-usps-validation-failed');
          } else if (!address) {
            _this2.set('errorText', 'Address not found in USPS record');
            _this2._logEvent('z-address-usps-validation-not-found');
          } else if (address.address.toLowerCase() != street.toLowerCase() || address.city.toLowerCase() != city.toLowerCase() || address.state.toLowerCase() != state.toLowerCase() || address.zip != zipCode) {
            _this2._setUspsAddress(address);
            _this2._logEvent('z-address-usps-validation-found-suggestion');
          } else {
            _this2._logEvent('z-address-usps-validation-found-match');
          }
        });
      }
    },

    _debouncedValidationFn: function _debouncedValidationFn() {
      _ember['default'].run.debounce(this, '_runValidation', 500);
    },

    _acceptAddressSuggestion: function _acceptAddressSuggestion() {
      var uspsAddress = this.get('uspsAddress');
      this.setProperties({
        street: uspsAddress.address,
        city: uspsAddress.city,
        state: uspsAddress.state,
        zipCode: uspsAddress.zip,
        errorText: null,
        uspsAddress: {}
      });
    },

    _setUspsAddress: function _setUspsAddress(uspsAddress) {
      this.set('uspsAddress', uspsAddress);
    },

    _clearUspsAddress: function _clearUspsAddress() {
      this.set('uspsAddress', {});
    },

    onCountryChangeObserver: _ember['default'].observer('country', function () {
      if (!this.get('_hasPowerSelect')) {
        this.resetCountryRelatedProps(this.get('country'));
      }
    }),

    resetCountryRelatedProps: function resetCountryRelatedProps(country) {
      if (typeof country === "string") {
        this.setProperties({
          country: country,
          errorText: '',
          state: null,
          zipCode: null,
          city: null
        });
      } else if (country) {
        // '_hasPowerSelect passes an countryObject'
        this.setProperties({
          country: country.code,
          countryObject: country,
          errorText: '',
          state: null,
          zipCode: null,
          city: null
        });
      }
    },

    actions: {
      useNewAddress: function useNewAddress() {
        this._acceptAddressSuggestion();
        this._logEvent('z-address-use-usps-suggested-address');
      },

      keepAddress: function keepAddress() {
        this._clearUspsAddress();
        this._logEvent('z-address-keep-address-as-typed');
      },

      addressFieldOnBlur: function addressFieldOnBlur() {
        this._debouncedValidationFn();
      },

      onStateChange: function onStateChange(value) {
        this._debouncedValidationFn();
        this.set('state', value);
      },

      onCountryChange: function onCountryChange(country) {
        this.resetCountryRelatedProps(country);
      }
    }
  });
});