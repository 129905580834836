define('component-library/components/z-external-link', ['exports', 'ember', 'component-library/components/confirmation-modal'], function (exports, _ember, _componentLibraryComponentsConfirmationModal) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		url: null,
		className: '',
		tagName: "",
		actions: {
			openLink: function openLink() {
				var _this = this;

				if (!this.url.includes(window.location.host)) {
					_componentLibraryComponentsConfirmationModal['default'].show({
						title: "You're Leaving TriNet",
						message: "You are now leaving TriNet and opening a third-party site.",
						ctaText: "Continue"
					}).then(function () {
						window.open(_this.url);
					});
				}
			}
		}
	});
});