define('component-library/components/z-settings-navigation-section', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-settingsWizardNavigationSection', 'u-flexContainer', 'u-flexStart'],
		classNameBindings: ['wizardSectionState.isComplete:is-complete', 'wizardSectionState.isActive:is-active', 'wizardSectionState.isAccessible::is-disabled', 'isExpanded:is-expanded'],

		isExpanded: false,
		wizardSectionState: null,

		click: function click() {
			this.attrs.click();
		}
	});
});