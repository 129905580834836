define('z-table/components/z-pager', ['exports', 'ember', 'z-table/templates/components/z-pager'], function (exports, _ember, _zTableTemplatesComponentsZPager) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    layout: _zTableTemplatesComponentsZPager['default'],
    currentPage: null,
    totalPages: null,
    onPageChange: function onPageChange() {},

    isFirstPage: computed('currentPage', function () {
      return parseInt(this.get('currentPage')) === 1;
    }),

    isFinalPage: computed('currentPage', 'totalPages', function () {
      return parseInt(this.get('currentPage')) === parseInt(this.get('totalPages'));
    }),

    actions: {
      onPageBackwards: function onPageBackwards() {
        this.get('onPageChange')(parseInt(this.get('currentPage')) - 1);
      },

      onPageForwards: function onPageForwards() {
        this.get('onPageChange')(parseInt(this.get('currentPage')) + 1);
      }
    }
  });
});