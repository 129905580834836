define('component-library/lazy-eval-templates', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var LazyEvalTemplates = _ember['default'].Mixin.create({
		resolveTemplate: function resolveTemplate(parsedName) {
			var template = this._super(parsedName);

			if (typeof template === 'string') {
				var templateName = parsedName.fullNameWithoutType.replace(/\./g, '/');
				template = eval(template); // eslint-disable-line no-eval
				_ember['default'].TEMPLATES[templateName] = template;
			}

			return template;
		}
	});

	exports['default'] = LazyEvalTemplates;
});