define('component-library/mixins/truncate-lines', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		truncateLinesDefault: 3,
		truncate: function truncate(elements, lineNumber) {
			var _truncateLinesDefault = this.get('truncateLinesDefault');
			_ember['default'].run.scheduleOnce('afterRender', this, function () {
				elements.each(function (i, el) {
					var clampTextAtLine = lineNumber || _truncateLinesDefault;
					el.setAttribute('title', _ember['default'].$(el).text().trim());
					(0, _componentLibraryUtilsGlobals.$clamp)(el, { clamp: clampTextAtLine });
				});
			});
		}
	});
});