define('component-library/mixins/payroll/prior-payroll-mixin', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		currentYear: _ember['default'].computed(function () {
			return (0, _componentLibraryUtilsGlobals.moment)().format('YYYY');
		}),

		questionsHelpText: _ember['default'].computed(function () {
			return new _ember['default'].Handlebars.SafeString('Have questions?<br><a href="https://help.zenefits.com/Payroll/Get_Started_With_Zenefits_Payroll/Importing_Payroll_History_for_Zenefits_Payroll/03-Entering_Payroll_History_to_Zenefits_Payroll" target="_blank" rel="noopener noreferrer">Visit our FAQs.</a>');
		}),

		payTypeHelpText: _ember['default'].computed(function () {
			return new _ember['default'].Handlebars.SafeString('Need to add an employee?<br><a href="/dashboard/#/payroll/pending-tasks/employee-onboarding">Let’s go do that.</a>');
		}),

		addEmployeehHelpText: _ember['default'].computed(function () {
			return new _ember['default'].Handlebars.SafeString('Are you missing a pay type? <a href="/dashboard/#/payroll/settings/pay-types">Let’s go add it now.</a>');
		}),

		contactSupportHelpText: _ember['default'].computed(function () {
			return new _ember['default'].Handlebars.SafeString('Get an error message asking you to contact support?<br><a href="/help/redirect/contactsupport" target="_blank" rel="noopener noreferrer">Go here.</a>');
		}),

		generateMomentFormat: function generateMomentFormat(partialDate) {
			return (0, _componentLibraryUtilsGlobals.moment)('' + partialDate + this.get('currentYear'), _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		},

		getQuarterStartDate: function getQuarterStartDate(quarter) {
			return ({
				1: this.generateMomentFormat('01/01/'),
				2: this.generateMomentFormat('04/01/'),
				3: this.generateMomentFormat('07/01/'),
				4: this.generateMomentFormat('10/01/')
			})[quarter];
		},

		getQuarterEndDate: function getQuarterEndDate(quarter) {
			return ({
				1: this.generateMomentFormat('03/31/'),
				2: this.generateMomentFormat('06/30/'),
				3: this.generateMomentFormat('09/30/'),
				4: this.generateMomentFormat('12/31/')
			})[quarter];
		},

		currentQuarter: _ember['default'].computed(function () {
			return (0, _componentLibraryUtilsGlobals.moment)().quarter();
		}),

		firstZPCheckDateFromPaySchedules: _ember['default'].computed('paySchedules', function () {
			var paySchedules = this.get('paySchedules');
			var initValue = undefined;
			return paySchedules.reduce(function (firstZPCheckDate, thisPaySchedule) {
				var thisCheckDate = (0, _componentLibraryUtilsGlobals.moment)(thisPaySchedule.get('shiftedAnchorCheckDate'));
				if (!firstZPCheckDate) return thisCheckDate;
				return firstZPCheckDate.isBefore(thisCheckDate) ? firstZPCheckDate : thisCheckDate;
			}, initValue);
		}),

		// Returns the quarter when client has their first payroll run with PYP.
		// Thus the quarter could be either a present or a future quarter.
		currentEffectiveQuarter: _ember['default'].computed('firstZPCheckDateFromPaySchedules', function () {
			var firstZPCheckDate = this.get('firstZPCheckDateFromPaySchedules');
			return firstZPCheckDate ? firstZPCheckDate.quarter() : this.get('currentQuarter');
		}),

		startOfYear: _ember['default'].computed(function () {
			return this.getQuarterStartDate(1);
		}),

		endOfYear: _ember['default'].computed(function () {
			return this.getQuarterEndDate(4);
		}),

		previousQuarterChecks: _ember['default'].computed('firstQuarterCheck.date', 'secondQuarterCheck.date', 'thirdQuarterCheck.@each.date', function () {
			return [this.get('firstQuarterCheck'), this.get('secondQuarterCheck'), this.get('thirdQuarterCheck')];
		}),

		// Currently used by Console facing prior payroll download page
		// Allows multiple selection of multiple check dates for past quarter
		pastQuarterChecks: _ember['default'].computed('firstQuarterChecks.@each.date', 'secondQuarterChecks.@each.date', 'thirdQuarterChecks.@each.date', function () {
			return this.get('firstQuarterChecks').concat(this.get('secondQuarterChecks')).concat(this.get('thirdQuarterChecks'));
		}),

		allQuartersCheckDates: _ember['default'].computed('pastQuarterChecks.@each.date', 'currentQuarterChecks.@each.date', function () {
			var allChecks = this.get('currentQuarterChecks').concat(this.get('pastQuarterChecks'));
			return allChecks.reject(function (check) {
				return _ember['default'].isEmpty(check.get('date'));
			});
		}),

		allCheckDates: _ember['default'].computed('previousQuarterChecks.@each.date', 'currentQuarterChecks.@each.date', function () {
			var allChecks = this.get('currentQuarterChecks').concat(this.get('previousQuarterChecks'));
			return allChecks.reject(function (check) {
				return _ember['default'].isEmpty(check.get('date'));
			});
		}),

		spreadsheetFileName: _ember['default'].computed('company.id', function () {
			return 'PriorPayroll_SpreadSheet_' + this.get('company.id') + '.xlsx';
		}),

		isDateWithinRange: function isDateWithinRange(check, startDate, endDate) {
			var date = (0, _componentLibraryUtilsGlobals.moment)(check);
			if (_ember['default'].isEmpty(check)) {
				return true;
			}
			return date.isBetween(startDate, endDate, 'days', '[]') || date.isSame(startDate) || date.isSame(endDate);
		},

		validateDatesWithinCurrentQuarter: function validateDatesWithinCurrentQuarter() {
			var _this = this;

			var currentQuarter = this.get('currentEffectiveQuarter');
			var currentQuarterChecks = this.get('currentQuarterChecks');
			var startOfCurrentQuarter = this.getQuarterStartDate(currentQuarter);
			var endOfCurrentQuarter = this.getQuarterEndDate(currentQuarter);

			return this.get('currentQuarterChecks').every(function (check) {
				return _this.isDateWithinRange(check.get('date'), startOfCurrentQuarter, endOfCurrentQuarter);
			});
		},

		validateAllQuarterDatesNotEmpty: function validateAllQuarterDatesNotEmpty() {
			return !this.get('allCheckDates').every(function (check) {
				return _ember['default'].isEmpty(check.get('date'));
			});
		},

		validateDateWithinFirstQuarter: function validateDateWithinFirstQuarter() {
			var date = this.get('firstQuarterCheck.date');
			var startOfQ1 = this.getQuarterStartDate(1);
			var endOfQ1 = this.getQuarterEndDate(1);
			return this.isDateWithinRange(date, startOfQ1, endOfQ1);
		},

		validateDateWithinSecondQuarter: function validateDateWithinSecondQuarter() {
			var date = this.get('secondQuarterCheck.date');
			var startOfQ2 = this.getQuarterStartDate(2);
			var endOfQ2 = this.getQuarterEndDate(2);
			return this.isDateWithinRange(date, startOfQ2, endOfQ2);
		},

		validateDateWithinThirdQuarter: function validateDateWithinThirdQuarter() {
			var date = this.get('thirdQuarterCheck.date');
			var startOfQ3 = this.getQuarterStartDate(3);
			var endOfQ3 = this.getQuarterEndDate(3);
			return this.isDateWithinRange(date, startOfQ3, endOfQ3);
		},

		validateDatesWithinQuarter: function validateDatesWithinQuarter(quarter, quarterChecks) {
			var _this2 = this;

			return quarterChecks.every(function (check) {
				return _this2.isDateWithinRange(check.get('date'), _this2.getQuarterStartDate(quarter), _this2.getQuarterEndDate(quarter));
			});
		},

		validateAllQuarterDatesNotEmptyV2: function validateAllQuarterDatesNotEmptyV2() {
			return !this.get('allQuartersCheckDates').every(function (check) {
				return _ember['default'].isEmpty(check.get('date'));
			});
		},

		generateRandomString: function generateRandomString(length) {
			var text = "";
			var possibleCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
			for (var i = 0; i < length; i++) {
				text += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
			}
			return text;
		},

		parseSpreadsheetValidationErrors: function parseSpreadsheetValidationErrors(validationErrors) {
			var validationArray = validationErrors.errors.trim().replace(/\n/g, '<br>').split('|');
			return validationArray.map(function (error) {
				return new _ember['default'].Handlebars.SafeString(error);
			});
		},

		parseSpreadsheetValidationWarnings: function parseSpreadsheetValidationWarnings(validationWarnings) {
			var validationArray = validationWarnings.warnings.trim().replace(/\n/g, '<br>').split('|');
			return validationArray.map(function (warning) {
				return new _ember['default'].Handlebars.SafeString(warning);
			});
		},

		actions: {
			addCurrentDateField: function addCurrentDateField() {
				if (!this.get('exceedsCurrentQuarterCheckInputLimit')) {
					this.get('currentQuarterChecks').pushObject(_ember['default'].Object.create({ date: null }));
				}
			},

			deleteCheckField: function deleteCheckField(index) {
				if (this.get('numCurrentQuarterChecks') > 1) {
					this.get('currentQuarterChecks').removeAt(index);
				}
			},

			addQuarterDateField: function addQuarterDateField(quarterChecks, isExceedsCheckInputLimit) {
				if (!this.get(isExceedsCheckInputLimit)) {
					this.get(quarterChecks).pushObject(_ember['default'].Object.create({ date: null }));
				}
			},

			deleteQuarterField: function deleteQuarterField(index, numQuarterChecks, quarterChecks) {
				if (this.get(numQuarterChecks) > 1) {
					this.get(quarterChecks).removeAt(index);
				}
			}
		}
	});
});