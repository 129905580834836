define('z-signature/components/z-signature', ['exports', 'ember', 'z-signature/templates/components/z-signature'], function (exports, _ember, _zSignatureTemplatesComponentsZSignature) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    signatureComplete: null,
    initialSignatureImage: null,

    layout: _zSignatureTemplatesComponentsZSignature['default'],
    // TODO(Andrew) BEM the class names
    classNames: ['z-signature', 'js-z-signature'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (_ember['default'].isEmpty(this.get('signatureComplete'))) {
        _ember['default'].assert('The signatureComplete parameter is required. The component itself will not save any signature. This needs to be handled in the signatureComplete action.');
      }
    },

    _isShowingSignatureModal: false,
    _signatureData: null,

    click: function click() {
      this.send('openSignatureModal');
    },

    actions: {
      openSignatureModal: function openSignatureModal() {
        this.set('_isShowingSignatureModal', true);
      },
      close: function close() {
        this.set('_isShowingSignatureModal', false);
      },
      updateSignatureData: function updateSignatureData(_signatureData) {
        this.set('_signatureData', _signatureData);
      },
      saveAndClose: function saveAndClose() {
        var _signatureData = this.get('_signatureData') || {};
        if (_signatureData.signatureIsValid) {
          this.sendAction('signatureComplete', _signatureData);
          this.send('close');
          this.set('_errorText', null);
        } else {
          this.set('_errorText', 'The signature is too small. Please try again.');
        }
      }
    }
  });
});