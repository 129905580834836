define('ember-table/views/table-row', ['exports', 'ember', 'ember-table/mixins/register-table-component', 'ember-table/views/lazy-item'], function (exports, _ember, _emberTableMixinsRegisterTableComponent, _emberTableViewsLazyItem) {
  'use strict';

  exports['default'] = _emberTableViewsLazyItem['default'].extend(_emberTableMixinsRegisterTableComponent['default'], {
    templateName: 'table-row',
    classNames: 'et-table-row',
    classNameBindings: ['row.isHovered:et-hover', 'row.isSelected:et-is-selected', 'row.rowStyle', 'isLastRow:et-last-row'],
    styleBindings: ['width', 'height'],
    row: _ember['default'].computed.alias('content'),
    columns: _ember['default'].computed.alias('parentView.columns'),
    width: _ember['default'].computed.alias('tableComponent._rowWidth'),
    height: _ember['default'].computed.alias('tableComponent.rowHeight'),

    // TODO(ppong): Why doesn't this apply to the table footer?
    isLastRow: _ember['default'].computed(function () {
      return this.get('row') === this.get('tableComponent.bodyContent.lastObject');
    }).property('tableComponent.bodyContent.lastObject', 'row'),

    // TODO(azirbel): Could simplify slightly via
    // this.set('row.isHovered', true) and remove the temp variable.
    // Also applies below/elsewhere.
    mouseEnter: function mouseEnter() {
      var row = this.get('row');
      if (row) {
        row.set('isHovered', true);
      }
    },

    mouseLeave: function mouseLeave() {
      var row = this.get('row');
      if (row) {
        row.set('isHovered', false);
      }
    },

    teardownContent: function teardownContent() {
      var row = this.get('row');
      if (row) {
        row.set('isHovered', false);
      }
    }
  });
});