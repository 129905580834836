define('component-library/deductions/review/single/route', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Route.extend({
		model: function model(params) {
			var deductionType = params.deduction_type;
			return new _ember['default'].RSVP.Promise(function (resolve) {
				_ember['default'].run.later(function () {
					resolve(deductionType);
				}, 0);
			});
		},

		setupController: function setupController(controller, model) {
			this._super(controller, model);
			this.controllerFor(this.routeName.split('.').slice(0, -1).join('.')).set('selectedDeductionType', model);
		}
	});
});