define('component-library/components/radial-progress', ['exports', 'ember', 'component-library/templates/components/radial-progress'], function (exports, _ember, _componentLibraryTemplatesComponentsRadialProgress) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		template: _componentLibraryTemplatesComponentsRadialProgress['default'],
		_displayedProgressPct: 0,

		progressPct: 0,
		size: 'medium',

		didInsertElement: function didInsertElement() {
			var progressPct = this.get('_intProgressPct');

			this.set('_displayedProgressPct', 0);

			//Set with delay to allow animation
			_ember['default'].run.later(this, 'setDisplayedProgressPct', progressPct, 200);
		},

		willDestroyElement: function willDestroyElement() {
			this.set('_displayedProgressPct', 0);
		},

		setDisplayedProgressPct: function setDisplayedProgressPct(progressPct) {
			if (this.get('isDestroyed')) {
				return;
			}

			this.set('_displayedProgressPct', progressPct);
		},

		_intProgressPct: (function () {
			var progressPct = this.get('progressPct');

			if (progressPct < 0) {
				return 0;
			}

			if (progressPct > 100) {
				return 100;
			}

			return Math.floor(progressPct);
		}).property('progressPct')
	});
});