define('component-library/data-table/cells/link-table-cell', ['exports', 'ember', 'component-library/components/notification-modal', 'ember-table/views/table-cell'], function (exports, _ember, _componentLibraryComponentsNotificationModal, _emberTableViewsTableCell) {
	'use strict';

	exports['default'] = _emberTableViewsTableCell['default'].extend({
		templateName: 'data-table/cells/link-table-cell',

		click: function click(event) {
			event.stopPropagation();
		},
		actions: {
			getActionText: function getActionText(messageId) {
				_componentLibraryComponentsNotificationModal['default'].show({
					notificationType: 'warning',
					title: 'Resend Event',
					message: 'Clicking Ok will resend the event with messageId: ' + messageId,
					showCancelButton: true
				}).then(function () {
					_ember['default'].ajaxPost('/zevents/messages/' + messageId + '/resend', {}).then(function (response) {
						if (!response || response['status_code'] !== 200) {
							_componentLibraryComponentsNotificationModal['default'].show({
								notificationType: 'error',
								title: 'Event resend failed',
								message: 'Try again in sometime.'
							});
							return;
						}
						_componentLibraryComponentsNotificationModal['default'].show({
							notificationType: 'success',
							title: 'Successfuly Resent'
						});
					});
				});
			}
		}
	});
});