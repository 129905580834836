define('component-library/components/single-select-filter', ['exports', 'ember', 'component-library/templates/components/single-select-filter', 'component-library/components/promise-popover'], function (exports, _ember, _componentLibraryTemplatesComponentsSingleSelectFilter, _componentLibraryComponentsPromisePopover) {
  'use strict';

  exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend({
    template: _componentLibraryTemplatesComponentsSingleSelectFilter['default'],
    hasArrow: true,
    placement: 'bottom',
    target: _ember['default'].computed.oneWay('model.target'),
    attachment: "top center",
    targetAttachment: "bottom center",

    filter: _ember['default'].computed.alias('content'),

    filterRadioName: (function () {
      return 'radio-' + this.get('filter.filterId');
    }).property('filter.filterId'),

    newSelection: null,
    initFilterValue: (function () {
      this.set('newSelection', this.get('filter.selection'));
    }).on('init'),

    addOrApply: (function () {
      return this.get('filter.isActive') ? 'Apply' : 'Add';
    }).property('filter.isActive'),

    actions: {
      addOrApplyFilter: function addOrApplyFilter() {
        if (!this.get('filter.isActive')) {
          this.set('filter.isActive', true);
        }
        this.set('filter.selection', this.get('newSelection'));
        this.send('close');
      }
    }
  });
});