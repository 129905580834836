define("component-library/templates/components/z-status-tag/status-popover", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.10-zenefits.3+18063622",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 14,
                    "column": 5
                  },
                  "end": {
                    "line": 20,
                    "column": 5
                  }
                },
                "moduleName": "modules/component-library/templates/components/z-status-tag/status-popover.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("						");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "grid-block status-popover-button-block");
                var el2 = dom.createTextNode("\n							");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2, "class", "btn btn--xs u-fillHorizontal u-rightFlush u-leftFlush u-topFlush js-glue-z-status-tag-button");
                var el3 = dom.createTextNode("\n								");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n							");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n						");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1, 1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element0);
                morphs[1] = dom.createMorphAt(element0, 1, 1);
                return morphs;
              },
              statements: [["element", "action", ["onClick", ["get", "button.action", ["loc", [null, [16, 135], [16, 148]]]]], [], ["loc", [null, [16, 116], [16, 150]]]], ["content", "button.label", ["loc", [null, [17, 8], [17, 24]]]]],
              locals: ["button"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.10-zenefits.3+18063622",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 3
                },
                "end": {
                  "line": 22,
                  "column": 3
                }
              },
              "moduleName": "modules/component-library/templates/components/z-status-tag/status-popover.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("				");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "grid-block status-popover-buttons");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("				");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "buttonRow", ["loc", [null, [14, 13], [14, 22]]]]], [], 0, null, ["loc", [null, [14, 5], [20, 14]]]]],
            locals: ["buttonRow"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.10-zenefits.3+18063622",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 1
              },
              "end": {
                "line": 24,
                "column": 1
              }
            },
            "moduleName": "modules/component-library/templates/components/z-status-tag/status-popover.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "u-padChildrenVertical--sm u-bumperBottom");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("		");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "buttonRows", ["loc", [null, [12, 11], [12, 21]]]]], [], 0, null, ["loc", [null, [12, 3], [22, 12]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.10-zenefits.3+18063622",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 25,
              "column": 0
            }
          },
          "moduleName": "modules/component-library/templates/components/z-status-tag/status-popover.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "grid-block");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "grid-content u-textLeft");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3, "class", "u-bottomFlush u-topFlush");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1, 1]), 0, 0);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["content", "content.message", ["loc", [null, [7, 39], [7, 60]]]], ["block", "if", [["get", "content.buttons.length", ["loc", [null, [10, 7], [10, 29]]]]], [], 0, null, ["loc", [null, [10, 1], [24, 8]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.10-zenefits.3+18063622",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 26,
            "column": 0
          }
        },
        "moduleName": "modules/component-library/templates/components/z-status-tag/status-popover.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "z-paper-container", [], ["depth", 3, "classNames", ["subexpr", "@mut", [["get", "statusPopoverClassNamesComputed", ["loc", [null, [3, 12], [3, 43]]]]], [], []]], 0, null, ["loc", [null, [1, 0], [25, 22]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});