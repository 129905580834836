define('component-library/components/benefits/timeline/transaction', ['exports', 'ember', 'component-library/utils/globals', 'component-library/constants/benefits', 'lodash/lodash'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryConstantsBenefits, _lodashLodash) {
	'use strict';

	var computedPropertyForJSON = function computedPropertyForJSON() {
		for (var _len = arguments.length, propertyNames = Array(_len), _key = 0; _key < _len; _key++) {
			propertyNames[_key] = arguments[_key];
		}

		return _ember['default'].computed.apply(_ember['default'], propertyNames.concat([function () {
			var _this = this;

			var values = propertyNames.map(function (propertyName) {
				return _this.get(propertyName);
			});
			return JSON.parse(_lodashLodash['default'].find(values) || null);
		}]));
	};

	exports['default'] = _ember['default'].Object.extend({

		benefitsApp: _ember['default'].computed('benefitsType', function () {
			var appId = _componentLibraryConstantsBenefits.benefitsTypes.findBy('text', this.get('benefitsType')).get('appId');
			return _componentLibraryConstantsBenefits.benefitsApps.findBy('id', appId).get('text');
		}),

		isActive: _ember['default'].computed.empty('deactivationDate'),

		momentOfCreationDate: _ember['default'].computed('creationDate', function () {
			return (0, _componentLibraryUtilsGlobals.moment)(this.get('creationDate')).tz('America/Los_Angeles');
		}),

		momentOfEffectiveDate: _ember['default'].computed('effectiveDate', function () {
			return (0, _componentLibraryUtilsGlobals.moment)(this.get('effectiveDate'), _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
		}),

		deductionAmount: _ember['default'].computed('employeeDeduction', 'dependentsDeduction', function () {
			return (this.get('employeeDeduction') || 0) + (this.get('dependentsDeduction') || 0);
		}),

		contributionAmount: _ember['default'].computed('employeeContribution', 'dependentsContribution', function () {
			return (this.get('employeeContribution') || 0) + (this.get('dependentsContribution') || 0);
		}),

		jsonDataOfContext: computedPropertyForJSON('benefitsContext', '_benefitsContext'),

		_jsonDataOfCost: computedPropertyForJSON('benefitsCost'),

		_jsonDataOfContribution: computedPropertyForJSON('contributionBreakdown'),

		jsonDataOfCost: _ember['default'].computed('_jsonDataOfCost', 'employeePlanCost', 'dependentsPlanCost', 'employeeRiderCost', 'dependentsRiderCost', function () {
			var _data = this.get('_jsonDataOfCost');
			if (_data) {
				return _data;
			} else {
				return {
					'employeePlanCost': this.get('employeePlanCost'),
					'employeeRidersCost': this.get('employeeRidersCost'),
					'dependentsPlanCost': this.get('dependentsPlanCost'),
					'dependentsRidersCost': this.get('dependentsRiderCost')
				};
			}
		}),

		jsonDataOfContribution: _ember['default'].computed('_jsonDataOfContribution', 'benefitsType', 'employeeDeduction', 'employeeContribution', 'dependentsDeduction', 'dependentsContribution', 'isDeductionAsPercentage', 'isContributionAsPercentage', 'maxAnnualDeduction', 'maxAnnualContribution', function () {
			// TODO: define insurance and flex types and use them
			// Or we should persist contributionBreakdown for flexBenefits as well
			var app = this.get('benefitsApp');
			var _data = this.get('_jsonDataOfContribution');
			if (_data) {
				return _data;
			} else {
				var data = {};
				data['employeeDeduction'] = this.get('employeeDeduction');
				data['employeeContribution'] = this.get('employeeContribution');
				if (app == 'MDV' || app == 'LIFE') {
					data['dependentsDeduction'] = this.get('dependentsDeduction');
					data['dependentsContribution'] = this.get('dependentsContribution');
				}
				if (app == 'FSA' || app == 'HSA' || app == 'RETIREMENT') {
					data['maxAnnualDeduction'] = this.get('maxAnnualDeduction');
					data['maxAnnualContribution'] = this.get('maxAnnualContribution');
				}
				if (app == 'RETIREMENT') {
					data['isDeductionAsPercentage'] = this.get('isDeductionAsPercentage');
					data['isContributionAsPercentage'] = this.get('isContributionAsPercentage');
				}
				return data;
			}
		}),

		changeRelationship: _ember['default'].computed('initiativeRelationships.@each.name', function () {
			return (this.get('initiativeRelationships') || []).findBy('name', 'change');
		}),

		jsonDataOfContextChange: computedPropertyForJSON('changeRelationship.contextChange'),
		jsonDataOfCostChange: computedPropertyForJSON('changeRelationship.costChange'),
		jsonDataOfContributionChange: computedPropertyForJSON('changeRelationship.contributionChange'),
		jsonDataOfEffectiveDateChange: computedPropertyForJSON('changeRelationship.effectiveDateChange'),

		// isInitial means that it is first transaction of employee and lineOfCoverage,
		// NOTE: ideally, this property should depends on event
		isInitial: _ember['default'].computed.not('changeRelationship'),

		isContextChangeError: _ember['default'].computed.alias('changeRelationship.contextChangeError'),
		isCostChangeError: _ember['default'].computed.alias('changeRelationship.costChangeError'),
		isContributionChangeError: _ember['default'].computed.alias('changeRelationship.contributionChangeError')
	});
});