define('component-library/utils/payment-method', ['exports'], function (exports) {
	'use strict';

	var BILLING_METHODS = {
		CREDIT_CARD: 'stripe',
		BANK: 'ach'
	};

	exports.BILLING_METHODS = BILLING_METHODS;

	// keys are possible return values from Stripe's API for card funding type
	var STRIPE_FUNDING_MAP = {
		credit: 'Credit',
		debit: 'Debit',
		prepaid: 'Prepaid',
		unknown: 'Unknown'
	};

	exports.STRIPE_FUNDING_MAP = STRIPE_FUNDING_MAP;

	var getFundingTypeLabel = function getFundingTypeLabel(funding) {
		var fundingType = funding || STRIPE_FUNDING_MAP.unknown;
		// if unknown, do not show it in the UI
		return fundingType === STRIPE_FUNDING_MAP.unknown ? '' : STRIPE_FUNDING_MAP[fundingType];
	};
	exports.getFundingTypeLabel = getFundingTypeLabel;
});