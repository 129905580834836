define('ember-table/views/lazy-table-block', ['exports', 'ember', 'ember-table/mixins/register-table-component', 'ember-table/views/lazy-container'], function (exports, _ember, _emberTableMixinsRegisterTableComponent, _emberTableViewsLazyContainer) {
  'use strict';

  exports['default'] = _emberTableViewsLazyContainer['default'].extend(_emberTableMixinsRegisterTableComponent['default'], {
    classNames: ['et-table-block'],
    styleBindings: ['width'],
    itemViewClass: _ember['default'].computed.alias('tableComponent.tableRowViewClass'),
    rowHeight: _ember['default'].computed.alias('tableComponent.rowHeight'),
    columns: null,
    content: null,
    scrollLeft: null,
    scrollTop: null,

    onScrollLeftDidChange: _ember['default'].observer(function () {
      return this.$().scrollLeft(this.get('scrollLeft'));
    }).observes('scrollLeft')
  });
});