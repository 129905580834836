define('component-library/mixins/shift-table-column-mixin', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		column: _ember['default'].computed.oneWay('content.column'),
		columns: _ember['default'].computed.oneWay('content.tableComponent.columns'),

		canMoveLeft: _ember['default'].computed('columns', 'columns.@each', 'column', function () {
			var column = this.get('column');
			var columns = this.get('columns');
			var index = columns.indexOf(column);
			return index > 0;
		}),

		canMoveRight: _ember['default'].computed('columns', 'columns.@each', 'column', function () {
			var column = this.get('column');
			var columns = this.get('columns');
			var index = columns.indexOf(column);
			return index < columns.get('length') - 1;
		}),

		actions: {
			moveColumnLeft: function moveColumnLeft() {
				var column = this.get('column');
				var columns = this.get('columns');
				var index = columns.indexOf(column);
				columns.removeObject(column);
				columns.insertAt(index - 1, column);
				this.send('close');
			},

			moveColumnRight: function moveColumnRight() {
				var column = this.get('column');
				var columns = this.get('columns');
				var index = columns.indexOf(column);
				columns.removeObject(column);
				columns.insertAt(index + 1, column);
				this.send('close');
			}
		}
	});
});