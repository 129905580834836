define('component-library/wizard/flow/index/controller', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Controller.extend({
		wizardFlowState: _ember['default'].computed.alias('model.wizardFlowState'),

		hasAnyProgress: (function () {
			var wizardFlowState = this.get('wizardFlowState');
			return wizardFlowState.get('stepsComplete') > 0;
		}).property('wizardFlowState', 'wizardFlowState.stepsComplete'),

		//Override if you want other text in the button
		transitionButtonText: (function () {
			var wizardFlowState = this.get('wizardFlowState'),
			    flowComplete = wizardFlowState.get('flowComplete'),
			    stepsComplete = wizardFlowState.get('stepsComplete'),
			    transitionButtonText = "Get Started";
			if (flowComplete) {
				transitionButtonText = "Finish";
			} else if (stepsComplete > 0) {
				transitionButtonText = "Continue";
			}
			return transitionButtonText;
		}).property('wizardFlowState.stepsComplete', 'wizardFlowState.flowComplete'),

		actions: {
			transitionToSection: function transitionToSection(section) {
				this.send('_transitionToFirstIncompleteStep_sectionAction', section.get('sectionKey'));
			}
		}
	});
});