define('component-library/utils/pretty-percent', ['exports'], function (exports) {
	'use strict';

	exports['default'] = function (number, decimalPoint) {
		var value = +number;
		if (decimalPoint === true) {
			return (value * 100.0).toFixed(2) + '%';
		}
		return value * 100.0 + '%';
	};
});