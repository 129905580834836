define('component-library/filters/range-filter-spec', ['exports', 'ember', 'component-library/components/number-range-filter', 'component-library/components/date-range-filter', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryComponentsNumberRangeFilter, _componentLibraryComponentsDateRangeFilter, _componentLibraryUtilsGlobals) {
  'use strict';

  exports['default'] = _ember['default'].Object.extend({
    popoverClass: (function () {
      switch (this.get('formatAs')) {
        case 'date':
          return _componentLibraryComponentsDateRangeFilter['default'];
        default:
          return _componentLibraryComponentsNumberRangeFilter['default'];
      }
    }).property('formatAs'),

    isActive: false,
    isHidden: false,
    isSerializable: true,
    filterId: undefined,
    name: "",
    allSerializedSelections: {},

    // 'number', 'float', 'date', or 'datetime'
    // If formatAs == 'date', the minValue and maxValue objects are expected to be moment objects.
    // TODO(ed): 'money' (value with $), 'percent'. That will not work if we need to dynamically format
    // individual moneyValues with different currencies. Otherwise, the label function can be overridden.
    formatAs: 'number',
    // Set this to true when you are filtering (specifically, backend filtering) on datetime values using formatAs === 'date'.
    datetimeToDateComparison: false,

    valuePath: undefined,
    // The labelPath is relative to the valuePath.
    // TODO(ed): Do we actually need this for a number?
    labelPath: '',
    labelPaths: (function () {
      return [this.get('labelPath')];
    }).property('labelPath'),
    labelFunction: (function (value) {
      var _this = this;

      return function (value) {
        var labelValue = _ember['default'].get(value, _this.get('labelPath'));
        if (labelValue === null) {
          return labelValue;
        }
        switch (_this.get('formatAs')) {
          case 'date':
            return (_componentLibraryUtilsGlobals.moment.isMoment(labelValue) ? labelValue : (0, _componentLibraryUtilsGlobals.moment)(labelValue)).format('l');
          case 'datetime':
            return (_componentLibraryUtilsGlobals.moment.isMoment(labelValue) ? labelValue : (0, _componentLibraryUtilsGlobals.moment)(labelValue)).format('lll');
          default:
            return labelValue;
        }
      };
    }).property(),

    queryValuePath: undefined,

    // The number filter components should convert these to numbers or moment objects.
    minValue: null,
    maxValue: null,

    setupDescriptionProperty: (function () {
      var dependentPaths = ['labelFunction', 'minValue', 'maxValue', 'formatAs'];
      dependentPaths.push.apply(dependentPaths, this.get('labelPaths').map(function (labelPath) {
        return 'minValue.' + labelPath;
      }));
      dependentPaths.push.apply(dependentPaths, this.get('labelPaths').map(function (labelPath) {
        return 'maxValue.' + labelPath;
      }));
      this.propertyWillChange('description');
      _ember['default'].defineProperty(this, 'description', _ember['default'].computed.apply(null, dependentPaths.concat(function () {
        var minLabel = this.get('labelFunction')(this.get('minValue'));
        var maxLabel = this.get('labelFunction')(this.get('maxValue'));
        return this.descriptionFunction(minLabel, maxLabel);
      })));
      this.propertyDidChange('description');
    }).observes('labelPaths.[]').on('init'),

    descriptionFunction: function descriptionFunction(minLabel, maxLabel) {
      if (minLabel == null && maxLabel == null) {
        return "(None)";
      } else if (minLabel == null) {
        return "<= " + maxLabel;
      } else if (maxLabel == null) {
        return ">= " + minLabel;
      } else {
        return ">= " + minLabel + ", <= " + maxLabel;
      }
    },

    applyFuncHelper: function applyFuncHelper(key, property, valueTransform) {
      var minVal = this.get('minValue');
      var maxVal = this.get('maxValue');

      // No filter is applied.
      if (minVal === null && maxVal === null) {
        return true;
      }

      var value = property;
      if (_ember['default'].typeOf(valueTransform) === 'function') {
        value = valueTransform.call(null, property);
      }
      var formatAs = this.get('formatAs');
      // TODO(ed): This could be really inefficient for date/datetime...
      // If this causes performance issues, the objects passed into this filter
      // should have a computed moment conversion property so the moment
      // is only calculated once per object.
      // For datetime, it would be best to have a computed for +value.
      if (formatAs === 'date') {
        value = _componentLibraryUtilsGlobals.moment.isMoment(value) ? value : (0, _componentLibraryUtilsGlobals.moment)(value);
        if (minVal !== null && value.isBefore(minVal, 'day')) {
          return false;
        }
        if (maxVal !== null && value.isAfter(maxVal, 'day')) {
          return false;
        }
        return true;
      } else if (formatAs === 'datetime') {
        value = _componentLibraryUtilsGlobals.moment.isMoment(value) ? +value : +(0, _componentLibraryUtilsGlobals.moment)(value);
        minVal = minVal && +minVal;
        maxVal = maxVal && +maxVal;
      }

      if (minVal !== null && value < minVal) {
        return false;
      }
      if (maxVal !== null && value > maxVal) {
        return false;
      }
      return true;
    },

    applyFunc: (function (key, property, valueTransform) {
      var _this2 = this;

      return function (element) {
        return _this2.applyFuncHelper(undefined, _ember['default'].get(element, _this2.get('valuePath')));
      };
    }).property('valuePath', 'minValue', 'maxValue', 'formatAs'),

    filterProperty: (function () {
      var valuePath = this.get('valuePath');
      var applyFuncHelper = this.applyFuncHelper.bind(this);
      return [valuePath, applyFuncHelper];
    }).property('valuePath', 'applyFunc'),

    query: (function () {
      var query = {};
      var formatAs = this.get('formatAs');
      var valuePath = this.get('queryValuePath') || this.get('valuePath');
      var minVal = this.get('minValue');
      var maxVal = this.get('maxValue');

      if (formatAs === 'date') {
        if (this.get('datetimeToDateComparison')) {
          minVal = minVal && minVal.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
          maxVal = maxVal && maxVal.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
        } else {
          minVal = minVal && minVal.format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
          maxVal = maxVal && maxVal.format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
        }
      } else if (formatAs === 'datetime') {
        minVal = minVal && minVal.format();
        maxVal = maxVal && maxVal.format();
      }

      if (minVal !== null) {
        query[valuePath + '__gte'] = minVal;
      }
      if (maxVal !== null) {
        query[valuePath + '__lte'] = maxVal;
      }
      return query;
    }).property('queryValuePath', 'valuePath', 'minValue', 'maxValue', 'formatAs', 'datetimeToDateComparison', 'updateSelection'),

    serialize: function serialize() {
      var value = [];
      var formatAs = this.get('formatAs');
      var minVal = this.get('minValue');
      var maxVal = this.get('maxValue');

      if (formatAs === 'date') {
        if (this.get('datetimeToDateComparison')) {
          minVal = minVal && minVal.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
          maxVal = maxVal && maxVal.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
        } else {
          minVal = minVal && minVal.format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
          maxVal = maxVal && maxVal.format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
        }
      } else if (formatAs === 'datetime') {
        minVal = minVal && minVal.format();
        maxVal = maxVal && maxVal.format();
      }

      if (minVal !== null) {
        value.push("l" + minVal);
      }
      if (maxVal !== null) {
        value.push("g" + maxVal);
      }
      return value;
    },

    deserialize: function deserialize(serializedSelections) {
      var _this3 = this;

      return serializedSelections.reduce(function (options, value) {
        var val = value.substring(1);
        if (_this3.get('formatAs') === 'date') {
          val = (0, _componentLibraryUtilsGlobals.moment)(val, _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT, true);
        }
        if (value[0] == 'g') {
          options.maxVal = val;
        } else {
          options.minVal = val;
        }
        return options;
      }, {});
    },

    updateSelection: (function () {
      var allSerializedSelections = this.get('allSerializedSelections');
      var filterId = this.get('filterId');
      if (allSerializedSelections.hasOwnProperty(filterId)) {
        var serializedSelections = allSerializedSelections[filterId];
        var options = this.deserialize(serializedSelections);
        this.set('minValue', options.minVal ? options.minVal : null);
        this.set('maxValue', options.maxVal ? options.maxVal : null);
        this.set('isActive', true);
      }
    }).observes('allSerializedSelections')

  });
});