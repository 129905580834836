define('component-library/data-table/cells/data-table-cell', ['exports', 'ember', 'ember-table/views/table-cell'], function (exports, _ember, _emberTableViewsTableCell) {
	'use strict';

	exports['default'] = _emberTableViewsTableCell['default'].extend({
		templateName: 'data-table/cells/data-table-cell',
		classNames: ['z-dataTableCell'],
		classNameBindings: ['verticallyCenterContent:u-flexContainer', 'verticallyCenterContent:u-flexCenter'],
		isTreeNode: _ember['default'].computed.alias('row.isCollapsible'),
		isLeafNode: _ember['default'].computed.not('isTreeNode'),

		verticallyCenterContent: false
	});
});