define('component-library/instance-initializers/switches', ['exports', 'ui-infra/services/switches'], function (exports, _uiInfraServicesSwitches) {
  'use strict';

  exports.initialize = initialize;

  // NOTE: we don't move this to ui-infra since it's only required here for backward
  // compat. Going forward we avoid globals like `Switches.instance` in favor of explicit
  // injections

  function initialize(application) {
    _uiInfraServicesSwitches['default'].instance = application.container.lookup('service:switches');
  }

  exports['default'] = {
    name: 'switches',
    initialize: initialize
  };
});