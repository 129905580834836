define('component-library/components/tabbed-modal/tab', ['exports', 'ember', 'component-library/components/tabbed-modal/utils'], function (exports, _ember, _componentLibraryComponentsTabbedModalUtils) {
	'use strict';

	function createTabDataComputed(propName) {
		return _ember['default'].computed('tabId', 'tabApi.tabsData', 'tabApi.tabsData.[]', 'tabApi.tabsData.@each.' + propName, function () {
			var currentTabData = (this.get('tabApi.tabsData') || []).findBy('id', this.get('tabId'));
			return currentTabData && currentTabData.get(propName);
		});
	}

	exports['default'] = _ember['default'].Component.extend(_componentLibraryComponentsTabbedModalUtils.TabbedModalModeFields, {
		classNames: 'z-tabbedModal-tabContent u-flexContainer u-flexColumn u-spaceBetween u-flexGrow',
		classNameBindings: ['isTabVisible::u-hide'],

		tabApi: null,
		footerActions: _ember['default'].computed(function () {
			var _this = this;

			return _ember['default'].Object.create({
				onNext: function onNext() {
					return _this.onNext.apply(_this, arguments);
				},
				onCancel: function onCancel() {
					return _this.onCancel.apply(_this, arguments);
				}
			});
		}),

		modalMode: _ember['default'].computed.reads('tabApi.modalMode'),
		isTabVisible: _ember['default'].computed('tabId', 'tabApi.currentTabId', function () {
			return this.get('tabId') === this.get('tabApi.currentTabId');
		}),

		tabNextClicked: createTabDataComputed('nextClicked'),
		revealErrorsSetter: _ember['default'].on('init', _ember['default'].observer('tabNextClicked', function () {
			this.set('revealErrors', this.get('tabNextClicked'));
		})),

		lastTimeTabClicked: createTabDataComputed('lastClickTime'),
		_prevLastTabClickTime: null,
		animateTabError: false,
		tabErrorAnimationSetter: _ember['default'].observer('lastTimeTabClicked', function () {
			var _this2 = this;

			var lastTimeTabClicked = this.get('lastTimeTabClicked');
			if (lastTimeTabClicked !== this.get('_prevLastTabClickTime')) {
				this.setProperties({
					animateTabError: true,
					revealErrors: true,
					_prevLastTabClickTime: lastTimeTabClicked
				});
				_ember['default'].run.later(function () {
					if (!_this2.get('isDestroyed') && !_this2.get('isDestroying')) {
						_this2.set('animateTabError', false);
					}
				}, 500);
			}
		}),

		revealErrors: false,
		isValid: true, // overwrite this
		tabValidationSetter: _ember['default'].on('init', _ember['default'].observer('isValid', function () {
			this.get('tabApi').setTabValidationState(this.get('tabId'), this.get('isValid'));
		})),

		onNext: function onNext() {
			this.setProperties({
				revealErrors: true,
				lastTimeTabClicked: Date.now()
			});
			return this.get('tabApi').nextTab();
		},
		onCancel: function onCancel() {
			this.get('tabApi').closeModal();
		},

		actions: {}
	});
});