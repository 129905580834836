define('component-library/components/payroll/pay-types/link-action-table-cell', ['exports', 'ember-table/views/table-cell', 'ember-table/mixins/register-table-component'], function (exports, _emberTableViewsTableCell, _emberTableMixinsRegisterTableComponent) {
	'use strict';

	exports['default'] = _emberTableViewsTableCell['default'].extend(_emberTableMixinsRegisterTableComponent['default'], {
		templateName: 'components/payroll/pay-types/link-action-table-cell',

		didInsertElement: function didInsertElement() {
			this._super();

			this.$('[data-toggle="tooltip"]').bstooltip({
				container: 'body'
			});
		},

		willDestroyElement: function willDestroyElement() {
			this.$('[data-toggle="tooltip"]').bstooltip('destroy');
		},

		actions: {
			onEdit: function onEdit() {
				var linkAction = this.get('column.linkAction');
				var content = this.get('row.content');
				this.get('tableComponent').sendAction(linkAction, content);
			}
		}
	});
});