define('component-library/components/cards/base/card-head', ['exports', 'ember', 'component-library/components/cards/card-base', 'component-library/components/cards/modals/unsaved-changes-modal'], function (exports, _ember, _componentLibraryComponentsCardsCardBase, _componentLibraryComponentsCardsModalsUnsavedChangesModal) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['settings-card-head', 'u-flexContainer', 'u-flexCenter'],

		showTitle: true,
		title: null, // optional
		titleTooltipText: '',
		titleIconClass: '',

		actionLabel: false,

		mode: _componentLibraryComponentsCardsCardBase.SETTINGS_CARD_MODES.EDIT,

		multiEditMode: false,
		showCardControls: _ember['default'].computed.equal('mode', _componentLibraryComponentsCardsCardBase.SETTINGS_CARD_MODES.READ),
		showAdditionalCardControl: false,

		setCardControls: (function () {
			var mode = this.get('mode');

			if (mode == _componentLibraryComponentsCardsCardBase.SETTINGS_CARD_MODES.READ_ONLY) {
				this.set('showCardControls', false);
			}
		}).on('init'),

		cardEventsService: _ember['default'].inject.service('card-events'),

		click: function click(event) {
			if (typeof this.attrs.onClick === 'function') {
				this.attrs.onClick();
			}
		},

		actions: {
			toggleEdit: function toggleEdit() {
				if (this.get('cardEventsService').isCardInEditMode() && !this.get('multiEditMode')) {
					// alert user of unsaved changes
					_componentLibraryComponentsCardsModalsUnsavedChangesModal['default'].show();
				} else {
					this.get('cardEventsService').editCard(this.get('title'));
					if (this.get('toggleEdit')) {
						this.get('toggleEdit')();
					}
				}
			},

			additionalCardControl: function additionalCardControl() {
				this.sendAction('additionalCardControlAction');
			},
			additionalCardControl2: function additionalCardControl2() {
				this.sendAction('additionalCardControlAction2');
			}
		}
	});
});