define('component-library/constants/z-spreadsheet-employee', ['exports'], function (exports) {
	'use strict';

	var EMPLOYMENT_TYPE_KEYS = {
		FULL: 'FT',
		PART: 'PT',
		TEMP: 'TP'
	};

	exports.EMPLOYMENT_TYPE_KEYS = EMPLOYMENT_TYPE_KEYS;

	var EMPLOYMENT_TYPE_LABELS = {
		FT: 'Full-time',
		PT: 'Part-time',
		TP: 'Intern'
	};

	exports.EMPLOYMENT_TYPE_LABELS = EMPLOYMENT_TYPE_LABELS;

	var CONTRACTOR_TYPE_KEYS = {
		AGENCY_TEMP_WORKER: 'AW',
		COMPANY_PAID_TEMP_WORKER: 'CW',
		INDEPENDENT_CONTRACTOR: 'IC',
		VENDOR_EMPLOYEE: 'VE',
		VOLUNTEER: 'VO'
	};

	exports.CONTRACTOR_TYPE_KEYS = CONTRACTOR_TYPE_KEYS;

	var CONTRACTOR_TYPE_LABELS = {
		AW: 'Agency-paid Temp',
		CW: 'Company-paid Temp',
		IC: 'Independent Contractor',
		VE: 'Vendor Employee',
		VO: 'Volunteer'
	};

	exports.CONTRACTOR_TYPE_LABELS = CONTRACTOR_TYPE_LABELS;

	var EMPLOYEE_EMPLOYMENT_TYPE_MAPPINGS = [{ key: EMPLOYMENT_TYPE_KEYS.FULL, label: EMPLOYMENT_TYPE_LABELS.FT, options: ['f', 'ft', 'full', 'fulltime'] }, { key: EMPLOYMENT_TYPE_KEYS.PART, label: EMPLOYMENT_TYPE_LABELS.PT, options: ['p', 'pt', 'part', 'parttime'] }, { key: EMPLOYMENT_TYPE_KEYS.TEMP, label: EMPLOYMENT_TYPE_LABELS.TP, options: ['t', 'i', 'intern', 'internship', 'interntemp', 'tempintern'] }];

	exports.EMPLOYEE_EMPLOYMENT_TYPE_MAPPINGS = EMPLOYEE_EMPLOYMENT_TYPE_MAPPINGS;

	var CONTRACTOR_EMPLOYMENT_TYPE_MAPPINGS = [{ key: CONTRACTOR_TYPE_KEYS.AGENCY_TEMP_WORKER, label: CONTRACTOR_TYPE_LABELS.AW, options: ['a', 'agency', 'agencytemp'] }, { key: CONTRACTOR_TYPE_KEYS.COMPANY_PAID_TEMP_WORKER, label: CONTRACTOR_TYPE_LABELS.CW, options: ['c', 'company', 'companypaid'] }, { key: CONTRACTOR_TYPE_KEYS.INDEPENDENT_CONTRACTOR, label: CONTRACTOR_TYPE_LABELS.IC, options: ['ind', 'contractor'] }, { key: CONTRACTOR_TYPE_KEYS.VENDOR_EMPLOYEE, label: CONTRACTOR_TYPE_LABELS.VE, options: ['ve', 'vendor'] }, { key: CONTRACTOR_TYPE_KEYS.VOLUNTEER, label: CONTRACTOR_TYPE_LABELS.VO, options: ['vo', 'volunteer'] }];

	exports.CONTRACTOR_EMPLOYMENT_TYPE_MAPPINGS = CONTRACTOR_EMPLOYMENT_TYPE_MAPPINGS;

	var EMPLOYMENT_TYPE_MAPPINGS = EMPLOYEE_EMPLOYMENT_TYPE_MAPPINGS.concat(CONTRACTOR_EMPLOYMENT_TYPE_MAPPINGS);

	exports.EMPLOYMENT_TYPE_MAPPINGS = EMPLOYMENT_TYPE_MAPPINGS;

	var COMPENSATION_TYPE_LABELS = {
		S: 'Per Year (Annual Salary)',
		H: 'Per Hour (Hourly Rate)'
	};

	exports.COMPENSATION_TYPE_LABELS = COMPENSATION_TYPE_LABELS;

	var CONTRACTOR_COMPENSATION_TYPE_LABELS = {
		H: 'Per Hour (Hourly Rate)',
		A: 'Fixed Amount',
		N: 'Not Applicable'
	};

	exports.CONTRACTOR_COMPENSATION_TYPE_LABELS = CONTRACTOR_COMPENSATION_TYPE_LABELS;

	var ALL_COMPENSATION_TYPE_LABELS = {
		S: 'Per Year (Annual Salary)',
		H: 'Per Hour (Hourly Rate)',
		A: 'Fixed Amount',
		N: 'Not Applicable'
	};

	exports.ALL_COMPENSATION_TYPE_LABELS = ALL_COMPENSATION_TYPE_LABELS;

	var EMPLOYMENT_TYPE_TO_COMPENSATION_TYPE_MAPPING = [{ key: EMPLOYMENT_TYPE_LABELS.FT, label: Object.values(COMPENSATION_TYPE_LABELS) }, { key: EMPLOYMENT_TYPE_LABELS.PT, label: Object.values(COMPENSATION_TYPE_LABELS) }, { key: EMPLOYMENT_TYPE_LABELS.TP, label: Object.values(COMPENSATION_TYPE_LABELS) }, { key: CONTRACTOR_TYPE_LABELS.AW, label: Object.values(CONTRACTOR_COMPENSATION_TYPE_LABELS) }, { key: CONTRACTOR_TYPE_LABELS.CW, label: Object.values(ALL_COMPENSATION_TYPE_LABELS) }, { key: CONTRACTOR_TYPE_LABELS.IC, label: Object.values(CONTRACTOR_COMPENSATION_TYPE_LABELS) }, { key: CONTRACTOR_TYPE_LABELS.VE, label: Object.values(CONTRACTOR_COMPENSATION_TYPE_LABELS) }, { key: CONTRACTOR_TYPE_LABELS.VO, label: Object.values(CONTRACTOR_COMPENSATION_TYPE_LABELS) }];

	exports.EMPLOYMENT_TYPE_TO_COMPENSATION_TYPE_MAPPING = EMPLOYMENT_TYPE_TO_COMPENSATION_TYPE_MAPPING;

	var COMPENSATION_TYPE_KEYS = {
		SALARY: 'S',
		HOURLY: 'H'
	};

	exports.COMPENSATION_TYPE_KEYS = COMPENSATION_TYPE_KEYS;

	var CONTRACTOR_COMPENSATION_TYPE_KEYS = {
		HOURLY: 'H',
		FIXED: 'A',
		NOT_APPLICABLE: 'N'
	};

	exports.CONTRACTOR_COMPENSATION_TYPE_KEYS = CONTRACTOR_COMPENSATION_TYPE_KEYS;

	var COMPENSATION_TYPE_MAPPINGS = [{ key: COMPENSATION_TYPE_KEYS.SALARY, label: COMPENSATION_TYPE_LABELS.S, options: ['s', 'sal', 'salary', 'salaried', 'annual', 'annualsalary', 'peryearannualsalary', 'peryear', 'year', 'yearly'] }, { key: COMPENSATION_TYPE_KEYS.HOURLY, label: COMPENSATION_TYPE_LABELS.H, options: ['h', 'hr', 'hour', 'hourly', 'hourlyrate', 'perhourhourlyrate', 'perhour'] }, { key: CONTRACTOR_COMPENSATION_TYPE_KEYS.FIXED, label: CONTRACTOR_COMPENSATION_TYPE_LABELS.A, options: ['f', 'fixed'] }, { key: CONTRACTOR_COMPENSATION_TYPE_KEYS.NOT_APPLICABLE, label: CONTRACTOR_COMPENSATION_TYPE_LABELS.N, options: ['n', 'na', 'not applicable'] }];

	exports.COMPENSATION_TYPE_MAPPINGS = COMPENSATION_TYPE_MAPPINGS;

	var GENDER_KEYS = {
		MALE: 'M',
		FEMALE: 'F'
	};

	exports.GENDER_KEYS = GENDER_KEYS;

	var GENDER_LABELS = {
		M: 'Male',
		F: 'Female'
	};

	exports.GENDER_LABELS = GENDER_LABELS;

	var GENDER_MAPPINGS = [{ key: GENDER_KEYS.MALE, label: GENDER_LABELS.M, options: ['m', 'male', 'man'] }, { key: GENDER_KEYS.FEMALE, label: GENDER_LABELS.F, options: ['f', 'female', 'woman'] }];

	exports.GENDER_MAPPINGS = GENDER_MAPPINGS;

	var BOOLEAN_KEYS = {
		YES: true,
		NO: false
	};

	exports.BOOLEAN_KEYS = BOOLEAN_KEYS;

	var BOOLEAN_LABELS = {
		Y: 'Yes',
		N: 'No'
	};

	exports.BOOLEAN_LABELS = BOOLEAN_LABELS;

	var BOOLEAN_MAPPINGS = [{ key: BOOLEAN_KEYS.YES, label: BOOLEAN_LABELS.Y, options: ['y', 'yes', 't', 'true'] }, { key: BOOLEAN_KEYS.NO, label: BOOLEAN_LABELS.N, options: ['n', 'no', 'f', 'false'] }];

	exports.BOOLEAN_MAPPINGS = BOOLEAN_MAPPINGS;

	var EMPLOYEE_STATUS_KEYS = {
		REQUESTED: 'Req',
		SETUP: 'Set',
		ACTIVE: 'Act',
		TERMINATED: 'Ter',
		LOA: 'LOA',
		DELETED: 'Del'
	};

	exports.EMPLOYEE_STATUS_KEYS = EMPLOYEE_STATUS_KEYS;

	var EMPLOYEE_STATUS_LABELS = {
		REQUESTED: 'Requested',
		SETUP: 'Setup',
		ACTIVE: 'Active',
		TERMINATED: 'Terminated',
		LOA: 'LOA',
		DELETED: 'Deleted'
	};

	exports.EMPLOYEE_STATUS_LABELS = EMPLOYEE_STATUS_LABELS;

	var EMPLOYEE_STATUS_MAPPINGS = [{ key: EMPLOYEE_STATUS_KEYS.REQUESTED, label: EMPLOYEE_STATUS_LABELS.REQUESTED, options: ['requested'] }, { key: EMPLOYEE_STATUS_KEYS.SETUP, label: EMPLOYEE_STATUS_LABELS.SETUP, options: ['setup'] }, { key: EMPLOYEE_STATUS_KEYS.ACTIVE, label: EMPLOYEE_STATUS_LABELS.ACTIVE, options: ['active'] }, { key: EMPLOYEE_STATUS_KEYS.TERMINATED, label: EMPLOYEE_STATUS_LABELS.TERMINATED, options: ['terminated'] }, { key: EMPLOYEE_STATUS_KEYS.LOA, label: EMPLOYEE_STATUS_LABELS.LOA, options: ['loa'] }, { key: EMPLOYEE_STATUS_KEYS.DELETED, label: EMPLOYEE_STATUS_LABELS.DELETED, options: ['deleted'] }];

	exports.EMPLOYEE_STATUS_MAPPINGS = EMPLOYEE_STATUS_MAPPINGS;

	var EMPLOYEE_PAYMENT_METHOD_KEYS = {
		DIRECT_REPOSIT: 'DD',
		MANUAL_CHECK: 'MA'
	};

	exports.EMPLOYEE_PAYMENT_METHOD_KEYS = EMPLOYEE_PAYMENT_METHOD_KEYS;

	var EMPLOYEE_PAYMENT_METHOD_LABELS = {
		DIRECT_REPOSIT: 'Direct Deposit',
		MANUAL_CHECK: 'Manual Check'
	};

	exports.EMPLOYEE_PAYMENT_METHOD_LABELS = EMPLOYEE_PAYMENT_METHOD_LABELS;

	var EMPLOYEE_PAYMENT_METHOD_MAPPINGS = [{ key: EMPLOYEE_PAYMENT_METHOD_KEYS.DIRECT_REPOSIT, label: EMPLOYEE_PAYMENT_METHOD_LABELS.DIRECT_REPOSIT, options: ['direct_deposit'] }, { key: EMPLOYEE_PAYMENT_METHOD_KEYS.MANUAL_CHECK, label: EMPLOYEE_PAYMENT_METHOD_LABELS.MANUAL_CHECK, options: ['manual_check'] }];

	exports.EMPLOYEE_PAYMENT_METHOD_MAPPINGS = EMPLOYEE_PAYMENT_METHOD_MAPPINGS;

	var EEO_RACE_KEYS = {
		MALE_HISPANIC_OR_LATINO: 'A',
		MALE_WHITE: 'C',
		MALE_BLACK_OR_AFRICAN_AMERICAN: 'D',
		MALE_NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER: 'E',
		MALE_ASIAN: 'F',
		MALE_AMERICAN_INDIAN_OR_ALASKA_NATIVE: 'G',
		MALE_TWO_OR_MORE_RACES: 'H',
		FEMALE_HISPANIC_OR_LATINO: 'b',
		FEMALE_WHITE: 'I',
		FEMALE_BLACK_OR_AFRICAN_AMERICAN: 'J',
		FEMALE_NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER: 'K',
		FEMALE_ASIAN: 'L',
		FEMALE_AMERICAN_INDIAN_OR_ALASKA_NATIVE: 'M',
		FEMALE_TWO_OR_MORE_RACES: 'N'
	};

	exports.EEO_RACE_KEYS = EEO_RACE_KEYS;

	var EEO_RACE_LABELS = {
		A: '(A) - Male Hispanic or Latino',
		C: '(C) - Male White',
		D: '(D) - Male Black or African American',
		E: '(E) - Male Native Hawaiian or Other Pacific Islander',
		F: '(F) - Male Asian',
		G: '(G) - Male American Indian or Alaska Native',
		H: '(H) - Male Two or more races',
		B: '(B) - Female Hispanic or Latino',
		I: '(I) - Female White',
		J: '(J) - Female Black or African American',
		K: '(K) - Female Native Hawaiian or Other Pacific Islander',
		L: '(L) - Female Asian',
		M: '(M) - Female American Indian or Alaska Native',
		N: '(N) - Female Two or more races'
	};

	exports.EEO_RACE_LABELS = EEO_RACE_LABELS;

	var EEO_RACE_MAPPINGS = [{ key: EEO_RACE_KEYS.MALE_HISPANIC_OR_LATINO, label: EEO_RACE_LABELS.A, options: ['malehispanic', 'malelatino'], race: 'A' }, { key: EEO_RACE_KEYS.MALE_WHITE, label: EEO_RACE_LABELS.C, options: ['malewhite'], race: 'C' }, { key: EEO_RACE_KEYS.MALE_BLACK_OR_AFRICAN_AMERICAN, label: EEO_RACE_LABELS.D, options: ['maleblack', 'maleafrican', 'maleafricanamerican'], race: 'D' }, { key: EEO_RACE_KEYS.MALE_NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER, label: EEO_RACE_LABELS.E, options: ['malehawaiian', 'malepacificislander'], race: 'E' }, { key: EEO_RACE_KEYS.MALE_ASIAN, label: EEO_RACE_LABELS.F, options: ['asian'], race: 'F' }, { key: EEO_RACE_KEYS.MALE_AMERICAN_INDIAN_OR_ALASKA_NATIVE, label: EEO_RACE_LABELS.G, options: ['maleindian', 'malenativeamerican', 'malealaskan'], race: 'G' }, { key: EEO_RACE_KEYS.MALE_TWO_OR_MORE_RACES, label: EEO_RACE_LABELS.H, options: ['malemore', 'maletwoormore', 'malemultiple'], race: 'H' }, { key: EEO_RACE_KEYS.FEMALE_HISPANIC_OR_LATINO, label: EEO_RACE_LABELS.B, options: ['femalehispanic', 'femalelatino'], race: 'b' }, { key: EEO_RACE_KEYS.FEMALE_WHITE, label: EEO_RACE_LABELS.I, options: ['femalewhite'], race: 'I' }, { key: EEO_RACE_KEYS.FEMALE_BLACK_OR_AFRICAN_AMERICAN, label: EEO_RACE_LABELS.J, options: ['femaleblack', 'femaleafrican'], race: 'J' }, { key: EEO_RACE_KEYS.FEMALE_NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER, label: EEO_RACE_LABELS.K, options: ['femalenative', 'femalehawaiian', 'femalepacificislander'], race: 'K' }, { key: EEO_RACE_KEYS.FEMALE_ASIAN, label: EEO_RACE_LABELS.L, options: ['femaleasian'], race: 'L' }, { key: EEO_RACE_KEYS.FEMALE_AMERICAN_INDIAN_OR_ALASKA_NATIVE, label: EEO_RACE_LABELS.M, options: ['femaleindian', 'femalenativeamerican', 'femalealaska'], race: 'M' }, { key: EEO_RACE_KEYS.FEMALE_TWO_OR_MORE_RACES, label: EEO_RACE_LABELS.N, options: ['femalemore', 'femaletwoormore', 'femalemultiple'], race: 'N' }];

	exports.EEO_RACE_MAPPINGS = EEO_RACE_MAPPINGS;

	var EEO_JOB_CATEGORY_KEYS = {
		EXECUTIVE_SENIOR_LEVEL_OFFICIALS_AND_MANAGERS: '1.1',
		FIRST_MID_LEVEL_OFFICIALS_AND_MANAGERS: '1.2',
		PROFESSIONALS: '2',
		TECHNICIANS: '3',
		SALES_WORKERS: '4',
		ADMINISTRATIVE_SUPPORT_WORKERS: '5',
		CRAFT_WORKERS: '6',
		OPERATIVES: '7',
		LABORERS_AND_HELPERS: '8',
		SERVICE_WORKERS: '9'
	};

	exports.EEO_JOB_CATEGORY_KEYS = EEO_JOB_CATEGORY_KEYS;

	var EEO_JOB_CATEGORY_LABELS = {
		k1_1: '(1.1) - Executive/Senior Level Officials and Managers',
		k1_2: '(1.2) - First/Mid-Level Officials and Managers',
		k2: '(2) - Professionals',
		k3: '(3) - Technicians',
		k4: '(4) - Sales Workers',
		k5: '(5) - Administrative Support Workers',
		k6: '(6) - Craft Workers',
		k7: '(7) - Operatives',
		k8: '(8) - Laborers and Helpers',
		k9: '(9) - Service Workers'
	};

	exports.EEO_JOB_CATEGORY_LABELS = EEO_JOB_CATEGORY_LABELS;

	var EEO_JOB_CATEGORY_COMPONENT_2_LABELS = {
		k1: '(1) - Executive/Senior Level Officials and Managers',
		k2: '(2) - First/Mid-Level Officials and Managers',
		k3: '(3) - Professionals',
		k4: '(4) - Technicians',
		k5: '(5) - Sales Workers',
		k6: '(6) - Administrative Support Workers',
		k7: '(7) - Craft Workers',
		k8: '(8) - Operatives',
		k9: '(9) - Laborers and Helpers',
		k10: '(10) - Service Workers'
	};

	exports.EEO_JOB_CATEGORY_COMPONENT_2_LABELS = EEO_JOB_CATEGORY_COMPONENT_2_LABELS;

	var EEO_JOB_CATEGORY_MAPPINGS = [{ key: EEO_JOB_CATEGORY_KEYS.EXECUTIVE_SENIOR_LEVEL_OFFICIALS_AND_MANAGERS, label: EEO_JOB_CATEGORY_LABELS.k1_1, options: ['executive', 'seniorofficials', 'seniormanager', 'exec', 'sr'], jobCategory: '1.1' }, { key: EEO_JOB_CATEGORY_KEYS.FIRST_MID_LEVEL_OFFICIALS_AND_MANAGERS, label: EEO_JOB_CATEGORY_LABELS.k1_2, options: ['firstmidlevel', 'firstofficials'], jobCategory: '1.2' }, { key: EEO_JOB_CATEGORY_KEYS.PROFESSIONALS, label: EEO_JOB_CATEGORY_LABELS.k2, options: ['professionals', 'professional'], jobCategory: '2' }, { key: EEO_JOB_CATEGORY_KEYS.TECHNICIANS, label: EEO_JOB_CATEGORY_LABELS.k3, options: ['technicians', 'technician'], jobCategory: '3' }, { key: EEO_JOB_CATEGORY_KEYS.SALES_WORKERS, label: EEO_JOB_CATEGORY_LABELS.k4, options: ['sales', 'salesrep', 'salesworker', 'salesworkers'], jobCategory: '4' }, { key: EEO_JOB_CATEGORY_KEYS.ADMINISTRATIVE_SUPPORT_WORKERS, label: EEO_JOB_CATEGORY_LABELS.k5, options: ['admin', 'support', 'administrative'], jobCategory: '5' }, { key: EEO_JOB_CATEGORY_KEYS.CRAFT_WORKERS, label: EEO_JOB_CATEGORY_LABELS.k6, options: ['craft', 'craftsmen', 'craftworkers', 'craftworker', 'tradesmen', 'trades'], jobCategory: '6' }, { key: EEO_JOB_CATEGORY_KEYS.OPERATIVES, label: EEO_JOB_CATEGORY_LABELS.k7, options: ['operativies', 'ops'], jobCategory: '7' }, { key: EEO_JOB_CATEGORY_KEYS.LABORERS_AND_HELPERS, label: EEO_JOB_CATEGORY_LABELS.k8, options: ['laborers', 'laborer', 'helpers'], jobCategory: '8' }, { key: EEO_JOB_CATEGORY_KEYS.SERVICE_WORKERS, label: EEO_JOB_CATEGORY_LABELS.k9, options: ['service', 'servicesworkers', 'serviceworker'], jobCategory: '9' }];

	exports.EEO_JOB_CATEGORY_MAPPINGS = EEO_JOB_CATEGORY_MAPPINGS;

	var EEO_JOB_CATEGORY_COMPONENT2_MAPPINGS_FROM_OLD_KEY_TO_NEW_LABEL = [{ key: EEO_JOB_CATEGORY_KEYS.EXECUTIVE_SENIOR_LEVEL_OFFICIALS_AND_MANAGERS, label: EEO_JOB_CATEGORY_COMPONENT_2_LABELS.k1, options: ['executive', 'seniorofficials', 'seniormanager', 'exec', 'sr'], jobCategory: '1' }, { key: EEO_JOB_CATEGORY_KEYS.FIRST_MID_LEVEL_OFFICIALS_AND_MANAGERS, label: EEO_JOB_CATEGORY_COMPONENT_2_LABELS.k2, options: ['firstmidlevel', 'firstofficials'], jobCategory: '2' }, { key: EEO_JOB_CATEGORY_KEYS.PROFESSIONALS, label: EEO_JOB_CATEGORY_COMPONENT_2_LABELS.k3, options: ['professionals', 'professional'], jobCategory: '3' }, { key: EEO_JOB_CATEGORY_KEYS.TECHNICIANS, label: EEO_JOB_CATEGORY_COMPONENT_2_LABELS.k4, options: ['technicians', 'technician'], jobCategory: '4' }, { key: EEO_JOB_CATEGORY_KEYS.SALES_WORKERS, label: EEO_JOB_CATEGORY_COMPONENT_2_LABELS.k5, options: ['sales', 'salesrep', 'salesworker', 'salesworkers'], jobCategory: '5' }, { key: EEO_JOB_CATEGORY_KEYS.ADMINISTRATIVE_SUPPORT_WORKERS, label: EEO_JOB_CATEGORY_COMPONENT_2_LABELS.k6, options: ['admin', 'support', 'administrative'], jobCategory: '6' }, { key: EEO_JOB_CATEGORY_KEYS.CRAFT_WORKERS, label: EEO_JOB_CATEGORY_COMPONENT_2_LABELS.k7, options: ['craft', 'craftsmen', 'craftworkers', 'craftworker', 'tradesmen', 'trades'], jobCategory: '7' }, { key: EEO_JOB_CATEGORY_KEYS.OPERATIVES, label: EEO_JOB_CATEGORY_COMPONENT_2_LABELS.k8, options: ['operativies', 'ops'], jobCategory: '8' }, { key: EEO_JOB_CATEGORY_KEYS.LABORERS_AND_HELPERS, label: EEO_JOB_CATEGORY_COMPONENT_2_LABELS.k9, options: ['laborers', 'laborer', 'helpers'], jobCategory: '9' }, { key: EEO_JOB_CATEGORY_KEYS.SERVICE_WORKERS, label: EEO_JOB_CATEGORY_COMPONENT_2_LABELS.k10, options: ['service', 'servicesworkers', 'serviceworker'], jobCategory: '10' }];

	exports.EEO_JOB_CATEGORY_COMPONENT2_MAPPINGS_FROM_OLD_KEY_TO_NEW_LABEL = EEO_JOB_CATEGORY_COMPONENT2_MAPPINGS_FROM_OLD_KEY_TO_NEW_LABEL;

	var MAXIMUM_SALARY = 9999999999.9999;

	exports.MAXIMUM_SALARY = MAXIMUM_SALARY;

	var DATE_FIELDS = ['hire_date'];

	exports.DATE_FIELDS = DATE_FIELDS;

	var COMPENSATION_FIELDS = ['compensation_type', '_compensation'];

	exports.COMPENSATION_FIELDS = COMPENSATION_FIELDS;

	var translateMapping = function translateMapping(mappingList, inputVal) {
		var isKeyToLabelConversion = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

		var from = 'key';
		var to = 'label';
		var outputVal = undefined;

		if (!isKeyToLabelConversion) {
			from = 'label';
			to = 'key';
		}

		outputVal = mappingList.findBy(from, inputVal);

		if (!outputVal) {
			return inputVal;
		}

		return outputVal[to];
	};

	var getMappingLabelByKey = function getMappingLabelByKey(mappingList, mappingKey) {
		var mappingResult = mappingList.findBy('label', mappingKey);
		if (mappingResult) {
			return mappingKey;
		}
		return translateMapping(mappingList, mappingKey, true);
	};

	exports.getMappingLabelByKey = getMappingLabelByKey;

	var getMappingKeyByLabel = function getMappingKeyByLabel(mappingList, mappingLabel) {
		var mappingResult = mappingList.findBy('key', mappingLabel);
		if (mappingResult) {
			return mappingLabel;
		}
		return translateMapping(mappingList, mappingLabel, false);
	};
	exports.getMappingKeyByLabel = getMappingKeyByLabel;
});