define('component-library/utils/payroll-connect/constants', ['exports', 'component-library/utils/payroll/payroll-utils'], function (exports, _componentLibraryUtilsPayrollPayrollUtils) {
	'use strict';

	var payrollChoices = [{ value: null, label: '' }].concat(_componentLibraryUtilsPayrollPayrollUtils.payrollProviders);

	exports['default'] = {

		PAYROLL_CHOICES: payrollChoices
	};

	var PAYROLL_ID_VALIDATIONS = {
		// empty strings allowed to let admins delete then save the payroll ID.
		PL: {
			regExp: /^[A-Za-z0-9]*$/, // allows "", letters, or numbers.
			errorMessage: 'Employee Payroll IDs can only contain letters and numbers.'
		},
		WF: {
			regExp: /^[A-Za-z0-9\S]{6,}$/, // allows "" or Minimum six characters:
			errorMessage: 'Each employee must have minimum six characters.'
		}
	};

	exports.PAYROLL_ID_VALIDATIONS = PAYROLL_ID_VALIDATIONS;

	var smpSetupProviderBuckets = {
		PAYROLL_REPORTS: 'payrollReports', // Full Sync with optout, or ones that don't fall under rest of buckets
		FILE_SYNC: 'fileSync', // PL only for now (determined via smp.isFileSync)
		SYNC_COMPANY_ID_ONLY: 'syncCompanyIdOnly', // PX - Collect only Company ID Credentials
		SYNC_IMPLEMENTATION: 'syncImplementation', // AR - Let IM teams take care of it. WF is moved to SYNC flow.
		SYNC: 'sync', // If Sync company chose to actually do the full Sync.
		SYNC_OPT_OUT: 'syncOptOut' // If Sync company chose "No Sync" - goes to PayrollReports for the most part.
	};

	exports.smpSetupProviderBuckets = smpSetupProviderBuckets;

	var PAYROLL_SWITCH_CODE_TO_STATUS = {
		'FIL': 'Filling Out',
		'SUB': 'Submitted',
		'PEN': 'Pending',
		'DON': 'Done',
		'CNL': 'Cancel'
	};

	exports.PAYROLL_SWITCH_CODE_TO_STATUS = PAYROLL_SWITCH_CODE_TO_STATUS;

	var WF_ASSIGN_CODE_CHOICES = [{ value: 1, label: 'A single company code' }, { value: 2, label: 'One company code for hourly and one for salaried employees' }, { value: 3, label: 'One company code for full time and one for part-time employees' }, { value: 4, label: 'A manually entered company code per employee' }, { value: 5, label: 'Company code based on department' }, { value: 6, label: 'Company code based on work location' }];

	exports.WF_ASSIGN_CODE_CHOICES = WF_ASSIGN_CODE_CHOICES;

	var WF_BADGE_NUMBER_FORMAT_CHOICES = [{ value: 'None', label: 'None' }, { value: '4_SSN', label: 'Use last four digits of SSN' }, { value: 'FILE_NUM', label: 'Use file number' }, { value: 'CUST_VAL', label: 'Ask for custom value in hiring flow' }];

	exports.WF_BADGE_NUMBER_FORMAT_CHOICES = WF_BADGE_NUMBER_FORMAT_CHOICES;

	var WF_PRENOTIFICATION_CHOICES = [{ value: 0, label: 'Company Default' }, { value: 1, label: 'Set to "None"' }];
	exports.WF_PRENOTIFICATION_CHOICES = WF_PRENOTIFICATION_CHOICES;
});