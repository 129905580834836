define('component-library/components/avatar-name-badge', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var AVATAR_SIZES = {
    'x-small': 'avatar-container--xs',
    small: 'avatar-container--sm',
    medium: 'avatar-container--md',
    large: 'avatar-container--lg',
    'x-large': 'avatar-container--xl'
  };

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['avatar-name-badge', 'u-flexContainer', 'u-flexCenter'],
    classNameBindings: ['sizeClass'],
    isVerticalAligned: false,
    showLastName: true,
    firstName: undefined,
    lastName: undefined,
    photoUrl: undefined,
    badgeTooltip: undefined,
    size: undefined,
    badgeText: 'Contingent Worker',
    tooltipHeader: 'Contingent Worker',
    tooltipText: 'Independent Contractor',

    sizeClass: _ember['default'].computed('size', function () {
      return AVATAR_SIZES[this.get('size')];
    })
  });
});