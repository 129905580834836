define('component-library/mixins/element-tagless-mixin', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
  /**
   * Makes `this.$()` and `this.$(selector)` avaliable for tagless components
   *
   * By default Ember doesn't allow to use `this.$()` for tagless components:
   * https://github.com/emberjs/ember.js/pull/12500
   *
   * Usage:
   *  1. extend your component with this mixin
   *  2. in your component's template add `class=uniqClassName` for top level element (usually another component)
   *
   * Example:
   *
   * js:
   *   export default Ember.Component.extend(ElementTaglessMixin, {
   *     tagName: '',
   *   });
   *
   * hbs:
   *   {{#overview-page-card class=uniqClassName classNames="overview-avatars-card"}}
   *     <div>My component</div>
   *   {{/overview-page-card}}
   */

  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    init: function init() {
      var id = Math.round(Math.random() * Math.pow(10, 10));
      var uniqClassName = 'js-uniq-id-' + id;
      this.set('uniqClassName', uniqClassName);

      this._super.apply(this, arguments);

      this.$ = function (selector) {
        return (0, _componentLibraryUtilsGlobals.$)('.' + uniqClassName + ' ' + (selector || ''));
      };
    },
    uniqClassName: null
  });
});