define('component-library/timeline/component', ['exports', 'ember', 'component-library/filters/filter-array', 'component-library/filters/multi-select-filter-spec', 'component-library/utils/globals', 'component-library/utils/traditional-query-converter'], function (exports, _ember, _componentLibraryFiltersFilterArray, _componentLibraryFiltersMultiSelectFilterSpec, _componentLibraryUtilsGlobals, _componentLibraryUtilsTraditionalQueryConverter) {
	'use strict';

	var JumpDate = _ember['default'].Object.extend({
		isSelected: false
	});

	var DisplayEntry = _ember['default'].Object.extend({

		entry: null,
		isCheckDate: false,

		dataComponent: (function () {
			console.log(this.get('entry.data.component'));
			return this.get('entry.data.component') || 'paystubs/components/default';
		}).property('entry.data.component')

	});

	var dummyMoment = {
		toDate: function toDate() {
			return null;
		}
	};
	var CheckDate = _ember['default'].Object.extend({

		moment: null,
		date: (function () {
			return this.getWithDefault('moment', dummyMoment).toDate();
		}).property('moment'),
		isCheckDate: true,
		entry: _ember['default'].computed.alias('date'),

		dataComponent: 'timeline/data/check-date'

	});

	var compareMoments = function compareMoments(a, b) {
		return a.valueOf() - b.valueOf();
	};
	var compareCheckDates = function compareCheckDates(a, b) {
		return compareMoments(b.get('moment'), a.get('moment'));
	};

	exports['default'] = _ember['default'].Component.extend(_ember['default'].Evented, {

		classNames: ['grid-block', 'vertical', 'z-productBody-container'],
		templateName: 'timeline',
		contentSelector: '.js-z-productBody',
		debounceTime: 30,
		rangeOffset: 400,
		debounceId: null,

		// must override adminEmployee and employees
		adminEmployee: null,
		employees: null,

		title: 'Timeline',
		earliestDate: null,
		currentDate: null,
		employeeId: null,
		searchQuery: '',
		employeesArray: (function () {
			return _ember['default'].PromiseArray.create({
				promise: this.get('employees') || _ember['default'].RSVP.resolve([])
			});
		}).property('employees'),
		requestId: 1,
		store: _ember['default'].inject.service(),

		isLoading: false,
		isNotLoading: _ember['default'].computed.not('isLoading'),

		consoleCompanyId: null,
		donotShowCheckDates: false,

		/**
  	Must define entry types.
  	entryTypes should be an array of POJOs, each containing model and label properties
  	The model property is used for making the query
  	The label property is used for displaying on the filters
  	ex:
  		entryTypes: [
  			{
  				model: 'new_hire',
  				label: 'New Hire',
  			},
  			{
  				model: 'salary_change',
  				label: 'Salary Change',
  			},
  			{
  				model: 'employee_termination',
  				label: 'Employee Termination',
  			},
  		],
   */
		entryTypes: [],

		eventTypeFilter: (function () {
			// TODO(mnm): replace this with a custom event type filter
			return _componentLibraryFiltersMultiSelectFilterSpec['default'].create({
				filterId: 'entryType',
				name: 'Event Type',
				placeholder: 'Filter by Event Type',
				valuePath: 'entry_type',
				labelPath: 'label',
				optionValuePath: '',
				options: this.get('entryTypes').map(function (type) {
					return _ember['default'].Object.create({
						id: type.model,
						label: type.label
					});
				}),
				showAlways: true
			});
		}).property(),

		filters: (function () {
			return _componentLibraryFiltersFilterArray['default'].create({
				content: [this.get('eventTypeFilter')]
			});
		}).property(),

		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);
			var consoleCompanyId = this.get('consoleCompanyId');
			var params = consoleCompanyId ? '?company=' + consoleCompanyId : '';
			// This gets lazy loaded. It's not needed immediately on page load.
			_ember['default'].getJSON('/api/feed_boundaries/' + params).then(function (boundaries) {
				if (!boundaries) {
					_this.eventLogger.log('timeline-boundaries-null');
				} else {
					var startDate = (0, _componentLibraryUtilsGlobals.moment)(boundaries.min);
					var jumpDates = [JumpDate.create({
						moment: null,
						displayDate: 'Current',
						isSelected: true
					})];
					for (var endDate = (0, _componentLibraryUtilsGlobals.moment)().endOf('month').subtract(1, 'months'); endDate > startDate; endDate.subtract(1, 'months')) {
						jumpDates.push(JumpDate.create({
							moment: endDate.clone(),
							displayDate: endDate.format('MMMM YYYY')
						}));
					}
					if (!_this.isDestroyed && !_this.isDestroying) {
						_this.set('jumpDates', jumpDates);
					}
				}
			})['catch'](function (error) {
				_this.eventLogger.logError(error);
			});
			if (!this.get('donotShowCheckDates')) {
				this.setupCheckDates(this.get('companyPayrollRuns'));
			}
			this.initLoad();
		},

		didInsertElement: function didInsertElement() {
			var _this2 = this;

			this._super.apply(this, arguments);
			var $scrollable = this.$(this.get('contentSelector')).parent();
			$scrollable.on('scroll.zenefinity resize.zenefinity', function () {
				var debounceId = _ember['default'].run.debounce(_this2, _this2.checkIfInRange, _this2.get('debounceTime'));
				_this2.set('debounceId', debounceId);
			});
			this.on('feedEntriesLoaded', function () {
				_ember['default'].run.next(_this2, _this2.checkIfInRange);
			});
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			var $scrollable = this.$().parent();
			$scrollable.off('.zenefinity');

			// don't care if this succeeds or not
			_ember['default'].run.cancel(this.get('debounceId'));
		},

		checkIfInRange: function checkIfInRange() {
			if (this.get('controller.isDone') || this.get('controller.isLoading')) {
				return;
			}
			var $content = this.$(this.get('contentSelector'));
			var $scrollable = $content.parent();
			var scrollPosition = $scrollable.scrollTop() + $scrollable.height();
			var contentHeight = $content.height();
			var rangeOffset = this.get('rangeOffset');
			if (scrollPosition > contentHeight - rangeOffset) {
				this.fetch();
			}
		},

		initLoad: (function () {
			this.set('entries', []);
			this.set('currentDate', this.get('earliestDate'));
			this.set('isDone', false);
			this.fetch();
		}).observes('filters.query', 'employeeId', 'earliestDate'),

		checkDates: [],

		setupCheckDates: function setupCheckDates(companyPayrollRuns) {
			var regex = /^(RG|SMP)$/;
			var checkDates = companyPayrollRuns.filter(function (run) {
				return regex.test(run.get('reasonCode'));
			}).map(function (run) {
				return CheckDate.create({
					moment: (0, _componentLibraryUtilsGlobals.moment)(run.get('payDate'), 'MM/DD/YYYY')
				});
			});
			checkDates.sort(compareCheckDates);
			this.set('checkDates', checkDates);
		},

		fetch: function fetch() {
			var _this3 = this;

			// TODO(mnm): replace this with an action

			var filterQuery = this.get('filters.query');
			// if no event types selected, we return empty array
			var entriesPromise = undefined;
			if (this.get('eventTypeFilter.selections.length')) {
				var qps = (0, _componentLibraryUtilsTraditionalQueryConverter['default'])(filterQuery);
				var currentDate = this.get('currentDate');
				if (_componentLibraryUtilsGlobals.moment.isMoment(currentDate)) {
					qps['from'] = this.get('currentDate').utc().format();
				}
				var employeeId = this.get('employeeId');
				var consoleCompanyId = this.get('consoleCompanyId');
				if (employeeId) {
					qps['employee'] = employeeId;
				}

				if (consoleCompanyId) {
					qps['company'] = consoleCompanyId;
				}

				entriesPromise = this.get('store').find('feedEntry', qps);
			} else {
				entriesPromise = _ember['default'].RSVP.resolve([]);
			}
			this.incrementProperty('requestId');
			var thisRequestId = this.get('requestId');
			// entries are feed entries.
			entriesPromise.then(function (entries) {
				if (_this3.get('requestId') != thisRequestId) {
					throw new Error('outdated request');
				}
				_this3.addEntries(entries.slice());
				if (_ember['default'].isEmpty(entries)) {
					_this3.set('isDone', true);
				} else {
					// timestamp is a js Date object
					_this3.set('currentDate', (0, _componentLibraryUtilsGlobals.moment)(entries.get('lastObject.timestamp')));
				}
			}).then(function () {
				_this3.set('isLoading', false);
				_this3.trigger('feedEntriesLoaded');
			})['catch'](function (err) {
				if (!err || err.message != 'outdated request') {
					_this3.eventLogger.logError(err);
				}
			});
			this.set('isLoading', true);
		},

		// this function attempts to modify the entries array without replacing it, which would result in a complete re-render
		// this means avoiding all the fun ES5 methods designed for operating on immutable arrays
		addEntries: function addEntries(newEntries) {
			newEntries = newEntries.map(function (ent) {
				return DisplayEntry.create({
					entry: ent
				});
			});
			var entries = this.get('entries');

			// filter out check dates before the selected jump date
			var checkDates = undefined;
			var earliestDate = this.get('earliestDate');
			if (earliestDate) {
				checkDates = this.get('checkDates').filter(function (cd) {
					return !earliestDate.isBefore(cd.get('moment'));
				});
			} else {
				checkDates = this.get('checkDates').slice();
			}

			// slicing only check dates after the last check date in the entries array
			var lastCheckDateEntriesIndex = entries.mapBy('isCheckDate').lastIndexOf(true);
			if (lastCheckDateEntriesIndex >= 0) {
				var lastCheckDate = entries.objectAt(lastCheckDateEntriesIndex);
				var checkDatesIndex = checkDates.indexOf(lastCheckDate);
				checkDates = checkDates.slice(checkDatesIndex + 1);
			}

			// adding check dates to newEntries
			for (var i = 0; i < newEntries.length; i++) {
				var thisDate = newEntries.objectAt(i).get('entry.timestamp'); // JS Date
				for (var j = 0; j < checkDates.length; j++) {
					var checkDate = checkDates.objectAt(j);
					var checkMoment = checkDate.get('moment'); // moment
					if (checkMoment.isAfter(thisDate)) {
						newEntries.insertAt(i++, checkDate);
						checkDates.removeAt(j--);
					} else {
						break;
					}
				}
			}

			// append new entries and check dates to the existing array
			entries.addObjects(newEntries);
		},

		getFullFeedEntry: (function () {
			var _this4 = this;

			return function (id) {
				return _this4.get('store').find('feedFullEntry', id);
			};
		}).property(),

		actions: {
			setJumpDate: function setJumpDate(jumpDate) {
				this.get('jumpDates').setEach('isSelected', false);
				jumpDate.set('isSelected', true);
				var earliestDate = _componentLibraryUtilsGlobals.moment.isMoment(jumpDate.moment) ? jumpDate.moment.clone() : null;
				this.set('earliestDate', earliestDate);
			}
		}
	});
});