define('component-library/constants/handsontable-editors', ['exports'], function (exports) {
	'use strict';

	exports.customValidationEditor = customValidationEditor;

	function customValidationEditor(Handsontable, editorComponent) {

		var CustomEditor = Handsontable.editors.TextEditor.prototype.extend();

		CustomEditor.prototype.createElements = function () {

			// Call the original createElements method
			Handsontable.editors.TextEditor.prototype.createElements.apply(this, arguments);

			// stuff from https://docs.handsontable.com/0.23.0/tutorial-cell-editor.html
			this.TEXTAREA = document.createElement('input');
			this.TEXTAREA.setAttribute('type', 'text');
			this.TEXTAREA.className = 'handsontableInput';
			this.textareaStyle = this.TEXTAREA.style;
			this.textareaStyle.width = 0;
			this.textareaStyle.height = 0;
			Handsontable.Dom.empty(this.TEXTAREA_PARENT);

			// insert our component
			this.TEXTAREA_PARENT.appendChild(editorComponent.createElement().element);
		};

		return CustomEditor;
	}
});