define('ember-table/mixins/touch-move-handler', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    onTouchMove: _ember['default'].K,

    didInsertElement: function didInsertElement() {
      var _this = this;
      var startX = 0;
      var startY = 0;

      this._super();

      this.$().bind('touchstart', function (event) {
        startX = event.originalEvent.targetTouches[0].pageX;
        startY = event.originalEvent.targetTouches[0].pageY;
      });

      this.$().bind('touchmove', function (event) {
        var newX = event.originalEvent.targetTouches[0].pageX;
        var newY = event.originalEvent.targetTouches[0].pageY;
        var deltaX = -(newX - startX);
        var deltaY = -(newY - startY);
        _ember['default'].run(_this, _this.onTouchMove, event, deltaX, deltaY);
        startX = newX;
        startY = newY;
      });
    },

    willDestroyElement: function willDestroyElement() {
      this.$().unbind('touchstart');
      this.$().unbind('touchmove');
      this._super();
    }
  });
});