define('component-library/components/d-info-tooltip', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		anchorSelector: 'body',
		placement: 'bottom',
		title: null,
		tooltipClass: '',

		init: function init() {
			this._super();
			_ember['default'].deprecate('info-tooltip will be fully deprecated in Q4 2016. Use the Style Guide version instead, https://console.zenefits.com/console/style-guide#/components/tooltip', false, { id: 'tooltip-deprecation' });
		},

		didInsertElement: function didInsertElement() {
			var tooltipClass = this.get('tooltipClass');
			this.$().bstooltip({
				container: this.get('anchorSelector'),
				placement: this.get('placement'),
				title: this.get('title'),
				template: '<div class="tooltip ' + tooltipClass + '"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
			});
		},

		willDestroyElement: function willDestroyElement() {
			this.$().bstooltip('destroy');
		}
	});
});