define('component-library/mixins/reports/timeattendance/download-report-modal-mixin', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var MULTIPLE_EMPLOYEES_SELECTED = 'multipleEmployeesSelected';
	var ALL_EMPLOYEES_SELECTED = 'allEmployeesSelected';

	exports['default'] = _ember['default'].Mixin.create({
		autoSetHeight: false,
		showDownloadError: false,
		payPeriodOptions: null,
		payPeriodSelection: null,
		isSummaryByPayPeriod: true,
		selectedEmployees: [],
		selectedDepartments: [],
		selectedLocations: [],
		isAllEmployeesSelected: true,
		includeContingentWorkers: false,
		eventLogger: _ember['default'].inject.service(),

		showsSelectEmployeesSection: _ember['default'].computed('isSingleEmployee', 'isSummaryByPayPeriod', function () {
			return !this.get('isSummaryByPayPeriod') && !this.get('isSingleEmployee');
		}),

		getDownloadUrl: function getDownloadUrl() {
			if (this.get('isSummaryByPayPeriod')) {
				return this.generateReportByPayPeriodUrl();
			}
			return this.generateReportByDateRangeUrl();
		},

		_hasAllGenericInfo: function _hasAllGenericInfo() {
			var isDownloadingForSingleEmployee = this.get('isSingleEmployee');
			var isDownloadingForAllEmployeesAndFormCompleted = this.get('isAllEmployeesSelected') || !_ember['default'].isEmpty(this.get('selectedEmployees'));
			var haveAllFormData = isDownloadingForSingleEmployee || isDownloadingForAllEmployeesAndFormCompleted;
			return this.get('isDateValid') && haveAllFormData;
		},

		generateCommonQueryParams: function generateCommonQueryParams(includesStartAndEndDates) {
			var queryOptions = {};
			if (includesStartAndEndDates) {
				queryOptions.start_date = this.get('startDate');
				queryOptions.end_date = this.get('endDate');
			}
			queryOptions.employee_ids = this.get('isSingleEmployee') ? [this.get('singleEmployeeID')] : this.get('selectedEmployees').mapBy('id');
			queryOptions.department_ids = this.get('selectedDepartments').mapBy('id');
			queryOptions.location_ids = this.get('selectedLocations').mapBy('id');
			queryOptions.includeContingentWorkers = this.get('includeContingentWorkers');
			return queryOptions;
		},

		// date in filename needs to be represented as 2017-03-01 instead of 2017/03/01
		replaceSlashByDash: function replaceSlashByDash(str) {
			var modifiedString = str.split('/').join('-');
			return modifiedString;
		},

		generateFileName: function generateFileName() {
			var companyName = this.get('companyName');
			var reportTypeName = this.get('modalType');
			switch (reportTypeName) {
				case 'summary':
					reportTypeName = 'Summary';
					break;
				case 'hours':
					reportTypeName = 'Hours';
					break;
				case 'mealBreaks':
					reportTypeName = 'Meal Break Compliance';
					break;
				case 'projectCodes':
					reportTypeName = 'Project Codes';
					break;
				case 'salaryNonExempt':
					reportTypeName = 'Salary Non Exempt';
					break;
				case 'contingentWorker':
					reportTypeName = 'Contingent Workers';
					break;
				default:
					break;
			}
			var fileName = undefined;
			if (this.get('isSummaryByPayPeriod')) {
				// date in payPeriodSelectionName needs to be represented as 2017-03-01 instead of 2017/03/01
				var payPeriodSelectionName = this.replaceSlashByDash(this.get('payPeriodSelection.name'));
				fileName = companyName + ' - Time & Attendance - ' + reportTypeName + ' - ' + payPeriodSelectionName;
			} else {
				var startDate = this.replaceSlashByDash(this.get('startDate'));
				var endDate = this.replaceSlashByDash(this.get('endDate'));
				fileName = companyName + ' - Time & Attendance - ' + reportTypeName + ' - ' + startDate + ' - ' + endDate;
			}
			return fileName;
		},

		actions: {
			download: function download(laddaButton) {
				var _this = this;

				var modalType = this.get('modalType');
				var fileName = this.generateFileName();

				this.get('eventLogger').log('bi_reports', {
					biAppSection: 'premadeReportsTa',
					biAppActionType: 'download',
					reportName: modalType,
					fileType: 'xlsx'
				});

				this.set('showDownloadError', false);
				_ember['default'].ajax({
					url: this.getDownloadUrl(),
					type: 'GET',
					contentType: 'application/json',
					processData: false,
					dataType: 'binary',
					success: function success(data, textStatus, jqXHR) {
						var blob = new Blob([data], { type: jqXHR.getResponseHeader('Content-Type') });
						window.saveAs(blob, fileName + '.xlsx');
						laddaButton.stop();
						_this.send('close');
					},
					error: function error(jqXHR, status, _error) {
						laddaButton.stop();
						_this.set('showDownloadError', true);
					}
				});
			},

			_onToggleDateFormat: function _onToggleDateFormat() {
				this.setProperties({
					selectedEmployees: [],
					radioEmployeeValue: ALL_EMPLOYEES_SELECTED,
					showDownloadError: false,
					isAllEmployeesSelected: true
				});
			}
		}
	});
});