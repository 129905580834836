define('component-library/deductions/review/deductions-lazy-loader', ['exports', 'ember', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryUtilsGlobals) {
	'use strict';

	// Deduction-lazy-loader serves as lazy loader for DeductionsReviewData.
	// We bundle requests together every .
	// Currently, no cache is used.
	exports['default'] = _ember['default'].Object.extend({

		aggregateTime: 1000,
		aggregateNumber: 30,

		requestDeductionsFromServer: function requestDeductionsFromServer() {
			if (this.get('pendingIds.length') === 0) {
				return;
			}
			var self = this;
			var requestingIds = this.get('pendingIds');
			this.set('pendingIds', _ember['default'].A());
			var url = '/api/company/' + this.get('companyId') + '/deductions_review_data?employeeIds=' + requestingIds.join(',');
			if (this.get('reviewStartDate')) {
				url += '&startFrom=' + (0, _componentLibraryUtilsGlobals.moment)(this.get('reviewStartDate')).format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
			}
			_ember['default'].$.getJSON(url).then(function (data) {
				var employeeDeductions = _ember['default'].Map.create();
				data.forEach(function (item, index, enumerable) {
					var employeeId = item.employeeId;
					var deductionType = item.deductionType;
					var isCatchUp = item.isCatchUp;
					var doNotPush = item.doNotPush;
					var isCatchUpContrib = item.isCatchUpContrib;
					var doNotPushContrib = item.doNotPushContrib;
					var map = null;
					if (!employeeDeductions.has(employeeId)) {
						map = _ember['default'].Map.create();
						map.set('discrepancy', 0);
						map.set('discrepancyContrib', 0);
						employeeDeductions.set(employeeId, map);
					} else {
						map = employeeDeductions.get(employeeId);
					}
					map.set(deductionType, item);
					if (isCatchUp && !doNotPush) {
						map.set('discrepancy', map.get('discrepancy') + 1);
					}
					if (isCatchUpContrib && !doNotPushContrib) {
						map.set('discrepancyContrib', map.get('discrepancyContrib') + 1);
					}
				});
				requestingIds.forEach(function (employeeId, index, enumerable) {
					if (!self.get('id2ReviewData').has(employeeId)) {
						return;
					}
					var reviewData = self.get('id2ReviewData').get(employeeId);
					if (!employeeDeductions.has(employeeId)) {
						reviewData.set('discrepancy', null);
						reviewData.set('discrepancyContrib', null);
						return;
					}
					var map = employeeDeductions.get(employeeId);
					self.get('deductionTypes').forEach(function (deductionType, index, enumerable) {
						if (map.has(deductionType)) {
							reviewData.set(deductionType, map.get(deductionType));
						} else {
							reviewData.set(deductionType, null);
						}
					});
					reviewData.set('discrepancy', map.get('discrepancy'));
					reviewData.set('discrepancyContrib', map.get('discrepancyContrib'));
					if (!self.get('pendingIds').contains(employeeId)) {
						self.get('id2ReviewData')['delete'](employeeId);
					}
				});
			});
		},

		clearTimer: function clearTimer() {
			if (this.get('timeoutRequest')) {
				clearTimeout(this.get('timeoutRequest'));
				this.set('timeoutRequest', null);
			}
		},

		loadDeductionsForRow: function loadDeductionsForRow(reviewData, employeeId) {
			if (!employeeId || !reviewData) {
				return;
			}
			// Currenlty, relationship between employeeId and reviewData is 1:1
			// We always update the mapping
			this.get('id2ReviewData').set(employeeId, reviewData);
			// employeeId in pending list can not duplicate
			if (this.get('pendingIds').contains(employeeId)) {
				return;
			}
			this.get('pendingIds').push(employeeId);
			if (!this.get('timeoutRequest')) {
				// For first item in pending list, set up timer to fire request
				var self = this;
				this.set('timeoutRequest', setTimeout(function () {
					// clear timer and fire request
					self.clearTimer();
					self.requestDeductionsFromServer();
				}, this.get('aggregateTime')));
			} else {
				// If there are already 30 employeeIds in pending list, fire request now
				if (this.get('pendingIds.length') >= this.get('aggregateNumber')) {
					// clear timer and fire requst
					this.clearTimer();
					this.requestDeductionsFromServer();
				}
			}
		},

		loadDeductionsForEmployee: function loadDeductionsForEmployee(employee) {
			var employeeId = employee.get('id');
			// TODO: instead of making query again, we should use cache
			var url = '/api/company/' + this.get('companyId') + '/deductions_review_data?employeeIds=' + employeeId;
			if (this.get('reviewStartDate')) {
				url += '&startFrom=' + (0, _componentLibraryUtilsGlobals.moment)(this.get('reviewStartDate')).format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT);
			}
			return _ember['default'].$.getJSON(url);
		}
	});
});