define('component-library/components/address-text-field', ['exports', 'ember'], function (exports, _ember) {
	// DEPRECIATED: DO NOT USE THIS COMPONENT
	//`z-address` is the only supported address component
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		address: (function (key, value, previousValue) {
			// setter
			if (arguments.length > 1 && !this.get('enableAutocomplete')) {
				this.set('value', value);
			}
			// getter
			return this.get('value');
		}).property('value'),
		enableAutocomplete: true,

		fieldMapping: {
			street_number: {
				type: 'short_name',
				key: 'addressStreet'
			},
			route: {
				type: 'long_name',
				key: 'addressStreet'
			},
			locality: {
				type: 'long_name',
				key: 'city'
			},
			administrative_area_level_1: {
				type: 'short_name',
				key: 'state'
			},
			administrative_area_level_2: {
				type: 'short_name',
				key: 'county'
			},
			postal_code: {
				type: 'short_name',
				key: 'zipCode'
			}
		},

		didInsertElement: function didInsertElement() {
			var input = this.$('.js-textField-input').get(0);

			if (window.google && this.get('enableAutocomplete')) {
				var autocomplete = new window.google.maps.places.Autocomplete(input, {
					types: ['geocode']
				});
				window.google.maps.event.addListener(autocomplete, 'place_changed', _ember['default'].run.next(this, this.fillInPhysicalAddress));
				this.set('autocomplete', autocomplete);
			} else {
				this.set('enableAutocomplete', false);
			}
		},

		fillInPhysicalAddress: function fillInPhysicalAddress() {
			var autocomplete = this.get('autocomplete');
			var place = autocomplete.getPlace();
			// sometimes place is empty
			if (!place) {
				return;
			}
			var street = '';
			for (var i = 0; i < place.address_components.length; i++) {
				var component = place.address_components[i];
				var addressType = component.types[0];
				var field = this.fieldMapping[addressType];
				if (!field) {
					continue;
				}
				if (addressType === 'street_number' || addressType === 'route') {
					street += component[field.type] + ' ';
				} else {
					this.set(field.key, component[field.type]);
				}
			}
			this.set('value', street.trim());
			this.set('address', street.trim());
		},

		actions: {
			focusedOut: function focusedOut() {
				// NOTE: important on focus out we need to sync all these value. Google api
				// tries to set the value to the selected long address on blur. There is no
				// way to prevent that behavior. We have to put a hack here to prevent that
				// e.g. 303 2nd Street. San Francisco, CA 94105 -> 303 2nd Street
				var address = this.get('address');
				_ember['default'].run.next(this, function () {
					this.set('value', address);
					this.set('address', address);
					this.$('.js-textField-input').val(address);
				});
			}
		}
	});
});