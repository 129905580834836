define('component-library/components/deductions/deduction-paystubPopover-table-cell', ['exports', 'ember-table/views/table-cell', 'component-library/components/deductions/deduction-paystub-popover', 'component-library/utils/deductions/deductions-utils', 'component-library/templates/components/deductions/deduction-paystubPopover-table-cell'], function (exports, _emberTableViewsTableCell, _componentLibraryComponentsDeductionsDeductionPaystubPopover, _componentLibraryUtilsDeductionsDeductionsUtils, _componentLibraryTemplatesComponentsDeductionsDeductionPaystubPopoverTableCell) {
	'use strict';

	exports['default'] = _emberTableViewsTableCell['default'].extend({
		template: _componentLibraryTemplatesComponentsDeductionsDeductionPaystubPopoverTableCell['default'],
		attributeBindings: ['style'],
		detailPanelPopover: _componentLibraryComponentsDeductionsDeductionPaystubPopover['default'],

		hasHoverEffect: (function () {
			var reasonCode = this.get('row.reasonCode');
			return Boolean(reasonCode) && reasonCode in _componentLibraryUtilsDeductionsDeductionsUtils.manualPaystubReasons;
		}).property('row'),

		cellHoverContent: (function () {
			return this.get('row');
		}).property('row')
	});
});