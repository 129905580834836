define('component-library/components/radio-button', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-radioButton', 'js-glue-radioButton'],
		value: null,
		name: null,
		label: null,

		selectedValue: null,
		_isSelected: false,
		disableInput: false,

		_selectedValueHasChanged: (function () {
			var selectedValue = this.get('selectedValue');
			if (!_ember['default'].isNone(selectedValue) && this.get('value') == selectedValue) {
				this.set('_isSelected', true);
			} else {
				this.set('_isSelected', false);
			}
		}).observes('selectedValue'),

		init: function init() {
			this._super();
			this._selectedValueHasChanged();
		},

		actions: {
			onOptionClick: function onOptionClick() {
				if (!this.get('disableInput')) {
					this.set('_isSelected', this.$('input[type="radio"]').prop('checked'));
					this.set('selectedValue', this.get('value'));
				}
			}
		}
	});
});