define('component-library/components/payroll/cancel-pay-run-modal', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		conditionalCancelMsg: _ember['default'].computed(function () {
			return this.get('isContractorPaymentsCompany') ? 'If you exit, the payment will not be saved. Do you wish to proceed?' : 'If you exit, the pay run will not be saved. Do you wish to proceed?';
		}),
		conditionalExitPay: _ember['default'].computed(function () {
			return this.get('isContractorPaymentsCompany') ? 'Exit Payment' : 'Exit Pay Run';
		}),
		actions: {
			onCloseModal: function onCloseModal() {
				this.attrs.onCloseCancelPayRunModal();
			},
			onCancelPayRun: function onCancelPayRun() {
				this.send('onCloseModal');
				this.attrs.onCancelPayRun();
			}
		}
	});
});