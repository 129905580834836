define('component-library/components/confirmation-modal', ['exports', 'ember', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryComponentsPromiseModal) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'components/confirmation-modal',
		modalDialogClass: 'z-confirmationModal',
		cancelOnBackground: true,
		title: '',
		message: '',
		showIcon: false,
		cancelText: 'Go Back',
		showCancelButton: _ember['default'].computed.bool('cancelText'),
		ctaText: 'Confirm'
	});
});