define('component-library/components/charts/catchup', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		templateName: 'components/charts/catchup',

		graphWidth: 180,
		graphHeight: 220,
		rectMarginTop: 10,
		rectMarginHorizontal: 10,
		maxRectHeight: 140,

		rects: (function () {
			if (!this.get('smartChange')) {
				return _ember['default'].A();
			}
			var expectedTotalAmount = this.get('smartChange.expectedTotalAmount');
			var actualTotalAmount = this.get('smartChange.actualTotalAmount');
			var normalAmount = this.get('smartChange.normalAmount');
			var catchupPayPeriods = this.get('smartChange.catchupPayPeriods');
			var catchupFirstPPAmount = this.get('smartChange.catchupFirstPPAmount');
			var catchupLastPPAmount = this.get('smartChange.catchupLastPPAmount');

			var scale = this.get('maxRectHeight') / Math.max(expectedTotalAmount, actualTotalAmount, normalAmount, normalAmount + catchupFirstPPAmount, normalAmount + catchupLastPPAmount);
			var rectWidth = this.get('graphWidth') / ((catchupPayPeriods + 1) * 2 + 0.5);
			var marginTop = this.get('rectMarginTop');

			var all = _ember['default'].A();
			var rectHeight = scale * expectedTotalAmount;
			all.push({
				x: rectWidth * 0.5,
				y: marginTop + (this.get('maxRectHeight') - rectHeight),
				width: rectWidth,
				height: rectHeight,
				isExpected: true
			});
			rectHeight = scale * actualTotalAmount;
			all.push({
				x: rectWidth,
				y: marginTop + (this.get('maxRectHeight') - rectHeight),
				width: rectWidth,
				height: rectHeight,
				isExpected: false
			});

			var left = rectWidth;
			for (var i = 0; i < catchupPayPeriods; i++) {
				left = left + rectWidth * 1.5;
				rectHeight = scale * normalAmount;
				all.push({
					x: left,
					y: marginTop + (this.get('maxRectHeight') - rectHeight),
					width: rectWidth,
					height: rectHeight,
					isExpected: true
				});
				left = left + rectWidth * 0.5;
				rectHeight = scale * (normalAmount + (i == catchupPayPeriods - 1 ? catchupLastPPAmount : catchupFirstPPAmount));
				all.push({
					x: left,
					y: marginTop + (this.get('maxRectHeight') - rectHeight),
					width: rectWidth,
					height: rectHeight,
					isExpected: false
				});
			}
			return all;
		}).property('smartChange')
	});
});