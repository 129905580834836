define('component-library/components/benefits/timeline/operation-panel', ['exports', 'ember', 'lodash/lodash', 'component-library/utils/globals', 'component-library/templates/components/benefits/timeline/operation-panel', 'component-library/constants/benefits', 'component-library/filters/filter-array', 'component-library/filters/multi-select-filter-spec', 'component-library/filters/single-select-filter-spec', 'component-library/filters/range-filter-spec', 'component-library/components/filter-list-popover'], function (exports, _ember, _lodashLodash, _componentLibraryUtilsGlobals, _componentLibraryTemplatesComponentsBenefitsTimelineOperationPanel, _componentLibraryConstantsBenefits, _componentLibraryFiltersFilterArray, _componentLibraryFiltersMultiSelectFilterSpec, _componentLibraryFiltersSingleSelectFilterSpec, _componentLibraryFiltersRangeFilterSpec, _componentLibraryComponentsFilterListPopover) {
	'use strict';

	var _slicedToArray = (function () {
		function sliceIterator(arr, i) {
			var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;_e = err;
			} finally {
				try {
					if (!_n && _i['return']) _i['return']();
				} finally {
					if (_d) throw _e;
				}
			}return _arr;
		}return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError('Invalid attempt to destructure non-iterable instance');
			}
		};
	})();

	var FilterListPopover = _componentLibraryComponentsFilterListPopover['default'].extend({
		showFilterControls: false
	});

	exports['default'] = _ember['default'].Component.extend({
		layout: _componentLibraryTemplatesComponentsBenefitsTimelineOperationPanel['default'],

		FilterListPopover: FilterListPopover,

		enableCompanySelection: false,
		// Disable filters when detail panel is open
		isDisabled: false,
		enableSepeculativeToggle: false,

		// candidateBenefitsApps: benefitsApps,
		candidateBenefitsCategories: _ember['default'].computed('', function () {
			// TODO: bumper lodash version to support _.concat
			// return _.concat(insuranceBenefitsTypes, flexBenefitsApps);
			return _componentLibraryConstantsBenefits.insuranceBenefitsTypes.concat(_componentLibraryConstantsBenefits.flexBenefitsApps);
		}),

		candidateBenefitsEvents: _componentLibraryConstantsBenefits.benefitsEvents,

		filters: _ember['default'].computed('', function () {
			var CustomFilterArray = _componentLibraryFiltersFilterArray['default'].extend({
				query: (function () {
					var applicableFilters = this.get('applicableFilters');
					var traditionalQuery = {};
					applicableFilters.getEach('query').compact().forEach(function (query) {
						Object.keys(query).forEach(function (key) {
							_ember['default'].merge(traditionalQuery, query);
						});
					});
					return traditionalQuery;
				}).property('applicableFilters.@each.query')
			});
			var CustomMultiSelectFilter = _componentLibraryFiltersMultiSelectFilterSpec['default'].extend({
				query: (function () {
					var query = {};
					query[this.get('filterId')] = this.get('selections').mapBy('text');
					return query;
				}).property('selections', 'filterId'),
				deserialize: function deserialize(serializedSelections) {
					return this.get('options').filter(function (option) {
						return serializedSelections.contains(option.get('text'));
					});
				}
			});
			var CustomSingleSelectFilter = _componentLibraryFiltersSingleSelectFilterSpec['default'].extend({
				query: (function () {
					var query = {};
					query[this.get('filterId')] = this.get('selection.text');
					return query;
				}).property('selection', 'filterId'),
				deserialize: function deserialize(serializedSelection) {
					return this.get('options').findBy('text', serializedSelection);
				}
			});
			var CustomDateRangeFilter = _componentLibraryFiltersRangeFilterSpec['default'].extend({
				query: (function () {
					var query = {};
					query[this.get('filterId')] = [this.get('minValue'), this.get('maxValue')];
					return query;
				}).property('filterId', 'minValue', 'maxValue'),
				deserialize: function deserialize(serializedSelection) {
					var _serializedSelection = _slicedToArray(serializedSelection, 2);

					var startDate = _serializedSelection[0];
					var endDate = _serializedSelection[1];

					return {
						'minVal': startDate,
						'maxVal': endDate
					};
				}
			});
			var content = [CustomMultiSelectFilter.create({
				filterId: 'benefitsCategories',
				name: "Line Of Coverage",
				placeholder: "Filter by Coverage Category",
				options: this.get('candidateBenefitsCategories'),
				optionValuePath: '',
				optionLabelPath: 'label',
				labelPath: 'description'
			}), CustomMultiSelectFilter.create({
				filterId: 'benefitsEvents',
				name: "Benefits Event Type",
				placeholder: "Filter by Benefits Event Type",
				options: _componentLibraryConstantsBenefits.benefitsEvents,
				optionValuePath: '',
				optionLabelPath: 'label',
				labelPath: 'label'
			}), CustomSingleSelectFilter.create({
				filterId: 'sort',
				name: "Sort By",
				options: [_ember['default'].Object.create({ id: 1, text: 'effectiveDate', label: 'Effective Date' }), _ember['default'].Object.create({ id: 2, text: 'creationDate', label: 'Creation Date' })],
				optionValuePath: '',
				optionLabelPath: 'label',
				labelPath: 'label'
			}), CustomDateRangeFilter.create({
				filterId: 'dateRange',
				name: 'Date Range',
				formatAs: 'date'
			})];
			return CustomFilterArray.create({
				content: content
			});
		}),

		filterValue: _ember['default'].computed('filters.query', function () {
			var filterQuery = this.get('filters.query');
			var categorySelections = filterQuery ? _ember['default'].get(filterQuery, 'benefitsCategories') : null;
			var eventSelections = filterQuery ? _ember['default'].get(filterQuery, 'benefitsEvents') : null;
			var sortSelection = filterQuery ? _ember['default'].get(filterQuery, 'sort') : null;

			var _ref = filterQuery ? _ember['default'].getWithDefault(filterQuery, 'dateRange', [null, null]) : [null, null];

			var _ref2 = _slicedToArray(_ref, 2);

			var dateMin = _ref2[0];
			var dateMax = _ref2[1];

			dateMin = dateMin ? dateMin.format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT) : null;
			dateMax = dateMax ? dateMax.format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT) : null;
			var value = {
				'benefitsApps': _lodashLodash['default'].intersection(categorySelections || [], _componentLibraryConstantsBenefits.flexBenefitsApps.mapBy('text')),
				'benefitsTypes': _lodashLodash['default'].intersection(categorySelections || [], _componentLibraryConstantsBenefits.insuranceBenefitsTypes.mapBy('text')),
				'benefitsEvents': eventSelections || [],
				'sort': sortSelection || 'effectiveDate'
			};
			var creationDateMin = null;
			var creationDateMax = null;
			var effectiveDateMin = null;
			var effectiveDateMax = null;

			if (value['sort'] == 'effectiveDate') {
				effectiveDateMin = dateMin;
				effectiveDateMax = dateMax;
			} else {
				creationDateMin = dateMin;
				creationDateMax = dateMax;
			}
			value['creationDateMin'] = creationDateMin;
			value['creationDateMax'] = creationDateMax;
			value['effectiveDateMin'] = effectiveDateMin;
			value['effectiveDateMax'] = effectiveDateMax;
			return value;
		}),

		queryValue: _ember['default'].computed('selected.benefitsApps', 'selected.benefitsTypes', 'selected.benefitsEvents', 'selected.sort', 'selected.creationDateMin', 'selected.creationDateMax', 'selected.effectiveDateMin', 'selected.effectiveDateMax', function () {
			var selected = this.get('selected');
			var appSelections = selected ? _ember['default'].get(selected, 'benefitsApps') : null;
			var typeSelections = selected ? _ember['default'].get(selected, 'benefitsTypes') : null;
			var eventSelections = selected ? _ember['default'].get(selected, 'benefitsEvents') : null;
			var sortSelection = selected ? _ember['default'].get(selected, 'sort') : null;
			var creationDateMin = selected ? _ember['default'].get(selected, 'creationDateMin') : null;
			var creationDateMax = selected ? _ember['default'].get(selected, 'creationDateMax') : null;
			var effectiveDateMin = selected ? _ember['default'].get(selected, 'effectiveDateMin') : null;
			var effectiveDateMax = selected ? _ember['default'].get(selected, 'effectiveDateMax') : null;
			return {
				'benefitsApps': _lodashLodash['default'].intersection((appSelections || []).mapBy('text'), _componentLibraryConstantsBenefits.flexBenefitsApps.mapBy('text')),
				'benefitsTypes': _lodashLodash['default'].intersection((typeSelections || []).mapBy('text'), _componentLibraryConstantsBenefits.insuranceBenefitsTypes.mapBy('text')),
				'benefitsEvents': (eventSelections || []).mapBy('text'),
				'sort': sortSelection || 'creationDate',
				'creationDateMin': creationDateMin ? creationDateMin.format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT) : null,
				'creationDateMax': creationDateMax ? creationDateMax.format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT) : null,
				'effectiveDateMin': effectiveDateMin ? effectiveDateMin.format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT) : null,
				'effectiveDateMax': effectiveDateMax ? effectiveDateMax.format(_componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT) : null
			};
		}),

		isQueryAndFilterSynced: _ember['default'].computed('queryValue', 'filterValue', function () {
			var value1 = this.get('queryValue');
			var value2 = this.get('filterValue');
			return _lodashLodash['default'].isEqual(value1, value2);
		}),

		queryValueChanged: _ember['default'].on('init', _ember['default'].observer('queryValue', function () {
			_ember['default'].run.next(this, 'processQueryValueChange');
		})),

		filterValueChanged: _ember['default'].observer('filterValue', function () {
			_ember['default'].run.next(this, 'processFilterValueChange');
		}),

		processQueryValueChange: function processQueryValueChange() {
			var _this = this;

			if (!this.get('isQueryAndFilterSynced')) {
				(function () {
					var queryValue = _this.get('queryValue');
					var selections = {};
					var appQuery = _ember['default'].get(queryValue, 'benefitsApps');
					var typeQuery = _ember['default'].get(queryValue, 'benefitsTypes');
					var eventQuery = _ember['default'].get(queryValue, 'benefitsEvents');
					var sortQuery = _ember['default'].get(queryValue, 'sort');
					var deactivatedFilterIds = [];
					if (!_ember['default'].isEmpty(appQuery) || !_ember['default'].isEmpty(typeQuery)) {
						selections['benefitsCategories'] = (typeQuery || []).concat(appQuery || []);
					}
					if (_ember['default'].isEmpty(appQuery) && _ember['default'].isEmpty(typeQuery)) {
						deactivatedFilterIds.push('benefitsCategories');
					}
					if (!_ember['default'].isEmpty(eventQuery)) {
						selections['benefitsEvents'] = eventQuery;
					} else {
						deactivatedFilterIds.push('benefitsEvents');
					}
					if (!_ember['default'].isEmpty(sortQuery)) {
						selections['sort'] = sortQuery;
					} else {
						deactivatedFilterIds.push('sort');
					}
					var dateMin = null;
					var dateMax = null;

					if (sortQuery == 'effectiveDate') {
						dateMin = _ember['default'].get(queryValue, 'effectiveDateMin');
						dateMax = _ember['default'].get(queryValue, 'effectiveDateMax');
					} else {
						dateMin = _ember['default'].get(queryValue, 'creationDateMin');
						dateMax = _ember['default'].get(queryValue, 'creationDateMax');
					}
					if (dateMin || dateMax) {
						selections['dateRange'] = [dateMin ? (0, _componentLibraryUtilsGlobals.moment)(dateMin, _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT) : null, dateMax ? (0, _componentLibraryUtilsGlobals.moment)(dateMax, _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT) : null];
					} else {
						deactivatedFilterIds.push('dateRange');
					}
					_this.get('filters').forEach(function (item) {
						return item.set('allSerializedSelections', selections);
					});
					deactivatedFilterIds.forEach(function (item) {
						var filter = _this.get('filters').findBy('filterId', item);
						if (filter) {
							filter.set('isActive', false);
						}
					});
				})();
			}
		},

		processFilterValueChange: function processFilterValueChange() {
			if (!this.get('isQueryAndFilterSynced')) {
				var filterValue = this.get('filterValue');
				var appSelections = _ember['default'].get(filterValue, 'benefitsApps');
				var typeSelections = _ember['default'].get(filterValue, 'benefitsTypes');
				var eventSelections = _ember['default'].get(filterValue, 'benefitsEvents');
				var sortSelection = _ember['default'].get(filterValue, 'sort');
				var creationDateMin = _ember['default'].get(filterValue, 'creationDateMin');
				var creationDateMax = _ember['default'].get(filterValue, 'creationDateMax');
				var effectiveDateMin = _ember['default'].get(filterValue, 'effectiveDateMin');
				var effectiveDateMax = _ember['default'].get(filterValue, 'effectiveDateMax');
				if (_ember['default'].isEmpty(appSelections)) {
					this.set('selected.benefitsApps', null);
				} else {
					this.set('selected.benefitsApps', (0, _componentLibraryConstantsBenefits.getBenefitsAppsFromText)(appSelections));
				}
				if (_ember['default'].isEmpty(typeSelections)) {
					this.set('selected.benefitsTypes', null);
				} else {
					this.set('selected.benefitsTypes', (0, _componentLibraryConstantsBenefits.getBenefitsTypesFromText)(typeSelections));
				}
				if (_ember['default'].isEmpty(eventSelections)) {
					this.set('selected.benefitsEvents', null);
				} else {
					this.set('selected.benefitsEvents', (0, _componentLibraryConstantsBenefits.getBenefitsEventsFromText)(eventSelections));
				}
				this.set('selected.sort', sortSelection);
				if (creationDateMin) {
					this.set('selected.creationDateMin', (0, _componentLibraryUtilsGlobals.moment)(creationDateMin, _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT));
				} else {
					this.set('selected.creationDateMin', null);
				}
				if (creationDateMax) {
					this.set('selected.creationDateMax', (0, _componentLibraryUtilsGlobals.moment)(creationDateMax, _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT));
				} else {
					this.set('selected.creationDateMax', null);
				}
				if (effectiveDateMin) {
					this.set('selected.effectiveDateMin', (0, _componentLibraryUtilsGlobals.moment)(effectiveDateMin, _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT));
				} else {
					this.set('selected.effectiveDateMin', null);
				}
				if (effectiveDateMax) {
					this.set('selected.effectiveDateMax', (0, _componentLibraryUtilsGlobals.moment)(effectiveDateMax, _componentLibraryUtilsGlobals.DATABASE_DATE_FORMAT));
				} else {
					this.set('selected.effectiveDateMax', null);
				}
			}
		}
	});
});