define('component-library/data-table/cells/plan-with-carrier-logo-cell', ['exports', 'ember', 'component-library/data-table/cells/data-table-cell'], function (exports, _ember, _componentLibraryDataTableCellsDataTableCell) {
	'use strict';

	exports['default'] = _componentLibraryDataTableCellsDataTableCell['default'].extend({
		templateName: 'data-table/cells/plan-with-carrier-logo-cell',

		planName: _ember['default'].computed.alias('row.displayName'),
		carrierLogoUrl: _ember['default'].computed.alias('row.carrierLogoUrl'),
		carrierName: _ember['default'].computed.alias('row.carrierDisplayName'),

		treeNodeContent: _ember['default'].computed('row.content.values', function () {
			return this.get('row').content.key + ' Plans (' + this.get('row').content.values.length + ')';
		})
	});
});