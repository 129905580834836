define('component-library/timeline/components/company-health-event', ['exports', 'component-library/timeline/components/copy', 'component-library/timeline/mixins/feed-entry', 'component-library/timeline/utils/status', 'component-library/timeline/utils/computed-copy', 'component-library/utils/tags/context-tag-computed'], function (exports, _componentLibraryTimelineComponentsCopy, _componentLibraryTimelineMixinsFeedEntry, _componentLibraryTimelineUtilsStatus, _componentLibraryTimelineUtilsComputedCopy, _componentLibraryUtilsTagsContextTagComputed) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['', '\'s was approved for ', ' from ', ' CHC during ', ''], ['', '\\\'s was approved for ', ' from ', ' CHC during ', '']),
	    _templateObject2 = _taggedTemplateLiteral(['', '\'s admin has completed their enrollment with ', ' for ', ' coverage'], ['', '\\\'s admin has completed their enrollment with ', ' for ', ' coverage']);

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	exports['default'] = _componentLibraryTimelineComponentsCopy['default'].extend(_componentLibraryTimelineMixinsFeedEntry['default'], {
		copy: _componentLibraryTimelineUtilsComputedCopy['default'].apply(undefined, _toConsumableArray(_componentLibraryTimelineUtilsStatus.companyHealthEventStatusTypes)),
		ENROLL_APPROVED: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject, 'companyStr', 'lineOfCoverageStr', 'carrierNameStr', 'enrollmentTypeStr'),
		ENROLL_COMPLETE: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject2, 'companyStr', 'carrierNameStr', 'lineOfCoverageStr')
	});
});