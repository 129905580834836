define('component-library/components/nested-checkbox', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var NestedCheckbox = _ember['default'].Object.extend({
		isChecked: false,
		name: null,
		value: null,
		children: (function () {
			return _ember['default'].A();
		}).property(),
		showChildrenButtonLabel: '(Show more)',
		hideChildrenButtonLabel: '(Show less)',
		showChildren: false,
		checkboxes: [],
		optional: false,

		checked: (function (key, value, previousValue) {
			if (arguments.length > 1) {
				this.set('isChecked', value);
				this.get('children').setEach('checked', value);
			}
			if (this.get('children').length > 0) {
				var newValue = this.get('children').getEach('checked').reduce(function (previousValue, value) {
					return previousValue && value;
				}, true);
				this.set('isChecked', newValue);
			}
			var checkboxes = this.get('checkboxes');
			for (var i = 0; i < checkboxes.length; i++) {
				if (_ember['default'].get(checkboxes[i], 'name') === this.get('name')) {
					_ember['default'].set(checkboxes[i], 'isChecked', this.get('isChecked'));
				}
			}
			return this.get('isChecked');
		}).property('children.@each.isChecked'),

		childrenVisibilityButtonLabel: (function () {
			var isShown = this.get('showChildren');
			return isShown ? this.get('hideChildrenButtonLabel') : this.get('showChildrenButtonLabel');
		}).property('showChildren')

	});

	exports['default'] = _ember['default'].Component.extend({
		tagName: '',
		// should have url
		checkboxes: [],
		showOptionalCheckboxes: false,
		showOptionalCheckboxesButtonLabel: 'Optional Checkboxes',
		optionalCheckboxesVisible: true,

		_checkboxes: (function () {
			var checkboxes = this.get('checkboxes');
			var optionalCheckboxesVisible = false;
			var emberizedCheckboxes = [];
			if (_ember['default'].isNone(checkboxes)) {
				return _ember['default'].A(emberizedCheckboxes);
			}
			var rollupNameToLeafCheckboxes = {};
			checkboxes.forEach(function (checkbox) {
				optionalCheckboxesVisible |= checkbox.optional;
				if (checkbox.rollupTo === null) {
					emberizedCheckboxes.push(NestedCheckbox.create({
						checkboxes: checkboxes,
						name: checkbox.name,
						value: checkbox.value,
						isChecked: checkbox.isChecked,
						optional: checkbox.optional
					}));
				} else {
					if (!(checkbox.rollupTo in rollupNameToLeafCheckboxes)) {
						rollupNameToLeafCheckboxes[checkbox.rollupTo] = [];
					}
					var leafCheckboxes = rollupNameToLeafCheckboxes[checkbox.rollupTo];
					leafCheckboxes.push(NestedCheckbox.create({
						checkboxes: checkboxes,
						name: checkbox.name,
						value: checkbox.value,
						isChecked: checkbox.isChecked,
						optional: checkbox.optional
					}));
				}
			});
			Object.keys(rollupNameToLeafCheckboxes).forEach(function (rollupName) {
				if (rollupNameToLeafCheckboxes.hasOwnProperty(rollupName)) {
					emberizedCheckboxes.push(NestedCheckbox.create({
						checkboxes: checkboxes,
						name: rollupName,
						children: _ember['default'].A(rollupNameToLeafCheckboxes[rollupName]),
						showChildren: rollupNameToLeafCheckboxes[rollupName].reduce(function (prev, curr) {
							return prev || curr.isChecked;
						}, false),
						optional: rollupNameToLeafCheckboxes[rollupName].reduce(function (prev, curr) {
							return prev && curr.optional;
						}, true)
					}));
				}
			});
			this.set('optionalCheckboxesVisible', optionalCheckboxesVisible);
			return _ember['default'].A(emberizedCheckboxes);
		}).property('checkboxes'),

		actions: {
			toggleChildrenVisibility: function toggleChildrenVisibility(checkbox) {
				var isShown = checkbox.get('showChildren');
				checkbox.set('showChildren', !isShown);
			},
			showOptionalCheckboxesButtonClicked: function showOptionalCheckboxesButtonClicked() {
				this.set('showOptionalCheckboxes', !this.get('showOptionalCheckboxes'));
			}
		}
	});
});