define('component-library/components/deductions/deduction-discrepancyPopover-table-cell', ['exports', 'ember', 'ember-table/views/table-cell', 'component-library/components/deductions/deduction-doNotPush-popover', 'component-library/components/deductions/deduction-catchup-popover', 'component-library/templates/components/deductions/deduction-discrepancyPopover-table-cell'], function (exports, _ember, _emberTableViewsTableCell, _componentLibraryComponentsDeductionsDeductionDoNotPushPopover, _componentLibraryComponentsDeductionsDeductionCatchupPopover, _componentLibraryTemplatesComponentsDeductionsDeductionDiscrepancyPopoverTableCell) {
	'use strict';

	exports['default'] = _emberTableViewsTableCell['default'].extend({
		template: _componentLibraryTemplatesComponentsDeductionsDeductionDiscrepancyPopoverTableCell['default'],
		attributeBindings: ['style'],
		isContribution: _ember['default'].computed.alias('column.isContribution'),
		deductionType: _ember['default'].computed.alias('column.contentPath'),

		detailPanelPopover: (function () {
			if (this.get('doNotPush')) {
				return _componentLibraryComponentsDeductionsDeductionDoNotPushPopover['default'];
			} else if (this.get('isCatchup')) {
				return _componentLibraryComponentsDeductionsDeductionCatchupPopover['default'];
			}
		}).property('isCatchup', 'doNotPush'),

		isCatchup: (function () {
			var keyField = this.get('isContribution') ? 'isCatchUpContrib' : 'isCatchUp';
			var catchupKey = 'row.' + this.get('deductionType') + '.' + keyField;
			return this.get(catchupKey);
		}).property('cellContent', 'isContribution', 'deductionType'),

		doNotPush: (function () {
			var keyField = this.get('isContribution') ? 'doNotPushContrib' : 'doNotPush';
			var doNotPushKey = 'row.' + this.get('deductionType') + '.' + keyField;
			return this.get(doNotPushKey);
		}).property('cellContent', 'isContribution', 'deductionType'),

		hasHoverEffect: _ember['default'].computed.or('isCatchup', 'doNotPush'),

		cellHoverContent: (function () {
			return { 'isContribution': this.get('isContribution'), 'data': this.get('row.' + this.get('deductionType')) };
		}).property('cellContent', 'isContribution', 'deductionType')
	});
});