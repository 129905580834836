define('component-library/components/z-settings-left-navigation', ['exports', 'ember', 'component-library/wizard/settings-setup'], function (exports, _ember, _componentLibraryWizardSettingsSetup) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		wizardFlowState: undefined,
		wizardService: null,
		mode: _componentLibraryWizardSettingsSetup.SETTINGS_SETUP_MODES.SETUP,
		isSetupMode: _ember['default'].computed.equal('mode', _componentLibraryWizardSettingsSetup.SETTINGS_SETUP_MODES.SETUP),

		stateSectionPresenters: _ember['default'].computed('wizardFlowState.sections.[]', 'wizardFlowState.sections.@each.steps', 'wizardFlowState.sections.@each.isActive', 'wizardFlowState.sections.@each.currentStep', function () {
			var stateSectionPresenters = this.get('wizardFlowState.sections').map(function (section) {
				return _ember['default'].Object.create({
					isExpanded: section.get('isActive'),
					section: section,
					steps: section.get('steps')
				});
			});
			return stateSectionPresenters || [];
		}),

		// setup mode - expands the section navigation
		clickedSectionInSetupMode: function clickedSectionInSetupMode(clickedSectionPresenter) {
			if (!clickedSectionPresenter.get('isExpanded')) {
				this.get('stateSectionPresenters').forEach(function (stateSectionPresenters) {
					stateSectionPresenters.set('isExpanded', false);
				});
				clickedSectionPresenter.set('isExpanded', true);
			}

			// transition automatically to the first step in the section
			var section = clickedSectionPresenter.get('section');
			var firstIncompleteStep = section.get('firstIncompleteStep');
			var firstStep = section.get('steps.firstObject');
			if (!firstIncompleteStep && !firstStep.get('isAccessible')) {
				// No-Op
				return;
			}
			this._transitionToStep(firstStep);
		},

		// settings mode - transitions to the section index page
		clickedSectionInSettingsMode: function clickedSectionInSettingsMode(clickedSectionPresenter) {
			var section = clickedSectionPresenter.get('section');
			this._transitionToSection(section);
		},

		_transitionToStep: function _transitionToStep(stepState) {
			var wizardService = this.get('wizardService');
			if (wizardService) {
				var sectionStateModel = stepState.get('section.model');
				wizardService.transitionToStep(stepState, [sectionStateModel]);
			} else {
				this.sendAction('triggerWizardAction', '_transitionToStep', stepState);
			}
		},

		_transitionToSection: function _transitionToSection(sectionState) {
			var wizardService = this.get('wizardService');
			if (wizardService) {
				wizardService.transitionToStep(sectionState);
			} else {
				this.sendAction('triggerWizardAction', '_transitionToStep', sectionState);
			}
		},

		actions: {
			clickedSection: function clickedSection(clickedSectionPresenter) {
				if (this.get('isSetupMode')) {
					this.clickedSectionInSetupMode(clickedSectionPresenter);
				} else {
					this.clickedSectionInSettingsMode(clickedSectionPresenter);
				}
			},

			clickedStep: function clickedStep(stepState) {
				// FYI should NEVER be able to click a step in settings mode so
				// setup mode is implied
				if (!stepState.get('isAccessible')) {
					// No-Op
					return;
				}
				this._transitionToStep(stepState);
			}
		}
	});
});