define('z-inputs/utils/z-text-field-input-mask-mixin-factory', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
    } else {
      return Array.from(arr);
    }
  }

  /**
   * A function that generates a mixin that can be extended to any z-text-field components
   * to reapply the mask when one of the mask options changes
   * @param {object} maskParams - an array of mask params as specified
   in https://github.com/RobinHerbots/Inputmask
   * @return {object} Ember Mixin - a mixin that can be extended to any z-text-field-*
   to reapply the mask when one of the mask options changes
   */
  var zTextFieldInputmaskMixinFactory = function zTextFieldInputmaskMixinFactory(maskParams) {
    return _ember['default'].Mixin.create({
      _currentMaskOptions: {},

      _createMaskOptions: function _createMaskOptions() {
        var _this = this;

        var maskObj = {};
        maskParams.forEach(function (param) {
          maskObj[param] = _this.get(param);
        });
        return maskObj;
      },

      _setCurrentMaskOptions: function _setCurrentMaskOptions() {
        this.set('_currentMaskOptions', this._createMaskOptions());
      },

      _currentMaskOptionsHasChanged: function _currentMaskOptionsHasChanged() {
        var _this2 = this;

        return maskParams.some(function (param) {
          return _this2.get(param) !== _this2.get('_currentMaskOptions.' + param);
        });
      },

      _maskOptionsObserver: _ember['default'].observer.apply(_ember['default'], _toConsumableArray(maskParams).concat([function () {
        if (this._currentMaskOptionsHasChanged()) {
          this._applyMask(this._createMaskOptions());
        }
      }]))
    });
  };

  exports['default'] = zTextFieldInputmaskMixinFactory;
});