define('component-library/components/inbox-action', ['exports', 'ember', 'component-library/modals/inbox-action/sign-form', 'component-library/utils/globals', 'component-library/constants/inbox-actions', 'component-library/mixins/zapp-icon'], function (exports, _ember, _componentLibraryModalsInboxActionSignForm, _componentLibraryUtilsGlobals, _componentLibraryConstantsInboxActions, _componentLibraryMixinsZappIcon) {
	'use strict';

	var inject = _ember['default'].inject;

	exports['default'] = _ember['default'].Component.extend(_componentLibraryMixinsZappIcon['default'], {
		tagName: '',
		fileTypeFileCategory: 'single_value_sub_action_file_type',
		inboxData: inject.service(),
		lazyLoader: inject.service(),

		BANK_ACCOUNT_TYPES: _componentLibraryConstantsInboxActions.BANK_ACCOUNT_TYPES,
		CONTENT_REVIEW_DECISIONS: _componentLibraryConstantsInboxActions.CONTENT_REVIEW_DECISIONS,
		GENDERS: _componentLibraryConstantsInboxActions.GENDERS,
		SIGNATURE_ICON: _componentLibraryConstantsInboxActions.SIGNATURE_ICON,
		STATES: _componentLibraryConstantsInboxActions.STATES,
		showCannotCompleteTextArea: false,
		errorText: '',
		_isLoaded: false,
		employee: null,

		actionCannotBeCompleted: _ember['default'].computed.equal('inboxAction.status', _componentLibraryConstantsInboxActions.INBOX_STATUS_CHOICES.CANNOT_COMPLETE),
		isCompletedOrCannotComplete: _ember['default'].computed.or('inboxAction.isCompleted', 'actionCannotBeCompleted'),
		isPreviewMode: _ember['default'].computed.or('templatePreview', 'createPreview'),

		init: function init() {
			this._super.apply(this, arguments);
			this._fileUploader();
		},

		_fileUploader: function _fileUploader() {
			var lazyLoader = this.get('lazyLoader');
			if (lazyLoader) {
				try {
					lazyLoader.loadBundle('file-uploader');
				} catch (error) {
					// This happens outside of client-app as md5 and dropzone are already loaded under static
					// and there is no need to lazily load them here.
				}
			}
		},

		isOnlyOneSubAction: _ember['default'].computed(function () {
			var inboxSubActionCount = this.get('inboxAction.inboxSubActions.length');
			var typedSubActionCount = this.get('inboxAction.inboxSubActions.firstObject.totalSubActions');
			return inboxSubActionCount === 1 && typedSubActionCount === 1;
		}).property('isMultipleEmployees', 'inboxAction.inboxSubActions.firstObject.totalSubActions'),
		showFooter: _ember['default'].computed('isOnlyOneSubAction', 'inboxAction.hideCannotComplete', 'isCompletedOrCannotComplete', function () {
			return this.get('isOnlyOneSubAction') || !this.get('inboxAction.hideCannotComplete') || this.get('isCompletedOrCannotComplete');
		}),
		showCannotCompeteLink: _ember['default'].computed('showCannotCompleteTextArea', 'isCompletedOrCannotComplete', 'inboxAction.hideCannotComplete', function () {
			return !this.get('showCannotCompleteTextArea') && !this.get('isCompletedOrCannotComplete') && !this.get('inboxAction.hideCannotComplete');
		}),

		// csat related computed properties and functions
		csat: _ember['default'].computed.alias('inboxAction.inboxSubActions.firstObject.csatSubActions.firstObject'),
		isCsat: _ember['default'].computed.bool('csat'),
		starLabelCopy: _ember['default'].computed('csat.rating', function () {
			return _componentLibraryConstantsInboxActions.STAR_RATING_LABEL_MAP[this.get('csat.rating')];
		}),
		starResponseCopy: _ember['default'].computed('csat.rating', function () {
			var rating = this.get('csat.rating');
			if (rating === 5) {
				return "We're happy to hear that! To help us keep up the good work, let us know what we did right.";
			} else if (rating === 4) {
				return 'Thanks! What kept us from a 5-star rating?';
			} else {
				return "We're sorry to hear that. To help us do better next time, please let us know why you were unsatisfied.";
			}
		}),
		responseTimeIsChecked: _ember['default'].computed('csat.selectedFeedbackList', function () {
			return this.get('csat.selectedFeedbackList').indexOf('Response Time') > -1;
		}),
		qualityHelpIsChecked: _ember['default'].computed('csat.selectedFeedbackList', function () {
			return this.get('csat.selectedFeedbackList').indexOf('Quality of Help') > -1;
		}),
		resolutionTimeIsChecked: _ember['default'].computed('csat.selectedFeedbackList', function () {
			return this.get('csat.selectedFeedbackList').indexOf('Resolution Time') > -1;
		}),

		onlySubAction: _ember['default'].computed('isOnlyOneSubAction', function () {
			if (!this.get('isOnlyOneSubAction')) {
				return null;
			}

			var inboxAction = this.get('inboxAction');
			return inboxAction.get('subActionTypes').reduce(function (accum1, subActionType) {
				return accum1.pushObjects(inboxAction.get('inboxSubActions').reduce(function (accum2, inboxSubAction) {
					return accum2.pushObjects(inboxSubAction.get(subActionType).toArray());
				}, []));
			}, []).get('firstObject');
		}),

		// needed for ZAppIcon mixin
		inboxItem: _ember['default'].computed.alias('inboxAction'),

		completedBySelf: _ember['default'].computed('inboxAction.completedBy', 'employee', function () {
			var completedById = this.get('inboxAction.completedBy.id');
			return !_ember['default'].isNone(completedById) && completedById === this.get('employee.id');
		}),
		markedAsCopy: _ember['default'].computed('isCompletedOrCannotComplete', 'inboxAction.completedBy', function () {
			var copy = this.get('actionCannotBeCompleted') ? 'Marked as "Cannot Complete"' : 'Completed';
			return this.get('inboxAction.completedBy') ? copy + ' by' : copy;
		}),

		markedByCopy: _ember['default'].computed('inboxAction.completionDate', 'inboxAction.completedBy.fullName', 'completedBySelf', function () {
			var date = this.get('inboxAction.completionDate');
			var formattedDate = date && (0, _componentLibraryUtilsGlobals.moment)(date).format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
			var name = this.get('completedBySelf') ? 'You' : this.get('inboxAction.completedBy.fullName');
			return name ? name + ' on ' + formattedDate : 'on ' + formattedDate;
		}),
		defaultCompletedCopy: _ember['default'].computed('actionCannotBeCompleted', function () {
			var cannotCompleteCopy = 'This task has been marked as "Cannot Complete"';
			var completedCopy = 'This task has been completed';
			return this.get('actionCannotBeCompleted') ? cannotCompleteCopy : completedCopy;
		}),

		isLoaded: (function () {
			var _this = this;

			if (this.get('templatePreview')) {
				return true;
			}

			if (this.get('_isLoaded')) {
				return true;
			}
			var inboxAction = this.get('inboxAction');
			_ember['default'].RSVP.all(inboxAction.get('inboxSubActions').toArray()).then(function (inboxSubActions) {
				return _ember['default'].RSVP.all(inboxAction.get('subActionTypes').reduce(function (accum1, subActionType) {
					return accum1.pushObjects(inboxSubActions.reduce(function (accum2, inboxSubAction) {
						return accum2.pushObjects(inboxSubAction.get(subActionType));
					}, []));
				}, []));
			}).then(function () {
				return inboxAction.get('inboxAttachments');
			}).then(function () {
				if (!_this.get('isPreviewMode')) {
					_this.incrementProperty('inboxAction.viewCount');
					_this.get('inboxAction').save().then(function () {
						// update unread status for main nav
						_this.get('inboxData').refreshInboxActions();
					});
					// save inboxAction async and don't rely on it before returning dashboard employee
					return _ember['default'].RSVP.resolve((0, _componentLibraryUtilsGlobals.dashboard)('employee'));
				}
			}).then(function (employee) {
				_this.set('employee', employee);
				var inboxAction = _this.get('inboxAction');
				inboxAction.get('inboxSubActions').forEach(function (inboxSubAction) {
					inboxAction.get('subActionTypes').forEach(function (subActionType) {
						inboxSubAction.get(subActionType).forEach(function (typedSubAction) {
							return typedSubAction.setDefaultValue();
						});
					});
				});
				_this.set('_isLoaded', true);
			});
			return this.get('_isLoaded');
		}).property('_isLoaded'),

		_completeSubAction: function _completeSubAction(subAction) {
			var _this2 = this;

			if (this.get('isPreviewMode')) {
				return new _ember['default'].RSVP.Promise(function (resolve, reject) {
					reject();
				});
			}
			subAction.set('errorText', undefined);
			if (!subAction.validate()) {
				// this could be better somehow...
				subAction.set('errorText', 'Please enter a valid value.');
				this.set('errorText', 'Please complete all required tasks. You may need to scroll down to view them.');
				return new _ember['default'].RSVP.Promise(function (resolve, reject) {
					reject();
				});
			}
			if (this.get('isCsat')) {
				this.sendAction('logStarRating');
			}
			return subAction.setProperties({
				isCompleted: true,
				completedBy: this.get('employee'),
				completionDate: (0, _componentLibraryUtilsGlobals.moment)().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT)
			}).save().then(function () {
				_this2.set('errorText', '');
				return _this2.get('inboxAction').reload();
			}).then(function (inboxAction) {
				if (inboxAction.get('errorMsg')) {
					_this2.set('errorText', inboxAction.get('errorMsg'));
					inboxAction.get('subActionTypes').reduce(function (accum1, subActionType) {
						return accum1.pushObjects(inboxAction.get('inboxSubActions').reduce(function (accum2, inboxSubAction) {
							return accum2.pushObjects(inboxSubAction.get(subActionType).map(function (subAction) {
								return subAction.reload();
							}));
						}, []));
					}, []);
				}
			}).then(function () {
				_this2.eventLogger.log('inbox-action-save-sub-action', {
					// don't log title, could contain sensitive info
					inboxActionId: _this2.get('inboxAction.id'),
					companyId: _this2.get('inboxAction.company.id'),
					subActionId: subAction.get('id'),
					subActionType: subAction.constructor.toString().substring(4)
				});
			}).then(function () {
				return _this2.get('employee').reload();
			});
		},

		_redirect: function _redirect(redirectUrl) {
			if (this.get('isPreviewMode')) {
				return;
			}
			if (redirectUrl.match(/https?:\/\//)) {
				window.location.replace(redirectUrl);
			} else {
				this.sendAction(_componentLibraryConstantsInboxActions.INBOX_REDIRECT, redirectUrl);
			}
		},

		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
			this.setZappIconPath();
		},

		actions: {
			cannotComplete: function cannotComplete() {
				this.set('errorText', '');
				this.toggleProperty('showCannotCompleteTextArea');
			},
			signForm: function signForm(signatureSubAction) {
				if (this.get('isPreviewMode')) {
					return;
				}
				_componentLibraryModalsInboxActionSignForm['default'].open({
					subAction: signatureSubAction
				});
			},
			onFileUploadSuccess: function onFileUploadSuccess(singleValueSubAction, file, data) {
				if (this.get('isPreviewMode')) {
					return;
				}
				singleValueSubAction.set('value', data.url);
			},
			onFileUploadError: function onFileUploadError(file, error) {
				this.eventLogger.log('inbox-action-single-value-sub-action-file-upload-error', {
					message: "Error uploading file: " + error.toString()
				});
			},
			// TODO (bmcgue): remove `redirect` action
			redirect: function redirect(subAction) {
				this._redirect(subAction.get('redirectUrl'));
			},
			redirectPrimary: function redirectPrimary(subAction) {
				this._redirect(subAction.get('ctaInfoJson.primary.redirectUrl'));
			},
			redirectSecondary: function redirectSecondary(subAction) {
				this._redirect(subAction.get('ctaInfoJson.secondary.redirectUrl'));
			},
			redirectTertiary: function redirectTertiary(subAction) {
				this._redirect(subAction.get('ctaInfoJson.tertiary.redirectUrl'));
			},
			saveCannotCompleteFeedback: function saveCannotCompleteFeedback(button) {
				var _this3 = this;

				if (this.get('isPreviewMode')) {
					button.stop();
					return;
				}
				if (_ember['default'].isEmpty(this.get('inboxAction.cannotCompleteReason'))) {
					this.set('errorText', 'Please explain why you are not able to complete this request');
					button.stop();
					return;
				}
				this.set('errorText', '');
				return this.get('inboxAction').setProperties({
					completedBy: this.get('employee'),
					completionDate: (0, _componentLibraryUtilsGlobals.moment)(),
					status: _componentLibraryConstantsInboxActions.INBOX_STATUS_CHOICES.CANNOT_COMPLETE
				}).save().then(function () {
					button.stop();
					_this3.send('cannotComplete');

					_this3.eventLogger.log('inbox-action-submit-cannot-complete-action-feedback', {
						inboxActionId: _this3.get('inboxAction.id'),
						reason: _this3.get('inboxAction.cannotCompleteReason')
					});
				});
			},
			// TODO (bmcgue): remove `completeSubActionTrue` action
			completeSubActionTrue: function completeSubActionTrue(subAction) {
				subAction.set('value', true);
				return this._completeSubAction(subAction);
			},
			// TODO (bmcgue): remove `completeSubActionFalse` action
			completeSubActionFalse: function completeSubActionFalse(subAction) {
				subAction.set('value', false);
				return this._completeSubAction(subAction);
			},
			completeSubActionPrimary: function completeSubActionPrimary(subAction) {
				subAction.set('value', subAction.get('ctaInfoJson.primary.value'));
				return this._completeSubAction(subAction);
			},
			completeSubActionSecondary: function completeSubActionSecondary(subAction) {
				subAction.set('value', subAction.get('ctaInfoJson.secondary.value'));
				return this._completeSubAction(subAction);
			},
			completeSubActionTertiary: function completeSubActionTertiary(subAction) {
				subAction.set('value', subAction.get('ctaInfoJson.tertiary.value'));
				return this._completeSubAction(subAction);
			},
			completeSubAction: function completeSubAction(subAction) {
				return this._completeSubAction(subAction);
			},
			back: function back() {
				return this.sendAction('inboxActionBack');
			},
			feedbackSelected: function feedbackSelected(addOption, option) {
				var selectedFeedbackListCopy = this.get('csat.selectedFeedbackList').copy();
				if (addOption) {
					selectedFeedbackListCopy.pushObject(option);
				} else {
					selectedFeedbackListCopy.removeObject(option);
				}
				this.set('csat.selectedFeedbackList', selectedFeedbackListCopy);
			},
			logStarRating: function logStarRating() {
				var csat = this.get('csat');
				this.eventLogger.log('csat-rating-change', {
					// don't log title, could contain sensitive info
					inboxActionId: this.get('inboxAction.id'),
					ownerIds: this.get('inboxAction.inboxOwners').mapBy('owner.id'),
					subActionType: 'CsatSubAction',
					subActionId: csat.get('id'),
					rating: csat.get('rating'),
					selectedFeedback: csat.get('selectedFeedback'),
					additionalFeedback: csat.get('additionalFeedback')
				});
			}
		}
	});
});