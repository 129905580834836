define('component-library/mixins/reports/timeattendance/reports-page-controller-mixin', ['exports', 'ember', 'component-library/utils/globals', 'component-library/components/reports/timeattendance/download-summary-report-modal', 'component-library/components/reports/timeattendance/download-project-code-report-modal', 'component-library/components/reports/timeattendance/download-contingent-worker-report-modal'], function (exports, _ember, _componentLibraryUtilsGlobals, _componentLibraryComponentsReportsTimeattendanceDownloadSummaryReportModal, _componentLibraryComponentsReportsTimeattendanceDownloadProjectCodeReportModal, _componentLibraryComponentsReportsTimeattendanceDownloadContingentWorkerReportModal) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		taCompany: _ember['default'].computed.alias('model.taCompany'),
		switches: _ember['default'].inject.service(),

		payPeriods: (function () {
			var pps = this.get('model._payPeriods');

			var startDates = pps.map(function (entry) {
				var startDate = entry.get('startDate');
				var endDate = entry.get('endDate');
				var eStartDate = _ember['default'].Object.create({
					name: startDate + ' - ' + endDate,
					id: entry.get('id'),
					model: entry
				});
				return eStartDate;
			});

			return startDates;
		}).property('model._payPeriods.@each.startDate', 'model._payPeriods.@each.endDate'),

		singleEmployeeID: (function () {
			return this.get('model.employee') && this.get('model.employee').get('id');
		}).property('model.employee'),

		contractors: _ember['default'].computed('reportToEmployees', function () {
			return this.get('reportToEmployees').filter(function (ee) {
				return ee.get('workerType');
			});
		}),

		allEmployees: _ember['default'].computed('reportToEmployees', function () {
			return this.get('reportToEmployees').filter(function (ee) {
				return !ee.get('workerType');
			});
		}),

		showsMealBreaksCard: _ember['default'].computed.alias('taCompany.hasMealBreaks'),
		actions: {
			openModal: function openModal(modalType) {
				var Modal = undefined;
				var defaultOptions = {
					payPeriodOptions: this.get('payPeriods'),
					startDate: this.get('startDate'),
					endDate: this.get('endDate'),
					employee: this.get('model.employee'), // for employee reports page
					singleEmployeeID: this.get('singleEmployeeID'), // for employee reports page
					isSingleEmployee: this.get('isSingleEmployee'), // for employee reports page
					allEmployees: this.get('allEmployees'),
					isAllEmployeesSelected: this.get('isAllEmployeesSelected'),
					contractors: this.get('contractors'),
					isApproverViewing: this.get('isApproverViewing'),
					companyName: this.get('model.taCompany.company.name')
				};
				var moreOptions = {};
				switch (modalType) {
					case 'summary':
						Modal = _componentLibraryComponentsReportsTimeattendanceDownloadSummaryReportModal['default'];
						moreOptions.modalType = 'summary';
						break;
					case 'hours':
						Modal = _componentLibraryComponentsReportsTimeattendanceDownloadSummaryReportModal['default'];
						moreOptions.modalType = 'hours';
						break;
					case 'mealBreaks':
						Modal = _componentLibraryComponentsReportsTimeattendanceDownloadSummaryReportModal['default'];
						moreOptions.modalType = 'mealBreaks';
						break;
					case 'projectCodes':
						Modal = _componentLibraryComponentsReportsTimeattendanceDownloadProjectCodeReportModal['default'];
						moreOptions.allProjectCodesAndLaborGroups = this.get('allProjectCodesAndLaborGroups');
						break;
					case 'salaryNonExempt':
						Modal = _componentLibraryComponentsReportsTimeattendanceDownloadSummaryReportModal['default'];
						moreOptions.modalType = 'salaryNonExempt';
						break;
					case 'contingentWorker':
						Modal = _componentLibraryComponentsReportsTimeattendanceDownloadContingentWorkerReportModal['default'];
						moreOptions.modalType = 'contingentWorker';
						break;
					default:
						break;
				}

				Modal.show(Object.assign(defaultOptions, moreOptions));
			}
		}
	});
});