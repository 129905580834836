define('component-library/components/z-sic-selector', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var MODES = {
		DATABASE: 'database',
		COMMON: 'common',
		ENTRY: 'entry'
	};

	var MAX_COMMON_CODES = 8;

	exports['default'] = _ember['default'].Component.extend({
		value: null,
		errorText: null,
		hasValidSICCode: false,

		mode: MODES.ENTRY,

		_modeDatabase: MODES.DATABASE,
		_modeCommon: MODES.COMMON,
		_modeEntry: MODES.ENTRY,

		_isDatabaseMode: _ember['default'].computed.equal('mode', MODES.DATABASE),
		_isCommonMode: _ember['default'].computed.equal('mode', MODES.COMMON),
		_isEntryMode: _ember['default'].computed.equal('mode', MODES.ENTRY),

		_sicCodeTree: {},

		_sicCodeLookupHash: (function () {
			var sicCodeTree = this.get('_sicCodeTree');
			var sicCodeLookupHash = {};
			sicCodeTree.divisions = sicCodeTree.divisions || [];
			sicCodeTree.divisions.forEach(function (division) {
				division.groups.forEach(function (group) {
					group.industries.forEach(function (industry) {
						industry.codes.forEach(function (code) {
							sicCodeLookupHash[code.value] = _ember['default'].Object.create({
								division: division,
								group: group,
								industry: industry,
								specialization: code
							});
						});
					});
				});
			});
			return sicCodeLookupHash;
		}).property('_sicCodeTree'),

		_selectedSicCodeTaxonomy: (function () {
			return this.get('_sicCodeLookupHash')[this.get('value')] || null;
		}).property('value', '_sicCodeLookupHash'),

		/* Database Mode */

		_dbSelectedDivision: null,
		_dbSelectedGroup: null,
		_dbSelectedIndustry: null,
		_dbSelectedSpecialization: null,

		_possibleDivisions: (function () {
			var divisions = this.get('_sicCodeTree').divisions;
			return divisions ? [''].concat(divisions) : []; // blank option prepended
		}).property('_sicCodeTree'),

		_possibleGroups: (function () {
			var selectedDivision = this.get('_dbSelectedDivision');
			if (_ember['default'].isNone(selectedDivision)) {
				return [];
			}

			var groups = this._getSicCodeTreeBranch(selectedDivision).groups;
			return groups ? [''].concat(groups) : []; // blank option prepended
		}).property('_dbSelectedDivision', '_sicCodeTree'),

		_possibleIndustries: (function () {
			var selectedDivision = this.get('_dbSelectedDivision');
			var selectedGroup = this.get('_dbSelectedGroup');
			if (_ember['default'].isNone(selectedDivision) || _ember['default'].isNone(selectedGroup)) {
				return [];
			}

			var industries = this._getSicCodeTreeBranch(selectedDivision, selectedGroup).industries;
			return industries ? [''].concat(industries) : []; // blank option prepended
		}).property('_dbSelectedDivision', '_dbSelectedGroup', '_sicCodeTree'),

		_possibleSpecializations: (function () {
			var selectedDivision = this.get('_dbSelectedDivision');
			var selectedGroup = this.get('_dbSelectedGroup');
			var selectedIndustry = this.get('_dbSelectedIndustry');

			if (_ember['default'].isNone(selectedDivision) || _ember['default'].isNone(selectedGroup) || _ember['default'].isNone(selectedIndustry)) {
				return [];
			}

			var specializations = this._getSicCodeTreeBranch(selectedDivision, selectedGroup, selectedIndustry).codes;
			return specializations ? [''].concat(specializations) : []; // blank option prepended
		}).property('_dbSelectedDivision', '_dbSelectedGroup', ',_dbSelectedIndustry', '_sicCodeTree'),

		_dbSelectedSpecializationChanged: (function () {
			var dbSelectedSpecialization = this.get('_dbSelectedSpecialization');
			if (!dbSelectedSpecialization) {
				return;
			}

			var newValue = dbSelectedSpecialization.value;
			if (this.get('value') != newValue) {
				this.set('value', newValue);
			}
		}).observes('_dbSelectedSpecialization'),

		/* Common Mode */

		_commonSicCodes: (function () {
			var sicCodeTree = this.get('_sicCodeTree');
			return sicCodeTree.commonCodes ? sicCodeTree.commonCodes.slice(0, MAX_COMMON_CODES) : [];
		}).property('_sicCodeTree'),

		/* Misc */

		didInsertElement: function didInsertElement() {
			var _this = this;

			_ember['default'].getJSON('/custom_api/get_all_sic_codes').then(function (sicCodeTree) {
				_this.set('_sicCodeTree', sicCodeTree);
			}).then(function () {
				_this._attachHoverHandlers();
				_this._sicCodeDidChange();
			});
		},

		_attachHoverHandlers: function _attachHoverHandlers() {
			// c for component
			var self = this;

			this.$('.js-resizeOnHover').on({
				// cant use fat arrows here b/c we're passing in an object to `on`
				// using e.target instead of `this` to cut down on `this` confusion
				mouseenter: function mouseenter(e) {
					var $section = self.$(e.currentTarget);
					if ($section.attr('is-expandable') == 'expandable') {
						$section.addClass('is-expanded');
						$section.siblings().addClass('is-shrunk');
					} else {
						$section.addClass('is-shrunk');
						$section.siblings(':not([is-expandable=expandable])').addClass('is-shrunk');
						$section.siblings('[is-expandable=expandable]').addClass('is-shrunk');
						$section.siblings('[is-expandable=expandable]').last().removeClass('is-shrunk').addClass('is-expanded');
					}
				},
				mouseleave: function mouseleave(e) {
					var $section = self.$(e.currentTarget);
					$section.removeClass('is-expanded is-shrunk');
					$section.siblings().removeClass('is-expanded is-shrunk');
				}
			});
		},

		_getSicCodeTreeBranch: function _getSicCodeTreeBranch(selectedDivision, selectedGroup, selectedIndustry) {
			var traversedTree = this.get('_sicCodeTree');

			if (!selectedDivision || !traversedTree.divisions) {
				return traversedTree;
			}

			traversedTree = traversedTree.divisions.find(function (division) {
				return division.name == selectedDivision.name;
			}) || {};

			if (!selectedGroup || !traversedTree.groups) {
				return traversedTree;
			}

			traversedTree = traversedTree.groups.find(function (group) {
				return group.name == selectedGroup.name;
			}) || {};

			if (!selectedIndustry || !traversedTree.industries) {
				return traversedTree;
			}

			traversedTree = traversedTree.industries.find(function (industry) {
				return industry.name == selectedIndustry.name;
			}) || {};

			return traversedTree;
		},

		_sicCodeDidChange: (function () {
			if (this.get('value') && this.get('value').length != 4) {
				this.set('hasValidSICCode', false);
				return;
			}

			var selectedSicCodeTaxonomy = this.get('_selectedSicCodeTaxonomy');
			if (!selectedSicCodeTaxonomy) {
				this.setProperties({
					_dbSelectedDivision: null,
					_dbSelectedGroup: null,
					_dbSelectedIndustry: null,
					_dbSelectedSpecialization: null,
					errorText: 'Invalid SIC code',
					hasValidSICCode: false
				});
			} else {
				this.setProperties({
					_dbSelectedDivision: selectedSicCodeTaxonomy.division,
					_dbSelectedGroup: selectedSicCodeTaxonomy.group,
					_dbSelectedIndustry: selectedSicCodeTaxonomy.industry,
					_dbSelectedSpecialization: selectedSicCodeTaxonomy.specialization,
					errorText: null,
					hasValidSICCode: true
				});
			}
		}).observes('value'),

		actions: {
			selectMode: function selectMode(mode) {
				this.set('mode', mode);
			},

			selectCommonSicCode: function selectCommonSicCode(commonSicCode) {
				this.set('value', commonSicCode.value);
			}
		}
	});
});