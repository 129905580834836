define('component-library/components/filter-list-popover', ['exports', 'ember', 'component-library/components/promise-popover', 'component-library/mixins/adjust-popover-height'], function (exports, _ember, _componentLibraryComponentsPromisePopover, _componentLibraryMixinsAdjustPopoverHeight) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromisePopover['default'].extend(_componentLibraryMixinsAdjustPopoverHeight['default'], {
		templateName: 'components/filter-list-popover',
		placement: 'bottom',
		target: _ember['default'].computed.oneWay('model.target'),
		attachment: "top left",
		targetAttachment: "bottom left",
		filters: _ember['default'].computed.alias('content'),
		showFilterControls: true,
		filterAdded: 'close',
		constraints: [{ to: 'scrollParent', pin: ['left'] }]
	});
});