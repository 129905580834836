define('ember-cli-bundle-loader/utils/load-assets', ['exports', 'ember', 'ember-cli-bundle-loader/utils/-resource-helper'], function (exports, _ember, _emberCliBundleLoaderUtilsResourceHelper) {
  'use strict';

  exports.singleInflightPromise = singleInflightPromise;
  exports.loadScript = loadScript;
  exports.loadStylesheet = loadStylesheet;
  exports['default'] = loadAssets;

  var _Ember$RSVP = _ember['default'].RSVP;
  var all = _Ember$RSVP.all;
  var resolve = _Ember$RSVP.resolve;
  var inFlightPromises = {};

  function singleInflightPromise(key, promiseGenerator) {
    if (inFlightPromises[key]) {
      // We already have a promise for that script, return it.
      return inFlightPromises[key];
    }
    var promise = promiseGenerator();
    inFlightPromises[key] = promise;
    // When the promise is either resolved/rejected, we allow for another request
    // NOTE: we only want to prevent inflight promises, but downloading the same
    // asset more than once may be ok. lazyLoader.loadBundle already check that the
    // bundle isn't loaded before attempting to loadAssets
    promise['finally'](function () {
      return inFlightPromises[key] = undefined;
    });
    return promise;
  }

  function loadScript(url) {
    return singleInflightPromise(url, function () {
      if (_emberCliBundleLoaderUtilsResourceHelper['default'].isJavascriptLoaded(url)) {
        return resolve();
      }
      return _emberCliBundleLoaderUtilsResourceHelper['default'].loadJavascript(url);
    });
  }

  function loadStylesheet(url) {
    return singleInflightPromise(url, function () {
      if (_emberCliBundleLoaderUtilsResourceHelper['default'].isStylesheetLoaded(url)) {
        return resolve();
      }
      return _emberCliBundleLoaderUtilsResourceHelper['default'].loadStylesheet(url);
    });
  }

  function loadAssets() {
    var urls = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

    urls = urls || [];
    var promises = urls.map(function (url) {
      return url.match(/\.js$/) ? loadScript(url) : url.match(/\.css$/) ? loadStylesheet(url) : _ember['default'].RSVP.reject('The specified url (' + url + ') doesnt match any of the expected types');
    });
    return all(promises);
  }
});