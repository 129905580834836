define('component-library/timeline/mixins/pto-feed-entry', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({

		user: (function () {
			var rId = this.get('data.operatedBy.id');
			var eId = this.get('employee.id');
			if (rId && eId && rId === eId) {
				return "you";
			} else if (this.get('data.operatedByTitle') === null || this.get('data.operatedByTitle') === 'admin' && this.get('data.operatedBy.fullName') === 'Admin User') {
				return this.get('data.operatedBy.fullName');
			} else {
				return this.get('data.operatedByTitle') + " " + this.get('data.operatedBy.fullName');
			}
		}).property('data.requestedBy.id', 'employee.id', 'data.operatedBy.fullName', 'data.operatedByTitle'),

		employeePossessive: (function () {
			var currentUserId = this.get('employee.id');
			var employeeId = this.get('data.employee.id');
			var operatorId = this.get('data.operatedBy.id');
			if (currentUserId && employeeId && currentUserId === employeeId) {
				return "your";
			} else if (operatorId && employeeId && operatorId === employeeId) {
				return "their";
			} else {
				return this.get('employeeName') + "'s";
			}
		}).property('employee.id', 'data.employee.id', 'data.operatedBy.id', 'employeeName'),

		capitalizeFirstLetter: function capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}

	});
});