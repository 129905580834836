define('component-library/components/carousel-item', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['carouselBox-item'],
		value: null,

		click: function click() {
			this.sendAction("selectedCarouselItem", this.get('value'));
		}
	});
});