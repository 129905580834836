define('component-library/components/cards/payroll/tax-header-accordion-card', ['exports', 'ember', 'component-library/components/cards/card-base', 'component-library/constants/z-payroll/service-levels', 'component-library/components/notification-modal', 'ui-infra/utils/sanitize-html', 'component-library/constants/z-payroll/tax-header-const', 'component-library/mixins/payroll/tax-header-mixin'], function (exports, _ember, _componentLibraryComponentsCardsCardBase, _componentLibraryConstantsZPayrollServiceLevels, _componentLibraryComponentsNotificationModal, _uiInfraUtilsSanitizeHtml, _componentLibraryConstantsZPayrollTaxHeaderConst, _componentLibraryMixinsPayrollTaxHeaderMixin) {
	'use strict';

	exports['default'] = _componentLibraryComponentsCardsCardBase['default'].extend(_componentLibraryMixinsPayrollTaxHeaderMixin['default'], {

		actions: {
			updateLevelOfService: function updateLevelOfService(taxHeader) {
				this.attrs.updateLevelOfService(taxHeader);
			},

			updateAnswerEffective: function updateAnswerEffective(taxHeader, question) {
				this.attrs.updateAnswerEffective(taxHeader, question);
			},

			viewHistoricalAgencyInfo: function viewHistoricalAgencyInfo(taxHeader, agencyInfoKey, agencyInfoKeyName) {
				this.attrs.viewHistoricalAgencyInfo(taxHeader, agencyInfoKey, agencyInfoKeyName);
			},

			viewContinuousReconHistory: function viewContinuousReconHistory(taxHeader, taxType) {
				this.attrs.viewContinuousReconHistory(taxHeader, taxType);
			},

			changeAgencyStatus: function changeAgencyStatus(laddaButton) {
				var _this = this;

				var agencyInfo = {};
				if (this.get('status') != 'active') {
					agencyInfo['status'] = 'active';
				} else {
					agencyInfo['status'] = 'inactive';
				}

				var postObj = {
					company_id: this.get('company.id'),
					agencyId: this.get('title'),
					agencyInfo: agencyInfo
				};
				_ember['default'].ajaxPost('/custom_api/z_payroll/createOrUpdateAgencyInfo/', JSON.stringify(postObj)).then(function (result) {
					if (result.status === 'success') {
						_this.showAgencyStatusChangeNotification();
						// this.send('cancel');
					} else {
							_ember['default'].RSVP.reject();
						}
				})['catch'](function (err) {
					_this.showAgencyStatusChangeFailureNotification();
				})['finally'](function () {
					// laddaButton.stop();
				});
				return _ember['default'].RSVP.resolve();
			},

			save: function save(laddaButton) {
				var _this2 = this;

				var hasWcError = this.validateWcRates();
				var hasRateError = this.validateRates();
				if (hasRateError || hasWcError) {
					laddaButton.stop();
					return;
				}

				var agencyInfo = {};
				this.get('taxHeader.questions').forEach(function (question) {
					var answer = [];
					if (question.questionType == 'Tax Rate') {
						var rateObj = question.answer[0];
						answer.pushObject({
							taxType: question.taxType,
							taxPayee: rateObj.taxPayee,
							taxRate: rateObj.rate
						});
						var futureTax = rateObj.futureStateTax;
						if (futureTax) {
							answer.pushObject({
								taxType: question.taxType,
								taxPayee: futureTax.taxPayee,
								taxRate: futureTax.rate,
								effectiveDate: futureTax.date,
								shouldDelete: futureTax.shouldDelete
							});
						}
					} else if (question.questionType == 'Workers Comp Tax Rate') {
						(function () {
							var codeSet = _this2.getWcClassCode();
							question.answer.forEach(function (record) {
								// prevent deleteing invalid tax rates
								if (codeSet.has(record.classCode) || !record.shouldDelete) {
									answer.pushObject({
										taxType: question.taxType,
										taxPayee: 'EE',
										taxRate: record.employeeRate * 100,
										workersCompClassCode: record.classCode,
										shouldDelete: record.shouldDelete
									});
									answer.pushObject({
										taxType: question.taxType,
										taxPayee: 'ER',
										taxRate: (record.hourlyRate - record.employeeRate) * 100,
										workersCompClassCode: record.classCode,
										shouldDelete: record.shouldDelete
									});
								}
							});
						})();
					} else {
						answer = question.answer;
					}
					agencyInfo[question.storageKey] = {
						minLength: question.minLength,
						maxLength: question.maxLength,
						minValue: question.minValue,
						maxValue: question.maxValue,
						answer: answer,
						isRequired: question.isRequired
					};
				});

				var postObj = {
					company_id: this.get('company.id'),
					agencyId: this.get('title'),
					agencyInfo: agencyInfo
				};
				_ember['default'].ajaxPost('/custom_api/z_payroll/createOrUpdateAgencyInfo/', JSON.stringify(postObj)).then(function (result) {
					var errorKeys = result.status === 'success' ? [] : result.errorMessages;
					var isIncomplete = false;
					_this2.get('taxHeader.questions').forEach(function (question) {
						_ember['default'].set(question, 'hasError', !_ember['default'].isEmpty(errorKeys) && errorKeys.contains(question.storageKey));
						isIncomplete = isIncomplete || question.hasError;
					});
					_this2.set('taxHeader.isIncomplete', isIncomplete);
					if (result.status === 'success') {
						if (!_this2.get('isFullService')) {
							_this2.showNotification();
						}
					}
				})['catch'](function (err) {
					_componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'Oops! Something went wrong.',
						message: 'An error occurred while saving changes. Please try again later.'
					});
				});
				// .finally(() => {
				// laddaButton.stop();
				// return Ember.RSVP.resolve();
				// });

				return _ember['default'].RSVP.resolve();
			},

			cancel: function cancel() {
				var _this3 = this;

				_ember['default'].ajaxPost('/custom_api/z_payroll/getAgencyInfoForOnboarding/', JSON.stringify({ company_id: this.get('company.id'), agencyIds: [this.get('title')] })).then(function (res) {
					var agencyInfo = res.data[_this3.get('title')];
					if (!_ember['default'].isEmpty(agencyInfo)) {
						_this3.get('taxHeader.questions').forEach(function (question) {
							if (question.storageKey in agencyInfo) {
								_ember['default'].set(question, 'answer', agencyInfo[question.storageKey]);
								// convert backend percentage figure to dollar figure
								if (question.storageKey == 'taxRates/WC') {
									question.answer.forEach(function (ans) {
										ans.employeeRate = ans.employeeRate / 100;
										ans.hourlyRate = ans.hourlyRate / 100;
									});
								}
							}
						});
						_this3.set('taxHeader.status', agencyInfo['status']);
					}
				}).then(function () {
					_this3._resetFutureStateTaxes();
					// this.send('toggleEdit');
					// return Ember.RSVP.resolve();
				});
				return _ember['default'].RSVP.resolve();
			},

			toggleEditAndValidateTaxRates: function toggleEditAndValidateTaxRates() {
				if (this.get('mode') === _componentLibraryComponentsCardsCardBase.SETTINGS_CARD_MODES.READ) {
					this.validateWcRates();
					this.validateRates();
				}
				this.send('toggleEdit');
			}
		}

	});
});