define('component-library/instance-initializers/log-model-property-usage', ['exports', 'ember', 'zen'], function (exports, _ember, _zen) {
	'use strict';

	exports.initialize = initialize;

	var normalizeModelName = function normalizeModelName(type) {
		return type.replace('App.', '').camelize().replace(/-_/, '').capitalize();
	};

	function initialize(applicationInstance, testOptions /* this argument should be only used for testing */) {
		var recentlyLogged = {};
		var currentRouteName = null;
		var isLogCodeUsageSwitchOn = undefined;
		testOptions = testOptions || {};

		if (typeof testOptions.isSwitchOn == 'undefined') {
			if (_ember['default'].$(document) && _ember['default'].$(document).find('meta[property="z:logCodeUsageInfoModelProps"]').length) {
				isLogCodeUsageSwitchOn = _ember['default'].$(document).find('meta[property="z:logCodeUsageInfoModelProps"]').attr('content') === 'true';
			}
		} else {
			isLogCodeUsageSwitchOn = testOptions.isSwitchOn;
		}

		if (isLogCodeUsageSwitchOn) {
			(function () {
				var oldGet = _ember['default'].ComputedProperty.prototype.get;
				var appName = applicationInstance.application.name;
				var store = applicationInstance.container.lookup('service:store') || testOptions.store;
				var originalModelFor = store.modelFor;
				var routingService = applicationInstance.container.lookup('service:routing') || testOptions.routingService;
				var logger = _zen['default'].eventLogger || testOptions.zenObj.eventLogger;

				store.modelFor = function (type) {
					var klass = originalModelFor.apply(this, arguments);
					if (!klass._type) {
						klass.reopenClass({
							_type: normalizeModelName(type)
						});
					}
					return klass;
				};

				routingService.on('routerWillTransition', function (transition) {
					currentRouteName = transition.targetName;
					recentlyLogged = {};
				});

				_ember['default'].ComputedProperty.prototype.get = function (obj, keyName) {
					if (obj instanceof _ember['default'].Model) {
						var modelName = obj.constructor._type || normalizeModelName(obj.constructor.toString());
						if (!recentlyLogged[modelName]) {
							recentlyLogged[modelName] = {};
						}
						if (!recentlyLogged[modelName][keyName]) {
							recentlyLogged[modelName][keyName] = true;
							logger.batchLog('code_usage_info', {
								appName: appName,
								accessedType: 'model-object',
								propertyName: keyName,
								modelName: modelName,
								currentRouteName: currentRouteName
							});
						}
					}
					return oldGet.apply(this, arguments);
				};
			})();
		}
	}

	exports['default'] = {
		name: 'log-model-property-usage',
		initialize: initialize
	};
});