define('component-library/components/z-select-field-state', ['exports', 'ember', 'component-library/components/z-select-field-base', 'component-library/utils/globals'], function (exports, _ember, _componentLibraryComponentsZSelectFieldBase, _componentLibraryUtilsGlobals) {
	'use strict';

	exports['default'] = _componentLibraryComponentsZSelectFieldBase['default'].extend({
		classNames: ['z-selectField--state'],
		label: "State",
		usStates: (function () {
			var statesHash = _componentLibraryUtilsGlobals.STATE_ABBREV_TO_NAME;
			var abbrevStates = [''].concat(Object.keys(statesHash));
			return abbrevStates;
		}).property(),
		content: _ember['default'].computed.alias('usStates')
	});
});