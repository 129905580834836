define('ember-table/views/table-block', ['exports', 'ember', 'ember-table/mixins/style-bindings', 'ember-table/mixins/register-table-component'], function (exports, _ember, _emberTableMixinsStyleBindings, _emberTableMixinsRegisterTableComponent) {
  'use strict';

  // TODO: This should be a mixin
  exports['default'] = _ember['default'].CollectionView.extend(_emberTableMixinsStyleBindings['default'], _emberTableMixinsRegisterTableComponent['default'], {
    classNames: ['et-table-block'],
    styleBindings: ['width', 'height'],
    itemViewClass: _ember['default'].computed.alias('tableComponent.tableRowViewClass'),
    height: _ember['default'].computed.oneWay('tableComponent._headerHeight'),
    columns: null,
    content: null,
    scrollLeft: null,

    onScrollLeftDidChange: _ember['default'].observer(function () {
      return this.$().scrollLeft(this.get('scrollLeft'));
    }, 'scrollLeft')
  });
});