define('component-library/utils/components/list-item', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var ListItemDataObject = _ember['default'].Object.extend({
		// Passed in properties during object creation
		field: null,
		model: null,

		// Properties set during init
		label: null,
		attrName: null,
		value: null,
		targetModel: null,
		classNames: null,
		listItemIsEditable: true,

		init: function init() {
			this._super.apply(this, arguments);

			// if attrName is provided use it, else defaults to --
			var attrName = this.get('field.attrName') || '--';

			// specific model used if declared or null
			var targetModel, value, defaultValue;
			if (typeof this.get('field.value') !== 'undefined') {
				value = this.get('field.value');
			} else if (typeof this.get('field.specificModel') !== 'undefined') {
				targetModel = this.get('field.specificModel');
				value = this.get('field.specificModel.' + attrName);
			} else {
				targetModel = this.get('model');
				value = this.get('model.' + attrName);
			}

			// use isNone to allow for falsy values
			if (_ember['default'].isNone(value)) {
				defaultValue = this.get('field.defaultValue');
				value = _ember['default'].isNone(defaultValue) ? null : defaultValue;
			}

			// Set data props
			this.setProperties({
				'label': this.get('field.label') || null, // Ex. 'Hire Date'
				'attrName': attrName, // 'hireDate'
				'value': value, // '01/02/2014'
				'targetModel': targetModel, // allEmployee
				'classNames': ['listItem-row'], // decorates the view
				'href': this.get('field.href')
			});

			if (targetModel) {
				targetModel.addObserver(attrName, this, this._valueChanged);
			}
		},

		_valueChanged: function _valueChanged() {
			var targetModel = this.get('targetModel');
			var attrName = this.get('attrName');
			var value = targetModel.get(attrName);
			this.set('value', value);
		},

		destroy: function destroy() {
			var targetModel = this.get('targetModel');
			var attrName = this.get('attrName');
			targetModel.removeObserver(attrName, this, this._valueChanged);
		}
	});

	var createListItemInputObjects = function createListItemInputObjects(fields, model) {
		return fields.map(function (field) {
			return ListItemDataObject.create({
				field: field,
				model: model,

				listItemType: 'list-item-input',
				onChangeEventName: 'listItemValueChanged'
			});
		});
	};

	exports.createListItemInputObjects = createListItemInputObjects;

	var createListItemErrorMissingInputObjects = function createListItemErrorMissingInputObjects(fields, model) {
		return fields.map(function (field) {
			return ListItemDataObject.create({
				field: field,
				model: model,
				listItemType: 'list-item-error-missing-input',
				onChangeEventName: 'listItemValueChanged'
			});
		});
	};

	exports.createListItemErrorMissingInputObjects = createListItemErrorMissingInputObjects;

	var destroyListItemInputObjects = function destroyListItemInputObjects(objs) {
		objs.forEach(function (obj) {
			obj.destroy();
		});
	};

	exports.destroyListItemInputObjects = destroyListItemInputObjects;

	var createListItemLinkObjects = function createListItemLinkObjects(fields, model) {
		return fields.map(function (field) {
			return ListItemDataObject.create({
				field: field,
				model: model,

				listItemType: 'list-item-link',
				routeName: field.routeName,
				linkOnLeft: field.linkOnLeft || false,
				withAttrValue: field.withAttrValue || false
			});
		});
	};

	exports.createListItemLinkObjects = createListItemLinkObjects;

	var createListItemSSNObjects = function createListItemSSNObjects(fields, model) {
		return fields.map(function (field) {
			return ListItemDataObject.create({
				field: field,
				model: model,
				listItemType: 'list-item-ssn',
				routeName: field.routeName,
				linkOnLeft: field.linkOnLeft || false
			});
		});
	};
	exports.createListItemSSNObjects = createListItemSSNObjects;
});