define('component-library/timeline/components/salary-change', ['exports', 'component-library/timeline/components/copy', 'component-library/timeline/mixins/change-feed-entry', 'component-library/utils/pretty-currency'], function (exports, _componentLibraryTimelineComponentsCopy, _componentLibraryTimelineMixinsChangeFeedEntry, _componentLibraryUtilsPrettyCurrency) {
	'use strict';

	function getSalaryType(type) {
		switch (type) {
			case 'H':
				return 'hourly';
			case 'S':
				return 'salary';
			default:
				return 'unknown compensation type';
		}
	}

	exports['default'] = _componentLibraryTimelineComponentsCopy['default'].extend(_componentLibraryTimelineMixinsChangeFeedEntry['default'], {

		isSameCompType: (function () {
			return this.get('data.oldCompensationType') == this.get('data.newCompensationType');
		}).property('data.oldCompensationType', 'data.newCompensationType'),

		oldTypeDenominationPeriod: (function () {
			switch (this.get('data.oldCompensationType')) {
				case 'H':
					return 'hour';
				case 'S':
					return 'year';
				default:
					return 'unknown period';
			}
		}).property('data.oldCompensationType'),
		newTypeDenominationPeriod: (function () {
			switch (this.get('data.newCompensationType')) {
				case 'H':
					return 'hour';
				case 'S':
					return 'year';
				default:
					return 'unknown period';
			}
		}).property('data.newCompensationType'),

		changeStr: (function () {

			var isSameCompType = this.get('isSameCompType');
			var oldAmt = this.get('oldAmt');
			var newAmt = this.get('newAmt');
			var effectiveDateStr = this.get('effectiveDateStr');
			var employeeStrPossessive = this.get('employeeStrPossessive');
			var newCompType = this.get('newCompType');

			if (this.get('data.salaryRedacted')) {
				if (isSameCompType) {
					return 'change ' + employeeStrPossessive + ' ' + newCompType;
				}
				return 'switch ' + this.get('employeeStr') + ' from\n\t\t\t\t\t' + getSalaryType(this.get('data.oldCompensationType')) + ' to ' + getSalaryType(this.get('data.newCompensationType'));
			}

			// if there's no old value to compare to
			if (this.get('data.oldValuesNotAvailable')) {
				return 'set ' + employeeStrPossessive + ' ' + newCompType + ' to ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(newAmt) + ' ' + effectiveDateStr;
			}

			// if the comp type is the same
			if (isSameCompType) {
				var increaseOrDecrease = '';
				if (oldAmt > newAmt) {
					increaseOrDecrease = 'decrease';
				} else {
					increaseOrDecrease = 'increase';
				}
				return increaseOrDecrease + ' ' + employeeStrPossessive + ' ' + newCompType + ' from ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(oldAmt) + ' to ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(newAmt) + ' ' + effectiveDateStr;
			}

			// if the comp type changed
			var oldPeriod = this.get('oldTypeDenominationPeriod');
			var newPeriod = this.get('newTypeDenominationPeriod');
			return 'change ' + employeeStrPossessive + ' compensation from ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(oldAmt) + ' per ' + oldPeriod + ' to ' + (0, _componentLibraryUtilsPrettyCurrency['default'])(newAmt) + ' per ' + newPeriod + ' ' + effectiveDateStr;
		}).property('isSameCompType', 'oldAmt', 'newAmt', 'effectiveDateStr', 'employeeStrPossessive', 'newCompType', 'data.oldValuesNotAvailable', 'oldTypeDenominationPeriod', 'newTypeDenominationPeriod'),

		newCompType: (function () {
			switch (this.get('data.newCompensationType')) {
				case 'H':
					return 'hourly rate';
				case 'S':
					return 'salary';
				default:
					return 'unknown compensation type';
			}
		}).property('data.newCompensationType'),

		oldAmt: (function () {
			if (this.get('data.oldValuesNotAvailable')) {
				return 'N/A';
			}
			switch (this.get('data.oldCompensationType')) {
				case 'H':
					return this.get('data.oldPayRate');
				case 'S':
					return this.get('data.oldAnnualSalary');
				default:
					return 'N/A';
			}
		}).property('data.oldAnnualSalary', 'data.oldPayRate', 'data.oldCompensationType', 'data.oldValuesNotAvailable'),

		newAmt: (function () {
			switch (this.get('data.newCompensationType')) {
				case 'H':
					return this.get('data.newPayRate');
				case 'S':
					return this.get('data.newAnnualSalary');
				default:
					return 'N/A';
			}
		}).property('data.newAnnualSalary', 'data.newPayRate', 'data.newCompensationType')

	});
});