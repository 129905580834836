define('component-library/components/document-viewer', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['z-documentViewer', 'js-z-documentViewer'],

		title: '',
		fullName: '',
		signatureImage: null,

		showTitle: true,

		errorTextTitle: null,
		errorTextFullName: null,
		errorTextSignature: null,

		roundedCorners: true,

		/**
   * If you include a scrollElementSelector, the document viewer will drop down a button that will let
   * you scroll down to the signature block of the page, once the document has reached the top of the element
   * represented by the scrollElementSelector
   *
   * Your scrollElementSelector is most likely the element that has overflow: scroll
   */
		scrollElementSelector: '',

		/**
   * Because of liquid-fire, a new incoming doc viewer will create the new banner with didInsert, but then the
   * previous doc-views willDestroy fires later, deleting the new banner as well. So instead of deleting by className
   * we need to use the actual element.
   */
		bannerElement: null,
		scrollHandler: null,

		/**
   * Prevents the display of the signature block in the document viewer
   */
		suppressSignatureBlock: false,

		/**
   * In case the scrollElement is not in the same route, make sure it gets reset to the top
   * before rendering in new content
   */
		willInsertElement: function willInsertElement() {
			var scrollElementSelector = this.get('scrollElementSelector');

			if (_ember['default'].isPresent(scrollElementSelector)) {
				_ember['default'].$(scrollElementSelector).scrollTop(0);
			}
		},

		didInsertElement: function didInsertElement() {
			var _this = this;

			var scrollElementSelector = this.get('scrollElementSelector');

			if (_ember['default'].isPresent(scrollElementSelector)) {
				(function () {

					var parent = _ember['default'].$(scrollElementSelector);
					var parentOffsetTop = parent.offset().top;
					var parentOffsetHeight = parent.outerHeight();
					var docView = _this.$();
					var docViewTop = docView.offset().top;
					var diff = docViewTop - parentOffsetTop;

					var bannerElement = _ember['default'].$('\n\t\t\t\t<div class="documentView-scrollDownBanner z-typography u-flexContainer">\n\t\t\t\t\t<div class="internal-scrollDownBanner">\n\t\t\t\t\t\t<i class="zmdi zmdi-long-arrow-down"></i>\n\t\t\t\t\t\t<span class="u-bumperLeft--lg u-bumperRight--lg">Skip to sign at bottom</span>\n\t\t\t\t\t\t<i class="zmdi zmdi-long-arrow-down"></i>\n\t\t\t\t\t</div>\n\t\t\t  </div>');

					_this.set('bannerElement', bannerElement);

					var scrollHandler = function scrollHandler() {
						parent.animate({
							scrollTop: parent[0].scrollHeight
						}, 1000);
					};

					bannerElement.on('click', scrollHandler);
					_this.set('scrollHandler', scrollHandler);

					_ember['default'].$('body').prepend(bannerElement);

					var internalBanner = bannerElement.find('.internal-scrollDownBanner');

					parent.on('scroll', function (e) {
						if (e.currentTarget.scrollTop - diff > 0 && e.currentTarget.scrollTop + parentOffsetHeight < e.currentTarget.scrollHeight && !internalBanner.hasClass('down')) {
							internalBanner.addClass('down');
						} else if ((e.currentTarget.scrollTop - diff < 0 || e.currentTarget.scrollTop + parentOffsetHeight >= e.currentTarget.scrollHeight) && internalBanner.hasClass('down')) {
							internalBanner.removeClass('down');
						}
					});
				})();
			}
		},

		willDestroyElement: function willDestroyElement() {
			var scrollElementSelector = this.get('scrollElementSelector');

			if (_ember['default'].isPresent(scrollElementSelector)) {

				var scrollHandler = this.get('scrollHandler');
				_ember['default'].$(scrollElementSelector).off('scroll', scrollHandler);
				this.set('scrollHandler');

				var bannerElement = this.get('bannerElement');
				bannerElement.off('click');
				bannerElement.remove();
				this.set('bannerElement');
			}
		},

		actions: {
			signatureComplete: function signatureComplete(signatureData) {
				this.set('signatureImage', signatureData.signatureImage);
			}
		}
	});
});