define('component-library/components/select-field', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		classNames: ['selectField'],
		classNameBindings: ['errorText:has-error', 'isOpen', 'isDisabled', 'isStatic'],
		selectedWrappedItem: _ember['default'].computed.findByProperty('wrappedItems', 'isSelected'),

		label: null,
		prompt: null,
		errorText: null,
		showErrorText: _ember['default'].computed.bool('errorText.length'),
		helpText: null,
		height: 0,
		isOpen: false,
		isDisabled: false,
		isStatic: false,
		useNative: false,
		isFocused: false,
		fieldId: undefined,

		click: function click() {
			if (this.get('isDisabled') || this.get('isStatic')) {
				return;
			}
			this.toggleProperty('isOpen');

			if (this.get('isOpen')) {
				this.openMenu();
			} else {
				this.closeMenu();
			}
		},

		closeMenu: function closeMenu() {
			var $selectFieldMenu = this.$('.js-selectField-menu');
			_ember['default'].run.scheduleOnce('afterRender', this, function () {
				$selectFieldMenu.height(0);
			});
		},

		openMenu: function openMenu() {
			var $selectFieldMenu = this.$('.js-selectField-menu');
			$selectFieldMenu.height('auto');
			// 16 = 8px + 8px => @desktop-gutter-mini from is-visible
			var height = $selectFieldMenu.height() + 16;
			$selectFieldMenu.height(0);
			_ember['default'].run.scheduleOnce('afterRender', this, function () {
				$selectFieldMenu.height(height);
			});
		},

		wrappedItems: (function () {
			var optionLabelPath = this.get('optionLabelPath');
			var optionValuePath = this.get('optionValuePath');
			return this.getWithDefault('content', []).map(function (item) {
				return _ember['default'].Object.create({
					data: item,
					label: _ember['default'].isEmpty(optionLabelPath) ? item : _ember['default'].get(item, optionLabelPath),
					value: _ember['default'].isEmpty(optionValuePath) ? item : _ember['default'].get(item, optionValuePath)
				});
			});
		}).property('content.[]', 'optionLabelPath', 'optionValuePath'),

		didInsertElement: function didInsertElement() {
			this.valueDidChange();
		},

		valueDidChange: (function () {
			var value = this.get('value');
			var allValuePairs = this.get('wrappedItems');
			allValuePairs.setEach('isSelected', false);
			var selectedItem;
			if (this.get('optionValuePath')) {
				selectedItem = allValuePairs.findBy('value', value);
			} else {
				selectedItem = allValuePairs.findBy('data', value);
			}
			if (selectedItem) {
				selectedItem.set('isSelected', true);
			}
		}).observes('content.[]', 'value'),

		actions: {

			selectItem: function selectItem(item) {
				if (!item) {
					return;
				}
				var optionValuePath = this.get('optionValuePath');
				this.get('wrappedItems').setEach('isSelected', false);
				item.set('isSelected', true);
				if (optionValuePath) {
					this.set('value', item.value);
				} else {
					this.set('value', item.data);
				}
				this.sendAction('change', item.data);
			},
			close: function close() {
				this.set('isOpen', false);
				this.closeMenu();
			},
			insertNewline: function insertNewline() {
				// TODO(L)
				console.log('Enter pressed');
				this.sendAction('insertNewline');
			},
			escapePress: function escapePress() {
				// TODO(L)
				console.log('ESC pressed');
				this.sendAction('escapePress');
			}
		}
	});
});