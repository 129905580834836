define('component-library/modals/onboarding/preview-modal', ['exports', 'component-library/components/promise-modal', 'component-library/utils/squire-iframe-helper'], function (exports, _componentLibraryComponentsPromiseModal, _componentLibraryUtilsSquireIframeHelper) {
	'use strict';

	exports['default'] = _componentLibraryComponentsPromiseModal['default'].extend({
		templateName: 'modals/onboarding/preview-modal',
		modalDialogClass: 'z-okModal',
		title: '',
		message: '',
		useSquireIframe: false,

		didInsertElement: function didInsertElement() {
			this._super();

			if (this.get('useSquireIframe')) {
				(0, _componentLibraryUtilsSquireIframeHelper['default'])(this.$('iframe')[0], this.get('message'));
			}
		}
	});
});