define('component-library/deductions/review/single/view', ['exports', 'ember', 'component-library/mixins/resize-handler'], function (exports, _ember, _componentLibraryMixinsResizeHandler) {
	'use strict';

	exports['default'] = _ember['default'].View.extend(_componentLibraryMixinsResizeHandler['default'], {
		classNames: ['grid-block', 'vertical', 'z-detailPanel-body'],
		templateName: 'deductions/review/single',
		tableMaxHeight: 500,

		adjustHeightOfTableContainer: function adjustHeightOfTableContainer() {
			var containerHeight = _ember['default'].$('.js-z-detailPanel-tableContainer').height();
			this.set('tableMaxHeight', containerHeight);
		},

		onResizeEnd: function onResizeEnd() {
			this._super();
			this.adjustHeightOfTableContainer();
		},

		didInsertElement: function didInsertElement() {
			this._super();
			this.adjustHeightOfTableContainer();
		}
	});
});