define('component-library/components/charts/svg-tooltip', ['exports', 'ember', 'component-library/utils/tags/context-tag-computed'], function (exports, _ember, _componentLibraryUtilsTagsContextTagComputed) {
	'use strict';

	var _templateObject = _taggedTemplateLiteral(['translate(', ',', ')'], ['translate(', ',', ')']);

	function _taggedTemplateLiteral(strings, raw) {
		return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
	}

	exports['default'] = _ember['default'].Component.extend({
		templateName: 'components/charts/svg-tooltip',
		tagName: 'g',
		classNames: ['z-medicalChart-tooltip'],
		attributeBindings: ['transform', 'fill'],
		transform: (0, _componentLibraryUtilsTagsContextTagComputed['default'])(_templateObject, 'x', 'y'),
		fill: '#888'
	});
});