define('component-library/mixins/benefits/timeline/transaction-employee-data-mixin', ['exports', 'ember', 'lodash/lodash', 'component-library/utils/benefits/timeline/computed'], function (exports, _ember, _lodashLodash, _componentLibraryUtilsBenefitsTimelineComputed) {
	'use strict';

	var RiderChangeModel = _ember['default'].Object.extend({
		old: null,
		'new': null,
		change: null,

		isSelectedOld: _ember['default'].computed('old', 'change', function () {
			return !_ember['default'].isEmpty(this.get('old')) || !_ember['default'].isEmpty(this.get('change'));
		}),

		isSelectedNew: _ember['default'].computed('new', 'change', function () {
			return !_ember['default'].isEmpty(this.get('new')) || !_ember['default'].isEmpty(this.get('change'));
		}),

		newRiderType: _ember['default'].computed.alias('new.riderType'),
		oldRiderType: _ember['default'].computed.alias('old.riderType'),

		riderType: _ember['default'].computed.or('newRiderType', 'oldRiderType')
	});

	exports['default'] = _ember['default'].Mixin.create({
		store: _ember['default'].inject.service(),

		employeeFirstName: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.personalInfo.firstName'),
		employeeLastName: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.personalInfo.lastName'),

		employeeName: (0, _componentLibraryUtilsBenefitsTimelineComputed.computedForFullName)('employeeFirstName', 'employeeLastName'),

		employeeDoB: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.personalInfo.dateOfBirth'),

		employeeGender: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.personalInfo.gender'),
		employeeEnrollmentTier: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.coverage.enrollmentTier'),

		coverageEffectiveDate: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.coverage.effectiveDate'),
		coverageEffectiveDateChange: _ember['default'].computed.alias('transaction.jsonDataOfContextChange.employee.coverage.effectiveDate'),
		oldCoverageEffectiveDate: _ember['default'].computed('transaction.isInitial', 'coverageEffectiveDate', 'coverageEffectiveDateChange', function () {
			if (_ember['default'].get(this, 'transaction.isInitial')) {
				return null;
			} else {
				var oldValue = this.get('coverageEffectiveDateChange.lastObject');
				return oldValue || this.get('coverageEffectiveDate');
			}
		}),

		carrierName: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.coverage.carrierName'),
		carrierChange: _ember['default'].computed.alias('transaction.jsonDataOfContextChange.employee.coverage.carrierId'),
		carrierNameChange: _ember['default'].computed.alias('transaction.jsonDataOfContextChange.employee.coverage.carrierName'),

		oldCarrierName: _ember['default'].computed('transaction.isInitial', 'carrierName', 'carrierChange', 'carrierNameChange', function () {
			if (this.get('transaction.isInitial')) {
				return null;
			} else if (this.get('carrierChange') && this.get('carrierNameChange')) {
				return this.get('carrierNameChange.lastObject');
			} else {
				return this.get('carrierName');
			}
		}),

		chpNamePromise: function chpNamePromise(chpId) {
			return this.get('store').find('companyHealthPlan', chpId).then(function (chp) {
				return chp.get('normalizedRawPlan');
			}).then(function (plan) {
				return plan.get('name');
			})['catch'](function () {
				return '';
			});
		},

		planNamePromise: _ember['default'].computed('transaction.jsonDataOfContext.employee.coverage.primaryBenefitsPlan.companyHealthPlanId', function () {
			var chpId = this.get('transaction.jsonDataOfContext.employee.coverage.primaryBenefitsPlan.companyHealthPlanId');
			return chpId ? this.chpNamePromise(chpId) : _ember['default'].RSVP.resolve(null);
		}),

		oldPlanNamePromise: _ember['default'].computed('transaction.jsonDataOfContextChange.employee.coverage.primaryBenefitsPlan.companyHealthPlanId', function () {
			var chpId = this.get('transaction.jsonDataOfContextChange.employee.coverage.primaryBenefitsPlan.companyHealthPlanId.lastObject');
			return chpId ? this.chpNamePromise(chpId) : _ember['default'].RSVP.resolve(null);
		}),

		planDisplayNamePromise: _ember['default'].computed('employeeMDVPlan.companyHealthPlanId', 'employeeLnDBasicPlan.companyHealthPlanId', 'employeeLnDVoluntaryPlan.companyHealthPlanId', function () {
			var mdvPlanChpId = this.get('employeeMDVPlan.companyHealthPlanId');
			var lndBasicPlanChpId = this.get('employeeLnDBasicPlan.companyHealthPlanId');
			var lndVoluntaryPlanChpId = this.get('employeeLnDVoluntaryPlan.companyHealthPlanId');
			var chpIds = _lodashLodash['default'].filter([mdvPlanChpId, lndBasicPlanChpId, lndVoluntaryPlanChpId], function (x) {
				return x;
			});

			if (!chpIds.length) {
				return _ember['default'].RSVP.resolve(null);
			}

			return this.get('store').find('companyHealthPlan', chpIds).then(function (chps) {
				return _ember['default'].RSVP.all(chps.mapBy('normalizedRawPlan'));
			}).then(function (plans) {
				return plans.map(function (p) {
					return p.get('name');
				}).join(' and ');
			})['catch'](function () {
				return '';
			});
		}),

		planChange: _ember['default'].computed.alias('transaction.jsonDataOfContextChange.employee.coverage.primaryBenefitsPlan.id'),
		planNameChange: _ember['default'].computed.alias('transaction.jsonDataOfContextChange.employee.coverage.primaryBenefitsPlan.name'),

		oldPlanNameDisplayPromise: _ember['default'].computed('transaction.isInitial', 'planNamePromise', 'planChange', 'planNameChange', function () {
			if (this.get('transaction.isInitial')) {
				return _ember['default'].RSVP.resolve(null);
			} else if (this.get('planChange') && this.get('planNameChange')) {
				return this.get('oldPlanNamePromise');
			} else {
				return this.get('planNamePromise');
			}
		}),

		riders: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.coverage.riders'),
		ridersChange: _ember['default'].computed.alias('transaction.jsonDataOfContextChange.employee.coverage.riders'),
		removedRiders: _ember['default'].computed.alias('ridersChange.-'),
		addedRiders: _ember['default'].computed.alias('ridersChange.+'),
		modifiedRiders: _ember['default'].computed.alias('ridersChange.~'),

		riderChangeModels: (0, _componentLibraryUtilsBenefitsTimelineComputed.computedArrayForChangeModel)(RiderChangeModel, 'id', 'riders', 'addedRiders', 'removedRiders', 'modifiedRiders'),

		newRiderChangeModels: _ember['default'].computed.filterBy('riderChangeModels', 'isSelectedNew', true),
		oldRiderChangeModels: _ember['default'].computed.filterBy('riderChangeModels', 'isSelectedOld', true),

		ridersName: _ember['default'].computed.mapBy('newRiderChangeModels', 'riderType'),
		oldRidersName: _ember['default'].computed.mapBy('oldRiderChangeModels', 'riderType'),

		employeeMDVPlan: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.coverage.primaryBenefitsPlan'),
		employeeLnDBasicPlan: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.coverage.lndBasicPlan'),
		employeeLnDVoluntaryPlan: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.coverage.lndVoluntaryPlan'),

		signatureId: _ember['default'].computed.alias('transaction.jsonDataOfContext.employee.coverage.signatureId')
	});
});